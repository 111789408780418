import {useParams} from 'react-router-dom';

import {isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetArticleCategoryQuery} from '@dms/api/metadaWarehouseArticleCategory';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';
import {ArticleCategoryDetailForm} from './components/ArticleCategoryDetailForm';

export type WarehousesArticleCategoryForm = {
  name?: string;
  code?: string | null;
  active: boolean;
  isDefault: boolean;
};

export function WarehouseArticleCategoryDetail() {
  const params = useParams();

  const {
    data: articleCategory,
    isLoading,
    isError,
  } = useGetArticleCategoryQuery(
    {articleCategoryId: params.id as string},
    {skip: isNilOrEmpty(params.id)}
  );

  const header: SettingsTemplateHeader = {
    title: isNil(articleCategory)
      ? i18n.t('entity.warehouse.labels.newWarehouseCategory')
      : (articleCategory?.name ?? ''),
    breadcrumbs: [
      {
        label: i18n.t('entity.warehouse.labels.warehouseCategory'),
        href: settingsRoutes.warehousesArticleCategory,
      },
    ],
  };

  return (
    <SettingsTemplate
      header={header}
      isLoading={isLoading}
      isError={isError}
      data-testid={testIds.settings.warehousesArticleCategoryDetail('template')}
    >
      <ArticleCategoryDetailForm
        id={params.id}
        articleCategory={articleCategory}
        data-testid={testIds.settings.warehousesArticleCategory('articleCategoryDetailForm')}
      />
    </SettingsTemplate>
  );
}
