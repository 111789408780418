import {z} from 'zod';

import {BaseVoidResponseSchema} from '@dms/api/shared';

const TreeFolderSchema = z.object({
  id: z.string().nullable().optional(),
  label: z.string().nullable().optional(),
  level: z.number().nullable().optional(),
  parentId: z.string().nullable().optional(),
});

const LabourCatalogItemSchema = z.object({
  id: z.string().nullable().optional(),
  labourCatalogCategoryId: z.string().nullable().optional(),
  priceType: z.string().nullable().optional(),
  workType: z.string().nullable().optional(),
  number: z.string().nullable().optional(),
  name: z.string().nullable().optional(),
  unit: z.string().nullable().optional(),
  quantity: z.number().nullable().optional(),
  isUnitPriceWithVat: z.boolean().nullable().optional(),
  purchaseUnitPrice: z.number().nullable().optional(),
  sellingUnitPrice: z.number().nullable().optional(),
  isDoNotApplyDiscount: z.boolean().nullable().optional(),
  timeOutputPerUnit: z.number().nullable().optional(),
  timeOutputUnit: z.string().nullable().optional(),
  currency: z.string().nullable().optional(),
  vatType: z.string().nullable().optional(),
  treeFolder: TreeFolderSchema.nullable().optional(),
});

export const GetLabourCatalogApiResponseSchema = LabourCatalogItemSchema.nullable();

export type GetLabourCatalogApiResponse = z.infer<typeof GetLabourCatalogApiResponseSchema>;

export const GetLabourCatalogApiArgSchema = z.object({
  labourCatalogId: z.string(),
});

export type GetLabourCatalogApiArg = z.infer<typeof GetLabourCatalogApiArgSchema>;

export const PostLabourCatalogResponseSchema = GetLabourCatalogApiResponseSchema;
export type PostLabourCatalogCalculationPriceApiResponse = z.infer<
  typeof PostLabourCatalogResponseSchema
>;
export type PostLabourCatalogCooperationApiResponse = z.infer<
  typeof PostLabourCatalogResponseSchema
>;
export type PostLabourCatalogDirectPriceApiResponse = z.infer<
  typeof PostLabourCatalogResponseSchema
>;
export type PostLabourCatalogTimeNormApiResponse = z.infer<typeof PostLabourCatalogResponseSchema>;

const PostLabourCatalogBodySchema = z
  .object({
    labourCatalogCategoryId: z.string().nullable().optional(),
    name: z.string().nullable().optional(),
    number: z.string().nullable().optional(),
    workType: z.string().nullable().optional(),
    unit: z.string().nullable().optional(),
    quantity: z.number().nullable().optional(),
    purchaseUnitPrice: z.number().nullable().optional(),
    sellingUnitPrice: z.number().nullable().optional(),
    vatType: z.string().nullable().optional(),
    timeOutputPerUnit: z.number().nullable().optional(),
    timeOutputUnit: z.string().nullable().optional(),
    isDoNotApplyDiscount: z.boolean().nullable().optional(),
    currency: z.string().nullable().optional(),
  })
  .nullable();

export const PostLabourCatalogCalculationPriceApiArgSchema = z.object({
  body: PostLabourCatalogBodySchema,
});
export type PostLabourCatalogCalculationPriceApiArg = z.infer<
  typeof PostLabourCatalogCalculationPriceApiArgSchema
>;

export const PostLabourCatalogCooperationApiArgSchema = z.object({
  body: PostLabourCatalogBodySchema,
});
export type PostLabourCatalogCooperationApiArg = z.infer<
  typeof PostLabourCatalogCooperationApiArgSchema
>;

export const PostLabourCatalogDirectPriceApiArgSchema = z.object({
  body: PostLabourCatalogBodySchema,
});
export type PostLabourCatalogDirectPriceApiArg = z.infer<
  typeof PostLabourCatalogDirectPriceApiArgSchema
>;

export const PostLabourCatalogTimeNormApiArgSchema = z.object({
  body: PostLabourCatalogBodySchema,
});
export type PostLabourCatalogTimeNormApiArg = z.infer<typeof PostLabourCatalogTimeNormApiArgSchema>;

export const DeleteLabourCatalogItemsApiResponseSchema = BaseVoidResponseSchema;
export type DeleteLabourCatalogItemsApiResponse = z.infer<
  typeof DeleteLabourCatalogItemsApiResponseSchema
>;

export const DeleteLabourCatalogItemsApiArgSchema = z.object({
  body: z.object({
    labourCatalogItemIds: z.array(z.string()),
  }),
});

export type DeleteLabourCatalogItemsApiArg = z.infer<typeof DeleteLabourCatalogItemsApiArgSchema>;

export const PatchLabourCatalogResponseSchema = GetLabourCatalogApiResponseSchema;
export type PatchLabourCatalogCalculationPriceApiResponse = z.infer<
  typeof PatchLabourCatalogResponseSchema
>;
export type PatchLabourCatalogCooperationApiResponse = z.infer<
  typeof PatchLabourCatalogResponseSchema
>;
export type PatchLabourCatalogDirectPriceApiResponse = z.infer<
  typeof PatchLabourCatalogResponseSchema
>;
export type PatchLabourCatalogTimeNormApiResponse = z.infer<
  typeof PatchLabourCatalogResponseSchema
>;

const PatchLabourCatalogBodySchema = PostLabourCatalogBodySchema;

export const PatchLabourCatalogCalculationPriceApiArgSchema = z.object({
  labourCatalogId: z.string(),
  body: PatchLabourCatalogBodySchema,
});
export type PatchLabourCatalogCalculationPriceApiArg = z.infer<
  typeof PatchLabourCatalogCalculationPriceApiArgSchema
>;

export const PatchLabourCatalogCooperationApiArgSchema = z.object({
  labourCatalogId: z.string(),
  body: PatchLabourCatalogBodySchema,
});
export type PatchLabourCatalogCooperationApiArg = z.infer<
  typeof PatchLabourCatalogCooperationApiArgSchema
>;

export const PatchLabourCatalogDirectPriceApiArgSchema = z.object({
  labourCatalogId: z.string(),
  body: PatchLabourCatalogBodySchema,
});
export type PatchLabourCatalogDirectPriceApiArg = z.infer<
  typeof PatchLabourCatalogDirectPriceApiArgSchema
>;

export const PatchLabourCatalogTimeNormApiArgSchema = z.object({
  labourCatalogId: z.string(),
  body: PatchLabourCatalogBodySchema,
});
export type PatchLabourCatalogTimeNormApiArg = z.infer<
  typeof PatchLabourCatalogTimeNormApiArgSchema
>;

export const GetLabourCatalogCategoryApiResponseSchema = z
  .object({
    id: z.string().nullable().optional(),
    name: z.string().nullable().optional(),
    code: z.string().nullable().optional(),
    isEditable: z.boolean().nullable().optional(),
    authorizationProfiles: z.array(z.string().nullable()).optional(),
  })
  .nullable();

export type GetLabourCatalogCategoryApiResponse = z.infer<
  typeof GetLabourCatalogCategoryApiResponseSchema
>;

export const GetLabourCatalogCategoryApiArgSchema = z.object({
  labourCatalogId: z.string(),
});

export type GetLabourCatalogCategoryApiArg = z.infer<typeof GetLabourCatalogCategoryApiArgSchema>;

export const PatchLabourCatalogCategoryApiResponseSchema = BaseVoidResponseSchema;
export type PatchLabourCatalogCategoryApiResponse = z.infer<
  typeof PatchLabourCatalogCategoryApiResponseSchema
>;

export const PatchLabourCatalogCategoryApiArgSchema = z.object({
  labourCatalogId: z.string(),
  body: z
    .object({
      name: z.string(),
      code: z.string().nullable().optional(),
      authorizationProfiles: z.array(z.string().nullable()),
    })
    .nullable(),
});

export type PatchLabourCatalogCategoryApiArg = z.infer<
  typeof PatchLabourCatalogCategoryApiArgSchema
>;

export const PostLabourCatalogCategoryApiResponseSchema = z
  .object({
    id: z.string().nullable().optional(),
  })
  .nullable();

export type PostLabourCatalogCategoryApiResponse = z.infer<
  typeof PostLabourCatalogCategoryApiResponseSchema
>;

export const PostLabourCatalogCategoryApiArgSchema = z.object({
  body: z
    .object({
      name: z.string(),
      code: z.string().nullable().optional(),
      authorizationProfiles: z.array(z.string().nullable()),
    })
    .nullable(),
});

export type PostLabourCatalogCategoryApiArg = z.infer<typeof PostLabourCatalogCategoryApiArgSchema>;

export const GetLabourCatalogItemByCatalogNumberApiResponseSchema =
  GetLabourCatalogApiResponseSchema;
export type GetLabourCatalogItemByCatalogNumberApiResponse = z.infer<
  typeof GetLabourCatalogItemByCatalogNumberApiResponseSchema
>;

export const GetLabourCatalogItemByCatalogNumberApiArgSchema = z.object({
  labourCatalogId: z.string(),
  catalogNumber: z.string(),
});

export type GetLabourCatalogItemByCatalogNumberApiArg = z.infer<
  typeof GetLabourCatalogItemByCatalogNumberApiArgSchema
>;

export const GetLabourCatalogItemApiResponseSchema = GetLabourCatalogApiResponseSchema;
export type GetLabourCatalogItemApiResponse = z.infer<typeof GetLabourCatalogItemApiResponseSchema>;

export const GetLabourCatalogItemApiArgSchema = z.object({
  labourCatalogItemId: z.string(),
});

export type GetLabourCatalogItemApiArg = z.infer<typeof GetLabourCatalogItemApiArgSchema>;

export const DeleteLabourCatalogCategoryApiResponseSchema = BaseVoidResponseSchema;
export type DeleteLabourCatalogCategoryApiResponse = z.infer<
  typeof DeleteLabourCatalogCategoryApiResponseSchema
>;

export const DeleteLabourCatalogCategoryApiArgSchema = z.object({
  labourCatalogCategoryId: z.string(),
});

export type DeleteLabourCatalogCategoryApiArg = z.infer<
  typeof DeleteLabourCatalogCategoryApiArgSchema
>;

export const PostLabourCatalogCategorySetAsActiveApiResponseSchema = BaseVoidResponseSchema;
export type PostLabourCatalogCategorySetAsActiveApiResponse = z.infer<
  typeof PostLabourCatalogCategorySetAsActiveApiResponseSchema
>;

export const PostLabourCatalogCategorySetAsInactiveApiResponseSchema = BaseVoidResponseSchema;
export type PostLabourCatalogCategorySetAsInactiveApiResponse = z.infer<
  typeof PostLabourCatalogCategorySetAsInactiveApiResponseSchema
>;

export const PostLabourCatalogCategorySetAsActiveApiArgSchema = z.object({
  labourCatalogId: z.string(),
});
export type PostLabourCatalogCategorySetAsActiveApiArg = z.infer<
  typeof PostLabourCatalogCategorySetAsActiveApiArgSchema
>;

export const PostLabourCatalogCategorySetAsInactiveApiArgSchema = z.object({
  labourCatalogId: z.string(),
});
export type PostLabourCatalogCategorySetAsInactiveApiArg = z.infer<
  typeof PostLabourCatalogCategorySetAsInactiveApiArgSchema
>;

export const PostLabourCatalogSubcontractApiResponseSchema = z
  .object({
    id: z.string().nullable().optional(),
  })
  .nullable();

export type PostLabourCatalogSubcontractApiResponse = z.infer<
  typeof PostLabourCatalogSubcontractApiResponseSchema
>;

export const PostLabourCatalogSubcontractApiArgSchema = z.object({
  body: z
    .object({
      labourCatalogCategoryId: z.string().nullable().optional(),
      name: z.string(),
      number: z.string(),
      workType: z.string().nullable().optional(),
      is_unit_price_with_vat: z.boolean().nullable().optional(),
      purchaseUnitPrice: z.number(),
      sellingUnitPrice: z.number(),
      currency: z.string().nullable().optional(),
      vatType: z.string(),
      timeOutputPerUnit: z.number().nullable().optional(),
      timeOutputUnit: z.string().nullable().optional(),
      isDoNotApplyDiscount: z.boolean().nullable().optional(),
    })
    .nullable(),
});

export type PostLabourCatalogSubcontractApiArg = z.infer<
  typeof PostLabourCatalogSubcontractApiArgSchema
>;

export const PatchLabourCatalogSubcontractApiResponseSchema = BaseVoidResponseSchema;
export type PatchLabourCatalogSubcontractApiResponse = z.infer<
  typeof PatchLabourCatalogSubcontractApiResponseSchema
>;

export const PatchLabourCatalogSubcontractApiArgSchema = z.object({
  labourCatalogItemId: z.string(),
  body: z
    .object({
      labourCatalogCategoryId: z.string().nullable().optional(),
      name: z.string(),
      number: z.string(),
      workType: z.string().nullable().optional(),
      is_unit_price_with_vat: z.boolean().nullable().optional(),
      purchaseUnitPrice: z.number(),
      sellingUnitPrice: z.number(),
      currency: z.string().nullable().optional(),
      vatType: z.string(),
      timeOutputPerUnit: z.number().nullable().optional(),
      timeOutputUnit: z.string().nullable().optional(),
      isDoNotApplyDiscount: z.boolean().nullable().optional(),
    })
    .nullable(),
});

export type PatchLabourCatalogSubcontractApiArg = z.infer<
  typeof PatchLabourCatalogSubcontractApiArgSchema
>;
