import {z} from 'zod';

import {BaseVoidResponseSchema} from '@dms/api/shared';

const MechanicSchema = z.object({
  id: z.string().nullable().optional(),
  name: z.string().nullable().optional(),
  costCenterId: z.string().nullable().optional(),
});

const AssignedMechanicSchema = z.object({
  id: z.string(),
  isDefault: z.boolean(),
  costCenterId: z.string().nullable().optional(),
  ratio: z.number(),
});

const ItemSchema = z.object({
  jobId: z.string(),
  itemId: z.string(),
});

export const GetWorkSplittingBulkApiResponseSchema = z
  .object({
    assignMechanics: z.array(MechanicSchema.nullable()).optional(),
  })
  .nullable();

export type GetWorkSplittingBulkApiResponse = z.infer<typeof GetWorkSplittingBulkApiResponseSchema>;

export const GetWorkSplittingBulkApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  itemId: z.array(z.string()),
});

export type GetWorkSplittingBulkApiArg = z.infer<typeof GetWorkSplittingBulkApiArgSchema>;

export const PutWorkSplittingBulkApiResponseSchema = BaseVoidResponseSchema;

export type PutWorkSplittingBulkApiResponse = z.infer<typeof PutWorkSplittingBulkApiResponseSchema>;

export const PutWorkSplittingBulkApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  body: z
    .object({
      items: z.array(ItemSchema.nullable()),
      assignMechanics: z.array(AssignedMechanicSchema.nullable()),
    })
    .nullable(),
});

export type PutWorkSplittingBulkApiArg = z.infer<typeof PutWorkSplittingBulkApiArgSchema>;
