import {EmptyStatus} from 'platform/components';
import {Box, Stack} from 'platform/foundation';

import i18n from '@dms/i18n';

import {useBcToOnlineOffer} from '../../../hooks/useBcToOnlineOffer';

interface EmptyStateProps {
  businessCaseId: string;
}

export function EmptyState(props: EmptyStateProps) {
  const {canSendOffer, actions} = useBcToOnlineOffer(props.businessCaseId);

  return (
    <Stack direction="column" height="100%" width="100%">
      <Box paddingTop={45}>
        <EmptyStatus
          variant="table"
          headline={i18n.t('entity.activityPanel.activity.noActivity')}
          subheadline={i18n.t('entity.activityPanel.activity.offerNotShared')}
          action={
            canSendOffer
              ? {
                  onClick: () => actions.sendOnlineOfferToCustomer({shouldCreateOffer: true}),
                  title: i18n.t('page.businessCase.actions.sendOnlineOffer'),
                  variant: 'secondary',
                }
              : undefined
          }
        />
      </Box>
    </Stack>
  );
}
