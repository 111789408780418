/**
 * Fields are stored in the form as `categoryId_paramDefinitionId`.
 * This function splits the form field name into categoryId and paramDefinitionId.
 */

export function getCategoryIdFromFormFieldName(formFieldName: string) {
  const [categoryId, ...rest] = formFieldName.split('_');
  const paramDefinitionId = rest.join('_');
  return {categoryId, paramDefinitionId};
}
