import {z} from 'zod';

import {BaseIdSchema, BaseVoidRequestSchema, BaseVoidResponseSchema} from '@dms/api/shared';

/*
 * Base marketing code
 *
 * */
export const BaseMarketingCodeSchema = z.object({
  warehouseId: z.string(),
  name: z.string(),
  salesBasePriceMarkup: z.number().optional(),
  externalCode: z.string().optional(),
  warrantyPriceMarkup: z.number().optional(),
});
export type BaseMarketingCode = z.infer<typeof BaseMarketingCodeSchema>;

/**
 * GET requests
 */
export const GetMarketingCodesRequestSchema = BaseVoidRequestSchema;
export type GetMarketingCodesRequest = z.infer<typeof GetMarketingCodesRequestSchema>;

export const GetMarketingCodesResponseSchema = z.array(
  z
    .object({
      name: z.string().optional(),
      salesBasePriceMarkup: z.number().optional(),
      warrantyPriceMarkup: z.number().optional(),
    })
    .merge(BaseIdSchema)
);
export type GetMarketingCodesResponse = z.infer<typeof GetMarketingCodesResponseSchema>;

/**
 * GET request
 */

export const GetMarketingCodeRequestSchema = z.object({
  marketingCodeId: z.string(),
});
export type GetMarketingCodeRequest = z.infer<typeof GetMarketingCodeRequestSchema>;

export const GetMarketingCodeResponseSchema = BaseMarketingCodeSchema.merge(BaseIdSchema);
export type GetMarketingCodeResponse = z.infer<typeof GetMarketingCodeResponseSchema>;

/**
 * POST requests
 */
export const PostMarketingCodeRequestSchema = BaseMarketingCodeSchema;
export type PostMarketingCodeRequest = z.infer<typeof PostMarketingCodeRequestSchema>;

export const PostMarketingCodeResponseSchema = z.object({marketingCodeId: z.string()});
export type PostMarketingCodeResponse = z.infer<typeof PostMarketingCodeResponseSchema>;

/**
 * PATCH requests
 */
export const PatchMarketingCodeRequestSchema = z.object({
  marketingCodeId: z.string(),
  body: BaseMarketingCodeSchema,
});
export type PatchMarketingCodeRequest = z.infer<typeof PatchMarketingCodeRequestSchema>;

export const PatchMarketingCodeResponseSchema = BaseVoidResponseSchema;
export type PatchMarketingCodeResponse = z.infer<typeof PatchMarketingCodeResponseSchema>;
