import {Button, Card, DataStatus, openDialog, Segment} from 'platform/components';
import {VStack, Box, HStack} from 'platform/foundation';

import {useState} from 'react';

import {useGetOrderProfitabilityQuery} from '@dms/api/metadaWorkshopServiceOrder';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {useWorkshopUrl} from '../../../../../hooks/useWorkshopUrl';
import {OrderType} from '../../../../../types/OrderType';
import {OrderTrackedTime} from './OrderTrackedTime';
import {ProfitabilityFinances} from './ProfitabilityFinances';
import {ProfitabilityWorkingTime} from './ProfitabilityWorkingTime';

interface ProfitabilitySummaryProps extends TestIdProps {
  order: OrderType;
  serviceCaseId: string;
  isReportButtonDisabled: boolean;
}

type ProfitabilityType = 'finances' | 'time';

export function ProfitabilitySummary(props: ProfitabilitySummaryProps) {
  const [type, setType] = useState<ProfitabilityType>('finances');
  const {orderId, serviceCaseId} = useWorkshopUrl();

  const {data, isLoading, isError} = useGetOrderProfitabilityQuery({
    serviceCaseId: props.serviceCaseId,
    serviceOrderId: orderId,
  });

  return (
    <DataStatus isLoading={isLoading} isError={isError} minHeight={46}>
      <Card variant="inlineGrey">
        <VStack spacing={4}>
          <HStack justify="space-between">
            <Box width={75}>
              <Segment
                value={type}
                onChange={setType}
                options={[
                  {value: 'finances', label: i18n.t('entity.order.labels.finances')},
                  {value: 'time', label: i18n.t('entity.order.labels.workingTime')},
                ]}
              />
            </Box>
            {type === 'time' && (
              <Button
                title={i18n.t('entity.order.labels.report')}
                variant="link"
                isDisabled={props.isReportButtonDisabled}
                leftIcon="devices/access_time"
                data-testid={suffixTestId('report', props)}
                onClick={() =>
                  openDialog(
                    <OrderTrackedTime serviceCaseId={serviceCaseId} serviceOrderId={orderId} />,
                    {title: i18n.t('entity.orderRequest.labels.workReport')}
                  )
                }
              />
            )}
          </HStack>
          {type === 'finances' ? (
            <ProfitabilityFinances
              profitabilityData={data}
              data-testid={suffixTestId('finances', props)}
            />
          ) : (
            <ProfitabilityWorkingTime
              profitabilityData={data}
              data-testid={suffixTestId('workingTime', props)}
            />
          )}
        </VStack>
      </Card>
    </DataStatus>
  );
}
