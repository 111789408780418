import {Dropdown} from 'platform/components';
import {Box} from 'platform/foundation';

import {UserSidebarIconSize} from '@dms/api/shared';

import {RequiredTestIdProps, useBoolean} from 'shared';

import {TaskCenterContent} from './components/TaskCenterContent';
import {TaskCenterHeaderButton} from './components/TaskCenterHeaderButton';

interface TaskCenterPopupProps extends RequiredTestIdProps {
  iconSize: UserSidebarIconSize;
}

export function TaskCenterPopup(props: TaskCenterPopupProps) {
  const [isOpen, setOpen, setClosed] = useBoolean();

  return (
    <Dropdown
      closeOnSelect
      closeOnBlur
      onOpen={setOpen}
      onClose={setClosed}
      isOpen={isOpen}
      placement="bottom-end"
      dropdownControl={
        <TaskCenterHeaderButton
          iconSize={props.iconSize}
          isOpen={isOpen}
          data-testid={props['data-testid']}
        />
      }
    >
      <Box minWidth={120}>
        <TaskCenterContent onClick={setClosed} data-testid={props['data-testid']} />
      </Box>
    </Dropdown>
  );
}
