import {match} from 'ts-pattern';

import {useEffect} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {always, isNil} from 'ramda';
import {toNumber} from 'ramda-adjunct';

import {useGetBusinessCaseQuery} from '@dms/api/businessCase';
import {useGetOrderQuery} from '@dms/api/checkout';

import {isNilOrZero, useRequiredParams} from 'shared';

import type {FormValuesType} from '../types/FormValuesType';
import {getPercentage} from '../utils/getPercentage';
import {getPredefinedNote} from '../utils/getPredefinedNote';

interface PaymentAmountSyncProps {
  formApi: UseFormReturn<FormValuesType>;
  orderId: string;
  checkoutId: string;
  paymentId: string;
  isPartialPayment: boolean;
}

/**
 * https://carvago.atlassian.net/browse/T20-41670
 *
 * this is important business requirement.
 * sync multiple payment forms and their values based other forms states.
 * react-hook-form is not ment for this approche, more optimal solution would be to use redux or context api,
 * but that would get even more complicated than this syncing hook/component
 */
export function PaymentAmountSync(props: PaymentAmountSyncProps) {
  const {id: businessCaseId} = useRequiredParams();
  const {data: order} = useGetOrderQuery({checkoutId: props.checkoutId, orderId: props.orderId});
  const {data: businessCase} = useGetBusinessCaseQuery({businessCaseId});

  const payment = order?.payments.find((item) => item.id === props.paymentId);

  const isBrokeregeSale = !!businessCase?.brokerageBusinessCaseId;
  const isPaymentInFullAmount = order?.totalPrice.withVat.amount === payment?.amount?.amount;
  const isSinglePayment = (order?.payments.length ?? 0) <= 1;
  const isSaleOrder = order?.orderDiscriminator === 'SALE';
  const isDeductible = order?.items.some((item) => item.deductible);
  const isDeductibleAndInland = order?.typeOfSale === 'inland' && isDeductible;

  const note = match([
    order?.orderDiscriminator,
    payment?.paymentDiscriminator,
    isBrokeregeSale,
    isDeductibleAndInland,
  ])
    .with(
      ['SALE', 'BALANCE', false, false],
      always(payment?.comment || getPredefinedNote(order?.typeOfSale) || '')
    )
    .otherwise(always(payment?.comment ?? ''));

  const nonPartialPaymentPrice = payment?.amount?.amount ?? '0';

  useEffect(() => {
    if (props.isPartialPayment) {
      return;
    }
    props.formApi.setValue('amount', parseFloat(nonPartialPaymentPrice));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonPartialPaymentPrice]);

  useEffect(() => {
    if (props.formApi.formState.isSubmitting) {
      return;
    }

    props.formApi.setValue('note', note);

    const isPaymentAmountNilOrZero =
      isNil(payment?.amount?.amount) || isNilOrZero(toNumber(payment.amount?.amount));

    if (order?.payments?.length === 1) {
      props.formApi.setValue('percentage', 100);
      props.formApi.setValue('amount', parseFloat(order?.totalPrice.withVat.amount ?? '0'));
      return;
    }

    if (isNil(order) || isNil(payment) || isPaymentInFullAmount || isSinglePayment) {
      return;
    }

    const percentage = getPercentage(payment.amount?.amount, order.totalPrice.withVat.amount);

    if (!isSaleOrder) {
      props.formApi.setValue('percentage', percentage);
    }

    if (!isPaymentAmountNilOrZero) {
      props.formApi.setValue('amount', parseFloat(payment.amount?.amount ?? '0'));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  return null;
}
