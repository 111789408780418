import {Button, ButtonGroup, Dialog, Attributes, showNotification} from 'platform/components';
import {Space, Text, Box} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {useState} from 'react';
import {useSelector} from 'react-redux';

import {isNotEmpty} from 'ramda';

import {useSyncInspectionToConditionMutation} from '@dms/api/carAudit';
import i18n from '@dms/i18n';

import {selectActiveAuditId} from '../../../store/carAudit/selectors';

interface ValidationError {
  code: string;
  message: string;
}

const errorCodesFieldNameMap: Record<string, string> = {
  VIN_REGEX_RULE: i18n.t('entity.vehicle.labels.vin'),
  MANUFACTURED_ON_LESS_THAN_OR_EQUAL_FIRST_REGISTRATION_RULE: String(
    i18n.t('entity.vehicle.labels.firstRegistration')
  ),
};

export function UpdateCondition({vehicleId}: {vehicleId: string}) {
  const formatDateTime = useDateTimeFormatter();
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
  const inspectionId = useSelector(selectActiveAuditId);
  const [syncInspectionToCondition, {isLoading}] = useSyncInspectionToConditionMutation();

  const updateConditionData = () => {
    if (!inspectionId) {
      throw new Error('Audit ID is required');
    }

    syncInspectionToCondition({
      inspectionId,
      vehicleId,
    })
      .unwrap()
      .then(() => {
        showNotification.success([
          i18n.t('entity.inspection.notifications.conditionWasUpdatedSuccessfully'),
          formatDateTime('timeShort', new Date()),
        ]);
      })
      .catch((e: {response?: {data?: {errors?: ValidationError[]}}}) => {
        const errors = e?.response?.data?.errors;
        if (errors?.length) {
          return setValidationErrors(errors);
        }

        showNotification.error(i18n.t('general.labels.error'));
      })
      .finally(() => {
        setIsConfirmDialogOpen(false);
      });
  };

  const handleCloseDialog = () => {
    setValidationErrors([]);
  };

  const dialogErrorRows = validationErrors.map(({code, message}) => ({
    label: errorCodesFieldNameMap[code] ?? null,
    content: (
      <Text color="danger" size="xSmall">
        {message}
      </Text>
    ),
  }));

  const hasErrors = isNotEmpty(validationErrors);

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setIsConfirmDialogOpen(true)}
        title={i18n.t('entity.condition.actions.updateCondition')}
      />

      <Dialog
        title={i18n.t('entity.condition.labels.updateConflicts')}
        isOpen={hasErrors}
        onClose={handleCloseDialog}
      >
        <Text size="small" color="secondary">
          {i18n.t('entity.condition.notifications.conditionCouldNotBeUpdated')}
        </Text>

        <Box paddingTop={6}>
          <Box borderTop="1px solid" borderColor="palettes.neutral.30.100">
            <Attributes rows={dialogErrorRows} />
          </Box>
        </Box>

        <Space vertical={4} />
        <ButtonGroup align="right">
          <Button onClick={handleCloseDialog} title={i18n.t('general.actions.gotIt')} />
        </ButtonGroup>
      </Dialog>

      <Dialog
        size="small"
        isOpen={isConfirmDialogOpen}
        onClose={() => {
          if (isLoading) {
            return;
          }

          setIsConfirmDialogOpen(false);
        }}
        title={i18n.t('entity.condition.labels.conditionUpdateConfirm')}
      >
        <Space vertical={4} />
        <ButtonGroup align="right">
          <Button
            variant="secondary"
            isDisabled={isLoading}
            onClick={() => setIsConfirmDialogOpen(false)}
            title={i18n.t('general.actions.cancel')}
          />
          <Button
            isLoading={isLoading}
            onClick={updateConditionData}
            title={i18n.t('general.actions.update')}
          />
        </ButtonGroup>
      </Dialog>
    </>
  );
}
