import {PayloadAction} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';
import {showNotification} from 'platform/components';

import {all, call, put, PutEffect, takeLatest} from 'redux-saga/effects';

import {ApiException} from '@dms/api/shared';

import {PublishingService} from '../../services/PublishingService';
import {SalesDocumentService} from '../../services/SalesDocumentService';
import {AdvertisingPlatformCodeEnum} from '../../types/AdvertisingPlatformCodeEnum';
import {
  EPlatformUpdateTypes,
  TUpdatePlatformStatusRequest,
} from '../../types/UpdatePlatformStatusRequest';
import {callApiSaga} from '../../utils/api';
import {adPlatformsRequestQuiet, getWindshieldTemplates, togglePlatformRequest} from './actions';
import {adPlatformsError, adPlatformsSuccess, windshieldTemplateSuccess} from './reducer';

function* togglePlatformSaga(action: PayloadAction<TUpdatePlatformStatusRequest>): Generator {
  const {vehicleId, option, platformCode} = action.payload;

  const body = {vehicleId, platformCode: platformCode as AdvertisingPlatformCodeEnum};
  try {
    /*
     * if option is equal "switchOn" have to disable vehicle platforms
     * if option is equal "switchOff" have to un enable vehicle platforms
     * */

    if (option === EPlatformUpdateTypes.switchOn) {
      yield callApiSaga(PublishingService.disablePlatformForVehicle, body);
    } else {
      yield callApiSaga(PublishingService.enablePlatformForVehicle, body);
    }

    yield put(adPlatformsRequestQuiet({vehicleId}));
  } catch (error: any) {
    yield call(handlingError, {error, platformCode});
  }
}

function* handlingError({
  error,
}: {
  error: AxiosError<ApiException>;
  platformCode?: string;
}): Generator<PutEffect> {
  call(() => showNotification.error(error?.response?.data?.errors?.[0]?.message));
}

function* fetchAdPlatforms(action: PayloadAction<{vehicleId: string}>): Generator {
  const {vehicleId} = action.payload;

  try {
    const response = yield* callApiSaga(PublishingService.getAdvertisementList, {
      vehicleId,
    });
    yield put(adPlatformsSuccess(response));
  } catch (error: any) {
    yield put(adPlatformsError(error));
    showNotification.error(error?.response?.data?.error?.message);
  }
}

function* getWindshieldTemplatesSaga(): Generator {
  try {
    const response = yield* callApiSaga(SalesDocumentService.getWindshieldPaperTemplates, {});
    yield put(windshieldTemplateSuccess(response));
  } catch (error: any) {
    showNotification.error(error?.response?.data?.error?.message);
  }
}

export function* advertisementSaga(): Generator {
  yield all([
    takeLatest(togglePlatformRequest.type, togglePlatformSaga),
    takeLatest(adPlatformsRequestQuiet.type, fetchAdPlatforms),
    takeLatest(getWindshieldTemplates.type, getWindshieldTemplatesSaga),
  ]);
}
