import {
  closeCurrentDialog,
  FormSubmitHandler,
  openDeleteDialog,
  openDialog,
  showNotification,
} from 'platform/components';
import {match} from 'ts-pattern';

import {always} from 'ramda';
import {isNilOrEmpty, isNull, isTrue} from 'ramda-adjunct';

import {
  AddressRequestBodyV2,
  CreateCustomerAddressV2ApiArg,
  CreateCustomerAddressV2ApiResponse,
  DeleteCustomerAddressApiArg,
  PatchCustomerAddressV2ApiArg,
  useCreateCustomerAddressV2Mutation,
  useDeleteCustomerAddressMutation,
  useGetCustomerV2Query,
  usePatchCustomerAddressV2Mutation,
} from '@dms/api/customer';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

import {InternationalAddressForm} from '../components/AddressForm/InternationalAddressForm';
import {handleApiError} from '../utils/handleApiError';
import {useAddress} from './useAddress';

type CreateCustomerAddressCallback = (address: CreateCustomerAddressV2ApiResponse) => void;
type PatchCustomerAddressCallback = () => void;

export type OpenAddressDialog = {
  onSuccess?: CreateCustomerAddressCallback;
  address?: AddressRequestBodyV2;
};

export function useCustomerAddress(customerId: string) {
  const {getAddressOptions, getAddressById} = useAddress();
  const {data} = useGetCustomerV2Query({customerId}, {skip: isNilOrEmpty(customerId)});
  const [createAddress, {isLoading: isCreateCustomerAddressLoading}] =
    useCreateCustomerAddressV2Mutation();
  const [editAddress, {isLoading: isEditCustomerAddressLoading}] =
    usePatchCustomerAddressV2Mutation();
  const [deleteAddress, {isLoading: isDeleteCustomerAddressLoading}] =
    useDeleteCustomerAddressMutation();

  const addressOptions = getAddressOptions(data?.addresses);

  const createCustomerAddress =
    (onSuccess?: CreateCustomerAddressCallback): FormSubmitHandler<AddressRequestBodyV2> =>
    async (addressData) => {
      const args: CreateCustomerAddressV2ApiArg = {
        customerId,
        addressRequestBody: {
          address: addressData.address,
          type: addressData.type,
          invalid: false,
        },
      };
      await createAddress(args)
        .unwrap()
        .then((address) => {
          showNotification.success(i18n.t('general.notifications.success'));
          onSuccess?.(address);
        })
        .then(closeCurrentDialog)
        .catch(handleApiError);
    };

  const editCustomerAddress =
    (
      addressId: string,
      onSuccess?: PatchCustomerAddressCallback
    ): FormSubmitHandler<AddressRequestBodyV2> =>
    async (addressData) => {
      const args: PatchCustomerAddressV2ApiArg = {
        addressId,
        customerId,
        addressRequestBody: {
          address: addressData.address,
          type: addressData.type,
          invalid: false,
        },
      };
      await editAddress(args)
        .unwrap()
        .then(() => {
          showNotification.success(i18n.t('general.notifications.success'));
          onSuccess?.();
        })
        .then(closeCurrentDialog)
        .catch(handleApiError);
    };

  const deleteCustomerAddress = async (addressId: string) => {
    const args: DeleteCustomerAddressApiArg = {
      addressId,
      customerId,
    };
    await deleteAddress(args)
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);
  };

  const openCreateAddressDialog = (args?: OpenAddressDialog) =>
    openDialog(
      <InternationalAddressForm
        data-testid={testIds.customer.detail('createCustomerAddress')}
        isLoading={isCreateCustomerAddressLoading}
        address={args?.address}
        onSubmit={createCustomerAddress(args?.onSuccess)}
      />,
      {
        title: i18n.t('entity.customer.actions.newAddress'),
        scrollBehavior: 'outside',
        withAdditionalFooter: true,
      }
    );

  const openEditAddressDialog = (addressId: string) => {
    const address = getAddressById({addressId, addresses: data?.addresses});

    openDialog(
      <InternationalAddressForm
        data-testid={testIds.customer.detail('editCustomerAddress')}
        isEdit={true}
        isLoading={isCreateCustomerAddressLoading}
        address={address}
        onSubmit={editCustomerAddress(addressId)}
      />,
      {
        title: i18n.t('entity.customer.actions.editAddress'),
        scrollBehavior: 'outside',
        withAdditionalFooter: true,
      }
    );
  };

  const openDeleteAddressDialog = (addressId: string) =>
    openDeleteDialog({onConfirm: () => deleteCustomerAddress(addressId)});

  const switchAddressValidity = (addressData: AddressRequestBodyV2, addressId: string) => {
    const isInvalid = match(addressData.invalid)
      .when(isNull, always(true))
      .when(isTrue, always(false))
      .otherwise(always(true));

    const args: PatchCustomerAddressV2ApiArg = {
      addressId,
      customerId,
      addressRequestBody: {
        address: addressData.address,
        type: addressData.type,
        invalid: isInvalid,
      },
    };

    editAddress(args).unwrap().catch(handleApiError);
  };

  return {
    addressOptions,
    isCreateCustomerAddressLoading,
    isEditCustomerAddressLoading,
    isDeleteCustomerAddressLoading,
    editCustomerAddress,
    createCustomerAddress,
    openCreateAddressDialog,
    openEditAddressDialog,
    openDeleteAddressDialog,
    switchAddressValidity,
  };
}
