import {randomLowerCaseUUID} from 'shared';

import {QueueVideoTask} from '../types/QueueVideoTask';
import {RawFileWithCountParts} from '../types/RawFileWithCountParts';
import {UploadPartsInfo} from '../types/UploadPartsInfo';

export function convertFileToQueueTask(
  file: Omit<RawFileWithCountParts, 'chunkSize'> & {chunkSize: number},
  info: UploadPartsInfo,
  callback: (params: QueueVideoTask) => void
): QueueVideoTask {
  return {
    callback,
    id: file.id,
    uri: file.uri,
    size: file.size,
    name: file.name,
    status: 'PENDING',
    mimeType: file.mimeType,
    expiredAt: info.expiredAt,
    chunkSize: file.chunkSize,
    connectionFileId: info.fileId,

    jobs: info.parts.map((part) => ({
      etag: part.etag,
      status: 'PENDING',
      url: part.uploadUri,
      id: randomLowerCaseUUID(),
      partNumber: part.partNumber,
    })),
  };
}
