import {openDeleteDialog, showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {path} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {useDeleteInvoiceMarginMutation} from '@dms/api/accounting';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function InvoiceMarginOverview() {
  const navigate = useNavigate();

  const [deleteMarginInvoice] = useDeleteInvoiceMarginMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData, rowData}) => {
    const id = isArray(rowId) ? rowId[0] : rowId;
    const branchName = path<string>(['branch', 'value', 'label'], rowData);

    match(actionKey)
      .with('DELETE', () =>
        openDeleteDialog({
          onConfirm: () =>
            deleteMarginInvoice({id})
              .unwrap()
              .then(() => showNotification.success())
              .then(refreshData)
              .catch(handleApiError),
          text: i18n.t('entity.accounting.labels.deleteDefinitionDeleteText', {
            name: branchName,
          }),
        })
      )
      .with('DETAIL', 'EDIT', () =>
        navigate(composePath(settingsRoutes.invoiceMarginDetail, {params: {id}}))
      );
  };

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('page.accountingSettings.labels.invoiceMargin'),
        actions: [
          {
            type: 'button',
            title: i18n.t('page.accounting.labels.newDefinition'),
            onClick: () => navigate(settingsRoutes.newInvoiceMargin),
          },
        ],
      }}
      description={i18n.t('page.accountingSettings.labels.invoiceMarginDescription')}
      data-testid={testIds.settings.invoiceMarginOverview('page')}
    >
      <DataGrid
        gridCode="accounting-margin-invoice-settings"
        actionCallback={actionCallback}
        data-testid={testIds.settings.invoiceMarginOverview('datagrid')}
      />
    </SettingsTemplate>
  );
}
