import {isEqual} from 'date-fns';

import {useEffect, useMemo, useState} from 'react';

import {any, isNil} from 'ramda';

import {BigCalendarEventWithLayout} from '../types/BigCalendarEventWithLayout';
import {calculateDuration} from '../utils/calculateDuration';
import {calculedResizedEventToBottom} from '../utils/calculedResizedEventToBottom';
import {useDnd} from './useDnd';

interface UseResizeHandlerProps {
  event: BigCalendarEventWithLayout;
}

export function useResizeHandler(args: UseResizeHandlerProps) {
  const {activeDnDId, isResizing, isResizingToBottom, overId} = useDnd();

  const [endDate, setEndDate] = useState<Date>(args.event.end);
  const [startDate, setStartDate] = useState<Date>(args.event.start);

  useEffect(() => {
    if (any(isNil, [endDate, startDate, args.event.end, args.event.start])) {
      return;
    }

    if (!isEqual(endDate, args.event.end)) {
      setEndDate(args.event.end);
    }
    if (!isEqual(startDate, args.event.start)) {
      setStartDate(args.event.start);
    }
  }, [args.event.end, args.event.start]);

  useEffect(() => {
    if (!overId || !isResizing || activeDnDId !== args.event.id) {
      return;
    }

    if (isResizingToBottom) {
      const newEnd = calculedResizedEventToBottom({
        start: args.event.start,
        end: args.event.end,
        overId,
      });

      if (isNil(newEnd)) {
        return;
      }
      setEndDate(newEnd);
    }
  }, [overId, activeDnDId, isResizing, isResizingToBottom]);

  const height = useMemo(() => calculateDuration(startDate, endDate), [startDate, endDate]);

  return {startDate, endDate, height};
}
