import {Button, DataStatus, openDialog} from 'platform/components';
import {VStack, HStack, Show} from 'platform/foundation';

import {isFalse, isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {useGetBusinessCaseQuery, useGetBusinessCaseUpcomingTasksQuery} from '@dms/api/businessCase';
import {useGetBusinessCaseActivityListQuery} from '@dms/api/sales';
import {EntityResourceIds} from '@dms/api/shared';
import {
  ActivitySidebarSendMessage,
  LogContactDialog,
  transformTaskToActivity,
} from '@dms/features/activity-panel';
import {openCreateTaskFormDialog} from '@dms/features/tasks';
import i18n from '@dms/i18n';
import {usePermissions} from '@dms/shared';

import {parseDate} from 'shared';

import {ActivityList} from './ActivityList';
import {EmptyState} from './EmptyState';

interface ActivityTabProps {
  businessCaseId: string;
}

export function ActivityTab(props: ActivityTabProps) {
  const [canReadTasks, canCreateTasks] = usePermissions({
    permissionKeys: ['readTask', 'createTask'],
  });

  const {
    data: businessCaseData,
    isLoading: isBusinessCaseLoading,
    isError: isBusinessCaseErrored,
  } = useGetBusinessCaseQuery(
    {businessCaseId: props.businessCaseId},
    {skip: isNilOrEmpty(props.businessCaseId)}
  );

  const {
    data,
    isLoading: isBusinessCaseActivityLoading,
    isError: isBusinessCaseActivityErrored,
  } = useGetBusinessCaseActivityListQuery(
    {businessCaseId: props.businessCaseId},
    {skip: isNilOrEmpty(props.businessCaseId)}
  );

  const {
    data: upcomingTasks,
    isLoading: isUpcomingTasksLoading,
    isError: isUpcomingTasksErrored,
  } = useGetBusinessCaseUpcomingTasksQuery(
    {businessCaseId: props.businessCaseId},
    {skip: isNilOrEmpty(props.businessCaseId)}
  );

  const handleNewTaskClick = () => {
    openCreateTaskFormDialog({
      'data-testid': 'business-case-activity-tab-create-task',
      relatedRecordId: props.businessCaseId,
      resourceId: EntityResourceIds.businessCase,
    });
  };

  const pastActivities =
    data?.filter((activity) => parseDate(activity.timestamp) < new Date()) ?? [];
  const isAddNewTaskEnabled = canReadTasks && canCreateTasks;
  const isLoading =
    isBusinessCaseLoading || isBusinessCaseActivityLoading || isUpcomingTasksLoading;
  const isError = isBusinessCaseErrored || isBusinessCaseActivityErrored || isUpcomingTasksErrored;

  if (isNilOrEmpty(data)) {
    return <EmptyState businessCaseId={props.businessCaseId} />;
  }

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <VStack spacing={4} height="100%">
        <HStack spacing={2}>
          <ActivitySidebarSendMessage
            resourceId={EntityResourceIds.businessCase}
            recordId={props.businessCaseId}
            customerId={businessCaseData!.customerId}
          />

          <Button
            leftIcon="content/add_circle"
            title={i18n.t('entity.task.actions.createTask')}
            variant="link"
            size="small"
            isDisabled={isFalse(isAddNewTaskEnabled)}
            onClick={handleNewTaskClick}
          />

          <Button
            leftIcon="communication/phone"
            title={i18n.t('entity.activityPanel.activity.logCall')}
            onClick={() =>
              openDialog(<LogContactDialog businessCaseId={props.businessCaseId} />, {
                id: 'LOG_CONTACT_DIALOG',
                title: i18n.t('entity.activityPanel.logContact.createLog'),
                scrollBehavior: 'outside',
              })
            }
            variant="link"
            size="small"
          />
        </HStack>

        <VStack spacing={4} height="92%">
          <ActivityList
            showEmpty
            label={i18n.t('entity.activityPanel.activity.nextSteps')}
            items={(upcomingTasks ?? []).map(transformTaskToActivity)}
          />

          <Show when={isNotNilOrEmpty(pastActivities)}>
            <ActivityList
              label={i18n.t('entity.activityPanel.activity.pastActivities')}
              items={pastActivities}
              isFullHeight={isNilOrEmpty(upcomingTasks)}
            />
          </Show>
        </VStack>
      </VStack>
    </DataStatus>
  );
}
