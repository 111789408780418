import {addDays, parseISO, startOfToday} from 'date-fns';
import {Card, DataStatus, Form, FormSubmitHandler} from 'platform/components';
import {Hide, Show, VStack} from 'platform/foundation';

import {Helmet} from 'react-helmet-async';
import {DeepPartial} from 'react-hook-form';
import {useParams} from 'react-router-dom';

import {isNotNil} from 'ramda-adjunct';

import {PaymentInfoRequestBody} from '@dms/api/metadaErrorLogs';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {useGetDocumentData} from '@dms/shared';

import {getApiDateString, useQueryState} from 'shared';

import {CustomerAndSupplier} from '../../components/CustomerAndSupplier';
import {DocumentSeriesSelect} from '../../components/DocumentSeriesSelect';
import {FormHeader} from '../../components/FormHeader';
import {InvoicePage} from '../../components/InvoicePage';
import {ListOfItemsForm} from '../../components/listOfItems/ListOfItemsForm';
import {PaymentDates} from '../../components/PaymentDates';
import {PaymentDetails} from '../../components/PaymentDetails';
import {PaymentsNotes} from '../../components/PaymentsNotes';
import {InvoiceVatRateProvider} from '../../context/InvoiceVatRateProvider';
import {AccountingDocumentFormValues} from '../../types';
import {$CreateCorrectiveTaxDocumentSchema} from './$CreateCorrectiveTaxDocumentSchema';
import {CorrectiveTaxDocumentDetails} from './CorrectiveTaxDocumentDetails';
import {useGetBalanceInvoiceCorrectiveItems} from './hooks/useGetBalanceInvoiceCorrectiveItems';
import {useHandleSubmitCheckoutCorrectiveDocument} from './hooks/useHandleSubmitCheckoutCorrectiveDocument';
import {useHandleSubmitCorrectiveTaxDocument} from './hooks/useHandleSubmitCorrectiveTaxDocument';
import {mapInvoiceItems} from './utils/mapInvoiceItems';

export function CreateCorrectiveTaxDocument() {
  const {id} = useParams();
  const [invoiceType] = useQueryState('invoiceType');
  const [checkoutId] = useQueryState('checkoutId');
  const [type] = useQueryState('type');

  const isFromCheckout = isNotNil(checkoutId);

  const [createCorrectiveTaxDocument] = useHandleSubmitCorrectiveTaxDocument();
  const [createCheckoutCorrectiveTaxDocument] = useHandleSubmitCheckoutCorrectiveDocument();
  const {
    data: accountingDocument,
    isError: isInvoiceError,
    isLoading: isLoadingInvoice,
  } = useGetDocumentData(invoiceType, id);

  const isBalanceInvoiceFromCheckout = isFromCheckout && invoiceType === 'BALANCE_INVOICE';
  const {balanceInvoiceItems, isLoadingBalanceItems, isBalanceItemsError} =
    useGetBalanceInvoiceCorrectiveItems(isBalanceInvoiceFromCheckout, id);

  const handleFormSubmit: FormSubmitHandler<AccountingDocumentFormValues> = async (formData) => {
    isFromCheckout
      ? await createCheckoutCorrectiveTaxDocument(
          formData as AccountingDocumentFormValues<PaymentInfoRequestBody>
        )
      : await createCorrectiveTaxDocument(formData);
  };

  const todaysDate = getApiDateString(startOfToday());
  const defaultValues: DeepPartial<AccountingDocumentFormValues> = {
    additionalCustomer: isNotNil(accountingDocument?.additionalCustomer?.customer.id)
      ? {
          ...accountingDocument?.additionalCustomer,
          customer: {
            ...accountingDocument?.additionalCustomer?.customer,
            id: accountingDocument?.additionalCustomer?.customer.id,
          },
        }
      : null,
    currency: accountingDocument?.currency,
    customer: accountingDocument?.customer,
    customFieldsPayload: accountingDocument?.customFields,
    issueDate: todaysDate,
    dueDate: getApiDateString(addDays(todaysDate, 14)),
    dateOfTaxableSupply: todaysDate,
    dateOfReception: accountingDocument?.dateOfReception,
    internalNote: accountingDocument?.internalNote,
    paymentInfo: accountingDocument?.paymentInfo,
    id: accountingDocument?.id,
    supplier: accountingDocument?.supplier,
    invoiceItems: isBalanceInvoiceFromCheckout
      ? mapInvoiceItems(balanceInvoiceItems, false)
      : mapInvoiceItems(
          accountingDocument?.items?.filter(
            (item) => item.generatedItemType !== 'proforma_deduction'
          ),
          true
        ),
    documentSeriesId: accountingDocument?.documentSeriesId,
    exchangeRateRatio: accountingDocument?.exchangeRateRatio,
    number: accountingDocument?.number,
    reason: null,
    isExchangeRateRatioEnabled: accountingDocument?.isExchangeRateRatioEnabled,
    dueSinceIssueDateInDays: accountingDocument?.dueSinceIssueDateInDays,
    footerNote: accountingDocument?.footerNote,
    isInternal: accountingDocument?.invoiceCategory === 'internal',
  };

  const isLoading = isLoadingInvoice || isLoadingBalanceItems;
  const isError = isInvoiceError || isBalanceItemsError;

  return (
    <DataStatus isLoading={isLoading} isError={isError} minHeight="80vh">
      <Helmet title={i18n.t('entity.correctiveTaxDocument.labels.newDocument')} />
      <Form<AccountingDocumentFormValues>
        defaultValues={defaultValues}
        onSubmit={handleFormSubmit}
        schema={$CreateCorrectiveTaxDocumentSchema}
      >
        {(control, formApi) => (
          <InvoiceVatRateProvider
            control={control}
            listItemType="invoicing-documents"
            date={
              accountingDocument?.dateOfTaxableSupply
                ? getApiDateString(parseISO(accountingDocument?.dateOfTaxableSupply))
                : undefined
            }
          >
            <VStack>
              <FormHeader
                actions={[
                  {
                    type: 'form-button',
                    title: i18n.t('general.actions.create'),
                    control,
                    buttonType: 'submit',
                    'data-testid':
                      testIds.accounting.newCorrectiveTaxDocument('create-corrective-tax'),
                  },
                ]}
                title={i18n.t('entity.correctiveTaxDocument.labels.newDocument')}
              />
              <Hide when={isFromCheckout}>
                <DocumentSeriesSelect
                  control={control}
                  documentType="corrective_tax_document"
                  data-testid={testIds.accounting.newCorrectiveTaxDocument('create-corrective-tax')}
                />
              </Hide>

              <InvoicePage>
                <Card>
                  <VStack spacing={4}>
                    <Show when={isNotNil(defaultValues)}>
                      <CustomerAndSupplier
                        control={control}
                        formApi={formApi}
                        supplier={accountingDocument?.supplier}
                        allowAdditionalCustomer
                        isCustomerFixed
                        data-testid={testIds.accounting.newCorrectiveTaxDocument(
                          'create-customer-and-supplier'
                        )}
                      />
                    </Show>
                    <PaymentDates
                      formApi={formApi}
                      control={control}
                      data-testid={testIds.accounting.newCorrectiveTaxDocument('payment-dates')}
                    />
                  </VStack>
                </Card>

                <PaymentDetails
                  control={control}
                  formApi={formApi}
                  data-testid={testIds.accounting.newCorrectiveTaxDocument('payment-details')}
                  isCorrectiveTaxDocument
                />

                <CorrectiveTaxDocumentDetails
                  control={control}
                  formApi={formApi}
                  data-testid={testIds.accounting.newCorrectiveTaxDocument('document-details')}
                />

                <ListOfItemsForm
                  control={control}
                  formApi={formApi}
                  listItemType="invoices"
                  isCorrectiveTaxDocument
                  isDisabled={
                    isFromCheckout && type === 'full' && invoiceType !== 'TAX_DOCUMENT_FOR_PAYMENT'
                  }
                  dateOfTaxableSupply={accountingDocument?.dateOfTaxableSupply}
                  data-testid={testIds.accounting.newCorrectiveTaxDocument('list-of-items')}
                />
                <PaymentsNotes
                  control={control}
                  data-testid={testIds.accounting.newCorrectiveTaxDocument('payments-notes')}
                />
              </InvoicePage>
            </VStack>
          </InvoiceVatRateProvider>
        )}
      </Form>
    </DataStatus>
  );
}
