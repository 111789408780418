import {isNil} from 'ramda';

import {
  NotificationReceiveSettingsResponseBody,
  NotificationSettingsResponseBody,
} from '@dms/api/messaging';

import type {FormValues} from '../types/FormValues';

export const getDefaultValues = (
  notificationSettings: NotificationSettingsResponseBody | undefined
): FormValues | undefined => {
  if (isNil(notificationSettings)) {
    return undefined;
  }

  const {systemReceiveSettings, tenantReceiveSettings, userReceiveSettings} = notificationSettings;

  const getUserSettingsWithFallback = (key: keyof NotificationReceiveSettingsResponseBody) => {
    const fallbackSettings = tenantReceiveSettings?.[key] ?? systemReceiveSettings?.[key];

    if (notificationSettings.tenantSettingsForced) {
      return fallbackSettings;
    }

    return userReceiveSettings?.[key] ?? fallbackSettings;
  };

  return {
    email: getUserSettingsWithFallback('email') === 'always',
    sms: getUserSettingsWithFallback('sms') === 'always',
    toastNotification: getUserSettingsWithFallback('toastNotification') === 'always',
  };
};
