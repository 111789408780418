import {Checkbox, Table} from 'platform/components';
import {Box, HStack, Heading, Scroll, getSize} from 'platform/foundation';

import {memo} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';

import {isNotEmpty, keysIn, pipe, valuesIn} from 'ramda';
import {isTrue} from 'ramda-adjunct';

import {useGetRoleQuery} from '@dms/api/accessControl';
import {ListProtectedUnitsApiResponse} from '@dms/api/accessControlList';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

import {Nullish} from 'shared';

import {ResourceItem} from './components/ResourceItem';
import {filterAndMapResourcesBySearchString} from './utils/filterAndMapResourcesBySearchString';
import {filterResourcesByFeatureFlag} from './utils/filterResourcesByFeatureFlag';

interface AccessControlListParams {
  roleId: string;
  searchString: string | null;
  resources: ListProtectedUnitsApiResponse | undefined;
}

const HEADER_HEIGHT = getSize(37);
const FORM_HEIGHT = getSize(44);
const FOOTER_HEIGHT = getSize(35);

function AccessControlListComponent(props: AccessControlListParams) {
  const {data: role} = useGetRoleQuery({roleId: props.roleId});

  const {control, setValue, formState} = useFormContext();
  const protectedUnits = useWatch({control, name: 'protectedUnits'});

  const isSystem = role?.system ?? false;

  const resources = pipe(
    filterAndMapResourcesBySearchString(props.searchString),
    filterResourcesByFeatureFlag
  )(props.resources);

  const protectedUnitsValues: {value: boolean | Nullish}[] = valuesIn(protectedUnits ?? {});

  const isChecked =
    isNotEmpty(protectedUnitsValues) && protectedUnitsValues.every((item) => isTrue(item?.value));
  const isIndeterminate = !isChecked && protectedUnitsValues.some((item) => isTrue(item?.value));

  const handleToggleAllPermissions = (value: boolean) => {
    keysIn(protectedUnits ?? {}).forEach((key) => setValue(`protectedUnits.${key}.value`, value));
  };

  return (
    <Scroll height={`calc(100vh - ${HEADER_HEIGHT} - ${FORM_HEIGHT} - ${FOOTER_HEIGHT})`} always>
      <Table
        data-testid={testIds.settings.roleManagementDetail('aclTable')}
        columns={[
          {
            element: (
              <Box paddingHorizontal={2} paddingVertical={4}>
                <HStack align="center" spacing={21}>
                  <Checkbox
                    value={isChecked}
                    isIndeterminate={isIndeterminate}
                    onChange={handleToggleAllPermissions}
                    isDisabled={isSystem || formState.isSubmitting}
                    data-testid={testIds.settings.roleManagementDetail('select-all')}
                  />
                  <Box paddingHorizontal={2} borderLeft="solid 1px" borderColor="general.separator">
                    <Heading color="secondary" size={5}>
                      {i18n.t('page.settings.role.tabs.label.permissions')}
                    </Heading>
                  </Box>
                </HStack>
              </Box>
            ),
            width: '40%',
          },
          {
            element: (
              <Box paddingHorizontal={2} borderLeft="solid 1px" borderColor="general.separator">
                <HStack>
                  <Heading color="secondary" size={5}>
                    {i18n.t('general.labels.description')}
                  </Heading>
                </HStack>
              </Box>
            ),
            width: '30%',
          },
          {
            element: (
              <Box paddingHorizontal={2} borderLeft="solid 1px" borderColor="general.separator">
                <HStack>
                  <Heading color="secondary" size={5}>
                    {i18n.t('acl.permission.condition')}
                  </Heading>
                </HStack>
              </Box>
            ),
            width: '30%',
          },
        ]}
      >
        {resources?.map((resource) => (
          <ResourceItem
            data={resource}
            data-testid={testIds.settings.roleManagementDetail('aclTable')}
            key={resource.id}
          />
        ))}
      </Table>
    </Scroll>
  );
}

// memo is needed to prevent re-renders when non-debounced search string in parent changes
export const AccessControlList = memo(AccessControlListComponent);
