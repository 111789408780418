import {intervalToDuration} from 'date-fns';
import {match} from 'ts-pattern';
import {Nullish} from 'utility-types';

import {isNotNil} from 'ramda';

interface ComputeElapsedTimeProps {
  startedAt: string | Nullish;
  finishedAt: string | Nullish;
  status: string | Nullish;
}

export function computeElapsedTime(props: ComputeElapsedTimeProps) {
  const duration = match(props.status)
    .with('inProgress', () => {
      if (!props.startedAt) {
        return;
      }

      return intervalToDuration({
        start: props.startedAt,
        end: new Date(),
      });
    })
    .with('failed', 'finished', () => {
      if (!props.startedAt || !props.finishedAt) {
        return;
      }

      return intervalToDuration({
        start: props.startedAt,
        end: props.finishedAt,
      });
    })
    .otherwise(() => undefined);

  if (!duration) {
    return '0';
  }

  let elapsedTimeBase = `${duration.hours ?? 0}h ${duration.minutes ?? 0}m ${duration.seconds ?? 0}s`;

  if (isNotNil(duration.days)) {
    elapsedTimeBase = `${duration.days ?? 0}d ${elapsedTimeBase}`;
  }

  return elapsedTimeBase;
}
