import {z} from 'zod';

export type GetMyCalendarSelectedUsersApiResponse = /** status 200  */ SelectedUserBody[];
export type SetMyCalendarSelectedUsersApiResponse = /** status 204  */ void;
export type SetMyCalendarSelectedUsersApiArg = {
  setMyCalendarSelectedUsersRequestBody: SetMyCalendarSelectedUsersRequestBody;
};
export type SelectedUserBody = {
  userId: string;
  isHidden: boolean;
  color: string | null;
};

export type SetMyCalendarSelectedUsersRequestBody = {
  selectedUsers: SelectedUserBody[];
};

export const selectedUserBodySchema = z.object({
  userId: z.string(),
  isHidden: z.boolean(),
  color: z.string().nullable(),
});

export const setMyCalendarSelectedUsersRequestBodySchema = z.object({
  selectedUsers: z.array(selectedUserBodySchema),
});

export const getMyCalendarSelectedUsersResponseSchema = z.array(selectedUserBodySchema);
