export const columns: Record<string, number> = {
  NEW: 0,
  OPEN: 1,
  OFFER: 2,
  CONTRACT: 3,
  DEPOSIT: 4,
  DEPOSIT_PAID: 5,
  INVOICE: 6,
  PAID: 7,
};
