import {TabProps, Tabs} from 'platform/components';
import {Show} from 'platform/foundation';

import {GetServiceOrderReturnResponse} from '@dms/api/metadaWarehouseServiceOrderReturn';
import i18n from '@dms/i18n';
import {queryParams, Section} from '@dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId, useBoolean, useQueryState} from 'shared';

import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';
import {GeneralInformationTab} from './components/GeneralInformationTab';
import {ListOfItemsTab} from './components/ListOfItemsTab';
import {ServiceOrderReturnsMaterialReturnModal} from './components/ServiceOrderReturnsMaterialReturnModal';

interface OverviewProps extends RequiredTestIdProps {
  serviceOrderReturn: GetServiceOrderReturnResponse | Nullish;
  isServiceOrderReturnLoading: boolean;
}

export function Overview(props: OverviewProps) {
  const {serviceOrderReturnId} = useWarehouseParams();

  const [activeTabId, setActiveTabId] = useQueryState(queryParams.COMPONENT_SECTIONS_TAB);

  const [isReturnMaterialModalVisible, openReturnMaterialModal, closeReturnMaterialModal] =
    useBoolean(false);

  const isServiceOrderReturnCompleted =
    props.serviceOrderReturn?.serviceOrderReturn?.state === 'COMPLETED';

  const tabs: TabProps[] = [
    {
      id: 'general-information',
      title: i18n.t('general.labels.generalInformation'),
      content: (
        <GeneralInformationTab
          serviceOrderReturn={props.serviceOrderReturn}
          isServiceOrderReturnLoading={props.isServiceOrderReturnLoading}
          data-testid={suffixTestId('tabs.generalInformation', props)}
        />
      ),
    },
    {
      id: 'list-of-items',
      title: i18n.t('general.labels.listOfItems'),
      actions: [
        {
          isDisabled: isServiceOrderReturnCompleted,
          type: 'button',
          variant: 'outlined',
          title: i18n.t('general.actions.addMaterial'),
          onClick: openReturnMaterialModal,
          'data-testid': suffixTestId('actions.add', props),
        },
      ],
      content: (
        <ListOfItemsTab
          totalPrice={props.serviceOrderReturn?.sparePartsTotalPrice?.totalPurchasePrice}
          data-testid={suffixTestId('tabs.listOfItems', props)}
        />
      ),
    },
  ];

  return (
    <Section isFullHeight data-testid={suffixTestId('wrapper', props)}>
      <Tabs
        isFullHeight
        isLazy
        variant="condensed"
        tabs={tabs}
        activeTabId={activeTabId}
        onChange={setActiveTabId}
      />
      <Show when={isReturnMaterialModalVisible}>
        <ServiceOrderReturnsMaterialReturnModal
          serviceOrderReturnId={serviceOrderReturnId}
          serviceOrderId={props.serviceOrderReturn?.serviceOrderReturn?.serviceOrderId as string}
          authorizationProfileId={props.serviceOrderReturn?.serviceOrder?.authorizationProfileId}
          onClose={closeReturnMaterialModal}
          data-testid={suffixTestId('dialogs.returnMaterial', props)}
        />
      </Show>
    </Section>
  );
}
