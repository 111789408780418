import {Avatar} from 'platform/components';
import {
  Box,
  Center,
  Hide,
  Show,
  HStack,
  VStack,
  Text,
  Heading,
  Space,
  Clickable,
} from 'platform/foundation';

import {isNil, isNotNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {useReadCodeQuery} from '@dms/api/codelist';
import {useGetInterestQuery} from '@dms/api/interest';
import {InterestActivityResponseBody} from '@dms/api/sales';
import {EntityResourceIds} from '@dms/api/shared';
import {useGetUserQuery} from '@dms/api/user';
import {
  ActivityDate,
  ActivitySidebarLink,
  getActivityIconColor,
  transformActivityItem,
} from '@dms/features/activity-panel';
import {openEditTaskFormDialog} from '@dms/features/tasks';
import {getNaturalPersonFullName} from '@dms/shared';

import {DOT_CHARACTER} from 'shared';

interface ActivityItemProps {
  item: InterestActivityResponseBody;
  showLineToNextItem: boolean;
  interestId: string;
}

export function ActivityItem(props: ActivityItemProps) {
  const {data: interestData} = useGetInterestQuery(
    {id: props.interestId},
    {
      skip:
        props.item.activityType !== 'INTEREST_OPPORTUNITY_LOST' &&
        props.item.activityType !== 'INTEREST_CREATED' &&
        props.item.activityType !== 'INTEREST_CLOSED',
    }
  );

  const {data: sourceCodeData} = useReadCodeQuery(
    {codelistId: 'interest_source', codeId: interestData?.sourceCodeId ?? ''},
    {skip: !interestData?.sourceCodeId}
  );

  const {data: closeReasonData} = useReadCodeQuery(
    {codelistId: 'unsuccessful_interest_reason', codeId: interestData?.reasonCloseCodeId ?? ''},
    {skip: isNil(interestData?.reasonCloseCodeId)}
  );

  const {data: userData} = useGetUserQuery(
    {id: props.item.userId ?? ''},
    {skip: !props.item.userId || isNotNilOrEmpty(props.item.userName)}
  );

  const handleOpenEditTaskFormDialog = (id: string) => {
    openEditTaskFormDialog({
      id,
      'data-testid': 'activity-panel-task-edit',
    });
  };

  const transformedItem = transformActivityItem(
    props.item,
    interestData,
    {
      source: sourceCodeData,
      closeReason: closeReasonData,
    },
    EntityResourceIds.interest,
    handleOpenEditTaskFormDialog
  );

  const hasUserName = isNotNilOrEmpty(props.item.userName) || isNotNil(userData);

  if (!transformedItem) {
    return null;
  }

  return (
    <HStack spacing={2}>
      <VStack>
        <Avatar
          color={transformedItem.color}
          icon={transformedItem.icon}
          variant="square"
          iconColor={getActivityIconColor(transformedItem.color)}
        />
        <Hide when={props.showLineToNextItem}>
          <Center width="100%" height="100%">
            <Box height="100%" backgroundColor="general.separator" width="0.125rem" />
          </Center>
        </Hide>
      </VStack>
      <VStack width="100%">
        <Box paddingBottom={4}>
          <Box paddingBottom={0}>
            <HStack width="100%">
              <VStack width="100%">
                <Clickable
                  onClick={transformedItem.onClick}
                  isDisabled={!transformedItem.isEditable}
                >
                  <Heading size={5}>{transformedItem?.title}</Heading>
                </Clickable>
                <Text color="tertiary" size="xSmall">
                  <HStack align="center">
                    <Show when={transformedItem?.interestType}>
                      {transformedItem?.interestType}
                    </Show>
                    <Show when={transformedItem?.interestType && transformedItem?.userName}>
                      <Space horizontal={1} />
                      {DOT_CHARACTER}
                      <Space horizontal={1} />
                    </Show>
                    <Show when={hasUserName}>
                      {transformedItem?.userName ?? getNaturalPersonFullName(userData)}
                    </Show>
                  </HStack>
                </Text>
              </VStack>
              <ActivityDate timestamp={props.item.timestamp} />
            </HStack>
          </Box>
          <Show when={isNotNilOrEmpty(transformedItem?.description)}>
            <Text size="xSmall" color="secondary" noWrap>
              {transformedItem?.description}
            </Text>
          </Show>
          <Show when={isNotNilOrEmpty(transformedItem?.links)}>
            <HStack>
              {transformedItem?.links?.map((link) => (
                <ActivitySidebarLink key={link.label} {...link} />
              ))}
            </HStack>
          </Show>
        </Box>
      </VStack>
    </HStack>
  );
}
