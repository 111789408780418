import {Card} from 'platform/components';
import {VStack} from 'platform/foundation';

import {equals} from 'ramda';

import {PaymentTypeResponseBody} from '@dms/api/accounting';
import {BaseAuthorizationProfile} from '@dms/api/metadaAdminAuthorizationProfile';
import {GetWarehousesResponse} from '@dms/api/metadaWarehouse';
import {GetReceiveNoteCorrectionResponse} from '@dms/api/metadaWarehouseReceiveNoteCorrection';
import {BaseSupplier} from '@dms/api/metadaWarehouseSupplier';
import {CurrencyResponseBody} from '@dms/api/shared';
import i18n from '@dms/i18n';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {ReceiveNoteBasicInformationReadOnly} from '../../../../../components/ReceiveNoteBasicInformationReadOnly';
import {getSupplierById} from '../../../../../utils/getSupplierById';
import {getWarehouseById} from '../../../../../utils/getWarehouseById';

interface DeliveryNoteTabProps extends RequiredTestIdProps {
  receiveNoteCorrection: GetReceiveNoteCorrectionResponse;
  suppliers: BaseSupplier[] | Nullish;
  warehouses: GetWarehousesResponse | Nullish;
  authorizationProfiles: BaseAuthorizationProfile[] | Nullish;
  paymentTypes: PaymentTypeResponseBody[] | Nullish;
  currencies: CurrencyResponseBody[] | Nullish;
}

export function DeliveryNoteTab(props: DeliveryNoteTabProps) {
  const originalSupplier = getSupplierById(
    props.suppliers ?? [],
    props.receiveNoteCorrection?.deliveryNote?.supplierId
  )?.name;

  const originalWarehouse = getWarehouseById(
    props.warehouses ?? [],
    props.receiveNoteCorrection?.deliveryNote?.warehouseId
  )?.name;

  const originalAuthorizationProfile = props.authorizationProfiles?.find((authorizationProfile) =>
    equals(
      authorizationProfile.id,
      props.receiveNoteCorrection?.deliveryNote?.authorizationProfileId
    )
  )?.name;

  const originalNumber = props.receiveNoteCorrection?.deliveryNote?.deliveryNoteNumber;

  const originalDate = props.receiveNoteCorrection?.deliveryNote?.created;

  const originalInvoiceNumber = props.receiveNoteCorrection?.deliveryNote?.invoiceNumber;

  const originalInvoiceIssueDate = props.receiveNoteCorrection?.deliveryNote?.invoiceIssueDate;

  const originalPaymentType = props.paymentTypes?.find((paymentType) =>
    equals(paymentType.key, props.receiveNoteCorrection?.deliveryNote?.paymentType)
  )?.value;

  const originalCurrency = props.currencies?.find((currency) =>
    equals(currency.code, props.receiveNoteCorrection?.deliveryNote?.currency)
  )?.code;

  const originalExchangeRate = props.receiveNoteCorrection?.deliveryNote?.exchangeRate;

  const originalNcConversion = props.receiveNoteCorrection?.deliveryNote?.ncConversion;

  const originalNote = props.receiveNoteCorrection?.deliveryNote?.note;

  const originalCreatedDate = props.receiveNoteCorrection?.deliveryNote?.created;

  const originalUpdatedDate = props.receiveNoteCorrection?.deliveryNote?.updated;

  const isDeliveryNoteCompleted = props.receiveNoteCorrection?.deliveryNote?.state === 'COMPLETED';

  const originalCompletedDate = isDeliveryNoteCompleted
    ? props.receiveNoteCorrection?.deliveryNote?.updated
    : null;

  return (
    <Card title={i18n.t('entity.warehouse.labels.deliveryNote')} data-testid={props['data-testid']}>
      <VStack spacing={4}>
        <Card
          variant="inlineGrey"
          flags={[{label: i18n.t('entity.warehouse.labels.original'), colorScheme: 'black'}]}
        >
          <ReceiveNoteBasicInformationReadOnly
            isOriginalDocument
            readOnlyFields={{
              supplierName: originalSupplier,
              warehouseName: originalWarehouse,
              authorizationProfileName: originalAuthorizationProfile,
              number: originalNumber,
              date: originalDate,
              invoiceNumber: originalInvoiceNumber,
              invoiceIssueDate: originalInvoiceIssueDate,
              paymentType: originalPaymentType,
              currency: originalCurrency,
              exchangeRate: originalExchangeRate,
              ncConversion: originalNcConversion,
              note: originalNote,
              createdDate: originalCreatedDate,
              updatedDate: originalUpdatedDate,
              completedDate: originalCompletedDate,
            }}
            data-testid={suffixTestId('basicInformationReadOnly', props)}
          />
        </Card>
      </VStack>
    </Card>
  );
}
