import {NumberInput, TextInput} from 'platform/components';
import {Grid} from 'platform/foundation';

import {useEffect, useState} from 'react';

import {isEmpty, isNil, isNotNil} from 'ramda';

import {
  useSourcingCreateUserVehicleSummaryMutation,
  useSourcingGetDefaultUserPresetCostQuery,
  useSourcingGetUserCostVehicleListQuery,
} from '@dms/api/sourcing';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {useSourcingCurrency} from '../../hooks/useSourcingCurrency';

interface VehiclePriceFormInitValues {
  purchasePrice?: string;
  sellingPrice?: string;
  logistics?: string;
  otherCosts?: string;
  comment?: string;
}

interface VehiclePriceFormProps extends TestIdProps {
  vehicleId?: string;
  isDisabled?: boolean;
  initialValues?: VehiclePriceFormInitValues;
}

export function VehiclePriceForm(props: VehiclePriceFormProps) {
  const currency = useSourcingCurrency();
  const currencyName = currency?.code;

  const [createUserVehicleSummary] = useSourcingCreateUserVehicleSummaryMutation();

  const {data: userPreset} = useSourcingGetDefaultUserPresetCostQuery();
  const {data: vehicleCosts} = useSourcingGetUserCostVehicleListQuery(
    {sourcingVehicleId: props.vehicleId!},
    {skip: isNil(props.vehicleId)}
  );
  const totalPrice = (vehicleCosts?.length === 0 ? userPreset?.presetItems : vehicleCosts)
    ?.filter(({enabled}) => enabled)
    .reduce((prev, current) => Number(current.cost) + prev, 0);

  const [purchasePrice, setPurchasePrice] = useState(props.initialValues?.purchasePrice);
  const [sellingPrice, setSellingPrice] = useState(props.initialValues?.sellingPrice);
  const [comment, setComment] = useState(props.initialValues?.comment);

  useEffect(() => {
    setPurchasePrice(props.initialValues?.purchasePrice);
    setSellingPrice(props.initialValues?.sellingPrice);
    setComment(props.initialValues?.comment);
  }, [props.initialValues]);

  const onBlur = () => {
    if (
      (props.initialValues?.purchasePrice !== purchasePrice ||
        props.initialValues?.sellingPrice !== sellingPrice ||
        props.initialValues?.comment !== comment) &&
      isNotNil(props.vehicleId)
    ) {
      createUserVehicleSummary({
        sourcingVehicleId: props.vehicleId,
        body: {
          purchasePrice: purchasePrice ? parseInt(purchasePrice) : null,
          sellingPrice: sellingPrice ? parseInt(sellingPrice) : null,
          comment: isEmpty(comment) || isNil(comment) ? null : comment,
        },
      })
        .unwrap()
        .catch(handleApiError);
    }
  };

  return (
    <Grid columns={[1, 2, 4]}>
      <NumberInput
        onChange={(value) => setPurchasePrice(value?.toString())}
        onBlur={onBlur}
        value={isNotNil(purchasePrice) ? parseInt(purchasePrice) : null}
        label={i18n.t('entity.warehouse.labels.purchasePrice')}
        suffix={currencyName}
        isDisabled={props.isDisabled}
        data-testid={suffixTestId('purchasePrice', props)}
      />
      <NumberInput
        onChange={(value) => setSellingPrice(value?.toString())}
        onBlur={onBlur}
        value={isNotNil(sellingPrice) ? parseInt(sellingPrice) : null}
        label={i18n.t('entity.warehouse.labels.sellingPrice')}
        suffix={currencyName}
        isDisabled={props.isDisabled}
        data-testid={suffixTestId('sellingPrice', props)}
      />
      <NumberInput
        value={totalPrice ?? null}
        label={i18n.t('general.labels.totalCosts')}
        suffix={currencyName}
        tooltip={i18n.t('general.labels.costs')}
        isDisabled
        data-testid={suffixTestId('otherCosts', props)}
      />
      <TextInput
        onChange={(value) => setComment(value ?? undefined)}
        onBlur={onBlur}
        value={comment ?? null}
        label={i18n.t('page.comments.labels.comment')}
        isDisabled={props.isDisabled}
        data-testid={suffixTestId('comment', props)}
      />
    </Grid>
  );
}
