import {Button, Card, GridPhoto, PhotoGrid} from 'platform/components';
import {HStack, Heading, Show, VStack} from 'platform/foundation';
import {Lightbox, useLightbox} from 'platform/lightbox';
import {useDateTimeFormatter} from 'platform/locale';

import {join, isNotEmpty} from 'ramda';

import {GetMetadaServiceCaseApiResponse} from '@dms/api/metadaWorkshopServiceCase';
import i18n from '@dms/i18n';
import {workshopRoutes} from '@dms/routes';

import {parseDate, suffixTestId, composePath, TestIdProps, Nullish} from 'shared';

export interface ServiceCasePhotosCardProps extends TestIdProps {
  serviceCase: GetMetadaServiceCaseApiResponse;
  serviceCasePhotos: GridPhoto[];
  checkinPhotos: GridPhoto[];
  handoverPhotos: GridPhoto[];
  onDownloadAll: VoidFunction;
  onDownloadCheckin: VoidFunction;
  onDownloadHandover: VoidFunction;
}

export function ServiceCasePhotosCard(props: ServiceCasePhotosCardProps) {
  const formatDateTime = useDateTimeFormatter();
  const [serviceCasePhotosLightboxControls, {onOpen: onServiceCasePhotoOpen}] =
    useLightbox('serviceCasePhotos');
  const [checkinPhotosLightboxControls, {onOpen: onCheckinPhotoOpen}] =
    useLightbox('checkinPhotos');
  const [handoverPhotosLightboxControls, {onOpen: onHandoverPhotoOpen}] =
    useLightbox('handoverPhotos');

  return (
    <Card
      title={join(' - ', [
        props.serviceCase?.createdAt &&
          formatDateTime('dateShort', parseDate(props.serviceCase?.createdAt)),
        props.serviceCase?.number,
      ])}
      variant="inlineWhite"
      isExpandable
      isClosedByDefault
      actions={[
        {
          type: 'button',
          variant: 'link',
          title: i18n.t('entity.workshop.actions.openServiceCase'),
          leftIcon: 'action/launch',
          onClick: () =>
            window.open(
              composePath(workshopRoutes.serviceCaseDetail, {
                params: {
                  id: props.serviceCase?.id,
                },
              }),
              '_blank'
            ),
        },
      ]}
      data-testid={props['data-testid']}
    >
      <VStack spacing={4}>
        <Show when={isNotEmpty(props.serviceCasePhotos)}>
          <HStack justify="space-between">
            <Heading size={4} alternative>
              {i18n.t('entity.photo.labels.photos')}
            </Heading>
            <Button
              data-testid={suffixTestId('serviceCasePhotos-downloadAll', props)}
              variant="link"
              title={i18n.t('general.actions.downloadAll')}
              leftIcon="file/download"
              onClick={props.onDownloadAll}
              size="small"
            />
          </HStack>
          <PhotoGrid
            images={props.serviceCasePhotos}
            onSlideClick={(_, index) => onServiceCasePhotoOpen(index)}
            data-testid={suffixTestId('serviceCasePhotos', props)}
          />
          <Lightbox
            data-testid={suffixTestId('serviceCasePhotos', props)}
            controls={serviceCasePhotosLightboxControls}
            images={makeLightboxPhotos(props.serviceCasePhotos)}
          />
        </Show>
        <Show when={isNotEmpty(props.checkinPhotos)}>
          <HStack justify="space-between">
            <Heading size={4} alternative>
              {i18n.t('entity.workshop.labels.checkIn')}
            </Heading>
            <Button
              data-testid={suffixTestId('serviceCasePhotos-checkin-downloadAll', props)}
              variant="link"
              title={i18n.t('general.actions.downloadAll')}
              leftIcon="file/download"
              onClick={props.onDownloadCheckin}
              size="small"
            />
          </HStack>
          <PhotoGrid
            images={props.checkinPhotos}
            onSlideClick={(_, index) => onCheckinPhotoOpen(index)}
            data-testid={suffixTestId('checkinPhotos', props)}
          />
          <Lightbox
            data-testid={suffixTestId('checkinPhotos', props)}
            controls={checkinPhotosLightboxControls}
            images={makeLightboxPhotos(props.checkinPhotos)}
          />
        </Show>
        <Show when={isNotEmpty(props.handoverPhotos)}>
          <HStack justify="space-between">
            <Heading size={4} alternative>
              {i18n.t('entity.workshop.labels.handover')}
            </Heading>
            <Button
              data-testid={suffixTestId('serviceCasePhotos-handover-downloadAll', props)}
              variant="link"
              title={i18n.t('general.actions.downloadAll')}
              leftIcon="file/download"
              onClick={props.onDownloadHandover}
              size="small"
            />
          </HStack>
          <PhotoGrid
            images={props.handoverPhotos}
            onSlideClick={(_, index) => onHandoverPhotoOpen(index)}
            data-testid={suffixTestId('handoverPhotos', props)}
          />
          <Lightbox
            data-testid={suffixTestId('handoverPhotos', props)}
            controls={handoverPhotosLightboxControls}
            images={makeLightboxPhotos(props.handoverPhotos)}
          />
        </Show>
      </VStack>
    </Card>
  );
}

const makeLightboxPhotos = (photos: GridPhoto[] | Nullish) =>
  photos?.map((image) => ({
    id: image.id,
    url: image.originalUrl?.replace('resize', 'get') ?? image.url.replace('resize', 'get'),
    resizeUrl: image.resizeUrl,
    title: image.title,
    fileName: image.title || image.id ? `${image.title || image.id}.jpg` : undefined,
  }));
