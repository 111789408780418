import {Checkbox, IconButton, TableRow} from 'platform/components';
import {Box, HStack, Integer, Show, Text, ThemeColorPath} from 'platform/foundation';

import {MouseEvent} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';

import {indexBy, isNil} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty, isTrue} from 'ramda-adjunct';

import {useGetRoleQuery} from '@dms/api/accessControl';
import {PermissionScopeResponseBody, ProtectedGroupResponseBody} from '@dms/api/accessControlList';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

import {
  Nullish,
  suffixTestId,
  TestIdProps,
  useQueryState,
  useRequiredParams,
  useToggle,
} from 'shared';

import {NEXT_LINE_OFFSET} from '../utils/consts';
import {ActionItem} from './ActionItem';

interface ChildProtectedUnit {
  value: boolean | Nullish;
  scopes: PermissionScopeResponseBody[] | Nullish;
  id: string;
}

interface ResourceItemProps extends TestIdProps {
  data: ProtectedGroupResponseBody;
  level?: number;
}

export function ResourceItem(props: ResourceItemProps) {
  const {roleId} = useRequiredParams();
  const [mode] = useQueryState('mode');

  const {data: role} = useGetRoleQuery({roleId});
  const isSystem = role?.system ?? false;

  const {setValue, control, formState} = useFormContext();

  const childProtectedUnitsNames = getChildProtectedUnitsNames(props.data);
  const childProtectedUnits: ChildProtectedUnit[] = useWatch({
    control,
    name: childProtectedUnitsNames,
  });

  const indexedChildProtectedUnits = indexBy<ChildProtectedUnit>((item) => item.id)(
    childProtectedUnits
  );

  const isChecked = childProtectedUnits.every((item) => isTrue(item?.value));
  const isIndeterminate = !isChecked && childProtectedUnits.some((item) => isTrue(item?.value));

  const [isOpen, toggleOpen] = useToggle(isNil(mode));

  const level = props.level ?? 0;
  const nextLevel = level + 1;
  const hasChildren = isNotNilOrEmpty(props.data.children);
  const hasProtectedUnits = isNotNilOrEmpty(props.data.protectedUnits);

  const handleToggleButton = (e: MouseEvent) => {
    e.stopPropagation();
    toggleOpen();
  };

  const handleGrantGroup = (value: boolean) => {
    childProtectedUnitsNames.forEach((name) => setValue(`${name}.value`, value));
  };

  const rowColor: ThemeColorPath | undefined =
    isChecked || isIndeterminate ? undefined : 'palettes.red.10.100';

  return (
    <>
      <TableRow
        actions={{primary: []}}
        data-testid={suffixTestId('resourceRow', props)}
        color={rowColor}
      >
        <Box paddingHorizontal={2} paddingLeft={(1 + level * NEXT_LINE_OFFSET) as Integer}>
          <HStack align="center" spacing={8}>
            <Show when={hasChildren || hasProtectedUnits}>
              <IconButton
                onClick={handleToggleButton}
                icon={isOpen ? 'hardware/keyboard_arrow_up' : 'hardware/keyboard_arrow_down'}
                data-testid={suffixTestId('resourceToggle', props)}
              />
              <Checkbox
                isDisabled={isSystem || formState.isSubmitting}
                value={isChecked}
                isIndeterminate={isIndeterminate}
                onChange={handleGrantGroup}
                data-testid={suffixTestId('grant', props)}
              />
            </Show>
            <Text size="small" alternative>
              {props.data.name}
            </Text>
          </HStack>
        </Box>
        <Box>
          <Text size="small">{i18n.t(`acl.resource.${props.data.id.toLowerCase()}`)}</Text>
        </Box>
        <Box />
      </TableRow>

      <Show when={isOpen}>
        <Show when={hasChildren}>
          {props.data.children.map((children) => (
            <ResourceItem
              data={children}
              level={nextLevel}
              data-testid={testIds.settings.roleManagementDetail('aclTable')}
              key={children.id}
            />
          ))}
        </Show>

        <Show when={hasProtectedUnits}>
          {props.data.protectedUnits.map((protectedUnit) => {
            const actionState = indexedChildProtectedUnits[protectedUnit.id];

            return (
              <ActionItem
                protectedUnit={protectedUnit}
                resource={props.data}
                level={nextLevel}
                actionState={actionState}
                data-testid={testIds.settings.roleManagementDetail('aclTable')}
                key={`${props.data.id}-${protectedUnit.id}`}
              />
            );
          })}
        </Show>
      </Show>
    </>
  );
}

const getChildProtectedUnitsNames = (resource: ProtectedGroupResponseBody): string[] => {
  if (isNilOrEmpty(resource.children)) {
    return resource.protectedUnits.map(({id}) => `protectedUnits.${id}`);
  }

  return resource.children.reduce(
    (acc, child: ProtectedGroupResponseBody) => [...acc, ...getChildProtectedUnitsNames(child)],
    resource.protectedUnits.map(({id}) => `protectedUnits.${id}`)
  );
};
