import {Checkbox, Flag, Tooltip} from 'platform/components';
import {
  Image,
  Box,
  Clickable,
  Show,
  Spinner,
  ClickableProps,
  Icon,
  Hide,
  HStack,
} from 'platform/foundation';

import {MouseEvent} from 'react';

import {pickAll} from 'ramda';

import {FileOperationStateHttpBody, VehiclePhotoCategory} from '@dms/api/shared';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {Sortable} from './dragndrop/Sortable';

interface AlbumImageProps extends Omit<ClickableProps, 'children'>, TestIdProps {
  id: string;
  src: string;
  category?: VehiclePhotoCategory | null;
  isLoading?: boolean;
  isSelected?: boolean;
  isSelectable?: boolean;
  isCoverPhoto?: boolean;
  isPromoPhoto?: boolean;
  isGhost?: boolean;
  isDisabledDndDrop?: boolean;
  isDisabledDndDrag?: boolean;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  isProcessing?: {
    message: string;
    state: FileOperationStateHttpBody;
  };
}

const getClickableProps = pickAll([
  'isDisabled',
  'onClick',
  'onMouseWheelClick',
  'onContextMenu',
  'onMouseEnter',
  'onMouseLeave',
  'role',
]);

export function AlbumImage(props: AlbumImageProps) {
  const clickableProps = getClickableProps(props) satisfies ClickableProps;
  const isSelected = (props.isSelectable && props.isSelected) ?? false;

  return (
    <Sortable
      id={props.id}
      disabledDrag={props.isDisabledDndDrag}
      disabledDrop={props.isDisabledDndDrop}
      withHandle={props.isSelectable}
    >
      {({isDragging, handleProps}) => (
        <Clickable
          data-testid={suffixTestId('albumImage', props)}
          {...clickableProps}
          onClick={(event) => {
            event.preventDefault();
            props.onClick?.(event);
          }}
          onContextMenu={(event) => {
            event.preventDefault();
            props.onContextMenu?.(event);
          }}
          isDisabled={props.isDisabled || props.isLoading}
        >
          <Box position="relative">
            <Show when={props.isSelectable}>
              <Box position="absolute" zIndex="OVERLAY" paddingVertical={2} paddingHorizontal={1}>
                <div {...handleProps}>
                  <Icon value="action/drag_indicator" color="general.white" />
                </div>
              </Box>
            </Show>
            <Box
              ratio="4 / 3"
              width="100%"
              overflow="hidden"
              position="relative"
              borderColor={isSelected ? 'palettes.blue.60.100' : 'general.transparent'}
              border="3px solid"
              borderRadius="small"
              opacity={isDragging || props.isGhost ? 0.5 : 1}
            >
              <Image
                data-testid={suffixTestId('albumImage', props)}
                width="100%"
                draggable={false}
                borderRadius={isSelected ? undefined : 'small'}
                height="100%"
                src={props.src}
                fit="cover"
                hasSpinner
              />
              <Show when={props.isLoading}>
                <Spinner data-testid={suffixTestId('albumImage', props)} variant="overlay" />
              </Show>
              <Show when={props.isProcessing && props.isProcessing?.state !== 'SUCCESS'}>
                <Box position="absolute" top={0} left={0} padding={2}>
                  <Tooltip label={props.isProcessing?.message}>
                    <Hide when={props.isProcessing?.state === 'FAILED'}>
                      <Icon value="action/watch_later" color="severity.informational" />
                    </Hide>
                    <Show when={props.isProcessing?.state === 'FAILED'}>
                      <Icon value="alert/warning" color="severity.danger" />
                    </Show>
                  </Tooltip>
                </Box>
              </Show>
              <Show when={props.isPromoPhoto || props.isCoverPhoto || props.category}>
                <Box position="absolute" top={0} right={0} padding={2}>
                  <HStack spacing={1}>
                    <Show when={props.isPromoPhoto}>
                      <Flag
                        data-testid={suffixTestId('isPromoPhoto-flag', props)}
                        label={i18n.t('entity.photo.labels.promoPhoto')}
                        colorScheme="black"
                        isSubtle
                        size="small"
                      />
                    </Show>
                    <Show when={props.isCoverPhoto}>
                      <Flag
                        data-testid={suffixTestId('isCoverPhoto-flag', props)}
                        label={i18n.t('entity.photo.labels.coverPhoto')}
                        colorScheme="blue"
                        isSubtle
                        size="small"
                      />
                    </Show>
                    <Show when={props.category}>
                      <Flag
                        data-testid={suffixTestId('category-flag', props)}
                        label={
                          props.category === 'INTERIOR'
                            ? i18n.t('entity.photo.labels.category.interior')
                            : i18n.t('entity.photo.labels.category.exterior')
                        }
                        colorScheme={props.category === 'INTERIOR' ? 'orange' : 'black'}
                        size="small"
                      />
                    </Show>
                  </HStack>
                </Box>
              </Show>
              <Show when={props.isSelectable && !props.isLoading && !props.isDisabled}>
                <Box position="absolute" bottom={0} right={0} padding={2}>
                  <Checkbox value={isSelected} data-testid={suffixTestId('albumImage', props)} />
                </Box>
              </Show>
            </Box>
          </Box>
        </Clickable>
      )}
    </Sortable>
  );
}
