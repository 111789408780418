import {isEmpty, isNil} from 'ramda';

import {PermissionScopeResponseBody} from '@dms/api/accessControlList';

import {Nullish} from 'shared';

const SCOPE_SEPARATOR = '; ';
const VALUE_SEPARATOR = ', ';

export const createScopeString = (scopes: PermissionScopeResponseBody[] | Nullish) => {
  if (isNil(scopes) || isEmpty(scopes)) {
    return null;
  }

  return scopes
    .map(({name, values}) => `${name} = ${values.map((val) => val.name).join(VALUE_SEPARATOR)}`)
    .join(SCOPE_SEPARATOR);
};
