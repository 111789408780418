import {isEmpty, sortBy} from 'ramda';

import {useGetListPreAccountingProcessingSettingsQuery} from '@dms/api/accounting';
import {useAccItemCodeServiceGetValidCodesQuery} from '@dms/api/metadaAccounting';

import {Nullish} from 'shared';

import {useGetCurrentBranch} from './useGetCurrentBranch';

export const useAccProductItemCodesOptions = (
  defaultItemCode: string | Nullish,
  currentlyValid = false
) => {
  const {
    data: productItemCodes,
    isLoading: isLoadingProductItemCodes,
    isError: isProductItemCodesError,
  } = useAccItemCodeServiceGetValidCodesQuery(undefined);

  const {
    data: preAccountingProcessingSettings,
    isLoading: isLoadingPreAccountingProcessingSettings,
    isError: isErrorPreAccountingProcessingSettings,
  } = useGetListPreAccountingProcessingSettingsQuery();

  const {activeBranchId} = useGetCurrentBranch();

  const systemSetting = preAccountingProcessingSettings?.find((settings) => settings.isSystem);

  const productItemCodesOptions = sortBy((item) => item?.itemCode || '', productItemCodes || [])
    .filter((item) => (currentlyValid ? item?.currentlyValid : true))
    .map((item) => ({
      label: `${item?.itemCode} - ${item?.itemCodeName}`,
      value: item?.itemCode ?? '',
    }));

  const defaultItemCodeOptions = [
    {
      label: defaultItemCode,
      value: defaultItemCode,
    },
  ];

  const props = {
    isLoadingProductItemCodes:
      isLoadingProductItemCodes || isLoadingPreAccountingProcessingSettings,
    isProductItemCodesError: isErrorPreAccountingProcessingSettings || isProductItemCodesError,
    shouldShowItemCodesChoice: (
      preAccountingProcessingSettings?.find((item) => item.branchId === activeBranchId) ||
      systemSetting
    )?.notifyAccountingDocuments,
  };

  if (isEmpty(productItemCodes) || !productItemCodes) {
    return {
      productItemCodesOptions: defaultItemCodeOptions,
      ...props,
    };
  }

  return {
    productItemCodesOptions:
      productItemCodesOptions.some((option) => option.value === defaultItemCode) || !defaultItemCode
        ? productItemCodesOptions
        : [...defaultItemCodeOptions, ...productItemCodesOptions],
    ...props,
  };
};
