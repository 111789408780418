import {Button, closeCurrentDialog, Form, FormField} from 'platform/components';
import {Box, Heading, HStack, Icon, Link, Text, VStack} from 'platform/foundation';
import {useLocale} from 'platform/locale';
import {z} from 'zod';

import {UseFormReturn} from 'react-hook-form';
import {Trans} from 'react-i18next';

import {isNil, isNotNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {
  BusinessCaseResponseBody,
  useLazyGetBusinessCaseQuery,
  useSendBusinessCaseOnlineOfferCustomerMessageMutation,
  useSendBusinessCaseOnlineOfferMutation,
} from '@dms/api/businessCase';
import {getWorkspaceFromUri, urlBuilder} from '@dms/api/core';
import {EmailResponseBody, useGetCustomerV2Query} from '@dms/api/customer';
import {environment} from '@dms/environment';
import i18n from '@dms/i18n';
import {vehiclesRoutes} from '@dms/routes';

import {buildArray, composePath, Nullish} from 'shared';

import {createSearchParamsString} from '../../utils/createSearchParamsString';
import {getNaturalPersonFullName} from '../../utils/getNaturalPersonFullName';
import {handleApiError} from '../../utils/handleApiError';

type SendEmailOnlineOfferDialogBaseProps = {
  shouldCreateOffer: boolean;
  businessCaseData: BusinessCaseResponseBody;
  vehicleId: string;
  sellerId: string;
  photoId: string | Nullish;
};

type OfferRequiredProps = SendEmailOnlineOfferDialogBaseProps & {
  shouldCreateOffer: false;
  onlineOfferUri: string;
  sellerEmail?: never;
};

type OfferNotRequiredProps = SendEmailOnlineOfferDialogBaseProps & {
  shouldCreateOffer: true;
  onlineOfferUri?: never;
  sellerEmail: string;
};

const schema = z.object({
  contactPerson: z.string(),
  emailAddress: z.string(),
});

type SchemaType = z.infer<typeof schema>;

type SendEmailOnlineOfferDialogProps = OfferRequiredProps | OfferNotRequiredProps;

const {workspace} = getWorkspaceFromUri();

export function SendEmailOnlineOfferDialog(props: SendEmailOnlineOfferDialogProps) {
  const {language} = useLocale();
  const [sendEmailOnlineOffer, {isLoading: isSendEmailOnlineOfferMutationLoading}] =
    useSendBusinessCaseOnlineOfferCustomerMessageMutation();
  const [getBusinessCase, {isLoading: isGetBusinessCaseQueryLoading}] =
    useLazyGetBusinessCaseQuery();
  const [createOnlineOffer, {isLoading: isCreateOnlineOfferMutationLoading}] =
    useSendBusinessCaseOnlineOfferMutation();
  const {data: customerData} = useGetCustomerV2Query({
    customerId: props.businessCaseData.customerId,
  });

  const isLoading =
    isSendEmailOnlineOfferMutationLoading ||
    isGetBusinessCaseQueryLoading ||
    isCreateOnlineOfferMutationLoading;

  const contactPersonsOptions = buildArray<{
    label: string;
    value: string;
    emails: EmailResponseBody[];
  }>()
    .when(
      isNotNilOrEmpty(customerData?.contacts),
      ...(customerData?.contacts || []).map((contact) => ({
        label: getNaturalPersonFullName(contact) ?? '',
        value: contact.id ?? '',
        emails: contact.emails ?? [],
      }))
    )
    .when(isNotNil(customerData?.foundingPerson), {
      label: getNaturalPersonFullName(customerData?.foundingPerson) ?? '',
      value: customerData?.foundingPerson?.id ?? '',
      emails: customerData?.foundingPerson?.emails ?? [],
    });

  const firstContactPersonValue = contactPersonsOptions[0]?.value ?? undefined;
  const firstEmailValue = contactPersonsOptions[0]?.emails[0]?.email ?? undefined;

  const onSubmit = (values: SchemaType) => {
    const sendEmailData = {
      businessCaseId: props.businessCaseData.id,
      vehicleId: props.vehicleId,
      photoId: props.photoId || null,
      sellerId: props.sellerId,
      customerEmailAddress: values.emailAddress,
    };
    if (props.shouldCreateOffer) {
      return createOnlineOffer({businessCaseId: props.businessCaseData.id})
        .unwrap()
        .then(() =>
          getBusinessCase({businessCaseId: props.businessCaseData.id})
            .unwrap()
            .then((newBcData) => {
              if (!newBcData?.offerId) {
                closeCurrentDialog();
              }
              const onlineOfferUri = urlBuilder({
                workspace,
                protocol: window.location.protocol,
                domain: environment.ONLINE_OFFER_DOMAIN,
                route: `/${newBcData.offerId}`,
                searchParam: createSearchParamsString({
                  lang: language,
                  sellerEmail: props.sellerEmail,
                }),
              });
              return sendEmailOnlineOffer({
                createOnlineOfferCustomerMessageRequestBody: {...sendEmailData, onlineOfferUri},
              })
                .unwrap()
                .then(closeCurrentDialog)
                .catch(handleApiError);
            })
            .catch(handleApiError)
        )
        .catch(handleApiError);
    } else {
      return sendEmailOnlineOffer({
        createOnlineOfferCustomerMessageRequestBody: {
          ...sendEmailData,
          onlineOfferUri: props.onlineOfferUri,
        },
      })
        .unwrap()
        .then(closeCurrentDialog)
        .catch(handleApiError);
    }
  };

  const handleSelectContactPerson = (
    selectedContactPersonId: string | number | null,
    formApi: UseFormReturn<SchemaType>
  ) => {
    if (typeof selectedContactPersonId !== 'string') {
      // @ts-ignore is needed because default value can be undefined but not in validation schema.
      formApi.setValue('emailAddress', undefined);
      return;
    }
    const selectedContact = contactPersonsOptions.find(
      (contact) => contact.value === selectedContactPersonId
    );
    if (!selectedContact || !selectedContact.emails[0]?.email) {
      return;
    }
    formApi.setValue('emailAddress', selectedContact.emails[0]?.email, {shouldValidate: true});
  };

  return (
    <Box paddingVertical={4}>
      <Form
        experimentalZodSchema={schema}
        defaultValues={{
          contactPerson: firstContactPersonValue,
          emailAddress: firstEmailValue,
        }}
        onSubmit={onSubmit}
      >
        {(control, formApi) => {
          const contactPersonValue = formApi.watch('contactPerson');
          const selectedContact = contactPersonsOptions.find(
            (contact) => contact.value === contactPersonValue
          );
          const emailAddressesFromSelectedContact =
            selectedContact?.emails?.map((email) => ({
              label: email.email,
              value: email.email,
            })) ?? [];
          return (
            <VStack spacing={4} align="center">
              <VStack maxWidth={140} spacing={4} align="center">
                <Icon value="communication/email_upload" size={20} isNotFilled />
                <Heading size={3}>
                  {i18n.t('entity.sendEmailOnlineOfferDialog.innerHeading')}
                </Heading>
                <Text color="secondary" align="center">
                  <Trans
                    i18nKey="entity.sendEmailOnlineOfferDialog.description"
                    components={{
                      vehiclelink: (
                        <Link
                          href={composePath(vehiclesRoutes.photos, {
                            params: {id: props.vehicleId, tab: 'vehicle-photos'},
                          })}
                          target="_blank"
                        />
                      ),
                    }}
                  />
                </Text>
              </VStack>
              <Box width="100%">
                <HStack width="100%" spacing={4}>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      name="contactPerson"
                      type="choice"
                      label={i18n.t('entity.sendEmailOnlineOfferDialog.contactPersonLabel')}
                      options={contactPersonsOptions}
                      onChange={(value) => handleSelectContactPerson(value, formApi)}
                      isRequired
                    />
                  </Box>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      name="emailAddress"
                      type="choice"
                      options={emailAddressesFromSelectedContact}
                      label={i18n.t('entity.sendEmailOnlineOfferDialog.emailAddressLabel')}
                      isDisabled={isNil(selectedContact)}
                      isRequired
                    />
                  </Box>
                </HStack>
              </Box>
              <Box paddingTop={4} width="100%">
                <HStack spacing={2} width="100%" justify="flex-end">
                  <Button
                    variant="secondary"
                    title={i18n.t('general.actions.cancel')}
                    isDisabled={isLoading}
                    onClick={closeCurrentDialog}
                  />
                  <Button
                    variant="primary"
                    title={i18n.t('general.actions.send')}
                    type="submit"
                    isLoading={isLoading}
                  />
                </HStack>
              </Box>
            </VStack>
          );
        }}
      </Form>
    </Box>
  );
}
