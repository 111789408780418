import {z} from 'zod';

const MIN_VALUE = 100;
const MAX_VALUE = 999;

export const WarehouseAccountFormSchema = z.object({
  name: z.string().min(1),
  syntheticNumber: z.number().min(MIN_VALUE).max(MAX_VALUE),
  analyticalNumber: z.number().min(MIN_VALUE).max(MAX_VALUE),
  salesBasePriceMarkup: z.number().min(0),
  warrantyPriceMarkup: z.number().min(0),
  operationId: z.string().optional(),
});

export type WarehouseAccountFormType = z.infer<typeof WarehouseAccountFormSchema>;
