import {
  Button,
  ButtonGroup,
  closeDialog,
  Form,
  FormField,
  HelperText,
  IconButton,
  isFile,
  Label,
  Upload,
} from 'platform/components';
import {Box, Heading, HStack, Icon, Link, Show, Space, Text, VStack} from 'platform/foundation';
import {z} from 'zod';

import {useState} from 'react';

import {
  useDeleteTaskFileMutation,
  useUpdateTaskFileMutation,
  useUpdateTaskNoteMutation,
} from '@dms/api/checklist';
import {useUploadFileMutation} from '@dms/api/core';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

import {openFile} from 'shared';

import {getFileIcon} from '../../../utils/getFileIcon';
import {handleApiError} from '../../../utils/handleApiError';
import {TaskCompletedImage} from './TaskCompletedImage';

const updateTaskSchema = z.object({
  note: z.string().optional(),
  toSaveFileId: z.string().optional(),
  toDeleteFileId: z.string().optional(),
});

type UpdateTaskFormValue = z.infer<typeof updateTaskSchema>;

interface EditTaskFile {
  id: string;
  name: string;
  contentType: string;
  url: string;
}

interface EditTaskDialogProps {
  dialogId: string;
  taskName: string;
  recordId: string;
  resourceId: string;
  checklistId: string;
  taskId: string;
  note: string;
  file?: EditTaskFile;
  highlightFileUpload?: boolean;
}

export function EditTaskDialog(props: EditTaskDialogProps) {
  const [uploadFile, uploadFileQuery] = useUploadFileMutation();

  const [attachNote, attachNoteQuery] = useUpdateTaskNoteMutation();
  const [attachFile, attachFileQuery] = useUpdateTaskFileMutation();
  const [deleteFile, deleteFileQuery] = useDeleteTaskFileMutation();

  const isLoading =
    attachNoteQuery.isLoading || attachFileQuery.isLoading || deleteFileQuery.isLoading;

  const [file, setFile] = useState<EditTaskFile | undefined>(props.file);

  const handleSubmit = async (formData: UpdateTaskFormValue) => {
    const promises = [];

    promises.push(
      attachNote({
        resourceId: props.resourceId,
        recordId: props.recordId,
        checklistId: props.checklistId,
        taskId: props.taskId,
        body: {
          note: formData.note || '',
        },
      }).unwrap()
    );

    if (formData.toSaveFileId) {
      promises.push(
        attachFile({
          resourceId: props.resourceId,
          recordId: props.recordId,
          checklistId: props.checklistId,
          taskId: props.taskId,
          body: {
            fileId: formData.toSaveFileId,
          },
        }).unwrap()
      );
    }

    if (formData.toDeleteFileId) {
      promises.push(
        deleteFile({
          resourceId: props.resourceId,
          recordId: props.recordId,
          checklistId: props.checklistId,
          taskId: props.taskId,
        }).unwrap()
      );
    }

    const results = await Promise.allSettled(promises);

    results.forEach((result) => {
      if (result.status === 'rejected') {
        handleApiError(result.reason);
      }
    });

    return closeDialog(props.dialogId);
  };

  return (
    <Form<UpdateTaskFormValue>
      values={{
        note: props.note || '',
        toSaveFileId: '',
        toDeleteFileId: '',
      }}
      onSubmit={handleSubmit}
      experimentalZodSchema={updateTaskSchema}
    >
      {(control, formApi) => (
        <VStack spacing={4} width="100%">
          <Box padding={6}>
            <VStack spacing={4} align="center" width="100%">
              <TaskCompletedImage />
              <Heading size={2}>{props.taskName}</Heading>
              <Box maxWidth={165}>
                <Text color="secondary" size="small" align="center">
                  {i18n.t('entity.checklist.labels.editTaskDescription')}
                </Text>
              </Box>
              <Box width="100%">
                <FormField
                  data-testid={testIds.vehicles.checklists('note')}
                  control={control}
                  name="note"
                  type="textarea"
                  label={i18n.t('general.labels.note')}
                  isResizable
                />
              </Box>
              <Show when={file}>
                <HStack align="center" spacing={3} width="100%">
                  <Icon value={getFileIcon(file?.contentType)} size={8} />
                  <Link
                    data-testid={testIds.vehicles.checklists('document')}
                    size="small"
                    title={file?.name}
                    onClick={() => openFile(file?.url || '')}
                  />
                  <Space fillAvailable />
                  <IconButton
                    severity="danger"
                    icon="action/delete"
                    isDisabled={deleteFileQuery.isLoading}
                    onClick={() => {
                      if (!file) {
                        return;
                      }

                      formApi.setValue('toDeleteFileId', file.id);
                      setFile(undefined);
                    }}
                    size="small"
                  />
                </HStack>
              </Show>
              <Show when={!file}>
                <Box width="100%">
                  <Label isRequired>{i18n.t('entity.document.actions.uploadDocument')}</Label>
                  <Upload
                    data-testid={testIds.vehicles.checklists('uploadDocument')}
                    type="card"
                    size="minHeight"
                    customRequest={async ({file, onSuccess, onError}) => {
                      if (!isFile(file)) {
                        return;
                      }

                      await uploadFile({file})
                        .unwrap()
                        .then((data) => {
                          setFile({
                            id: data.fileId,
                            name: data.file.name,
                            contentType: data.file.type,
                            url: data.fileUri,
                          });

                          formApi.setValue('toSaveFileId', data.fileId);
                          onSuccess?.(data);
                        })
                        .catch((err: Error) => {
                          handleApiError(err);
                          onError?.(err);
                        });
                    }}
                  />
                  <HelperText
                    errorMessage={
                      props.highlightFileUpload ? i18n.t('errors.field_is_required') : undefined
                    }
                  />
                </Box>
              </Show>
            </VStack>
          </Box>
          <ButtonGroup align="right">
            <Button
              variant="secondary"
              title={i18n.t('general.labels.discard')}
              onClick={() => closeDialog(props.dialogId)}
              isDisabled={isLoading}
              data-testid={testIds.vehicles.checklists('discard')}
            />
            <Button
              data-testid={testIds.vehicles.checklists('submit')}
              title={i18n.t('general.actions.save')}
              type="submit"
              isLoading={isLoading}
              isDisabled={uploadFileQuery.isLoading}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}
