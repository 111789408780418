import {
  FormControl,
  FormField,
  isFile,
  showNotification,
  Upload,
  UploadState,
} from 'platform/components';
import {Show, Hide, HStack, Icon, Space, Link} from 'platform/foundation';

import {useState} from 'react';
import {FieldPathByValue, FieldValues, Path, PathValue, useFormContext} from 'react-hook-form';

import {useUploadFileMutation} from '@dms/api/core';
import i18n from '@dms/i18n';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {handleApiError} from '../../../utils/handleApiError';

type FieldValuesWithFileId = FieldValues & {fileId: string | null};

interface DocumentUploadFormProps<T extends FieldValuesWithFileId> extends RequiredTestIdProps {
  onSuccessfulUpload?: (fileId: string) => void;
  control: FormControl<T>;
  name: FieldPathByValue<T, string | number | Nullish>;
}

export function DocumentUploadForm<T extends FieldValuesWithFileId = FieldValuesWithFileId>(
  props: DocumentUploadFormProps<T>
) {
  const {setValue} = useFormContext<T>();

  const [uploadFile, {isLoading}] = useUploadFileMutation();

  const [file, setFile] = useState<{
    fileName: string;
    fileUri: string;
    fileId: string;
  } | null>(null);

  return (
    <>
      <FormField
        type="hidden"
        name={props.name}
        control={props.control}
        data-testid={props['data-testid']}
      />
      <Show when={file?.fileId}>
        <HStack align="center" spacing={3}>
          <Icon value="files_default/file_type_PDF" size={8} />
          <Link
            size="base"
            href={file?.fileUri}
            target="_blank"
            title={file?.fileName}
            data-testid={suffixTestId('link', props)}
          />
          <Space fillAvailable />
          <Icon
            onClick={() => {
              setFile(null);
              setValue(props.name, null as PathValue<T, Path<T>>);
            }}
            value="action/delete"
            color="severity.danger"
            size={4}
          />
        </HStack>
      </Show>
      <Hide when={file?.fileId}>
        <Upload
          type="button"
          accept="application/pdf"
          buttonVariant="secondary"
          uploadState={isLoading ? UploadState.Uploading : UploadState.Idle}
          errorIcon="navigation/cancel"
          uploadingIcon="action/hourglass_empty"
          uploadIcon="file/upload"
          customRequest={async (file) => {
            if (!isFile(file)) {
              return;
            }
            if (file.type !== 'application/pdf') {
              showNotification.error(i18n.t('entity.inspection.errors.invalidFileType'));
              return;
            }

            await uploadFile({file})
              .unwrap()
              .then((data) => {
                setValue(props.name, data.fileId as PathValue<T, Path<T>>);
                setFile({
                  fileName: data.file.name,
                  fileUri: data.fileUri,
                  fileId: data.fileId,
                });
                props.onSuccessfulUpload?.(data.fileId);
              })
              .catch(handleApiError);
          }}
          data-testid={suffixTestId('upload', props)}
        />
      </Hide>
    </>
  );
}
