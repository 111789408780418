import {HStack, Integer, Show} from 'platform/foundation';
import styled from 'styled-components';

import {useRef} from 'react';

import i18n from '@dms/i18n';

import {composePath, useBoolean} from 'shared';

import {useIsMobileNavigation} from '../hooks/useIsMobileNavigation';
import {MenuItemProps} from '../types/MenuItemProps';
import {Collapse} from './Collapse';
import {CollapseToggle} from './CollapseToggle';
import {MenuItemActionButton} from './MenuItemActionButton';
import {MenuItemContent} from './MenuItemContent';
import {MenuItemLinkOrButton} from './MenuItemLinkOrButton';
import {MenuItemPin} from './MenuItemPin';
import {useMenuItemsState} from './MenuItemsStateProvider';

export function MenuItem(props: MenuItemProps) {
  const {isOpen: isMenuItemOpen, toggle} = useMenuItemsState();
  const isMobileNavigation = useIsMobileNavigation();
  const [isPinVisible, setPinVisible, hidePin] = useBoolean(false);
  const ref = useRef<HTMLDivElement>(null);
  const isGroup = Boolean(props.content && props.content.length > 1);
  const isPinned = props.isPinned || Boolean(props.legacyPinId);
  const isOpen = isMenuItemOpen(props.id);

  const handleToggle = () => {
    toggle(props.id);
  };

  const handleMenuItemClick = () => {
    if (isGroup) {
      return handleToggle();
    }
    return props.onClick();
  };

  const handleAnimationComplete = () => {
    if (ref.current) {
      ref.current.scrollIntoView({block: 'nearest', behavior: 'smooth'});
    }
  };

  return (
    <>
      <StyledMenuItemContainer onMouseEnter={setPinVisible} onMouseLeave={hidePin}>
        <MenuItemLinkOrButton
          isGroup={isGroup}
          to={composePath(props.path, {isSearchParamsPreserved: false})}
          onClick={handleMenuItemClick}
        >
          <MenuItemContent
            depth={props.depth}
            iconSize={props.iconSize}
            title={i18n.t(props.layoutProps.title)}
            icon={props.layoutProps.iconV2 ?? props.layoutProps.icon}
          />
        </MenuItemLinkOrButton>
        <HStack spacing={0}>
          <Show when={(isPinVisible || isPinned || isMobileNavigation) && !isGroup}>
            <MenuItemPin
              iconSize={props.iconSize}
              id={props.id}
              path={props.path}
              isPinned={props.isPinned}
              legacyPinId={props.legacyPinId}
            />
          </Show>
          <Show when={isGroup}>
            <MenuItemActionButton onClick={handleToggle}>
              <CollapseToggle isOpen={isOpen} iconSize={props.iconSize} />
            </MenuItemActionButton>
          </Show>
        </HStack>
      </StyledMenuItemContainer>
      <div ref={ref}>
        <Collapse onAnimationComplete={handleAnimationComplete} isOpen={isOpen && isGroup}>
          {props.content?.map((module) => (
            <MenuItem
              key={module.path}
              iconSize={props.iconSize}
              depth={(props.depth + 1) as Integer}
              onClick={props.onClick}
              {...module}
            />
          ))}
        </Collapse>
      </div>
    </>
  );
}

export const StyledMenuItemContainer = styled.div`
  display: flex;
  max-width: 100%;
  border-radius: ${({theme}) => theme.getSize(1)};
  color: ${({theme}) => theme.colors.text.white};
  &:hover {
    background-color: ${(props) => props.theme.colors.palettes.neutral[500]['100']};
  }
`;
