import {isFeatureEnabled} from 'feature-flags';
import {ColorSchemeType} from 'platform/components';
import {ThemeIconKey} from 'platform/foundation';
import {match} from 'ts-pattern';

import {always, groupBy, map, pipe, sort, toPairs, filter} from 'ramda';

import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';

type Category = {
  label: string;
  icon: ThemeIconKey;
  position: number;
  id: string;
  colorStripe: ColorSchemeType;
};
const categories: Record<string, Category> = {
  sales: {
    id: 'sales',
    label: i18n.t('general.labels.sell'),
    icon: 'action/assessment',
    colorStripe: 'blue',
    position: 1,
  },
  afterSales: {
    id: 'afterSales',
    label: i18n.t('general.labels.service'),
    icon: 'action/build',
    colorStripe: 'purple',
    position: 3,
  },
  accounting: {
    id: 'accounting',
    label: i18n.t('module.accounting.title'),
    icon: 'custom/invoice',
    position: 2,
    colorStripe: 'orange',
  },
  caraudit: {
    id: 'caraudit',
    label: i18n.t('module.CarAudit.title'),
    icon: 'maps/directions_car',
    position: 4,
    colorStripe: 'teal',
  },
  other: {
    id: 'other',
    label: i18n.t('entity.vehicle.labels.otherLabel'),
    icon: 'AV/replay',
    position: 5,
    colorStripe: 'neutral',
  },
};

const categoriesByDocumentKind: Record<string, Category> = {
  business_case: categories.sales,
  vehicle: categories.sales,
  vehicle_warehouse: categories.sales,
  warehouse: categories.afterSales,
  workshop: categories.afterSales,
  pneu_hotel: categories.afterSales,
  cash_register_document: categories.accounting,
  accounting: categories.accounting,
  car_audit: categories.caraudit,
};

export type GetTemplatesApiResponse = TemplateResponseBodyV2[];
export type UserResponseBodyV2 = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
} | null;
export type TemplateResponseBodyV2 = {
  id: string;
  title: string;
  note: string | null;
  primary: boolean;
  system: boolean;
  hidden: boolean;
  url: string;
  fileName: string;
  documentKindCode: string;
  updatedAt: string | null;
  updatedBy: UserResponseBodyV2;
};
export type GetTemplateGroupsApiResponse = TemplateGroupResponseBodyV2[];
export type TemplateGroupResponseBodyV2 = {
  key: string;
  title: string;
};
export type GetDocumentKindsApiResponse = DocumentKindResponseBody[];

export type DocumentKindGroupEnum =
  | 'business_case'
  | 'vehicle'
  | 'cash_register_document'
  | 'accounting'
  | 'car_audit'
  | 'warehouse'
  | 'workshop'
  | 'pneu_hotel'
  | 'vehicle_warehouse';
export type DocumentKindResponseBody = {
  code: string;
  title: string;
  group: DocumentKindGroupEnum;
};

const isDocumentGroupEnabled = (documentGroupKey: string, hasSalesLicense: boolean) =>
  match(documentGroupKey)
    .with('warehouse', () => isFeatureEnabled(featureFlags.WAREHOUSE_DOCUMENT_TEMPLATES))
    .with('workshop', () => isFeatureEnabled(featureFlags.WORKSHOP_DOCUMENT_TEMPLATES))
    .with('pneu_hotel', () => isFeatureEnabled(featureFlags.PNEUHOTEL_DOCUMENT_TEMPLATES))
    .with('business_case', () => hasSalesLicense)
    .with('vehicle_warehouse', () => hasSalesLicense)
    .otherwise(always(true));

export const createDocumentTemplatesStructure = (
  templates: GetTemplatesApiResponse,
  templateGroups: GetTemplateGroupsApiResponse,
  documentKinds: GetDocumentKindsApiResponse,
  hasSalesLicense: boolean
) => {
  const groupedDocumentKinds = groupBy((documentKind) => documentKind.group, documentKinds);
  const templatesByDocumentKind = groupBy((template) => template.documentKindCode, templates);

  return pipe(
    Object.entries<DocumentKindResponseBody[]>,
    filter<[string, DocumentKindResponseBody[]]>(([group]) =>
      isDocumentGroupEnabled(group, hasSalesLicense)
    ),
    map(([group, documentKinds]) => ({
      title:
        templateGroups.find((templateGroup) => templateGroup.key === group)?.title ??
        i18n.t('entity.vehicle.labels.otherLabel'),
      id: group,
      category: categoriesByDocumentKind[group] ?? categories.other,
      documentTemplates: documentKinds.map((documentKind) => ({
        ...documentKind,
        templates: templatesByDocumentKind[documentKind.code]?.sort(sortByIsPrimary) ?? [],
      })),
    })),
    groupBy((group) => group.category.id),
    toPairs,
    sort(([a], [b]) => categories[a].position - categories[b].position),
    map(([categoryId, documentKinds]) => ({
      ...categories[categoryId],
      documentKinds: documentKinds ?? [],
    }))
  )(groupedDocumentKinds);
};

const sortByIsPrimary = (a: TemplateResponseBodyV2, b: TemplateResponseBodyV2) => {
  const aPrimary = a.primary ? 1 : 0;
  const bPrimary = b.primary ? 1 : 0;

  return bPrimary - aPrimary;
};
