import {DataStatus} from 'platform/components';
import {VStack} from 'platform/foundation';

import {useGetBusinessCaseQuery} from '@dms/api/businessCase';
import {useGetSaleVehicleQuery} from '@dms/api/saleVehicle';

import {useRequiredParams} from 'shared';

import {getPurchaseVehicleIdFromBusinessCase} from '../../../../../utils/getPurchaseVehicleIdFromBusinessCase';
import {PricerWidget} from './Pricing/index';
import {ReadyToPriceButton} from './ReadyToPriceButton';
import {RequestedPriceWidget} from './RequestedPrice/index';

export function CentralizedPricingWidget() {
  const {id: businessCaseId} = useRequiredParams();
  const {data: businessCase} = useGetBusinessCaseQuery({businessCaseId});
  const purchaseVehicleId = getPurchaseVehicleIdFromBusinessCase(businessCase);

  const {
    data: saleVehicle,
    isError,
    isLoading,
    refetch,
  } = useGetSaleVehicleQuery({
    vehicleId: purchaseVehicleId ?? '',
  });
  // TODO - T20-39109 - SALE_VEHICLE_STATES - 18
  const BusinessCaseProgressState = saleVehicle?.buyingState;

  return (
    <VStack spacing={4}>
      <DataStatus isLoading={isLoading} isError={isError}>
        <RequestedPriceWidget BusinessCaseProgressState={BusinessCaseProgressState} />
        <ReadyToPriceButton
          BusinessCaseProgressState={BusinessCaseProgressState}
          onAfterSubmit={refetch}
        />
        <PricerWidget />
      </DataStatus>
    </VStack>
  );
}
