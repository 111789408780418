import {getSize} from 'platform/foundation';
import styled from 'styled-components';

import {TOP_BAR_ITEM_SIZE} from '../constants/topBarItemSize';

export const TopBarActionContainer = styled.div`
  height: ${getSize(TOP_BAR_ITEM_SIZE)};
  min-width: ${getSize(TOP_BAR_ITEM_SIZE)};
  cursor: pointer;
  &:hover {
    button[aria-label*='topBar'] {
      background-color: ${({theme}) => theme.colors.palettes.neutral[30][100]};
    }
  }
  &:active {
    button[aria-label*='topBar'] {
      background-color: ${({theme}) => theme.colors.palettes.neutral[40][100]};
    }
  }
`;
