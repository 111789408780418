import {DataStatus} from 'platform/components';
import {Grid, Show, VStack} from 'platform/foundation';

import {isNilOrEmpty, isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {useGetServiceCheckInQuery} from '@dms/api/metadaWorkshopCheckin';
import {useGetServiceHandOverQuery} from '@dms/api/metadaWorkshopHandover';
import {useGetServiceCaseServiceRepairApiQuery} from '@dms/api/metadaWorkshopServiceCase';
import {useGetServiceCaseScheduleRepairQuery} from '@dms/api/metadaWorkshopSheduleRepair';
import {useGetParticipationQuery} from '@dms/api/participation';
import {EntityResourceIds} from '@dms/api/shared';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {EntityCustomFields, Section, usePermissions} from '@dms/shared';

import {Nullish} from 'shared';

import {RepairScheduledCard} from '../../../../components/RepairScheduledCard/RepairScheduledCard';
import {useWorkshopUrl} from '../../../../hooks/useWorkshopUrl';
import {CheckinHandoverCard} from './components/CheckinHandoverCard';
import {ServiceRepairCard} from './components/ServiceRepairCard';

export function Overview() {
  const {serviceCaseId} = useWorkshopUrl();

  const {data: interestParticipation} = useGetParticipationQuery({
    recordId: serviceCaseId,
    resourceId: EntityResourceIds.serviceCase,
  });
  const {
    data: checkin,
    isLoading: isCheckinLoading,
    isError: isCheckinError,
  } = useGetServiceCheckInQuery({serviceCaseId});
  const {
    data: handover,
    isLoading: isHandoverLoading,
    isError: isHandoverError,
  } = useGetServiceHandOverQuery({serviceCaseId});
  const {
    data: scheduleRepair,
    isLoading: isScheduleRepairLoading,
    isError: isScheduleRepairError,
  } = useGetServiceCaseScheduleRepairQuery({serviceCaseId}, {refetchOnMountOrArgChange: true});
  const {
    data: serviceRepair,
    isLoading: isServiceRepairLoading,
    isError: isServiceRepairError,
  } = useGetServiceCaseServiceRepairApiQuery({serviceCaseId}, {refetchOnMountOrArgChange: true});

  const handleOpenPlanner = (url?: string | Nullish) => {
    if (isNotNil(url)) {
      window.open(url, '_blank');
    }
  };

  const [canEditCustomFields] = usePermissions({
    permissionKeys: ['serviceCaseCustomFields'],
    scopes: {
      serviceCaseCustomFields: {
        participation: interestParticipation,
        branchId: 'SKIP',
      },
    },
  });

  const isLoading =
    isCheckinLoading || isHandoverLoading || isScheduleRepairLoading || isServiceRepairLoading;
  const isError =
    isCheckinError || isHandoverError || isScheduleRepairError || isServiceRepairError;

  return (
    <Section data-testid={testIds.workshop.serviceCaseDetail('overview')}>
      <DataStatus isLoading={isLoading} isError={isError} minHeight={140}>
        <VStack spacing={4}>
          <CheckinHandoverCard
            checkin={checkin}
            handover={handover}
            data-testid={testIds.workshop.serviceCaseDetail('scheduled')}
          />
          <Show when={isNotNilOrEmpty(scheduleRepair)}>
            <RepairScheduledCard
              data={scheduleRepair}
              cardActions={[
                {
                  type: 'button',
                  title: i18n.t('entity.serviceScheduler.actions.openPlanner'),
                  leftIcon: 'action/open_in_new',
                  onClick: () => handleOpenPlanner(scheduleRepair?.url),
                  variant: 'link',
                  isDisabled: isNilOrEmpty(scheduleRepair?.url),
                },
              ]}
              data-testid={testIds.workshop.serviceCaseDetail('scheduled')}
            />
          </Show>
          <Show when={isNotNilOrEmpty(serviceRepair)}>
            <ServiceRepairCard
              data={serviceRepair}
              cardActions={[
                {
                  type: 'button',
                  title: i18n.t('entity.serviceScheduler.actions.planner'),
                  rightIcon: 'action/open_in_new',
                  onClick: () => handleOpenPlanner(serviceRepair?.repairUrl),
                  variant: 'link',
                  isDisabled: isNilOrEmpty(serviceRepair?.repairUrl),
                },
              ]}
              data-testid={testIds.workshop.serviceCaseDetail('service-repair')}
            />
          </Show>
          <Grid columns={2}>
            <EntityCustomFields
              recordId={serviceCaseId}
              resourceId={EntityResourceIds.serviceCase}
              isEditable={canEditCustomFields}
              data-testid={testIds.workshop.serviceCaseDetail('interest-custom-fields')}
            />
          </Grid>
        </VStack>
      </DataStatus>
    </Section>
  );
}
