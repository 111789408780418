import {z} from 'zod';

import {BigDecimalSchema} from '@dms/api/shared';
import i18n from '@dms/i18n';

const MIN_DAILY_INTEREST_RATE = 0.01;
const MIN_EXPECTED_DAYS_TO_SELL = 1;

export const FinancingFormSchema = z.object({
  defaultDailyInterestRate: BigDecimalSchema.trim()
    .transform((val) => val.replace(',', '.'))
    .pipe(
      z.coerce
        .number({invalid_type_error: i18n.t('general.errors.number.integer')})
        .min(MIN_DAILY_INTEREST_RATE, i18n.t('general.errors.number.positive'))
        .transform(String)
    )
    .nullable(),
  expectedDaysToSell: z
    .number()
    .min(MIN_EXPECTED_DAYS_TO_SELL, i18n.t('general.errors.number.positive'))
    .nullable(),
});

export type FinancingFormSchemaType = z.infer<typeof FinancingFormSchema>;
