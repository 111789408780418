import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragStartEvent,
} from '@dnd-kit/core';
import {differenceInMilliseconds} from 'date-fns';
import {Box, Show} from 'platform/foundation';

import {useState} from 'react';

import {parseDate, pushGtmEvent, suffixTestId} from 'shared';

import {useOptimisticEventManager} from '../hooks/useOptimisticEventManager';
import {dragStore} from '../store/dragStore';
import {BigCalendarProps} from '../types/BigCalendarProps';
import {BigCalendarViewType} from '../types/BigCalendarViewType';
import {calculedResizedEventToBottom} from '../utils/calculedResizedEventToBottom';
import {BigCalendarDragOverlay} from './BigCalendarDragOverlay';
import {DayView} from './DayView';
import {Header} from './Header';
import {MonthView} from './MonthView';
import {WeekView} from './WeekView';

/**
 * A powerful and flexible calendar component that provides comprehensive event management capabilities
 * with support for day, week, and month views. Built with drag-and-drop functionality and optimistic
 * updates for a smooth user experience.
 *
 * @component
 * @param {BigCalendarProps} props - The component configuration props
 * @param {BigCalendarEvent[]} props.events - Array of calendar events to display in the calendar
 * @param {BigCalendarViewType} [props.defaultView='week'] - Initial calendar view type ('day', 'week', or 'month')
 * @param {boolean} [props.isReadOnly=false] - When true, disables drag-and-drop and editing functionality
 * @param {Date} [props.currentDate] - The date to display in the calendar view
 * @param {User} [props.currentUser] - Currently logged in user information
 * @param {User[]} [props.selectedUsers] - Array of selected users for filtering events
 * @param {User[]} [props.users] - Array of all available users
 * @param {(event: BigCalendarEvent) => void} [props.onEventClick] - Handler called when an event is clicked
 * @param {(date: Date) => void} [props.onDateClick] - Handler called when a date cell is clicked
 * @param {(event: BigCalendarEvent, start: Date, end: Date) => void} [props.onEventDragMove] - Handler called when an event is dragged to a new position
 * @param {(date: Date) => void} [props.onNavigate] - Handler called when calendar navigation occurs
 * @param {(changes: UserControlChanges) => void} [props.onUserControlChange] - Handler called when user selection changes
 *
 * @example
 * Basic usage with minimal props:
 * ```tsx
 * <BigCalendar
 *   events={myEvents}
 *   defaultView="week"
 *   currentDate={new Date()}
 *   onEventClick={(event) => handleEventClick(event)}
 * />
 * ```
 *
 * @example
 * Advanced usage with all features enabled:
 * ```tsx
 * <BigCalendar
 *   events={myEvents}
 *   defaultView="week"
 *   currentDate={new Date()}
 *   currentUser={currentUser}
 *   selectedUsers={selectedUsers}
 *   users={allUsers}
 *   isReadOnly={false}
 *   onEventClick={(event) => handleEventClick(event)}
 *   onDateClick={(date) => handleDateClick(date)}
 *   onEventDragMove={async (event, start, end) => {
 *     try {
 *       await updateEventTimes(event.id, start, end);
 *       showSuccessNotification();
 *     } catch (error) {
 *       showErrorNotification();
 *     }
 *   }}
 *   onNavigate={(date) => updateUrlWithNewDate(date)}
 *   onUserControlChange={(changes) => handleUserFiltering(changes)}
 * />
 * ```
 *
 * Key Features:
 * - Multiple calendar views (day, week, month) with easy switching
 * - Drag-and-drop event management with optimistic updates
 * - User filtering and multi-user support
 * - Responsive design that works across device sizes
 * - Read-only mode for view-only scenarios
 * - Customizable event rendering
 * - Accessibility support
 * - Timezone aware
 *
 * Technical Details:
 * - Uses dnd-kit for smooth drag-and-drop functionality
 * - Implements optimistic updates for immediate UI feedback
 * - Maintains internal state for view type, current date, and drag operations
 * - Supports both all-day and timed events
 * - Handles event overlap and collision detection
 * - Provides granular control over event interactions
 *
 * @see {@link https://github.com/clauderic/dnd-kit} - dnd-kit documentation
 * @see {@link BigCalendarEvent} - Event object structure
 * @see {@link BigCalendarViewType} - Available view types
 */
export function BigCalendar(props: BigCalendarProps) {
  const {optimisticEvents, optimisticEventUpdate} = useOptimisticEventManager(props.events);

  const [view, setView] = useState<BigCalendarViewType>(props.defaultView || 'week');

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const handleDragStart = (event: DragStartEvent) => {
    dragStore.startDragging(String(event.active.id));
  };

  const handleDragEnd = (event: DragEndEvent) => {
    if (!event.over || props.isReadOnly) {
      dragStore.resetDragState();
      return;
    }

    const dndEventData = event.active?.data?.current;

    if (dndEventData?.type === 'resize' && dndEventData?.direction === 'bottom') {
      const originalEvent = optimisticEvents.find((event) => event.id === dndEventData?.eventId);

      if (!originalEvent) {
        dragStore.resetDragState();
        return;
      }

      const endDate = calculedResizedEventToBottom({
        start: originalEvent.start,
        end: originalEvent.end,
        overId: event.over.id,
      });

      if (!endDate) {
        dragStore.resetDragState();
        return;
      }

      pushGtmEvent({
        event: 'drag_and_drop',
        event_id: 'big_calendar_event_resize',
        new_value: {
          eventId: originalEvent.id,
          start: originalEvent.start.toISOString(),
          end: endDate.toISOString(),
        },
        previous_value: {
          eventId: originalEvent.id,
          start: originalEvent.start.toISOString(),
          end: originalEvent.end.toISOString(),
        },
      });

      optimisticEventUpdate({
        event: originalEvent,
        start: originalEvent.start,
        end: endDate,
        onEventDragMove: props.onEventDragMove,
      });
    }

    const dropTimestamp = parseInt(String(event.over.id), 10);
    const newStart = parseDate(dropTimestamp);
    const originalEvent = optimisticEvents.find((e) => e.id === event.active.id);

    if (!originalEvent) {
      dragStore.resetDragState();
      return;
    }

    if (originalEvent.isAllDay) {
      pushGtmEvent({
        event: 'drag_and_drop',
        event_id: 'big_calendar_all_day_event_drag_move',
        new_value: {
          eventId: originalEvent.id,
          start: newStart.toISOString(),
          end: newStart.toISOString(),
        },
        previous_value: {
          eventId: originalEvent.id,
          start: originalEvent.start.toISOString(),
          end: originalEvent.end.toISOString(),
        },
      });

      optimisticEventUpdate({
        event: originalEvent,
        start: newStart,
        end: newStart,
        onEventDragMove: props.onEventDragMove,
      });
      return;
    }

    const duration = differenceInMilliseconds(originalEvent.end, originalEvent.start);
    const newEnd = parseDate(newStart.getTime() + duration);

    pushGtmEvent({
      event: 'drag_and_drop',
      event_id: 'big_calendar_event_drag_move',
      new_value: {
        eventId: originalEvent.id,
        start: newStart.toISOString(),
        end: newEnd.toISOString(),
      },
      previous_value: {
        eventId: originalEvent.id,
        start: originalEvent.start.toISOString(),
        end: originalEvent.end.toISOString(),
      },
    });

    optimisticEventUpdate({
      event: originalEvent,
      start: newStart,
      end: newEnd,
      onEventDragMove: props.onEventDragMove,
    });
  };

  return (
    <DndContext sensors={sensors} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
      <Box position="relative" width="100%" height="100%">
        <Box
          position="sticky"
          top={0}
          left={0}
          right={0}
          paddingTop={[1, 3]}
          paddingBottom={[1, 0]}
          minHeight={[10, 15]}
          zIndex="BIG_CALENDAR_HEADER"
          backgroundColor="palettes.neutral.10.100"
        >
          <Header
            data-testid={suffixTestId('bigCalendar', props)}
            view={view}
            currentDate={props.currentDate}
            currentUser={props.currentUser}
            selectedUsers={props.selectedUsers}
            users={props.users}
            onViewChange={setView}
            onNavigate={props.onNavigate}
            onUserControlChange={props.onUserControlChange}
          />
        </Box>
        <Show when={view === 'month'}>
          <MonthView
            data-testid={suffixTestId('bigCalendar', props)}
            currentDate={props.currentDate}
            events={optimisticEvents}
            isReadOnly={props.isReadOnly}
            onEventClick={props.onEventClick}
            onDateClick={props.onDateClick}
          />
        </Show>
        <Show when={view === 'week'}>
          <WeekView
            data-testid={suffixTestId('bigCalendar', props)}
            currentDate={props.currentDate}
            events={optimisticEvents}
            isReadOnly={props.isReadOnly}
            onEventClick={props.onEventClick}
            onDateClick={props.onDateClick}
          />
        </Show>
        <Show when={view === 'day'}>
          <DayView
            data-testid={suffixTestId('bigCalendar', props)}
            currentDate={props.currentDate}
            events={optimisticEvents}
            isReadOnly={props.isReadOnly}
            onEventClick={props.onEventClick}
            onDateClick={props.onDateClick}
          />
        </Show>
      </Box>
      <BigCalendarDragOverlay data-testid={suffixTestId('bigCalendar', props)} />
    </DndContext>
  );
}
