import {RTKQStateTransformerFactory} from '../types/RTKQStateTransformerFactory';

export const createLanguageCheckTransform: RTKQStateTransformerFactory<[() => string]> = (
  getCurrentLanguage: () => string
) => ({
  serialize: (state) => {
    // Add current language to the state for later comparison
    if (state && typeof state === 'object') {
      return {
        ...state,
        _meta: {
          ...(state._meta || {}),
          language: getCurrentLanguage(),
        },
      };
    }
    return state;
  },

  deserialize: (state) => {
    if (state && typeof state === 'object' && '_meta' in state) {
      const meta = state._meta;
      const currentLanguage = getCurrentLanguage();

      if (meta && typeof meta === 'object' && 'language' in meta) {
        const storedLanguage = meta.language;

        // If languages don't match, don't use the stored data
        if (storedLanguage !== currentLanguage) {
          return undefined;
        }
      }
    }
    return state;
  },
});
