import {showNotification} from 'platform/components';

import {useNavigate} from 'react-router-dom';

import {
  useAddToAllVehicleMutation,
  useResetPositionsToAllVehiclesMutation,
} from '@dms/api/promotionalPhoto';
import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {DialogTypes} from '../types/DialogTypes';
import {UpdateDialog} from './UpdateDialog';

export type DialogsProps = {
  type: DialogTypes;
  id: string;
};

const dialogData = {
  [DialogTypes.ADDED]: {
    text: i18n.t('entity.promoPhotos.dialogs.addToAllVehicle'),
    confirmText: i18n.t('general.actions.add'),
    discardText: i18n.t('general.actions.notAdd'),
  },
  [DialogTypes.ORDER_CHANGE]: {
    text: i18n.t('entity.promoPhotos.dialogs.orderChanged'),
    confirmText: i18n.t('general.actions.update'),
    discardText: i18n.t('general.actions.notUpdate'),
  },
  [DialogTypes.PHOTO_CHANGE]: {
    text: i18n.t('entity.promoPhotos.dialogs.photoChanged'),
    confirmText: i18n.t('general.actions.update'),
    discardText: i18n.t('general.actions.notUpdate'),
  },
  [DialogTypes.ORDER_PHOTO_CHANGE]: {
    text: i18n.t('entity.promoPhotos.dialogs.orderPhotoChanged'),
    confirmText: i18n.t('general.actions.update'),
    discardText: i18n.t('general.actions.notUpdate'),
  },
};

export function Dialogs(props: DialogsProps) {
  const {type, id} = props;
  const navigate = useNavigate();
  const [addToAllVehicle] = useAddToAllVehicleMutation();
  const [resetPositionsToAllVehicles] = useResetPositionsToAllVehiclesMutation();

  const handleAddToAllVehicles = (promotionalPhotoIds: string[]) =>
    addToAllVehicle({
      addToAllVehiclesRequestBody: {promotionalPhotoIds},
    })
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('entity.promoPhotos.notifications.addToAllVehicle'));
      })
      .catch(handleApiError);

  const handleResetPositionsToAllVehicles = (promotionalPhotoIds: string[]) =>
    resetPositionsToAllVehicles({
      resetPositionsToAllVehiclesRequestBody: {promotionalPhotoIds},
    })
      .unwrap()
      .then(() => {
        showNotification.success(
          i18n.t('entity.promoPhotos.notifications.resetPositionsToAllVehicles')
        );
      })
      .catch(handleApiError);

  const handleConfirm = () => {
    const promisses = [];
    if (
      type === DialogTypes.ADDED ||
      type === DialogTypes.PHOTO_CHANGE ||
      type === DialogTypes.ORDER_PHOTO_CHANGE
    ) {
      promisses.push(handleAddToAllVehicles([id]));
    }
    if (type === DialogTypes.ORDER_CHANGE || type === DialogTypes.ORDER_PHOTO_CHANGE) {
      promisses.push(handleResetPositionsToAllVehicles([id]));
    }

    Promise.all(promisses).then(() => handleClose());
  };

  const handleClose = () => {
    navigate(settingsRoutes.advertisingPromoPhotos);
  };

  return (
    <UpdateDialog isOpen onConfirm={handleConfirm} onDiscard={handleClose} {...dialogData[type]} />
  );
}
