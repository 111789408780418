import {z} from 'zod';

export const ReceiveNoteItemDetailSchema = z.object({
  receiveNoteItem: z.object({
    quantity: z.number().positive(),
    unitPrice: z.number().positive(),
    totalPrice: z.number().positive(),
    vatType: z.string(),
  }),
  deliveryItem: z.object({
    quantityVerified: z.number().positive(),
    unitPrice: z.number().positive(),
    totalPrice: z.number().positive(),
    vatType: z.string(),
  }),
  supplierArticle: z.object({
    supplierQuantity: z.number().positive(),
    supplierUnitId: z.string().min(1),
    warehouseQuantity: z.number().positive(),
    warehouseUnitId: z.string(),
    supplierOrderingNumber: z.string().nullish(),
    supplierBulkPackageQuantity: z.number().nullish(),
    supplier: z.string(),
  }),
});

export type ReceiveNoteItemFormType = z.infer<typeof ReceiveNoteItemDetailSchema>;
