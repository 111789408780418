import {openDialog, showNotification} from 'platform/components';
import {match} from 'ts-pattern';

// eslint-disable-next-line no-restricted-imports
import {head, path} from 'ramda';
import {isArray, isNotNaN, noop} from 'ramda-adjunct';

import {
  useAccPostDeleteErrorLogsMutation,
  useReprocessErrorLogMutation,
} from '@dms/api/metadaErrorLogs';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {DeleteErrorLogsDialogBody} from './components/DeleteErrorLogsDialogBody';

export function AccountingErrorLog() {
  const navigate = useNavigate();
  const [reprocessErrorLog] = useReprocessErrorLogMutation();
  const [deleteErrorLog] = useAccPostDeleteErrorLogsMutation();

  const handleActionCallback: ActionCallback = ({
    actionKey,
    rowId,
    rowData,
    refreshData,
    deselectAll,
  }) => {
    const firstErrorLogId = isArray(rowId) ? head(rowId) : rowId;
    const documentId = path<string>(['document_id', 'value'], rowData);

    const handleReprocess = async () => {
      if (!documentId || !firstErrorLogId) {
        return;
      }

      await reprocessErrorLog({errorLogId: firstErrorLogId, documentId})
        .unwrap()
        .then(() => showNotification.success())
        .then(refreshData)
        .catch(handleApiError);
    };

    const handleDeleteLogs = async (shouldDeleteUnresolved: boolean) => {
      const errorLogIds = isArray(rowId) ? rowId : [rowId];
      const parsedRowIds = errorLogIds.map(Number).filter(isNotNaN);

      await deleteErrorLog({id: parsedRowIds, deleteAll: shouldDeleteUnresolved})
        .unwrap()
        .then(() => showNotification.success(i18n.t('general.notifications.successfullyDeleted')))
        .then(deselectAll)
        .then(refreshData)
        .catch(handleApiError);
    };

    match(actionKey)
      .with('redirectDetail', () =>
        navigate(
          composePath(settingsRoutes.accountingErrorLogDetail, {
            params: {id: firstErrorLogId},
          })
        )
      )
      .with('reprocess', handleReprocess)
      .with('delete', () =>
        openDialog(
          <DeleteErrorLogsDialogBody
            onConfirm={handleDeleteLogs}
            data-testid={testIds.settings.accountingErrorLog('delete')}
          />,
          {title: i18n.t('page.accounting.labels.deleteErrorLogsTitle')}
        )
      )
      .otherwise(noop);
  };

  return (
    <SettingsTemplate
      data-testid={testIds.settings.accountingErrorLog('header')}
      header={{
        title: i18n.t('page.accounting.labels.accountingErrorLog'),
      }}
    >
      <DataGrid
        gridCode="error-log"
        actionCallback={handleActionCallback}
        data-testid={testIds.settings.accountingErrorLog('grid')}
      />
    </SettingsTemplate>
  );
}
