import {IconButton, Tooltip, useLocalStorage, useTranslationContext} from 'platform/components';
import {Box, HStack, Show} from 'platform/foundation';
import styled from 'styled-components';

import {useMemo} from 'react';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {QUICK_FILTER_VALUE_STORAGE_KEY} from '../const/localStorage';
import {useDataGridConfig} from '../context/useDatagridConfig';
import {useDataGridFiltersModel} from '../context/useDataGridFiltersModel';
import {useGridTheme} from '../hooks/useGridTheme';
import {usePresets} from '../hooks/usePresets';
import {useResetFilterByUser} from '../hooks/useResetFilterByUser';
import {GridApi} from '../types/AgGridTypes';
import {FeGridSettings, GetDataQueryResponse, TransformedDefinitionApiResponse} from '../types/Api';
import {DataGridProps} from '../types/DataGridProps';
import {refreshDatagrid} from '../utils/refreshDatagrid';
import {DefaultResetFilterButtonRenderer} from './DefaultResetFilterButtonRenderer';
import {ExportButton} from './ExportButton/ExportButton';
import {PresetsPanelRenderer} from './PresetPanel/PresetsPanelRenderer';
import {QuickFilterWrapper} from './QuickFilterWrapper';
import {SharePresetButton} from './SharePresetButton/SharePresetButton';

export type DataGridHeaderProps = {
  dataGridSettings: FeGridSettings;
  dataGridProps: DataGridProps;
  gridApi: GridApi;
  definition: TransformedDefinitionApiResponse;
  dataQuery: GetDataQueryResponse | Nullish;
  setSettings: (settings: FeGridSettings) => void;
  refreshData: () => void;
} & TestIdProps;

const HeaderWrapper = styled.div``;

export function DataGridHeader(props: DataGridHeaderProps) {
  const {dataGridSettings, setSettings, dataGridProps, gridApi, definition} = props;

  const showHeaderPanel = useMemo(
    () =>
      definition.behavior.smartSearchEnabled ||
      definition.behavior.presetsEnabled ||
      definition.behavior.globalActionsEnabled ||
      definition.behavior.filterToolPanelVisible,
    [
      definition.behavior.smartSearchEnabled,
      definition.behavior.presetsEnabled,
      definition.behavior.globalActionsEnabled,
      definition.behavior.filterToolPanelVisible,
    ]
  );

  const {buildAbsoluteUrl} = useDataGridConfig();
  const {headerTheme} = useGridTheme(dataGridSettings, definition);
  const {isFilterActive} = useDataGridFiltersModel();
  const presetsPanelProps = usePresets(gridApi, dataGridSettings, props.dataQuery, setSettings);

  const [quickFilterValue, _] = useLocalStorage<string | null>(
    `${QUICK_FILTER_VALUE_STORAGE_KEY}${dataGridProps.gridCode}`,
    ''
  );

  const resetFilter = useResetFilterByUser(gridApi);
  const t = useTranslationContext();

  if (!showHeaderPanel) {
    return null;
  }

  const handleRefreshData = () => {
    refreshDatagrid(props.dataGridProps.gridCode);
  };

  return (
    <HeaderWrapper css={headerTheme} data-testid={suffixTestId('headerWrapper', props)}>
      <div className="ag-custom-header-panel">
        <Show when={definition.behavior.smartSearchEnabled}>
          <div style={{flexShrink: 0}}>
            <QuickFilterWrapper
              gridProps={dataGridProps}
              gridApi={gridApi}
              data-testid={props['data-testid']}
            />
          </div>
        </Show>
        <Show when={definition.behavior.presetsEnabled}>
          <PresetsPanelRenderer
            {...presetsPanelProps}
            data-testid={props['data-testid']}
            gridApi={props.gridApi}
          />
        </Show>
        <Show when={definition.behavior.globalActionsEnabled}>
          <HStack grow={1} justify="flex-end">
            <Tooltip label={t('page.datagrid.button.refreshData')}>
              <IconButton
                aria-label={t('page.datagrid.button.refreshData')}
                icon="navigation/refresh"
                onClick={handleRefreshData}
                data-testid={suffixTestId('refreshDataIconButton', props)}
              />
            </Tooltip>
            <Show when={buildAbsoluteUrl}>
              <SharePresetButton data-testid={props['data-testid']} />
            </Show>
            <ExportButton gridApi={gridApi} data-testid={suffixTestId('exportButton', props)} />
          </HStack>
        </Show>
      </div>
      <Box>
        {(quickFilterValue || isFilterActive) && (
          <DefaultResetFilterButtonRenderer
            resetFilter={resetFilter}
            data-testid={props['data-testid']}
          />
        )}
      </Box>
    </HeaderWrapper>
  );
}
