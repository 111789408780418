import {parseISO} from 'date-fns';
import {isFeatureEnabled} from 'feature-flags';
import {useDateTimeFormatter} from 'platform/locale';
import {match, Pattern} from 'ts-pattern';

import {always, isNil, isNotNil} from 'ramda';

import {useInvoicingDocumentRecalculateMutation} from '@dms/api/accounting';
import {
  useGetBusinessCaseQuery,
  useGetBusinessCaseSeriesDefinitionListQuery,
} from '@dms/api/businessCase';
import {useGetOrderQuery} from '@dms/api/checkout';
import {
  CreateCorrectiveTaxDocumentRequestBody,
  PaymentInfoRequestBody,
} from '@dms/api/metadaErrorLogs';
import {useGetTenantQuery} from '@dms/api/tenant';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {useGetCurrentBranch} from '@dms/shared';

import {useQueryState, useRequiredParams} from 'shared';

import {ACCOUNTING_DOCUMENT_CALCULATION_FIXED_CACHE_KEY, PERCENTAGE_SIGN} from '../../../constants';
import {AccountingDocumentFormValues, InvoiceItem} from '../../../types';
import {getInvoicingDocumentType} from '../utils/getInvoicingDocumentType';

export const useGetCorrectiveTaxForBCDocumentBody = () => {
  const formatDateTime = useDateTimeFormatter();

  const [invoiceType] = useQueryState('invoiceType');
  const [orderId] = useQueryState('orderId');
  const [checkoutId] = useQueryState('checkoutId');
  const [businessCaseId] = useQueryState('businessCaseId');

  const {id} = useRequiredParams();

  const {data: tenant} = useGetTenantQuery();
  const {activeBranchId} = useGetCurrentBranch();
  const invoicingDocumentType = getInvoicingDocumentType(invoiceType);
  const {data: documentSeries} = useGetBusinessCaseSeriesDefinitionListQuery();
  const {data: order} = useGetOrderQuery(
    {orderId: orderId ?? '', checkoutId: checkoutId ?? ''},
    {skip: isNil(orderId) || isNil(checkoutId)}
  );

  const [_, {data: summary}] = useInvoicingDocumentRecalculateMutation({
    fixedCacheKey: ACCOUNTING_DOCUMENT_CALCULATION_FIXED_CACHE_KEY,
  });

  const currency = tenant?.currency as string;

  const activeDefinition =
    documentSeries?.find((item) => item.branchId === activeBranchId) ??
    documentSeries?.find((item) => item.systemDefault) ??
    null;

  const {data: businessCase} = useGetBusinessCaseQuery(
    {businessCaseId: businessCaseId ?? ''},
    {skip: isNil(businessCaseId)}
  );

  const isBrokerageBusinessCase = isNotNil(businessCase?.brokerageBusinessCase);

  const documentSeriesId = match([order?.orderDiscriminator, isBrokerageBusinessCase])
    .with(['SALE', false], always(activeDefinition?.correctiveTaxDocumentSeriesId))
    .with(['SALE', true], always(activeDefinition?.saleBrokerageCorrectiveTaxDocumentSeriesId))
    .with(
      ['PURCHASE_BROKERAGE_SALE', Pattern.boolean],
      ['PURCHASE_BROKERAGE_FEES', Pattern.boolean],
      always(activeDefinition?.brokerageFeesCorrectiveTaxDocumentSeriesId)
    )
    .otherwise(always(activeDefinition?.correctiveTaxDocumentSeriesId));

  const getRequestBody = (
    formData: AccountingDocumentFormValues<PaymentInfoRequestBody>
  ): CreateCorrectiveTaxDocumentRequestBody => {
    if (isNil(documentSeriesId)) {
      // TYPE CHECK: there should be always present at least system default series,
      throw new Error('Document series not found');
    }

    const items = formData.invoiceItems.map((item: InvoiceItem) => {
      const discount =
        summary?.items.find(
          (summaryItem) => summaryItem.itemId === item.itemId && summaryItem.type === 'discount'
        )?.discount ?? null;

      return {
        currency,
        description: item.description,
        isUnitPriceWithVat: item.isUnitPriceWithVat,
        itemId: item.itemId,
        pricePerUnit: item.pricePerUnit?.toString() ?? '0',
        quantity: item.unit === PERCENTAGE_SIGN ? '1' : (item.quantity?.toString() ?? '0'),
        type: item.type,
        unit: item.unit,
        correctedItemId: item.itemId,
        vatType: item.vatType,
        discount: item.type === 'discount' ? discount : null,
        relatedItemId: item.relatedItemId,
      };
    });

    return {
      dateOfReception: formData.dateOfReception ?? '',
      behindItemsNote: formData.footerNote ?? null,
      customFields: formData.customFieldsPayload,
      dateOfTaxableSupply: formData.dateOfTaxableSupply,
      description: i18n.t('entity.correctiveTaxDocument.labels.descriptionContent', {
        invoiceNumber: formData.number,
        issueDate: formatDateTime('dateShort', parseISO(formData.issueDate)),
      }),
      documentSeriesId,
      dueDate: formData.dueDate,
      internalNote: formData.internalNote ?? null,
      issueDate: formData.issueDate,
      paymentInfo: formData.paymentInfo,
      additionalCustomer:
        isNotNil(formData.additionalCustomer?.customer.id) &&
        isFeatureEnabled(featureFlags.ACCOUNTING_ADDITIONAL_CUSTOMER)
          ? {
              contractInformationId: formData.additionalCustomer.contractInformationId,
              customerId: formData.additionalCustomer.customer.id,
              type: formData.additionalCustomer.type,
            }
          : null,
      reason: formData.reason ?? '',
      invoicingDocumentType,
      invoicingDocumentId: id,
      items,
      exchangeRateRatio: formData.isExchangeRateRatioEnabled
        ? {
            amount: parseFloat(formData.exchangeRateRatio?.amount ?? ''),
            currency: formData.exchangeRateRatio?.currency ?? '',
            ratio: formData.exchangeRateRatio?.ratio?.toString() ?? '',
          }
        : null,
    };
  };

  return [getRequestBody];
};
