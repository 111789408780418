import {omneticApi} from '@dms/api/core';
import {
  CalculateFromWithoutVatApiArg,
  CalculateFromWithoutVatApiResponse,
  CalculateFromWithVatApiArg,
  CalculateFromWithVatApiResponse,
} from '@dms/api/shared';

export const commonApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    calculateFromWithVat: build.query<CalculateFromWithVatApiResponse, CalculateFromWithVatApiArg>({
      query: (queryArg) => ({
        url: `/common/v1/vat-calculation/from-with-vat`,
        params: {
          countryCode: queryArg.countryCode,
          vatRateType: queryArg.vatRateType,
          amount: queryArg.amount,
          date: queryArg.date,
        },
      }),
    }),
    calculateFromWithoutVat: build.query<
      CalculateFromWithoutVatApiResponse,
      CalculateFromWithoutVatApiArg
    >({
      query: (queryArg) => ({
        url: `/common/v1/vat-calculation/from-without-vat`,
        params: {
          countryCode: queryArg.countryCode,
          vatRateType: queryArg.vatRateType,
          amount: queryArg.amount,
          date: queryArg.date,
        },
      }),
    }),
  }),
});

export const {
  useCalculateFromWithVatQuery,
  useCalculateFromWithoutVatQuery,
  useLazyCalculateFromWithVatQuery,
  useLazyCalculateFromWithoutVatQuery,
} = commonApi;
