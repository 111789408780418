import {useFormContext, useWatch, FieldValues} from 'react-hook-form';

import {pick} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

/**
 * Custom hook to access and watch specific form values from react-hook-form context
 *
 * @template T - The form values type
 * @template U - The keys of T that we want to watch (must be strings)
 * @param fields - A single field name or array of field names to watch
 * @returns An object containing the requested form values
 * @throws Error if used outside of a FormProvider
 */
export function useFormValues<T extends FieldValues, U extends string & keyof T = string & keyof T>(
  fields: U | readonly U[]
): Pick<T, U> {
  const formContext = useFormContext<T>();

  if (isNilOrEmpty(formContext)) {
    throw new Error(
      'useFormValues hook must be used within a FormProvider (parent form component)'
    );
  }

  // Ensure fields is an array and filter out any non-string values
  const fieldsArray = (Array.isArray(fields) ? fields : [fields]).filter(
    (val): val is U => typeof val === 'string'
  ) as U[];

  const watchedValues = useWatch({name: fieldsArray});
  const allValues = formContext.getValues();

  return pick(fieldsArray, {
    ...watchedValues, // Subscribe to form value updates
    ...allValues,
  });
}
