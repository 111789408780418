import {BigCalendarEvent, BigCalendarUser} from 'platform/big-calendar';

import {GetDataByDataQueryApiResponse} from '@dms/api/datagrid';

import {Nullish, parseDate} from 'shared';

import {getIconKeyByType} from '../../../../../utils/getIconKeyByType';
import {getUserColorVariants} from './getUserColorVariants';
import {parseDueTime} from './parseDueTime';

export function getBigCalendarDataFromGrid(
  dataGridData: GetDataByDataQueryApiResponse | Nullish,
  selectedUsers: BigCalendarUser[] | Nullish
) {
  return dataGridData?.rows?.reduce<BigCalendarEvent[]>((events, row) => {
    const dueDate: string | null = row.cells.dueDate.value;
    const dueTime: string | null = row.cells.dueTime.value;

    const assignedUserId = row.cells.assignedTo?.value?.id;

    const baseDate = parseDate(dueDate);

    const userIndex = selectedUsers?.findIndex((user) => user.id === assignedUserId);

    const userColorVariants = getUserColorVariants(userIndex);

    if (!baseDate) {
      return events;
    }

    if (!dueTime) {
      events.push({
        id: row.id,
        title: row.cells.title.value,
        start: parseDate(baseDate.setHours(0, 0, 0)),
        end: parseDate(baseDate.setHours(0, 0, 0)),
        color: userColorVariants[row.cells?.priority?.value?.key],
        icon: getIconKeyByType(row.cells?.type?.value?.key),
        alternative: row.cells.state.value?.key === 'DONE',
        isAllDay: true,
      });
      return events;
    }

    const {start, end} = parseDueTime(dueTime, baseDate);

    events.push({
      id: row.id,
      title: row.cells.title.value,
      start,
      end,
      color: userColorVariants[row.cells?.priority?.value?.key],
      icon: getIconKeyByType(row.cells?.type?.value?.key),
      alternative: row.cells.state.value?.key === 'DONE',
    });

    return events;
  }, []);
}
