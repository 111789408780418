import {Dropdown, IconButton} from 'platform/components';
import {Box, Center, Heading, Hide, Scroll, VStack} from 'platform/foundation';

import {UserSidebarIconSize} from '@dms/api/shared';
import i18n from '@dms/i18n';
import {omneticReleaseNotes} from '@dms/releaseNotes';

import {pushGtmEvent, suffixTestId, TestIdProps, useBoolean} from 'shared';

import {HeaderButtonWrapper} from './HeaderButtonWrapper';
import {Release} from './Release';
import {TopBarActionContainer} from './TopBarActionContainer';

interface ReleasePopupProps extends TestIdProps {
  iconSize: UserSidebarIconSize;
}

export function ReleasePopup(props: ReleasePopupProps) {
  const [isOpen, setOpen, setClose] = useBoolean();

  const onOpen = () => {
    pushGtmEvent({
      event: 'button_click',
      event_id: 'top_bar_release_popup_open',
    });
    setOpen();
  };

  return (
    <Dropdown
      onOpen={onOpen}
      onClose={setClose}
      closeOnBlur
      data-testid={suffixTestId('releasePopupDropdown', props)}
      dropdownControl={
        <TopBarActionContainer>
          <Center width="100%" height="100%">
            <HeaderButtonWrapper $isOpen={isOpen}>
              <IconButton
                aria-label="topBar"
                iconSize={props.iconSize}
                icon="action/grade"
                data-testid={suffixTestId('releasePopup-button', props)}
              />
            </HeaderButtonWrapper>
          </Center>
        </TopBarActionContainer>
      }
      placement="bottom-end"
      isLazy
      lazyBehavior="unmount"
    >
      <Box data-testid={suffixTestId('releasePopup', props)}>
        <Scroll width={120} maxWidth={200} maxHeight="85vh" auto>
          <VStack data-testid={suffixTestId('releasePopupInnerBox', props)}>
            <Box padding={2}>
              <Center justify="flex-start" minHeight={8}>
                <Heading size={4}>{i18n.t('general.labels.releaseNotesTitle')}</Heading>
              </Center>
            </Box>
            <VStack spacing={1}>
              {omneticReleaseNotes.map((release, index) => (
                <Hide whenFeatureDisabled={release.featureName} key={`release-[${release.url}]`}>
                  <Release
                    data-testid={`release-[${index}]`}
                    type={release.type}
                    title={release.title}
                    description={release.description}
                    dateTime={release.dateTime}
                    url={release.url}
                  />
                </Hide>
              ))}
            </VStack>
          </VStack>
        </Scroll>
      </Box>
    </Dropdown>
  );
}
