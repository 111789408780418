import {Action, Card, FormControl, Label} from 'platform/components';
import {Grid, Link, Text, VStack} from 'platform/foundation';
import {CurrencyFormat, useDateTimeFormatter} from 'platform/locale';
import {match} from 'ts-pattern';

import {useEffect} from 'react';
import {UseFormReturn, useWatch} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';

import {always, isNil} from 'ramda';

import {InvoiceDocumentTypes} from '@dms/api/shared';
import i18n from '@dms/i18n';
import {accountingRoutes} from '@dms/routes';
import {AccountingDocument, DEFAULT_CURRENCY} from '@dms/shared';

import {
  Nullish,
  RequiredTestIdProps,
  buildArray,
  composePath,
  parseDate,
  suffixTestId,
} from 'shared';

import {AccountingDocumentFormValues} from '../../types';
import {useRecalculateInvoiceItems} from '../listOfItems/hooks/useRecalculateInvoiceItems';
import {getAccountingDocumentTitle} from './utils/getAccountingDocumentTitle';
import {getAccountingDocumentTotalPrice} from './utils/getAccountingDocumentTotalPrice';

interface PairedInvoiceProps extends RequiredTestIdProps {
  invoiceDocument: AccountingDocument | undefined;
  formApi: UseFormReturn<AccountingDocumentFormValues>;
  control: FormControl<AccountingDocumentFormValues>;
  invoiceType: InvoiceDocumentTypes | Nullish;
  isDetail?: boolean;
}

const DEFAULT_PRICE = '0';

export function PairedInvoice(props: PairedInvoiceProps) {
  const navigate = useNavigate();
  const formatDateTime = useDateTimeFormatter();
  const [recalculate] = useRecalculateInvoiceItems({
    control: props.control,
    formApi: props.formApi,
    listItemType: 'cash-receipts',
    isCorrectiveTaxDocument: false,
  });

  const invoiceId = useWatch({control: props.control, name: 'id'});

  const handleUnpairInvoice = () => props.formApi.reset(props.control._defaultValues);

  useEffect(() => {
    if (isNil(props.invoiceDocument?.id) || isNil(invoiceId) || !recalculate) {
      return;
    }

    recalculate();
  }, [invoiceId, props.invoiceDocument?.id, recalculate]);

  if (isNil(props.invoiceDocument) || (isNil(invoiceId) && !props.isDetail)) {
    return null;
  }

  const cardActions = buildArray<Action>().whenNot(props.isDetail, {
    type: 'button',
    variant: 'dangerLink',
    onClick: handleUnpairInvoice,
    title: i18n.t('page.accounting.cashReceipt.unpairInvoice'),
    leftIcon: 'action/delete',
  });

  const handleNavigateToInvoice = () => {
    const path = match(props.invoiceType)
      .with('invoice', always(accountingRoutes.invoiceDetail))
      .with('balance_invoice', always(accountingRoutes.balanceInvoiceDetail))
      .with('invoice_proforma', always(accountingRoutes.proformaInvoiceDetail))
      .with('tax_document_for_payment', always(accountingRoutes.taxDocumentForPaymentDetail))
      .with('corrective_tax_document', always(accountingRoutes.correctiveTaxDocumentDetail))
      .otherwise(always(accountingRoutes.invoiceDetail));

    navigate(composePath(path, {params: {id: props.invoiceDocument?.id}}));
  };

  const invoiceTitle = getAccountingDocumentTitle(props.invoiceType);
  const invoiceTotalPrice = getAccountingDocumentTotalPrice(props.invoiceDocument);

  return (
    <Card title={invoiceTitle} actions={cardActions}>
      <Grid columns={4}>
        <VStack>
          <Label>{i18n.t('entity.warehouse.labels.invoiceNumber')}</Label>
          <Link
            title={props.invoiceDocument.number}
            onClick={handleNavigateToInvoice}
            data-testid={suffixTestId('number', props)}
          />
        </VStack>

        <VStack>
          <Label>{i18n.t('general.labels.amount')}</Label>
          <Text alternative data-testid={suffixTestId('amount', props)}>
            <CurrencyFormat
              decimals={2}
              currency={invoiceTotalPrice?.currency ?? DEFAULT_CURRENCY}
              number={parseFloat(invoiceTotalPrice?.amount ?? DEFAULT_PRICE)}
            />
          </Text>
        </VStack>

        <VStack>
          <Label>{i18n.t('general.labels.issuedOn')}</Label>
          <Text alternative>
            {formatDateTime('dateShort', parseDate(props.invoiceDocument.issueDate))}
          </Text>
        </VStack>

        <VStack>
          <Label>{i18n.t('entity.accounting.labels.dueDate')}</Label>
          <Text alternative>
            {formatDateTime('dateShort', parseDate(props.invoiceDocument.dueDate))}
          </Text>
        </VStack>
      </Grid>
    </Card>
  );
}
