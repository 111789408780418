import {isFeatureEnabled} from 'feature-flags';

import {Module, SingleModuleItem} from '@dms/api/shared';
import {moduleKeys} from '@dms/config';
import {featureFlags} from '@dms/feature-flags';
import {analyticsRoutes} from '@dms/routes';
import {permissions} from '@dms/shared';

import {Analytics} from './pages/Analytics/Analytics';
import {AnalyticsCloud} from './pages/AnalyticsCloud/AnalyticsCloud';
import {WarehouseReport} from './pages/WarehouseReport/WarehouseReport';
import {WorkshopReport} from './pages/WorkshopReport/WorkshopReport';

function AnalyticsComponent() {
  if (isFeatureEnabled(featureFlags.GOOD_DATA_COULD)) {
    return <AnalyticsCloud />;
  }
  return <Analytics />;
}

const enabledForAclReportingAndGoodDataCloud: SingleModuleItem['requiredFeatureFlag'] = (
  resolve
) => {
  const flags = [featureFlags.ACL_REPORTING, featureFlags.GOOD_DATA_COULD];

  return flags.some(resolve);
};

export const AnalyticsModule: Module = {
  id: moduleKeys.analytics,
  layoutProps: {
    title: 'analytics.label.title',
    icon: 'action/timeline',
    iconV2: 'sidebar/analytics',
  },
  routerProps: {
    path: (isFeatureEnabled) =>
      isFeatureEnabled(featureFlags.GOOD_DATA_COULD)
        ? analyticsRoutes.cloudOverview
        : analyticsRoutes.overview,
    element: <AnalyticsComponent />,
  },
  requiredFeatureFlag: enabledForAclReportingAndGoodDataCloud,
  requiredPermission: permissions.reportingRead,
  content: [
    {
      id: 'analytics-overview',
      requiredFeatureFlag: (resolve) => {
        const isGoodDataCouldEnabled = resolve(featureFlags.GOOD_DATA_COULD);
        const isAclReportingEnabled = resolve(featureFlags.ACL_REPORTING);

        return !isGoodDataCouldEnabled && isAclReportingEnabled;
      },
      routerProps: {
        path: analyticsRoutes.overview,
        element: <Analytics />,
      },
      layoutProps: {
        title: 'analytics.label.title',
        icon: 'sidebar/analytics',
      },
    },
    {
      id: 'analytics-cloud-overview',
      requiredFeatureFlag: featureFlags.GOOD_DATA_COULD,
      routerProps: {
        path: analyticsRoutes.cloudOverview,
        element: <AnalyticsCloud />,
      },
      layoutProps: {
        title: 'analytics.label.cloudTitle',
        icon: 'sidebar/analytics',
      },
    },
    {
      id: 'analytics-workshop-reports',
      requiredFeatureFlag: enabledForAclReportingAndGoodDataCloud,
      routerProps: {
        path: analyticsRoutes.workshopReports,
        element: <WorkshopReport />,
      },
      layoutProps: {
        title: 'analytics.label.workshopReports',
        icon: 'sidebar/analytics',
      },
    },
    {
      id: 'analytics-warehouse-reports',
      requiredFeatureFlag: enabledForAclReportingAndGoodDataCloud,
      routerProps: {
        path: analyticsRoutes.warehouseReports,
        element: <WarehouseReport />,
      },
      layoutProps: {
        title: 'analytics.label.warehouseReports',
        icon: 'sidebar/analytics',
      },
    },
    {
      id: 'analytics-dashboard',
      requiredFeatureFlag: featureFlags.ACL_REPORTING,
      routerProps: {
        path: analyticsRoutes.dashboard,
        element: <Analytics />,
      },
    },
    {
      id: 'analytics-cloud-dashboard',
      requiredFeatureFlag: featureFlags.GOOD_DATA_COULD,
      routerProps: {
        path: analyticsRoutes.cloudDashboard,
        element: <AnalyticsCloud />,
      },
    },
    {
      id: 'analytics-workshop-reports-tab',
      requiredFeatureFlag: featureFlags.ACL_REPORTING,
      routerProps: {
        path: analyticsRoutes.workshopReportsTab,
        element: <WorkshopReport />,
      },
    },
    {
      id: 'analytics-warehouse-reports-tab',
      requiredFeatureFlag: featureFlags.ACL_REPORTING,
      routerProps: {
        path: analyticsRoutes.warehouseReportsTab,
        element: <WarehouseReport />,
      },
    },
  ],
};
