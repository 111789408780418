import {
  Alert,
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Grid, Show, Space, VStack} from 'platform/foundation';

import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import {isNil} from 'ramda';
import {isNilOrEmpty, isNotNil} from 'ramda-adjunct';

import {useGetTemplatesQuery} from '@dms/api/document';
import {useGetAuthorizationProfilesQuery} from '@dms/api/metadaAdminAuthorizationProfile';
import {
  GetWarehouseResponse,
  useGetWarehousesQuery,
  useLazyGetWarehouseQuery,
} from '@dms/api/metadaWarehouse';
import {BaseSupplier, useGetSuppliersQuery} from '@dms/api/metadaWarehouseSupplier';
import {
  useGetSupplierOrderVariantQuery,
  usePatchSupplierOrderVariantMutation,
  usePostSupplierOrderVariantMutation,
} from '@dms/api/metadaWarehouseSupplierOrderVariants';
import {useGetSeriesListQuery} from '@dms/api/series';
import {useGetTenantQuery} from '@dms/api/tenant';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {
  getOptionsFromAuthorizationProfiles,
  getOptionsFromSuppliers,
  getOptionsFromWarehouses,
  handleApiError,
  useDuplicateErrorHandler,
} from '@dms/shared';

import {Nullish, useNavigate} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';
import {getOptionsFromSeries} from '../../utils/getOptionsFromSeries';
import {getOptionsFromTemplates} from '../../utils/getOptionsFromTemplates';
import {
  SupplierOrderVariantForm,
  SupplierOrderVariantFormSchema,
} from './types/SupplierOrderVariantForm';

export function WarehousesSupplierOrderVariantsDetail() {
  const {id} = useParams();
  const navigate = useNavigate();
  const {duplicateError: isDuplicated, duplicateErrorHandler} = useDuplicateErrorHandler();

  const [selectedWarehouse, setSelectedWarehouse] = useState<GetWarehouseResponse | Nullish>(null);

  const [postSupplierOrderVariant] = usePostSupplierOrderVariantMutation();

  const [patchSupplierOrderVariant] = usePatchSupplierOrderVariantMutation();

  const [getWarehouse, {isLoading: isWarehouseLoading, isFetching: isWarehouseFetching}] =
    useLazyGetWarehouseQuery();

  const {
    data: warehouses,
    isLoading: isWarehousesLoading,
    isError: hasWarehousesError,
  } = useGetWarehousesQuery();

  const {
    data: suppliers,
    isLoading: areSuppliersLoading,
    isError: hasSupplierError,
  } = useGetSuppliersQuery();

  const {
    data: supplierOrderVariant,
    isLoading: isSupplierOrderVariantLoading,
    isError: hasSupplierOrderVariantError,
  } = useGetSupplierOrderVariantQuery({orderVariantId: id ?? ''}, {skip: isNil(id)});

  const {data: tenant, isLoading: isTenantLoading, isError: hasTenantError} = useGetTenantQuery();

  const {
    data: authorizationProfiles,
    isLoading: isAuthorizationProfilesLoading,
    isError: hasAuthorizationProfilesError,
  } = useGetAuthorizationProfilesQuery({'x-tenant': tenant?.id ?? ''}, {skip: isNil(tenant)});

  const {
    data: supplierOrderTemplates,
    isLoading: supplierOrderTemplatesLoading,
    isError: supplierOrderTemplatesError,
  } = useGetTemplatesQuery({documentKindCode: 'warehouse-supplier-order'});

  const {
    data: series,
    isLoading: isSeriesLoading,
    isError: hasSeriesError,
  } = useGetSeriesListQuery({
    type: ['warehouse/order'],
  });

  useEffect(() => {
    if (isNil(supplierOrderVariant?.orderingWarehouseId)) {
      return;
    }

    getWarehouse({warehouseId: supplierOrderVariant?.orderingWarehouseId})
      .unwrap()
      .then(setSelectedWarehouse)
      .catch(handleApiError);
  }, [supplierOrderVariant?.orderingWarehouseId, getWarehouse]);

  const isLoading =
    isSupplierOrderVariantLoading ||
    isAuthorizationProfilesLoading ||
    isWarehousesLoading ||
    isTenantLoading ||
    isSeriesLoading ||
    supplierOrderTemplatesLoading ||
    areSuppliersLoading;

  const isError =
    hasSupplierOrderVariantError ||
    hasAuthorizationProfilesError ||
    hasWarehousesError ||
    hasTenantError ||
    hasSeriesError ||
    supplierOrderTemplatesError ||
    hasSupplierError;

  const handleSubmit: FormSubmitHandler<SupplierOrderVariantForm> = async (formValues) => {
    if (isNotNil(id)) {
      return await patchSupplierOrderVariant({orderVariantId: id, body: formValues})
        .unwrap()
        .then(() => {
          showNotification.success(
            i18n.t('entity.warehouse.notifications.supplierOrderVariantUpdated')
          );
        })
        .then(handleNavigateBack)
        .catch(handleApiError);
    }
    await postSupplierOrderVariant(formValues)
      .unwrap()
      .then(() => {
        showNotification.success(
          i18n.t('entity.warehouse.notifications.supplierOrderVariantCreated')
        );
      })
      .then(handleNavigateBack)
      .catch(duplicateErrorHandler);
  };

  const handleNavigateBack = () => {
    navigate(settingsRoutes.warehousesSupplierOrderVariants);
  };

  const header: SettingsTemplateHeader = {
    title: isNil(id)
      ? i18n.t('entity.warehouse.labels.warehouseSupplierOrderVariantCreate')
      : (supplierOrderVariant?.name ?? ''),
  };

  const getSuppliersForSelectedWarehouse = (suppliers: BaseSupplier[] | Nullish) => {
    if (isNilOrEmpty(suppliers)) {
      return [];
    }

    const filteredSuppliers = suppliers?.filter((supplier) => {
      const warehouseSuppliers = selectedWarehouse?.suppliers?.map(
        (warehouseSupplier) => warehouseSupplier?.id
      );
      return warehouseSuppliers?.includes(supplier.id);
    });

    return getOptionsFromSuppliers(filteredSuppliers);
  };

  const defaultValues: Partial<SupplierOrderVariantForm> = {
    name: supplierOrderVariant?.name,
    authorizationProfileId: supplierOrderVariant?.authorizationProfileId,
    docSeriesId: supplierOrderVariant?.docSeriesId,
    orderingWarehouseId: supplierOrderVariant?.orderingWarehouseId,
    defaultSupplierId: supplierOrderVariant?.defaultSupplierId,
    additionalRequestingWarehouses: supplierOrderVariant?.additionalRequestingWarehouses,
    orderTemplateId: supplierOrderVariant?.orderTemplateId,
    isOrderUseIntegration: supplierOrderVariant?.isOrderUseIntegration ?? false,
  };

  return (
    <SettingsTemplate
      data-testid={testIds.settings.warehousesSupplierOrderVariantsDetail('template')}
      header={header}
    >
      <DataStatus isLoading={isLoading} isError={isError}>
        <Form<SupplierOrderVariantForm>
          experimentalZodSchema={SupplierOrderVariantFormSchema}
          defaultValues={defaultValues}
          onSubmit={handleSubmit}
        >
          {(control, formApi) => {
            const handleWarehouseChange = (newWarehouseId: string | string[] | number | null) => {
              if (isNilOrEmpty(newWarehouseId)) {
                setSelectedWarehouse(null);
                formApi.setValue('defaultSupplierId', null);
                formApi.trigger('defaultSupplierId');
                return;
              }

              getWarehouse({warehouseId: newWarehouseId as string})
                .unwrap()
                .then((warehouse) => {
                  setSelectedWarehouse(warehouse);
                  formApi.setValue('defaultSupplierId', warehouse.defaultSupplierId);
                  formApi.trigger('defaultSupplierId');
                })
                .catch(handleApiError);
            };

            return (
              <VStack spacing={4}>
                <FormField
                  type="switch"
                  name="isOrderUseIntegration"
                  label={i18n.t('entity.warehouse.labels.integration')}
                  control={control}
                  data-testid={testIds.settings.warehousesSupplierOrderVariantsDetail(
                    'integration'
                  )}
                />
                <Separator />
                <Show when={isDuplicated}>
                  <Alert
                    variant="error"
                    title={i18n.t(
                      'entity.warehouse.notifications.supplierOrderVariantAlreadyExists'
                    )}
                    data-testid={testIds.settings.warehousesSupplierOrderVariantsDetail(
                      'supplierOrderVariantAlreadyExists'
                    )}
                  />
                </Show>
                <Grid columns={1}>
                  <FormField
                    type="text"
                    name="name"
                    label={i18n.t('entity.warehouse.labels.name')}
                    control={control}
                    isRequired
                    data-testid={testIds.settings.warehousesSupplierOrderVariantsDetail('name')}
                  />
                </Grid>
                <Grid columns={2}>
                  <FormField
                    type="choice"
                    name="authorizationProfileId"
                    label={i18n.t('entity.warehouse.labels.authorizationProfile')}
                    options={getOptionsFromAuthorizationProfiles(authorizationProfiles)}
                    control={control}
                    isRequired
                    isNotClearable
                    data-testid={testIds.settings.warehousesSupplierOrderVariantsDetail(
                      'authorizationProfile'
                    )}
                  />
                  <FormField
                    type="choice"
                    name="docSeriesId"
                    options={getOptionsFromSeries(series, 'warehouse/order')}
                    label={i18n.t('entity.warehouse.labels.numberingSeries')}
                    control={control}
                    isRequired
                    isNotClearable
                    data-testid={testIds.settings.warehousesSupplierOrderVariantsDetail(
                      'docSeriesId'
                    )}
                  />
                </Grid>
                <Grid columns={2}>
                  <FormField
                    type="choice"
                    name="orderingWarehouseId"
                    label={i18n.t('entity.warehouse.labels.orderingWarehouse')}
                    control={control}
                    options={getOptionsFromWarehouses(warehouses)}
                    onChange={handleWarehouseChange}
                    isRequired
                    isNotClearable
                    data-testid={testIds.settings.warehousesSupplierOrderVariantsDetail(
                      'orderingWarehouseId'
                    )}
                  />
                  <FormField
                    type="choice"
                    name="defaultSupplierId"
                    label={i18n.t('entity.warehouse.labels.defaultSupplier')}
                    options={getSuppliersForSelectedWarehouse(suppliers)}
                    isLoading={isWarehouseLoading || isWarehouseFetching}
                    control={control}
                    data-testid={testIds.settings.warehousesSupplierOrderVariantsDetail(
                      'defaultSupplier'
                    )}
                  />
                </Grid>
                <Grid columns={2}>
                  <FormField
                    type="multiChoice"
                    name="additionalRequestingWarehouses"
                    label={i18n.t('entity.warehouse.labels.additionalRequestingWarehouses')}
                    control={control}
                    options={getOptionsFromWarehouses(warehouses)}
                    data-testid={testIds.settings.warehousesSupplierOrderVariantsDetail(
                      'additionalRequestingWarehouses'
                    )}
                  />
                  <FormField
                    type="choice"
                    name="orderTemplateId"
                    label={i18n.t('entity.warehouse.labels.orderTemplate')}
                    control={control}
                    options={getOptionsFromTemplates(supplierOrderTemplates)}
                    data-testid={testIds.settings.warehousesSupplierOrderVariantsDetail(
                      'orderTemplateId'
                    )}
                  />
                </Grid>
                <Space fillAvailable />
                <SettingsFooter
                  actions={[
                    {
                      type: 'button',
                      variant: 'secondary',
                      title: isNil(id)
                        ? i18n.t('general.actions.discard')
                        : i18n.t('general.actions.discardChanges'),
                      onClick: handleNavigateBack,
                      'data-testid':
                        testIds.settings.warehousesSupplierOrderVariantsDetail('discard'),
                    },
                    {
                      type: 'form-button',
                      control,
                      buttonType: 'submit',
                      variant: 'primary',
                      title: isNil(id)
                        ? i18n.t('general.actions.save')
                        : i18n.t('general.actions.saveChanges'),
                      'data-testid': testIds.settings.warehousesSupplierOrderVariantsDetail('save'),
                    },
                  ]}
                />
              </VStack>
            );
          }}
        </Form>
      </DataStatus>
    </SettingsTemplate>
  );
}
