import {Card, DataStatus} from 'platform/components';
import {GridItem, Box, Grid} from 'platform/foundation';

import {useGetBusinessCaseStatisticsQuery} from '@dms/api/businessCase';
import i18n from '@dms/i18n';
import {businessCaseRoutes, testIds} from '@dms/routes';

import {Nullish} from 'shared';

import {StatisticColumn} from './StatisticColumn';

interface BusinessCaseStatisticsCardProps {
  branchId: string | Nullish;
}

export function BusinessCaseStatisticsCard(props: BusinessCaseStatisticsCardProps) {
  const {data, isLoading, isError} = useGetBusinessCaseStatisticsQuery(
    {
      branchId: props.branchId,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  return (
    <Card
      variant="inlineWhite"
      data-testid={testIds.dashboard.home('bc-card')}
      title={i18n.t('page.businessCase.labels.businessCaseList')}
    >
      <Box minHeight={29}>
        <DataStatus isLoading={isLoading} isError={isError}>
          <Grid columns={12}>
            <GridItem span={3}>
              <StatisticColumn
                label={i18n.t('page.homepage.label.offer')}
                count={data?.open}
                href={businessCaseRoutes.home}
                data-testid={testIds.dashboard.home('businessCase')}
              />
            </GridItem>
            <GridItem span={3}>
              <StatisticColumn
                label={i18n.t('page.businessCase.label.stateContract')}
                count={data?.contract}
              />
            </GridItem>
            <GridItem span={3}>
              <StatisticColumn
                label={`${i18n.t('page.businessCase.label.stateUnsuccessful')}`}
                count={data?.unsuccessful}
              />
            </GridItem>
            <GridItem span={3}>
              <StatisticColumn
                label={`${i18n.t('page.businessCase.label.stateClosed')} (${i18n.t(
                  'page.homepage.labels.lastWeek'
                )})`}
                count={data?.successful}
              />
            </GridItem>
          </Grid>
        </DataStatus>
      </Box>
    </Card>
  );
}
