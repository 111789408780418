import {useSelector} from 'react-redux';

import {map} from 'ramda';

import {selectActiveBranchId} from '@dms/api/features';
import {
  useGetEmployeeMechanicsByBranchQuery,
  useGetTireLocationsQuery,
  useGetTireManufacturerQuery,
  useGetTireOptionsQuery,
  useGetTireWidthsQuery,
  useGetTireAspectRatiosQuery,
  useGetTireRimsQuery,
  useGetTireLoadIndexesQuery,
  useGetTireSpeedRatiosQuery,
  useGetTireCategoriesQuery,
} from '@dms/api/metadaTires';

import {Nullish} from 'shared';

export function useTireSetOptions() {
  const branchId = useSelector(selectActiveBranchId);

  const {data: seasonOptions, isLoading: isSeasonOptionsLoading} = useGetTireOptionsQuery({
    category: 'TIRTIRESEASON',
  });
  const {data: locations, isLoading: isLocationOptionsLoading} = useGetTireLocationsQuery();
  const {data: rimTypeOptions, isLoading: isRimTypeOptionsLoading} = useGetTireOptionsQuery({
    category: 'TIRRIMTYPE',
  });
  const {data: manufacturers, isLoading: isManufacturerOptionsLoading} =
    useGetTireManufacturerQuery(undefined);
  const {data: mechanicOptions, isLoading: isMechanicOptionsLoading} =
    useGetEmployeeMechanicsByBranchQuery({branchId});
  const {data: tireWidths, isLoading: isTireWidthOptionsLoading} = useGetTireWidthsQuery();
  const {data: aspectRatios, isLoading: isAspectRatioOptionsLoading} =
    useGetTireAspectRatiosQuery();
  const {data: loadIndexes, isLoading: isLoadIndexOptionsLoading} = useGetTireLoadIndexesQuery();
  const {data: speedRatios, isLoading: isSpeedRatioOptionsLoading} = useGetTireSpeedRatiosQuery();
  const {data: tireRims, isLoading: isRimOptionsLoading} = useGetTireRimsQuery();
  const {data: tireCategories, isLoading: isTireCategoryOptionsLoading} =
    useGetTireCategoriesQuery();

  const manufacturerOptions = map(
    (item) => ({label: item?.name ?? '', value: item?.id ?? ''}),
    manufacturers?.filter((item) => item?.isActive) ?? []
  );

  const tireWidthOptions = map(
    (item) => ({label: item?.name ?? '', value: item?.tireWidthId ?? ''}),
    tireWidths?.filter((item) => item?.isActive) ?? []
  ).sort((a, b) => a.label.localeCompare(b.label));

  const aspectRatioOptions = map(
    (item) => ({label: item?.name ?? '', value: item?.tireAspectRatioId ?? ''}),
    aspectRatios?.filter((item) => item?.isActive) ?? []
  ).sort((a, b) => a.label.localeCompare(b.label));

  const rimOptions = map(
    (item) => ({label: item?.name ?? '', value: item?.tireRimId ?? ''}),
    tireRims?.filter((item) => item?.isActive) ?? []
  ).sort((a, b) => a.label.localeCompare(b.label));

  const loadIndexOptions = map(
    (item) => ({label: item?.name ?? '', value: item?.tireLoadIndexId ?? ''}),
    loadIndexes?.filter((item) => item?.isActive) ?? []
  ).sort((a, b) => a.label.localeCompare(b.label));

  const speedRatioOptions = map(
    (item) => ({label: item?.name ?? '', value: item?.tireSpeedRatioId ?? ''}),
    speedRatios?.filter((item) => item?.isActive) ?? []
  ).sort((a, b) => a.label.localeCompare(b.label));
  const categoryOptions = map(
    (item) => ({label: item?.name ?? '', value: item?.tireCategoryId ?? ''}),
    tireCategories?.filter((item) => item?.isActive) ?? []
  );

  const defaultCategory = tireCategories?.find((item) => item?.isDefault);

  const getManufacturerOptionsWithSelectedValue = (selectedManufacturer: string | Nullish) =>
    map(
      (item) => ({label: item?.name ?? '', value: item?.id ?? ''}),
      manufacturers?.filter((item) => item?.isActive || item?.id === selectedManufacturer) ?? []
    );

  const locationOptions = map(
    (item) => ({label: item?.name ?? '', value: item?.id ?? ''}),
    locations?.filter((item) => item?.isActive) ?? []
  );

  const getLoactionOptionsWithSelectedValue = (selectedLocation: string | Nullish) =>
    map(
      (item) => ({label: item?.name ?? '', value: item?.id ?? ''}),
      locations?.filter((item) => item?.isActive || item?.id === selectedLocation) ?? []
    );

  const defaultManufacturer = manufacturers?.find((manufacturer) => manufacturer?.isDefault);

  const isLoading =
    isSeasonOptionsLoading ||
    isLocationOptionsLoading ||
    isRimOptionsLoading ||
    isManufacturerOptionsLoading ||
    isMechanicOptionsLoading ||
    isTireWidthOptionsLoading ||
    isAspectRatioOptionsLoading ||
    isLoadIndexOptionsLoading ||
    isSpeedRatioOptionsLoading ||
    isRimTypeOptionsLoading ||
    isTireCategoryOptionsLoading;

  return {
    seasonOptions,
    locationOptions,
    rimOptions,
    manufacturerOptions,
    mechanicOptions,
    defaultManufacturer,
    tireWidthOptions,
    aspectRatioOptions,
    loadIndexOptions,
    speedRatioOptions,
    rimTypeOptions,
    categoryOptions,
    defaultCategory,
    isLoading,
    getManufacturerOptionsWithSelectedValue,
    getLoactionOptionsWithSelectedValue,
  };
}
