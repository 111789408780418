import {Choice} from 'platform/components';

import {useState} from 'react';

import {defaultTo, pipe, trim} from 'ramda';

import {PhoneNumber} from '@dms/api/shared';
import {useGetTenantQuery} from '@dms/api/tenant';
import {useGetUsersQuery} from '@dms/api/user';
import i18n from '@dms/i18n';
import {getNaturalPersonFullName} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

export type SignatoryUserSelectType = {
  name: string;
  email: string;
  id: string;
  phoneNumber: PhoneNumber;
};

interface UserSelectChoiceProps extends RequiredTestIdProps {
  onSelect: (userValues: SignatoryUserSelectType) => void;
  defaultUserId?: string;
}

export function UserSelectChoice(props: UserSelectChoiceProps) {
  const [selectedUserId, setSelectedUserId] = useState<string | null>(props.defaultUserId ?? null);

  const {data: users, isLoading} = useGetUsersQuery();
  const {data: tenant} = useGetTenantQuery();
  const userOptions =
    users?.map((user) => ({value: user.id, label: getNaturalPersonFullName(user)})) ?? [];

  const handleOnChange = (userId: string | null) => {
    setSelectedUserId(userId);

    const selectedUser = users?.find((user) => user.id === userId);
    const phoneNumber = selectedUser?.phoneNumber;
    const tenantFallbackPhoneNumber =
      selectedUser?.phoneNumber ?? tenant?.publicContacts?.phoneNumber;

    const userValues: SignatoryUserSelectType = {
      name: getNaturalPersonFullName(selectedUser) ?? '',
      email: getFormPhoneValue(selectedUser?.email),
      id: selectedUser?.id ?? '',
      phoneNumber: {
        number: getFormPhoneValue(phoneNumber?.number),
        prefix: getFormPhoneValue(tenantFallbackPhoneNumber?.prefix),
        countryCode: getFormPhoneValue(tenantFallbackPhoneNumber?.countryCode),
      },
    };

    props.onSelect(userValues);
  };

  return (
    <Choice
      isLoading={isLoading}
      value={selectedUserId}
      onChange={handleOnChange}
      options={userOptions}
      isNotClearable
      menuInPortal
      data-testid={suffixTestId('user-select', props)}
      label={i18n.t('entity.document.actions.searchForUser')}
      placeholder={i18n.t('general.labels.searchPlaceholder')}
    />
  );
}

const getFormPhoneValue = pipe(defaultTo<string | undefined>(''), trim);
