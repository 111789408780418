import {Card} from 'platform/components';
import {Link, Space, Text} from 'platform/foundation';

import {useLazyGetDocument360RedirectUrlQuery} from '@dms/api/auth';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

export function FaqsCard() {
  const [getDocument360RedirectUrl, {isLoading}] = useLazyGetDocument360RedirectUrlQuery();

  const handleRedirectToDoc360 = () =>
    getDocument360RedirectUrl({redirectTo: '/docs/zakladni-informace'})
      .unwrap()
      .then((res) => res.redirectUrl)
      .then(window.open)
      .catch(handleApiError);

  return (
    <Card variant="inlineWhite" data-testid={testIds.dashboard.home('card-faq')} isFullHeight>
      <Text alternative>{i18n.t('page.homepage.labels.FAQ')}</Text>
      <Space vertical={4} />
      <Text size="small" color="secondary">
        {i18n.t('page.homepage.labels.faqDescription')}
      </Text>
      <Space vertical={8} />
      <Link
        onClick={handleRedirectToDoc360}
        size="small"
        isDisabled={isLoading}
        data-testid={testIds.dashboard.home('faq-learn-more')}
        rightIcon="action/launch"
        title={i18n.t('page.homepage.labels.learnMore')}
      />
    </Card>
  );
}
