import i18n from 'i18next';
import {ButtonGroup, Checkbox, closeCurrentDialog, Table, TableRow} from 'platform/components';
import {Box, Right, Show, Text, VStack} from 'platform/foundation';
import {CurrencyFormat, useFormatNumber} from 'platform/locale';

import {useState} from 'react';

import {append, includes, pipe, uniq, without} from 'ramda';

import {
  OrderItemResponseBody,
  SplitCheckoutOrderApiArg,
  useSplitCheckoutOrderMutation,
} from '@dms/api/checkout';
import {handleApiError} from '@dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

interface OrderSplitDialogProps extends TestIdProps {
  checkoutId: SplitCheckoutOrderApiArg['checkoutId'];
  orderId: SplitCheckoutOrderApiArg['orderId'];
  items: OrderItemResponseBody[];
  onComplete?: (isFullCorrectiveDocument?: boolean) => Promise<void>;
}

export function OrderSplitDialog(props: OrderSplitDialogProps) {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const formatNumber = useFormatNumber();

  const [splitOrder, {isLoading}] = useSplitCheckoutOrderMutation();

  const handleItemChange = (itemContextId: string, value: boolean) => {
    if (value) {
      setSelectedItems(pipe(append(itemContextId), uniq));
    } else {
      setSelectedItems(without([itemContextId]));
    }
  };

  const handleSubmit = () => {
    splitOrder({
      checkoutId: props.checkoutId,
      orderId: props.orderId,
      orderSplitRequestBody: {
        itemContextIds: selectedItems,
      },
    })
      .unwrap()
      .then(() => {
        props.onComplete?.();
      })
      .then(closeCurrentDialog)
      .catch(handleApiError);
  };

  return (
    <VStack spacing={4}>
      <Table tableLayout="fixed" data-testid={suffixTestId('table', props)}>
        <TableRow data-testid={suffixTestId('tableRow', props)}>
          <Box paddingVertical={1}>
            <Text size="xSmall" color="secondary">
              {i18n.t('entity.checkout.labels.itemName')}
            </Text>
          </Box>
          <Box paddingVertical={1}>
            <Right>
              <Text size="xSmall" color="secondary">
                {i18n.t('entity.checkout.labels.itemPriceWoVAT')}
              </Text>
            </Right>
          </Box>
          <Box paddingVertical={1}>
            <Right>
              <Text size="xSmall" color="secondary">
                {i18n.t('entity.accounting.labels.vat')}
              </Text>
            </Right>
          </Box>
          <Box paddingVertical={1}>
            <Right>
              <Text size="xSmall" color="secondary">
                {i18n.t('entity.checkout.labels.itemPrice')}
              </Text>
            </Right>
          </Box>
        </TableRow>
        {props.items.map((item, index: number) => (
          <Show when={item.itemContextId} key={item.id}>
            <TableRow data-testid={suffixTestId(index.toString(), props)}>
              <Box paddingVertical={2}>
                <Checkbox
                  value={includes(item.itemContextId ?? item.id, selectedItems)}
                  label={item.name}
                  onChange={(value) => handleItemChange(item.itemContextId ?? item.id, value)}
                  data-testid={suffixTestId(index.toString(), props)}
                />
              </Box>
              <Box paddingVertical={2}>
                <Right>
                  <Text size="small">
                    {item.deductible && item.price.withoutVat ? (
                      <CurrencyFormat
                        number={Number(item.price.withoutVat.amount)}
                        currency={item.price.withoutVat.currency}
                      />
                    ) : (
                      '–'
                    )}
                  </Text>
                </Right>
              </Box>
              <Box paddingVertical={2}>
                <Right>
                  <Text size="small">
                    {item.deductible
                      ? `${formatNumber(Number(Number(item.price.vatRate)), 0)}%`
                      : '–'}
                  </Text>
                </Right>
              </Box>
              <Box paddingVertical={2}>
                <Right>
                  <Text size="small" alternative>
                    <CurrencyFormat
                      number={Number(item.price.withVat.amount)}
                      currency={item.price.withVat.currency}
                    />
                  </Text>
                </Right>
              </Box>
            </TableRow>
          </Show>
        ))}
      </Table>
      <ButtonGroup
        align="right"
        buttons={[
          {
            title: i18n.t('general.actions.cancel'),
            variant: 'secondary',
            onClick: closeCurrentDialog,
            'data-testid': suffixTestId('cancel', props),
          },
          {
            title: i18n.t('general.actions.confirm'),
            onClick: handleSubmit,
            isDisabled: selectedItems.length === 0 || selectedItems.length === props.items.length,
            isLoading,
            'data-testid': suffixTestId('confirm', props),
          },
        ]}
      />
    </VStack>
  );
}
