import {isFeatureEnabled} from 'feature-flags';
import {DataStatus, FormControl, FormField, LabelActions, openDropdown} from 'platform/components';
import {Box, Grid, Hide, Show, Spinner, Text, VStack} from 'platform/foundation';

import {useEffect, useMemo} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {
  useGetContextTemplateListQuery,
  useRenderServiceOrderEmailTemplateMutation,
} from '@dms/api/messaging';
import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';

import {buildArray, isDefined, RequiredTestIdProps, suffixTestId} from 'shared';

import {FormContactPerson} from '../../../hooks/useContactPersons';
import {TemplatesEmailDropdownContent} from '../components/TemplatesEmailDropdownContent';
import {EmailSmsNotificationForm} from '../types/EmailSmsNotificationForm';
import {DocumentsView} from './DocumentsView';

interface EmailSectionProps extends RequiredTestIdProps {
  control: FormControl<EmailSmsNotificationForm>;
  formApi: UseFormReturn<EmailSmsNotificationForm>;
  orderId?: string;
  serviceCaseId: string;
  personsWithEmails: FormContactPerson[];
  isCategoryCustom: boolean;
}

export function EmailSection(props: EmailSectionProps) {
  const [renderTemplate, {isLoading: isRenderTemplateInProgress}] =
    useRenderServiceOrderEmailTemplateMutation();
  const {
    data: templates,
    isLoading: isLoadingTemplates,
    isError: isTemplatesError,
  } = useGetContextTemplateListQuery({
    type: 'email',
    context: 'service-order',
  });
  const {watch, setValue} = props.formApi;

  const enableEmail = watch('email.isEnabled');
  const selectedPerson = watch('email.data.person');

  const availableEmails = useMemo(
    () => props.personsWithEmails.find((item) => item.value === selectedPerson)?.emails || [],
    [props.personsWithEmails, selectedPerson]
  );

  useEffect(() => {
    setValue('email.data.email', availableEmails[0]?.value || '');
  }, [availableEmails, setValue]);

  return (
    <DataStatus isLoading={isLoadingTemplates} isError={isTemplatesError}>
      <FormField
        control={props.control}
        type="switch"
        name="email.isEnabled"
        label={i18n.t('entity.workshop.labels.enableEmailNotifications')}
        data-testid={suffixTestId('email.isEnabled', props)}
      />
      <Show when={enableEmail}>
        <Hide when={props.isCategoryCustom}>
          <Text color="tertiary">{i18n.t('entity.workshop.label.linkToDigitalCertificate')}</Text>
        </Hide>
        <Grid columns={2}>
          <FormField
            control={props.control}
            name="email.data.person"
            type="choice"
            label={i18n.t('entity.businessCase.labels.contactPerson')}
            options={props.personsWithEmails}
            onChange={() => setValue('email.data.email', '')}
            data-testid={suffixTestId('email.data.person', props)}
            isNotClearable
            isRequired
          />
          <FormField
            control={props.control}
            name="email.data.email"
            type="choice"
            label={i18n.t('general.labels.emailAddress')}
            options={availableEmails}
            isDisabled={!selectedPerson}
            data-testid={suffixTestId('email.data.email', props)}
            isNotClearable
            isRequired
          />
        </Grid>
        <Show when={props.isCategoryCustom}>
          <Box position="relative">
            <VStack spacing={4}>
              <Show when={isRenderTemplateInProgress}>
                <Spinner variant="overlay" />
              </Show>
              <FormField
                control={props.control}
                label={i18n.t('general.labels.subject')}
                name="email.data.subject"
                type="text"
                data-testid={suffixTestId('email.data.subject', props)}
                labelActions={buildArray<LabelActions[number]>().when(
                  isFeatureEnabled(featureFlags.MESSAGE_TEMPLATES) && props.orderId,
                  {
                    leftIcon: 'content/add_circle',
                    title: i18n.t('general.labels.templates'),
                    onClick: (e) =>
                      openDropdown(
                        e,
                        <TemplatesEmailDropdownContent
                          formApi={props.formApi}
                          control={props.control}
                          renderTemplate={renderTemplate}
                          templates={templates}
                          serviceCaseId={props.serviceCaseId}
                          serviceOrderId={props.orderId || ' '}
                        />,
                        {
                          isHeightLimited: true,
                        }
                      ),
                  }
                )}
                isRequired
              />
              <FormField
                control={props.control}
                name="email.data.message"
                type="textarea"
                label={i18n.t('general.labels.message')}
                minRows={5}
                data-testid={suffixTestId('email.data.message', props)}
                isResizable
                isRequired
              />
            </VStack>
          </Box>
        </Show>
        {isDefined(props.orderId) && (
          <DocumentsView
            orderId={props.orderId}
            serviceCaseId={props.serviceCaseId}
            data-testid={suffixTestId('document', props)}
          />
        )}
      </Show>
    </DataStatus>
  );
}
