import {pipe, filter, head} from 'ramda';

import {
  GetDocumentSeriesDefinitionListApiResponse,
  InvoicingDocumentTypeUnion,
  DocumentSeriesDefinitionResponseBody,
} from '@dms/api/metadaErrorLogs';

export const getDocumentSystemSeriesId = (
  series: GetDocumentSeriesDefinitionListApiResponse = [],
  documentType: InvoicingDocumentTypeUnion
) =>
  pipe(
    filter(
      (series: DocumentSeriesDefinitionResponseBody) =>
        series.invoicingDocumentType === documentType
    ),
    (series: DocumentSeriesDefinitionResponseBody[]) =>
      series.length === 1 ? head(series) : series.find((item) => item.isSystem),
    (series) => series?.id
  )(series);
