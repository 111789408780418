import {
  IconButton,
  ButtonGroup,
  ButtonProps,
  Portal,
  PortalManager,
  Action,
  Actions,
} from 'platform/components';
import {Box, Heading, HStack, VStack, Scroll, Show} from 'platform/foundation';
import {useTheme} from 'styled-components';

import {ReactNode} from 'react';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

export interface FullScreenModalProps extends TestIdProps {
  headline?: string;
  children?: ReactNode;
  actions?: ButtonProps[];
  headerActions?: Action[];
  onClose?: VoidFunction;
}

export function FullScreenModal(props: FullScreenModalProps) {
  const theme = useTheme();

  const testId = props['data-testid'] ? suffixTestId('wrapper', props) : 'fullScreenModal';

  return (
    <PortalManager zIndex={theme.zIndices.FULLSCREEN_MODAL}>
      <Portal>
        <Box
          position="fixed"
          left={0}
          right={0}
          top={0}
          bottom={0}
          height="100%"
          width="100%"
          backgroundColor="palettes.neutral.20.100"
          data-testid={testId}
        >
          <VStack height="100%">
            <Box
              padding={3}
              borderBottom="1px solid"
              borderColor="general.separator"
              backgroundColor="palettes.white.10.100"
              data-testid={suffixTestId('header', props)}
            >
              <HStack justify="space-between" align="center">
                {props.headline ? <Heading size={3}>{props.headline}</Heading> : null}

                <Show when={isNotNilOrEmpty(props.headerActions)}>
                  <Actions
                    actions={props.headerActions}
                    data-testid={suffixTestId('headerActions', props)}
                  />
                </Show>

                <Show when={isNotNilOrEmpty(props.actions)}>
                  <ButtonGroup
                    data-testid={suffixTestId('actions', props)}
                    buttons={props.actions}
                  />
                </Show>

                {props.onClose ? (
                  <IconButton
                    priority="secondary"
                    onClick={props.onClose}
                    icon="navigation/close"
                    data-testid={suffixTestId('close-button', props)}
                  />
                ) : null}
              </HStack>
            </Box>

            <Scroll auto width="100%" height="100%" data-testid={suffixTestId('content', props)}>
              {props.children}
            </Scroll>
          </VStack>
        </Box>
      </Portal>
    </PortalManager>
  );
}
