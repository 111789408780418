import {metadaApi} from '@dms/api/core';

import {
  GetTreeFolderApiResponse,
  GetTreeFolderApiArg,
  DeleteTreeFolderApiResponse,
  DeleteTreeFolderApiArg,
  PatchTreeFolderApiResponse,
  PatchTreeFolderApiArg,
  PutTreeFolderRelationsApiResponse,
  PutTreeFolderRelationsApiArg,
  PostTreeFolderParentApiResponse,
  PostTreeFolderParentApiArg,
  PostTreeFolderChildApiResponse,
  PostTreeFolderChildApiArg,
  GetTreeFolderPathApiResponse,
  GetTreeFolderPathApiArg,
  TreeFolder,
  GetTreeFoldersApiArg,
  GetTreeFoldersApiResponse,
} from './types';

export const metadaTreeFolderApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getTreeFolder: build.query<GetTreeFolderApiResponse, GetTreeFolderApiArg>({
      query: (queryArg) => ({
        url: `/core/v1/tree-folder/${queryArg.contextTarget}/${queryArg.contextId}`,
      }),
      providesTags: (result, error, queryArg) => [{type: 'treeFolder', id: queryArg.contextId}],
    }),
    getTreeFolders: build.query<TreeFolder[], GetTreeFoldersApiArg>({
      query: (queryArg) => ({
        url: `/core/v1/tree-folder/${queryArg.contextTarget}`,
        method: 'POST',
        body: queryArg.body,
      }),
      transformResponse: (response: GetTreeFoldersApiResponse) => response.treeFolders,
      providesTags: (result, error, queryArg) =>
        queryArg.body.contextIds.map((id) => ({type: 'treeFolder', id})),
    }),
    getTreeFolderPath: build.query<GetTreeFolderPathApiResponse, GetTreeFolderPathApiArg>({
      query: (queryArg) => ({
        url: `/core/v1/tree-folder/leaf/${queryArg.leafId}`,
      }),
    }),
    deleteTreeFolder: build.mutation<DeleteTreeFolderApiResponse, DeleteTreeFolderApiArg>({
      query: (queryArg) => ({
        url: `/core/v1/tree-folder/${queryArg.contextTarget}/${queryArg.contextId}/leaf/${queryArg.leafId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'treeFolder', id: queryArg.contextId}],
    }),
    patchTreeFolder: build.mutation<PatchTreeFolderApiResponse, PatchTreeFolderApiArg>({
      query: (queryArg) => ({
        url: `/core/v1/tree-folder/${queryArg.contextTarget}/${queryArg.contextId}/leaf/${queryArg.leafId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'treeFolder', id: queryArg.contextId}],
    }),
    putTreeFolderRelations: build.mutation<
      PutTreeFolderRelationsApiResponse,
      PutTreeFolderRelationsApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/tree-folder/${queryArg.contextTarget}/${queryArg.contextId}/leaf/${queryArg.leafId}/assign-records`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        ...(queryArg.body?.relation?.target === 'spare-parts-catalog-item'
          ? (queryArg.body?.relation?.ids || []).map((articleId) => ({
              type: 'article' as const,
              id: articleId,
            }))
          : []),
        {type: 'treeFolder', id: queryArg.contextId},
        'labourCatalogItem',
      ],
    }),
    postTreeFolderParent: build.mutation<
      PostTreeFolderParentApiResponse,
      PostTreeFolderParentApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/tree-folder/${queryArg.contextTarget}/${queryArg.contextId}/parent`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'treeFolder', id: queryArg.contextId}],
    }),
    postTreeFolderChild: build.mutation<PostTreeFolderChildApiResponse, PostTreeFolderChildApiArg>({
      query: (queryArg) => ({
        url: `/core/v1/tree-folder/${queryArg.contextTarget}/${queryArg.contextId}/parent/${queryArg.parentId}/child`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'treeFolder', id: queryArg.contextId}],
    }),
  }),
});

export const {
  useGetTreeFolderQuery,
  useGetTreeFoldersQuery,
  useGetTreeFolderPathQuery,
  useLazyGetTreeFolderPathQuery,
  useLazyGetTreeFolderQuery,
  useDeleteTreeFolderMutation,
  usePatchTreeFolderMutation,
  usePutTreeFolderRelationsMutation,
  usePostTreeFolderParentMutation,
  usePostTreeFolderChildMutation,
} = metadaTreeFolderApi;
