import {closeCurrentDialog, DataStatus, openDialog} from 'platform/components';
import {match} from 'ts-pattern';

import {isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {AftersalesPaymentMethod} from '@dms/api/metadaAftersalesCheckout';
import {
  GetDirectSaleResponse,
  useGetDirectSaleCheckoutQuery,
  usePostDirectSaleCheckoutMutation,
} from '@dms/api/metadaWarehouseDirectSale';
import {useGetDirectSaleVariantQuery} from '@dms/api/metadaWarehouseDirectSaleVariant';
import {EntityResourceIds} from '@dms/api/shared';
import i18n from '@dms/i18n';
import {AftersalesCheckout, CustomerMatchOrCreate, handleApiError, logError} from '@dms/shared';

import {buildArray, Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

interface CheckoutTabV2Props extends RequiredTestIdProps {
  directSale: GetDirectSaleResponse | Nullish;
  isEditingDisabled: boolean;
}

export function CheckoutTabV2(props: CheckoutTabV2Props) {
  const {
    data: checkout,
    isLoading: isLoadingCheckout,
    isError: hasCheckoutError,
  } = useGetDirectSaleCheckoutQuery(
    {directSaleId: props.directSale?.id as string},
    {skip: isNilOrEmpty(props.directSale?.id)}
  );

  const {
    data: directSaleVariant,
    isLoading: isLoadingDirectSaleVariant,
    isError: hasDirectSaleVariantError,
  } = useGetDirectSaleVariantQuery(
    {directSaleVariantId: props.directSale?.directSaleVariantId as string},
    {
      skip: isNilOrEmpty(props.directSale?.directSaleVariantId),
    }
  );

  const [postDirectSaleCheckout] = usePostDirectSaleCheckoutMutation();

  const isLoading = isLoadingCheckout || isLoadingDirectSaleVariant;

  const hasError = hasCheckoutError || hasDirectSaleVariantError;

  const disallowedPaymentMethods = buildArray<AftersalesPaymentMethod>()
    .whenNot(directSaleVariant?.documents?.bankPayment?.isEnable, 'BANK_TRANSFER')
    .whenNot(directSaleVariant?.documents?.cardPayment?.isEnable, 'CARD')
    .whenNot(directSaleVariant?.documents?.cashPayment?.isEnable, 'CASH')
    .whenNot(directSaleVariant?.documents?.internalPayment?.isEnable, 'INTERNAL');

  const directSaleVariantDefaultPaymentType = directSaleVariant?.documents?.defaultPaymentType;

  const defaultPaymentType = match(directSaleVariantDefaultPaymentType)
    .with('CARD', 'CASH', 'BANK_TRANSFER', 'INTERNAL', (paymentType) => paymentType)
    .otherwise(() => undefined);

  const handleCreateCheckout = (customerId: string) => {
    if (isNilOrEmpty(props.directSale?.id)) {
      return logError('Missing direct sale id');
    }

    postDirectSaleCheckout({
      directSaleId: props.directSale?.id as string,
      body: {customerId},
    })
      .unwrap()
      .then(closeCurrentDialog)
      .catch(handleApiError);
  };

  const handleAssignCustomer = () =>
    openDialog(
      <CustomerMatchOrCreate
        onCustomer={(customer) => handleCreateCheckout(customer.id)}
        secondStepComponentType="BUSINESS_CASE"
        data-testid={suffixTestId('assignCustomer', props)}
      />,
      {
        title: i18n.t('entity.customer.labels.customer'),
        'data-testid': suffixTestId('dialog.assignCustomer', props),
      }
    );

  return (
    <>
      <DataStatus
        isLoading={isLoading}
        isError={hasError}
        isEmpty={isNil(checkout)}
        emptyMessage={i18n.t('entity.warehouse.labels.noAssignedCustomer')}
        action={{
          title: i18n.t('general.actions.assignCustomer'),
          onClick: handleAssignCustomer,
          isDisabled: props.isEditingDisabled,
        }}
        minHeight={230}
      >
        <AftersalesCheckout
          checkoutId={checkout?.checkoutId ?? ''}
          defaultPaymentType={defaultPaymentType}
          disallowedPaymentMethods={disallowedPaymentMethods}
          recordId={props.directSale?.id}
          resourceId={EntityResourceIds.directSales}
          data-testid={suffixTestId('checkout', props)}
        />
      </DataStatus>
    </>
  );
}
