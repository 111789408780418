import {z} from 'zod';

import {BaseVoidRequestSchema, BaseVoidResponseSchema} from '@dms/api/shared';

/**
 * Base
 */

const BaseSupplierOrderVariant = z.object({
  name: z.string(),
  authorizationProfileId: z.string(),
  orderingWarehouseId: z.string(),
  additionalRequestingWarehouses: z.array(z.string()).nullish(),
  docSeriesId: z.string(),
  defaultSupplierId: z.string().nullish(),
  orderTemplateId: z.string().nullish(),
  isOrderUseIntegration: z.boolean().nullish(),
});

const BaseSupplierOrderVariantSupplierSchema = z.object({
  supplierId: z.string(),
  isDefault: z.boolean(),
});

export type BaseSupplierOrderVariantSupplier = z.infer<
  typeof BaseSupplierOrderVariantSupplierSchema
>;

/**
 * GET
 */
export const GetSupplierOrderVariantsRequestSchema = BaseVoidRequestSchema;
export type GetSupplierOrderVariantsRequest = z.infer<typeof GetSupplierOrderVariantsRequestSchema>;

export const GetSupplierOrderVariantsResponseSchema = z.object({
  isOrderUseIntegration: z.boolean(),
  orderVariantId: z.string(),
  name: z.string(),
});
export type GetSupplierOrderVariantsResponse = z.infer<
  typeof GetSupplierOrderVariantsResponseSchema
>;

export const GetSupplierOrderVariantRequestSchema = z.object({
  orderVariantId: z.string(),
});
export type GetSupplierOrderVariantRequest = z.infer<typeof GetSupplierOrderVariantRequestSchema>;

export const GetSupplierOrderVariantResponseSchema = BaseSupplierOrderVariant.merge(
  z.object({
    orderVariantId: z.string(),
  })
);
export type GetSupplierOrderVariantResponse = z.infer<typeof GetSupplierOrderVariantResponseSchema>;

export const GetSupplierOrderVariantSuppliersRequestSchema = z.object({
  orderVariantId: z.string(),
});
export type GetSupplierOrderVariantSuppliersRequest = z.infer<
  typeof GetSupplierOrderVariantSuppliersRequestSchema
>;

export const GetSupplierOrderVariantSuppliersResponseSchema = z.object({
  supplier: z.array(BaseSupplierOrderVariantSupplierSchema),
});
export type GetSupplierOrderVariantSuppliersResponse = z.infer<
  typeof GetSupplierOrderVariantSuppliersResponseSchema
>;

/**
 * POST
 */
export const PostSupplierOrderVariantRequestSchema = BaseSupplierOrderVariant;
export type PostSupplierOrderVariantRequest = z.infer<typeof PostSupplierOrderVariantRequestSchema>;

export const PostSupplierOrderVariantResponseSchema = z.object({
  orderVariantId: z.string(),
});
export type PostSupplierOrderVariantResponse = z.infer<
  typeof PostSupplierOrderVariantResponseSchema
>;

/**
 * PATCH
 */
export const PatchSupplierOrderVariantRequestSchema = z.object({
  orderVariantId: z.string(),
  body: BaseSupplierOrderVariant,
});
export type PatchSupplierOrderVariantRequest = z.infer<
  typeof PatchSupplierOrderVariantRequestSchema
>;

export const PatchSupplierOrderVariantResponseSchema = BaseVoidResponseSchema;
export type PatchSupplierOrderVariantResponse = z.infer<
  typeof PatchSupplierOrderVariantResponseSchema
>;

/**
 * DELETE
 */
export const DeleteSupplierOrderVariantRequestSchema = z.object({
  body: z.object({
    orderVariantId: z.array(z.string()),
  }),
});
export type DeleteSupplierOrderVariantRequest = z.infer<
  typeof DeleteSupplierOrderVariantRequestSchema
>;

export const DeleteSupplierOrderVariantResponseSchema = BaseVoidResponseSchema;
export type DeleteSupplierOrderVariantResponse = z.infer<
  typeof DeleteSupplierOrderVariantResponseSchema
>;
