import {isEmpty, isNil, isNotEmpty} from 'ramda';

import {ProtectedGroupResponseBody} from '@dms/api/accessControlList';
import i18n from '@dms/i18n';

import {Nullish} from 'shared';

export const filterAndMapResourcesBySearchString =
  (searchString: string | null) => (resources: ProtectedGroupResponseBody[] | Nullish) => {
    if (isNil(resources)) {
      return [];
    }
    if (isNil(searchString) || isEmpty(searchString)) {
      return resources;
    }

    return resources
      .filter(hasMatchingResourcesBySearchString(searchString))
      .map(hasMatchinActionsBySearchString(searchString));
  };

const hasMatchingResourcesBySearchString =
  (searchString: string | null) =>
  (value: ProtectedGroupResponseBody): boolean => {
    if (isNil(searchString)) {
      return true;
    }

    const search = toLowerCaseWithoutDiacritics(searchString);
    const name = toLowerCaseWithoutDiacritics(value.name);

    if (name.includes(search)) {
      return true;
    }

    const filteredSubResources = value.children.filter((subResource) =>
      hasMatchingResourcesBySearchString(searchString)(subResource)
    );

    const isSomeActionMatching = value.protectedUnits.some(
      (action) =>
        toLowerCaseWithoutDiacritics(action.name).includes(search) ||
        toLowerCaseWithoutDiacritics(i18n.t(`acl.${value.name}.${action.name}`)).includes(search)
    );

    return (
      isNotEmpty(filteredSubResources) || (isSomeActionMatching && isEmpty(filteredSubResources))
    );
  };

const hasMatchinActionsBySearchString =
  (searchString: string | null) =>
  (value: ProtectedGroupResponseBody): ProtectedGroupResponseBody => {
    if (isNil(searchString)) {
      return value;
    }

    const search = toLowerCaseWithoutDiacritics(searchString);
    const name = toLowerCaseWithoutDiacritics(value.name);

    if (name.includes(search)) {
      return value;
    }

    return {
      ...value,
      children: value.children
        .map(hasMatchinActionsBySearchString(searchString))
        .filter(filterEmpty),
      protectedUnits: value.protectedUnits.filter(
        (action) =>
          toLowerCaseWithoutDiacritics(action.name).includes(search) ||
          toLowerCaseWithoutDiacritics(i18n.t(`acl.${value.name}.${action.name}`)).includes(search)
      ),
    };
  };

const filterEmpty = (data: ProtectedGroupResponseBody) =>
  isNotEmpty(data.protectedUnits) || isNotEmpty(data.children);

const toLowerCaseWithoutDiacritics = (val: string) =>
  val
    .toLowerCase()
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '');
