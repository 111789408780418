import {Heading, Text, HStack, Hide} from 'platform/foundation';

import {useLocation} from 'react-router-dom';

import i18n from '@dms/i18n';
import {sourcingRoutes} from '@dms/routes';
import {useNavigationBreadCrumb} from '@dms/shared';

import {RequiredTestIdProps} from 'shared';

import {useIsMobileNavigation} from '../hooks/useIsMobileNavigation';
import {HideOnMobileNavigation} from './HideOnMobileNavigation';
import {PageNavigation} from './PageNavigation';
import {ShowOnMobileNavigation} from './ShowOnMobileNavigation';

interface HeaderContentProps extends RequiredTestIdProps {
  title?: string;
}

export function HeaderContent(props: HeaderContentProps) {
  const location = useLocation();
  const isMobileNavigation = useIsMobileNavigation();
  const [, isBreadcrumbVisible] = useNavigationBreadCrumb();

  const title = props.title ? i18n.t(props.title) : EMPTY_TITLE;
  const isHeadingHidden =
    (isBreadcrumbVisible && !isMobileNavigation) || location.pathname.includes(sourcingRoutes.home);

  return (
    <HStack data-testid="layout-headerTitle" shrink={1} grow={1} minWidth={0}>
      <Hide when={isHeadingHidden}>
        <HideOnMobileNavigation>
          <Heading isSingleLine headingLevel={2} size={4}>
            {title}
          </Heading>
        </HideOnMobileNavigation>
        <ShowOnMobileNavigation>
          <Text color="white" size="base" noWrap>
            {title}
          </Text>
        </ShowOnMobileNavigation>
      </Hide>
      <HideOnMobileNavigation>
        <PageNavigation data-testid={props['data-testid']} />
      </HideOnMobileNavigation>
    </HStack>
  );
}

const EMPTY_TITLE = '';
