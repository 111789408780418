import {FormSubmitHandler} from 'platform/components';
import {match} from 'ts-pattern';

import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetBusinessCaseQuery} from '@dms/api/businessCase';
import {
  useDeleteCommentMutation,
  useCreateCommentMutation,
  useGetCommentsQuery,
} from '@dms/api/comment';
import {useGetParticipationQuery} from '@dms/api/participation';
import {EntityResourceIds} from '@dms/api/shared';
import {useGetVehicleQuery} from '@dms/api/vehicle';
import {CommentCardForm, handleApiError, usePermissions} from '@dms/shared';

interface UseCommentActionsProps {
  resourceId: string;
  recordId: string;
}

export const useCommentActions = (props: UseCommentActionsProps) => {
  const [deleteComment, {isLoading: isDeleteCommentLoading}] = useDeleteCommentMutation();
  const [createComment, {isLoading: isSendCommentLoading}] = useCreateCommentMutation();

  const isRecordIdEmpty = isNilOrEmpty(props.recordId);
  const shouldSkipBC = props.resourceId !== EntityResourceIds.businessCase || isRecordIdEmpty;
  const shouldSkipVehicle = props.resourceId !== EntityResourceIds.vehicle || isRecordIdEmpty;

  const {
    data: comments,
    isLoading: isFetchingCommentsLoading,
    isError: isFetchingCommentsErrored,
  } = useGetCommentsQuery({
    resourceId: props.resourceId,
    recordId: props.recordId,
  });

  const {data: businessCase} = useGetBusinessCaseQuery(
    {businessCaseId: props.recordId},
    {skip: shouldSkipBC}
  );

  const {data: vehicleData} = useGetVehicleQuery(
    {vehicleId: props.recordId},
    {skip: shouldSkipVehicle}
  );

  const {data: vehicleParticipation} = useGetParticipationQuery(
    {
      resourceId: EntityResourceIds.vehicle,
      recordId: props.recordId,
    },
    {skip: shouldSkipVehicle}
  );

  const {data: businessCaseParticipation} = useGetParticipationQuery(
    {
      resourceId: EntityResourceIds.businessCase,
      recordId: props.recordId,
    },
    {skip: shouldSkipBC}
  );

  const [
    hasVehicleCommentReadPermission,
    hasVehicleCommentAddPermission,
    hasVehicleCommentDeletePermission,
    hasBusinessCaseCommentReadPermission,
    hasBusinessCaseCommentAddPermission,
    hasBusinessCaseCommentDeletePermission,
  ] = usePermissions({
    permissionKeys: [
      'vehicleCommentRead',
      'vehicleCommentAdd',
      'vehicleCommentDelete',
      'businessCaseCommentRead',
      'businessCaseCommentAdd',
      'businessCaseCommentDelete',
    ],
    scopes: {
      vehicleCommentAdd: {
        participation: vehicleParticipation,
        branchId: vehicleData?.branchId,
      },
      vehicleCommentRead: {
        participation: vehicleParticipation,
        branchId: vehicleData?.branchId,
      },
      vehicleCommentDelete: {
        participation: vehicleParticipation,
        branchId: vehicleData?.branchId,
      },
      businessCaseCommentRead: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      businessCaseCommentAdd: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      businessCaseCommentDelete: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
    },
  });

  const onCommentSubmit: FormSubmitHandler<CommentCardForm> = (values, _, reset) =>
    createComment({
      resourceId: props.resourceId,
      recordId: props.recordId,
      createCommentRequestBody: {
        text: values.comment,
      },
    })
      .unwrap()
      .then(() => {
        reset();
      })
      .catch(handleApiError);

  const handleCommentDelete = (commentId: string) => {
    deleteComment({
      resourceId: props.resourceId,
      recordId: props.recordId,
      commentId,
    })
      .unwrap()
      .catch(handleApiError);
  };

  const isLoading = isDeleteCommentLoading || isSendCommentLoading || isFetchingCommentsLoading;
  const isError = isFetchingCommentsErrored;

  const {canDeleteComments, canAddComments} = match(props.resourceId)
    .with(EntityResourceIds.interest, () => ({
      canDeleteComments: true,
      canAddComments: true,
    }))
    .with(EntityResourceIds.businessCase, () => ({
      canDeleteComments: hasBusinessCaseCommentDeletePermission,
      canAddComments: hasBusinessCaseCommentAddPermission,
    }))
    .with(EntityResourceIds.vehicle, () => ({
      canDeleteComments: hasVehicleCommentDeletePermission,
      canAddComments: hasVehicleCommentAddPermission,
    }))
    .otherwise(() => ({
      canDeleteComments: false,
      canAddComments: false,
    }));

  return {
    comments,
    isLoading,
    isError,
    onCommentSubmit,
    handleCommentDelete,
    canDeleteComments,
    canAddComments,
    hasBusinessCaseCommentReadPermission,
    hasVehicleCommentReadPermission,
  };
};
