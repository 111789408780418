import {CardVariantType, DataStatus} from 'platform/components';

import {useGetAdditionalNoteQuery, useSetAdditionalNoteMutation} from '@dms/api/vehicle';

import {TestIdProps, useIsLoadingWithInitialError} from 'shared';

import {handleApiError} from '../../utils/handleApiError';
import {NoteCard, NoteCardForm} from '../NoteCard/NoteCard';

interface VehicleNoteWidgetProps extends TestIdProps {
  vehicleId: string;
  title?: string;
  cardVariant?: CardVariantType;
}

export function VehicleNoteWidget(props: VehicleNoteWidgetProps) {
  const {
    data: note,
    isLoading: isNoteLoading,
    isError,
  } = useGetAdditionalNoteQuery({
    vehicleId: props.vehicleId,
  });

  const [saveNote, {isLoading: isSaveLoading}] = useSetAdditionalNoteMutation();

  const isLoading = useIsLoadingWithInitialError(isNoteLoading, isError);

  const onChange = (values: NoteCardForm | undefined) =>
    saveNote({
      vehicleId: props.vehicleId,
      additionalNoteRequestBody: {
        note: values?.note ?? '',
      },
    })
      .unwrap()
      .then()
      .catch(handleApiError);

  return (
    <DataStatus isLoading={isLoading}>
      <NoteCard
        cardVariant={props.cardVariant}
        note={note?.note}
        authorId={note?.author.id}
        datetime={note?.createdAt}
        title={props.title}
        onChange={onChange}
        isLoading={isSaveLoading}
        data-testid={props['data-testid']}
      />
    </DataStatus>
  );
}
