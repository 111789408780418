import {Button, DataStatus, Label, OptionTypeBase, Separator} from 'platform/components';
import {HStack, VStack} from 'platform/foundation';

import {useState} from 'react';
import {Options} from 'react-select';

import {isNil} from 'ramda';

import {
  SeriesType,
  SeriesTypeResponseBody,
  useGetSeriesListQuery,
  useGetSeriesTypeListQuery,
} from '@dms/api/series';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';

import {useNavigate} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SearchRow} from './components/SearchRow';
import {SeriesTable} from './components/SeriesTable';
import {DropdownMultiStyled, StyledCard} from './components/styles';

const EMPTY_FILTERS = {
  search: '',
  type: [],
};

export function SeriesDefinition() {
  const navigate = useNavigate();

  const [search, setSearch] = useState<string>(EMPTY_FILTERS.search);
  const [type, setType] = useState<SeriesType[]>(EMPTY_FILTERS.type);

  const {
    data: series,
    isLoading: isLoadingSeriesList,
    isFetching: isFetchingSeriesList,
  } = useGetSeriesListQuery({search, type}, {refetchOnMountOrArgChange: true});
  const {data: seriesTypes, isLoading: isLoadingSeriesTypesList} = useGetSeriesTypeListQuery();

  const onDocumentTypeChange = (types: Options<OptionTypeBase<string>> | undefined) => {
    if (isNil(types)) {
      return;
    }

    setType(types.map((option) => option.value) as SeriesType[]);
  };

  const documentSeriesTypeOptions = seriesTypes?.map(seriesToOption) ?? [];

  return (
    <SettingsTemplate
      header={{title: i18n.t('entity.document.labels.seriesTitle')}}
      data-testid="general-document-series-modal"
    >
      <VStack spacing={2}>
        <HStack justify="flex-end" data-testid="general-document-series-filter-container">
          <VStack>
            <Label>{i18n.t('entity.series.labels.documentType')}</Label>
            <DropdownMultiStyled
              id="series-list-document-type"
              options={documentSeriesTypeOptions}
              placeholder={i18n.t('general.labels.dropdownMultiPlaceholderAll')}
              onChange={onDocumentTypeChange}
            />
          </VStack>
        </HStack>
        <StyledCard
          title={i18n.t('entity.seriesTable.labels.title')}
          headerContent={
            <Button
              variant="link"
              leftIcon="content/add_circle"
              title={i18n.t('entity.document.actions.newSeries')}
              onClick={() => navigate(settingsRoutes.seriesDefinitionNew)}
              data-testid={testIds.settings.seriesDefinition('new')}
            />
          }
          noPadding
        >
          <SearchRow onSearch={setSearch} data-testid="series-table-search-row" />
          <Separator spacing={0} orientation="horizontal" />
          <DataStatus
            isLoading={isLoadingSeriesList || isLoadingSeriesTypesList || isFetchingSeriesList}
            isEmpty={series?.length === 0}
            spacing={5}
          >
            <SeriesTable series={series} seriesTypes={seriesTypes} />
          </DataStatus>
        </StyledCard>
      </VStack>
    </SettingsTemplate>
  );
}

const seriesToOption = (series: SeriesTypeResponseBody) => ({
  value: series.code,
  label: series.name,
});
