import {
  Attributes,
  AttributesRow,
  Button,
  ButtonGroup,
  Card,
  ColorSchemeType,
  DataStatus,
  DialogFooter,
  Flag,
  Separator,
} from 'platform/components';
import {
  HStack,
  Icon,
  ThemeColorPath,
  Text,
  ThemeIconKey,
  ThemeColorTextPath,
  Show,
  VStack,
} from 'platform/foundation';
import {useDateTimeFormatter, useFormatNumber} from 'platform/locale';
import {match} from 'ts-pattern';

import {Fragment} from 'react';

import {always} from 'ramda';

import {useGetServiceCaseIntegrationsClaraClaimDetailQuery} from '@dms/api/metadaWorkshopIntegration';
import i18n from '@dms/i18n';

import {TestIdProps, buildArray, parseDate, suffixTestId} from 'shared';

import {getExternalStatusLabel} from '../utils/getExternalStatusLabel';

interface ClaraDetailDialogProps extends TestIdProps {
  serviceCaseId: string;
  serviceOrderId: string;
  onClose: () => void;
}

export function ClaraDetailDialog(props: ClaraDetailDialogProps) {
  const formatDate = useDateTimeFormatter();
  const formatNumber = useFormatNumber();

  const {data, isLoading, isError} = useGetServiceCaseIntegrationsClaraClaimDetailQuery(
    {
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.serviceOrderId,
    },
    {refetchOnMountOrArgChange: true}
  );

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <VStack spacing={4}>
        <Attributes
          rows={buildArray<AttributesRow>([
            {
              label: i18n.t('entity.claimType.labels.state'),
              content: (
                <Flag
                  label={getExternalStatusLabel(data?.status)}
                  colorScheme={match(data?.statusLevel)
                    .with('error', always<ColorSchemeType>('red'))
                    .with('warning', always<ColorSchemeType>('orange'))
                    .with('success', always<ColorSchemeType>('green'))
                    .with('info', always<ColorSchemeType>('blue'))
                    .otherwise(always(undefined))}
                  size="small"
                />
              ),
            },
            {label: i18n.t('general.labels.number'), value: data?.claimNumber},
            {
              label: i18n.t('general.labels.type'),
              value: match(data?.claimType)
                .with('0', always(i18n.t('entity.vehicle.labels.vehicle')))
                .with('1', always(i18n.t('entity.claimType.labels.part')))
                .otherwise(always(undefined)),
            },
          ])
            .when(data?.claimType === '1', {
              label: i18n.t('entity.claimType.labels.partNumber'),
              value: data?.partNumber,
            })
            .when(data?.claimType === '1', {
              label: i18n.t('entity.claimType.labels.partPurchaseDate'),
              value: data?.partPurchaseDate
                ? formatDate('dateShort', parseDate(data.partPurchaseDate))
                : undefined,
            })
            .when(data?.claimType === '1', {
              label: i18n.t('entity.claimType.labels.mileagePartPurchase'),
              value: `${formatNumber(data?.mileagePartPurchase, 0)} ${i18n.t('general.metric.km')}`,
            })
            .add({
              label: i18n.t('entity.claimType.labels.repairStartDate'),
              value: data?.repairStartDate
                ? formatDate('dateShort', parseDate(data.repairStartDate))
                : undefined,
            })
            .add({
              label: i18n.t('entity.claimType.labels.mileageStart'),
              value: `${formatNumber(data?.mileageStart, 0)} ${i18n.t('general.metric.km')}`,
            })
            .add({
              label: i18n.t('entity.claimType.labels.repairEndDate'),
              value: data?.repairEndDate
                ? formatDate('dateShort', parseDate(data.repairEndDate))
                : undefined,
            })
            .add({
              label: i18n.t('entity.claimType.labels.mileageEnd'),
              value: `${formatNumber(data?.mileageEnd, 0)} ${i18n.t('general.metric.km')}`,
            })}
        />
        <Show when={data?.issues?.length}>
          <Card
            title={i18n.t('entity.claimType.labels.issues') + ` (${data?.issues?.length})`}
            variant="inlineGrey"
          >
            {data?.issues?.map((issue, index) => (
              <Fragment key={issue.content}>
                <Show when={index > 0}>
                  <Separator spacing={2} />
                </Show>
                <HStack spacing={2}>
                  <Icon
                    color={match(issue.level)
                      .with('info', always<ThemeColorPath>('severity.informational'))
                      .with('warning', always<ThemeColorPath>('severity.warning'))
                      .with('success', always<ThemeColorPath>('severity.success'))
                      .with('error', always<ThemeColorPath>('severity.danger'))
                      .otherwise(always(undefined))}
                    value={match(issue.level)
                      .with('info', always<ThemeIconKey>('action/info'))
                      .with('warning', always<ThemeIconKey>('alert/warning'))
                      .with('success', always<ThemeIconKey>('action/check_circle'))
                      .with('error', always<ThemeIconKey>('alert/error'))
                      .otherwise(always(undefined))}
                    size={4}
                  />
                  <Text
                    size="xSmall"
                    color={match(issue.level)
                      .with('error', always<ThemeColorTextPath>('danger'))
                      .with('warning', always<ThemeColorTextPath>('warning'))
                      .with('success', always<ThemeColorTextPath>('success'))
                      .with('info', always<ThemeColorTextPath>('informational'))
                      .otherwise(always(undefined))}
                  >
                    {issue.content}
                  </Text>
                </HStack>
              </Fragment>
            ))}
          </Card>
        </Show>
      </VStack>
      <DialogFooter>
        <ButtonGroup align="right">
          <Button
            title={i18n.t('general.actions.close')}
            variant="secondary"
            onClick={props.onClose}
            data-testid={suffixTestId('close', props)}
          />
        </ButtonGroup>
      </DialogFooter>
    </DataStatus>
  );
}
