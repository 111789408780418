import {z} from 'zod';

import {PaymentTypeSchema} from '@dms/api/shared';

export const DeliveryNoteFormSchema = z.object({
  supplierId: z.string(),
  warehouseId: z.string(),
  authorizationProfileId: z.string(),
  invoiceNumber: z.string().nullish(),
  paymentType: PaymentTypeSchema,
  currency: z.string(),
  exchangeRate: z.number().nullish(),
  ncConversion: z.string().nullish(),
});

export type DeliveryNoteForm = z.infer<typeof DeliveryNoteFormSchema>;
