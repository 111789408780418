import {isNil, reject} from 'ramda';

import {UserResponseBodyV2} from '@dms/api/document';
import {EMPTY_PLACEHOLDER} from '@dms/shared';

export const formatUpdatedByName = (user: UserResponseBodyV2) => {
  if (isNil(user)) {
    return EMPTY_PLACEHOLDER;
  }

  return reject(isNil, [user?.firstName, user?.lastName]).join(' ');
};
