import {Button, Tooltip} from 'platform/components';
import {Link} from 'platform/foundation';

import {ActivityItemDataLink} from '../../types/ActivitySidebarAction';

export function ActivitySidebarLink(props: ActivityItemDataLink) {
  if (props.onClick) {
    return (
      <Button
        variant="link"
        size="small"
        title={props.label}
        leftIcon={props.icon ?? 'action/open_in_new'}
        onClick={props.onClick}
      />
    );
  }

  if (props.tooltip) {
    return (
      <Tooltip description={props.tooltip[0]} label={props.tooltip[1]}>
        <Link size="xSmall" iconSize={3} title={props.label} />
      </Tooltip>
    );
  }

  return (
    <Link
      size="xSmall"
      iconSize={3}
      leftIcon={props.icon ?? 'action/open_in_new'}
      href={props.url}
      title={props.label}
    />
  );
}
