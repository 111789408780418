import {Action, Card, useDialog} from 'platform/components';
import {Box} from 'platform/foundation';

import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {mergeAll} from 'ramda';

import {documentContextApi} from '@dms/api/documentContext';
import featureFlags from '@dms/feature-flags';
import {useGetDocumentDatagridActions} from '@dms/features/document-context';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {Main, UploadDocumentsDialog} from '@dms/shared';

import {buildArray, useRequiredParams} from 'shared';

import {DataGrid, QueryFilterObject, useRefreshDataGrid} from 'features/datagrid';

export function VehicleDocuments() {
  const {id: vehicleId} = useRequiredParams();

  const dispatch = useDispatch();

  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();
  const [actionCallback, openBulkSignatureStepper] = useGetDocumentDatagridActions({
    contextId: vehicleId,
    contextTarget: 'vehicle',
    refreshData: refreshDataGrid,
  });

  const [isUploadDialogOpen, openUploadDialog, closeUploadDialog] = useDialog();

  const onDocumentCreated = () => {
    refreshDataGrid();

    dispatch(
      documentContextApi.util.invalidateTags([{type: 'documentsCount', id: vehicleId ?? ''}])
    );
  };

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {vehicleId}]),
    [vehicleId]
  );

  return (
    <Main>
      <Card
        title={i18n.t('page.vehicle.documents.title')}
        actions={buildArray<Action>([
          {
            variant: 'link',
            type: 'button',
            leftIcon: 'action/backup',
            onClick: openUploadDialog,
            title: i18n.t('entity.document.actions.uploadDocument'),
          },
        ]).whenFeatureEnabled(featureFlags.DIGITAL_SIGNATURE, {
          type: 'button',
          variant: 'link',
          leftIcon: 'image/edit',
          title: i18n.t('entity.document.actions.signMultipleDocument'),
          onClick: openBulkSignatureStepper,
        })}
      >
        <Box minHeight={40}>
          <DataGrid
            ref={dataGridRef}
            gridCode="document-context-list-vehicle"
            emptyState={{
              headline: i18n.t('page.vehicle.notifications.noDocuments'),
              subheadline: i18n.t('page.vehicle.notifications.documentListWillAppearHere'),
            }}
            actionCallback={actionCallback}
            data-testid={testIds.vehicles.documents('document-context-list-vehicle')}
            autoHeight
            queryModifier={queryModifier}
          />
        </Box>
      </Card>
      <UploadDocumentsDialog
        isOpen={isUploadDialogOpen}
        handleClose={closeUploadDialog}
        onDocumentCreated={onDocumentCreated}
        contextTarget="vehicle"
        contextId={vehicleId}
      />
    </Main>
  );
}
