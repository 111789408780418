import {Button, ButtonGroup} from 'platform/components';
import {Heading, VStack, Text, HStack} from 'platform/foundation';

import {GetEmployeeMechanicsApiResponse} from '@dms/api/metadaEmployee';
import {usePutServiceOrderJobMechanicsMutation} from '@dms/api/metadaWorkshopServiceOrderJob';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {DOT_CHARACTER, RequiredTestIdProps, suffixTestId} from 'shared';

interface ConfirmAssignMechanicProps extends RequiredTestIdProps {
  employeeMechanics: GetEmployeeMechanicsApiResponse;
  mechanics: string[];
  orderId: string;
  serviceJobId: string;
  serviceCaseId: string;
  onClose: () => void;
  onConfirm: () => void;
}

export function ConfirmAssignMechanic(props: ConfirmAssignMechanicProps) {
  const [putServiceOrderJobMechanics, {isLoading}] = usePutServiceOrderJobMechanicsMutation();

  const putMechanicsAction = async (shouldUpdateJobItems: boolean) =>
    await putServiceOrderJobMechanics({
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.orderId,
      serviceJobId: props.serviceJobId,
      body: {
        assignMechanics: props.mechanics?.map((mechanic, index) => ({
          id: mechanic,
          isDefault: index === 0,
          costCenterId: props.employeeMechanics?.employees?.find(
            (employee) => employee?.id === mechanic
          )?.costCenterId,
        })),
        shouldUpdateJobItems,
      },
    })
      .unwrap()
      .then(() => props.onConfirm())
      .catch(handleApiError);

  return (
    <VStack spacing={4}>
      <Heading size={4}>{i18n.t('entity.serviceJob.labels.confirmAssignMechanic')}</Heading>
      <VStack spacing={2}>
        <HStack spacing={2}>
          <Text>{DOT_CHARACTER}</Text>
          <Text>{i18n.t('entity.serviceJob.labels.confirmAssignMechanicApply')}</Text>
        </HStack>
        <HStack spacing={2}>
          <Text>{DOT_CHARACTER}</Text>
          <Text>{i18n.t('entity.serviceJob.labels.confirmAssignMechanicDontApply')}</Text>
        </HStack>
      </VStack>
      <ButtonGroup align="right">
        <Button
          title={i18n.t('general.actions.discard')}
          variant="secondary"
          onClick={props.onClose}
          data-testid={suffixTestId('discard', props)}
          isDisabled={isLoading}
        />
        <Button
          title={i18n.t('general.actions.dontApply')}
          variant="secondary"
          onClick={() => putMechanicsAction(false)}
          data-testid={suffixTestId('dont-apply', props)}
          isDisabled={isLoading}
        />
        <Button
          onClick={() => putMechanicsAction(true)}
          title={i18n.t('general.actions.apply')}
          variant="primary"
          data-testid={suffixTestId('apply', props)}
          isDisabled={isLoading}
        />
      </ButtonGroup>
    </VStack>
  );
}
