import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';

export const getPositionOptions = (position: 'top' | 'center' | 'bottom') => [
  {value: `${position}_left`, label: ''},
  {value: `${position}_center`, label: ''},
  {value: `${position}_right`, label: ''},
];

export const addToOptions = [
  {
    value: 'ONLY_FIRST_PHOTO',
    label: i18n.t('entity.watermark.labels.addToFirstPhoto'),
    requiredFeatureFlag: null,
  },
  {
    value: 'ONLY_INTERIOR_PHOTOS',
    label: i18n.t('entity.watermark.labels.addToInteriorPhotos'),
    requiredFeatureFlag: featureFlags.SETTINGS_WATERMARKS_CATEGORIES,
  },
  {
    value: 'ONLY_EXTERIOR_PHOTOS',
    label: i18n.t('entity.watermark.labels.addToExteriorPhotos'),
    requiredFeatureFlag: featureFlags.SETTINGS_WATERMARKS_CATEGORIES,
  },
  {
    value: 'ALL_PHOTOS',
    label: i18n.t('entity.watermark.labels.addToAllPhotos'),
    requiredFeatureFlag: null,
  },
];
