import {openDeleteDialog, showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {head} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {useDeleteCorrectiveTaxDocumentSettingMutation} from '@dms/api/accounting';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export const CorrectiveTaxDocuments = () => {
  const navigate = useNavigate();
  const [deleteCorrectiveTaxDocument] = useDeleteCorrectiveTaxDocumentSettingMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData}) => {
    const id = isArray(rowId) ? head(rowId) : rowId;
    if (!id) {
      throw new Error('Invalid data for callback');
    }

    match(actionKey)
      .with('DELETE', () =>
        openDeleteDialog({
          onConfirm: () =>
            deleteCorrectiveTaxDocument({
              id,
            })
              .unwrap()
              .then(() => showNotification.success())
              .then(refreshData)
              .catch(handleApiError),
        })
      )
      .with('EDIT', 'DETAIL', () =>
        navigate(composePath(settingsRoutes.correctiveTaxDocumentsEdit, {params: {id}}))
      );
  };

  return (
    <SettingsTemplate
      data-testid={testIds.settings.correctiveTaxDocuments('list')}
      header={{
        title: i18n.t('entity.correctiveTaxDocumentSetting.labels.list'),
        subtitle: i18n.t('entity.correctiveTaxDocumentSetting.labels.listDescription'),
        actions: [
          {
            type: 'button',
            onClick: () => navigate(settingsRoutes.correctiveTaxDocumentsNew),
            title: i18n.t('entity.correctiveTaxDocumentSetting.labels.add'),
          },
        ],
      }}
    >
      <DataGrid
        gridCode="accounting-corrective-tax-document-setting"
        actionCallback={actionCallback}
        data-testid={testIds.settings.correctiveTaxDocuments('dg')}
      />
    </SettingsTemplate>
  );
};
