import {BigCalendar, BigCalendarEvent} from 'platform/big-calendar';

import {useState} from 'react';

import {Main} from '@dms/shared';

import {mockEvents} from './mockEvents';

export function BigCalendarPlayground() {
  const [events, setEvents] = useState<BigCalendarEvent[]>(mockEvents);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());

  const handleEventDragMove = async (event: BigCalendarEvent, start: Date, end: Date) => {
    await setEvents((currentEvents) =>
      currentEvents.map((e) =>
        e.id === event.id
          ? {
              ...e,
              start,
              end,
            }
          : e
      )
    );
  };

  const handleEventClick = (event: BigCalendarEvent) => {
    // eslint-disable-next-line no-console
    console.log('Event clicked:', event);
  };

  const handleDateSelect = (date: Date) => {
    // eslint-disable-next-line no-console
    console.log('Date selected:', date);
  };

  return (
    <Main isFullHeight>
      <BigCalendar
        data-testid="demo-scheduler"
        currentDate={currentDate}
        currentUser={{
          id: '1',
          fullName: 'John Doe',
          email: 'john.doe@example.com',
        }}
        events={events}
        onNavigate={setCurrentDate}
        onEventDragMove={handleEventDragMove}
        onEventClick={handleEventClick}
        onDateClick={handleDateSelect}
        defaultView="week"
      />
    </Main>
  );
}
