import {Option} from 'platform/components';

import {isNil} from 'ramda';

import {GetWarehousesResponse} from '@dms/api/metadaWarehouse';

import {Nullish} from 'shared';

export const getOptionsFromWarehouses = (warehouses: GetWarehousesResponse | Nullish): Option[] => {
  if (isNil(warehouses)) {
    return [];
  }

  return warehouses.map((warehouse) => ({
    label: warehouse.name,
    value: warehouse.id,
  }));
};
