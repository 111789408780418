import {captureException} from '@sentry/browser';
import {openDB, IDBPDatabase} from 'idb';

import {ApiCacheDB} from './types/ApiCacheDB';

const DB_NAME = 'omnetic-rtk-cache';
const STORE_NAME = 'apiCache';
const DB_VERSION = 1;

// Database connection singleton
let dbPromise: Promise<IDBPDatabase<ApiCacheDB>> | null = null;

const isIndexedDBSupported = () => {
  try {
    return 'indexedDB' in window && window.indexedDB !== null;
  } catch {
    return false;
  }
};

/**
 * Get or initialize the IndexedDB database connection
 */
const getDB = () => {
  if (!isIndexedDBSupported()) {
    console.error('IndexedDB is not supported in this browser');
    return Promise.reject();
  }
  if (!dbPromise) {
    dbPromise = openDB<ApiCacheDB>(DB_NAME, DB_VERSION, {
      upgrade(db) {
        // Create object store if it doesn't exist
        if (!db.objectStoreNames.contains(STORE_NAME)) {
          db.createObjectStore(STORE_NAME);
        }
      },
    });
  }
  return dbPromise;
};

/**
 * Save data to IndexedDB
 */
export const saveToIDB = async (key: string, value: unknown): Promise<void> => {
  try {
    const db = await getDB();
    await db.put(STORE_NAME, value, key);
  } catch (error) {
    console.error('Error when saving to IndexedDB:', error);
    captureException(error, {
      contexts: {indexedDB: {operation: 'save', key}},
    });
  }
};

/**
 * Load data from IndexedDB
 */
export const loadFromIDB = async (key: string): Promise<unknown | null> => {
  try {
    const db = await getDB();
    const data = await db.get(STORE_NAME, key);

    return data || null;
  } catch (error) {
    console.error('Error when loading from IndexedDB:', error);
    captureException(error, {
      contexts: {indexedDB: {operation: 'load', key}},
    });
    return null;
  }
};

/**
 * Delete data from IndexedDB
 */
export const deleteFromIDB = async (key: string): Promise<void> => {
  try {
    const db = await getDB();
    await db.delete(STORE_NAME, key);
  } catch (error) {
    console.error('Error when deleting from IndexedDB:', error);
    captureException(error, {
      contexts: {indexedDB: {operation: 'delete', key}},
    });
  }
};

/**
 * Clear all data from IndexedDB
 */
export const purgeCache = async (): Promise<void> => {
  try {
    const db = await getDB();
    await db.clear(STORE_NAME);
  } catch (error) {
    console.error('Error when clearing IndexedDB:', error);
    captureException(error, {
      contexts: {indexedDB: {operation: 'clear'}},
    });
  }
};

export const storage = {
  saveToIDB,
  loadFromIDB,
  deleteFromIDB,
  purgeCache,
};
