import {useDroppable} from '@dnd-kit/core';
import {css, useTheme} from 'styled-components';

import {parseDate, pushGtmEvent, RequiredTestIdProps, suffixTestId} from 'shared';

import {SLOT_HEIGHT_IN_PIXELS} from '../constants/slotHeight';
import {useDnd} from '../hooks/useDnd';
import {BigCalendarEvent} from '../types/BigCalendarEvent';
import {calculateIntervalCount} from '../utils/calculateIntervalCount';
import {ContentCell} from './ContentCell';

/**
 * Props for the DroppableInterval component
 * @interface DroppableIntervalProps
 * @property {string} id - Unique identifier for the droppable interval
 * @property {number} top - Top position in pixels for the interval
 * @property {number} height - Height in pixels for the interval
 */
interface DroppableIntervalProps extends RequiredTestIdProps {
  id: string;
  top: number;
  height: number;
}

/**
 * Renders a droppable interval zone within the scheduler cell
 * @component
 * @param {DroppableIntervalProps} props - Component props
 * @returns A droppable interval div that changes appearance when an event is dragged over it
 */
function DroppableInterval(props: DroppableIntervalProps) {
  const theme = useTheme();
  const {setNodeRef, isOver} = useDroppable({
    id: props.id,
  });
  const {isResizing} = useDnd();
  return (
    <div
      ref={setNodeRef}
      css={css`
        position: absolute;
        height: ${props.height}px;
        width: 100%;
        top: ${props.top}px;
        left: 1px;
        right: 0;
        bottom: 1px;
        background-color: ${theme.colors.palettes.neutral[30][100]};
        opacity: ${isOver && !isResizing ? 1 : 0};
        pointer-events: auto;
        will-change: opacity;
      `}
      data-testid={suffixTestId('droppableInterval', props)}
    />
  );
}

/**
 * Props for the DroppableCell component
 * @interface DroppableCellProps
 * @property {Date} date - The date associated with this cell
 * @property {number} hour - The hour (0-23) this cell represents
 * @property {BigCalendarEvent} [draggedEvent] - The event being dragged, if any
 * @property {(date: Date) => void} [onDateClick] - Callback function when the cell is clicked
 */
interface DroppableCellProps extends RequiredTestIdProps {
  date: Date;
  hour: number;
  canCreateEvent?: boolean;
  draggedEvent?: BigCalendarEvent;
  onDateClick?: (date: Date) => void;
}

/**
 * Renders a droppable cell in the scheduler grid that can receive dragged events
 * @component
 * @param {DroppableCellProps} props - Component props
 * @returns A cell containing droppable intervals and optional child elements
 *
 * @example
 * ```tsx
 * <DroppableCell
 *   date={new Date()}
 *   hour={9}
 *   draggedEvent={someEvent}
 *   onDateClick={(date) => console.log(date)}
 * />
 * ```
 */
export function DroppableCell(props: DroppableCellProps) {
  /**
   * Handles click events on the cell
   * Creates a new Date object with the cell's date and hour, then calls the onDateClick callback
   */
  const onCellClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (typeof props.onDateClick !== 'function') {
      return;
    }

    const clickedDate = parseDate(props.date);
    clickedDate.setHours(props.hour);

    pushGtmEvent({
      event: 'button_click',
      event_id: 'big_calendar_droppable_cell_click',
      value: clickedDate.toISOString(),
    });

    props.onDateClick(clickedDate);
  };

  /**
   * Calculates the number of 15-minute intervals that the dragged event spans
   *
   * @remarks
   * The calculation works by:
   * 1. Taking the difference between end and start time in milliseconds
   * 2. Converting it to 15-minute intervals by dividing by (1000ms * 60s * 15min)
   * 3. Rounding up to ensure partial intervals are counted as full
   *
   * @returns {number} Number of 15-minute intervals, or 0 if no event is being dragged
   */
  const intervalCount = props.draggedEvent
    ? calculateIntervalCount(props.draggedEvent.start, props.draggedEvent.end)
    : 0;

  const height = props.draggedEvent?.isAllDay ? ALL_DAY_HEIGHT : INTERVAL_HEIGHT * intervalCount;

  return (
    <div
      css={css`
        position: relative;
        height: ${SLOT_HEIGHT_IN_PIXELS}px;
        width: 100%;
      `}
      data-testid={suffixTestId('droppableCell', props)}
    >
      {INTERVALS.map((minutes) => {
        const dropDate = parseDate(props.date);
        dropDate.setHours(props.hour, minutes, 0, 0);
        const dropId = dropDate.getTime().toString();

        return (
          <DroppableInterval
            key={dropId}
            data-testid={suffixTestId(`[${minutes}]`, props)}
            id={dropId}
            top={minutes * (INTERVAL_HEIGHT / 15)}
            height={height}
          />
        );
      })}
      <ContentCell
        data-testid={suffixTestId('droppableCell-contentCell', props)}
        isClickDisabled={typeof props.onDateClick !== 'function'}
        onCellClick={onCellClick}
      />
    </div>
  );
}

/**
 * Array representing the minutes at which intervals occur within an hour (0, 15, 30, 45)
 * Used to create droppable zones for 15-minute intervals in the scheduler
 */
const INTERVALS = [0, 15, 30, 45];

/**
 * Height in pixels of a single 15-minute interval
 * Calculated by dividing the total slot height by 4 (since there are 4 intervals per hour)
 */
const INTERVAL_HEIGHT = SLOT_HEIGHT_IN_PIXELS / 4;

/**
 * Height in pixels for all-day events
 * Calculated by multiplying the interval height by 96 (representing a full 24-hour period)
 * This ensures all-day events span the entire vertical height of the calendar day
 */
const ALL_DAY_HEIGHT = INTERVAL_HEIGHT * 96;
