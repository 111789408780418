import {has} from 'ramda';

import {columns} from '../constants/columns';

// Default column to use if state key is not found in columns mapping
export const DEFAULT_COLUMN = 0; // Using NEW (0) as the default fallback column

/**
 * Gets the column number for a state key with validation
 * @param stateKey The state key to look up
 * @returns The column number or the default column if not found
 */
export function getColumnForState(stateKey: string | undefined): number | null {
  if (!stateKey) {
    return null;
  }

  const columnValue = columns[stateKey];

  if (columnValue === undefined) {
    return has(stateKey, columns) ? columns[stateKey] : DEFAULT_COLUMN;
  }

  return columnValue;
}
