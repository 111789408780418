import {useDraggable} from '@dnd-kit/core';
import {getSize} from 'platform/foundation';
import {css, useTheme} from 'styled-components';

import {RequiredTestIdProps, suffixTestId} from 'shared';

interface ResizeHandlerProps extends RequiredTestIdProps {
  eventId: string;
  direction: 'bottom';
  isReadOnly?: boolean;
}

export function ResizeHandler(props: ResizeHandlerProps) {
  const theme = useTheme();

  const {
    attributes: resizeAttributes,
    listeners: resizeListeners,
    setNodeRef: resizeSetNodeRef,
  } = useDraggable({
    id: `${props.eventId}-resize`,
    data: {
      type: 'resize',
      direction: props.direction,
      eventId: props.eventId,
    },
    disabled: props.isReadOnly,
  });
  return (
    <div
      ref={resizeSetNodeRef}
      {...resizeListeners}
      {...resizeAttributes}
      css={css`
        position: absolute;
        bottom: ${props.direction === 'bottom' ? 0 : 'auto'};
        left: 0;
        width: 100%;
        height: ${getSize(3)};
        border-radius: ${theme.radii.xSmall};
        background-color: ${theme.colors.palettes.black[900][60]};
        cursor: row-resize;
        opacity: 0;
        transition: opacity 0.1s ease;
        &:hover {
          opacity: 0.6;
        }
      `}
      data-testid={suffixTestId('resizeHandler', props)}
    />
  );
}
