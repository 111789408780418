import {AnimatePresence} from 'framer-motion';
import {Separator} from 'platform/components';
import {Box, Hide, HStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useState} from 'react';

import {always} from 'ramda';

import {suffixTestId} from 'shared';

import {DeviceSelectStep} from '../(Sections)/DeviceSelectStep/DeviceSelectStep';
import {DocumentSelectionList} from '../(Sections)/DocumentSelectionList/DocumentSelectionList';
import {ErrorStep} from '../(Sections)/ErrorStep/ErrorStep';
import {ModeSelectStep} from '../(Sections)/ModeSelectStep/ModeSelectStep';
import {OnSiteDocumentMenu} from '../(Sections)/OnSiteDocumentMenu/OnSiteDocumentMenu';
import {RemoteDocumentMenu} from '../(Sections)/RemoteDocumentMenu/RemoteDocumentMenu';
import {SendToDeviceStep} from '../(Sections)/SendToDeviceStep/SendToDeviceStep';
import {SendToSignatoriesStep} from '../(Sections)/SendToSignatoriesStep/SendToSignatoriesStep';
import {SignatoriesSelectStep} from '../(Sections)/SignatoriesSelectStep/SignatoriesSelectStep';
import {SignByQrCodeStep} from '../(Sections)/SignByQrCodeStep/SignByQrCodeStep';
import {DEFAULT_SIGNING_STEP} from '../constants/DEFAULT_SIGNING_STEP';
import {STEPPER_MIN_HEIGHT} from '../constants/STEPPER_MIN_HEIGHT';
import {DocumentSelectionProvider} from '../contexts/DocumentSelectionProvider';
import {DocumentStateProvider} from '../contexts/DocumentStateProvider';
import {useSetupDefaultTransactionValues} from '../hooks/useSetupDefaultTransactionValues';
import {SignDocumentStep} from '../types/SignDocumentStep';
import {SignDocumentStepperProps} from '../types/SignDocumentStepperProps';
import {StepContentProps} from '../types/StepContentProps';
import {isErrorStep} from '../utils/isErrorStep';
import {isSignatureOnSite} from '../utils/isSignatureOnSite';
import {AnimateHeightObserver} from './AnimateHeightObserver';

export function SignDocumentStepper(props: SignDocumentStepperProps) {
  const [signingStep, setSigningStep] = useState<SignDocumentStep>(DEFAULT_SIGNING_STEP);

  const {isLoading, transaction, isTransactionError} = useSetupDefaultTransactionValues({
    fileId: props.fileId,
    signingStep,
    setSigningStep,
  });

  const contentProps: StepContentProps = {
    customerId: props.customerId,
    context: props.contextTarget,
    signingStep,
    setSigningStep,
    fileId: props.fileId,
    refreshData: props.refreshData,
    'data-testid': suffixTestId(signingStep, props),
  };

  const documentList = match(signingStep)
    .with('modeSelect', always(<DocumentSelectionList key="DocumentSelectionList" />))
    .when(isSignatureOnSite, always(<OnSiteDocumentMenu key="OnSiteDocumentMenu" />))
    .otherwise(always(<RemoteDocumentMenu key="RemoteDocumentMenu" signingStep={signingStep} />));

  const content = match(signingStep)
    .with('modeSelect', always(<ModeSelectStep {...contentProps} />))
    .with('OnSite_deviceSelect', always(<DeviceSelectStep {...contentProps} />))
    .with('OnSite_qrCode', always(<SignByQrCodeStep {...contentProps} />))
    .with('OnSite_sentToDevice', always(<SendToDeviceStep {...contentProps} />))
    .with('OnSite_error', always(<ErrorStep {...contentProps} />))
    .with('Remote_signatoriesSelect', always(<SignatoriesSelectStep {...contentProps} />))
    .with('Remote_sentToSignatories', always(<SendToSignatoriesStep {...contentProps} />))
    .with('Remote_error', always(<ErrorStep {...contentProps} />))
    .otherwise(always(null));

  return (
    <Box minHeight={STEPPER_MIN_HEIGHT}>
      <DocumentSelectionProvider
        {...props}
        isLoading={isLoading}
        transaction={transaction}
        isTransactionError={isTransactionError}
      >
        <DocumentStateProvider>
          <AnimateHeightObserver>
            <HStack minHeight={STEPPER_MIN_HEIGHT}>
              <Hide when={isErrorStep(signingStep)}>
                <Box minWidth={80} maxWidth={80} maxHeight={200} overflowY="auto">
                  <AnimatePresence>{documentList}</AnimatePresence>
                </Box>

                <Separator orientation="vertical" />
              </Hide>

              <Box paddingVertical={4} width="100%">
                {content}
              </Box>
            </HStack>
          </AnimateHeightObserver>
        </DocumentStateProvider>
      </DocumentSelectionProvider>
    </Box>
  );
}
