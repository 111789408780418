import {DataStatus} from 'platform/components';

import {
  SourcingComment,
  useSourcingDeleteCommentMutation,
  useSourcingGetCommentsQuery,
  useSourcingUpdateCommentMutation,
} from '@dms/api/sourcing';
import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {CommentItem} from './CommentItem';

interface CommentsListProps extends RequiredTestIdProps {
  readonly vehicleId: string;
}

export function CommentsList(props: CommentsListProps) {
  const {data: comments, isLoading} = useSourcingGetCommentsQuery({
    externalId: props.vehicleId,
  });
  const [deleteComment] = useSourcingDeleteCommentMutation();
  const [updateComment] = useSourcingUpdateCommentMutation();

  const handleDeleteComment = (comment: SourcingComment) => {
    deleteComment({
      externalId: props.vehicleId,
      uuid: comment.uuid,
    });
  };

  const handleEditComment = (comment: SourcingComment, newMessage: string) => {
    updateComment({
      externalId: props.vehicleId,
      uuid: comment.uuid,
      body: {
        message: newMessage,
        parentCommentUuid: comment.parent?.uuid ?? null,
      },
    });
  };

  return (
    <DataStatus
      isLoading={isLoading}
      isEmpty={comments?.length === 0}
      emptyMessage={i18n.t('page.comments.labels.noOneCommented')}
    >
      {comments?.map((item, index) => (
        <CommentItem
          comment={item}
          key={item.uuid}
          onDelete={handleDeleteComment}
          onEdit={handleEditComment}
          data-testid={suffixTestId(`commentItem[${index}]`, props)}
        />
      ))}
    </DataStatus>
  );
}
