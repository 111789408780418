import {Button, CheckboxTree, Search, Separator} from 'platform/components';
import {Box, Heading, Space} from 'platform/foundation';
import styled from 'styled-components';

import {ReactNode, useState, useTransition} from 'react';

import i18n from '@dms/i18n';

import {
  convertStringToCamelCase,
  Nullish,
  sortByAsciiNumberLast,
  suffixTestId,
  TestIdProps,
} from 'shared';

import {FeaturePickerItem} from './types/FeaturePickerItem';
import {filterFeatures} from './utils/filterFeatures';
import {mapFeaturesRecursively} from './utils/mapFeaturesRecursively';

interface FeaturesPickerProps extends TestIdProps {
  categories: FeaturePickerItem[];
  features: FeaturePickerItem[];
  selected?: Nullish | string[];
  onFeatureSelect: (selected: string[]) => void;
  /**
   * Allow the option to expand all features
   */
  isExpandable?: boolean;
  /**
   * Forces all features to be in single column instead of two columns
   */
  forceSingleColumn?: boolean;
}

export function FeaturesPicker(props: FeaturesPickerProps) {
  const {
    categories,
    features,
    selected = [],
    onFeatureSelect,
    isExpandable = false,
    forceSingleColumn = false,
  } = props;

  const [, startTransition] = useTransition();
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [isExpanded, setIsExpanded] = useState(!isExpandable);
  const [filteredFeatures, setFilteredFeatures] = useState<FeaturePickerItem[] | null>(null);

  const handleClick = (value: string[] | null) => onFeatureSelect(value ?? []);

  const handleSearch = (newSearchQuery: string | null) => {
    setSearchQuery(newSearchQuery);

    if (!newSearchQuery) {
      setFilteredFeatures(null);
      return;
    }

    startTransition(() => {
      const filtered = filterFeatures(
        (item, level) =>
          (level > 0 ? item?.shortLabel || item?.label : item?.label)
            ?.toLowerCase()
            .trim()
            .includes(String(newSearchQuery.toLowerCase().trim())),
        features
      );

      setFilteredFeatures(filtered);
    });
  };

  const renderCategory = (category: FeaturePickerItem, level = 0, last = false): ReactNode => (
    <div
      key={category.label}
      data-testid={suffixTestId(
        `${convertStringToCamelCase(category.value)}-categoryWrapper`,
        props
      )}
    >
      {renderFeaturesInCategory(category, level, last)}

      {!!category.children?.length &&
        category.children.map((child) => (
          <div key={child.value}>{renderCategory(child, level + 1)}</div>
        ))}
    </div>
  );

  const renderFeaturesInCategory = (
    category: FeaturePickerItem,
    level = 0,
    last = false
  ): ReactNode => {
    const categoryKey = category?.value as string;
    const filteredItems = (filteredFeatures ?? features).filter((feature) =>
      feature.categories?.find((category) => category === categoryKey)
    );
    const mappedItems = filteredItems.map(mapFeaturesRecursively);
    const filteredSortedItems = mappedItems.sort((a, b) => sortByAsciiNumberLast(a.label, b.label));

    return filteredSortedItems.length ? (
      <>
        {level === 0 ? (
          <Box paddingBottom={3}>
            <Heading size={4} color="secondary">
              {category.label}
            </Heading>
          </Box>
        ) : (
          <Box paddingVertical={3}>
            <Heading size={5} color="tertiary">
              {category.label}
            </Heading>
          </Box>
        )}

        <CategoryFeaturesWrapper
          data-testid={suffixTestId(
            `${convertStringToCamelCase(category.value)}-categoryListWrapper`,
            props
          )}
          columnCount={forceSingleColumn ? 1 : 3}
        >
          {filteredSortedItems.map((item) => (
            <Box
              padding={1}
              overflow="hidden"
              key={item.value}
              data-testid={suffixTestId(
                `${convertStringToCamelCase(category.value)}-checkBoxTreeWrapper`,
                props
              )}
            >
              <CheckboxTree
                options={[item]}
                value={selected ?? []}
                onChange={(value) => handleClick(value)}
                data-testid={suffixTestId(
                  `${convertStringToCamelCase(category.value)}-checkBoxTree`,
                  props
                )}
              />
            </Box>
          ))}
        </CategoryFeaturesWrapper>

        {!last ? <Separator /> : null}
      </>
    ) : null;
  };

  return (
    <div>
      <Search
        data-testid={suffixTestId('search', props)}
        value={searchQuery}
        onChange={handleSearch}
      />

      {isExpandable && (
        <>
          <Space vertical={3} />
          <Button
            data-testid={suffixTestId('toggleExpand', props)}
            variant="link"
            rightIcon={isExpanded ? 'hardware/keyboard_arrow_up' : 'hardware/keyboard_arrow_down'}
            onClick={() => setIsExpanded(!isExpanded)}
            title={
              isExpanded
                ? i18n.t('entity.vehicle.actions.hideAllFeatures')
                : i18n.t('entity.vehicle.actions.showAllFeatures')
            }
          />
        </>
      )}

      {(isExpanded || filteredFeatures) && (
        <>
          <Space vertical={3} />
          {categories.map((category, i) =>
            renderCategory(category, 0, categories.length === i + 1)
          )}
        </>
      )}
    </div>
  );
}

const CategoryFeaturesWrapper = styled.div<{columnCount: number}>`
  flex: 3;
  column-count: ${({columnCount}) => columnCount};
`;
