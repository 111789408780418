import {Tooltip} from 'platform/components';
import {Text} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {forwardRef, Ref, useImperativeHandle, useState} from 'react';

import {equals} from 'ramda';

import {useTooltipProps} from '../../hooks/useTooltipProps';
import {ICellRenderer, ICellRendererParams} from '../../types/AgGridTypes';
import {Style} from '../../types/Api';
import {TooltipGetter} from '../../types/TooltipGetter';
import {getCellClassName} from '../../utils/getCellClassName';
import {getStylesToRender} from '../../utils/getStylesToRender';

export interface MoneyCellRendererProps extends ICellRendererParams {
  getTooltipProps?: TooltipGetter;
  decimalPlaces?: number;
}

function MoneyCellRendererComponent(props: MoneyCellRendererProps, ref: Ref<ICellRenderer>) {
  const {getTooltipProps, data, node, colDef, decimalPlaces} = props;
  const className = getCellClassName(colDef);
  const formatCurrency = useFormatCurrency();

  const [cellStyles, setCellStyles] = useState<Style>(() => getStylesToRender(props));
  const [cellValue, setCellValue] = useState<string | null>(() => {
    const value = props.getValue?.() ?? props.value;
    return formatCurrency(value?.amount, value?.currency, decimalPlaces ?? 2);
  });

  useImperativeHandle(ref, () => ({
    refresh: (refreshProps) => {
      const newCellValue = refreshProps?.getValue?.() ?? refreshProps.value;
      const formattedNewCellValue = formatCurrency(
        newCellValue?.amount,
        newCellValue?.currency,
        decimalPlaces ?? 2
      );

      if (formattedNewCellValue !== cellValue) {
        setCellValue(formattedNewCellValue);
      }
      const newCellStyles = getStylesToRender(refreshProps);

      if (!equals(newCellStyles, cellStyles)) {
        setCellStyles(newCellStyles);
      }
      return true;
    },
  }));

  const tooltipProps = useTooltipProps(data, node, getTooltipProps);

  return (
    <Tooltip {...tooltipProps} label={cellValue}>
      {/* eslint-disable-next-line react/forbid-dom-props */}
      <div className={className}>
        <Text color={cellStyles.textColor} noWrap={!colDef?.wrapText} size="small">
          {cellValue}
        </Text>
      </div>
    </Tooltip>
  );
}

export const MoneyCellRenderer = forwardRef<ICellRenderer, MoneyCellRendererProps>(
  MoneyCellRendererComponent
);
