import {CommentsTab, ActivitySidebar, ActivitySidebarAction} from '@dms/features/activity-panel';
import i18n from '@dms/i18n';

import {buildArray} from 'shared';

import {ActivityTab} from './ActivityTab';

interface InterestActivityPanelProps {
  interestId: string;
}

export function InterestActivityPanel(props: InterestActivityPanelProps) {
  const actions = buildArray<ActivitySidebarAction>()
    .add({
      label: i18n.t('entity.activityPanel.activity.title'),
      icon: 'action/history',
      component: <ActivityTab interestId={props.interestId} />,
    })
    .add({
      label: i18n.t('entity.activityPanel.comments.title'),
      icon: 'communication/comment',
      component: <CommentsTab interestId={props.interestId} />,
    });

  return <ActivitySidebar actions={actions} />;
}
