import {Card, FormControl, Separator} from 'platform/components';
import {VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {GetWarehouseAccountResponse} from '@dms/api/metadaWarehouseAccount';
import {BaseArticle, GetArticleResponse} from '@dms/api/metadaWarehouseArticle';
import {TenantResponseBody} from '@dms/api/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {PricesTabPricesForm} from './PricesTabPricesForm';
import {PricesTabSettingsForm} from './PricesTabSettingsForm';

interface OverviewPricesTabProps extends TestIdProps {
  article: GetArticleResponse;
  tenant: TenantResponseBody;
  warehouseAccount: GetWarehouseAccountResponse;
  control: FormControl<BaseArticle>;
  formApi: UseFormReturn<BaseArticle>;
}

export function PricesTab(props: OverviewPricesTabProps) {
  return (
    <Card>
      <VStack data-testid={suffixTestId('articleCatalogs', props)} width="100%">
        <PricesTabSettingsForm control={props.control} />
        <Separator />
        <PricesTabPricesForm
          control={props.control}
          article={props.article}
          tenant={props.tenant}
          warehouseAccount={props.warehouseAccount}
        />
      </VStack>
    </Card>
  );
}
