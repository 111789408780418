import {diff} from 'deep-object-diff';
import {
  ButtonGroup,
  Form,
  FormButton,
  FormSubmitHandler,
  showNotification,
  Tabs,
} from 'platform/components';
import {Box, Space} from 'platform/foundation';
import {DeepPartial} from 'utility-types';

import {useRef, useState} from 'react';
import {UseFormReturn} from 'react-hook-form/dist/types/form';

import {assocPath, clone, concat, defaultTo, flip, head, isEmpty, last, path, pipe} from 'ramda';
import {isFunction, isNilOrEmpty, isString} from 'ramda-adjunct';

import {GetWarehouseResponse, GetWarehousesResponse} from '@dms/api/metadaWarehouse';
import {GetWarehouseAccountResponse} from '@dms/api/metadaWarehouseAccount';
import {
  BaseArticle,
  ChangedFieldValue,
  DispensingPrices,
  GetArticleResponse,
  PatchArticleRequest,
  PricesSettings,
  usePatchArticleMutation,
  usePutArticleCalculationMutation,
} from '@dms/api/metadaWarehouseArticle';
import {useGetCodeListOptionsQuery} from '@dms/api/metadaWarehouseCodeList';
import {TenantResponseBody} from '@dms/api/shared';
import i18n from '@dms/i18n';
import {warehouseRoutes} from '@dms/routes';
import {handleApiError, queryParams, Section} from '@dms/shared';

import {
  ApiError,
  Nullish,
  sanitizeObject,
  suffixTestId,
  TestIdProps,
  useNavigate,
  useQueryState,
} from 'shared';

import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';
import {CatalogFilter} from '../../../../types/CatalogFilter';
import {filterElementsBySuffixMatch} from '../../../../utils/filterElementsBySuffixMatch';
import {objectPaths} from '../../../../utils/objectPaths';
import {removeKeys} from '../../../../utils/removeKeys';
import {CatalogsInformationTab} from './components/CatalogsInformationTab';
import {GeneralInformationTab} from './components/GeneralInformationTab';
import {PricesTab} from './components/PricesTab';

const ABORT_ERROR = 'AbortError';

interface OverviewProps extends TestIdProps {
  article: GetArticleResponse;
  warehouse: GetWarehouseResponse;
  warehouses: GetWarehousesResponse;
  tenant: TenantResponseBody;
  warehouseAccount: GetWarehouseAccountResponse;
}

type SubmitOptions = 'save' | 'saveAndBack';

export function Overview(props: OverviewProps) {
  const {articleId, warehouseId} = useWarehouseParams();
  const [activeTabId, setActiveTabId] = useQueryState(queryParams.COMPONENT_SECTIONS_TAB);
  const navigate = useNavigate();

  const [leafId, setLeafId] = useState<string | Nullish>(props.article.treeFolder.leafId);
  const submitOption = useRef<SubmitOptions>('save');

  const {data: presetDispensingUnits, isLoading: arePresetDispensingUnitsLoading} =
    useGetCodeListOptionsQuery({
      category: 'WRH-ARTICLE-DISPENSING-UNIT',
    });

  const [articleCalculate] = usePutArticleCalculationMutation();
  const [patchArticle] = usePatchArticleMutation();

  const defaultValues: DeepPartial<BaseArticle> = {
    ...props.article,
    warehouseAccount: props.warehouseAccount.id,
    dispensingPrices: {
      ...props.article.dispensingPrices,
      saleBaseMarginPercent: defaultTo(
        props.warehouse.salePriceMarginPercent,
        props.article.dispensingPrices?.saleBaseMarginPercent
      ),
      saleBaseMarkUpPercent: defaultTo(
        props.warehouse.salePriceMarkupPercent,
        props.article.dispensingPrices?.saleBaseMarkUpPercent
      ),
      saleBaseProfit: defaultTo(
        props.warehouse.salePriceProfitWithoutVat,
        props.article.dispensingPrices?.saleBaseProfit
      ),
    },
  };

  const prevFormValues = useRef<DeepPartial<BaseArticle>>(defaultValues);
  const abortControllerInstance = useRef<AbortController | Nullish>(null);

  const manufacturerId = props.article.manufacturerId;

  const catalogFilter: CatalogFilter = {
    branchId: props.warehouse.branchId,
    supplierId: props.article.supplierId,
    manufacturerId,
    manufacturerNumber: props.article.manufacturerNumber,
  };

  const handleNavigateBack = () => {
    navigate(warehouseRoutes.articleList);
  };

  const handleSubmit: FormSubmitHandler<BaseArticle> = async (data) => {
    const modifiedArticle: PatchArticleRequest['body'] = {
      warehouseId: data.warehouseId,
      supplierId: data.supplierId,
      manufacturerId: data.manufacturerId,
      makeCode: data.makeCode,
      name: data.name,
      description: data.description,
      stockNumber: data.stockNumber,
      manufacturerNumber: data.manufacturerNumber,
      handlingUnit: data.handlingUnit,
      dispensingUnit: data.dispensingUnit,
      vatType: data.vatType,
      storageLocation: data.storageLocation,
      discountGroup: data.discountGroup,
      marketingCode: data.marketingCode,
      warehouseAccount: data.warehouseAccount,
      treeFolder: {leafId: leafId ?? ''},
      pricesSettings: data.pricesSettings,
      dispensingPrices: data.dispensingPrices,
    };

    await patchArticle({articleId, warehouseId, body: modifiedArticle})
      .unwrap()
      .then(() => showNotification.success(i18n.t('page.warehouse.notification.articleUpdated')))
      .then(() => {
        if (submitOption.current === 'save') {
          return;
        }

        handleNavigateBack();
      })
      .catch(handleApiError);
  };

  const handleChange = (
    values: DeepPartial<BaseArticle>,
    change: any,
    formApi: UseFormReturn<BaseArticle>
  ) => {
    const copiedValues = JSON.parse(JSON.stringify(values)) as object;
    const {asString} = pipe(
      defaultTo({}),
      flip(diff)(sanitizeObject(copiedValues)),
      objectPaths
    )(prevFormValues.current);

    // Name of the changed field
    const changedField = last<string>(change.name?.split('.') ?? []); // => saleBasePriceWithoutVat
    // Indicate if the changed field is allowed to trigger recalculation
    const shouldTriggerRecalculation = ALLOWED_VALUES.includes(changedField);
    // Get the difference between the previous and current form values
    const difference = diff(sanitizeObject(prevFormValues.current), sanitizeObject(copiedValues));
    // Difference that contains only allowed values
    const allowedDifference = removeKeys(
      difference,
      concat(ALLOWED_VALUES, ALLOWED_VALUES_IN_OBJECT)
    );

    const isDispensingPricesDiffEmpty = allowedDifference?.dispensingPrices
      ? isEmpty(allowedDifference?.dispensingPrices)
      : false;

    const isPricesSettingsDiffEmpty = allowedDifference?.pricesSettings
      ? isEmpty(allowedDifference?.pricesSettings)
      : false;

    const isDiffEmpty = isDispensingPricesDiffEmpty && isPricesSettingsDiffEmpty;

    if (!shouldTriggerRecalculation || isNilOrEmpty(allowedDifference) || isDiffEmpty) {
      return;
    }

    const arrayOfChangedValues = asString.includes('.') ? asString.split(',') : asString;
    const pathToChangedValues = head(
      filterElementsBySuffixMatch(arrayOfChangedValues, ALLOWED_VALUES)
    )?.split('.') as string[];
    let changedValue = path(pathToChangedValues ?? [], values);
    const newPrevFormValues = assocPath(
      pathToChangedValues ?? [],
      changedValue,
      prevFormValues.current
    );

    prevFormValues.current = newPrevFormValues;

    // If the changed field is saleBasePriceWithoutVat, then we need to recalculate purchasePrice BE requires purchasePrice to be sent as changed field
    let field = changedField === 'basePriceWithoutVat' ? 'purchasePrice' : changedField;

    // If the changed field is null, then we need to send 0 as changed value, this is because BE doesn't accept null values
    if (changedValue === null) {
      changedValue = 0;
    }

    // If the changed field is salesPriceCalculation, basePriceSource or unitSalesPriceWithVat, then we need to send purchasePrice as changed field with null value, this is because BE
    if (
      changedField === 'salesPriceCalculation' ||
      changedField === 'basePriceSource' ||
      changedField === 'unitSalesPriceWithVat'
    ) {
      field = 'purchasePrice';
      changedValue = null;
    }

    if (abortControllerInstance.current && isFunction(abortControllerInstance.current.abort)) {
      abortControllerInstance.current.abort();
    }

    const articleCalculateController = new AbortController();

    abortControllerInstance.current = articleCalculateController;

    articleCalculate({
      articleId,
      warehouseId,
      body: {
        changedFieldValue: {
          field: field as ChangedFieldValue['field'],
          value: String(changedValue),
        },
        currentFeFieldsValues: {
          supplierId: values.supplierId,
          manufacturerId: values.manufacturerId,
          manufacturerNumber: values.manufacturerNumber,
          vatType: values.vatType,
          discountGroup: values.discountGroup,
          marketingCode: values.marketingCode,
          warehouseAccount: values.warehouseAccount,
          pricesSettings: values.pricesSettings as PricesSettings,
          dispensingPrices: values.dispensingPrices as DispensingPrices,
        },
      },
      signal: articleCalculateController.signal,
    })
      .unwrap()
      .then((response) => {
        const newValues = clone(
          sanitizeObject({
            ...values,
            pricesSettings: response.pricesSettings,
            dispensingPrices: response.dispensingPrices,
          })
        );

        prevFormValues.current = newValues;
        formApi.reset(newValues);
      })
      .catch((error: {error: Error}) => {
        // If the request is aborted, then we don't want to show the error notification
        if (
          error &&
          'error' in error &&
          isString(error.error) &&
          error.error.includes(ABORT_ERROR)
        ) {
          return;
        }

        handleApiError(error as ApiError);
      });
  };

  return (
    <Section isFullHeight>
      <Form<BaseArticle>
        onSubmit={handleSubmit}
        defaultValues={defaultValues}
        shouldWatchForUnsavedChanges
        isFullHeight
      >
        {(control, formApi) => {
          formApi.watch((data, test) => handleChange(data, test, formApi));

          return (
            <Box height="100%">
              <Box height="90%">
                <Tabs
                  isFullHeight
                  activeTabId={activeTabId}
                  onChange={setActiveTabId}
                  variant="condensed"
                  tabs={[
                    {
                      title: i18n.t('entity.warehouse.labels.generalInformation'),
                      id: 'generalInformation',
                      content: (
                        <GeneralInformationTab
                          articleId={articleId}
                          article={props.article}
                          control={control}
                          formApi={formApi}
                          manufacturerId={manufacturerId}
                          warehouse={props.warehouse}
                          warehouses={props.warehouses}
                          leafId={leafId}
                          leafIdChange={setLeafId}
                          presetDispensingUnits={presetDispensingUnits}
                          arePresetDispensingUnitsLoading={arePresetDispensingUnitsLoading}
                        />
                      ),
                    },
                    {
                      title: i18n.t('entity.warehouse.labels.prices'),
                      id: 'prices',
                      content: (
                        <PricesTab
                          article={props.article}
                          tenant={props.tenant}
                          warehouseAccount={props.warehouseAccount}
                          control={control}
                          formApi={formApi}
                        />
                      ),
                    },
                    {
                      title: i18n.t('entity.warehouse.labels.catalogsInformation'),
                      id: 'catalogsInformation',
                      content: (
                        <CatalogsInformationTab control={control} catalogFilter={catalogFilter} />
                      ),
                    },
                  ]}
                />
                <Space vertical={4} />
                <ButtonGroup align="right">
                  <FormButton
                    variant="secondary"
                    control={control}
                    title={i18n.t('general.actions.discardChanges')}
                    onClick={handleNavigateBack}
                    data-testid={suffixTestId('actions.discard', props)}
                  />
                  <FormButton
                    onClick={() => {
                      submitOption.current = 'saveAndBack';
                    }}
                    control={control}
                    type="submit"
                    title={i18n.t('entity.warehouse.actions.saveAndBackToArticle')}
                    data-testid={suffixTestId('actions.saveAndBackToArticle', props)}
                  />
                  <FormButton
                    onClick={() => {
                      submitOption.current = 'save';
                    }}
                    control={control}
                    type="submit"
                    title={i18n.t('general.actions.saveChanges')}
                    data-testid={suffixTestId('actions.submit', props)}
                  />
                </ButtonGroup>
              </Box>
            </Box>
          );
        }}
      </Form>
    </Section>
  );
}

const ALLOWED_VALUES_IN_OBJECT = ['pricesSettings', 'dispensingPrices'];

const ALLOWED_VALUES = [
  'vatType',
  'supplierId',
  'warehouseAccount',
  'marketingCode',
  'purchasePrice',
  'recommendedPriceWithOutVat',
  'recommendedPriceWithVat',
  'saleBasePriceWithoutVat',
  'saleBasePriceWithVat',
  'saleBaseMarkUpPercent',
  'saleBaseProfit',
  'saleBaseMarginPercent',
  'warrantyPriceWithoutVat',
  'warrantyPriceWithVat',
  'warrantyMarkUpPercent',
  'warrantyProfit',
  'warrantyMarginPercent',
  'basePriceWithoutVat',
  'salesPriceCalculation',
  'basePriceSource',
  'unitSalesPriceWithVat',
];
