import {Box} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {useCallback} from 'react';

import {mergeAll} from 'ramda';

import {PriceWithAndWithoutVat} from '@dms/api/shared';
import {DEFAULT_CURRENCY} from '@dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {DataGrid, QueryFilterObject} from 'features/datagrid';

import {OverviewTotalprice} from '../../../../../components/OverviewTotalPrice';
import {WAREHOUSE_GRID_CODES} from '../../../../../constants/warehouseGridCodes';
import {useWarehouseParams} from '../../../../../hooks/useWarehouseParams';

interface ListOfItemsTabProps extends RequiredTestIdProps {
  totalPrice: PriceWithAndWithoutVat | Nullish;
}

export function ListOfItemsTab(props: ListOfItemsTabProps) {
  const {serviceOrderReturnId} = useWarehouseParams();

  const formatCurrency = useFormatCurrency();

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {serviceOrderReturnId}]),
    [serviceOrderReturnId]
  );

  const totalPriceWithoutVat = formatCurrency(
    props.totalPrice?.withoutVat.amount ?? 0,
    props.totalPrice?.withoutVat?.currency ?? DEFAULT_CURRENCY,
    2
  );

  const totalPriceWithVat = formatCurrency(
    props.totalPrice?.withVat?.amount ?? 0,
    props.totalPrice?.withVat?.currency ?? DEFAULT_CURRENCY,
    2
  );

  return (
    <>
      <Box height="95%">
        <DataGrid
          gridCode={WAREHOUSE_GRID_CODES.warehouseServiceOrderPartsReturnItem}
          queryModifier={queryModifier}
          data-testid={suffixTestId('returnedItems', props)}
        />
      </Box>
      <OverviewTotalprice
        totalPriceWithoutVat={totalPriceWithoutVat}
        totalPriceWithVat={totalPriceWithVat}
        data-testid={suffixTestId('totalPrice', props)}
      />
    </>
  );
}
