import {closeCurrentDialog, openDialog} from 'platform/components';
import {useFormatCurrency} from 'platform/locale';

import {UserRole} from '@dms/api/metadaInsurance';
import i18n from '@dms/i18n';

import {InsuranceData} from '../../../hooks/useInsuranceData';
import {BecomeTipperForm} from './BecomeTipperForm';
import {InsuranceActionCard} from './InsuranceActionCard';

interface TipperCardProps {
  insuranceData: InsuranceData;
  onRecommend?: VoidFunction;
}

export function TipperCard(props: TipperCardProps) {
  const formatCurrency = useFormatCurrency();

  const amount = `${formatCurrency(
    parseInt(i18n.t('entity.insurance.labels.recommendationFeeNumber')),
    'CZK'
  )}`;

  function handleBecomeTipper() {
    openDialog(
      <BecomeTipperForm
        insuranceData={props.insuranceData}
        onDone={closeCurrentDialog}
        onCancel={closeCurrentDialog}
      />,
      {
        title: i18n.t('entity.insurance.labels.customerLeading'),
      }
    );
  }

  if (props.insuranceData.isFinished) {
    return (
      <InsuranceActionCard
        title={i18n.t('entity.insurance.labels.thanksForRecommendation')}
        description={i18n.t('entity.insurance.labels.feeNextMonth')}
        image="assets/images/insurance_wallet.png"
        severity="success"
        amount={amount}
      />
    );
  }

  if (props.insuranceData.role === UserRole.TIPPER) {
    return (
      <InsuranceActionCard
        button={{
          variant: 'primary',
          leftIcon: 'action/thumb_up',
          title: i18n.t('entity.insurance.actions.recommendCustomer'),
          onClick: props.onRecommend,
        }}
        title={i18n.t('entity.insurance.actions.recommendCustomer')}
        description={i18n.t('entity.insurance.labels.feeForLead')}
        image="assets/images/insurance_notebook.png"
        amount={amount}
      />
    );
  }

  return (
    <InsuranceActionCard
      button={{
        variant: 'primary',
        rightIcon: 'hardware/keyboard_arrow_right',
        title: i18n.t('entity.insurance.actions.becomeTipper'),
        onClick: handleBecomeTipper,
      }}
      title={i18n.t('entity.insurance.labels.customerLeading')}
      description={i18n.t('entity.insurance.labels.feeForLead')}
      footer={i18n.t('entity.insurance.labels.tippersUseThisOption')}
      image="assets/images/insurance_notebook.png"
      amount={amount}
    />
  );
}
