import {metadaApi} from '@dms/api/core';

import {
  PostServiceOrderActionForApproveClaimApiArg,
  PostServiceOrderActionForApproveClaimApiResponse,
  PostServiceOrderActionForRejectClaimApiArg,
  PostServiceOrderActionForRejectClaimApiResponse,
} from './types';

export const metadaWorkshopOrderClaim = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postServiceOrderActionForApproveClaim: build.mutation<
      PostServiceOrderActionForApproveClaimApiResponse,
      PostServiceOrderActionForApproveClaimApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/approve-claim`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceActions', id: queryArg.serviceCaseId},
        {type: 'orderActions', id: queryArg.serviceOrderId},
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        {type: 'serviceOrder', id: queryArg.serviceOrderId},
        {type: 'serviceCase', id: queryArg.serviceCaseId},
        {type: 'serviceCaseTotalAmount', id: queryArg.serviceCaseId},
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
        'checkout',
        'afsCheckout',
        'afsPaymentsList',
        {type: 'serviceOrderCheckoutRestriction', id: queryArg.serviceOrderId},
        'afsPaymentIssueRestriction',
      ],
    }),
    postServiceOrderActionForRejectClaim: build.mutation<
      PostServiceOrderActionForRejectClaimApiResponse,
      PostServiceOrderActionForRejectClaimApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/reject-claim`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceActions', id: queryArg.serviceCaseId},
        {type: 'orderActions', id: queryArg.serviceOrderId},
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        {type: 'serviceOrder', id: queryArg.serviceOrderId},
        {type: 'serviceCase', id: queryArg.serviceCaseId},
        {type: 'serviceCaseTotalAmount', id: queryArg.serviceCaseId},
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
        'checkout',
        'afsCheckout',
        'afsPaymentsList',
        {type: 'serviceOrderCheckoutRestriction', id: queryArg.serviceOrderId},
        'afsPaymentIssueRestriction',
      ],
    }),
  }),
});

export const {
  usePostServiceOrderActionForApproveClaimMutation,
  usePostServiceOrderActionForRejectClaimMutation,
} = metadaWorkshopOrderClaim;
