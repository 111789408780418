import {isNil} from 'ramda';

import {
  CorrectiveTaxDocumentV5ResponseBodyRead,
  InvoiceV4ResponseBodyRead,
} from '@dms/api/metadaErrorLogs';

import {isCanceledOrCanceling} from './isInvoiceCanceledOrCanceling';

export const getShouldDisplaySendButton = (
  document: InvoiceV4ResponseBodyRead | CorrectiveTaxDocumentV5ResponseBodyRead | undefined
) =>
  isNil(document?.sentAt) &&
  isNil(document?.cancelledAt) &&
  !isCanceledOrCanceling(document?.paymentState) &&
  document?.documentSource.moduleSource === 'accounting';
