import {closeCurrentDialog, openDialog} from 'platform/components';
import {match} from 'ts-pattern';

import {useRef} from 'react';

import {isNil, isNotNil, path} from 'ramda';
import {isString} from 'ramda-adjunct';

import {useLazyGetArticleIdByManufacturerNumberAndManufacturerIdQuery} from '@dms/api/metadaWarehouseArticle';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {
  catchUnhandledDataGridActions,
  handleApiError,
  ArticleCreate,
  ArticleCreateDefaultValues,
  logError,
} from '@dms/shared';

import {TestIdProps} from 'shared';

import {ActionCallback, DataGrid, ExternalFilters} from 'features/datagrid';

import {FullscreenDataGridWrapper} from '../../../../../components/FullscreenDataGridWrapper';
import {PriceListRowData} from '../types/PriceListRowData';
import {MaterialPriceListTabExternalFilters} from './MaterialPriceListTabExternalFilters';

const SUPPLIER_ORDER_MATERIAL_EXTERNAL_FILTER_ID = 'supplier-order-material-external-filter';

interface MaterialPriceListProps extends TestIdProps {
  warehouseId: string;
  onAddMaterialToBasket: (articleId: string) => Promise<void>;
}

export function MaterialPriceListTab(props: MaterialPriceListProps) {
  const externalFilters = useRef<ArticleCreateDefaultValues>({});
  const [getArticleIdByManufacturerNumberAndManufacturerId] =
    useLazyGetArticleIdByManufacturerNumberAndManufacturerIdQuery();

  const handleNewArticle = (rowData: PriceListRowData) => {
    openDialog(
      <ArticleCreate
        defaultValues={{
          catalogNumber: rowData.catalogNumber,
          manufacturerId: rowData.manufacturerId,
          warehouseId: props.warehouseId,
          name: rowData.name,
        }}
        onArticleCreate={(createdArticleData) => {
          props.onAddMaterialToBasket(createdArticleData.articleId);
          closeCurrentDialog();
        }}
        onAddToBasket={(articleId) => {
          props.onAddMaterialToBasket(articleId);
          closeCurrentDialog();
        }}
        hasAddToBasketButton
        submitButtonTitle={i18n.t('general.actions.createAndAdd')}
        data-testid={testIds.warehouse.articleList('createNewArticle')}
      />,
      {
        id: 'createNewArticleDialog',
        title: i18n.t('general.labels.createNew'),
        scrollBehavior: 'outside',
        withAdditionalFooter: true,
      }
    );
  };

  const handleAddMaterialToBasket = async (rowData: PriceListRowData) => {
    const articleIdResponse = await getArticleIdByManufacturerNumberAndManufacturerId({
      warehouseId: props.warehouseId,
      manufacturerNumber: rowData.catalogNumber,
      manufacturerId: rowData.manufacturerId,
    })
      .unwrap()
      .catch(handleApiError);

    const articleId = articleIdResponse?.articleId;

    if (isNotNil(articleId)) {
      props.onAddMaterialToBasket(articleId);
      return;
    }

    handleNewArticle({
      catalogNumber: rowData.catalogNumber,
      manufacturerId: rowData.manufacturerId,
      name: rowData.name,
    });
  };

  const warehouseActionCallback: ActionCallback = async ({actionKey, rowData}) => {
    const itemManufacturerNumber = path(['catalogNumber', 'value'], rowData) as string;
    const itemManufacturerId = path(['manufacturerId', 'value', 'id'], rowData) as string;
    const itemName = path(['name', 'value'], rowData) as string;

    if (isNil(itemManufacturerNumber)) {
      return logError('ItemManufacturerNumber is not defined');
    }

    if (isNil(itemManufacturerId)) {
      return logError('ItemManufacturerId is not defined');
    }

    if (isNil(itemName)) {
      return logError('ItemName is not defined');
    }

    await match(actionKey)
      .with('addToBasket', async () => {
        await handleAddMaterialToBasket({
          catalogNumber: itemManufacturerNumber,
          manufacturerId: itemManufacturerId,
          name: itemName,
        });
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  const handleExternalFiltersChange = (filters: ExternalFilters) => {
    const articleCreateDefaultValues: ArticleCreateDefaultValues = {};

    if (isString(filters.catalogNumber)) {
      articleCreateDefaultValues.catalogNumber = filters.catalogNumber;
    }

    if (isString(filters.manufacturer)) {
      articleCreateDefaultValues.manufacturerId = filters.manufacturer;
    }

    externalFilters.current = articleCreateDefaultValues;
  };

  return (
    <FullscreenDataGridWrapper
      externalFilterId={SUPPLIER_ORDER_MATERIAL_EXTERNAL_FILTER_ID}
      externalFilterHeight={100}
      hideCardWrapper
      data-testid={props['data-testid']}
    >
      <DataGrid
        gridCode="supplier-price-list-cart"
        actionCallback={warehouseActionCallback}
        externalFilterId={SUPPLIER_ORDER_MATERIAL_EXTERNAL_FILTER_ID}
        _useAsLastResort_definitionBehaviorOverrides={{filterMode: 'NATIVE'}}
        data-testid="supplier-price-list-cart"
        filterComponent={MaterialPriceListTabExternalFilters}
        onExternalFilterChange={handleExternalFiltersChange}
      />
    </FullscreenDataGridWrapper>
  );
}
