import {Box, HStack, VStack} from 'platform/foundation';

import {SourcingVehicleDetailResponseBody} from '@dms/api/sourcing';

import {suffixTestId, TestIdProps} from 'shared';

import {ActionButtonsVehicle} from '../../ActionButtonsVehicle/ActionButtonsVehicle';
import {SourcingVehicleActions} from '../../SourcingVehicleActions/SourcingVehicleActions';
import {convertVehicleTagsToVehicleHighlights} from '../../VehicleHighlights/utils/convertVehicleTagsToVehicleHighlights';
import {VehicleHighlights} from '../../VehicleHighlights/VehicleHighlights';
import {VehiclePriceForm} from '../../VehiclePriceForm/VehiclePriceForm';

interface TableDetailRowProps extends TestIdProps {
  row: {index: number; original: SourcingVehicleDetailResponseBody};
  setLastVisitedVehicle?: (adId: string, index: number) => void;
}

export function TableDetailRow({
  row: {
    index,
    original,
    original: {vehicleSummary, features},
  },
  setLastVisitedVehicle,
  ...props
}: TableDetailRowProps) {
  const vehicleData = original.sourcingVehicle;
  const isDisabled = !original.sourcingVehicle?.isAvailable;

  return (
    <VStack>
      <Box paddingBottom={3}>
        <HStack justify="space-between">
          <VehicleHighlights
            data={convertVehicleTagsToVehicleHighlights(
              [],
              [],
              Object.values(features).filter((i) => i.isHighlight)
            )}
          />
          <ActionButtonsVehicle
            vehicle={original}
            index={index}
            isDisabled={isDisabled}
            actionButton={<SourcingVehicleActions vehicle={vehicleData} />}
            adId={vehicleData?.adId}
            setLastVisitedVehicle={setLastVisitedVehicle}
          />
        </HStack>
      </Box>
      <VehiclePriceForm
        isDisabled={isDisabled}
        vehicleId={vehicleData?.adId}
        initialValues={{
          comment: vehicleSummary?.comment as string,
          purchasePrice: `${vehicleSummary?.purchasePrice}`,
          sellingPrice: `${vehicleSummary?.sellingPrice}`,
        }}
        data-testid={suffixTestId('vehiclePriceForm', props)}
      />
    </VStack>
  );
}
