import {Action, TabProps, Tabs} from 'platform/components';
import {Show} from 'platform/foundation';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import {GetServiceOrderIssueNoteResponse} from '@dms/api/metadaWarehouseServiceOrderIssueNote';
import i18n from '@dms/i18n';
import {queryParams, Section} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId, useBoolean, useQueryState} from 'shared';

import {refreshDatagrid} from 'features/datagrid';

import {WAREHOUSE_GRID_CODES} from '../../../../constants/warehouseGridCodes';
import {GeneralInformationTab} from './components/GeneralInformationTab';
import {ListOfItemTab} from './components/ListOfItemTab';
import {ServiceOrderIssueAddMaterialModal} from './components/ServiceOrderIssueAddMaterialModal';

interface OverviewProps extends RequiredTestIdProps {
  isServiceOrderIssueLoading: boolean;
  priceWithoutVat: string | null;
  priceWithVat: string | null;
  serviceOrderIssueNote?: GetServiceOrderIssueNoteResponse;
}

export function Overview(props: OverviewProps) {
  const [activeTabId, setActiveTabId] = useQueryState(queryParams.COMPONENT_SECTIONS_TAB);
  const [isAddMaterialModalVisible, , , toggleAddMaterialModal] = useBoolean();

  const handleSubmitAddMaterial = () => {
    toggleAddMaterialModal();
    refreshDatagrid(WAREHOUSE_GRID_CODES.issueNoteServiceOrderAddedItems, {deselectAll: true});
  };

  const workItemsActions = match(props.serviceOrderIssueNote?.state)
    .returnType<Action[] | undefined>()
    .with(
      'PENDING',
      always([
        {
          type: 'button',
          variant: 'outlined',
          title: i18n.t('general.actions.addMaterial'),
          onClick: toggleAddMaterialModal,
          'data-testid': suffixTestId('action.addMaterial', props),
        },
      ])
    )
    .otherwise(always(undefined));

  const tabs: TabProps[] = [
    {
      title: i18n.t('entity.warehouse.labels.generalInformation'),
      id: 'generalInformation',
      content: (
        <GeneralInformationTab
          serviceOrderIssueNote={props.serviceOrderIssueNote}
          data-testid={suffixTestId('generalInformation', props)}
        />
      ),
    },
    {
      title: i18n.t('entity.warehouse.labels.listOfItems'),
      id: 'listOfItems',
      actions: workItemsActions,
      content: (
        <ListOfItemTab
          serviceOrderIssueNote={props.serviceOrderIssueNote}
          priceWithoutVat={props.priceWithoutVat}
          priceWithVat={props.priceWithVat}
          data-testid={suffixTestId('listOfItems', props)}
        />
      ),
    },
  ];

  return (
    <Section isFullHeight data-testid={suffixTestId('wrapper', props)}>
      <Tabs
        isFullHeight
        tabs={tabs}
        activeTabId={activeTabId}
        onChange={setActiveTabId}
        isLazy
        variant="condensed"
      />

      <Show when={isAddMaterialModalVisible}>
        <ServiceOrderIssueAddMaterialModal
          onDiscard={toggleAddMaterialModal}
          onSubmit={handleSubmitAddMaterial}
          data-testid={suffixTestId('dialogs.addMaterial', props)}
        />
      </Show>
    </Section>
  );
}
