import {Grid, VStack} from 'platform/foundation';

import {useCallback} from 'react';

import {values} from 'ramda';

import {
  SourcingVehicleDetailResponseBody,
  useSourcingAddVehicleHighlightBulkMutation,
  useSourcingAddVehicleShortcomingBulkMutation,
  useSourcingRemoveVehicleHighlightBulkMutation,
  useSourcingRemoveVehicleHighlightMutation,
  useSourcingRemoveVehicleShortcomingBulkMutation,
  useSourcingRemoveVehicleShortcomingMutation,
  useSourcingSetFeatureAsHighlightedMutation,
  useSourcingSetFeatureAsNotHighlightedMutation,
} from '@dms/api/sourcing';
import {useListHighlightsForTenantQuery, useListShortcomingsForTenantQuery} from '@dms/api/vehicle';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {CardTagSelection, CardTagSelectionTagType, handleApiError} from '@dms/shared';
import {TagType, VehicleCatalogueFeature} from '@dms/teas';

import {AllFeaturesCard} from './components/AllFeaturesCard/AllFeaturesCard';
import {HighlightedFeaturesCard} from './components/HighlightedFeaturesCard/HighlightedFeaturesCard';
import {SalesClaimNote} from './components/SalesClaimNote/SalesClaimNote';

interface HighlightsTabProps {
  adId: string;
  vehicle: SourcingVehicleDetailResponseBody;
}

export function HighlightsTab(props: HighlightsTabProps) {
  const {data: highlightsForTenant} = useListHighlightsForTenantQuery({size: 1000});
  const {data: shortcomingsForTenant} = useListShortcomingsForTenantQuery({size: 1000});
  const {highlights, shortcomings} = props.vehicle;
  const [setFeatureAsHighlight] = useSourcingSetFeatureAsHighlightedMutation();
  const [setFeatureAsNotHighlight] = useSourcingSetFeatureAsNotHighlightedMutation();
  const [addVehicleHighlightBulk] = useSourcingAddVehicleHighlightBulkMutation();
  const [removeVehicleHighlight] = useSourcingRemoveVehicleHighlightMutation();
  const [removeVehicleHighlightBulk] = useSourcingRemoveVehicleHighlightBulkMutation();
  const [addVehicleShortcomingBulk] = useSourcingAddVehicleShortcomingBulkMutation();
  const [removeVehicleShortcoming] = useSourcingRemoveVehicleShortcomingMutation();
  const [removeVehicleShortcomingBulk] = useSourcingRemoveVehicleShortcomingBulkMutation();

  const sortByName = (a: string, b: string) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  };

  const vehicleFeaturesWithTranslation = values(props.vehicle.features).filter(
    (feature) => feature.key !== feature.translation
  );

  const highlightedFeatureKeys = vehicleFeaturesWithTranslation
    .filter((f) => f.isHighlight)
    .sort((a, b) => sortByName(a.translation, b.translation))
    .map((f) => f.key);

  const featuresRemapped: VehicleCatalogueFeature[] = vehicleFeaturesWithTranslation
    .map((feature) => ({
      key: feature.key,
      oldKey: feature.key,
      parent: undefined,
      name: feature.translation,
      nameShort: feature.translation,
      category: undefined,
      isPremium: feature.isPremium,
    }))
    .sort((a, b) => sortByName(a.name, b.name));

  const handleFeatureHighlightOnBulk = (keys: string[]) => {
    Promise.all(
      keys.map((key) =>
        setFeatureAsHighlight({
          externalId: props.adId,
          featureKey: key,
        }).unwrap()
      )
    ).catch(handleApiError);
  };

  const handleFeatureHighlightOff = (key: string) => {
    setFeatureAsNotHighlight({
      externalId: props.adId,
      featureKey: key,
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleFeatureHighlightOffBulk = (keys: string[]) => {
    Promise.all(
      keys.map((key) =>
        setFeatureAsNotHighlight({
          externalId: props.adId,
          featureKey: key,
        }).unwrap()
      )
    ).catch(handleApiError);
  };

  const handleHighlightsAdd = (options: CardTagSelectionTagType[]) => {
    addVehicleHighlightBulk({
      externalId: props.adId,
      highlights: options.map((highlight) => highlight.label),
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleHighlightDelete = (option: CardTagSelectionTagType) => {
    removeVehicleHighlight({
      externalId: props.adId,
      highlightId: option.id,
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleHighlightsDelete = (options: CardTagSelectionTagType[]) => {
    removeVehicleHighlightBulk({
      externalId: props.adId,
      removeHighlightBulkRequestBody: {
        highlightIds: options.map((highlight) => highlight.id),
      },
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleShortcomingsAdd = (options: CardTagSelectionTagType[]) => {
    addVehicleShortcomingBulk({
      externalId: props.adId,
      shortcomings: options.map((shortcoming) => shortcoming.label),
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleShortcomingDelete = (option: CardTagSelectionTagType) => {
    removeVehicleShortcoming({
      externalId: props.adId,
      shortcomingId: option.id,
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleShortcomingsDelete = (options: CardTagSelectionTagType[]) => {
    removeVehicleShortcomingBulk({
      externalId: props.adId,
      removeShortcomingBulkRequestBody: {
        shortcomingIds: options.map((shortcoming) => shortcoming.id),
      },
    })
      .unwrap()
      .catch(handleApiError);
  };

  const remapTitleToLabel = useCallback(
    (x: readonly TagType[]) => x?.map(({id, title}) => ({id, label: title})) || [],
    []
  );

  return (
    <Grid columns={[1, 2]} spacing={2}>
      <AllFeaturesCard
        featureItems={featuresRemapped}
        highlightedFeatures={highlightedFeatureKeys}
        onHighlightOnBulk={handleFeatureHighlightOnBulk}
        onHighlightOff={handleFeatureHighlightOff}
      />

      <VStack spacing={2} minWidth={0}>
        <CardTagSelection
          tags={remapTitleToLabel(highlights)}
          predefinedTags={remapTitleToLabel(highlightsForTenant ?? [])}
          onDelete={handleHighlightDelete}
          onDeleteBulk={handleHighlightsDelete}
          onCreateBulk={handleHighlightsAdd}
          data-testid={testIds.vehicles.detail('highlightedFeatures')}
          title={i18n.t('entity.vehicle.labels.carHighlights')}
          editDialogTitle={i18n.t('entity.vehicle.labels.carHighlights')}
          emptyMessage={i18n.t('entity.vehicle.labels.highlightedFeaturesPlaceholder')}
        />

        <CardTagSelection
          tags={remapTitleToLabel(shortcomings)}
          predefinedTags={remapTitleToLabel(shortcomingsForTenant ?? [])}
          onDelete={handleShortcomingDelete}
          onDeleteBulk={handleShortcomingsDelete}
          onCreateBulk={handleShortcomingsAdd}
          data-testid={testIds.vehicles.detail('shortcomings')}
          title={i18n.t('entity.vehicle.labels.shortcomings')}
          editDialogTitle={i18n.t('entity.vehicle.labels.shortcomings')}
          emptyMessage={i18n.t('entity.vehicle.labels.shortcomingsPlaceholder')}
          tagsColorScheme="red"
        />

        <HighlightedFeaturesCard
          featureItems={featuresRemapped}
          highlightedFeatures={highlightedFeatureKeys}
          onHighlightOnBulk={handleFeatureHighlightOnBulk}
          onHighlightOff={handleFeatureHighlightOff}
          onHighlightOffBulk={handleFeatureHighlightOffBulk}
        />

        <SalesClaimNote adId={props.adId} />
      </VStack>
    </Grid>
  );
}
