import {useEffect, useState} from 'react';

import {isNotNil} from 'ramda';

import {kanbanApi, SetMyKanbanSelectedUsersApiArg} from '@dms/api/kanban';
import {useGetCurrentUserInfoQuery, useGetUsersQuery} from '@dms/api/user';
import {handleApiError} from '@dms/shared';

import {pushGtmEvent} from 'shared';

import {KanbanUser} from '../types/KanbanUser';
import {getKanbanUsersFromUsers} from '../utils/getKanbanUsersFromUsers';
import {getNonHiddenSelectedUserIds} from '../utils/getNonHiddenSelectedUserIds';
import {getSelectedKanbanUsers} from '../utils/getSelectedKanbanUsers';

type UseKanbanUsersParams = (nonHiddenSelectedUserIds: string[]) => void;

export function useKanbanUsers(onFilterChange: UseKanbanUsersParams) {
  const {data: currentUserData} = useGetCurrentUserInfoQuery();

  const {
    data: users,
    isLoading: isUsersLoading,
    isError: isUsersErrored,
  } = useGetUsersQuery(undefined, {
    skip: !currentUserData,
  });

  const {
    data: kanbanSelectedUsers,
    isLoading: isSelectedUsersLoading,
    isError: isSelectedUsersErrored,
  } = kanbanApi.useGetMyKanbanSelectedUsersQuery(undefined, {
    skip: !currentUserData,
  });

  const [setMyKanbanSelectedUsers] = kanbanApi.useSetMyKanbanSelectedUsersMutation();

  const [selectedUsers, setSelectedUsers] = useState<KanbanUser[]>([]);

  const selectedUsersLoadingFallback = selectedUsers && selectedUsers.length === 0;

  useEffect(() => {
    if (isNotNil(kanbanSelectedUsers) && isNotNil(users) && selectedUsersLoadingFallback) {
      const kanbanUsers = getSelectedKanbanUsers({
        users,
        selectedUsers: kanbanSelectedUsers,
        currentUserData,
      });
      setSelectedUsers(kanbanUsers);
      onFilterChange(getNonHiddenSelectedUserIds(kanbanUsers));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kanbanSelectedUsers, users]);

  const onSelectUsers = async (filterdUsers: KanbanUser[]) => {
    setSelectedUsers(filterdUsers);

    const args: SetMyKanbanSelectedUsersApiArg = {
      setMyKanbanSelectedUsersRequestBody: {
        selectedUsers: filterdUsers.map((user) => ({
          userId: user.id,
          isHidden: user.isHidden,
        })),
      },
    };
    return await setMyKanbanSelectedUsers(args)
      .unwrap()
      .then(() => {
        onFilterChange(getNonHiddenSelectedUserIds(filterdUsers));
      })
      .then(() => {
        pushGtmEvent({
          event: 'button_click',
          event_id: 'opportunities_kanban_user_control_change',
          value: {
            selectedUsers: filterdUsers.map((user) => user.email),
            numberOfSelectedUsers: filterdUsers.length,
          },
        });
      })
      .catch(handleApiError);
  };

  const isLoading = isUsersLoading || isSelectedUsersLoading || selectedUsersLoadingFallback;
  const isError = isUsersErrored || isSelectedUsersErrored;

  return {
    currentUser: currentUserData,
    users: getKanbanUsersFromUsers(users),
    selectedUsers,
    isLoading,
    isError,
    onSelectUsers,
  };
}
