import {z} from 'zod';

import {omneticApi} from '@dms/api/core';

import {
  CreateDataQueryApiResponse,
  CreateDataQueryApiArg,
  ApplySharedPresetApiArg,
  ApplySharedPresetApiResponse,
  GetDataByDataQueryApiArg,
  GetDataByDataQueryApiResponse,
  ApplySharedPresetApiArgSchema,
  ApplySharedPresetApiResponseSchema,
  GetDataByDataQueryApiArgSchema,
  GetDataByDataQueryApiResponseSchema,
} from './types';

export const datagridApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getGridCodesList: build.query<string[], void>({
      query: () => ({
        url: `/dms/v5/data-grid`,
      }),
      extraOptions: {
        responseSchema: z.array(z.string()),
      },
    }),
    createDataQuery: build.query<CreateDataQueryApiResponse, CreateDataQueryApiArg>({
      query: (queryArg) => ({
        url: `/dms/v6/data-grid/${queryArg.code}/data-query`,
        method: 'POST',
        body: queryArg.createDataQueryRequestBody,
      }),
    }),
    applySharedPreset: build.mutation<ApplySharedPresetApiResponse, ApplySharedPresetApiArg>({
      query: (queryArg) => ({
        url: `/dms/v6/data-grid/${queryArg.code}/apply-shared-preset`,
        method: 'PUT',
        body: queryArg.applySharedPresetRequestBody,
        params: {instance: queryArg.instance},
      }),
      extraOptions: {
        requestSchema: ApplySharedPresetApiArgSchema,
        responseSchema: ApplySharedPresetApiResponseSchema,
      },
    }),
    getDataByDataQuery: build.query<GetDataByDataQueryApiResponse, GetDataByDataQueryApiArg>({
      query: (queryArg) => ({
        url: `/dms/v6/data-grid/${queryArg.gridCode}/data/${queryArg.dataQueryId}`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
      providesTags: (_result, _error, queryArg) => [{type: 'DataGrid', id: queryArg.gridCode}],
      extraOptions: {
        requestSchema: GetDataByDataQueryApiArgSchema,
        responseSchema: GetDataByDataQueryApiResponseSchema,
      },
    }),
  }),
});

export const {
  useGetGridCodesListQuery,
  useCreateDataQueryQuery,
  useLazyCreateDataQueryQuery,
  useApplySharedPresetMutation,
  useGetDataByDataQueryQuery,
  useLazyGetDataByDataQueryQuery,
} = datagridApi;
