import {Trend} from 'platform/components';
import {
  FlexboxAlign,
  Heading,
  HStack,
  Show,
  Text,
  ThemeColorTextPath,
  ValueByDevice,
  VStack,
} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';
import {match, Pattern} from 'ts-pattern';

import {ReactNode} from 'react';

import {always, isNil} from 'ramda';
import {isNotNaN, isNotNilOrEmpty, isNumber} from 'ramda-adjunct';

import {Money} from '@dms/api/shared';
import i18n from '@dms/i18n';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {EMPTY_PLACEHOLDER} from '../../constants/placeholders';

interface PriceBoxProps extends TestIdProps {
  title?: string | Nullish;
  price?: Money | Nullish;
  priceSub?: Money | Nullish;
  percentTag?: number;
  isDiscount?: boolean;
  align?: FlexboxAlign | ValueByDevice<FlexboxAlign>;
  size?: 'large';
  isVatDeductible?: boolean;
}

export function PriceBox(props: PriceBoxProps) {
  const formatCurrency = useFormatCurrency();

  const priceAmount = props.isDiscount
    ? Number(props.price?.amount) * -1
    : Number(props.price?.amount);

  const priceSubAmount = props.isDiscount
    ? Number(props.priceSub?.amount) * -1
    : Number(props.priceSub?.amount);

  const isPositive = match<[number | undefined, boolean | undefined], boolean | undefined>([
    props.percentTag,
    props.isDiscount,
  ])
    .when(
      ([percentTag, isDiscount]) =>
        isNumber(percentTag) && (isDiscount ? percentTag < 0 : percentTag > 0),
      always(true)
    )
    .otherwise(always(undefined));

  const isNegative = match<[number | undefined, boolean | undefined], boolean | undefined>([
    props.percentTag,
    props.isDiscount,
  ])
    .when(
      ([percentTag, isDiscount]) =>
        isNumber(percentTag) && (isDiscount ? percentTag > 0 : percentTag < 0),
      always(true)
    )
    .otherwise(always(undefined));

  const color = match<[boolean | undefined, boolean | undefined], ThemeColorTextPath | undefined>([
    isPositive,
    isNegative,
  ])
    .with([true, Pattern.any], always('success'))
    .with([Pattern.any, true], always('danger'))
    .otherwise(always(undefined));

  const getTrendTag = (): ReactNode => {
    if (isNil(props.percentTag) || isNaN(props.percentTag)) {
      return null;
    }

    return (
      <Trend
        label={`${Math.abs(props.percentTag)}%`}
        isPositive={isPositive}
        isNegative={isNegative}
        isSmall
        isSubtle
        data-testid={props['data-testid']}
      />
    );
  };

  return (
    <VStack spacing={1} data-testid={suffixTestId('priceBox', props)} align={props.align}>
      <VStack spacing={2} align={props.align}>
        <Text size="xSmall" color="secondary">
          {props.title}
        </Text>
        <HStack spacing={2} align="center">
          <Heading
            size={props.size === 'large' ? 3 : 4}
            color={color}
            data-testid={suffixTestId('withVat', props)}
          >
            {isNotNilOrEmpty(priceAmount) && isNotNaN(priceAmount)
              ? formatCurrency(priceAmount, props.price?.currency ?? '')
              : EMPTY_PLACEHOLDER}
          </Heading>
          {getTrendTag()}
        </HStack>
      </VStack>
      <Show
        when={isNotNilOrEmpty(priceSubAmount) && isNotNaN(priceSubAmount) && props.isVatDeductible}
      >
        <Text size="xSmall" color="secondary" data-testid={suffixTestId('withoutVat', props)}>
          {formatCurrency(priceSubAmount, props.priceSub?.currency ?? '')}{' '}
          {i18n.t('general.labels.withoutVat')}
        </Text>
      </Show>
    </VStack>
  );
}
