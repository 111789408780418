import {Card, TabProps, Tabs} from 'platform/components';
import {Box, HStack, Space, VStack} from 'platform/foundation';

import {isArray} from 'ramda-adjunct';

import {
  usePostBasketMaterialItemPriceListApiMutation,
  usePostBasketMaterialItemWarehouseArticleApiMutation,
} from '@dms/api/metadaWorkshopBasket';
import i18n from '@dms/i18n';
import {
  ArticleFolderTree,
  handleApiError,
  RequestTabMaterialWarehouseTabExternalFilters,
  useWarehouseDataGridTreeFolder,
} from '@dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

interface MaterialItemsListCardProps extends TestIdProps {
  packageId: string;
}

const DSMATERIAL_EXTERNAL_FILTER_ID = 'dsmaterial-external-filter';

export function MaterialItemsListCard(props: MaterialItemsListCardProps) {
  const [postBasketMaterialItemPriceList] = usePostBasketMaterialItemPriceListApiMutation();
  const [postBasketMaterialItemWarehouseArticle] =
    usePostBasketMaterialItemWarehouseArticleApiMutation();

  const [folderTreeRef, dataGridModifier] = useWarehouseDataGridTreeFolder();

  const actionCallback: (isWarehouse: boolean) => ActionCallback =
    (isWarehouse: boolean) =>
    ({actionKey, rowId}) => {
      if (actionKey !== 'addToBasket') {
        return;
      }

      const warehouseArticleId = isArray(rowId) ? rowId[0] : rowId;

      const requestParams = {
        packageId: props.packageId,
        body: {warehouseArticleId},
      };

      const request = isWarehouse
        ? postBasketMaterialItemWarehouseArticle(requestParams)
        : postBasketMaterialItemPriceList(requestParams);

      request.unwrap().catch(handleApiError);
    };

  const tabs: TabProps[] = [
    {
      id: 'warehouse',
      title: i18n.t('entity.warehouse.labels.warehouse'),
      content: (
        <Box height="75vh" width="100%">
          <HStack height="100%" spacing={4}>
            <Box flex={1}>
              <VStack height="100%">
                <Box>
                  <div id={DSMATERIAL_EXTERNAL_FILTER_ID}></div>
                </Box>
                <Space vertical={8} />
                <ArticleFolderTree
                  ref={folderTreeRef}
                  data-testid={suffixTestId('articleFolderTree', props)}
                />
              </VStack>
            </Box>
            <Box flex={3}>
              <DataGrid
                // DG must be re-rendered on folder change to update the query modifier
                // eslint-disable-next-line no-restricted-syntax
                key={dataGridModifier?.key}
                gridCode="basket-warehouse-articles"
                externalFilterId={DSMATERIAL_EXTERNAL_FILTER_ID}
                queryModifier={dataGridModifier?.queryModifier}
                filterComponent={(datagridProps) => (
                  <RequestTabMaterialWarehouseTabExternalFilters
                    data-testid={suffixTestId('dg-external-filters', props)}
                    {...datagridProps}
                  />
                )}
                actionCallback={actionCallback(true)}
                data-testid={suffixTestId('warehouse', props)}
              />
            </Box>
          </HStack>
        </Box>
      ),
    },
    {
      id: 'priceList',
      title: i18n.t('entity.warehouse.labels.priceList'),
      content: (
        <Box height="75vh" width="100%">
          <DataGrid
            gridCode="basket-supplier-price-list"
            actionCallback={actionCallback(false)}
            data-testid={suffixTestId('price-list', props)}
          />
        </Box>
      ),
    },
  ];

  return (
    <Card title={i18n.t('entity.workshopServicePackages.labels.itemsList')} isFullHeight>
      <VStack height="100%" grow={1}>
        <Tabs tabs={tabs} data-testid={suffixTestId('tabs', props)} />
      </VStack>
    </Card>
  );
}
