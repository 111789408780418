import {Currency} from 'platform/locale';

import {isNotNil} from 'ramda-adjunct';

import {useGetCurrentUserInfoQuery} from '@dms/api/user';

export function useSourcingCurrency(): Currency | null {
  const {data} = useGetCurrentUserInfoQuery();

  const currencyCode = data?.settings.currency;

  return isNotNil(currencyCode)
    ? {
        name: currencyCode,
        code: currencyCode,
        preferred: false,
        symbol: '',
      }
    : null;
}
