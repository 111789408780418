import {
  SaleVehicleAlbumPhotoResponseBody,
  SaleVehiclePromoPhotoResponseBody,
} from '@dms/api/saleVehiclePhoto';

import {UnifiedAlbumImage} from '../types/UnifiedAlbumImage';

export const toUnifiedAlbumImage = (
  image: SaleVehicleAlbumPhotoResponseBody | SaleVehiclePromoPhotoResponseBody
): UnifiedAlbumImage => {
  if ('position' in image) {
    return {
      ...image,
      latestFileOperation: null,
      weight: image.position,
      isCoverPhoto: false,
      isPromoPhoto: false,
      position: image.position,
      positionInSettings: image.positionInSettings,
    };
  }
  return {
    ...image,
    isPromoPhoto: false,
  };
};
