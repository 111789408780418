import {Icon} from 'platform/foundation';
import styled from 'styled-components';

import {UserSidebarIconSize} from '@dms/api/shared';

import {SIDEBAR_DESKTOP_MENU_ICON_SIZE} from '../constants/sidebarDesktopMenuIconSize';
import {SIDEBAR_MOBILE_ICON_SIZE} from '../constants/sidebarMobileIconSize';
import {useIsMobileNavigation} from '../hooks/useIsMobileNavigation';

interface CollapseToggleProps {
  iconSize: UserSidebarIconSize;
  isOpen: boolean;
}

export function CollapseToggle(props: CollapseToggleProps) {
  const isMobileNavigation = useIsMobileNavigation();

  return (
    <StyledIconWrapper isOpen={props.isOpen}>
      <Icon
        color="text.white"
        size={
          isMobileNavigation
            ? SIDEBAR_MOBILE_ICON_SIZE
            : SIDEBAR_DESKTOP_MENU_ICON_SIZE[props.iconSize]
        }
        value="navigation/expand_more"
      />
    </StyledIconWrapper>
  );
}

const StyledIconWrapper = styled.div<{isOpen?: boolean}>`
  transform: rotate(${(props) => (props.isOpen ? '180deg' : '0deg')});
`;
