import {DataStatus} from 'platform/components';
import {Heading, HStack, Show, Text, VStack} from 'platform/foundation';
import {Nullish} from 'utility-types';

import {isNil} from 'ramda';

import {useGetStorageLocationStatisticsApiQuery} from '@dms/api/metadaStorageLocation';
import i18n from '@dms/i18n';

interface StatisticsProps {
  warehouseId: string | Nullish;
}

export function Statistics(props: StatisticsProps) {
  const storageStatsQuery = useGetStorageLocationStatisticsApiQuery(
    {
      contextTarget: 'TIRE-WAREHOUSE',
      contextId: props.warehouseId || '',
    },
    {skip: !props.warehouseId}
  );

  const getOccupiedPercentage = () => {
    if (!storageStatsQuery.currentData) {
      return 0;
    }

    if (storageStatsQuery.currentData.occupied === 0 || storageStatsQuery.currentData.total === 0) {
      return 0;
    }

    return Math.round(
      (storageStatsQuery.currentData.occupied / storageStatsQuery.currentData.total) * 100
    );
  };

  const occupiedPercentageAsString = `${getOccupiedPercentage()}%`;

  return (
    <HStack width="100%" align="center" justify="center">
      <DataStatus
        isLoading={storageStatsQuery.isFetching || storageStatsQuery.isLoading}
        isError={storageStatsQuery.isError}
      >
        <HStack spacing={6} width="100%">
          <VStack width="100%" spacing={2}>
            <Text size="xSmall">{i18n.t('general.labels.total')}</Text>
            <Show when={isNil(storageStatsQuery.currentData?.total)}>
              <Heading size={3} color="tertiary">
                {i18n.t('page.tiresInventory.labels.noData')}
              </Heading>
            </Show>
            <Show when={storageStatsQuery.currentData?.total}>
              <Heading size={3}>
                {i18n.t('page.tiresInventory.labels.locations', {
                  value: storageStatsQuery.currentData?.total ?? '',
                })}
              </Heading>
            </Show>
          </VStack>
          <VStack width="100%" spacing={2}>
            <Text size="xSmall">{i18n.t('general.labels.available')}</Text>
            <Show when={isNil(storageStatsQuery.currentData?.available)}>
              <Heading size={3} color="tertiary">
                {i18n.t('page.tiresInventory.labels.noData')}
              </Heading>
            </Show>
            <Show when={storageStatsQuery.currentData?.available}>
              <Heading size={3} color="success">
                {i18n.t('page.tiresInventory.labels.locations', {
                  value: storageStatsQuery.currentData?.available ?? '',
                })}
              </Heading>
            </Show>
          </VStack>
          <VStack width="100%" spacing={2}>
            <Text size="xSmall">{i18n.t('page.tiresInventory.labels.occupied')}</Text>
            <Show when={isNil(storageStatsQuery.currentData?.occupied)}>
              <Heading size={3} color="tertiary">
                {i18n.t('page.tiresInventory.labels.noData')}
              </Heading>
            </Show>
            <Show when={storageStatsQuery.currentData?.occupied}>
              <Heading size={3} color="danger">
                {i18n.t('page.tiresInventory.labels.locations', {
                  value: storageStatsQuery.currentData?.occupied ?? '',
                })}
              </Heading>
            </Show>
          </VStack>
          <VStack width="100%" spacing={2}>
            <Text size="xSmall">{i18n.t('page.tiresInventory.labels.occupiedPercent')}</Text>
            <Show when={isNil(storageStatsQuery.currentData)}>
              <Heading size={3} color="tertiary">
                {i18n.t('page.tiresInventory.labels.noData')}
              </Heading>
            </Show>
            <Show when={!isNil(storageStatsQuery.currentData)}>
              <Heading size={3} color="danger">
                {occupiedPercentageAsString}
              </Heading>
            </Show>
          </VStack>
        </HStack>
      </DataStatus>
    </HStack>
  );
}
