import {isSameDay} from 'date-fns';

import {parseDate} from 'shared';

/**
 * Compares two dates to see if they are the same day
 * @param eventDate - The date to compare
 * @param compareDate - The date to compare against
 * @returns True if the dates are the same day, false otherwise
 */
export function compareDates(eventDate: string | Date, compareDate: string | Date): boolean {
  const parsedEventDate = typeof eventDate === 'string' ? parseDate(eventDate) : eventDate;
  const parsedCompareDate = typeof compareDate === 'string' ? parseDate(compareDate) : compareDate;
  return isSameDay(parsedEventDate, parsedCompareDate);
}
