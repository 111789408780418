import {metadaApi} from '@dms/api/core';

import {
  DeleteCustomerContractRequest,
  DeleteCustomerContractRequestSchema,
  DeleteCustomerContractResponse,
  DeleteCustomerContractResponseSchema,
  GetCustomerContractRequest,
  GetCustomerContractRequestSchema,
  GetCustomerContractResponse,
  GetCustomerContractResponseSchema,
  PostCustomerContractRequest,
  PostCustomerContractRequestSchema,
  PostCustomerContractResponse,
  PostCustomerContractResponseSchema,
} from './types';

export const metadaWarehouseDirectSaleVariantCustomerContractApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getCustomerContract: build.query<GetCustomerContractResponse, GetCustomerContractRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/customer-contract`,
        method: 'GET',
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'customerContract', id: queryArg.directSaleId},
      ],
      extraOptions: {
        requestSchema: GetCustomerContractRequestSchema,
        responseSchema: GetCustomerContractResponseSchema,
      },
    }),
    putCustomerContract: build.mutation<PostCustomerContractResponse, PostCustomerContractRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/customer-contract`,
        body: queryArg.body,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'customerContract', id: queryArg.directSaleId},
        {type: 'directSales', id: queryArg.directSaleId},
      ],
      extraOptions: {
        requestSchema: PostCustomerContractRequestSchema,
        responseSchema: PostCustomerContractResponseSchema,
      },
    }),
    deleteDirectSaleCustomerContract: build.mutation<
      DeleteCustomerContractResponse,
      DeleteCustomerContractRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/customer-contract/delete`,
        body: queryArg.body,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'customerContract', id: queryArg.directSaleId},
        {type: 'directSales', id: queryArg.directSaleId},
      ],
      extraOptions: {
        requestSchema: DeleteCustomerContractRequestSchema,
        responseSchema: DeleteCustomerContractResponseSchema,
      },
    }),
  }),
});

export const {
  useGetCustomerContractQuery,
  usePutCustomerContractMutation,
  useDeleteDirectSaleCustomerContractMutation,
} = metadaWarehouseDirectSaleVariantCustomerContractApi;
