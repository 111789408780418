import {ReactNode} from 'react';

import {buildUrl} from '@dms/api/core';
import {environment} from '@dms/environment';
import {useGetUserOptions, usePermissions} from '@dms/shared';

import {DataGridConfigProvider} from 'features/datagrid';

import {entityResourceResolver} from '../../utils/entityResourceResolver';

type DataGridContainerProps = {
  children: ReactNode;
};

//  This is AG Grid Enterprise License for production environment
const AG_GRID_LICENSE_KEY = environment.AG_GRID_LICENSE_KEY;

export function DataGridContainer(props: DataGridContainerProps) {
  return (
    <DataGridConfigProvider
      value={{
        entityResourceResolver,
        agGridEnterpriseLicenseKey: AG_GRID_LICENSE_KEY,
        buildAbsoluteUrl: buildUrl,
        useGetUserOptions,
        usePermissions,
      }}
    >
      {props.children}
    </DataGridConfigProvider>
  );
}
