import {useSelector} from 'react-redux';

import {defaultTo, find, map, reject} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetBankListQuery} from '@dms/api/cached';
import {selectActiveBranchId} from '@dms/api/features';
import {BankAccountData, BankAccountResponseBody, BankListResponseBody} from '@dms/api/shared';
import {useGetBranchQuery, useGetTenantQuery} from '@dms/api/tenant';

import {Nullish} from 'shared';

import {useCurrencies} from './useCurrencies';

type Code = string | Nullish;

export function useBank() {
  const {getCurrencyName} = useCurrencies();
  const {data: tenantData, isLoading: isLoadingTenant} = useGetTenantQuery();
  const {data, isLoading: isLoadingBankList} = useGetBankListQuery(
    {countryCode: tenantData?.country},
    {skip: !tenantData}
  );
  const branchId = useSelector(selectActiveBranchId);
  const {data: branchData, isLoading: isLoadingBranchData} = useGetBranchQuery(
    {branchId},
    {skip: !branchId}
  );

  const getBankByCode = (code: Code): BankListResponseBody | undefined =>
    find((bank) => bank.code === code, data ?? []);

  const composeBankAccount = (bankAccountData: Partial<BankAccountData> | Nullish): string | null =>
    reject(isNilOrEmpty, [
      bankAccountData?.ownerName,
      bankAccountData?.number,
      getBankByCode(bankAccountData?.bankCode)?.name,
      getCurrencyName(bankAccountData?.currency),
      bankAccountData?.swiftBic,
      bankAccountData?.iban,
    ]).join(', ');

  const bankOptions = data?.map((g) => ({
    label: `${g.name} (${g.code})`,
    value: g.code,
  }));

  const getBankAccountName = (bankAccount: BankAccountResponseBody) =>
    reject(isNilOrEmpty, [
      bankAccount.accountName,
      bankAccount.accountNumber,
      bankAccount.bankName,
      getCurrencyName(bankAccount.currency),
    ]).join(', ');

  const bankAccountOptions = map(
    (account) => ({
      label: getBankAccountName(account),
      value: account.accountNumber ?? '',
    }),
    branchData?.billingInformation?.bankAccounts ?? []
  );

  const getBankAccountByNumber = (bankNumber: string) =>
    find(
      (bank) => bank.accountNumber === bankNumber,
      branchData?.billingInformation?.bankAccounts ?? []
    );

  const isLoading = isLoadingTenant || isLoadingBankList || isLoadingBranchData;

  return {
    bankOptions,
    bankAccountOptions,
    getBank: (code: Code) => defaultTo(null, getBankByCode(code)),
    getBankName: (code: Code) => defaultTo(null, getBankByCode(code)?.name),
    composeBankAccount,
    getBankAccountName,
    getBankAccountByNumber,
    isLoading,
  };
}
