import {isFeatureEnabled} from 'feature-flags';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import featureFlags from '@dms/feature-flags';

import {OpportunityType} from '../types/OpportunityType';

export const getGridCode = (opportunityType: OpportunityType) =>
  match(opportunityType)
    .with('newInterests', 'activeInterests', always('interest'))
    .with('inSale', 'inBuying', 'warehouseTotal', () => {
      const redirectGridCode =
        (isFeatureEnabled(featureFlags.SALES_360_VEHICLES) &&
          !isFeatureEnabled(featureFlags.REPLACE_VEHICLE_DG_WITH_OPENSEARCH)) ||
        isFeatureEnabled(featureFlags.REPLACE_VEHICLE_DG_WITH_OPENSEARCH)
          ? 'vehicle-opensearch'
          : 'vehicle';

      return redirectGridCode;
    })
    .otherwise(always('business-case'));
