/* eslint-disable eag/match-named-export */
import {z} from 'zod';

export const ApplySharedPresetRequestBodySchema = z.object({
  sharedPresetId: z.string(),
});

export const ApplySharedPresetApiArgSchema = z.object({
  code: z.string(),
  instance: z.string().nullable().optional(),
  applySharedPresetRequestBody: ApplySharedPresetRequestBodySchema,
});

export const ApplySharedPresetApiResponseSchema = z.unknown();

export const CellStyleSchema = z.union([
  z.object({
    textColor: z.string().optional(),
    flagColor: z.string().optional(),
    flagSubtle: z.boolean().optional(),
  }),
  z.array(z.any()),
]);

export const CellSchema = z.object({
  value: z.any(),
  style: CellStyleSchema.optional(),
});

export const SmartSearchSchema = z.string().nullable();
export const FilterValuesSchema = z.record(
  z.string(),
  z.union([
    z.string(),
    z.array(z.string()),
    z.boolean(),
    z.number(),
    z.object({
      from: z.string().nullable(),
      to: z.string().nullable(),
    }),
    z.object({
      from: z.number().nullable(),
      to: z.number().nullable(),
    }),
    z.object({
      relativeInputKey: z.string(),
      relativeInputValue: z.number(),
    }),
  ])
);
export const OrderSchema = z.enum(['ASC', 'DESC']);
export const OrderByRequestBodySchema = z.object({
  columnKey: z.string(),
  order: OrderSchema,
});

export const CreateDataQueryRequestBodySchema = z.object({
  smartSearch: SmartSearchSchema,
  filters: FilterValuesSchema,
  order: z.array(OrderByRequestBodySchema),
});

export const CreateDataQueryApiArgSchema = z.object({
  code: z.string(),
  createDataQueryRequestBody: CreateDataQueryRequestBodySchema,
});

export const DataQueryIdResponseBodySchema = z.object({
  dataQueryId: z.string(),
});
export const CreateDataQueryApiResponseSchema = DataQueryIdResponseBodySchema;

export const RowActionsSchema = z.object({
  primary: z.array(z.string()).optional(),
  secondary: z.array(z.string()).optional(),
  rowClick: z.string().optional(),
});

export const DataGridRowSchema = z.object({
  id: z.string(),
  rowNumber: z.number(),
  cells: z.record(z.string(), CellSchema),
  actions: RowActionsSchema.optional(),
});

export const GetDataByDataQueryApiArgSchema = z.object({
  gridCode: z.string().optional(),
  dataQueryId: z.string().optional(),
  offset: z.number(),
  limit: z.number(),
});

export const GetDataByDataQueryApiResponseSchema = z.object({
  rows: z.array(DataGridRowSchema),
});

// Types (alphabetically ordered)
export type ApplySharedPresetApiArg = z.infer<typeof ApplySharedPresetApiArgSchema>;
export type ApplySharedPresetApiResponse = z.infer<typeof ApplySharedPresetApiResponseSchema>;
export type ApplySharedPresetRequestBody = z.infer<typeof ApplySharedPresetRequestBodySchema>;
export type Cell = z.infer<typeof CellSchema>;
export type CellStyle = z.infer<typeof CellStyleSchema>;
export type CreateDataQueryApiArg = z.infer<typeof CreateDataQueryApiArgSchema>;
export type CreateDataQueryApiResponse = z.infer<typeof CreateDataQueryApiResponseSchema>;
export type CreateDataQueryRequestBody = z.infer<typeof CreateDataQueryRequestBodySchema>;
export type DataGridRow = z.infer<typeof DataGridRowSchema>;
export type DataQueryIdResponseBody = z.infer<typeof DataQueryIdResponseBodySchema>;
export type FilterValues = z.infer<typeof FilterValuesSchema>;
export type GetDataByDataQueryApiArg = z.infer<typeof GetDataByDataQueryApiArgSchema>;
export type GetDataByDataQueryApiResponse = z.infer<typeof GetDataByDataQueryApiResponseSchema>;
export type Order = z.infer<typeof OrderSchema>;
export type OrderByRequestBody = z.infer<typeof OrderByRequestBodySchema>;
export type RowActions = z.infer<typeof RowActionsSchema>;
export type SmartSearch = z.infer<typeof SmartSearchSchema>;
