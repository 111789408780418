import {omit} from 'ramda';

import {GetMyKanbanSelectedUsersApiResponse} from '@dms/api/kanban';
import {GetCurrentUserInfoApiResponse, UserResponseBody} from '@dms/api/shared';
import {EMPTY_PLACEHOLDER, getNaturalPersonFullName} from '@dms/shared';

import {Nullish} from 'shared';

type GetSelectedKanbanUsersParams = {
  users: UserResponseBody[] | Nullish;
  selectedUsers: GetMyKanbanSelectedUsersApiResponse | Nullish;
  currentUserData: GetCurrentUserInfoApiResponse | Nullish;
};

export function getSelectedKanbanUsers({
  users,
  selectedUsers,
  currentUserData,
}: GetSelectedKanbanUsersParams) {
  if (!currentUserData) {
    return [];
  }

  const currentUser = {
    ...omit(['settings', 'firstName', 'lastName'], currentUserData),
    fullName:
      getNaturalPersonFullName({
        firstName: currentUserData.firstName,
        lastName: currentUserData.lastName,
      }) || EMPTY_PLACEHOLDER,
    isHidden: !!selectedUsers?.find((user) => user.userId === currentUserData.id)?.isHidden,
  };

  if (!users?.length || !selectedUsers?.length) {
    return [currentUser];
  }

  const selectedKanbanUsers = selectedUsers
    .map((selectedUser) => {
      const user = users.find(
        (u) => u.id === selectedUser.userId && u.id !== currentUser.id && !u.blocked
      );

      if (!user) {
        return null;
      }

      return {
        ...omit(['firstName', 'lastName', 'blocked'], user),
        fullName:
          getNaturalPersonFullName({
            firstName: user.firstName,
            lastName: user.lastName,
          }) || EMPTY_PLACEHOLDER,
        isHidden: selectedUser.isHidden,
      };
    })
    .filter(Boolean);

  return [currentUser, ...selectedKanbanUsers];
}
