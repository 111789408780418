import {HStack, Link, Show, Text} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {CebiaReportResponseBody} from '@dms/api/shared';
import {useGetUserQuery} from '@dms/api/user';
import i18n from '@dms/i18n';
import {getNaturalPersonFullName} from '@dms/shared';

import {parseDate, TestIdProps} from 'shared';

interface CebiaReportLineProps extends TestIdProps {
  report: CebiaReportResponseBody;
}

export function CebiaReportLine(props: CebiaReportLineProps) {
  const formatDateTime = useDateTimeFormatter();
  const {data: user} = useGetUserQuery(
    {id: props.report.createdBy ?? ''},
    {skip: isNilOrEmpty(props.report.createdBy)}
  );

  // @ts-ignore has same attributes
  const userFullName = getNaturalPersonFullName(user);

  return (
    <HStack spacing={3} align="center">
      <Link
        href={props.report.url}
        title={i18n.t('entity.cebiaReport.previousReport', {
          date: formatDateTime('dateShort', parseDate(props.report.createdAt)),
        })}
        rightIcon="action/open_in_new"
        size="small"
        target="_blank"
      />
      <Show when={isNotNilOrEmpty(userFullName)}>
        <Text size="xSmall" color="tertiary">
          {userFullName}
        </Text>
      </Show>
    </HStack>
  );
}
