import {
  Action,
  Actions,
  Button,
  ColorSchemeType,
  DataStatus,
  DialogsContext,
  Flag,
  Parameter,
  Parameters,
  closeCurrentDialog,
  openDialog,
  useAnimatedPopper,
} from 'platform/components';
import {
  Box,
  HStack,
  Heading,
  Icon,
  Link,
  Show,
  Space,
  ThemeColorPath,
  ThemeIconKey,
  VStack,
} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';
import {match} from 'ts-pattern';

import {use, useState} from 'react';

import {always, isNil, map, pipe, reject, sortBy} from 'ramda';
import {isEmptyArray} from 'ramda-adjunct';

import {
  IntegrationAction,
  IntegrationActionKey,
  IntegrationCode,
  IntegrationLevel,
  useGetServiceOrderIntegrationsQuery,
  usePutServiceOrderIntegrationMutation,
} from '@dms/api/metadaWorkshopServiceOrder';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {Nullish, TestIdProps, buildArray, parseDate, suffixTestId} from 'shared';

import {getExternalStatusLabel} from '../utils/getExternalStatusLabel';
import {AudatexCalculations} from './AudatexCalculations';
import {ClaraDetailDialog} from './ClaraDetailDialog';
import {ClaraSendDialog} from './ClaraSendDialog';
import {OrderTrackedTime} from './OrderTrackedTime';
import {SilverdatCalculations} from './SilverdatCalculations';

interface IntegrationsDropdownProps extends TestIdProps {
  serviceCaseId: string;
  serviceOrderId: string;
}

export function IntegrationsDropdown(props: IntegrationsDropdownProps) {
  const formatDateTime = useDateTimeFormatter();

  const [loadingAction, setLoadingAction] = useState<IntegrationActionKey | null>(null);

  const {data, isLoading, isError, refetch} = useGetServiceOrderIntegrationsQuery({
    serviceCaseId: props.serviceCaseId,
    serviceOrderId: props.serviceOrderId,
  });
  const [putServiceOrderIntegration] = usePutServiceOrderIntegrationMutation();

  const {isAnyDialogOpen} = use(DialogsContext);

  const {popperProps, Popper, togglePopper, referenceRef} = useAnimatedPopper({
    placement: 'bottom-end',
    gutter: 4,
    isOutsideClickIgnored: isAnyDialogOpen,
  });

  const integrations = sortBy(
    (integration) => integration?.position ?? 0,
    data?.orderIntegration ?? []
  );

  const onActionClick = (actionKey?: IntegrationActionKey) => {
    if (isNil(actionKey)) {
      return;
    }

    match(actionKey)
      .with('chronosContractReport', () =>
        openDialog(
          <OrderTrackedTime
            serviceCaseId={props.serviceCaseId}
            serviceOrderId={props.serviceOrderId}
          />,
          {title: i18n.t('entity.orderRequest.labels.workReport')}
        )
      )
      .with('audatexTaskCalculations', () =>
        openDialog(
          <AudatexCalculations
            serviceCaseId={props.serviceCaseId}
            serviceOrderId={props.serviceOrderId}
            onClose={closeCurrentDialog}
            data-testid={suffixTestId('audatexCalculations', props)}
          />,
          {
            title: i18n.t('entity.orderRequest.labels.selectCalculation'),
            size: 'large',
            withAdditionalFooter: true,
          }
        )
      )
      .with('silverdatMyclaimContractCalculations', () =>
        openDialog(
          <SilverdatCalculations
            serviceCaseId={props.serviceCaseId}
            serviceOrderId={props.serviceOrderId}
            onClose={closeCurrentDialog}
            data-testid={suffixTestId('audatexCalculations', props)}
          />,
          {
            title: i18n.t('entity.orderRequest.labels.selectCalculation'),
            size: 'large',
            withAdditionalFooter: true,
          }
        )
      )
      .with('daimlerClaraClaimSend', () =>
        openDialog(
          <ClaraSendDialog
            serviceCaseId={props.serviceCaseId}
            serviceOrderId={props.serviceOrderId}
            onClose={closeCurrentDialog}
          />,
          {
            title: i18n.t('entity.orderIntegration.labels.daimlerClara'),
            withAdditionalFooter: true,
          }
        )
      )
      .with('daimlerClaraClaimDetail', () =>
        openDialog(
          <ClaraDetailDialog
            serviceCaseId={props.serviceCaseId}
            serviceOrderId={props.serviceOrderId}
            onClose={closeCurrentDialog}
          />,
          {
            title: i18n.t('entity.orderIntegration.labels.daimlerClara'),
            withAdditionalFooter: true,
          }
        )
      )
      .otherwise((key) => {
        setLoadingAction(key);

        putServiceOrderIntegration({
          serviceCaseId: props.serviceCaseId,
          serviceOrderId: props.serviceOrderId,
          body: {actionKey: key},
        })
          .unwrap()
          .finally(() => setLoadingAction(null))
          .catch(handleApiError);
      });
  };

  return (
    <>
      <div ref={referenceRef}>
        <Button
          title={i18n.t('entity.orderIntegration.labels.integration')}
          variant="outlined"
          size="small"
          data-testid={props['data-testid']}
          onClick={(event) => {
            event.stopPropagation();
            togglePopper();
            refetch();
          }}
        />
      </div>
      <Popper {...popperProps}>
        <div onClick={(event) => event.stopPropagation()}>
          <Box
            borderRadius="medium"
            boxShadow="elevation_2"
            border="1px solid"
            borderColor="palettes.neutral.40.100"
            backgroundColor="general.white"
            padding={3}
          >
            <DataStatus
              isLoading={isLoading}
              isError={isError}
              isEmpty={isEmptyArray(integrations)}
            >
              <Box padding={2}>
                <VStack spacing={4}>
                  {integrations.map((integration) => (
                    <Box
                      key={integration?.integration?.code}
                      padding={4}
                      border="1px solid"
                      borderColor="general.separator"
                      borderRadius="small"
                      backgroundColor={
                        !integration?.integration?.createdAt ? 'palettes.neutral.10.100' : undefined
                      }
                    >
                      <HStack spacing={4} align="center" justify="space-between" minWidth={100}>
                        <HStack align="center" spacing={2}>
                          <Heading size={4}>
                            {getIntegrationTitle(integration?.integration?.code)}
                          </Heading>
                          <Show when={integration?.externalStatus?.status}>
                            <Flag
                              size="small"
                              label={getExternalStatusLabel(integration?.externalStatus?.status)}
                              colorScheme={match<
                                IntegrationLevel | Nullish,
                                ColorSchemeType | undefined
                              >(integration?.externalStatus?.level)
                                .with('error', always('red'))
                                .with('warning', always('orange'))
                                .with('success', always('green'))
                                .with('info', always('blue'))
                                .otherwise(always(undefined))}
                            />
                          </Show>
                        </HStack>
                        <Actions
                          size="small"
                          actions={pipe(
                            reject<IntegrationAction>((action) => action?.style === 'hidden'),
                            map<IntegrationAction, Action>((action) => ({
                              type: 'button',
                              title: getActionTitle(action?.key),
                              leftIcon: getActionIcon(action?.key),
                              variant: 'outlined',
                              isLoading: loadingAction === action?.key,
                              onClick: (e) => {
                                e.stopPropagation();
                                onActionClick(action?.key);
                              },
                              isDisabled: action?.style === 'disabled',
                            }))
                          )(integration?.action ?? [])}
                          data-testid={suffixTestId('actions', props)}
                        />
                      </HStack>
                      <Show
                        when={
                          integration?.notification ||
                          integration?.integration?.createdAt ||
                          integration?.integration?.updatedAt
                        }
                      >
                        <Space vertical={3} />
                      </Show>
                      <HStack align="center">
                        <Show when={integration?.notification?.level}>
                          <Icon
                            color={match<IntegrationLevel | Nullish, ThemeColorPath | undefined>(
                              integration?.notification?.level
                            )
                              .with('info', () => 'severity.informational')
                              .with('warning', () => 'severity.warning')
                              .with('success', () => 'severity.success')
                              .with('error', () => 'severity.danger')
                              .otherwise(always(undefined))}
                            value={match<IntegrationLevel | Nullish, ThemeIconKey | undefined>(
                              integration?.notification?.level
                            )
                              .with('info', () => 'action/info')
                              .with('warning', () => 'alert/warning')
                              .with('success', () => 'action/check_circle')
                              .with('error', () => 'alert/error')
                              .otherwise(always(undefined))}
                            size={4}
                          />
                          <Space horizontal={1} />
                        </Show>
                        <Parameters
                          color="secondary"
                          size="xSmall"
                          parameters={buildArray<Parameter>()
                            .when(
                              integration?.notification?.content,
                              integration?.notification?.content
                            )
                            .when(
                              integration?.integration?.createdAt,
                              i18n.t('entity.orderIntegration.labels.sent', {
                                date:
                                  integration?.integration?.createdAt &&
                                  formatDateTime(
                                    'dateTimeShort',
                                    parseDate(integration?.integration?.createdAt)
                                  ),
                              })
                            )
                            .when(
                              integration?.integration?.updatedAt,
                              i18n.t('entity.orderIntegration.labels.updated', {
                                date:
                                  integration?.integration?.updatedAt &&
                                  formatDateTime(
                                    'dateTimeShort',
                                    parseDate(integration?.integration?.updatedAt)
                                  ),
                              })
                            )}
                          data-testid={suffixTestId('parameters', props)}
                        />
                        <Show
                          when={
                            integration?.integration?.viewDetailsLink &&
                            integration?.integration?.createdAt
                          }
                        >
                          <Space fillAvailable />
                          <Link
                            size="small"
                            title={i18n.t('general.actions.viewDetail')}
                            href={decodeURIComponent(
                              integration?.integration?.viewDetailsLink ?? ''
                            )}
                            target="_blank"
                            data-testid={suffixTestId('view-detail', props)}
                          />
                        </Show>
                      </HStack>
                    </Box>
                  ))}
                </VStack>
              </Box>
            </DataStatus>
          </Box>
        </div>
      </Popper>
    </>
  );
}

const getActionIcon = (key?: IntegrationActionKey) =>
  match<IntegrationActionKey | undefined, ThemeIconKey | undefined>(key)
    .with(
      undefined,
      'audatexTaskCalculations',
      'silverdatMyclaimContractCalculations',
      always(undefined)
    )
    .with(
      'audatexTaskResend',
      'chronosContractResend',
      'hyundaiHapReceiveResend',
      'silverdatMyclaimContractResend',
      always('action/update')
    )
    .with(
      'hyundaiHapReceiveRetrieve',
      'xentryPartsJobRetrieve',
      'xentryOperationTimeRetrieve',
      'tecrmiGetBasket',
      always('file/download')
    )
    .with(
      'audatexTaskSend',
      'chronosContractSend',
      'hyundaiHapReceiveSend',
      'silverdatMyclaimContractSend',
      'xentryPartsJobSend',
      'xentryOperationTimeSend',
      'tecrmiSend',
      'daimlerClaraClaimSend',
      always('content/send')
    )
    .with('chronosContractReport', always('action/info_outline'))
    .with('daimlerClaraClaimDetail', always('view'))
    .exhaustive();

const getActionTitle = (key?: IntegrationActionKey) =>
  match<IntegrationActionKey | undefined, string>(key)
    .with(undefined, always(''))
    .with(
      'audatexTaskResend',
      'chronosContractResend',
      'hyundaiHapReceiveResend',
      'silverdatMyclaimContractResend',
      always(i18n.t('entity.orderIntegration.actions.resend'))
    )
    .with(
      'hyundaiHapReceiveRetrieve',
      'xentryPartsJobRetrieve',
      'xentryOperationTimeRetrieve',
      'tecrmiGetBasket',
      always(i18n.t('entity.orderIntegration.actions.retrieve'))
    )
    .with('audatexTaskCalculations', always(i18n.t('entity.orderIntegration.actions.calculations')))
    .with(
      'audatexTaskSend',
      'chronosContractSend',
      'hyundaiHapReceiveSend',
      'silverdatMyclaimContractSend',
      'xentryPartsJobSend',
      'xentryOperationTimeSend',
      'tecrmiSend',
      'daimlerClaraClaimSend',
      always(i18n.t('entity.orderIntegration.actions.send'))
    )
    .with('chronosContractReport', always(i18n.t('entity.orderIntegration.actions.report')))
    .with(
      'silverdatMyclaimContractCalculations',
      always(i18n.t('entity.orderIntegration.actions.calculations'))
    )
    .with('daimlerClaraClaimDetail', always(i18n.t('general.actions.viewDetail')))
    .exhaustive();

const getIntegrationTitle = (key?: IntegrationCode) =>
  match<IntegrationCode | undefined, string>(key)
    .with(undefined, always(''))
    .with('AUDATEX', always(i18n.t('entity.orderIntegration.labels.audatex')))
    .with('CHRONOS', always(i18n.t('entity.orderIntegration.labels.chronos')))
    .with('HYUNDAI_HAP', always(i18n.t('entity.orderIntegration.labels.hyundai')))
    .with('SILVERDAT_MYCLAIM', always(i18n.t('entity.orderIntegration.labels.silverdatMyclaim')))
    .with('XENTRY-PARTS-JOB', always(i18n.t('entity.orderIntegration.labels.xentryPartsJob')))
    .with(
      'XENTRY-OPERATION-TIME',
      always(i18n.t('entity.orderIntegration.labels.xentryOperationTime'))
    )
    .with('TECRMI', always(i18n.t('entity.orderIntegration.labels.tecRMI')))
    .with('DAIMLER_CLARA', always(i18n.t('entity.orderIntegration.labels.daimlerClara')))
    .exhaustive();
