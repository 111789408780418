import {openConfirmDialog, openDeleteDialog} from 'platform/components';
import {match} from 'ts-pattern';

import {isArray} from 'ramda-adjunct';

import {
  useDeleteTireWidthMutation,
  usePostTireWidthSetActiveMutation,
  usePostTireWidthSetInactiveMutation,
} from '@dms/api/metadaTires';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function TireInventoryWidth() {
  const navigate = useNavigate();

  const [deleteTireWidth] = useDeleteTireWidthMutation();
  const [postTireWidthSetActive] = usePostTireWidthSetActiveMutation();
  const [postTireWidthSetInactive] = usePostTireWidthSetInactiveMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData}) => {
    const id = isArray(rowId) ? rowId[0] : rowId;

    match(actionKey)
      .with('edit', 'redirectDetail', () =>
        navigate(composePath(settingsRoutes.tireWidthDetail, {params: {id}}))
      )
      .with('setAsInactive', () =>
        openConfirmDialog({
          onConfirm: () =>
            postTireWidthSetInactive({tireWidthId: id})
              .unwrap()
              .then(refreshData)
              .catch(handleApiError),
        })
      )
      .with('setAsActive', () =>
        openConfirmDialog({
          onConfirm: () =>
            postTireWidthSetActive({tireWidthId: id})
              .unwrap()
              .then(refreshData)
              .catch(handleApiError),
        })
      )
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () =>
            deleteTireWidth({tireWidthId: id}).unwrap().then(refreshData).catch(handleApiError),
        })
      );
  };

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('entity.tiresInventory.labels.tireWidth'),
        actions: [
          {
            type: 'button',
            title: i18n.t('entity.tiresInventory.labels.newTireWidth'),
            onClick: () => navigate(settingsRoutes.tireWidthCreate),
          },
        ],
      }}
      data-testid={testIds.settings.tireWidth('template')}
    >
      <DataGrid
        gridCode="tire-width"
        actionCallback={actionCallback}
        data-testid={testIds.settings.tireWidth('datagrid')}
      />
    </SettingsTemplate>
  );
}
