import {Button, ButtonGroup, closeCurrentDialog, showNotification} from 'platform/components';
import {Box, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useCallback, useState} from 'react';

import {mergeAll} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {
  GetMetadaServiceCaseApiResponse,
  usePostServiceCaseCopyServiceRequestsMutation,
} from '@dms/api/metadaWorkshopServiceCase';
import i18n from '@dms/i18n';
import {workshopRoutes} from '@dms/routes';
import {ORDER_TABS, handleApiError, queryParams, workshopSearchParams} from '@dms/shared';

import {RequiredTestIdProps, composePath, suffixTestId} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject, RowData} from 'features/datagrid';

type PreviousRequestsRowData = {
  serviceCaseId: {value: string};
  serviceOrderId: {value: string};
};

interface CopyPreviousRequestsProps extends RequiredTestIdProps {
  serviceCase: GetMetadaServiceCaseApiResponse;
}

export function CopyPreviousRequests(props: CopyPreviousRequestsProps) {
  const [postServiceCaseCopyServiceRequests] = usePostServiceCaseCopyServiceRequestsMutation();

  const [selectedRequests, setSelectedRequests] = useState<string[] | null>(null);

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {vehicleId: props.serviceCase?.vehicleId}]),
    [props.serviceCase?.vehicleId]
  );

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData}) => {
    const castedRowData = rowData as PreviousRequestsRowData;
    const requestId = isArray(rowId) ? rowId[0] : rowId;

    const path = composePath(workshopRoutes.serviceCaseDetail, {
      params: {
        id: castedRowData.serviceCaseId.value,
      },
      queryParams: {
        source: 'service-orders',
        section: workshopSearchParams.serviceDetail.orders,
        [queryParams.SERVICE_CASE_ORDER_ID]: castedRowData.serviceOrderId.value,
        [queryParams.SERVICE_CASE_ORDER_TAB]: ORDER_TABS.JOBS,
        [queryParams.SERVICE_CASE_OPEN_JOB_ID]: requestId,
      },
    });

    match(actionKey)
      .with('open', () => window.open(path))
      .otherwise(() =>
        showNotification.error(`Action callback was not specified for action ${actionKey}`)
      );
  };

  const onRowSelectionChange = (data: RowData[]) => setSelectedRequests(data.map((row) => row.id));

  const handleSubmit = async () => {
    if (props.serviceCase?.id) {
      await postServiceCaseCopyServiceRequests({
        serviceCaseId: props.serviceCase?.id,
        body: {serviceJobIds: selectedRequests || []},
      })
        .unwrap()
        .then(closeCurrentDialog)
        .catch(handleApiError);
    }
  };

  return (
    <VStack spacing={4}>
      <Box height={135}>
        <DataGrid
          gridCode="service-history-requests-service-case-creating"
          onRowSelectionChange={onRowSelectionChange}
          queryModifier={queryModifier}
          actionCallback={actionCallback}
          data-testid={suffixTestId('dataGrid', props)}
        />
      </Box>
      <VStack spacing={4}>
        <ButtonGroup align="right">
          <Button
            variant="secondary"
            title={i18n.t('general.actions.discard')}
            onClick={closeCurrentDialog}
            data-testid={suffixTestId('discard', props)}
          />
          <Button
            variant="primary"
            title={i18n.t('general.actions.add')}
            isDisabled={!selectedRequests?.length}
            onClick={handleSubmit}
            data-testid={suffixTestId('submit', props)}
          />
        </ButtonGroup>
      </VStack>
    </VStack>
  );
}
