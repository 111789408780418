import {Helmet} from 'react-helmet-async';

import {defaultTo} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetIssueNoteCorrectionQuery} from '@dms/api/metadaWarehouseIssueNoteCorrection';
import i18n from '@dms/i18n';
import {testIds, warehouseRoutes} from '@dms/routes';
import {DetailTemplate, DetailTemplateHeader, NavigationItem} from '@dms/shared';

import {composePath, generateHashFromObjects} from 'shared';

import {useWarehouseParams} from '../../hooks/useWarehouseParams';
import {Documents} from './(sections)/Documents/Documents';
import {Overview} from './(sections)/Overview/Overview';

export function IssueNoteCorrectionDetail() {
  const {issueNoteCorrectionId} = useWarehouseParams();

  const {
    data: issueNoteCorrection,
    isLoading,
    isError,
  } = useGetIssueNoteCorrectionQuery(
    {issueNoteCorrectionId: issueNoteCorrectionId as string},
    {skip: isNilOrEmpty(issueNoteCorrectionId)}
  );

  const header: DetailTemplateHeader = {
    title: `${i18n.t('page.warehouse.labels.issueNoteCorrection')} ${defaultTo('', issueNoteCorrection?.issueNoteCorrection.number)}`,
  };

  const navigation: NavigationItem[] = [
    {
      id: 'overview',
      label: i18n.t('general.labels.overview'),
      href: composePath(warehouseRoutes.issueNoteCorrectionDetailOverview, {
        params: {id: issueNoteCorrectionId},
      }),
      content: (
        <Overview
          issueNoteCorrection={issueNoteCorrection}
          isIssueNoteCorrectionLoading={isLoading}
          data-testid={testIds.warehouse.issueNoteCorrectionDetailOverview('section.overview')}
        />
      ),
      'data-testid': testIds.warehouse.issueNoteCorrectionDetailOverview('navigation.overview'),
    },
    {
      id: 'documents',
      label: i18n.t('general.labels.documents'),
      href: composePath(warehouseRoutes.issueNoteCorrectionDetailDocuments, {
        params: {id: issueNoteCorrectionId},
      }),
      content: (
        <Documents
          isIssueNoteCorrectionLoading={isLoading}
          data-testid={testIds.warehouse.issueNoteCorrectionDetailOverview('section.documents')}
        />
      ),
      'data-testid': testIds.warehouse.issueNoteCorrectionDetailOverview('navigation.documents'),
    },
  ];

  return (
    <>
      <Helmet title={i18n.t('page.warehouse.labels.issueNoteCorrectionDetail')} />
      <DetailTemplate
        key={generateHashFromObjects(issueNoteCorrection)}
        isLoading={isLoading}
        isError={isError}
        header={header}
        navigation={navigation}
        data-testid={testIds.warehouse.issueNoteCorrectionDetailOverview('page')}
      />
    </>
  );
}
