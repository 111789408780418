import {Option} from 'platform/components';

import {defaultTo, isNil} from 'ramda';

import {GetSupplierOrderVariantsResponse} from '@dms/api/metadaWarehouseSupplierOrderVariants';

import {Nullish} from 'shared';

export const getOptionsFromSupplierOrderVariant = (
  supplierOrderVariants: GetSupplierOrderVariantsResponse[] | Nullish
): Option[] => {
  if (isNil(supplierOrderVariants)) {
    return [];
  }
  return supplierOrderVariants.map((supplierOrderVariant) => ({
    label: defaultTo('', supplierOrderVariant?.name),
    value: defaultTo('', supplierOrderVariant?.orderVariantId),
  }));
};
