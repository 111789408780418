import {openDeleteDialog} from 'platform/components';
import {match} from 'ts-pattern';

import {isArray} from 'ramda-adjunct';

import {
  useDeleteServiceOrderGroupMutation,
  usePostServiceOrderGroupSetAsActiveMutation,
  usePostServiceOrderGroupSetAsInactiveMutation,
} from '@dms/api/metadaWorkshopServiceOrderGroup';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {catchUnhandledDataGridActions, handleApiError} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function WorkshopServiceOrderGroups() {
  const navigate = useNavigate();

  const [deleteServiceOrderGroup] = useDeleteServiceOrderGroupMutation();
  const [postServiceOrderGroupSetAsActive] = usePostServiceOrderGroupSetAsActiveMutation();
  const [postServiceOrderGroupSetAsInactive] = usePostServiceOrderGroupSetAsInactiveMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData}) => {
    const id = isArray(rowId) ? rowId[0] : rowId;

    match(actionKey)
      .with('setAsActive', () =>
        postServiceOrderGroupSetAsActive({serviceOrderGroupId: id})
          .unwrap()
          .then(refreshData)
          .catch(handleApiError)
      )
      .with('setAsInactive', () =>
        postServiceOrderGroupSetAsInactive({serviceOrderGroupId: id})
          .unwrap()
          .then(refreshData)
          .catch(handleApiError)
      )
      .with('redirectDetail', () =>
        navigate(composePath(settingsRoutes.workshopServiceOrderGroupDetail, {params: {id}}))
      )
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () =>
            deleteServiceOrderGroup({serviceOrderGroupId: id})
              .unwrap()
              .then(refreshData)
              .catch(handleApiError),
        })
      )
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('page.settings.labels.serviceOrderGroups'),
        actions: [
          {
            type: 'button',
            title: i18n.t('page.settings.actions.newServiceOrderGroup'),
            onClick: () => navigate(settingsRoutes.workshopServiceOrderGroupCreate),
          },
        ],
      }}
      data-testid={testIds.settings.workshopServiceOrderGroups('template')}
    >
      <DataGrid
        gridCode="service-order-group"
        actionCallback={actionCallback}
        data-testid={testIds.settings.workshopServiceOrderGroups('datagrid')}
      />
    </SettingsTemplate>
  );
}
