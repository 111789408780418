import {ButtonGroup, Form, FormButton, FormField} from 'platform/components';
import {Box, HStack, Show, VStack, Text} from 'platform/foundation';

import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {useTenant} from '../../hooks/useTenant';
import {AmountDisplay} from '../VehicleTransactionForm/components/AmountDisplay';
import {calculateFinancingCost} from './utils/calculateFinancingCost';

interface CalculateFinancingFormProps extends RequiredTestIdProps {
  onDiscard?: VoidFunction;
  onSubmit: (expectedFinancingCost: number) => void;
}

export function CalculateFinancingForm(props: CalculateFinancingFormProps) {
  const {tenantCurrency} = useTenant();
  return (
    <Form
      onSubmit={(data) => {
        props.onSubmit(
          calculateFinancingCost(data.financedValue, data.interestRate, data.expectedDaysInStock)
        );
        return Promise.resolve();
      }}
    >
      {(control, formApi) => (
        <VStack spacing={4}>
          <Text size="xSmall" color="secondary" noWrap>
            {i18n.t('entity.vehicle.labels.financingCostCalculationDescription')}
          </Text>
          <FormField
            type="currency"
            name="financedValue"
            label={i18n.t('entity.vehicle.labels.financedValue')}
            control={control}
            data-testid={suffixTestId('financedValue', props)}
            currency={tenantCurrency}
          />
          <HStack spacing={4}>
            <Box flexGrow={1} width="50%">
              <FormField
                type="number"
                name="expectedDaysInStock"
                control={control}
                label={i18n.t('entity.vehicle.labels.expectedDaysInStock')}
              />
            </Box>
            <Box flexGrow={1} width="50%">
              <FormField
                name="interestRate"
                type="number"
                control={control}
                label={i18n.t('entity.vehicle.labels.interestRate')}
              />
            </Box>
          </HStack>
          <AmountDisplay
            label={i18n.t('entity.vehicle.labels.expectedFinancingCostWithoutVat')}
            amount={calculateFinancingCost(
              formApi.watch('financedValue'),
              formApi.watch('interestRate'),
              formApi.watch('expectedDaysInStock')
            )}
            currency={tenantCurrency}
          />

          <ButtonGroup align="right">
            <Show when={props.onDiscard}>
              <FormButton
                variant="secondary"
                title={i18n.t('general.actions.discard')}
                onClick={props.onDiscard}
                control={control}
                data-testid={suffixTestId('discard', props)}
              />
            </Show>
            <FormButton
              type="submit"
              variant="primary"
              control={control}
              title={i18n.t('general.actions.use')}
              data-testid={suffixTestId('submit', props)}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}
