import {Button, openDeleteDialog, openDialog, showNotification} from 'platform/components';
import {Heading, HStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useCallback} from 'react';

import {head, isNil} from 'ramda';
import {isArray, isNotNil} from 'ramda-adjunct';

import {useGetAdvertisingStatisticsQuery} from '@dms/api/salesPublishing';
import {useDeleteWatermarkMutation, useLazyGetWatermarkListQuery} from '@dms/api/vehicle';
import {useGetWatermarkQuery} from '@dms/api/watermark';
import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid, useRefreshDataGrid} from 'features/datagrid';

import {openRepublishInfoDialog} from '../../components/RepublishInfoDialogContent/openRepublishInfoDialog';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {WatermarkDeleteDialog} from './components/WatermarkDeleteDialog';

export function PhotosWatermarking() {
  const {isLoading} = useGetWatermarkQuery();
  const navigate = useNavigate();
  const [deleteWatermark] = useDeleteWatermarkMutation();
  const [getWatermarkList] = useLazyGetWatermarkListQuery();
  const {data: advertisingStatistics} = useGetAdvertisingStatisticsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const handleActionCallback: ActionCallback = useCallback(
    ({actionKey, rowId, refreshData}) => {
      const id = isArray(rowId) ? head(rowId) : rowId;

      if (isNil(id)) {
        showNotification.error();
        return;
      }

      match(actionKey)
        .with('edit', () =>
          navigate(composePath(settingsRoutes.advertisingWatermarkingDetail, {params: {id}}))
        )
        .with('delete', () => {
          getWatermarkList()
            .unwrap()
            .then((watermarkList) => {
              if (watermarkList?.length === 1) {
                openDeleteDialog({
                  onConfirm: () =>
                    deleteWatermark({
                      watermarkId: id,
                      deleteWatermarkRequestBody: {republishAds: true},
                    })
                      .unwrap()
                      .then(refreshData)
                      .then(() => {
                        if (
                          isNotNil(advertisingStatistics?.inAdvertisementCount) &&
                          advertisingStatistics.inAdvertisementCount > 0
                        ) {
                          openRepublishInfoDialog();
                        } else {
                          showNotification.success(
                            i18n.t('entity.watermark.deleteNotification.success')
                          );
                        }
                      })
                      .catch(handleApiError),
                  text: i18n.t('page.salesSettings.labels.watermarks.delete'),
                });
              } else {
                openDialog(
                  <WatermarkDeleteDialog
                    inAdvertisementCount={advertisingStatistics?.inAdvertisementCount}
                    watermarkId={id}
                  />,
                  {
                    id: 'deleteWatermark-newWatermark',
                    title: i18n.t('entity.watermark.deleteDialog.title'),
                    'data-testid': 'deleteWatermark-newWatermark-dialog',
                    size: 'small',
                    onCloseComplete: () => {
                      refreshDataGrid();
                    },
                  }
                );
              }
            })
            .catch(handleApiError);
        })
        .otherwise(() => showNotification.warning('unknown action key'));
    },
    [deleteWatermark, getWatermarkList, navigate, refreshDataGrid, advertisingStatistics]
  );

  return (
    <SettingsTemplate
      isLoading={isLoading}
      header={{
        title: i18n.t('page.salesSettings.labels.watermarks.name'),
        breadcrumbs: [
          {
            label: i18n.t('page.salesSettings.labels.advertising'),
            href: settingsRoutes.advertising,
          },
        ],
      }}
      description={i18n.t('page.salesSettings.labels.watermarks.description')}
      data-testid="settings-advertising-watermarking"
    >
      <HStack align="center" justify="space-between">
        <Heading size={4}>
          {i18n.t('page.salesSettings.backgroundRemoval.configurationList')}
        </Heading>
        <Button
          leftIcon="content/add"
          title={i18n.t('page.salesSettings.backgroundRemoval.addConfiguration')}
          variant="link"
          onClick={() => navigate(settingsRoutes.advertisingWatermarkingCreate)}
          data-testid="settings-advertising-watermarking-createConfiguration"
        />
      </HStack>
      <DataGrid
        gridCode="watermark"
        actionCallback={handleActionCallback}
        data-testid="settings-advertising-watermarking-datagrid"
        ref={dataGridRef}
      />
    </SettingsTemplate>
  );
}
