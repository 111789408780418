import {Alert, closeCurrentDialog, openDialog} from 'platform/components';
import {useFormatCurrency} from 'platform/locale';

import {isNilOrEmpty} from 'ramda-adjunct';

import {UserRole} from '@dms/api/metadaInsurance';
import i18n from '@dms/i18n';

import {useRequiredParams} from 'shared';

import {useInsuranceComparison} from '../hooks/useInsuranceComparison';
import {useInsuranceData} from '../hooks/useInsuranceData';
import {RecommendCustomerForm} from './RecommendCustomerForm';

export function InsuranceBanner() {
  const {id: businessCaseId} = useRequiredParams();
  const formatCurrency = useFormatCurrency();

  const insuranceData = useInsuranceData(businessCaseId, true);

  const {compareOffers, isComparisonLoading} = useInsuranceComparison(
    businessCaseId,
    insuranceData
  );

  if (
    isNilOrEmpty(insuranceData.insurerNumber) ||
    insuranceData.isFinished ||
    insuranceData.isLoading ||
    insuranceData.isError
  ) {
    return null;
  }

  const recommendCustomer = () => {
    openDialog(
      <RecommendCustomerForm
        insuranceData={insuranceData}
        businessCaseId={businessCaseId}
        onDone={closeCurrentDialog}
        onCancel={closeCurrentDialog}
      />,
      {
        title: i18n.t('entity.insurance.actions.recommendCustomer'),
      }
    );
  };

  if (insuranceData.role === UserRole.SELLER) {
    return (
      <Alert
        type="banner"
        variant="info"
        title={i18n.t('entity.insurance.labels.sellInsuranceBanner', {
          amount: formatCurrency(insuranceData.insuranceOffer?.commissionFull ?? 0, 'CZK'),
        })}
        hyperlinks={[
          {
            title: i18n.t('entity.insurance.actions.offerInsurance'),
            onClick: compareOffers,
            isDisabled: isComparisonLoading,
          },
        ]}
      />
    );
  }

  if (insuranceData.role === UserRole.TIPPER) {
    return (
      <Alert
        type="banner"
        variant="info"
        title={i18n.t('entity.insurance.labels.recommendCustomerBanner')}
        hyperlinks={[
          {
            title: i18n.t('entity.insurance.actions.recommendCustomer'),
            onClick: recommendCustomer,
          },
        ]}
      />
    );
  }

  return null;
}
