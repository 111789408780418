import {Separator} from 'platform/components';
import {Grid, GridItem, Heading, Integer, Show, Text, VStack} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {descend, head, indexBy, map, pipe, reverse, sort, toPairs} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {InvoiceV3ResponseBody} from '@dms/api/metadaErrorLogs';
import {
  VatTypeSummaryV2ResponseBody,
  VatTypeSummaryResponseBody,
  MoneyResponseBody,
} from '@dms/api/shared';
import i18n from '@dms/i18n';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {useInvoiceVatRatesContext} from '../context/useInvoiceVatRatesContext';
import {VatTypeSummary} from '../types';

type Summary = VatTypeSummaryV2ResponseBody | VatTypeSummaryResponseBody;
type SummaryWithKey = Summary & {key: string};

interface AdditionalVatCalculationProps extends TestIdProps {
  roundingItems: InvoiceV3ResponseBody['documentSummary']['summaryItems'] | Nullish;
  vatTypeSummary: VatTypeSummary | Nullish;
}

const COLUMNS_COUNT = 4;
const ROUNDING_ITEMS_THRESHOLD = 2;

export function RoundingSummary(props: AdditionalVatCalculationProps) {
  const formatCurrency = useFormatCurrency();
  const {rateOptions} = useInvoiceVatRatesContext();
  const vatRatesByValue = indexBy((item) => item.value, rateOptions);

  const highestVatRate = pipe(
    toPairs<object>,
    map(([key, value]: [string, Summary]) => ({...value, key})),
    sort(descend((item) => item.vatRate)),
    head,
    (summary: SummaryWithKey) => vatRatesByValue[summary?.key ?? '']?.label
  )(props.vatTypeSummary ?? {});

  const formatPrice = (money: MoneyResponseBody | Nullish) =>
    formatCurrency(parseFloat(money?.amount ?? ''), money?.currency ?? '', 2);

  const roundingItemsLength = props.roundingItems?.length ?? 0;
  const areMultipleItemsVisible = roundingItemsLength > ROUNDING_ITEMS_THRESHOLD;
  const titleSpan = (COLUMNS_COUNT - roundingItemsLength) as Integer;

  const roundingItems = reverse(props.roundingItems ?? []);

  return (
    <VStack spacing={2} data-testid={suffixTestId('section', props)}>
      <Show when={isNotNilOrEmpty(props.roundingItems)}>
        <Heading size={4}>{i18n.t('entity.accounting.labels.rounding')}</Heading>

        <Grid columns={COLUMNS_COUNT} key="labels-calculation" align="flex-end">
          <GridItem span={titleSpan}>
            <Text size="xSmall" color="tertiary">
              {i18n.t('general.labels.vatRate')}
            </Text>
          </GridItem>
          <Show when={areMultipleItemsVisible}>
            <Text size="xSmall" color="tertiary">
              {i18n.t('entity.lineItems.labels.totalPriceWithoutVat')}
            </Text>
            <Text size="xSmall" color="tertiary">
              {i18n.t('general.labels.vat')}
            </Text>
          </Show>
          <Text size="xSmall" color="tertiary">
            {i18n.t('entity.lineItems.labels.totalPriceWithVat')}
          </Text>
        </Grid>
        <Separator spacing={0} />
        <Grid columns={COLUMNS_COUNT}>
          <GridItem span={titleSpan}>
            <Text size="xSmall" color="secondary">
              {highestVatRate}
            </Text>
          </GridItem>
          {roundingItems.map((item) => (
            <Text
              size="xSmall"
              color="secondary"
              key={item.reference}
              data-testid={suffixTestId(item.type, props)}
            >
              {formatPrice(item.amount)}
            </Text>
          ))}
        </Grid>
      </Show>
    </VStack>
  );
}
