/* eslint-disable eag/match-named-export */
import {ThemeColorPath} from 'platform/foundation';

export const avatarColors: ThemeColorPath[] = [
  'palettes.neutral.50.100',
  'palettes.orange.60.100',
  'palettes.magenta.60.100',
  'palettes.green.60.100',
  'palettes.purple.60.100',
  'palettes.blue.60.100',
];

export const cardColors: (ThemeColorPath | null)[] = [
  null,
  'palettes.orange.40.100',
  'palettes.magenta.40.100',
  'palettes.green.40.100',
  'palettes.purple.40.100',
  'palettes.blue.40.100',
];
