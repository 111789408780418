import {FloatingTree} from '@floating-ui/react';
import {Text, TextProps} from 'platform/foundation';

import {ReactElement, ReactNode} from 'react';

import {TestIdProps} from 'shared';

import {Placement} from '../../../types/Placement';
import {DropdownContainer} from './DropdownContainer';
import {DropdownItem} from './DropdownItem';

export interface DropdownSubmenuProps extends TestIdProps {
  label: string | ReactElement<TextProps, typeof Text>;
  children: ReactNode;
  prefix?: ReactNode;
  suffix?: ReactElement;
  placement?: Placement;
  strategy?: 'fixed' | 'absolute';
  isLazy?: boolean;
  isDisabled?: boolean;
  lazyBehavior?: 'unmount' | 'keepMounted';
  boundary?: 'clippingParents' | 'scrollParent' | HTMLElement;
  isActive?: boolean;
  onClick?(): void;
}

export function DropdownSubmenu(props: DropdownSubmenuProps) {
  const dropdownControl = (
    <DropdownItem
      isDisabled={props.isDisabled}
      label={props.label}
      suffix={props.suffix}
      onClick={props.onClick}
      isActive={props.isActive}
    />
  );
  return (
    <FloatingTree>
      <DropdownContainer dropdownControl={dropdownControl} placement="right-end">
        {props.children}
      </DropdownContainer>
    </FloatingTree>
  );
}
