import {openDeleteDialog, showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {path} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {useDeleteDueDateSettingMutation} from '@dms/api/checkout';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {catchUnhandledDataGridActions, handleApiError} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function BusinessCaseDueDatesOverview() {
  const navigate = useNavigate();

  const [deleteBranchDueDateSettings] = useDeleteDueDateSettingMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData, refreshData}) => {
    const id = isArray(rowId) ? rowId[0] : rowId;
    const name = path(['branch', 'value', 'label'], rowData);

    match(actionKey)
      .with('EDIT', 'DETAIL', () => {
        navigate(composePath(settingsRoutes.businessCaseDueDatesDetail, {params: {id}}));
      })
      .with('DELETE', () => {
        openDeleteDialog({
          onConfirm: () =>
            deleteBranchDueDateSettings({id})
              .unwrap()
              .then(refreshData)
              .then(() =>
                showNotification.success(i18n.t('general.notifications.successfullyDeleted'))
              )
              .catch(handleApiError),
          text: i18n.t('entity.accounting.labels.deleteDefinitionDeleteText', {name}),
        });
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('page.settings.labels.dueDates'),
        breadcrumbs: [
          {
            label: i18n.t('page.businessCases.title'),
            href: settingsRoutes.businessCase,
          },
        ],
        actions: [
          {
            type: 'button',
            onClick: () => navigate(settingsRoutes.businessCaseDueDatesNew),
            title: i18n.t('page.accounting.labels.newDefinition'),
          },
        ],
      }}
      data-testid={testIds.settings.businessCaseDueDates('settingsTemplate')}
    >
      <DataGrid
        gridCode="checkout-due-date-settings"
        actionCallback={actionCallback}
        data-testid={testIds.settings.businessCaseDueDates('employee-profession')}
      />
    </SettingsTemplate>
  );
}
