/* eslint-disable eag/match-named-export */
import {ThemeColorPath} from 'platform/foundation';

export const IconColors: Record<string, ThemeColorPath> = {
  RED: 'palettes.red.20.100',
  RED_ICON: 'severity.danger',
  GREEN: 'palettes.green.20.100',
  GREEN_ICON: 'severity.success',
  BLUE: 'palettes.blue.20.100',
  BLUE_ICON: 'general.accent',
};
