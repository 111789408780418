import {metadaApi} from '@dms/api/core';
import {BaseVoidResponseSchema} from '@dms/api/shared';

import {
  PostMeasurementUnitLabourOperationApiResponse,
  PostMeasurementUnitLabourOperationApiArg,
  GetMeasurementUnitLabourOperationByIdApiResponse,
  GetMeasurementUnitLabourOperationByIdApiArg,
  DeleteMeasurementUnitLabourOperationApiResponse,
  DeleteMeasurementUnitLabourOperationApiArg,
  PatchMeasurementUnitLabourOperationApiResponse,
  PatchMeasurementUnitLabourOperationApiArg,
  PostMeasurementUnitLabourOperationApiArgSchema,
  MeasurementUnitLabourOperationApiResponseSchema,
  GetMeasurementUnitLabourOperationByIdApiArgSchema,
  DeleteMeasurementUnitLabourOperationApiArgSchema,
  PatchMeasurementUnitLabourOperationApiArgSchema,
  GetMeasurementUnitLabourOperationByIdApiResponseSchema,
} from './types';

export const metadaWorkshopMeasurementUnitLabourOperationApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postMeasurementUnitLabourOperation: build.mutation<
      PostMeasurementUnitLabourOperationApiResponse,
      PostMeasurementUnitLabourOperationApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/measurement-unit-labour-operation`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      extraOptions: {
        responseSchema: MeasurementUnitLabourOperationApiResponseSchema,
        requestSchema: PostMeasurementUnitLabourOperationApiArgSchema,
      },
    }),
    getMeasurementUnitLabourOperationById: build.query<
      GetMeasurementUnitLabourOperationByIdApiResponse,
      GetMeasurementUnitLabourOperationByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/measurement-unit-labour-operation/${queryArg.muloId}`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [{type: 'measurementUnit', id: queryArg.muloId}],
      extraOptions: {
        responseSchema: GetMeasurementUnitLabourOperationByIdApiResponseSchema,
        requestSchema: GetMeasurementUnitLabourOperationByIdApiArgSchema,
      },
    }),
    deleteMeasurementUnitLabourOperation: build.mutation<
      DeleteMeasurementUnitLabourOperationApiResponse,
      DeleteMeasurementUnitLabourOperationApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/measurement-unit-labour-operation/${queryArg.muloId}`,
        method: 'DELETE',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'measurementUnit', id: queryArg.muloId},
      ],
      extraOptions: {
        responseSchema: BaseVoidResponseSchema,
        requestSchema: DeleteMeasurementUnitLabourOperationApiArgSchema,
      },
    }),
    patchMeasurementUnitLabourOperation: build.mutation<
      PatchMeasurementUnitLabourOperationApiResponse,
      PatchMeasurementUnitLabourOperationApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/measurement-unit-labour-operation/${queryArg.muloId}`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'measurementUnit', id: queryArg.muloId},
      ],
      extraOptions: {
        responseSchema: BaseVoidResponseSchema,
        requestSchema: PatchMeasurementUnitLabourOperationApiArgSchema,
      },
    }),
  }),
});

export const {
  usePostMeasurementUnitLabourOperationMutation,
  useGetMeasurementUnitLabourOperationByIdQuery,
  useDeleteMeasurementUnitLabourOperationMutation,
  usePatchMeasurementUnitLabourOperationMutation,
} = metadaWorkshopMeasurementUnitLabourOperationApi;
