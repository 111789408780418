import {ForwardedRef, forwardRef, useCallback} from 'react';

import {DataGrid, DataGridRef, QueryFilterObject} from 'features/datagrid';

interface ProbabiltyDataGridProps {
  serviceCaseId: string;
  orderId: string;
}

export const ProfitabilityDataGrid = forwardRef(
  (props: ProbabiltyDataGridProps, ref: ForwardedRef<DataGridRef>) => {
    const queryModifier = useCallback(
      (filter: QueryFilterObject) => ({
        ...filter,
        orderId: props.orderId,
      }),
      [props.orderId]
    );

    return (
      <DataGrid
        ref={ref}
        gridCode="service-order-profitability"
        autoHeight
        queryModifier={queryModifier}
      />
    );
  }
);
