import i18n from 'i18next';
import {Button, Dropdown, DropdownDivider, DropdownGroup, DropdownItem} from 'platform/components';

import {useState} from 'react';
import {useSelector} from 'react-redux';

import {useGetAutoFillTemplatesQuery, usePrefetch} from '@dms/api/carAudit';
import {testIds} from '@dms/routes';
import {usePermissions} from '@dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {areAuditFieldsEmpty} from '../../../store/carAudit/reducerUtils';
import {selectAudit} from '../../../store/carAudit/selectors';
import {InspectionIdForAuditId} from '../../../types/InspectionIdForAuditId';
import {useApplyAutoFillTemplate} from '../hooks/useApplyAutoFillTemplate';
import {AutoFillTemplateApplyDialog} from './AutoFillTemplateApplyDialog';
import {AutoFillTemplatesActions} from './AutoFillTemplatesActions';
import {ScrollAreaWrapper} from './NewInspectionButton';

interface AutoFillTemplatesProps extends TestIdProps, Omit<InspectionIdForAuditId, 'auditId'> {
  inspectionId?: string | null;
  vehicleId?: string | null;
}

export function AutoFillTemplates(props: AutoFillTemplatesProps) {
  const audit = useSelector(selectAudit);

  const {data: autoFillTemplates} = useGetAutoFillTemplatesQuery();
  const prefetchAutoFillTemplate = usePrefetch('getAutoFillTemplate');
  const {applyAutoFillTemplate, isLoading} = useApplyAutoFillTemplate();

  const [selectedTemplate, setSelectedTemplate] = useState<{name: string; id: string} | null>(null);

  const [hasUpdateInspectionPermission] = usePermissions({
    permissionKeys: ['updateInspection'],
    scopes: {
      updateInspection: {inspectionType: audit?.inspectionType},
    },
  });

  const areFieldsEmpty = areAuditFieldsEmpty(audit?.fields ?? {});

  const handleChooseAutoFillTemplate = (templateId: string, templateName: string) => {
    if (!audit) {
      return;
    }

    if (areFieldsEmpty) {
      return applyAutoFillTemplate(templateId, audit);
    }

    setSelectedTemplate({id: templateId, name: templateName});
  };

  const handleCloseDialog = () => {
    setSelectedTemplate(null);
  };

  if (!hasUpdateInspectionPermission) {
    return null;
  }

  return (
    <>
      <Dropdown
        data-testid={testIds.vehicles.inspectionDetail('newAutoFillTemplate')}
        placement="bottom-end"
        dropdownControl={
          <Button
            isLoading={isLoading}
            variant="outlined"
            rightIcon="hardware/keyboard_arrow_down"
            data-testid={suffixTestId('templates', props)}
            title={i18n.t('general.actions.autofillTemplates.dropDown.label')}
          />
        }
      >
        <ScrollAreaWrapper data-testid={testIds.vehicles.inspectionDetail('scrollAreaWrapper')}>
          {autoFillTemplates && autoFillTemplates.length > 0 && (
            <>
              <DropdownGroup label={i18n.t('entity.vehicle.labels.fillFromTemplate')}>
                {autoFillTemplates?.map((template, index) => (
                  <DropdownItem
                    key={`$template-${template.id}`}
                    data-testid={`autoFillTemplate-dropdownItem-[${index}]`}
                    label={template.inspectionName || ''}
                    onMouseEnter={() => {
                      if (audit?.id) {
                        prefetchAutoFillTemplate({
                          autoFillTemplateId: template.id,
                          inspectionId: audit.id,
                        });
                      }
                    }}
                    onClick={() => {
                      handleChooseAutoFillTemplate(template.id, template.inspectionName ?? '');
                    }}
                  />
                ))}
              </DropdownGroup>
              <DropdownDivider />
            </>
          )}
          <AutoFillTemplatesActions
            shouldUseAuditIdForUpdateTemplate={props.shouldUseAuditIdForUpdateTemplate ?? false}
            inspectionId={props.inspectionId}
            vehicleId={props.vehicleId}
            auditId={audit?.id}
          />
        </ScrollAreaWrapper>
      </Dropdown>
      {selectedTemplate && audit && (
        <AutoFillTemplateApplyDialog
          audit={audit}
          template={selectedTemplate}
          onClose={handleCloseDialog}
        />
      )}
    </>
  );
}
