import {Card, DataStatus} from 'platform/components';
import {VStack} from 'platform/foundation';

import {useGetCustomerV2Query} from '@dms/api/customer';
import i18n from '@dms/i18n';

import {Nullish, RequiredTestIdProps, suffixTestId, useToggle} from 'shared';

import {ContractInformationForm} from '../../ContactInformationForm/ContractInformationForm';
import {AdditionalCustomerTypeProps} from '../types/AdditionalCustomerTypeProps';
import {CustomerContractCard} from './CustomerContractCard';

interface CustomerCardProps extends RequiredTestIdProps {
  customerId: string;
  checkoutId: string;
  recordId?: string;
  resourceId?: string;
  selectedContractId: string | Nullish;
  additionalCustomerType?: AdditionalCustomerTypeProps;
  onAddContract: (contractId: string) => void;
}

export function BillingInformationList(props: CustomerCardProps) {
  const [isAddOpen, toggleAddOpen] = useToggle();

  const {
    data: customerData,
    isLoading,
    isError,
  } = useGetCustomerV2Query({customerId: props.customerId});

  const handleAddContract = (contractId: string) => {
    toggleAddOpen();
    props.onAddContract(contractId);
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <VStack spacing={4}>
        {customerData?.contractInformation.map((customerContract, index) => (
          <CustomerContractCard
            key={customerContract.id}
            customerContract={customerContract}
            customerId={props.customerId}
            recordId={props.recordId}
            resourceId={props.resourceId}
            isSelected={props.selectedContractId === customerContract.id}
            onSelect={() => props.onAddContract(customerContract.id)}
            additionalCustomerType={props.additionalCustomerType}
            data-testid={suffixTestId(String(index), props)}
          />
        ))}
        <Card
          title={i18n.t('entity.customer.actions.newContractInformation')}
          isExpandable
          hasSeparator={false}
          variant="inlineWhite"
          isClosedByDefault
          isExpanded={isAddOpen}
          onExpandButtonClick={toggleAddOpen}
          control={{type: 'radio', value: isAddOpen, onChange: toggleAddOpen}}
          data-testid={suffixTestId('newContractInformation', props)}
        >
          <ContractInformationForm
            isOutsideDialog
            customerId={props.customerId}
            onClose={toggleAddOpen}
            onSave={handleAddContract}
            data-testid={suffixTestId('createContract', props)}
          />
        </Card>
      </VStack>
    </DataStatus>
  );
}
