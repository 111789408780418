import {BuyingFormType} from '../types/BuyingFormType';

export type CalculateUpdate = {
  form: BuyingFormType;
  vatType: ('S' | 'R' | 'MR' | 'E' | 'Z' | 'SR' | 'P') | null;
  withVat: string | null;
  withoutVat: string | null;
  defaultCurrency: string | null;
};

export const handleCalculateUpdate = (params: CalculateUpdate): BuyingFormType => ({
  ...params.form,
  vatType: params.vatType,
  priceOfferedBase:
    params.withoutVat && (params.form.priceOfferedWithoutVat?.currency || params.defaultCurrency)
      ? {
          currency: (params.form.priceOfferedWithoutVat?.currency ??
            params.defaultCurrency) as string,
          amount: params.withoutVat,
        }
      : null,
  priceOfferedWithoutVat:
    params.withoutVat && (params.form.priceOfferedWithoutVat?.currency || params.defaultCurrency)
      ? {
          currency: (params.form.priceOfferedWithoutVat?.currency ??
            params.defaultCurrency) as string,
          amount: params.withoutVat,
        }
      : null,
  priceOfferedWithVat:
    params.withVat && (params.form.priceOfferedWithVat?.currency || params.defaultCurrency)
      ? {
          currency: (params.form.priceOfferedWithVat?.currency ?? params.defaultCurrency) as string,
          amount: params.withVat,
        }
      : null,
});
