import {isNotNil} from 'ramda-adjunct';

import {
  GetDocumentSeriesDefinitionListApiResponse,
  GetInvoiceDraftApiResponse,
} from '@dms/api/metadaErrorLogs';
import {VatTypeEnum} from '@dms/api/shared';
import i18n from '@dms/i18n';
import {getDocumentSystemSeriesId} from '@dms/shared';

import {PERCENTAGE_SIGN} from '../../constants';
import {AccountingDocumentFormValues} from '../../types';

export const getInitialValuesFromDraft = (
  draft: GetInvoiceDraftApiResponse | undefined,
  series: GetDocumentSeriesDefinitionListApiResponse = []
): Partial<AccountingDocumentFormValues> | null => {
  if (!draft) {
    return null;
  }

  const draftInvoiceDefaultValues: Partial<AccountingDocumentFormValues> = {
    issueDate: draft.issueDate ?? undefined,
    dueDate: draft.dueDate ?? undefined,
    id: draft.id,
    dueSinceIssueDateInDays: draft.dueSinceIssueDateInDays ?? undefined,
    customer: draft.customer?.id ? draft.customer : undefined,
    dateOfTaxableSupply: draft.dateOfTaxableSupply ?? undefined,
    paymentInfo: {
      ...draft.paymentInfo,
      paymentMethod: draft.paymentInfo?.paymentMethod ?? 'BANK_TRANSFER',
      bankAccount: draft.paymentInfo?.bankAccount ?? undefined,
      iban: draft.paymentInfo?.iban ?? undefined,
      swift: draft.paymentInfo?.swift ?? undefined,
      variableSymbol: draft.paymentInfo?.variableSymbol ?? undefined,
      constantSymbol: draft.paymentInfo?.constantSymbol ?? undefined,
      specificSymbol: draft.paymentInfo?.specificSymbol ?? undefined,
    },
    invoiceItems: draft.items?.map((item) => ({
      description: item.description ?? '',
      discount: item.discount
        ? {
            pricePerUnit: null,
            priceWithoutVat: null,
            priceWithVat: null,
            quantity: item.discount.discountValue,
            relatedItemId: item.discount.relatedItemId,
            unit: item.discount.percent,
          }
        : null,
      generatedItemType: 'standard',
      isUnitPriceWithVat: item.isUnitPriceWithVat ?? false,
      itemId: item.itemId,
      pricePerUnit: item.pricePerUnit?.amount ?? null,
      priceWithoutVat: null,
      priceWithVat: null,
      quantity:
        (item.type === 'discount' && item.unit === PERCENTAGE_SIGN
          ? item.discount?.percent
          : item.quantity) ?? '0',
      relatedItemId: item.relatedItemId,
      type: item.type,
      unit: item.unit ?? 'pcs',
      vatType: item.vatType ?? VatTypeEnum.S,
    })),
    documentSeriesId: getDocumentSystemSeriesId(series, 'invoice'),
    footerNote: draft.footerNote ?? undefined,
    internalNote: draft.internalNote,
    isExchangeRateRatioEnabled: isNotNil(draft.exchangeRateRatio),
    exchangeRateRatio: {
      amount: draft.exchangeRateRatio?.amount.toString() ?? '1',
      currency: draft.exchangeRateRatio?.currency ?? 'EUR',
      ratio: draft.exchangeRateRatio?.ratio ?? null,
      type: i18n.t('general.actions.recalculate'),
    },
    additionalCustomer:
      isNotNil(draft) && isNotNil(draft.additionalCustomer)
        ? {
            customer: {
              ...draft.additionalCustomer,
              id: draft.additionalCustomer?.customerId ?? '',
              tradeName: draft.additionalCustomer?.name ?? '',
            },
            type: draft.additionalCustomer?.type,
            contractInformationId: draft.additionalCustomer?.contractInformationId ?? '',
          }
        : null,
  };

  return draftInvoiceDefaultValues;
};
