import {Button, ButtonGroup, closeCurrentDialog, IconButton} from 'platform/components';
import {Text, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import {useGetDigitalCertificateThemesQuery, useGetSalePresetSettingsV2Query} from '@dms/api/sales';
import {useGetWatermarkListQuery} from '@dms/api/vehicle';
import {i18n} from '@dms/i18n';

export interface ShowSalePresetSuccess {
  salePresetId: string;
}

export function SalePresetUpdateInfoDialog(props: ShowSalePresetSuccess) {
  const {data: salePreset} = useGetSalePresetSettingsV2Query({salePresetId: props.salePresetId});
  const {data: watermarkList} = useGetWatermarkListQuery();
  const {data: digitalCertificateList} = useGetDigitalCertificateThemesQuery();

  const selectedDigitalCertificate = digitalCertificateList?.find(
    (digitalCertificate) => digitalCertificate.id === salePreset?.digitalCertificateThemeId
  );
  const selectedWatermark = watermarkList?.find(
    (watermark) => watermark.id === salePreset?.watermarkId
  );
  const translationString = match<[boolean, boolean], string>([
    Boolean(selectedDigitalCertificate),
    Boolean(selectedWatermark),
  ])
    .with([true, true], always('entity.vehicle.notifications.salePreset.successFull'))
    .with([true, false], always('entity.vehicle.notifications.salePreset.successOnlyCert'))
    .with([false, true], always('entity.vehicle.notifications.salePreset.successOnlyBackground'))
    .otherwise(always('entity.vehicle.notifications.salePreset.success'));

  return (
    <>
      <VStack align="flex-end">
        <IconButton
          data-testid="salePresetUpdateInfoDialog"
          icon="navigation/close"
          onClick={closeCurrentDialog}
        />
      </VStack>
      <VStack spacing={1}>
        <Text>
          {i18n.t(translationString, {
            saleProgramName: salePreset?.name ?? '',
            watermarkName: selectedWatermark?.name,
            digitalCertificateName: selectedDigitalCertificate?.name,
          })}
        </Text>
      </VStack>
      <ButtonGroup align="right">
        <Button
          data-testid="republishAds-dialog-confirm"
          variant="primary"
          onClick={closeCurrentDialog}
          title={i18n.t('general.actions.confirm')}
        />
      </ButtonGroup>
    </>
  );
}
