import {
  Card,
  Choice,
  FormControl,
  FormField,
  Label,
  NumberInput,
  Separator,
  TextInput,
} from 'platform/components';
import {Grid, GridItem, Show} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {UseFormReturn, useWatch} from 'react-hook-form';

import {isNil, isNotNil} from 'ramda';
import {toNumber} from 'ramda-adjunct';

import {
  useLazyCalculateFromWithoutVatQuery,
  useLazyCalculateFromWithVatQuery,
} from '@dms/api/common';
import {
  VatRateType,
  CalculateFromWithoutVatApiArg,
  CalculateFromWithVatApiArg,
} from '@dms/api/shared';
import {useGetUserQuery} from '@dms/api/user';
import i18n from '@dms/i18n';
import {
  getNaturalPersonFullName,
  handleApiError,
  useCurrencies,
  useTenant,
  useVatRatesOptions,
} from '@dms/shared';

import {parseDate, RequiredTestIdProps, suffixTestId, useDebouncedCallback} from 'shared';

import {DEFAULT_DEBOUNCED_WAIT, ONE_MINUTE_WAIT} from '../../../../../constants/debounceWait';
import {useMinisaleUpdate} from '../hooks/useMinisaleUpdate';
import {BuyingFormType} from '../types/BuyingFormType';
import {CalculateUpdate, handleCalculateUpdate} from '../utils/handleCalculateUpdate';

interface OfferProps extends RequiredTestIdProps {
  interestId: string;
  control: FormControl<BuyingFormType>;
  formApi: UseFormReturn<BuyingFormType>;
}

export function Offer(props: OfferProps) {
  const {tenantCurrency, tenantCountry} = useTenant();

  const {onBuyingUpdate} = useMinisaleUpdate(props.interestId);
  const [vatRatesOptions] = useVatRatesOptions();

  const {getCurrencySymbol} = useCurrencies();
  const currencySymbol = getCurrencySymbol(tenantCurrency);
  const formatDate = useDateTimeFormatter();

  const [calculateFromWithVat, {isLoading: isCalculateFromWithVatLoading}] =
    useLazyCalculateFromWithVatQuery();
  const [calculateFromWithoutVat, {isLoading: isCalculateFromWithoutVatLoading}] =
    useLazyCalculateFromWithoutVatQuery();

  const [
    isVatDeductible,
    vatType,
    pricedBy,
    pricedAt,
    priceOfferedWithoutVat,
    priceOfferedWithVat,
  ] = useWatch({
    control: props.control,
    name: [
      'isVatDeductible',
      'vatType',
      'pricedBy',
      'pricedAt',
      'priceOfferedWithoutVat.amount',
      'priceOfferedWithVat.amount',
    ],
  });

  const {pricer} = useGetUserQuery(
    {id: pricedBy!},
    {
      skip: isNil(pricedBy),
      selectFromResult: ({data}) => ({
        pricer: getNaturalPersonFullName(data),
      }),
    }
  );

  const handleCalculationChange = useDebouncedCallback(
    (params: {vatType: VatRateType; withVat?: number | null; withoutVat?: number | null}) => {
      if (isNotNil(params.withoutVat)) {
        const arg: CalculateFromWithoutVatApiArg = {
          amount: params.withoutVat.toString(),
          vatRateType: params.vatType,
          countryCode: tenantCountry,
        };
        calculateFromWithoutVat(arg)
          .unwrap()
          .then((result) => {
            const calculateUpdate: CalculateUpdate = {
              form: props.formApi.getValues(),
              vatType: params.vatType,
              withVat: result.withVat,
              withoutVat: result.withoutVat,
              defaultCurrency: tenantCurrency!,
            };
            const form = handleCalculateUpdate(calculateUpdate);
            props.formApi.reset(form);
            onBuyingUpdate(form);
          })
          .catch(handleApiError);
      } else if (isNotNil(params.withVat)) {
        const arg: CalculateFromWithVatApiArg = {
          amount: params.withVat.toString(),
          vatRateType: params.vatType,
          countryCode: tenantCountry,
        };
        calculateFromWithVat(arg)
          .unwrap()
          .then((result) => {
            const calculateUpdate: CalculateUpdate = {
              form: props.formApi.getValues(),
              vatType: params.vatType,
              withVat: result.withVat,
              withoutVat: result.withoutVat,
              defaultCurrency: tenantCurrency!,
            };
            const form = handleCalculateUpdate(calculateUpdate);
            props.formApi.reset(form);
            onBuyingUpdate(form);
          })
          .catch(handleApiError);
      } else if (params.withVat === null || params.withoutVat === null) {
        const form = handleCalculateUpdate({
          form: props.formApi.getValues(),
          vatType: params.vatType,
          withVat: null,
          withoutVat: null,
          defaultCurrency: tenantCurrency!,
        });
        props.formApi.reset(form);
        onBuyingUpdate(form);
      }
    },
    DEFAULT_DEBOUNCED_WAIT,
    ONE_MINUTE_WAIT
  );

  const isDisabled = isCalculateFromWithoutVatLoading || isCalculateFromWithVatLoading;

  return (
    <Card variant="inlineWhite" title={i18n.t('entity.interest.labels.offer')}>
      <Grid columns={2} verticalSpacing={4}>
        <GridItem span={2}>
          <FormField
            data-testid={suffixTestId('offer-isVatDeductible', props)}
            control={props.control}
            type="chips"
            name="isVatDeductible"
            label={i18n.t('general.labels.vatDeductible')}
            options={[
              {label: i18n.t('general.labels.yes'), value: 'true'},
              {label: i18n.t('general.labels.no'), value: 'false'},
            ]}
            isDisabled={isDisabled}
          />
        </GridItem>
        <Show when={isVatDeductible?.[0] === 'true'}>
          <GridItem span={1}>
            <Choice
              label={i18n.t('general.labels.vatRate')}
              data-testid={suffixTestId('offer-vatType', props)}
              value={vatType ?? null}
              options={vatRatesOptions}
              isDisabled={isDisabled}
              onChange={(value) =>
                handleCalculationChange({
                  withoutVat: toNumber(priceOfferedWithoutVat ?? null),
                  withVat: toNumber(priceOfferedWithVat ?? null),
                  vatType: value as VatRateType,
                })
              }
              isNotClearable
            />
          </GridItem>
        </Show>
        <GridItem span={1}>
          <FormField
            data-testid={suffixTestId('offer-priceRequested', props)}
            control={props.control}
            type="number"
            name="priceRequested.amount"
            label={i18n.t('general.labels.requestedPrice')}
            decimalPlaces={0}
            suffix={currencySymbol}
            isDisabled={isDisabled}
          />
        </GridItem>
        <Show when={isVatDeductible?.[0] === 'true'}>
          <GridItem span={1}>
            <NumberInput
              data-testid={suffixTestId('offer-priceOfferedWithoutVat', props)}
              label={i18n.t('general.labels.offeredPriceWithoutVat')}
              suffix={currencySymbol}
              decimalPlaces={0}
              isDisabled={isDisabled || isNil(vatType)}
              value={priceOfferedWithoutVat ? toNumber(priceOfferedWithoutVat) : null}
              onChange={(value) => handleCalculationChange({withoutVat: value, vatType: vatType!})}
            />
          </GridItem>
        </Show>
        <GridItem span={1}>
          <NumberInput
            data-testid={suffixTestId('offer-offeredPrice', props)}
            label={i18n.t('general.labels.offeredPrice')}
            suffix={currencySymbol}
            decimalPlaces={0}
            isDisabled={isDisabled}
            value={priceOfferedWithoutVat ? toNumber(priceOfferedWithVat || isNil(vatType)) : null}
            onChange={(value) => handleCalculationChange({withVat: value, vatType: vatType!})}
          />
        </GridItem>
        <GridItem span={2}>
          <FormField
            data-testid={suffixTestId('offer-reason', props)}
            control={props.control}
            type="textarea"
            name="reason"
            label={i18n.t('general.labels.reason')}
            minRows={3}
            isDisabled={isDisabled}
          />
        </GridItem>
        <GridItem span={2}>
          <Separator spacing={0} />
        </GridItem>
        <GridItem span={1}>
          <FormField
            data-testid={suffixTestId('offer-expectedCost', props)}
            control={props.control}
            type="number"
            name="expectedCosts.amount"
            label={i18n.t('entity.vehicle.labels.expectedCost')}
            suffix={currencySymbol}
            decimalPlaces={0}
            isDisabled={isDisabled}
          />
        </GridItem>
        <GridItem span={2}>
          <FormField
            data-testid={suffixTestId('offer-costReason', props)}
            control={props.control}
            type="textarea"
            name="costReason"
            label={i18n.t('entity.vehicle.labels.costReason')}
            minRows={3}
            isDisabled={isDisabled}
          />
        </GridItem>
        <GridItem span={2}>
          <Separator spacing={0} />
        </GridItem>
        <GridItem span={1}>
          <Label>{i18n.t('entity.interest.labels.pricer')}</Label>
          <TextInput isDisabled value={pricer} data-testid="interest-labels-pricer" />
        </GridItem>
        <GridItem span={1}>
          <Label>{i18n.t('entity.interest.labels.pricedOn')}</Label>
          <TextInput
            data-testid="interest-labels-pricedAt"
            isDisabled
            value={pricedAt ? formatDate('dateTimeShort', parseDate(pricedAt)) : null}
          />
        </GridItem>
      </Grid>
    </Card>
  );
}
