import {Button, DataStatus, openDialog} from 'platform/components';
import {VStack, HStack, Show} from 'platform/foundation';

import {isFalse, isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {useGetInterestUpcomingTasksQuery} from '@dms/api/businessCase';
import {useGetInterestQuery} from '@dms/api/interest';
import {useGetInterestActivityListQuery} from '@dms/api/sales';
import {EntityResourceIds} from '@dms/api/shared';
import {
  ActivitySidebarSendMessage,
  LogContactDialog,
  transformTaskToActivity,
} from '@dms/features/activity-panel';
import {openCreateTaskFormDialog} from '@dms/features/tasks';
import i18n from '@dms/i18n';
import {usePermissions} from '@dms/shared';

import {parseDate} from 'shared';

import {ActivityList} from './ActivityList';

interface ActivityTabProps {
  interestId: string;
}

export function ActivityTab(props: ActivityTabProps) {
  const [canReadTasks, canCreateTasks] = usePermissions({
    permissionKeys: ['readTask', 'createTask'],
  });

  const {
    data: interestData,
    isLoading: isInterestLoading,
    isError: isInterestErrored,
  } = useGetInterestQuery({id: props.interestId});

  const {
    data,
    isLoading: isInterestActivityLoading,
    isError: isInterestActivityErrored,
  } = useGetInterestActivityListQuery(
    {interestId: props.interestId},
    {skip: isNilOrEmpty(props.interestId)}
  );

  const handleNewTaskClick = () => {
    openCreateTaskFormDialog({
      'data-testid': 'interest-case-activity-tab-create-task',
      relatedRecordId: props.interestId,
      resourceId: EntityResourceIds.interest,
    });
  };

  const {
    data: upcomingTasks,
    isLoading: isUpcomingTasksLoading,
    isError: isUpcomingTasksErrored,
  } = useGetInterestUpcomingTasksQuery(
    {interestId: props.interestId},
    {skip: isNilOrEmpty(props.interestId)}
  );

  const pastActivities =
    data?.filter((activity) => parseDate(activity.timestamp) < new Date()) ?? [];

  const isAddNewTaskEnabled = canReadTasks && canCreateTasks;
  const isLoading = isInterestLoading || isInterestActivityLoading || isUpcomingTasksLoading;
  const isError = isInterestErrored || isInterestActivityErrored || isUpcomingTasksErrored;

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <VStack spacing={4} height="100%">
        <HStack spacing={2}>
          <ActivitySidebarSendMessage
            resourceId={EntityResourceIds.interest}
            recordId={props.interestId}
            customerId={interestData?.customerId ?? ''}
          />

          <Button
            leftIcon="content/add_circle"
            title={i18n.t('entity.task.actions.createTask')}
            variant="link"
            size="small"
            isDisabled={isFalse(isAddNewTaskEnabled)}
            onClick={handleNewTaskClick}
          />
          <Button
            leftIcon="communication/phone"
            title={i18n.t('entity.activityPanel.activity.logCall')}
            variant="link"
            size="small"
            onClick={() =>
              openDialog(<LogContactDialog interestId={props.interestId} />, {
                id: 'LOG_CONTACT_DIALOG',
                title: i18n.t('entity.activityPanel.logContact.createLog'),
                scrollBehavior: 'outside',
              })
            }
          />
        </HStack>

        <VStack spacing={4} height="92%">
          <ActivityList
            showEmpty
            label={i18n.t('entity.activityPanel.activity.nextSteps')}
            items={(upcomingTasks ?? []).map(transformTaskToActivity)}
            interestId={props.interestId}
          />

          <Show when={isNotNilOrEmpty(pastActivities)}>
            <ActivityList
              label={i18n.t('entity.activityPanel.activity.pastActivities')}
              items={pastActivities}
              interestId={props.interestId}
              isFullHeight={isNilOrEmpty(upcomingTasks)}
            />
          </Show>
        </VStack>
      </VStack>
    </DataStatus>
  );
}
