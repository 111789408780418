import {Form, FormButton, FormField, FormSubmitHandler, OptionType} from 'platform/components';
import {Box, HStack, Show} from 'platform/foundation';
import {z} from 'zod';

import {isNilOrEmpty} from 'ramda-adjunct';

import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';

export type CommentCardForm = {
  comment: string;
};

export interface CommentsTabInputProps {
  isSendCommentLoading?: boolean;
  onSubmit: FormSubmitHandler<CommentCardForm>;
  userOptions?: OptionType[];
  isAddDisabled?: boolean;
}

export function CommentsTabInput(props: CommentsTabInputProps) {
  const defaultValues = {comment: ''};

  return (
    <Form experimentalZodSchema={schema} onSubmit={props.onSubmit} defaultValues={defaultValues}>
      {(control, {watch}) => (
        <HStack spacing={4} align="flex-end">
          <Box flex={1}>
            <Show whenFeatureEnabled={featureFlags.NOTIFICATIONS_COMMENT_MENTION}>
              <FormField
                label={i18n.t('page.comments.actions.writeAComment')}
                control={control}
                name="comment"
                type="mentionsInput"
                options={props.userOptions}
              />
            </Show>
            <Show whenFeatureDisabled={featureFlags.NOTIFICATIONS_COMMENT_MENTION}>
              <FormField
                label={i18n.t('page.comments.actions.writeAComment')}
                control={control}
                name="comment"
                type="textarea"
              />
            </Show>
          </Box>

          <FormButton
            control={control}
            type="submit"
            variant="secondary"
            size="default"
            isLoading={props.isSendCommentLoading}
            isDisabled={isNilOrEmpty(watch('comment'))}
            title={i18n.t('general.actions.send')}
          />
        </HStack>
      )}
    </Form>
  );
}

const schema = z.object({
  comment: z.string(),
});
