import {Avatar, Button, Choice, Separator, showNotification} from 'platform/components';
import {Box, HStack, Heading, Show, Space, Text, VStack} from 'platform/foundation';

import {useMemo, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {isNil} from 'ramda';

import {getWorkspaceFromUri, buildUrl} from '@dms/api/core';
import {selectActiveBranchId, selectUserBranchOptions, setActiveBranchId} from '@dms/api/features';
import {useLogoutMutation} from '@dms/api/public';
import {SetUserSettingsItemApiArg} from '@dms/api/shared';
import {
  selectCurrentUserInfo,
  useGetUserAvatarUrlQuery,
  useSetUserSettingsItemMutation,
} from '@dms/api/user';
import {browserStorageKey} from '@dms/config';
import i18n from '@dms/i18n';
import {loginRoutes, myProfileRoutes, settingsRoutes} from '@dms/routes';
import {EMPTY_PLACEHOLDER, handleApiError, usePermissions, useUserFullName} from '@dms/shared';

import {suffixTestId, RequiredTestIdProps, GtmEvent, pushGtmEvent} from 'shared';

import {useIsMobileNavigation} from '../hooks/useIsMobileNavigation';
import {LatestReleaseLink} from './LatestReleaseLink';
import {ShowOnMobileNavigation} from './ShowOnMobileNavigation';
import {TopBarItem, TopBarItemRef} from './TopBarItem';
import {UserAvatarButton} from './UserAvatarButton';

export function UserAvatar(props: RequiredTestIdProps) {
  const dispatch = useDispatch();
  const topBarItemRef = useRef<TopBarItemRef>(null);
  const isMobileNavigation = useIsMobileNavigation();

  const [hasReadSettingsPermission] = usePermissions({permissionKeys: ['readSettings']});
  const [logoutUser] = useLogoutMutation();
  const {data: userInfo} = useSelector(selectCurrentUserInfo);
  const {data: userAvatarUrl} = useGetUserAvatarUrlQuery(
    {userId: userInfo?.id ?? ''},
    {skip: isNil(userInfo)}
  );

  const userFullName = useUserFullName();
  const activeBranchId = useSelector(selectActiveBranchId);
  const branchOptions = useSelector(selectUserBranchOptions);
  const [updateUserSettings] = useSetUserSettingsItemMutation();

  const handleClosePopup = () => {
    if (isMobileNavigation) {
      topBarItemRef.current?.close();
    }
  };

  const handleBranchChange = (value: string | number | string[] | null) => {
    if (typeof value !== 'string') {
      return;
    }

    const arg: SetUserSettingsItemApiArg = {
      settingKey: 'active_branch',
      userSettingRequestBody: {value},
    };

    const gtmEvent: GtmEvent = {
      event: 'button_click',
      event_id: 'top_bar_branch_select',
      previous_value: {
        branchId: activeBranchId,
      },
      new_value: {
        branchId: value,
      },
    };

    updateUserSettings(arg)
      .unwrap()
      .then(() => {
        handleClosePopup();
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
      })
      .then(() => {
        dispatch(setActiveBranchId(value));
        pushGtmEvent(gtmEvent);
      })
      .catch(handleApiError);
  };

  const handleLogout = () => {
    const accessToken = sessionStorage.getItem(browserStorageKey.ACCESS_TOKEN);
    const {workspace} = getWorkspaceFromUri();

    logoutUser({accessToken, workspace})
      .unwrap()
      .then(() => {
        pushGtmEvent({
          event: 'button_click',
          event_id: 'user_logout',
        });
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem(browserStorageKey.LAST_KNOWN_LANGUAGE, i18n.language);
        window.location.href = buildUrl(loginRoutes.login);
      })
      .catch(handleApiError);
  };

  const getBranchByActiveId = useMemo(
    () => branchOptions?.find((b) => b.value === activeBranchId) || null,
    [branchOptions, activeBranchId]
  );

  if (!userInfo) {
    return null;
  }

  return (
    <TopBarItem
      gtmEventId="top_bar_user_avatar_open"
      ref={topBarItemRef}
      isFullScreen={isMobileNavigation}
      trigger={(isOpen) => <UserAvatarButton isOpen={isOpen} userAvatarUrl={userAvatarUrl} />}
    >
      <Box backgroundColor="general.white" padding={2}>
        <Box width={['auto', 'auto', 75]}>
          <HStack align="center">
            <Avatar
              data-testid={suffixTestId('myProfileAndSettings', props)}
              name={userFullName}
              size="default"
              imageUrl={userAvatarUrl}
              variant="circle"
            />
            <Space horizontal={4} />
            <VStack width="100%">
              <Heading
                alternative
                headingLevel={2}
                size={4}
                data-testid={suffixTestId('myProfileAndSettings-name', props)}
              >
                {userFullName ?? EMPTY_PLACEHOLDER}
              </Heading>
              <Text
                size="small"
                color="secondary"
                overflowWrap="anywhere"
                data-testid={suffixTestId('myProfileAndSettings-email', props)}
              >
                {userInfo.email}
              </Text>
            </VStack>
          </HStack>

          <ShowOnMobileNavigation>
            <Space vertical={4} />
            {branchOptions && branchOptions.length > 1 ? (
              <Choice
                label={i18n.t('entity.branch.labels.selectedBranch')}
                data-testid={suffixTestId('myProfileAndSettings-activeBranch', props)}
                name="activeBranch"
                value={activeBranchId || null}
                options={branchOptions}
                onChange={handleBranchChange}
                closeMenuOnScroll={false}
                isNotClearable
                maxMenuHeight={33}
              />
            ) : (
              <Heading
                data-testid={suffixTestId('myProfileAndSettings-activeBranch', props)}
                headingLevel={2}
                size={4}
              >
                {getBranchByActiveId?.label ?? EMPTY_PLACEHOLDER}
              </Heading>
            )}
          </ShowOnMobileNavigation>
        </Box>

        <Space vertical={4} />
        <Button
          to={myProfileRoutes.personalInformation}
          onClick={handleClosePopup}
          textAlign="left"
          title={i18n.t('entity.user.labels.myProfile')}
          variant="ghost"
          isFullWidth
          leftIcon="social/person"
          data-testid={suffixTestId('myProfileAndSettings-personalInformation', props)}
        />
        <ShowOnMobileNavigation when={hasReadSettingsPermission}>
          <Button
            to={settingsRoutes.userManagement}
            onClick={handleClosePopup}
            textAlign="left"
            title={i18n.t('page.settings.labels.title')}
            variant="ghost"
            isFullWidth
            data-testid={suffixTestId('myProfileAndSettings-userManagement', props)}
            leftIcon="action/settings"
          />
        </ShowOnMobileNavigation>
        <Show onMobile>
          <LatestReleaseLink />
        </Show>
        <Separator spacing={2} />
        <Button
          onClick={handleLogout}
          textAlign="left"
          title={i18n.t('general.actions.logout')}
          variant="ghost"
          isFullWidth
          data-testid={suffixTestId('myProfileAndSettings-logout', props)}
          leftIcon="action/exit_to_app"
        />
      </Box>
    </TopBarItem>
  );
}
