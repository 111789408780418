import {z} from 'zod';

import {BaseVoidRequest, BaseVoidResponse} from '@dms/api/shared';

const OptionSchema = z.object({
  label: z.string(),
  value: z.string(),
});

export type GetCodeListRequest = BaseVoidRequest;

export const GetCodeListResponseSchema = z.array(
  z.object({
    id: z.string(),
    category: z.string(),
    code: z.string(),
    name: z.string(),
    priority: z.number().optional(),
    isDefault: z.boolean(),
    isActive: z.boolean(),
    dictionary: z.string().optional(),
  })
);
export type GetCodeListResponse = z.infer<typeof GetCodeListResponseSchema>;

export const GetCodeListOptionsRequestSchema = z.object({
  category: z.enum([
    'WRH-SALES-PRICE-CALCULATION',
    'WRH-BASE-PRICE-SOURCE',
    'WRH-SUPPLIER-ORDER-TYPE',
    'WRH-ARTICLE-DISPENSING-UNIT',
  ]),
});
export type GetCodeListOptionsRequest = z.infer<typeof GetCodeListOptionsRequestSchema>;

export const GetCodeListOptionsResponseSchema = z.array(OptionSchema);
export type GetCodeListOptionsResponse = z.infer<typeof GetCodeListOptionsResponseSchema>;

export const GetStaticValueListRequestSchema = z.object({
  listCode: z.enum(['measurementUnits']),
});
export type GetStaticValueListRequest = z.infer<typeof GetStaticValueListRequestSchema>;

export type GetStaticValueListResponse = BaseVoidResponse;
