import {object, SchemaOf, string, mixed} from 'yup';

import {BigDecimal, Money} from '@dms/api/shared';
import i18n from '@dms/i18n';

export type AmountLimitType = {
  amount?: number | string;
  errorMessage?: string;
};

export type MinMaxAmountLimitType = {
  min?: AmountLimitType;
  max?: AmountLimitType;
};

export const $MoneyAmount = (limit?: MinMaxAmountLimitType): SchemaOf<BigDecimal> => {
  const minAmount = Number(limit?.min?.amount ?? 0);
  const maxAmount = Number(limit?.max?.amount ?? 1000000000);

  const MIN_MESSAGE =
    limit?.min?.errorMessage || i18n.t('general.notifications.priceMin', {min: minAmount});
  const MAX_MESSAGE =
    limit?.max?.errorMessage || i18n.t('general.notifications.priceMax', {max: maxAmount});

  return mixed()
    .test('priceMax', MAX_MESSAGE, (value) => !value || value <= maxAmount)
    .test(
      'positive',
      i18n.t('general.notifications.numberPositive'),
      (value) => !value || value >= 0
    )
    .test('priceMin', MIN_MESSAGE, (value) => !value || value >= minAmount)
    .test(
      'typeError',
      i18n.t('general.notifications.numberValidationMessage'),
      (value) => !value || !isNaN(value)
    );
};

export const $Money = (
  limit?: MinMaxAmountLimitType,
  isAmountRequired?: boolean
): SchemaOf<Money> =>
  // eslint-disable-next-line no-restricted-syntax
  object({
    amount: isAmountRequired
      ? $MoneyAmount(limit).required(i18n.t('general.notifications.errorFieldRequired'))
      : $MoneyAmount(limit),
    currency: string(),
  }) as unknown as SchemaOf<Money>;
