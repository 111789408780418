import {metadaApi} from '@dms/api/core';

import {
  DeleteEmployeeProfessionApiArg,
  DeleteEmployeeProfessionApiResponse,
  GetEmployeeProfessionApiArg,
  GetEmployeeProfessionApiResponse,
  GetEmployeeWorkProfessionsListApiResponse,
  PatchEmployeeProfessionApiArg,
  PatchEmployeeProfessionApiResponse,
  PostEmployeeProfessionApiArg,
  PostEmployeeProfessionApiResponse,
  PostEmployeeProfessionSetAsActiveApiArg,
  PostEmployeeProfessionSetAsActiveApiResponse,
  PostEmployeeProfessionSetAsInactiveApiArg,
  PostEmployeeProfessionSetAsInactiveApiResponse,
} from './types';

export const metadaEmployeeProfessionApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postEmployeeProfession: build.mutation<
      PostEmployeeProfessionApiResponse,
      PostEmployeeProfessionApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-profession`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['employeeProfessions'],
    }),
    getEmployeeProfession: build.query<
      GetEmployeeProfessionApiResponse,
      GetEmployeeProfessionApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-profession/${queryArg.id}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'employeeProfession', projectId: queryArg.id},
      ],
    }),
    deleteEmployeeProfession: build.mutation<
      DeleteEmployeeProfessionApiResponse,
      DeleteEmployeeProfessionApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-profession/${queryArg.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'employeeProfessions',
        {type: 'employeeProfession', projectId: queryArg.id},
      ],
    }),
    patchEmployeeProfession: build.mutation<
      PatchEmployeeProfessionApiResponse,
      PatchEmployeeProfessionApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-profession/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'employeeProfessions',
        {type: 'employeeProfession', projectId: queryArg.id},
      ],
    }),
    postEmployeeProfessionSetAsActive: build.mutation<
      PostEmployeeProfessionSetAsActiveApiResponse,
      PostEmployeeProfessionSetAsActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-profession/${queryArg.professionId}/set-as-active`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'employeeProfessions',
        {type: 'employeeProfession', projectId: queryArg.professionId},
      ],
    }),
    postEmployeeProfessionSetAsInactive: build.mutation<
      PostEmployeeProfessionSetAsInactiveApiResponse,
      PostEmployeeProfessionSetAsInactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-profession/${queryArg.professionId}/set-as-inactive`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'employeeProfessions',
        {type: 'employeeProfession', projectId: queryArg.professionId},
      ],
    }),
    getEmployeeWorkProfessionsList: build.query<GetEmployeeWorkProfessionsListApiResponse, void>({
      query: () => ({
        url: `/core/v1/employee-professions`,
      }),
      providesTags: ['employeeProfessions'],
    }),
  }),
});

export const {
  usePostEmployeeProfessionMutation,
  useGetEmployeeProfessionQuery,
  useDeleteEmployeeProfessionMutation,
  usePatchEmployeeProfessionMutation,
  usePostEmployeeProfessionSetAsActiveMutation,
  usePostEmployeeProfessionSetAsInactiveMutation,
  useGetEmployeeWorkProfessionsListQuery,
} = metadaEmployeeProfessionApi;
