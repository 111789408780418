import {useDndContext} from '@dnd-kit/core';

import {isNotNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

export function useDnd() {
  const dnd = useDndContext();

  const activeDnDId = isNotNilOrEmpty(dnd.active?.data?.current?.eventId)
    ? String(dnd.active!.data!.current!.eventId!)
    : null;

  const activeDnDType =
    isNotNil(activeDnDId) && isNotNil(dnd.active?.data?.current?.type)
      ? String(dnd.active!.data!.current!.type!)
      : null;

  const activeDnDDirection =
    isNotNil(activeDnDId) && isNotNil(dnd.active?.data?.current?.direction)
      ? String(dnd.active!.data!.current!.direction!)
      : null;

  const overId = isNotNil(activeDnDId) && isNotNil(dnd.over?.id) ? String(dnd.over.id) : null;

  const isResizing = isNotNil(activeDnDId) && activeDnDType === 'resize';
  const isResizingToBottom =
    isNotNil(activeDnDId) && activeDnDType === 'resize' && activeDnDDirection === 'bottom';

  return {
    activeDnDId,
    overId,
    isResizing,
    isResizingToBottom,
  };
}
