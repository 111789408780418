import {Button, openDialog, showNotification, Switch} from 'platform/components';
import {HStack, VStack} from 'platform/foundation';

import {isNil} from 'ramda';
import {isTrue} from 'ramda-adjunct';

import {useGetEsignoServiceQuery, usePatchEsignoServiceMutation} from '@dms/api/tenant';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {ApiKeyForm} from './components/ApiKeyForm';

export function EsignoService() {
  const {data: esigno, isLoading, isError, refetch} = useGetEsignoServiceQuery();
  const [updateEsigno, {isLoading: isPatchingEsigno}] = usePatchEsignoServiceMutation();
  const isEsignoEnabled = isTrue(esigno?.enabled);

  const handleToggleEsigno = (enabled: boolean) =>
    updateEsigno({enabled})
      .unwrap()
      .then(() => showNotification.success(i18n.t('general.notifications.successfullyCompleted')))
      .then(refetch)
      .then(({data}) => {
        if (isTrue(data?.enabled) && isNil(data?.credentials?.apiKey)) {
          openUpdateApiKeyDialog();
        }
      })
      .catch(handleApiError);

  const openUpdateApiKeyDialog = () =>
    openDialog(<ApiKeyForm />, {
      'data-testid': testIds.settings.esignoDetail('apiKeyDialog'),
      title: i18n.t('page.settings.labels.updateApiKey'),
    });

  return (
    <SettingsTemplate
      data-testid={testIds.settings.esignoDetail('settingsTemplate')}
      header={{title: i18n.t('page.settings.labels.digitalSignature')}}
      isLoading={isLoading}
      isError={isError}
    >
      <SettingsSection>
        <VStack spacing={4}>
          <Switch
            value={isEsignoEnabled}
            onChange={handleToggleEsigno}
            isDisabled={isPatchingEsigno}
            label={i18n.t('page.settings.labels.enableEsignoService')}
            data-testid={testIds.settings.esignoDetail('enableEsignoService')}
          />

          <HStack>
            <Button
              variant="secondary"
              onClick={openUpdateApiKeyDialog}
              title={i18n.t('page.settings.labels.updateApiKey')}
              data-testid={testIds.settings.esignoDetail('updateApiKey')}
            />
          </HStack>
        </VStack>
      </SettingsSection>
    </SettingsTemplate>
  );
}
