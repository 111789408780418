import {z} from 'zod';

export const SupplierOrderItemFormSchema = z.object({
  orderDetail: z.object({
    supplierQuantity: z.number().positive(),
    supplierUnit: z.string(),
    supplierUnitPrice: z.number().positive(),
    supplierTotalPrice: z.number().positive(),
    name: z.string(),
    catalogueNumber: z.string(),
    manufacturerId: z.string(),
    supplierOrderingNumber: z.string().nullish(),
    vat: z.string(),
    vin: z.string().max(50).nullish(),
    keyCode: z.string().max(50).nullish(),
    isIntelligentPart: z.boolean(),
    engineNumber: z.string().max(50).nullish(),
    serialNumber: z.number().nullish(),
    labelInfo: z.string().max(12).nullish(),
  }),
  requestDetail: z.object({
    warehouseRequestedQuantity: z.number().positive().min(0.0001),
    warehouseUnitPurchasePrice: z.number(),
  }),
  supplierArticle: z.object({
    supplierQuantity: z.number().positive(),
    supplierUnitId: z.string(),
    warehouseQuantity: z.number().positive(),
    warehouseUnitId: z.string(),
    supplierOrderingNumber: z.string(),
    supplierBulkPackageQuantity: z.number(),
    supplier: z.string(),
    isUpdateMapping: z.boolean().optional(),
  }),
});

export type SupplierOrderItemForm = z.infer<typeof SupplierOrderItemFormSchema>;
