import {Card, DataStatus} from 'platform/components';
import {Hide, HStack} from 'platform/foundation';

import {negate} from 'ramda';

import {Money} from '@dms/api/shared';
import i18n from '@dms/i18n';

import {CurrencyCodeType, suffixTestId, TestIdProps} from 'shared';

import {PriceColumn} from './PriceColumn';

interface SummaryCardProps extends TestIdProps {
  title: string;
  earnings?: Money | null;
  earningsDifference?: Money | null;
  costs?: Money | null;
  costsDifference?: Money | null;
  margin?: Money | null;
  isLoading?: boolean;
  isError?: boolean;
  areCostsHidden?: boolean;
  areEarningsHidden?: boolean;
  isTotalMarginHidden?: boolean;
}

export function SummaryCard(props: SummaryCardProps) {
  return (
    <Card title={props.title} data-testid={props['data-testid']}>
      <DataStatus spacing={4} isLoading={props.isLoading} isError={props.isError}>
        <HStack spacing={4}>
          <Hide when={props.areEarningsHidden}>
            <PriceColumn
              title={i18n.t('entity.earningsCosts.labels.totalEarnings')}
              priceWithVat={props.earnings?.amount}
              currency={props.costs?.currency as CurrencyCodeType}
              difference={props.earningsDifference?.amount}
              isNonDeductible
              data-testid={suffixTestId('total', props)}
            />
          </Hide>
          <Hide when={props.areCostsHidden}>
            <PriceColumn
              title={i18n.t('entity.earningsCosts.labels.totalCosts')}
              priceWithVat={props.costs?.amount}
              currency={props.costs?.currency as CurrencyCodeType}
              difference={negate(Number(props.costsDifference?.amount)).toString()}
              invertTag
              isNonDeductible
              data-testid={suffixTestId('amount', props)}
            />
          </Hide>
          <Hide when={props.areCostsHidden || props.areEarningsHidden || props.isTotalMarginHidden}>
            <PriceColumn
              colored
              title={i18n.t('entity.vehicle.labels.totalGrossMargin')}
              priceWithVat={props.margin?.amount}
              currency={props.costs?.currency as CurrencyCodeType}
              isNonDeductible
              tooltipText={i18n.t('entity.businessCase.labels.totalGrossMarginTooltip')}
              data-testid={suffixTestId('withVat', props)}
            />
          </Hide>
        </HStack>
      </DataStatus>
    </Card>
  );
}
