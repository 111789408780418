import {metadaApi} from '@dms/api/core';

import {
  BaseSupplierOrderVariantSupplier,
  DeleteSupplierOrderVariantRequest,
  DeleteSupplierOrderVariantRequestSchema,
  DeleteSupplierOrderVariantResponse,
  DeleteSupplierOrderVariantResponseSchema,
  GetSupplierOrderVariantRequest,
  GetSupplierOrderVariantRequestSchema,
  GetSupplierOrderVariantResponse,
  GetSupplierOrderVariantResponseSchema,
  GetSupplierOrderVariantsRequest,
  GetSupplierOrderVariantsRequestSchema,
  GetSupplierOrderVariantsResponse,
  GetSupplierOrderVariantsResponseSchema,
  GetSupplierOrderVariantSuppliersRequest,
  GetSupplierOrderVariantSuppliersRequestSchema,
  GetSupplierOrderVariantSuppliersResponse,
  GetSupplierOrderVariantSuppliersResponseSchema,
  PatchSupplierOrderVariantRequest,
  PatchSupplierOrderVariantRequestSchema,
  PatchSupplierOrderVariantResponse,
  PatchSupplierOrderVariantResponseSchema,
  PostSupplierOrderVariantRequest,
  PostSupplierOrderVariantRequestSchema,
  PostSupplierOrderVariantResponse,
  PostSupplierOrderVariantResponseSchema,
} from './types';

export const metadaWarehouseSupplierOrderVariantsApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getSupplierOrderVariants: build.query<
      GetSupplierOrderVariantsResponse[],
      GetSupplierOrderVariantsRequest
    >({
      query: () => ({
        url: `warehouse/v1/supplier-order-variants`,
        method: 'GET',
      }),
      providesTags: ['supplerOrderVariants'],
      extraOptions: {
        requestSchema: GetSupplierOrderVariantsRequestSchema,
        responseSchema: GetSupplierOrderVariantsResponseSchema,
      },
    }),
    getSupplierOrderVariant: build.query<
      GetSupplierOrderVariantResponse,
      GetSupplierOrderVariantRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/supplier-order-variant/${queryArg.orderVariantId}`,
        method: 'GET',
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'supplerOrderVariants', id: queryArg.orderVariantId},
      ],
      extraOptions: {
        requestSchema: GetSupplierOrderVariantRequestSchema,
        responseSchema: GetSupplierOrderVariantResponseSchema,
      },
    }),
    postSupplierOrderVariant: build.mutation<
      PostSupplierOrderVariantResponse,
      PostSupplierOrderVariantRequest
    >({
      query: (body) => ({
        url: `warehouse/v1/supplier-order-variant`,
        method: 'POST',
        body,
        queryArg: body,
      }),
      invalidatesTags: (result) => [{type: 'supplerOrderVariants', id: result?.orderVariantId}],
      extraOptions: {
        requestSchema: PostSupplierOrderVariantRequestSchema,
        responseSchema: PostSupplierOrderVariantResponseSchema,
      },
    }),
    patchSupplierOrderVariant: build.mutation<
      PatchSupplierOrderVariantResponse,
      PatchSupplierOrderVariantRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/supplier-order-variant/${queryArg.orderVariantId}`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'supplerOrderVariants', id: queryArg?.orderVariantId},
      ],
      extraOptions: {
        requestSchema: PatchSupplierOrderVariantRequestSchema,
        responseSchema: PatchSupplierOrderVariantResponseSchema,
      },
    }),
    deleteSupplierOrderVariant: build.mutation<
      DeleteSupplierOrderVariantResponse,
      DeleteSupplierOrderVariantRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/supplier-order-variant/delete`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: ['supplerOrderVariants'],
      extraOptions: {
        requestSchema: DeleteSupplierOrderVariantRequestSchema,
        responseSchema: DeleteSupplierOrderVariantResponseSchema,
      },
    }),
    getSupplierOrderVariantSuppliers: build.query<
      BaseSupplierOrderVariantSupplier[],
      GetSupplierOrderVariantSuppliersRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/supplier-order-variant/${queryArg.orderVariantId}/suppliers`,
        method: 'GET',
        queryArg,
      }),
      transformResponse: (response: GetSupplierOrderVariantSuppliersResponse) => response.supplier,
      providesTags: ['supplerOrderVariantSuppliers'],
      extraOptions: {
        requestSchema: GetSupplierOrderVariantSuppliersRequestSchema,
        responseSchema: GetSupplierOrderVariantSuppliersResponseSchema,
      },
    }),
  }),
});

export const {
  useGetSupplierOrderVariantsQuery,
  useGetSupplierOrderVariantQuery,
  usePostSupplierOrderVariantMutation,
  usePatchSupplierOrderVariantMutation,
  useDeleteSupplierOrderVariantMutation,
  useGetSupplierOrderVariantSuppliersQuery,
  useLazyGetSupplierOrderVariantSuppliersQuery,
} = metadaWarehouseSupplierOrderVariantsApi;
