import {Card, DataStatus, TabProps, Tabs} from 'platform/components';
import {Show, VStack} from 'platform/foundation';

import {useState} from 'react';

import {defaultTo, isNil, not} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetPaymentTypeEnumQuery} from '@dms/api/accounting';
import {useGetCurrenciesQuery} from '@dms/api/cached';
import {useGetAuthorizationProfilesQuery} from '@dms/api/metadaAdminAuthorizationProfile';
import {useGetWarehouseQuery, useGetWarehousesQuery} from '@dms/api/metadaWarehouse';
import {GetReceiveNoteResponse} from '@dms/api/metadaWarehouseReceiveNote';
import {GetReceiveNoteCorrectionResponse} from '@dms/api/metadaWarehouseReceiveNoteCorrection';
import {useGetSuppliersQuery} from '@dms/api/metadaWarehouseSupplier';
import {useGetTenantQuery} from '@dms/api/tenant';
import i18n from '@dms/i18n';
import {DEFAULT_CURRENCY, queryParams, Section} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId, useBoolean, useQueryState} from 'shared';

import {refreshDatagrid} from 'features/datagrid';

import {WAREHOUSE_GRID_CODES} from '../../../../constants/warehouseGridCodes';
import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';
import {BasicInformationForm} from './components/BasicInformationForm';
import {DeliveryNoteTab} from './components/DeliveryNoteTab';
import {ListOfItemsTab} from './components/ListOfItemsTab';
import {ReceiveNoteCorrectionAddMaterialModal} from './components/ReceiveNoteCorrectionAddMaterialModal';
import {ReceiveNoteTab} from './components/ReceiveNoteTab';

interface OverviewProps extends RequiredTestIdProps {
  isReceiveNoteCorrectionLoading: boolean;
  receiveNote?: GetReceiveNoteResponse;
  receiveNoteCorrection?: GetReceiveNoteCorrectionResponse;
}

export function Overview(props: OverviewProps) {
  const [activeTabId, setActiveTabId] = useQueryState(queryParams.COMPONENT_SECTIONS_TAB);
  const {receiveNoteCorrectionId} = useWarehouseParams();

  const [isMaterialModalVisible, openMaterialModal, closeMaterialModal] = useBoolean();

  const receiveNoteId = defaultTo('', props.receiveNoteCorrection?.receiveNote?.receiveNoteId);

  const [isCreatingReceiveNoteCorrection, setIsCreatingReceiveNoteCorrection] = useState<boolean>(
    isNilOrEmpty(receiveNoteCorrectionId)
  );

  const {
    data: suppliers,
    isLoading: areSuppliersLoading,
    isError: hasSuppliersError,
  } = useGetSuppliersQuery();

  const {
    data: warehouses,
    isLoading: areWarehousesLoading,
    isError: hasWarehousesError,
  } = useGetWarehousesQuery();

  const {
    data: warehouseByReceiveNoteCorrection,
    isLoading: isWarehouseByReceiveNoteCorrectionLoading,
    isError: hasWarehouseByReceiveNoteCorrectionError,
  } = useGetWarehouseQuery(
    {warehouseId: props.receiveNoteCorrection?.creditNote?.warehouseId!},
    {skip: isNil(props.receiveNoteCorrection?.creditNote?.warehouseId)}
  );

  const {data: tenant, isLoading: isTenantLoading, isError: hasTenantError} = useGetTenantQuery();

  const {
    data: authorizationProfiles,
    isLoading: areAuthorizationProfilesLoading,
    isError: hasAuthorizationProfilesError,
  } = useGetAuthorizationProfilesQuery({'x-tenant': tenant?.id!}, {skip: isNil(tenant)});

  const {
    data: paymentTypes,
    isLoading: arePaymentTypesLoading,
    isError: hasPaymentTypesError,
  } = useGetPaymentTypeEnumQuery();

  const {
    data: currencies,
    isLoading: areCurrenciesLoading,
    isError: hasCurrenciesError,
  } = useGetCurrenciesQuery();

  const isLoading =
    areSuppliersLoading ||
    areWarehousesLoading ||
    isWarehouseByReceiveNoteCorrectionLoading ||
    isTenantLoading ||
    areAuthorizationProfilesLoading ||
    arePaymentTypesLoading ||
    areCurrenciesLoading;

  const hasError =
    hasSuppliersError ||
    hasWarehousesError ||
    hasWarehouseByReceiveNoteCorrectionError ||
    hasTenantError ||
    hasAuthorizationProfilesError ||
    hasPaymentTypesError ||
    hasCurrenciesError;

  const isReceiveNoteCorrectionPending =
    props.receiveNoteCorrection?.creditNote?.state === 'PENDING';

  const currencyByReceiveNoteCorrectionWarehouse = defaultTo(
    DEFAULT_CURRENCY,
    warehouseByReceiveNoteCorrection?.currency
  );

  const handleClose = () => {
    closeMaterialModal();
    refreshDatagrid(WAREHOUSE_GRID_CODES.warehouseCreditNoteItem, {deselectAll: true});
  };

  const tabs: TabProps[] = [
    {
      title: i18n.t('entity.warehouse.labels.receiveNote'),
      id: 'receiveNote',
      content: (
        <ReceiveNoteTab
          receiveNoteCorrection={props.receiveNoteCorrection!}
          suppliers={suppliers}
          warehouses={warehouses}
          authorizationProfiles={authorizationProfiles}
          paymentTypes={paymentTypes}
          currencies={currencies}
          data-testid={suffixTestId('generalInformation', props)}
        />
      ),
    },
    {
      title: i18n.t('entity.warehouse.labels.deliveryNote'),
      id: 'deliveryNote',
      content: (
        <DeliveryNoteTab
          receiveNoteCorrection={props.receiveNoteCorrection!}
          suppliers={suppliers}
          warehouses={warehouses}
          authorizationProfiles={authorizationProfiles}
          paymentTypes={paymentTypes}
          currencies={currencies}
          data-testid={suffixTestId('deliveryNote', props)}
        />
      ),
    },
    {
      title: i18n.t('entity.warehouse.labels.listOfItems'),
      id: 'listOfItems',
      actions: [
        {
          type: 'button',
          variant: 'outlined',
          title: i18n.t('entity.warehouse.labels.addMaterial'),
          isDisabled: not(isReceiveNoteCorrectionPending),
          onClick: () => openMaterialModal(),
        },
      ],
      content: (
        <ListOfItemsTab
          receiveNoteId={receiveNoteId}
          isCreatingReceiveNoteCorrection={isCreatingReceiveNoteCorrection}
          isReceiveNoteCorrectionPending={isReceiveNoteCorrectionPending}
          totalPrice={props.receiveNoteCorrection?.total}
          totalPriceCurrency={currencyByReceiveNoteCorrectionWarehouse}
          data-testid={suffixTestId('listOfItems', props)}
        />
      ),
    },
  ];

  return (
    <Section isFullHeight data-testid={props['data-testid']}>
      <DataStatus
        isLoading={isLoading}
        isError={hasError}
        minHeight="100vh"
        data-testid={suffixTestId('status', props)}
      >
        <VStack height="100%" spacing={4}>
          {isCreatingReceiveNoteCorrection ? (
            <Card
              title={i18n.t('general.labels.basicInformation')}
              data-testid={suffixTestId('cards.basicInformation', props)}
            >
              <BasicInformationForm
                isCreatingReceiveNoteCorrection={isCreatingReceiveNoteCorrection}
                receiveNote={props.receiveNote}
                receiveNoteCorrection={props.receiveNoteCorrection}
                suppliers={suppliers}
                warehouses={warehouses}
                authorizationProfiles={authorizationProfiles}
                paymentTypes={paymentTypes}
                currencies={currencies}
                onClose={() => setIsCreatingReceiveNoteCorrection(false)}
                data-testid={suffixTestId('forms.basicInformation', props)}
              />
            </Card>
          ) : (
            <>
              <Tabs
                isFullHeight
                tabs={tabs}
                activeTabId={activeTabId}
                onChange={setActiveTabId}
                isLazy
                variant="condensed"
              />

              <Show when={isMaterialModalVisible}>
                <ReceiveNoteCorrectionAddMaterialModal
                  receiveNoteId={receiveNoteId}
                  receiveNoteCorrectionId={receiveNoteCorrectionId}
                  onClose={handleClose}
                  data-testid={suffixTestId('addMaterial', props)}
                />
              </Show>
            </>
          )}
        </VStack>
      </DataStatus>
    </Section>
  );
}
