import {openDeleteDialog, openDialog, showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {path} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {useDeleteRoleMutation} from '@dms/api/accessControl';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {composePath, noop, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {NewRoleForm} from './components/NewRoleForm';

export function RoleOverview() {
  const navigate = useNavigate();
  const [deleteRole] = useDeleteRoleMutation();

  const createNewRole = () =>
    openDialog(<NewRoleForm />, {
      size: 'small',
      scrollBehavior: 'outside',
      title: i18n.t('page.settings.actions.newCustomRole'),
      'data-testid': testIds.settings.userManagement('newRoleModal'),
    });

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData, rowData}) => {
    const roleId = isArray(rowId) ? rowId[0] : rowId;
    const roleName = path<string>(['title', 'value'], rowData);

    match(actionKey)
      .with('detail', () =>
        navigate(composePath(settingsRoutes.roleManagementDetail, {params: {roleId}}))
      )
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () =>
            deleteRole({roleId})
              .unwrap()
              .then(() => showNotification.success())
              .then(refreshData)
              .catch(handleApiError),
          text: i18n.t('page.settings.labels.deleteRoleDialog', {
            name: roleName,
          }),
          'data-testid': testIds.settings.userManagement('deleteRoleConfirmDialog'),
        })
      )
      .otherwise(noop);
  };

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('general.labels.roles'),
        actions: [
          {
            type: 'button',
            title: i18n.t('page.settings.actions.newCustomRole'),
            onClick: createNewRole,
          },
        ],
      }}
      data-testid={testIds.settings.userManagement('page')}
    >
      <DataGrid
        gridCode="role"
        actionCallback={actionCallback}
        data-testid={testIds.settings.userManagement('role-datagrid')}
      />
    </SettingsTemplate>
  );
}
