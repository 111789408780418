import {match} from 'ts-pattern';

import {always} from 'ramda';

import {OrderPaymentResponseBody, PaymentDiscriminator} from '@dms/api/checkout';
import {InvoiceDocumentTypes} from '@dms/api/shared';

export const getPaymentInvoiceInfo = (payment: OrderPaymentResponseBody) =>
  match<PaymentDiscriminator, {id: string | null; type: InvoiceDocumentTypes}>(
    payment.paymentDiscriminator
  )
    .with('DEPOSIT', always({id: payment.proformaInvoiceId, type: 'invoice_proforma'}))
    .with('PURCHASE', 'BALANCE', always({id: payment.invoiceId, type: 'invoice'}))
    .exhaustive();
