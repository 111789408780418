import {DataStatus, Form, FormField} from 'platform/components';
import {Grid, GridItem, VStack} from 'platform/foundation';

import {head} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {useGetManufacturersQuery} from '@dms/api/metadaAdminManufacturer';
import {useGetWarehousesQuery} from '@dms/api/metadaWarehouse';
import {useGetSuppliersQuery} from '@dms/api/metadaWarehouseSupplier';
import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {FormToDataGridConnectorProps} from 'features/datagrid';

import {getOptionsFromManufacturers} from '../../utils/getOptionsFromManufacturers';
import {getOptionsFromSuppliers} from '../../utils/getOptionsFromSuppliers';
import {getOptionsFromWarehouses} from '../../utils/getOptionsFromWarehouses';
import {getSearchTypeOptions} from '../../utils/getSearchTypeOptions';

type MaterialArticleTabExternalFiltersForm = Partial<{
  warehouseId: string[];
  isAvailable: boolean;
  supplierId: string;
  isInStock: boolean;
  catalogNumber: string;
  searchType: string;
  manufacturerId: string;
}>;

type RequestTabMaterialWarehouseTabExternalFiltersProps = {
  directSaleVariantId?: string;
} & FormToDataGridConnectorProps &
  RequiredTestIdProps;

export function RequestTabMaterialWarehouseTabExternalFilters(
  props: RequestTabMaterialWarehouseTabExternalFiltersProps
) {
  const {
    data: warehouses,
    isLoading: isWarehousesLoading,
    isError: isWarehousesError,
  } = useGetWarehousesQuery({
    directSaleVariantId: props.directSaleVariantId,
  });

  const {
    data: manufacturers,
    isLoading: isManufacturersLoading,
    isError: isManufacturersError,
  } = useGetManufacturersQuery();

  const {
    data: suppliers,
    isLoading: isSuppliersLoading,
    isError: isSuppliersError,
  } = useGetSuppliersQuery();

  const isLoading = isWarehousesLoading || isManufacturersLoading || isSuppliersLoading;
  const isError = isWarehousesError || isManufacturersError || isSuppliersError;

  const searchType = isArray(props.values.searchType)
    ? head(props.values.searchType)
    : props.values.searchType;

  const filteringMethodOptions = getSearchTypeOptions();
  const defaultFilteringMethod = filteringMethodOptions[0].value;

  const formValues: MaterialArticleTabExternalFiltersForm = {
    isAvailable: false,
    isInStock: false,
    warehouseId: [],
    ...props.values,
    searchType: searchType ?? defaultFilteringMethod,
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<MaterialArticleTabExternalFiltersForm>
        values={formValues}
        defaultValues={{}}
        onChange={props.onChange}
      >
        {(control) => (
          <VStack spacing={4}>
            <Grid columns={3} align="flex-end">
              <GridItem span={2}>
                <FormField
                  control={control}
                  name="catalogNumber"
                  type="text"
                  label={i18n.t('entity.warehouse.labels.catalogueNumber')}
                  data-testid={suffixTestId('inputs.catalogueNumber', props)}
                />
              </GridItem>
              <GridItem span={1}>
                <FormField
                  control={control}
                  name="searchType"
                  type="choice"
                  options={filteringMethodOptions}
                  isNotClearable
                  data-testid={suffixTestId('inputs.filteringMethod', props)}
                />
              </GridItem>
            </Grid>
            <FormField
              control={control}
              name="warehouseId"
              type="multiChoice"
              label={i18n.t('entity.warehouse.labels.warehouse')}
              options={getOptionsFromWarehouses(warehouses)}
              data-testid={suffixTestId('warehouse', props)}
            />
            <FormField
              control={control}
              name="manufacturerId"
              type="multiChoice"
              label={i18n.t('entity.warehouse.labels.manufacturer')}
              options={getOptionsFromManufacturers(manufacturers)}
              data-testid={suffixTestId('inputs.manufacturer', props)}
            />
            <FormField
              control={control}
              name="supplierId"
              type="multiChoice"
              label={i18n.t('entity.warehouse.labels.articleDefaultSupplier')}
              options={getOptionsFromSuppliers(suppliers)}
              data-testid={suffixTestId('inputs.supplierId', props)}
            />
            <FormField
              control={control}
              name="isAvailable"
              label={i18n.t('entity.warehouse.labels.availableOnly')}
              type="checkbox"
              data-testid={suffixTestId('availableOnly', props)}
            />
            <FormField
              control={control}
              name="isInStock"
              label={i18n.t('entity.warehouse.labels.inStock')}
              type="checkbox"
              data-testid={suffixTestId('inStock', props)}
            />
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}
