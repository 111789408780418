import {
  Button,
  ButtonGroup,
  Card,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Box, Space, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {useEffect, useMemo} from 'react';
import {Helmet} from 'react-helmet-async';
import {useNavigate, useSearchParams} from 'react-router-dom';

import {ChangePasswordApiArg, getWorkspaceFromUri, VerifyTokenApiArg} from '@dms/api/core';
import {useChangePasswordMutation, useVerifyTokenMutation} from '@dms/api/public';
import i18n from '@dms/i18n';
import {loginRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {AuthContainer} from '../../components/AuthContainer/AuthContainer';
import {
  lovercaseLettersRegex,
  numbersRegex,
  passwordRegex,
  uppercaseLettersRegex,
} from './utils/regexes';

type PasswordForm = {
  password: string;
  repeatPassword: string;
};

const {workspace} = getWorkspaceFromUri();

export function ChangePassword() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [verifyToken, {isLoading: isVerifyTokenLoading}] = useVerifyTokenMutation();
  const [requestPasswordChange, {isLoading: isRequestPasswordChangeLoading}] =
    useChangePasswordMutation();

  const getToken = useMemo(() => {
    const token = searchParams.get('token');
    return token?.split('?')[0] as string | undefined;
  }, [searchParams]);

  useEffect(() => {
    const token = getToken;

    if (!token) {
      showNotification.error(i18n.t('page.login.actions.missingToken'));
      navigate(loginRoutes.loginForgotPassword);
      return;
    }

    const arg: VerifyTokenApiArg = {
      workspace: workspace!,
      verifyTokenRequestBody: {
        token,
      },
    };

    verifyToken(arg)
      .unwrap()
      .catch((error: Error & {status?: number}) => {
        if (error.status === 401 || error.status === 400) {
          navigate(loginRoutes.login);
        }
        handleApiError(error);
      });
  }, [navigate, searchParams]);

  const onSubmit: FormSubmitHandler<PasswordForm> = async ({password}, setErrors) => {
    const token = searchParams.get('token');

    if (!token) {
      showNotification.error(i18n.t('page.login.actions.missingToken'));
      navigate(loginRoutes.loginForgotPassword);
      return;
    }

    const arg: ChangePasswordApiArg = {
      workspace: workspace!,
      changePasswordRequestBody: {
        token,
        password,
      },
    };

    await requestPasswordChange(arg)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.passwordChanged'));
        navigate(loginRoutes.login);
      })
      .catch((error: Error & {status?: number}) => {
        if (error.status === 400) {
          setErrors([{name: 'password', message: i18n.t('general.notifications.error')}]);
          return;
        }
        showNotification.error(i18n.t('general.notifications.error'));
      });
  };

  const isLoading = isVerifyTokenLoading || isRequestPasswordChangeLoading;

  return (
    <>
      <Helmet>
        <title>{i18n.t('page.login.labels.newPassword')}</title>
      </Helmet>

      <AuthContainer>
        <Card title={i18n.t('page.login.labels.newPassword')}>
          <Form onSubmit={onSubmit} schema={schema}>
            {(control) => (
              <Box width={80}>
                <VStack spacing={4}>
                  <FormField
                    isRequired
                    name="password"
                    type="password"
                    control={control}
                    label={i18n.t('page.login.actions.setPassword')}
                    data-testid={testIds.login.loginChangePassword('password')}
                  />
                  <FormField
                    isRequired
                    name="repeatPassword"
                    type="password"
                    control={control}
                    label={i18n.t('page.login.placeholders.repeatPassword')}
                    data-testid={testIds.login.loginChangePassword('repeatPassword')}
                  />
                </VStack>

                <Space vertical={4} />

                <ButtonGroup
                  align="right"
                  data-testid={testIds.login.loginChangePassword('actions')}
                >
                  <Button
                    variant="link"
                    data-testid={testIds.login.loginChangePassword('backToLogin')}
                    isDisabled={isLoading}
                    onClick={() => navigate(loginRoutes.login)}
                    title={i18n.t('page.login.actions.backToLogin')}
                  />

                  <FormButton
                    control={control}
                    data-testid={testIds.login.loginChangePassword('saveNewPassword')}
                    type="submit"
                    isLoading={isLoading}
                    title={i18n.t('page.login.actions.saveNewPassword')}
                  />
                </ButtonGroup>
              </Box>
            )}
          </Form>
        </Card>
      </AuthContainer>
    </>
  );
}

const schema = Yup.object().shape({
  password: Yup.string()
    .required(i18n.t('general.notifications.errorPasswordsRequired'))
    .min(8, i18n.t('general.notifications.errorPasswordsLength'))
    .matches(passwordRegex, {
      message: i18n.t('general.notifications.errorPasswordsFormat'),
    })
    .matches(uppercaseLettersRegex, {
      message: i18n.t('general.notifications.errorPasswordsUppercase'),
    })
    .matches(lovercaseLettersRegex, {
      message: i18n.t('general.notifications.errorPasswordsLowercase'),
    })
    .matches(numbersRegex, {
      message: i18n.t('general.notifications.errorPasswordsDigit'),
    }),
  repeatPassword: Yup.string()
    .required(i18n.t('general.notifications.passwordsDoesNotMatch'))
    .oneOf([Yup.ref('password'), null], i18n.t('general.notifications.passwordsDoesNotMatch')),
});
