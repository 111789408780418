import {Dropdown, DropdownItem, showNotification} from 'platform/components';
import {Link} from 'platform/foundation';

import {isNotNil} from 'ramda';

import {useGetParticipationQuery} from '@dms/api/participation';
import {useGetSalePresetSettingsListQuery} from '@dms/api/sales';
import {useGetSaleVehicleQuery, usePatchSaleVehicleSalePresetMutation} from '@dms/api/saleVehicle';
import {EntityResourceIds} from '@dms/api/shared';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {usePermissions} from '../../../hooks/usePermissions/usePermissions';
import {handleApiError} from '../../../utils/handleApiError';
import {NoPermissionTooltip} from '../../NoPermissionTooltip/NoPermissionTooltip';
import {openSalePresetUpdateInfoDialog} from '../../SalePresetUpdateInfoDialog/openSalePresetUpdateInfoDialog';

export interface SalePresetProps extends TestIdProps {
  recordId: string;
}

export function SalePreset(props: SalePresetProps) {
  const {data: salePresetList, isLoading: isSalePresetListQueryLoading} =
    useGetSalePresetSettingsListQuery();
  const {data: saleVehicle, isLoading: isSaleVehicleQueryLoading} = useGetSaleVehicleQuery({
    vehicleId: props.recordId,
  });
  const {data: vehicleParticipation} = useGetParticipationQuery({
    recordId: props.recordId,
    resourceId: EntityResourceIds.vehicle,
  });
  const [patchSalePreset, {isLoading: isPatchSalePresetMutationLoading}] =
    usePatchSaleVehicleSalePresetMutation();

  const [canUpdateVehicle, canEditGeneralSettings] = usePermissions({
    permissionKeys: ['updateVehicle', 'editGeneralSettings'],
    scopes: {
      updateVehicle: {
        participation: vehicleParticipation,
        branchId: saleVehicle?.branchId,
      },
      editGeneralSettings: {
        participation: vehicleParticipation,
        branchId: saleVehicle?.branchId,
      },
    },
  });

  const canEditSalePreset = canUpdateVehicle && canEditGeneralSettings;

  const isLoading =
    isSalePresetListQueryLoading || isSaleVehicleQueryLoading || isPatchSalePresetMutationLoading;

  const selectedSalePreset = salePresetList?.find(
    (salePreset) => salePreset.id === saleVehicle?.salePresetId
  );

  const title = selectedSalePreset?.name ?? i18n.t('entity.vehicle.labels.salePreset');

  const updateSalePreset = (salePresetIdToUpdate: string) => {
    patchSalePreset({
      vehicleId: props.recordId,
      patchSaleVehicleSalePresetRequestBody: {
        salePresetId: salePresetIdToUpdate,
      },
    })
      .unwrap()
      .then(() => {
        if (isNotNil(selectedSalePreset?.id) && selectedSalePreset.id !== salePresetIdToUpdate) {
          openSalePresetUpdateInfoDialog({salePresetId: salePresetIdToUpdate});
        } else {
          showNotification.success();
        }
      })
      .catch(handleApiError);
  };

  return canEditSalePreset ? (
    <Dropdown
      dropdownControl={
        <Link
          size="small"
          isDisabled={!salePresetList?.length || isLoading || !canEditSalePreset}
          leftIcon="toggle/star"
          title={title}
          data-testid={suffixTestId('salePreset', props)}
        />
      }
      isDisabled={isLoading || !canEditSalePreset}
    >
      {salePresetList?.map((salePreset) => (
        <DropdownItem
          key={salePreset.id}
          label={salePreset.name}
          onClick={() => {
            updateSalePreset(salePreset.id);
          }}
          data-testid={suffixTestId(`salePreset-${salePreset.id}`, props)}
        />
      ))}
    </Dropdown>
  ) : (
    <NoPermissionTooltip shouldShowTooltip>
      <Link
        size="small"
        isDisabled
        leftIcon="toggle/star"
        title={title}
        data-testid={suffixTestId('salePreset', props)}
      />
    </NoPermissionTooltip>
  );
}
