import {Option} from 'platform/components';
import {Nullish} from 'utility-types';

import {GetApiMakeModelWithMakeApiResponse, VehicleTypeEnumObject} from '@dms/api/shared';
import {useLazyGetTopMakesQuery} from '@dms/api/topMakes';
import {
  useLazyGetCustomTenantCatalogueQuery,
  useLazyGetMakeModelWithMakeQuery,
} from '@dms/api/vehicleCatalogue';
import i18n, {FALLBACK_LANGUAGE} from '@dms/i18n';

import {vehicleMakeParser} from '../utils/vehicleMakeParser';

interface DecodeMakeProps {
  vehicleType: VehicleTypeEnumObject | Nullish;
  unknownMakes?: Option[];
}

export function useLazyVehicleMake() {
  const [getVehicleMake, vehicleMakeApi] = useLazyGetMakeModelWithMakeQuery();

  const [getCustomTenantCatalogue, customTenantCataloguqApi] =
    useLazyGetCustomTenantCatalogueQuery();

  const [getTopMakes, topMakesApi] = useLazyGetTopMakesQuery();

  const isLoading =
    vehicleMakeApi.isLoading || customTenantCataloguqApi.isLoading || topMakesApi.isLoading;

  const isError = vehicleMakeApi.isError || customTenantCataloguqApi.isError || topMakesApi.isError;

  const decodeMake = async (props: DecodeMakeProps) => {
    const unknownMakes = props.unknownMakes || [];

    let vehicleMakeData: GetApiMakeModelWithMakeApiResponse | Nullish = null;

    if (props.vehicleType) {
      const vehicleMakeQuery = await getVehicleMake(
        {
          vehicleType: props.vehicleType,
          lang: [i18n.resolvedLanguage ?? FALLBACK_LANGUAGE],
        },
        true
      ).catch(() => null);

      vehicleMakeData = vehicleMakeQuery?.data;
    }

    const customTenantMakeModelsQuery = await getCustomTenantCatalogue(undefined, true).catch(
      () => null
    );

    const topMakesQuery = await getTopMakes(undefined, true).catch(() => null);

    return vehicleMakeParser({
      vehicleType: props.vehicleType,
      unknownMakes,
      vehicleMakeData,
      customTenantMakeModelData: customTenantMakeModelsQuery?.data,
      topMakesData: topMakesQuery?.data,
    });
  };

  return {decodeMake, isLoading, isError};
}
