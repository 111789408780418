import {FormControl, FormField, IconButton, showNotification} from 'platform/components';
import {Box, Grid, GridItem, HStack} from 'platform/foundation';

import {useEffect} from 'react';
import {UseFormReturn, useWatch} from 'react-hook-form';

import {useGetAllTireWarehousesQuery} from '@dms/api/metadaTires';
import i18n from '@dms/i18n';

import {TireWarehouseForm} from '../types/TireWarehouseForm';

interface ExternalWarehouseSelectorProps {
  control: FormControl<TireWarehouseForm>;
  formApi: UseFormReturn<TireWarehouseForm>;
  isLastOfKind: boolean;
  index: number;
  onRemove: () => void;
}

export function ExternalWarehouseSelector(props: ExternalWarehouseSelectorProps) {
  const isDefault = useWatch({
    control: props.control,
    name: `externalWarehouses.${props.index}.isDefault`,
  });

  const warehouseQuery = useGetAllTireWarehousesQuery();

  const selectedWarehouses = useWatch({
    control: props.control,
    name: `externalWarehouses`,
  });

  const warehouseOptions =
    warehouseQuery.data?.map((warehouse) => ({
      label: warehouse.name,
      value: warehouse.warehouseId,
      isDisabled: selectedWarehouses.find((item) => item.warehouseId === warehouse.warehouseId),
    })) || [];

  useEffect(() => {
    if (warehouseQuery.isError) {
      showNotification.error();
    }
  }, [warehouseQuery.isError]);

  useEffect(() => {
    if (isDefault) {
      const currentValues = props.formApi.getValues('externalWarehouses');
      currentValues.forEach((_, idx) => {
        if (idx !== props.index) {
          props.formApi.setValue(`externalWarehouses.${idx}.isDefault`, false);
        }
      });
    }
  }, [isDefault, props.formApi, props.index]);

  return (
    <Grid columns={2}>
      <GridItem>
        <HStack spacing={2}>
          <Box flex={1}>
            <FormField
              control={props.control}
              type="choice"
              name={`externalWarehouses.${props.index}.warehouseId`}
              label={i18n.t('entity.warehouse.labels.warehouse')}
              options={warehouseOptions}
              isDisabled={warehouseQuery.isLoading || warehouseQuery.isError}
              isLoading={warehouseQuery.isLoading}
              isRequired
            />
          </Box>
        </HStack>
      </GridItem>
      <GridItem>
        <Box paddingTop={6}>
          <HStack align="center" spacing={4}>
            <IconButton
              icon="action/delete"
              size="small"
              severity="danger"
              onClick={props.onRemove}
              isDisabled={props.isLastOfKind}
            />
            <FormField
              type="checkbox"
              control={props.control}
              name={`externalWarehouses.${props.index}.isDefault`}
              label={i18n.t('general.labels.default')}
            />
          </HStack>
        </Box>
      </GridItem>
    </Grid>
  );
}
