import {BigCalendarEvent} from '../types/BigCalendarEvent';

interface JobQueueItem {
  id: string;
  originalEvent: BigCalendarEvent;
  optimisticEvent: BigCalendarEvent;
}

interface DragState {
  isDragging: boolean;
  activeId: string | null;
  events: BigCalendarEvent[];
  jobQueue: JobQueueItem[];
}

const initialState: DragState = {
  isDragging: false,
  activeId: null,
  events: [],
  jobQueue: [],
};

let state = initialState;
const listeners = new Set<(state: DragState) => void>();

const setState = (newState: DragState) => {
  state = newState;
  listeners.forEach((listener) => listener(state));
};

export const dragStore = {
  getState: () => state,

  subscribe: (listener: (state: DragState) => void) => {
    listeners.add(listener);
    return () => listeners.delete(listener);
  },

  startDragging: (id: string) => {
    setState({
      ...state,
      isDragging: true,
      activeId: id,
    });
  },

  setEvents: (events: BigCalendarEvent[]) => {
    setState({
      ...state,
      events,
    });
  },

  addJob: (id: string, optimisticEvent: BigCalendarEvent) => {
    const eventIndex = state.events.findIndex((event) => event.id === optimisticEvent.id);
    const originalEvent = state.events[eventIndex];
    const newEvents = [...state.events];
    newEvents[eventIndex] = optimisticEvent;

    setState({
      ...state,
      activeId: null,
      isDragging: false,
      jobQueue: [...state.jobQueue, {id, originalEvent, optimisticEvent}],
      events: newEvents,
    });
  },

  resolveJob: (id: string) => {
    setState({
      ...state,
      isDragging: false,
      activeId: null,
      jobQueue: state.jobQueue.filter((job) => job.id !== id),
    });
  },

  rejectJob: (id: string) => {
    const jobIndex = state.jobQueue.findIndex((job) => job.id === id);
    const originalEvent = state.jobQueue[jobIndex].originalEvent;

    const eventIndex = state.events.findIndex((event) => event.id === originalEvent.id);
    const newEvents = [...state.events];
    newEvents[eventIndex] = originalEvent;

    setState({
      ...state,
      isDragging: false,
      activeId: null,
      events: newEvents,
      jobQueue: state.jobQueue.filter((job) => job.id !== id),
    });
  },

  resetDragState: () => {
    setState({
      ...state,
      isDragging: false,
      activeId: null,
    });
  },

  cleanup: () => {
    setState(initialState);
  },
};

export const selectIsDragging = () => dragStore.getState().isDragging;
export const selectActiveId = () => dragStore.getState().activeId;
export const selectEvents = () => dragStore.getState().events;
export const selectJobQueue = () => dragStore.getState().jobQueue;
