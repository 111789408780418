import {
  Action,
  Actions,
  Card,
  openDeleteDialog,
  openDialog,
  Parameter,
  Parameters,
  RadioItem,
  showNotification,
} from 'platform/components';
import {Box, Clickable, HStack, Show, Space, Text} from 'platform/foundation';
import {match} from 'ts-pattern';

import {always} from 'ramda';
import {isFalsy, isNotNil, isTrue} from 'ramda-adjunct';

import {
  ContractInformationResponseBodyV2,
  useDeleteCustomerContractInformationMutation,
} from '@dms/api/customer';
import i18n from '@dms/i18n';

import {buildArray, DOT_CHARACTER, suffixTestId, TestIdProps, useToggle} from 'shared';

import {useAddress} from '../../hooks/useAddress';
import {getContractSubTitle} from '../../utils/getContractSubTitle';
import {getContractTitle} from '../../utils/getContractTitle';
import {handleApiError} from '../../utils/handleApiError';
import {hasContractIncompleteBillingInfo} from '../../utils/hasContractIncompleteBillingInfo';
import {ContractInformationForm} from '../ContactInformationForm/ContractInformationForm';
import {CustomerContractAttributes} from '../CustomerContractAttributes/CustomerContractAttributes';
import {CustomerVerification} from '../CustomerVerification/CustomerVerification';

interface CustomerBillingInformationCardProps extends TestIdProps {
  isReadOnly?: boolean;
  isSelected: boolean;
  customerId: string;
  contract: ContractInformationResponseBodyV2;
  hasVerification?: boolean;
  resourceId?: string;
  recordId?: string;
  onSelect: (contract: ContractInformationResponseBodyV2) => void;
}

export function CustomerBillingInformationCard(props: CustomerBillingInformationCardProps) {
  const [isOpen, toggleOpen] = useToggle();

  const {composeAddress} = useAddress();

  const [deleteContract, {isLoading: isDeleteLoading}] =
    useDeleteCustomerContractInformationMutation();

  const onDelete = (contractInformationId: string) =>
    openDeleteDialog({
      onConfirm: () =>
        deleteContract({
          customerId: props.customerId,
          contractInformationId,
        })
          .unwrap()
          .then(() => showNotification.success())
          .catch(handleApiError),
    });

  const getContractAddress = (contract: ContractInformationResponseBodyV2) =>
    match([isNotNil(contract.person?.permanentAddress), isNotNil(contract.businessInfo?.address)])
      .with([true, false], always(composeAddress(contract.person?.permanentAddress?.address)))
      .with([false, true], always(composeAddress(contract.businessInfo?.address?.address)))
      .otherwise(always(i18n.t('entity.contract.labels.doesNotContainAnAddress')));

  const dropdownActions = [
    {
      label: i18n.t('general.actions.edit'),
      onClick: () =>
        openDialog(
          <ContractInformationForm
            isBankListDisabled
            isEditContactInformation
            customerId={props.customerId}
            contract={props.contract}
            data-testid={suffixTestId('editContract', props)}
          />,
          {
            title: i18n.t('entity.customer.actions.editBillingInformation'),
            scrollBehavior: 'outside',
            withAdditionalFooter: true,
            'data-testid': suffixTestId('editContract', props),
          }
        ),
    },
    {
      label: i18n.t('general.actions.remove'),
      onClick: () => onDelete(props.contract.id),
      isLoading: isDeleteLoading,
    },
  ];

  const actions = buildArray<Action>()
    .when(isFalsy(props.isReadOnly), {
      type: 'dropdown-iconButton',
      icon: 'navigation/more_vert',
      menuItems: dropdownActions,
    })
    .add({
      type: 'iconButton',
      icon: isOpen ? 'navigation/expand_less' : 'navigation/expand_more',
      onClick: toggleOpen,
    });

  return (
    <Card variant="inlineWhite" data-testid={props['data-testid']}>
      <HStack align="flex-start" spacing={3}>
        <RadioItem
          value={isTrue(props.isSelected)}
          isDisabled={props.isReadOnly}
          onChange={() => props.onSelect(props.contract)}
          data-testid={props['data-testid']}
        />
        <Box flexGrow={1}>
          <Clickable onClick={toggleOpen} data-testid={props['data-testid']}>
            <HStack spacing={1}>
              <Text size="small" alternative data-testid={suffixTestId('title', props)}>
                {getContractTitle(props.contract)}
              </Text>
              <Text color="tertiary" size="small">
                {DOT_CHARACTER}
              </Text>
              <Text size="small" data-testid={suffixTestId('subTitle', props)}>
                {getContractSubTitle(props.contract)}
              </Text>
              <Show when={hasContractIncompleteBillingInfo(props.contract)}>
                <Text color="tertiary" size="small">
                  {DOT_CHARACTER}
                </Text>
                <Text
                  size="small"
                  alternative
                  color="warning"
                  data-testid={suffixTestId('subTitle', props)}
                >
                  {i18n.t('entity.checkout.labels.incomplete')}
                </Text>
              </Show>
            </HStack>
            <Parameters
              parameters={buildArray<Parameter>().add(getContractAddress(props.contract))}
              color="secondary"
              size="small"
              data-testid={suffixTestId('description', props)}
            />
          </Clickable>
        </Box>
        <Actions size="small" actions={actions} data-testid={suffixTestId('actions', props)} />
      </HStack>
      <Show when={isOpen}>
        <Space vertical={4} />
        <CustomerContractAttributes contract={props.contract} data-testid={props['data-testid']} />
        <Show when={props.hasVerification}>
          <Space vertical={4} />
          <CustomerVerification
            contractInformationId={props.contract.id}
            customerId={props.customerId}
            recordId={props.recordId}
            resourceId={props.resourceId}
          />
        </Show>
      </Show>
    </Card>
  );
}
