import {Card} from 'platform/components';

import {useCallback} from 'react';

import {mergeAll} from 'ramda';

import i18n from '@dms/i18n';
import {Section, useCustomerUrl, useInvoiceActionCallback} from '@dms/shared';

import {DataGrid, QueryFilterObject} from 'features/datagrid';

export function Invoices() {
  const {customerId} = useCustomerUrl();

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {customer: [customerId]}]),
    [customerId]
  );

  const actionCallback = useInvoiceActionCallback();

  return (
    <Section>
      <Card title={i18n.t('entity.customer.labels.invoices')}>
        <DataGrid
          data-testid="customer-invoicing"
          gridCode="customer-invoicing"
          queryModifier={queryModifier}
          autoHeight
          actionCallback={actionCallback}
        />
      </Card>
    </Section>
  );
}
