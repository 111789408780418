import styled from 'styled-components';

import {ReactNode} from 'react';

import {TestIdProps} from 'shared';

import {APP_CONTAINER_ID} from '../hooks/useAppContainerRef';
import {useIsMobileNavigation} from '../hooks/useIsMobileNavigation';
import {useIsPageNavigationVisible} from '../hooks/useIsPageNavigationVisible';

interface AppContainerProps extends TestIdProps {
  children: ReactNode;
}

export function AppContainer(props: AppContainerProps) {
  const isMobileNavigation = useIsMobileNavigation();
  const isPageNavigationVisible = useIsPageNavigationVisible();

  return (
    <StyledAppContainer
      id={APP_CONTAINER_ID}
      $isMobile={isMobileNavigation}
      $isPageNavigationVisible={isPageNavigationVisible}
    >
      {props.children}
    </StyledAppContainer>
  );
}

const StyledAppContainer = styled.div<{$isMobile: boolean; $isPageNavigationVisible: boolean}>`
  overflow-y: auto;
  padding-top: ${({theme, $isMobile, $isPageNavigationVisible}) =>
    $isMobile && $isPageNavigationVisible ? theme.getSize(12) : 0};
  background-color: ${({theme}) => theme.colors.palettes.neutral[10][100]};
  grid-column-end: span ${({$isMobile}) => ($isMobile ? 2 : 1)};
`;
