import {match} from 'ts-pattern';

import {always} from 'ramda';

import {OpportunityType} from '../types/OpportunityType';

export const getSpecificFilters = (opportunityType: OpportunityType, isSelling: boolean) => {
  const type = isSelling ? 'SELLING' : 'BUYING';

  return match(opportunityType)
    .with(
      'newInterests',
      always({
        type: [type, 'SWAP'],
        state: ['NEW'],
      })
    )
    .with(
      'activeInterests',
      always({
        type: [type, 'SWAP'],
        state: ['OPEN'],
      })
    )
    .with(
      'offers',
      always({
        businessCaseType: [type],
        businessCaseState: ['OFFER'],
      })
    )
    .with(
      'contracts',
      always({
        businessCaseType: [type],
        businessCaseState: ['CONTRACT', 'DEPOSIT_PAID', 'DEPOSIT'],
      })
    )
    .with(
      'salesFunnel',
      always({
        businessCaseType: [type],
        businessCaseState: ['INVOICE', 'PAID'],
      })
    )
    .with(
      'closed',
      always({
        businessCaseType: [type],
        businessCaseState: ['CLOSED'],
        businessCaseClosedAt: {
          relativeInputValue: 7,
          relativeInputKey: 'LAST_N_DAYS',
        },
      })
    )
    .with(
      'warehouseTotal',
      always({
        saleState: ['for-sale', 'selling'],
      })
    )
    .with(
      'inSale',
      always({
        saleState: ['selling', 'payment-pending', 'contract'],
      })
    )
    .with(
      'inBuying',
      always({
        buyingState: ['buying', 'for-pricing', 'priced'],
      })
    )
    .exhaustive();
};
