import {isFeatureEnabled} from 'feature-flags';

import {isEmpty, isNil} from 'ramda';
import {isFunction} from 'ramda-adjunct';

import {ResolveFeatureFlagCallback} from '@dms/api/shared';

import {Nullish} from 'shared';

export const resolveFeatureFlag = (featureFlag: string | Nullish | ResolveFeatureFlagCallback) => {
  if (isNil(featureFlag) || isEmpty(featureFlag)) {
    return true;
  }

  if (isFunction(featureFlag)) {
    return featureFlag(isFeatureEnabled);
  }

  return isFeatureEnabled(featureFlag);
};
