import {
  Alert,
  Card,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Right, VStack} from 'platform/foundation';
import {LocaleConfig} from 'platform/locale';
import {object, ref, string} from 'yup';

import {
  useGetTenantLocaleConfigQuery,
  usePutTenantLocaleConfigMutation,
} from '@dms/api/localeConfig';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {localeOptions} from '../constants/localeOptions';
import {getCurrencyPreview} from '../utils/getCurrencyPreview';
import {getNumberPreview} from '../utils/getNumberPreview';
import {validateDateTimeFormat} from '../utils/validateDateTimeFormat';

export function LocaleConfigForm(props: TestIdProps) {
  const {data: currentLocale, isLoading, isError} = useGetTenantLocaleConfigQuery();
  const [saveLocale] = usePutTenantLocaleConfigMutation();

  const handleSubmit: FormSubmitHandler<LocaleConfig> = (values: LocaleConfig) =>
    saveLocale(values)
      .unwrap()
      .then(() =>
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'))
      )
      .catch(handleApiError);

  const preview = `${i18n.t('general.localeConfig.preview')}: `;

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<LocaleConfig>
        onSubmit={handleSubmit}
        defaultValues={currentLocale}
        schema={localeSchema}
      >
        {(control, formApi) => {
          const values = formApi.watch();
          const numberPreview = `${preview}${getNumberPreview(values as LocaleConfig)}`;
          const currencyPreview = `${preview}${getCurrencyPreview(values as LocaleConfig)}`;
          return (
            <VStack spacing={4}>
              <Alert
                variant="info"
                message={i18n.t('page.settings.locale.tenantAlert')}
                data-testid={suffixTestId('tenant-alert', props)}
              />
              <Card title={i18n.t('general.localeConfig.dateTimeSettings')}>
                <VStack spacing={4}>
                  <FormField
                    name="dateTimeFormat.timeShort"
                    type="choice"
                    control={control}
                    isNotClearable
                    options={localeOptions.dateTimeFormat.timeShort}
                    label={i18n.t('general.localeConfig.timeShort')}
                    data-testid={suffixTestId('datetime-time-short', props)}
                  />
                  <FormField
                    name="dateTimeFormat.timeMedium"
                    type="choice"
                    control={control}
                    isNotClearable
                    options={localeOptions.dateTimeFormat.timeMedium}
                    label={i18n.t('general.localeConfig.timeMedium')}
                    data-testid={suffixTestId('datetime-time-medium', props)}
                  />
                  <FormField
                    name="dateTimeFormat.timeLong"
                    type="choice"
                    control={control}
                    isNotClearable
                    options={localeOptions.dateTimeFormat.timeLong}
                    label={i18n.t('general.localeConfig.timeLong')}
                    data-testid={suffixTestId('datetime-time-long', props)}
                  />
                  <FormField
                    name="dateTimeFormat.timeFull"
                    type="choice"
                    control={control}
                    isNotClearable
                    options={localeOptions.dateTimeFormat.timeFull}
                    label={i18n.t('general.localeConfig.timeFull')}
                    data-testid={suffixTestId('datetime-time-full', props)}
                  />
                  <FormField
                    name="dateTimeFormat.dateShort"
                    type="choice"
                    control={control}
                    isNotClearable
                    options={localeOptions.dateTimeFormat.dateShort}
                    label={i18n.t('general.localeConfig.dateShort')}
                    data-testid={suffixTestId('datetime-date-short', props)}
                  />
                  <FormField
                    name="dateTimeFormat.dateMedium"
                    type="choice"
                    control={control}
                    isNotClearable
                    options={localeOptions.dateTimeFormat.dateMedium}
                    label={i18n.t('general.localeConfig.dateMedium')}
                    data-testid={suffixTestId('datetime-date-medium', props)}
                  />
                  <FormField
                    name="dateTimeFormat.dateLong"
                    type="choice"
                    control={control}
                    isNotClearable
                    options={localeOptions.dateTimeFormat.dateLong}
                    label={i18n.t('general.localeConfig.dateLong')}
                    data-testid={suffixTestId('datetime-date-long', props)}
                  />
                  <FormField
                    name="dateTimeFormat.dateFull"
                    type="choice"
                    control={control}
                    isNotClearable
                    options={localeOptions.dateTimeFormat.dateFull}
                    label={i18n.t('general.localeConfig.dateFull')}
                    data-testid={suffixTestId('datetime-date-full', props)}
                  />
                  <FormField
                    name="dateTimeFormat.dateTimeShort"
                    type="choice"
                    control={control}
                    isNotClearable
                    options={localeOptions.dateTimeFormat.dateTimeShort}
                    label={i18n.t('general.localeConfig.dateTimeShort')}
                    data-testid={suffixTestId('datetime-datetime-short', props)}
                  />
                  <FormField
                    name="dateTimeFormat.dateTimeMedium"
                    type="choice"
                    control={control}
                    isNotClearable
                    options={localeOptions.dateTimeFormat.dateTimeMedium}
                    label={i18n.t('general.localeConfig.dateTimeMedium')}
                    data-testid={suffixTestId('datetime-datetime-medium', props)}
                  />
                  <FormField
                    name="dateTimeFormat.dateTimeLong"
                    type="choice"
                    control={control}
                    isNotClearable
                    options={localeOptions.dateTimeFormat.dateTimeLong}
                    label={i18n.t('general.localeConfig.dateTimeLong')}
                    data-testid={suffixTestId('datetime-datetime-long', props)}
                  />
                  <FormField
                    name="dateTimeFormat.dateTimeFull"
                    type="choice"
                    control={control}
                    isNotClearable
                    options={localeOptions.dateTimeFormat.dateTimeFull}
                    label={i18n.t('general.localeConfig.dateTimeFull')}
                    data-testid={suffixTestId('datetime-datetime-full', props)}
                  />
                </VStack>
              </Card>
              <Card title={i18n.t('general.localeConfig.calendar')}>
                <FormField
                  name="calendar.firstDayOfTheWeek"
                  type="choice"
                  control={control}
                  isNotClearable
                  options={localeOptions.calendar.firstDayOfTheWeek}
                  label={i18n.t('general.localeConfig.selectFirstDayOfWeek')}
                  data-testid={suffixTestId('calendar-first-day-of-week', props)}
                />
              </Card>
              <Card title={i18n.t('general.localeConfig.numberSettings')}>
                <VStack spacing={4}>
                  <FormField
                    name="number.decimalSeparator"
                    type="choice"
                    control={control}
                    isNotClearable
                    helperText={numberPreview}
                    options={localeOptions.number.decimalSeparator}
                    label={i18n.t('general.localeConfig.decimalDelimiter')}
                    data-testid={suffixTestId('number-decimal-delimiter', props)}
                  />
                  <FormField
                    name="number.thousandsSeparator"
                    type="choice"
                    control={control}
                    isNotClearable
                    helperText={numberPreview}
                    options={localeOptions.number.thousandsSeparator}
                    label={i18n.t('general.localeConfig.thousandDelimiter')}
                    data-testid={suffixTestId('number-thousand-delimiter', props)}
                  />
                </VStack>
              </Card>
              <Card title={i18n.t('general.localeConfig.currencySettings')}>
                <VStack spacing={4}>
                  <FormField
                    name="currency.decimalSeparator"
                    type="choice"
                    control={control}
                    isNotClearable
                    helperText={currencyPreview}
                    options={localeOptions.currency.decimalSeparator}
                    label={i18n.t('general.localeConfig.decimalDelimiter')}
                    data-testid={suffixTestId('currency-decimal-delimiter', props)}
                  />
                  <FormField
                    name="currency.thousandsSeparator"
                    type="choice"
                    control={control}
                    isNotClearable
                    helperText={currencyPreview}
                    options={localeOptions.currency.thousandsSeparator}
                    label={i18n.t('general.localeConfig.thousandDelimiter')}
                    data-testid={suffixTestId('currency-thousand-delimiter', props)}
                  />
                  <FormField
                    name="currency.position"
                    type="choice"
                    control={control}
                    isNotClearable
                    label={i18n.t('general.localeConfig.currencyPosition')}
                    options={localeOptions.currency.position}
                    helperText={currencyPreview}
                    data-testid={suffixTestId('currency-currency-position', props)}
                  />
                </VStack>
              </Card>
              <Right>
                <FormButton
                  type="submit"
                  variant="primary"
                  control={control}
                  title={i18n.t('general.actions.save')}
                  data-testid="locale-config-submit-button"
                />
              </Right>
            </VStack>
          );
        }}
      </Form>
    </DataStatus>
  );
}

const localeSchema = object({
  language: string().required().nullable(),
  dateTimeFormat: object({
    timeShort: string()
      .required()
      .nullable()
      .test('valid-format', i18n.t('general.validations.invalidFormat'), (value) =>
        validateDateTimeFormat(value)
      ),
    timeMedium: string()
      .nullable()
      .required()
      .test('valid-format', i18n.t('general.validations.invalidFormat'), (value) =>
        validateDateTimeFormat(value)
      ),
    timeLong: string()
      .required()
      .nullable()
      .test('valid-format', i18n.t('general.validations.invalidFormat'), (value) =>
        validateDateTimeFormat(value)
      ),
    timeFull: string()
      .required()
      .nullable()
      .test('valid-format', i18n.t('general.validations.invalidFormat'), (value) =>
        validateDateTimeFormat(value)
      ),
    dateShort: string()
      .required()
      .nullable()
      .test('valid-format', i18n.t('general.validations.invalidFormat'), (value) =>
        validateDateTimeFormat(value)
      ),
    dateMedium: string()
      .required()
      .nullable()
      .test('valid-format', i18n.t('general.validations.invalidFormat'), (value) =>
        validateDateTimeFormat(value)
      ),
    dateLong: string()
      .required()
      .nullable()
      .test('valid-format', i18n.t('general.validations.invalidFormat'), (value) =>
        validateDateTimeFormat(value)
      ),
    dateFull: string()
      .required()
      .nullable()
      .test('valid-format', i18n.t('general.validations.invalidFormat'), (value) =>
        validateDateTimeFormat(value)
      ),
    dateTimeShort: string()
      .required()
      .nullable()
      .test('valid-format', i18n.t('general.validations.invalidFormat'), (value) =>
        validateDateTimeFormat(value)
      ),
    dateTimeMedium: string()
      .required()
      .nullable()
      .test('valid-format', i18n.t('general.validations.invalidFormat'), (value) =>
        validateDateTimeFormat(value)
      ),
    dateTimeLong: string()
      .required()
      .nullable()
      .test('valid-format', i18n.t('general.validations.invalidFormat'), (value) =>
        validateDateTimeFormat(value)
      ),
    dateTimeFull: string()
      .required()
      .nullable()
      .test('valid-format', i18n.t('general.validations.invalidFormat'), (value) =>
        validateDateTimeFormat(value)
      ),
  }),
  number: object({
    decimalSeparator: string().required().nullable().required(),
    thousandsSeparator: string()
      .nullable()
      .notOneOf([ref('decimalSeparator')], i18n.t('general.validations.delimiterCanNotBeSame')),
  }),
  currency: object({
    decimalSeparator: string().nullable().required(),
    thousandsSeparator: string()
      .nullable()
      .notOneOf([ref('decimalSeparator')], i18n.t('general.validations.delimiterCanNotBeSame')),
    position: string().required(),
  }),
});
