import {parseDate} from 'shared';

export function parseDueTime(dueTime: string, baseDate: Date) {
  const dueTimeParts = dueTime.split(' - ');
  const startTime = dueTimeParts[0];
  const endTime = dueTimeParts[1];

  // If there is no end time, we need to set the end time to the start time
  if (!endTime) {
    const start = parseDate(baseDate.setHours(0, 0, 0, 0));
    const end = parseDate(baseDate);
    const [endHours, endMinutes] = startTime.split(':');
    end.setHours(parseInt(endHours, 10), parseInt(endMinutes, 10));

    return {start, end};
  }

  const start = parseDate(baseDate);
  const [startHours, startMinutes] = startTime.split(':');
  start.setHours(parseInt(startHours, 10), parseInt(startMinutes, 10));

  const end = parseDate(baseDate);
  const [endHours, endMinutes] = endTime.split(':');
  end.setHours(parseInt(endHours, 10), parseInt(endMinutes, 10));

  return {start, end};
}
