import {z} from 'zod';

import {BaseVoidResponseSchema} from '@dms/api/shared';

/**
 * GET requests
 */
export const GetCustomerContractRequestSchema = z.object({
  directSaleId: z.string(),
});

export type GetCustomerContractRequest = z.infer<typeof GetCustomerContractRequestSchema>;

const MaterialDiscountGroupSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string(),
});

const CustomerContractSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  materialDiscountGroup: MaterialDiscountGroupSchema.optional(),
});

export const GetCustomerContractResponseSchema = CustomerContractSchema;

export type GetCustomerContractResponse = z.infer<typeof GetCustomerContractResponseSchema>;

/**
 * POST requests
 */
export const PostCustomerContractRequestSchema = z.object({
  directSaleId: z.string(),
  body: z.object({
    customerContractId: z.string(),
    isDiscountApply: z.boolean(),
  }),
});

export type PostCustomerContractRequest = z.infer<typeof PostCustomerContractRequestSchema>;

export const PostCustomerContractResponseSchema = CustomerContractSchema;

export type PostCustomerContractResponse = z.infer<typeof PostCustomerContractResponseSchema>;

/**
 * PATCH requests
 */

/**
 * DELETE requests
 */
export const DeleteCustomerContractRequestSchema = z.object({
  directSaleId: z.string(),
  body: z.object({
    isDiscountRemove: z.boolean(),
  }),
});

export type DeleteCustomerContractRequest = z.infer<typeof DeleteCustomerContractRequestSchema>;

export const DeleteCustomerContractResponseSchema = BaseVoidResponseSchema;

export type DeleteCustomerContractResponse = z.infer<typeof DeleteCustomerContractResponseSchema>;
