import {Option} from 'platform/components';

import {isNil} from 'ramda';

import {BaseAuthorizationProfile} from '@dms/api/metadaAdminAuthorizationProfile';

import {Nullish} from 'shared';

export const getOptionsFromAuthorizationProfiles = (
  authorizationProfiles: BaseAuthorizationProfile[] | Nullish
): Option[] => {
  if (isNil(authorizationProfiles)) {
    return [];
  }

  return authorizationProfiles.map((authorizationProfile) => ({
    label: authorizationProfile.name,
    value: authorizationProfile.id,
  }));
};
