import {z} from 'zod';

import {BaseVoidRequest, BaseVoidResponse} from '@dms/api/shared';

export const BaseWarehouseAccountSchema = z.object({
  warehouseId: z.string().optional(),
  syntheticNumber: z.number().optional(),
  analyticalNumber: z.number().optional(),
  name: z.string(),
  operationId: z.string().optional().optional(),
  salesBasePriceMarkup: z.number(),
  warrantyPriceMarkup: z.number(),
  validFrom: z.string().optional(),
  validTo: z.string().optional(),
});

export const GetWarehouseAccountResponseSchema = z.object({
  warehouseId: z.string().optional(),
  syntheticNumber: z.number().optional(),
  analyticalNumber: z.number().optional(),
  name: z.string(),
  operationId: z.string().optional().optional(),
  salesBasePriceMarkup: z.number(),
  warrantyPriceMarkup: z.number(),
  validFrom: z.string().optional(),
  validTo: z.string().optional(),
  created: z.string(),
  createdBy: z.string(),
  updated: z.string().optional(),
  updatedBy: z.string().optional(),
  id: z.string(),
});

export type GetWarehouseAccountsRequest = BaseVoidRequest;

export const GetWarehouseAccountsResponseSchema = z.array(
  z.object({
    id: z.string(),
    name: z.string(),
    salesBasePriceMarkup: z.number(),
    warrantyPriceMarkup: z.number(),
  })
);
export type GetWarehouseAccountsResponse = z.infer<typeof GetWarehouseAccountsResponseSchema>;

export const GetWarehouseAccountRequestSchema = z.object({
  warehouseAccountId: z.string(),
});
export type GetWarehouseAccountRequest = z.infer<typeof GetWarehouseAccountRequestSchema>;

export type GetWarehouseAccountResponse = z.infer<typeof GetWarehouseAccountResponseSchema>;

export const CreateWarehouseAccountRequestSchema = BaseWarehouseAccountSchema.partial();
export type CreateWarehouseAccountRequest = z.infer<typeof CreateWarehouseAccountRequestSchema>;

export type CreateWarehouseAccountResponse = BaseVoidResponse;

export const UpdateWarehouseAccountRequestSchema = z.object({
  warehouseAccountId: z.string(),
  body: BaseWarehouseAccountSchema.partial(),
});
export type UpdateWarehouseAccountRequest = z.infer<typeof UpdateWarehouseAccountRequestSchema>;

export const UpdateWarehouseAccountResponseSchema = BaseWarehouseAccountSchema;
export type UpdateWarehouseAccountResponse = z.infer<typeof UpdateWarehouseAccountResponseSchema>;
