import {useFormContext as useRHFContext, FieldValues, Path, PathValue} from 'react-hook-form';

import {isNilOrEmpty} from 'ramda-adjunct';

/**
 * Enhanced useFormContext hook that extends react-hook-form's useFormContext
 * with additional utility functions.
 *
 * @template T - Form values type that extends FieldValues
 * @returns The original form context with added utility functions:
 *   - setValues: Batch-set multiple form values with type safety
 * @throws Error if used outside of a FormProvider context
 *
 * @example
 * const { register, watch, setValues } = useFormContext<MyFormType>();
 *
 * // Set multiple values at once
 * setValues({
 *   firstName: 'John',
 *   'address.city': 'New York'
 * });
 */
export function useFormContext<T extends FieldValues = FieldValues>() {
  const formContext = useRHFContext<T>();

  if (isNilOrEmpty(formContext)) {
    throw new Error(
      `useFormContext hook must be used within a FormProvider (parent form component)`
    );
  }

  const setValues = <K extends Path<T>>(values: Partial<Record<K, PathValue<T, K>>>) => {
    Object.entries(values).forEach(([key, value]) => {
      formContext.setValue(key as K, value as PathValue<T, K>);
    });
  };

  return {...formContext, setValues};
}
