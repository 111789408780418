import {FlagProps} from 'platform/components';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import {BusinessCaseState} from '@dms/api/commission';
import i18n from '@dms/i18n';

import {Nullish, LiteralUnionAutocomplete} from 'shared';

// TODO T20-25437 unify with Customer2Module utils
export const getBusinessCaseStateFlag = (
  type: LiteralUnionAutocomplete<BusinessCaseState> | Nullish
) =>
  match<string | Nullish, FlagProps | undefined>(type)
    .with(
      'CONCEPT',
      always({
        colorScheme: 'neutral',
        label: i18n.t('page.businessCase.label.stateConcept'),
        isSubtle: true,
      })
    )
    .with(
      'OFFER',
      always({
        colorScheme: 'orange',
        label: i18n.t('page.businessCase.label.stateOffer'),
        isSubtle: true,
      })
    )
    .with(
      'CONTRACT',
      always({
        colorScheme: 'blue',
        label: i18n.t('page.businessCase.label.stateContract'),
        isSubtle: true,
      })
    )
    .with(
      'CLOSED',
      always({
        colorScheme: 'green',
        label: i18n.t('page.businessCase.label.stateClosed'),
        isSubtle: true,
      })
    )
    .with(
      'UNSUCCESSFUL',
      always({
        colorScheme: 'red',
        label: i18n.t('page.businessCase.label.stateUnsuccessful'),
        isSubtle: true,
      })
    )
    .with(
      'DEPOSIT',
      always({
        colorScheme: 'blue',
        label: i18n.t('page.businessCase.label.stateDeposit'),
        isSubtle: true,
      })
    )
    .with(
      'DEPOSIT_PAID',
      always({
        colorScheme: 'green',
        label: i18n.t('page.businessCase.label.stateDepositPaid'),
        isSubtle: true,
      })
    )
    .with(
      'INVOICE',
      always({
        colorScheme: 'blue',
        label: i18n.t('page.businessCase.label.stateInvoice'),
        isSubtle: true,
      })
    )
    .with(
      'PAID',
      always({
        colorScheme: 'green',
        label: i18n.t('page.businessCase.label.statePaid'),
        isSubtle: true,
      })
    )
    .otherwise(always(undefined));
