import {DataStatus, OptionType, Segment, Separator} from 'platform/components';
import {Hide, Scroll, Show, VStack, Text} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useState} from 'react';

import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {useGetBusinessCaseQuery} from '@dms/api/businessCase';
import {EntityResourceIds} from '@dms/api/shared';
import i18n from '@dms/i18n';
import {CommentCardItem, useGetUserOptions} from '@dms/shared';

import {buildArray} from 'shared';

import {CommentsTabInput} from './CommentsTabInput';
import {useCommentActions} from './hooks/useCommentActions';

interface CommentsTabProps {
  businessCaseId?: string;
  interestId?: string;
}

type TabType = 'business-case' | 'vehicle' | 'interest';

export function CommentsTab(props: CommentsTabProps) {
  const [activeTab, setActiveTab] = useState<TabType>(
    props.businessCaseId ? 'business-case' : 'interest'
  );

  const {userOptions} = useGetUserOptions();

  const {data: businessCaseData} = useGetBusinessCaseQuery(
    {businessCaseId: props.businessCaseId ?? ''},
    {skip: isNilOrEmpty(props.businessCaseId)}
  );

  const offer = businessCaseData?.offers?.[0];

  const vehicleId = match(businessCaseData?.businessCaseType)
    .with('BUYING', () => offer?.purchaseVehicles?.[0]?.vehicleId)
    .with('SELLING', () => offer?.saleVehicles?.[0]?.vehicleId)
    .otherwise(() => '');

  const [resourceId, recordId] = match(activeTab)
    .with('business-case', () => [EntityResourceIds.businessCase, props.businessCaseId ?? ''])
    .with('interest', () => [EntityResourceIds.interest, props.interestId ?? ''])
    .with('vehicle', () => [EntityResourceIds.vehicle, vehicleId ?? ''])
    .exhaustive();

  const {
    comments,
    isLoading,
    isError,
    onCommentSubmit,
    handleCommentDelete,
    canDeleteComments,
    canAddComments,
    hasBusinessCaseCommentReadPermission,
    hasVehicleCommentReadPermission,
  } = useCommentActions({
    resourceId,
    recordId,
  });

  const tabOptions = buildArray<OptionType<TabType, string>>()
    .when(hasBusinessCaseCommentReadPermission, {
      value: 'business-case',
      label: i18n.t('entity.activityPanel.comments.businessCase'),
    })
    .when(hasVehicleCommentReadPermission, {
      value: 'vehicle',
      label: i18n.t('entity.activityPanel.comments.vehicle'),
    });

  return (
    <VStack spacing={4} height="100%">
      <Show when={isNotNilOrEmpty(props.businessCaseId)}>
        <Segment
          value={activeTab}
          onChange={(value: TabType) => {
            setActiveTab(value);
          }}
          options={tabOptions}
        />
      </Show>

      <Scroll auto height="100%">
        <DataStatus
          minHeight={35}
          isLoading={isLoading}
          isError={isError}
          isEmpty={!comments?.length}
          emptyMessage={i18n.t('page.comments.labels.noOneCommented')}
        >
          {comments?.map((comment, index) => (
            <VStack key={comment.id}>
              <CommentCardItem
                comment={comment}
                isDeleteCommentLoading={isLoading}
                isDeleteDisabled={!canDeleteComments}
                onDelete={handleCommentDelete}
              />

              <Show when={index !== comments.length - 1}>
                <Separator spacing={0} />
              </Show>
            </VStack>
          ))}
        </DataStatus>
      </Scroll>

      <Hide when={!canAddComments}>
        <CommentsTabInput
          isSendCommentLoading={isLoading}
          onSubmit={onCommentSubmit}
          userOptions={userOptions}
          isAddDisabled={!canAddComments}
        />
      </Hide>
      <Show when={!canAddComments}>
        <Text size="xSmall" color="tertiary">
          {i18n.t('page.comments.labels.canNotAddComment')}
        </Text>
      </Show>
    </VStack>
  );
}
