import {z} from 'zod';

import {BaseVoidResponseSchema} from '@dms/api/shared';

const LabourRateSchema = z.object({
  labourRateId: z.string().nullable().optional(),
  authorizationProfiles: z.array(z.string().nullable()).optional(),
  name: z.string().nullable().optional(),
  rateTypes: z.array(z.string().nullable()).optional(),
  rateValueWithoutVat: z.number().nullable().optional(),
  currency: z.string().nullable().optional(),
  workTypes: z.array(z.string().nullable()).optional(),
  vehicleGroups: z.array(z.string().nullable()).optional(),
});

export const LabourRateApiResponseSchema = LabourRateSchema.nullable();

export type PostLabourRateApiResponse = z.infer<typeof LabourRateApiResponseSchema>;
export type GetLabourRateApiResponse = z.infer<typeof LabourRateApiResponseSchema>;
export type PatchLabourRateApiResponse = z.infer<typeof LabourRateApiResponseSchema>;

export const PostLabourRateApiArgSchema = z.object({
  body: z
    .object({
      authorizationProfiles: z.array(z.string()),
      name: z.string(),
      rateTypes: z.array(z.string()),
      rateValueWithoutVat: z.number(),
      currency: z.string(),
      workTypes: z.array(z.string().nullable()).optional(),
      vehicleGroups: z.array(z.string().nullable()).optional(),
    })
    .nullable(),
});

export type PostLabourRateApiArg = z.infer<typeof PostLabourRateApiArgSchema>;

export const GetLabourRateApiArgSchema = z.object({
  labourRateId: z.string(),
});

export type GetLabourRateApiArg = z.infer<typeof GetLabourRateApiArgSchema>;

export const PatchLabourRateApiArgSchema = z.object({
  labourRateId: z.string(),
  body: z
    .object({
      authorizationProfiles: z.array(z.string()),
      name: z.string(),
      rateTypes: z.array(z.string()),
      rateValueWithoutVat: z.number(),
      currency: z.string(),
      workTypes: z.array(z.string().nullable()).optional(),
      vehicleGroups: z.array(z.string().nullable()).optional(),
    })
    .nullable(),
});

export type PatchLabourRateApiArg = z.infer<typeof PatchLabourRateApiArgSchema>;

export type DeleteLabourRateApiResponse = z.infer<typeof BaseVoidResponseSchema>;
export type PostLabourRateSetAsActiveApiResponse = z.infer<typeof BaseVoidResponseSchema>;
export type PostLabourRateSetAsInActiveApiResponse = z.infer<typeof BaseVoidResponseSchema>;

export const DeleteLabourRateApiArgSchema = z.object({
  labourRateId: z.string(),
});

export type DeleteLabourRateApiArg = z.infer<typeof DeleteLabourRateApiArgSchema>;

export const PostLabourRateSetAsActiveApiArgSchema = z.object({
  labourRateId: z.string(),
});
export type PostLabourRateSetAsActiveApiArg = z.infer<typeof PostLabourRateSetAsActiveApiArgSchema>;

export const PostLabourRateSetAsInActiveApiArgSchema = z.object({
  labourRateId: z.string(),
});
export type PostLabourRateSetAsInActiveApiArg = z.infer<
  typeof PostLabourRateSetAsInActiveApiArgSchema
>;
