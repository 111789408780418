import {
  DropdownItemProps,
  closeCurrentDialog,
  openDeleteDialog,
  openDialog,
} from 'platform/components';

import {flip, includes, isNil} from 'ramda';

import {
  useDeleteTreeFolderMutation,
  usePatchTreeFolderMutation,
  usePostTreeFolderChildMutation,
} from '@dms/api/metadaTreeFolder';
import {TreeFolder, TreeFolderContextTarget} from '@dms/api/shared';
import i18n from '@dms/i18n';

import {TestIdProps, buildArray, suffixTestId} from 'shared';

import {handleApiError} from '../../../utils/handleApiError';
import {FolderTreeForm} from '../components/FolderTreeForm';

type TreeFolderContext = {
  contextId?: string;
  contextTarget?: TreeFolderContextTarget;
};

type TreeFolderWithOptionalContext = TreeFolder & TreeFolderContext & TestIdProps;

export const useFolderItemActions = ({
  actions,
  contextTarget,
  contextId,
  label,
  id,
  ...rest
}: TreeFolderWithOptionalContext) => {
  const [postTreeFolderChild] = usePostTreeFolderChildMutation();
  const [patchTreeFolder] = usePatchTreeFolderMutation();
  const [deleteTreeFolder] = useDeleteTreeFolderMutation();

  if (isNil(contextTarget) || isNil(contextId) || isNil(id) || isNil(actions)) {
    return;
  }

  const handleCreateNewFolder = () =>
    openDialog(
      <FolderTreeForm
        isCreate
        onClose={closeCurrentDialog}
        onSubmit={(data) =>
          postTreeFolderChild({
            contextTarget,
            contextId,
            parentId: id,
            body: {
              name: data.name,
            },
          })
            .unwrap()
            .then(closeCurrentDialog)
            .catch(handleApiError)
        }
        data-testid={suffixTestId('newFolderForm', rest)}
      />,
      {
        title: i18n.t('entity.treeFolder.actions.newFolder'),
        size: 'small',
      }
    );

  const handleEdit = () =>
    openDialog(
      <FolderTreeForm
        defaultName={label}
        onClose={closeCurrentDialog}
        onSubmit={(data) =>
          patchTreeFolder({
            contextTarget,
            contextId,
            leafId: id,
            body: {
              name: data.name,
            },
          })
            .unwrap()
            .then(closeCurrentDialog)
            .catch(handleApiError)
        }
        data-testid={suffixTestId('editFolderForm', rest)}
      />,
      {
        title: i18n.t('entity.treeFolder.labels.editFolder', {name: label}),
        size: 'small',
      }
    );

  const handleDelete = () =>
    openDeleteDialog({
      text: i18n.t('entity.treeFolder.labels.delete', {name: label}),
      onConfirm: () =>
        deleteTreeFolder({
          contextTarget,
          contextId,
          leafId: id,
        })
          .unwrap()
          .catch(handleApiError),
      'data-testid': suffixTestId('deleteFolderDialog', rest),
    });

  const isActionActive = flip(includes)(actions);

  return buildArray<DropdownItemProps>()
    .when(isActionActive('addFolder'), {
      label: i18n.t('entity.treeFolder.actions.newFolder'),
      onClick: (event) => {
        event.stopPropagation();
        handleCreateNewFolder();
      },
    })
    .when(isActionActive('edit'), {
      label: i18n.t('general.actions.edit'),
      onClick: (event) => {
        event.stopPropagation();
        handleEdit();
      },
    })
    .when(isActionActive('delete'), {
      label: i18n.t('general.actions.delete'),
      onClick: (event) => {
        event.stopPropagation();
        handleDelete();
      },
      severity: 'danger',
    });
};
