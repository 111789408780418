import {Card, Flag, Parameter, Parameters} from 'platform/components';
import {Heading, HStack, Link, Show, Space, Text, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetBusinessCaseQuery} from '@dms/api/businessCase';
import {
  ActiveBusinessCaseResponseBody,
  BusinessCaseState,
  BusinessCaseType,
} from '@dms/api/commission';
import {EntityResourceIds} from '@dms/api/shared';
import {useGetTagsForEntityQuery} from '@dms/api/tag';
import i18n from '@dms/i18n';
import {businessCaseRoutes} from '@dms/routes';

import {
  buildArray,
  composePath,
  DOT_CHARACTER,
  EMPTY_PLACEHOLDER,
  parseDate,
  suffixTestId,
  TestIdProps,
} from 'shared';

import {usePricePermissions} from '../../hooks/usePricePermissions';
import {BusinessCaseStateFlag} from '../BusinessCaseStateFlag/BusinessCaseStateFlag';
import {BusinessCaseTypeFlag} from '../BusinessCaseTypeFlag/BusinessCaseTypeFlag';
import {PriceBox} from '../PriceBox/PriceBox';
import {getVehicle, getVehicleTitle} from './utils/getVehicle';

interface BusinessCaseSummaryProps extends TestIdProps {
  activeBusinessCase: ActiveBusinessCaseResponseBody;
  variant: 'customer' | 'vehicle';
}

export function BusinessCaseSummary(props: BusinessCaseSummaryProps) {
  const formatDateTime = useDateTimeFormatter();

  const {data: businessCase} = useGetBusinessCaseQuery(
    {
      businessCaseId: props.activeBusinessCase.id,
    },
    {skip: isNilOrEmpty(props.activeBusinessCase.id)}
  );

  const {
    canReadBusinessCasePurchaseVehiclePurchasePrice: canReadPurchasePrice,
    canReadBusinessCaseSaleVehicleSalePrice: canReadSalePrice,
  } = usePricePermissions({
    businessCaseRecordId: props.activeBusinessCase.id,
    vehicleRecordId: null,
  });

  const isPurchaseType =
    businessCase?.businessCaseType === 'PURCHASE_BROKERAGE' ||
    businessCase?.businessCaseType === 'BUYING';

  const isSaleType = businessCase?.businessCaseType === 'SELLING';

  const shouldShowPrice =
    (isPurchaseType && canReadPurchasePrice) || (isSaleType && canReadSalePrice);

  const isVatDeductable =
    businessCase?.offers?.[0]?.purchaseVehicles?.[0]?.pricing?.vatDeductible ||
    businessCase?.offers?.[0]?.purchaseBrokerageVehicles?.[0]?.pricing?.vatDeductible ||
    businessCase?.offers?.[0]?.saleVehicles?.[0]?.vatDeductible ||
    false;

  const tagsQueryArgs = {
    resourceId: EntityResourceIds.businessCase,
    recordId: props.activeBusinessCase.id,
  };

  const {data: entityTags} = useGetTagsForEntityQuery(tagsQueryArgs);

  const createdAt = formatDateTime('dateShort', parseDate(props.activeBusinessCase.createdAt));
  const updatedAt = props.activeBusinessCase.updatedAt
    ? formatDateTime('dateShort', parseDate(props.activeBusinessCase.updatedAt))
    : undefined;
  const authorFullName = `${props.activeBusinessCase.createdByFirstName} ${props.activeBusinessCase.createdByLastName}`;

  const parameters = buildArray<Parameter>()
    .add(`${i18n.t('general.labels.created')} ${createdAt}`)
    .when(!!updatedAt, `${i18n.t('general.labels.lastUpdated')} ${updatedAt}`)
    .add(i18n.t('general.labels.by', [authorFullName]));

  const vehicle = getVehicle(props.activeBusinessCase);
  const vehicleTitle = getVehicleTitle(vehicle);

  const title =
    (props.variant === 'customer' ? props.activeBusinessCase.customerName : vehicleTitle) ??
    EMPTY_PLACEHOLDER;

  return (
    <Card data-testid={suffixTestId('businessCaseSummary-card', props)}>
      <HStack justify="space-between">
        <VStack spacing={1}>
          <HStack spacing={1} data-testid={suffixTestId('businessCaseSummary-flags', props)}>
            <BusinessCaseStateFlag
              state={props.activeBusinessCase.businessCaseState as BusinessCaseState}
              data-testid={suffixTestId('businessCaseStateFlag', props)}
            />
            <BusinessCaseTypeFlag
              type={props.activeBusinessCase.businessCaseType as BusinessCaseType}
              data-testid={suffixTestId('businessCaseTypeFlag', props)}
            />
            {entityTags?.map((tag) => (
              <Flag
                size="small"
                colorScheme={tag.valueColor}
                label={tag.valueName}
                key={`tag-[${tag.tagId}]`}
                data-testid={suffixTestId('flags', props)}
              />
            ))}
          </HStack>
          <Space horizontal={1} />
          <Heading size={4} data-testid={props['data-testid']}>
            {title}
          </Heading>
          <HStack spacing={1}>
            <Link
              size="xSmall"
              leftIcon="action/launch"
              href={composePath(businessCaseRoutes.overview, {
                params: {
                  id: props.activeBusinessCase.id,
                },
                isSearchParamsPreserved: false,
              })}
              title={props.activeBusinessCase.code}
              data-testid={props['data-testid']}
            />
            <Text size="xSmall" color="secondary">
              {DOT_CHARACTER}
            </Text>
            <Parameters
              size="xSmall"
              color="secondary"
              parameters={parameters}
              data-testid={props['data-testid']}
            />
          </HStack>
        </VStack>
        <Show when={shouldShowPrice}>
          <PriceBox
            title={i18n.t('entity.businessCase.labels.priceOfSale')}
            price={props.activeBusinessCase.offerTotalPrice?.withVat}
            priceSub={props.activeBusinessCase.offerTotalPrice?.withoutVat}
            data-testid={suffixTestId('priceOfSale', props)}
            isVatDeductible={isVatDeductable}
            align="flex-end"
          />
        </Show>
      </HStack>
    </Card>
  );
}
