import {DataStatus, Label, isCurrency} from 'platform/components';
import {HStack, Heading, Show, VStack} from 'platform/foundation';
import {useCurrencySymbolFormatter, useFormatCurrency} from 'platform/locale';
import {match} from 'ts-pattern';

import {always, isNotNil, negate} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {useShowPaymentPrescriptionQuery} from '@dms/api/payment';
import {Money} from '@dms/api/shared';
import i18n from '@dms/i18n';
import {EMPTY_PLACEHOLDER} from '@dms/shared';

import {Nullish} from 'shared';

interface PrescriptionIdPriceBoxProps {
  prescriptionId: string | Nullish;
  isCorrectiveTaxDocument?: boolean;
}

export function PrescriptionIdPriceBox(props: PrescriptionIdPriceBoxProps) {
  const formatCurrency = useFormatCurrency();
  const formatCurrencySymbol = useCurrencySymbolFormatter();

  const {
    data: prescription,
    isLoading,
    isError,
  } = useShowPaymentPrescriptionQuery(
    {paymentPrescriptionId: props.prescriptionId ?? ''},
    {skip: isNilOrEmpty(props.prescriptionId)}
  );

  const formatSymbol = (val: string | Nullish) =>
    match(isCurrency(val))
      .with(true, always(`(${isCurrency(val) ? formatCurrencySymbol(val) : val})`))
      .otherwise(always(''));

  const formatPrice = (val: Money | Nullish) =>
    isNotNil(val)
      ? (formatCurrency(
          props.isCorrectiveTaxDocument ? negate(parseFloat(val.amount)) : parseFloat(val.amount),
          val.currency,
          2
        ) ?? EMPTY_PLACEHOLDER)
      : EMPTY_PLACEHOLDER;

  return (
    <DataStatus isError={isError} isLoading={isLoading} grow={1} spacing={5}>
      <HStack spacing={4}>
        <VStack width={41}>
          <Label>{`${i18n.t('general.labels.amount')} ${formatSymbol(
            prescription?.price.currency
          )}`}</Label>
          <Heading size={4}>{formatPrice(prescription?.price)}</Heading>
        </VStack>

        <Show when={prescription?.state !== 'completed'}>
          <VStack width={41}>
            <Label>{`${i18n.t('entity.invoice.labels.remaining')} ${formatSymbol(
              prescription?.remainsToPay?.currency
            )}`}</Label>
            <Heading size={4} color="danger">
              {formatPrice(prescription?.remainsToPay)}
            </Heading>
          </VStack>
        </Show>
      </HStack>
    </DataStatus>
  );
}
