import {Avatar, Dropdown} from 'platform/components';
import {Box, Center, Scroll, Show} from 'platform/foundation';

import {NotificationsWidget, useNotifications} from '@dms/notifications';

import {suffixTestId, TestIdProps, useBoolean} from 'shared';

import {HeaderButtonWrapper} from '../HeaderButtonWrapper/HeaderButtonWrapper';

const NOTIFICATION_PAGE_SIZE = 20;

interface NotificationsPopupProps extends TestIdProps {}

export function NotificationsPopup(props: NotificationsPopupProps) {
  const [isOpen, setOpen, setClosed] = useBoolean();

  const {
    data: notificationData,
    isLoading,
    isFetching,
    isError,
    hasUnreadNotifications,
  } = useNotifications(NOTIFICATION_PAGE_SIZE);

  return (
    <Dropdown
      closeOnSelect
      closeOnBlur
      onClose={setClosed}
      onOpen={setOpen}
      isOpen={isOpen}
      dropdownControl={
        <HeaderButtonWrapper
          $isOpen={isOpen}
          data-testid={suffixTestId('notificationsPopup-button', props)}
        >
          <Center width="100%" height="100%">
            <Box position="relative">
              <Avatar variant="circle" size="xSmall" icon="social/notifications" />
              <Show when={hasUnreadNotifications}>
                <Box
                  position="absolute"
                  top={0}
                  right={0}
                  backgroundColor="severity.danger"
                  width={2}
                  height={2}
                  borderRadius="circular"
                />
              </Show>
            </Box>
          </Center>
        </HeaderButtonWrapper>
      }
      placement="bottom-end"
      isLazy
      lazyBehavior="unmount"
      data-testid={suffixTestId('notificationsPopup', props)}
    >
      <Scroll maxHeight="calc(100vh - 100px)" auto>
        <Box width={120}>
          <NotificationsWidget
            notificationData={notificationData}
            isLoading={isLoading}
            isError={isError}
            isFetching={isFetching}
            pageSize={NOTIFICATION_PAGE_SIZE}
            onNotificationClick={setClosed}
          />
        </Box>
      </Scroll>
    </Dropdown>
  );
}
