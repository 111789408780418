import {indexBy, isNotNil} from 'ramda';

import {useGetIdentityCardTypesQuery} from '@dms/api/cached';

import {Nullish} from 'shared';

const allowedPersonalIdTypes = {
  ID_IDENTITY_CARD: 'ID_TYPE_ID',
  ID_OTHER: 'ID_TYPE_OTHER',
  ID_PASSPORT: 'ID_TYPE_PASSPORT',
  ID_DRIVER_LICENSE: 'ID_TYPE_DRIVING_LICENSE',
} as const;

type PersonalIdType = (typeof allowedPersonalIdTypes)[keyof typeof allowedPersonalIdTypes];

export const useGetPersonalIdTypes = () => {
  const {data: types} = useGetIdentityCardTypesQuery();
  const typesByCode = indexBy((type) => type.code, types ?? []);

  const allowedTypes = Object.entries(allowedPersonalIdTypes).map(([key, value]) => ({
    value,
    label: typesByCode[key]?.name ?? key,
  }));

  const allowedTypesByCode = indexBy((type) => type.value, allowedTypes);

  const getTypeByCode = (code: PersonalIdType | Nullish) =>
    isNotNil(code) ? allowedTypesByCode[code] : null;

  return {
    types: allowedTypes,
    getTypeByCode,
  };
};
