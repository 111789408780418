import {FormControl, FormField, openConfirmDialog} from 'platform/components';
import {Nullish} from 'utility-types';

import {UseFormReturn} from 'react-hook-form';

import {VehicleTypeEnumObject} from '@dms/api/shared';
import i18n from '@dms/i18n';

import {handleFormReset, RequiredTestIdProps, suffixTestId} from 'shared';

import {MAKE_OTHER} from '../../../constants/makeModel';
import {RentalVehicleFormType} from '../../../types/RentalVehicleFormType';
import {useVehicleMake} from '../hooks/useVehicleMake';
import {createVehicleTitle} from '../utils/createVehicleTitle';

interface CreatableMakeSelectProps extends RequiredTestIdProps {
  control: FormControl<RentalVehicleFormType>;
  formApi: UseFormReturn<RentalVehicleFormType>;
  vehicleType: VehicleTypeEnumObject;
}

export function CreatableMakeSelect(props: CreatableMakeSelectProps) {
  const unknownMakes = props.formApi.watch('unknownMakes');

  const {
    groupedMakeOptions,
    detail: {globalMakes, flatMakes},
    isLoading,
  } = useVehicleMake({
    vehicleType: props.vehicleType,
    unknownMakes,
  });

  const handleChange = (newFormMake: string | Nullish) => {
    const newTitle = createVehicleTitle({
      flatMakes,
      flatModels: [],
      formMake: newFormMake,
      formModel: null,
    });

    handleFormReset<RentalVehicleFormType>(props.formApi, [
      {name: 'formModelFamily', value: null},
      {name: 'modelFamily', value: null},
      {name: 'customModelFamily', value: null},
      {name: 'trim', value: null},
      {name: 'title', value: newTitle},
      {name: 'variant', value: null},
      {name: 'engineName', value: null},
      {name: 'engineCode', value: null},
      {name: 'power', value: null},
      {name: 'engineVolume', value: null},
    ]);

    if (!newFormMake) {
      return;
    }

    if (globalMakes.some((item) => item.value === newFormMake)) {
      props.formApi.setValue('make', newFormMake);
      props.formApi.setValue('customMake', null);
      return;
    }

    props.formApi.setValue('make', MAKE_OTHER);
    props.formApi.setValue('customMake', newFormMake);
  };

  return (
    <FormField
      type="creatableChoice"
      control={props.control}
      name="formMake"
      isLoading={isLoading}
      options={groupedMakeOptions}
      label={i18n.t('entity.vehicle.labels.make')}
      onChange={(newValue) => handleChange(newValue as string | Nullish)}
      onCreateOption={(newValue) =>
        openConfirmDialog({
          text: i18n.t('entity.vehicle.labels.createCustomMakeConfirm', {make: newValue}),
          onConfirm: () => {
            props.formApi.setValue('formMake', newValue);
            props.formApi.setValue('unknownMakes', [{label: newValue, value: newValue}]);

            props.formApi.setValue('unknownModels', [
              {
                label: i18n.t('entity.vehicle.labels.otherLabel'),
                value: i18n.t('entity.vehicle.labels.otherLabel'),
              },
            ]);

            handleChange(newValue);
          },
        })
      }
      isRequired
      data-testid={suffixTestId('make', props)}
    />
  );
}
