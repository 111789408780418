import {OptionType} from 'platform/components';

import {PaymentImportMode} from '@dms/api/metadaErrorLogs';
import i18n from '@dms/i18n';

export const importTypeOptions: OptionType<PaymentImportMode>[] = [
  {value: 'importTrxOnly', label: i18n.t('page.accountingSettings.labels.importTrxOnly')},
  {
    value: 'importTrxDmsCreateTaxDocument',
    label: i18n.t('page.accountingSettings.labels.importTrxDmsCreateTaxDocument'),
  },
  {
    value: 'importTaxDocumentOnly',
    label: i18n.t('page.accountingSettings.labels.importTaxDocumentOnly'),
  },
];
