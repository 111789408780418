import {
  Card,
  CardVariantType,
  DataStatus,
  FormSubmitHandler,
  OptionType,
} from 'platform/components';
import {Hide, Show, Text, VStack} from 'platform/foundation';

import {CommentResponseBody} from '@dms/api/comment';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {CommentCardItem} from './components/CommentCardItem';
import {CommentsCardInput, CommentCardForm} from './components/CommentsCardInput';

export interface CommentsCardProps extends TestIdProps {
  cardVariant?: CardVariantType;
  isSendCommentLoading?: boolean;
  isFetchingCommentsLoading?: boolean;
  isFetchingCommentsErrored?: boolean;
  isDeleteCommentLoading?: boolean;
  title?: string;
  comments?: CommentResponseBody[];
  onSubmit: FormSubmitHandler<CommentCardForm>;
  onDelete?: (id: string) => void;
  userOptions?: OptionType[];
  isAddDisabled?: boolean;
  isDeleteDisabled?: boolean;
}

export function CommentsCard(props: CommentsCardProps) {
  return (
    <Card
      title={props.title ?? i18n.t('page.comments.labels.comments')}
      variant={props.cardVariant}
      data-testid={suffixTestId('comment', props)}
    >
      <VStack spacing={4}>
        <DataStatus
          minHeight={35}
          isLoading={props.isFetchingCommentsLoading}
          isError={props.isFetchingCommentsErrored}
          isEmpty={!props.comments?.length}
          emptyMessage={i18n.t('page.comments.labels.noOneCommented')}
          data-testid={suffixTestId('comment', props)}
        >
          <>
            {props.comments?.map((comment, index) => (
              <CommentCardItem
                key={comment.id}
                comment={comment}
                isDeleteCommentLoading={props.isDeleteCommentLoading}
                onDelete={props.onDelete}
                isDeleteDisabled={props.isDeleteDisabled}
                data-testid={suffixTestId(`comment-[${index}]`, props)}
              />
            ))}
          </>
        </DataStatus>
        <Hide when={props.isAddDisabled}>
          <CommentsCardInput
            isSendCommentLoading={props.isSendCommentLoading}
            onSubmit={props.onSubmit}
            userOptions={props.userOptions}
            data-testid={suffixTestId(`commentInput`, props)}
          />
        </Hide>
        <Show when={props.isAddDisabled}>
          <Text size="xSmall" color="tertiary">
            {i18n.t('page.comments.labels.canNotAddComment')}
          </Text>
        </Show>
      </VStack>
    </Card>
  );
}
