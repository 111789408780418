import {Card, DataStatus, useDialog} from 'platform/components';

import {useCallback} from 'react';

import {mergeAll} from 'ramda';

import {useGetDocumentDatagridActions} from '@dms/features/document-context';
import i18n from '@dms/i18n';
import {Section, UploadDocumentsDialog} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {DataGrid, QueryFilterObject, refreshDatagrid} from 'features/datagrid';

import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';

interface DocumentsProps extends RequiredTestIdProps {
  isCostCorrectionLoading: boolean;
}

export function Documents(props: DocumentsProps) {
  const {costCorrectionId} = useWarehouseParams();

  const [isUploadDialogOpen, openUploadDialog, closeUploadDialog] = useDialog();

  const [rowActions] = useGetDocumentDatagridActions({
    contextId: costCorrectionId,
    contextTarget: 'warehouse-issue-note-correction',
    refreshData: () => refreshDatagrid(DOCUMENTS_GRID_CODE),
  });
  const queryModifier = useCallback(
    (filter: QueryFilterObject) =>
      mergeAll([filter, {warehouseIssueNoteCorrectionId: costCorrectionId}]),
    [costCorrectionId]
  );

  return (
    <Section isFullHeight data-testid={suffixTestId('wrapper', props)}>
      <DataStatus isLoading={props.isCostCorrectionLoading} minHeight={60}>
        <Card
          isFullHeight
          title={i18n.t('entity.warehouse.labels.documents')}
          actions={[
            {
              type: 'button',
              variant: 'ghostLink',
              leftIcon: 'content/add_circle',
              title: i18n.t('entity.warehouse.actions.addDocument'),
              onClick: openUploadDialog,
              'data-testid': suffixTestId('action.addDocument', props),
            },
          ]}
        >
          <DataGrid
            gridCode={DOCUMENTS_GRID_CODE}
            actionCallback={rowActions}
            queryModifier={queryModifier}
            data-testid="documentContextListWarehouseIssueNoteCorrection"
          />
        </Card>
      </DataStatus>

      <UploadDocumentsDialog
        isOpen={isUploadDialogOpen}
        handleClose={closeUploadDialog}
        onDocumentCreated={() => refreshDatagrid(DOCUMENTS_GRID_CODE)}
        contextTarget="warehouse-issue-note-correction"
        contextId={costCorrectionId}
        bulkContext={[{target: 'warehouse-issue-note-correction', targetId: costCorrectionId}]}
        data-testid={suffixTestId('upload-document', props)}
      />
    </Section>
  );
}

const DOCUMENTS_GRID_CODE = 'document-context-list-warehouse-issue-note-correction';
