import {Button, ButtonGroup, Card, FormControl, FormField} from 'platform/components';
import {HStack, VStack} from 'platform/foundation';

import {UseFormReturn, useWatch} from 'react-hook-form';

import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

import {ChecklistFormValue} from '../types/ChecklistFormValue';

interface TaskCardProps {
  formApi: UseFormReturn<ChecklistFormValue>;
  control: FormControl<ChecklistFormValue>;
  index: number;
  onRemove: () => void;
  isAlone: boolean;
  onMoveUp: () => void;
  onMoveDown: () => void;
  isFirst: boolean;
  isLast: boolean;
}

export function TaskCard(props: TaskCardProps) {
  const title = useWatch({
    control: props.formApi.control,
    name: `tasks.${props.index}.description`,
  });

  const isMandatory = useWatch({
    control: props.formApi.control,
    name: `tasks.${props.index}.isMandatory`,
  });

  const fileRequired = useWatch({
    control: props.formApi.control,
    name: `tasks.${props.index}.fileRequired`,
  });

  return (
    <Card
      variant="inlineGrey"
      title={title || i18n.t('entity.settings.labels.newTask')}
      data-testid={testIds.settings.generalChecklistCreate(`taskCard-${props.index}`)}
      actions={[
        {
          type: 'iconButton',
          icon: 'navigation/arrow_upward',
          size: 'small',
          isDisabled: props.isFirst,
          onClick: props.onMoveUp,
        },
        {
          type: 'iconButton',
          icon: 'navigation/arrow_downward',
          size: 'small',
          isDisabled: props.isLast,
          onClick: props.onMoveDown,
        },
      ]}
      parameters={[
        {
          title: isMandatory
            ? i18n.t('entity.settings.labels.mandatory')
            : i18n.t('entity.settings.labels.optional'),
          tooltip: isMandatory
            ? i18n.t('entity.settings.labels.mandatory')
            : i18n.t('entity.settings.labels.optional'),
        },
        {
          title: fileRequired
            ? i18n.t('entity.settings.labels.documentRequired')
            : i18n.t('entity.settings.labels.noDocumentRequired'),
          tooltip: fileRequired
            ? i18n.t('entity.settings.labels.documentRequired')
            : i18n.t('entity.settings.labels.noDocumentRequired'),
        },
      ]}
      isExpandable
    >
      <VStack spacing={6}>
        <FormField
          type="text"
          control={props.control}
          name={`tasks.${props.index}.description`}
          label={i18n.t('general.labels.name')}
          data-testid={testIds.settings.generalChecklistCreate(`taskName`)}
          isRequired
          isCounterVisible
          maxLength={255}
        />
        <HStack spacing={4}>
          <FormField
            type="checkbox"
            control={props.control}
            name={`tasks.${props.index}.isMandatory`}
            label={i18n.t('entity.settings.labels.mandatory')}
            data-testid={testIds.settings.generalChecklistCreate(`taskMandatory`)}
            isRequired
          />
          <FormField
            type="checkbox"
            control={props.control}
            name={`tasks.${props.index}.fileRequired`}
            label={i18n.t('entity.settings.labels.documentRequired')}
            data-testid={testIds.settings.generalChecklistCreate(`taskRequired`)}
            isRequired
          />
        </HStack>
        <ButtonGroup align="right">
          <Button
            type="button"
            variant="dangerGhost"
            leftIcon="action/delete"
            title={i18n.t('general.labels.delete')}
            data-testid={testIds.settings.generalChecklistCreate(`deleteTask`)}
            onClick={props.onRemove}
            isDisabled={props.isAlone}
          />
        </ButtonGroup>
      </VStack>
    </Card>
  );
}
