import {omneticApi} from '@dms/api/core';

import {
  CreateCarVerticalReportApiArg,
  CreateCarVerticalReportApiResponse,
  GetCarVerticalReportsApiArg,
  GetCarVerticalReportsApiResponse,
} from './types';

export const carVericalApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    createCarVerticalReport: build.mutation<
      CreateCarVerticalReportApiResponse,
      CreateCarVerticalReportApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/car-vertical-report`,
        method: 'POST',
        body: queryArg.createCarVerticalReportRequestBody,
      }),
      invalidatesTags: ['CarVerticalReports'],
    }),
    getCarVerticalReports: build.query<
      GetCarVerticalReportsApiResponse,
      GetCarVerticalReportsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/car-vertical-report`,
      }),
      providesTags: ['CarVerticalReports'],
    }),
  }),
});

export const {useCreateCarVerticalReportMutation, useGetCarVerticalReportsQuery} = carVericalApi;
