import {Button, Card, DataStatus} from 'platform/components';
import {HStack, Show, Space} from 'platform/foundation';

import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {any, defaultTo, head, isNotNil} from 'ramda';
import {isTrue} from 'ramda-adjunct';

import {useDeleteInspectionMutation} from '@dms/api/carAudit';
import i18n from '@dms/i18n';
import {queryParams, DigitalCertificateLink, handleApiError} from '@dms/shared';
import {
  selectAudit,
  loadVehicleDetailsData,
  LoadAuditDataResponseItemBody,
  useThunkDispatch,
  getAudit,
  updateAuditData,
  Audit,
  selectCarAudit,
  getAuditStructure,
  ReopenInspection,
  FinishInspection,
} from '@dms/teas';

import {TestIdProps, suffixTestId, useQueryState, Nullish} from 'shared';

interface InspectionProps extends TestIdProps {
  vehicleId: string;
  inspectionId: string;
  isCompletionDisabled: boolean;
  isError: boolean;
  onSendEmailSmsNotification: () => void;
  onInspectionDeleted: () => void;
}

export function Inspection(props: InspectionProps) {
  const [activeInspectionTab, setActiveInspectionTab] = useQueryState(
    queryParams.SERVICE_CASE_INSPECTION
  );

  const [auditFetched, setAuditFetched] = useState(false);
  const [deleteInspection, deleteInspectionQuery] = useDeleteInspectionMutation();

  const dispatch = useThunkDispatch();

  const audit = useSelector(selectAudit);
  const {loading} = useSelector(selectCarAudit);

  const isAuditLoading = any(isTrue, [
    loading.getAudit,
    loading.getAuditStructure,
    loading['sales/vehicleDetail/loadCarDetailsHighlightsRequest'],
    loading['sales/vehicleDetail/loadCarDetailsShortcomingsRequest'],
    loading['sales/vehicleDetail/loadCarDetailsVehicleDetailRequest'],
    loading['sales/vehicleDetail/loadPremiumFeatures'],
    loading['vehicleCatalogue/getCustomTenantCatalogue'],
  ]);

  useEffect(() => {
    if (auditFetched) {
      return;
    }

    const fetchAuditStructure = (auditStructure: LoadAuditDataResponseItemBody | Nullish) => {
      if (isNotNil(auditStructure?.controlStructureId)) {
        dispatch(getAuditStructure.action({auditStructureId: auditStructure?.controlStructureId}));
        setAuditFetched(true);
      }
    };

    if (isNotNil(props.vehicleId) && isNotNil(props.inspectionId) && !isAuditLoading) {
      loadVehicleDetailsData(props.vehicleId, dispatch).then(() => {
        dispatch(
          getAudit.action({
            auditId: props.inspectionId,
          })
        )
          .unwrap()
          .then((response) => {
            if (isNotNil(response)) {
              fetchAuditStructure(head(response));
            }
          });
      });
    }
  }, [props.vehicleId, props.inspectionId, auditFetched, isAuditLoading, dispatch]);

  const handleInspectionTabChange = (id: string) => setActiveInspectionTab(id);

  const isAuditFinished = audit?.state === LoadAuditDataResponseItemBody.state.FINISHED_AUDIT;

  useEffect(
    () =>
      // Remove audit when component unmounts
      () => {
        dispatch(updateAuditData({auditData: undefined}));
      },
    [dispatch]
  );

  return (
    <Card title={i18n.t('page.Inspection.labels.title')}>
      <DataStatus isLoading={isAuditLoading} isError={props.isError}>
        <Audit
          activeTab={defaultTo(undefined, activeInspectionTab)}
          onTabChange={handleInspectionTabChange}
          vehicleId={props.vehicleId}
          isInspection
          isBodyOnly
          isCompletionDisabled={props.isCompletionDisabled}
          customFooterContent={
            <HStack spacing={2}>
              <Show when={isAuditFinished}>
                <Space fillAvailable />
                <ReopenInspection
                  handleBackButton={() => setAuditFetched(false)}
                  inspectionId={props.inspectionId}
                />
              </Show>
              <Show when={!isAuditFinished}>
                <Button
                  title={i18n.t('general.actions.remove')}
                  leftIcon="action/delete"
                  variant="dangerGhost"
                  isLoading={deleteInspectionQuery.isLoading}
                  onClick={() =>
                    deleteInspection({inspectionId: props.inspectionId, force: true})
                      .unwrap()
                      .then(() => props.onInspectionDeleted?.())
                      .catch(handleApiError)
                  }
                  data-testid={suffixTestId('remove', props)}
                />
                <Space fillAvailable />
                <FinishInspection
                  buttonTitle={i18n.t('general.actions.complete')}
                  showWhenFinished
                  isDisabled={props.isCompletionDisabled}
                />
              </Show>
            </HStack>
          }
          customHeaderContent={
            <HStack spacing={2}>
              <Button
                title={i18n.t('entity.order.actions.sendEmailSms')}
                variant="outlined"
                isDisabled={!isAuditFinished}
                onClick={props.onSendEmailSmsNotification}
                data-testid={suffixTestId('sendEmailSms', props)}
              />
              <DigitalCertificateLink
                inspectionId={props.inspectionId}
                isDisabled={!isAuditFinished}
              />
            </HStack>
          }
          data-testid={props['data-testid']}
        />
      </DataStatus>
    </Card>
  );
}
