import {omneticApi} from '@dms/api/core';

import {
  CreateConversionRatioApiArg,
  CreateConversionRatioApiResponse,
  CreateConversionTableApiArg,
  CreateConversionTableApiResponse,
  FindConversionRateApiArg,
  FindConversionRateApiResponse,
  FindConversionRatiosApiArg,
  FindConversionRatiosApiResponse,
  FindConversionTablesApiArg,
  FindConversionTablesApiResponse,
  GetConversionRatioApiArg,
  GetConversionRatioApiResponse,
  GetConversionTableApiArg,
  GetConversionTableApiResponse,
  RemoveConversionRatioApiArg,
  RemoveConversionRatioApiResponse,
  RemoveConversionTableApiArg,
  RemoveConversionTableApiResponse,
  UpdateConversionRatioApiArg,
  UpdateConversionRatioApiResponse,
} from './types';

export const moneyApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    createConversionRatio: build.mutation<
      CreateConversionRatioApiResponse,
      CreateConversionRatioApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/conversion-table/${queryArg.conversionTableId}/conversion-ratio`,
        method: 'POST',
        body: queryArg.createConversionRatioRequestBody,
      }),
    }),
    createConversionTable: build.mutation<
      CreateConversionTableApiResponse,
      CreateConversionTableApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/conversion-table`,
        method: 'POST',
        body: queryArg.createConversionTableRequestBody,
      }),
    }),
    findConversionTables: build.query<FindConversionTablesApiResponse, FindConversionTablesApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/conversion-table/${queryArg.domesticCurrency}/${queryArg.foreignCurrency}`,
      }),
    }),
    getConversionRatio: build.query<GetConversionRatioApiResponse, GetConversionRatioApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/conversion-table/${queryArg.conversionTableId}/conversion-ratio/${queryArg.conversionRatioId}`,
      }),
    }),
    removeConversionRatio: build.mutation<
      RemoveConversionRatioApiResponse,
      RemoveConversionRatioApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/conversion-table/${queryArg.conversionTableId}/conversion-ratio/${queryArg.conversionRatioId}`,
        method: 'DELETE',
      }),
    }),
    updateConversionRatio: build.mutation<
      UpdateConversionRatioApiResponse,
      UpdateConversionRatioApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/conversion-table/${queryArg.conversionTableId}/conversion-ratio/${queryArg.conversionRatioId}`,
        method: 'PUT',
        body: queryArg.updateConversionRatioRequestBody,
      }),
    }),
    getConversionTable: build.query<GetConversionTableApiResponse, GetConversionTableApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/conversion-table/${queryArg.conversionTableId}`,
      }),
    }),
    removeConversionTable: build.mutation<
      RemoveConversionTableApiResponse,
      RemoveConversionTableApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/conversion-table/${queryArg.conversionTableId}`,
        method: 'DELETE',
      }),
    }),
    findConversionRatios: build.query<FindConversionRatiosApiResponse, FindConversionRatiosApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/conversion-ratio/${queryArg.domesticCurrency}/${queryArg.foreignCurrency}`,
        params: {type: queryArg.type, validOn: queryArg.validOn},
      }),
    }),
    findConversionRate: build.query<FindConversionRateApiResponse, FindConversionRateApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/money/conversion-rate/${queryArg.domesticCurrency}/${queryArg.foreignCurrency}`,
        params: {validOn: queryArg.validOn},
      }),
    }),
  }),
});

export const {
  useCreateConversionRatioMutation,
  useCreateConversionTableMutation,
  useFindConversionTablesQuery,
  useGetConversionRatioQuery,
  useRemoveConversionRatioMutation,
  useUpdateConversionRatioMutation,
  useGetConversionTableQuery,
  useRemoveConversionTableMutation,
  useLazyFindConversionTablesQuery,
  useLazyFindConversionRatiosQuery,
  useFindConversionRateQuery,
  useLazyFindConversionRateQuery,
} = moneyApi;
