import {DeepPartial} from 'utility-types';

import {VatTypeEnum} from '@dms/api/shared';

import {PriceRequestBody} from '../../../types/PriceRequestBody';
import {PriceValue} from '../../../types/PriceValue';
import {isPriceFromWithVat} from './isPriceFromWithVat';

export const getDefaultPriceRequestBodyForEmptyValue = (
  value: DeepPartial<PriceValue> | null | undefined,
  defaultCurrency: string
): PriceRequestBody => {
  const price = isPriceFromWithVat(value) ? value?.withVat : value?.withoutVat;
  return {
    isWithVat: isPriceFromWithVat(value),
    vatType: value?.vatType || VatTypeEnum.S,
    price: {
      amount: price?.amount || '0',
      currency: price?.currency || defaultCurrency,
    },
  };
};
