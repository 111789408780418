import {isEmpty, isNil} from 'ramda';

import {DrillDownLevelName, DrillDownStatistics} from '@dms/api/sourcing';

import {Nullish} from 'shared';

export function isRowSelected(
  data: DrillDownStatistics,
  selectedRow: Partial<Record<DrillDownLevelName, string | Nullish>> | Nullish
) {
  if (isNil(selectedRow) || isEmpty(selectedRow)) {
    return false;
  }

  return data.levels.findIndex((item) => item.value !== selectedRow[item.name]) < 0;
}
