import {EntityResourceIds} from '@dms/api/shared';
import i18n from '@dms/i18n';

export type CustomFieldsResource = {resourceId: EntityResourceIds; label: string};

export const CUSTOM_FIELDS_RESOURCES = [
  {resourceId: EntityResourceIds.vehicle, label: i18n.t('entity.vehicle.labels.vehicle')},
  {resourceId: EntityResourceIds.customer, label: i18n.t('entity.customer.labels.customer')},
  {resourceId: EntityResourceIds.interest, label: i18n.t('entity.interest.labels.interest')},
  {resourceId: EntityResourceIds.businessCase, label: i18n.t('general.labels.businessCase')},
  {resourceId: EntityResourceIds.serviceCase, label: i18n.t('page.workshop.labels.serviceCases')},
] satisfies CustomFieldsResource[];
