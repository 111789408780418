import {Label} from 'platform/components';
import {Heading, VStack} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {CurrencyCodeType} from 'shared';

interface AmountDisplayProps {
  label?: string;
  amount: number;
  currency: CurrencyCodeType;
}

export function AmountDisplay(props: AmountDisplayProps) {
  const formatCurrency = useFormatCurrency();

  return (
    <VStack>
      <Label>{props.label}</Label>
      <Heading size={3}>{formatCurrency(props.amount, props.currency, 2)}</Heading>
    </VStack>
  );
}
