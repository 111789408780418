import {differenceInMilliseconds} from 'date-fns';
import {match, Pattern} from 'ts-pattern';

import {BigCalendarEvent} from '../types/BigCalendarEvent';
import {BigCalendarEventWithLayout} from '../types/BigCalendarEventWithLayout';

/**
 * Calculates the layout for a list of scheduler events.
 * It determines the left position and width for each event to handle overlapping cases.
 * The algorithm works in several steps:
 * 1. Sorts events by start time and duration
 * 2. Groups overlapping events together
 * 3. Calculates layout properties for each event based on its group size
 *
 * @param {BigCalendarEvent[]} events - Array of events to calculate layout for
 * @returns {BigCalendarEventWithLayout[]} Array of events with computed layout properties (left position and width)
 */
export function calculateEventLayout(events: BigCalendarEvent[]): BigCalendarEventWithLayout[] {
  if (!events.length) {
    return [];
  }

  /**
   * Sort events primarily by start time, and secondarily by duration.
   * Events starting earlier appear first. For events with same start time,
   * longer events are prioritized to ensure better visual layout.
   *
   * @param {BigCalendarEvent} firstEvent - First event to compare
   * @param {BigCalendarEvent} secondEvent - Second event to compare
   * @returns {number} Comparison result (-1, 0, or 1)
   */
  const sortedEvents = [...events].sort((firstEvent, secondEvent) => {
    const startTimeDifference = differenceInMilliseconds(firstEvent.start, secondEvent.start);
    if (startTimeDifference !== 0) {
      return startTimeDifference;
    }
    return differenceInMilliseconds(
      differenceInMilliseconds(secondEvent.end, secondEvent.start),
      differenceInMilliseconds(firstEvent.end, firstEvent.start)
    );
  });

  /**
   * Storage for groups of overlapping events
   * Each inner array contains events that overlap in time
   */
  const eventGroups: BigCalendarEvent[][] = [];

  /**
   * Tracks which events have been assigned to groups to prevent duplicates
   */
  const assignedEvents = new Set<BigCalendarEvent>();

  /**
   * Groups overlapping events by checking their time intersections.
   * An event overlaps with another if:
   * - It starts during another event
   * - It ends during another event
   * - It completely encompasses another event
   */
  sortedEvents.forEach((event) => {
    if (assignedEvents.has(event)) {
      return;
    }

    const group = sortedEvents.filter(
      (e) =>
        !assignedEvents.has(e) &&
        ((e.start >= event.start && e.start < event.end) || // e starts during event
          (e.end > event.start && e.end <= event.end) || // e ends during event
          (e.start <= event.start && e.end >= event.end)) // e completely overlaps event
    );

    group.forEach((e) => assignedEvents.add(e));
    if (group.length > 0) {
      eventGroups.push(group);
    }
  });

  const eventsWithLayout: BigCalendarEventWithLayout[] = [];

  /**
   * Process each group of overlapping events to calculate their layout properties.
   * Events are positioned based on their group size:
   * - Single event: Takes full width
   * - Two events: Split width in half
   * - Three events: Cascading layout with reduced width
   * - Four or more: Cascading layout with further reduced width
   */
  eventGroups.forEach((group) => {
    const totalEvents = group.length;

    /**
     * Sort events within group by start time (oldest first)
     * This ensures older events get priority in positioning
     */
    const sortedByDuration = [...group].sort((firstEvent, secondEvent) =>
      differenceInMilliseconds(firstEvent.start, secondEvent.start)
    );

    sortedByDuration.forEach((event, index) => {
      const {leftPosition, eventWidth} = match([totalEvents, index])
        .with([1, Pattern.any], () => ({leftPosition: 0, eventWidth: FOUR_QUARTER_WIDTH}))
        .with([2, 0], () => ({leftPosition: 0, eventWidth: THREE_QUARTERS_WIDTH}))
        .with([2, 1], () => ({
          leftPosition: TWO_QUARTERS_WIDTH,
          eventWidth: TWO_QUARTERS_WIDTH,
        }))
        .with([3, 0], () => ({
          leftPosition: 0,
          eventWidth: THREE_QUARTERS_WIDTH,
        }))
        .with([3, 1], () => ({
          leftPosition: ONE_QUARTER_WIDTH,
          eventWidth: THREE_QUARTERS_WIDTH,
        }))
        .with([3, 2], () => ({
          leftPosition: TWO_QUARTERS_WIDTH,
          eventWidth: TWO_QUARTERS_WIDTH,
        }))
        .with([4, 0], () => ({
          leftPosition: 0,
          eventWidth: TWO_QUARTERS_WIDTH,
        }))
        .with([4, 1], () => ({
          leftPosition: 20,
          eventWidth: TWO_QUARTERS_WIDTH,
        }))
        .with([4, 2], () => ({
          leftPosition: 35,
          eventWidth: TWO_QUARTERS_WIDTH,
        }))
        .with([4, 3], () => ({
          leftPosition: 50,
          eventWidth: TWO_QUARTERS_WIDTH,
        }))
        .otherwise(() => ({
          leftPosition: index < MAX_VISIBLE_EVENTS ? index * FIVE_EVENTS_OFFSET : 10,
          eventWidth: FIVE_AND_MORE_EVENTS_WIDTH,
        }));

      eventsWithLayout.push({
        ...event,
        left: leftPosition,
        width: eventWidth,
      });
    });
  });

  return eventsWithLayout.sort((firstEvent, secondEvent) =>
    differenceInMilliseconds(firstEvent.start, secondEvent.start)
  );
}

/**
 * Layout constants for scheduler events (in percentage units)
 * These values determine the positioning and width of events based on overlap count
 *
 * @constant {number} FULL_WIDTH - Width for a single non-overlapping event (100%)
 * @constant {number} TWO_EVENTS_WIDTH - Width when two events overlap (50%)
 * @constant {number} THREE_EVENTS_WIDTH - Width when three events overlap (40%)
 * @constant {number} THREE_EVENTS_OFFSET - Horizontal offset for three overlapping events (30%)
 * @constant {number} FOUR_AND_MORE_EVENTS_WIDTH - Width for four or more overlapping events (40%)
 * @constant {number} FOUR_EVENTS_OFFSET - Horizontal offset for four or more overlapping events (20%)
 * @constant {number} MAX_VISIBLE_EVENTS - Maximum number of events shown side by side (4)
 */
export const FOUR_QUARTER_WIDTH = 100;
export const THREE_QUARTERS_WIDTH = 75;
export const TWO_QUARTERS_WIDTH = 50;
export const ONE_QUARTER_WIDTH = 25;
export const FIVE_AND_MORE_EVENTS_WIDTH = 40;
export const FIVE_EVENTS_OFFSET = 20;
export const MAX_VISIBLE_EVENTS = 4;
