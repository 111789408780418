import {openDeleteDialog} from 'platform/components';
import {match} from 'ts-pattern';

import {always} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {
  useDeleteLabourCatalogCategoryMutation,
  usePostLabourCatalogCategorySetAsInactiveMutation,
  usePostLabourCatalogCategorySetAsActiveMutation,
} from '@dms/api/metadaWorkshopLabourCatalog';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function LabourCatalogues() {
  const navigate = useNavigate();

  const [deleteLabourCatalog] = useDeleteLabourCatalogCategoryMutation();
  const [postLabourCatalogCategorySetAsActive] = usePostLabourCatalogCategorySetAsActiveMutation();
  const [postLabourCatalogCategorySetAsInactive] =
    usePostLabourCatalogCategorySetAsInactiveMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData}) => {
    const id = isArray(rowId) ? rowId[0] : rowId;

    match(actionKey)
      .with('redirectDetail', 'edit', () =>
        navigate(composePath(settingsRoutes.labourCataloguesDetail, {params: {id}}))
      )
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () =>
            deleteLabourCatalog({labourCatalogCategoryId: id})
              .unwrap()
              .then(refreshData)
              .catch(handleApiError),
        })
      )
      .with('setAsActive', () =>
        postLabourCatalogCategorySetAsActive({labourCatalogId: id})
          .unwrap()
          .then(refreshData)
          .catch(handleApiError)
      )
      .with('setAsInactive', () =>
        postLabourCatalogCategorySetAsInactive({labourCatalogId: id})
          .unwrap()
          .then(refreshData)
          .catch(handleApiError)
      )
      .otherwise(always(undefined));
  };

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('entity.workshop.labels.labourCatalogues'),
        actions: [
          {
            type: 'button',
            title: i18n.t('entity.workshop.actions.newLabourCatalogue'),
            onClick: () => navigate(settingsRoutes.labourCataloguesCreate),
          },
        ],
      }}
      data-testid={testIds.settings.labourCatalogues('template')}
    >
      <DataGrid gridCode="labour-catalog" actionCallback={actionCallback} />
    </SettingsTemplate>
  );
}
