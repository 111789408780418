import {Button, openConfirmDialog, openDialog, showNotification} from 'platform/components';
import {Heading, HStack, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {mergeAll} from 'ramda';

import {usePostStorageLocationDeleteApiMutation} from '@dms/api/metadaStorageLocation';
import i18n from '@dms/i18n';
import {catchUnhandledDataGridActions, CreateTireLocation, handleApiError} from '@dms/shared';

import {
  ActionCallbackParams,
  DataGrid,
  QueryFilterObject,
  refreshDatagrid,
} from 'features/datagrid';

import {CreateMultipleLocations} from './CreateMultipleLocations';
import {NotesDialog} from './NotesDialog';
import {SingleLocationDetail} from './SingleLocationDetail';

interface RowDataNote {
  style: unknown[];
  value: string;
}

interface StorageLocationsProps {
  tireWarehouseId: string;
}

export function StorageLocations(props: StorageLocationsProps) {
  const [deleteStorageLocation] = usePostStorageLocationDeleteApiMutation();

  const queryModifier = (filter: QueryFilterObject) =>
    mergeAll([filter, {tireWarehouse: props.tireWarehouseId}]);

  const actionCallback = (params: ActionCallbackParams) => {
    const saneIds = Array.isArray(params.rowId) ? params.rowId : [params.rowId];

    match(params.actionKey)
      .with('note', () => {
        const castedRowData = Array.isArray(params.rowData) ? params.rowData[0] : params.rowData;
        const noteData = (castedRowData.note as RowDataNote).value;

        openDialog(<NotesDialog rowIds={saneIds} defaultNote={noteData} />, {
          title: i18n.t('general.actions.addNote'),
          id: 'storage-locations-notes-dialog',
        });
      })
      .with('delete', () => {
        openConfirmDialog({
          onConfirm: () => {
            deleteStorageLocation({
              body: {
                storageLocationIds: saneIds,
              },
            })
              .unwrap()
              .then(() => {
                showNotification.success();
                refreshDatagrid('storage-location-tire-warehouse');
              })
              .catch(handleApiError);
          },
        });
      })
      .with('redirectDetail', () => {
        const castedRowData = Array.isArray(params.rowData) ? params.rowData[0] : params.rowData;
        const storageLocationId = castedRowData.id as string;

        openDialog(
          <SingleLocationDetail
            storageLocationId={storageLocationId}
            dialogId="single-location-detail-dialog"
          />,
          {
            title: i18n.t('entity.tireWarehouses.labels.editStorageLocation'),
            id: 'single-location-detail-dialog',
          }
        );
      })
      .otherwise(() => catchUnhandledDataGridActions(params.actionKey));
  };

  return (
    <VStack spacing={7}>
      <HStack width="100%" justify="space-between" align="center">
        <Heading size={4}>{i18n.t('entity.tireWarehouses.labels.storageLocations')}</Heading>
        <HStack spacing={2}>
          <Button
            title={i18n.t('entity.tireWarehouses.labels.createSingleLocation')}
            variant="secondary"
            onClick={() =>
              openDialog(
                <CreateTireLocation
                  tireWarehouseId={props.tireWarehouseId}
                  dialogId="create-single-location-dialog"
                  onSuccess={() => refreshDatagrid('storage-location-tire-warehouse')}
                />,
                {
                  title: i18n.t('entity.tireWarehouses.labels.createSingleLocation'),
                  id: 'create-single-location-dialog',
                }
              )
            }
          />
          <Button
            title={i18n.t('entity.tireWarehouses.labels.createMultipleLocation')}
            variant="secondary"
            onClick={() =>
              openDialog(<CreateMultipleLocations tireWarehouseId={props.tireWarehouseId} />, {
                title: i18n.t('entity.tireWarehouses.labels.createMultipleLocation'),
                id: 'create-multiple-locations-dialog',
              })
            }
          />
        </HStack>
      </HStack>
      <DataGrid
        gridCode="storage-location-tire-warehouse"
        queryModifier={queryModifier}
        actionCallback={actionCallback}
        autoHeight
      />
    </VStack>
  );
}
