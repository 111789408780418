import {useNavigate} from 'react-router-dom';

import {testIds, vehiclesRoutes} from '@dms/routes';

import {composePath} from 'shared';

import {RentalVehicleForm} from '../../components/RentalVehicleForm/RentalVehicleForm';
import {RentalVehicleFormMode} from '../../components/RentalVehicleForm/types/RentalVehicleFormMode';

export function NewRentalVehicle() {
  const navigate = useNavigate();

  return (
    <RentalVehicleForm
      mode={RentalVehicleFormMode.NEW}
      onDiscard={() => navigate(-1)}
      onSuccess={(newVehicleId) =>
        navigate(composePath(vehiclesRoutes.detail, {params: {id: newVehicleId}}))
      }
      data-testid={testIds.vehicles.createRentalVehicle('create')}
    />
  );
}
