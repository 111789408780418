import {Button, FormControl, FormField, IconButton} from 'platform/components';
import {Align, Box, HStack} from 'platform/foundation';

import {ArrayPath, FieldValues, Path, useFieldArray, UseFormReturn} from 'react-hook-form';

import {any} from 'ramda';
import {isNilOrEmpty, lengthLt} from 'ramda-adjunct';

import {PhoneNumberData} from '@dms/api/customer';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {usePhoneNumbers} from '../../hooks/usePhoneNumbers';
import {useTenant} from '../../hooks/useTenant';

type PhoneNumberListProps<TFieldValues extends FieldValues = FieldValues> = {
  control: FormControl<TFieldValues>;
  formApi: UseFormReturn<TFieldValues>;
  name: ArrayPath<TFieldValues>;
  isRequired?: boolean;
  isDisabled?: boolean;
} & TestIdProps;

export function PhoneNumberList<TFieldValues extends FieldValues = FieldValues>(
  props: PhoneNumberListProps<TFieldValues>
) {
  const {countriesOptions} = usePhoneNumbers();

  const {fields, remove, append} = useFieldArray({
    control: props.control,
    name: props.name,
  });

  const {tenantPhoneInfo} = useTenant();
  const emptyPhoneNumber: PhoneNumberData = {
    type: null,
    phoneNumber: {
      countryCode: tenantPhoneInfo.countryCode,
      prefix: tenantPhoneInfo.prefix,
      number: '',
    },
  };

  const isAddAnotherDisabled = any((item: PhoneNumberData) =>
    isNilOrEmpty(item.phoneNumber.number)
  )(props.formApi.watch(props.name as Path<TFieldValues>));

  return (
    <>
      {fields.map((field, index) => (
        <HStack key={field.id} spacing={4}>
          <Box flex={1}>
            <FormField
              control={props.control}
              type="phone"
              countries={countriesOptions}
              isRequired={props.isRequired}
              isDisabled={props.isDisabled}
              name={`${props.name}.${index}.phoneNumber` as Path<TFieldValues>}
              label={i18n.t('entity.phoneNumber.labels.number')}
              data-testid={suffixTestId(`phoneNumber-[${index}]`, props)}
            />
          </Box>
          <Box flex={1}>
            <HStack spacing={4} align="flex-end">
              <Box flex={1}>
                <FormField
                  control={props.control}
                  type="text"
                  isDisabled={props.isDisabled}
                  name={`${props.name}.${index}.type` as Path<TFieldValues>}
                  label={i18n.t('entity.phoneNumber.labels.type')}
                  data-testid={suffixTestId(`phoneNumberType-[${index}]`, props)}
                />
              </Box>
              <IconButton
                icon="action/delete"
                isDisabled={lengthLt(2, fields)}
                severity="danger"
                onClick={() => remove(index)}
                data-testid={suffixTestId(`phoneNumberRemove-[${index}]`, props)}
              />
            </HStack>
          </Box>
        </HStack>
      ))}
      <Align left>
        <Button
          leftIcon="content/add_circle"
          title={i18n.t('general.actions.addAnother')}
          size="small"
          variant="link"
          isDisabled={isAddAnotherDisabled}
          onClick={() => append(emptyPhoneNumber as any)}
          data-testid={suffixTestId('phoneNumberAddAnother', props)}
        />
      </Align>
    </>
  );
}
