import {isFeatureEnabled} from 'feature-flags';

import featureFlags from '@dms/feature-flags';
import {Section, ActivityLogs} from '@dms/shared';

export function ActivityLog() {
  const gridCode = isFeatureEnabled(featureFlags.SALE_INTEREST_ACTION_LOG)
    ? 'interest-audit-log'
    : 'interest-action-log';

  return (
    <Section>
      <ActivityLogs code={gridCode} />
    </Section>
  );
}
