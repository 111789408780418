import {Box, Heading, HStack} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {useCallback} from 'react';

import {mergeAll, defaultTo} from 'ramda';

import {GetIssueNoteResponse} from '@dms/api/metadaWarehouseIssueNote';
import i18n from '@dms/i18n';
import {DEFAULT_CURRENCY} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {DataGrid, QueryFilterObject} from 'features/datagrid';

import {useWarehouseParams} from '../../../../../hooks/useWarehouseParams';

interface ListOfItemsTabProps extends RequiredTestIdProps {
  issueNote: GetIssueNoteResponse | undefined;
}

export function ListOfItemsTab(props: ListOfItemsTabProps) {
  const {issueNoteId} = useWarehouseParams();
  const currencyFormatter = useFormatCurrency();

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {issueNoteId}]),
    [issueNoteId]
  );

  const totalValue = currencyFormatter(
    defaultTo(0, props.issueNote?.summaryInformation.totalValue.amount),
    defaultTo(DEFAULT_CURRENCY, props.issueNote?.summaryInformation.totalValue.currency),
    2
  );

  return (
    <Box height="100%">
      <Box height="95%">
        <DataGrid
          gridCode="warehouse-issue-note-items"
          queryModifier={queryModifier}
          data-testid={suffixTestId('issuedItems', props)}
        />
      </Box>
      <HStack minHeight={15} align="center" justify="space-between">
        <Heading size={5}>{i18n.t('general.labels.totalValue')}</Heading>
        <Heading size={5}>{totalValue}</Heading>
      </HStack>
    </Box>
  );
}
