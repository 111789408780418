import {Card, DataStatus, Flag, Percentage, Separator, Status} from 'platform/components';
import {HStack, Heading, Show, Space, Text} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {isNil} from 'ramda';

import {VehicleTypeEnumObject} from '@dms/api/shared';
import {useSourcingGetCarFeaturesQuery} from '@dms/api/sourcing';
import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';

import {RequiredTestIdProps, parseDate, suffixTestId} from 'shared';

import {RentalVehicleFormType} from '../../../types/RentalVehicleFormType';
import {useFeatureAnalysis} from '../hooks/useFeatureAnalysis';

interface FeatureMarketAnalysisProps extends RequiredTestIdProps {
  formApi: UseFormReturn<RentalVehicleFormType>;
  isLoading: boolean;
}

export function FeatureMarketAnalysis(props: FeatureMarketAnalysisProps) {
  const [make, model, year, features, vehicleType] = props.formApi.watch([
    'make',
    'modelFamily',
    'firstRegistrationOn',
    'features',
    'type',
  ]);

  const sourcingCarQuery = useSourcingGetCarFeaturesQuery(
    {
      body: {
        make: make || '',
        model: model || '',
        year: parseDate(year)?.getFullYear() || 0,
        features: features || [],
      },
    },
    {
      skip: isNil(make) || isNil(model) || isNil(year),
    }
  );

  const {highlightedItems, premiumItems} = useFeatureAnalysis(
    vehicleType.at(0) as VehicleTypeEnumObject,
    sourcingCarQuery.data
  );

  const featureScore = sourcingCarQuery.data?.featuresScore;
  const percentage = isNil(featureScore) ? 0 : Math.floor(featureScore * 100);

  return (
    <Card title={i18n.t('entity.vehicle.labels.featureMarketAnalysis')} isExpandable>
      <DataStatus
        isLoading={sourcingCarQuery.isLoading || props.isLoading}
        isError={sourcingCarQuery.isError}
      >
        <Heading size={5}>{i18n.t('entity.vehicle.labels.featureScore')}</Heading>
        <Text size="xSmall" color="tertiary">
          {i18n.t('entity.vehicle.labels.featureScoreSubtitle')}
        </Text>
        <Percentage percentage={percentage} data-testid={suffixTestId('featureScore', props)} />
        <Separator />
        <Show whenFeatureEnabled={featureFlags.VEHICLE_PREMIUM_FEATURES}>
          <Heading size={5}>{i18n.t('entity.vehicle.labels.premium')}</Heading>
          <Text size="xSmall" color="tertiary">
            {i18n.t('entity.vehicle.labels.premiumSubtitle')}
          </Text>
          <Space vertical={4} />
          <Show when={premiumItems.length}>
            <HStack spacing={2} data-testid={suffixTestId('premiumItems', props)} wrap>
              {premiumItems.map((item) => (
                <Flag
                  key={item.const_key}
                  colorScheme="blue"
                  label={item.labels[0].label}
                  data-testid={suffixTestId('premiumItem', props)}
                />
              ))}
            </HStack>
          </Show>
          <Show when={!premiumItems.length}>
            <Status
              headline={i18n.t('entity.vehicle.labels.noPremiumFeaturesToShow')}
              data-testid={suffixTestId('noPremium', props)}
            />
          </Show>
          <Separator />
        </Show>
        <Heading size={5}>{i18n.t('entity.vehicle.labels.highlight')}</Heading>
        <Text size="xSmall" color="tertiary">
          {i18n.t('entity.vehicle.labels.highlightSubtitle')}
        </Text>
        <Space vertical={4} />
        <Show when={highlightedItems.length}>
          <HStack spacing={2} data-testid={suffixTestId('highlightedItems', props)} wrap>
            {highlightedItems.map((item) => (
              <Flag
                key={item.const_key}
                label={item.labels[0].label}
                colorScheme="blue"
                data-testid={suffixTestId('highlightedItem', props)}
                isSubtle
              />
            ))}
          </HStack>
        </Show>
        <Show when={!highlightedItems.length}>
          <Status
            headline={i18n.t('entity.vehicle.labels.noFeaturesToShow')}
            data-testid={suffixTestId('noHighlighted', props)}
          />
        </Show>
      </DataStatus>
    </Card>
  );
}
