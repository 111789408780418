import {
  Button,
  Checkboxes,
  DataStatus,
  openConfirmDialog,
  openDialog,
  Separator,
  showNotification,
} from 'platform/components';
import {Box, Hide, HStack, VStack} from 'platform/foundation';

import {useState} from 'react';

import {isNilOrEmpty} from 'ramda-adjunct';

import {
  useAccPostMatrixConfigInitializeMutation,
  useGetAccountingDictionaryQuery,
  useMatricesConfigCleanupMutation,
  useMoveMatricesMutation,
} from '@dms/api/metadaAccounting';
import {GetCategoryApiArg, GetCategoryApiRes} from '@dms/api/metadaErrorLogs';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {ConvertFromCebiaForm} from './components/ConvertFromCebiaForm';

export function MatrixList(props: GetCategoryApiArg) {
  const {data: matrices, isLoading, isError} = useGetAccountingDictionaryQuery(props);
  const [moveMatrices, {isLoading: isMoving}] = useMoveMatricesMutation();
  const [cleanUpMatrices, {isLoading: isCleaningUp}] = useMatricesConfigCleanupMutation();
  const [initializeConfiguration, {isLoading: isInitializing}] =
    useAccPostMatrixConfigInitializeMutation();

  const [checkboxesValue, setCheckboxesValue] = useState<string[] | null>(null);

  const checkboxOptions = (matrices ?? []).map(matrixToOption);

  const handleConvertFromCaris = () =>
    openDialog(
      <ConvertFromCebiaForm
        data-testid={testIds.settings.accountingMatrixManagement(
          `${props.category}-covertFromCebiaForm`
        )}
      />,
      {
        title: i18n.t('page.accountingSettings.labels.covertFromCaris'),
      }
    );

  const handleMovement = () =>
    openConfirmDialog({
      text: i18n.t('page.accountingSettings.labels.carisFormatDescription'),
      onConfirm: () =>
        moveMatrices({category: props.category, tables: checkboxesValue ?? []})
          .unwrap()
          .then(() => showNotification.success())
          .then(() => setCheckboxesValue([]))
          .catch(handleApiError),
    });

  const handleCleanUp = () =>
    cleanUpMatrices({tables: checkboxesValue ?? []})
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

  const handleInitializeConfiguration = () =>
    initializeConfiguration()
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

  return (
    <VStack align="flex-start">
      <Box width="100%" paddingVertical={6}>
        <DataStatus isLoading={isLoading} isError={isError}>
          <Checkboxes
            options={checkboxOptions}
            value={checkboxesValue}
            onChange={setCheckboxesValue}
            direction="vertical"
            data-testid={testIds.settings.accountingMatrixManagement(
              `${props.category}-checkboxes`
            )}
          />
        </DataStatus>
      </Box>

      <HStack spacing={2}>
        <Button
          onClick={handleMovement}
          variant="secondary"
          isLoading={isMoving}
          title={getButtonLabel(props)}
          isDisabled={isNilOrEmpty(checkboxesValue)}
          data-testid={testIds.settings.accountingMatrixManagement(`${props.category}-transfer`)}
        />

        <Hide when={isGoogleSheetList(props.category)}>
          <Button
            onClick={handleCleanUp}
            variant="secondary"
            isLoading={isCleaningUp}
            title={i18n.t('page.accountingSettings.labels.cleanUp')}
            isDisabled={isNilOrEmpty(checkboxesValue)}
            data-testid={testIds.settings.accountingMatrixManagement(`${props.category}-transfer`)}
          />
        </Hide>
      </HStack>

      <Separator />
      {isGoogleSheetList(props.category) ? (
        <Button
          title={i18n.t('page.accountingSettings.labels.covertFromCaris')}
          onClick={handleConvertFromCaris}
          variant="secondary"
          data-testid={testIds.settings.accountingMatrixManagement(
            `${props.category}-covertFromCaris`
          )}
        />
      ) : (
        <Button
          title={i18n.t('page.accountingSettings.labels.initializeConfiguration')}
          onClick={handleInitializeConfiguration}
          isLoading={isInitializing}
          variant="secondary"
          data-testid={testIds.settings.accountingMatrixManagement(
            `${props.category}-initializeConfiguration`
          )}
        />
      )}
    </VStack>
  );
}

const matrixToOption = (val: GetCategoryApiRes[number]) => ({
  value: val.code ?? '',
  label: val.name ?? '',
});

const isGoogleSheetList = (category: GetCategoryApiArg['category']) =>
  category === 'matrices-config-from-google-to-metada';

const getButtonLabel = (val: GetCategoryApiArg) =>
  isGoogleSheetList(val.category)
    ? i18n.t('page.accountingSettings.labels.transferFromGoogleToMetada')
    : i18n.t('page.accountingSettings.labels.transferFromMetadaToGoogle');
