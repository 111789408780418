import {showNotification} from 'platform/components';

import {getApiErrorMessages} from '@dms/api/utils';

import {ApiError} from 'shared';

import {getApiValidationErrors} from './getApiValidationErrors';

type ValidationErrors = ReturnType<typeof getApiValidationErrors>;

type OptionsCallback = {
  error: ApiError;
  validationErrors: ValidationErrors;
};

export type ApiErrorOptions = {
  silent?: boolean;
  silentOnValidationError?: boolean;
  callback?: (args: OptionsCallback) => void;
  setErrors?: (errors: ValidationErrors) => void;
};

/**
 * @about Handle errors by sending them to sentry and pops a toast with common error message.
 */
export function handleApiError(error: ApiError, options?: ApiErrorOptions) {
  const validationErrors = getApiValidationErrors(error);

  options?.callback?.({error, validationErrors});

  options?.setErrors?.(validationErrors);

  if (options?.silent || (options?.silentOnValidationError && !!validationErrors)) {
    return;
  }

  showNotification.error(getApiErrorMessages(error));
}
