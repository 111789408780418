import {match} from 'ts-pattern';

import {always} from 'ramda';

import {SentToastNotificationResponseBody} from '@dms/api/messaging';
import i18n from '@dms/i18n';
import {notificationTypes} from '@dms/shared';

export const getNotificationText = (notification: SentToastNotificationResponseBody) =>
  match<string, string | undefined>(notification.type)
    .with(
      notificationTypes.SALE_VEHICLE_PHOTO_ROTATE_FINISHED,
      always(i18n.t('entity.photo.notifications.rotationSuccess'))
    )
    .with(
      notificationTypes.SALE_VEHICLE_PHOTO_COPY_FINISHED,
      always(i18n.t('entity.photo.notifications.copySuccess'))
    )
    .otherwise(always(undefined));
