import {
  Action,
  DataStatus,
  FormControl,
  openDeleteDialog,
  showNotification,
} from 'platform/components';
import {useFormatCurrency} from 'platform/locale';

import {UseFormReturn, useWatch} from 'react-hook-form';
import {useDispatch} from 'react-redux';

import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  interestApi,
  UpdateMinisaleApiArg,
  useDeleteMinisaleMutation,
  useInterestCatalogueQuery,
  useUpdateMinisaleMutation,
} from '@dms/api/interest';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {handleApiError, usePricePermissions, VehicleWidgetCard} from '@dms/shared';

import {buildArray, handleFormReset, Nullish, RequiredTestIdProps} from 'shared';

import {useInterestData} from '../../../hooks/useInterestData';
import {BuyingFormType} from '../types/BuyingFormType';
import {transformBuyingFormToMinisale} from '../utils/transformBuyingFormToMinisale';

interface BuyingVehicleWidgetProps extends RequiredTestIdProps {
  interestId: string;
  control: FormControl<BuyingFormType>;
  formApi: UseFormReturn<BuyingFormType>;
}

export function BuyingVehicleWidget(props: BuyingVehicleWidgetProps) {
  const dispatch = useDispatch();

  const formatPrice = useFormatCurrency();
  const {interest, miniSale, isLoading, isError} = useInterestData(props.interestId);

  const [deleteMiniSale, {isLoading: isDeleteMinisaleLoading}] = useDeleteMinisaleMutation();
  const [updateMiniSale, {isLoading: isMinisaleLoading}] = useUpdateMinisaleMutation();

  const {
    data: interestCatalogue,
    isLoading: isInterestCatalogueLoading,
    isError: isInterestCatalogueErrored,
  } = useInterestCatalogueQuery();

  const {canViewAnyOfVehiclePriceFields: canReadPrices} = usePricePermissions({
    vehicleRecordId: null,
    businessCaseRecordId: null,
  });

  const buyingForm = useWatch({control: props.control});
  const miniSaleForm = transformBuyingFormToMinisale(buyingForm as BuyingFormType);

  const onUpdateInterestRate = (values: string[] | Nullish) => {
    if (!miniSaleForm || !miniSale?.id) {
      showNotification.error('missing miniSale object');
      return;
    }

    const arg: UpdateMinisaleApiArg = {
      updateMinisaleRequestBody: {
        ...miniSaleForm,
        customerBuyingStatement: miniSale.customerBuyingStatement ?? false,
        minisaleId: miniSale.id,
        interestId: props.interestId,
        interestRate: isNotNilOrEmpty(values) ? parseInt(String(values![0]), 10) : null,
      },
    };

    updateMiniSale(arg)
      .unwrap()
      .then(() => {
        dispatch(interestApi.util.invalidateTags([{type: 'miniSale', id: miniSale.id}]));
      })
      .catch(handleApiError);
  };

  return (
    <DataStatus
      isLoading={isLoading || isInterestCatalogueLoading || isMinisaleLoading}
      isError={isError || isInterestCatalogueErrored}
      minHeight={21}
    >
      <VehicleWidgetCard
        data-testid={props['data-testid']}
        vehicles={[
          {
            id: miniSale?.id || miniSaleForm?.minisaleId || 'empty-minisale-id',
            vin: miniSaleForm?.vehicleData?.vin,
            registrationPlate: miniSaleForm?.vehicleData?.licencePlate,
            firstRegistration: miniSaleForm?.vehicleData?.realProductionYear,
            make: miniSaleForm?.vehicleData?.make,
            vehicleType: miniSaleForm?.vehicleData?.type?.toString(),
            modelFamily: miniSaleForm?.vehicleData?.modelFamily,
            trim: miniSaleForm?.vehicleData?.trim,
            variant: miniSaleForm?.vehicleData?.variant,
            mileage: miniSaleForm?.vehicleData?.mileage,
            transmission: miniSaleForm?.vehicleData?.transmission,
            fuelType: miniSaleForm?.vehicleData?.fuelType,
            bodyStyle: miniSaleForm?.vehicleData?.bodyStyle,
            driveType: miniSaleForm?.vehicleData?.drive,
            firstParameter:
              canReadPrices && miniSaleForm?.priceOfferedWithoutVat && miniSaleForm?.isVatDeductible
                ? formatPrice(
                    Number(miniSaleForm.priceOfferedWithoutVat.amount),
                    miniSaleForm.priceOfferedWithoutVat.currency,
                    0
                  )
                : null,
            secondParameter:
              canReadPrices && miniSaleForm?.priceOfferedWithVat
                ? formatPrice(
                    Number(miniSaleForm.priceOfferedWithVat.amount),
                    miniSaleForm.priceOfferedWithVat.currency,
                    0
                  )
                : null,
            actions: buildArray<Action>()
              .when(isNotNilOrEmpty(interestCatalogue?.interestRate), {
                'data-testid': testIds.interest.detail(`buying-updateInterestRate`),
                type: 'chips',
                value: [String(miniSale?.interestRate ?? '')],
                options:
                  interestCatalogue?.interestRate?.map((catalogueItem) => ({
                    label: catalogueItem.name,
                    value: catalogueItem.key,
                  })) ?? [],
                isDeselectable: true,
                isDisabled: isNilOrEmpty(miniSale),
                onChange: onUpdateInterestRate,
              })
              .add({
                'data-testid': testIds.interest.detail('buying-delete'),
                type: 'button',
                leftIcon: 'action/delete',
                variant: 'outlined',
                title: i18n.t('general.labels.delete'),
                isDisabled:
                  isDeleteMinisaleLoading ||
                  !miniSale?.id ||
                  interest?.state === 'UNSUCCESSFUL' ||
                  interest?.state === 'CLOSED',
                onClick: () =>
                  openDeleteDialog({
                    'data-testid': testIds.interest.detail('buying-delete'),
                    onConfirm: () =>
                      deleteMiniSale({
                        minisaleId: miniSale!.id!,
                        interestId: props.interestId,
                      })
                        .unwrap()
                        .then(() => {
                          handleFormReset<BuyingFormType>(props.formApi, [
                            {name: 'priceRequested', value: null},
                            {name: 'priceOfferedBase', value: null},
                            {name: 'priceOfferedWithVat', value: null},
                            {name: 'priceOfferedWithoutVat', value: null},
                            {name: 'expectedCosts', value: null},
                            {name: 'reason', value: null},
                            {name: 'costReason', value: null},
                            {name: 'isVatDeductible', value: null},
                            {name: 'interestRate', value: null},
                            {
                              name: 'vehicleData',
                              value: {
                                type: ['VEHICLETYPE_PASSENGER_CAR'],
                                vin: null,
                                make: null,
                                note: null,
                                modelFamily: null,
                                trim: null,
                                variant: null,
                                fuelType: null,
                                transmission: null,
                                bodyStyle: null,
                                drive: null,
                                firstRegistration: null,
                                realProductionYear: null,
                                mileage: null,
                                secondaryFuelType: null,
                                licencePlate: null,
                                features: null,
                                power: null,
                                engineVolume: null,
                              },
                            },
                          ]);
                          showNotification.info(
                            i18n.t('entity.interest.actions.removeBuyingVehicle')
                          );
                        })
                        .catch(handleApiError),
                  }),
              }),
          },
        ]}
      />
    </DataStatus>
  );
}
