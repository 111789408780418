import {isFeatureEnabled} from 'feature-flags';
import {Box, Center, Clickable, Icon} from 'platform/foundation';
import styled, {css, useTheme} from 'styled-components';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import {UserSidebarIconSize} from '@dms/api/shared';
import {featureFlags} from '@dms/feature-flags';

import {suffixTestId, TestIdProps} from 'shared';

interface DevSettingsHeaderButtonProps extends TestIdProps {
  isOpen: boolean;
  isAnyDevSettingEnabled: boolean;
  iconSize?: UserSidebarIconSize;
  onClick: () => void;
}

export function DevSettingsHeaderButton(props: DevSettingsHeaderButtonProps) {
  const theme = useTheme();
  if (isFeatureEnabled(featureFlags.SIDEBAR)) {
    return (
      <Clickable onClick={props.onClick}>
        <Center height={12}>
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              width: ${theme.getSize(8)};
              height: ${theme.getSize(8)};
              border-radius: ${theme.radii.small};
              background-color: ${match<[boolean, boolean], string>([
                props.isAnyDevSettingEnabled,
                props.isOpen,
              ])
                .with([false, false], always(theme.colors.palettes.yellow[30][100]))
                .with([false, true], always(theme.colors.palettes.yellow[50][100]))
                .with([true, false], always(theme.colors.palettes.red[30][100]))
                .with([true, true], always(theme.colors.palettes.red[40][100]))
                .exhaustive()};
              &:hover {
                filter: saturate(3);
              }
            `}
            data-testid={suffixTestId('devSettings-button-icon', props)}
          >
            <Icon size={props.iconSize === 'large' ? 5 : 4} value="action/build" cursor="pointer" />
          </div>
        </Center>
      </Clickable>
    );
  }

  return (
    <StyledDevSettingsHeaderButtonWrapper
      $isOpen={props.isOpen}
      data-testid={suffixTestId('devSettings-button', props)}
      onClick={props.onClick}
    >
      <Center height="100%">
        <Box
          width={6}
          height={6}
          backgroundColor={
            props.isAnyDevSettingEnabled ? 'palettes.red.30.100' : 'palettes.yellow.30.100'
          }
          borderRadius="circular"
          data-testid={suffixTestId('devSettings-button-icon', props)}
        >
          <Center height="100%">
            <Icon size={3} value="action/build" cursor="pointer" />
          </Center>
        </Box>
      </Center>
    </StyledDevSettingsHeaderButtonWrapper>
  );
}

const StyledDevSettingsHeaderButtonWrapper = styled.div<{
  $isOpen: boolean;
  $size?: 'small' | 'default';
  $rounded?: boolean;
}>`
  background-color: ${({theme, $isOpen}) => $isOpen && theme.colors.palettes.neutral[30][100]};
  height: ${({theme, $size}) => theme.getSize($size === 'small' ? 6 : 12)};
  width: ${({theme, $size}) => theme.getSize($size === 'small' ? 6 : 12)};
  border-radius: ${({$rounded}) => ($rounded ? '50%' : '0')};
  &:hover {
    background-color: ${({theme}) => theme.colors.palettes.neutral[30][100]};
  }
  cursor: pointer;
`;
