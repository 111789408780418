import {groupBy, map, pipe} from 'ramda';

import {SeriesResponseBody, SeriesType, useGetSeriesListQuery} from '@dms/api/series';

import {Nullish} from 'shared';

export const getGroupedSeries = (series: SeriesResponseBody[] | Nullish) =>
  pipe(
    map((item: SeriesResponseBody) => ({type: item.type, value: item.id, label: item.name})),
    groupBy((item) => item.type)
  )(series ?? []);

export const useSeriesOptions = (type?: SeriesType[]) => {
  const {data: series, isLoading, isError} = useGetSeriesListQuery({type});

  const groupedSeries = getGroupedSeries(series);

  return {groupedSeries, isSeriesLoading: isLoading, isSeriesError: isError};
};
