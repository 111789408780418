import {TabProps, Tabs} from 'platform/components';

import {GetIssueNoteResponse} from '@dms/api/metadaWarehouseIssueNote';
import i18n from '@dms/i18n';
import {queryParams, Section} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId, useQueryState} from 'shared';

import {GeneralInformationTab} from './components/GeneralInformationTab';
import {ListOfItemsTab} from './components/ListOfItemsTab';

interface OverviewProps extends RequiredTestIdProps {
  issueNote: GetIssueNoteResponse | undefined;
  isIssueNoteLoading: boolean;
}

export function Overview(props: OverviewProps) {
  const [activeTabId, setActiveTabId] = useQueryState(queryParams.COMPONENT_SECTIONS_TAB);

  const tabs: TabProps[] = [
    {
      title: i18n.t('entity.warehouse.labels.generalInformation'),
      id: 'generalInformation',
      content: (
        <GeneralInformationTab
          issueNote={props.issueNote}
          data-testid={suffixTestId('generalInformation', props)}
        />
      ),
    },
    {
      title: i18n.t('entity.warehouse.labels.listOfItems'),
      id: 'listOfItems',
      content: (
        <ListOfItemsTab
          issueNote={props.issueNote}
          data-testid={suffixTestId('listOfItems', props)}
        />
      ),
    },
  ];

  return (
    <Section isFullHeight data-testid={suffixTestId('wrapper', props)}>
      <Tabs
        isFullHeight
        tabs={tabs}
        activeTabId={activeTabId}
        onChange={setActiveTabId}
        isLazy
        variant="condensed"
      />
    </Section>
  );
}
