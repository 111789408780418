import {Button, closeDialog, Label, openDialog} from 'platform/components';
import {Show, VStack} from 'platform/foundation';

import {useGetTireSetLocationQuery} from '@dms/api/metadaTires';
import i18n from '@dms/i18n';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {StorageWarehouseDialog} from './StorageWarehouseDialog';

interface StorageWarehouseProps extends RequiredTestIdProps {
  setId?: string | Nullish;
  orderId: string;
  isDisabled: boolean;
}

export function StorageWarehouse(props: StorageWarehouseProps) {
  const tireSetLocationQuery = useGetTireSetLocationQuery(
    {
      orderId: props.orderId,
      setId: props.setId || '',
    },
    {
      skip: !props.setId || !props.orderId,
    }
  );

  const storageTitle =
    tireSetLocationQuery.data?.location || i18n.t('entity.warehouse.actions.assignStorageLocation');

  return (
    <Show when={props.setId}>
      <VStack align="flex-start">
        <Label>{i18n.t('entity.warehouse.labels.storageLocation')}</Label>
        <Button
          variant="link"
          leftIcon="custom/warehouse"
          isDisabled={props.isDisabled || false}
          title={storageTitle}
          isLoading={tireSetLocationQuery.isLoading}
          onClick={() =>
            openDialog(
              <StorageWarehouseDialog
                setId={props.setId}
                orderId={props.orderId}
                onClose={() => closeDialog('storageWarehouseDialog')}
                data-testid={suffixTestId('storageWarehouseDialog', props)}
              />,
              {
                id: 'storageWarehouseDialog',
                title: tireSetLocationQuery.data?.location
                  ? `${i18n.t('entity.warehouse.labels.assignStorageLocation')} ${i18n.t('entity.warehouse.labels.currentlyAssigned', {location: tireSetLocationQuery.data?.location})}`
                  : i18n.t('entity.warehouse.labels.assignStorageLocation'),
                size: 'large',
              }
            )
          }
          data-testid={suffixTestId('storageWarehouse', props)}
        />
      </VStack>
    </Show>
  );
}
