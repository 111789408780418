import {useParams} from 'react-router-dom';

import {isNil} from 'ramda';
import {isNilOrEmpty, isNotNil} from 'ramda-adjunct';

import {useGetWarehousesQuery} from '@dms/api/metadaWarehouse';
import {useGetMarketingCodeQuery} from '@dms/api/metadaWarehouseMarketingCode';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';
import {MarketingCodeDetailForm} from './components/MarketingCodeDetailForm';

export function WarehouseMarketingCodeDetail() {
  const {id} = useParams();

  const {
    data: marketingCode,
    isLoading: isMarketingCodeLoading,
    isError: isMarketingCodeError,
  } = useGetMarketingCodeQuery({marketingCodeId: id as string}, {skip: isNilOrEmpty(id)});

  const {
    data: warehouses,
    isLoading: isWarehousesLoading,
    isError: isWarehousesError,
  } = useGetWarehousesQuery();

  const isLoading = isNotNil(id)
    ? isWarehousesLoading || isMarketingCodeLoading
    : isWarehousesLoading;

  const isError = isNotNil(id) ? isWarehousesError || isMarketingCodeError : isWarehousesError;

  const header: SettingsTemplateHeader = {
    title: isNil(id) ? i18n.t('entity.warehouse.labels.newMarketingCode') : marketingCode?.name,
  };

  return (
    <SettingsTemplate
      header={header}
      isLoading={isLoading}
      isError={isError}
      data-testid={testIds.settings.workshopPresetsCategory('template')}
    >
      <MarketingCodeDetailForm
        marketingCode={marketingCode}
        warehouses={warehouses}
        data-testid={testIds.settings.warehousesMarketingCodeDetail('marketingCodeDetailForm')}
      />
    </SettingsTemplate>
  );
}
