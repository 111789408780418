import {Option} from 'platform/components';
import {z} from 'zod';

import {BaseVoidRequestSchema} from '@dms/api/shared';

export type DictionaryOption = Option & {isDefault: boolean};

const NameUuidSchema = z.object({
  name: z.string().nullable().optional(),
  uuid: z.string().nullable().optional(),
});

export const GetCodelistApplicationLawApiResponseSchema = z
  .object({
    'application-law': z.array(NameUuidSchema.nullable()).optional(),
  })
  .nullable();

export type GetCodelistApplicationLawApiResponse = z.infer<
  typeof GetCodelistApplicationLawApiResponseSchema
>;

export const GetCodelistCostCenterApiResponseSchema = z
  .object({
    cost_centers: z.array(NameUuidSchema.nullable()).optional(),
  })
  .nullable();

export type GetCodelistCostCenterApiResponse = z.infer<
  typeof GetCodelistCostCenterApiResponseSchema
>;

export const GetCodelistInsuranceTypesApiResponseSchema = z
  .object({
    insurance_types: z.array(NameUuidSchema.nullable()).optional(),
  })
  .nullable();

export type GetCodelistInsuranceTypesApiResponse = z.infer<
  typeof GetCodelistInsuranceTypesApiResponseSchema
>;

export const GetCodelistTechniciansApiResponseSchema = z
  .object({
    technician: z.array(NameUuidSchema.nullable()).optional(),
  })
  .nullable();

export type GetCodelistTechniciansApiResponse = z.infer<
  typeof GetCodelistTechniciansApiResponseSchema
>;

export const GetDictionaryApiResponseSchema = z.array(
  z
    .object({
      name: z.string().nullable().optional(),
      id: z.string().nullable().optional(),
      code: z.string().nullable().optional(),
      labourCategoryId: z.string().nullable().optional(),
      isEditable: z.boolean().nullable().optional(),
      isDefault: z.boolean().nullable().optional(),
      priority: z.number().nullable().optional(),
    })
    .nullable()
);

export type GetDictionaryApiResponse = z.infer<typeof GetDictionaryApiResponseSchema>;

export const GetLabourCatalogGridFilterCatalogCategoryApiResponseSchema = z
  .object({
    labourCatalog: z
      .array(
        z
          .object({
            name: z.string().nullable().optional(),
            code: z.string().nullable().optional(),
            labourCategoryId: z.string().nullable().optional(),
            isEditable: z.boolean().nullable().optional(),
            isDefault: z.boolean().nullable().optional(),
            priority: z.number().nullable().optional(),
          })
          .nullable()
      )
      .optional(),
  })
  .nullable();

export type GetLabourCatalogGridFilterCatalogCategoryApiResponse = z.infer<
  typeof GetLabourCatalogGridFilterCatalogCategoryApiResponseSchema
>;

export const GetDictionaryApiArgSchema = z.object({
  category: z.enum([
    'SVCBASEPRICESOURCE',
    'SVCDISCOUNTSOURCE',
    'SVCITEMTYPE',
    'SVCLABOURCATALOGCATEGORYTYPE',
    'SVCLABOURCATALOGCLASSIFICATION',
    'SVCPRICETYPE',
    'SVCRATETYPE',
    'SVCUNIT',
    'SVCVEHICLECUSTOMERTYPE',
    'SVCWORKTYPE',
    'SVCPRICETYPEMATERIAL',
    'SVCLABOURCATALOGCATEGORYTYPE',
  ]),
});

export type GetDictionaryApiArg = z.infer<typeof GetDictionaryApiArgSchema>;

export const GetLabourCatalogGridFilterCatalogCategoryApiArgSchema = z.union([
  BaseVoidRequestSchema,
  z.object({
    serviceCaseId: z.string().optional(),
  }),
]);

export type GetLabourCatalogGridFilterCatalogCategoryApiArg = z.infer<
  typeof GetLabourCatalogGridFilterCatalogCategoryApiArgSchema
>;
