import {Card, DataStatus} from 'platform/components';
import {Icon} from 'platform/foundation';

import {ReactElement, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

import {useGetConsentTypesQuery} from '@dms/api/gdpr';
import {ConsentType, ConsentChannel} from '@dms/api/shared';
import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';
import {ColumnType, DataType, SimpleTable} from '@dms/teas';

import {ConsentTypesListActions} from './ConsentTypesListActions';
import {TemplatesCount} from './TemplatesCount';

export function GdprConsentTypesList() {
  const navigate = useNavigate();

  return (
    <Card
      title={i18n.t('entity.gdpr.labels.consentTypes')}
      actions={[
        {
          'data-testid': 'gdpr-consents-create-button',
          onClick: () => navigate(settingsRoutes.gdprConsentTypesNew),
          title: i18n.t('general.labels.createNew'),
          leftIcon: 'content/add_circle',
          variant: 'link',
          type: 'button',
        },
      ]}
    >
      <GdprConsentTypesTable />
    </Card>
  );
}

function GdprConsentTypesTable() {
  const {data: consentTypes, isLoading, isError} = useGetConsentTypesQuery();

  const rows = useMemo(
    () => [
      {
        data: (consentTypes ?? []).map((type: ConsentType): DataType => {
          const channels: Record<string, ReactElement> = {};
          type.channels.forEach((channel: ConsentChannel) => {
            channels[channel] = <Icon value="AV/fiber_manual_record" size={4} />;
          });

          return {
            ...channels,
            name: type.name,
            templates: <TemplatesCount templates={type.templates} />,
            action: <ConsentTypesListActions type={type} />,
          };
        }),
      },
    ],
    [consentTypes]
  );

  return (
    <DataStatus spacing={4} isEmpty={!consentTypes?.length} isLoading={isLoading} isError={isError}>
      <SimpleTable
        data-testid="gdpr-consents-list-table"
        tdPadding="default"
        columns={columns}
        showRowDivider
        rows={rows}
        noZebra
      />
    </DataStatus>
  );
}

const columns: ColumnType<DataType>[] = [
  {Header: i18n.t('entity.gdpr.labels.consentTypeName'), id: 'name'},
  {Header: i18n.t('entity.gdpr.labels.templatesCount'), id: 'templates'},
  {Header: i18n.t('general.labels.phone'), id: 'phone'},
  {Header: i18n.t('general.labels.sms'), id: 'sms'},
  {Header: i18n.t('general.labels.email'), id: 'email'},
  {Header: i18n.t('general.labels.mail'), id: 'mail'},
  {Header: <></>, id: 'action', width: 32},
];
