import {Card, closeCurrentDialog} from 'platform/components';

import {useGetMyTasksQuery} from '@dms/api/taskManagement';
import {useGetCurrentUserInfoQuery} from '@dms/api/user';
import {openCreateTaskFormDialog, openEditTaskFormDialog} from '@dms/features/tasks';
import i18n from '@dms/i18n';
import {taskManagerRoutes} from '@dms/routes';
import {useDataQuery, usePermissions} from '@dms/shared';

import {composePath, RequiredTestIdProps, suffixTestId, useNavigate} from 'shared';

import {TaskCenterList} from '../TaskCenterList/TaskCenterList';

interface TaskCenterCardProps extends RequiredTestIdProps {}

export function TaskCenterCard(props: TaskCenterCardProps) {
  const [canCreateTask] = usePermissions({
    permissionKeys: ['createTask'],
  });

  const navigate = useNavigate();

  const {
    data: myTasks,
    isLoading: areMyTasksLoading,
    isError: hasMyTasksError,
  } = useGetMyTasksQuery();

  const {data: userInfo} = useGetCurrentUserInfoQuery();
  const userId = userInfo?.id ?? '';

  const {queryParams: assignedToMeQueryParams} = useDataQuery('task', {
    filters: {
      assignedTo: [userId],
    },
    order: [
      {
        columnKey: 'createdAt',
        order: 'DESC',
      },
    ],
  });

  return (
    <Card
      data-testid={props['data-testid']}
      title={i18n.t('entity.task.labels.myTasks')}
      hasSeparator={false}
      actions={[
        {
          type: 'button',
          variant: 'link',
          title: i18n.t('entity.task.actions.showAll'),
          onClick: () => {
            navigate(
              composePath(taskManagerRoutes.taskOverview, {queryParams: assignedToMeQueryParams})
            );
          },
          rightIcon: 'navigation/chevron_right',
        },
      ]}
    >
      <TaskCenterList
        data-testid={suffixTestId('myTasks', props)}
        list={myTasks ?? []}
        isLoading={areMyTasksLoading}
        isError={hasMyTasksError}
        onItemClick={(task) => {
          closeCurrentDialog();
          openEditTaskFormDialog({
            id: task.id,
            'data-testid': suffixTestId('myTasks', props),
          });
        }}
        emptyStatus={
          canCreateTask
            ? {
                action: {
                  title: i18n.t('entity.task.actions.createTask'),
                  leftIcon: 'content/add_circle',
                  onClick: () => {
                    closeCurrentDialog();
                    openCreateTaskFormDialog({
                      'data-testid': suffixTestId('myTasks', props),
                    });
                  },
                },
              }
            : undefined
        }
      />
    </Card>
  );
}
