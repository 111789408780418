import {UseFormReturn} from 'react-hook-form';

import {lensPath, set} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {CompGetInfoV3ApiResponse} from '@dms/api/company';
import {AddressRequestBodyV2} from '@dms/api/customer';
import i18n from '@dms/i18n';

import {LegalPersonFormType} from '../types/LegalPersonFormType';
import {PhysicalPersonFormType} from '../types/PhysicalPersonFormType';

export const handleDecodeBusinessIdForm =
  (
    formApi: UseFormReturn<PhysicalPersonFormType> | UseFormReturn<LegalPersonFormType>,
    shouldShowPersonData?: boolean
  ) =>
  (company: CompGetInfoV3ApiResponse) => {
    let formValues = formApi.getValues() as PhysicalPersonFormType | LegalPersonFormType;

    const isLegalPerson = formValues.customerData.customerDiscriminator === 'BUSINESS';

    const businessInfoData = {
      countryOfRegistrationCode: company?.[0]?.country ?? null,
      registrationNumber: company?.[0]?.businessId ?? null,
      vatNumber: company?.[0]?.taxId ?? null,
      fileNumber: company?.[0]?.fileNumber ?? null,
      tradeName: company?.[0]?.businessName ?? null,
      province: company?.[0]?.state ?? null,
      type: null,
      invalid: null,
    };

    const personData = {
      firstName: company?.[0]?.personSelfEmployee?.firstName ?? null,
      lastName: company?.[0]?.personSelfEmployee?.lastName ?? null,
      birthdate: null,
      citizenshipCode: null,
      genderKey: null,
      middleName: null,
      personalIdentifier: null,
      roles: null,
      titleAfter: null,
      titleBefore: null,
    };

    const addressData: AddressRequestBodyV2 = {
      address: {
        street: company?.[0]?.street ?? '',
        descriptiveNumber: company?.[0]?.descriptiveNumber ?? null,
        orientationNumber: company?.[0]?.orientationNumber ?? null,
        city: company?.[0]?.city ?? '',
        zip: company?.[0]?.zipCode ?? '',
        state: company?.[0]?.state,
        country: company?.[0]?.country ?? '',
        addressComplement: null,
        prefix: company?.[0]?.prefix ?? null,
        district: null,
      },
      type: i18n.t('entity.person.permanentAddressId'),
      invalid: null,
    };

    if (isLegalPerson) {
      formValues = set(
        lensPath(['businessInfo', 'businessInfoData']),
        businessInfoData,
        formValues
      );
    }

    if (!isLegalPerson) {
      formValues = set(lensPath(['selfEmployedBusinessInfoData']), businessInfoData, formValues);
    }

    if (!isLegalPerson && shouldShowPersonData) {
      formValues = set(lensPath(['foundingPerson', 'personData']), personData, formValues);
    }

    if (isNotNil(company?.[0]?.street)) {
      formValues = set(lensPath(['addressData']), addressData, formValues);
    }

    formApi.reset(formValues);
  };
