import {startOfToday} from 'date-fns';
import {FormControl} from 'platform/components';

import {ReactNode, useEffect} from 'react';
import {useWatch} from 'react-hook-form';

import {isNil} from 'ramda';

import {useGetVatRatesForCountryQuery} from '@dms/api/cached';
import {useGetTenantQuery} from '@dms/api/tenant';
import {DEFAULT_COUNTRY} from '@dms/shared';

import {getApiDateString, Nullish} from 'shared';

import {AccountingDocumentFormValues} from '../types';
import {InvoiceVatRateContext} from './InvoiceVatRateContext';
import {vatRateToOption} from './vatRateToOption';

type InvoiceVatRateProviderProps = {
  date?: string | Nullish;
  control: FormControl<AccountingDocumentFormValues>;
  children: ReactNode;
  listItemType: 'invoicing-documents' | 'cash-receipts';
};

export function InvoiceVatRateProvider(props: InvoiceVatRateProviderProps) {
  const {data: tenant} = useGetTenantQuery();
  const [dateOfTaxableSupply, issuedAt] = useWatch({
    name: ['dateOfTaxableSupply', 'issueDate'],
    control: props.control,
  });

  const formDate = props.listItemType === 'invoicing-documents' ? dateOfTaxableSupply : issuedAt;
  const fetchArgs = {
    country: tenant?.country ?? DEFAULT_COUNTRY,
    date: props.date ?? formDate ?? getApiDateString(startOfToday()),
  };

  const {data: vatRate, refetch: refetchVatRates} = useGetVatRatesForCountryQuery(fetchArgs, {
    skip: isNil(tenant?.country),
  });
  const rateOptions = vatRate?.rates?.map(vatRateToOption) ?? [];

  useEffect(() => {
    if (props.listItemType === 'cash-receipts') {
      return;
    }

    refetchVatRates();
  }, [dateOfTaxableSupply, refetchVatRates, props.listItemType]);

  useEffect(() => {
    if (props.listItemType === 'invoicing-documents') {
      return;
    }

    refetchVatRates();
  }, [issuedAt, refetchVatRates, props.listItemType]);

  return (
    <InvoiceVatRateContext.Provider value={{rates: vatRate?.rates ?? [], rateOptions}}>
      {props.children}
    </InvoiceVatRateContext.Provider>
  );
}
