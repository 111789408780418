import {
  BreadcrumbType,
  Button,
  Card,
  closeCurrentDialog,
  Form,
  FormField,
  FormSubmitHandler,
  openDeleteDialog,
  openDialog,
  Separator,
  Tooltip,
} from 'platform/components';
import {Heading, Space, Text, VStack} from 'platform/foundation';
import {object, string} from 'yup';

import {useParams} from 'react-router-dom';

import {head, isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  useDeleteEmployeeCompetenceLinkMutation,
  useGetEmployeeCompetenceIntegrationQuery,
  useGetEmployeeCompetenceQuery,
  useGetIntegrationCompetencesQuery,
  usePatchEmployeeCompetenceMutation,
  usePostEmployeeCompetenceMutation,
} from '@dms/api/metadaEmployeeCompetence';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {useNavigate} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {PairingWithPlanner} from './components/PairingWithPlanner';

type CompetencesFormType = {description: string; isActive: boolean};

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('entity.employeeCompetences.labels.title'),
    href: settingsRoutes.employeeCompetences,
  },
];

export function EmployeeCompetencesDetail() {
  const navigate = useNavigate();
  const {id} = useParams();

  const [postEmployeeCompetence] = usePostEmployeeCompetenceMutation();
  const [patchEmployeeCompetence] = usePatchEmployeeCompetenceMutation();
  const {
    data: employeeCompetence,
    isError: isEmployeeCompetenceError,
    isLoading: isEmployeeCompetenceLoading,
  } = useGetEmployeeCompetenceQuery({id: id ?? ''}, {skip: isNil(id)});
  const {
    data: employeeCompetenceIntegration,
    isLoading: isEmployeeCompetenceIntegrationLoading,
    isError: isEmployeeCompetenceIntegrationError,
  } = useGetEmployeeCompetenceIntegrationQuery({competenceId: id ?? ''}, {skip: isNil(id)});
  const {
    data: integrationCompetences,
    isLoading: isIntegrationCompetencesLoading,
    isError: isIntegrationCompetencesError,
  } = useGetIntegrationCompetencesQuery();
  const [deleteEmployeeCompetenceLink] = useDeleteEmployeeCompetenceLinkMutation();

  const handleSubmit: FormSubmitHandler<CompetencesFormType> = async (data) => {
    const request = isNil(id)
      ? postEmployeeCompetence({body: data})
      : patchEmployeeCompetence({body: data, id});

    await request
      .unwrap()
      .then(() => navigate(settingsRoutes.employeeCompetences))
      .catch(handleApiError);
  };

  const handleUnpair = () => {
    if (isNil(id)) {
      return;
    }

    openDeleteDialog({
      onConfirm: () =>
        deleteEmployeeCompetenceLink({
          competenceId: id,
          contextTarget: 'PLANNER',
        })
          .unwrap()
          .catch(handleApiError),
    });
  };

  const isError =
    isEmployeeCompetenceError ||
    isIntegrationCompetencesError ||
    isEmployeeCompetenceIntegrationError;
  const isLoading =
    isEmployeeCompetenceIntegrationLoading ||
    isIntegrationCompetencesLoading ||
    isEmployeeCompetenceLoading;

  const integration = integrationCompetences?.find(
    (integration) => integration.id === head(employeeCompetenceIntegration ?? [])?.contextId
  );

  return (
    <SettingsTemplate
      header={{
        breadcrumbs,
        title:
          employeeCompetence?.description ??
          i18n.t('entity.employeeCompetences.labels.newCompetence'),
      }}
      isError={isError}
      isLoading={isLoading}
      isCreating={isNil(id)}
      data-testid={testIds.settings.employeeCompetencesDetail('settingsTemplate')}
    >
      <Form<CompetencesFormType>
        defaultValues={{
          description: employeeCompetence?.description ?? undefined,
          isActive: employeeCompetence?.isActive ?? true,
        }}
        schema={schema}
        onSubmit={handleSubmit}
      >
        {(control) => (
          <>
            <SettingsSection>
              <FormField
                control={control}
                type="switch"
                name="isActive"
                label={i18n.t('entity.employeeCompetences.labels.setAsActive')}
                data-testid={testIds.settings.employeeCompetencesDetail('isActive')}
              />
              <Separator spacing={6} />
              <FormField
                control={control}
                type="text"
                name="description"
                label={i18n.t('general.labels.description')}
                data-testid={testIds.settings.employeeCompetencesDetail('description')}
              />
              <Separator spacing={6} />
              <VStack>
                <Heading size={4}>
                  {i18n.t('entity.employeeCompetences.labels.pairingCompenetceWithPlanner')}
                </Heading>
                <Space vertical={1} />
                <Text size="small">
                  {i18n.t(
                    'entity.employeeCompetences.labels.pairingCompenetceWithPlannerDescription'
                  )}
                </Text>
                <Space vertical={6} />
                {integration ? (
                  <Card
                    variant="inlineGrey"
                    title={integration.label}
                    actions={[
                      {
                        type: 'button',
                        variant: 'link',
                        title: i18n.t('general.actions.change'),
                        leftIcon: 'navigation/refresh',
                        onClick: () =>
                          openDialog(
                            <PairingWithPlanner
                              competenceId={id!}
                              onClose={closeCurrentDialog}
                              data-testid={testIds.settings.employeeCompetencesDetail(
                                'pairWithPlanner'
                              )}
                            />,
                            {title: i18n.t('general.actions.change')}
                          ),
                      },
                      {
                        type: 'button',
                        variant: 'dangerLink',
                        title: i18n.t('entity.employeeCompetences.labels.unpair'),
                        leftIcon: 'navigation/close',
                        onClick: handleUnpair,
                      },
                    ]}
                  />
                ) : (
                  <VStack align="flex-start">
                    <Tooltip
                      label={
                        isNilOrEmpty(id)
                          ? i18n.t('entity.employeeCompetences.actions.createCompetenceFirst')
                          : undefined
                      }
                    >
                      <Button
                        leftIcon="content/link"
                        variant="secondary"
                        title={i18n.t('entity.employeeCompetences.actions.pairWithPlanner')}
                        isDisabled={isNilOrEmpty(id)}
                        onClick={() =>
                          openDialog(
                            <PairingWithPlanner
                              competenceId={id!}
                              onClose={closeCurrentDialog}
                              data-testid={testIds.settings.employeeCompetencesDetail(
                                'pairWithPlanner'
                              )}
                            />,
                            {title: i18n.t('entity.employeeCompetences.actions.pairWithPlanner')}
                          )
                        }
                        data-testid={testIds.settings.employeeCompetencesDetail('pairWithPlanner')}
                      />
                    </Tooltip>
                  </VStack>
                )}
              </VStack>
            </SettingsSection>
            <SettingsFooter
              actions={[
                {
                  type: 'button',
                  onClick: () => navigate(settingsRoutes.employeeCompetences),
                  title: id
                    ? i18n.t('general.actions.discardChanges')
                    : i18n.t('general.actions.discard'),
                  variant: 'secondary',
                  'data-testid': testIds.settings.employeeCompetencesDetail('discard'),
                },
                {
                  type: 'form-button',
                  control,
                  buttonType: 'submit',
                  title: id
                    ? i18n.t('general.actions.saveAndClose')
                    : i18n.t('general.actions.create'),
                  'data-testid': testIds.settings.employeeCompetencesDetail('save'),
                },
              ]}
            />
          </>
        )}
      </Form>
    </SettingsTemplate>
  );
}

const schema = object({description: string().nullable().required()});
