import {Form, FormField, FormSubmitHandler} from 'platform/components';
import {Box} from 'platform/foundation';
import {object, string} from 'yup';

import {DefaultValues} from 'react-hook-form';

import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {TireDictionaryFormType} from '../../types/TireDictionaryFormType';

interface TireDictionaryFormProps extends RequiredTestIdProps {
  onSubmit: FormSubmitHandler<TireDictionaryFormType>;
  defaultValues: DefaultValues<TireDictionaryFormType>;
  onDiscard: () => void;
}

export function TireDictionaryForm(props: TireDictionaryFormProps) {
  return (
    <Form<TireDictionaryFormType>
      onSubmit={props.onSubmit}
      defaultValues={props.defaultValues}
      schema={formSchema}
    >
      {(control) => (
        <>
          <Box width={85}>
            <FormField
              control={control}
              name="name"
              type="text"
              label={i18n.t('general.labels.name')}
              isRequired
              data-testid={suffixTestId('name', props)}
            />
          </Box>
          <SettingsFooter
            actions={[
              {
                type: 'button',
                title: i18n.t('general.actions.discardChanges'),
                variant: 'secondary',
                onClick: props.onDiscard,
              },
              {
                control,
                type: 'form-button',
                buttonType: 'submit',
                title: i18n.t('general.actions.saveChanges'),
              },
            ]}
            data-testid={suffixTestId('footer', props)}
          />
        </>
      )}
    </Form>
  );
}

const formSchema = object({
  name: string().required(),
});
