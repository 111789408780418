import {Show, HStack, Box, getValueByDevice, useDevice} from 'platform/foundation';

import {useState} from 'react';

import {ActivitySidebarAction} from '../types/ActivitySidebarAction';
import {ActivitySidebarItem} from './ActivitySidebarItem';
import {ActivitySidebarMenu} from './ActivitySidebarMenu';

interface ActivitySidebarProps {
  actions: ActivitySidebarAction[];
}

export function ActivitySidebar(props: ActivitySidebarProps) {
  const [openTab, setOpenTab] = useState<string | null>(null);
  const device = useDevice();

  const handleCloseTab = () => setOpenTab(null);

  const handleToggleTab = (tab: string) => {
    setOpenTab(openTab === tab ? null : tab);
  };

  const activeAction = props.actions.find((action) => action.label === openTab);

  return (
    <Box position="relative">
      <HStack spacing={0} height="100%">
        <Show when={openTab}>
          <Box
            position={getValueByDevice(device, 'absolute', 'absolute', 'absolute', 'initial')}
            height="100%"
            zIndex="SIDEBAR"
            right={12}
            width={100}
            backgroundColor="general.white"
            borderRight="1px solid"
            borderColor="general.separator"
            boxShadow="elevation_3"
            minHeight="65dvh"
          >
            <ActivitySidebarItem title={activeAction?.label ?? ''} onClose={handleCloseTab}>
              {activeAction?.component ?? null}
            </ActivitySidebarItem>
          </Box>
        </Show>
        <ActivitySidebarMenu
          actions={props.actions}
          onClick={handleToggleTab}
          activeTab={openTab}
        />
      </HStack>
    </Box>
  );
}
