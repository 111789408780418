import {Action, DropdownActionMenuItem, TabProps, Tabs} from 'platform/components';
import {match, Pattern} from 'ts-pattern';

import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {always} from 'ramda';

import {useGetParticipationQuery} from '@dms/api/participation';
import {
  AdvertisementStatusEnum,
  GetSalesPlatformsApiRes,
  SalesPlatformsResBody,
  useGetGeneralSettingsQuery,
  useGetSalesPlatformsQuery,
  useUpdateVehicleIsInSaleMutation,
} from '@dms/api/sales';
import {EntityResourceIds} from '@dms/api/shared';
import {useGetVehicleQuery} from '@dms/api/vehicle';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {handleApiError, queryParams, usePermissions} from '@dms/shared';
import {
  AppDispatch,
  downloadWindshieldTemplate,
  getWindshieldTemplates,
  printWindshieldTemplate,
  PublishingService,
  selectWindshieldTemplates,
  useCallApi,
} from '@dms/teas';

import {buildArray, useQueryState, useRequiredParams} from 'shared';

import {AdvertisementTab} from './AdvertisementTab';
import {WindshieldTab} from './WindshieldTab';

const TAB_IDS = {
  AD_PLATFORMS: 'adPlatforms',
  WINDSHIELD: 'windshield',
};

export function AdvertisementContent() {
  const {id: vehicleId} = useRequiredParams();

  const {data: vehicleParticipation} = useGetParticipationQuery({
    resourceId: EntityResourceIds.vehicle,
    recordId: vehicleId,
  });

  const {data: vehicle} = useGetVehicleQuery({vehicleId});

  const [
    hasGenerateWindshieldPaperPermission,
    hasUpdateVehicleOnAllPlatformPermission,
    hasEnableVehicleInAdvertisementPermission,
    hasDisableVehicleInAdvertisementPermission,
  ] = usePermissions({
    permissionKeys: [
      'generateWindshieldPaper',
      'updateVehicleOnAllPlatform',
      'enableVehicleInAdvertisement',
      'disableVehicleInAdvertisement',
    ],
    scopes: {
      generateWindshieldPaper: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
      updateVehicleOnAllPlatform: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
      enableVehicleInAdvertisement: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
      disableVehicleInAdvertisement: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
    },
  });

  const [downloadOptions, setDownloadOptions] = useState<DropdownActionMenuItem[]>([]);
  const [printOptions, setPrintOptions] = useState<DropdownActionMenuItem[]>([]);

  const [tabId, setActiveTabId] = useQueryState(queryParams.VEHICLE_ADVERTISEMENT_PUBLISHING);
  const activeTabId = tabId ?? TAB_IDS.AD_PLATFORMS;

  const dispatch = useDispatch<AppDispatch>();

  const updateAll = useCallApi(PublishingService.updateVehicleOnAllPlatforms);

  const [updateVehicleIsInSale, {isLoading: isUpdatingVehicleIsInSale}] =
    useUpdateVehicleIsInSaleMutation();
  const {data: platforms} = useGetSalesPlatformsQuery({vehicleId});

  const templates = useSelector(selectWindshieldTemplates);

  const {data: generalSettings} = useGetGeneralSettingsQuery({vehicleId});
  const inAdvertisement = generalSettings?.inSale;

  const platformsPublishedCount = getPublishedPlatformsLength(platforms);
  const possiblePlatformUpdateCount = getPlatformsToUpdateLength(platforms);

  useEffect(() => {
    if (activeTabId !== TAB_IDS.WINDSHIELD) {
      return;
    }

    dispatch(getWindshieldTemplates());
  }, [dispatch, activeTabId]);

  useEffect(() => {
    const downloadTemplate = (templateId: string) => {
      dispatch(downloadWindshieldTemplate({templateId, vehicleId}));
    };

    const printTemplate = (templateId: string) => {
      dispatch(printWindshieldTemplate({templateId, vehicleId}));
    };

    if (templates?.length) {
      const downloadTemplateList = templates.map(({title, id}) => ({
        label: title,
        onClick: () => downloadTemplate(id),
        id,
      }));

      const printTemplateList = templates.map(({title, id}) => ({
        label: title,
        onClick: () => printTemplate(id),
        id,
      }));
      setDownloadOptions(downloadTemplateList);
      setPrintOptions(printTemplateList);
    }
  }, [templates, vehicleId, dispatch]);

  const isLoading = getIsAnyPlatformProcessing(platforms);

  const isUpdateShown = match([
    inAdvertisement,
    possiblePlatformUpdateCount,
    platformsPublishedCount,
  ])
    .with(
      [false, Pattern.any, Pattern.any],
      [Pattern.any, 0, Pattern.any],
      [Pattern.any, Pattern.any, 0],
      always(false)
    )
    .otherwise(always(true));

  const handleUpdate = useCallback(() => {
    updateAll({vehicleId}).catch((error: {response: Error}) => handleApiError(error?.response));
  }, [updateAll, vehicleId]);

  const handlePublish = async () => {
    await updateVehicleIsInSale({vehicleId, publish: !inAdvertisement})
      .unwrap()
      .catch(handleApiError);
  };

  const platformActions = buildArray<Action>()
    .when(hasUpdateVehicleOnAllPlatformPermission && isUpdateShown, {
      title: i18n.t('page.advertisement.actions.updateAd'),
      onClick: handleUpdate,
      isLoading,
      type: 'button',
      variant: 'outlined',
      'data-testid': testIds.vehicles.publishing('refresh'),
    })
    .when(hasDisableVehicleInAdvertisementPermission && inAdvertisement, {
      title: i18n.t('page.advertisement.actions.unPublish'),
      onClick: handlePublish,
      isLoading: isLoading || isUpdatingVehicleIsInSale,
      type: 'button',
      variant: 'danger',
      'data-testid': testIds.vehicles.publishing('publish'),
    })
    .when(hasEnableVehicleInAdvertisementPermission && !inAdvertisement, {
      title: i18n.t('page.advertisement.actions.publish'),
      onClick: handlePublish,
      isLoading: isLoading || isUpdatingVehicleIsInSale,
      type: 'button',
      variant: 'primary',
      'data-testid': testIds.vehicles.publishing('publish'),
    });

  const tabs: TabProps[] = [
    {
      id: TAB_IDS.AD_PLATFORMS,
      'data-testid': testIds.vehicles.publishing('adPlatforms'),
      title: i18n.t('entity.vehicle.labels.advertisementTabPlatforms'),
      content: (
        <AdvertisementTab
          isPoolingEnabled={activeTabId === TAB_IDS.AD_PLATFORMS}
          data-testid={testIds.vehicles.publishing('adPlatformsContent')}
        />
      ),
      actions: platformActions,
    },
    {
      id: TAB_IDS.WINDSHIELD,
      'data-testid': testIds.vehicles.publishing('windshield'),
      title: i18n.t('entity.vehicle.labels.windshieldTab'),
      content: <WindshieldTab data-testid={testIds.vehicles.publishing('windshieldContent')} />,
      actions: buildArray<Action>()
        .when(hasGenerateWindshieldPaperPermission, {
          title: i18n.t('page.advertisement.labels.downloadWindshield'),
          rightIcon: 'navigation/expand_more',
          isLoading: false,
          type: 'dropdown-button',
          variant: 'outlined',
          menuItems: downloadOptions,
        })
        .when(hasGenerateWindshieldPaperPermission, {
          title: i18n.t('page.advertisement.labels.printWindshield'),
          rightIcon: 'navigation/expand_more',
          type: 'dropdown-button',
          variant: 'outlined',
          menuItems: printOptions,
        }),
    },
  ];

  return (
    <Tabs
      variant="condensed"
      onChange={setActiveTabId}
      activeTabId={activeTabId}
      tabs={tabs}
      data-testid={testIds.vehicles.publishing('tabs')}
    />
  );
}

const getIsAnyPlatformProcessing = (platforms: GetSalesPlatformsApiRes | undefined): boolean => {
  if (!platforms) {
    return false;
  }

  return platforms.some((value) =>
    match(value.status)
      .with(AdvertisementStatusEnum.PUBLISHING, AdvertisementStatusEnum.UNPUBLISHING, always(true))
      .otherwise(always(false))
  );
};

const getPublishedPlatformsLength = (platforms: GetSalesPlatformsApiRes | undefined) =>
  platforms?.reduce(
    (previous: number, current: SalesPlatformsResBody) =>
      current.status === AdvertisementStatusEnum.PUBLISHED ? previous + 1 : previous,
    0
  ) ?? 0;

const getPlatformsToUpdateLength = (platforms: GetSalesPlatformsApiRes | undefined) =>
  platforms?.reduce(
    (previous: number, current: SalesPlatformsResBody) =>
      (current.status === AdvertisementStatusEnum.PUBLISHED ||
        current.status === AdvertisementStatusEnum.UPDATE_FAILED) &&
      current.platform.enabled
        ? previous + 1
        : previous,
    0
  ) ?? 0;
