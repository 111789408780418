import {ReactNode} from 'react';

import {pushGtmEvent} from 'shared';

import {MenuButton} from './MenuButton';
import {MenuLink} from './MenuLink';

interface MenuItemLinkOrButtonProps {
  to: string;
  isGroup: boolean;
  children: ReactNode;
  onClick: () => void;
}

export function MenuItemLinkOrButton(props: MenuItemLinkOrButtonProps) {
  const onClick = () => {
    pushGtmEvent({
      event: 'button_click',
      event_id: 'sidebar_menu_item_click',
      value: props.to,
    });
    props.onClick();
  };

  if (props.isGroup) {
    return <MenuButton onClick={onClick}>{props.children}</MenuButton>;
  }

  return (
    <MenuLink to={props.to} onClick={onClick}>
      {props.children}
    </MenuLink>
  );
}
