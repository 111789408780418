import {
  Button,
  closeCurrentDialog,
  DataStatus,
  openDeleteDialog,
  openDialog,
  Search,
  showNotification,
  Table,
  TableRow,
} from 'platform/components';
import {Box, Heading, HStack, Text, VStack} from 'platform/foundation';
import {useCurrencySymbolFormatter, useFormatCurrency, useFormatNumber} from 'platform/locale';

import {useState} from 'react';

import {isEmpty, isNil, values} from 'ramda';

import {
  RentalPriceGroupItemResponseBody,
  useAddRentalPriceGroupItemMutation,
  useRemoveRentalPriceGroupItemMutation,
  useUpdateRentalPriceGroupItemMutation,
} from '@dms/api/rentalVehicles';
import i18n from '@dms/i18n';
import {EMPTY_PLACEHOLDER, handleApiError, useTenant} from '@dms/shared';

import {PriceListItemForm} from '../../PriceGroupsNew/components/PriceListItemForm';
import {getPriceListColumns} from '../../PriceGroupsNew/utils/getPriceListColumns';

interface PriceListProps {
  items: RentalPriceGroupItemResponseBody[];
  rentalPriceGroupId: string;
}

export function PriceList(props: PriceListProps) {
  const {tenantCurrency} = useTenant();
  const formatCurrencySymbol = useCurrencySymbolFormatter();
  const formatCurrency = useFormatCurrency();
  const formatNumber = useFormatNumber();

  const [deletePriceGroupItem] = useRemoveRentalPriceGroupItemMutation();
  const [editPriceGroupItem] = useUpdateRentalPriceGroupItemMutation();
  const [addPriceGroupItem] = useAddRentalPriceGroupItemMutation();

  const [searchString, setSearchString] = useState<string | null>(null);

  const handleAddNewPriceListItem = () =>
    openDialog(
      <PriceListItemForm
        items={props.items}
        onSubmit={async (data) => {
          await addPriceGroupItem({
            rentalPriceGroupId: props.rentalPriceGroupId,
            rentalPriceGroupItemRequestBody: {
              name: data.name,
              price: {
                includedKilometers: data.includedKilometers,
                rate: data.rate.toString(),
                ratePerKilometer: data.ratePerKilometer.toString(),
              },
              range: {
                from: data.from,
                to: data.to,
                period: data.period,
              },
            },
          })
            .unwrap()
            .then(() => {
              closeCurrentDialog();
              showNotification.success(i18n.t('general.notifications.successfullyCreated'));
            })
            .catch(handleApiError);
        }}
      />,
      {
        title: i18n.t('entity.rental.labels.newPriceList'),
      }
    );

  const handleEditPriceListItem = (item: RentalPriceGroupItemResponseBody) => {
    openDialog(
      <PriceListItemForm
        defaultValues={item}
        items={props.items}
        onSubmit={async (data) => {
          await editPriceGroupItem({
            rentalPriceGroupId: props.rentalPriceGroupId,
            rentalPriceGroupItemId: item.rentalPriceGroupItemId,
            rentalPriceGroupItemRequestBody: {
              name: data.name,
              price: {
                includedKilometers: data.includedKilometers,
                rate: data.rate.toString(),
                ratePerKilometer: data.ratePerKilometer.toString(),
              },
              range: {
                from: data.from,
                to: data.to,
                period: data.period,
              },
            },
          })
            .unwrap()
            .then(() => {
              closeCurrentDialog();
              showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
            })
            .catch(handleApiError);
        }}
      />,
      {
        title: i18n.t('entity.rental.labels.editPriceList'),
      }
    );
  };

  const handleRemovePriceListItem = (field: RentalPriceGroupItemResponseBody) =>
    openDeleteDialog({
      onConfirm: async () =>
        await deletePriceGroupItem({
          rentalPriceGroupItemId: field.rentalPriceGroupItemId,
          rentalPriceGroupId: props.rentalPriceGroupId,
        })
          .unwrap()
          .then(() => {
            showNotification.success(i18n.t('general.notifications.successfullyDeleted'));
          })
          .catch(handleApiError),
    });

  const filteredAndSortedFields = props.items
    .filter((item) => {
      if (isNil(searchString) || isEmpty(searchString)) {
        return true;
      }

      return values(item).some((value) => value.toString().includes(searchString));
    })
    .sort((a, b) => a.range.from - b.range.from);

  return (
    <VStack maxWidth="100%" spacing={6}>
      <Heading size={4}>{i18n.t('entity.rental.labels.priceList')}</Heading>

      <DataStatus
        isEmpty={isEmpty(filteredAndSortedFields)}
        minHeight={75}
        action={{
          title: i18n.t('entity.rental.labels.newPriceList'),
          leftIcon: 'content/add_circle',
          onClick: handleAddNewPriceListItem,
        }}
      >
        <VStack spacing={4}>
          <HStack justify="space-between" width="100%" align="flex-end">
            <Search value={searchString} onChange={setSearchString} />
            <Button
              variant="link"
              title={i18n.t('entity.rental.labels.newPriceList')}
              leftIcon="content/add_circle"
              onClick={handleAddNewPriceListItem}
            />
          </HStack>

          <Table
            columns={getPriceListColumns(formatCurrencySymbol(tenantCurrency))}
            variant="bordered"
          >
            {filteredAndSortedFields.map((item) => (
              <TableRow
                key={item.rentalPriceGroupItemId}
                onRowClick={() => handleEditPriceListItem(item)}
                actionsWidth="5%"
                actions={{
                  primary: [
                    {
                      icon: 'image/edit',
                      onClick: () => handleEditPriceListItem(item),
                      title: i18n.t('general.actions.edit'),
                    },
                    {
                      icon: 'action/delete',
                      onClick: () => handleRemovePriceListItem(item),
                      title: i18n.t('general.actions.remove'),
                      severity: 'danger',
                    },
                  ],
                }}
              >
                <Box padding={2}>
                  <Text alternative>{item.name || EMPTY_PLACEHOLDER}</Text>
                </Box>
                <Box padding={2}>
                  <Text>{formatNumber(item.range.from)}</Text>
                </Box>
                <Box padding={2}>
                  <Text>{formatNumber(item.range.to)}</Text>
                </Box>
                <Box padding={2}>
                  <Text>{i18n.t(`entity.rental.labels.${item.range.period}`)}</Text>
                </Box>
                <Box padding={2}>
                  <Text>{formatCurrency(parseFloat(item.price.rate), tenantCurrency)}</Text>
                </Box>
                <Box padding={2}>
                  <Text>
                    {formatCurrency(parseFloat(item.price.ratePerKilometer), tenantCurrency, 2)}
                  </Text>
                </Box>
                <Box padding={2}>
                  <Text>{formatNumber(item.price.includedKilometers)} Km</Text>
                </Box>
              </TableRow>
            ))}
          </Table>
        </VStack>
      </DataStatus>
    </VStack>
  );
}
