import {Icon} from 'platform/foundation';

import {filter, isNotNil} from 'ramda';

import {MenuItemProps, UserSidebarIconSize} from '@dms/api/shared';
import {usePinUserMenuItemMutation, useUnpinUserMenuItemMutation} from '@dms/api/user';
import {handleApiError} from '@dms/shared';

import {pushGtmEvent, suffixTestId} from 'shared';

import {SIDEBAR_DESKTOP_MENU_ICON_SIZE} from '../constants/sidebarDesktopMenuIconSize';
import {SIDEBAR_MOBILE_ICON_SIZE} from '../constants/sidebarMobileIconSize';
import {useIsMobileNavigation} from '../hooks/useIsMobileNavigation';
import {MenuItemWithPin} from '../utils/addPinsToMenuItems';
import {MenuItemActionButton} from './MenuItemActionButton';

interface MenuItemPinProps
  extends Pick<MenuItemWithPin<MenuItemProps>, 'path' | 'id' | 'isPinned' | 'legacyPinId'> {
  iconSize: UserSidebarIconSize;
}

export function MenuItemPin(props: MenuItemPinProps) {
  const [unpinUserMenuItem] = useUnpinUserMenuItemMutation();
  const [pinUserMenuItem] = usePinUserMenuItemMutation();
  const isMobileNavigation = useIsMobileNavigation();

  const testIdBase = `routePath=${props.path};id=${props.id}-menuItem`;

  // Because of difference in logic from legacy sidebar, we need to do two checks.
  // `props.isPinned` represents the new way of pinning items,
  // while `props.legacyPinId` represents the old way.
  const isPinned = props.isPinned || Boolean(props.legacyPinId);

  const handlePin = () => {
    if (!isPinned) {
      return pinUserMenuItem({item: props.id})
        .unwrap()
        .then(() => {
          pushGtmEvent({
            event: 'button_click',
            event_id: 'sidebar_pin_item',
            value: props.id,
          });
        })
        .catch(handleApiError);
    }

    const idsToUnpin = filter(isNotNil, [
      props.legacyPinId, // Unpin legacy pinned item
      props.isPinned ? props.id : null,
    ]);
    for (const pinId of idsToUnpin) {
      unpinUserMenuItem({item: pinId})
        .unwrap()
        .then(() => {
          pushGtmEvent({
            event: 'button_click',
            event_id: 'sidebar_unpin_item',
            value: pinId,
          });
        })
        .catch(handleApiError);
    }
  };

  return (
    <MenuItemActionButton
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        handlePin();
      }}
    >
      <Icon
        size={
          isMobileNavigation
            ? SIDEBAR_MOBILE_ICON_SIZE
            : SIDEBAR_DESKTOP_MENU_ICON_SIZE[props.iconSize]
        }
        color="text.white"
        value={isPinned ? 'custom/push_pin' : 'custom/push_pin_outlined'}
        data-testid={suffixTestId('pin', {'data-testid': testIdBase})}
      />
    </MenuItemActionButton>
  );
}
