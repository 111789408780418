import {openDialog, showNotification, TableRowAction} from 'platform/components';

import {
  TemplateResponseBodyV2,
  useDeleteDocumentTemplateMutation,
  useSetPrimaryTemplateMutation,
  useHideSystemTemplateMutation,
  useUnhideSystemTemplateMutation,
} from '@dms/api/document';
import i18n from '@dms/i18n';
import {downloadFile, handleApiError} from '@dms/shared';

import {buildArray} from 'shared';

import {UploadDocumentTemplateForm} from '../components/UploadDocumentTemplateForm';

export const useTemplateActions = (template: TemplateResponseBodyV2): TableRowAction[] => {
  const isPrimary = template.primary;
  const isSystem = template.system;
  const isHidden = template.hidden;

  const fileType = template.fileName.split('.').pop()?.toUpperCase();

  const [deleteTemplate] = useDeleteDocumentTemplateMutation();
  const [setTemplateAsPrimary] = useSetPrimaryTemplateMutation();
  const [hideTemplate] = useHideSystemTemplateMutation();
  const [showTemplate] = useUnhideSystemTemplateMutation();

  const handleSetPrimary = () =>
    setTemplateAsPrimary({
      setPrimaryTemplateRequestBody: {templateId: template.id},
      documentKindCode: template.documentKindCode,
    })
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

  const handleDelete = () =>
    deleteTemplate({templateId: template.id})
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

  const handleHide = () =>
    hideTemplate({templateId: template.id})
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

  const handleShow = () =>
    showTemplate({templateId: template.id})
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

  const handleEdit = () =>
    openDialog(
      <UploadDocumentTemplateForm
        template={template}
        documentKindCode={template.documentKindCode}
      />,
      {
        title: i18n.t('entity.documents.labels.uploadEditTemplateDialogTitle'),
      }
    );

  const handleDownload = () => downloadFile(template.url, {fileName: template.fileName});

  return buildArray<TableRowAction>()
    .add({
      icon: 'file/download',
      title: `${i18n.t('entity.document.labels.downloadAs')} ${fileType}`,
      onClick: handleDownload,
    })
    .when(!isPrimary && !isHidden, {
      icon: 'action/check_circle',
      title: i18n.t('entity.document.actions.setPrimary'),
      severity: 'success',
      onClick: handleSetPrimary,
    })
    .when(!isPrimary && !isHidden, {
      icon: 'action/visibility_off',
      title: i18n.t('general.actions.hide'),
      onClick: handleHide,
    })
    .when(!isPrimary && isHidden, {
      icon: 'action/visibility',
      title: i18n.t('general.actions.unhide'),
      onClick: handleShow,
    })
    .whenNot(isSystem, {
      icon: 'image/edit',
      title: i18n.t('general.actions.edit'),
      onClick: handleEdit,
    })
    .whenNot(isSystem, {
      icon: 'action/delete',
      title: i18n.t('general.actions.delete'),
      severity: 'danger',
      onClick: handleDelete,
    });
};
