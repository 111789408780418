import {closeCurrentDialog, openDialog} from 'platform/components';

import {useRef} from 'react';
import {Helmet} from 'react-helmet-async';

import {not} from 'ramda';

import i18n from '@dms/i18n';
import {testIds, warehouseRoutes} from '@dms/routes';
import {Main, PageTab, usePermissions} from '@dms/shared';

import {useNavigate} from 'shared';

import {DeliveryNoteList, DeliveryNoteListHandle} from '../DeliveryNoteList/DeliveryNoteList';
import {
  ReceiveNoteCorrectionsHandle,
  ReceiveNoteCorrectionsList,
} from '../ReceiveCorrectionsList/ReceiveNoteCorrectionsList';
import {DownloadNew} from './components/DownloadNew';
import {ReceiveNoteList, ReceiveNoteListHandle} from './components/ReceiveNoteList';

export function ReceiveNoteLists() {
  const navigate = useNavigate();

  const [canCreateReceiveNote] = usePermissions({permissionKeys: ['receiveNoteCreate']});

  const deliveryNoteListRef = useRef<DeliveryNoteListHandle>(null);
  const receiveNoteListHandleRef = useRef<ReceiveNoteListHandle>(null);
  const receiveNoteCorrectionsListHandleRef = useRef<ReceiveNoteCorrectionsHandle>(null);

  const tabs: PageTab[] = [
    {
      id: 'deliveryNotes',
      title: i18n.t('page.warehouse.labels.deliveryNotes'),
      content: <DeliveryNoteList ref={deliveryNoteListRef} />,
      href: warehouseRoutes.deliveryNoteList,
    },
    {
      id: 'receiveNotes',
      title: i18n.t('page.warehouse.labels.receiveNotes'),
      content: <ReceiveNoteList ref={receiveNoteListHandleRef} />,
      href: warehouseRoutes.receiveNoteList,
    },
    {
      id: 'receiveCorrections',
      title: i18n.t('page.warehouse.labels.receiveCorrections'),
      content: <ReceiveNoteCorrectionsList ref={receiveNoteCorrectionsListHandleRef} />,
      href: warehouseRoutes.receiveNoteCorrectionsList,
    },
  ];

  const handleDownloadNew = () => {
    openDialog(
      <DownloadNew
        onDiscard={closeCurrentDialog}
        onSave={() => {
          closeCurrentDialog();
          deliveryNoteListRef.current?.refreshDataGrid();
          receiveNoteListHandleRef.current?.refreshDataGrid();
          receiveNoteCorrectionsListHandleRef.current?.refreshDataGrid();
        }}
        data-testid={testIds.warehouse.receiveNoteList('downloadNewDialog')}
      />,
      {
        size: 'small',
        title: i18n.t('entity.warehouse.labels.downloadNew'),
      }
    );
  };

  return (
    <>
      <Helmet title={i18n.t('module.warehouse.title')} />
      <Main
        isFullHeight
        tabs={tabs}
        actions={[
          {
            type: 'button',
            title: i18n.t('entity.warehouse.labels.downloadNew'),
            onClick: handleDownloadNew,
            'data-testid': testIds.warehouse.receiveNoteList('headerActions.downloadNew'),
          },
          {
            type: 'buttonWithTooltip',
            title: i18n.t('entity.warehouse.labels.newReceiveNote'),
            onClick: () => navigate(warehouseRoutes.receiveNoteCreate),
            isDisabled: not(canCreateReceiveNote),
            tooltip: {
              label: i18n.t('general.labels.noPermission'),
              isDisabled: canCreateReceiveNote,
            },
            'data-testid': testIds.warehouse.receiveNoteList('headerActions.new'),
          },
        ]}
        data-testid={testIds.warehouse.receiveNoteList('createReceiveNote')}
      />
    </>
  );
}
