import {Card, DataStatus} from 'platform/components';
import {VStack, Show} from 'platform/foundation';

import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetHandlingUnitQuery} from '@dms/api/metadaWarehouseHandlingUnit';

import {RequiredTestIdProps} from 'shared';

import {EitherQuantityOrError} from '../../../types/basket/EitherQuantityOrError';
import {JobChange} from '../../../types/basket/JobChange';
import {MappedBasketItem} from '../../../types/basket/MappedBasketItem';
import {MaterialBasketItem} from '../types/MaterialBasketItem';
import {MaterialBasketItemEditingDetails} from '../types/MaterialBasketItemEditingDetails';
import {AfterSalesMaterialBasketItemJobChange} from './AfterSalesMaterialBasketItemJobChange';
import {AfterSalesMaterialBasketItemQuantityWithPrice} from './AfterSalesMaterialBasketItemQuantityWithPrice';
import {AfterSalesMaterialBasketItemTitleWithActions} from './AfterSalesMaterialBasketItemTitleWithActions';

interface AfterSalesMaterialBasketItemProps<T extends MaterialBasketItem>
  extends RequiredTestIdProps {
  item: MappedBasketItem<T>;
  onSelect: (itemId: string, isSelected: boolean) => void;
  onQuantityChange: (itemId: string, quantity: EitherQuantityOrError) => Promise<void>;
  onEdit: (editingDetails: MaterialBasketItemEditingDetails) => void;
  onDelete: (itemsIds: string[]) => Promise<void>;
  jobChange?: JobChange;
  isPriceHidden?: boolean;
}

export function AfterSalesMaterialBasketItem<T extends MaterialBasketItem>(
  props: AfterSalesMaterialBasketItemProps<T>
) {
  const {data: handlingUnit, isLoading: isHandlingUnitLoading} = useGetHandlingUnitQuery(
    {id: props.item.unit as string},
    {skip: isNilOrEmpty(props.item.unit)}
  );

  return (
    <Card variant="inlineGrey" data-testid={props['data-testid']}>
      <DataStatus isLoading={isHandlingUnitLoading} minHeight={25}>
        <VStack spacing={1}>
          <AfterSalesMaterialBasketItemTitleWithActions
            item={props.item}
            onSelect={props.onSelect}
            onEdit={props.onEdit}
            onDelete={props.onDelete}
            jobChange={props.jobChange}
            data-testid={props['data-testid']}
          />
          <AfterSalesMaterialBasketItemQuantityWithPrice
            item={props.item}
            handlingUnit={handlingUnit?.name}
            onQuantityChange={props.onQuantityChange}
            isPriceHidden={props.isPriceHidden}
            data-testid={props['data-testid']}
          />
          <Show when={props.jobChange?.canPerformJobChange}>
            <AfterSalesMaterialBasketItemJobChange
              item={props.item}
              jobChange={props.jobChange}
              data-testid={props['data-testid']}
            />
          </Show>
        </VStack>
      </DataStatus>
    </Card>
  );
}
