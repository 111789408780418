import {Action, Button, Card, DataStatus, TabProps, Tabs, useDialog} from 'platform/components';
import {Box, FlexboxJustify, Hide, HStack, Show, Space} from 'platform/foundation';
import {match, Pattern} from 'ts-pattern';

import {FC, useCallback} from 'react';

import {always, isNil, mergeAll, uniqBy, values} from 'ramda';
import {isNilOrEmpty, isNonEmptyArray, isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {useGetBusinessCaseQuery} from '@dms/api/businessCase';
import {
  CheckoutContractInformationBody,
  CheckoutSubEntityClass,
  OrderPaymentResponseBody,
  useGetCheckoutDocumentNoteQuery,
  useGetCheckoutPurchaseBrokerageTemplatesQuery,
  useGetCheckoutPurchaseTemplatesQuery,
  useGetCheckoutSaleTemplatesQuery,
  useGetOrderQuery,
  usePutCheckoutDocumentNoteMutation,
} from '@dms/api/checkout';
import {useGetCustomerV2Query, useListCustomerContractInformationsQuery} from '@dms/api/customer';
import {useGetConsentTypesQuery} from '@dms/api/gdpr';
import {useGetParticipationQuery} from '@dms/api/participation';
import {EntityResourceIds} from '@dms/api/shared';
import {useGetVehicleQuery} from '@dms/api/vehicle';
import featureFlags from '@dms/feature-flags';
import {useGetDocumentDatagridActions} from '@dms/features/document-context';
import i18n from '@dms/i18n';
import {
  handleApiError,
  queryParams,
  UploadDocumentsDialog,
  usePermissions,
  usePricePermissions,
  useTenant,
} from '@dms/shared';
import {
  FileDataTypeEnum,
  LegalFormEnum,
  NoteRequestBody,
  OrderDiscriminatorEnum,
  PaymentStateEnum,
} from '@dms/teas';

import {
  buildArray,
  Nullish,
  suffixTestId,
  TestIdProps,
  useQueryState,
  useRequiredParams,
} from 'shared';

import {DataGrid, QueryFilterObject, useRefreshDataGrid} from 'features/datagrid';

import {CheckoutOrderBilling} from './Billing/CheckoutOrderBilling';
import {Documents} from './Documents';
import {CheckoutOrderPayment} from './Payment/CheckoutOrderPayments';
import {TAB_IDS} from './tabIds';
import {CheckoutOrderProps} from './types/CheckoutOrderProps';

const TAB_IDS_VALUES = values(TAB_IDS);
const GRID_REFRESH_TIMEOUT = 2000;

export const CheckoutOrder: FC<CheckoutOrderProps & TestIdProps> = ({
  allOrders,
  documents,
  isBrokeredSale,
  isBrokerage,
  handleCreateDocuments,
  refreshDocumentContext,
  isLoadingDocuments,
  invoicable,
  shouldHidePaymentTab,
  ...props
}) => {
  const {tenantCountry, isTenantCountryRestricted} = useTenant();
  const {id: businessCaseId} = useRequiredParams();
  const {data: order} = useGetOrderQuery({orderId: props.order.id, checkoutId: props.checkoutId});
  const {data: customer} = useGetCustomerV2Query({customerId: props.customerId});
  const {data: customerContractInformation} = useListCustomerContractInformationsQuery({
    customerId: props.customerId,
  });

  const {data: businessCase} = useGetBusinessCaseQuery({businessCaseId});
  const isPurchaseBusinessCase =
    businessCase?.businessCaseType === 'BUYING' ||
    businessCase?.businessCaseType === 'PURCHASE_BROKERAGE';
  const isSaleBusinessCase = businessCase?.businessCaseType === 'SELLING';

  const vehicleId = match<[boolean, boolean], string | Nullish>([
    isPurchaseBusinessCase,
    isSaleBusinessCase,
  ])
    .with([true, false], always(businessCase?.offers?.[0]?.purchaseVehicles?.[0]?.vehicleId))
    .with([false, true], always(businessCase?.offers?.[0]?.saleVehicles?.[0]?.vehicleId))
    .otherwise(always(null));

  const {data: vehicleData} = useGetVehicleQuery(
    {vehicleId: vehicleId ?? ''},
    {skip: isNil(vehicleId)}
  );

  const {data: vehicleParticipation} = useGetParticipationQuery(
    {
      resourceId: EntityResourceIds.vehicle,
      recordId: vehicleId ?? '',
    },
    {skip: isNilOrEmpty(vehicleId)}
  );

  const {data: businessCaseParticipation} = useGetParticipationQuery({
    recordId: businessCaseId,
    resourceId: EntityResourceIds.businessCase,
  });

  const [
    canReadPaymentInformation,
    canGenerateSaleDocuments,
    canGeneratePurchaseDocuments,
    vehicleEnableLeasingAndCreditCompany,
    vehicleDisableLeasingAndCreditCompany,
    vehicleSetLeasingAndCreditCompany,
    vehicleSetFleetInsurance,
    vehicleUpdateStorageSpace,
    canReadDocuments,
  ] = usePermissions({
    permissionKeys: [
      'readCheckoutPaymentInformation',
      'businessCaseSaleGenerateDocuments',
      'generateBusinessCasePurchaseDocuments',
      'vehicleEnableLeasingAndCreditCompany',
      'vehicleDisableLeasingAndCreditCompany',
      'vehicleSetLeasingAndCreditCompany',
      'vehicleSetFleetInsurance',
      'vehicleUpdateStorageSpace',
      'readDocuments',
    ],
    scopes: {
      vehicleEnableLeasingAndCreditCompany: {
        participation: vehicleParticipation,
        branchId: vehicleData?.branchId,
      },
      vehicleDisableLeasingAndCreditCompany: {
        participation: vehicleParticipation,
        branchId: vehicleData?.branchId,
      },
      vehicleSetLeasingAndCreditCompany: {
        participation: vehicleParticipation,
        branchId: vehicleData?.branchId,
      },
      vehicleSetFleetInsurance: {
        participation: vehicleParticipation,
        branchId: vehicleData?.branchId,
      },
      vehicleUpdateStorageSpace: {
        participation: vehicleParticipation,
        branchId: vehicleData?.branchId,
      },
      businessCaseSaleGenerateDocuments: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      generateBusinessCasePurchaseDocuments: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
    },
  });

  const canEditSaleVehicle =
    vehicleEnableLeasingAndCreditCompany &&
    vehicleDisableLeasingAndCreditCompany &&
    vehicleSetLeasingAndCreditCompany &&
    vehicleSetFleetInsurance &&
    vehicleUpdateStorageSpace;

  const {canReadVehicleSellingPrice, canReadVehiclePurchasePrice} = usePricePermissions({
    vehicleRecordId: vehicleId,
    businessCaseRecordId: null,
  });

  const [activeTabId, setActiveTabId] = useQueryState(
    `${props.order.orderDiscriminator}-${queryParams.BUSINESS_CASE_CHECKOUT_ORDER}`
  );

  const isFirstTabActive = isNil(activeTabId) || activeTabId === TAB_IDS.BILLING_INFORMATION;
  const isLastTabActive = activeTabId === TAB_IDS.PAYMENT;
  const isDocumentTabActive = activeTabId === TAB_IDS.DOCUMENTS && canReadDocuments;

  const moveToNextTab = () => {
    const activeTabIndex = isNotNil(activeTabId)
      ? TAB_IDS_VALUES.findIndex((item) => item === activeTabId)
      : 0;

    if (!canReadDocuments && isFirstTabActive) {
      setActiveTabId(TAB_IDS.PAYMENT);
      return;
    }

    if (activeTabIndex === TAB_IDS_VALUES.length) {
      return;
    }

    setActiveTabId(TAB_IDS_VALUES[activeTabIndex + 1]);
  };

  const moveToPreviousTab = () => {
    const activeTabIndex = TAB_IDS_VALUES.findIndex((item) => item === activeTabId);
    if (activeTabIndex === 0) {
      return;
    }

    if (!canReadDocuments && isLastTabActive) {
      setActiveTabId(TAB_IDS.BILLING_INFORMATION);
      return;
    }

    setActiveTabId(TAB_IDS_VALUES[activeTabIndex - 1]);
  };

  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const [isUploadDialogOpen, openUploadDialog, closeUploadDialog] = useDialog();

  const {data: consentTypes} = useGetConsentTypesQuery();
  const {data: purchaseBrokerageTemplates = [], isLoading: isLoadingPurchaseBrokaregeTemplates} =
    useGetCheckoutPurchaseBrokerageTemplatesQuery(undefined, {
      skip:
        props.order.orderDiscriminator === 'SALE' || props.order.orderDiscriminator === 'PURCHASE',
    });
  const {data: saleTemplates = [], isLoading: isLoadingSaleTemplates} =
    useGetCheckoutSaleTemplatesQuery(undefined, {skip: props.order.orderDiscriminator !== 'SALE'});
  const {data: purchaseTemplates = [], isLoading: isLoadingPurchaseTemplates} =
    useGetCheckoutPurchaseTemplatesQuery(undefined, {
      skip: props.order.orderDiscriminator !== 'PURCHASE',
    });

  const {data: purchaseContract} = useGetCheckoutDocumentNoteQuery({
    orderId: props.order.id,
    subEntityClass: 'PURCHASE_CONTRACT',
  });
  const {data: purchaseBrokerageContract} = useGetCheckoutDocumentNoteQuery({
    orderId: props.order.id,
    subEntityClass: 'PURCHASE_BROKERAGE_CONTRACT',
  });
  const {data: saleContract} = useGetCheckoutDocumentNoteQuery({
    orderId: props.order.id,
    subEntityClass: 'SALE_CONTRACT',
  });
  const {data: saleBrokerageContract} = useGetCheckoutDocumentNoteQuery({
    orderId: props.order.id,
    subEntityClass: 'SALE_BROKERAGE_CONTRACT',
  });

  const [updateDocumentNote] = usePutCheckoutDocumentNoteMutation();

  const documentNotes = {
    'purchase-contract': purchaseContract,
    'purchase-brokerage-contract': purchaseBrokerageContract,
    'sale-contract': saleContract,
    'sale-brokerage-contract': saleBrokerageContract,
  };

  const documentTemplates = [...purchaseBrokerageTemplates, ...saleTemplates, ...purchaseTemplates];

  const isLoading =
    isLoadingPurchaseBrokaregeTemplates || isLoadingSaleTemplates || isLoadingPurchaseTemplates;

  const consentTypeTemplates = consentTypes?.map((consentType) => ({
    id: consentType.id,
    type: FileDataTypeEnum.GDPR_MARKETING_CONSENT,
    title: consentType.name,
    templates: consentType.templates.map((template) => ({
      ...template,
      note: null,
      id: template.fileId ?? '',
      primary: template.isPrimary,
    })),
  }));

  const templates = documentTemplates
    ?.filter(
      (documentTemplate) =>
        (documentTemplate.type !== 'sale-brokerage-contract' || isBrokeredSale) &&
        (documentTemplate.type !== 'sale-contract' || !isBrokeredSale)
    )
    .concat(
      order?.contractInformation?.customerContractInformation?.legalForm ===
        LegalFormEnum.NATURAL_PERSON && consentTypeTemplates
        ? consentTypeTemplates
        : []
    );

  const isBrokerageFees =
    order?.orderDiscriminator === OrderDiscriminatorEnum.PURCHASE_BROKERAGE_FEES;

  const hasAllDocumentsGenerated: boolean =
    isNonEmptyArray(documents) &&
    uniqBy((template) => template.type, templates ?? [])?.every((template) =>
      documents?.some((document) => template.type === document.documentType)
    );

  const isAtLeastOneDocumentGenerated = documents?.some(
    (document) => document.documentType !== FileDataTypeEnum.GDPR_MARKETING_CONSENT
  );

  const paymentTabIcon = order?.payments?.every(isPaymentPaid) ? 'success' : 'pending';

  const documentTabIcon: TabProps['icon'] =
    hasAllDocumentsGenerated || isAtLeastOneDocumentGenerated ? 'success' : 'pending';

  const justifyNav = match<[boolean, boolean], FlexboxJustify>([isFirstTabActive, isLastTabActive])
    .with([true, Pattern.any], always('flex-end'))
    .with([false, true], always('flex-start'))
    .otherwise(always('space-between'));

  const handleNoteChange = async (
    subEntityClass: CheckoutSubEntityClass,
    noteRequestBody: NoteRequestBody
  ) => {
    await updateDocumentNote({noteRequestBody, orderId: props.order.id, subEntityClass})
      .unwrap()
      .catch(handleApiError);
  };

  const [actions, openBulkSignatureStepper] = useGetDocumentDatagridActions({
    refreshData: refreshDataGrid,
    customerId: customer?.id,
    contextTarget: 'business-case',
    contextId: businessCaseId,
  });

  const queryModifier = useCallback(
    (filter: QueryFilterObject) =>
      mergeAll([filter, {businessCaseId: props.documentContextId ?? ''}]),
    [props.documentContextId]
  );

  const isDocumentUploadSupported = isNotNil(props.documentContextTarget);

  const onDocumentCreated = () => {
    // TODO T20-34493
    // refreshDataGrid();
    refreshDocumentContext?.();

    // TODO ugly temporary fix with timeout to show document print icon
    setTimeout(refreshDataGrid, GRID_REFRESH_TIMEOUT);
  };

  const tabs = buildArray<TabProps>()
    .when(isNotNil(order?.contractInformation) && isNotNilOrEmpty(customerContractInformation), {
      id: TAB_IDS.BILLING_INFORMATION,
      title: i18n.t('entity.checkout.labels.billingInformation'),
      'data-testid': suffixTestId('billingInformation', props),
      icon: 'success',
      isDisabled: props.disabledTabIds?.includes('BILLING_INFORMATION'),
      content: (
        <CheckoutOrderBilling
          {...props}
          businessCase={businessCase}
          customerContractInformation={customerContractInformation!}
          checkoutContractsInformation={
            order?.contractInformation as CheckoutContractInformationBody
          }
          data-testid={suffixTestId('billingInformation', props)}
        />
      ),
    })
    .when(canReadDocuments && !isBrokerageFees, {
      id: TAB_IDS.DOCUMENTS,
      title: i18n.t('entity.checkout.labels.documents'),
      icon: documentTabIcon,
      isDisabled: props.disabledTabIds?.includes('DOCUMENTS'),
      'data-testid': suffixTestId('documents', props),
      content: (
        <>
          <DataStatus isLoading={isLoading}>
            <Documents
              emptySubHeadline={i18n.t('entity.businessCase.labels.allIssuedDocuments')}
              handleCreateDocuments={handleCreateDocuments(props.order.id, onDocumentCreated)}
              isCreateDisabled={
                order?.orderDiscriminator === OrderDiscriminatorEnum.PURCHASE ||
                order?.orderDiscriminator === OrderDiscriminatorEnum.PURCHASE_BROKERAGE_FEES ||
                order?.orderDiscriminator === OrderDiscriminatorEnum.PURCHASE_BROKERAGE_SALE
                  ? !canGeneratePurchaseDocuments
                  : !canGenerateSaleDocuments
              }
              isLoading={isLoadingDocuments}
              onNoteChange={handleNoteChange}
              documentNotes={documentNotes}
              documents={documents}
              businessCase={businessCase}
              templates={templates}
              {...props}
              data-testid={suffixTestId('documents', props)}
            />
          </DataStatus>
          <Show when={isNotNil(props.documentContextId)}>
            <Space vertical={4} />
            <Show when={isDocumentUploadSupported}>
              <UploadDocumentsDialog
                isOpen={isUploadDialogOpen}
                handleClose={closeUploadDialog}
                onDocumentCreated={onDocumentCreated}
                contextTarget={props.documentContextTarget!}
                contextId={props.documentContextId!}
                bulkContext={props.documentBulkContext}
              />
            </Show>
            <Card
              title={i18n.t('page.businessCase.documents.title')}
              actions={buildArray<Action>()
                .when(isDocumentUploadSupported, {
                  type: 'button',
                  variant: 'link',
                  leftIcon: 'action/backup',
                  title: i18n.t('entity.document.actions.uploadDocument'),
                  onClick: openUploadDialog,
                })
                .whenFeatureEnabled(featureFlags.DIGITAL_SIGNATURE, {
                  type: 'button',
                  variant: 'link',
                  leftIcon: 'editor/border_color',
                  title: i18n.t('entity.document.actions.signMultipleDocument'),
                  onClick: openBulkSignatureStepper,
                })}
              variant="inlineWhite"
            >
              <Box minHeight={40} key={activeTabId}>
                <DataGrid
                  autoHeight
                  ref={dataGridRef}
                  gridCode="document-context-list-business-case-checkout"
                  actionCallback={actions}
                  queryModifier={queryModifier}
                  data-testid={suffixTestId('dataGrid', props)}
                />
              </Box>
            </Card>
          </Show>
        </>
      ),
    })
    .when(
      !isTenantCountryRestricted(['POL']) &&
        invoicable &&
        canReadPaymentInformation &&
        !shouldHidePaymentTab &&
        ((isSaleBusinessCase && canReadVehicleSellingPrice) ||
          (isPurchaseBusinessCase && canReadVehiclePurchasePrice)),
      {
        id: TAB_IDS.PAYMENT,
        title: i18n.t('entity.checkout.labels.payment'),
        icon: paymentTabIcon,
        isDisabled: props.disabledTabIds?.includes('PAYMENT'),
        'data-testid': suffixTestId('payment', props),
        content: (
          <CheckoutOrderPayment
            allOrders={allOrders}
            tenantCountry={tenantCountry}
            isBrokeredSale={isBrokeredSale}
            isBrokerage={isBrokerage}
            {...props}
            data-testid={suffixTestId('payment', props)}
            isFleetInsuranceDisabled={!canEditSaleVehicle}
          />
        ),
      }
    );

  const isActiveTabIdValid = activeTabId && tabs.some((tab) => tab.id === activeTabId);

  return (
    <Show when={isNotNilOrEmpty(tabs)}>
      <Tabs
        data-testid={props['data-testid']}
        variant="condensed"
        onChange={setActiveTabId}
        activeTabId={isActiveTabIdValid ? activeTabId : tabs[0]?.id}
        tabs={tabs}
      />

      <HStack justify={justifyNav}>
        <Show when={!isFirstTabActive}>
          <Button
            data-testid={suffixTestId('back', props)}
            variant="outlined"
            onClick={moveToPreviousTab}
            leftIcon="navigation/chevron_left"
            title={i18n.t('general.actions.back')}
          />
        </Show>
        <Hide
          when={
            isLastTabActive ||
            (isDocumentTabActive && isTenantCountryRestricted(['POL'])) ||
            (isFirstTabActive && !canReadDocuments && !canReadVehicleSellingPrice) ||
            (isDocumentTabActive && canReadDocuments && !canReadVehicleSellingPrice)
          }
        >
          <Button
            data-testid={suffixTestId('continue', props)}
            onClick={moveToNextTab}
            variant="outlined"
            rightIcon="navigation/chevron_right"
            title={
              isFirstTabActive && !isBrokerageFees && canReadDocuments
                ? i18n.t('entity.businessCase.continueToDocuments')
                : i18n.t('entity.businessCase.continueToPayment')
            }
          />
        </Hide>
      </HStack>
    </Show>
  );
};

const isPaymentPaid = (payment: OrderPaymentResponseBody) =>
  payment.paymentState === PaymentStateEnum.PAID;
