import {Card, Parameters} from 'platform/components';
import {Box, HStack, Icon, VStack, Text, Heading, Clickable} from 'platform/foundation';

import {useMemo} from 'react';

import {useLazyGetDocument360RedirectUrlQuery} from '@dms/api/auth';
import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {omneticReleaseNotes} from '@dms/releaseNotes';
import {AdBanner, AdBannerProps, handleApiError, useTenant} from '@dms/shared';

import {buildArray, pickRandomValueFromArr, useNavigate} from 'shared';

import {HoverableBackground} from './HoverableBackground';

type Alpha3CountryCode = 'POL' | 'CZE';

const SupportLinks = {
  tutorials: {
    POL: 'https://www.youtube.com/playlist?list=PLFuYl-c7Vj0M5wFkI4rhZGBHn_zQcGrRY',
    CZE: 'https://www.youtube.com/playlist?list=PL6D30SkkrGZFkJtDLR9Q-FH3HUHPjIIxw',
  },
  support: {POL: '/contacts/pl', CZE: '/contacts'},
  faq: {POL: '/docs/pl/main-information', CZE: '/zakladni-informace'},
  taskManager: {
    CZE: '/docs/spravce-ukolu',
    POL: '/docs/pl/zarzadzanie-zadaniami',
  },
} satisfies {
  [key: string]: {[Property in Alpha3CountryCode]: string};
};

const SupportParamsByCountryCode = {
  CZE: ['771 125 125', 'podpora@omnetic.com'],
  POL: [i18n.t('page.homepage.description.contactUs')],
} satisfies {
  [Property in Alpha3CountryCode]: string[];
};

const getSupportLinkByCountryCode =
  (countryCode: Alpha3CountryCode) => (key: keyof typeof SupportLinks) =>
    SupportLinks[key]?.[countryCode] ?? SupportLinks[key]?.CZE;

export function Support() {
  const navigate = useNavigate();
  const [getDocument360RedirectUrl, {isLoading}] = useLazyGetDocument360RedirectUrlQuery();
  const {tenantCountry} = useTenant();

  const getSupportLink = getSupportLinkByCountryCode((tenantCountry as Alpha3CountryCode) ?? 'CZE');

  const handleRedirectToDoc360 = (redirectTo: string) =>
    getDocument360RedirectUrl({redirectTo})
      .unwrap()
      .then((res) => res.redirectUrl)
      .then(window.open)
      .catch(handleApiError);

  const latestNews = useMemo(
    () => omneticReleaseNotes.filter((note) => note.countryCodesAlpha3?.includes(tenantCountry)),
    [tenantCountry]
  );

  const latestNewsUrl = latestNews?.[0]?.urlByCountry?.[tenantCountry] ?? latestNews?.[0]?.url;

  const taskAndBannerNews =
    latestNews?.find((note) => note.url === '/docs/news-2025-03') ?? latestNews?.[0];

  const taskAndBannerNewsUrl =
    taskAndBannerNews?.urlByCountry?.[tenantCountry] ?? taskAndBannerNews?.url;

  const onClickOpenNews = latestNews ? () => handleRedirectToDoc360(latestNewsUrl) : undefined;
  const onClickOpenVideos = () => window.open(getSupportLink('tutorials'));
  const onClickOpenSupport = () => handleRedirectToDoc360(getSupportLink('support'));
  const onClickOpenTaskManager = () => handleRedirectToDoc360(getSupportLink('taskManager'));
  const onClickOpenFAQ = () => handleRedirectToDoc360(getSupportLink('faq'));
  // Photography page does not have any other language variant than Czech
  const onClickOpenPhotograpyPromo = () => window.open(photography);

  const Ads = buildArray<AdBannerProps>([
    {
      heading: i18n.t('page.homepage.ad.PhotoBackgroundReplacement'),
      subheading: i18n.t('page.homepage.ad.PerfectPhotosWithAI'),
      bgImage: 'bgRemoval',
      onClick: onClickOpenPhotograpyPromo,
    },
  ])
    .whenFeatureEnabled(featureFlags.CORE_TASK_MANAGEMENT_BANNER, {
      heading: i18n.t('page.homepage.ad.TaskManagement'),
      subheading: i18n.t('page.homepage.ad.TaskManagementDescription'),
      bgImage: 'taskManagement',
      onClick: onClickOpenTaskManager,
    })
    .whenFeatureEnabled(featureFlags.CORE_DIGISIGN_BANNER, {
      heading: i18n.t('page.homepage.ad.DigitalSignature'),
      subheading: i18n.t('page.homepage.ad.DigitalSignatureDescription'),
      bgImage: 'digitalSignature',
      onClick: () =>
        taskAndBannerNewsUrl ? handleRedirectToDoc360(taskAndBannerNewsUrl) : undefined,
    });

  return (
    <Card title={i18n.t('page.homepage.labels.support')} hasSeparator={false}>
      <Box minHeight={55}>
        <HStack spacing={5}>
          <AdBanner {...pickRandomValueFromArr(Ads)!} />

          <VStack spacing={5} grow={1}>
            <Clickable onClick={onClickOpenNews} isDisabled={isLoading}>
              <HoverableBackground>
                <HStack spacing={5} align="center">
                  <Icon value="content/rss" size={8} />
                  <VStack grow={1}>
                    <Heading size={4}>{i18n.t('page.homepage.labels.news')}</Heading>
                    <Text color="tertiary" size="xSmall">
                      {i18n.t('page.homepage.description.latestNews', {
                        date: omneticReleaseNotes?.[0].dateTime,
                      })}
                    </Text>
                  </VStack>
                  <Icon value="navigation/chevron_right" size={4} />
                </HStack>
              </HoverableBackground>
            </Clickable>
            <Clickable onClick={onClickOpenVideos}>
              <HoverableBackground>
                <HStack spacing={5} align="center">
                  <Icon value="content/run_mirror" size={8} />
                  <VStack grow={1}>
                    <Heading size={4}>{i18n.t('page.homepage.labels.tutorials')}</Heading>
                    <Text color="tertiary" size="xSmall">
                      {i18n.t('page.homepage.description.tutorials')}
                    </Text>
                  </VStack>
                  <Icon value="navigation/chevron_right" size={4} />
                </HStack>
              </HoverableBackground>
            </Clickable>
            <Clickable onClick={onClickOpenSupport}>
              <HoverableBackground>
                <HStack spacing={5} align="center">
                  <Icon value="content/customer_service" size={8} />
                  <VStack grow={1}>
                    <Heading size={4} data-testid="supportHeading">
                      {i18n.t('page.homepage.labels.support')}
                    </Heading>
                    <Parameters
                      data-testid="supportParameters"
                      parameters={SupportParamsByCountryCode[tenantCountry as Alpha3CountryCode]}
                      size="xSmall"
                      color="tertiary"
                    />
                  </VStack>
                  <Icon value="navigation/chevron_right" size={4} />
                </HStack>
              </HoverableBackground>
            </Clickable>
            <Clickable onClick={onClickOpenFAQ}>
              <HoverableBackground>
                <HStack spacing={5} align="center">
                  <Icon value="content/notebook" size={8} />
                  <VStack grow={1}>
                    <Heading size={4}>{i18n.t('page.homepage.labels.faq')}</Heading>
                    <Text color="tertiary" size="xSmall">
                      {i18n.t('page.homepage.description.faq')}
                    </Text>
                  </VStack>
                  <Icon value="navigation/chevron_right" size={4} />
                </HStack>
              </HoverableBackground>
            </Clickable>
          </VStack>
        </HStack>
      </Box>
    </Card>
  );
}

const photography = 'https://www.omnetic.com/cs/fotografie/';
