import {isNotEmpty} from 'ramda';

import {omneticApi} from '@dms/api/core';

export const publishingMultibranchApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    isPublishingMultiBranchEnabled: build.query<boolean, void>({
      query: () => ({
        url: `/dms/v1/settings/publishing-multi-branch/tenant`,
      }),
      transformResponse: (response: {value: boolean}[]) =>
        isNotEmpty(response) && response[0].value,
    }),
    setPublishingMultiBranchEnabled: build.mutation<void, boolean>({
      query: (enabled) => ({
        url: `/dms/v1/settings/publishing-multi-branch/tenant`,
        method: 'POST',
        body: {value: enabled, force: false},
      }),
      onQueryStarted: async (value, {dispatch, queryFulfilled}) => {
        const patchResult = dispatch(
          publishingMultibranchApi.util.updateQueryData(
            'isPublishingMultiBranchEnabled',
            undefined,
            () => value
          )
        );
        await queryFulfilled.catch(patchResult.undo);
      },
    }),
  }),
});

export const {useIsPublishingMultiBranchEnabledQuery, useSetPublishingMultiBranchEnabledMutation} =
  publishingMultibranchApi;
