import {
  Action,
  Actions,
  Alert,
  AlertProps,
  DataStatus,
  Flag,
  FlagProps,
  IconButton,
  openDialog,
  Parameter,
  Parameters,
  PreviewAction,
  Tooltip,
  TooltipProps,
} from 'platform/components';
import {Box, Heading, HStack, Show, Space, Text, ThemeIconKey, VStack} from 'platform/foundation';

import {Fragment} from 'react/jsx-runtime';

import {and, defaultTo, or} from 'ramda';
import {isArray, isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {EntityResourceIds} from '@dms/api/shared';
import {useGetEmailConfigQuery, useGetKonzultaConfigQuery} from '@dms/api/tenant';
import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {usePermissions} from '../../hooks/usePermissions/usePermissions';
import {VehicleConditionEvaluationType} from '../../hooks/useVehicleConditionEvaluation';
import {SendEmail} from '../SendEmail/SendEmail';
import {SendSms} from '../SendSms/SendSms';
import {EntityHeaderControl, EntityHeaderControls} from './components/EntityHeaderControls';
import {EntityHeaderImage} from './components/EntityHeaderImage';
import {ListEditableTags} from './components/labels/ListEditableTags';
import {SnippetProps} from './components/Snippet';
import {SnippetPanel} from './components/SnippetPanel';
import {VehicleConditionEvaluation} from './components/VehicleConditionEvaluation';
import {HeaderPermissions} from './types/headerPermissions';

export interface EntityHeaderFlag extends Omit<FlagProps, 'size'> {
  tooltip?: TooltipProps;
}

export interface EntityHeaderProps extends TestIdProps {
  image?: string;
  icon?: ThemeIconKey;
  iconActions?: PreviewAction[];
  flags?: EntityHeaderFlag[];
  title?: string;
  parameters?: Parameter[];
  primaryParameter?: string;
  secondaryParameter?: string;
  tertiaryParameter?: string;
  snippets?: SnippetProps[];
  isLoadingSnippets?: boolean;
  actions?: Action[];
  resourceId?: EntityResourceIds;
  recordId?: string;
  imageActions?: PreviewAction[];
  controls?: EntityHeaderControl[];
  vehicleConditionEvaluation?: VehicleConditionEvaluationType;
  permissions?: HeaderPermissions;
  alerts?: AlertProps[];
  parkingRecordId?: string;
}

export function EntityHeader(props: EntityHeaderProps) {
  const {data: emailConfig} = useGetEmailConfigQuery();
  const {data: konzultaConfig} = useGetKonzultaConfigQuery();

  const [canReadBusinessCase] = usePermissions({
    permissionKeys: ['businessCaseRead'],
  });

  return (
    <>
      <Box padding={4} backgroundColor="palettes.white.10.100">
        <VStack>
          <HStack spacing={4} align="stretch">
            <EntityHeaderImage
              icon={props.icon}
              image={props.image}
              imageActions={props.imageActions}
              iconActions={props.iconActions}
              data-testid={suffixTestId('entityHeader-image', props)}
            />
            <VStack spacing={2}>
              <Show
                when={or(
                  isNotNil(props.flags),
                  and(or(isNotNil(props.resourceId), isNotNil(props.recordId)))
                )}
              >
                <HStack spacing={1} align="center" wrap>
                  {props.flags?.map((flag: EntityHeaderFlag, index) => (
                    <Tooltip
                      key={`${flag.label}-${flag.colorScheme}-${index}`}
                      {...flag.tooltip}
                      data-testid={suffixTestId(`entityHeader-flags-tooltip-[${index}]`, props)}
                    >
                      <Flag
                        {...flag}
                        data-testid={suffixTestId(`entityHeader-flags-flag-[${index}]`, props)}
                      />
                    </Tooltip>
                  ))}
                  <Show when={props.recordId && props.resourceId}>
                    <ListEditableTags
                      size="default"
                      buttonVariant="secondary"
                      recordId={props.recordId as string}
                      resourceId={props.resourceId as EntityResourceIds}
                      data-testid={suffixTestId('entityHeader-editable-tags', props)}
                      isTagDeletable={props.permissions?.tag?.isDeletable}
                      isTagUpdatable={props.permissions?.tag?.isUpdatable}
                      isTagReadable={props.permissions?.tag?.isReadable}
                      isTagCreatable={props.permissions?.tag?.isCreatable}
                      isTagAssignable={props.permissions?.tag?.isAssignable}
                      isTagUnassignable={props.permissions?.tag?.isUnassignable}
                    />
                  </Show>
                </HStack>
              </Show>
              <Heading size={2} data-testid={suffixTestId('entityHeader-title', props)}>
                {props.title}
              </Heading>
              <HStack spacing={1}>
                <Parameters
                  size="small"
                  color="secondary"
                  parameters={props.parameters}
                  data-testid={suffixTestId('entityHeader-parameters', props)}
                />
                <Show whenFeatureEnabled={featureFlags.ACL_SALES} when={canReadBusinessCase}>
                  <VehicleConditionEvaluation
                    size="small"
                    color="secondary"
                    vehicleCondition={props.vehicleConditionEvaluation}
                    data-testid={suffixTestId('entityHeader-vehicle-condition', props)}
                  />
                </Show>
              </HStack>
              <Show when={and(isNotNil(props.recordId), props?.controls?.length)}>
                <EntityHeaderControls
                  controls={props.controls!}
                  resourceId={props.resourceId}
                  recordId={props.recordId as string}
                  parkingRecordId={props.parkingRecordId}
                  permissions={props.permissions}
                  data-testid={suffixTestId('entityHeader-controls', props)}
                />
              </Show>
            </VStack>
            <Space fillAvailable />
            <VStack align="flex-end" justify="space-between" spacing={2}>
              <Show when={isNotNilOrEmpty(props?.actions)}>
                <Actions
                  actions={props.actions}
                  data-testid={suffixTestId('entityHeader-actions', props)}
                />
              </Show>
              <Show
                when={props.primaryParameter || props.secondaryParameter || props.tertiaryParameter}
              >
                <VStack spacing={1} align="flex-end">
                  <Show when={props.primaryParameter}>
                    <HStack spacing={1} align="center">
                      <Heading
                        size={3}
                        isSingleLine
                        data-testid={suffixTestId('entityHeader-primaryParameter', props)}
                      >
                        {props.primaryParameter}
                      </Heading>
                      <Show
                        when={
                          props.resourceId === 'CUSTOMER' &&
                          props.primaryParameter !== i18n.t('entity.customer.labels.noPhoneNumber')
                        }
                      >
                        <Tooltip
                          label={
                            konzultaConfig?.enabled
                              ? i18n.t('general.actions.sendMessage')
                              : i18n.t('general.notifications.setupSmsProvider')
                          }
                          placement="top-end"
                        >
                          <IconButton
                            severity="informational"
                            data-testid="open-send-message-modal"
                            icon="communication/textsms"
                            isDisabled={!konzultaConfig?.enabled}
                            onClick={() =>
                              openDialog(
                                <SendSms
                                  resourceId={props.resourceId}
                                  recordId={props.recordId}
                                  data-testid={`${props['data-testid']}-sendSmsModal`}
                                  customerId={defaultTo('', props.recordId)}
                                  selectedContactId={defaultTo('', props.recordId)}
                                />,
                                {
                                  scrollBehavior: 'outside',
                                  title: i18n.t('general.labels.smsMessage'),
                                }
                              )
                            }
                          />
                        </Tooltip>
                      </Show>
                      <Show when={isNotNilOrEmpty(props.tertiaryParameter)}>
                        <Heading
                          size={3}
                          isSingleLine
                          data-testid={suffixTestId('entityHeader-tertiaryParameter', props)}
                        >
                          {`/ ${props.tertiaryParameter ?? ''}`}
                        </Heading>
                      </Show>
                    </HStack>
                  </Show>
                  <Show when={props.secondaryParameter}>
                    <HStack spacing={1} align="center">
                      <Text
                        size="base"
                        color="secondary"
                        noWrap
                        data-testid={suffixTestId('entityHeader-secondaryParameter', props)}
                      >
                        {props.secondaryParameter}
                      </Text>
                      <Show
                        when={
                          props.resourceId === 'CUSTOMER' &&
                          props.secondaryParameter !== i18n.t('entity.customer.labels.noEmail')
                        }
                      >
                        <Tooltip
                          label={
                            emailConfig?.enabled
                              ? i18n.t('general.actions.sendEmail')
                              : i18n.t('general.notifications.setupEmailProvider')
                          }
                          placement="top-end"
                        >
                          <IconButton
                            severity="informational"
                            data-testid="open-send-email-modal"
                            icon="communication/email"
                            isDisabled={!emailConfig?.enabled}
                            onClick={() =>
                              openDialog(
                                <SendEmail
                                  resourceId={props.resourceId}
                                  recordId={props.recordId}
                                  data-testid={`${props['data-testid']}-sendEmailModal`}
                                  customerId={props.recordId}
                                />,
                                {
                                  scrollBehavior: 'outside',
                                  title: i18n.t('general.labels.email'),
                                }
                              )
                            }
                          />
                        </Tooltip>
                      </Show>
                    </HStack>
                  </Show>
                </VStack>
              </Show>
            </VStack>
          </HStack>
          <Show when={props.snippets}>
            <DataStatus minHeight={23} isLoading={props.isLoadingSnippets}>
              <Space vertical={4} />
              <SnippetPanel
                snippets={props.snippets}
                data-testid={suffixTestId('entityHeader-snippets', props)}
              />
            </DataStatus>
          </Show>
        </VStack>
      </Box>
      <Show when={props.alerts}>
        {props.alerts?.map((alert, index) => (
          <Fragment key={isArray(alert.message) ? alert.message?.[0] : alert.message}>
            <Alert {...alert} data-testid={suffixTestId(`entityHeader-alert-[${index}]`, props)} />
            {index < (props.alerts?.length ?? 0) - 1 && <Space vertical={1} />}
          </Fragment>
        ))}
      </Show>
    </>
  );
}
