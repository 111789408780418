import {any} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {ContractInformationResponseBodyV2} from '@dms/api/customer';

import {buildArray} from 'shared';

import {isContractNaturalPerson} from './isContractNaturalPerson';

export const hasContractIncompleteBillingInfo = (contract: ContractInformationResponseBodyV2) => {
  const requiredFields = buildArray()
    .when(isContractNaturalPerson(contract), contract?.person?.firstName)
    .when(isContractNaturalPerson(contract), contract?.person?.lastName)
    .whenNot(isContractNaturalPerson(contract), contract.businessInfo?.businessInfo.tradeName)
    .whenNot(
      isContractNaturalPerson(contract),
      contract.businessInfo?.businessInfo.registrationNumber
    )
    .when(contract.legalForm === 'LEGAL_ENTITY', contract.businessInfo?.businessInfo.fileNumber);

  return any(isNilOrEmpty, requiredFields);
};
