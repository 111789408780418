import {createSelector} from '@reduxjs/toolkit';

import {TRANSLATED_LANGUAGES} from '@dms/api/shared';
import {selectCurrentUserInfo} from '@dms/api/user';

import type {TeasState} from '../../types/TeasState';
import {validateLang} from '../../utils/validateLang';

export const selectUserInfo = createSelector(selectCurrentUserInfo, ({data}) => data);

export const selectUserId = createSelector(selectUserInfo, (userInfo) => userInfo?.id);
export const selectUserName = createSelector(
  selectUserInfo,
  (userInfo) => userInfo && `${userInfo.firstName} ${userInfo.lastName}`
);

// Branches
export const selectUserBranches = createSelector(selectUserInfo, (userInfo) => userInfo?.branches);
export const selectUserDefaultBranch = createSelector(
  selectUserInfo,
  (userInfo) => userInfo?.defaultBranch
);

// @ts-ignore branch reducer exists, but it is ignored because of circular dependency
export const selectActiveBranch = (state: TeasState) => state.branch.activeBranchId;

// User settings
export const selectUserSettings = createSelector(
  [selectCurrentUserInfo, selectActiveBranch],
  ({data}, branch) => ({
    ...data?.settings,
    currency: data?.settings?.currency,
    country: data?.settings?.country,
    branch,
    language: validateLang(data?.settings?.language),
    inactivityPeriod: data?.settings?.inactivityPeriod || 'INACTIVITY_PERIOD_NEVER',
  })
);

export const selectUserSelectedLanguage = createSelector(
  selectUserSettings,
  ({language}): TRANSLATED_LANGUAGES => language
);
