import {Column, Separator} from 'platform/components';
import {Box, HStack, Hide, Space, Text} from 'platform/foundation';

import i18n from '@dms/i18n';

const HeaderCell = (props: {isFirst?: boolean; label: string}) => (
  <Box paddingVertical={2} backgroundColor="palettes.neutral.10.100">
    <HStack>
      <Hide when={props.isFirst}>
        <Separator orientation="vertical" spacing={0} />
      </Hide>

      <Space horizontal={2} />

      <Text align="left" alternative>
        {props.label}
      </Text>
    </HStack>
  </Box>
);

export const getPriceListColumns = (formattedTenantCurrency: string): Column[] => [
  {
    element: <HeaderCell isFirst label={i18n.t('general.labels.name')} />,
    width: '20%',
  },
  {
    element: <HeaderCell isFirst label={i18n.t('entity.rental.labels.from')} />,
    width: '10%',
  },
  {
    element: <HeaderCell label={i18n.t('entity.rental.labels.to')} />,
    width: '10%',
  },
  {
    element: <HeaderCell label={i18n.t('entity.rental.labels.period')} />,
    width: '15%',
  },
  {
    element: <HeaderCell label={i18n.t('entity.rental.labels.rate')} />,
    width: '15%',
  },
  {
    element: (
      <HeaderCell
        label={`${formattedTenantCurrency}${i18n.t('entity.rental.labels.ratePerKilometer')}`}
      />
    ),
    width: '15%',
  },
  {
    element: <HeaderCell label={i18n.t('entity.rental.labels.includedKilometers')} />,
    width: '20%',
    colspan: 2,
  },
];
