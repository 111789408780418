import {
  Action,
  Actions,
  Avatar,
  Choice,
  useTranslationContext,
  Dialog,
  IconButton,
} from 'platform/components';
import {VStack, Text, Box, HStack, Space, Heading} from 'platform/foundation';

import {MouseEvent} from 'react';

import {buildArray, RequiredTestIdProps, suffixTestId} from 'shared';

import {BigCalendarUser} from '../types/BigCalendarUser';

interface HeaderUserControllDialogProps extends RequiredTestIdProps {
  isOpen: boolean;
  currentUser: BigCalendarUser;
  users: BigCalendarUser[];
  selectedUsers: BigCalendarUser[];
  onUserControlChange: (selectedUsers: BigCalendarUser[]) => void;
  onClose: () => void;
}

export function HeaderUserControllDialog(props: HeaderUserControllDialogProps) {
  const t = useTranslationContext();

  const handleUserVisibilityChange = (user: BigCalendarUser) => {
    props.onUserControlChange(
      props.selectedUsers.map((selectedUser) =>
        selectedUser.id === user.id
          ? {...selectedUser, isHidden: !selectedUser.isHidden}
          : selectedUser
      )
    );
  };

  const handleRemoveUser = (user: BigCalendarUser) => {
    const filteredUsers = props.selectedUsers.filter((u) => u.id !== user.id);
    if (filteredUsers.length === 1) {
      props.onUserControlChange([{...filteredUsers[0], isHidden: false}]);
    } else {
      props.onUserControlChange(filteredUsers);
    }
  };

  const options = props.users.reduce(
    (values: {label: string; value: string}[], user: BigCalendarUser) => {
      if (
        user.id === props.currentUser.id ||
        props.selectedUsers.some((selectedUser) => selectedUser.id === user.id)
      ) {
        return values;
      }

      return [
        ...values,
        {
          label: `${user.fullName} <${user.email}>`,
          value: user.id,
        },
      ];
    },
    []
  );

  return (
    <Dialog
      isOpen={props.isOpen}
      disableBodyPadding
      scrollBehavior="outside"
      data-testid={suffixTestId('headerUserControllDialog', props)}
    >
      <Box padding={3}>
        <VStack spacing={3}>
          <HStack justify="space-between">
            <Heading size={5}>{t('general.presets.userShare')}</Heading>
            <IconButton
              data-testid={suffixTestId('headerUserControllDialog-close', props)}
              icon="navigation/close"
              priority="default"
              onClick={props.onClose}
            />
          </HStack>
          <Choice
            data-testid={suffixTestId('headerUserControllDialog-choice', props)}
            value=""
            autoFocus
            options={options}
            isSearchable
            isNotClearable
            isDisabled={props.selectedUsers.length === 6}
            placeholder={t('general.labels.searchPlaceholder')}
            onChange={(userId) => {
              const user = props.users.find((user) => user.id === userId);
              if (!user) {
                return;
              }
              props.onUserControlChange([...props.selectedUsers, {...user, isHidden: false}]);
            }}
          />
          <VStack spacing={2}>
            {props.selectedUsers.map((user, userIndex) => (
              <Box
                key={user.id}
                backgroundColor="general.white"
                borderLeft="2px solid"
                borderColor={user.color}
                paddingHorizontal={2}
              >
                <HStack align="center" minHeight={9}>
                  <Box opacity={user.isHidden ? 0.2 : 1}>
                    <Avatar
                      data-testid={suffixTestId(
                        `headerUserControllDialog-avatar-[${userIndex}]`,
                        props
                      )}
                      color={user.color}
                      name={user.fullName}
                      size="small"
                      textColor="white"
                    />
                  </Box>
                  <Space horizontal={2} />
                  <Text
                    data-testid={suffixTestId(
                      `headerUserControllDialog-name-[${userIndex}]`,
                      props
                    )}
                    size="small"
                  >
                    {user.fullName}
                  </Text>
                  <Space fillAvailable />
                  <Actions
                    data-testid={suffixTestId(
                      `headerUserControllDialog-actions-[${userIndex}]`,
                      props
                    )}
                    size="small"
                    actions={buildArray<Action>()
                      .add({
                        type: 'iconButton',
                        isDisabled:
                          !user.isHidden &&
                          props.selectedUsers.filter((u) => !u.isHidden).length === 1,
                        icon: user.isHidden ? 'action/visibility_off' : 'action/visibility',
                        onClick: (event: MouseEvent<HTMLButtonElement>) => {
                          event.stopPropagation();
                          handleUserVisibilityChange(user);
                        },
                      })
                      .when(user.id !== props.currentUser.id, {
                        type: 'iconButton',
                        icon: 'navigation/close',
                        onClick: (event: MouseEvent<HTMLButtonElement>) => {
                          event.stopPropagation();
                          handleRemoveUser(user);
                        },
                      })}
                  />
                </HStack>
              </Box>
            ))}
          </VStack>
        </VStack>
      </Box>
    </Dialog>
  );
}
