import {z} from 'zod';

import {SalesActivityManualTypeEnumSchema} from '@dms/api/sales';
import i18n from '@dms/i18n';

export const LogContactDialogSchema = z.object({
  subject: z.string().min(1, {message: i18n.t('general.validations.fieldIsRequired')}),
  note: z
    .string()
    .min(1, {message: i18n.t('general.validations.fieldIsRequired')})
    .nullable(),
  date: z.date(),
  time: z.string().nullable().optional(),
  type: z.array(SalesActivityManualTypeEnumSchema),
});

export type LogContactDialogSchemaType = z.infer<typeof LogContactDialogSchema>;
