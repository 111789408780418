import {DataStatus, Form, FormField} from 'platform/components';
import {Grid, GridItem, VStack} from 'platform/foundation';

import {defaultTo} from 'ramda';

import {useGetManufacturersQuery} from '@dms/api/metadaAdminManufacturer';
import {useGetWarehousesQuery} from '@dms/api/metadaWarehouse';
import {useGetSuppliersQuery} from '@dms/api/metadaWarehouseSupplier';
import i18n from '@dms/i18n';
import {
  getOptionsFromSuppliers,
  getOptionsFromWarehouses,
  getOptionsFromManufacturers,
} from '@dms/shared';

import {TestIdProps, suffixTestId} from 'shared';

import {FormToDataGridConnectorProps} from 'features/datagrid';

import {RangeField} from '../../../components/RangeField';
import {DEFAULT_RANGE_VALUE} from '../../../constants/defaultRangeValue';
import {DateRangeField} from '../../../types/DateRangeField';
import {NumberRangeField} from '../../../types/NumberRangeField';

type PurchaseListExternalFilterForm = Partial<{
  warehouseId: string;
  manufacturerId: string;
  supplierId: string;
  created: DateRangeField;
  neededAt: DateRangeField;
  neededIn: NumberRangeField;
}>;

type PurchaseListExternalFilterProps = FormToDataGridConnectorProps & TestIdProps;

export function PurchaseListExternalFilter(props: PurchaseListExternalFilterProps) {
  const {
    data: warehouses,
    isLoading: areWarehousesLoading,
    isError: hasWarehousesError,
  } = useGetWarehousesQuery();

  const {
    data: manufacturers,
    isLoading: areManufacturersLoading,
    isError: hasManufacturersError,
  } = useGetManufacturersQuery();

  const {
    data: suppliers,
    isLoading: areSuppliersLoading,
    isError: hasSuppliersError,
  } = useGetSuppliersQuery();

  const isLoading = areWarehousesLoading || areManufacturersLoading || areSuppliersLoading;

  const hasError = hasWarehousesError || hasManufacturersError || hasSuppliersError;

  const values: PurchaseListExternalFilterForm = {
    ...props.values,
    created: defaultTo(DEFAULT_RANGE_VALUE, props.values?.created),
    neededAt: defaultTo(DEFAULT_RANGE_VALUE, props.values?.neededAt),
    neededIn: defaultTo(DEFAULT_RANGE_VALUE, props.values?.neededIn),
  };

  return (
    <DataStatus isLoading={isLoading} isError={hasError}>
      <Form<PurchaseListExternalFilterForm>
        values={values}
        defaultValues={{}}
        onChange={props.onChange}
      >
        {(control) => (
          <VStack spacing={4} height="100%">
            <Grid columns={2}>
              <GridItem span={2}>
                <FormField
                  control={control}
                  name="warehouseId"
                  type="choice"
                  label={i18n.t('entity.warehouse.labels.requestingWarehouse')}
                  options={getOptionsFromWarehouses(warehouses)}
                  data-testid={suffixTestId('inputs.requestingWarehouseId', props)}
                />
              </GridItem>
              <GridItem span={2}>
                <FormField
                  control={control}
                  name="manufacturerId"
                  type="choice"
                  label={i18n.t('entity.warehouse.labels.manufacturer')}
                  options={getOptionsFromManufacturers(manufacturers)}
                  data-testid={suffixTestId('inputs.manufacturerId', props)}
                />
              </GridItem>
              <GridItem span={2}>
                <FormField
                  control={control}
                  name="supplierId"
                  type="choice"
                  label={i18n.t('entity.warehouse.labels.articleDefaultSupplier')}
                  options={getOptionsFromSuppliers(suppliers)}
                  data-testid={suffixTestId('inputs.supplierId', props)}
                />
              </GridItem>
              <GridItem span={2}>
                <RangeField
                  control={control}
                  type="date"
                  name="created"
                  labelFrom={i18n.t('general.labels.createdFrom')}
                  labelTo={i18n.t('general.labels.createdTo')}
                  data-testid={suffixTestId('inputs.created', props)}
                />
              </GridItem>

              <GridItem span={2}>
                <RangeField
                  control={control}
                  type="date"
                  name="neededAt"
                  labelFrom={i18n.t('general.labels.neededAtFrom')}
                  labelTo={i18n.t('general.labels.neededAtTo')}
                  data-testid={suffixTestId('inputs.neededAt', props)}
                />
              </GridItem>
              <GridItem span={2}>
                <RangeField
                  control={control}
                  type="number"
                  name="neededIn"
                  labelFrom={i18n.t('general.labels.neededInFrom')}
                  labelTo={i18n.t('general.labels.neededInTo')}
                  data-testid={suffixTestId('inputs.neededIn', props)}
                />
              </GridItem>
            </Grid>
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}
