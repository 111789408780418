import {useParams} from 'react-router-dom';

import {defaultTo, isNil} from 'ramda';

import {useGetCurrenciesQuery} from '@dms/api/cached';
import {useGetTemplatesQuery} from '@dms/api/document';
import {useGetAuthorizationProfilesQuery} from '@dms/api/metadaAdminAuthorizationProfile';
import {useGetManufacturersQuery} from '@dms/api/metadaAdminManufacturer';
import {useGetWarehouseQuery} from '@dms/api/metadaWarehouse';
import {useGetWarehouseAccountsQuery} from '@dms/api/metadaWarehouseAccount';
import {
  useGetCodeListOptionsQuery,
  useGetWarehousePricingEnumsQuery,
} from '@dms/api/metadaWarehouseCodeList';
import {useGetDirectSaleVariantsQuery} from '@dms/api/metadaWarehouseDirectSaleVariant';
import {useGetServiceOrderVariantsQuery} from '@dms/api/metadaWarehouseServiceOrderVariants';
import {useGetSuppliersQuery} from '@dms/api/metadaWarehouseSupplier';
import {useGetSeriesListQuery} from '@dms/api/series';
import {useGetBranchListQuery, useGetTenantQuery} from '@dms/api/tenant';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';
import {WarehouseDetailForm} from './components/WarehouseDetailForm';

export function WarehouseDetail() {
  const {id} = useParams();

  const {data: tenant, isLoading: isTenantLoading, isError: hasTenantError} = useGetTenantQuery();

  const {
    data: warehouse,
    isLoading: isWarehouseLoading,
    isError: hasWarehouseError,
  } = useGetWarehouseQuery({warehouseId: defaultTo('', id)}, {skip: isNil(id)});

  const {
    data: authorizationProfiles,
    isLoading: areAuthorizationProfilesLoading,
    isError: hasAuthorizationProfilesError,
  } = useGetAuthorizationProfilesQuery({'x-tenant': tenant?.id ?? ''}, {skip: isNil(tenant)});

  const {
    data: branches,
    isLoading: areBranchesLoading,
    isError: hasBranchesError,
  } = useGetBranchListQuery();

  const {
    data: manufacturers,
    isLoading: areManufacturersLoading,
    isError: hasManufacturersError,
  } = useGetManufacturersQuery();

  const {
    data: warehousePricing,
    isLoading: isWarehousePricingLoading,
    isError: hasWarehousePricingError,
  } = useGetWarehousePricingEnumsQuery();

  const {
    data: currencies,
    isLoading: areCurrenciesLoading,
    isError: hasCurrenciesError,
  } = useGetCurrenciesQuery();

  const {
    data: warehouseAccounts,
    isLoading: areWarehouseAccountsLoading,
    isError: hasWarehouseAccountsError,
  } = useGetWarehouseAccountsQuery();

  const {
    data: salesPriceCalculationOptions,
    isLoading: areSalesPriceCalculationOptionsLoading,
    isError: hasSalesPriceCalculationOptionsError,
  } = useGetCodeListOptionsQuery({
    category: 'WRH-SALES-PRICE-CALCULATION',
  });

  const {
    data: baseSourcePriceOptions,
    isLoading: areBasePriceSourceOptionsLoading,
    isError: hasBaseSourcePriceOptionsError,
  } = useGetCodeListOptionsQuery({
    category: 'WRH-BASE-PRICE-SOURCE',
  });

  const {
    data: series,
    isLoading: areSeriesLoading,
    isError: hasSeriesError,
  } = useGetSeriesListQuery({
    type: [
      'warehouse/receipt',
      'warehouse/dispense',
      'warehouse/corrective-receipt',
      'warehouse/issue-note-correction',
    ],
  });

  const {
    data: creditNoteTemplates,
    isLoading: areCreditNoteTemplatesLoading,
    isError: hasCreditNoteTemplatesError,
  } = useGetTemplatesQuery({
    documentKindCode: 'warehouse-corrective-receipt',
  });

  const {
    data: receiveNoteTemplates,
    isLoading: areReceiveNoteTemplatesLoading,
    isError: hasReceiveNoteTemplatesError,
  } = useGetTemplatesQuery({
    documentKindCode: 'warehouse-receipt',
  });

  const {
    data: issueNoteTemplates,
    isLoading: areIssueNoteTemplatesLoading,
    isError: hasIssueNoteTemplatesError,
  } = useGetTemplatesQuery({documentKindCode: 'warehouse-issue'});

  const {
    data: issueNoteCorrectionTemplates,
    isLoading: areIssueNoteCorrectionTemplatesLoading,
    isError: hasIssueNoteCorrectionTemplatesError,
  } = useGetTemplatesQuery({documentKindCode: 'warehouse-issue-note-correction'});

  const {
    data: serviceOrderVariants,
    isLoading: areServiceOrderVariantsLoading,
    isError: hasServiceOrderVariantsError,
  } = useGetServiceOrderVariantsQuery();

  const {
    data: directSaleVariants,
    isLoading: areDirectSaleVariantsLoading,
    isError: hasDirectSaleVariantsError,
  } = useGetDirectSaleVariantsQuery();

  const {
    data: suppliers,
    isLoading: areSuppliersLoading,
    isError: hasSuppliersError,
  } = useGetSuppliersQuery();

  const isLoading =
    isTenantLoading ||
    isWarehouseLoading ||
    areAuthorizationProfilesLoading ||
    areBranchesLoading ||
    areManufacturersLoading ||
    isWarehousePricingLoading ||
    areCurrenciesLoading ||
    areWarehouseAccountsLoading ||
    areSalesPriceCalculationOptionsLoading ||
    areBasePriceSourceOptionsLoading ||
    areSeriesLoading ||
    areCreditNoteTemplatesLoading ||
    areReceiveNoteTemplatesLoading ||
    areIssueNoteTemplatesLoading ||
    areIssueNoteCorrectionTemplatesLoading ||
    areServiceOrderVariantsLoading ||
    areDirectSaleVariantsLoading ||
    areSuppliersLoading;

  const hasError =
    hasTenantError ||
    hasWarehouseError ||
    hasAuthorizationProfilesError ||
    hasBranchesError ||
    hasManufacturersError ||
    hasWarehousePricingError ||
    hasCurrenciesError ||
    hasWarehouseAccountsError ||
    hasSalesPriceCalculationOptionsError ||
    hasBaseSourcePriceOptionsError ||
    hasSeriesError ||
    hasCreditNoteTemplatesError ||
    hasReceiveNoteTemplatesError ||
    hasIssueNoteTemplatesError ||
    hasIssueNoteCorrectionTemplatesError ||
    hasServiceOrderVariantsError ||
    hasDirectSaleVariantsError ||
    hasSuppliersError;

  const header: SettingsTemplateHeader = {
    title: isNil(id)
      ? i18n.t('entity.warehouse.labels.newWarehouse')
      : defaultTo('', warehouse?.name),
    breadcrumbs: [
      {
        label: i18n.t('entity.warehouse.labels.warehouses'),
        href: settingsRoutes.warehousesList,
      },
    ],
  };

  return (
    <SettingsTemplate
      header={header}
      isLoading={isLoading}
      isError={hasError}
      data-testid={testIds.settings.warehousesDetail('warehouseDetailWrapper')}
    >
      <WarehouseDetailForm
        tenant={tenant}
        warehouse={warehouse}
        authorizationProfiles={authorizationProfiles}
        branches={branches}
        manufacturers={manufacturers}
        warehousePricing={warehousePricing}
        currencies={currencies}
        warehouseAccounts={warehouseAccounts}
        salesPriceCalculationOptions={salesPriceCalculationOptions}
        baseSourcePriceOptions={baseSourcePriceOptions}
        series={series}
        receiveNoteTemplates={receiveNoteTemplates}
        creditNoteTemplates={creditNoteTemplates}
        issueNoteTemplates={issueNoteTemplates}
        issueNoteCorrectionTemplates={issueNoteCorrectionTemplates}
        serviceOrderVariants={serviceOrderVariants}
        directSaleVariants={directSaleVariants}
        suppliers={suppliers}
        data-testid={testIds.settings.warehousesDetail('warehouseDetailForm')}
      />
    </SettingsTemplate>
  );
}
