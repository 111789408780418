import {BaseQueryApi} from '@reduxjs/toolkit/query/react';
import {captureException} from '@sentry/browser';
import axios from 'axios';
import {AxiosCacheInstance, setupCache} from 'axios-cache-interceptor';
import flagsmith from 'flagsmith';

// eslint-disable-next-line no-restricted-imports
import {path} from 'ramda';

import {appWorkspaceKey, browserStorageKey} from '@dms/config';
import {environment} from '@dms/environment';
import {loginRoutes} from '@dms/routes';

import {publicApi} from '../modules/publicApi/publicApi';
import {customRequestInterceptor} from './customRequestInterceptor';
import {getArgsWithAuthorization} from './getArgsWithAuthorization';
import {buildUrl} from './utils/buildUrl';
import {getWorkspaceFromUri} from './utils/getWorkspaceFromUri';
import {redirectToLoginPage} from './utils/redirect';

const URL_PATH_PREFIX = 'dms';

// Track if axios has been initialized
let isAxiosInitialized = false;

export const initializeAxios = (api: BaseQueryApi) => {
  // Only initialize axios once
  if (isAxiosInitialized) {
    return;
  }

  isAxiosInitialized = true;
  axios.defaults.baseURL = `${environment.API_URL}/${URL_PATH_PREFIX}`;
  axios.interceptors.request.use((config) => {
    const activeBranchId = path(['branch', 'activeBranchId'], api.getState());
    // @ts-ignore
    config.headers = getArgsWithAuthorization({headers: config.headers}, activeBranchId).headers;

    return config;
  });

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response === undefined || error.response?.status >= 500) {
        captureException(error);
      }

      if (error.response?.status === 401) {
        const getRefreshToken = localStorage.getItem(browserStorageKey.REFRESH_TOKEN);
        const {workspace, shouldRedirectToAppWorkspace} = getWorkspaceFromUri();

        if (!workspace || shouldRedirectToAppWorkspace) {
          window.location.href = buildUrl(loginRoutes.loginWorkspace, appWorkspaceKey);
          return error;
        }

        if (!getRefreshToken) {
          window.location.href = buildUrl(redirectToLoginPage(), undefined, false);
          return error;
        }

        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete('directLink');

        const refreshTokenArgs = {
          workspace,
          refreshTokenRequestBody: {
            refreshToken: getRefreshToken,
          },
        };

        const token = await api
          .dispatch(publicApi.endpoints.refreshToken.initiate(refreshTokenArgs))
          .unwrap()
          .then(({refreshToken, token}) => {
            sessionStorage.setItem(browserStorageKey.ACCESS_TOKEN, token);
            localStorage.setItem(browserStorageKey.REFRESH_TOKEN, refreshToken);

            return token;
          })
          .catch(() => {
            sessionStorage.removeItem(browserStorageKey.ACCESS_TOKEN);
            localStorage.removeItem(browserStorageKey.REFRESH_TOKEN);
            flagsmith.logout();
            window.location.href = buildUrl(redirectToLoginPage(), undefined, false);
          });

        return axios.request({
          ...error.config,
          cache: false,
          headers: {...error.config.headers, Authorization: `Bearer ${token}`},
        });
      }

      throw error;
    }
  );

  // eslint-disable-next-line no-restricted-syntax
  if (!(axios as unknown as AxiosCacheInstance).storage) {
    setupCache(axios, {
      // eslint-disable-next-line no-restricted-syntax
      requestInterceptor: customRequestInterceptor(axios as unknown as AxiosCacheInstance),
      ttl: 750,
      cacheTakeover: false,
    });
  }
};
