import {Center, Box, VStack, Icon, Heading, Text} from 'platform/foundation';

import i18n from '@dms/i18n';

export function LogContactDialogBox() {
  return (
    <Center>
      <Box maxWidth={140} paddingBottom={8}>
        <VStack spacing={4} align="center" justify="center">
          <Icon value="action/mail_upload_v2" color="palettes.blue.30.100" isNotFilled size={20} />
          <Heading size={3} color="primary">
            {i18n.t('entity.activityPanel.logContact.heading')}
          </Heading>
          <Text size="base" color="secondary" align="center">
            {i18n.t('entity.activityPanel.logContact.description')}
          </Text>
        </VStack>
      </Box>
    </Center>
  );
}
