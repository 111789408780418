import {DataStatus} from 'platform/components';

import {isArray} from 'ramda-adjunct';

import {useGetArticleCategoryListQuery} from '@dms/api/metadaWarehouseArticleCategory';
import {MoveToFolder} from '@dms/shared';

import {suffixTestId, RequiredTestIdProps} from 'shared';

const FOLDER_CONTEXT_TARGET = 'spare-parts-catalog';
const FOLDER_RELATION_TARGET = 'spare-parts-catalog-item';

interface ArticleMoveToFolderProps extends RequiredTestIdProps {
  itemIds: string | string[];
  onClose: VoidFunction;
}

export function ArticleMoveToFolder(props: ArticleMoveToFolderProps) {
  const {data: articleCategoryList, isLoading, isError} = useGetArticleCategoryListQuery();

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <MoveToFolder
        catalogueIds={articleCategoryList?.categories?.map((category) => category.id) ?? []}
        contextTarget={FOLDER_CONTEXT_TARGET}
        relationTarget={FOLDER_RELATION_TARGET}
        itemIds={isArray(props.itemIds) ? props.itemIds : [props.itemIds]}
        onClose={props.onClose}
        data-testid={suffixTestId('moveDialog', props)}
      />
    </DataStatus>
  );
}
