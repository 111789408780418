import {differenceInDays} from 'date-fns';
import {Card, DataStatus, Form} from 'platform/components';
import {Box, Show, VStack} from 'platform/foundation';

import {Helmet} from 'react-helmet-async';

import {indexBy, isNil, reject} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {useGetTaxDocumentForPaymentV4Query} from '@dms/api/accounting';
import {PaymentInfoResponseBody} from '@dms/api/shared';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

import {parseDate, useRequiredParams} from 'shared';

import {CancellingInvoiceDetail} from '../../components/CancellingInvoiceDetail';
import {CustomerAndSupplierDetail} from '../../components/CustomerAndSupplierDetail';
import {InvoiceDetailHeader} from '../../components/InvoiceDetailHeader';
import {InvoiceDetailSubHeader} from '../../components/InvoiceDetailSubHeader';
import {InvoicePage} from '../../components/InvoicePage';
import {ListOfItemsDetail} from '../../components/ListOfItemsDetail';
import {PaymentDatesDetail} from '../../components/PaymentDatesDetail';
import {PaymentDetailsDetail} from '../../components/PaymentDetailsDetail';
import {PaymentsNotesDetail} from '../../components/PaymentsNotesDetail';
import {invoicePaymentOptions} from '../../constants';
import {InvoiceVatRateProvider} from '../../context/InvoiceVatRateProvider';
import {useDocumentActions} from '../../hooks/useDocumentActions';
import {AccountingDocumentFormValues} from '../../types';
import {getDocumentSourceFlags} from '../../utils/getDocumentSourceFlags';

export function TaxDocumentForPaymentDetail() {
  const {id: taxDocumentForPaymentId} = useRequiredParams();

  const [subheaderActions] = useDocumentActions(
    'TAX_DOCUMENT_FOR_PAYMENT',
    taxDocumentForPaymentId
  );

  const {
    data: invoice,
    isLoading,
    isError,
  } = useGetTaxDocumentForPaymentV4Query({id: taxDocumentForPaymentId});

  const bankAccountValue = reject(isNil, [
    invoice?.paymentInfo?.bankAccount,
    invoice?.paymentInfo?.bankName,
    invoice?.currency,
  ]).join(', ');

  const paymentTypesByType = indexBy((item) => item.value, invoicePaymentOptions);
  const paymentMethodLabel = invoice
    ? (paymentTypesByType[invoice.paymentInfo.paymentMethod]
        .label as PaymentInfoResponseBody['paymentMethod'])
    : 'BANK_TRANSFER';

  const initialValues = invoice
    ? {
        ...invoice,
        // TODO: add dueSinceIssueDateInDays to BE response
        dueSinceIssueDateInDays: differenceInDays(
          parseDate(invoice.issueDate),
          parseDate(invoice.dueDate)
        ),
        paymentInfo: {
          ...invoice.paymentInfo,
          bankAccount: bankAccountValue,
          paymentMethod: paymentMethodLabel,
        },
        exchangeRateRatio: {
          amount: null,
          currency: null,
          type: i18n.t('general.actions.recalculate'),
        },
      }
    : undefined;

  const pageTitle = i18n.t('entity.taxDocument.labels.taxDocumentNumber', {
    taxDocumentNumber: invoice?.number,
  });

  return (
    <DataStatus isLoading={isLoading} isError={isError} minHeight="80vh">
      <Show when={isNotNil(invoice)}>
        <Helmet title={pageTitle} />

        <Form<AccountingDocumentFormValues> defaultValues={initialValues}>
          {(control) => (
            <InvoiceVatRateProvider control={control} listItemType="invoicing-documents">
              <VStack>
                <Box backgroundColor="general.white">
                  <InvoiceDetailHeader title={pageTitle} />
                  <InvoiceDetailSubHeader
                    actions={subheaderActions}
                    subFlags={getDocumentSourceFlags(invoice?.documentSource)}
                  />
                </Box>

                <InvoicePage>
                  <Card>
                    <VStack spacing={4}>
                      <CustomerAndSupplierDetail
                        customer={invoice!.customer}
                        supplier={invoice!.supplier}
                        data-testid={testIds.accounting.taxDocumentForPaymentDetail(
                          'customerAndSupplier'
                        )}
                      />
                      <PaymentDatesDetail
                        control={control}
                        data-testid={testIds.accounting.taxDocumentForPaymentDetail('paymentDates')}
                      />
                    </VStack>
                  </Card>

                  <Show whenFeatureEnabled={featureFlags.ACCOUNTING_CANCEL_V2}>
                    <CancellingInvoiceDetail
                      cancelingInvoiceType="TAX_DOCUMENT_FOR_PAYMENT"
                      cancelingInvoiceId={invoice?.cancelledDocumentId}
                    />
                  </Show>

                  <PaymentDetailsDetail
                    control={control}
                    data-testid={testIds.accounting.taxDocumentForPaymentDetail('payment-details')}
                  />

                  <ListOfItemsDetail
                    items={invoice!.items}
                    summary={invoice!.taxDocumentSummary}
                    exchangeRateRatioCalculation={invoice?.exchangeRateRatioTaxDocumentSummary}
                    control={control}
                    data-testid={testIds.accounting.taxDocumentForPaymentDetail('listOfItems')}
                  />

                  <PaymentsNotesDetail control={control} />
                </InvoicePage>
              </VStack>
            </InvoiceVatRateProvider>
          )}
        </Form>
      </Show>
    </DataStatus>
  );
}
