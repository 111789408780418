import {
  ButtonGroup,
  Card,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {
  useUpdatePhotoSettingsMutation,
  useGetPhotoSettingsQuery,
} from '@dms/api/publishingPlatformPhotosSettings';
import {UpdatePhotoSettingsApiArg} from '@dms/api/shared';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {useBranchId} from '../../../../hooks/useBranchId';

type PlatformPhotoSettingsFormType = {
  promoPhotoAllowed: boolean;
  watermarkAllowed: boolean;
  maxPhotoNumberSelected: number;
};

export interface PlatformPhotoSettingsProps {
  platformCode?: string;
  isPublishingOnTenant?: boolean;
}

export function PlatformPhotoSettings(props: PlatformPhotoSettingsProps) {
  const branchId = useBranchId();
  const [updatePhotoSettings] = useUpdatePhotoSettingsMutation();
  const {data, isLoading, isFetching, isError} = useGetPhotoSettingsQuery(
    {
      platformCode: props.platformCode as UpdatePhotoSettingsApiArg['platformCode'],
      params: {branchId},
    },
    {skip: !props.platformCode}
  );

  const handleSubmit: FormSubmitHandler<PlatformPhotoSettingsFormType> = async (data) => {
    await updatePhotoSettings({
      platformCode: props.platformCode as UpdatePhotoSettingsApiArg['platformCode'],
      updatePlatformPhotoSettingsRequestBody: data,
      params: {branchId},
    })
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);
  };

  return (
    <Card title={i18n.t('page.settings.labels.photoSettings')}>
      <DataStatus
        isError={isError}
        isLoading={isLoading || isFetching}
        isEmpty={!props.platformCode}
      >
        <Form<PlatformPhotoSettingsFormType>
          onSubmit={handleSubmit}
          schema={PlatformPhotoSettingsFormSchema(data?.maxPhotoNumberAllowed ?? 0)}
          defaultValues={{
            maxPhotoNumberSelected:
              data?.maxPhotoNumberSelected ?? data?.maxPhotoNumberAllowed ?? 0,
            promoPhotoAllowed: data?.promoPhotoAllowed ?? false,
            watermarkAllowed: data?.watermarkAllowed ?? false,
          }}
          key={branchId}
        >
          {(control) => (
            <>
              <FormField
                type="integer"
                name="maxPhotoNumberSelected"
                control={control}
                minStepperValue={1}
                maxStepperValue={data?.maxPhotoNumberAllowed}
                label={i18n.t('page.settings.labels.maxPhotoNumberSelected')}
                helperText={i18n.t('page.settings.labels.photoLimit', {
                  max: data?.maxPhotoNumberAllowed ?? 0,
                })}
              />
              <Separator />
              <VStack spacing={4}>
                <FormField
                  label={i18n.t('page.settings.labels.promoPhotoAllowed')}
                  type="switch"
                  name="promoPhotoAllowed"
                  control={control}
                />
                <FormField
                  label={i18n.t('page.settings.labels.watermarkAllowed')}
                  type="switch"
                  name="watermarkAllowed"
                  control={control}
                />
                <ButtonGroup align="right">
                  <FormButton
                    type="submit"
                    title={i18n.t('general.actions.save')}
                    control={control}
                  />
                </ButtonGroup>
              </VStack>
            </>
          )}
        </Form>
      </DataStatus>
    </Card>
  );
}

const PlatformPhotoSettingsFormSchema = (maxNumber: number) =>
  Yup.object({
    promoPhotoAllowed: Yup.boolean().required(),
    watermarkAllowed: Yup.boolean().required(),
    maxPhotoNumberSelected: Yup.number()
      .typeError(i18n.t('general.validations.mustBeNumber'))
      .min(1)
      .max(maxNumber)
      .required(),
  });
