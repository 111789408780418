import {IconButton} from 'platform/components';
import {Box, Heading, HStack} from 'platform/foundation';
import styled from 'styled-components';

import {ReactNode} from 'react';

interface ActivitySidebarItemProps {
  title: string;
  onClose: () => void;
  children: ReactNode;
}

export function ActivitySidebarItem(props: ActivitySidebarItemProps) {
  return (
    <ActivityBox>
      <Box
        borderBottom="1px solid"
        borderColor="general.separator"
        padding={4}
        data-testid="activity-sidebar-item-header"
      >
        <HStack spacing={4} justify="space-between" align="center">
          <Heading size={4}>{props.title}</Heading>
          <IconButton icon="navigation/close" onClick={props.onClose} />
        </HStack>
      </Box>
      <Box flex={1} padding={4} height="100%" overflow="hidden">
        {props.children}
      </Box>
    </ActivityBox>
  );
}

const ActivityBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding-inline: 0;
`;
