import {Module} from '@dms/api/shared';
import {moduleKeys} from '@dms/config';
import featureFlags from '@dms/feature-flags';
import {taskManagerRoutes} from '@dms/routes';
import {permissions} from '@dms/shared';

import {TaskOverview} from './pages/TaskOverview/TaskOverview';

export const TaskManagerModule: Module = {
  id: moduleKeys.taskManager,
  requiredFeatureFlag: featureFlags.CORE_TASK_MANAGEMENT,
  requiredPermission: permissions.readTask,
  layoutProps: {
    title: 'page.taskManager.title',
    icon: 'action/assignment_turned_in',
    iconV2: 'action/assignment_turned_in',
  },
  routerProps: {
    path: taskManagerRoutes.taskOverview,
    element: <TaskOverview />,
  },
  content: [
    {
      id: 'task-list',
      layoutProps: {
        title: 'page.taskManager.labels.tasks',
        icon: 'action/assignment_turned_in',
        iconV2: 'action/assignment_turned_in',
      },
      routerProps: {
        path: taskManagerRoutes.taskOverview,
        element: <TaskOverview />,
      },
    },
    {
      id: 'task-calendar',
      requiredFeatureFlag: featureFlags.CORE_TASK_CALENDAR,
      layoutProps: {
        title: 'page.taskManager.labels.calendar',
        icon: 'sidebar/task_management_calendar',
        iconV2: 'sidebar/task_management_calendar',
      },
      routerProps: {
        path: taskManagerRoutes.taskCalendar,
        element: <TaskOverview />,
      },
    },
  ],
};
