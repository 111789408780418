export type AssignmentIdentity = {
  type: `${AssignmentIdentity.type}`;
  id: string;
};

export namespace AssignmentIdentity {
  export enum type {
    VEHICLE = 'vehicle',
    VEHICLE_AUDIT = 'vehicle_audit',
  }
}
