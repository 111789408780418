import {Button, FormControl} from 'platform/components';
import {VStack} from 'platform/foundation';

import {useFieldArray, UseFormReturn} from 'react-hook-form';

import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

import {ChecklistFormValue} from '../types/ChecklistFormValue';
import {checklistTaskFactory} from '../utils/checklistTaskFactory';
import {TaskCard} from './TaskCard';

interface TasksControlProps {
  formApi: UseFormReturn<ChecklistFormValue>;
  control: FormControl<ChecklistFormValue>;
}

export function TasksControl(props: TasksControlProps) {
  const taskControl = useFieldArray({
    control: props.formApi.control,
    name: 'tasks',
  });

  return (
    <VStack spacing={6}>
      {taskControl.fields.map((field, index) => (
        <TaskCard
          key={field.id}
          index={index}
          formApi={props.formApi}
          control={props.control}
          onRemove={() => taskControl.remove(index)}
          onMoveUp={() => taskControl.move(index, index - 1)}
          onMoveDown={() => taskControl.move(index, index + 1)}
          isFirst={index === 0}
          isLast={index === taskControl.fields.length - 1}
          isAlone={taskControl.fields.length === 1}
        />
      ))}
      <Button
        title={i18n.t('entity.settings.labels.addTask')}
        leftIcon="content/add_circle"
        variant="ghostLink"
        onClick={() => taskControl.append(checklistTaskFactory())}
        data-testid={testIds.settings.generalChecklistCreate('addTask')}
      />
    </VStack>
  );
}
