import {
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {VStack} from 'platform/foundation';
import {object} from 'yup';

import {isNilOrEmpty} from 'ramda-adjunct';

import {usePatchTireLocationMutation, usePostTireLocationMutation} from '@dms/api/metadaTires';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {yupString} from 'shared';

interface LocationFormProps {
  defaultValue?: {id: string; name: string; isActive: boolean};
  onSave: () => void;
  onDiscard: () => void;
}

type LocationFormType = {
  name: string;
};

export function LocationForm(props: LocationFormProps) {
  const [postTireLocation] = usePostTireLocationMutation();
  const [patchTireLocation] = usePatchTireLocationMutation();

  const handleSubmit: FormSubmitHandler<LocationFormType> = async (data) => {
    const isCreating = isNilOrEmpty(props.defaultValue);

    const body = {
      name: data.name,
      isActive: props.defaultValue?.isActive ?? true,
    };

    const request = isCreating
      ? postTireLocation({body}).unwrap()
      : patchTireLocation({codelistId: props.defaultValue?.id ?? '', body}).unwrap();

    await request.then(props.onSave).catch(handleApiError);
  };

  return (
    <Form<LocationFormType>
      defaultValues={{name: props.defaultValue?.name}}
      onSubmit={handleSubmit}
      schema={locationFormSchema}
    >
      {(control) => (
        <VStack spacing={4}>
          <FormField
            control={control}
            type="text"
            name="name"
            label={i18n.t('general.labels.name')}
          />
          <ButtonGroup align="right">
            <Button
              title={i18n.t('general.actions.discard')}
              variant="secondary"
              onClick={props.onDiscard}
            />
            <FormButton
              control={control}
              title={i18n.t('general.actions.save')}
              type="submit"
              variant="primary"
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}

const locationFormSchema = object({
  name: yupString.required(),
});
