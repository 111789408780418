import {
  isValid,
  differenceInMilliseconds,
  addMilliseconds,
  differenceInMinutes,
  endOfDay,
} from 'date-fns';

import {Nullish, parseDate} from 'shared';

interface CalculedResizedEventToBottomProps {
  start: Date;
  end: Date;
  overId: string | number | Nullish;
}

export function calculedResizedEventToBottom(args: CalculedResizedEventToBottomProps): Date | null {
  const dropTimestamp = parseInt(String(args.overId), 10);
  const dropDate = parseDate(dropTimestamp);

  if (!dropDate || !isValid(dropDate)) {
    return null;
  }

  const originalDuration = differenceInMilliseconds(args.end, args.start);
  const duration = differenceInMilliseconds(dropDate, args.start);

  const endDate = addMilliseconds(args.start, duration + originalDuration);

  if (differenceInMinutes(endDate, args.start) <= 14) {
    return null;
  }

  const dayEnd = endOfDay(args.start);
  if (endDate > dayEnd) {
    return null;
  }

  return endDate;
}
