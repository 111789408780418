import {HStack, Heading, Show, Text, VStack} from 'platform/foundation';
import {currencies, formatCurrencySymbol, useFormatCurrency} from 'platform/locale';

import {isNil, isNotNil, negate} from 'ramda';
import {isTrue} from 'ramda-adjunct';

import {Money} from '@dms/api/shared';
import i18n from '@dms/i18n';

import {CurrencyCodeType, EMPTY_PLACEHOLDER, suffixTestId} from 'shared';

import {PriceLabel} from './components/PriceLabel';
import {Tag} from './components/Tag';
import {PriceIndicatorProps} from './types/PriceIndicatorProps';
import {formatPriceString} from './utils/formatPriceString';
import {getPriceColor} from './utils/getPriceColor';
import {isPositiveValue} from './utils/isPositiveValue';

// @Samuel.Farkas treba pridat foreignPrice https://carvago.atlassian.net/browse/T20-53497
export const PriceIndicator = (props: PriceIndicatorProps) => {
  const formatCurrency = useFormatCurrency();

  const decimalPlaces = props.showDecimals ? 2 : 0;
  const tagPlacement = props.tagPlacement ?? 'left';

  let price = isNotNil(props.value) ? Number(props.value.amount) : null;
  let priceWithoutVat = isNotNil(props.valueWithoutVat)
    ? Number(props.valueWithoutVat.amount)
    : null;

  const formattedCurrencySymbol = formatCurrencySymbol(
    currencies,
    props.value?.currency as CurrencyCodeType
  );

  if (isNil(props.value) || isNil(price) || isNaN(price)) {
    return <EmptyPriceIndicator {...props} />;
  }

  // Positive numbers can have negative meaning, so they can be inverted
  if (props.areInvertedNumbers) {
    price = negate(price);
    priceWithoutVat = negate(priceWithoutVat ?? 0) || null;
  }

  const formatPrice = formatPriceString(
    props.isHighligted,
    isPositiveValue(price, props.areInvertedNumbers)
  );

  return (
    <VStack spacing={2} data-testid={props['data-testid']}>
      <PriceLabel
        label={props.label}
        labelTooltip={props.labelTooltip}
        data-testid={props['data-testid']}
      />
      <HStack spacing={2} align="center">
        <Show when={tagPlacement === 'left' && isNotNil(props.tag)}>
          <Tag
            {...getTrendTagProps(props.tag!, props.tagType!)}
            data-testid={suffixTestId('tag', props)}
            size={props.size}
            areInvertedNumbers={props.areInvertedNumbers}
          />
        </Show>
        <Heading
          size={props.size === 'large' ? 3 : 4}
          color={getPriceColor({
            isHighlighted: props.isHighligted,
            invert: props.areInvertedNumbers,
            value: price,
          })}
          data-testid={suffixTestId('withVat', props)}
        >
          {formatPrice(formatCurrency(price, props.value.currency, decimalPlaces)) ??
            EMPTY_PLACEHOLDER}
        </Heading>
        <Show when={tagPlacement === 'right' && isNotNil(props.tag)}>
          <Tag
            {...getTrendTagProps(props.tag!, props.tagType!)}
            data-testid={suffixTestId('tag', props)}
            size={props.size}
            areInvertedNumbers={props.areInvertedNumbers}
          />
        </Show>
      </HStack>
      <Show when={isTrue(props.alwaysShowWithoutVatValue) || isNotNil(priceWithoutVat)}>
        <Text
          size="xSmall"
          inline
          color="secondary"
          data-testid={suffixTestId('withoutVat', props)}
        >
          <Show when={isNotNil(priceWithoutVat)}>
            {`${formatPrice(
              formatCurrency(
                priceWithoutVat!,
                props.valueWithoutVat?.currency ?? props.value.currency,
                decimalPlaces
              )
            )} ${i18n.t('general.labels.withoutVat')}`}
          </Show>
          <Show when={isNil(priceWithoutVat)}>
            {`${EMPTY_PLACEHOLDER} ${formattedCurrencySymbol} ${i18n.t('general.labels.withoutVat')}`}
          </Show>
        </Text>
      </Show>
    </VStack>
  );
};

const EmptyPriceIndicator = (props: PriceIndicatorProps) => (
  <VStack data-testid={props['data-testid']} spacing={2}>
    <PriceLabel
      label={props.label}
      labelTooltip={props.labelTooltip}
      data-testid={props['data-testid']}
    />
    <HStack>
      <Heading size={props.size === 'large' ? 3 : 4} data-testid={suffixTestId('withVat', props)}>
        {EMPTY_PLACEHOLDER}
      </Heading>
    </HStack>
  </VStack>
);

const getTrendTagProps = (tag: number | Money, tagType: 'percent' | 'amount') =>
  tagType === 'percent' ? {tag: tag as number, tagType} : {tag: tag as Money, tagType};
