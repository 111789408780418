import {isFeatureEnabled} from 'feature-flags';
import {
  ButtonGroup,
  CustomRequestOption,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  isCurrency,
  isFile,
  Upload,
  UploadState,
} from 'platform/components';
import {Grid, Hide, HStack, Icon, Link, Show, Space, VStack} from 'platform/foundation';
import {array, object, string} from 'yup';

import {useState} from 'react';
import {useSelector} from 'react-redux';

import {pick} from 'ramda';
import {isNotNilOrEmpty, isPositive} from 'ramda-adjunct';

import {useUploadFileMutation} from '@dms/api/core';
import {
  useCreateCostMutation,
  useCreateEarningMutation,
  useEditCostMutation,
  useEditEarningMutation,
  useListVehicleCostCategoriesQuery,
} from '@dms/api/saleVehicle';
import {
  CostResponseBody,
  CreateCostRequestBody,
  CreateEarningRequestBody,
  EarningResponseBody,
  EditCostRequestBody,
  EditEarningRequestBody,
} from '@dms/api/saleVehiclePhoto';
import {selectTenant} from '@dms/api/tenant';
import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {DEFAULT_CURRENCY} from '../../constants/currency';
import {handleApiError} from '../../utils/handleApiError';

type FormValues =
  | CreateCostRequestBody
  | CreateEarningRequestBody
  | EditEarningRequestBody
  | EditCostRequestBody;

type File = {
  fileUri?: string | null;
  fileName?: string | null;
  fileId?: string | null;
};

interface VehicleEarningCostFormProps extends TestIdProps {
  vehicleId: string;
  defaultValues?: EarningResponseBody | CostResponseBody;
  entityId?: string | null;
  entity: 'earning' | 'cost';
  onSuccess: () => void;
  onCancel: () => void;
  isSimplified?: boolean;
  isCostsEditable?: boolean;
  businessCaseId?: string;
}

const isCostForm = (
  entity: 'earning' | 'cost',
  values?: FormValues
): values is CreateCostRequestBody | EditCostRequestBody => entity === 'cost';

export function VehicleEarningCostForm(props: VehicleEarningCostFormProps) {
  const [uploadFile, {isLoading}] = useUploadFileMutation();
  const [createEarning] = useCreateEarningMutation();
  const [editEarning] = useEditEarningMutation();
  const [createCost] = useCreateCostMutation();
  const [editCost] = useEditCostMutation();

  const isCostCategoriesEnabled = isFeatureEnabled(featureFlags.SALES_COSTS_WITH_VAT);
  const {
    data: costCategories,
    isLoading: isCostCategoriesLoading,
    isError: hasCostCategoriesError,
  } = useListVehicleCostCategoriesQuery(
    {},
    {
      skip: props.entity !== 'cost' || !isCostCategoriesEnabled,
    }
  );

  const {data: selectedTenant} = useSelector(selectTenant);
  const currency = isCurrency(selectedTenant?.currency)
    ? selectedTenant?.currency
    : DEFAULT_CURRENCY;

  const [file, setFile] = useState<File | null>(() =>
    props.defaultValues ? pick(['fileId', 'fileName', 'fileUri'], props.defaultValues) : null
  );

  const isEdit = !!props.entityId;

  const onSubmit: FormSubmitHandler<FormValues> = async (values) => {
    const createEntity = isCostForm(props.entity, values) ? createCost : createEarning;
    const editEntity = isCostForm(props.entity, values) ? editCost : editEarning;

    const body = {
      ...values,
      fileId: file?.fileId ?? null,
      expectedPriceWithoutVat:
        currency && values.expectedPriceWithoutVat?.amount
          ? {currency, amount: values.expectedPriceWithoutVat.amount}
          : null,
      realPriceWithoutVat:
        currency && values.realPriceWithoutVat?.amount
          ? {currency, amount: values.realPriceWithoutVat.amount}
          : null,
      ...(!isEdit &&
        props.businessCaseId && {
          businessCaseId: props.businessCaseId,
        }),
      category: isCostForm(props.entity, values) ? (values.category?.[0] ?? null) : null,
    };

    if (isEdit) {
      await editEntity({
        body,
        vehicleId: props.vehicleId,
        costId: props.entityId ?? '',
        earningId: props.entityId ?? '',
      })
        .unwrap()
        .then(props.onSuccess)
        .catch(handleApiError);
    } else {
      await createEntity({
        body,
        vehicleId: props.vehicleId,
      })
        .unwrap()
        .then(props.onSuccess)
        .catch(handleApiError);
    }
  };

  const handleUpload = async ({file}: CustomRequestOption) => {
    if (!isFile(file)) {
      return;
    }
    await uploadFile({file})
      .unwrap()
      .then((data) => {
        setFile({
          fileName: data.file.name,
          fileUri: data.fileUri,
          fileId: data.fileId,
        });
      })
      .catch(handleApiError);
  };

  return (
    <Form<FormValues>
      onSubmit={onSubmit}
      defaultValues={{
        name: props.defaultValues?.name,
        note: props.defaultValues?.note ?? null,
        fileId: props.defaultValues?.fileId ?? null,
        realPriceWithoutVat: props.defaultValues?.realPriceWithoutVat ?? null,
        expectedPriceWithoutVat: props.defaultValues?.expectedPriceWithoutVat ?? null,
        ...(isCostForm(props.entity, props.defaultValues) && {
          category: props.defaultValues?.category ? [props.defaultValues.category] : null,
        }),
      }}
      schema={getSchema(props.isSimplified, props.isCostsEditable)}
    >
      {(control) => (
        <>
          <VStack spacing={4}>
            <Hide
              when={
                isCostCategoriesLoading ||
                props.entity !== 'cost' ||
                hasCostCategoriesError ||
                !isCostCategoriesEnabled
              }
            >
              <FormField
                type="chips"
                isDeselectable
                name="category"
                label={i18n.t('entity.vehicle.costs.category')}
                control={control}
                data-testid={suffixTestId('category', props)}
                options={costCategories?.map((category) => ({
                  value: category.key,
                  label: category.name,
                }))}
              />
            </Hide>
            <Grid columns={props.isSimplified ? 1 : 2}>
              <FormField
                type="text"
                control={control}
                name="name"
                label={i18n.t('general.labels.name')}
                data-testid={suffixTestId('name', props)}
              />
            </Grid>
            <Grid columns={props.isSimplified ? 1 : 2}>
              <FormField
                type="currency"
                currency={currency}
                control={control}
                name="expectedPriceWithoutVat.amount"
                label={i18n.t('entity.vehicle.costs.expectedPriceWithoutVAT')}
                isDisabled={!props.isCostsEditable}
                data-testid={suffixTestId('expectedAmount', props)}
              />
              <Hide when={props.isSimplified}>
                <FormField
                  type="currency"
                  currency={currency}
                  control={control}
                  name="realPriceWithoutVat.amount"
                  label={i18n.t('entity.vehicle.costs.realPriceWithoutVAT')}
                  data-testid={suffixTestId('realAmount', props)}
                />
              </Hide>
            </Grid>
            <Hide when={props.isSimplified}>
              <FormField
                minRows={2}
                name="note"
                type="textarea"
                control={control}
                label={i18n.t('general.labels.note')}
                data-testid={suffixTestId('note', props)}
              />
              <Show when={file?.fileId}>
                <HStack align="center" spacing={3}>
                  <Icon value="files_default/file_type_PDF" size={8} />
                  <Link
                    size="base"
                    href={file?.fileUri}
                    target="_blank"
                    title={file?.fileName}
                    data-testid={suffixTestId('link', props)}
                  />
                  <Space fillAvailable />
                  <Icon
                    onClick={() => setFile(null)}
                    value="action/delete"
                    color="severity.danger"
                    size={4}
                  />
                </HStack>
              </Show>
              <Hide when={file?.fileId}>
                <Upload
                  type="button"
                  accept="application/pdf"
                  uploadState={isLoading ? UploadState.Uploading : UploadState.Idle}
                  errorIcon="navigation/cancel"
                  uploadingIcon="action/hourglass_empty"
                  uploadIcon="file/upload"
                  customRequest={handleUpload}
                  data-testid={suffixTestId('upload', props)}
                />
              </Hide>
            </Hide>
          </VStack>
          <Space vertical={4} />
          <ButtonGroup align="right">
            <FormButton
              variant="secondary"
              title={i18n.t('general.actions.discard')}
              onClick={props.onCancel}
              control={control}
              data-testid={suffixTestId('cancel', props)}
            />
            <FormButton
              type="submit"
              variant="primary"
              control={control}
              isDisabled={isLoading}
              title={isEdit ? i18n.t('general.actions.save') : i18n.t('general.actions.add')}
              data-testid={suffixTestId('submit', props)}
            />
          </ButtonGroup>
        </>
      )}
    </Form>
  );
}

const getSchema = (isSimplified = false, isCostsEditable = true) =>
  object().shape({
    name: string().required().max(100),
    note: string().nullable().max(255),
    category: array().of(string()).nullable(),
    /**
     * Yup is not typed correctly. It's missing 'context.from' value from types.
     * This is resolved in Yup v1, but that is still in beta.
     */
    expectedPriceWithoutVat: object().shape({
      amount: string()
        .nullable()
        .test(
          'name',
          isSimplified
            ? i18n.t('general.validations.fieldIsRequired')
            : i18n.t('general.validations.oneOfThoseFieldsIsRequired'),
          // context typed in yup v1
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (value: string | Nullish, context: any) => {
            const realAmount = context?.from?.[1]?.value?.realPriceWithoutVat?.amount;
            return !isCostsEditable || isNotNilOrEmpty(value) || isNotNilOrEmpty(realAmount);
          }
        ),
    }),
    realPriceWithoutVat: isSimplified
      ? object().nullable()
      : object().shape({
          amount: string()
            .nullable()
            .test(
              'name',
              isSimplified || !isCostsEditable
                ? i18n.t('general.validations.fieldIsRequired')
                : i18n.t('general.validations.oneOfThoseFieldsIsRequired'),
              // context typed in yup v1
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (value: string | Nullish, context: any) => {
                const expectedAmount = context?.from?.[1]?.value?.expectedPriceWithoutVat?.amount;
                return isNotNilOrEmpty(value) || isPositive(expectedAmount);
              }
            ),
        }),
  });
