import {openConfirmDialog} from 'platform/components';
import {match} from 'ts-pattern';

import {useCallback} from 'react';

import {mergeAll} from 'ramda';
import {isArray, noop} from 'ramda-adjunct';

import {
  usePatchHandlingUnitActiveMutation,
  usePatchHandlingUnitDefaultMutation,
  usePatchHandlingUnitInActiveMutation,
} from '@dms/api/metadaWarehouseHandlingUnit';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';

export function WarehousesMeasurementUnits() {
  const navigate = useNavigate();

  const [updateHandlingUnitDefault] = usePatchHandlingUnitDefaultMutation();
  const [updateHandlingUnitActive] = usePatchHandlingUnitActiveMutation();
  const [updateHandlingUnitInActive] = usePatchHandlingUnitInActiveMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData}) => {
    const id = isArray(rowId) ? rowId[0] : rowId;

    match(actionKey)
      .with('redirectDetail', () => {
        navigate(composePath(settingsRoutes.warehousesMeasurementUnitsDetail, {params: {id}}));
      })
      .with('setAsDefault', () => {
        openConfirmDialog({
          text: i18n.t('entity.warehouse.actions.setAsDefault'),
          onConfirm: () => {
            updateHandlingUnitDefault({id}).unwrap().then(refreshData).catch(handleApiError);
          },
        });
      })
      .with('setAsActive', () => {
        openConfirmDialog({
          text: i18n.t('entity.warehouse.actions.enableMeasurementUnit'),
          onConfirm: () => {
            updateHandlingUnitActive({id}).unwrap().then(refreshData).catch(handleApiError);
          },
        });
      })
      .with('setAsInactive', () => {
        openConfirmDialog({
          text: i18n.t('entity.warehouse.actions.disableMeasurementUnit'),
          onConfirm: () => {
            updateHandlingUnitInActive({id}).unwrap().then(refreshData).catch(handleApiError);
          },
        });
      })
      .otherwise(noop);
  };

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {codelistCategory: 'wrh-handling-units'}]),
    []
  );

  const header: SettingsTemplateHeader = {
    title: i18n.t('entity.warehouse.labels.measurementUnits'),
    actions: [
      {
        type: 'button',
        title: i18n.t('entity.warehouse.actions.newMeasurementUnit'),
        onClick: () => navigate(settingsRoutes.warehousesMeasurementUnitsCreate),
      },
    ],
  };

  return (
    <SettingsTemplate
      data-testid={testIds.settings.warehousesMeasurementUnits('template')}
      header={header}
    >
      <DataGrid
        gridCode="user-codelist"
        actionCallback={actionCallback}
        queryModifier={queryModifier}
      />
    </SettingsTemplate>
  );
}
