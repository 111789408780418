import {z} from 'zod';

import i18n from '@dms/i18n';

export const VehicleWarehouseFormSchema = z
  .object({
    name: z.string().min(1),
    customId: z.string().min(1),
    costCentre: z.string().nullable(),
    companyId: z.string().min(1),
    branchIds: z.array(z.string().min(1)).min(1),
    receivingSeriesId: z.string().min(1),
    issuingSeriesId: z.string().min(1),
    serialNumberSeriesId: z.string().min(1),
    overrideBusinessCaseSeries: z.boolean(),
    invoiceSeriesId: z.string().nullable(),
    proformaInvoiceSeriesId: z.string().nullable(),
    taxDocumentSeriesId: z.string().nullable(),
    correctiveTaxDocumentSeriesId: z.string().nullable(),
  })
  .superRefine((data, ctx) => {
    if (!data.overrideBusinessCaseSeries) {
      return;
    }

    const requiredFields = [
      'invoiceSeriesId',
      'proformaInvoiceSeriesId',
      'taxDocumentSeriesId',
      'correctiveTaxDocumentSeriesId',
    ];

    requiredFields.forEach((field) => {
      if (!data[field as keyof typeof data]) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: [field],
          message: i18n.t('general.errors.mixed.required'),
        });
      }
    });
  });

export type VehicleWarehouseFormSchemaType = z.infer<typeof VehicleWarehouseFormSchema>;
