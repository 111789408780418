import {openDeleteDialog, showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {path} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {useRemoveRentalPriceGroupMutation} from '@dms/api/rentalVehicles';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {catchUnhandledDataGridActions, handleApiError} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function PriceGroups() {
  const navigate = useNavigate();

  const [deletePriceGroup] = useRemoveRentalPriceGroupMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData, rowData}) => {
    const id = isArray(rowId) ? rowId[0] : rowId;
    const name = path<string>(['name', 'value'], rowData);

    match(actionKey)
      .with('edit', () => navigate(composePath(settingsRoutes.priceGroupDetail, {params: {id}})))
      .with('delete', () =>
        openDeleteDialog({
          name,
          onConfirm: async () =>
            await deletePriceGroup({rentalPriceGroupId: id})
              .unwrap()
              .then(() => {
                showNotification.success(i18n.t('general.notifications.successfullyDeleted'));
                refreshData();
              })
              .catch(handleApiError),
        })
      )
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('entity.rental.labels.priceGroups'),
        breadcrumbs: [
          {
            label: i18n.t('entity.rental.labels.rental'),
            href: settingsRoutes.priceGroupList,
            isCurrentPage: true,
          },
        ],
        actions: [
          {
            type: 'button',
            title: i18n.t('entity.rental.labels.priceGroupsNew'),
            onClick: () => navigate(settingsRoutes.priceGroupNew),
          },
        ],
      }}
      data-testid={testIds.settings.priceGroupList('template')}
    >
      <DataGrid
        gridCode="rental-price-group"
        actionCallback={actionCallback}
        data-testid={testIds.settings.priceGroupList('datagrid')}
      />
    </SettingsTemplate>
  );
}
