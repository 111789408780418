import {ThemeIconKey} from 'platform/foundation';
import {match} from 'ts-pattern';

import {EntityResourceIds} from '@dms/api/shared';

import {TaskRelations} from '../types/TaskRelations';

export function getIconKeyByTaskRelationType(relationType: TaskRelations) {
  return match(relationType)
    .returnType<ThemeIconKey>()
    .with(EntityResourceIds.customer, () => 'sidebar/customers')
    .with(EntityResourceIds.vehicle, () => 'sidebar/vehicles')
    .with(EntityResourceIds.businessCase, () => 'sidebar/business_case')
    .with(EntityResourceIds.interest, () => 'sidebar/interest')
    .otherwise(() => 'action/question_answer');
}
