import {css, useTheme} from 'styled-components';

import {useEffect, useRef} from 'react';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {calculateTimePosition} from '../utils/calculateTimePosition';

export function CurrentTimeIndicator(props: RequiredTestIdProps) {
  const theme = useTheme();
  const currentTime = useRef(new Date());
  const indicatorRef = useRef<HTMLDivElement>(null);

  /**
   * Updates the current time every 3.33 minutes (200000ms)
   * Creates an interval that updates the currentTime ref with the new Date
   * Cleans up the interval when component unmounts
   */
  useEffect(() => {
    const timer = setInterval(() => {
      currentTime.current = new Date();
    }, UPDATE_INTERVAL);

    return () => clearInterval(timer);
  }, []);

  /**
   * Scrolls the time indicator into view when component mounts
   * Centers the indicator in the viewport using scrollIntoView
   * Cleans up by nullifying the ref when component unmounts
   * @note The instant behavior is used to prevent smooth scrolling animation
   */
  useEffect(() => {
    if (indicatorRef.current) {
      indicatorRef.current.scrollIntoView({behavior: 'instant', block: 'center'});
    }
    return () => {
      if (indicatorRef.current) {
        indicatorRef.current = null;
      }
    };
  }, []);

  return (
    <div
      ref={indicatorRef}
      css={css`
        position: absolute;
        top: ${calculateTimePosition(currentTime.current)}px;
        right: -3px;
        left: 0px;
        height: 0;
        border-top: 2px solid ${theme.colors.palettes.red[60][100]};
        z-index: ${theme.zIndices.BIG_CALENDAR_CURRENT_TIME_INDICATOR};
        pointer-events: none;

        &::before {
          content: '';
          position: absolute;
          left: -10px;
          top: -7px;
          width: 12px;
          height: 12px;
          background-color: ${theme.colors.palettes.red[60][100]};
          border-radius: 50%;
        }
      `}
      data-testid={suffixTestId('currentTimeIndicator', props)}
    />
  );
}

const UPDATE_INTERVAL = 200000; // 3.33 minutes
