import {
  closeCurrentDialog,
  openDeleteDialog,
  openDialog,
  showNotification,
} from 'platform/components';
import {match} from 'ts-pattern';

import {forwardRef, useImperativeHandle} from 'react';

import {isArray} from 'ramda-adjunct';

import {
  BulkPatchDeliveryNoteRequest,
  useBulkDeleteDeliveryNoteMutation,
  useBulkPatchDeliveryNoteMutation,
  useBulkPostReceiveNoteMutation,
} from '@dms/api/metadaWarehouseDeliveryNote';
import i18n from '@dms/i18n';
import {testIds, warehouseRoutes} from '@dms/routes';
import {catchUnhandledDataGridActions, handleApiError} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid, useRefreshDataGrid} from 'features/datagrid';

import {FullscreenDataGridWrapper} from '../../components/FullscreenDataGridWrapper';
import {DeliveryNoteListBulkEdit} from './components/DeliveryNoteListBulkEdit';

export type DeliveryNoteListHandle = {
  refreshDataGrid: () => void;
};

export const DeliveryNoteList = forwardRef<DeliveryNoteListHandle>((_, ref) => {
  const navigate = useNavigate();
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const [deleteDeliveryNote] = useBulkDeleteDeliveryNoteMutation();
  const [postReceiveNote] = useBulkPostReceiveNoteMutation();
  const [bulkPatchDeliveryNote] = useBulkPatchDeliveryNoteMutation();

  useImperativeHandle(
    ref,
    () => ({
      refreshDataGrid,
    }),
    []
  );

  const handleBulkEditDeliveryNote = async (requestBody: BulkPatchDeliveryNoteRequest) => {
    await bulkPatchDeliveryNote(requestBody)
      .unwrap()
      .then(() =>
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'))
      )
      .then(closeCurrentDialog)
      .then(refreshDataGrid)
      .catch(handleApiError);
  };

  const handleDelete = (rowId: string | string[], deselectAll: () => void) => {
    openDeleteDialog({
      onConfirm: async () =>
        await deleteDeliveryNote({deliveryNoteId: isArray(rowId) ? rowId : [rowId]})
          .unwrap()
          .then(deselectAll)
          .then(() => {
            showNotification.success(i18n.t('general.notifications.successfullyDeleted'));
          })
          .then(refreshDataGrid)
          .catch(handleApiError),
    });
  };

  const handleCreateReceiveNote = async (rowId: string | string[]) => {
    await postReceiveNote({deliveryNoteId: isArray(rowId) ? rowId : [rowId]})
      .unwrap()
      .then(() => showNotification.success(i18n.t('general.notifications.successfullyCreated')))
      .then(refreshDataGrid)
      .catch(handleApiError);
  };

  const actionCallback: ActionCallback = async ({
    actionKey,
    rowId,
    deselectAll,
    sourceSearchParams,
  }) => {
    await match(actionKey)
      .with('redirectDetail', 'edit', () => {
        const id = isArray(rowId) ? rowId[0] : rowId;
        navigate(
          composePath(warehouseRoutes.deliveryNoteDetailOverview, {
            queryParams: sourceSearchParams,
            params: {id},
            isSearchParamsPreserved: false,
          })
        );
      })
      .with('bulkEdit', () => {
        openDialog(
          <DeliveryNoteListBulkEdit
            rowId={rowId}
            onSubmit={handleBulkEditDeliveryNote}
            data-testid={testIds.warehouse.deliveryNoteList('dialogs.bulkEdit')}
          />,
          {
            size: 'small',
            title: i18n.t('entity.warehouse.labels.bulkEdit'),
          }
        );
      })
      .with('delete', () => {
        handleDelete(rowId, deselectAll);
      })
      .with('createReceiveNote', async () => {
        await handleCreateReceiveNote(rowId);
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <FullscreenDataGridWrapper hideCardWrapper>
      <DataGrid
        ref={dataGridRef}
        gridCode="warehouse-delivery-notes"
        actionCallback={actionCallback}
        data-testid="warehouse-delivery-notes"
      />
    </FullscreenDataGridWrapper>
  );
});
