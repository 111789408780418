import {openDialog} from 'platform/components';
import {Box} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';
import {match} from 'ts-pattern';

import {useCallback} from 'react';

import {head, isNil, mergeAll} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {
  TotalPriceWithAndWithoutVat,
  useDeleteSupplierOrderItemsMutation,
} from '@dms/api/metadaWarehouseSupplierOrder';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {catchUnhandledDataGridActions, DEFAULT_CURRENCY, logError} from '@dms/shared';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject} from 'features/datagrid';

import {OverviewTotalprice} from '../../../../../components/OverviewTotalPrice';
import {WAREHOUSE_GRID_CODES} from '../../../../../constants/warehouseGridCodes';
import {useWarehouseParams} from '../../../../../hooks/useWarehouseParams';
import {EDIT_SUPPLIER_ORDER_ITEM_DIALOG} from '../constants/editSupplierOrderItemDialog';
import {EditItem} from './EditItem';

interface ListOfItemsTabProps extends TestIdProps {
  totalPrice: TotalPriceWithAndWithoutVat | Nullish;
  isPending: boolean;
}

export function ListOfItemsTab(props: ListOfItemsTabProps) {
  const {supplierOrderId} = useWarehouseParams();
  const formatCurrency = useFormatCurrency();

  const [deleteSupplierOrderItems] = useDeleteSupplierOrderItemsMutation();

  const handleEditItem = (supplierOrderItemId: string, refreshDatagrid: VoidFunction) => {
    openDialog(
      <EditItem
        supplierOrderId={supplierOrderId}
        supplierOrderItemId={supplierOrderItemId}
        onSubmit={refreshDatagrid}
        data-testid={testIds.warehouse.supplierOrderDetailOverview('forms.editItem')}
      />,
      {
        id: EDIT_SUPPLIER_ORDER_ITEM_DIALOG,
        title: i18n.t('general.labels.edit'),
        size: 'large',
        withAdditionalFooter: true,
        'data-testid': testIds.warehouse.supplierOrderDetailOverview('dialogs.editItem'),
      }
    );
  };

  const totalPriceWithoutVat = formatCurrency(
    props.totalPrice?.totalWithoutVat?.amount ?? 0,
    props.totalPrice?.totalWithoutVat?.currency ?? DEFAULT_CURRENCY,
    2
  );

  const totalPriceWithVat = formatCurrency(
    props.totalPrice?.totalWithVat?.amount ?? 0,
    props.totalPrice?.totalWithVat?.currency ?? DEFAULT_CURRENCY,
    2
  );

  const actionCallback: ActionCallback = async ({actionKey, rowId, refreshData}) => {
    await match(actionKey)
      .with('redirectDetail', 'edit', () => {
        const supplierOrderItemId = isArray(rowId) ? head(rowId) : rowId;

        if (isNil(supplierOrderItemId)) {
          return logError('Item id is not defined!');
        }

        handleEditItem(supplierOrderItemId, refreshData);
      })
      .with('delete', async () => {
        const itemId = isArray(rowId) ? rowId : [rowId];

        await deleteSupplierOrderItems({orderId: supplierOrderId, body: {itemId}})
          .unwrap()
          .then(refreshData);
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {orderId: supplierOrderId}]),
    [supplierOrderId]
  );

  return (
    <>
      <Box height="95%">
        <DataGrid
          gridCode={WAREHOUSE_GRID_CODES.warehouseSupplierOrderItems}
          actionCallback={actionCallback}
          queryModifier={queryModifier}
          data-testid="warehouse-supplier-order-items"
        />
      </Box>
      <OverviewTotalprice
        totalPriceWithoutVat={totalPriceWithoutVat}
        totalPriceWithVat={totalPriceWithVat}
        data-testid={suffixTestId('totalPrice', props)}
      />
    </>
  );
}
