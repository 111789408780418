export function ErrorImage() {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g style={{transformOrigin: 'center', animation: 'bounce 0.5s ease-in-out'}}>
        <path
          d="M39.9998 73.3334C58.4093 73.3334 73.3332 58.4095 73.3332 40C73.3332 21.5905 58.4093 6.66669 39.9998 6.66669C21.5903 6.66669 6.6665 21.5905 6.6665 40C6.6665 58.4095 21.5903 73.3334 39.9998 73.3334Z"
          fill="#FFEBE6"
          stroke="#E32C1E"
          strokeWidth="3"
          style={{
            fillOpacity: 0,
            animation: 'fadeInFill 2s ease forwards',
          }}
        />
      </g>
      <g style={{transformOrigin: 'center', animation: 'bounceExclamation 0.5s ease-in-out 0.1s'}}>
        <path
          d="M39.9736 54H40.0036"
          stroke="#DB3D3D"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40 46L40 26"
          stroke="#DB3D3D"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <style>
        {`
          @keyframes fadeInFill {
            to {
              fill-opacity: 1;
            }
          }
          @keyframes bounce {
            0%, 100% { transform: scale(1); }
            50% { transform: scale(1.15); }
          }
          @keyframes bounceExclamation {
            0%, 100% { transform: scale(1); }
            50% { transform: scale(1.3); }
          }
        `}
      </style>
    </svg>
  );
}
