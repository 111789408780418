import {z} from 'zod';

import {BaseVoidResponseSchema} from '@dms/api/shared';

export const PostPostponeJobApiResponseSchema = z.unknown();

export type PostPostponeJobApiResponse = z.infer<typeof PostPostponeJobApiResponseSchema>;

export const PostPostponeJobApiArgSchema = z.object({
  serviceJobId: z.string(),
  serviceOrderId: z.string(),
  serviceCaseId: z.string(),
  body: z.object({
    postponedUntil: z.string().nullable(),
    postponeReason: z.string().nullable(),
  }),
});

export type PostPostponeJobApiArg = z.infer<typeof PostPostponeJobApiArgSchema>;

export const GetPostponeJobsByVehicleIdApiResponseSchema = z
  .object({
    postponeJob: z.array(
      z.object({
        postponeJobId: z.string(),
        name: z.string(),
        postponedUntil: z.string().nullable().optional(),
        postponeReason: z.string().nullable().optional(),
        postponedAt: z.string(),
        postponedBy: z.string(),
      })
    ),
    count: z.number().nullable().optional(),
  })
  .nullable();

export type GetPostponeJobsByVehicleIdApiResponse = z.infer<
  typeof GetPostponeJobsByVehicleIdApiResponseSchema
>;

export const GetPostponeJobsByVehicleIdApiArgSchema = z.object({
  vehicleId: z.string(),
});

export type GetPostponeJobsByVehicleIdApiArg = z.infer<
  typeof GetPostponeJobsByVehicleIdApiArgSchema
>;

export const GetPostponeJobApiResponseSchema = z
  .object({
    postponeJobId: z.string(),
    name: z.string(),
    state: z
      .enum(['SVCPOSTPONEJOB_POSTPONE', 'SVCPOSTPONEJOB_DONE', 'SVCPOSTPONEJOB_CANCELLED'])
      .nullable()
      .optional(),
    description: z.string().nullable().optional(),
    solution: z.string().nullable().optional(),
    workTeam: z.string().nullable().optional(),
    workTimeEstimated: z.number().nullable().optional(),
    totalPriceEstimated: z.number().nullable().optional(),
    repeatedRepair: z.boolean(),
    partsReturn: z.boolean(),
    priority: z.number().nullable().optional(),
    postponedUntil: z.string().nullable().optional(),
    postponeReason: z.string().nullable().optional(),
  })
  .nullable();

export type GetPostponeJobApiResponse = z.infer<typeof GetPostponeJobApiResponseSchema>;

export const GetPostponeJobApiArgSchema = z.object({
  postponeJobId: z.string(),
});

export type GetPostponeJobApiArg = z.infer<typeof GetPostponeJobApiArgSchema>;

export const DeletePostponeJobApiResponseSchema = z.unknown();

export type DeletePostponeJobApiResponse = z.infer<typeof DeletePostponeJobApiResponseSchema>;

export const DeletePostponeJobApiArgSchema = z.object({
  postponeJobId: z.string(),
  serviceCaseId: z.string().optional(),
  vehicleId: z.string().optional(),
});

export type DeletePostponeJobApiArg = z.infer<typeof DeletePostponeJobApiArgSchema>;

export const PatchPostponeJobApiResponseSchema = z.unknown();

export type PatchPostponeJobApiResponse = z.infer<typeof PatchPostponeJobApiResponseSchema>;

export const PatchPostponeJobApiArgSchema = z.object({
  postponeJobId: z.string(),
  serviceCaseId: z.string().optional(),
  vehicleId: z.string().optional(),
  body: z
    .object({
      name: z.string().nullable().optional(),
      description: z.string().nullable().optional(),
      solution: z.string().nullable().optional(),
      workTeam: z.string().nullable().optional(),
      workTimeEstimated: z.number().nullable().optional(),
      totalPriceEstimated: z.number().nullable().optional(),
      repeatedRepair: z.boolean().nullable().optional(),
      partsReturn: z.boolean().nullable().optional(),
      priority: z.number().nullable().optional(),
      postponedUntil: z.string().nullable().optional(),
      postponeReason: z.string().nullable().optional(),
    })
    .nullable(),
});

export type PatchPostponeJobApiArg = z.infer<typeof PatchPostponeJobApiArgSchema>;

export const DeletePostponeJobsApiApiResponseSchema = BaseVoidResponseSchema;

export type DeletePostponeJobsApiApiResponse = z.infer<
  typeof DeletePostponeJobsApiApiResponseSchema
>;

export const DeletePostponeJobsApiApiArgSchema = z.object({
  body: z.array(
    z
      .object({
        postponeJobId: z.string(),
      })
      .nullable()
  ),
});

export type DeletePostponeJobsApiApiArg = z.infer<typeof DeletePostponeJobsApiApiArgSchema>;

export const PostPostponeJobPostponeUntilApiApiResponseSchema = BaseVoidResponseSchema;

export type PostPostponeJobPostponeUntilApiApiResponse = z.infer<
  typeof PostPostponeJobPostponeUntilApiApiResponseSchema
>;

export const PostPostponeJobPostponeUntilApiApiArgSchema = z.object({
  vehicleId: z.string().optional(),
  serviceCaseId: z.string().optional(),
  body: z
    .object({
      postponeJobId: z.array(z.string()),
      postponedUntil: z.string().nullable().optional(),
      postponeReason: z.string().nullable().optional(),
    })
    .nullable(),
});

export type PostPostponeJobPostponeUntilApiApiArg = z.infer<
  typeof PostPostponeJobPostponeUntilApiApiArgSchema
>;
