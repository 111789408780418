import {
  ButtonGroup,
  Card,
  closeCurrentDialog,
  CustomRequestOption,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  isFile,
  showNotification,
  Upload,
  UploadState,
} from 'platform/components';
import {Box, Show, VStack} from 'platform/foundation';
import {match, Pattern} from 'ts-pattern';
import {z} from 'zod';

import {useState} from 'react';

import {always, isNil, isNotNil} from 'ramda';

import {useUploadFileMutation} from '@dms/api/core';
import {
  TemplateResponseBodyV2,
  useGetTemplatesQuery,
  useAddDocumentTemplateMutation,
  usePatchDocumentTemplateMutation,
} from '@dms/api/document';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';
import {AddTemplateRequestBody} from '@dms/teas';

import {buildObject, DOCX_MIME_TYPE, useBoolean} from 'shared';

interface EditTemplateFormProps {
  template?: TemplateResponseBodyV2;
  documentKindCode: string;
}

type FormValues = {
  title: string;
  note: string | null;
};

type File = {
  fileUri: string;
  fileName: string;
  fileId: string;
  isPrefilled: boolean;
};

export function UploadDocumentTemplateForm(props: EditTemplateFormProps) {
  const {refetch} = useGetTemplatesQuery({});
  const [uploadFile, {isLoading}] = useUploadFileMutation();
  const [addTemplate] = useAddDocumentTemplateMutation();
  const [patchTemplate] = usePatchDocumentTemplateMutation();

  const [isFileError, setIsFileError, clearFileError] = useBoolean();
  const [file, setFile] = useState<File | null>(
    props.template
      ? {
          fileId: props.template.id,
          fileName: props.template.fileName,
          fileUri: props.template.url,
          isPrefilled: true,
        }
      : null
  );

  const handleSubmit: FormSubmitHandler<FormValues> = async (values) => {
    if (isNil(file)) {
      setIsFileError();
      return;
    }

    const submitData = buildObject<AddTemplateRequestBody>()
      .fileId(file.fileId, !file.isPrefilled)
      .note(values.note)
      .title(values.title)
      .documentKindCode(props.documentKindCode)
      .build();

    const submitAction = isNil(props.template)
      ? addTemplate({addTemplateRequestBody: submitData})
      : patchTemplate({patchTemplateRequestBody: {...submitData, templateId: props.template.id}});

    await submitAction
      .unwrap()
      .then(refetch)
      .then(() => {
        showNotification.success();
        closeCurrentDialog();
      })
      .catch(handleApiError);
  };

  const handleUploadFile = async ({file}: CustomRequestOption) => {
    clearFileError();

    if (!isFile(file)) {
      return;
    }
    await uploadFile({file})
      .unwrap()
      .then((data) => {
        setFile({
          fileName: data.file.name,
          fileUri: data.fileUri,
          fileId: data.fileId,
          isPrefilled: false,
        });
      })
      .catch(handleApiError);
  };

  return (
    <Form<FormValues>
      defaultValues={{title: props.template?.title, note: props.template?.note ?? null}}
      onSubmit={handleSubmit}
      onInvalidSubmit={() => {
        if (isNil(file)) {
          setIsFileError();
        } else {
          clearFileError();
        }
      }}
      experimentalZodSchema={schema}
    >
      {(control) => (
        <VStack spacing={4}>
          <FormField
            control={control}
            name="title"
            label={i18n.t('entity.document.labels.templateTitle')}
            type="text"
          />

          <FormField
            control={control}
            name="note"
            label={i18n.t('entity.document.labels.note')}
            type="text"
          />

          <Show when={isNil(file)}>
            <Box height={30}>
              <Upload
                type="card"
                accept={DOCX_MIME_TYPE}
                uploadState={match([isFileError, isLoading])
                  .with([true, Pattern.boolean], always(UploadState.Error))
                  .with([false, true], always(UploadState.Uploading))
                  .otherwise(always(UploadState.Idle))}
                errorIcon="navigation/cancel"
                uploadingIcon="action/hourglass_empty"
                uploadIcon="file/upload"
                customRequest={handleUploadFile}
              />
            </Box>
          </Show>

          <Show when={isNotNil(file)}>
            <VStack spacing={2}>
              <Card
                title={file?.fileName}
                colorStripe="blue"
                actions={[
                  {
                    type: 'custom',
                    component: (
                      <Upload
                        type="button"
                        accept={DOCX_MIME_TYPE}
                        uploadText={i18n.t('entity.vehicle.labels.override')}
                        uploadState={isLoading ? UploadState.Uploading : UploadState.Idle}
                        errorIcon="navigation/cancel"
                        uploadingIcon="action/hourglass_empty"
                        uploadIcon="file/upload"
                        customRequest={handleUploadFile}
                      />
                    ),
                  },
                ]}
              />
            </VStack>
          </Show>

          <ButtonGroup align="right">
            <FormButton
              variant="secondary"
              title={i18n.t('general.actions.discard')}
              control={control}
            />
            <FormButton
              type="submit"
              variant="primary"
              control={control}
              isDisabled={isLoading}
              title={
                isNotNil(props.template)
                  ? i18n.t('general.actions.save')
                  : i18n.t('general.actions.add')
              }
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}

const schema = z.object({
  title: z.string().nonempty(),
  note: z.string().nullable(),
});
