export function ChecklistImage() {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none">
      <path d="M36.6667 20H70" stroke="#BFE0FF" strokeWidth="3" strokeLinecap="round" />
      <path d="M36.6667 40H70" stroke="#BFE0FF" strokeWidth="3" strokeLinecap="round" />
      <path d="M36.6667 60H70" stroke="#BFE0FF" strokeWidth="3" strokeLinecap="round" />
      <path
        d="M10 24.6428C10 24.6428 13.3333 26.8155 15 29.9999C15 29.9999 20 17.4999 26.6667 13.3333"
        stroke="#0065FF"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 61.3097C10 61.3097 13.3333 63.4823 15 66.6667C15 66.6667 20 54.1667 26.6667 50"
        stroke="#0065FF"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
