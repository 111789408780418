import {isBefore} from 'date-fns';

import {TaskResponseBody} from '@dms/api/taskManagement';

import {parseDate} from 'shared';

import {IconColors} from '../constants/activityItem';

export const transformTaskToActivity = (task: TaskResponseBody) => {
  const isFinished = task?.finishedAt !== null;
  const taskDueDate = task?.dueTo ?? task?.dueDate;
  const isOverdue = taskDueDate && isBefore(parseDate(taskDueDate), new Date());

  return {
    id: task.id,
    userId: task.createdBy,
    reasonCloseCodeId: null,
    reasonCloseNote: null,
    interestId: null,
    description: task.title,
    activityType: 'TASK_CREATED' as const,
    timestamp: task.dueDate ?? task.createdAt ?? new Date().toISOString(),
    color: !isFinished && isOverdue ? IconColors.RED : IconColors.GREEN,
    userName: '',
    relatedTask: task,
    relatedMessage: null,
    relatedCall: null,
    isEditable: true,
    subject: null,
    interestCode: null,
    interestType: null,
    businessCaseId: null,
    businessCaseCode: null,
    businessCaseType: null,
  };
};
