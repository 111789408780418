import {useGetListBusinessCaseProformaInvoiceSettingQuery} from '@dms/api/checkout';
import {useGetCurrentBranch} from '@dms/shared';

export const useProformaCreationSettings = () => {
  const {activeBranchId} = useGetCurrentBranch();
  const {data: proformaCreationSettings} = useGetListBusinessCaseProformaInvoiceSettingQuery();

  const settingsForCurrentBranch = proformaCreationSettings?.find(
    (setting) => setting.branchId === activeBranchId
  );
  const systemSettings = proformaCreationSettings?.find((setting) => setting.isSystem);
  const usedSetting = settingsForCurrentBranch ?? systemSettings;

  return usedSetting?.proformaInvoiceSettings === 'with-proforma-invoice';
};
