import {Card, DataStatus, useDialog} from 'platform/components';
import {Box} from 'platform/foundation';

import {useCallback} from 'react';

import {mergeAll} from 'ramda';

import {useGetDocumentDatagridActions} from '@dms/features/document-context';
import i18n from '@dms/i18n';
import {Section, UploadDocumentsDialog} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {DataGrid, QueryFilterObject, refreshDatagrid} from 'features/datagrid';

import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';

interface DocumentsProps extends RequiredTestIdProps {
  isServiceOrderIssueLoading: boolean;
}

export function Documents(props: DocumentsProps) {
  const {serviceOrderIssueNoteId} = useWarehouseParams();

  const [isUploadDialogOpen, openUploadDialog, closeUploadDialog] = useDialog();

  const [rowActions] = useGetDocumentDatagridActions({
    contextId: serviceOrderIssueNoteId,
    contextTarget: 'service-order-issue-note',
    refreshData: () => refreshDatagrid(DOCUMENTS_GRID_CODE),
  });

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {serviceOrderIssueNoteId}]),
    [serviceOrderIssueNoteId]
  );

  return (
    <Section data-testid={suffixTestId('wrapper', props)}>
      <DataStatus isLoading={props.isServiceOrderIssueLoading} minHeight={60}>
        <Card
          title={i18n.t('page.warehouse.labels.documents')}
          actions={[
            {
              type: 'button',
              variant: 'ghostLink',
              leftIcon: 'content/add_circle',
              title: i18n.t('entity.warehouse.actions.addDocument'),
              onClick: openUploadDialog,
              'data-testid': suffixTestId('action.addDocument', props),
            },
          ]}
        >
          <Box height="65vh">
            <DataGrid
              gridCode={DOCUMENTS_GRID_CODE}
              emptyState={{headline: i18n.t('general.labels.noData')}}
              actionCallback={rowActions}
              queryModifier={queryModifier}
              autoHeight
              data-testid={suffixTestId('documents', props)}
            />
          </Box>
        </Card>
      </DataStatus>

      <UploadDocumentsDialog
        isOpen={isUploadDialogOpen}
        handleClose={closeUploadDialog}
        onDocumentCreated={() => refreshDatagrid(DOCUMENTS_GRID_CODE)}
        contextTarget="service-order-issue-note"
        contextId={serviceOrderIssueNoteId}
        bulkContext={[{target: 'service-order-issue-note', targetId: serviceOrderIssueNoteId}]}
        data-testid={suffixTestId('upload-document', props)}
      />
    </Section>
  );
}

const DOCUMENTS_GRID_CODE = 'document-context-list-service-order-issue-note';
