import {FormSubmitHandler} from 'platform/components';

import {useParams} from 'react-router-dom';

import {isNil} from 'ramda';

import {
  useGetTireCategoryQuery,
  usePatchTireCategoryMutation,
  usePostTireCategoryMutation,
} from '@dms/api/metadaTires';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {useNavigate} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {TireDictionaryForm} from '../../components/TireDictionaryForm/TireDictionaryForm';
import {TireDictionaryFormType} from '../../types/TireDictionaryFormType';

export function TireInventoryCategoryDetail() {
  const {id} = useParams();
  const navigate = useNavigate();
  const {data, isLoading, isError} = useGetTireCategoryQuery(
    {tireCategoryId: id ?? ''},
    {skip: isNil(id)}
  );
  const [postTireCategory] = usePostTireCategoryMutation();
  const [patchTireCategory] = usePatchTireCategoryMutation();

  const handleSubmit: FormSubmitHandler<TireDictionaryFormType> = async (formData) => {
    const body = {name: formData.name};

    if (isNil(id)) {
      return await postTireCategory({
        body,
      })
        .unwrap()
        .then(() => navigate(settingsRoutes.tireCategory))
        .catch(handleApiError);
    }

    await patchTireCategory({
      tireCategoryId: id,
      body,
    })
      .unwrap()
      .then(() => navigate(settingsRoutes.tireCategory))
      .catch(handleApiError);
  };

  return (
    <SettingsTemplate
      header={{
        title: data?.name ?? i18n.t('entity.tiresInventory.labels.newTireCategory'),
        breadcrumbs: [
          {
            label: i18n.t('module.tiresInventory.title'),
            isCurrentPage: true,
          },
          {
            label: i18n.t('entity.tiresInventory.labels.tireCategory'),
            href: settingsRoutes.tireCategory,
          },
        ],
      }}
      isLoading={isLoading}
      isError={isError}
      isCreating={isNil(id)}
      data-testid={testIds.settings.tireCategoryDetail('template')}
    >
      <TireDictionaryForm
        onSubmit={handleSubmit}
        defaultValues={{name: data?.name}}
        onDiscard={() => navigate(settingsRoutes.tireCategory)}
        data-testid={testIds.settings.tireCategoryDetail('form')}
      />
    </SettingsTemplate>
  );
}
