import {Card, DataStatus, FormControl} from 'platform/components';
import {Box, Grid, GridItem, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {RentalVehicleFormMode} from '../../types/RentalVehicleFormMode';
import {RentalVehicleFormType} from '../../types/RentalVehicleFormType';
import {Additional} from './components/Additional';
import {Main} from './components/Main';

interface BasicInformationProps extends RequiredTestIdProps {
  control: FormControl<RentalVehicleFormType>;
  formApi: UseFormReturn<RentalVehicleFormType>;
  mode: RentalVehicleFormMode;
  isLoading?: boolean;
}

export function BasicInformation(props: BasicInformationProps) {
  return (
    <Box padding={4}>
      <Grid columns={3}>
        <GridItem span={2}>
          <VStack spacing={4}>
            <Card>
              <DataStatus isLoading={props.isLoading}>
                <Main
                  control={props.control}
                  formApi={props.formApi}
                  mode={props.mode}
                  data-testid={suffixTestId('main', props)}
                />
              </DataStatus>
            </Card>
            <Card title={i18n.t('general.labels.additionalInformation')}>
              <DataStatus isLoading={props.isLoading}>
                <Additional
                  control={props.control}
                  data-testid={suffixTestId('additional', props)}
                />
              </DataStatus>
            </Card>
          </VStack>
        </GridItem>
      </Grid>
    </Box>
  );
}
