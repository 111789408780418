import {Actions, DataStatus, Form, FormField, FormSubmitHandler} from 'platform/components';
import {VStack} from 'platform/foundation';
import {object} from 'yup';

import {FieldValues} from 'react-hook-form';

import {isNil} from 'ramda';

import {
  useGetServicePackageCategoryQuery,
  usePatchServicePackageCategoryMutation,
  usePostServicePackageCategoryMutation,
} from '@dms/api/metadaWorkshopServicePackagesCategory';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {TestIdProps, suffixTestId, yupString} from 'shared';

interface PackagesCategoryFormFormProps extends TestIdProps {
  categoryId?: string;
  onClose: () => void;
}

type FormType = {
  name: string;
  description: string;
} & FieldValues;

export function PackagesCategoryForm(props: PackagesCategoryFormFormProps) {
  const isCreating = isNil(props.categoryId);

  const [postServicePackageCategory] = usePostServicePackageCategoryMutation();
  const [patchServicePackageCategory] = usePatchServicePackageCategoryMutation();
  const {
    data: category,
    isLoading,
    isError,
  } = useGetServicePackageCategoryQuery({categoryId: props.categoryId ?? ''}, {skip: isCreating});

  const onSubmit: FormSubmitHandler<FormType> = async (data) => {
    if (isCreating) {
      return postServicePackageCategory({
        body: data,
      })
        .unwrap()
        .then(props.onClose)
        .catch(handleApiError);
    }

    await patchServicePackageCategory({
      body: data,
      categoryId: props.categoryId ?? '',
    })
      .unwrap()
      .then(props.onClose)
      .catch(handleApiError);
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<FormType>
        schema={schema}
        onSubmit={onSubmit}
        defaultValues={{
          name: category?.name ?? undefined,
          description: category?.description ?? undefined,
        }}
      >
        {(control) => (
          <VStack spacing={4}>
            <FormField
              control={control}
              name="name"
              type="text"
              isRequired
              label={i18n.t('general.labels.name')}
              data-testid={suffixTestId('name', props)}
            />
            <FormField
              control={control}
              name="description"
              type="textarea"
              label={i18n.t('general.labels.note')}
              data-testid={suffixTestId('description', props)}
            />
            <Actions
              align="right"
              data-testid={suffixTestId('actions', props)}
              actions={[
                {
                  type: 'button',
                  variant: 'secondary',
                  title: isCreating
                    ? i18n.t('general.actions.discard')
                    : i18n.t('general.actions.discardChanges'),
                  onClick: props.onClose,
                  'data-testid': suffixTestId('discard', props),
                },
                {
                  type: 'form-button',
                  control,
                  buttonType: 'submit',
                  variant: 'primary',
                  title: isCreating
                    ? i18n.t('general.actions.save')
                    : i18n.t('general.actions.saveChanges'),
                  'data-testid': suffixTestId('save', props),
                },
              ]}
            />
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}

const schema = object().shape({
  name: yupString.required(),
  description: yupString.optional(),
});
