import {omneticApi} from '@dms/api/core';
import {
  GetWatermarkApiResponse,
  UpdateWatermarkApiResponse,
  UpdateWatermarkApiArg,
  GetPreviewApiResponse,
  GetPreviewApiArg,
  GetPublicPreviewApiResponse,
  GetPublicPreviewApiArg,
  GetPreviewFromSettingsApiResponse,
  GetPreviewFromSettingsApiArg,
  PatchAdvertisedVehicleInAdvertisementUpdatedAtApiResponse,
  PatchAdvertisedVehicleInAdvertisementUpdatedAtApiArg,
} from '@dms/api/shared';
import {browserStorageKey} from '@dms/config';

export const watermarkApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getWatermark: build.query<GetWatermarkApiResponse, void>({
      query: () => ({url: `/dms/v2/sales/watermark`}),
      providesTags: ['watermark'],
    }),
    updateWatermark: build.mutation<UpdateWatermarkApiResponse, UpdateWatermarkApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sales/watermark`,
        method: 'PUT',
        body: queryArg.updateWatermarkRequestBody,
      }),
      invalidatesTags: ['watermark'],
    }),
    getPreview: build.query<GetPreviewApiResponse, GetPreviewApiArg>({
      query: (queryArg) => ({url: `/dms/v2/sales/watermark/preview/${queryArg.vehiclePhotoId}`}),
    }),
    getPublicPreview: build.query<GetPublicPreviewApiResponse, GetPublicPreviewApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/storage/watermark-processor/${queryArg.remoteFileId}`,
        method: 'POST',
        params: {token: sessionStorage.getItem(browserStorageKey.ACCESS_TOKEN)},
      }),
    }),
    patchAdvertisedVehicleInAdvertisementUpdatedAt: build.mutation<
      PatchAdvertisedVehicleInAdvertisementUpdatedAtApiResponse,
      PatchAdvertisedVehicleInAdvertisementUpdatedAtApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/sales/publishing/vehicle/${queryArg.vehicleId}/advertisement/updated-at`,
        method: 'PATCH',
        body: queryArg.patchAdvertisedVehicleInAdvertisementUpdatedAtRequestBody,
      }),
    }),
    getPreviewFromSettings: build.query<
      GetPreviewFromSettingsApiResponse,
      GetPreviewFromSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/sales/watermark/settings-preview`,
        params: {
          fileId: queryArg.fileId,
          position: queryArg.position,
          size: queryArg.size,
          fitToImage: queryArg.fitToImage,
        },
      }),
    }),
  }),
});

export const {
  useGetWatermarkQuery,
  useUpdateWatermarkMutation,
  useGetPreviewQuery,
  useGetPublicPreviewQuery,
  useGetPreviewFromSettingsQuery,
  usePatchAdvertisedVehicleInAdvertisementUpdatedAtMutation,
} = watermarkApi;
