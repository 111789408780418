import {omneticApi} from '@dms/api/core';
import {
  CreatePromotionalPhotoApiResponse,
  CreatePromotionalPhotoApiArg,
  DeletePromotionalPhotoApiResponse,
  DeletePromotionalPhotoApiArg,
  EditPromotionalPhotoApiResponse,
  EditPromotionalPhotoApiArg,
  AddToAllVehicleApiResponse,
  AddToAllVehicleApiArg,
  RemoveInAllVehiclesApiResponse,
  RemoveInAllVehiclesApiArg,
  ResetPositionsToAllVehiclesApiResponse,
  ResetPositionsToAllVehiclesApiArg,
  GetPromotionalPhotoApiResponse,
  GetPromotionalPhotoApiArg,
  GetSupportedPlatformsApiResponse,
  AddPromoPhotosToVehicleApiResponse,
  AddPromoPhotosToVehicleApiArg,
  GetPromoPhotoForVehicleApiResponse,
  GetPromoPhotoForVehicleApiArg,
  RemovePromoPhotoInVehicleApiResponse,
  RemovePromoPhotoInVehicleApiArg,
  UpdatePositionsInVehicleApiResponse,
  UpdatePositionsInVehicleApiArg,
  ResetPositionsInVehicleApiResponse,
  ResetPositionsInVehicleApiArg,
  GetPromotionalPhotosApiResponse,
} from '@dms/api/shared';

export const promotionalPhotoApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    createPromotionalPhoto: build.mutation<
      CreatePromotionalPhotoApiResponse,
      CreatePromotionalPhotoApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/promotional-photo`,
        method: 'POST',
        body: queryArg.createPromotionalPhotoRequestBody,
      }),
    }),
    getPromotionalPhotos: build.query<GetPromotionalPhotosApiResponse, void>({
      query: () => ({
        url: `/dms/v1/promotional-photo`,
      }),
    }),
    deletePromotionalPhoto: build.mutation<
      DeletePromotionalPhotoApiResponse,
      DeletePromotionalPhotoApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/promotional-photo`,
        method: 'DELETE',
        body: queryArg.deletePromotionalPhotoRequestBody,
      }),
    }),
    editPromotionalPhoto: build.mutation<
      EditPromotionalPhotoApiResponse,
      EditPromotionalPhotoApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/promotional-photo/${queryArg.promotionalPhotoId}`,
        method: 'PUT',
        body: queryArg.editPromotionalPhotoRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'promotionalPhoto', id: queryArg.promotionalPhotoId},
      ],
    }),
    getPromotionalPhoto: build.query<GetPromotionalPhotoApiResponse, GetPromotionalPhotoApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/promotional-photo/${queryArg.promotionalPhotoId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'promotionalPhoto', id: queryArg.promotionalPhotoId},
      ],
    }),
    getSupportedPlatforms: build.query<GetSupportedPlatformsApiResponse, void>({
      query: () => ({
        url: `/dms/v1/promotional-photo/supported-platforms/list`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.codes.map((code) => ({type: 'supportedPlatforms' as const, id: code})),
              {type: 'supportedPlatforms', id: 'LIST'},
            ]
          : [{type: 'supportedPlatforms', id: 'LIST'}],
    }),
    addToAllVehicle: build.mutation<AddToAllVehicleApiResponse, AddToAllVehicleApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/promotional-photo/vehicle/add-to-all-vehicles`,
        method: 'POST',
        body: queryArg.addToAllVehiclesRequestBody,
      }),
    }),
    addPromoPhotosToVehicle: build.mutation<
      AddPromoPhotosToVehicleApiResponse,
      AddPromoPhotosToVehicleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/promotional-photo/vehicle/add-all-to-vehicle`,
        method: 'POST',
        body: queryArg.addToVehicleRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'promotionalPhotoVehicle', id: queryArg.addToVehicleRequestBody.vehicleId},
      ],
    }),
    getPromoPhotoForVehicle: build.query<
      GetPromoPhotoForVehicleApiResponse,
      GetPromoPhotoForVehicleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/promotional-photo/vehicle/${queryArg.vehicleId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'promotionalPhotoVehicle', id: queryArg.vehicleId},
      ],
    }),
    removeInAllVehicles: build.mutation<RemoveInAllVehiclesApiResponse, RemoveInAllVehiclesApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/promotional-photo/vehicle/remove-in-all-vehicles`,
        method: 'DELETE',
        body: queryArg.removeInAllVehiclesRequestBody,
      }),
    }),
    removePromoPhotoInVehicle: build.mutation<
      RemovePromoPhotoInVehicleApiResponse,
      RemovePromoPhotoInVehicleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/promotional-photo/vehicle/remove-in-vehicle`,
        method: 'DELETE',
        body: queryArg.removeInVehicleRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'promotionalPhotoVehicle', id: queryArg.vehicleId},
      ],
    }),
    resetPositionsInVehicle: build.mutation<
      ResetPositionsInVehicleApiResponse,
      ResetPositionsInVehicleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/promotional-photo/vehicle/reset-positions-in-vehicle`,
        method: 'POST',
        body: queryArg.resetPositionsInVehicleRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {
          type: 'promotionalPhotoVehicle',
          id: queryArg.resetPositionsInVehicleRequestBody.vehicleId,
        },
      ],
    }),
    resetPositionsToAllVehicles: build.mutation<
      ResetPositionsToAllVehiclesApiResponse,
      ResetPositionsToAllVehiclesApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/promotional-photo/vehicle/reset-positions-to-all-vehicles`,
        method: 'POST',
        body: queryArg.resetPositionsToAllVehiclesRequestBody,
      }),
    }),
    updatePositionsInVehicle: build.mutation<
      UpdatePositionsInVehicleApiResponse,
      UpdatePositionsInVehicleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/promotional-photo/vehicle/positions`,
        method: 'PUT',
        body: queryArg.updatePositionsInVehicleRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'promotionalPhotoVehicle', id: queryArg.vehicleId},
      ],
    }),
  }),
});

export const {
  useCreatePromotionalPhotoMutation,
  useDeletePromotionalPhotoMutation,
  useEditPromotionalPhotoMutation,
  useGetPromotionalPhotoQuery,
  useGetPromotionalPhotosQuery,
  useGetSupportedPlatformsQuery,
  useAddToAllVehicleMutation,
  useAddPromoPhotosToVehicleMutation,
  useGetPromoPhotoForVehicleQuery,
  useRemoveInAllVehiclesMutation,
  useRemovePromoPhotoInVehicleMutation,
  useResetPositionsToAllVehiclesMutation,
  useUpdatePositionsInVehicleMutation,
  useResetPositionsInVehicleMutation,
} = promotionalPhotoApi;
