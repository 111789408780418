import {ButtonGroup, Card, closeCurrentDialog, openDialog, RadioItem} from 'platform/components';
import {Box, Clickable, Heading, HStack, Right, Text, VStack} from 'platform/foundation';

import {useState} from 'react';

import {equals, isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {ContractInformationResponseBodyV2, useGetCustomerV2Query} from '@dms/api/customer';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {ContractInformationForm} from '../ContactInformationForm/ContractInformationForm';
import {CustomerBillingInformationCard} from '../CustomerBillingInformationCard/CustomerBillingInformationCard';

interface Props extends TestIdProps {
  customerId: string;
  onSave: (contract: ContractInformationResponseBodyV2) => void;
  onClose?: () => void;
}

export function BillingInformationListDialog(props: Props) {
  const [selectedContract, setContract] = useState<ContractInformationResponseBodyV2 | null>(null);

  const {data: customerData} = useGetCustomerV2Query(
    {customerId: props.customerId},
    {skip: isNilOrEmpty(props.customerId)}
  );

  const onCreateContract = () =>
    openDialog(
      <ContractInformationForm
        customerId={props.customerId ?? ''}
        data-testid={suffixTestId('createContract', props)}
      />,
      {
        title: i18n.t('entity.customer.actions.newContractInformation'),
        withAdditionalFooter: true,
        'data-testid': suffixTestId('createContract', props),
      }
    );

  const onSave = () => {
    props.onSave(selectedContract!);
    closeCurrentDialog();
  };

  const onClose = () => {
    props.onClose?.();
    closeCurrentDialog();
  };

  return (
    <VStack spacing={4}>
      <Heading size={4} color="secondary">
        {i18n.t('entity.invoice.labels.selectBillingInformation')}
      </Heading>
      <VStack spacing={4}>
        {customerData?.contractInformation?.map((contract, index) => (
          <CustomerBillingInformationCard
            key={contract.id}
            customerId={props.customerId}
            isSelected={equals(selectedContract?.id, contract.id)}
            contract={contract}
            onSelect={setContract}
            data-testid={suffixTestId(index.toString(), props)}
          />
        ))}
        <Clickable onClick={onCreateContract} data-testid={props['data-testid']}>
          <Card variant="inlineWhite" data-testid={props['data-testid']}>
            <HStack align="flex-start" spacing={3}>
              <RadioItem value={false} data-testid={props['data-testid']} />
              <Box flexGrow={1}>
                <Text size="small" alternative data-testid={suffixTestId('title', props)}>
                  {i18n.t('entity.customer.actions.newBillingAndContractInformation')}
                </Text>
              </Box>
            </HStack>
          </Card>
        </Clickable>
      </VStack>
      <Right>
        <ButtonGroup
          buttons={[
            {
              title: i18n.t('general.actions.cancel'),
              variant: 'secondary',
              onClick: onClose,
            },
            {
              title: i18n.t('general.actions.save'),
              variant: 'primary',
              isDisabled: isNil(selectedContract),
              onClick: onSave,
              'data-testid': suffixTestId('submit', props),
            },
          ]}
        />
      </Right>
    </VStack>
  );
}
