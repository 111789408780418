import {isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {GetDataByDataQueryApiResponse} from '@dms/api/datagrid';
import {EMPTY_PLACEHOLDER} from '@dms/shared';

import {Card} from '../types/Card';
import {getColumnForState} from './getColumnForState';

export function mapBusinessCasesToCards(businessCases: GetDataByDataQueryApiResponse): Card[] {
  return businessCases.rows
    .filter((row) => isNotNilOrEmpty(row.cells))
    .map((row) => ({
      id: row.id,
      column: row.cells?.businessCaseState?.value?.key
        ? getColumnForState(row.cells.businessCaseState.value.key)
        : null,
      title: row.cells.vehicle?.value?.label || EMPTY_PLACEHOLDER,
      subTitle: row.cells.customerName?.value?.label || EMPTY_PLACEHOLDER,
      flags:
        row.cells.tag?.value?.map((tag: Record<string, string>) => ({
          label: tag.label,
          colorScheme: tag.color,
        })) || null,
      hasChat: false,
      hasComments: isNotNil(row.cells.comment?.value),
      price: row.cells.businessCasePrice?.value || row.cells.vehiclePriceWithVat?.value || null,
      date:
        row.cells.businessCaseUpdatedAt?.value || row.cells.businessCaseCreatedAt?.value || null,
      ownerId: row.cells.owner?.value?.id || null,
      origin: 'business-case',
    }));
}
