import {metadaApi} from '@dms/api/core';

import {
  BulkDeleteReceiveNoteBasketItemsRequest,
  BulkDeleteReceiveNoteBasketItemsRequestSchema,
  BulkDeleteReceiveNoteBasketItemsResponse,
  BulkDeleteReceiveNoteBasketItemsResponseSchema,
  DeleteReceiveNoteBasketRequest,
  DeleteReceiveNoteBasketRequestSchema,
  DeleteReceiveNoteBasketResponse,
  DeleteReceiveNoteBasketResponseSchema,
  GetReceiveNoteBasketRequest,
  GetReceiveNoteBasketRequestSchema,
  GetReceiveNoteBasketResponse,
  GetReceiveNoteBasketResponseSchema,
  PatchReceiveNoteBasketItemQuantityRequest,
  PatchReceiveNoteBasketItemQuantityRequestSchema,
  PatchReceiveNoteBasketItemQuantityResponse,
  PatchReceiveNoteBasketItemQuantityResponseSchema,
  PostReceiveNoteBasketCheckoutRequest,
  PostReceiveNoteBasketCheckoutRequestSchema,
  PostReceiveNoteBasketCheckoutResponse,
  PostReceiveNoteBasketCheckoutResponseSchema,
  PostReceiveNoteBasketItemRequest,
  PostReceiveNoteBasketItemRequestSchema,
  PostReceiveNoteBasketItemResponse,
  PostReceiveNoteBasketItemResponseSchema,
} from './types';

export const metadaWarehouseReceiveNoteBasketApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getReceiveNoteBasket: build.query<GetReceiveNoteBasketResponse, GetReceiveNoteBasketRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/receive-note/${queryArg.receiveNoteId}/basket`,
        method: 'GET',
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'receiveNoteBasket', id: queryArg.receiveNoteId},
      ],
      extraOptions: {
        requestSchema: GetReceiveNoteBasketRequestSchema,
        responseSchema: GetReceiveNoteBasketResponseSchema,
      },
    }),
    postReceiveNoteBasketCheckout: build.mutation<
      PostReceiveNoteBasketCheckoutResponse,
      PostReceiveNoteBasketCheckoutRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/receive-note/${queryArg.receiveNoteId}/basket/checkout`,
        method: 'POST',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'receiveNoteBasket', id: queryArg.receiveNoteId},
        {type: 'receiveNote', id: queryArg.receiveNoteId},
        'receiveNoteTotalPrice',
        'headerDynamicActions',
      ],
      extraOptions: {
        requestSchema: PostReceiveNoteBasketCheckoutRequestSchema,
        responseSchema: PostReceiveNoteBasketCheckoutResponseSchema,
      },
    }),
    postReceiveNoteBasketItem: build.mutation<
      PostReceiveNoteBasketItemResponse,
      PostReceiveNoteBasketItemRequest
    >({
      query: (queryArg) => ({
        url: 'warehouse/v2/receive-note-item/manual',
        method: 'POST',
        queryArg,
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'receiveNoteBasket', id: queryArg.body.receiveNoteId},
        {type: 'receiveNote', id: queryArg.body.receiveNoteId},
        'receiveNoteTotalPrice',
      ],
      extraOptions: {
        requestSchema: PostReceiveNoteBasketItemRequestSchema,
        responseSchema: PostReceiveNoteBasketItemResponseSchema,
      },
    }),
    patchReceiveNoteBasketItemQuantity: build.mutation<
      PatchReceiveNoteBasketItemQuantityResponse,
      PatchReceiveNoteBasketItemQuantityRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/receive-note/${queryArg.receiveNoteId}/basket/item/${queryArg.itemId}/quantity`,
        method: 'PATCH',
        queryArg,
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'receiveNoteBasket', id: queryArg.receiveNoteId},
      ],
      extraOptions: {
        requestSchema: PatchReceiveNoteBasketItemQuantityRequestSchema,
        responseSchema: PatchReceiveNoteBasketItemQuantityResponseSchema,
      },
    }),
    bulkDeleteReceiveNoteBasketItems: build.mutation<
      BulkDeleteReceiveNoteBasketItemsResponse,
      BulkDeleteReceiveNoteBasketItemsRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/receive-note/${queryArg.receiveNoteId}/basket/item/delete`,
        method: 'PATCH',
        queryArg,
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'receiveNoteBasket', id: queryArg.receiveNoteId},
        {type: 'receiveNote', id: queryArg.receiveNoteId},
        'receiveNoteTotalPrice',
      ],
      extraOptions: {
        requestSchema: BulkDeleteReceiveNoteBasketItemsRequestSchema,
        responseSchema: BulkDeleteReceiveNoteBasketItemsResponseSchema,
      },
    }),
    deleteReceiveNoteBasket: build.mutation<
      DeleteReceiveNoteBasketResponse,
      DeleteReceiveNoteBasketRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/receive-note/${queryArg.receiveNoteId}/basket`,
        method: 'DELETE',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'receiveNoteBasket', id: queryArg.receiveNoteId},
        {type: 'receiveNote', id: queryArg.receiveNoteId},
        'receiveNoteTotalPrice',
        'headerDynamicActions',
      ],
      extraOptions: {
        requestSchema: DeleteReceiveNoteBasketRequestSchema,
        responseSchema: DeleteReceiveNoteBasketResponseSchema,
      },
    }),
  }),
});

export const {
  useGetReceiveNoteBasketQuery,
  usePostReceiveNoteBasketCheckoutMutation,
  usePostReceiveNoteBasketItemMutation,
  usePatchReceiveNoteBasketItemQuantityMutation,
  useBulkDeleteReceiveNoteBasketItemsMutation,
  useDeleteReceiveNoteBasketMutation,
} = metadaWarehouseReceiveNoteBasketApi;
