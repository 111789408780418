import {
  eachDayOfInterval, // eslint-disable-next-line eag/no-external-date-time-format-functions -- Custom date format
  format,
  Interval,
  isPast,
  isSameDay,
  isSameMonth,
  isToday,
  isWeekend,
  isWithinInterval,
  lastDayOfMonth,
  lastDayOfWeek,
  Locale,
  startOfWeek,
  isBefore,
} from 'date-fns';

import {FC, useMemo} from 'react';

import {DayContent, DaysWrapper, DayWrapper, MonthName, MonthWrapper} from './styles';
import {DateRange} from './types';

/**
 * @internal
 */
export interface MonthProps {
  month: number;
  year: number;
  onDayClick: (date: Date) => void;
  locale?: Locale;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  selected?: Date;
  onHover?: (date: Date) => void;
  range?: DateRange;
  showYear?: boolean;
  disablePast?: boolean;
  disableTo?: Date;
}

export const Month: FC<MonthProps> = ({
  month,
  year,
  locale,
  weekStartsOn,
  onDayClick,
  selected,
  onHover,
  range,
  showYear = true,
  disablePast = false,
  disableTo,
}) => {
  const options = useMemo(
    () => ({
      locale,
      weekStartsOn,
    }),
    [locale, weekStartsOn]
  );

  const {firstDay, interval, firstSevenDays} = useMemo(() => {
    const firstDay = new Date(year, month, 1);
    const lastDay = lastDayOfMonth(firstDay);
    const intervalStart = startOfWeek(firstDay, options);
    const intervalEnd = lastDayOfWeek(lastDay, options);
    const interval = eachDayOfInterval({start: intervalStart, end: intervalEnd});
    const firstSevenDays = interval.slice(0, 7);

    return {firstDay, interval, firstSevenDays};
  }, [year, month, options]);

  return (
    <MonthWrapper>
      <MonthName>{format(firstDay, showYear ? 'LLLL yyyy' : 'LLLL', options)}</MonthName>
      <DaysWrapper>
        {firstSevenDays.map((date) => {
          const text = format(date, 'cccccc', options);
          return (
            <DayWrapper className="header" key={text}>
              {text}
            </DayWrapper>
          );
        })}
        {interval.map((date) => {
          const classes = [];
          if (isToday(date)) {
            classes.push('today');
          }
          if (disablePast && isPast(date) && !isToday(date)) {
            classes.push('past');
          }
          if (disableTo && isBefore(date, disableTo)) {
            classes.push('past');
          }
          if (isWeekend(date)) {
            classes.push('weekend');
          }
          if (selected) {
            if (isSameDay(date, selected)) {
              classes.push('selected');
            }
          }

          const wrapperClasses = [];
          if (range) {
            if (range.start && isSameDay(date, range.start)) {
              wrapperClasses.push('range-start');
            }
            if (range.end && isSameDay(date, range.end)) {
              wrapperClasses.push('range-end');
            }

            if (range.start && range.end) {
              if (isSameMonth(range.start, range.end)) {
                if (isWithinInterval(date, range as Interval) && isSameMonth(firstDay, date)) {
                  wrapperClasses.push('within-interval');
                }
              } else {
                if (isWithinInterval(date, range as Interval)) {
                  wrapperClasses.push('within-interval');
                }
              }
            }
          }
          return (
            <DayWrapper key={date.valueOf()} className={wrapperClasses.join(' ')}>
              {isSameMonth(firstDay, date) ? (
                <DayContent
                  data-testid={`calendar-day-${format(date, 'dd-MM-yy', options)}`}
                  className={classes.join(' ')}
                  onClick={onDayClick.bind(null, date)}
                  onMouseOver={onHover?.bind(null, date)}
                >
                  {format(date, 'd', options)}
                </DayContent>
              ) : (
                ''
              )}
            </DayWrapper>
          );
        })}
      </DaysWrapper>
    </MonthWrapper>
  );
};
