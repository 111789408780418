import {Card, DataStatus, Search, showNotification} from 'platform/components';
import {Box, Space} from 'platform/foundation';

import {useEffect, useState} from 'react';

import {isNil, isNotNil} from 'ramda';

import {
  useCreateRoleMutation,
  useDeleteRoleMutation,
  useGetRolesQuery,
} from '@dms/api/accessControl';
import {useGrantAllPermissionMutation} from '@dms/api/accessControlList';
import {useGetCurrentUserInfoQuery, usePatchUserMutation} from '@dms/api/user';
import {handleApiError} from '@dms/shared';

import {DEBUG_ROLE_REFIX} from '../utils/const';
import {getFixedTranslation} from '../utils/getFixedTranslation';
import {AclDebugList} from './AclDebugList/AclDebugList';

export function AclDebugCard() {
  const [searchString, setSearchString] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const {data: currentUser} = useGetCurrentUserInfoQuery();
  const [createDebugRole, {isLoading: isLoadingCreatingRole}] = useCreateRoleMutation();
  const [deleteDebugRole, {isLoading: isLoadingDeletingRole}] = useDeleteRoleMutation();
  const [grantAllPermissions, {isLoading: isLoadingGrantAllPermission}] =
    useGrantAllPermissionMutation();
  const [patchUser, {isLoading: isLoadingPatchingUser}] = usePatchUserMutation();

  const {data: roles, refetch: refetchRoles, isLoading: isLoadingRoles} = useGetRolesQuery({});

  const isLoading =
    isLoadingCreatingRole ||
    isLoadingDeletingRole ||
    isLoadingGrantAllPermission ||
    isLoadingPatchingUser ||
    isLoadingRoles;

  const debugRole = roles?.find((role) => role.title === `${DEBUG_ROLE_REFIX}-${currentUser?.id}`);
  const isAclDebugOn = isNotNil(debugRole);
  const managerRole = roles?.find((role) => role.system);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setIsOpen(isAclDebugOn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAclDebugOn]);

  const onAclDebugChange = async (isDebugOn: boolean) => {
    if (isNil(managerRole) || isNil(currentUser)) {
      return showNotification.error();
    }

    setIsOpen(isDebugOn);

    try {
      if (isDebugOn) {
        const createdRole = await createDebugRole({
          createRoleRequestBody: {title: `${DEBUG_ROLE_REFIX}-${currentUser.id}`},
        }).unwrap();

        await grantAllPermissions({roleId: createdRole.id});
        await patchUser({userId: currentUser.id, updateUserRequestBody: {roles: [createdRole.id]}});
      } else {
        if (isNil(debugRole)) {
          return showNotification.error();
        }

        await patchUser({userId: currentUser.id, updateUserRequestBody: {roles: [managerRole.id]}});
        await deleteDebugRole({roleId: debugRole.id});
      }
    } catch (e: any) {
      handleApiError(e.response);
    } finally {
      refetchRoles();
    }
  };

  return (
    <Card
      control={{
        type: 'switch',
        onChange: onAclDebugChange,
        value: isOpen,
        isDisabled: isLoading,
      }}
      isExpanded={isOpen}
      title={getFixedTranslation('page.settings.labels.aclDebug')}
      flags={[
        isOpen
          ? {label: getFixedTranslation('general.labels.on'), colorScheme: 'orange', isSubtle: true}
          : {
              label: getFixedTranslation('general.labels.off'),
              colorScheme: 'neutral',
              isSubtle: true,
            },
      ]}
      variant="inlineGrey"
    >
      <Box width={60}>
        <Search
          value={searchString}
          onChange={setSearchString}
          data-testid="layout-header-devSettings-AclDebugCard-search"
        />
      </Box>
      <Space vertical={4} />

      {/*
        NOTE:
        this is repeated logic from @dms/settings, nonetheless, we want
        the devSettings logic to be completely separated and not affected by any
        DMS changes. (featureFlags, settings, styles, etc.)
      */}
      <DataStatus isLoading={isLoading} spacing={10}>
        <AclDebugList
          roleId={debugRole?.id ?? ''}
          searchString={searchString}
          data-testid="layout-header-devSettings-AclDebugCard"
        />
      </DataStatus>
    </Card>
  );
}
