import {FormSubmitHandler} from 'platform/components';

import {useParams} from 'react-router-dom';

import {isNil} from 'ramda';

import {
  useGetTireSpeedRatioQuery,
  usePatchTireSpeedRatioMutation,
  usePostTireSpeedRatioMutation,
} from '@dms/api/metadaTires';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {useNavigate} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {TireDictionaryForm} from '../../components/TireDictionaryForm/TireDictionaryForm';
import {TireDictionaryFormType} from '../../types/TireDictionaryFormType';

export function TireInventorySpeedRatioDetail() {
  const {id} = useParams();
  const navigate = useNavigate();
  const {data, isLoading, isError} = useGetTireSpeedRatioQuery(
    {tireSpeedRatioId: id ?? ''},
    {skip: isNil(id)}
  );
  const [postTireSpeedRatio] = usePostTireSpeedRatioMutation();
  const [patchTireSpeedRatio] = usePatchTireSpeedRatioMutation();

  const handleSubmit: FormSubmitHandler<TireDictionaryFormType> = async (formData) => {
    const body = {
      name: formData.name,
      isDefault: false,
      isActive: data?.isActive ?? true,
    };

    if (isNil(id)) {
      return await postTireSpeedRatio({
        body,
      })
        .unwrap()
        .then(() => navigate(settingsRoutes.tireSpeedRatio))
        .catch(handleApiError);
    }

    await patchTireSpeedRatio({
      tireSpeedRatioId: id,
      body,
    })
      .unwrap()
      .then(() => navigate(settingsRoutes.tireSpeedRatio))
      .catch(handleApiError);
  };

  return (
    <SettingsTemplate
      header={{
        title: data?.name ?? i18n.t('entity.tiresInventory.labels.newTireSpeedRatio'),
        breadcrumbs: [
          {
            label: i18n.t('module.tiresInventory.title'),
            isCurrentPage: true,
          },
          {
            label: i18n.t('entity.tiresInventory.labels.tireSpeedRatio'),
            href: settingsRoutes.tireSpeedRatio,
          },
        ],
      }}
      isLoading={isLoading}
      isError={isError}
      isCreating={isNil(id)}
      data-testid={testIds.settings.tireSpeedRatioDetail('template')}
    >
      <TireDictionaryForm
        onSubmit={handleSubmit}
        defaultValues={{name: data?.name}}
        onDiscard={() => navigate(settingsRoutes.tireSpeedRatio)}
        data-testid={testIds.settings.tireSpeedRatioDetail('form')}
      />
    </SettingsTemplate>
  );
}
