import {omneticApi} from '@dms/api/core';
import {
  GetConsentTypesApiResponse,
  CreateConsentTypeApiResponse,
  CreateConsentTypeApiArg,
  EditConsentTypeApiResponse,
  EditConsentTypeApiArg,
  GetConsentTypeApiResponse,
  GetConsentTypeApiArg,
  DeleteConsentTypeApiArg,
  DeleteConsentTypeApiResponse,
  GetConsentListApiResponse,
  DeleteConsentApiResponse,
  DeleteConsentApiArg,
  BatchCreateConsentApiResponse,
  BatchCreateConsentApiArg,
  AgreeConsentApiResponse,
  AgreeConsentApiArg,
  RejectConsentApiResponse,
  RejectConsentApiArg,
  WithdrawConsentApiResponse,
  WithdrawConsentApiArg,
  GetConsentListApiArg,
} from '@dms/api/shared';

export const gdprApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getConsentTypes: build.query<GetConsentTypesApiResponse, null | void>({
      query: () => ({
        url: `/dms/v1/gdpr/consent-types`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({id}) => ({type: 'GdprConsentType' as const, id})),
              {type: 'GdprConsentType', id: 'LIST'},
            ]
          : [{type: 'GdprConsentType', id: 'LIST'}],
    }),

    getConsentType: build.query<GetConsentTypeApiResponse, GetConsentTypeApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/gdpr/consent-type/${queryArg.consentTypeId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'GdprConsentType', id: queryArg.consentTypeId},
      ],
    }),

    createConsentType: build.mutation<CreateConsentTypeApiResponse, CreateConsentTypeApiArg>({
      query: (queryArg) => ({
        url: '/dms/v1/gdpr/consent-type',
        method: 'POST',
        body: queryArg.createConsentTypeRequestBody,
      }),
      invalidatesTags: [{type: 'GdprConsentType', id: 'LIST'}],
    }),

    editConsentType: build.mutation<EditConsentTypeApiResponse, EditConsentTypeApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/gdpr/consent-type/${queryArg.consentTypeId}`,
        method: 'PUT',
        body: queryArg.editConsentTypeRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'GdprConsentType', id: queryArg.consentTypeId},
      ],
    }),

    deleteConsentType: build.mutation<DeleteConsentTypeApiResponse, DeleteConsentTypeApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/gdpr/consent-type/${queryArg.consentTypeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: 'GdprConsentType', id: 'LIST'}],
    }),

    getConsentList: build.query<GetConsentListApiResponse, GetConsentListApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/gdpr/consents`,
        params: {customerId: queryArg.customerId},
      }),
      providesTags: (result, error, queryArg) => [{type: 'GdprConsent', id: queryArg.customerId}],
    }),

    batchCreateConsent: build.mutation<BatchCreateConsentApiResponse, BatchCreateConsentApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/gdpr/consents`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'GdprConsent', id: queryArg.customerId},
        {type: 'documentsCount', id: queryArg.customerId},
      ],
    }),

    deleteConsent: build.mutation<DeleteConsentApiResponse, DeleteConsentApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/gdpr/consent/${queryArg.consentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'GdprConsent', id: queryArg.customerId},
        {type: 'documentsCount', id: queryArg.customerId},
      ],
    }),

    agreeConsent: build.mutation<AgreeConsentApiResponse, AgreeConsentApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/gdpr/consent/${queryArg.consentId}/agree`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'GdprConsent', id: queryArg.customerId},
      ],
    }),

    rejectConsent: build.mutation<RejectConsentApiResponse, RejectConsentApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/gdpr/consent/${queryArg.consentId}/reject`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'GdprConsent', id: queryArg.customerId},
      ],
    }),

    withdrawConsent: build.mutation<WithdrawConsentApiResponse, WithdrawConsentApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/gdpr/consent/${queryArg.consentId}/withdraw`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'GdprConsent', id: queryArg.customerId},
      ],
    }),
  }),
});

export const selectConsentTypes = gdprApi.endpoints.getConsentTypes.select();

export const {
  useCreateConsentTypeMutation,
  useGetConsentTypesQuery,
  useGetConsentTypeQuery,
  useEditConsentTypeMutation,
  useDeleteConsentTypeMutation,
  useBatchCreateConsentMutation,
  useDeleteConsentMutation,
  useGetConsentListQuery,
  useAgreeConsentMutation,
  useRejectConsentMutation,
  useWithdrawConsentMutation,
} = gdprApi;
