import {Make} from '@dms/api/shared';

export const isModelLastMakeSelected = (
  modelKey: string,
  selectedModels: string[],
  make?: Make | null
) => {
  const makeModels = make?.models;
  return (
    selectedModels
      .filter((model) => makeModels?.includes(model))
      .filter((model) => model !== modelKey).length === 0
  );
};
