import {openConfirmDialog, showNotification} from 'platform/components';
import {VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {RefObject} from 'react';

import {useDeleteTaskMutation} from '@dms/api/taskManagement';
import {openEditTaskFormDialog} from '@dms/features/tasks';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {RequiredTestIdProps} from 'shared';

import {ActionCallback, DataGrid, DataGridRef} from 'features/datagrid';

import {TasksExternalFilter} from './components/TasksExternalFilter';

const EXTERNAL_FILTERS_TASKS_DATAGRID = 'external-filter-tasks-datagrid';

interface TasksDataGridProps extends RequiredTestIdProps {
  dataGridRef: RefObject<DataGridRef | null>;
}

export function TasksDataGrid(props: TasksDataGridProps) {
  const [deleteTask] = useDeleteTaskMutation();

  const dataGridActionsHandler: ActionCallback = ({actionKey, rowId, refreshData}) => {
    match(actionKey)
      .with('detail', () => {
        if (Array.isArray(rowId)) {
          // This should never happen, but just in case
          throw new Error('Cannot view multiple tasks at once');
        }
        openEditTaskFormDialog({
          id: rowId,
          onEdit: refreshData,
          onDelete: refreshData,
          'data-testid': testIds.taskManager.taskOverview('editTaskDialog'),
        });
      })
      .with('edit', () => {
        if (Array.isArray(rowId)) {
          // This should never happen, but just in case
          throw new Error('Cannot delete multiple tasks at once');
        }
        openEditTaskFormDialog({
          id: rowId,
          onEdit: refreshData,
          onDelete: refreshData,
          'data-testid': testIds.taskManager.taskOverview('editTaskDialog'),
        });
      })
      .with('delete', () => {
        openConfirmDialog({
          onConfirm: () => {
            if (Array.isArray(rowId)) {
              // This should never happen, but just in case
              throw new Error('Cannot delete multiple tasks at once');
            }
            deleteTask({id: rowId})
              .unwrap()
              .then(() => {
                refreshData();
                showNotification.success(i18n.t('general.notifications.successfullyDeleted'));
              })
              .catch(handleApiError);
          },
        });
      });
  };

  return (
    <VStack spacing={5} height="100%">
      <div id={EXTERNAL_FILTERS_TASKS_DATAGRID}></div>
      <DataGrid
        ref={props.dataGridRef}
        data-testid={props['data-testid']}
        actionCallback={dataGridActionsHandler}
        externalFilterId={EXTERNAL_FILTERS_TASKS_DATAGRID}
        filterComponent={(dgProps) => (
          <TasksExternalFilter dgProps={dgProps} data-testid={props['data-testid']} />
        )}
        gridCode="task"
        emptyState={{
          headline: i18n.t('general.labels.noData'),
        }}
      />
    </VStack>
  );
}
