import {Tabs} from 'platform/components';
import {Show} from 'platform/foundation';

import i18n from '@dms/i18n';
import {Checklists, Main} from '@dms/shared';

interface VehicleChecklistsProps {
  saleVehicleId: string;
  serviceVehicleId: string;
}

export function VehicleChecklists(props: VehicleChecklistsProps) {
  const {saleVehicleId, serviceVehicleId} = props;

  const isSaleVehicle = !!saleVehicleId;
  const isServiceVehicle = !!serviceVehicleId;

  return (
    <Main>
      <Show when={isSaleVehicle && isServiceVehicle}>
        <Tabs
          variant="condensed"
          tabs={[
            {
              id: 'sale-vehicle',
              title: i18n.t('page.settings.generalChecklist.saleVehicle'),
              content: <Checklists recordId={saleVehicleId} resourceId="SALE_VEHICLE" />,
            },
            {
              id: 'service-vehicle',
              title: i18n.t('page.settings.generalChecklist.serviceVehicle'),
              content: <Checklists recordId={serviceVehicleId} resourceId="SERVICE_VEHICLE" />,
            },
          ]}
        />
      </Show>
      <Show when={isSaleVehicle && !isServiceVehicle}>
        <Checklists recordId={saleVehicleId} resourceId="SALE_VEHICLE" />
      </Show>
      <Show when={!isSaleVehicle && isServiceVehicle}>
        <Checklists recordId={serviceVehicleId} resourceId="SERVICE_VEHICLE" />
      </Show>
    </Main>
  );
}
