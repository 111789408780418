import {Card, DataStatus, Separator} from 'platform/components';
import {GridItem, Box, Grid, Heading, Space} from 'platform/foundation';

import {useGetVehicleStatisticsQuery} from '@dms/api/vehicle';
import i18n from '@dms/i18n';
import {vehiclesRoutes, testIds} from '@dms/routes';

import {Nullish} from 'shared';

import {StatisticColumn} from './StatisticColumn';

interface VehicleStatisticsCardProps {
  branchId: string | Nullish;
}

export function VehicleStatisticsCard(props: VehicleStatisticsCardProps) {
  const {data, isLoading, isError} = useGetVehicleStatisticsQuery(
    {
      branchId: props.branchId,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  return (
    <Card
      variant="inlineWhite"
      data-testid={testIds.dashboard.home('card-vehicles')}
      isFullHeight
      title={i18n.t('entity.vehicle.labels.pageTitle')}
    >
      <Box minHeight={76}>
        <DataStatus isLoading={isLoading} isError={isError}>
          <Grid columns={4}>
            <GridItem>
              <StatisticColumn
                label={i18n.t('general.labels.onStock')}
                count={data?.countOnStock}
                href={vehiclesRoutes.home}
                data-testid={testIds.dashboard.home('vehicles')}
              />
            </GridItem>
            <GridItem>
              <StatisticColumn
                label={i18n.t('page.homepage.labels.inBrokerage')}
                count={data?.countBrokerage}
              />
            </GridItem>
            <GridItem>
              <StatisticColumn
                label={i18n.t('entity.vehicle.labels.stateToSell')}
                count={data?.countToSell}
              />
            </GridItem>
            <GridItem>
              <StatisticColumn
                label={i18n.t('page.homepage.labels.inAdvertising')}
                count={data?.countInAdvertisement}
              />
            </GridItem>
          </Grid>

          <Space vertical={2} />
          <Separator />

          <Heading size={4} alternative>
            {i18n.t('page.homepage.labels.lastWeek')}
          </Heading>
          <Space vertical={4} />
          <Grid columns={4}>
            <GridItem>
              <StatisticColumn
                label={i18n.t('general.labels.bought')}
                count={data?.countBoughtInWeek}
              />
            </GridItem>
            <GridItem>
              <StatisticColumn
                label={i18n.t('entity.vehicle.labels.sold')}
                count={data?.countSoldInWeek}
              />
            </GridItem>
          </Grid>
        </DataStatus>
      </Box>
    </Card>
  );
}
