import {AppLanguage} from 'platform/locale';

import {getWorkspaceFromUri} from '@dms/api/core';
import {useGetAvailableLanguagesListQuery} from '@dms/api/public';
import {browserStorageKey} from '@dms/config';
import i18n from '@dms/i18n';

const {workspace} = getWorkspaceFromUri();

const CERTIFICATE_ALLOWED_LANGUAGES = [
  'en',
  'de',
  'fr',
  'it',
  'es',
  'pl',
  'cs',
  'sk',
] as const satisfies AppLanguage[];
const CERTIFICATE_FALLBACK_LANGUAGE = 'en' as const satisfies AppLanguage;

function isAppLanguage(value: string): value is AppLanguage {
  return CERTIFICATE_ALLOWED_LANGUAGES.includes(value);
}

export function useWorkspaceLanguage() {
  const {data: availableLanguages} = useGetAvailableLanguagesListQuery(
    {
      workspace: workspace!, // `workspace` is checked in second argument of `useGetAvailableLanguagesListQuery`
    },
    {skip: !workspace}
  );

  const defaultLanguage =
    i18n?.resolvedLanguage ||
    (localStorage[browserStorageKey.LAST_KNOWN_LANGUAGE] as string) ||
    availableLanguages?.default;

  const language =
    defaultLanguage && isAppLanguage(defaultLanguage)
      ? defaultLanguage
      : CERTIFICATE_FALLBACK_LANGUAGE;

  return language;
}
