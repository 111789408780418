// to keep layout in sync with figma
import {v4 as uuid} from 'uuid';

import {VatTypeEnum} from '@dms/api/shared';
import i18n from '@dms/i18n';
import {EMPTY_PLACEHOLDER} from '@dms/shared';

import {getRandomId} from 'shared';

import {AccountingDocumentFormValues} from './types';

// https://www.figma.com/file/ZBcJgTtkEANQKhkESE7TA1/Accounting-2.0?node-id=2434%3A175541&t=E73FcotGaNEbtBnH-4
export const INVOICE_FORM_MAX_WIDTH = 292;
export const STANDARD_INVOICE_DUE = 14;
export const ALLOWED_FOREIGN_CURRENCIES_RATIO_AMOUNTS = [1, 100, 1000];
export const PERCENTAGE_SIGN = '%';

export const LIST_ITEM_FIELD_NAME = 'invoiceItems';
export const REQUIRED_INDICATOR = `*`;

export const TAX_DOCUMENT_CALCULATION_FIXED_CACHE_KEY = getRandomId();
export const ACCOUNTING_DOCUMENT_CALCULATION_FIXED_CACHE_KEY = getRandomId();

export const invoicePaymentOptions = [
  {value: 'CASH', label: i18n.t('entity.invoice.paymentMethod.cash')},
  {value: 'CARD', label: i18n.t('entity.invoice.paymentMethod.card')},
  {value: 'BANK_TRANSFER', label: i18n.t('entity.invoice.paymentMethod.bankTransfer')},
  {value: 'OFFSET', label: i18n.t('entity.invoice.paymentMethod.offset')},
  {value: 'FREE_OF_CHARGE', label: i18n.t('entity.invoice.paymentMethod.freeOfCharge')},
] as const;

export const PAYMENT_TYPE_LABELS = {
  cash: i18n.t('entity.invoice.paymentMethod.cash'),
  payment_card: i18n.t('entity.invoice.paymentMethod.card'),
  bank_transfer: i18n.t('entity.invoice.paymentMethod.bankTransfer'),
  offset: i18n.t('entity.invoice.paymentMethod.offset'),
  free_of_charge: EMPTY_PLACEHOLDER,
} as const;

// shortcut from BE standing for "Ceska narodni banka";
export const CNB = 'cnb';
export const DEFAULT_EXCHANGE_RATE_AMOUNT = '1';
export const DEFAULT_EXCHANGE_RATE_TYPE = 'recalculate';

export const discountItemUnits = [
  {
    value: i18n.t('general.metric.pcs'),
    label: i18n.t('general.metric.pcs'),
  },
  {
    value: '%',
    label: '%',
  },
];

export const invoiceItemUnits = [
  {
    value: i18n.t('general.metric.pcs'),
    label: i18n.t('general.metric.pcs'),
  },
  {
    value: i18n.t('general.metric.m'),
    label: i18n.t('general.metric.m'),
  },
  {
    value: i18n.t('general.metric.l'),
    label: i18n.t('general.metric.l'),
  },
  {
    value: i18n.t('general.metric.kg'),
    label: i18n.t('general.metric.kg'),
  },
];

export const cashReceiptItemUnits = [
  {
    value: 'pcs',
    label: i18n.t('general.metric.pcs'),
  },
  {
    value: 'm',
    label: i18n.t('general.metric.m'),
  },
  {
    value: 'l',
    label: i18n.t('general.metric.l'),
  },
  {
    value: 'kg',
    label: i18n.t('general.metric.kg'),
  },
];

type EmptyItemType = AccountingDocumentFormValues['invoiceItems'][number];
export const getEmptyItem = (overRide?: Partial<EmptyItemType>): EmptyItemType => ({
  quantity: '',
  unit: invoiceItemUnits[0].value,
  vatType: VatTypeEnum.S,
  pricePerUnit: null,
  priceWithoutVat: null,
  priceWithVat: null,
  relatedItemId: null,
  type: 'standard',
  isUnitPriceWithVat: false,
  description: '',
  itemId: uuid(),
  ...overRide,
});
