import {DefinitionValueType} from '@dms/api/customFields';
import i18n from '@dms/i18n';

export const VALUE_TYPE_OPTIONS: {value: DefinitionValueType; label: string}[] = [
  {value: 'integer', label: i18n.t('page.generalSettings.labels.integer')},
  {value: 'string', label: i18n.t('page.generalSettings.labels.string')},
  {value: 'money', label: i18n.t('page.generalSettings.labels.money')},
  {value: 'date', label: i18n.t('page.generalSettings.labels.date')},
  {value: 'datetime', label: i18n.t('page.generalSettings.labels.datetime')},
  {value: 'boolean', label: i18n.t('page.generalSettings.labels.boolean')},
  {value: 'decimal', label: i18n.t('page.generalSettings.labels.decimal')},
] as const;
