/* eslint-disable eag/match-named-export */
import {Integer} from 'platform/foundation';
import styled from 'styled-components';

type ColorElementType = {
  $needBorder?: boolean;
  $color: string;
  $size?: Integer;
};

type ColorBorderElementType = {
  $isSelected?: boolean;
};

// eslint-disable-next-line eag/no-css-property
export const ColorBorder = styled.div<ColorBorderElementType>`
  border: ${(props) =>
    props.$isSelected
      ? `2px solid ${props.theme.colors.palettes.blue[60][100]}`
      : '2px solid transparent'};
  border-radius: 50%;
  margin-left: 1px;
  overflow: hidden;
  font-size: 0;

  &:hover {
    border-color: ${({theme}) => theme.colors.palettes.blue[20][100]};
  }
`;

export const StyledColorCircle = styled.div<ColorElementType>`
  border: ${(props) =>
    props.$needBorder
      ? `1px solid ${props.theme.colors.palettes.neutral[70][100]}`
      : 'transparent'};
  ${(props) => props.$needBorder && 'border-radius: 50%'};
  cursor: pointer;
  height: ${({theme, $size}) => theme.getSize($size ?? 3)};
  width: ${({theme, $size}) => theme.getSize($size ?? 3)};
  background: ${({$color}) => $color};
`;
