import {clone} from 'ramda';

import {FeaturePickerItem} from '../types/FeaturePickerItem';

/**
 * Filters features with `filter` function and keeps its tree like structure
 * @param filter
 * @param features
 * @param level
 */
export const filterFeatures = (
  filter: (item: FeaturePickerItem, level: number) => boolean,
  features: FeaturePickerItem[],
  level = 0
): FeaturePickerItem[] =>
  clone(features)
    .map((item) => {
      if (filter(item, level)) {
        return item;
      }

      if (item.children?.length) {
        item.children = filterFeatures(filter, item.children, level + 1);
      }

      return item.children?.length ? item : undefined;
    })
    .filter(Boolean) as FeaturePickerItem[];
