import {metadaApi} from '@dms/api/core';

import {
  GetDirectSaleLabourItemCalculatePriceRequest,
  GetDirectSaleLabourItemCalculatePriceRequestSchema,
  GetDirectSaleLabourItemCalculatePriceResponse,
  GetDirectSaleLabourItemCalculatePriceResponseSchema,
  GetDirectSaleLabourItemRequest,
  GetDirectSaleLabourItemRequestSchema,
  GetDirectSaleLabourItemResponse,
  GetDirectSaleLabourItemResponseSchema,
  PatchDirectSaleLabourItemRequest,
  PatchDirectSaleLabourItemRequestSchema,
  PatchDirectSaleLabourItemResponse,
  PatchDirectSaleLabourItemResponseSchema,
} from './types';

export const metadaWarehouseDirectSaleItemLabourApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getDirectSaleLabourItem: build.query<
      GetDirectSaleLabourItemResponse,
      GetDirectSaleLabourItemRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/item-labour/${queryArg.itemId}`,
        method: 'GET',
        queryArg,
      }),
      providesTags: ['directSaleLabourItems'],
      extraOptions: {
        requestSchema: GetDirectSaleLabourItemRequestSchema,
        responseSchema: GetDirectSaleLabourItemResponseSchema,
      },
    }),
    patchDirectSaleLabourItem: build.mutation<
      PatchDirectSaleLabourItemResponse,
      PatchDirectSaleLabourItemRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/item-labour/${queryArg.itemId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: [
        'directSales',
        'directSaleLabourBasket',
        'afsCheckout',
        'afsPaymentsList',
        'afsPaymentById',
      ],
      extraOptions: {
        requestSchema: PatchDirectSaleLabourItemRequestSchema,
        responseSchema: PatchDirectSaleLabourItemResponseSchema,
      },
    }),
    getDirectSaleLabourItemCalculatePrice: build.query<
      GetDirectSaleLabourItemCalculatePriceResponse,
      GetDirectSaleLabourItemCalculatePriceRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/item-labour/${queryArg.itemId}/calculate-price`,
        method: 'POST',
        body: queryArg.body,
      }),
      extraOptions: {
        requestSchema: GetDirectSaleLabourItemCalculatePriceRequestSchema,
        responseSchema: GetDirectSaleLabourItemCalculatePriceResponseSchema,
      },
    }),
  }),
});

export const {
  useGetDirectSaleLabourItemQuery,
  useLazyGetDirectSaleLabourItemQuery,
  usePatchDirectSaleLabourItemMutation,
  useGetDirectSaleLabourItemCalculatePriceQuery,
  useLazyGetDirectSaleLabourItemCalculatePriceQuery,
} = metadaWarehouseDirectSaleItemLabourApi;
