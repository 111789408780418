import DOMPurify from 'dompurify';
import styled from 'styled-components';

interface MessageDialogProps {
  message: string;
}

export function MessageDialog(props: MessageDialogProps) {
  return (
    // eslint-disable-next-line no-restricted-syntax -- Sanitized by backend, returned only by BE
    <MessageText dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.message ?? '')}} />
  );
}

// eslint-disable-next-line eag/no-css-property
const MessageText = styled.div`
  font-size: ${({theme}) => theme.fontSizes.text.xSmall};
`;
