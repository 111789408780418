import {Module} from '@dms/api/shared';
import {moduleKeys} from '@dms/config';
import {featureFlags} from '@dms/feature-flags';
import {tiresInventoryRoutes} from '@dms/routes';
import {permissions} from '@dms/shared';

import {CreateTireOrder} from './pages/CreateTireOrder/CreateTireOrder';
import {StorageLocationCapacity} from './pages/StorageLocationCapacity/StorageLocationCapacity';
import {TireOrderDetail} from './pages/TireOrderDetail/TireOrderDetail';
import {TiresInventoryOverview} from './pages/TiresInventoryOverview/TiresInventoryOverview';

export const TiresInventoryModule: Module = {
  id: moduleKeys.tiresInventory,
  layoutProps: {
    title: 'module.tiresInventory.title',
    icon: 'modules/pneu_hotel',
    iconV2: 'sidebar/tires_inventory',
  },
  routerProps: {
    path: tiresInventoryRoutes.overview,
    element: <TiresInventoryOverview />,
  },
  requiredPermission: permissions.pneuhotelRead,
  requiredFeatureFlag: featureFlags.PNEUHOTEL_BASIC_VIEW,
  content: [
    {
      id: 'tiresInventory-tireOrders',
      routerProps: {
        path: tiresInventoryRoutes.tireOrders,
        element: <TiresInventoryOverview />,
      },
      layoutProps: {
        title: 'page.tiresInventory.labels.tireOrders',
        icon: 'sidebar/tires_inventory_tire_orders',
      },
    },
    {
      id: 'tiresInventory-tireSets',
      routerProps: {
        path: tiresInventoryRoutes.tireSets,
        element: <TiresInventoryOverview />,
      },
      layoutProps: {
        title: 'page.tiresInventory.labels.tireSets',
        icon: 'sidebar/tireSets',
      },
    },
    {
      id: 'tiresInventory-listOfTires',
      routerProps: {
        path: tiresInventoryRoutes.tireList,
        element: <TiresInventoryOverview />,
      },
      layoutProps: {
        title: 'page.tiresInventory.labels.tireList',
        icon: 'sidebar/tires_inventory_list_of_tires',
      },
    },
    {
      id: 'tiresInventory-listOfTires',
      routerProps: {
        path: tiresInventoryRoutes.storageLocationCapacity,
        element: <StorageLocationCapacity />,
      },
      layoutProps: {
        title: 'page.tiresInventory.labels.storageLocationCapacity',
        icon: 'sidebar/storageLocationCapacity',
      },
    },
    {
      id: 'tiresInventory-createTireOrder',
      routerProps: {
        path: tiresInventoryRoutes.createTireOrder,
        element: <CreateTireOrder />,
      },
      layoutProps: {
        title: 'page.tiresInventory.labels.createTireOrder',
        isPartOfCreateMenuItem: true,
      },
    },
    {
      id: 'tiresInventory-tireOrder-detail',
      routerProps: {
        path: tiresInventoryRoutes.tireOrderDetail,
        element: <TireOrderDetail />,
      },
    },
  ],
};
