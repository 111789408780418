import {match} from 'ts-pattern';

import {head, path, isNil} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {testIds, warehouseRoutes, workshopRoutes} from '@dms/routes';
import {catchUnhandledDataGridActions, logError, queryParams} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {FullscreenDataGridWrapper} from '../../../components/FullscreenDataGridWrapper';
import {WAREHOUSE_GRID_CODES} from '../../../constants/warehouseGridCodes';

export function IssueNoteCorrections() {
  const navigate = useNavigate();

  const actionCallback: ActionCallback = ({actionKey, rowId}) => {
    const id = isArray(rowId) ? head(rowId) : rowId;

    match(actionKey)
      .with('redirectDetail', 'details', () =>
        navigate(composePath(warehouseRoutes.issueNoteCorrectionDetailOverview, {params: {id}}))
      )
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <FullscreenDataGridWrapper hideCardWrapper>
      <DataGrid
        gridCode={WAREHOUSE_GRID_CODES.warehouseIssueNoteCorrections}
        actionCallback={actionCallback}
        _useAsLastResort_linkClickOverride={({rowData, defaultRedirectRoute}) => {
          const resourceId = path(['originDocNumber', 'value', 'resourceId'], rowData);

          if (isNil(resourceId)) {
            return logError('resourceId is not defined');
          }

          return match(resourceId)
            .with('WORKSHOP_SERVICE_ORDER_DETAIL', () => {
              const serviceCaseId = path(['serviceCaseId', 'value'], rowData);
              const serviceOrderId = path(['serviceOrderId', 'value'], rowData);

              if (isNil(serviceCaseId)) {
                return logError('serviceCaseId is not defined');
              }

              if (isNil(serviceOrderId)) {
                return logError('serviceOrderId is not defined');
              }

              return navigate(
                composePath(workshopRoutes.serviceCaseDetail, {
                  params: {
                    id: serviceCaseId as string,
                  },
                  queryParams: {
                    [queryParams.SERVICE_CASE_ORDER_ID]: serviceOrderId as string,
                  },
                })
              );
            })
            .otherwise(() => navigate(defaultRedirectRoute));
        }}
        data-testid={testIds.warehouse.issueNoteCorrections('list')}
      />
    </FullscreenDataGridWrapper>
  );
}
