import {CustomFilterProps} from 'ag-grid-react';
// eslint-disable-next-line eag/no-external-date-time-format-functions -- Special format because of Datagrid
import {formatRFC3339} from 'date-fns';
import {
  Chips,
  DateTimePicker,
  NumberInput,
  Separator,
  useTranslationContext,
} from 'platform/components';
import {HStack, VStack} from 'platform/foundation';

import {useCallback, useMemo} from 'react';

import {intersection, pathOr} from 'ramda';
import {isString} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {quickFiltersConst} from '../../constants/quickFilters';
import {useFilterOnChipsChange} from '../../hooks/useFilterOnChipsChange';
import {QuickFilters} from '../../types/Api';
import {parseDateFilter} from '../../utils/parseDateFilter';

export type DateTimeRangeFilterProps = {
  min: string | null;
  max: string | null;
  relativeDates: Array<{value: string; label: string}>;
  relativeInputs: Array<{value: string; label: string}>;
  isDisabled: boolean;
} & CustomFilterProps &
  QuickFilters &
  TestIdProps;

export function DateTimeRangeFilter(props: DateTimeRangeFilterProps) {
  const t = useTranslationContext();
  const minDate = useMemo(() => parseDateFilter(props.min), [props.min]);
  const maxDate = useMemo(() => parseDateFilter(props.max), [props.max]);

  const {onChipsChange} = useFilterOnChipsChange({
    setFilterValue: props.onModelChange,
    defaultValue: null,
  });

  const [fromPickerValue, toPickerValue, inputValue, chipsValue] = useMemo(
    () => [
      pathOr(null, ['from'], props.model),
      pathOr(null, ['to'], props.model),
      pathOr(0, ['relativeInputValue'], props.model),
      isString(props.model) ? [props.model] : [],
    ],
    [props.model]
  );

  const onDateFromChange = useCallback(
    (value: Date | null) => {
      props.onModelChange({
        from: value ? formatRFC3339(value) : null,
        to: pathOr(null, ['to'], props.model),
      });
    },
    [props.model]
  );

  const onDateToChange = useCallback(
    (value: Date | null) => {
      props.onModelChange({
        to: value ? formatRFC3339(value) : null,
        from: pathOr(null, ['from'], props.model),
      });
    },
    [props.model]
  );

  const onInputChange = useCallback((relativeInputValue: number, relativeInputKey: string) => {
    const newModel =
      isNaN(relativeInputValue) || relativeInputValue === 0
        ? null
        : {relativeInputValue, relativeInputKey};

    props.onModelChange(newModel);
  }, []);

  const isQuickFilterValue = intersection([props.model], quickFiltersConst) as string[];

  return (
    <VStack>
      {props.quickFilters && props.quickFilters.length > 0 && (
        <>
          <HStack>
            <Chips
              isDisabled={props.isDisabled}
              value={isQuickFilterValue}
              options={props.quickFilters}
              onChange={onChipsChange}
              isMultiple={false}
              data-testid={suffixTestId('date-time-range-filter-quickFilters', props)}
              isDeselectable
            />
          </HStack>
          <Separator />
        </>
      )}
      {props.relativeDates && props.relativeDates.length > 0 && (
        <>
          <Chips
            isDisabled={props.isDisabled}
            value={chipsValue}
            options={props.relativeDates}
            onChange={onChipsChange}
            isMultiple={false}
            data-testid={suffixTestId('date-time-range-filter-relativeDate', props)}
            isDeselectable
          />
          <Separator />
        </>
      )}
      <VStack spacing={2}>
        <DateTimePicker
          label={t('page.datagrid.filter.dateTimeLabelFrom')}
          minDate={minDate}
          maxDate={maxDate}
          isDisabled={props.isDisabled}
          value={fromPickerValue}
          onChange={onDateFromChange}
          data-testid={suffixTestId('date-time-range-filter-from', props)}
          isRelativeDatesHidden
        />
        <DateTimePicker
          label={t('page.datagrid.filter.dateTimeLabelTo')}
          minDate={minDate}
          maxDate={maxDate}
          isDisabled={props.isDisabled}
          value={toPickerValue}
          onChange={onDateToChange}
          data-testid={suffixTestId('date-time-range-filter-to', props)}
          isRelativeDatesHidden
        />
      </VStack>
      {props.relativeInputs.map((relativeInput) => (
        <NumberInput
          key={relativeInput.value}
          label={relativeInput.label}
          isDisabled={props.isDisabled}
          onChange={(value) => onInputChange(value ?? 0, relativeInput.value)}
          value={inputValue}
          data-testid={suffixTestId(
            `date-time-range-filter-relativeInput-${relativeInput.label}`,
            props
          )}
        />
      ))}
    </VStack>
  );
}
