import {DataStatus} from 'platform/components';

import {Suspense, lazy} from 'react';

const Reports = lazy(() => import('./components/Reports'));

export function WorkshopReport() {
  return (
    <Suspense fallback={<DataStatus minHeight={100} isLoading />}>
      <Reports />
    </Suspense>
  );
}
