import validatePhone from 'phone';
import {
  Button,
  ButtonGroup,
  Card,
  DialogFooter,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Box, Grid, Heading, HStack, Show, Space, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {UseFormReturn} from 'react-hook-form';

import {always, any, defaultTo, head, ifElse, isNil, isNotNil, pipe, values} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  AddressRequestBodyV2,
  CommonAddressRequestBody,
  CreateCustomerRequestBody,
  CustomerResponseBodyV2,
  EmailData,
  PhoneNumberData,
  useCreateCustomerAddressV2Mutation,
  useCreateCustomerMutation,
  useLazyGetCustomerV2Query,
  usePatchCustomerMutation,
} from '@dms/api/customer';
import {AddrDetailApiResponse} from '@dms/api/metadaAddress';
import {PhoneNumber} from '@dms/api/shared';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';

import {Nullish, suffixTestId, TestIdProps, yupString} from 'shared';

import {emptyAddressPol, emptyPersonData} from '../../../constants/emptyValues';
import {useCustomerGroups} from '../../../hooks/useCustomerGroups';
import {useTenant} from '../../../hooks/useTenant';
import {handleApiError} from '../../../utils/handleApiError';
import {isAddressCompleted} from '../../../utils/isAddressCompleted';
import {isPolishTenant} from '../../../utils/isPolishTenant';
import {AddressSearch} from '../../AddressSearch/AddressSearch';
import {CountrySelect} from '../../CountrySelect/CountrySelect';
import {DecodeBusinessIdButton} from '../../DecodeBusinessIdButton/DecodeBusinessIdButton';
import {EmailList} from '../../EmailList/EmailList';
import {PhoneNumberList} from '../../PhoneNumberList/PhoneNumberList';
import {CreateCustomerProps} from '../types/CreateCustomerProps';
import {PhysicalPersonFormType} from '../types/PhysicalPersonFormType';
import {handleDecodeBusinessIdForm} from '../utils/handleDecodeBusinessIdForm';

interface NewPhysicalPersonFormProps extends TestIdProps {
  customer: CreateCustomerProps;
  onCreate: (customer: CustomerResponseBodyV2) => void;
  onClose: () => void;
}

export function PhysicalPersonForm(props: NewPhysicalPersonFormProps) {
  const {customerGroupOptions} = useCustomerGroups();

  const [getCustomer, {isLoading: isGetCustomerLoading}] = useLazyGetCustomerV2Query();
  const [createCustomer, {isLoading: isCreateCustomerLoading}] = useCreateCustomerMutation();
  const [createCustomerAddress, {isLoading: isCreateCustomerAddressLoading}] =
    useCreateCustomerAddressV2Mutation();
  const [patchCustomer, {isLoading: isPatchCustomerLoading}] = usePatchCustomerMutation();

  const onSubmit: FormSubmitHandler<PhysicalPersonFormType> = async (data) => {
    if (isNilOrEmpty(data.foundingPerson?.phoneNumbers?.[0]?.phoneNumber?.number)) {
      data.foundingPerson!.phoneNumbers = [];
    }

    if (isNilOrEmpty(data.foundingPerson?.emails?.[0]?.email)) {
      data.foundingPerson!.emails = [];
    }

    const createCustomerRequestBody: CreateCustomerRequestBody = {
      customerDiscriminator: data.customerData.customerDiscriminator,
      email: head(data.foundingPerson?.emails ?? [])?.email ?? null,
      firstName: data.foundingPerson?.personData.firstName ?? null,
      lastName: data.foundingPerson?.personData.lastName ?? null,
      phoneNumber: head(data.foundingPerson?.phoneNumbers ?? [])?.phoneNumber ?? null,
      registrationNumber: null,
      tradeName: null,
      customerGroups: data.customerGroups ?? [],
    };

    await createCustomer({createCustomerRequestBody})
      .unwrap()
      .then(async ({id: customerId}) => {
        if (isAddressCompleted(data.addressData) && isNotNil(data.addressData?.address)) {
          await createCustomerAddress({
            customerId,
            addressRequestBody: {
              address: data.addressData?.address ?? emptyAddressPol.address,
              type: null,
              invalid: null,
            },
          })
            .unwrap()
            .then((address) => {
              if (data.foundingPerson) {
                data.foundingPerson.permanentAddressId = address.id;
              }
              if (data.selfEmployed) {
                data.selfEmployedBusinessAddressId = address.id;
              }
            })
            .catch(handleApiError);
        }

        if (!data.selfEmployed) {
          data.selfEmployedBusinessInfoData = null;
        }

        await patchCustomer({
          customerId,
          customerRequestBody: data,
        })
          .unwrap()
          .catch(handleApiError);

        await getCustomer({customerId}).unwrap().then(props.onCreate).catch(handleApiError);
      })
      .catch(handleApiError);
  };

  const {tenantPhoneInfo, tenantCountry} = useTenant();
  const isPolish = isPolishTenant(tenantCountry);

  const setAddressFormSearch =
    (formApi: UseFormReturn<PhysicalPersonFormType>) => (address: AddrDetailApiResponse) => {
      if (isNil(tenantCountry)) {
        showNotification.error();
        return;
      }

      formApi.setValue(
        'addressData.address',
        {
          city: address?.city ?? '',
          street: address?.street ?? '',
          zip: address?.zipCode ?? '',
          state: address?.state ?? '',
          prefix: address?.prefix ?? '',
          descriptiveNumber: address?.descriptiveNumber ?? null,
          orientationNumber: address?.orientationNumber ?? null,
          addressComplement: null,
          district: null,
          country: tenantCountry,
          coordinates: null,
          postal: null,
        },
        {shouldValidate: true}
      );
    };

  const defaultValues: PhysicalPersonFormType = {
    selfEmployed: props.customer.isSelfEmployed,
    selfEmployedBusinessInfoData: {
      registrationNumber: props.customer.registrationNumber ?? null,
      countryOfRegistrationCode: null,
      vatNumber: props.customer.vatNumber ?? null,
      tradeName: null,
      fileNumber: null,
    },
    customerData: {
      customerDiscriminator: props.customer.customerDiscriminator,
      customerSource: null,
    },
    customerGroups: props.customer.customerGroups,
    foundingPerson: {
      personData: {
        ...emptyPersonData,
        firstName: props.customer.firstName ?? null,
        lastName: props.customer.lastName ?? null,
      },
      identityCards: [],
      permanentAddressId: null,
      phoneNumbers: [
        {
          phoneNumber: isNotNilOrEmpty(props.customer.phoneNumber?.number)
            ? props.customer.phoneNumber!
            : {
                prefix: tenantPhoneInfo.prefix,
                number: '',
                countryCode: tenantPhoneInfo.countryCode,
              },
          type: null,
        },
      ],
      emails: [
        {
          email: props.customer.email ?? '',
          type: null,
        },
      ],
    },
    businessInfo: null,
    addressData: {
      address: {
        city: props.customer.address?.city ?? '',
        street: props.customer.address?.street ?? '',
        zip: props.customer.address?.zip ?? '',
        state: props.customer.address?.state ?? '',
        prefix: props.customer.address?.prefix ?? '',
        descriptiveNumber: props.customer.address?.descriptiveNumber ?? '',
        orientationNumber: props.customer.address?.orientationNumber ?? '',
        addressComplement: props.customer.address?.addressComplement ?? '',
        district: props.customer.address?.district ?? '',
        country: props.customer.address?.country ?? '',
        coordinates:
          props.customer.address?.coordinates &&
          isNotNil(props.customer.address.coordinates.longitude) &&
          isNotNil(props.customer.address.coordinates.latitude)
            ? {
                longitude: String(props.customer.address.coordinates.longitude),
                latitude: String(props.customer.address.coordinates.latitude),
              }
            : null,
        postal: props.customer.address?.postal || null,
      },
      type: null,
      invalid: null,
    },
  };

  const isLoading =
    isCreateCustomerLoading ||
    isCreateCustomerAddressLoading ||
    isPatchCustomerLoading ||
    isGetCustomerLoading;

  return (
    <Form<PhysicalPersonFormType> onSubmit={onSubmit} defaultValues={defaultValues} schema={schema}>
      {(control, formApi) => (
        <>
          <VStack spacing={4}>
            <HStack spacing={4}>
              <Box flex={1}>
                <FormField
                  control={control}
                  type="text"
                  name="foundingPerson.personData.titleBefore"
                  maxLength={10}
                  label={i18n.t('entity.person.labels.titleBefore')}
                  data-testid={suffixTestId('titleBefore', props)}
                />
              </Box>
              <Box flex={2}>
                <FormField
                  control={control}
                  type="text"
                  name="foundingPerson.personData.firstName"
                  isRequired
                  maxLength={64}
                  label={i18n.t('entity.person.labels.firstName')}
                  data-testid={suffixTestId('firstName', props)}
                />
              </Box>
              <Box flex={2}>
                <FormField
                  control={control}
                  type="text"
                  name="foundingPerson.personData.lastName"
                  isRequired
                  maxLength={64}
                  label={i18n.t('entity.person.labels.lastName')}
                  data-testid={suffixTestId('lastName', props)}
                />
              </Box>
              <Box flex={1}>
                <FormField
                  control={control}
                  type="text"
                  name="foundingPerson.personData.titleAfter"
                  maxLength={10}
                  label={i18n.t('entity.person.labels.titleAfter')}
                  data-testid={suffixTestId('titleAfter', props)}
                />
              </Box>
            </HStack>
            <Card variant="inlineGrey">
              <VStack spacing={4}>
                <Box width="fit-content">
                  <FormField
                    control={control}
                    type="switch"
                    name="selfEmployed"
                    label={i18n.t('entity.customer.labels.isSelfEmployed')}
                    data-testid={suffixTestId('registrationNumber', props)}
                  />
                </Box>
                <Show when={formApi.watch('selfEmployed')}>
                  <Box data-testid={suffixTestId('selfEmployedWrapper', props)}>
                    <VStack spacing={4}>
                      <HStack spacing={4}>
                        <Box flex={1}>
                          <FormField
                            control={control}
                            type="text"
                            name="selfEmployedBusinessInfoData.registrationNumber"
                            label={i18n.t('entity.businessInfo.labels.registrationNumber')}
                            data-testid={suffixTestId('registrationNumber', props)}
                          />
                        </Box>
                        <Box flex={1}>
                          <FormField
                            control={control}
                            type="text"
                            name="selfEmployedBusinessInfoData.vatNumber"
                            label={i18n.t('entity.businessInfo.labels.vatNumber')}
                            data-testid={suffixTestId('vatNumber', props)}
                          />
                        </Box>
                        <HStack height={13} align="flex-end">
                          <DecodeBusinessIdButton
                            businessId={formApi.watch(
                              'selfEmployedBusinessInfoData.registrationNumber'
                            )}
                            vatId={formApi.watch('selfEmployedBusinessInfoData.vatNumber')}
                            countryCode={formApi.watch(
                              'selfEmployedBusinessInfoData.countryOfRegistrationCode'
                            )}
                            onSuccess={handleDecodeBusinessIdForm(formApi, isPolish)}
                            data-testid={suffixTestId('decode', props)}
                          />
                        </HStack>
                      </HStack>
                      <Box flex={1}>
                        <FormField
                          control={control}
                          type="text"
                          name="selfEmployedBusinessInfoData.tradeName"
                          label={i18n.t('entity.businessInfo.labels.tradeName')}
                          isRequired
                          data-testid={suffixTestId('tradeName', props)}
                        />
                      </Box>
                      <HStack spacing={4}>
                        <Box flex={1}>
                          <FormField
                            control={control}
                            type="text"
                            name="selfEmployedBusinessInfoData.fileNumber"
                            label={i18n.t('entity.businessInfo.labels.fileNumber')}
                            data-testid={suffixTestId('fileNumber', props)}
                          />
                        </Box>
                        <Box flex={1}>
                          <CountrySelect<PhysicalPersonFormType>
                            control={control}
                            name="selfEmployedBusinessInfoData.countryOfRegistrationCode"
                            label={i18n.t('entity.businessInfo.labels.countryOfRegistrationCode')}
                            isRequired
                            menuInPortal
                            data-testid={suffixTestId('countryOfRegistrationCode', props)}
                          />
                        </Box>
                      </HStack>
                    </VStack>
                  </Box>
                </Show>
              </VStack>
            </Card>
            <Heading size={4}>{i18n.t('entity.person.labels.contactInformation')}</Heading>
            <PhoneNumberList<PhysicalPersonFormType>
              control={control}
              formApi={formApi}
              name="foundingPerson.phoneNumbers"
              data-testid={suffixTestId('phoneNumberList', props)}
            />
            <EmailList<PhysicalPersonFormType>
              control={control}
              formApi={formApi}
              name="foundingPerson.emails"
              data-testid={suffixTestId('emailList', props)}
            />
            <Separator spacing={0} />
            <Heading size={4}>{i18n.t('entity.person.permanentAddressId')}</Heading>
            <AddressSearch onSelect={setAddressFormSearch(formApi)} />
            <Show when={!isPolish}>
              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    isRequired
                    control={control}
                    name="addressData.address.street"
                    type="text"
                    label={i18n.t('entity.address.labels.street')}
                    data-testid={suffixTestId('street', props)}
                  />
                </Box>
                <Box flex={1}>
                  <HStack spacing={4}>
                    <Box flex={1}>
                      <FormField
                        control={control}
                        name="addressData.address.descriptiveNumber"
                        type="text"
                        label={i18n.t('entity.address.labels.descriptiveNumber')}
                        data-testid={suffixTestId('descriptiveNumber', props)}
                      />
                    </Box>
                    <Box flex={1}>
                      <FormField
                        control={control}
                        name="addressData.address.orientationNumber"
                        type="text"
                        label={i18n.t('entity.address.labels.orientationNumber')}
                        data-testid={suffixTestId('orientationNumber', props)}
                      />
                    </Box>
                  </HStack>
                </Box>
              </HStack>
              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    isRequired
                    control={control}
                    name="addressData.address.city"
                    type="text"
                    label={i18n.t('entity.address.labels.city')}
                    data-testid={suffixTestId('city', props)}
                  />
                </Box>
                <Box flex={1}>
                  <HStack spacing={4}>
                    <Box flex={1}>
                      <FormField
                        control={control}
                        name="addressData.address.district"
                        type="text"
                        label={i18n.t('entity.address.labels.district')}
                        data-testid={suffixTestId('postalCode', props)}
                      />
                    </Box>
                    <Box flex={1}>
                      <FormField
                        control={control}
                        name="addressData.address.zip"
                        type="text"
                        label={i18n.t('entity.address.labels.zipCode')}
                        data-testid={suffixTestId('zipCode', props)}
                        isRequired
                      />
                    </Box>
                  </HStack>
                </Box>
              </HStack>
              <HStack spacing={4}>
                <Box flex={1}>
                  <CountrySelect<PhysicalPersonFormType>
                    isRequired
                    control={control}
                    name="addressData.address.country"
                    label={i18n.t('entity.address.labels.country')}
                    menuInPortal
                    data-testid={suffixTestId('country', props)}
                  />
                </Box>
                <Box flex={1}>
                  <FormField
                    control={control}
                    name="addressData.address.addressComplement"
                    type="text"
                    label={i18n.t('entity.address.labels.addressComplement')}
                    data-testid={suffixTestId('addressComplement', props)}
                  />
                </Box>
              </HStack>
              <FormField
                control={control}
                name="addressData.type"
                type="text"
                label={i18n.t('entity.address.labels.type')}
                data-testid={suffixTestId('type', props)}
              />
              <Space fillAvailable />
            </Show>
            <Show when={isPolish}>
              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    isRequired
                    control={control}
                    name="addressData.address.street"
                    type="text"
                    label={i18n.t('entity.address.labels.street')}
                    data-testid={suffixTestId('street', props)}
                  />
                </Box>
                <Box flex={1}>
                  <HStack spacing={4}>
                    <Box flex={1}>
                      <FormField
                        control={control}
                        name="addressData.address.descriptiveNumber"
                        type="text"
                        label={i18n.t('entity.address.labels.descriptiveNumber')}
                        data-testid={suffixTestId('descriptiveNumber', props)}
                      />
                    </Box>
                    <Box flex={1}>
                      <FormField
                        control={control}
                        name="addressData.address.orientationNumber"
                        type="text"
                        label={i18n.t('entity.address.labels.orientationNumber')}
                        data-testid={suffixTestId('orientationNumber', props)}
                      />
                    </Box>
                  </HStack>
                </Box>
              </HStack>
              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    isRequired
                    control={control}
                    name="addressData.address.city"
                    type="text"
                    label={i18n.t('entity.address.labels.city')}
                    data-testid={suffixTestId('city', props)}
                  />
                </Box>
                <Box flex={1}>
                  <HStack spacing={4}>
                    <Box flex={1}>
                      <FormField
                        control={control}
                        name="addressData.address.district"
                        type="text"
                        label={i18n.t('entity.address.labels.district')}
                        data-testid={suffixTestId('postalCode', props)}
                      />
                    </Box>
                    <Box flex={1}>
                      <FormField
                        isRequired
                        control={control}
                        name="addressData.address.zip"
                        type="text"
                        label={i18n.t('entity.address.labels.zipCode')}
                        data-testid={suffixTestId('zipCode', props)}
                      />
                    </Box>
                  </HStack>
                </Box>
              </HStack>
              <HStack spacing={4}>
                <Box flex={1}>
                  <CountrySelect<PhysicalPersonFormType>
                    isRequired
                    control={control}
                    name="addressData.address.country"
                    label={i18n.t('entity.address.labels.country')}
                    menuInPortal
                    data-testid={suffixTestId('country', props)}
                  />
                </Box>
                <Box flex={1}>
                  <FormField
                    control={control}
                    name="addressData.address.addressComplement"
                    type="text"
                    label={i18n.t('entity.address.labels.addressComplement')}
                    data-testid={suffixTestId('addressComplement', props)}
                  />
                </Box>
              </HStack>
              <FormField
                control={control}
                name="addressData.type"
                type="text"
                label={i18n.t('entity.address.labels.type')}
                data-testid={suffixTestId('type', props)}
              />
              <Space fillAvailable />
            </Show>
            <Show whenFeatureEnabled={featureFlags.CORE_CUSTOMER_GROUPS}>
              <Separator spacing={0} />
              <Heading size={4}>{i18n.t('entity.person.segmentation')}</Heading>
              <Grid columns={2}>
                <FormField
                  name="customerGroups"
                  label={i18n.t('entity.person.labels.customerGroups')}
                  type="multiChoice"
                  options={customerGroupOptions}
                  menuPlacement="top"
                  menuInPortal
                  isNotClearable
                  control={control}
                  data-testid={suffixTestId('customerGroups', props)}
                />
              </Grid>
            </Show>
          </VStack>
          <DialogFooter>
            <ButtonGroup align="right">
              <Button
                variant="secondary"
                onClick={props.onClose}
                isDisabled={isLoading}
                title={i18n.t('general.actions.discard')}
                data-testid={suffixTestId('discard', props)}
              />
              <FormButton
                variant="primary"
                type="submit"
                control={control}
                isLoading={isLoading}
                title={i18n.t('general.actions.create')}
                data-testid={suffixTestId('create', props)}
              />
            </ButtonGroup>
          </DialogFooter>
        </>
      )}
    </Form>
  );
}

const schema = Yup.object().shape(
  {
    selfEmployed: Yup.boolean(),
    customerGroups: Yup.array(yupString),
    foundingPerson: Yup.object().shape(
      {
        personData: Yup.object().shape({
          titleBefore: yupString.optional(),
          firstName: yupString.required().max(64),
          lastName: yupString.required().max(64),
          titleAfter: yupString.optional(),
        }),
        phoneNumbers: Yup.array().when('emails', {
          is: (emails?: EmailData[]) =>
            emails?.length && emails.length <= 1 && isNilOrEmpty(emails?.[0]?.email),
          then: (schema) =>
            schema
              .of(
                Yup.object().shape({
                  type: yupString.optional(),
                  phoneNumber: Yup.mixed()
                    .test(
                      'isPhone',
                      i18n.t('general.validations.invalidPhoneNumber'),
                      (phone?: PhoneNumber | Nullish) => {
                        if (isNilOrEmpty(phone?.number)) {
                          return true;
                        }
                        return validatePhone(phone?.number || '', {
                          country: phone?.countryCode,
                          validateMobilePrefix: false,
                        }).isValid;
                      }
                    )
                    .test(
                      'required',
                      i18n.t('general.validations.phoneOrEmail'),
                      (phone?: PhoneNumber | Nullish) => isNotNilOrEmpty(phone?.number)
                    ),
                })
              )
              .min(1),
          otherwise: (schema) =>
            schema.of(
              Yup.object().shape({
                type: yupString.optional(),
                phoneNumber: Yup.mixed().test(
                  'isPhone',
                  i18n.t('general.validations.invalidPhoneNumber'),
                  (phone?: PhoneNumber | Nullish) => {
                    if (isNilOrEmpty(phone?.number)) {
                      return true;
                    }
                    return validatePhone(phone?.number || '', {
                      country: phone?.countryCode,
                      validateMobilePrefix: false,
                    }).isValid;
                  }
                ),
              })
            ),
        }),
        emails: Yup.array().when('phoneNumbers', {
          is: (phoneNumbers?: PhoneNumberData[]) =>
            phoneNumbers?.length &&
            phoneNumbers?.length <= 1 &&
            isNilOrEmpty(phoneNumbers?.[0]?.phoneNumber?.number),
          then: (schema) =>
            schema
              .of(
                Yup.object().shape({
                  type: yupString.optional(),
                  email: yupString.required(i18n.t('general.validations.phoneOrEmail')),
                })
              )
              .min(1),
          otherwise: (schema) =>
            schema.of(
              Yup.object().shape({
                type: yupString.optional(),
                email: yupString.optional().default(''),
              })
            ),
        }),
      },
      [['emails', 'phoneNumbers']]
    ),
    selfEmployedBusinessInfoData: Yup.object().when('selfEmployed', {
      is: true,
      then: (schema) =>
        schema.shape(
          {
            countryOfRegistrationCode: yupString.required(),
            registrationNumber: yupString.when('vatNumber', {
              is: (value: string | Nullish) => isNilOrEmpty(value),
              then: yupString.required(i18n.t('general.errors.mixed.vatIdOrBusinessIdRequired')),
            }),
            vatNumber: yupString.when('registrationNumber', {
              is: (value: string | Nullish) => isNilOrEmpty(value),
              then: yupString.required(i18n.t('general.errors.mixed.vatIdOrBusinessIdRequired')),
            }),
            tradeName: yupString.required(),
            fileNumber: yupString.optional().default(null),
          },
          [['registrationNumber', 'vatNumber']]
        ),
      otherwise: (schema) => schema.nullable(),
    }),
    addressData: Yup.object().when('addressData', {
      is: (addressData: AddressRequestBodyV2 | Nullish) =>
        ifElse(
          isNotNilOrEmpty,
          pipe<
            [CommonAddressRequestBody | Nullish],
            CommonAddressRequestBody | object,
            (string | {latitude: string; longitude: string} | boolean | Nullish)[],
            boolean
          >(defaultTo({}), values, any(isNotNilOrEmpty)),
          always(false)
        )(addressData?.address),
      then: (schema) =>
        schema.shape({
          address: Yup.object().shape({
            street: Yup.string().required().nullable(),
            descriptiveNumber: Yup.string().optional().nullable(),
            orientationNumber: Yup.string().optional().nullable(),
            city: Yup.string().required().nullable(),
            district: Yup.string().optional().nullable(),
            zip: Yup.string().required().nullable(),
            country: Yup.string().required().nullable(),
            addressComplement: Yup.string().nullable().optional().nullable(),
            coordinates: Yup.object()
              .shape({
                latitude: yupString,
                longitude: yupString,
              })
              .optional()
              .nullable(),
            postal: Yup.string().optional().nullable(),
          }),
          type: Yup.string().nullable().optional(),
        }),
    }),
  },
  [['addressData', 'addressData']]
);
