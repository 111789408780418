import {Button, ButtonGroup, Form, FormField} from 'platform/components';
import {Box, Heading, Text, VStack} from 'platform/foundation';

import {ImportFileType, useCreateImportMutation} from '@dms/api/import';
import i18n from '@dms/i18n';

import {importFormSchema, ImportFormValue} from '../types/ImportFormValue';
import {CloudImage} from './CloudImage';
import {UploadComponent} from './UploadComponent';

interface ImportFormProps {
  onClose: () => void;
  onSuccess: () => void;
  onError: (error: unknown) => void;
}

export function ImportForm(props: ImportFormProps) {
  const [createImport, createImportQuery] = useCreateImportMutation();

  const handleSubmit = async (data: ImportFormValue) => {
    await createImport({
      body: {
        fileId: data.fileId,
        fileType: data.fileType as ImportFileType,
      },
    })
      .unwrap()
      .then(props.onSuccess)
      .catch(props.onError);
  };

  return (
    <Form<ImportFormValue>
      defaultValues={{
        fileId: '',
        fileType: '',
      }}
      onSubmit={handleSubmit}
      experimentalZodSchema={importFormSchema}
    >
      {(control, formApi) => (
        <VStack spacing={4}>
          <Box padding={6}>
            <VStack align="center" justify="center" spacing={4}>
              <CloudImage />
              <Heading size={2}>{i18n.t('entity.import.labels.createImportHeading')}</Heading>
              <Box maxWidth={165}>
                <Text size="small" align="center" color="secondary">
                  {i18n.t('entity.import.labels.createImportDescription')}
                </Text>
              </Box>
              <Box width="100%">
                <FormField
                  control={control}
                  name="fileType"
                  type="choice"
                  label={i18n.t('entity.import.labels.fileType')}
                  options={[
                    {
                      label: i18n.t('entity.import.labels.carisCost'),
                      value: 'carisSaleVehicleCosts',
                    },
                  ]}
                  isRequired
                  isNotClearable
                />
              </Box>
              <UploadComponent name="fileId" control={control} formApi={formApi} />
            </VStack>
          </Box>
          <ButtonGroup align="right">
            <Button
              type="button"
              variant="secondary"
              title={i18n.t('general.labels.discard')}
              onClick={props.onClose}
              isDisabled={createImportQuery.isLoading}
            />
            <Button
              type="submit"
              title={i18n.t('entity.import.actions.createImportTask')}
              isLoading={createImportQuery.isLoading}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}
