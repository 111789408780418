import validatePhone from 'phone';
import {
  Button,
  DialogFooter,
  Form,
  FormButton,
  FormSubmitHandler,
  PhoneNumber,
} from 'platform/components';
import {Box, HStack, Show} from 'platform/foundation';
import {mixed, object} from 'yup';

import {SubmitErrorHandler} from 'react-hook-form';

import {indexBy, isNil, isNotEmpty, isNotNil, mapObjIndexed, toPairs} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  RemoteFileSignatureBody,
  useGetSignableDocumentListQuery,
  useSignDocumentRemotelyMutation,
} from '@dms/api/digitalSignature';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {Nullish, suffixTestId, yupString} from 'shared';

import {BASE_PATH_SEPARATOR} from '../../constants/BASE_PATH_SEPARATOR';
import {useDocumentSelection} from '../../contexts/DocumentSelectionProvider';
import {useDocumentValidationState} from '../../contexts/DocumentStateProvider';
import {StepContentProps} from '../../types/StepContentProps';
import {getContextSessionName} from '../../utils/getContextSessionName';
import {getSignatoriesDefaultValues} from '../../utils/getSignatoriesDefaultValues';
import {DefaultSignatories} from './components/DefaultSignatories';
import {DocumentSignatories} from './components/DocumentSignatories';
import {FormValues} from './types/FormValues';

export function SignatoriesSelectStep(props: StepContentProps) {
  const {signatureDocumentFileIds} = useDocumentSelection();
  const {
    activeDocumentId,
    setDocumentsValidationState,
    clearAllDocumentsValidationState,
    documentValidationState,
  } = useDocumentValidationState();

  const [signRemotely] = useSignDocumentRemotelyMutation({fixedCacheKey: 'signRemotely'});

  const {data: activeTransactionFiles} = useGetSignableDocumentListQuery({
    files: signatureDocumentFileIds,
  });

  const documentByFileId = indexBy((item) => item?.sourceFileId, activeTransactionFiles ?? []);
  const activeDocument = documentByFileId[activeDocumentId ?? ''];

  const handleSubmit: FormSubmitHandler<FormValues> = async (data) => {
    const files = toPairs(data).reduce((prev, [id], _, array) => {
      const fileId = id.split(BASE_PATH_SEPARATOR)[0];

      if (prev.some((item) => item.fileId === fileId)) {
        return prev;
      }

      const allSignatories = array
        .filter(([key]) => key.startsWith(fileId))
        .map(([signatoryKey, signatoryValue]) => {
          const signatoryId = signatoryKey.split(BASE_PATH_SEPARATOR)[1];

          return {
            ...signatoryValue,
            id: signatoryId,
          };
        });

      return [...prev, {fileId, signatoryPersons: allSignatories}];
    }, [] as RemoteFileSignatureBody[]);

    await signRemotely({
      sessionName: getContextSessionName(props.context, activeTransactionFiles?.length),
      files,
    })
      .unwrap()
      .then(() => {
        props.setSigningStep('Remote_sentToSignatories');
        clearAllDocumentsValidationState();
      })
      .catch((error: Error) => {
        handleApiError(error, {silent: true});
        props.setSigningStep('Remote_error');
      })
      .finally(props.refreshData);
  };

  const handleInvalidSubmit: SubmitErrorHandler<FormValues> = (errors) => {
    const fileIdsWithErrors = Object.keys(errors)
      .map((key) => key.split(BASE_PATH_SEPARATOR)[0])
      .filter((fileId) => fileId !== activeDocumentId);

    setDocumentsValidationState(fileIdsWithErrors);
  };

  const defaultFormValues = getSignatoriesDefaultValues(activeTransactionFiles);

  return (
    <Form<FormValues>
      schema={getSchema(defaultFormValues)}
      onSubmit={handleSubmit}
      onInvalidSubmit={handleInvalidSubmit}
    >
      {(control, formApi) => (
        <>
          <Show when={isNotNil(activeDocument)}>
            <DocumentSignatories
              control={control}
              formApi={formApi}
              activeDocument={activeDocument}
              key={activeDocument?.sourceFileId}
            />
          </Show>

          <Show when={isNil(activeDocument)}>
            <DefaultSignatories
              control={control}
              formApi={formApi}
              documents={activeTransactionFiles ?? []}
              customerId={props.customerId}
            />
          </Show>

          <DialogFooter>
            <Box paddingHorizontal={0}>
              <HStack justify="space-between">
                <Button
                  title={i18n.t('entity.document.actions.goToModeSelect')}
                  variant="dangerOutlined"
                  onClick={() => {
                    clearAllDocumentsValidationState();
                    props.setSigningStep('modeSelect');
                  }}
                  data-testid={suffixTestId('modeSelect', props)}
                />

                <FormButton
                  control={control}
                  type="submit"
                  title={i18n.t('general.actions.continue')}
                  variant="primary"
                  isDisabled={isNotEmpty(documentValidationState)}
                  data-testid={suffixTestId('continue', props)}
                />
              </HStack>
            </Box>
          </DialogFooter>
        </>
      )}
    </Form>
  );
}

const phoneNumberSchema = mixed()
  .test(
    'isPhone',
    i18n.t('general.validations.invalidPhoneNumber'),
    (phone?: PhoneNumber | Nullish) => {
      if (isNilOrEmpty(phone?.number)) {
        return true;
      }
      return validatePhone(phone?.number || '', {
        country: phone?.countryCode,
        validateMobilePrefix: false,
      }).isValid;
    }
  )
  .test(
    'required',
    i18n.t('general.validations.fieldIsRequired'),
    (phone?: PhoneNumber | Nullish) => isNotNilOrEmpty(phone?.number)
  );

const getSchema = (obj: FormValues) =>
  object(
    mapObjIndexed(
      () =>
        object({
          email: yupString.required(),
          name: yupString.required(),
          phoneNumber: phoneNumberSchema,
        }),
      obj
    )
  );
