import {Card, Label} from 'platform/components';
import {Link, VStack} from 'platform/foundation';

import {isNil} from 'ramda';

import i18n from '@dms/i18n';
import {useGetDocumentData, useRedirectToInvoice, InvoiceTypes} from '@dms/shared';

import {Nullish} from 'shared';

interface CancellingInvoiceDetailProps {
  cancelingInvoiceId: string | Nullish;
  cancelingInvoiceType: InvoiceTypes;
}

export function CancellingInvoiceDetail(props: CancellingInvoiceDetailProps) {
  const [redirectToInvoice] = useRedirectToInvoice();
  const {data: cancellingInvoice} = useGetDocumentData(
    props.cancelingInvoiceType,
    props.cancelingInvoiceId
  );

  if (isNil(props.cancelingInvoiceId)) {
    return null;
  }

  const handleRedirectToInvoice = () =>
    redirectToInvoice(props.cancelingInvoiceType, props.cancelingInvoiceId);

  return (
    <Card title={i18n.t('page.accounting.labels.invoiceCancellingTitle')}>
      <VStack>
        <Label>{i18n.t('entity.invoice.labels.invoiceNumberLabel')}</Label>
        <Link title={cancellingInvoice?.number} onClick={handleRedirectToInvoice} />
      </VStack>
    </Card>
  );
}
