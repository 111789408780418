import {z} from 'zod';

import {BaseVoidResponseSchema} from '@dms/api/shared';

export const GetServiceOrderPreviousRequestCountApiResponseSchema = z
  .object({
    totalPreviousRequests: z.number(),
  })
  .nullable();

export type GetServiceOrderPreviousRequestCountApiResponse = z.infer<
  typeof GetServiceOrderPreviousRequestCountApiResponseSchema
>;

export const GetServiceOrderPreviousRequestCountApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
});

export type GetServiceOrderPreviousRequestCountApiArg = z.infer<
  typeof GetServiceOrderPreviousRequestCountApiArgSchema
>;

export const PostServiceOrderPreviousRequestDeleteAllApiResponseSchema = BaseVoidResponseSchema;

export type PostServiceOrderPreviousRequestDeleteAllApiResponse = z.infer<
  typeof PostServiceOrderPreviousRequestDeleteAllApiResponseSchema
>;

export const PostServiceOrderPreviousRequestDeleteAllApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
});

export type PostServiceOrderPreviousRequestDeleteAllApiArg = z.infer<
  typeof PostServiceOrderPreviousRequestDeleteAllApiArgSchema
>;

export const PostServiceOrderPreviousRequestDeleteApiResponseSchema = BaseVoidResponseSchema;

export type PostServiceOrderPreviousRequestDeleteApiResponse = z.infer<
  typeof PostServiceOrderPreviousRequestDeleteApiResponseSchema
>;

export const PostServiceOrderPreviousRequestDeleteApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  body: z
    .object({
      serviceOrderPreviousJobId: z.array(z.string()),
    })
    .nullable(),
});

export type PostServiceOrderPreviousRequestDeleteApiArg = z.infer<
  typeof PostServiceOrderPreviousRequestDeleteApiArgSchema
>;

export const PostServiceOrderAssignPreviousRequestApiResponseSchema = z
  .object({
    id: z.array(z.string()),
  })
  .nullable();

export type PostServiceOrderAssignPreviousRequestApiResponse = z.infer<
  typeof PostServiceOrderAssignPreviousRequestApiResponseSchema
>;

export const PostServiceOrderAssignPreviousRequestApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  body: z.array(
    z
      .object({
        previousServiceOrderId: z.string(),
        previousServiceCaseJobId: z.string(),
      })
      .nullable()
  ),
});

export type PostServiceOrderAssignPreviousRequestApiArg = z.infer<
  typeof PostServiceOrderAssignPreviousRequestApiArgSchema
>;
