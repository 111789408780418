import {Card, DataStatus, Label} from 'platform/components';
import {Center, Grid, Heading, VStack} from 'platform/foundation';
import {useDateTimeFormatter, useFormatCurrency} from 'platform/locale';

import {useCallback} from 'react';

import {defaultTo, isNotEmpty, isNotNil, mergeAll} from 'ramda';

import {GetArticleResponse} from '@dms/api/metadaWarehouseArticle';
import {useGetArticleStockItemOverviewQuery} from '@dms/api/metadaWarehouseArticleStock';
import {TenantResponseBody} from '@dms/api/shared';
import i18n from '@dms/i18n';
import {Section} from '@dms/shared';

import {parseDate} from 'shared';

import {DataGrid, QueryFilterObject} from 'features/datagrid';

import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';
import {getQuantityWithHandlingUnit} from '../../../../utils/getQuantityWithHandlingUnit';

interface InventoriesProps {
  article: GetArticleResponse;
  tenant: TenantResponseBody;
  handlingUnitLabel: string;
}

export function Inventories(props: InventoriesProps) {
  const params = useWarehouseParams();
  const currencyFormatter = useFormatCurrency();
  const dateFormatter = useDateTimeFormatter();

  const {
    data: articleStockItemOverview,
    isLoading: isArticleStockItemOverviewLoading,
    isError: isArticleStockItemError,
  } = useGetArticleStockItemOverviewQuery({
    warehouseId: params.warehouseId,
    articleId: params.articleId,
  });

  const isLoading = isArticleStockItemOverviewLoading;
  const isError = isArticleStockItemError;

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {articleId: params.articleId}]),
    [params]
  );

  if (!articleStockItemOverview) {
    return (
      <Center width="100%">
        <DataStatus isLoading={isLoading} isError={isError} minHeight="100vh" />
      </Center>
    );
  }

  const totalValueCurrency = currencyFormatter(
    defaultTo(0, Number(articleStockItemOverview.totalValue)),
    props.tenant.currency,
    2
  );
  const lastReceptionDate =
    isNotNil(articleStockItemOverview.lastReception) &&
    isNotEmpty(articleStockItemOverview.lastReception)
      ? dateFormatter('dateTimeShort', parseDate(articleStockItemOverview.lastReception))
      : '-';

  return (
    <Section isFullHeight>
      <Card title={i18n.t('entity.warehouse.labels.inventories')} isFullHeight>
        <VStack spacing={4} height="100%">
          <Grid columns={4}>
            <VStack>
              <Label>{i18n.t('entity.warehouse.labels.totalInventory')}</Label>
              <Heading size={1}>
                {getQuantityWithHandlingUnit(
                  articleStockItemOverview.totalInventory,
                  props.handlingUnitLabel
                )}
              </Heading>
            </VStack>
            <VStack>
              <Label>{i18n.t('entity.warehouse.labels.totalValue')}</Label>
              <Heading size={1}>{totalValueCurrency}</Heading>
            </VStack>
            <VStack>
              <Label>{i18n.t('entity.warehouse.labels.lastReception')}</Label>
              <Heading size={1}>{lastReceptionDate}</Heading>
            </VStack>
          </Grid>
          <div css={{height: '100%'}}>
            <DataGrid
              gridCode="warehouse-article-inventory"
              data-testid="warehouse-article-inventory"
              queryModifier={queryModifier}
            />
          </div>
        </VStack>
      </Card>
    </Section>
  );
}
