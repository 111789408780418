import {Image, Box, Center} from 'platform/foundation';
import styled from 'styled-components';

import i18n from '@dms/i18n';

export function CarVerticalLogo() {
  return (
    <StyledCarVerticalBackground>
      <Box height="100%" paddingHorizontal={4} minWidth={39}>
        <Center height="100%">
          <Image
            src="../../../../assets/images/car_vertical_logo.svg"
            alt={i18n.t('page.integrations.labels.carVerticalTitle')}
          />
        </Center>
      </Box>
    </StyledCarVerticalBackground>
  );
}

// Specific color – Car Vertical logo background color
// eslint-disable-next-line eag/no-css-property
const StyledCarVerticalBackground = styled.div`
  background-color: #0074f1;
`;
