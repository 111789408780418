import {closeDialog, openDialog, showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {isArray, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  useLazyGetTireSetQuery,
  usePostTiresArchiveMutation,
  usePostTireSetDeliveryNoteMutation,
  usePostTireSetLabelMutation,
  usePostTireSetsSetUnreadyMutation,
  usePostTireSetsSetReadyMutation,
  usePostTiresIssueMutation,
  usePostTiresReceiptMutation,
  usePostTiresTransferToOrderMutation,
} from '@dms/api/metadaTires';
import i18n from '@dms/i18n';
import {
  BulkRequestTireSet,
  catchUnhandledDataGridActions,
  EditTireSet,
  handleApiError,
  printPDF,
  RequestTireSet,
} from '@dms/shared';

import {ActionCallback, DataGrid, refreshDatagrid} from 'features/datagrid';

import {extractWheelIds} from './utils/extractWheelIds';

type TireSetDGValuesProps = {
  tireSeason: {value: string};
  tireOrderId: {value: string};
  setNumber: {value: string};
  id: string;
};

export function TireSetsOverview() {
  const [getTireSetQuery] = useLazyGetTireSetQuery();

  const [postTireSetLabel] = usePostTireSetLabelMutation();
  const [postTireSetDeliveryNote] = usePostTireSetDeliveryNoteMutation();
  const [postTiresArchive] = usePostTiresArchiveMutation();
  const [postTiresTransferToOrder] = usePostTiresTransferToOrderMutation();
  const [postTiresIssue] = usePostTiresIssueMutation();
  const [postTiresReceipt] = usePostTiresReceiptMutation();
  const [postTireSetsSetReady] = usePostTireSetsSetReadyMutation();
  const [postTireSetsSetUnready] = usePostTireSetsSetUnreadyMutation();

  const actionCallback: ActionCallback = ({actionKey, rowData, refreshData}) => {
    const castedRowData = (isArray(rowData) ? rowData[0] : rowData) as TireSetDGValuesProps;
    const castedRowBulkData = (isArray(rowData) ? rowData : [rowData]) as TireSetDGValuesProps[];

    const transferActionCallback: ActionCallback = ({rowId}) => {
      const targetOrderId = Array.isArray(rowId) ? rowId[0] : rowId;

      if (!targetOrderId) {
        return;
      }

      postTiresTransferToOrder({
        targetOrderId,
        body: {
          tireSets: castedRowBulkData.map((row) => ({
            tireOrderId: row.tireOrderId.value,
            tireSetId: row.id,
          })),
        },
      })
        .unwrap()
        .then(() => {
          closeDialog('transferToOrder');
          refreshData();
        })
        .catch(handleApiError);
    };

    match(actionKey)
      .with('redirectDetail', () => {
        const titleData = [castedRowData?.tireSeason?.value].filter(isNotNilOrEmpty);

        openDialog(
          <EditTireSet
            tireOrderId={castedRowData.tireOrderId.value}
            setId={castedRowData.id}
            onClose={() => closeDialog('editTireSet')}
            onEdit={refreshData}
          />,
          {
            id: 'editTireSet',
            title: `${castedRowData?.setNumber?.value} - ${titleData.join(', ')}`,
            size: 'large',
            withAdditionalFooter: true,
          }
        );
      })
      .with('printLabel', () => {
        getTireSetQuery({orderId: castedRowData.tireOrderId.value, setId: castedRowData.id})
          .unwrap()
          .then((data) => {
            const wheelIds = extractWheelIds(data);

            if (!wheelIds?.length) {
              showNotification.error();
              return;
            }

            postTireSetLabel({
              orderId: castedRowData.tireOrderId.value,
              setId: castedRowData.id,
              body: {
                wheels: wheelIds,
              },
            })
              .unwrap()
              .then((tireLabelData) => {
                const url = tireLabelData?.printout?.pdfUrl;

                if (!url) {
                  showNotification.error();
                  return;
                }

                printPDF(url);
                showNotification.success();
                refreshData();
              })
              .catch(handleApiError);
          })
          .catch(handleApiError);
      })
      .with('printDeliveryNote', () => {
        getTireSetQuery({orderId: castedRowData.tireOrderId.value, setId: castedRowData.id})
          .unwrap()
          .then((data) => {
            const wheelIds = extractWheelIds(data);

            if (!wheelIds?.length) {
              showNotification.error();
              return;
            }

            postTireSetDeliveryNote({
              orderId: castedRowData.tireOrderId.value,
              setId: castedRowData.id,
              body: {
                wheels: wheelIds,
              },
            })
              .unwrap()
              .then((tireLabelData) => {
                const url = tireLabelData?.printout?.pdfUrl;

                if (!url) {
                  showNotification.error();
                  return;
                }

                printPDF(url);
                showNotification.success();
                refreshData();
              })
              .catch(handleApiError);
          })
          .catch(handleApiError);
      })
      .with('transferToOrder', () => {
        openDialog(
          <DataGrid
            gridCode="tire-set-transfer"
            autoHeight
            actionCallback={transferActionCallback}
          />,
          {
            id: 'transferToOrder',
            title: i18n.t('page.tiresInventory.labels.transferToOrder'),
            size: 'large',
          }
        );
      })
      .with('request', 'editRequest', () => {
        openDialog(
          castedRowBulkData.length > 1 ? (
            <BulkRequestTireSet
              tireSets={castedRowBulkData.map((row) => ({
                setId: row.id,
                orderId: row.tireOrderId.value,
              }))}
              onSave={refreshData}
              onClose={() => closeDialog('requestTireSet')}
            />
          ) : (
            <RequestTireSet
              orderId={castedRowData.tireOrderId.value}
              setId={castedRowData.id}
              onSave={refreshData}
              onClose={() => closeDialog('requestTireSet')}
            />
          ),
          {
            id: 'requestTireSet',
            title: i18n.t('entity.tireSet.actions.tireSetRequest'),
            size: 'small',
          }
        );
      })
      .with('issue', () => {
        getTireSetQuery({orderId: castedRowData.tireOrderId.value, setId: castedRowData.id})
          .unwrap()
          .then((data) => {
            const wheelIds = extractWheelIds(data);

            if (!wheelIds?.length) {
              showNotification.error();
              return;
            }

            postTiresIssue({
              orderId: castedRowData.tireOrderId.value,
              setId: castedRowData.id,
              body: {wheels: wheelIds},
            })
              .unwrap()
              .then((response) => {
                showNotification.success();
                refreshData();

                if (isNotNilOrEmpty(response?.printout?.pdfUrl)) {
                  printPDF(response?.printout?.pdfUrl ?? '');
                }
              })
              .catch(handleApiError);
          })
          .catch(handleApiError);
      })
      .with('archive', () => {
        getTireSetQuery({orderId: castedRowData.tireOrderId.value, setId: castedRowData.id})
          .unwrap()
          .then((data) => {
            const wheelIds = extractWheelIds(data);

            if (!wheelIds?.length) {
              showNotification.error();
              return;
            }

            postTiresArchive({
              orderId: castedRowData.tireOrderId.value,
              setId: castedRowData.id,
              body: {
                wheels: wheelIds,
              },
            })
              .unwrap()
              .then(() => {
                showNotification.success();
                refreshDatagrid('tire-sets');
              })
              .catch(handleApiError);
          })
          .catch(handleApiError);
      })
      .with('receipt', () => {
        getTireSetQuery({orderId: castedRowData.tireOrderId.value, setId: castedRowData.id})
          .unwrap()
          .then((data) => {
            const wheelIds = extractWheelIds(data);

            if (!wheelIds?.length) {
              showNotification.error();
              return;
            }

            postTiresReceipt({
              orderId: castedRowData.tireOrderId.value,
              setId: castedRowData.id,
              body: {wheels: wheelIds},
            })
              .unwrap()
              .then(() => {
                showNotification.success();
                refreshData();
              })
              .catch(handleApiError);
          })
          .catch(handleApiError);
      })
      .with('requestReady', () => {
        postTireSetsSetReady({
          body: {
            tireSets: castedRowBulkData.map((row) => ({
              tireOrderId: row.tireOrderId.value,
              tireSetId: row.id,
            })),
          },
        })
          .unwrap()
          .then(() => {
            showNotification.success();
            refreshData();
          })
          .catch(handleApiError);
      })
      .with('requestUnready', () => {
        postTireSetsSetUnready({
          body: {
            tireSets: castedRowBulkData.map((row) => ({
              tireOrderId: row.tireOrderId.value,
              tireSetId: row.id,
            })),
          },
        })
          .unwrap()
          .then(() => {
            showNotification.success();
            refreshData();
          })
          .catch(handleApiError);
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return <DataGrid gridCode="tire-sets" actionCallback={actionCallback} />;
}
