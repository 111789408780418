import {useLocale} from 'platform/locale';

import {useCallback} from 'react';

import {useGetCountriesQuery} from '@dms/api/cached';
import {useGetAllApiEnumsQuery} from '@dms/api/vehicleCatalogue';

import {ApiEnum} from '../types/ApiEnum';
import {buildFeatureEnumTree} from '../utils/buildFeatureEnumTree';
import {getDefaultLabel} from '../utils/getDefaultLabel';
import {getEnumByProperty, PropertyName} from '../utils/getEnumByProperty';

export function useApiEnums(options: {flat?: boolean} = {flat: false}) {
  const {language} = useLocale();
  const {data: enums, isLoading} = useGetAllApiEnumsQuery({
    lang: [language],
    vehicleType: ['VEHICLETYPE_PASSENGER_CAR'],
  });
  const {data: countries} = useGetCountriesQuery();

  const getEnum = useCallback(
    (propertyName: PropertyName): ApiEnum => {
      if (propertyName === 'body_color') {
        return getEnumByProperty(propertyName, enums).map((item) => ({
          key: item.const_key,
          value: item.const_key,
          label: getDefaultLabel(item),
          colorCode: item.properties?.[0]?.value,
        }));
      }

      if (propertyName === 'country') {
        return (
          countries?.map((country) => ({
            key: country.code,
            value: country.code,
            label: country.name,
          })) ?? []
        );
      }

      if (propertyName === 'feature' && !options.flat) {
        return buildFeatureEnumTree(enums);
      }

      return getEnumByProperty(propertyName, enums).map((item) => ({
        key: item.const_key,
        value: item.const_key,
        label: getDefaultLabel(item),
      }));
    },
    [countries, enums, options.flat]
  );

  return {enums, getEnum, isLoading};
}
