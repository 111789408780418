import {findIndex, includes} from 'ramda';

import {TRANSLATED_LANGUAGES} from '@dms/api/shared';

import {DEFAULT_LANG} from '../types/CommonAppTypes';

/**
 * Check that given locale is supported by the application
 */
export const validateLang = (lang?: string): TRANSLATED_LANGUAGES => {
  if (!lang) {
    return DEFAULT_LANG;
  }
  const TRANSLATED_LANGUAGES_ARRAY = Object.values(TRANSLATED_LANGUAGES);
  const langIndex = findIndex(includes(lang))(TRANSLATED_LANGUAGES_ARRAY);

  if (langIndex === -1) {
    return DEFAULT_LANG;
  }
  return TRANSLATED_LANGUAGES_ARRAY[langIndex];
};
