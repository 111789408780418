import {
  Action,
  Card,
  DataStatus,
  EmptyStatus,
  openDialog,
  showNotification,
} from 'platform/components';
import {Hide, Show, VStack} from 'platform/foundation';

import {defaultTo, isNil} from 'ramda';
import {isFalse, isNilOrEmpty, isNotNil} from 'ramda-adjunct';

import {InspectionType, useCreateInspectionMutation} from '@dms/api/carAudit';
import {
  GetServiceCheckInApiResponse,
  PostServiceCheckInApiArg,
  useGetServiceCheckInQuery,
  usePatchServiceCheckInMutation,
  usePostServiceCheckInMutation,
  useGetServiceCheckInDefaultDriverApiQuery,
} from '@dms/api/metadaWorkshopCheckin';
import {useGetMetadaServiceCaseQuery} from '@dms/api/metadaWorkshopServiceCase';
import {useGetOccupiedSpaceQuery} from '@dms/api/storage';
import {useGetVehicleQuery, usePartialUpdateVehicleMutation} from '@dms/api/vehicle';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {buildArray, getApiDateString, parseDate, useBoolean} from 'shared';

import {CheckInHandOverForm} from '../../../../../components/CheckInHandOverForm/CheckInHandOverForm';
import {CheckInHandOverParameter} from '../../../../../components/CheckInHandOverParameter/CheckInHandOverParameter';
import {EmailSmsNotificationBoundary} from '../../../../../components/EmailSmsNotifications/EmailSmsNotificationBoundary';
import {NotificationCategory} from '../../../../../components/EmailSmsNotifications/types/EmailSmsNotificationForm';
import {useWorkshopUrl} from '../../../../../hooks/useWorkshopUrl';
import {MileageValidationDialog} from '../../../components/MileageValidationDialog';
import {Inspection} from './Inspection';

interface CheckInProps {
  vehicleId: string;
}

export function CheckIn(props: CheckInProps) {
  const {serviceCaseId: id} = useWorkshopUrl();
  const {
    data: checkIn,
    isLoading: isCheckInLoading,
    isError: isCheckInError,
  } = useGetServiceCheckInQuery({serviceCaseId: id});
  const {
    data: serviceCase,
    isLoading: isServiceCaseLoading,
    isError: iseServiceCaseError,
  } = useGetMetadaServiceCaseQuery({serviceCaseId: id});
  const {
    data: vehicle,
    isLoading: isVehicleLoading,
    isError: isVehicleError,
  } = useGetVehicleQuery(
    {vehicleId: defaultTo('', serviceCase?.vehicleId)},
    {skip: !serviceCase?.vehicleId}
  );
  const {data: parkingPlace} = useGetOccupiedSpaceQuery(
    {recordId: serviceCase?.vehicleId ?? '', spaceType: 'VEHICLE'},
    {skip: isNilOrEmpty(serviceCase?.vehicleId)}
  );
  const [postServiceCheckIn] = usePostServiceCheckInMutation();
  const [patchServiceCheckIn, patchServiceCheckInQuery] = usePatchServiceCheckInMutation();
  const [partialUpdateVehicle] = usePartialUpdateVehicleMutation();
  const [createInspection, {isLoading: isCreatingInspection, isError: isInspectionError}] =
    useCreateInspectionMutation();

  const [isEditing, setEditing, cancelEditing] = useBoolean();

  const {data: checkInDefaultDriver, isLoading: isCheckInDefaultDriverLoading} =
    useGetServiceCheckInDefaultDriverApiQuery({serviceCaseId: id});

  const inspectionId = checkIn?.inspectionId?.[0];

  const handleSaveCheckIn = async (data: PostServiceCheckInApiArg['body']) => {
    if (isNotNil(data) && inspectionId) {
      data.inspectionId = [inspectionId];
    }

    if (data?.technicalInspection && vehicle) {
      partialUpdateVehicle({
        vehicleId: vehicle.id,
        patchVehicleRequestBody: {
          state: {
            technicalInspectionValidUntil: getApiDateString(parseDate(data?.technicalInspection)),
          },
        },
      })
        .unwrap()
        .catch(handleApiError);
    }
    if (checkIn) {
      await patchServiceCheckIn({body: data, serviceCaseId: id})
        .unwrap()
        .then((response) => {
          if (isFalse(response?.isMileageValid)) {
            openDialog(
              <MileageValidationDialog
                highestMileage={response?.highestMileage}
                data-testid={testIds.workshop.serviceCaseDetail('mileageValidation')}
              />,
              {
                size: 'small',
              }
            );
          }

          showNotification.success();
          cancelEditing();
        })
        .catch(handleApiError);
      return;
    }

    await postServiceCheckIn({body: data, serviceCaseId: id})
      .unwrap()
      .then((response) => {
        if (isFalse(response?.isMileageValid)) {
          openDialog(
            <MileageValidationDialog
              highestMileage={response?.highestMileage}
              data-testid={testIds.workshop.serviceCaseDetail('mileageValidation')}
            />,
            {
              size: 'small',
            }
          );
        }

        showNotification.success();
        cancelEditing();
      })
      .catch(handleApiError);
  };

  const vehicleDefaultValues: GetServiceCheckInApiResponse = {
    technicalInspection: vehicle?.state?.technicalInspectionValidUntil,
  };

  const emptyDefaultValues: GetServiceCheckInApiResponse = {
    driverFirstname: checkInDefaultDriver?.driverFirstname || null,
    driverLastname: checkInDefaultDriver?.driverLastname || null,
    driverEmail: checkInDefaultDriver?.driverEmail || null,
    driverPhonePrefix: checkInDefaultDriver?.driverPhonePrefix || null,
    driverPhoneNo: checkInDefaultDriver?.driverPhoneNo || null,
    driverIdentityCard: checkInDefaultDriver?.driverIdentityCard || null,
    consentToTestDrive: true,
    ...vehicleDefaultValues,
  };

  const isLoading =
    isCheckInLoading || isServiceCaseLoading || isVehicleLoading || isCheckInDefaultDriverLoading;
  const isError = isCheckInError || iseServiceCaseError || isVehicleError;
  const isFormView = !checkIn || checkIn?.state === 'SCHEDULED' || isEditing;

  const cardActions = buildArray<Action>().whenNot(isFormView, {
    type: 'button',
    variant: 'link',
    leftIcon: 'image/edit',
    title: i18n.t('general.actions.edit'),
    onClick: setEditing,
    'data-testid': testIds.workshop.serviceCaseDetail('checkInEdit'),
  });

  const parkingPlaceName =
    `${parkingPlace?.prefix ?? ''}${parkingPlace?.number ?? ''}` || undefined;

  return (
    <VStack spacing={4}>
      <Card title={i18n.t('general.labels.basicInformation')} actions={cardActions}>
        <DataStatus
          isLoading={isLoading}
          isError={isError}
          data-testid={testIds.workshop.serviceCaseDetail('checkInStatus')}
        >
          <Show when={isFormView}>
            <CheckInHandOverForm
              data-testid={testIds.workshop.serviceCaseDetail('checkIneditForm')}
              defaultValues={checkIn ?? emptyDefaultValues}
              onSubmit={handleSaveCheckIn}
              isCheckin
              isEditing={isEditing}
              onEditDiscard={cancelEditing}
            />
          </Show>
          <Hide when={isFormView}>
            <CheckInHandOverParameter
              data={checkIn}
              parkingPlace={parkingPlaceName}
              data-testid={testIds.workshop.serviceCaseDetail('checkInlist')}
              isCheckin
            />
          </Hide>
        </DataStatus>
      </Card>
      <Show when={isNil(inspectionId)}>
        <Card
          title={i18n.t('page.Inspection.labels.title')}
          actions={[
            {
              type: 'button',
              variant: 'link',
              leftIcon: 'content/add_circle',
              title: i18n.t('general.labels.createNew'),
              isDisabled: isNil(checkIn),
              isLoading: isCreatingInspection || patchServiceCheckInQuery.isLoading,
              onClick: () => {
                createInspection({
                  vehicleId: props.vehicleId,
                  createAuditRequestBody: {
                    inspectionType: InspectionType.CHECKIN,
                  },
                })
                  .unwrap()
                  .then(({id}) =>
                    patchServiceCheckIn({
                      body: {
                        ...checkIn,
                        inspectionId: [id],
                      },
                      serviceCaseId: serviceCase?.id || '',
                    }).unwrap()
                  )
                  .then(() => showNotification.success())
                  .catch(handleApiError);
              },
            },
          ]}
        >
          <EmptyStatus headline={i18n.t('entity.order.actions.checkInEmpty')} />
        </Card>
      </Show>
      <Show when={!!checkIn && !!inspectionId}>
        <Inspection
          inspectionId={inspectionId || ''}
          vehicleId={props.vehicleId}
          isCompletionDisabled={isNil(checkIn?.inspectionId?.[0])}
          isError={isInspectionError}
          data-testid={testIds.workshop.serviceCaseDetail('checkinInspection')}
          onInspectionDeleted={() => {
            if (!checkIn) {
              return;
            }

            const {inspectionId: _, ...rest} = checkIn;

            patchServiceCheckIn({serviceCaseId: id, body: rest})
              .unwrap()
              .then(() => showNotification.success())
              .catch(handleApiError);
          }}
          onSendEmailSmsNotification={() => {
            openDialog(
              <EmailSmsNotificationBoundary
                serviceCaseId={id}
                dialogId="email-sms-notification-dialog-checkin"
                data-testid={testIds.workshop.serviceCaseDetail('emailSmsNotificationHandover')}
                defaultCategory={NotificationCategory.CHECKIN}
              />,
              {
                id: 'email-sms-notification-dialog-checkin',
                size: 'large',
                title: i18n.t('entity.order.actions.sendEmailSms'),
              }
            );
          }}
        />
      </Show>
    </VStack>
  );
}
