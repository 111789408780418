import {isFeatureEnabled} from 'feature-flags';
import {Action, Attributes, AttributesRow, Card, DataStatus, openDialog} from 'platform/components';
import {useDateTimeFormatter} from 'platform/locale';

import {isNil} from 'ramda';

import {useReadCodelistQuery} from '@dms/api/codelist';
import {useGetSaleVehicleQuery} from '@dms/api/saleVehicle';
import {useGetVehicleFinancialInstitutionQuery} from '@dms/api/vehicle';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {EMPTY_PLACEHOLDER, VehicleFinancingCard} from '@dms/shared';

import {buildArray, isApiDateString, parseDate, suffixTestId, TestIdProps} from 'shared';

import {VehicleFinancialInstitutionEditDialog} from './VehicleFinancialInstitutionEditDialog';

interface VehicleFinancialInstitutionProps extends TestIdProps {
  vehicleId: string;
  canEditVehicle: boolean;
}

const LEASING_CODELIST_ID = 'leasing_and_credit_company';

export function VehicleFinancialInstitution(props: VehicleFinancialInstitutionProps) {
  const formatDateTime = useDateTimeFormatter();

  const isFinancingInformationV2Enabled = isFeatureEnabled(
    featureFlags.CORE_FINANCING_INFORMATION_V2
  );

  const {data, isLoading, isError} = useGetVehicleFinancialInstitutionQuery({
    vehicleId: props.vehicleId,
  });

  const {data: saleVehicle} = useGetSaleVehicleQuery(
    {vehicleId: props.vehicleId ?? ''},
    {skip: isNil(props.vehicleId)}
  );

  const {data: leasingAndCreditCompanies} = useReadCodelistQuery({
    codelistId: LEASING_CODELIST_ID,
  });

  const isFinancedV2 = isFinancingInformationV2Enabled && saleVehicle?.leasingAndCreditCompany;

  const institution = leasingAndCreditCompanies?.codes.find(
    (code) => code.codeId === saleVehicle?.leasingAndCreditCompanyCodeId
  )?.name;

  const attributeRows = buildArray<AttributesRow>()
    .whenNot(isFinancingInformationV2Enabled, {
      label: i18n.t('entity.vehicleFinancialInstitution.labels.financed'),
      value: data?.isFinanced ? i18n.t('general.labels.yes') : i18n.t('general.labels.no'),
    })
    .when(!isFinancingInformationV2Enabled && data?.isFinanced, {
      label: i18n.t('entity.vehicleFinancialInstitution.labels.institution'),
      value: data?.institution,
    })
    .when(isFinancingInformationV2Enabled, {
      label: i18n.t('entity.vehicleFinancialInstitution.labels.financed'),
      value: saleVehicle?.leasingAndCreditCompany
        ? i18n.t('general.labels.yes')
        : i18n.t('general.labels.no'),
    })
    .when(isFinancedV2, {
      label: i18n.t('entity.vehicleFinancialInstitution.labels.institution'),
      value: institution,
    })
    .when(isFinancedV2, {
      label: i18n.t('entity.checkout.labels.endOfFinancing'),
      value: isApiDateString(saleVehicle?.endOfFinancing)
        ? formatDateTime('dateShort', parseDate(saleVehicle!.endOfFinancing))
        : EMPTY_PLACEHOLDER,
    })
    .when(isFinancedV2, {
      label: i18n.t('entity.checkout.labels.contractNumber'),
      value: saleVehicle?.financingContractNumber ?? EMPTY_PLACEHOLDER,
    })
    .when(isFinancedV2, {
      label: i18n.t('general.labels.note'),
      value: saleVehicle?.financingNote ?? EMPTY_PLACEHOLDER,
    });

  const cardActions = buildArray<Action>().add({
    type: 'button',
    variant: 'link',
    leftIcon: 'image/edit',
    title: i18n.t('general.actions.edit'),
    isDisabled: isNil(data),
    onClick: () =>
      openDialog(
        isFinancingInformationV2Enabled ? (
          <VehicleFinancingCard
            vehicleId={props.vehicleId}
            isWithSubmit
            data-testid={suffixTestId('financingCard', props)}
          />
        ) : (
          <VehicleFinancialInstitutionEditDialog
            vehicleId={props.vehicleId}
            defaultValues={{
              isFinanced: data?.isFinanced,
              institution: data && data.isFinanced ? data.institution : '',
            }}
          />
        ),
        {
          title: i18n.t('entity.vehicleFinancialInstitution.labels.title'),
          scrollBehavior: 'outside',
        }
      ),
  });

  return (
    <Card
      title={i18n.t('entity.vehicleFinancialInstitution.labels.title')}
      actions={props.canEditVehicle ? cardActions : undefined}
    >
      <DataStatus isLoading={isLoading} isError={isError}>
        <Attributes rows={attributeRows} data-testid={suffixTestId('attributes', props)} />
      </DataStatus>
    </Card>
  );
}
