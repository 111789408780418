import {
  Alert,
  Attributes,
  Button,
  ButtonGroup,
  ButtonProps,
  Card,
  DataStatus,
  Separator,
} from 'platform/components';
import {Align, Grid, GridItem, Show} from 'platform/foundation';

import {FC, useState} from 'react';

import {isNil} from 'ramda';
import {isNotNil, isNotNilOrEmpty, isPositive} from 'ramda-adjunct';

import {CheckoutDeputyPersonResponseBody} from '@dms/api/checkout';
import {
  ContractInformationResponseBody,
  ContractInformationResponseBodyV2,
  CustomerResponseBodyV2,
  useGetCustomerV2Query,
} from '@dms/api/customer';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {
  composeAddressLineFromCommonAddress,
  NoPermissionTooltip,
  useAvailableVerificationRegisters,
  useCustomerContractAttributes,
  useCustomerPersonAttributes,
  VehicleFinancingCard,
} from '@dms/shared';
import {OrderDiscriminatorEnum, PersonRequestBody, TypeOfSaleEnum} from '@dms/teas';

import {suffixTestId, TestIdProps} from 'shared';

import {
  ContractInformationForm,
  ContractInformationFormState,
} from '../../ContractInformation/ContractInformationForm';
import {CustomerVerification} from '../../CustomerVerification/CustomerVerification';
import {PersonForm} from '../../Person/PersonForm';
import {CheckoutOrderBillingProps} from '../types/CheckoutOrderBillingProps';
import {CheckoutAdditionalCustomersDetail} from './CheckoutAdditionalCustomersDetail';
import {CheckoutOrderBillingAbroad} from './CheckoutOrderBillingAbroad';

export const CheckoutOrderBillingDetail: FC<CheckoutOrderBillingProps & TestIdProps> = ({
  checkoutContractsInformation,
  order,
  handlePersonSubmit,
  handleAddressSubmit,
  handleEditContractInformation,
  handleChangeTypeOfSaleVehicle,
  readonly,
  checkoutId,
  customerContractInformation,
  ...props
}) => {
  const [getAttributes] = useCustomerPersonAttributes(true);
  const [getContractAttributes] = useCustomerContractAttributes(true);

  const {data: customer} = useGetCustomerV2Query({customerId: props.customerId});

  const {registers, isLoading: isLoadingAvailableVerifyingRegisters} =
    useAvailableVerificationRegisters();

  const customerContractInformationV2 = customerContractInformation.find(
    (contractInformation) =>
      contractInformation.id === checkoutContractsInformation.customerContractInformation.id
  );

  const customerContractInformationData =
    customerContractInformationV2 && getCustomerContractInfoV1(customerContractInformationV2);

  const isSoftDeleted = Boolean(customerContractInformationV2?.isDeleted);

  const [editOpen, setEditOpen] = useState<string[]>([]);
  const [editDeputyPersonOpen, setEditDeputyPersonOpen] = useState<string[]>([]);

  const toggleEdit = (contactPersonId: string) => () => {
    if (editOpen.includes(contactPersonId)) {
      setEditOpen(editOpen.filter((id) => id !== contactPersonId));
    } else {
      setEditOpen([...editOpen, contactPersonId]);
    }
  };

  const toggleEditDeputyPerson = (contactPersonId: string) => () => {
    if (editDeputyPersonOpen.includes(contactPersonId)) {
      setEditDeputyPersonOpen(editDeputyPersonOpen.filter((id) => id !== contactPersonId));
    } else {
      setEditDeputyPersonOpen([...editDeputyPersonOpen, contactPersonId]);
    }
  };

  const editContractInformation =
    (contractInformationId: string) =>
    async (values: ContractInformationFormState): Promise<void> => {
      await handleEditContractInformation(contractInformationId, order.id)(values);
      toggleEdit(contractInformationId)();
    };

  const editDeputyPerson =
    (personId: string) =>
    async (values: PersonRequestBody): Promise<void> => {
      await handlePersonSubmit(values, personId);
      toggleEditDeputyPerson(personId)();
    };

  const isDeputyPersonSoftDeleted = (deputyPersonId: string) =>
    isNil(customer?.persons?.find((person) => person.id === deputyPersonId));

  if (!customerContractInformationData) {
    return null;
  }

  return (
    <Grid columns={1}>
      <GridItem>
        <Card variant="inlineWhite" data-testid={props['data-testid']}>
          {editOpen.includes(customerContractInformationData.id) ? (
            <ContractInformationForm
              data-testid={props['data-testid']}
              customerId={props.customerId}
              contractInformation={customerContractInformationData}
              onSubmit={editContractInformation(customerContractInformationData.id)}
              handleAddressSubmit={handleAddressSubmit}
              handlePersonSubmit={handlePersonSubmit}
              WrapperComponent={({children, handleSubmit}) => {
                const buttons: ButtonProps[] = [
                  {
                    title: i18n.t('general.actions.discard'),
                    variant: 'secondary',
                    onClick: toggleEdit(
                      checkoutContractsInformation.customerContractInformation.id
                    ),
                    'data-testid': suffixTestId('discard', props),
                  },
                  {
                    title: i18n.t('general.actions.save'),
                    variant: 'primary',
                    onClick: handleSubmit,
                    'data-testid': suffixTestId('save', props),
                  },
                ];

                return (
                  <>
                    {children}
                    <Separator />
                    <ButtonGroup align="right" buttons={buttons} />
                  </>
                );
              }}
            />
          ) : (
            <Grid columns={1}>
              {isSoftDeleted ? (
                <Alert
                  data-testid={suffixTestId('isSoftDeleted', props)}
                  variant="warning"
                  title={i18n.t('general.notifications.contactPersonSoftDeleted')}
                />
              ) : !readonly ? (
                <Align left>
                  <NoPermissionTooltip shouldShowTooltip={Boolean(props.hasContractSelectDisabled)}>
                    <Button
                      data-testid={suffixTestId('edit', props)}
                      variant="link"
                      size="small"
                      leftIcon="image/edit"
                      onClick={toggleEdit(customerContractInformationData.id)}
                      isDisabled={props.hasContractSelectDisabled}
                      title={i18n.t('general.actions.edit')}
                    />
                  </NoPermissionTooltip>
                </Align>
              ) : null}
              <GridItem>
                <Show when={isNotNil(customerContractInformationV2)}>
                  <Attributes
                    rows={getContractAttributes(customerContractInformationV2)}
                    size="third"
                    data-testid={props['data-testid']}
                  />
                </Show>
                <DataStatus isLoading={isLoadingAvailableVerifyingRegisters}>
                  <Show
                    when={
                      isNotNilOrEmpty(customerContractInformationData?.id) &&
                      isPositive(registers?.length)
                    }
                  >
                    <CustomerVerification
                      contractInformationId={customerContractInformationData?.id ?? ''}
                      data-testid={suffixTestId('verification', props)}
                    />
                  </Show>
                </DataStatus>
              </GridItem>
            </Grid>
          )}
        </Card>
      </GridItem>
      {!!checkoutContractsInformation?.deputyPersons?.length && (
        <GridItem>
          <Card
            variant="inlineGrey"
            title={i18n.t('entity.order.labels.deputyPerson')}
            data-testid={suffixTestId('deputyPerson', props)}
          >
            {getDeputyPersons(checkoutContractsInformation.deputyPersons, customer)?.map(
              (deputyPerson, i) => (
                <Card
                  data-testid={suffixTestId(`deputyPerson[${i}]`, props)}
                  variant="inlineWhite"
                  key={deputyPerson.id}
                >
                  {editDeputyPersonOpen.includes(deputyPerson.id) ? (
                    <PersonForm
                      data-testid={suffixTestId(`deputyPerson[${i}]`, props)}
                      showIdentityCards
                      initialValues={deputyPerson}
                      onSubmit={editDeputyPerson(deputyPerson.id)}
                      handleAddressSubmit={handleAddressSubmit}
                      WrapperComponent={({children, handleSubmit}) => {
                        const buttons: ButtonProps[] = [
                          {
                            title: i18n.t('general.actions.discard'),
                            variant: 'secondary',
                            onClick: toggleEditDeputyPerson(deputyPerson.id),
                            'data-testid': suffixTestId(`deputyPerson[${i}]-discard`, props),
                          },
                          {
                            title: i18n.t('general.actions.save'),
                            variant: 'primary',
                            onClick: handleSubmit,
                            'data-testid': suffixTestId(`deputyPerson[${i}]-save`, props),
                          },
                        ];

                        return (
                          <>
                            {children}
                            <Separator />
                            <ButtonGroup
                              align="right"
                              buttons={buttons}
                              data-testid={suffixTestId(`deputyPerson[${i}]`, props)}
                            />
                          </>
                        );
                      }}
                    />
                  ) : (
                    <Grid columns={1}>
                      {isDeputyPersonSoftDeleted(deputyPerson.id) ? (
                        <Alert
                          data-testid={suffixTestId(
                            `deputyPerson[${i}]-isDeputyPersonSoftDeleted`,
                            props
                          )}
                          variant="warning"
                          title={i18n.t('general.notifications.personSoftDeleted.title')}
                        />
                      ) : !readonly ? (
                        <Align left>
                          <NoPermissionTooltip
                            shouldShowTooltip={Boolean(props.hasDeputyPersonSelectDisabled)}
                          >
                            <Button
                              data-testid={suffixTestId(`deputyPerson[${i}]-edit`, props)}
                              variant="link"
                              size="small"
                              leftIcon="image/edit"
                              isDisabled={props.hasDeputyPersonSelectDisabled}
                              onClick={toggleEditDeputyPerson(deputyPerson.id)}
                              title={i18n.t('general.actions.edit')}
                            />
                          </NoPermissionTooltip>
                        </Align>
                      ) : null}

                      <Attributes
                        rows={getAttributes(deputyPerson, {
                          isRoleHidden: true,
                          isEmailsHidden: true,
                          isPhoneNumbersHidden: true,
                          isPersonalIdentifierHidden: true,
                        })}
                        size="third"
                        data-testid={suffixTestId(`deputyPerson[${i}]`, props)}
                      />
                    </Grid>
                  )}
                </Card>
              )
            )}
          </Card>
        </GridItem>
      )}
      <Show
        when={isNotNilOrEmpty(order.additionalCustomers)}
        whenFeatureEnabled={featureFlags.ACCOUNTING_ADDITIONAL_CUSTOMER}
      >
        <CheckoutAdditionalCustomersDetail
          additionalCustomers={order.additionalCustomers}
          counterStartAt={2}
        />
      </Show>
      <Show
        when={
          order.orderDiscriminator === OrderDiscriminatorEnum.SALE &&
          (order?.items?.[0]?.deductible || order.typeOfSale !== TypeOfSaleEnum.INLAND)
        }
      >
        <GridItem>
          <CheckoutOrderBillingAbroad
            handleChangeTypeOfSaleVehicle={handleChangeTypeOfSaleVehicle}
            checkoutId={checkoutId}
            orderId={order.id}
            typeOfSale={order.typeOfSale}
            readonly
          />
        </GridItem>
      </Show>
      <Show whenFeatureEnabled={featureFlags.CORE_FINANCING_INFORMATION_V2}>
        <VehicleFinancingCard
          vehicleId={props.saleVehicle?.vehicleId}
          isReadonly={readonly}
          data-testid={suffixTestId('financing', props)}
        />
      </Show>
    </Grid>
  );
};

const getDeputyPersons = (
  deputyPersons: CheckoutDeputyPersonResponseBody[],
  customer: CustomerResponseBodyV2 | undefined
) => {
  const deputyPersonsIds = deputyPersons.map((person) => person.customerPerson.id);
  return customer?.persons?.filter((person) => deputyPersonsIds.includes(person.id));
};

const getCustomerContractInfoV1 = (
  contractInformation: ContractInformationResponseBodyV2
): ContractInformationResponseBody => ({
  id: contractInformation.id,
  permanent: contractInformation.permanent,
  legalForm: contractInformation.legalForm,
  isDeleted: contractInformation.isDeleted,
  bankAccounts: contractInformation.bankAccounts?.map((bankAccount) => ({
    id: bankAccount.id,
    bankAccountData: {
      name: bankAccount.name,
      countryCode: bankAccount.countryCode,
      ownerName: bankAccount.ownerName,
      iban: bankAccount.iban,
      swiftBic: bankAccount.swiftBic,
      currency: bankAccount.currency,
      number: bankAccount.number,
      bankCode: bankAccount.bankCode,
      isDeleted: bankAccount.isDeleted,
    },
  })),
  person: contractInformation.person?.id
    ? {
        id: contractInformation.person.id,
        personData: {
          firstName: contractInformation.person.firstName,
          lastName: contractInformation.person.lastName,
          middleName: contractInformation.person.middleName,
          titleBefore: contractInformation.person.titleBefore,
          titleAfter: contractInformation.person.titleAfter,
          genderKey: contractInformation.person.genderKey,
          roles: contractInformation.person.roles,
          citizenshipCode: contractInformation.person.citizenshipCode,
          birthdate: contractInformation.person.birthdate,
          personalIdentifier: contractInformation.person.personalIdentifier,
        },
        phoneNumbers: contractInformation.person.phoneNumbers.map((phoneNumber) => ({
          type: phoneNumber.type,
          phoneNumber: {
            countryCode: phoneNumber.countryCode,
            prefix: phoneNumber.prefix,
            number: phoneNumber.number,
          },
        })),
        emails: contractInformation.person.emails,
        identityCards: contractInformation.person.identityCards.map((identityCard) => ({
          id: identityCard.id,
          identityCardData: {
            type: identityCard.type,
            cardNumber: identityCard.cardNumber,
            issuedOn: identityCard.issuedOn,
            validUntil: identityCard.validUntil,
            issuer: identityCard.issuer,
            issuedInCountryCode: identityCard.issuedInCountryCode,
            note: identityCard.note,
          },
        })),
        permanentAddress: contractInformation.person.permanentAddress
          ? {
              id: contractInformation.person.permanentAddress.id,
              addressData: {
                city: contractInformation.person.permanentAddress.address.city,
                postalCode: contractInformation.person.permanentAddress.address.zip,
                countryCode: contractInformation.person.permanentAddress.address.country,
                addressLine1: contractInformation.person.permanentAddress.address
                  ? composeAddressLineFromCommonAddress(
                      contractInformation.person.permanentAddress.address
                    )
                  : null,
                addressLine2: null,
                type: contractInformation.person.permanentAddress.type,
                province: null,
                invalid: contractInformation.person.permanentAddress.invalid,
              },
            }
          : null,
      }
    : null,
  businessInfo: contractInformation.businessInfo
    ? {
        id: contractInformation.businessInfo.id,
        businessAddress: contractInformation.businessInfo.address
          ? {
              id: contractInformation.businessInfo.address.id,
              addressData: {
                city: contractInformation.businessInfo.address.address.city,
                postalCode: contractInformation.businessInfo.address.address.zip,
                countryCode: contractInformation.businessInfo.address.address.country,
                addressLine1: contractInformation.businessInfo.address.address
                  ? composeAddressLineFromCommonAddress(
                      contractInformation.businessInfo.address.address
                    )
                  : null,
                addressLine2: null,
                type: contractInformation.businessInfo.address.type,
                province: null,
                invalid: contractInformation.businessInfo.address.invalid,
              },
            }
          : null,
        businessInfoData: contractInformation.businessInfo.businessInfo,
      }
    : null,
  customFieldsPayload: contractInformation.customFieldsPayload,
});
