import {Tooltip} from 'platform/components';
import {HStack} from 'platform/foundation';

import {useMemo, useState, forwardRef, Ref, useImperativeHandle} from 'react';
import {Link, LinkProps} from 'react-router-dom';

import {isNil, isNotNil} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {useTooltipProps} from '../../hooks/useTooltipProps';
import {ICellRenderer, ICellRendererParams} from '../../types/AgGridTypes';
import {LinkClickOverride} from '../../types/LinkClickOverride';
import {LinkPropsGetter} from '../../types/LinkPropsGetter';
import {TooltipGetter} from '../../types/TooltipGetter';
import {getCellClassName} from '../../utils/getCellClassName';

export interface LinkCellRendererProps extends ICellRendererParams {
  getTooltipProps?: TooltipGetter;
  getLinkProps?: LinkPropsGetter;
  linkClickOverride?: LinkClickOverride;
}

function LinkCellRendererComponent(props: LinkCellRendererProps, ref: Ref<ICellRenderer>) {
  const {getValue, value, getTooltipProps, data, node, getLinkProps, colDef} = props;
  const className = getCellClassName(colDef);

  const [cellValue, setCellValue] = useState<string>(() => (getValue ? getValue() : value));

  useImperativeHandle(ref, () => ({
    refresh: ({getValue: getNewValue, value: newValue}) => {
      const newCellValue = getNewValue ? getNewValue() : newValue;
      if (newCellValue !== newValue) {
        setCellValue(newCellValue);
      }
      return true;
    },
  }));

  const tooltipProps = useTooltipProps(data, node, getTooltipProps);

  const linkProps = useMemo<LinkProps | undefined>(() => {
    if (getLinkProps && data) {
      return getLinkProps({data});
    }
    return undefined;
  }, [data, getLinkProps]);

  if (isNil(cellValue)) {
    return null;
  }

  if (!isArray(linkProps)) {
    return (
      <Tooltip {...tooltipProps}>
        {/* eslint-disable-next-line react/forbid-dom-props */}
        <div className={className}>
          {isNil(props.linkClickOverride) && linkProps?.to && (
            <Link {...linkProps} ref={stopPropagationOnRef}>
              {cellValue}
            </Link>
          )}

          {isNotNil(props.linkClickOverride) && linkProps?.to && (
            <Link
              to={linkProps?.to}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                props.linkClickOverride!({
                  defaultRedirectRoute: linkProps?.to?.toString(),
                  rowData: props.data,
                  value: cellValue,
                });
              }}
            >
              {cellValue}
            </Link>
          )}
        </div>
      </Tooltip>
    );
  }

  return (
    <HStack wrap={colDef?.wrapText} width="100%">
      {linkProps.map((linkProp, index) => {
        const isLast = index === linkProps.length - 1;
        return (
          <Tooltip {...tooltipProps} key={index}>
            {isNil(props.linkClickOverride) && linkProp?.to && (
              <Link {...linkProp} ref={stopPropagationOnRef}>
                `${cellValue[index]}${isLast ? '' : ' / '}`
              </Link>
            )}

            {isNotNil(props.linkClickOverride) && linkProp?.to && (
              <Link
                to={linkProp?.to}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  props.linkClickOverride!({
                    defaultRedirectRoute: linkProp?.to?.toString(),
                    rowData: props.data,
                    value: cellValue[index],
                  });
                }}
              >
                `${cellValue[index]}${isLast ? '' : ' / '}`
              </Link>
            )}
          </Tooltip>
        );
      })}
    </HStack>
  );
}

export const LinkCellRenderer = forwardRef<ICellRenderer, LinkCellRendererProps>(
  LinkCellRendererComponent
);

const stopPropagationOnRef = (ref: HTMLAnchorElement) => {
  if (!ref) {
    return;
  }

  ref.onclick = (event) => event.stopPropagation();
};
