import {isNil} from 'ramda';

import {GetArticleResponse} from '@dms/api/metadaWarehouseArticle';

import {Nullish} from 'shared';

import {ArticlePricingDetails} from '../types/ArticlePricingDetails';

export const getArticleDetailCardPrices = (
  article: GetArticleResponse | Nullish
): ArticlePricingDetails | null => {
  if (isNil(article)) {
    return null;
  }

  return {
    averagePurchasePriceWithoutVat: article?.pricesSettings?.avgPurchasePriceWithoutVat ?? 0,
    averagePurchasePriceWithVat: article?.pricesSettings?.avgPurchasePriceWithVat ?? 0,
    lastPurchasePriceWithoutVat: article?.pricesSettings?.lastPurchasePriceWithoutVat ?? 0,
    lastPurchasePriceWithVat: article?.pricesSettings?.lastPurchasePriceWithVat ?? 0,
    recommendedPriceWithoutVat: article?.pricesSettings?.recommendedPurchasePriceWithoutVat ?? 0,
    recommendedPriceWithVat: article?.pricesSettings?.recommendedPurchasePriceWithVat ?? 0,
    saleBasePriceWithoutVat: article?.dispensingPrices?.saleBasePriceWithoutVat ?? 0,
    saleBasePriceWithVat: article?.dispensingPrices?.saleBasePriceWithVat ?? 0,
    warrantyPriceWithoutVat: article?.dispensingPrices?.warrantyPriceWithoutVat ?? 0,
    warrantyPriceWithVat: article?.dispensingPrices?.warrantyPriceWithVat ?? 0,
  };
};
