import i18n from '@dms/i18n';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {FinancingForm} from './components/FinancingForm';

export function Financing() {
  return (
    <SettingsTemplate
      header={{title: i18n.t('entity.financing.labels.pageTitle')}}
      data-testid="sales-financing"
      description={i18n.t('entity.financing.labels.description')}
    >
      <FinancingForm />
    </SettingsTemplate>
  );
}
