import {VehiclePhoto} from '@dms/api/shared';

import {ImageType} from '../components/ImagesCard/types';

export const filterSortImageByWeight = (images: VehiclePhoto[], albumId?: string): ImageType[] =>
  (images || [])
    .filter((p) => p.albumId === albumId)
    .sort((a, b) => {
      if (a.weight < b.weight) {
        return -1;
      }
      if (a.weight > b.weight) {
        return 1;
      }
      return 0;
    });
