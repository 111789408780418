import {Helmet} from 'react-helmet-async';

import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {useInvoiceActionCallback} from '@dms/shared';

import {DataGrid} from 'features/datagrid';

export function InvoiceOverview() {
  const actionCallback = useInvoiceActionCallback();

  return (
    <>
      <Helmet title={i18n.t('page.accounting.invoiceList.title')} />
      <DataGrid
        gridCode="invoicing"
        actionCallback={actionCallback}
        data-testid={testIds.accounting.invoiceOverview('datagrid')}
      />
    </>
  );
}
