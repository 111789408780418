import {groupBy} from 'ramda';

import {GetAllApiEnumsApiResponse} from '@dms/api/shared';

import {ApiEnum, ApiEnumItem} from '../types/ApiEnum';
import {getDefaultLabel} from './getDefaultLabel';
import {getEnumByProperty} from './getEnumByProperty';

export function buildFeatureEnumTree(enums?: GetAllApiEnumsApiResponse): ApiEnum {
  const featureItems = getEnumByProperty('feature', enums);

  const featureEnumItems = featureItems.map((item) => ({
    key: item.const_key,
    value: item.const_key,
    label: getDefaultLabel(item),
    categories: item.categories,
    _parent: item.parent, // Temporary field to help with grouping
  }));

  const groupedByParent = groupBy(
    (item) => (item._parent === null ? 'root' : item._parent),
    featureEnumItems
  );

  const rootItems = groupedByParent.root || [];

  const buildTree = (items: Array<ApiEnumItem & {_parent?: string | null}>): ApiEnum =>
    items.map((item) => {
      const children = groupedByParent[item.key];

      // Remove the temporary _parent field
      const {_parent, ...cleanItem} = item;

      if (children && children.length > 0) {
        return {
          ...cleanItem,
          children: buildTree(children),
        };
      }

      return cleanItem;
    });

  return buildTree(rootItems);
}
