import {setHours, setMinutes} from 'date-fns';
import {
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormSubmitHandler,
  FormField,
  openConfirmDialog,
} from 'platform/components';
import {Box, Grid, HStack, Show, VStack} from 'platform/foundation';
import {object} from 'yup';

import {DefaultValues} from 'react-hook-form';

import {useGetCurrentUserInfoQuery} from '@dms/api/user';
import i18n from '@dms/i18n';

import {getApiDateTimeString, suffixTestId, TestIdProps, yupDate, yupString} from 'shared';

export type RequestTireSetData = {
  requestedBranchId: string;
  neededAt: string;
  note: string;
};

type RequestTireSetFormType = {
  requestedBranchId: string;
  neededAtDate: Date;
  neededAtTime: string;
  note: string;
};

interface RequestTireSetFormProps extends TestIdProps {
  defaultValues?: DefaultValues<RequestTireSetFormType>;
  onCancelRequest?: () => Promise<void>;
  onSubmit: (data: RequestTireSetData) => Promise<void>;
  onClose: VoidFunction;
}

export function RequestTireSetForm(props: RequestTireSetFormProps) {
  const {data: currentUser, isLoading: isCurrentUserLoading} = useGetCurrentUserInfoQuery();

  const currentUserBranchesOptions = (currentUser?.branches ?? []).map((branch) => ({
    label: branch.name,
    value: branch.id,
  }));

  const handleSubmit: FormSubmitHandler<RequestTireSetFormType> = async (formData) => {
    const {neededAtDate, neededAtTime, ...rest} = formData;

    const [hours, minutes] = neededAtTime.split(':');

    const neededAtDateWithHours = setHours(neededAtDate, parseInt(hours));
    const neededAtDateTime = setMinutes(neededAtDateWithHours, parseInt(minutes));
    const neededAt = getApiDateTimeString(neededAtDateTime);

    const requestData = {...rest, neededAt};

    await props.onSubmit(requestData);
  };

  const handleCancelRequest = () => {
    openConfirmDialog({
      text: i18n.t('entity.tireSet.labels.cancelTireRequestQuestion'),
      onConfirm: () => props.onCancelRequest?.(),
    });
  };

  return (
    <Form<RequestTireSetFormType>
      schema={formSchema}
      onSubmit={handleSubmit}
      defaultValues={props.defaultValues}
    >
      {(control) => (
        <VStack spacing={4}>
          <FormField
            control={control}
            label={i18n.t('entity.tiresInventory.labels.branch')}
            name="requestedBranchId"
            type="choice"
            options={currentUserBranchesOptions}
            isLoading={isCurrentUserLoading}
            isRequired
            data-testid={suffixTestId('requestedBranchId', props)}
          />
          <Grid columns={2}>
            <FormField
              control={control}
              label={i18n.t('entity.warehouse.labels.neededAt')}
              name="neededAtDate"
              type="date"
              isRequired
              data-testid={suffixTestId('neededAtDate', props)}
            />
            <Box paddingTop={5}>
              <FormField
                control={control}
                name="neededAtTime"
                type="time"
                isRequired
                data-testid={suffixTestId('neededAtTime', props)}
              />
            </Box>
          </Grid>
          <FormField
            control={control}
            label={i18n.t('general.labels.note')}
            name="note"
            type="textarea"
            data-testid={suffixTestId('note', props)}
          />
          <HStack justify={props.onCancelRequest ? 'space-between' : 'flex-end'}>
            <Show when={props.onCancelRequest}>
              <Button
                title={i18n.t('entity.tireSet.actions.cancelRequest')}
                variant="dangerLink"
                leftIcon="navigation/cancel"
                onClick={handleCancelRequest}
                data-testid={suffixTestId('discardButton', props)}
              />
            </Show>
            <ButtonGroup>
              <Button
                title={i18n.t('general.actions.discard')}
                variant="secondary"
                onClick={props.onClose}
                data-testid={suffixTestId('discardButton', props)}
              />
              <FormButton
                control={control}
                type="submit"
                title={
                  props.defaultValues
                    ? i18n.t('general.actions.save')
                    : i18n.t('general.actions.confirm')
                }
                data-testid={suffixTestId('saveButton', props)}
              />
            </ButtonGroup>
          </HStack>
        </VStack>
      )}
    </Form>
  );
}

const formSchema = object({
  requestedBranchId: yupString.required(),
  neededAtDate: yupDate.required(),
  neededAtTime: yupString.required(),
  note: yupString,
});
