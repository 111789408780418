import {ButtonGroup, DataStatus} from 'platform/components';
import {Space, VStack} from 'platform/foundation';

import {useState} from 'react';

import {isNotNil} from 'ramda';

import {useGetArticleCategoryListQuery} from '@dms/api/metadaWarehouseArticleCategory';
import i18n from '@dms/i18n';

import {suffixTestId, RequiredTestIdProps} from 'shared';

import {FolderTree} from '../../../FolderTree/FolderTree';
import {ActiveFolder} from '../../types/ActiveFolder';

const FOLDER_CONTEXT_TARGET = 'spare-parts-catalog';

interface SelectFolderProps extends RequiredTestIdProps {
  onSelect: (activeFolder: ActiveFolder) => void;
  onClose: VoidFunction;
}

export function SelectFolder(props: SelectFolderProps) {
  const [activeFolder, setActiveFolder] = useState<ActiveFolder>();

  const {data: articleCategoryList, isLoading, isError} = useGetArticleCategoryListQuery();

  const articleCategories = articleCategoryList?.categories?.map((category) => category.id) ?? [];

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <VStack spacing={1} data-testid={props['data-testid']}>
        <FolderTree
          contextIds={articleCategories}
          contextTarget={FOLDER_CONTEXT_TARGET}
          onFolderChange={(id, contextId) =>
            setActiveFolder(isNotNil(id) && isNotNil(contextId) ? {id, contextId} : undefined)
          }
          activeFolderId={activeFolder?.id}
          data-testid={suffixTestId('catalogueTree', props)}
        />
        <Space vertical={1} />
        <ButtonGroup
          align="right"
          buttons={[
            {
              title: i18n.t('general.actions.discard'),
              onClick: props.onClose,
              variant: 'secondary',
            },
            {
              title: i18n.t('entity.order.actions.moveHere'),
              onClick: () => props.onSelect(activeFolder),
              isDisabled: !activeFolder,
              'data-testid': suffixTestId('move', props),
            },
          ]}
        />
      </VStack>
    </DataStatus>
  );
}
