import {isFeatureEnabled} from 'feature-flags';
import {Action, closeCurrentDialog, TabProps, Tabs} from 'platform/components';

import {EntityResourceIds, GetUserApiResponse} from '@dms/api/shared';
import {useGetMyTasksQuery, useGetTasksByMeQuery} from '@dms/api/taskManagement';
import {useGetCurrentUserInfoQuery} from '@dms/api/user';
import featureFlags from '@dms/feature-flags';
import {openCreateTaskFormDialog, openEditTaskFormDialog} from '@dms/features/tasks';
import i18n from '@dms/i18n';
import {employeeRoutes, taskManagerRoutes} from '@dms/routes';
import {useDataQuery, usePermissions} from '@dms/shared';

import {RequiredTestIdProps, buildArray, useNavigate, suffixTestId, composePath} from 'shared';

import {refreshDatagrid} from 'features/datagrid';

import {PRESET_FILTERS, PresetFilter} from '../../../constants/presetFilters';
import {useResourceIdentifier} from '../../../hooks/useResourceIdentifier';
import {TaskCenterList} from '../../TaskCenterList/TaskCenterList';

interface TaskCenterContentProps extends RequiredTestIdProps {
  onClick?: () => void;
}

export function TaskCenterContent(props: TaskCenterContentProps) {
  const [canCreateTask, canNavigateToEmployeeDetail] = usePermissions({
    permissionKeys: ['createTask', 'employeeRead'],
  });

  const {data: userInfo} = useGetCurrentUserInfoQuery();
  const userId = userInfo?.id ?? '';

  const {queryParams: assignedToMeQueryParams} = useDataQuery('task', {
    filters: {
      assignedTo: [userId],
    },
    order: [
      {
        columnKey: 'createdAt',
        order: 'DESC',
      },
    ],
  });

  const {queryParams: reportedByMeQueryParams} = useDataQuery('task', {
    filters: {
      reportedBy: [userId],
    },
    order: [
      {
        columnKey: 'createdAt',
        order: 'DESC',
      },
    ],
  });

  const navigate = useNavigate();

  const {resourceId, resourceRecordId} = useResourceIdentifier();

  const {
    data: myTasks,
    isLoading: areMyTasksLoading,
    isError: hasMyTasksError,
  } = useGetMyTasksQuery();

  const {
    data: tasksByMe,
    isLoading: areTasksByMeLoading,
    isError: hasTasksByMeError,
  } = useGetTasksByMeQuery();

  const TabActions = buildArray<Action>().when(canCreateTask, {
    type: 'button',
    title: i18n.t('entity.task.actions.createTask'),
    variant: 'link',
    leftIcon: 'content/add_circle',
    onClick: () => {
      closeCurrentDialog();
      openCreateTaskFormDialog({
        'data-testid': 'task-center-create-task',
        relatedRecordId: resourceRecordId,
        resourceId,
        onCreate() {
          refreshDatagrid('task');
          if (resourceId) {
            if (resourceId === EntityResourceIds.businessCase) {
              refreshDatagrid('business-case-task');
            } else {
              refreshDatagrid(`${resourceId.toLowerCase()}-task`);
            }
          }
        },
      });
      props.onClick?.();
    },
  });

  const handleShowAllClick = (filter: PresetFilter) => {
    const params = {
      [PRESET_FILTERS.REPORTED_BY]: reportedByMeQueryParams,
      [PRESET_FILTERS.ASSIGNED_TO]: assignedToMeQueryParams,
    };
    navigate(
      composePath(taskManagerRoutes.taskOverview, {
        queryParams: params[filter],
      })
    );
    props.onClick?.();
  };

  const handleAssigneeClick = (user: GetUserApiResponse) => {
    navigate(
      composePath(employeeRoutes.detail, {
        params: {id: user.id},
      })
    );
  };

  const tabs = buildArray<TabProps>()
    .add({
      id: 'my-tasks',
      title: i18n.t('entity.task.labels.myTasks'),
      content: (
        <TaskCenterList
          data-testid={suffixTestId('myTasks', props)}
          list={myTasks ?? []}
          isLoading={areMyTasksLoading}
          isError={hasMyTasksError}
          onItemClick={(task) => {
            closeCurrentDialog();
            openEditTaskFormDialog({
              id: task.id,
              'data-testid': 'task-center-myTasks',
              relatedRecordId: resourceRecordId,
              onDelete() {
                refreshDatagrid('task');
              },
              onEdit() {
                refreshDatagrid('task');
              },
              resourceId,
            });
            props.onClick?.();
          }}
          shouldRenderShowAllButton
          hideAssignee
          onShowAllClick={() => handleShowAllClick(PRESET_FILTERS.ASSIGNED_TO)}
        />
      ),
      actions: TabActions,
    })
    .add({
      id: 'by-me-tasks',
      title: i18n.t('entity.task.labels.byMeTasks'),
      content: (
        <TaskCenterList
          data-testid={suffixTestId('byMeTasks', props)}
          list={tasksByMe ?? []}
          isLoading={areTasksByMeLoading}
          isError={hasTasksByMeError}
          onItemClick={(task) => {
            closeCurrentDialog();
            openEditTaskFormDialog({
              id: task.id,
              'data-testid': 'task-center-byMeTasks',
              relatedRecordId: resourceRecordId,
              onDelete() {
                refreshDatagrid('task');
              },
              onEdit() {
                refreshDatagrid('task');
              },
            });
            props.onClick?.();
          }}
          shouldRenderShowAllButton
          onAssigneeClick={
            isFeatureEnabled(featureFlags.ACL_EMPLOYEE_MANAGEMENT) && canNavigateToEmployeeDetail
              ? handleAssigneeClick
              : undefined
          }
          onShowAllClick={() => handleShowAllClick(PRESET_FILTERS.REPORTED_BY)}
        />
      ),
      actions: TabActions,
    });

  return <Tabs isFullHeight tabs={tabs} />;
}
