import {Button, Card, Separator} from 'platform/components';
import {Grid, GridItem, Right, Show, Space, VStack} from 'platform/foundation';
import {object} from 'yup';

import {FC, PropsWithChildren, SyntheticEvent, useMemo} from 'react';
import {FormSpy} from 'react-final-form';
import {useSelector} from 'react-redux';

import {isNotNil} from 'ramda';

import {cachedApi} from '@dms/api/cached';
import {
  AddressRequestBodyV2,
  AddressResponseBodyV2,
  ContractInformationRequestBody,
  ContractInformationResponseBody,
  PersonResponseBodyV2,
  useGetCustomerAddressListV2Query,
  useGetCustomerV2Query,
} from '@dms/api/customer';
import {additionalFieldFormType} from '@dms/api/shared';
import {selectTenant, useGetTenantQuery} from '@dms/api/tenant';
import i18n from '@dms/i18n';
import {getFormAdditionalField} from '@dms/shared';
import {
  $BankAccountData,
  $BankAccountRequestBody,
  $ContractInformationRequestBody,
  AutoSave,
  BankAccountData,
  BusinessInfoRequestBody,
  Form,
  getDefaultValuesFromSchema,
  LegalFormEnum,
  PersonRequestBody,
  PossibleObject,
  selectCurrencies,
} from '@dms/teas';

import {appendSuffix, Nullish, suffixTestId, TestIdProps} from 'shared';

import {PersonForm} from '../Person/PersonForm';
import {PersonSelect} from '../Person/PersonSelect';
import {BusinessInfoForm} from './BusinessInfoForm';

type BankAccountRequestBody = {
  id: string | null;
  bankAccountData: BankAccountData;
};

export type ContractInformationFormState = {
  person: PersonRequestBody | null;
  contractInformation: ContractInformationRequestBody;
  additionalFields: Partial<additionalFieldFormType>;
};

export type ContractInformationWrapperProps = {
  handleSubmit: (event?: SyntheticEvent) => Promise<PossibleObject> | undefined;
  handleDiscard?: () => void;
  loading?: boolean;
  index?: number;
};

export interface ContractInformationFormProps {
  customerId: string;
  contractInformation?: ContractInformationResponseBody;
  WrapperComponent?: FC<PropsWithChildren<ContractInformationWrapperProps>>;
  onSubmit: (requestBody: ContractInformationFormState) => Promise<Record<string, string> | void>;
  handleDiscard?: () => void;
  handlePersonSubmit: (
    requestBody: PersonRequestBody,
    personId: string | null
  ) => Promise<PersonResponseBodyV2 | null>;
  handleAddressSubmit: (
    addressData: AddressRequestBodyV2,
    addressId?: string | null
  ) => Promise<string | null>;
  loading?: boolean;
  compactSelect?: boolean;
  showIdentityCards?: boolean;
  showBankAccounts?: boolean;
}

export const ContractInformationForm: FC<
  PropsWithChildren<ContractInformationFormProps & TestIdProps>
> = ({
  contractInformation,
  WrapperComponent,
  onSubmit,
  handleDiscard,
  handlePersonSubmit,
  handleAddressSubmit,
  loading,
  compactSelect,
  showIdentityCards = true,
  showBankAccounts = true,
  customerId,
  ...rest
}) => {
  const {data: customer} = useGetCustomerV2Query({customerId});

  const schema = object({
    contractInformation: $ContractInformationRequestBody.defined(),
  });
  const currenciesList = useSelector(selectCurrencies);
  const {data: tenantData} = useGetTenantQuery();
  const selectBankLists = useMemo(
    () => cachedApi.endpoints.getBankList.select({countryCode: tenantData?.country}),
    [tenantData]
  );
  const {data: bankTypesList} = useSelector(selectBankLists);

  const {data: {country: tenantCountry, currency: defaultCurrency} = {}} =
    useSelector(selectTenant);

  const {data: addressList} = useGetCustomerAddressListV2Query({customerId});

  const isPolandTenant = tenantData?.country === 'POL';

  if (!contractInformation) {
    return null;
  }

  return (
    <Form<ContractInformationFormState, ContractInformationFormState>
      formId={appendSuffix('form', rest['data-testid'])}
      onSubmit={onSubmit}
      getFormValues={(values) => values}
      schema={schema}
      initialValues={getFormValues(contractInformation)}
      defaultValues={{
        contractInformation: {
          legalForm: LegalFormEnum.NATURAL_PERSON,
          bankAccounts: [{}],
        },
      }}
      render={(formRendererProps) => {
        const {
          Field,
          FieldArray,
          Condition,
          Subscribe,
          form: {
            mutators: {push},
          },
          handleSubmit,
        } = formRendererProps;
        const form = (
          <Grid columns={1}>
            <GridItem>
              {!contractInformation?.id && (
                <Grid columns={1}>
                  <Field
                    name="contractInformation.legalForm"
                    as="chips"
                    options={[
                      {
                        value: LegalFormEnum.NATURAL_PERSON,
                        label: i18n.t('entity.person.labels.physicalPerson'),
                      },
                      {
                        value: LegalFormEnum.SELF_EMPLOYED,
                        label: i18n.t('entity.customer.labels.isSelfEmployed'),
                      },
                      {
                        value: LegalFormEnum.LEGAL_ENTITY,
                        label: i18n.t('general.labels.company'),
                      },
                    ]}
                  />
                </Grid>
              )}

              <Grid columns={1}>
                <GridItem>
                  <Condition
                    when="contractInformation.legalForm"
                    not={LegalFormEnum.NATURAL_PERSON}
                  >
                    <Field<BusinessInfoRequestBody>
                      name="contractInformation.businessInfo"
                      component={({input}) => (
                        <Card variant="inlineGrey">
                          <BusinessInfoForm
                            businessInfo={input.value}
                            onSubmit={input.onChange}
                            handleAddressSubmit={handleAddressSubmit}
                            address={customer?.addresses}
                            saveOnChange
                            data-testid={rest['data-testid']}
                          />
                        </Card>
                      )}
                    />
                  </Condition>
                  <Condition when="contractInformation.legalForm" is={LegalFormEnum.NATURAL_PERSON}>
                    <>
                      {!contractInformation?.id && (
                        <Grid columns={1}>
                          <Card variant="inlineGrey">
                            <Subscribe
                              name="contractInformation.legalForm"
                              component={({input: {value}}) => (
                                <PersonSelect<ContractInformationFormState>
                                  name="contractInformation.personId"
                                  label={i18n.t('entity.party.labels.personId')}
                                  handlePersonSubmit={handlePersonSubmit}
                                  handleAddressSubmit={handleAddressSubmit}
                                  data-testid={rest['data-testid']}
                                  person={customer?.persons}
                                  required={
                                    (value as LegalFormEnum) === LegalFormEnum.NATURAL_PERSON
                                  }
                                />
                              )}
                            />
                          </Card>
                        </Grid>
                      )}

                      <FormSpy
                        subscription={{values: true}}
                        render={({values}: {values: ContractInformationFormState}) => (
                          <Field<PersonRequestBody>
                            name="person"
                            component={({input}) => (
                              <PersonForm
                                compactSelect={compactSelect}
                                showPersonData={!!contractInformation?.id}
                                showAddress={!!values.contractInformation.personId}
                                showIdentityCards={
                                  showIdentityCards && isNotNil(values.contractInformation.personId)
                                }
                                address={customer?.addresses}
                                initialValues={getPersonFormInitValues(
                                  values.person,
                                  contractInformation?.person?.id,
                                  addressList
                                )}
                                onChange={input.onChange}
                                handleAddressSubmit={handleAddressSubmit}
                                saveOnChange
                                data-testid={rest['data-testid']}
                              />
                            )}
                          />
                        )}
                      />
                    </>
                  </Condition>
                  <Space vertical={3} />
                </GridItem>
              </Grid>

              <Show when={isPolandTenant}>
                <Field
                  name="additionalFields.affiliatedEntity"
                  as="checkbox"
                  label={i18n.t('general.labels.affiliatedEntity')}
                />
              </Show>

              {showBankAccounts && (
                <Grid columns={1}>
                  <GridItem>
                    <FieldArray<BankAccountRequestBody> name="contractInformation.bankAccounts">
                      {({fields, Field}) =>
                        fields.map((name, i) => (
                          <Field<BankAccountData>
                            key={name}
                            name={[name, 'bankAccountData']}
                            component={({input}) => (
                              <>
                                <Separator spacing={0} />
                                <Space vertical={3} />

                                <Form<BankAccountData>
                                  formId={appendSuffix('form', rest['data-testid'])}
                                  onSubmit={onSubmit}
                                  initialValues={input.value}
                                  defaultValues={{
                                    countryCode: tenantCountry,
                                    currency: defaultCurrency,
                                  }}
                                  schema={$BankAccountData}
                                  render={(formRendererProps) => {
                                    const {Field: BankAccountField} = formRendererProps;

                                    return (
                                      <>
                                        <AutoSave save={input.onChange} saveOnChange />
                                        <Grid columns={2}>
                                          <BankAccountField
                                            name="ownerName"
                                            label={i18n.t('entity.bankAccount.labels.ownerName')}
                                          />
                                          <BankAccountField
                                            name="name"
                                            label={i18n.t('entity.bankAccount.labels.name')}
                                          />
                                        </Grid>
                                        <Grid columns={2}>
                                          <BankAccountField
                                            name="number"
                                            label={i18n.t('entity.bankAccount.labels.number')}
                                          />
                                          <BankAccountField
                                            as="select"
                                            name="bankCode"
                                            label={i18n.t('entity.bankAccount.labels.bankCode')}
                                            options={bankTypesList}
                                            getOptionLabel={(bankType) =>
                                              `${bankType.code} ${bankType.name}`
                                            }
                                            getOptionValue={(bankType) => `${bankType.code}`}
                                          />
                                        </Grid>
                                        <Grid columns={2}>
                                          <BankAccountField
                                            as="select"
                                            name="currency"
                                            label={i18n.t('entity.bankAccount.labels.currency')}
                                            options={currenciesList}
                                            getOptionLabel={(currency) => `${currency.name}`}
                                            getOptionValue={(currency) => `${currency.code}`}
                                          />
                                          <BankAccountField
                                            as="country-select"
                                            name="countryCode"
                                            label={i18n.t('entity.bankAccount.labels.countryCode')}
                                          />
                                        </Grid>
                                        <Grid columns={2}>
                                          <BankAccountField
                                            name="swiftBic"
                                            label={i18n.t('entity.bankAccount.labels.swiftBic')}
                                          />
                                          <BankAccountField
                                            name="iban"
                                            label={i18n.t('entity.bankAccount.labels.iban')}
                                          />
                                        </Grid>
                                      </>
                                    );
                                  }}
                                />

                                <VStack spacing={4}>
                                  {(fields.length ?? 0) > 1 && (
                                    <Button
                                      size="small"
                                      variant="dangerLink"
                                      leftIcon="action/delete"
                                      onClick={() => fields.remove(i)}
                                      data-testid={suffixTestId(`bankAccount-[${i}]-remove`, rest)}
                                      title={i18n.t(
                                        'entity.contractInformation.person.actions.removeBankAccount'
                                      )}
                                    />
                                  )}
                                </VStack>
                              </>
                            )}
                          />
                        ))
                      }
                    </FieldArray>
                    <Space vertical={2} />
                    <VStack spacing={4}>
                      <Button
                        variant="link"
                        size="small"
                        leftIcon="content/add_circle"
                        onClick={() =>
                          push(
                            'contractInformation.bankAccounts',
                            getDefaultValuesFromSchema($BankAccountRequestBody)
                          )
                        }
                        data-testid={suffixTestId(`bankAccount-add`, rest)}
                        title={i18n.t('general.customer.addBankAccount')}
                      />
                    </VStack>
                  </GridItem>
                </Grid>
              )}
            </GridItem>
          </Grid>
        );

        if (WrapperComponent) {
          return (
            <WrapperComponent {...formRendererProps} handleDiscard={handleDiscard}>
              {form}
            </WrapperComponent>
          );
        }

        return (
          <>
            {form}
            <Grid columns={1}>
              <Right>
                <Button
                  variant="primary"
                  isDisabled={loading}
                  onClick={handleSubmit}
                  data-testid={suffixTestId('save', rest)}
                  title={i18n.t('general.actions.save')}
                />
              </Right>
            </Grid>
          </>
        );
      }}
    />
  );
};

const getContractInformationRequestBody = (
  contractInformation: ContractInformationResponseBody
): ContractInformationRequestBody => ({
  bankAccounts: contractInformation.bankAccounts,
  businessInfo: contractInformation.businessInfo?.businessInfoData
    ? {
        businessAddressId: contractInformation.businessInfo.businessAddress?.id ?? null,
        businessInfoData: contractInformation.businessInfo.businessInfoData,
      }
    : null,
  legalForm: contractInformation.legalForm,
  personId: contractInformation.person?.id || null,
  customFieldsPayload: [],
});

const getFormValues = (
  contractInformation: ContractInformationResponseBody
): ContractInformationFormState => ({
  contractInformation: getContractInformationRequestBody(contractInformation),
  person: contractInformation.person
    ? {
        personData: contractInformation.person.personData,
        phoneNumbers: contractInformation.person.phoneNumbers ?? null,
        emails: contractInformation.person?.emails ?? null,
        identityCards:
          contractInformation.person.identityCards.map((identityCard) => ({
            id: identityCard.id,
            cardData: identityCard.identityCardData,
          })) ?? null,
        permanentAddressId: contractInformation.person.permanentAddress?.id ?? null,
      }
    : null,
  additionalFields: getFormAdditionalField(
    contractInformation.customFieldsPayload
  ) as Partial<additionalFieldFormType>,
});

const getPersonFormInitValues = (
  formValues: PersonRequestBody | Nullish,
  personId: string | Nullish,
  addressList: AddressResponseBodyV2[] | Nullish
): PersonResponseBodyV2 => ({
  id: personId ?? '',
  firstName: formValues?.personData?.firstName ?? null,
  lastName: formValues?.personData?.lastName ?? null,
  middleName: formValues?.personData?.middleName ?? null,
  titleBefore: formValues?.personData?.titleBefore ?? null,
  titleAfter: formValues?.personData?.titleAfter ?? null,
  genderKey: formValues?.personData?.genderKey ?? null,
  roles: formValues?.personData?.roles ?? null,
  citizenshipCode: formValues?.personData?.citizenshipCode ?? null,
  birthdate: formValues?.personData?.birthdate ?? null,
  personalIdentifier: formValues?.personData?.personalIdentifier ?? null,
  phoneNumbers:
    formValues?.phoneNumbers?.map((phoneNumber) => ({
      type: phoneNumber.type,
      countryCode: phoneNumber.phoneNumber?.countryCode,
      prefix: phoneNumber.phoneNumber?.prefix,
      number: phoneNumber.phoneNumber?.number,
    })) ?? [],
  emails: formValues?.emails ?? [],
  identityCards:
    formValues?.identityCards.map((identityCard) => ({
      id: identityCard.id ?? '',
      type: identityCard.cardData.type ?? null,
      cardNumber: identityCard.cardData.cardNumber,
      issuedOn: identityCard.cardData.issuedOn ?? null,
      validUntil: identityCard.cardData.validUntil ?? null,
      issuer: identityCard.cardData.issuer ?? null,
      issuedInCountryCode: identityCard.cardData.issuedInCountryCode ?? null,
      note: identityCard.cardData.note ?? null,
    })) ?? [],
  permanentAddress:
    addressList?.find((address) => address.id === formValues?.permanentAddressId) ?? null,
});
