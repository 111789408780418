import {HStack} from 'platform/foundation';

import {FC, use, useState} from 'react';

import {slice} from 'ramda';

import {useGetAssignmentsQuery} from '@dms/api/assignment';

import {TestIdProps} from 'shared';

import {AssignmentButton} from './AssignmentButton';
import {AssignmentContext} from './AssignmentContext';
import {AssignmentCreatePopup} from './AssignmentCreatePopup';
import {AssignOption} from './AssignOption';
import {AssignUsers} from './AssignUsers';

export const AssignmentContainer: FC<TestIdProps> = (props) => {
  const context = use(AssignmentContext);
  const [showCreatePopup, setCreatePopup] = useState(false);

  const {
    data: assignments = [],
    isFetching,
    isLoading,
  } = useGetAssignmentsQuery({
    assignmentIdentityType: context.type,
    assignmentIdentityId: context.assignmentIdentityId,
  });

  return (
    <>
      <HStack spacing={1}>
        {assignments.length > 0 && (
          <AssignUsers data-testid={props['data-testid']} assignment={assignments?.[0]} />
        )}
        {assignments.length > 1 && (
          <AssignOption
            data-testid={props['data-testid']}
            assignments={slice(1, Infinity, assignments)}
          />
        )}
        <AssignmentButton
          assignedUsers={assignments ?? []}
          onCreateExternalUserClick={() => setCreatePopup(true)}
          data-testid={props['data-testid']}
        />
      </HStack>
      {showCreatePopup && (
        <AssignmentCreatePopup
          data-testid={props['data-testid']}
          modalEvent={(value) => setCreatePopup(value ?? false)}
        />
      )}
    </>
  );
};
