import {ThemeColorPath} from 'platform/foundation';

import {omit} from 'ramda';

import {GetMyCalendarSelectedUsersApiResponse} from '@dms/api/calendar';
import {GetCurrentUserInfoApiResponse, UserResponseBody} from '@dms/api/shared';
import {EMPTY_PLACEHOLDER, getNaturalPersonFullName} from '@dms/shared';

import {Nullish} from 'shared';

import {getUserColorVariants} from './getUserColorVariants';

type GetSelectedCalendarUsersParams = {
  usersData: UserResponseBody[] | Nullish;
  selectedUsers: GetMyCalendarSelectedUsersApiResponse | Nullish;
  currentUserData: GetCurrentUserInfoApiResponse | Nullish;
};

export function getSelectedCalendarUsers(params: GetSelectedCalendarUsersParams) {
  if (!params.currentUserData) {
    return [];
  }

  const currentUserFromSelectedUsers = params.selectedUsers?.find(
    (user) => user.userId === params.currentUserData!.id
  );

  const currentUser = {
    ...omit(['settings', 'firstName', 'lastName'], params.currentUserData),
    fullName:
      getNaturalPersonFullName({
        firstName: params.currentUserData.firstName,
        lastName: params.currentUserData.lastName,
      }) || EMPTY_PLACEHOLDER,
    isHidden: !!currentUserFromSelectedUsers?.isHidden,
    color: getUserColorVariants(0).USER,
  };

  if (!params.usersData?.length || !params.selectedUsers?.length) {
    return [currentUser];
  }

  const selectedCalendarUsers = params.selectedUsers
    .map((selectedUser) => {
      const user = params.usersData!.find(
        (u) => u.id === selectedUser.userId && u.id !== currentUser.id && !u.blocked
      );

      if (!user) {
        return null;
      }

      return {
        ...omit(['firstName', 'lastName', 'blocked'], user),
        fullName:
          getNaturalPersonFullName({
            firstName: user.firstName,
            lastName: user.lastName,
          }) || EMPTY_PLACEHOLDER,
        isHidden: selectedUser.isHidden,
        color: selectedUser.color as ThemeColorPath,
      };
    })
    .filter(Boolean);

  return [currentUser, ...selectedCalendarUsers];
}
