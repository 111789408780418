import {z} from 'zod';

import {
  BaseIdSchema,
  BaseVoidResponseSchema,
  PriceWithAndWithoutVatSchema,
  BaseDirectSaleIdSchema,
  BaseDirectSaleMaterialItemSchema,
  BaseDirectSaleAssignMechanicsRequestSchema,
} from '@dms/api/shared';

/**
 * GET requests
 */
export const GetDirectSaleMaterialBasketItemsRequestSchema = BaseDirectSaleIdSchema;
export type GetDirectSaleMaterialBasketItemsRequest = z.infer<
  typeof GetDirectSaleMaterialBasketItemsRequestSchema
>;

export const GetDirectSaleMaterialBasketItemsResponseSchema = z.object({
  sparePartsBasketItem: z.array(BaseDirectSaleMaterialItemSchema),
  sparePartsBasketTotalPrice: PriceWithAndWithoutVatSchema,
  assignMechanicId: z.string().nullish(),
});
export type GetDirectSaleMaterialBasketItemsResponse = z.infer<
  typeof GetDirectSaleMaterialBasketItemsResponseSchema
>;

/**
 * POST requests
 */
export const PostDirectSaleMaterialItemsMechanicRequestSchema =
  BaseDirectSaleAssignMechanicsRequestSchema;
export type PostDirectSaleMaterialItemsMechanicRequest = z.infer<
  typeof PostDirectSaleMaterialItemsMechanicRequestSchema
>;

export const PostDirectSaleMaterialItemsMechanicResponseSchema = BaseVoidResponseSchema;
export type PostDirectSaleMaterialItemsMechanicResponse = z.infer<
  typeof PostDirectSaleMaterialItemsMechanicResponseSchema
>;

export const PostDirectSaleMaterialItemToBasketRequestSchema = z.object({
  directSaleId: z.string(),
  body: z.object({
    warehouseArticleId: z.string(),
  }),
});
export type PostDirectSaleMaterialItemToBasketRequest = z.infer<
  typeof PostDirectSaleMaterialItemToBasketRequestSchema
>;

export const PostDirectSaleMaterialItemToBasketResponseSchema = z.object({
  sparePartItem: BaseIdSchema,
});
export type PostDirectSaleMaterialItemToBasketResponse = z.infer<
  typeof PostDirectSaleMaterialItemToBasketResponseSchema
>;

/**
 * PATCH requests
 */
export const PatchDirectSaleMaterialBasketItemQuantityRequestSchema = z.object({
  directSaleId: z.string(),
  itemId: z.string(),
  body: z.object({
    quantity: z.number(),
  }),
});
export type PatchDirectSaleMaterialBasketItemQuantityRequest = z.infer<
  typeof PatchDirectSaleMaterialBasketItemQuantityRequestSchema
>;

export const PatchDirectSaleMaterialBasketItemQuantityResponseSchema = BaseVoidResponseSchema;
export type PatchDirectSaleMaterialBasketItemQuantityResponse = z.infer<
  typeof PatchDirectSaleMaterialBasketItemQuantityResponseSchema
>;

/**
 * DELETE requests
 */
export const DeleteDirectSaleMaterialBasketItemsRequestSchema = BaseDirectSaleIdSchema;
export type DeleteDirectSaleMaterialBasketItemsRequest = z.infer<
  typeof DeleteDirectSaleMaterialBasketItemsRequestSchema
>;

export const DeleteDirectSaleMaterialBasketItemsResponseSchema = BaseVoidResponseSchema;
export type DeleteDirectSaleMaterialBasketItemsResponse = z.infer<
  typeof DeleteDirectSaleMaterialBasketItemsResponseSchema
>;
