import {AnimatePresence, Variants, motion} from 'framer-motion';
import styled from 'styled-components';

import {ReactNode} from 'react';

interface CollapseProps {
  isOpen: boolean;
  children: ReactNode;
  onAnimationComplete?: () => void;
}

export function Collapse(props: CollapseProps) {
  return (
    <AnimatePresence initial={false}>
      {props.isOpen && (
        <StyledMotionContent
          onAnimationComplete={props.onAnimationComplete}
          variants={variants}
          animate="enter"
          initial="exit"
          exit="exit"
        >
          {props.children}
        </StyledMotionContent>
      )}
    </AnimatePresence>
  );
}

const ease = [0.25, 0.1, 0.25, 1];

const defaultTransitions = {
  exit: {
    height: {duration: 0.2, ease},
    opacity: {duration: 0.3, ease},
  },
  enter: {
    height: {duration: 0.2, ease},
    opacity: {duration: 0.4, ease},
  },
};

const variants: Variants = {
  exit: {
    height: 0,
    overflow: 'hidden',
    transition: defaultTransitions.exit,
  },
  enter: {
    height: 'auto',
    transition: defaultTransitions.enter,
  },
};

const StyledMotionContent = styled(motion.div)`
  overflow: hidden;
  display: block;
`;
