import {defaultTo, find} from 'ramda';

import {useChangeInterestBranchMutation, useGetInterestQuery} from '@dms/api/interest';
import {BranchResponseBody} from '@dms/api/shared';
import {useGetCurrentUserInfoQuery} from '@dms/api/user';

import {TestIdProps} from 'shared';

import {handleApiError} from '../../../utils/handleApiError';
import {BranchChanger} from './BranchChanger';

export interface InterestBranchProps extends TestIdProps {
  recordId: string;
}

export function InterestBranch(props: InterestBranchProps) {
  const {data: interest} = useGetInterestQuery({id: props.recordId});
  const {data: currentUserInfo} = useGetCurrentUserInfoQuery();

  const [changeBranch] = useChangeInterestBranchMutation();

  const handleBranchChange = (branchId: string) =>
    changeBranch({
      interestId: props.recordId,
      changeInterestBranchRequestBody: {
        branchId,
      },
    })
      .unwrap()
      .catch(handleApiError);

  const activeBranch = find(
    (branch: BranchResponseBody) => branch.id === interest?.branchId,
    defaultTo([], currentUserInfo?.branches)
  );

  return (
    <BranchChanger
      recordId={props.recordId}
      activeBranch={activeBranch}
      onChange={handleBranchChange}
      data-testid={props['data-testid']}
    />
  );
}
