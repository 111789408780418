import {isNotNil} from 'ramda-adjunct';

import {PaymentStateType} from '@dms/api/metadaErrorLogs';

import {Nullish} from 'shared';

const INVALID_PAYMENT_STATES: PaymentStateType[] = ['canceling', 'canceled'];

export const isCanceledOrCanceling = (paymentState: PaymentStateType | Nullish) =>
  isNotNil(paymentState) && INVALID_PAYMENT_STATES.includes(paymentState);
