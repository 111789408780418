import {openDeleteDialog, showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {useCallback} from 'react';
import {Helmet} from 'react-helmet-async';

import {path} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {
  useCancelCashRegisterDocumentMutation,
  useRenderExpenseCashReceiptDocumentMutation,
  useRenderIncomeCashReceiptDocumentMutation,
} from '@dms/api/accounting';
import i18n from '@dms/i18n';
import {accountingRoutes, testIds} from '@dms/routes';
import {downloadFile, handleApiError} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

export function CashReceiptsOverview() {
  const navigate = useNavigate();
  const [cancelDocument] = useCancelCashRegisterDocumentMutation();
  const [renderIncomeCashRegisterDocument] = useRenderIncomeCashReceiptDocumentMutation();
  const [renderExpenseCashRegisterDocument] = useRenderExpenseCashReceiptDocumentMutation();

  const handleCancelDocument = useCallback(
    (id: string, refreshData: () => void) => {
      const issuedAt = new Date().toISOString();
      cancelDocument({
        cashRegisterDocumentId: id,
        cancelCashRegisterDocumentRequestBody: {issuedAt},
      })
        .unwrap()
        .then(() => showNotification.success())
        .then(refreshData)
        .catch(handleApiError);
    },
    [cancelDocument]
  );

  const actionCallback = useCallback<ActionCallback>(
    ({actionKey, rowId, rowData, refreshData}) => {
      const id = isArray(rowId) ? rowId[0] : rowId;

      // in this case, we can use path, since data is type Record<string, unknown>;
      const typeKey = path<string>(['type', 'value', 'key'], rowData);
      const documentNumber = path<string>(['number', 'value'], rowData);

      const isIncome = typeKey === 'income';

      match(actionKey)
        .with('detail', () =>
          navigate(composePath(accountingRoutes.cashReceiptDetail, {params: {id}}))
        )
        .with('cancel', () =>
          openDeleteDialog({
            onConfirm: () => handleCancelDocument(id, refreshData),
            text: isIncome
              ? i18n.t('entity.cashRegister.notifications.cancelIncomeCashRegisterDocument', {
                  documentNumber,
                })
              : i18n.t('entity.cashRegister.notifications.cancelExpenseCashRegisterDocument', {
                  documentNumber,
                }),
          })
        )
        .with('download', async () => {
          const renderAction = isIncome
            ? renderIncomeCashRegisterDocument({
                renderIncomeCashReceiptDocumentRequestBody: {cashRegisterDocumentId: id},
              })
            : renderExpenseCashRegisterDocument({
                renderExpenseCashReceiptDocumentRequestBody: {cashRegisterDocumentId: id},
              });

          await renderAction
            .unwrap()
            .then((data) => data.pdfUrl)
            .then(downloadFile)
            .then(() => showNotification.success())
            .catch(handleApiError);
        })
        .otherwise(showNotification.error);
    },
    [
      handleCancelDocument,
      navigate,
      renderExpenseCashRegisterDocument,
      renderIncomeCashRegisterDocument,
    ]
  );

  return (
    <>
      <Helmet title={i18n.t('page.accounting.invoiceList.title')} />
      <DataGrid
        gridCode="cash-receipts"
        actionCallback={actionCallback}
        data-testid={testIds.accounting.cashReceiptsOverview('datagrid')}
      />
    </>
  );
}
