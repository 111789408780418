import {metadaApi} from '@dms/api/core';

import {
  DeleteManufacturerRequest,
  DeleteManufacturerResponse,
  GetManufacturerRequest,
  GetManufacturerResponse,
  GetManufacturersRequest,
  GetManufacturersResponse,
  ManufacturerBase,
  PatchManufacturerRequest,
  PatchManufacturerResponse,
  PostManufacturerRequest,
  PostManufacturerResponse,
} from './types';

export const metadaAdminManufacturerApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getManufacturers: build.query<GetManufacturersResponse, GetManufacturersRequest>({
      query: () => ({
        url: `admin/v1/omnetic-manufacturer`,
      }),
      providesTags: ['manufacturers'],
    }),
    getManufacturer: build.query<ManufacturerBase, GetManufacturerRequest>({
      query: (queryArg) => ({
        url: `admin/v1/omnetic-manufacturer/${queryArg.manufacturerId}`,
        method: 'GET',
      }),
      transformResponse: (response: GetManufacturerResponse) => response.manufacturer,
      providesTags: (result, error, queryArg) => [
        {type: 'manufacturers', id: queryArg.manufacturerId},
      ],
    }),
    postManufacturer: build.mutation<PostManufacturerResponse, PostManufacturerRequest>({
      query: (queryArg) => ({
        url: `admin/v1/omnetic-manufacturer`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['manufacturers'],
    }),
    patchManufacturer: build.mutation<PatchManufacturerResponse, PatchManufacturerRequest>({
      query: (queryArg) => ({
        url: `admin/v1/omnetic-manufacturer/${queryArg.manufacturerId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'manufacturers',
        {type: 'manufacturers', id: queryArg.manufacturerId ?? ''},
      ],
    }),
    deleteManufacturer: build.mutation<DeleteManufacturerResponse, DeleteManufacturerRequest>({
      query: (queryArg) => ({
        url: `admin/v1/omnetic-manufacturer/${queryArg.manufacturerId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['manufacturers'],
    }),
  }),
});

export const {
  useGetManufacturersQuery,
  useGetManufacturerQuery,
  useLazyGetManufacturerQuery,
  usePostManufacturerMutation,
  usePatchManufacturerMutation,
  useDeleteManufacturerMutation,
} = metadaAdminManufacturerApi;
