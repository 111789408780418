import {ButtonGroup, Form, FormButton, FormField, FormSubmitHandler} from 'platform/components';
import {Text, Show, VStack, Scroll} from 'platform/foundation';

import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {
  CreateTransactionPresetFormSchema,
  CreateTransactionPresetFormSchemaType,
} from './CreateTransactionPresetFormSchema';

interface CreateTransactionPresetProps extends RequiredTestIdProps {
  onSubmit: FormSubmitHandler<CreateTransactionPresetFormSchemaType>;
  vehicleId: string;
  list: {
    value: string;
    label: string;
  }[];
  label: string;
  onDiscard?: VoidFunction;
  helperText?: string;
}

export function CreateTransactionPresetForm(props: CreateTransactionPresetProps) {
  return (
    <Form<CreateTransactionPresetFormSchemaType>
      onSubmit={props.onSubmit}
      experimentalZodSchema={CreateTransactionPresetFormSchema}
    >
      {(control) => (
        <VStack spacing={4}>
          <FormField
            type="text"
            name="name"
            control={control}
            label={props.label}
            data-testid={suffixTestId('name', props)}
          />
          <Show when={props.helperText}>
            <Text size="small" color="tertiary">
              {props.helperText}
            </Text>
          </Show>
          <VStack>
            <Scroll maxHeight={100} auto>
              <FormField
                control={control}
                options={props.list}
                type="checkboxes"
                direction="vertical"
                name="transactionsIds"
                data-testid={suffixTestId('transactionsIds', props)}
              />
            </Scroll>
          </VStack>
          <ButtonGroup align="right">
            <Show when={props.onDiscard}>
              <FormButton
                variant="secondary"
                title={i18n.t('general.actions.discard')}
                onClick={props.onDiscard}
                control={control}
                data-testid={suffixTestId('discard', props)}
              />
            </Show>
            <FormButton
              type="submit"
              variant="primary"
              control={control}
              title={i18n.t('general.actions.save')}
              data-testid={suffixTestId('submit', props)}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}
