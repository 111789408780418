import {openDeleteDialog, showNotification} from 'platform/components';
import {Box, Text, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useNavigate} from 'react-router-dom';

import {useDeleteChecklistDefinitionMutation} from '@dms/api/checklist';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {catchUnhandledDataGridActions, handleApiError} from '@dms/shared';

import {composePath} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function Checklist() {
  const navigate = useNavigate();

  const [deleteChecklistDefinition] = useDeleteChecklistDefinitionMutation();

  const handleActionsCallback: ActionCallback = (action) => {
    const rowId = Array.isArray(action.rowId) ? action.rowId[0] : action.rowId;

    match(action.actionKey)
      .with('detail', 'edit', () =>
        navigate(composePath(settingsRoutes.generalChecklistDetail, {params: {id: rowId}}))
      )
      .with('delete', () => {
        openDeleteDialog({
          text: i18n.t('general.actions.confirmNeutralQuestion'),
          onConfirm: () => {
            deleteChecklistDefinition({definitionId: rowId})
              .unwrap()
              .then(() => {
                showNotification.success();
                action.refreshData();
              })
              .catch(handleApiError);
          },
        });
      })
      .otherwise(() => catchUnhandledDataGridActions(action.actionKey));
  };

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('page.settings.generalChecklist.title'),
        actions: [
          {
            type: 'button',
            title: i18n.t('page.settings.generalChecklist.actions.create'),
            to: settingsRoutes.generalChecklistCreate,
          },
        ],
      }}
      data-testid={testIds.settings.generalChecklist()}
    >
      <VStack spacing={6} height="100%">
        <Box maxWidth={200}>
          <Text size="small" color="secondary">
            {i18n.t('page.settings.generalChecklist.description')}
          </Text>
        </Box>
        <Box height="100%">
          <DataGrid
            data-testid={testIds.settings.generalChecklist('grid')}
            gridCode="checklist-definition"
            actionCallback={handleActionsCallback}
            autoHeight
          />
        </Box>
      </VStack>
    </SettingsTemplate>
  );
}
