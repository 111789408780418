import {omit} from 'ramda';

import {UserResponseBody} from '@dms/api/shared';
import {getNaturalPersonFullName} from '@dms/shared';

import {EMPTY_PLACEHOLDER, Nullish} from 'shared';

export function getKanbanUsersFromUsers(users: UserResponseBody[] | Nullish) {
  if (!users) {
    return null;
  }

  return users
    .filter((user) => !user.blocked)
    .map((user) => ({
      ...omit(['firstName', 'lastName', 'blocked'], user),
      fullName:
        getNaturalPersonFullName({
          firstName: user.firstName,
          lastName: user.lastName,
        }) || EMPTY_PLACEHOLDER,
      isHidden: false,
    }));
}
