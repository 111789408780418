import {useAnimatedPopper} from 'platform/components';
import {Box, Center, VStack} from 'platform/foundation';

import {useRef} from 'react';

import {MenuItemProps, UserSidebarIconSize} from '@dms/api/shared';

import {RequiredTestIdProps, suffixTestId, useOutsideClick} from 'shared';

import {useIsMobileNavigation} from '../hooks/useIsMobileNavigation';
import {MenuItemWithPin} from '../utils/addPinsToMenuItems';
import {flattenPinnedMenuItems} from '../utils/flattenPinnedMenuItems';
import {MenuItem} from './MenuItem';
import {NavigationButton} from './NavigationButton';
import {PinsMenuItem} from './PinsMenuItem';
import {ShowOnMobileNavigation} from './ShowOnMobileNavigation';
import {SidebarCreateEntityShortcut} from './SidebarCreateEntityShortcut';
import {SidebarToggleMenuIcon} from './SidebarToggleMenuIcon';

interface SidebarMenuProps extends RequiredTestIdProps {
  iconSize: UserSidebarIconSize;
  menuItemsWithPins: MenuItemWithPin<MenuItemProps>[];
  createMenuItems: MenuItemProps[];
  menuItemsById: Record<string, MenuItemProps>;
}

export function SidebarMenu(props: SidebarMenuProps) {
  const ref = useRef<HTMLDivElement>(null);
  const isMobileNavigation = useIsMobileNavigation();
  const {togglePopper, closePopper, referenceRef, popperProps, Popper, isOpen} = useAnimatedPopper({
    placement: isMobileNavigation ? 'bottom-start' : 'right-start',
    isOutsideClickIgnored: true,
  });

  const pinnedItems = flattenPinnedMenuItems(props.menuItemsWithPins);
  const navButtonHeight = ref.current?.getBoundingClientRect().height ?? 48;
  const popperContentMobileHeight = `calc(100vh - ${navButtonHeight}px)` as const;

  useOutsideClick({
    ref,
    enabled: isOpen,
    handler: () => {
      closePopper();
    },
  });

  return (
    <div ref={ref}>
      <Box ref={referenceRef} data-testid={suffixTestId('hamburgerButtonWrapper', props)}>
        <NavigationButton
          data-testid={suffixTestId('hamburgerButton', props)}
          isActive={isOpen}
          onClick={togglePopper}
        >
          <SidebarToggleMenuIcon iconSize={props.iconSize} isOpen={isOpen} />
        </NavigationButton>
      </Box>
      <Popper {...popperProps}>
        <Box
          overflow="hidden"
          width={['100vw', '100vw', 'fit-content']}
          height={[popperContentMobileHeight, popperContentMobileHeight, '100dvh']}
          backgroundColor="palettes.neutral.900.100"
          borderLeft={isMobileNavigation ? 'none' : '1px solid'}
          borderTop={isMobileNavigation ? '1px solid' : 'none'}
          borderColor="palettes.neutral.600.100"
        >
          <VStack height="100%" width={['100%', '100%', 75]}>
            <Box overflowY="auto">
              <ShowOnMobileNavigation>
                <PinsMenuItem
                  iconSize={props.iconSize}
                  content={pinnedItems.map((module) => (
                    <MenuItem
                      key={module.id}
                      iconSize={props.iconSize}
                      depth={1}
                      onClick={togglePopper}
                      {...module}
                    />
                  ))}
                />
              </ShowOnMobileNavigation>
              <Box padding={2} paddingBottom={2}>
                <VStack spacing={1}>
                  {props.menuItemsWithPins.map((module) => (
                    <MenuItem
                      key={module.id}
                      iconSize={props.iconSize}
                      depth={0}
                      onClick={togglePopper}
                      {...module}
                    />
                  ))}
                </VStack>
              </Box>
            </Box>
            <ShowOnMobileNavigation>
              <Box
                height={16}
                padding={2}
                borderBottom="1px solid"
                borderTop="1px solid"
                borderColor="palettes.neutral.600.100"
                backgroundColor="palettes.neutral.900.100"
              >
                <Center height="100%" width="100%">
                  <SidebarCreateEntityShortcut
                    iconSize={props.iconSize}
                    menuItems={props.createMenuItems}
                    menuItemsById={props.menuItemsById}
                    onSidebarClose={closePopper}
                  />
                </Center>
              </Box>
            </ShowOnMobileNavigation>
          </VStack>
        </Box>
      </Popper>
    </div>
  );
}
