import {DataStatus, Card, Attributes, AttributesRow} from 'platform/components';
import {Space} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {defaultTo, isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetCustomerV2Query} from '@dms/api/customer';
import {GetServiceOrderIssueNoteResponse} from '@dms/api/metadaWarehouseServiceOrderIssueNote';
import {useGetUserQuery} from '@dms/api/user';
import {useGetVehicleQuery} from '@dms/api/vehicle';
import i18n from '@dms/i18n';
import {workshopRoutes} from '@dms/routes';
import {getCustomerName, getUserName} from '@dms/shared';

import {composePath, EMPTY_PLACEHOLDER, parseDate, RequiredTestIdProps, suffixTestId} from 'shared';

interface GeneralInformationTabProps extends RequiredTestIdProps {
  serviceOrderIssueNote?: GetServiceOrderIssueNoteResponse;
}

export function GeneralInformationTab(props: GeneralInformationTabProps) {
  const formatDateTime = useDateTimeFormatter();

  const {data: issuedBy, isLoading: isIssuedByLoading} = useGetUserQuery(
    {
      id: props.serviceOrderIssueNote?.updatedBy as string,
    },
    {skip: isNilOrEmpty(props.serviceOrderIssueNote?.updatedBy)}
  );

  const {
    data: vehicle,
    isLoading: isVehicleLoading,
    isError: hasVehicleError,
  } = useGetVehicleQuery(
    {vehicleId: defaultTo('', props.serviceOrderIssueNote?.serviceOrder.vehicleId)},
    {skip: isNil(props.serviceOrderIssueNote?.serviceOrder.vehicleId)}
  );

  const {
    data: customer,
    isLoading: isCustomerLoading,
    isError: hasCustomerError,
  } = useGetCustomerV2Query(
    {customerId: defaultTo('', props.serviceOrderIssueNote?.serviceOrder.customerId)},
    {skip: isNil(props.serviceOrderIssueNote?.serviceOrder.customerId)}
  );

  const isLoading = isVehicleLoading || isCustomerLoading || isIssuedByLoading;

  const isError = hasVehicleError || hasCustomerError;

  const getCurrentDateAndTime = (date?: string) => {
    if (isNil(date)) {
      return EMPTY_PLACEHOLDER;
    }
    return formatDateTime('dateTimeShort', parseDate(date));
  };

  const handleViewOrderInNewTab = () => {
    window.open(
      composePath(workshopRoutes.serviceCaseDetail, {
        params: {id: props.serviceOrderIssueNote?.serviceCaseId},
      }),
      '_blank'
    );
  };

  const customerName = getCustomerName(customer);

  const basicInformationAttributes: AttributesRow[] = [
    {
      label: i18n.t('general.labels.issuedBy'),
      value: getUserName(issuedBy),
    },
    {
      label: i18n.t('general.labels.receivedBy'),
      value: props.serviceOrderIssueNote?.mechanic?.name,
    },
    {
      label: i18n.t('entity.warehouse.labels.dateAndTimeOfCreation'),
      value: getCurrentDateAndTime(props.serviceOrderIssueNote?.createdAt),
    },
    {
      label: i18n.t('entity.warehouse.labels.dateAndTimeOfIssue'),
      value: getCurrentDateAndTime(props.serviceOrderIssueNote?.updatedAt),
    },
  ];

  const serviceOrderAttributes: AttributesRow[] = [
    {
      label: i18n.t('entity.warehouse.labels.serviceOrderNumber'),
      value: props.serviceOrderIssueNote?.serviceOrder.number,
    },
    {
      label: i18n.t('entity.warehouse.labels.serviceOrderType'),
      value: props.serviceOrderIssueNote?.serviceOrder.type,
    },
    {
      label: i18n.t('entity.warehouse.labels.serviceOrderVariant'),
      value: props.serviceOrderIssueNote?.serviceOrder.variant,
    },
    {
      label: i18n.t('entity.warehouse.labels.customer'),
      value: customerName,
    },
    {
      label: i18n.t('entity.warehouse.labels.vehicle'),
      value: vehicle?.title,
    },
  ];

  return (
    <DataStatus isLoading={isLoading} isError={isError} minHeight={60}>
      <Card title={i18n.t('general.labels.basicInformation')}>
        <Attributes
          rows={basicInformationAttributes}
          size="quarter"
          data-testid={suffixTestId('basicInformationAttributes', props)}
        />

        <Space vertical={4} />

        <Card
          title={i18n.t('entity.warehouse.labels.serviceOrder')}
          variant="inlineGrey"
          actions={[
            {
              type: 'button',
              variant: 'link',
              leftIcon: 'action/launch',
              title: i18n.t('entity.warehouse.actions.viewOrder'),
              onClick: handleViewOrderInNewTab,
              'data-testid': suffixTestId('linkToServiceOrder', props),
            },
          ]}
        >
          <Attributes
            rows={serviceOrderAttributes}
            size="quarter"
            data-testid={suffixTestId('serviceOrderAttributes', props)}
          />
        </Card>
      </Card>
    </DataStatus>
  );
}
