import {createAction, ThunkAction, UnknownAction} from '@reduxjs/toolkit';

import {REHYDRATE_RTK_QUERY} from '../constants/REHYDRATE_RTK_QUERY';
import {loadFromIDB} from '../storage';
import {GenericRTKQState} from '../types/GenericRTKQState';
import {StateTransformer} from '../types/StateTransformer';
import {isRTKState} from '../utils/isRTKState';
import {sanitizeRtkQueryState} from '../utils/sanitizeRtkQueryState';

const rehydrateRtkQuery = createAction<{
  reducerPath: string;
  state: unknown;
}>(REHYDRATE_RTK_QUERY);

interface RehydrateConfig {
  reducerPaths: string[];
  transforms?: StateTransformer[];
  onError?: (error: unknown) => void;
}

/**
 * Creates a thunk that loads persisted data from IndexedDB
 * and dispatches actions to rehydrate the store
 */
export const createRehydrateThunk =
  (config: RehydrateConfig) =>
  (): ThunkAction<Promise<unknown>, GenericRTKQState, unknown, UnknownAction> =>
  (dispatch) => {
    const {reducerPaths, transforms = []} = config;

    return Promise.all(
      reducerPaths.map(async (reducerPath) => {
        try {
          const data = await loadFromIDB(reducerPath);

          if (!data || !isRTKState(data)) {
            return;
          }

          let state = data;
          for (const transform of transforms) {
            const deserialized = transform.deserialize(state);
            // If a transform returns undefined/null, stop the chain
            if (deserialized === undefined || deserialized === null) {
              return;
            }
            state = deserialized;
          }

          if (!state) {
            return;
          }

          state = sanitizeRtkQueryState(state);

          const result = dispatch(
            rehydrateRtkQuery({
              reducerPath,
              state,
            })
          );
          return result;
        } catch (error) {
          config.onError?.(error);
        }
      })
    );
  };
