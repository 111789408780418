import {Box, Hide, Icon, useDevice} from 'platform/foundation';
import {css, useTheme} from 'styled-components';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {SLOT_HEIGHT} from '../constants/slotHeight';
import {useIsDragging} from '../hooks/useIsDragging';

interface ContentCellProps extends RequiredTestIdProps {
  isClickDisabled: boolean;
  onCellClick: (event: React.MouseEvent) => void;
}

export function ContentCell(props: ContentCellProps) {
  const theme = useTheme();
  const device = useDevice();
  const isDragging = useIsDragging();

  const isDisabled = isDragging || props.isClickDisabled || device === 'mobile';

  return (
    <Box
      height={SLOT_HEIGHT}
      borderBottom="1px solid"
      borderLeft="1px solid"
      borderColor="general.separator"
    >
      <div
        css={css`
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0;
          will-change: opacity, background-color, cursor;
          cursor: ${!isDisabled ? 'pointer' : 'default'};
          &:hover {
            opacity: ${!isDisabled ? 1 : 0};
            background-color: ${!isDisabled
              ? theme.colors.palettes.neutral[20][100]
              : 'transparent'};
          }
        `}
        onClick={!isDisabled ? props.onCellClick : undefined}
        data-testid={suffixTestId('contentCell', props)}
      >
        <Hide when={isDisabled}>
          <Icon
            data-testid={suffixTestId('contentCell-icon', props)}
            value="lightbox/plus"
            size={4}
            color="text.tertiary"
          />
        </Hide>
      </div>
    </Box>
  );
}
