import {Button, TableCellBaseProps} from 'platform/components';
import {Box, Hide, HStack, Icon, Show, Text} from 'platform/foundation';

import {EMPTY_PLACEHOLDER} from 'shared';

import {TableCellType} from '../types/TableCellType';

interface DocumentCellProps extends TableCellBaseProps<TableCellType> {
  hasFile: boolean;
  fileRequired: Boolean;
  fileUrl: string;
  fileName: string;
}

export function DocumentCell(props: DocumentCellProps) {
  return (
    <Box paddingHorizontal={2} overflow="hidden">
      <HStack spacing={2} align="center">
        <Show when={props.hasFile}>
          <Button variant="link" title={props.fileName} size="small" />
        </Show>
        <Hide when={props.hasFile}>
          <Show when={props.fileRequired}>
            <Icon value="content/asterisk" size={3} color="severity.danger" />
          </Show>
          <Hide when={props.fileRequired}>
            <Text>{EMPTY_PLACEHOLDER}</Text>
          </Hide>
        </Hide>
      </HStack>
    </Box>
  );
}
