import {DataStatus, Separator} from 'platform/components';

import {GetDirectSaleResponse} from '@dms/api/metadaWarehouseDirectSale';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {RequestDetailSegmentForm} from './RequestDetailSegmentForm';

interface RequestDetailSegmentProps extends RequiredTestIdProps {
  directSale: GetDirectSaleResponse;
  isDirectSaleLoading: boolean;
  isEditingDisabled: boolean;
}

export function RequestDetailSegment(props: RequestDetailSegmentProps) {
  return (
    <DataStatus isLoading={props.isDirectSaleLoading}>
      <Separator />
      <RequestDetailSegmentForm
        directSale={props.directSale}
        isEditingDisabled={props.isEditingDisabled}
        data-testid={suffixTestId('form', props)}
      />
    </DataStatus>
  );
}
