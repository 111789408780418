import {Card, DataStatus, RadioItem} from 'platform/components';
import {Clickable, Heading, HStack, Show, Text, VStack} from 'platform/foundation';

import {filter, isNil, not} from 'ramda';
import {isNonEmptyArray} from 'ramda-adjunct';

import {useGetServiceOrderQuery} from '@dms/api/metadaWorkshopServiceOrder';
import {useGetServiceCaseOrderJobsQuery} from '@dms/api/metadaWorkshopServiceOrderJob';
import {useGetServiceOrderVariantQuery} from '@dms/api/metadaWorkshopServiceOrderVariant';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {SelectedJobType} from '../../types/SelectedJobType';

interface MoveRequestItemOrderProps extends TestIdProps {
  serviceCaseId: string;
  id: string | Nullish;
  selectedJob: SelectedJobType | Nullish;
  onJobSelect: (jobId: string | Nullish) => void;
  hiddenServiceJob?: string | null;
}

export function MoveRequestItemOrder(props: MoveRequestItemOrderProps) {
  const {
    data: serviceOrder,
    isLoading: isServiceOrderLoading,
    isError: isServiceOrderError,
  } = useGetServiceOrderQuery(
    {
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.id ?? '',
    },
    {skip: isNil(props.id)}
  );
  const {
    data: serviceOrderVariant,
    isLoading: isServiceOrderVariantLoading,
    isError: isServiceOrderVariantError,
  } = useGetServiceOrderVariantQuery(
    {serviceOrderVariantId: serviceOrder?.serviceOrderVariantId ?? ''},
    {skip: isNil(serviceOrder?.serviceOrderVariantId)}
  );
  const {
    data: serviceCaseOrderJobs,
    isLoading: isServiceCaseOrderJobsLoading,
    isError: isServiceCaseOrderJobsError,
  } = useGetServiceCaseOrderJobsQuery(
    {
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.id ?? '',
    },
    {skip: isNil(props.id)}
  );

  const serviceCaseOrderJobsToShow = filter(
    (job) => job?.id !== props.hiddenServiceJob && not(job?.servicePackageDetails?.isLock),
    serviceCaseOrderJobs ?? []
  );

  return (
    <Show when={isNonEmptyArray(serviceCaseOrderJobsToShow)}>
      <Card variant="inlineWhite">
        <DataStatus
          isLoading={isServiceOrderVariantLoading || isServiceOrderLoading}
          isError={isServiceOrderVariantError || isServiceOrderError}
          minHeight={41}
        >
          <VStack spacing={4}>
            <HStack spacing={2}>
              <Heading size={4} data-testid={suffixTestId('orderName', props)}>
                {serviceOrder?.name}
              </Heading>
              <Heading size={4}>•</Heading>
              <Text data-testid={suffixTestId('orderVariantName', props)}>
                {serviceOrderVariant?.name}
              </Text>
            </HStack>
            <DataStatus
              isLoading={isServiceCaseOrderJobsLoading}
              isError={isServiceCaseOrderJobsError}
              minHeight={31}
            >
              <VStack spacing={3}>
                {serviceCaseOrderJobsToShow?.map(
                  (job, index) =>
                    job && (
                      <Clickable
                        key={job.id}
                        onClick={() => props.onJobSelect(job.id)}
                        data-testid={suffixTestId(`job-[${index}]`, props)}
                      >
                        <Card variant="inlineGrey">
                          <HStack spacing={2}>
                            <RadioItem
                              value={
                                job.id === props.selectedJob?.jobId &&
                                serviceOrder?.id === props.selectedJob?.orderId
                              }
                              data-testid={suffixTestId(`job-[${index}]`, props)}
                            />
                            <Heading size={4} data-testid={suffixTestId(`job-[${index}]`, props)}>
                              {job.name}
                            </Heading>
                          </HStack>
                        </Card>
                      </Clickable>
                    )
                )}
              </VStack>
            </DataStatus>
          </VStack>
        </DataStatus>
      </Card>
    </Show>
  );
}
