import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {Grid, VStack, Text, Hide} from 'platform/foundation';
import {object} from 'yup';

import {useState} from 'react';

import {find, head} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {useGetTemplatesQuery} from '@dms/api/document';
import {useGetSaleVehicleQuery} from '@dms/api/saleVehicle';
import {FileResponseBody, TemplateListItemResponseBody} from '@dms/api/shared';
import {
  useGetSaleVehicleWarehouseInformationQuery,
  useLazyGetVehicleWarehouseMovementQuery,
  useReReceiveVehicleToVehicleWarehouseMutation,
} from '@dms/api/vehicleWarehouse';
import i18n from '@dms/i18n';

import {getApiDateString, suffixTestId, TestIdProps, yupString} from 'shared';

import {handleApiError} from '../../../utils/handleApiError';
import {DocumentTemplateBox} from '../../DocumentTemplateBox/DocumentTemplateBox';

interface VehicleWarehouseStockInStockOutProps extends TestIdProps {
  vehicleId: string;
  onDocumentsCreation: (documents: FileResponseBody[]) => void;
  onMovementComplete?: () => void;
  isNotDismissible?: boolean;
}

interface VehicleWarehouseRestockInFormFields {
  vehicleWarehouseId: string;
  movementAt: string;
  note: string | null;
}

const CANCEL_STOCK_IN_DOCUMENT_TEMPLATES_CODE = 'cancel-receive-vehicle-warehouse-movement';
const STOCK_IN_DOCUMENT_TEMPLATES_CODE = 'receive-vehicle-warehouse-movement';

export function VehicleWarehouseRestockIn(props: VehicleWarehouseStockInStockOutProps) {
  const [isStockInDocumentSelected, setIsStockInDocumentSelected] = useState(true);
  const [isCancelStockInDocumentSelected, setIsCancelStockInDocumentSelected] = useState(true);
  const [stockInDocumentTemplate, setStockInDocumentTemplate] =
    useState<TemplateListItemResponseBody>();
  const [cancelStockInDocumentTemplate, setCancelStockInDocumentTemplate] =
    useState<TemplateListItemResponseBody>();

  const {
    data: saleVehicle,
    isLoading: isSaleVehicleLoading,
    isError: hasSaleVehicleError,
  } = useGetSaleVehicleQuery({
    vehicleId: props.vehicleId,
  });

  const {
    data: warehouseInfo,
    isLoading: isWarehouseInfoLoading,
    isError: hasWarehouseInfoError,
  } = useGetSaleVehicleWarehouseInformationQuery(
    {
      vehicleId: props.vehicleId,
      saleVehicleId: saleVehicle?.id!,
    },
    {
      skip: isNilOrEmpty(saleVehicle),
    }
  );

  const currentWarehouse = saleVehicle?.vehicleWarehouse;

  const {
    data: stockInTemplates,
    isLoading: isStockInTemplatesLoading,
    isError: hasStockInTemplatesError,
  } = useGetTemplatesQuery(
    {
      documentKindCode: STOCK_IN_DOCUMENT_TEMPLATES_CODE,
    },
    {
      skip: isNilOrEmpty(currentWarehouse),
    }
  );

  const {
    data: cancelStockInTemplates,
    isLoading: isCancelStockInTemplatesLoading,
    isError: hasCancelStockInTemplatesError,
  } = useGetTemplatesQuery(
    {
      documentKindCode: CANCEL_STOCK_IN_DOCUMENT_TEMPLATES_CODE,
    },
    {
      skip: isNilOrEmpty(currentWarehouse),
    }
  );

  const [restockVehicle] = useReReceiveVehicleToVehicleWarehouseMutation();
  const [getMovement] = useLazyGetVehicleWarehouseMovementQuery();

  if (!stockInDocumentTemplate && isNotNilOrEmpty(stockInTemplates)) {
    setStockInDocumentTemplate(find((template) => template.primary, stockInTemplates ?? []));
  }

  if (!cancelStockInDocumentTemplate && isNotNilOrEmpty(cancelStockInTemplates)) {
    setCancelStockInDocumentTemplate(
      find((template) => template.primary, cancelStockInTemplates ?? [])
    );
  }

  const warehouseOptions = [
    {
      label: currentWarehouse?.name,
      value: currentWarehouse?.id,
    },
  ];

  const onSubmit: FormSubmitHandler<VehicleWarehouseRestockInFormFields> = (values) =>
    restockVehicle({
      vehicleId: props.vehicleId,
      reReceiveVehicleToVehicleWarehouseRequestBody: {
        vehicleWarehouseId: values.vehicleWarehouseId,
        receiveDate: values.movementAt,
        note: values.note,
        receiptCancelTemplateId: cancelStockInDocumentTemplate
          ? cancelStockInDocumentTemplate.id
          : undefined,
        receiptTemplateId: stockInDocumentTemplate ? stockInDocumentTemplate.id : undefined,
      },
    })
      .unwrap()
      .then((response) =>
        Promise.all([
          getMovement({
            vehicleWarehouseMovementId: response?.receiptMovementId,
          }).unwrap(),
          getMovement({
            vehicleWarehouseMovementId: response?.receiptCancelMovementId,
          }).unwrap(),
        ])
      )
      .then(([stockInReceiptResponse, cancelReceiptResponse]) => {
        stockInReceiptResponse.document &&
          cancelReceiptResponse.document &&
          props.onDocumentsCreation([
            cancelReceiptResponse.document,
            stockInReceiptResponse.document,
          ]);
      })
      .then(props.onMovementComplete)
      .catch(handleApiError);

  const defaultValues: Partial<VehicleWarehouseRestockInFormFields> = {
    vehicleWarehouseId: head(warehouseOptions)?.value,
    movementAt: warehouseInfo?.receivedAt ?? getApiDateString(new Date()),
  };

  const isLoading =
    isSaleVehicleLoading ||
    isWarehouseInfoLoading ||
    isStockInTemplatesLoading ||
    isCancelStockInTemplatesLoading;
  const isError =
    hasSaleVehicleError ||
    hasWarehouseInfoError ||
    hasStockInTemplatesError ||
    hasCancelStockInTemplatesError;

  return (
    <VStack spacing={4}>
      <DataStatus
        isLoading={isLoading}
        isError={isError}
        isEmpty={isNilOrEmpty(currentWarehouse)}
        emptyMessage={i18n.t('entity.vehicleWarehouse.labels.noWarehouseForBranch')}
      >
        <Form<VehicleWarehouseRestockInFormFields>
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          schema={schema}
        >
          {(control, formApi) => (
            <VStack spacing={4}>
              <Text>{i18n.t('entity.vehicleWarehouse.modal.restockAlert')}</Text>
              <Grid columns={2}>
                <FormField
                  label={i18n.t('entity.vehicleWarehouse.labels.warehouse')}
                  isDisabled
                  name="vehicleWarehouseId"
                  type="choice"
                  options={warehouseOptions}
                  control={control}
                  isNotClearable
                  isRequired
                  data-testid={suffixTestId('vehicleWarehouseId-select', props)}
                />
                <FormField
                  label={i18n.t('entity.vehicleWarehouse.labels.dateOfStockIn')}
                  name="movementAt"
                  type="apiDate"
                  control={control}
                  isRequired
                  data-testid={suffixTestId('movementAt', props)}
                />
              </Grid>
              <DocumentTemplateBox
                data-testid={suffixTestId('cancelStockIn-documentTemplateBox', props)}
                title={i18n.t('entity.document.labels.vehicleWarehouseCancelStockInDocument')}
                templates={cancelStockInTemplates}
                selectedTemplate={cancelStockInDocumentTemplate}
                isSelected={isCancelStockInDocumentSelected}
                onSelect={setIsCancelStockInDocumentSelected}
                onTemplateChange={setCancelStockInDocumentTemplate}
                isDisabled
              />
              <DocumentTemplateBox
                data-testid={suffixTestId('stockIn-documentTemplateBox', props)}
                title={i18n.t('entity.document.labels.vehicleWarehouseStockInDocument')}
                templates={stockInTemplates}
                selectedTemplate={stockInDocumentTemplate}
                isSelected={isStockInDocumentSelected}
                onSelect={setIsStockInDocumentSelected}
                onTemplateChange={setStockInDocumentTemplate}
                isWithNote
                isDisabled
                onNoteChange={(value) => formApi.setValue('note', value)}
                noteLabel={i18n.t('entity.document.labels.vehicleWarehouseStockInNote')}
              />
              <ButtonGroup align="right">
                <Hide when={props.isNotDismissible}>
                  <Button
                    title={i18n.t('general.actions.cancel')}
                    variant="secondary"
                    onClick={closeCurrentDialog}
                    data-testid={suffixTestId('cancel-button', props)}
                  />
                </Hide>
                <FormButton
                  title={i18n.t('general.actions.confirm')}
                  type="submit"
                  control={control}
                  data-testid={suffixTestId('submit-button', props)}
                />
              </ButtonGroup>
            </VStack>
          )}
        </Form>
      </DataStatus>
    </VStack>
  );
}

const schema = object({
  vehicleWarehouseId: yupString.required(),
  movementAt: yupString.required(),
  note: yupString,
});
