import {VehicleCatalogueEnumOption} from '../types/VehicleCatalogueEnumOption';
import {VehicleCatalogueItem} from '../types/VehicleCatalogueItem';
import {VehicleCatalogueProperties} from '../types/VehicleCatalogueProperties';
import {VehicleType} from '../types/VehicleType';

export const getEnumOption = (item: VehicleCatalogueItem): VehicleCatalogueEnumOption => ({
  label: item.labels[0]?.label || '',
  shortLabel: item.labels[0]?.label_short,
  value: item.const_key,
  categories:
    item.categories?.map((category) =>
      typeof category === 'string'
        ? category
        : {
            value: category.const_key,
            label: category.labels?.[0]?.label || '',
            shortLabel: category.labels?.[0]?.label_short || '',
          }
    ) || [],
  properties: item?.properties || [],
  children: [],
});

export const getEnumOptions = (
  items: VehicleCatalogueItem[],
  parent: string | null = null
): VehicleCatalogueEnumOption[] => {
  const result: VehicleCatalogueEnumOption[] = [];

  items
    .filter((item) => (!parent && item.parent === null) || item.parent === parent)
    .forEach((item) => {
      const option = getEnumOption(item);
      option.children = getEnumOptions(items, item.const_key);
      result.push(option);
    });

  return result;
};

export const getVehicleTypeEnumOptions = <P>(
  properties: VehicleCatalogueProperties<P>,
  vehicleType?: VehicleType | null
): Array<VehicleCatalogueEnumOption<P>> =>
  properties
    ?.filter(
      (vehicleCatalogueProperty) =>
        !vehicleType || vehicleCatalogueProperty.vehicleTypes.includes(vehicleType)
    )
    .reduce<Array<VehicleCatalogueEnumOption<P>>>(
      (
        acc: Array<VehicleCatalogueEnumOption<P>>,
        vehicleCatalogueProperty: {
          options: Array<VehicleCatalogueEnumOption<P>>;
          vehicleTypes: VehicleType[];
        }
      ) => [...acc, ...vehicleCatalogueProperty.options],
      []
    ) || [];
