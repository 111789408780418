import {ButtonGroup, showNotification} from 'platform/components';
import {Space, VStack} from 'platform/foundation';

import {useState} from 'react';

import {isNotNil} from 'ramda';

import {
  TreeFolderRelationTarget,
  usePutTreeFolderRelationsMutation,
} from '@dms/api/metadaTreeFolder';
import {TreeFolderContextTarget} from '@dms/api/shared';
import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {handleApiError} from '../../utils/handleApiError';
import {FolderTree} from '../FolderTree/FolderTree';
import {ActiveFolder} from './types/activeFolder';

interface MoveToFolderProps extends RequiredTestIdProps {
  catalogueIds: string[];
  contextTarget: TreeFolderContextTarget;
  relationTarget: TreeFolderRelationTarget;
  itemIds: string[];
  onClose: VoidFunction;
  onSubmitted?: VoidFunction;
}

export function MoveToFolder(props: MoveToFolderProps) {
  const [activeFolder, setActiveFolder] = useState<ActiveFolder>();

  const [putTreeFolderRelations] = usePutTreeFolderRelationsMutation();

  const onMove = () => {
    if (!activeFolder) {
      return;
    }

    putTreeFolderRelations({
      contextId: activeFolder.contextId,
      contextTarget: props.contextTarget,
      leafId: activeFolder.id,
      body: {
        relation: {
          target: props.relationTarget,
          ids: props.itemIds,
        },
      },
    })
      .unwrap()
      .then(props.onClose)
      .then(props.onSubmitted)
      .then(() =>
        showNotification.success(undefined, {'data-testid': suffixTestId('success', props)})
      )
      .catch(handleApiError);
  };

  return (
    <VStack spacing={1}>
      <FolderTree
        contextIds={props.catalogueIds}
        contextTarget={props.contextTarget}
        onFolderChange={(id, contextId) =>
          setActiveFolder(isNotNil(id) && isNotNil(contextId) ? {id, contextId} : undefined)
        }
        activeFolderId={activeFolder?.id}
        data-testid={suffixTestId('catalogueTree', props)}
      />
      <Space vertical={1} />
      <ButtonGroup
        align="right"
        buttons={[
          {
            title: i18n.t('general.actions.discard'),
            onClick: props.onClose,
            variant: 'secondary',
          },
          {
            title: i18n.t('entity.order.actions.moveHere'),
            onClick: onMove,
            isDisabled: !activeFolder,
            'data-testid': suffixTestId('move', props),
          },
        ]}
      />
    </VStack>
  );
}
