import {DataStatus, showNotification} from 'platform/components';

import {useDispatch} from 'react-redux';

import {useGetEmailConfigQuery, usePatchEmailConfigMutation} from '@dms/api/tenant';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';
import {setEmailConfig} from '@dms/teas';

import {buildArray} from 'shared';

import {
  SettingsStackItemProps,
  SettingsStackItems,
} from '../../../components/SettingsStackItems/SettingsStackItems';

export function EmailServices() {
  const dispatch = useDispatch();
  const {data: emailConfigData, isLoading} = useGetEmailConfigQuery();
  const [patchEmailConfig] = usePatchEmailConfigMutation();

  const handleSwitchChange = (enabled: boolean) => {
    dispatch(setEmailConfig({enabled}));
    patchEmailConfig({
      emailConfigRequestBody: {
        enabled,
      },
    })
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
      })
      .catch(handleApiError);
  };

  const items = buildArray<SettingsStackItemProps>([
    {
      cyId: testIds.settings.emailServicesSmtp('link'),
      title: i18n.t('page.integrations.labels.emailServerSMTP'),
      url: settingsRoutes.emailServicesSmtp,
      switchProps: {
        name: 'smtp-server',
        value: emailConfigData?.enabled ?? false,
        onChange: handleSwitchChange,
      },
    },
  ]);

  return (
    <DataStatus data={emailConfigData} isLoading={isLoading}>
      {() => <SettingsStackItems items={items} />}
    </DataStatus>
  );
}
