import i18n from 'i18next';
import {Dialog} from 'platform/components';
import {Text} from 'platform/foundation';

import {usePermissions} from '@dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {AuditData} from '../../../store/carAudit/reducerUtils';
import {useApplyAutoFillTemplate} from '../hooks/useApplyAutoFillTemplate';

interface AutoFillTemplateApplyDialogProps extends TestIdProps {
  audit: AuditData;
  template: {id: string; name: string};
  onClose: () => void;
}

export function AutoFillTemplateApplyDialog(props: AutoFillTemplateApplyDialogProps) {
  const [hasUpdateInspectionPermission] = usePermissions({
    permissionKeys: ['updateInspection'],
    scopes: {
      updateInspection: {inspectionType: props.audit?.inspectionType},
    },
  });

  const {applyAutoFillTemplate, isLoading} = useApplyAutoFillTemplate();

  const handleCloseDialog = () => {
    if (!isLoading) {
      props.onClose();
    }
  };

  const handleApplyTemplate = async () => {
    await applyAutoFillTemplate(props.template.id, props.audit);
    props.onClose();
  };

  if (!hasUpdateInspectionPermission) {
    return null;
  }

  return (
    <Dialog
      size="small"
      isOpen={true}
      onClose={handleCloseDialog}
      title={i18n.t('general.actions.autofillTemplates.confirmDialog.title')}
      data-testid={suffixTestId('confirmTemplateDialog', props)}
      buttons={[
        {
          title: i18n.t('general.actions.cancel'),
          variant: 'outlined',
          onClick: handleCloseDialog,
          isDisabled: isLoading,
          'data-testid': suffixTestId('cancelTemplate', props),
        },
        {
          title: i18n.t('general.actions.apply'),
          variant: 'primary',
          onClick: handleApplyTemplate,
          isLoading,
          'data-testid': suffixTestId('applyTemplate', props),
        },
      ]}
    >
      <Text>
        {i18n.t('general.actions.autofillTemplates.confirmDialog.message', {
          templateName: props.template.name,
        })}
      </Text>
    </Dialog>
  );
}
