import {Card, DataStatus} from 'platform/components';
import {VStack} from 'platform/foundation';

import {isNil} from 'ramda';

import {useGetPostponeJobsByVehicleIdQuery} from '@dms/api/metadaWorkshopPostponeJob';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {Main, queryParams, PostponedJob} from '@dms/shared';

import {useRequiredParams, useQueryState} from 'shared';

export function VehiclePostponedJobs() {
  const {id: vehicleId} = useRequiredParams();
  const {data, isLoading, isError} = useGetPostponeJobsByVehicleIdQuery({
    vehicleId,
  });
  const [jobId, setJobId, clearJobId] = useQueryState(queryParams.SERVICE_CASE_JOB_ID);

  const handleJobExpand = (id?: string | null) => {
    if (isNil(id)) {
      return;
    }
    if (jobId === id) {
      clearJobId();
    }
    if (jobId !== id) {
      setJobId(id);
    }
  };

  return (
    <Main data-testid={testIds.vehicles.postponedJobs('page')}>
      <Card title={i18n.t('entity.vehicle.labels.postponedJobs')}>
        <DataStatus
          isEmpty={data?.count === 0}
          isLoading={isLoading}
          isError={isError}
          emptyMessage={i18n.t('entity.orderRequest.labels.emptyPostponedRequests')}
        >
          <VStack spacing={4}>
            {data?.postponeJob?.map((job, index) => (
              <PostponedJob
                vehicleId={vehicleId}
                isExpanded={job.postponeJobId === jobId}
                onExpand={() => handleJobExpand(job.postponeJobId)}
                key={job.postponeJobId}
                postponeJobData={job}
                data-testid={testIds.vehicles.postponedJobs(`job-[${index}]`)}
              />
            ))}
          </VStack>
        </DataStatus>
      </Card>
    </Main>
  );
}
