/* eslint-disable eag/match-named-export */
import {isEmpty, union} from 'ramda';

import {
  AuditCategoryOfStructure,
  AuditDataVideoAssetsFilesBody,
  GetAutoFillTemplateApiResponse,
} from '@dms/api/carAudit';

import {getCategoryIdFromFormFieldName} from '../../components/VehicleInspection/utils/getCategoryIdFromFormFieldName';
import {AuditDataAssetsFilesBody} from '../../types/AuditDataAssetsFilesBody';
import {AuditCategoriesWithError, TConditionFormValue} from '../../types/ConditionTypes';
import {LoadAuditDataCategories} from '../../types/LoadAuditDataCategories';
import {LoadAuditDataResponseItemBody} from '../../types/LoadAuditDataResponseItemBody';
import {getFormFieldName} from '../../utils/getFormFieldName';
import {isJsonString} from '../../utils/isJsonString';

export type AuditFields = Record<string, TConditionFormValue>;
export type AuditAsset = Record<string, AuditDataAssetsFilesBody>;
export type AuditAssets = Record<string, AuditAsset>;
export type AuditVideoAsset = Record<string, AuditDataVideoAssetsFilesBody>;
export type AuditVideoAssets = Record<string, AuditVideoAsset>;
export type AuditAssetsCategoryMap = Record<string, string[]>;

export type AuditData = Omit<LoadAuditDataResponseItemBody, 'categories' | 'vehicleId'> & {
  fields: AuditFields;
  assets: AuditAssets;
  videoAssets: AuditVideoAssets;
  assetsCategoryMap: AuditAssetsCategoryMap;
  isNew?: boolean;
  errorCategories: AuditCategoriesWithError;
};

const getParsedValue = (value: string): TConditionFormValue => {
  if (isJsonString(value)) {
    const parsedValue = JSON.parse(value);
    if (typeof parsedValue === 'number') {
      return value;
    }

    return parsedValue as TConditionFormValue;
  }

  return value;
};

const getFieldsAndAssetsData = (
  auditCategoriesData: LoadAuditDataCategories[]
): {
  assets: AuditAssets;
  assetsCategoryMap: AuditAssetsCategoryMap;
  videoAssets: AuditVideoAssets;
  fields: AuditFields;
} => {
  const fields: AuditFields = {};
  const assets: AuditAssets = {};
  const videoAssets: AuditVideoAssets = {};
  const assetsCategoryMap: AuditAssetsCategoryMap = {};

  auditCategoriesData.forEach((category) => {
    category.fields.forEach(({paramDefinitionId, value}) => {
      fields[getFormFieldName(category.id, paramDefinitionId)] = getParsedValue(value);
    });

    category.assets.forEach(({paramDefinitionId, files}) => {
      files.forEach((file) => {
        const name = getFormFieldName(category.id, paramDefinitionId);

        if (!assets?.[name]) {
          assets[name] = {};
        }

        if (file.imageId) {
          assets[name][file.imageId] = file;
        }
        assetsCategoryMap[category.id] = union(assetsCategoryMap[category.id] ?? [], [name]);
      });
    });

    category.videoAssets.forEach(({paramDefinitionId, videos}) => {
      videos.forEach((video) => {
        const name = getFormFieldName(category.id, paramDefinitionId);

        if (!videoAssets?.[name]) {
          videoAssets[name] = {};
        }

        if (video.videoId) {
          videoAssets[name][video.videoId] = video;
        }
        assetsCategoryMap[category.id] = union(assetsCategoryMap[category.id] ?? [], [name]);
      });
    });
  });

  return {
    fields,
    assets,
    videoAssets,
    assetsCategoryMap,
  };
};

export const getInitialAuditData = (audit?: LoadAuditDataResponseItemBody): AuditData | null => {
  if (!audit) {
    return null;
  }

  const {
    id,
    state,
    user,
    finishedAt,
    controlStructureId,
    updateAt,
    inspectionType,
    inspectionNote,
  } = audit;
  const {fields, assets, videoAssets, assetsCategoryMap} = getFieldsAndAssetsData(audit.categories);

  return {
    id,
    state,
    user,
    finishedAt,
    controlStructureId,
    updateAt,
    inspectionType,
    inspectionNote,
    fields,
    assets,
    videoAssets,
    assetsCategoryMap,
    errorCategories: {},
  };
};

function setFieldsToEmpty(fields: AuditFields, doNotOverrideCategoriesKeys?: string[]) {
  return Object.keys(fields).reduce((acc, key) => {
    const {categoryId} = getCategoryIdFromFormFieldName(key);
    if (doNotOverrideCategoriesKeys && doNotOverrideCategoriesKeys.includes(categoryId)) {
      return acc;
    }
    return {...acc, [key]: ''};
  }, {} as AuditFields);
}

export function areAuditFieldsEmpty(auditFields: AuditData['fields']) {
  const isEmptyObject = isEmpty(auditFields);

  if (isEmptyObject) {
    return true;
  }

  return !Object.values(auditFields).some((value) => value !== '');
}

export const getAuditDataFromAutoFillTemplate = (
  actualAudit: AuditData,
  template: GetAutoFillTemplateApiResponse,
  options: {overrideFields?: boolean; categoryIdsNotToOverride?: string[]} = {}
) => {
  if (!template) {
    throw new Error('Template is required');
  }

  const {fields} = getFieldsAndAssetsData(template.categories);

  const currentFields = options.overrideFields
    ? setFieldsToEmpty(actualAudit.fields, options.categoryIdsNotToOverride)
    : actualAudit.fields;

  return {
    ...actualAudit,
    fields: {
      ...currentFields,
      ...fields,
    },
  };
};

export function recursiveGetAllCategoriesIds(categories: AuditCategoryOfStructure[]): string[] {
  return categories.flatMap((category) => [
    category.id,
    ...recursiveGetAllCategoriesIds(category.childCategories ?? []),
  ]);
}
