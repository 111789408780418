import {Module} from '@dms/api/shared';
import {moduleKeys} from '@dms/config';
import {featureFlags} from '@dms/feature-flags';
import {interestRoutes} from '@dms/routes';
import {permissions} from '@dms/shared';

import {InterestCreate} from './pages/InterestCreate/InterestCreate';
import {InterestDetail} from './pages/InterestDetail/InterestDetail';
import {InterestOverview} from './pages/InterestOverview/InterestOverview';

export const InterestModule: Module = {
  id: moduleKeys.interest,
  layoutProps: {
    title: 'page.interests.title',
    icon: 'content/next_week',
    iconV2: 'sidebar/interest',
  },
  routerProps: {
    path: interestRoutes.overview,
    element: <InterestOverview />,
  },
  requiredPermission: [permissions.businessCaseRead, permissions.interestRead],
  requiredFeatureFlag: featureFlags.ACL_SALES,
  content: [
    {
      id: 'interest-overview',
      routerProps: {
        path: interestRoutes.overview,
        element: <InterestOverview />,
      },
      layoutProps: {
        title: 'page.interests.title',
        icon: 'sidebar/interest',
      },
    },
    {
      id: 'interest-detail',
      routerProps: {
        path: interestRoutes.detail,
        element: <InterestDetail />,
      },
    },
    {
      id: 'interest-create',
      routerProps: {
        path: interestRoutes.create,
        element: <InterestCreate />,
      },
      requiredPermission: permissions.interestCreate,
      layoutProps: {
        title: 'entity.interest.actions.newInterest',
        isPartOfCreateMenuItem: true,
      },
    },
  ],
};
