import {BigCalendarEvent, BigCalendar} from 'platform/big-calendar';
import {DataStatus} from 'platform/components';

import {useEditTaskMutation} from '@dms/api/taskManagement';
import {openCreateTaskFormDialog, openEditTaskFormDialog} from '@dms/features/tasks';
import {testIds} from '@dms/routes';
import {usePermissions} from '@dms/shared';

import {getCreateTaskDefaultValues} from '../../../../utils/getCreateTaskDefaultValues';
import {useBigCalendarControl} from './hooks/useBigCalendarControl';
import {getBigCalendarDataFromGrid} from './utils/getBigCalendarDataFromGrid';
import {getTaskFromBigCalendarEvent} from './utils/getTaskFromBigCalendarEvent';

export function TaskCalendar() {
  const [canCreateTask] = usePermissions({
    permissionKeys: ['createTask'],
  });

  const control = useBigCalendarControl();

  const [editTask] = useEditTaskMutation();

  const onEventDragMove = async (event: BigCalendarEvent, start: Date, end: Date) => {
    const editTaskRequestBody = getTaskFromBigCalendarEvent({
      event,
      newStart: start,
      newEnd: end,
      dataGridData: control.tasks,
    });

    if (!editTaskRequestBody) {
      return;
    }
    // do not handle error here, let the big calendar handle it
    return await editTask({
      id: event.id,
      editTaskRequestBody,
    }).unwrap();
  };

  const onDateClick = (date: Date) => {
    if (!canCreateTask) {
      return null;
    }
    openCreateTaskFormDialog({
      'data-testid': testIds.taskManager.taskCalendar('createTaskDialog'),
      values: getCreateTaskDefaultValues(date),
    });
  };

  const onEventClick = (event: BigCalendarEvent) => {
    openEditTaskFormDialog({
      id: event.id,
      'data-testid': testIds.taskManager.taskCalendar('editTaskDialog'),
    });
  };

  return (
    <DataStatus
      data={control.currentUser ? control.selectedUsers : null}
      isLoading={control.isLoading}
      isError={control.isError}
      minHeight={150}
    >
      {({data}) => (
        <BigCalendar
          data-testid={testIds.taskManager.taskCalendar('tasksCalendar')}
          defaultView="week"
          events={getBigCalendarDataFromGrid(control.tasks, control.selectedUsers)}
          users={control.users}
          currentUser={control.currentUser!}
          selectedUsers={data}
          currentDate={control.selectedDate}
          onNavigate={control.onNavigate}
          onDateClick={onDateClick}
          onEventClick={onEventClick}
          onEventDragMove={onEventDragMove}
          onUserControlChange={control.onUserControlChange}
        />
      )}
    </DataStatus>
  );
}
