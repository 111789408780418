import {useLocation} from 'react-router-dom';

import {sourcingRoutes} from '@dms/routes';
import {useNavigationBreadCrumb} from '@dms/shared';

export function useIsPageNavigationVisible() {
  const location = useLocation();
  const [breadcrumbProps, isBreadcrumbVisible] = useNavigationBreadCrumb();
  const rowControlDetail = breadcrumbProps?.rowControlDetail;

  const isSourcingPage = location.pathname.includes(sourcingRoutes.home);

  return Boolean(isBreadcrumbVisible || rowControlDetail || isSourcingPage);
}
