import {z} from 'zod';

import {
  BaseVoidResponseSchema,
  OriginEntityHeaderTypeSchema,
  OriginEntityTypeSchema,
} from '@dms/api/shared';

const RequestItemSchema = z.object({
  originEntityId: z.string(),
  articleId: z.string(),
  quantity: z.number(),
  handlingUnit: z.string(),
  priceUnitWithoutVat: z.number(),
  priceUnitWithVat: z.number(),
});
export type RequestItem = z.infer<typeof RequestItemSchema>;

const RequestSchema = z.object({
  requestId: z.string(),
  state: z.string(),
  quantity: z.number(),
});

/**
 * GET
 */

/**
 * POST
 */
export const PostPartsRequestReturnItemRequestSchema = z.object({
  body: z.object({
    originEntityType: OriginEntityTypeSchema,
    originEntityHeaderId: z.string(),
    originEntityHeaderType: OriginEntityHeaderTypeSchema,
    requestItems: z.array(RequestItemSchema),
  }),
});
export type PostPartsRequestReturnItemRequest = z.infer<
  typeof PostPartsRequestReturnItemRequestSchema
>;

export const PostPartsRequestReturnItemResponseSchema = z.object({
  requestItems: z.array(
    z.object({
      articleId: z.string(),
      originEntityId: z.string(),
      requests: z.array(RequestSchema),
    })
  ),
});
export type PostPartsRequestReturnItemResponse = z.infer<
  typeof PostPartsRequestReturnItemResponseSchema
>;

/**
 * PATCH
 */

/**
 * DELETE
 */
export const DeletePartsRequestReturnItemsRequestSchema = z.object({
  originEntityHeaderId: z.string(),
  body: z.object({
    requestItemId: z.array(z.string()),
  }),
});
export type DeletePartsRequestReturnItemsRequest = z.infer<
  typeof DeletePartsRequestReturnItemsRequestSchema
>;

export const DeletePartsRequestReturnItemsResponseSchema = BaseVoidResponseSchema;
export type DeletePartsRequestReturnItemsResponse = z.infer<
  typeof DeletePartsRequestReturnItemsResponseSchema
>;

/**
 * PUT
 */
