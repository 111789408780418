import {Button, Dropdown, DropdownItem, DropdownSearchItem, Tooltip} from 'platform/components';
import styled from 'styled-components';
import {match} from 'ts-pattern';

import {use, useState} from 'react';

import {
  CreateAssignmentArgs,
  GetAssignmentsResponse,
  useCreateAssignmentMutation,
  useSearchAssignmentUsersQuery,
} from '@dms/api/assignment';
import i18n from '@dms/i18n';
import {getNaturalPersonFullName, handleApiError} from '@dms/shared';

import {suffixTestId, TestIdProps, useDebouncedValue} from 'shared';

import {AssignmentContext} from './AssignmentContext';

const RESULTS_LIMIT = 10;

interface AssignmentButtonProps extends TestIdProps {
  assignedUsers: GetAssignmentsResponse;
  onCreateExternalUserClick: () => void;
}

export function AssignmentButton(props: AssignmentButtonProps) {
  const assignmentContext = use(AssignmentContext);

  const [search, setSearch] = useState<string | null>(null);
  const debouncedSearch = useDebouncedValue(search, 500);

  const {
    data: users,
    isFetching,
    isLoading,
  } = useSearchAssignmentUsersQuery({
    search: debouncedSearch ?? undefined,
    assignmentIdentityType: assignmentContext.type,
    assignmentIdentityId: assignmentContext.assignmentIdentityId,
    external: assignmentContext.external ?? undefined,
    limit: RESULTS_LIMIT,
  });

  const [createAssignment] = useCreateAssignmentMutation();

  const isWaitingForDebounce = search !== debouncedSearch;
  const isLoadingData = isLoading || isFetching || isWaitingForDebounce;
  const canCreateExternalUser =
    assignmentContext.external === true || assignmentContext.external === null;
  const assignmentTooltipText = match(assignmentContext.type)
    .with('vehicle', () => i18n.t('general.labels.assignVehicleHeaderTooltip'))
    .otherwise(() => i18n.t('general.labels.assignTooltip'));

  const dropdownItemStatus = match({
    isLoading,
    isFetching,
    hasSearchPhrase: search !== null || debouncedSearch !== null,
    usersLength: users?.length,
  })
    .with({isLoading: true}, () => <DropdownItem label={i18n.t('general.labels.loading')} />)
    .with({hasSearchPhrase: true, usersLength: 0, isFetching: false}, () => (
      <DropdownItem isDisabled label={i18n.t('general.labels.noSearchResults')} />
    ))
    .with({hasSearchPhrase: false, usersLength: 0, isFetching: false}, () => (
      <DropdownItem isDisabled label={i18n.t('general.labels.noData')} />
    ))

    .otherwise(() => null);

  const handleAssignUser =
    (user: Pick<CreateAssignmentArgs, 'userId' | 'email' | 'firstName' | 'lastName'>) => () => {
      createAssignment({
        userId: user.userId,
        identity: {
          id: assignmentContext.assignmentIdentityId,
          type: assignmentContext.type,
        },
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
      })
        .unwrap()
        .catch(handleApiError);
    };

  return (
    <Dropdown
      hasSearch
      isHeightLimited
      isKeyboardNavigationDisabled={isLoading || isFetching}
      closeOnSelect={false}
      dropdownControl={
        <Tooltip placement="top" label={assignmentTooltipText}>
          <Button title={i18n.t('general.actions.assign')} variant="secondary" size="small" />
        </Tooltip>
      }
      data-testid={suffixTestId('assignButton', props)}
    >
      <DropdownSearchItem value={search} onChange={setSearch} />
      <LoadingContainer $isLoadingData={isLoadingData}>
        {dropdownItemStatus}
        {canCreateExternalUser && (
          <DropdownItem
            label={`+ ${i18n.t('general.labels.createExternalUser')}`}
            onClick={() => {
              props.onCreateExternalUserClick();
            }}
          />
        )}
        {users?.map((user) => {
          const isAssigned = props.assignedUsers?.some(
            (assignment) => assignment.user.id === user.id
          );

          if (isAssigned) {
            return null;
          }

          return (
            <DropdownItem
              key={user.id}
              label={getNaturalPersonFullName(user) || user.email || ''}
              onClick={handleAssignUser({
                userId: user.id,
                email: user.email ?? undefined,
                firstName: user.firstName ?? undefined,
                lastName: user.lastName ?? undefined,
              })}
            />
          );
        })}
      </LoadingContainer>
    </Dropdown>
  );
}

const LoadingContainer = styled.div<{$isLoadingData: boolean}>`
  opacity: ${({$isLoadingData}) => ($isLoadingData ? 0.5 : 1)};
  pointer-events: ${({$isLoadingData}) => ($isLoadingData ? 'none' : 'auto')};
`;
