import {Attributes} from 'platform/components';
import {Heading, VStack, Text} from 'platform/foundation';
import {useFormatPercentage} from 'platform/locale';

import {isNotNil} from 'ramda';

import {GetOrderProfitabilityApiResponse} from '@dms/api/metadaWorkshopServiceOrder';
import i18n from '@dms/i18n';
import {EMPTY_PLACEHOLDER, numberToTimeString} from '@dms/shared';

import {suffixTestId, RequiredTestIdProps} from 'shared';

import {getTimeString} from '../utils/getTimeString';

interface ProfitabilityWorkingTimeProps extends RequiredTestIdProps {
  profitabilityData: GetOrderProfitabilityApiResponse;
}

export function ProfitabilityWorkingTime(props: ProfitabilityWorkingTimeProps) {
  const formatPercentage = useFormatPercentage();

  return (
    <>
      <VStack spacing={1}>
        <Text size="xSmall" color="secondary" data-testid={suffixTestId('timeSold', props)}>
          {i18n.t('entity.order.labels.timeSold')}
        </Text>
        <Heading size={3}>
          {isNotNil(props.profitabilityData?.timeSold?.amount)
            ? getTimeString(numberToTimeString(props.profitabilityData.timeSold.amount))
            : EMPTY_PLACEHOLDER}
        </Heading>
      </VStack>
      <Attributes
        rows={[
          {
            label: i18n.t('entity.order.labels.estiomatedTime'),
            value: props.profitabilityData?.timePlanned?.amount
              ? getTimeString(numberToTimeString(props.profitabilityData?.timePlanned?.amount))
              : null,
          },
          {
            label: i18n.t('entity.order.labels.timeTracked'),
            value: props.profitabilityData?.timeTracked?.amount
              ? getTimeString(numberToTimeString(props.profitabilityData?.timeTracked?.amount))
              : null,
          },
          {
            label: i18n.t('entity.order.labels.timeSold'),
            value: props.profitabilityData?.timeSold?.amount
              ? getTimeString(numberToTimeString(props.profitabilityData?.timeSold?.amount))
              : null,
          },
          {
            label: i18n.t('entity.order.labels.planningEfficency'),
            value: props.profitabilityData?.timePlanningEfficiency
              ? formatPercentage(props.profitabilityData?.timePlanningEfficiency, 'nearest')
              : null,
          },
          {
            label: i18n.t('entity.order.labels.performance'),
            value: props.profitabilityData?.timePerformance
              ? formatPercentage(props.profitabilityData?.timePerformance, 'nearest')
              : null,
          },
        ]}
        data-testid={suffixTestId('data', props)}
      />
    </>
  );
}
