import {Box, Heading} from 'platform/foundation';

import {useMemo} from 'react';
import {useSelector} from 'react-redux';

import i18n from '@dms/i18n';
import {FeaturesPicker} from '@dms/shared';
import {
  BasicCategoryOfStructure,
  selectFeatureCategories,
  selectVehicleFeatures,
  useFormRenderer,
} from '@dms/teas';

import {isDefined} from 'shared';

import {AuditStructureSettingsForm} from '../types';
import {getEquipmentUniqueKeyToIdDictionary} from '../utils/utils';

interface AuditSettingsFeaturesProps {
  category: BasicCategoryOfStructure;
}
/**
 * Component for selecting features in audit settings
 * It combines data from audit-settings and catalogue EP to display features picker
 */
export function AuditSettingsFeatures(props: AuditSettingsFeaturesProps) {
  const featureCategories = useSelector(selectFeatureCategories('VEHICLETYPE_PASSENGER_CAR'));
  const features = useSelector(selectVehicleFeatures('VEHICLETYPE_PASSENGER_CAR'));
  const {
    form: {
      mutators: {setFieldValue},
      getState,
    },
  } = useFormRenderer<AuditStructureSettingsForm>();

  const uniqueKeyToIdDictionary = useMemo(
    () => getEquipmentUniqueKeyToIdDictionary(props.category),
    [props.category]
  );

  const mappedFeatures = useMemo(
    () =>
      features
        .map((feature) => ({
          ...feature,
          // only main features are visible for checking
          children: [],
          value: uniqueKeyToIdDictionary[feature.value],
        }))
        // catalogue features contain all of the structure features and moore, which needs to be filtered
        .filter((feature) => isDefined(feature.value)),
    [features, uniqueKeyToIdDictionary]
  );

  return (
    <>
      <Box paddingBottom={3}>
        <Heading size={4} color="primary">
          {i18n.t('general.labels.condition.listOfFeatures')}
        </Heading>
      </Box>
      <FeaturesPicker
        categories={featureCategories}
        features={mappedFeatures}
        selected={getState().values.equipmentValues || []}
        onFeatureSelect={(selectedFeatures) => {
          setFieldValue(`equipmentValues`, selectedFeatures);
        }}
      />
    </>
  );
}
