import {FormControl, FormField} from 'platform/components';
import {Box, HStack, Show, Text, VStack} from 'platform/foundation';

import {Path, FieldValues} from 'react-hook-form';

import {toNumber} from 'ramda-adjunct';

import i18n from '@dms/i18n';

import {CurrencyCodeType} from 'shared';

import {useFormValues} from '../../../hooks/useFormValues';
import {calculateDailyInterestCost} from '../utils/calculateDailyInterestCost';
import {calculateInterestRecurringCosts} from '../utils/calculateInterestRecurringCosts';
import {calculateSimpleRecurringCosts} from '../utils/calculateSimpleRecurringCosts';
import {CalculationType, CoercedMoney} from '../VehicleTransactionFormSchema';
import {AmountDisplay} from './AmountDisplay';

export type RecurringTransactionFields = {
  startDate: string | null;
  endDate: string | null;
  realPrice: {
    withoutVat?: CoercedMoney;
    withVat?: CoercedMoney;
  } | null;
  interestPA: number | null;
  calculationType?: [CalculationType] | null;
};

export interface RecurringTransactionSubformProps<
  T extends FieldValues & RecurringTransactionFields,
> {
  control: FormControl<T>;
  currency: CurrencyCodeType;
}

export function RecurringTransactionSubform<T extends FieldValues & RecurringTransactionFields>(
  props: RecurringTransactionSubformProps<T>
) {
  const {startDate, endDate, realPrice, calculationType, interestPA} =
    useFormValues<RecurringTransactionFields>([
      'startDate',
      'endDate',
      'realPrice',
      'calculationType',
      'interestPA',
    ]);

  return (
    <Box
      backgroundColor="palettes.neutral.10.100"
      padding={4}
      border="1px solid"
      borderColor="palettes.neutral.40.100"
      borderRadius="small"
    >
      <VStack spacing={4}>
        <Text color="tertiary" size="xSmall">
          {i18n.t('entity.vehicle.labels.recurringTransaction')}
        </Text>
        <FormField
          type="hidden"
          name={'realPrice.withVat.amount' as Path<T>}
          control={props.control}
        />
        <HStack spacing={4} width="100%">
          <Box flexShrink={0} flexGrow={1}>
            <FormField<T>
              type="apiDate"
              label={i18n.t('entity.vehicle.labels.startDate')}
              name={'startDate' as Path<T>}
              control={props.control}
              isRequired
            />
          </Box>
          <Box flexShrink={0} flexGrow={1}>
            <FormField<T>
              type="apiDate"
              label={i18n.t('entity.vehicle.labels.endDate')}
              name={'endDate' as Path<T>}
              isRequired
              control={props.control}
            />
          </Box>
        </HStack>
        <FormField
          type="chips"
          name={'calculationType' as Path<T>}
          label={i18n.t('entity.vehicle.labels.calculationType')}
          control={props.control}
          options={
            [
              {label: i18n.t('entity.vehicle.labels.simpleTransaction'), value: 'SIMPLE'},
              {label: i18n.t('entity.vehicle.labels.calculatedTransaction'), value: 'CALCULATED'},
            ] satisfies {label: string; value: CalculationType}[]
          }
        />
        <Show when={calculationType?.[0] === 'SIMPLE'}>
          <HStack spacing={4} width="100%" justify="space-between">
            <Box flexGrow={1} width="50%">
              <FormField
                control={props.control}
                type="currency"
                name={'realPrice.withoutVat.amount' as Path<T>}
                label={i18n.t('entity.vehicle.labels.baseValueWithoutVAT')}
                currency={props.currency}
              />
            </Box>
            <Box flexGrow={1} width="50%">
              <AmountDisplay
                label={i18n.t('entity.vehicle.labels.currentAmountWithoutVat')}
                amount={calculateSimpleRecurringCosts(
                  endDate ?? new Date().toString(),
                  startDate ?? new Date().toString(),
                  toNumber(realPrice?.withoutVat?.amount ?? 0)
                )}
                currency={props.currency}
              />
            </Box>
          </HStack>
        </Show>
        <Show when={calculationType?.[0] === 'CALCULATED'}>
          <HStack spacing={4}>
            <Box flexGrow={1}>
              <FormField
                control={props.control}
                type="currency"
                name={'realPrice.withoutVat.amount' as Path<T>}
                label={i18n.t('entity.vehicle.labels.realPriceWithoutVAT')}
                currency={props.currency}
              />
            </Box>
            <Box flexGrow={1}>
              <FormField
                control={props.control}
                type="number"
                name={'interestPA' as Path<T>}
                label={i18n.t('entity.vehicle.labels.interestPA')}
              />
            </Box>
            <Box flexGrow={1}>
              <AmountDisplay
                label={i18n.t('entity.vehicle.labels.dailyInterestCost')}
                amount={calculateDailyInterestCost(
                  toNumber(realPrice?.withoutVat?.amount ?? 0),
                  interestPA ?? 0
                )}
                currency={props.currency}
              />
            </Box>
            <Box flexGrow={1}>
              <AmountDisplay
                label={i18n.t('entity.vehicle.labels.currentAmountWithoutVat')}
                amount={calculateInterestRecurringCosts(
                  endDate ?? new Date().toString(),
                  startDate ?? new Date().toString(),
                  toNumber(realPrice?.withoutVat?.amount ?? 0),
                  interestPA ?? 0
                )}
                currency={props.currency}
              />
            </Box>
          </HStack>
        </Show>
      </VStack>
    </Box>
  );
}
