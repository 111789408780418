import {Box, Heading, HStack} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {useCallback} from 'react';

import {defaultTo, mergeAll} from 'ramda';

import {GetIssueNoteCorrectionResponse} from '@dms/api/metadaWarehouseIssueNoteCorrection';
import i18n from '@dms/i18n';
import {DEFAULT_CURRENCY} from '@dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {DataGrid, QueryFilterObject} from 'features/datagrid';

import {WAREHOUSE_GRID_CODES} from '../../../../../constants/warehouseGridCodes';
import {useWarehouseParams} from '../../../../../hooks/useWarehouseParams';

interface ListOfItemsTabProps extends RequiredTestIdProps {
  issueNoteCorrection: GetIssueNoteCorrectionResponse | Nullish;
}

export function ListOfItemsTab(props: ListOfItemsTabProps) {
  const {issueNoteCorrectionId} = useWarehouseParams();

  const formatCurrency = useFormatCurrency();

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {issueNoteCorrectionId}]),
    [issueNoteCorrectionId]
  );

  const totalValue = formatCurrency(
    defaultTo(0, props.issueNoteCorrection?.summaryInformation.totalValue.amount),
    defaultTo(DEFAULT_CURRENCY, props.issueNoteCorrection?.summaryInformation.totalValue.currency),
    2
  );

  return (
    <>
      <Box height="95%">
        <DataGrid
          gridCode={WAREHOUSE_GRID_CODES.warehouseIssueNoteCorrectionItems}
          queryModifier={queryModifier}
          data-testid={suffixTestId('issuedItems', props)}
        />
      </Box>
      <HStack height={10} align="center" justify="space-between">
        <Heading size={5}>{i18n.t('general.labels.totalValue')}</Heading>
        <Heading size={5}>{totalValue}</Heading>
      </HStack>
    </>
  );
}
