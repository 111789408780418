import {match} from 'ts-pattern';

import {always} from 'ramda';

import {useGetCustomFieldsPayloadQuery} from '@dms/api/customFields';
import {EntityResourceIds, TypedAnyValue} from '@dms/api/shared';

import {parseDate} from 'shared';

import {isCurrency} from '../utils/isCurrency';

type GetDefaultValuesProps = {
  resourceId: EntityResourceIds;
  recordId: string;
};

export const useGetDefaultValues = (props: GetDefaultValuesProps) => {
  const {data: payload, isLoading, isError} = useGetCustomFieldsPayloadQuery(props);

  const getDefaultValues = () => {
    const defaultValues: Record<string, unknown> = {};

    payload?.forEach((item) => {
      defaultValues[item.definitionId] = formatDefaultInputValue(item.value);
    });

    return defaultValues;
  };

  return [getDefaultValues, {isLoading, isError}] as const;
};

const formatDefaultInputValue = (value: TypedAnyValue) =>
  match(value.type)
    .with('boolean', always(value.value?.toString()))
    .with('date', always(parseDate(value.value)))
    .with('datetime', always(parseDate(value.value)))
    .with('money', always(isCurrency(value.value) ? value.value.amount : null))
    .otherwise(always(value.value));
