import {Actions, Button, showNotification} from 'platform/components';
import {Image, Box, Heading, HStack, Right, Space, Text, VStack} from 'platform/foundation';

import {isEmpty, isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {useRecommendBusinessCaseCustomerMutation} from '@dms/api/businessCase';
import {useCarkPostTipMutation} from '@dms/api/metadaInsuranceNew';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {useBoolean} from 'shared';

import {InsuranceData} from '../hooks/useInsuranceData';
import {isInsuranceVehicleV2} from '../utils/isInsuranceVehicleV2';

interface RecommendCustomerFormProps {
  businessCaseId: string;
  insuranceData: InsuranceData;
  onCancel: VoidFunction;
  onDone: VoidFunction;
}

export function RecommendCustomerForm(props: RecommendCustomerFormProps) {
  const [isSent, showConfirmation] = useBoolean();

  const [setCustomerAsTipped, {isLoading: isTipMarkLoading}] =
    useRecommendBusinessCaseCustomerMutation();
  const [postCustomerTip, {isLoading: isTipLoading}] = useCarkPostTipMutation();

  const sendRecommendation = () => {
    if (isNilOrEmpty(props.insuranceData.insuranceVehicle)) {
      return showNotification.error(i18n.t('entity.insurance.errors.noVehicleSelected'));
    }
    if (isNilOrEmpty(props.insuranceData.insurancePerson?.phoneNumber)) {
      return showNotification.error(i18n.t('entity.insurance.errors.phoneNumberIsRequired'));
    }
    if (isNil(props.insuranceData.insurerNumber) || isEmpty(props.insuranceData.insurerNumber)) {
      return showNotification.error('User is not insurer');
    }

    if (!isInsuranceVehicleV2(props.insuranceData.insuranceVehicle)) {
      return showNotification.error('Vehicle is not valid');
    }

    postCustomerTip({
      body: {
        vehicle: props.insuranceData.insuranceVehicle,
        insurerNumber: props.insuranceData.insurerNumber,
        insuranceDraftId: props.businessCaseId,
        tenantId: props.insuranceData.tenantInfo.id,
        person: props.insuranceData.insurancePerson,
      },
    })
      .unwrap()
      .then(() => {
        setCustomerAsTipped({businessCaseId: props.businessCaseId}).unwrap().then(showConfirmation);
      })
      .catch(handleApiError);
  };

  if (isSent) {
    return (
      <HStack spacing={6} justify="stretch" align="stretch">
        <Box padding={6} backgroundColor="palettes.green.10.100">
          <Image
            width={58}
            src="assets/images/insurance_wallet.png"
            data-testid={testIds.businessCase?.customer('recommendationSent')}
          />
        </Box>
        <VStack spacing={6}>
          <Heading size={3}>{i18n.t('entity.insurance.labels.thanksForRecommendation')}</Heading>
          <Text size="small">{i18n.t('entity.insurance.labels.receivedInfo')}</Text>
          <Text size="xxSmall" color="secondary">
            {i18n.t('entity.insurance.labels.notice')}
          </Text>
          <Space fillAvailable />
          <Right>
            <Button
              title={i18n.t('general.actions.close')}
              onClick={props.onDone}
              data-testid={testIds.businessCase?.customer('closeButton')}
            />
          </Right>
        </VStack>
      </HStack>
    );
  }

  return (
    <HStack spacing={6} justify="stretch" align="stretch">
      <Box padding={6} backgroundColor="palettes.neutral.20.100">
        <Image
          width={58}
          src="assets/images/insurance_notebook.png"
          data-testid={testIds.businessCase?.customer('recommendInsuranceImage')}
        />
      </Box>
      <VStack>
        <Heading size={3}>{i18n.t('entity.insurance.actions.recommendCustomer')}</Heading>
        <Space vertical={6} />
        <Text size="small">{i18n.t('entity.insurance.labels.consent')}</Text>
        <Space vertical={6} />
        <Text size="xSmall" color="secondary">
          {i18n.t('entity.insurance.labels.handoveredInfo')}
        </Text>

        <Text size="small" alternative>
          {props.insuranceData.personName}
        </Text>
        <Text size="small" alternative>
          {props.insuranceData.insurancePerson?.phoneNumber ||
            i18n.t('entity.insurance.labels.phoneNotProvided')}
        </Text>
        <Text size="small" alternative>
          {props.insuranceData.insurancePerson?.email ||
            i18n.t('entity.insurance.labels.emailNotProvided')}
        </Text>

        <Space vertical={6} />
        <Text size="xxSmall" color="secondary">
          {i18n.t('entity.insurance.labels.notice')}
        </Text>

        <Space vertical={6} />
        <Space fillAvailable />

        <Actions
          align="right"
          data-testid={testIds.businessCase?.customer('recommendInsuranceActions')}
          actions={[
            {
              type: 'button',
              title: i18n.t('general.actions.cancel'),
              onClick: props.onCancel,
              variant: 'secondary',
              isDisabled: isTipLoading || isTipMarkLoading,
            },
            {
              type: 'button',
              title: i18n.t('general.actions.confirm'),
              onClick: sendRecommendation,
              isLoading: isTipLoading || isTipMarkLoading,
            },
          ]}
        />
      </VStack>
    </HStack>
  );
}
