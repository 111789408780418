import {format, isSameDay, addDays, Day, startOfWeek} from 'date-fns';
import {
  Box,
  getSize,
  Grid,
  GridItem,
  Heading,
  Show,
  Text,
  VStack,
  Hide,
  getValueByDevice,
  useDevice,
} from 'platform/foundation';
import {convertStringLocaleToDateFns, DayOfWeek, useLocale} from 'platform/locale';
import {useTheme, css} from 'styled-components';
import {match} from 'ts-pattern';

import {useMemo, useRef, useEffect} from 'react';

import {always} from 'ramda';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {MAX_DAY_WIDTH} from '../constants/maxDayWidth';
import {useActiveId} from '../hooks/useActiveId';
import {useDnd} from '../hooks/useDnd';
import {useIsDragging} from '../hooks/useIsDragging';
import {BigCalendarEvent} from '../types/BigCalendarEvent';
import {calculateAllDayEventsHeight} from '../utils/calculateAllDayEventsHeight';
import {calculateTimePosition} from '../utils/calculateTimePosition';
import {getEventsForDate} from '../utils/getEventsForDate';
import {AllDayEventItem} from './AllDayEventItem';
import {CurrentTimeIndicator} from './CurrentTimeIndicator';
import {DroppableCell} from './DroppableCell';
import {EventItem} from './EventItem';
import {TimeSlot} from './TimeSlot';

interface WeekViewProps extends RequiredTestIdProps {
  currentDate: Date;
  events: BigCalendarEvent[];
  isReadOnly?: boolean;
  onEventClick?: (event: BigCalendarEvent) => void;
  onDateClick?: (date: Date) => void;
}

export function WeekView(props: WeekViewProps) {
  const theme = useTheme();
  const device = useDevice();

  const {language, localeConfig} = useLocale();
  const {activeDnDId} = useDnd();

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const allDayHeaderRef = useRef<HTMLDivElement>(null);

  const isDragging = useIsDragging();
  const activeId = useActiveId();

  const weekStart = startOfWeek(props.currentDate, {
    weekStartsOn: match<DayOfWeek, Day>(localeConfig.calendar.firstDayOfTheWeek)
      .with(DayOfWeek.MONDAY, always(1))
      .with(DayOfWeek.TUESDAY, always(2))
      .with(DayOfWeek.WEDNESDAY, always(3))
      .with(DayOfWeek.THURSDAY, always(4))
      .with(DayOfWeek.FRIDAY, always(5))
      .with(DayOfWeek.SATURDAY, always(6))
      .with(DayOfWeek.SUNDAY, always(0))
      .otherwise(always(1)),
  });

  const weekDays = Array.from({length: 7}, (_, day) => addDays(weekStart, day));
  const timeSlots = Array.from({length: 24}, (_, slot) => slot);

  const draggedEvent = props.events.find(
    (event) => event.id === activeId || event.id === activeDnDId
  );

  const allDayHeight = useMemo(
    () => calculateAllDayEventsHeight(props.events, weekDays),
    [props.events, weekDays]
  );

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    const header = headerRef.current;
    const allDayHeader = allDayHeaderRef.current;

    if (!scrollContainer || !header || !allDayHeader) {
      return;
    }

    let isSyncing = false;
    let touchMoveFrame: number | null = null;

    const syncScroll = (scrollLeft: number) => {
      if (isSyncing) {
        return;
      }
      isSyncing = true;

      requestAnimationFrame(() => {
        if (header) {
          header.scrollLeft = scrollLeft;
        }
        if (allDayHeader) {
          allDayHeader.scrollLeft = scrollLeft;
        }
        isSyncing = false;
      });
    };

    const handleScroll = () => syncScroll(scrollContainer.scrollLeft);

    const handleTouchMove = () => {
      if (touchMoveFrame) {
        cancelAnimationFrame(touchMoveFrame);
      }
      touchMoveFrame = requestAnimationFrame(() => {
        syncScroll(scrollContainer.scrollLeft);
      });
    };

    syncScroll(scrollContainer.scrollLeft);

    scrollContainer.addEventListener('scroll', handleScroll, {passive: true});
    scrollContainer.addEventListener('touchmove', handleTouchMove, {passive: true});
    scrollContainer.addEventListener('touchend', handleScroll, {passive: true});

    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
      scrollContainer.removeEventListener('touchmove', handleTouchMove);
      scrollContainer.removeEventListener('touchend', handleScroll);
      if (touchMoveFrame) {
        cancelAnimationFrame(touchMoveFrame);
      }
    };
  }, []);

  return (
    <Box position="relative" width="100%" height="100%">
      <div
        ref={headerRef}
        css={css`
          position: sticky;
          top: ${getSize(getValueByDevice(device, 10, 15))};
          z-index: ${theme.zIndices.BIG_CALENDAR_HEADER};
          background-color: ${theme.colors.palettes.neutral[10][100]};
          overflow: hidden;
        `}
      >
        <Grid columns={20} spacing={0}>
          <GridItem span={[3, 1]}>
            <Box height={[12, 15]} borderBottom="1px solid" borderColor="general.separator" />
          </GridItem>

          <GridItem span={[17, 19]}>
            <Grid columns={7} spacing={0}>
              {weekDays.map((date, dateIndex) => (
                <Box
                  key={`header-${date.toISOString()}`}
                  height={[12, 15]}
                  minWidth={MAX_DAY_WIDTH}
                  maxWidth={getValueByDevice(device, MAX_DAY_WIDTH, undefined)}
                  borderBottom="1px solid"
                  borderLeft="1px solid"
                  borderColor="general.separator"
                  paddingHorizontal={2}
                >
                  <VStack height="100%" align="flex-start" justify="center">
                    <Show onMobile>
                      <Heading
                        data-testid={suffixTestId(`weekView-dayHeader-[${dateIndex}]`, props)}
                        size={4}
                        color={isSameDay(date, new Date()) ? 'warning' : undefined}
                      >
                        {format(date, 'E', {locale: convertStringLocaleToDateFns(language)})}
                      </Heading>
                    </Show>
                    <Hide onMobile>
                      <Heading
                        size={4}
                        data-testid={suffixTestId(`weekView-dayHeader-[${dateIndex}]`, props)}
                      >
                        {format(date, 'EEEE', {locale: convertStringLocaleToDateFns(language)})}
                      </Heading>
                    </Hide>
                    <Text
                      data-testid={suffixTestId(`weekView-dayHeader-[${dateIndex}]`, props)}
                      color={isSameDay(date, new Date()) ? 'warning' : 'tertiary'}
                      alternative={isSameDay(date, new Date())}
                    >
                      {format(date, 'd', {locale: convertStringLocaleToDateFns(language)})}
                    </Text>
                  </VStack>
                </Box>
              ))}
            </Grid>
          </GridItem>
        </Grid>
      </div>

      <div
        ref={allDayHeaderRef}
        css={css`
          position: sticky;
          top: ${getSize(getValueByDevice(device, 22, 30))};
          z-index: ${theme.zIndices.BIG_CALENDAR_STICKY_ALLDAY_SLOT};
          background-color: ${theme.colors.palettes.neutral[10][100]};
          overflow: hidden;
        `}
      >
        <Grid columns={20} spacing={0}>
          <GridItem span={[3, 1]}>
            <div
              css={css`
                width: 100%;
                height: ${allDayHeight}px;
                border-bottom: ${allDayHeight
                  ? `1px solid ${theme.colors.general.separator}`
                  : 'none'};
              `}
            />
          </GridItem>
          <GridItem span={[17, 19]}>
            <Grid columns={7} spacing={0}>
              {weekDays.map((date, dateIndex) => (
                <div
                  key={date.toISOString()}
                  css={css`
                    position: relative;
                    min-width: ${getSize(MAX_DAY_WIDTH)};
                    max-width: ${getValueByDevice(device, MAX_DAY_WIDTH, undefined)};
                    height: ${allDayHeight}px;
                    border-left: ${allDayHeight > 0
                      ? `1px solid ${theme.colors.general.separator}`
                      : 'none'};
                    border-bottom: ${allDayHeight > 0
                      ? `1px solid ${theme.colors.general.separator}`
                      : 'none'};
                    z-index: ${theme.zIndices.BIG_CALENDAR_STICKY_ALLDAY_SLOT};
                  `}
                >
                  {getEventsForDate(props.events, date).allDayEvents.map((event, index) => {
                    if (isDragging && event.id === activeId) {
                      return null;
                    }

                    return (
                      <AllDayEventItem
                        data-testid={suffixTestId(`weekView-[${dateIndex}]-[${index}]`, props)}
                        key={event.id}
                        event={event}
                        position={event.allDayPosition}
                        onClick={props.onEventClick}
                      />
                    );
                  })}
                </div>
              ))}
            </Grid>
          </GridItem>
        </Grid>
      </div>

      <div
        ref={scrollContainerRef}
        data-testid={props['data-testid']}
        css={css`
          display: grid;
          grid-template-columns: repeat(20, 1fr);
          gap: 0 0;
          overflow: ${isDragging ? 'hidden' : 'auto'};
          -webkit-overflow-scrolling: touch;
        `}
      >
        <GridItem span={[3, 1]}>
          {timeSlots.map((hour) => (
            <TimeSlot
              data-testid={suffixTestId(`weekView-[${hour}]`, props)}
              key={hour}
              hour={hour}
            />
          ))}
        </GridItem>

        <GridItem span={[17, 19]}>
          <Grid columns={7} spacing={0}>
            {weekDays.map((date, dateIndex) => (
              <Box
                minWidth={MAX_DAY_WIDTH}
                maxWidth={getValueByDevice(device, MAX_DAY_WIDTH, undefined)}
                key={date.toISOString()}
                position="relative"
              >
                {timeSlots.map((hour) => (
                  <DroppableCell
                    key={hour}
                    data-testid={suffixTestId(`weekView-[${dateIndex}]-[${hour}]`, props)}
                    date={date}
                    hour={hour}
                    draggedEvent={draggedEvent}
                    onDateClick={props.onDateClick}
                  />
                ))}
                <div
                  css={css`
                    position: absolute;
                    top: 0;
                    left: 4px;
                    right: 3px;
                    pointer-events: none;
                  `}
                >
                  {isSameDay(date, new Date()) ? (
                    <CurrentTimeIndicator data-testid={suffixTestId('weekView', props)} />
                  ) : null}

                  {getEventsForDate(props.events, date).regularEvents.map((event, index) => {
                    if (isDragging && event.id === activeId) {
                      return null;
                    }

                    return (
                      <EventItem
                        key={event.id}
                        data-testid={suffixTestId(`weekView-[${dateIndex}]-[${index}]`, props)}
                        isOnWeekView
                        event={event}
                        top={calculateTimePosition(event.start)}
                        onClick={props.onEventClick}
                      />
                    );
                  })}
                </div>
              </Box>
            ))}
          </Grid>
        </GridItem>
      </div>
    </Box>
  );
}
