import {metadaApi} from '@dms/api/core';

import {
  BulkDeleteDeliveryNoteRequest,
  BulkDeleteDeliveryNoteRequestSchema,
  BulkDeleteDeliveryNoteResponse,
  BulkDeleteDeliveryNoteResponseSchema,
  BulkPatchDeliveryNoteRequest,
  BulkPatchDeliveryNoteRequestSchema,
  BulkPatchDeliveryNoteResponse,
  BulkPatchDeliveryNoteResponseSchema,
  BulkPostReceiveNoteRequest,
  BulkPostReceiveNoteRequestSchema,
  BulkPostReceiveNoteResponse,
  BulkPostReceiveNoteResponseSchema,
  GetDeliveryNoteManualDownloadRequest,
  GetDeliveryNoteManualDownloadRequestSchema,
  GetDeliveryNoteManualDownloadResponse,
  GetDeliveryNoteManualDownloadResponseSchema,
  GetDeliveryNoteRequest,
  GetDeliveryNoteRequestSchema,
  GetDeliveryNoteResponse,
  GetDeliveryNoteResponseSchema,
  PatchDeliveryNoteRequest,
  PatchDeliveryNoteRequestSchema,
  PatchDeliveryNoteResponse,
  PatchDeliveryNoteResponseSchema,
} from './types';

export const metadaWarehouseDeliveryNoteApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getDeliveryNote: build.query<GetDeliveryNoteResponse, GetDeliveryNoteRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/delivery-note/${queryArg.deliveryNoteId}`,
        method: 'GET',
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'deliveryNote', id: queryArg.deliveryNoteId},
      ],
      extraOptions: {
        requestSchema: GetDeliveryNoteRequestSchema,
        responseSchema: GetDeliveryNoteResponseSchema,
      },
    }),
    patchDeliveryNote: build.mutation<PatchDeliveryNoteResponse, PatchDeliveryNoteRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/delivery-note/${queryArg.deliveryNoteId}/manual`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'deliveryNote', id: queryArg.deliveryNoteId},
      ],
      extraOptions: {
        requestSchema: PatchDeliveryNoteRequestSchema,
        responseSchema: PatchDeliveryNoteResponseSchema,
      },
    }),
    getDeliveryNoteManualDownload: build.query<
      GetDeliveryNoteManualDownloadResponse,
      GetDeliveryNoteManualDownloadRequest
    >({
      query: (queryArg) => ({
        url: 'warehouse/v1/delivery-note/manual-download',
        params: queryArg.params,
      }),
      extraOptions: {
        requestSchema: GetDeliveryNoteManualDownloadRequestSchema,
        responseSchema: GetDeliveryNoteManualDownloadResponseSchema,
      },
    }),
    bulkDeleteDeliveryNote: build.mutation<
      BulkDeleteDeliveryNoteResponse,
      BulkDeleteDeliveryNoteRequest
    >({
      query: (queryArg) => ({
        url: 'warehouse/v1/delivery-note/bulk-delete',
        method: 'PATCH',
        body: queryArg,
      }),
      extraOptions: {
        requestSchema: BulkDeleteDeliveryNoteRequestSchema,
        responseSchema: BulkDeleteDeliveryNoteResponseSchema,
      },
    }),
    bulkPostReceiveNote: build.mutation<BulkPostReceiveNoteResponse, BulkPostReceiveNoteRequest>({
      query: (queryArg) => ({
        url: 'warehouse/v1/delivery-note/bulk-create-receive-note',
        method: 'POST',
        body: queryArg,
      }),
      extraOptions: {
        requestSchema: BulkPostReceiveNoteRequestSchema,
        responseSchema: BulkPostReceiveNoteResponseSchema,
      },
    }),
    bulkPatchDeliveryNote: build.mutation<
      BulkPatchDeliveryNoteResponse,
      BulkPatchDeliveryNoteRequest
    >({
      query: (queryArg) => ({
        url: 'warehouse/v1/delivery-note/bulk-edit',
        method: 'PATCH',
        body: queryArg,
      }),
      invalidatesTags: ['deliveryNote'],
      extraOptions: {
        requestSchema: BulkPatchDeliveryNoteRequestSchema,
        responseSchema: BulkPatchDeliveryNoteResponseSchema,
      },
    }),
  }),
});

export const {
  useGetDeliveryNoteQuery,
  useLazyGetDeliveryNoteQuery,
  usePatchDeliveryNoteMutation,
  useGetDeliveryNoteManualDownloadQuery,
  useLazyGetDeliveryNoteManualDownloadQuery,
  useBulkDeleteDeliveryNoteMutation,
  useBulkPostReceiveNoteMutation,
  useBulkPatchDeliveryNoteMutation,
} = metadaWarehouseDeliveryNoteApi;
