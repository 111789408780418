import {
  Button,
  ButtonGroup,
  DataStatus,
  DialogFooter,
  openDialog,
  Option,
  showNotification,
} from 'platform/components';
import {Box, HStack, Show, Text, VStack} from 'platform/foundation';

import {useState} from 'react';

import {isNil} from 'ramda';

import {useGetAllTireWarehousesQuery, useUpdateTireSetLocationMutation} from '@dms/api/metadaTires';
import i18n from '@dms/i18n';

import {Nullish, RequiredTestIdProps} from 'shared';

import {DataGrid, refreshDatagrid, RowData} from 'features/datagrid';

import {handleApiError} from '../../../utils/handleApiError';
import {CreateTireLocation} from '../../CreateTireLocation/CreateTireLocation';
import {StorageLocationFilters} from './StorageLocationFilters';

interface StorageWarehouseGridRow extends RowData {
  storageLocation: {value: string};
}

const EXTERNAL_FILTER_ID = 'assign-storage-location-tire-warehouse-external-filter';

interface StorageWarehouseDialogProps extends RequiredTestIdProps {
  setId?: string | Nullish;
  orderId: string;
  onClose: () => void;
}

export function StorageWarehouseDialog(props: StorageWarehouseDialogProps) {
  const [selectedLocation, setSelectedLocation] = useState<StorageWarehouseGridRow>();
  const [selectedWarehouseId, setSelectedWarehouseId] = useState<string>();

  const [updateTireSetLocation, updateTireSetLocationStatus] = useUpdateTireSetLocationMutation();

  const warehouseQuery = useGetAllTireWarehousesQuery();

  const warehouseOptions: Option[] =
    warehouseQuery.data?.map((warehouse) => ({
      label: warehouse.name,
      value: warehouse.warehouseId,
    })) || [];

  const handleCreateSingleLocation = () => {
    if (!selectedWarehouseId) {
      return;
    }

    openDialog(
      <CreateTireLocation
        dialogId="createTireLocationDialog"
        tireWarehouseId={selectedWarehouseId}
        onSuccess={() => refreshDatagrid('assign-storage-location-tire-warehouse')}
      />,
      {
        id: 'createTireLocationDialog',
        title: i18n.t('entity.tireWarehouses.labels.createSingleLocation'),
      }
    );
  };

  const handleLocationSelection = () => {
    if (!props.setId || !selectedLocation) {
      return;
    }

    updateTireSetLocation({
      orderId: props.orderId,
      setId: props.setId,
      body: {
        location: selectedLocation.storageLocation.value,
        locationDefinition: {
          contextTarget: 'STORAGE_LOCATION',
          contextId: selectedLocation.id,
        },
      },
    })
      .unwrap()
      .then(() => {
        showNotification.success();
        refreshDatagrid('assign-storage-location-tire-warehouse');
        refreshDatagrid('tire-order-set');
        props.onClose();
      })
      .catch(handleApiError);
  };

  return (
    <VStack minHeight={50}>
      <DataStatus isLoading={warehouseQuery.isLoading} isError={warehouseQuery.isError}>
        <Show when={warehouseQuery.data?.length === 0}>
          <Text>{i18n.t('entity.tireWarehouses.labels.noWarehouses')}</Text>
        </Show>
        <Show when={warehouseQuery.data?.length && warehouseQuery.data?.length > 0}>
          <VStack spacing={4}>
            <HStack align="center" justify="space-between">
              <div id={EXTERNAL_FILTER_ID} />
              <Box paddingTop={4}>
                <ButtonGroup>
                  <Button
                    title={i18n.t('entity.tireWarehouses.labels.createSingleLocation')}
                    variant="secondary"
                    leftIcon="content/add_circle"
                    onClick={handleCreateSingleLocation}
                    isDisabled={!selectedWarehouseId}
                  />
                </ButtonGroup>
              </Box>
            </HStack>
            <Box minHeight={50} flex={1} paddingBottom={16}>
              <DataGrid
                gridCode="assign-storage-location-tire-warehouse"
                queryModifier={(filter) => {
                  if (isNil(filter.tireWarehouse)) {
                    setSelectedWarehouseId(warehouseOptions[0].value);

                    return {
                      ...filter,
                      tireWarehouse: [warehouseOptions[0].value],
                    };
                  }

                  setSelectedWarehouseId(filter.tireWarehouse as string);

                  return filter;
                }}
                filterComponent={(filterProps) => (
                  <StorageLocationFilters {...filterProps} warehouseOptions={warehouseOptions} />
                )}
                externalFilterId={EXTERNAL_FILTER_ID}
                onRowSelectionChange={(selection) => {
                  setSelectedLocation(selection[0] as StorageWarehouseGridRow);
                }}
                autoHeight
              />
            </Box>
            <DialogFooter>
              <ButtonGroup align="right">
                <Button
                  title={i18n.t('general.labels.discard')}
                  variant="secondary"
                  onClick={props.onClose}
                  isDisabled={updateTireSetLocationStatus.isLoading}
                />
                <Button
                  title={i18n.t('general.labels.assign')}
                  isDisabled={!selectedLocation}
                  onClick={handleLocationSelection}
                  isLoading={updateTireSetLocationStatus.isLoading}
                />
              </ButtonGroup>
            </DialogFooter>
          </VStack>
        </Show>
      </DataStatus>
    </VStack>
  );
}
