import {DrillDownLevelName} from '@dms/api/sourcing';
import i18n from '@dms/i18n';

import {Nullish} from 'shared';

import {LevelConfiguration} from '../types';

const LEVELS_CONFIGURATIONS: LevelConfiguration[] = [
  {
    name: 'make',
    title: i18n.t('entity.vehicle.labels.make'),
  },
  {
    name: 'model_family_group',
    title: i18n.t('entity.vehicle.labels.modelFamily'),
  },
  {
    name: 'model',
    title: i18n.t('entity.vehicle.labels.model'),
  },
  {
    name: 'year',
    title: i18n.t('entity.vehicle.labels.year'),
  },
  {
    name: 'power',
    title: i18n.t('entity.vehicle.labels.power'),
    formatter: (value) => `${value} ${i18n.t('general.metric.kW')}`,
  },
  {
    name: 'fuel_type',
    title: i18n.t('entity.vehicle.labels.fuel'),
  },
  {
    name: 'transmission',
    title: i18n.t('entity.vehicle.labels.transmission'),
  },
  {
    name: 'drive',
    title: i18n.t('entity.vehicle.labels.drive'),
  },
  {
    name: 'car_style',
    title: i18n.t('entity.vehicle.labels.body'),
  },
  {
    name: 'mileage_range',
    title: i18n.t('entity.vehicle.labels.mileage'),
  },
];

export function getLevelConfigurations(
  hiddenLevels: DrillDownLevelName[] | Nullish
): LevelConfiguration[] {
  return LEVELS_CONFIGURATIONS.filter(
    (levelConfiguration) => !hiddenLevels?.includes(levelConfiguration.name)
  );
}
