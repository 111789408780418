import {omneticApi} from '@dms/api/core';
import {
  GetAutoTracerUrlApiArg,
  GetAutoTracerUrlApiResponse,
  GetCebiaReportsApiArg,
  GetCebiaReportsApiResponse,
  GetLastAutotracerReportApiArg,
  GetLastAutotracerReportApiResponse,
  GetManufactureYearApiArg,
  GetManufactureYearApiResponse,
} from '@dms/api/shared';

export const cebiaApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getAutoTracerUrl: build.query<GetAutoTracerUrlApiResponse, GetAutoTracerUrlApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/cebia/auto-tracer-url`,
        params: {
          vin: queryArg.vin,
          vehicleMark: queryArg.vehicleMark,
          vehicleModel: queryArg.vehicleModel,
          mileage: queryArg.mileage,
          makeYear: queryArg.makeYear,
          vehicleId: queryArg.vehicleId,
        },
      }),
    }),
    getLastAutotracerReport: build.query<
      GetLastAutotracerReportApiResponse,
      GetLastAutotracerReportApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/cebia/last-autotracer-report`,
      }),
    }),
    getCebiaReports: build.query<GetCebiaReportsApiResponse, GetCebiaReportsApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/cebia-report`,
      }),
    }),
    getManufactureYear: build.query<GetManufactureYearApiResponse, GetManufactureYearApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/manufacture-time/${queryArg.vin}`,
        params: {
          vehicleId: queryArg.vehicleId,
        },
      }),
    }),
  }),
});

export const {
  useLazyGetAutoTracerUrlQuery,
  useGetLastAutotracerReportQuery,
  useGetCebiaReportsQuery,
  useGetManufactureYearQuery,
} = cebiaApi;
