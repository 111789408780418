import {DefaultTheme} from 'styled-components';

import {isNil} from 'ramda';

import {Nullish} from 'shared';

import {ThemeColorTextPath} from './foundationTheme';

export function getTextColorByPath(
  color: ThemeColorTextPath | Nullish,
  theme: DefaultTheme
): string | undefined {
  if (isNil(color)) {
    return undefined;
  }
  return theme.colors.text[color];
}
