import styled from 'styled-components';

import {MenuItem} from '@dms/teas';

export const StyledMenuItem = styled(MenuItem)`
  ${({isActive, theme}) =>
    isActive
      ? `
			a {
				color: ${theme.colors.palettes.blue[60][100]};
				font-weight: bold;
			}
	`
      : null}
`;
