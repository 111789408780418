import {BigCalendarEvent} from '../types/BigCalendarEvent';
import {calculateEventLayout} from './calculateEventLayout';
import {compareDates} from './compareDates';

type GetEventsForDateReturnType = {
  regularEvents: BigCalendarEvent[];
  allDayEvents: (BigCalendarEvent & {allDayPosition: number})[];
};

export function getEventsForDate(
  events: BigCalendarEvent[],
  date: Date
): GetEventsForDateReturnType {
  const regularEvents = events.filter(
    (event) => compareDates(event.start, date) && !event.isAllDay
  );

  const allDayEvents = events.reduce(
    (result: GetEventsForDateReturnType['allDayEvents'], event) => {
      if (compareDates(event.start, date) && event.isAllDay) {
        result.push({...event, allDayPosition: result.length});
      }
      return result;
    },
    []
  );

  return {
    regularEvents: calculateEventLayout(regularEvents),
    allDayEvents,
  };
}
