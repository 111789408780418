import {createContext, useContext, useState} from 'react';

const INITIAL_OPEN_ITEMS = {};

type MenuItemContextType = {
  openItemIds: Record<string, boolean>;
  toggle: (id: string) => void;
  open: (id: string) => void;
  close: (id: string) => void;
  isOpen: (id: string) => boolean;
};

const MenuItemsStateContext = createContext<MenuItemContextType | undefined>(undefined);

type MenuItemProviderProps = {
  children: React.ReactNode;
};

export function MenuItemsStateProvider({children}: MenuItemProviderProps) {
  const [openItemIds, setOpenItemIds] = useState<Record<string, boolean>>(INITIAL_OPEN_ITEMS);

  const toggle = (id: string) => {
    setOpenItemIds((prevIds) => ({
      ...prevIds,
      [id]: !prevIds[id],
    }));
  };

  const open = (id: string) => {
    setOpenItemIds((prevIds) => ({
      ...prevIds,
      [id]: true,
    }));
  };

  const close = (id: string) => {
    setOpenItemIds((prevIds) => ({
      ...prevIds,
      [id]: false,
    }));
  };

  const isOpen = (id: string) => openItemIds[id];

  const value = {
    openItemIds,
    toggle,
    open,
    close,
    isOpen,
  };

  return <MenuItemsStateContext value={value}>{children}</MenuItemsStateContext>;
}

export function useMenuItemsState() {
  const context = useContext(MenuItemsStateContext);

  if (context === undefined) {
    throw new Error('useMenuItemsState must be used within a MenuItemsStateProvider');
  }

  return context;
}
