import {Box, Space, Stack, Text} from 'platform/foundation';

import {CustomerResponseBodyV2} from '@dms/api/customer';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

import {OwnershipType} from '../../CustomerVehicleSelection/types/ownershipType';
import {OwnershipCustomerCard} from './OwnershipCustomerCard';

interface InvoicingProps {
  vehicleId: string;
  onOwnershipChange: (customerType: OwnershipType) => void;
  onDelete: (customerType: OwnershipType) => void;
  onMove: (excludedOwnership: OwnershipType) => (customer: CustomerResponseBodyV2) => void;
}

export function Invoicing(props: InvoicingProps) {
  return (
    <>
      <Text color="secondary" size="small">
        {i18n.t('entity.ownership.label.ownershipInfo')}
      </Text>
      <Space vertical={4} />
      <Stack direction={['column', 'column', 'row', 'row']} spacing={4}>
        <Box flex={1}>
          <OwnershipCustomerCard
            ownershipType="CORVEHICLECUSTOMERTYPE_INVOICE"
            vehicleId={props.vehicleId}
            onChange={() => props.onOwnershipChange('CORVEHICLECUSTOMERTYPE_INVOICE')}
            onDelete={() => props.onDelete('CORVEHICLECUSTOMERTYPE_INVOICE')}
            onMove={props.onMove('CORVEHICLECUSTOMERTYPE_INVOICE')}
            data-testid={testIds.workshop.serviceCaseDetail('invoicing-recepient')}
          />
        </Box>
        <Space fillAvailable />
      </Stack>
    </>
  );
}
