import {openDeleteDialog, showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {head, isNil} from 'ramda';
import {isArray, isNilOrEmpty} from 'ramda-adjunct';

import {useDeleteServiceOrderReturnMutation} from '@dms/api/metadaWarehouseServiceOrderReturn';
import i18n from '@dms/i18n';
import {testIds, warehouseRoutes} from '@dms/routes';
import {catchUnhandledDataGridActions, handleApiError, logError} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {FullscreenDataGridWrapper} from '../../../components/FullscreenDataGridWrapper';
import {WAREHOUSE_GRID_CODES} from '../../../constants/warehouseGridCodes';

export function ServiceOrderReturns() {
  const navigate = useNavigate();

  const [deleteServiceOrderReturn] = useDeleteServiceOrderReturnMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, sourceSearchParams, refreshData}) => {
    match(actionKey)
      .with('redirectDetail', 'edit', () => {
        const selectedRowId = isArray(rowId) ? head(rowId) : rowId;

        if (isNil(selectedRowId)) {
          return logError('Selected row id is undefined');
        }

        navigate(
          composePath(warehouseRoutes.serviceOrderReturnsDetailOverview, {
            queryParams: sourceSearchParams,
            params: {
              id: selectedRowId,
            },
            isSearchParamsPreserved: false,
          })
        );
      })
      .with('delete', () => {
        const selectedRowIds = isArray(rowId) ? rowId : [rowId];

        if (isNilOrEmpty(selectedRowIds)) {
          return logError('Selected row ids are empty or undefined');
        }

        openDeleteDialog({
          onConfirm: () =>
            deleteServiceOrderReturn({body: {serviceOrderReturnId: selectedRowIds}})
              .unwrap()
              .then(() =>
                showNotification.success(i18n.t('general.notifications.successfullyDeleted'))
              )
              .then(refreshData)
              .catch(handleApiError),
          'data-testid': testIds.warehouse.serviceOrderReturns('deleteDialog'),
        });
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <FullscreenDataGridWrapper hideCardWrapper>
      <DataGrid
        gridCode={WAREHOUSE_GRID_CODES.warehouseServiceOrderReturns}
        actionCallback={actionCallback}
        data-testid={WAREHOUSE_GRID_CODES.warehouseServiceOrderReturns}
      />
    </FullscreenDataGridWrapper>
  );
}
