import {Button} from 'platform/components';
import {Box, Heading, Text, VStack} from 'platform/foundation';

import i18n from '@dms/i18n';

import {SuccessImage} from './SuccessImage';

interface ImportSuccessProps {
  onClose: () => void;
}

export function ImportSuccess(props: ImportSuccessProps) {
  return (
    <Box padding={6}>
      <VStack align="center" justify="center" spacing={4}>
        <SuccessImage />
        <Heading size={2}>{i18n.t('entity.import.labels.importSuccessHeading')}</Heading>
        <Box maxWidth={165}>
          <Text align="center" color="secondary">
            {i18n.t('entity.import.labels.importSuccessDescription')}
          </Text>
        </Box>
        <Button title={i18n.t('general.actions.continue')} onClick={props.onClose} />
      </VStack>
    </Box>
  );
}
