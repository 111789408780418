import {Button, RestrictedStatus, showNotification} from 'platform/components';
import {Box, Center, HStack, Show, Space, Spinner, VStack} from 'platform/foundation';
import {css} from 'styled-components';

import {ReactNode} from 'react';
import {generatePath, matchPath, useLocation, useMatch} from 'react-router-dom';

import {find, isNil, map, propEq} from 'ramda';
import {isArray, isNotString} from 'ramda-adjunct';

import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId, useNavigate} from 'shared';

import {RowControlWrapper} from 'features/datagrid';

import {useNavigationBreadCrumb} from '../../hooks/useNavigationBreadCrumb';
import {NumberedMenu} from '../NumberedMenu/NumberedMenu';
import {NumberedMenuItem} from '../NumberedMenu/NumberedMenuItem';

export type RoutedContent = {route: string; element: ReactNode};

export type NavigationItem = {
  content?: ReactNode | RoutedContent[];
  href: string;
} & NumberedMenuItem;

export interface PageProps extends RequiredTestIdProps {
  navigation?: NavigationItem[];
  header?: ReactNode; //TODO: Use new EntityHeaderProps when ready / https://carvago.atlassian.net/browse/T20-15024
  children?: ReactNode;
  activityPanel?: ReactNode;
  isFullHeight?: boolean;
  onNavigate?: (item: NavigationItem) => void;
  isLocked?: boolean;
}

export function Page(props: PageProps) {
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const [breadcrumbProps] = useNavigationBreadCrumb();
  const rowControlDetailMatch = useMatch(breadcrumbProps?.rowControlDetail ?? '');

  const selectedItem = props.navigation?.find(({href, content}) => {
    const routes = [href.split('?')[0]];
    if (isArray(content)) {
      routes.push(...map((item) => item.route, content));
    }
    return routes.some((route) => matchPath(route, pathname));
  });

  const activeItem = selectedItem || props.navigation?.[0];
  const itemElement = isArray(activeItem?.content)
    ? activeItem?.content.find((routedContent) => matchPath(routedContent.route, pathname))?.element
    : activeItem?.content;

  const height = props.isFullHeight ? '100%' : undefined;

  const onItemChange = (itemId: string) => {
    const selectedItem = find(propEq(itemId, 'id'), props.navigation ?? []);
    if (isNil(selectedItem)) {
      return;
    }
    navigate(selectedItem.href);
    props.onNavigate?.(selectedItem);
  };

  const redirectToUrl = () => {
    const redirectToOverviewUrl = props.navigation?.[0].href;

    if (!redirectToOverviewUrl) {
      showNotification.error(i18n.t('page.detailRestrictedArea.noRedirectToOverviewUrl'));
      return;
    }

    navigate(redirectToOverviewUrl);
  };

  const handleRowControlChange = (newEntity: string, sourceSearchParams: string) => {
    if (isNotString(breadcrumbProps?.rowControlDetail)) {
      throw Error('You need to specify rowControlDetail');
    }

    navigate(
      generatePath(breadcrumbProps?.rowControlDetail as string, {
        ...rowControlDetailMatch?.params,
        id: newEntity,
      }) + `?${sourceSearchParams}`
    );
  };

  const disabledOverlay = (
    <Center width="100%">
      <VStack>
        <RestrictedStatus
          headline={i18n.t('page.detailRestrictedArea.headline')}
          subheadline={i18n.t('page.detailRestrictedArea.subheadline')}
        />
        <Space vertical={4} />
        <Button
          data-testid={suffixTestId('goToOverview', props)}
          title={i18n.t('page.detailRestrictedArea.goToOverview')}
          onClick={redirectToUrl}
        />
        <Space vertical={4} />
        <Center>
          <RowControlWrapper onChange={handleRowControlChange} />
        </Center>
      </VStack>
    </Center>
  );

  const context =
    activeItem && 'isDisabled' in activeItem && activeItem?.isDisabled
      ? disabledOverlay
      : (props.children ?? itemElement);

  return (
    <VStack height={height}>
      {props.header}
      <HStack height={height}>
        <Show when={props.navigation}>
          <Box width={54} minWidth={54}>
            <Show when={props.navigation}>
              <Box paddingTop={4} paddingLeft={4} paddingBottom={4}>
                <NumberedMenu
                  items={props.navigation ?? []}
                  activeItemId={activeItem?.id}
                  onItemChange={onItemChange}
                  data-testid={suffixTestId('page-navigation', props)}
                />
              </Box>
            </Show>
          </Box>
        </Show>
        {props.isLocked ? (
          <div
            css={css`
              position: relative;
              height: 100%;
              width: 100%;
              display: flex;
              overflow: hidden;
            `}
          >
            {context}
            <div
              css={css`
                cursor: not-allowed;
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.1);
                z-index: 99999;
                display: flex;
                align-items: center;
                justify-content: center;
              `}
            >
              <Spinner />
            </div>
          </div>
        ) : (
          <>
            {context}
            <Show when={props.activityPanel} whenFeatureEnabled={featureFlags.CRM_SALES_ACTIVITIES}>
              {props.activityPanel}
            </Show>
          </>
        )}
      </HStack>
    </VStack>
  );
}
