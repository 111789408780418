import {AxiosError} from 'axios';
import {Card, Search, showNotification} from 'platform/components';

import {useEffect, useState, useCallback} from 'react';
import {useSelector} from 'react-redux';

import {ApiException} from '@dms/api/shared';
import {testIds} from '@dms/routes';
import {
  editTopMakes,
  getTopMakes,
  updateTopMakeList,
  selectTopMakes,
  selectVehicleMakes,
  TopMake,
  useThunkDispatch,
  useApiDispatch,
} from '@dms/teas';

import {debounce} from 'shared';

import {TopMakesList} from './TopMakesList';
import {MakeAndChecked} from './types';

export function TopMakes() {
  const topMakes = useSelector(selectTopMakes) ?? [];
  // NOTE: For now it's only for VEHICLETYPE_PASSENGER_CAR type
  const makeOptions = useSelector(selectVehicleMakes('VEHICLETYPE_PASSENGER_CAR'));
  const [search, setSearch] = useState<string | null>(null);
  const [filteredOptions, setFilteredOptions] = useState<MakeAndChecked[]>([]);
  const apiDispatch = useApiDispatch();
  const dispatch = useThunkDispatch();

  const handleRowToggle = (makeCode: string) => {
    const topMakesUpdated =
      topMakes.findIndex((make) => make.code === makeCode) !== -1
        ? topMakes.filter((make) => make.code !== makeCode)
        : [...topMakes, {code: makeCode}];

    dispatch(updateTopMakeList({topMakes: topMakesUpdated}));
    updateTopMakes(topMakesUpdated);
  };

  const updateTopMakes = useCallback(
    debounce(async (topMakesUpdated: TopMake[]): Promise<void> => {
      try {
        await apiDispatch(editTopMakes.action, {
          requestBody: {makes: topMakesUpdated?.map((make) => make.code) ?? []},
        });
      } catch (error: any) {
        showNotification.error(
          (error as AxiosError<ApiException>).response?.data?.errors?.[0]?.message ||
            error.toString()
        );
        dispatch(getTopMakes.action({}));
      }
    }, 600),
    []
  );

  useEffect(() => {
    const makeOptionsWithChecks = makeOptions.map((option) => ({
      ...option,
      loading: false,
      checked: topMakes?.findIndex((make) => make.code === option.value) !== -1,
    }));

    setFilteredOptions(
      makeOptionsWithChecks.filter((make) =>
        search ? make?.label?.toLowerCase().includes(search.toLowerCase()) : true
      )
    );
  }, [search, JSON.stringify(topMakes)]);

  return (
    <Card>
      <Search
        value={search}
        onChange={setSearch}
        data-testid={testIds.settings.vehicleTopMakes('searchTopMakes')}
      />
      <TopMakesList makeOptions={filteredOptions} handleRowToggle={handleRowToggle} />
    </Card>
  );
}
