import {isFeatureEnabled} from 'feature-flags';
import {
  ConfirmDialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Radio,
  showNotification,
  Tooltip,
} from 'platform/components';
import {Box, Clickable, HStack, Icon, Show} from 'platform/foundation';
import styled, {css} from 'styled-components';
import * as Yup from 'yup';

import {ChangeEvent, ReactElement, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
  append,
  curry,
  findIndex,
  ifElse,
  includes,
  isEmpty,
  isNotNil,
  path,
  propEq,
  without,
} from 'ramda';

import {selectActiveBranchId, setActiveBranchId} from '@dms/api/features';
import {BlockUserApiArg, PatchUserApiArg, UnblockUserApiArg} from '@dms/api/shared';
import {useGetBranchListQuery} from '@dms/api/tenant';
import {
  useBlockUserMutation,
  useGetOtherUserSettingsQuery,
  useGetUserQuery,
  usePatchUserMutation,
  useSetOtherUserSettingsItemMutation,
  useUnblockUserMutation,
} from '@dms/api/user';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';
import {
  ADMINISTRATOR_ROLE,
  adminRoleSelector,
  allRolesListWithoutAdmin,
  blockUserSuccess,
  Button,
  Checkbox,
  CheckboxTree,
  CUSTOM_ROLE,
  fetchFullRoleRequest,
  getStringErrorMessage,
  Hyperlink,
  InfoCard,
  loadingUserList,
  patchUserSuccess,
  RowType,
  selectUserId,
  TextField,
  unblockUserSuccess,
  User,
} from '@dms/teas';

import {debounce} from 'shared';

import {SetPasswordModal} from './SetPasswordModal/SetPasswordModal';

interface InsurerNumberForm {
  insurerNumber: string | null;
}

interface CustomUserIdFrom {
  customId: string | null;
}

type ViewUserProps = {
  id: string;
};

type RadioRolesType = typeof ADMINISTRATOR_ROLE | typeof CUSTOM_ROLE;

const firstNameLastNameValidation = (field: string, error_message: string) =>
  Yup.string()
    .required(`${field} ${i18n.t('general.notifications.errorSpecFieldRequired')}`)
    .matches(/^[a-zA-Z0-9\u00C0-\u024F\u1E00-\u1EFF-\s.]+$/i, {
      message: error_message,
    })
    .max(60, error_message);

const ViewUserLayout = ({id}: ViewUserProps): ReactElement => {
  const dispatch = useDispatch();

  const isInsuranceComparisonEnabled = isFeatureEnabled(featureFlags.SALES_INSURANCE_COMPARISON_V1);
  const isUserCustomIdEnabled = isFeatureEnabled(featureFlags.CORE_CUSTOM_USER_ID);

  const [blockUser] = useBlockUserMutation();
  const [unblockUser] = useUnblockUserMutation();
  const [patchUser] = usePatchUserMutation();
  const {data: user} = useGetUserQuery({id});

  const {data: userSettings} = useGetOtherUserSettingsQuery(
    {userId: id},
    {skip: !isUserCustomIdEnabled && !isInsuranceComparisonEnabled}
  );

  const [saveOtherUserSetting] = useSetOtherUserSettingsItemMutation();

  const roles = useSelector(allRolesListWithoutAdmin);
  const adminRole = useSelector(adminRoleSelector);
  const isUserLoading = useSelector(loadingUserList);
  const loginedUserId = useSelector(selectUserId);
  const activeBranchId = useSelector(selectActiveBranchId);

  const {firstName, lastName, email, blocked, branches, defaultBranch} = user ?? {};
  const [firstNameState, setFN] = useState<string | undefined>(firstName);
  const [lastNameState, setLN] = useState<string | undefined>(lastName);
  const [isRoleEditable, toggleEdit] = useState<boolean>(false);
  const [showPasswordModal, setPasswordShow] = useState<boolean>(false);
  const [blockModal, showBlockModal] = useState<boolean>(false);
  const [unblockModal, showUnblockModal] = useState<boolean>(false);
  const [firstNameHasErrors, setFirstNameHasErrors] = useState<boolean>(false);
  const [lastNameHasErrors, setLastNameHasErrors] = useState<boolean>(false);

  const {data: branchList, isLoading, error} = useGetBranchListQuery();

  const [selectedBranches, setSelectedBranches] = useState<Record<string, boolean>>({});
  const [defaultBranchState, setDefaultBranch] = useState<string>();

  const isAdmin: boolean = user?.roles
    ? findIndex(propEq(adminRole[0]?.id, 'id'))(user?.roles) >= 0
    : false;
  const userRolesArray: readonly string[] =
    user?.roles?.map((item) => item.id).filter((item) => item !== adminRole[0]?.id) || [];
  const isCurrentLoginedUser: boolean = id === loginedUserId;

  const [rolesBuffer, setRolesBuffer] = useState<readonly string[]>(userRolesArray);
  /*
		Problem: we have 2 UI elements which works affect on same array
		Crazy thing: Roles array can't be empty. So we couldn't just Patch it, then we click on CUSTOM_ROLE.
		So I've added this additional value
		TODO: Ask BE devs to move MANGER to separated property
	*/
  const [rolesRadio, switchRolesRadio] = useState<RadioRolesType>(
    isAdmin ? ADMINISTRATOR_ROLE : CUSTOM_ROLE
  );
  // And also need to update this value :(
  useEffect(() => {
    switchRolesRadio(isAdmin ? ADMINISTRATOR_ROLE : CUSTOM_ROLE);
  }, [isAdmin]);
  // and onClick func
  const rolesOnChange = (value: string | null) => {
    if (value === ADMINISTRATOR_ROLE) {
      setRolesBuffer(userRolesArray);
      switchRolesRadio(ADMINISTRATOR_ROLE);
      patchProps('roles', [adminRole[0].id]);
    } else {
      switchRolesRadio(CUSTOM_ROLE);
      if (rolesBuffer.length) {
        patchProps('roles', rolesBuffer);
      }
    }
  };

  useEffect(() => {
    if (isEmpty(roles)) {
      dispatch(fetchFullRoleRequest());
    }
  }, [dispatch]);

  useEffect(() => {
    setFN(firstName);
  }, [firstName]);

  useEffect(() => {
    setLN(lastName);
  }, [lastName]);

  useEffect(() => {
    const _selectedBranches: Record<string, boolean> = {};

    branches?.forEach((_br) => {
      _selectedBranches[_br.id] = true;
    });

    setSelectedBranches(_selectedBranches);
  }, [branches]);

  useEffect(() => {
    if (defaultBranch) {
      setDefaultBranch(defaultBranch.id);
    }
  }, [defaultBranch]);

  const nodes = roles?.map((item) => ({
    label: item.title,
    value: item.id,
    children: [],
  }));

  const patchProps = async (key: string, value: string | readonly string[]): Promise<void> => {
    if (path([key], user) != value) {
      if (key === 'roles' && isEmpty(value)) {
        showNotification.error(i18n.t('page.settings.labels.minimumOneRole'));
        return;
      }

      try {
        const arg = {userId: id, updateUserRequestBody: {[key]: value}} as PatchUserApiArg;
        const res = await patchUser(arg).unwrap();

        if (res && res?.id) {
          // eslint-disable-next-line no-restricted-syntax
          dispatch(patchUserSuccess(res as unknown as User));
          showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
        }
      } catch (error: any) {
        showNotification.error(getStringErrorMessage(error));
      }
    }
  };

  const toggleListItem = curry((value, list) =>
    ifElse(includes(value), without([value]), append(value))(list)
  );

  const fNameValidationSchema = firstNameLastNameValidation(
    'First name',
    i18n.t('general.notifications.invalidFirstName')
  );
  const lNameValidationSchema = firstNameLastNameValidation(
    'Last name',
    i18n.t('general.notifications.invalidLastName')
  );

  const onFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFirstNameHasErrors(false);

    setFN(e.target.value);
  };

  const onFirstNameBlur = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    try {
      fNameValidationSchema.validateSync(value);
      patchProps('firstName', value);
    } catch (err) {
      setFirstNameHasErrors(true);
    }
  };

  const onLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLastNameHasErrors(false);

    setLN(e.target.value);
  };

  const onLastNameBlur = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    try {
      lNameValidationSchema.validateSync(value);
      patchProps('lastName', value);
    } catch (error) {
      setLastNameHasErrors(true);
    }
  };

  const viewUserContent: RowType[] = [
    {
      label: i18n.t('page.settings.labels.userStatus'),
      value: blocked ? i18n.t('general.labels.blocked') : i18n.t('entity.settings.labels.active'),
    },
    {
      label: i18n.t('entity.person.labels.firstName'),
      render: (
        <TextField
          variant="inline"
          name="firstName"
          type="text"
          placeholder={i18n.t('page.settings.labels.typeFirstName')}
          value={firstNameState}
          onChange={onFirstNameChange}
          onBlur={onFirstNameBlur}
          error={firstNameHasErrors}
          data-testid={testIds.settings.userManagementDetail('firstName')}
        />
      ),
    },
    {
      label: i18n.t('entity.person.labels.lastName'),
      render: (
        <TextField
          variant="inline"
          type="text"
          name="lastName"
          placeholder={i18n.t('page.settings.labels.typeLastName')}
          value={lastNameState}
          onChange={onLastNameChange}
          onBlur={onLastNameBlur}
          error={lastNameHasErrors}
          data-testid={testIds.settings.userManagementDetail('lastName')}
        />
      ),
    },
    {
      label: i18n.t('general.labels.email'),
      value: email,
    },
    {
      render: (
        <ResetPassword
          data-testid={testIds.settings.userManagementDetail('resetPasswordLink')}
          onClick={() => setPasswordShow(!showPasswordModal)}
          type="a"
        >
          {i18n.t('page.login.actions.resetPassword')}
        </ResetPassword>
      ),
      divider: true,
    },
    {
      label: i18n.t('entity.user.labels.userType'),
      render: (
        <MainRolesWrapper>
          <Radio
            value={rolesRadio ?? null}
            options={
              isRoleEditable
                ? [
                    {value: CUSTOM_ROLE, label: 'Regular User'},
                    {
                      value: ADMINISTRATOR_ROLE,
                      label: 'Administrator',
                    },
                  ]
                : [
                    isAdmin
                      ? {
                          value: ADMINISTRATOR_ROLE,
                          label: 'Administrator',
                        }
                      : {
                          value: CUSTOM_ROLE,
                          label: 'Regular User',
                        },
                  ]
            }
            onChange={rolesOnChange}
            data-testid={testIds.settings.userManagementDetail('userType')}
          />
        </MainRolesWrapper>
      ),
    },
  ];

  /*
		Adding roles block
		Please pay ATTENTION
		For PUT and PATCH user request we using roles as string[] (array of Ids)
		But in other cases we using roles as UserRole[]
	*/
  if (roles?.length && rolesRadio === CUSTOM_ROLE) {
    isRoleEditable
      ? viewUserContent.push({
          label: i18n.t('page.settings.labels.assignedRole'),
          alignItems: 'flex-start',
          render: (
            <CheckboxTree
              nodes={nodes}
              data-testid={testIds.settings.userManagementDetail('resetPasswordAssignedRole')}
              onSelect={(value: string) =>
                patchProps('roles', toggleListItem(value, userRolesArray))
              }
              selected={userRolesArray || rolesBuffer}
              margin={8}
            />
          ),
        })
      : viewUserContent.push({
          label: i18n.t('page.settings.labels.assignedRole'),
          alignItems: 'flex-start',
          hidden: !user?.roles?.length,
          render: (
            <RolesWrapper data-testid={testIds.settings.userManagement('userRoles')}>
              <Ul>{user?.roles?.map((item) => <li key={item.title}>{item.title}</li>)}</Ul>
            </RolesWrapper>
          ),
        });
  }

  /* Adding Edit roles to the bottom */
  viewUserContent.push({
    render: (
      <Hyperlink
        onClick={() => toggleEdit(!isRoleEditable)}
        type="a"
        icon={<Icon value="content/create" />}
        data-testid={testIds.settings.userManagementDetail('editRoles')}
      >
        {i18n.t('page.settings.actions.editRoles')}
      </Hyperlink>
    ),
    divider: true,
  });

  viewUserContent.push({
    key: 'BRANCHES_ROW',
    alignItems: 'flex-start',
    label: i18n.t('entity.branch.labels.branches'),
    divider: isInsuranceComparisonEnabled,
    render: (
      <Ul data-testid={testIds.settings.userManagementDetail('list')}>
        {branchList?.branchListItems?.map((branch, index) => (
          <BranchOption
            data-testid={testIds.settings.userManagementDetail('option')}
            key={branch.id}
          >
            {Object.values(selectedBranches).filter((b) => b).length >= 2 && (
              <div
                css={css`
                  min-width: 24px;
                `}
              >
                {selectedBranches[branch.id] && (
                  <Tooltip label={i18n.t('entity.branch.labels.defaultBranch')} placement="top">
                    <Clickable
                      onClick={() => !isUserLoading && handleDefaultBranchChange(branch.id)}
                    >
                      <BranchStarIcon
                        data-testid={testIds.settings.userManagementDetail(
                          `branchDefault-${index}`
                        )}
                        active={branch.id === defaultBranchState}
                      />
                    </Clickable>
                  </Tooltip>
                )}
              </div>
            )}
            <div>
              <Checkbox
                disabled={
                  isUserLoading ||
                  (selectedBranches[branch.id] && Object.values(selectedBranches).length === 1)
                }
                css={css`
                  margin: 2px;
                `}
                checked={selectedBranches[branch.id]}
                onChange={(e) => {
                  handleBranchSelect(branch.id, e.target.checked);
                }}
                data-testid={testIds.settings.userManagementDetail(`branchCheckbox-${index}`)}
                name={`branch-checkbox-${index}`}
                label={branch.marketingName}
              />
            </div>
          </BranchOption>
        ))}
      </Ul>
    ),
  });

  const blockButton = (
    <Button
      secondary
      onClick={() => showBlockModal(true)}
      data-testid={testIds.settings.userManagementDetail('block')}
    >
      {i18n.t('general.actions.block')}
    </Button>
  );

  const unblockButton = (
    <Button
      secondary
      onClick={() => showUnblockModal(true)}
      data-testid={testIds.settings.userManagementDetail('unblock')}
    >
      {i18n.t('general.actions.unblock')}
    </Button>
  );

  const handleBranchSelect = (id: string, checked: boolean) => {
    let _defaultBranch: string | undefined = defaultBranchState;
    const newState = {
      ...selectedBranches,
      [id]: checked,
    };

    if (_defaultBranch && !newState[_defaultBranch]) {
      _defaultBranch = branchList?.branchListItems.find((b) => newState[b.id])?.id ?? undefined;
    }

    setSelectedBranches(newState);
    setDefaultBranch(_defaultBranch);

    handleBranchChange(newState, _defaultBranch || '');
  };

  const handleDefaultBranchChange = (id: string) => {
    setDefaultBranch(id);

    handleBranchChange(selectedBranches, id);
  };
  const handleBranchChange = useCallback(
    debounce(async (_selectedBranches: Record<string, boolean>, _defaultBranch: string) => {
      const branchIds = Object.entries(_selectedBranches)
        .filter(([_, value]) => value)
        .map((b) => b[0]);

      if (!branchIds.length) {
        showNotification.error(i18n.t('entity.user.validation.requiredBranch'));
        return;
      }

      const res = await patchUser({
        userId: id,
        updateUserRequestBody: {
          branchIds,
          defaultBranchId: _defaultBranch,
        },
      }).unwrap();

      // if current branch was removed, move user to his default branch
      if (!res.branches?.some((branch) => branch.id === activeBranchId)) {
        dispatch(setActiveBranchId(res.defaultBranch?.id));
      }

      // eslint-disable-next-line no-restricted-syntax
      dispatch(patchUserSuccess(res as unknown as User));

      showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
    }, 1250),
    []
  );

  useEffect(() => {
    if (error) {
      showNotification.error();
    }
  }, [error]);

  const onInsurerSubmit: FormSubmitHandler<InsurerNumberForm> = (values, _, reset) =>
    saveOtherUserSetting({
      userId: id,
      settingKey: 'insurer_number',
      body: {
        value: values.insurerNumber ?? '',
      },
    })
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
        reset(undefined, {keepValues: true});
      })
      .catch(handleApiError);

  const onCustomIdSubmit: FormSubmitHandler<CustomUserIdFrom> = (values, _, reset) =>
    saveOtherUserSetting({
      userId: id,
      settingKey: 'custom_id',
      body: {
        value: values.customId ?? '',
      },
    })
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
        reset({customId: values.customId});
      })
      .catch(handleApiError);

  if (isInsuranceComparisonEnabled && isNotNil(userSettings)) {
    viewUserContent.push({
      key: 'INSURER',
      alignItems: 'center',
      label: i18n.t('page.settings.labels.insurerNumber'),
      render: (
        <Form<InsurerNumberForm>
          defaultValues={{
            insurerNumber: isNotNil(userSettings?.insurerNumber?.value)
              ? userSettings?.insurerNumber?.value.toString()
              : null,
          }}
          onSubmit={onInsurerSubmit}
        >
          {(control, formApi) => (
            <HStack spacing={4}>
              <FormField
                control={control}
                type="text"
                name="insurerNumber"
                placeholder={i18n.t('page.settings.labels.typeInsurerNumber')}
                data-testid={testIds.settings.userManagementDetail('typeInsurerNumber')}
              />
              <Show when={formApi.formState.isDirty}>
                <FormButton
                  control={control}
                  type="submit"
                  title={i18n.t('general.actions.save')}
                  data-testid={testIds.settings.userManagementDetail('typeInsurerNumber-save')}
                />
              </Show>
            </HStack>
          )}
        </Form>
      ),
    });
  }

  if (isUserCustomIdEnabled && isNotNil(userSettings)) {
    viewUserContent.push({
      key: 'USER_ID',
      alignItems: 'center',
      label: i18n.t('page.settings.labels.customUserId'),
      render: (
        <Form<CustomUserIdFrom>
          defaultValues={{
            customId: isNotNil(userSettings?.customId?.value)
              ? userSettings?.customId?.value.toString()
              : null,
          }}
          onSubmit={onCustomIdSubmit}
        >
          {(control, formApi) => (
            <HStack spacing={4}>
              <FormField
                control={control}
                type="text"
                name="customId"
                placeholder={i18n.t('page.settings.labels.customUserId')}
                data-testid={testIds.settings.userManagementDetail('customUserId')}
              />
              <Show when={formApi.formState.isDirty}>
                <FormButton
                  data-testid={testIds.settings.userManagementDetail('customUserId-save')}
                  control={control}
                  type="submit"
                  title={i18n.t('general.actions.save')}
                />
              </Show>
            </HStack>
          )}
        </Form>
      ),
    });
  }

  return (
    <>
      <InfoCard
        data-testid={testIds.settings.userManagementDetail('infoCard')}
        title={i18n.t('page.settings.labels.userInfo')}
        rows={viewUserContent}
        loading={isEmpty(user) || isLoading}
        footerContent={
          !isCurrentLoginedUser ? (
            <FooterContainer data-testid={testIds.settings.userManagementDetail('blockedButton')}>
              {blocked ? unblockButton : blockButton}
            </FooterContainer>
          ) : undefined
        }
      />
      <SetPasswordModal
        data-testid={testIds.settings.userManagementDetail('setPassword')}
        onClose={() => setPasswordShow(false)}
        open={showPasswordModal}
        userId={id}
      />
      <ConfirmDialog
        key={id}
        isOpen={blockModal}
        text={`${i18n.t('general.actions.block')} ${i18n.t('general.labels.user')}?`}
        onClose={() => showBlockModal(false)}
        data-testid={testIds.settings.userManagementDetail('block')}
        onConfirm={() => {
          const arg = {userId: id} as BlockUserApiArg;

          blockUser(arg)
            .unwrap()
            .then((res) => {
              if (res && res?.id) {
                // eslint-disable-next-line no-restricted-syntax
                dispatch(blockUserSuccess(res as unknown as User));

                showNotification.success(
                  `${i18n.t('general.labels.user')} ${firstName} ${lastName} ${i18n.t(
                    'general.notifications.blockedSuccess'
                  )}`
                );
              }

              showBlockModal(false);
            });
        }}
      />
      <ConfirmDialog
        key={id}
        isOpen={unblockModal}
        data-testid={testIds.settings.userManagementDetail('unblock')}
        text={`${i18n.t('general.actions.unblock')} ${i18n.t('general.labels.user')}?`}
        onClose={() => showUnblockModal(false)}
        onConfirm={() => {
          const arg = {userId: id} as UnblockUserApiArg;

          unblockUser(arg)
            .unwrap()
            .then((res) => {
              if (res && res?.id) {
                // eslint-disable-next-line no-restricted-syntax
                dispatch(unblockUserSuccess(res as unknown as User));
                showNotification.success(
                  `${i18n.t('general.labels.user')} ${firstName} ${lastName} ${i18n.t(
                    'general.notifications.unblockedSuccess'
                  )}`
                );
              }

              showUnblockModal(false);
            });
        }}
      />
    </>
  );
};

const Ul = styled.ul`
  margin: 0px 0 10px 0;
  padding: 0;
  list-style-type: none;

  li {
    margin: 0px 0 10px 0;
  }
`;

const BranchOption = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

const RolesWrapper = styled.div`
  padding-left: 4px;
`;

const MainRolesWrapper = styled.div`
  padding: 4px;
`;

const ResetPassword = styled(Hyperlink)`
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 12px;
`;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const BranchStarIcon = ({active}: {active: boolean}) => (
  <Clickable>
    <Box paddingRight={4}>
      <Icon
        value="toggle/star"
        color={active ? 'palettes.blue.60.100' : 'palettes.neutral.70.40'}
      />
    </Box>
  </Clickable>
);

export const ViewUser = ViewUserLayout;
