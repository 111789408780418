import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  openDialog,
  showNotification,
} from 'platform/components';
import {Text, VStack} from 'platform/foundation';
import {object} from 'yup';

import {useEffect, useState} from 'react';

import {find, isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  useCreatePurchaseBrokerageCancelationDocumentMutation,
  useEndPurchaseBrokerageBusinessCaseMutation,
  useGetPurchaseBrokerageTemplatesQuery,
} from '@dms/api/businessCase';
import {TemplatesListItemResponseBody} from '@dms/api/checkout';
import {TemplateListItemResponseBody, FileResponseBody} from '@dms/api/shared';
import i18n from '@dms/i18n';
import {businessCaseRoutes} from '@dms/routes';
import {DocumentsDialog, DocumentTemplateBox, handleApiError, useCodeList} from '@dms/shared';

import {
  composePath,
  convertStringToCamelCase,
  suffixTestId,
  TestIdProps,
  useNavigate,
  yupString,
} from 'shared';

interface BusinessCaseCancellationDialogProps extends TestIdProps {
  businessCaseId: string;
  customerId?: string;
  onCancel?: () => void;
}

const cancellationDocumentType = 'purchase-brokerage-cancelation';

type FormValues = {
  reasonCloseCodeId: string;
  reasonCloseNote: string;
};

export function BusinessCaseCancellationDialog(props: BusinessCaseCancellationDialogProps) {
  const navigate = useNavigate();
  const [closeReasons, {isLoading: isCodeListLoading, isError: isCodeListError}] = useCodeList(
    'unsuccessful_business_case_reason'
  );
  const [cancellationDocument, setCancellationDocument] = useState<TemplatesListItemResponseBody>();

  const [isDocumentSelected, setIsDocumentSelected] = useState(true);
  const [documentTemplate, setDocumentTemplate] = useState<TemplateListItemResponseBody>();
  const [documentNote, setDocumentNote] = useState<string | null>();

  const [createCancellationDocument] = useCreatePurchaseBrokerageCancelationDocumentMutation();
  const [brokerageCancellation] = useEndPurchaseBrokerageBusinessCaseMutation();

  const {data: documentTemplates, isLoading, isError} = useGetPurchaseBrokerageTemplatesQuery();

  useEffect(() => {
    if ((cancellationDocument && documentTemplate) || isNilOrEmpty(documentTemplates)) {
      return;
    }

    const document = find(
      (document) => document.type === cancellationDocumentType,
      documentTemplates ?? []
    );
    const template = document?.templates?.find((template) => !!template.primary);

    setCancellationDocument(document);
    setDocumentTemplate(template ?? document?.templates?.[0]);
  }, [documentTemplates, cancellationDocument, documentTemplate]);

  const reasonOptions = closeReasons?.map((reason) => ({
    value: reason.codeId,
    label: reason.name,
  }));

  const handleDocumentGenerated = (documents: FileResponseBody) => {
    openDialog(
      <DocumentsDialog
        customerId={props.customerId}
        documents={[documents]}
        isDocxFormat
        text={i18n.t('page.businessCase.labels.cancellationDocumentCreatedNote')}
      />,
      {
        title: i18n.t('page.businessCase.labels.cancellationDocumentCreated'),
        buttons: [
          {
            variant: 'secondary',
            title: i18n.t('general.actions.close'),
            onClick: closeCurrentDialog,
          },
        ],
      }
    );
  };

  const handleSubmit: FormSubmitHandler<FormValues> = (values) =>
    brokerageCancellation({
      businessCaseId: props.businessCaseId,
      closeBusinessCaseRequestBody: values,
    })
      .unwrap()
      .then(() => {
        showNotification.success();

        navigate(composePath(businessCaseRoutes.overview, {params: {id: props.businessCaseId}}));

        if (!isDocumentSelected || !documentTemplate) {
          return;
        }

        return createCancellationDocument({
          brokerageContractCancelationRequestBody: {
            businessCaseId: props.businessCaseId,
            templateId: documentTemplate.id,
            note: documentNote ?? null,
          },
        })
          .unwrap()
          .then(handleDocumentGenerated)
          .catch(handleApiError);
      })
      .then(props.onCancel)
      .catch(handleApiError);

  return (
    <DataStatus isEmpty={isNil(cancellationDocument)} isLoading={isLoading} isError={isError}>
      <VStack spacing={4}>
        <Text size="small" color="secondary">
          {i18n.t('page.businessCase.labels.brokerageCancellationDialogText')}
        </Text>
        <DocumentTemplateBox
          title={i18n.t(
            `entity.document.labels.${convertStringToCamelCase(cancellationDocumentType)}`
          )}
          templates={cancellationDocument?.templates}
          selectedTemplate={documentTemplate}
          isSelected={isDocumentSelected}
          noteLabel={i18n.t('page.businessCase.labels.cancellationReason')}
          onSelect={setIsDocumentSelected}
          onTemplateChange={setDocumentTemplate}
          onNoteChange={setDocumentNote}
          isWithNote
        />
        <Form<FormValues> onSubmit={handleSubmit} schema={schema}>
          {(control) => (
            <VStack spacing={4}>
              <FormField
                data-testid={suffixTestId('reasonChoice', props)}
                label={i18n.t('general.labels.reason')}
                name="reasonCloseCodeId"
                type="choice"
                options={reasonOptions}
                isDisabled={isCodeListLoading || isCodeListError}
                control={control}
                isRequired
              />
              <FormField
                data-testid={suffixTestId('reasonCloseNote', props)}
                label={i18n.t('general.labels.note')}
                name="reasonCloseNote"
                type="textarea"
                minRows={2}
                control={control}
              />
              <ButtonGroup align="right">
                <Button
                  data-testid={suffixTestId('cancelButton', props)}
                  title={i18n.t('general.actions.cancel')}
                  variant="secondary"
                  onClick={closeCurrentDialog}
                />
                <FormButton
                  data-testid={suffixTestId('submitButton', props)}
                  title={i18n.t('general.actions.endCase')}
                  type="submit"
                  control={control}
                />
              </ButtonGroup>
            </VStack>
          )}
        </Form>
      </VStack>
    </DataStatus>
  );
}

const schema = object({
  reasonCloseCodeId: yupString.required(),
  reasonCloseNote: yupString,
});
