import {Module} from '@dms/api/shared';
import {moduleKeys} from '@dms/config';
import {featureFlags} from '@dms/feature-flags';
import {workshopRoutes} from '@dms/routes';
import {permissions} from '@dms/shared';

import {CreateServiceCase} from './pages/CreateServiceCase/CreateServiceCase';
import {PostponedRequests} from './pages/PostponedRequests/PostponedRequests';
import {ServiceCampaignDetail} from './pages/ServiceCampaignDetail/ServiceCampaignDetail';
import {ServiceCampaignsOverview} from './pages/ServiceCampaigns/ServiceCampaignsOverview';
import {ServiceCaseDetail} from './pages/ServiceCaseDetail/ServiceCaseDetail';
import {WorkshopOverview} from './pages/WorkshopOverview/WorkshopOverview';

export const WorkshopModule: Module = {
  id: moduleKeys.workshop,
  layoutProps: {
    title: 'module.workshop.title',
    icon: 'action/build',
    iconV2: 'sidebar/workshop',
  },
  routerProps: {
    path: workshopRoutes.overview,
    element: <WorkshopOverview />,
  },
  requiredFeatureFlag: featureFlags.ACL_WORKSHOP,
  requiredPermission: permissions.serviceCaseRead,
  content: [
    {
      id: 'workshop-serviceCases',
      routerProps: {
        path: workshopRoutes.serviceCases,
        element: <WorkshopOverview />,
      },
      layoutProps: {
        title: 'page.workshop.labels.serviceCases',
        icon: 'sidebar/workshop_service_cases',
      },
    },
    {
      id: 'workshop-serviceOrders',
      routerProps: {
        path: workshopRoutes.serviceOrders,
        element: <WorkshopOverview />,
      },
      layoutProps: {
        title: 'page.workshop.labels.serviceOrders',
        icon: 'sidebar/workshop_service_orders',
      },
    },
    {
      id: 'workshop-directSales',
      routerProps: {
        path: workshopRoutes.directSales,
        element: <WorkshopOverview />,
      },
      layoutProps: {
        title: 'page.workshop.labels.directSales',
        icon: 'sidebar/workshop_direct_sales',
      },
    },
    {
      id: 'workshop-labour-operation-settings',
      routerProps: {
        path: workshopRoutes.labourOperationSettings,
        element: <WorkshopOverview />,
      },
      layoutProps: {
        title: 'page.workshop.labels.labourOperationSettings',
        icon: 'sidebar/workshop_labour_operation_settings',
      },
    },
    {
      id: 'workshop-draftServiceCase',
      routerProps: {
        path: workshopRoutes.draftServiceCase,
        element: <CreateServiceCase />,
      },
    },
    {
      id: 'workshop-createServiceCase',
      routerProps: {
        path: workshopRoutes.createServiceCase,
        element: <CreateServiceCase />,
      },
      layoutProps: {
        title: 'page.workshop.labels.newServiceCase',
        isPartOfCreateMenuItem: true,
      },
    },
    {
      id: 'workshop-serviceCase-detail',
      routerProps: {
        path: workshopRoutes.serviceCaseDetail,
        element: <ServiceCaseDetail />,
      },
    },
    {
      id: 'workshop-service-campaigns',
      routerProps: {
        path: workshopRoutes.serviceCampaigns,
        element: <ServiceCampaignsOverview />,
      },
      layoutProps: {
        title: 'page.workshop.labels.serviceCampaigns',
        icon: 'sidebar/workshop_service_campaigns',
      },
    },
    {
      id: 'workshop-service-campaigns-Hyundai',
      routerProps: {
        path: workshopRoutes.serviceCampaignsUnfinished,
        element: <ServiceCampaignsOverview />,
      },
    },
    {
      id: 'workshop-service-campaign-detail',
      routerProps: {
        path: workshopRoutes.serviceCampaignDetail,
        element: <ServiceCampaignDetail />,
      },
    },
    {
      id: 'workshop-postponed-requests',
      routerProps: {
        path: workshopRoutes.postponedRequests,
        element: <PostponedRequests />,
      },
      layoutProps: {
        title: 'page.workshop.labels.postponedRequests',
        icon: 'sidebar/workshop_postponed_requests',
      },
    },
  ],
};
