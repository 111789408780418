import {omneticApi} from '@dms/api/core';

import {EditTopMakesApiArg, EditTopMakesApiResponse, GetTopMakesApiResponse} from './types';

export const topMakesApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    editTopMakes: build.mutation<EditTopMakesApiResponse, EditTopMakesApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/top-makes`,
        method: 'PUT',
        body: queryArg.editTopMakesRequestBody,
      }),
      invalidatesTags: ['TopMakes'],
    }),
    getTopMakes: build.query<GetTopMakesApiResponse, void>({
      query: () => ({
        url: `/dms/v1/vehicle/top-makes`,
      }),
      providesTags: ['TopMakes'],
    }),
  }),
});

export const {useEditTopMakesMutation, useGetTopMakesQuery, useLazyGetTopMakesQuery} = topMakesApi;
