import type {i18n} from 'i18next';
import {z} from 'zod';

import {isNil} from 'ramda';

import {MoneySchema} from '@dms/api/shared';

export const CoercedMoneySchema = MoneySchema.extend({
  amount: z.coerce.string().nullable(),
  currency: z.string(),
});

export type CoercedMoney = z.infer<typeof CoercedMoneySchema>;

export const VatDirectionSchema = z.enum(['FROM_PRICE_WITH_VAT', 'FROM_PRICE_WITHOUT_VAT']);
export const CalculationTypeSchema = z.enum(['SIMPLE', 'CALCULATED']);
export const TransactionTypeSchema = z.enum(['ONE_TIME', 'RECURRING']);

export type VatDirection = z.infer<typeof VatDirectionSchema>;
export type CalculationType = z.infer<typeof CalculationTypeSchema>;
export type TransactionType = z.infer<typeof TransactionTypeSchema>;

export const VatCodePriceSchema = z.object({
  withVat: CoercedMoneySchema,
  withoutVat: CoercedMoneySchema,
  vatDirection: VatDirectionSchema,
  vatRate: z.string(),
  vatType: z.string(),
});

export const BaseTransactionFormSchema = z.object({
  name: z.string().nonempty(),
  category: z.tuple([z.string()]).nullable().optional(),
  expectedPrice: VatCodePriceSchema.nullable(),
});

export type BaseTransactionFormSchemaType = z.infer<typeof BaseTransactionFormSchema>;

const BaseFormSchema = z
  .object({
    note: z.string().nonempty().nullable().optional(),
    fileId: z.string().uuid().nullable(),
    transactionType: z.tuple([TransactionTypeSchema]),
    calculationType: z.tuple([CalculationTypeSchema]).nullable(),
    startDate: z.string().nullable(),
    endDate: z.string().nullable(),
    interestPA: z.number().nullable(),
  })
  .merge(BaseTransactionFormSchema);

export const VehicleTransactionFormSchema = z
  .object({
    expectedPrice: VatCodePriceSchema.nullable().transform((val) => {
      if (isNil(val?.withVat?.amount) || isNil(val?.withoutVat.amount)) {
        return null;
      }
      return val;
    }),
    realPrice: VatCodePriceSchema.nullable().transform((val) => {
      if (isNil(val?.withVat?.amount) || isNil(val?.withoutVat.amount)) {
        return null;
      }
      return val;
    }),
  })
  .merge(BaseFormSchema);

export type VehicleTransactionFormSchemaType = z.infer<typeof VehicleTransactionFormSchema>;

export const getVehicleTransactionFormSchema = (i18nInstance: i18n) =>
  VehicleTransactionFormSchema.superRefine((data, ctx) => {
    if (
      data.expectedPrice === null &&
      data.realPrice === null &&
      data.transactionType?.[0] === 'ONE_TIME'
    ) {
      ['expectedPrice.withoutVat.amount', 'realPrice.withoutVat.amount'].forEach((path) => {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18nInstance.t('general.validations.oneOfThoseFieldsIsRequired'),
          path: [path],
        });
      });
    }
  }).superRefine((data, ctx) => {
    const transactionType = data.transactionType?.[0];
    const calculationType = data.calculationType?.[0];

    if (transactionType === 'RECURRING') {
      if (!data.startDate) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18nInstance.t('general.validations.fieldIsRequired'),
          path: ['startDate'],
        });
      }

      if (!data.endDate) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18nInstance.t('general.validations.fieldIsRequired'),
          path: ['endDate'],
        });
      }

      if (calculationType === 'CALCULATED' && data.interestPA === null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18nInstance.t('general.validations.fieldIsRequired'),
          path: ['interestPA'],
        });
      }

      if (data.realPrice === null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18nInstance.t('general.validations.fieldIsRequired'),
          path: ['realPrice.withoutVat.amount'],
        });
      }
    }
  });
