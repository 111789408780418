import {DataStatus, showNotification} from 'platform/components';

import {useDispatch} from 'react-redux';

import {useGetKonzultaConfigQuery, usePatchKonzultaConfigMutation} from '@dms/api/tenant';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';
import {setKonzultaConfig} from '@dms/teas';

import {buildArray} from 'shared';

import {
  SettingsStackItemProps,
  SettingsStackItems,
} from '../../../components/SettingsStackItems/SettingsStackItems';

export function Gateways() {
  const dispatch = useDispatch();

  const {data: konzultaConfigData, isLoading} = useGetKonzultaConfigQuery();
  const [patchKonzultaConfig] = usePatchKonzultaConfigMutation();

  const handleSwitchChange = (enabled: boolean) => {
    dispatch(setKonzultaConfig({enabled}));
    patchKonzultaConfig({
      konzultaConfigRequestBody: {
        enabled,
      },
    })
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
      })
      .catch(handleApiError);
  };

  const items = buildArray<SettingsStackItemProps>([
    {
      cyId: testIds.settings.smsGatewaysKonzulta('link'),
      title: i18n.t('page.integrations.labels.smsGatewayKonzulta'),
      url: settingsRoutes.smsGatewaysKonzulta,
      switchProps: {
        name: 'sms-gateways-konzulta',
        value: konzultaConfigData?.enabled ?? false,
        onChange: handleSwitchChange,
      },
    },
  ]);

  return (
    <DataStatus data={konzultaConfigData} isLoading={isLoading}>
      {() => <SettingsStackItems items={items} />}
    </DataStatus>
  );
}
