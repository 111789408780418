import {closeDialog, DataStatus, FormSubmitHandler, showNotification} from 'platform/components';
import {Show} from 'platform/foundation';

import {
  useGetStorageLocationByIdApiQuery,
  usePatchStorageLocationV2Mutation,
} from '@dms/api/metadaStorageLocation';
import {CreateTireLocationForm, handleApiError, SingleTireLocationForm} from '@dms/shared';

import {refreshDatagrid} from 'features/datagrid';

import {VectorType} from '../types/MultipleLocationsForm';
import {SingleLocationFormValue, StorageLocationDefinition} from '../types/SingleLocationFormValue';
import {SingleLocationFromMultipleLocations} from './SingleLocationFromMultipleLocations';

/**
 * LORE!
 *
 * Before you dive deep in this insanity read well my dear coder.
 *
 * You need to think out of the bounds as this stuff requires some
 * next gen level of brain grey mass. Like for real, no cap.
 *
 * Imagine, you have a **single storage location** inside **warehouse**.
 * Let's call it SSL (haha add more confusion, why not).
 *
 * Good.
 *
 * Now, this SSL can be two types:
 *  - Automatically created via "Create multiple locations" button, let's call it **ACSL**
 *  - Manually created via "Create single location" button, let's call it **MCSL**
 *
 * Good.
 *
 * When this component is mounted, the **useGetStorageLocationByIdApiQuery** is called.
 * This returns data object with **isSingleLocation** prop on it.
 * That prop is telling you whether this SSL is ACSL or MCSL.
 *
 * If it is MCSL, then you must show only OTHER vector field.
 * For this use case we use form from shared **CreateTireLocation** component.
 *
 * If it is ACSL, then you must show ALL vector fields.
 * But editable are only those which has been selected as "selected" during generating multiple locations.
 * For this use case second **SingleLocationFromMultipleLocations** form.
 *
 * Confused little now? Let's add more confusion.
 *
 * Both of these distinct forms returns different data structure.
 * This structure needs to be transformed into common one.
 * Then it is send via **usePatchStorageLocationV2Mutation** to the Metada.
 * There happens a dark & dirty magic which separates ACSL and MCSL.
 *
 * Goal was to reuse forms for both creating and editing as the UI is the same
 * but "create" and "edit" endpoints use absolutelly distinct data structure.
 *
 * Now you know.
 *
 * Forgive the naming, I have not idea how to name it properly. Everything is single and multiple at once.
 * These things should not even exist, but hey, c'est la vie.
 */

interface SingleLocationDetailProps {
  storageLocationId: string;
  dialogId: string;
}

export function SingleLocationDetail(props: SingleLocationDetailProps) {
  const locationDetailQuery = useGetStorageLocationByIdApiQuery({
    storageLocationId: props.storageLocationId,
  });

  const [updateSingleLocation, queryStatus] = usePatchStorageLocationV2Mutation();

  // ### MCSL DRIVERS ###

  const getSingleLocationDefaultValues = (): CreateTireLocationForm => ({
    allowMultipleUsage: locationDetailQuery.data?.allowMultipleUsage ?? false,
    location:
      locationDetailQuery.data?.definition?.vectors.find(
        (item) => item?.vectorType === VectorType.Other
      )?.symbol || '',
    note: locationDetailQuery.data?.note || '',
  });

  const handleSingleLocationFormSubmit: FormSubmitHandler<CreateTireLocationForm> = async (
    formData
  ) => {
    await updateSingleLocation({
      storageLocationId: props.storageLocationId,
      body: {
        definition: {
          vectors:
            locationDetailQuery.data?.definition?.vectors.map((item) => {
              if (item?.vectorType === VectorType.Other) {
                return {
                  ...item,
                  symbol: formData.location,
                };
              }

              return item;
            }) || [],
          separator: locationDetailQuery.data?.definition?.separator || '',
        },
        allowMultipleUsage: formData.allowMultipleUsage,
        note: formData.note,
      },
    })
      .unwrap()
      .then(() => {
        showNotification.success();
        refreshDatagrid('storage-location-tire-warehouse');
        closeDialog(props.dialogId);
      })
      .catch(handleApiError);
  };

  // ### END MCLS ###
  // ******************
  // ### ACSL DRIVERS ###

  const getMultipleLocationDefaultValues = (): SingleLocationFormValue | undefined => {
    const vectors = locationDetailQuery.data?.definition?.vectors;

    if (!vectors) {
      return undefined;
    }

    return {
      storageLocation: vectors
        .filter((item) => !!item?.vectorType)
        .map(
          (item) =>
            ({
              isSelected: item?.isSelected ?? false,
              vectorType: (item?.vectorType as VectorType) ?? VectorType.Aisle,
              vectorPosition: item?.vectorPosition ?? 1,
              symbol: item?.symbol ?? '',
            }) as StorageLocationDefinition
        ),
      note: locationDetailQuery.data?.note || '',
      allowMultipleUsage: locationDetailQuery.data?.allowMultipleUsage ?? false,
    };
  };

  const handleMultipleLocationFormSubmit = async (formData: SingleLocationFormValue) => {
    await updateSingleLocation({
      storageLocationId: props.storageLocationId,
      body: {
        definition: {
          vectors: formData.storageLocation,
          separator: locationDetailQuery.data?.definition?.separator || '',
        },
        note: formData.note,
        allowMultipleUsage: formData.allowMultipleUsage,
      },
    })
      .unwrap()
      .then(() => {
        showNotification.success();
        refreshDatagrid('storage-location-tire-warehouse');
        closeDialog(props.dialogId);
      })
      .catch(handleApiError);
  };

  // ### END ACSL ###

  return (
    <DataStatus isLoading={locationDetailQuery.isLoading} isError={locationDetailQuery.isError}>
      <Show when={locationDetailQuery.data?.isSingleLocation}>
        <SingleTireLocationForm
          isLoading={queryStatus.isLoading}
          defaultValues={getSingleLocationDefaultValues()}
          handleSubmit={handleSingleLocationFormSubmit}
          handleClose={() => closeDialog(props.dialogId)}
        />
      </Show>
      <Show when={!locationDetailQuery.data?.isSingleLocation}>
        <SingleLocationFromMultipleLocations
          dialogId={props.dialogId}
          isLoading={queryStatus.isLoading}
          defaultValues={getMultipleLocationDefaultValues()}
          onFormSubmit={handleMultipleLocationFormSubmit}
        />
      </Show>
    </DataStatus>
  );
}
