import {isFeatureEnabled} from 'feature-flags';
import {Attributes, Card, DataStatus} from 'platform/components';
import {useDateTimeFormatter} from 'platform/locale';

import {useNavigate} from 'react-router-dom';

import {isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {useGetVehicleV2Query} from '@dms/api/vehicle';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {testIds, vehiclesRoutes} from '@dms/routes';

import {parseDate, composePath} from 'shared';

type ModelSpecificationCardProps = {
  vehicleId: string;
  canEditVehicle: boolean;
  isSaleVehicle?: boolean;
};

function ModelSpecificationCard({
  vehicleId,
  canEditVehicle,
  isSaleVehicle,
}: ModelSpecificationCardProps) {
  const navigate = useNavigate();
  const {data: vehicle, isLoading, isError} = useGetVehicleV2Query({vehicleId});

  const formatDateTime = useDateTimeFormatter();

  const handleOnClick = () => {
    if (!isSaleVehicle && isFeatureEnabled(featureFlags.AFTS_SERVICE_VEHICLE_ENTITY_V2)) {
      return navigate(
        composePath(vehiclesRoutes.editServiceVehicle, {
          params: {id: vehicleId},
          queryParams: {tab: 'vehicle-data'},
        })
      );
    }

    return navigate(
      composePath(vehiclesRoutes.editDetailTab, {params: {id: vehicleId, tab: 'vehicle-data'}})
    );
  };

  return (
    <Card
      title={i18n.t('entity.vehicle.labels.warrantyAndValidity')}
      actions={
        canEditVehicle
          ? [
              {
                variant: 'link',
                type: 'button',
                leftIcon: 'image/edit',
                onClick: handleOnClick,
                'data-testid': testIds.vehicles.vehicleData('warrantyAndValidity-edit'),
                title: i18n.t('general.actions.edit'),
              },
            ]
          : undefined
      }
      data-testid={testIds.vehicles.vehicleData('warrantyAndValidity')}
    >
      <DataStatus isLoading={isLoading} isError={isError}>
        <Attributes
          data-testid={testIds.vehicles.vehicleData('warrantyAndValidity-parameters')}
          rows={[
            {
              label: i18n.t('entity.vehicle.labels.warrantyUntil'),
              value: isNotNil(vehicle?.warrantyDate)
                ? formatDateTime('dateShort', parseDate(vehicle?.warrantyDate ?? ''))
                : null,
              testId: 'warrantyUntil',
            },
            {
              label: i18n.t('entity.vehicle.labels.warrantyMileageLimit'),
              value: String(isNil(vehicle?.warrantyMileage) ? '' : vehicle?.warrantyMileage),
              testId: 'warrantyMileageLimit',
            },
            {
              label: i18n.t('entity.vehicle.labels.serviceContractUntil'),
              value: vehicle?.serviceContractUntil
                ? formatDateTime('dateShort', parseDate(vehicle?.serviceContractUntil))
                : null,
              testId: 'serviceContractUntil',
            },
            {
              label: i18n.t('entity.vehicle.labels.serviceContractMileageLimit'),
              value: vehicle?.serviceContractMileageLimit,
              testId: 'serviceContractMileageLimit',
            },
            {
              label: i18n.t('entity.vehicle.labels.serviceContractNote'),
              value: vehicle?.serviceContractNote,
              testId: 'serviceContractNote',
            },
          ]}
        />
      </DataStatus>
    </Card>
  );
}

export const WarrantyCard = ModelSpecificationCard;
