import {
  closeCurrentDialog,
  ColorSchemeType,
  Flag,
  FlagSizeType,
  openDialog,
  showNotification,
  Tag,
} from 'platform/components';

import {isNilOrEmpty} from 'ramda-adjunct';

import {EntityResourceIds} from '@dms/api/shared';
import {useUnassignTagMutation} from '@dms/api/tag';
import i18n from '@dms/i18n';

import {noop, suffixTestId, TestIdProps} from 'shared';

import {handleApiError} from '../../../../utils/handleApiError';
import {CREATE_OR_EDIT_TAG_DIALOG_ID} from './const';
import {CreateOrEditTagForm} from './CreateOrEditTagForm';

interface EditableTagProps extends TestIdProps {
  recordId: string;
  resourceId: EntityResourceIds;
  tagId: string;
  tagValueId: string;
  label: string;
  size?: FlagSizeType;
  color: ColorSchemeType;
  isTagDeletable?: boolean;
  isTagUpdatable?: boolean;
  isTagCreatable?: boolean;
  isTagUnassignable?: boolean;
}

export function EditableTag({
  label,
  color,
  size,
  isTagCreatable,
  isTagDeletable,
  isTagUpdatable,
  isTagUnassignable,
  ...tagProps
}: EditableTagProps) {
  const [unassignTag] = useUnassignTagMutation();

  const handleUnassignTag = () =>
    unassignTag(tagProps)
      .unwrap()
      .then(() => {
        showNotification.success();
        closeCurrentDialog();
      })
      .catch(handleApiError);

  const handleEditTag = () =>
    isTagUpdatable
      ? openDialog(
          <CreateOrEditTagForm
            isDeletable={isTagDeletable}
            isCreatable={isTagCreatable}
            {...tagProps}
          />,
          {
            title: i18n.t('general.labels.editTag'),
            size: 'small',
            scrollBehavior: 'outside',
            id: CREATE_OR_EDIT_TAG_DIALOG_ID,
          }
        )
      : noop;

  const [parsedColor, isSubtle] = color.split('.') as [ColorSchemeType, string | undefined];

  if (isTagUnassignable) {
    return (
      <Tag
        onClick={handleEditTag}
        size={size}
        onDelete={handleUnassignTag}
        colorScheme={parsedColor}
        isSubtle={!isNilOrEmpty(isSubtle)}
        label={label}
        data-testid={suffixTestId('tag', tagProps)}
      />
    );
  }

  return (
    <span onClick={handleEditTag}>
      <Flag
        label={label}
        colorScheme={parsedColor}
        isSubtle={!isNilOrEmpty(isSubtle)}
        data-testid={suffixTestId('tag', tagProps)}
        size={size}
      />
    </span>
  );
}
