import {AxiosError} from 'axios';
import {showNotification} from 'platform/components';
import {Box} from 'platform/foundation';
import {v4 as uuidv4} from 'uuid';

import {ReactElement, useCallback, useState} from 'react';
import {useField} from 'react-final-form';
import {useSelector} from 'react-redux';

import {isNil, pluck} from 'ramda';
import {compact} from 'ramda-adjunct';

import {useGetParticipationQuery} from '@dms/api/participation';
import {ApiException, EntityResourceIds, VehicleAlbums} from '@dms/api/shared';
import {vehicleApi} from '@dms/api/vehicle';
import {testIds} from '@dms/routes';
import {usePermissions} from '@dms/shared';

import {useThunkDispatch} from '../../../hooks/useThunkDispatch';
import {UploadedFile} from '../../../services/UploadFileService';
import {
  addPhotosToAlbumRequest,
  deletePhotosRequest,
  movePhotosRequest,
} from '../../../store/carDetails/actions';
import {selectCarDetails} from '../../../store/carDetails/selectors';
import {CreateVehicleRequestBody} from '../../../types/CreateVehicleRequestBody';
import {filterSortImageByWeight} from '../../../utils/filterSortImageByWeight';
import {getStringErrorMessage} from '../../../utils/getStringErrorMessage';
import {DraggableDataWithContextId} from '../../CustomDragAndDrop/types';
import {useFormRenderer} from '../../FinalForm/hooks/useFormRenderer';
import {ImageType} from '../../ImagesCard/types';
import {UploadPhotos} from './UploadPhotos';

interface PhotosTabProps {
  vehicleId?: string;
}

let uploaderChanged = false;

export const PhotosTab = (props: PhotosTabProps): ReactElement => {
  const {data: vehicleParticipation} = useGetParticipationQuery(
    {
      resourceId: EntityResourceIds.vehicle,
      recordId: props.vehicleId ?? '',
    },
    {skip: isNil(props.vehicleId)}
  );

  const vehicle = useSelector(selectCarDetails);

  const [canUploadVehicleAlbumPhotos, canDeleteVehicleAlbumPhotos] = usePermissions({
    permissionKeys: ['createVehicleAlbumPhotos', 'deleteVehicleAlbumPhoto'],
    scopes: {
      createVehicleAlbumPhotos: {
        participation: vehicleParticipation,
        branchId: vehicle?.vehicleDetail?.data?.branchId,
      },
      deleteVehicleAlbumPhoto: {
        participation: vehicleParticipation,
        branchId: vehicle?.vehicleDetail?.data?.branchId,
      },
    },
  });

  const dispatch = useThunkDispatch();
  const {input} = useField('photos');
  const {Field} = useFormRenderer<CreateVehicleRequestBody>();
  const [photos, setPhotos] = useState<ImageType[]>(input.value);

  const getSalesVehicleAlbum = () => {
    const detail = vehicle?.vehicleDetail?.data;
    const salesAlbumId = detail?.albums?.find(
      (a) => a?.name === VehicleAlbums.PHOTOS_CAR_PURCHASE
    )?.id;

    return salesAlbumId || '';
  };

  const getPhotos = (): ImageType[] => {
    if (props.vehicleId) {
      const salesAlbumId = getSalesVehicleAlbum();
      return filterSortImageByWeight(vehicle?.vehicleDetail?.data?.photos || [], salesAlbumId);
    }

    return photos;
  };

  const handleDragEnd = (
    items: DraggableDataWithContextId<ImageType>[],
    position: number,
    drop?: DraggableDataWithContextId<ImageType>
  ) => {
    const ids = items.map((i) => i.data?.id);
    const newImages: ImageType[] = [];

    if (props.vehicleId) {
      const salesAlbum = getSalesVehicleAlbum();

      dispatch(
        movePhotosRequest({
          vehicleId: props.vehicleId,
          photos: ids,
          position: position === null ? null : (drop?.data.weight as number),
          sourceAlbum: salesAlbum,
          destinationAlbum: salesAlbum,
        })
      );
      return;
    } else {
      photos.forEach((p, i) => {
        const found = ids.find((i) => i === p.id);

        if (i === position) {
          newImages.push(...items.map((i) => ({...i.data})));
        }

        if (!found) {
          newImages.push(p);
        }
      });

      if (position >= photos.length) {
        newImages.push(...items.map((i) => ({...i.data})));
      }

      setPhotos(newImages.map((i) => ({...i, isSelected: undefined})));
    }
  };

  const handlePhotoDeletion = (ids: string[]) => {
    if (!canDeleteVehicleAlbumPhotos) {
      return;
    }
    if (props.vehicleId) {
      dispatch(
        deletePhotosRequest({
          vehicleId: props.vehicleId,
          albumId: getSalesVehicleAlbum(),
          photos: ids,
        })
      );
      return;
    }

    const _photos = [...photos];

    ids?.forEach((i) => {
      const foundPhoto = _photos.findIndex((p) => p.id === i);

      if (foundPhoto !== -1) {
        _photos.splice(foundPhoto, 1);
      }
    });

    setPhotos(_photos);
  };

  const handleFileChange = useCallback(
    async (files: UploadedFile[]) => {
      uploaderChanged = true;

      if (props.vehicleId) {
        const detail = vehicle?.vehicleDetail?.data;
        const salesAlbumId = detail?.albums?.find(
          (a) => a?.name === VehicleAlbums.PHOTOS_CAR_PURCHASE
        )?.id;
        const fileIds = compact(pluck('fileId', files));

        if (salesAlbumId && fileIds.length) {
          try {
            await dispatch(addPhotosToAlbumRequest({albumId: salesAlbumId, fileIds}));
            dispatch(
              vehicleApi.util.invalidateTags([
                {type: 'SaleVehicle', id: props.vehicleId},
                {type: 'SaleVehicleActions', id: props.vehicleId},
              ])
            );
          } catch (error) {
            showNotification.error(getStringErrorMessage(error as AxiosError<ApiException>));
          }
        }
      } else {
        const filesWithId: ImageType[] = files.map((file) => ({...file, id: uuidv4()}));
        setPhotos(input.value ? [...input.value, ...filesWithId] : filesWithId);
      }
    },
    [input, dispatch, props.vehicleId, vehicle?.vehicleDetail?.data]
  );

  return (
    <>
      <Box>
        <Field
          name="photos"
          component={({input}) => {
            if (!props.vehicleId && uploaderChanged) {
              input.onChange(getPhotos());
            }

            return (
              <UploadPhotos
                album={
                  props.vehicleId
                    ? vehicle?.vehicleDetail?.data?.albums?.find(
                        (a) => a.name === VehicleAlbums.PHOTOS_CAR_PURCHASE
                      )
                    : undefined
                }
                images={getPhotos()}
                isAddForm={!props.vehicleId && canUploadVehicleAlbumPhotos}
                isUploadDisabled={!canUploadVehicleAlbumPhotos}
                isDeleteDisabled={!canDeleteVehicleAlbumPhotos}
                onDelete={handlePhotoDeletion}
                onChange={handleFileChange}
                onDragEnd={handleDragEnd}
                data-testid={testIds.vehicles.create('photos')}
              />
            );
          }}
        />
      </Box>
    </>
  );
};
