import {omneticApi} from '@dms/api/core';

import {
  GetMyKanbanSelectedUsersApiResponse,
  SetMyKanbanSelectedUsersApiArg,
  SetMyKanbanSelectedUsersApiResponse,
  getMyKanbanSelectedUsersResponseSchema,
  setMyKanbanSelectedUsersRequestBodySchema,
} from './types';

export const kanbanApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getMyKanbanSelectedUsers: build.query<GetMyKanbanSelectedUsersApiResponse, void>({
      query: () => ({
        url: `/dms/v1/kanban/selected-users`,
      }),
      providesTags: ['KanbanUsers'],
      extraOptions: {
        responseSchema: getMyKanbanSelectedUsersResponseSchema,
      },
    }),
    setMyKanbanSelectedUsers: build.mutation<
      SetMyKanbanSelectedUsersApiResponse,
      SetMyKanbanSelectedUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/kanban/selected-users`,
        method: 'PUT',
        body: queryArg.setMyKanbanSelectedUsersRequestBody,
      }),
      invalidatesTags: ['KanbanUsers'],
      extraOptions: {
        requestSchema: setMyKanbanSelectedUsersRequestBodySchema,
      },
    }),
  }),
});
