import {z} from 'zod';

import {BaseIdSchema, BaseVoidRequestSchema, BaseVoidResponseSchema} from '@dms/api/shared';

/**
 * Base
 */
export const BaseHandlingUnitSchema = z.object({
  name: z.string(),
  description: z.string().nullish(),
  isDefault: z.boolean(),
  isActive: z.boolean().nullish(),
});
export type BaseHandlingUnit = z.infer<typeof BaseHandlingUnitSchema>;

/**
 * GET
 */
export const GetWarehouseHandlingUnitsRequestSchema = BaseVoidRequestSchema;
export type GetWarehouseHandlingUnitsRequest = z.infer<
  typeof GetWarehouseHandlingUnitsRequestSchema
>;

export const GetWarehouseHandlingUnitsResponseSchema = z.array(
  BaseHandlingUnitSchema.merge(BaseIdSchema)
);
export type GetWarehouseHandlingUnitsResponse = z.infer<
  typeof GetWarehouseHandlingUnitsResponseSchema
>;

export const GetWarehouseHandlingUnitRequestSchema = BaseIdSchema;
export type GetWarehouseHandlingUnitRequest = z.infer<typeof GetWarehouseHandlingUnitRequestSchema>;

export const GetWarehouseHandlingUnitResponseSchema = BaseHandlingUnitSchema.merge(BaseIdSchema);
export type GetWarehouseHandlingUnitResponse = z.infer<
  typeof GetWarehouseHandlingUnitResponseSchema
>;

/**
 * POST
 */
export const PostHandlingUnitRequestSchema = BaseHandlingUnitSchema;
export type PostHandlingUnitRequest = z.infer<typeof PostHandlingUnitRequestSchema>;

export const PostHandlingUnitResponseSchema = BaseHandlingUnitSchema.merge(BaseIdSchema);
export type PostHandlingUnitResponse = z.infer<typeof PostHandlingUnitResponseSchema>;

/**
 * PATCH
 */
export const PatchHandlingUnitRequestSchema = z.object({
  id: z.string(),
  body: BaseHandlingUnitSchema.optional(),
});
export type PatchHandlingUnitRequest = z.infer<typeof PatchHandlingUnitRequestSchema>;

export const PatchHandlingUnitResponseSchema = BaseVoidResponseSchema;
export type PatchHandlingUnitResponse = z.infer<typeof PatchHandlingUnitResponseSchema>;
