import {
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  OptionType,
  closeCurrentDialog,
} from 'platform/components';
import {VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {useNavigate} from 'react-router-dom';

import {useCreateRoleMutation} from '@dms/api/accessControl';
import {CreateRoleRequestBody} from '@dms/api/shared';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {composePath} from 'shared';

type RoleType = 'blank' | 'existing';

const radioButtons: OptionType<RoleType>[] = [
  {value: 'blank', label: i18n.t('general.labels.blank')},
  {value: 'existing', label: i18n.t('general.actions.duplicate')},
];

type FormValues = Pick<CreateRoleRequestBody, 'title'> & {
  roleType: string;
  baseRole: string;
};

export function NewRoleForm() {
  const navigate = useNavigate();
  const [createRole] = useCreateRoleMutation();

  const handleSubmit: FormSubmitHandler<FormValues> = async (data) => {
    const {roleType, baseRole, title} = data;

    const createRoleRequestBody = roleType === 'blank' ? {title} : {title, baseRole};

    await createRole({createRoleRequestBody})
      .unwrap()
      .then((res) =>
        navigate(
          composePath(settingsRoutes.roleManagementDetail, {
            params: {roleId: res.id},
            queryParams: {mode: 'create'},
          })
        )
      )
      .catch(handleApiError);
  };

  return (
    <Form<FormValues>
      schema={createRoleSchema}
      onSubmit={handleSubmit}
      mode="onSubmit"
      defaultValues={{roleType: 'blank'}}
    >
      {(control) => (
        <VStack spacing={4}>
          <FormField
            control={control}
            name="title"
            type="text"
            data-testid={testIds.settings.userManagement('newRoleModalTextField')}
          />

          <ButtonGroup align="right">
            <Button
              onClick={closeCurrentDialog}
              variant="secondary"
              title={i18n.t('general.actions.cancel')}
              data-testid={testIds.settings.userManagement('newRoleDiscard')}
            />
            <FormButton
              control={control}
              title={i18n.t('general.actions.save')}
              type="submit"
              variant="primary"
              data-testid={testIds.settings.userManagement('newRoleCreate')}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}

const createRoleSchema = Yup.object().shape({
  title: Yup.string().required(),
});
