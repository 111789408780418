import {Button, ButtonGroup, Choice, Dialog, DialogFooter, Separator} from 'platform/components';
import {Center, Show} from 'platform/foundation';

import {useState} from 'react';

import {MenuItemProps, UserSidebarIconSize} from '@dms/api/shared';
import {useSetUserMenuPinsMutation, useSetUserSettingsItemMutation} from '@dms/api/user';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId, pushGtmEvent} from 'shared';

import {SortableSidebarItems} from './SortableSidebarItems';

interface SidebarEditDialogProps extends RequiredTestIdProps {
  onClose: () => void;
  menuItemsById: Record<string, MenuItemProps>;
  pinnedMenuItems: string[];
  iconSize: UserSidebarIconSize;
}

export function SidebarEditDialog(props: SidebarEditDialogProps) {
  const [menuItems, setMenuItems] = useState(
    props.pinnedMenuItems.map((item) => ({id: item, isPinned: true}))
  );
  const [iconSize, setIconSize] = useState<UserSidebarIconSize>(props.iconSize);

  const [setUserSettings, {isLoading: isSettingsLoading}] = useSetUserSettingsItemMutation();
  const [setMenuPins, {isLoading: isMenuPinsLoading}] = useSetUserMenuPinsMutation();

  const onSave = () => {
    const pins = menuItems.filter((item) => item.isPinned).map((item) => ({item: item.id}));
    Promise.all([
      setMenuPins({items: pins}).unwrap(),
      setUserSettings({
        settingKey: 'sidebar_icon_size',
        userSettingRequestBody: {
          value: iconSize,
        },
      }).unwrap(),
    ])
      .then(() => {
        pushGtmEvent({
          event: 'button_click',
          event_id: 'sidebar_settings_save',
          previous_value: {iconSize: props.iconSize, pinnedItems: props.pinnedMenuItems},
          new_value: {iconSize: iconSize, pinnedItems: pins.map((pin) => pin.item)},
        });
      })
      .then(props.onClose)
      .catch(handleApiError);
  };

  const onClose = () => {
    pushGtmEvent({
      event: 'button_click',
      event_id: 'sidebar_settings_cancel',
      value: {
        pinnedItems: props.pinnedMenuItems,
        iconSize: props.iconSize,
      },
    });
    props.onClose();
  };

  return (
    <Dialog
      title={i18n.t('entity.user.labels.sidebarSettings')}
      size="small"
      isOpen={true}
      onClose={onClose}
      withAdditionalFooter
      data-testid={suffixTestId('sidebarEditDialog', props)}
    >
      <Choice<UserSidebarIconSize>
        isNotClearable
        label={i18n.t('entity.user.labels.sidebarSettingsIconSize')}
        value={iconSize}
        isLoading={isSettingsLoading || isMenuPinsLoading}
        options={[
          {value: 'default', label: i18n.t('entity.user.labels.sidebarSettingsDefault')},
          {value: 'large', label: i18n.t('entity.user.labels.sidebarSettingsLarge')},
        ]}
        onChange={(value) => {
          if (!value) {
            return;
          }
          setIconSize(value);
        }}
      />
      <Separator />
      <Show when={menuItems.length === 0}>
        <Center>{i18n.t('entity.user.labels.sidebarSettingsEmpty')}</Center>
      </Show>
      <SortableSidebarItems
        iconSize={iconSize}
        menuItems={menuItems}
        menuItemsById={props.menuItemsById}
        onChange={setMenuItems}
        data-testid={suffixTestId('sortableSidebarItems', props)}
        areActionsDisabled={isMenuPinsLoading || isSettingsLoading}
      />
      <DialogFooter>
        <ButtonGroup align="right">
          <Button
            variant="secondary"
            title={i18n.t('general.actions.cancel')}
            onClick={onClose}
            isDisabled={isMenuPinsLoading || isSettingsLoading}
            data-testid={suffixTestId('cancelButton', props)}
          />
          <Button
            title={i18n.t('general.actions.save')}
            isLoading={isMenuPinsLoading || isSettingsLoading}
            onClick={onSave}
            data-testid={suffixTestId('saveButton', props)}
            data-gtm="save-sidebar-settings"
          />
        </ButtonGroup>
      </DialogFooter>
    </Dialog>
  );
}
