import {Hide, Text} from 'platform/foundation';
import {DateTimeFormat} from 'platform/locale';
import {css, useTheme} from 'styled-components';

import {pushGtmEvent, RequiredTestIdProps, suffixTestId} from 'shared';

import {BigCalendarEvent} from '../types/BigCalendarEvent';
import {getThemeToken} from '../utils/getThemeToken';
import {repeatingGradient} from '../utils/repeatingGradient';

interface MonthEventItemProps extends RequiredTestIdProps {
  event: BigCalendarEvent;
  onClick?: (event: BigCalendarEvent) => void;
}

export function MonthEventItem(props: MonthEventItemProps) {
  const theme = useTheme();
  const EVENT_COLOR = props.event.color || 'general.black';
  const EVENT_COLOR_HEX = getThemeToken(theme, `colors.${EVENT_COLOR}`);

  const onClick = () => {
    if (props.event.isReadOnly) {
      return;
    }

    pushGtmEvent({
      event: 'button_click',
      event_id: 'big_calendar_month_event_click',
      value: {
        eventId: props.event.id,
      },
    });

    props.onClick?.(props.event);
  };

  return (
    <div
      data-testid={suffixTestId('monthEventItem', props)}
      onClick={onClick}
      css={css`
        cursor: pointer;
        display: flex;
        flex-direction: row;
        gap: 4px;
        background-color: ${props.event.alternative ? 'transparent' : EVENT_COLOR_HEX};
        background-image: ${props.event.alternative ? repeatingGradient(EVENT_COLOR_HEX) : 'none'};
        border: ${props.event.alternative ? `1px solid ${EVENT_COLOR_HEX}` : 'none'};
        border-radius: ${theme.radii.small};
        padding: 2px 4px;
        opacity: 0.9;
        transition: opacity 0.2s ease;

        &:hover {
          opacity: 1;
        }
      `}
    >
      <Hide when={props.event.isAllDay}>
        <Text
          data-testid={suffixTestId('monthEventItem-time', props)}
          size="xSmall"
          color={props.event.alternative ? 'primary' : 'white'}
        >
          <DateTimeFormat value={props.event.start} format="timeShort" />
        </Text>
      </Hide>
      <Text
        data-testid={suffixTestId('monthEventItem-title', props)}
        size="xSmall"
        color={props.event.alternative ? 'primary' : 'white'}
        noWrap
        alternative
      >
        {props.event.title}
      </Text>
    </div>
  );
}
