export const sourcingFilterConstants = {
  POWER_LIMIT_MIN: 0,
  POWER_LIMIT_MAX: 600,
  CUBIC_CAPACITY_LIMITS: [0, 2500],
  PRICE_MAX: 4000000,
  POSITION_MIN: 10,
  POSITION_MAX: 100,
  PRICE_GAP_MAX: 100,
  LIQUIDITY_MAX: 180,
  DAYS_ON_DISPLAY: 180,
  YEAR_LIMIT_MIN: 2000,
  MARGIN_LIMIT_MIN: 1000,
  MARGIN_LIMIT_MAX: 100000,
  MILEAGE_RANGE_MIN: 0,
  MILEAGE_RANGE_MAX: 300000,
  NUMBER_OF_SEATS_MIN: 0,
  NUMBER_OF_SEATS_MAX: 9,
  AVG_NUMBER_ADS_MIN: 1,
  AVG_NUMBER_ADS_MAX: 1000,
  FEATURE_SCORE_MIN: 0,
  FEATURE_SCORE_MAX: 100,
  POSITION_MARKS: [
    {value: 10},
    {value: 20},
    {value: 30},
    {value: 40},
    {value: 50},
    {value: 60},
    {value: 70},
    {value: 80},
    {value: 90},
    {value: 100},
  ],
  POWER_MARKS: [
    {value: 0},
    {value: 25},
    {value: 37},
    {value: 44},
    {value: 55},
    {value: 66},
    {value: 74},
    {value: 87},
    {value: 96},
    {value: 110},
    {value: 147},
    {value: 185},
    {value: 223},
    {value: 263},
    {value: 296},
    {value: 334},
    {value: 400},
    {value: 500},
    {value: 600},
  ],
  PRICE_MARKS: [
    {value: 10000},
    {value: 25000},
    {value: 50000},
    {value: 80000},
    {value: 100000},
    {value: 120000},
    {value: 140000},
    {value: 160000},
    {value: 180000},
    {value: 200000},
    {value: 240000},
    {value: 260000},
    {value: 280000},
    {value: 300000},
    {value: 320000},
    {value: 340000},
    {value: 360000},
    {value: 380000},
    {value: 400000},
    {value: 450000},
    {value: 500000},
    {value: 600000},
    {value: 700000},
    {value: 800000},
    {value: 900000},
    {value: 1000000},
    {value: 1200000},
    {value: 1500000},
    {value: 2000000},
    {value: 2500000},
    {value: 3000000},
    {value: 3500000},
    {value: 4000000},
  ],
  CUBIC_CAPACITY_MARKS: [{value: 0}, {value: 1000}, {value: 1500}, {value: 1800}, {value: 2500}],
  MILEAGE_RANGE_MARKS: [
    {value: 0},
    {value: 2500},
    {value: 5000},
    {value: 15000},
    {value: 20000},
    {value: 30000},
    {value: 40000},
    {value: 50000},
    {value: 60000},
    {value: 70000},
    {value: 80000},
    {value: 90000},
    {value: 125000},
    {value: 150000},
    {value: 175000},
    {value: 200000},
    {value: 250000},
    {value: 300000},
  ],
  AVG_NUMBER_ADS_MARKS: [
    {value: 1},
    {value: 50},
    {value: 100},
    {value: 300},
    {value: 500},
    {value: 1000},
  ],
};
