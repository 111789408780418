import {isNotNil} from 'ramda';

import {GetDataByDataQueryApiResponse} from '@dms/api/datagrid';

import {Card} from '../types/Card';
import {mapBusinessCasesToCards} from './mapBusinessCasesToCards';
import {mapInterestsToCards} from './mapInterestsToCards';

export const getCardsForColumn = (
  columnIndex: number,
  businessCases?: GetDataByDataQueryApiResponse,
  interests?: GetDataByDataQueryApiResponse
) => {
  const allCards: Card[] = [];

  if (isNotNil(businessCases)) {
    const businessCaseCards = mapBusinessCasesToCards(businessCases);
    allCards.push(...businessCaseCards);
  }

  if (isNotNil(interests)) {
    const interestCards = mapInterestsToCards(interests);
    allCards.push(...interestCards);
  }

  return allCards.filter((card) => card.column === columnIndex);
};
