import {CommentsTab, ActivitySidebarAction, ActivitySidebar} from '@dms/features/activity-panel';
import i18n from '@dms/i18n';

import {buildArray} from 'shared';

import {ActivityTab} from './components/ActivityTab';

interface BusinessCaseActivityPanelProps {
  businessCaseId: string;
}

export function BusinessCaseActivityPanel(props: BusinessCaseActivityPanelProps) {
  const actions = buildArray<ActivitySidebarAction>()
    .add({
      label: i18n.t('entity.activityPanel.activity.title'),
      icon: 'sidebar/activity',
      component: <ActivityTab businessCaseId={props.businessCaseId} />,
    })
    .add({
      label: i18n.t('entity.activityPanel.comments.title'),
      icon: 'communication/comment',
      component: <CommentsTab businessCaseId={props.businessCaseId} />,
    });

  return <ActivitySidebar actions={actions} />;
}
