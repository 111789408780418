import {ButtonGroup, Form, FormButton, FormField} from 'platform/components';
import {Show, VStack} from 'platform/foundation';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {ListVehicleCostCategoriesApiResponse} from '@dms/api/saleVehiclePhoto';
import {CountryAlpha3CodeEnum} from '@dms/api/shared';
import i18n from '@dms/i18n';

import {CurrencyCodeType, RequiredTestIdProps, suffixTestId} from 'shared';

import {DocumentUploadForm} from './components/DocumentUploadForm';
import {FormVatPriceField} from './components/FormVatPriceField';
import {RecurringTransactionSubform} from './components/RecurringTransactionSubform';
import {
  CalculationTypeSchema,
  getVehicleTransactionFormSchema,
  TransactionType,
  TransactionTypeSchema,
  VehicleTransactionFormSchemaType,
} from './VehicleTransactionFormSchema';

type VehicleTransactionFormProps = RequiredTestIdProps & {
  onSubmit: (data: VehicleTransactionFormSchemaType) => Promise<void>;
  onDiscard?: VoidFunction;
  values?: VehicleTransactionFormSchemaType;
  currency: CurrencyCodeType;
  countryCode: CountryAlpha3CodeEnum;
} & (
    | {showCategories: true; categories: ListVehicleCostCategoriesApiResponse}
    | {showCategories?: false; categories?: never}
  );

export function VehicleTransactionForm(props: VehicleTransactionFormProps) {
  const isEditForm = isNotNilOrEmpty(props.values);
  const defaultValues = isNotNilOrEmpty(props.values) ? props.values : undefined;

  return (
    <Form<VehicleTransactionFormSchemaType>
      onSubmit={props.onSubmit}
      defaultValues={{
        fileId: null,
        category: null,
        expectedPrice: null,
        realPrice: null,
        startDate: null,
        endDate: null,
        interestPA: null,
        calculationType: [CalculationTypeSchema.enum.SIMPLE],
        transactionType: [TransactionTypeSchema.enum.ONE_TIME],
        ...defaultValues,
      }}
      experimentalZodSchema={getVehicleTransactionFormSchema(i18n)}
    >
      {(control, formApi) => (
        <VStack spacing={4}>
          <Show when={props.showCategories && isNotNilOrEmpty(props.categories)}>
            <FormField
              type="chips"
              isDeselectable
              name="category"
              label={i18n.t('entity.vehicle.costs.category')}
              control={control}
              data-testid={suffixTestId('category', props)}
              options={props.categories?.map((category) => ({
                value: category.key,
                label: category.name,
              }))}
            />
          </Show>
          <FormField
            type="text"
            control={control}
            name="name"
            isRequired
            label={i18n.t('general.labels.name')}
            data-testid={suffixTestId('name', props)}
          />
          <FormVatPriceField
            control={control}
            name="expectedPrice"
            data-testid="expectedPrice"
            currency={props.currency}
            countryCode={props.countryCode}
            labels={{
              withVat: i18n.t('entity.vehicle.costs.expectedPriceWithVAT'),
              withoutVat: i18n.t('entity.vehicle.costs.expectedPriceWithoutVAT'),
            }}
          />
          <FormField
            type="chips"
            isDeselectable
            name="transactionType"
            label={i18n.t('entity.vehicle.costs.category')}
            control={control}
            data-testid={suffixTestId('category', props)}
            options={
              [
                {
                  label: i18n.t('entity.vehicle.labels.transactionType.oneTime'),
                  value: TransactionTypeSchema.enum.ONE_TIME,
                },
                {
                  label: i18n.t('entity.vehicle.labels.transactionType.recurring'),
                  value: TransactionTypeSchema.enum.RECURRING,
                },
              ] satisfies {label: string; value: TransactionType}[]
            }
          />
          <Show
            when={formApi.watch('transactionType')?.[0] === TransactionTypeSchema.enum.RECURRING}
          >
            <RecurringTransactionSubform control={control} currency={props.currency} />
          </Show>
          <Show
            when={formApi.watch('transactionType')?.[0] === TransactionTypeSchema.enum.ONE_TIME}
          >
            <FormVatPriceField
              control={control}
              name="realPrice"
              data-testid="realPrice"
              currency={props.currency}
              countryCode={props.countryCode}
              labels={{
                withVat: i18n.t('entity.vehicle.costs.realPriceWithVAT'),
                withoutVat: i18n.t('entity.vehicle.costs.realPriceWithoutVAT'),
              }}
            />
          </Show>
          <FormField
            minRows={1}
            name="note"
            type="textarea"
            control={control}
            label={i18n.t('general.labels.note')}
            data-testid={suffixTestId('note', props)}
          />
          <DocumentUploadForm data-testid={props['data-testid']} name="fileId" control={control} />
          <ButtonGroup align="right">
            <Show when={props.onDiscard}>
              <FormButton
                variant="secondary"
                title={i18n.t('general.actions.discard')}
                onClick={props.onDiscard}
                control={control}
                data-testid={suffixTestId('discard', props)}
              />
            </Show>
            <FormButton
              type="submit"
              variant="primary"
              control={control}
              title={isEditForm ? i18n.t('general.actions.save') : i18n.t('general.actions.add')}
              data-testid={suffixTestId('submit', props)}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}
