import {metadaApi} from '@dms/api/core';

import {
  PostIntegrationsForXentryLinkApiResponse,
  PostIntegrationsForXentryLinkApiArg,
  PostIntegrationsForXentryUnlinkApiResponse,
  PostIntegrationsForXentryUnlinkApiArg,
  PostAvailableXentryOrdersApiResponse,
  PostAvailableXentryOrdersApiArg,
  GetServiceCaseIntegrationsApiResponse,
  GetServiceCaseIntegrationsApiArg,
  PostServiceOrderAudatexTaskCalculationsApiResponse,
  PostServiceOrderAudatexTaskCalculationsApiArg,
  PostServiceOrderMyClaimCalculationsApiResponse,
  PostServiceOrderMyClaimCalculationsApiArg,
  PostIntegrationsForXentryLinkApiResponseSchema,
  PostIntegrationsForXentryLinkApiArgSchema,
  PostIntegrationsForXentryUnlinkApiResponseSchema,
  PostIntegrationsForXentryUnlinkApiArgSchema,
  PostAvailableXentryOrdersApiResponseSchema,
  PostAvailableXentryOrdersApiArgSchema,
  GetServiceCaseIntegrationsApiResponseSchema,
  GetServiceCaseIntegrationsApiArgSchema,
  PostServiceOrderAudatexTaskCalculationsApiResponseSchema,
  PostServiceOrderAudatexTaskCalculationsApiArgSchema,
  PostServiceOrderMyClaimCalculationsApiResponseSchema,
  PostServiceOrderMyClaimCalculationsApiArgSchema,
  PostServiceCaseIntegrationsXentryOrderManualCreationApiResponse,
  PostServiceCaseIntegrationsXentryOrderManualCreationApiArg,
  PostServiceCaseIntegrationsXentryOrderManualCreationApiResponseSchema,
  PostServiceCaseIntegrationsXentryOrderManualCreationApiArgSchema,
  GetServiceCaseIntegrationsXentryOrderSynchronizationApiResponse,
  GetServiceCaseIntegrationsXentryOrderSynchronizationApiArg,
  PostServiceCaseIntegrationsXentryOrderSynchronizationApiResponse,
  PostServiceCaseIntegrationsXentryOrderSynchronizationApiArg,
  GetServiceCaseIntegrationsXentryOrderSynchronizationApiResponseSchema,
  GetServiceCaseIntegrationsXentryOrderSynchronizationApiArgSchema,
  PostServiceCaseIntegrationsXentryOrderSynchronizationApiResponseSchema,
  PostServiceCaseIntegrationsXentryOrderSynchronizationApiArgSchema,
  GetAudatexCalculationsUserSettingsApiArg,
  GetAudatexCalculationsUserSettingsApiResponse,
  GetMyClaimCalculationsUserSettingsApiArg,
  GetMyClaimCalculationsUserSettingsApiResponse,
  GetMyClaimCalculationsUserSettingsApiResponseSchema,
  GetMyClaimCalculationsUserSettingsApiArgSchema,
  GetAudatexCalculationsUserSettingsApiResponseSchema,
  GetAudatexCalculationsUserSettingsApiArgSchema,
  GetServiceCaseIntegrationsClaraVehicleDetailsApiResponse,
  GetServiceCaseIntegrationsClaraVehicleDetailsApiArg,
  GetServiceCaseIntegrationsClaraClaimDetailApiArg,
  GetServiceCaseIntegrationsClaraClaimDetailApiResponse,
  GetServiceCaseIntegrationsClaraClaimApiArg,
  GetServiceCaseIntegrationsClaraClaimApiResponse,
  GetServiceCaseIntegrationsClaraClaimApiResponseSchema,
  GetServiceCaseIntegrationsClaraClaimApiArgSchema,
  GetServiceCaseIntegrationsClaraClaimDetailApiArgSchema,
  GetServiceCaseIntegrationsClaraClaimDetailApiResponseSchema,
  PostServiceCaseIntegrationsClaraClaimApiArg,
  PostServiceCaseIntegrationsClaraClaimApiResponse,
  PostServiceCaseIntegrationsClaraClaimApiArgSchema,
  PostServiceCaseIntegrationsClaraClaimApiResponseSchema,
} from './types';

const metadaWorkshopIntegrationApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postIntegrationsForXentryLink: build.mutation<
      PostIntegrationsForXentryLinkApiResponse,
      PostIntegrationsForXentryLinkApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/integration/xentry-order-link`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceCaseIntergations', id: queryArg.serviceCaseId},
        {type: 'serviceJobs', id: queryArg.serviceCaseId},
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        'serviceOrderJobs',
      ],
      extraOptions: {
        responseSchema: PostIntegrationsForXentryLinkApiResponseSchema,
        requestSchema: PostIntegrationsForXentryLinkApiArgSchema,
      },
    }),
    postIntegrationsForXentryUnlink: build.mutation<
      PostIntegrationsForXentryUnlinkApiResponse,
      PostIntegrationsForXentryUnlinkApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/integration/xentry-order-unlink`,
        method: 'POST',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceCaseIntergations', id: queryArg.serviceCaseId},
        {type: 'serviceJobs', id: queryArg.serviceCaseId},
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        'serviceOrderJobs',
      ],
      extraOptions: {
        responseSchema: PostIntegrationsForXentryUnlinkApiResponseSchema,
        requestSchema: PostIntegrationsForXentryUnlinkApiArgSchema,
      },
    }),
    postAvailableXentryOrders: build.mutation<
      PostAvailableXentryOrdersApiResponse,
      PostAvailableXentryOrdersApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/integration/xentry-orders`,
        method: 'POST',
        queryArg,
      }),
      extraOptions: {
        responseSchema: PostAvailableXentryOrdersApiResponseSchema,
        requestSchema: PostAvailableXentryOrdersApiArgSchema,
      },
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceCaseIntergations', id: queryArg.serviceCaseId},
      ],
    }),
    getServiceCaseIntegrations: build.query<
      GetServiceCaseIntegrationsApiResponse,
      GetServiceCaseIntegrationsApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/integrations`,
        queryArg,
      }),
      extraOptions: {
        responseSchema: GetServiceCaseIntegrationsApiResponseSchema,
        requestSchema: GetServiceCaseIntegrationsApiArgSchema,
      },
      providesTags: (result, error, queryArg) => [
        {type: 'serviceCaseIntergations', id: queryArg.serviceCaseId},
      ],
    }),
    postServiceOrderAudatexTaskCalculations: build.mutation<
      PostServiceOrderAudatexTaskCalculationsApiResponse,
      PostServiceOrderAudatexTaskCalculationsApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/integration/audatex-task-calculations`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'orderIntegration', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
        {type: 'serviceOrderTotalAmount', id: queryArg.serviceOrderId},
        {type: 'orderProfitability', id: queryArg.serviceOrderId},
        {type: 'serviceCase', id: queryArg.serviceCaseId},
        {type: 'serviceCaseTotalAmount', id: queryArg.serviceCaseId},
        'serviceOrderJobTotalAmount',
        'checkout',
        'audatexCalculationsUserSettings',
        'afsCheckout',
        'afsPaymentsList',
      ],
      extraOptions: {
        responseSchema: PostServiceOrderAudatexTaskCalculationsApiResponseSchema,
        requestSchema: PostServiceOrderAudatexTaskCalculationsApiArgSchema,
      },
    }),
    postServiceOrderMyClaimCalculations: build.mutation<
      PostServiceOrderMyClaimCalculationsApiResponse,
      PostServiceOrderMyClaimCalculationsApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/integration/myclaim-calculations`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'orderIntegration', id: queryArg.serviceOrderId},
        {type: 'serviceOrderJobs', id: queryArg.serviceOrderId},
        {type: 'serviceOrderTotalAmount', id: queryArg.serviceOrderId},
        {type: 'orderProfitability', id: queryArg.serviceOrderId},
        {type: 'serviceCase', id: queryArg.serviceCaseId},
        {type: 'serviceCaseTotalAmount', id: queryArg.serviceCaseId},
        'serviceOrderJobTotalAmount',
        'checkout',
        'myClaimCalculationsUserSettings',
        'afsCheckout',
        'afsPaymentsList',
      ],
      extraOptions: {
        responseSchema: PostServiceOrderMyClaimCalculationsApiResponseSchema,
        requestSchema: PostServiceOrderMyClaimCalculationsApiArgSchema,
      },
    }),
    postServiceCaseIntegrationsXentryOrderManualCreation: build.mutation<
      PostServiceCaseIntegrationsXentryOrderManualCreationApiResponse,
      PostServiceCaseIntegrationsXentryOrderManualCreationApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/integration/xentry-order-manual-creation`,
        method: 'POST',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceCaseIntergations', id: queryArg.serviceCaseId},
        {type: 'serviceJobs', id: queryArg.serviceCaseId},
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        'serviceOrderJobs',
      ],
      extraOptions: {
        responseSchema: PostServiceCaseIntegrationsXentryOrderManualCreationApiResponseSchema,
        requestSchema: PostServiceCaseIntegrationsXentryOrderManualCreationApiArgSchema,
      },
    }),
    getServiceCaseIntegrationsXentryOrderSynchronizationApi: build.query<
      GetServiceCaseIntegrationsXentryOrderSynchronizationApiResponse,
      GetServiceCaseIntegrationsXentryOrderSynchronizationApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/integration/xentry-order-synchronize`,
      }),
      extraOptions: {
        responseSchema: GetServiceCaseIntegrationsXentryOrderSynchronizationApiResponseSchema,
        requestSchema: GetServiceCaseIntegrationsXentryOrderSynchronizationApiArgSchema,
      },
      providesTags: (result, error, queryArg) => [
        {type: 'serviceCaseXentryOrderSynchronization', id: queryArg.serviceCaseId},
      ],
    }),
    PostServiceCaseIntegrationsXentryOrderSynchronizationApi: build.mutation<
      PostServiceCaseIntegrationsXentryOrderSynchronizationApiResponse,
      PostServiceCaseIntegrationsXentryOrderSynchronizationApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/integration/xentry-order-synchronize`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'serviceCaseIntergations', id: queryArg.serviceCaseId},
        {type: 'checkIn', id: queryArg.serviceCaseId},
        {type: 'handOver', id: queryArg.serviceCaseId},
        {type: 'serviceCase', id: queryArg.serviceCaseId},
        {type: 'serviceOrders', id: queryArg.serviceCaseId},
        'serviceOrder',
        'serviceOrderJobs',
        'serviceOrderTotalAmount',
        {type: 'serviceCase', id: queryArg.serviceCaseId},
        {type: 'serviceCaseTotalAmount', id: queryArg.serviceCaseId},
        'serviceOrderJobTotalAmount',
        'orderProfitability',
        'serviceOrderJobs',
        'checkout',
        'afsCheckout',
        'afsPaymentsList',
        'serviceJobActions',
        {type: 'serviceActions', id: queryArg.serviceCaseId},
        'orderActions',
        'serviceOrderCheckoutRestriction',
      ],
      extraOptions: {
        responseSchema: PostServiceCaseIntegrationsXentryOrderSynchronizationApiResponseSchema,
        requestSchema: PostServiceCaseIntegrationsXentryOrderSynchronizationApiArgSchema,
      },
    }),
    getAudatexCalculationsUserSettings: build.query<
      GetAudatexCalculationsUserSettingsApiResponse,
      GetAudatexCalculationsUserSettingsApiArg
    >({
      query: () => ({
        url: `/service/v1/integration/audatex-calculations-user-settings`,
      }),
      providesTags: () => [{type: 'audatexCalculationsUserSettings'}],
      extraOptions: {
        responseSchema: GetAudatexCalculationsUserSettingsApiResponseSchema,
        requestSchema: GetAudatexCalculationsUserSettingsApiArgSchema,
      },
    }),
    getMyClaimCalculationsUserSettings: build.query<
      GetMyClaimCalculationsUserSettingsApiResponse,
      GetMyClaimCalculationsUserSettingsApiArg
    >({
      query: () => ({
        url: `/service/v1/integration/myclaim-calculations-user-settings`,
      }),
      providesTags: () => ['myClaimCalculationsUserSettings'],
      extraOptions: {
        responseSchema: GetMyClaimCalculationsUserSettingsApiResponseSchema,
        requestSchema: GetMyClaimCalculationsUserSettingsApiArgSchema,
      },
    }),
    getServiceCaseIntegrationsClaraVehicleDetails: build.query<
      GetServiceCaseIntegrationsClaraVehicleDetailsApiResponse,
      GetServiceCaseIntegrationsClaraVehicleDetailsApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/integration/clara-vehicle-info`,
      }),
    }),
    getServiceCaseIntegrationsClaraClaim: build.query<
      GetServiceCaseIntegrationsClaraClaimApiResponse,
      GetServiceCaseIntegrationsClaraClaimApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/integration/clara-claim`,
      }),
      extraOptions: {
        responseSchema: GetServiceCaseIntegrationsClaraClaimApiResponseSchema,
        requestSchema: GetServiceCaseIntegrationsClaraClaimApiArgSchema,
      },
    }),
    postServiceCaseIntegrationsClaraClaim: build.mutation<
      PostServiceCaseIntegrationsClaraClaimApiResponse,
      PostServiceCaseIntegrationsClaraClaimApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/integration/clara-claim`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'orderIntegration', id: queryArg.serviceOrderId},
        {type: 'serviceCaseIntergations', id: queryArg.serviceCaseId},
      ],
      extraOptions: {
        responseSchema: PostServiceCaseIntegrationsClaraClaimApiResponseSchema,
        requestSchema: PostServiceCaseIntegrationsClaraClaimApiArgSchema,
      },
    }),
    getServiceCaseIntegrationsClaraClaimDetail: build.query<
      GetServiceCaseIntegrationsClaraClaimDetailApiResponse,
      GetServiceCaseIntegrationsClaraClaimDetailApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/integration/clara-claim-detail`,
      }),
      extraOptions: {
        responseSchema: GetServiceCaseIntegrationsClaraClaimDetailApiResponseSchema,
        requestSchema: GetServiceCaseIntegrationsClaraClaimDetailApiArgSchema,
      },
    }),
  }),
});
export const {
  usePostIntegrationsForXentryLinkMutation,
  usePostIntegrationsForXentryUnlinkMutation,
  usePostAvailableXentryOrdersMutation,
  useGetServiceCaseIntegrationsQuery,
  usePostServiceOrderAudatexTaskCalculationsMutation,
  usePostServiceOrderMyClaimCalculationsMutation,
  usePostServiceCaseIntegrationsXentryOrderManualCreationMutation,
  useGetServiceCaseIntegrationsXentryOrderSynchronizationApiQuery,
  useLazyGetServiceCaseIntegrationsXentryOrderSynchronizationApiQuery,
  usePostServiceCaseIntegrationsXentryOrderSynchronizationApiMutation,
  useGetAudatexCalculationsUserSettingsQuery,
  useGetMyClaimCalculationsUserSettingsQuery,
  useGetServiceCaseIntegrationsClaraVehicleDetailsQuery,
  useGetServiceCaseIntegrationsClaraClaimQuery,
  usePostServiceCaseIntegrationsClaraClaimMutation,
  useGetServiceCaseIntegrationsClaraClaimDetailQuery,
} = metadaWorkshopIntegrationApi;
