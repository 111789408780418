import {DataStatus} from 'platform/components';
import {Box, Text, VStack} from 'platform/foundation';

import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetServicePackageHoverInformationQuery} from '@dms/api/metadaWorkshopServicePackage';
import i18n from '@dms/i18n';
import {EMPTY_PLACEHOLDER} from '@dms/shared';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

interface PackageTooltipProps extends TestIdProps {
  servicePackageId: string | Nullish;
}

export function PackageTooltip(props: PackageTooltipProps) {
  const {data, isLoading, isError} = useGetServicePackageHoverInformationQuery(
    {servicePackageId: props.servicePackageId ?? ''},
    {skip: isNilOrEmpty(props.servicePackageId)}
  );

  return (
    <Box width={45}>
      <DataStatus isLoading={isLoading} isError={isError} minHeight={56}>
        <VStack spacing={4}>
          <VStack>
            <Text size="xSmall" alternative color="white">
              {i18n.t('general.labels.name')}:
            </Text>
            <Text size="xSmall" color="white" data-testid={suffixTestId('name', props)}>
              {data?.servicePackage?.name ?? EMPTY_PLACEHOLDER}
            </Text>
          </VStack>
          <VStack>
            <Text size="xSmall" alternative color="white">
              {i18n.t('general.labels.number')}:
            </Text>
            <Text size="xSmall" color="white" data-testid={suffixTestId('number', props)}>
              {data?.servicePackage?.number ?? EMPTY_PLACEHOLDER}
            </Text>
          </VStack>
          <VStack>
            <Text size="xSmall" alternative color="white">
              {i18n.t('general.labels.description')}:
            </Text>
            <Text size="xSmall" color="white" data-testid={suffixTestId('description', props)}>
              {data?.servicePackage?.desrciption ?? EMPTY_PLACEHOLDER}
            </Text>
          </VStack>
          <VStack>
            <Text size="xSmall" alternative color="white">
              {i18n.t('entity.workshopServicePackages.labels.competence')}:
            </Text>
            <Text size="xSmall" color="white" data-testid={suffixTestId('competence', props)}>
              {data?.servicePackage?.competence?.label ?? EMPTY_PLACEHOLDER}
            </Text>
          </VStack>
          <VStack>
            <Text size="xSmall" alternative color="white">
              {i18n.t('entity.workshopServicePackages.labels.estimatedTime')}:
            </Text>
            <Text size="xSmall" color="white" data-testid={suffixTestId('estimatedTime', props)}>
              {data?.servicePackage?.estimatedTime ?? EMPTY_PLACEHOLDER}
            </Text>
          </VStack>
        </VStack>
      </DataStatus>
    </Box>
  );
}
