import {IconButton} from 'platform/components';
import {HStack, Icon, Link, Show, Space} from 'platform/foundation';

import {getFileIcon} from '@dms/shared';

import {openFile} from 'shared';

interface FileListProps {
  contentType?: string | null;
  url: string;
  name: string;
  onDelete?: () => void;
}

export function FileList(props: FileListProps) {
  return (
    <HStack align="center" spacing={3} width="100%">
      <Icon value={getFileIcon(props.contentType)} size={8} />
      <Link size="small" title={props.name} onClick={() => openFile(props.url)} />
      <Show when={props.onDelete}>
        <Space fillAvailable />
        <IconButton severity="danger" icon="action/delete" onClick={props.onDelete} size="small" />
      </Show>
    </HStack>
  );
}
