import {UnknownAction} from 'redux';

import {createRTKQueryPersistEnhancer} from '../enhancers/createRtkQueryPersistEnhancer';
import {GenericRTKQState} from '../types/GenericRTKQState';
import {PersistEnhancerConfig} from '../types/PersistEnhancerConfig';

/**
 * Creates a persistent reducer for RTK Query
 *
 * This function wraps a reducer to add persistence functionality:
 * 1. State is automatically saved to IndexedDB when it changes
 * 2. State can be rehydrated from IndexedDB when the app starts
 * 3. The reducer exposes its persistence config for automatic rehydration
 *
 * @example
 * // In your reducer configuration:
 * [cachedApi.reducerPath]: persistReducer({
 *   key: cachedApi.reducerPath,
 *   transforms: [languageCheckTransform, duplicateKeysCheckTransform]
 * }, cachedApi.reducer)
 *
 * // Auto-rehydration will find all persistable reducers
 * // No need to manually call rehydratePersistedState
 */
export const persistRTKQReducer = <S extends GenericRTKQState, A extends UnknownAction>(
  config: PersistEnhancerConfig<S>,
  reducer: (state: S | undefined, action: A) => S
) => {
  const {key, transforms = [], debounceTime} = config;

  const enhancer = createRTKQueryPersistEnhancer<S, A>({
    key,
    transforms,
    debounceTime,
  });

  const enhancedReducer = enhancer(reducer);

  // Attach the persist config to the reducer to enable auto-discovery
  // This allows the store initialization to find all persistable reducers
  Object.defineProperty(enhancedReducer, '___persistConfig', {
    value: config,
    enumerable: false, // Don't include in JSON.stringify, etc.
  });

  return enhancedReducer;
};
