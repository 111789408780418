import {isFeatureEnabled} from 'feature-flags';
import {Nullish} from 'utility-types';

import {isNotNil} from 'ramda';

import {useGetParticipationQuery} from '@dms/api/participation';
import {SaleVehicleResponseBody} from '@dms/api/saleVehiclePhoto';
import {EntityResourceIds} from '@dms/api/shared';
import {featureFlags} from '@dms/feature-flags';
import {EntityHeaderControl, usePermissions, useVehicleWarehouse} from '@dms/shared';

import {buildArray} from 'shared';

interface UseGetVehicleControlsProps {
  isSaleVehicle: boolean;
  vehicleId: string;
  saleVehicle: SaleVehicleResponseBody | Nullish;
  branchId: string | Nullish;
}

export function useGetVehicleControls(props: UseGetVehicleControlsProps) {
  const {isVehicleWarehouseEnabled} = useVehicleWarehouse({
    vehicleId: props.vehicleId,
  });

  const {data: vehicleParticipation} = useGetParticipationQuery({
    recordId: props.vehicleId,
    resourceId: EntityResourceIds.vehicle,
  });

  const [hasVehicleReadWarehouseInfoPermission, canReadAdvertisingSettings] = usePermissions({
    permissionKeys: ['vehicleReadWarehouseInformation', 'readAdvertisingSettings'],
    scopes: {
      readAdvertisingSettings: {
        participation: vehicleParticipation,
        branchId: props.branchId,
      },
    },
  });

  if (!props.isSaleVehicle && isFeatureEnabled(featureFlags.AFTS_SERVICE_VEHICLE_ENTITY_V2)) {
    return buildArray<EntityHeaderControl>([
      'PARKING',
      'SERVICE_BRANCH',
      'ASSIGNEE',
      'VH_CUSTOM_ID',
    ]);
  }

  return buildArray<EntityHeaderControl>([
    'ASSIGNEE',
    isVehicleWarehouseEnabled ? 'VEHICLE_LOCATION' : 'LOCATION',
  ])
    .when(
      isNotNil(props.saleVehicle) &&
        canReadAdvertisingSettings &&
        isFeatureEnabled(featureFlags.SALES_SALE_PROGRAMS),
      'SALE_PRESET'
    )
    .when(isNotNil(props.saleVehicle), 'PARKING')
    .when(isNotNil(props.saleVehicle), 'KEY')
    .when(
      isVehicleWarehouseEnabled &&
        isNotNil(props.saleVehicle) &&
        hasVehicleReadWarehouseInfoPermission,
      'VEHICLE_WAREHOUSE'
    )
    .add('VH_CUSTOM_ID');
}
