import {isNotNil} from 'ramda';

import {SeriesPatternResponseBody} from '@dms/api/series';

import {parseDate} from 'shared';

import {DocumentSeriesFormValues} from '../types/DocumentSeriesFormValues';
import {getFormattedStartingNumber} from './getFormattedStartingNumber';

export const transformPatternsToDate = (
  patterns: SeriesPatternResponseBody[]
): DocumentSeriesFormValues['patterns'] =>
  patterns?.map((pattern) => ({
    ...pattern,
    startingNumber: getFormattedStartingNumber(pattern, true),
    from: isNotNil(pattern.from) ? parseDate(pattern.from) : null,
  }));
