import {Box, Center} from 'platform/foundation';

import {ReactNode} from 'react';

import {UserSidebarIconSize} from '@dms/api/shared';
import i18n from '@dms/i18n';

import {Collapse} from './Collapse';
import {CollapseToggle} from './CollapseToggle';
import {MenuButton} from './MenuButton';
import {StyledMenuItemContainer} from './MenuItem';
import {MenuItemActionButton} from './MenuItemActionButton';
import {MenuItemContent} from './MenuItemContent';
import {useMenuItemsState} from './MenuItemsStateProvider';

interface MenuItemPinsProps {
  content: ReactNode;
  iconSize: UserSidebarIconSize;
}

export function PinsMenuItem(props: MenuItemPinsProps) {
  const {isOpen: isMenuItemOpen, toggle} = useMenuItemsState();

  const isOpen = isMenuItemOpen('pins-menu-item');
  const handleToggle = () => {
    toggle('pins-menu-item');
  };

  return (
    <Box borderBottom="1px solid" borderColor="palettes.neutral.600.100">
      <Box padding={2}>
        <StyledMenuItemContainer>
          <MenuButton onClick={handleToggle}>
            <MenuItemContent
              title={i18n.t('general.labels.pinnedItems')}
              iconSize={props.iconSize}
              depth={0}
              icon="custom/push_pin"
            />
          </MenuButton>
          <Center>
            <MenuItemActionButton onClick={handleToggle}>
              <CollapseToggle isOpen={isOpen} iconSize={props.iconSize} />
            </MenuItemActionButton>
          </Center>
        </StyledMenuItemContainer>
        <Collapse isOpen={isOpen}>{props.content}</Collapse>
      </Box>
    </Box>
  );
}
