import {Dialog, RadioItem} from 'platform/components';

import {FC, useMemo, useState} from 'react';

import {BillingInformationResponseBody} from '@dms/api/shared';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {SimpleTable} from '@dms/teas';

type SelectBillingInformationProps = {
  billingInformation: BillingInformationResponseBody[];
  selectedBillingInformationIndex: number;
  onClose?: () => void;
  onBillingInformationChange?: (profileIndex: number) => void;
};

export const SelectBillingInformation: FC<SelectBillingInformationProps> = ({
  billingInformation,
  selectedBillingInformationIndex: _selectedBillingInformationIndex,
  onClose,
  onBillingInformationChange,
}) => {
  const [selectedBillingInformationIndex, setselectedBillingInformationIndex] = useState(
    _selectedBillingInformationIndex
  );

  const data = useMemo(
    () =>
      billingInformation.map((info, index) => ({
        radioButton: (
          <RadioItem
            value={index === selectedBillingInformationIndex}
            data-testid={testIds.settings.tenant(`primary-profile-radio-${index}`)}
            onChange={() => setselectedBillingInformationIndex(index)}
          />
        ),
        profile: `${i18n.t('page.settings.labels.profile')} ${index + 1}: ${
          info.contactInformation?.companyName
        }`,
        registrationNumber: info.contactInformation?.registrationNumber,
        vatNumber: info.contactInformation?.vatNumber,
      })),
    [selectedBillingInformationIndex, billingInformation]
  );

  return (
    <Dialog
      disableBodyPadding
      isOpen
      title={i18n.t('entity.branch.labels.selectBillingInformation')}
      onClose={onClose}
      buttons={[
        {
          title: i18n.t('general.actions.cancel'),
          variant: 'secondary',
          'data-testid': testIds.settings.tenant('billingModalCancel'),
          onClick: onClose,
        },
        {
          title: i18n.t('general.labels.select'),
          variant: 'primary',
          'data-testid': testIds.settings.tenant('billingModalConfirm'),
          onClick: () => onBillingInformationChange?.(selectedBillingInformationIndex),
        },
      ]}
    >
      <SimpleTable
        columns={[
          {
            Header: null,
            id: 'radioButton',
            width: 30,
          },
          {
            Header: i18n.t('page.settings.labels.profiles'),
            id: 'profile',
            width: 334,
          },
          {
            Header: i18n.t('entity.customer.labels.registrationNumber'),
            id: 'registrationNumber',
          },
          {
            Header: i18n.t('entity.customer.labels.vatNumber'),
            id: 'vatNumber',
          },
        ]}
        rows={[{data}]}
      />
    </Dialog>
  );
};
