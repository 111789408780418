import {match} from 'ts-pattern';

import {always} from 'ramda';

import {BillingRecipient} from '@dms/api/metadaAftersalesCheckout';
import i18n from '@dms/i18n';

export const getAllowedCustomerLabel = (type: BillingRecipient) =>
  match(type)
    .with('DEFAULT', always(i18n.t('general.labels.default')))
    .with('OTHER', always(i18n.t('entity.orderCheckout.labels.otherCustomer')))
    .with('OPERATOR', always(i18n.t('entity.ownership.label.vehicleOperator')))
    .with('USER', always(i18n.t('entity.ownership.label.vehicleUser')))
    .with('MANAGER', always(i18n.t('entity.ownership.label.vehicleManager')))
    .with('OWNER', always(i18n.t('entity.ownership.label.vehicleOwner')))
    .with('INVOICE', always(i18n.t('entity.ownership.label.defaultRecepient')))
    .exhaustive();
