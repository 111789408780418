import {Button, DataStatus, Dropdown, DropdownItem} from 'platform/components';
import {Box, Hide, Show, Spinner, Text} from 'platform/foundation';

import {useListChecklistDefinitionQuery} from '@dms/api/checklist';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

import {ChecklistResourceType} from '../types/ChecklistResourceType';

interface TemplateChooserProps {
  onTemplateSelected: (definitionId: string) => void;
  isLoading: boolean;
  resourceId: keyof typeof ChecklistResourceType;
  variant: 'primary' | 'ghostLink';
  forUpdate?: boolean;
}

export function TemplateChooser(props: TemplateChooserProps) {
  const checklistDefinitionsQuery = useListChecklistDefinitionQuery({
    resourceId: props.resourceId,
  });

  return (
    <DataStatus
      isLoading={checklistDefinitionsQuery.isLoading}
      isError={checklistDefinitionsQuery.isError}
    >
      <Show when={props.isLoading}>
        <Spinner />
      </Show>
      <Hide when={props.isLoading}>
        <Dropdown
          dropdownControl={
            <Button
              data-testid={testIds.vehicles.checklists('templateChooser')}
              title={
                props.forUpdate
                  ? i18n.t('entity.checklist.labels.changeTemplate')
                  : i18n.t('entity.checklist.labels.chooseTemplate')
              }
              rightIcon="navigation/arrow_drop_down"
              variant={props.variant}
            />
          }
        >
          <Show when={!checklistDefinitionsQuery.data?.length}>
            <Box padding={2} data-testid={testIds.vehicles.checklists('noTemplate')}>
              <Text size="small">{i18n.t('general.labels.noOptions')}</Text>
            </Box>
          </Show>
          {checklistDefinitionsQuery.data?.map((definition) => (
            <DropdownItem
              data-testid={testIds.vehicles.checklists('chechlistItems')}
              key={definition.id}
              label={definition.name}
              onClick={() => props.onTemplateSelected(definition.id)}
            />
          ))}
        </Dropdown>
      </Hide>
    </DataStatus>
  );
}
