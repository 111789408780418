import {isNil} from 'ramda';

import {GetWarehousesResponse} from '@dms/api/metadaWarehouse';

import {Nullish, Unpacked} from 'shared';

export const getWarehouseById = (
  warehouses: GetWarehousesResponse | Nullish,
  warehouseId: string | Nullish
): Unpacked<GetWarehousesResponse> | Nullish => {
  if (isNil(warehouses)) {
    return null;
  }
  return warehouses.find((w) => w.id === warehouseId);
};
