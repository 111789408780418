import {Checkbox, DataStatus} from 'platform/components';
import {VStack} from 'platform/foundation';
import {useFormatNumber} from 'platform/locale';

import {useMemo} from 'react';

import {useSourcingGetTopSellingQuery} from '@dms/api/sourcing';
import i18n from '@dms/i18n';
import {TopSellingType} from '@dms/teas';

import {suffixTestId, TestIdProps} from 'shared';

import {useSourcingTargetCountry} from '../../../../../../../hooks/useSourcingTargetCountry';
import {convertObjectToArray} from '../../../../../utils/convertObjectToArray';
import {useFilters} from '../../../../FiltersContext/FiltersContext';

export function TopSellingCheckboxList(props: TestIdProps) {
  const {filters, onUpdateFilters} = useFilters();
  const targetCountry = useSourcingTargetCountry();

  const {
    data: topSelling,
    isLoading,
    isError,
  } = useSourcingGetTopSellingQuery({
    size: 20,
    body: {
      seller: {
        country: [targetCountry?.code ?? 'CZE'],
      },
    },
  });

  const formatNumber = useFormatNumber();

  const {model, make} = filters;

  const convertedMake = useMemo(() => convertObjectToArray(make), [make]);
  const convertedModel = useMemo(() => convertObjectToArray(model), [model]);

  const handleClickTopSelling = (item: TopSellingType, isSelected: boolean) => {
    if (item.make?.key && !convertedMake.includes(item.make.key) && isSelected) {
      onUpdateFilters(['make', `${item.make.key}`], true);
    }

    // uncheck topSelling - do we have checked some other model of this make? No - unselect make too
    const modelOfThisMake = (modelKey: string) =>
      modelKey.split('-')[0] === item.make?.key && modelKey !== item.model?.key;

    if (!convertedModel.some(modelOfThisMake) && !isSelected) {
      onUpdateFilters(['make', `${item.make?.key}`], false);
    }
    onUpdateFilters(['model', `${item.model?.key}`], isSelected);
  };

  return (
    <DataStatus
      isLoading={isLoading}
      isError={isError}
      isEmpty={topSelling?.length === 0}
      emptyMessage={i18n.t('page.filters.labels.noTopSelling')}
      minHeight={24}
    >
      <VStack spacing={2}>
        {topSelling &&
          topSelling.map((item, index) => {
            const isSelected = !!item.model?.key && convertedModel.includes(item.model?.key);

            return (
              <Checkbox
                key={item.model?.key}
                name={`topSelling.${item.model?.key}`}
                label={`${index + 1}. ${item.make?.translation} ${
                  item.model?.translation
                } (${formatNumber(item?.count ?? 0, 0)})`}
                value={isSelected}
                onChange={(e) => handleClickTopSelling(item, e)}
                data-testid={suffixTestId(`topSelling-${item.model?.key}`, props)}
              />
            );
          })}
      </VStack>
    </DataStatus>
  );
}
