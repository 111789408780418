import {isFeatureEnabled} from 'feature-flags';

import {ReactElement} from 'react';
import {RouteProps} from 'react-router-dom';

import {hasPath, isNotNil, not, uniqBy} from 'ramda';
import {isFalse} from 'ramda-adjunct';

import {
  MenuItemProps,
  Module,
  ModuleItem,
  MultipleModuleItem,
  Register,
  SingleModuleItem,
} from '@dms/api/shared';

const makeContentThree = (listOfMenuItems?: any[]): MenuItemProps[] | undefined => {
  if (!listOfMenuItems) {
    return;
  }

  let node: MenuItemProps;
  let i: number;
  const map: any = {};
  const three = [];

  for (i = 0; i < listOfMenuItems.length; i += 1) {
    const {id} = listOfMenuItems[i];
    map[id] = i; // initialize the map
  }

  for (i = 0; i < listOfMenuItems.length; i += 1) {
    node = listOfMenuItems[i];
    if (node && node.parentId && node.parentId !== 'base' && listOfMenuItems) {
      // if you have dangling branches check that map[node.parentId] exists
      listOfMenuItems[map[node.parentId]].content.push(node);
    } else {
      three.push(node);
    }
  }

  return three;
};

function resolvePath(path: Module['routerProps']['path']) {
  if (typeof path === 'function') {
    return path(isFeatureEnabled);
  }

  return path;
}

function resolveRouterProps(routerProps: Module['routerProps']) {
  const path = resolvePath(routerProps.path);

  return {...routerProps, path};
}

/**
 * This function registers modules into our application.
 * The output of this function is an object that contains
 * all layout props and all route props on one level
 */
export function register<T extends Module[]>(
  modules: T,
  isAccessible: (module: Module | ModuleItem) => boolean | undefined,
  redirect: ReactElement,
  isTestEnvironment: boolean
): Register {
  const createMenuItems: MenuItemProps[] = [];
  const menuItems: MenuItemProps[] = [];
  const routesCache: RouteProps[] = [];

  modules.forEach((item) => {
    const {id, routerProps, content} = item;

    const hasModulePermission = isAccessible(item);

    if (Boolean(routerProps.element) && routerProps.path) {
      if (!hasModulePermission) {
        routerProps.element = redirect;
      }

      routesCache.push(resolveRouterProps(routerProps));
    }

    const contentItems = content?.reduce((filteredItems: MenuItemProps[], contentItem) => {
      const hasContentPermission = isAccessible(contentItem);

      if (isFalse(hasContentPermission)) {
        return filteredItems;
      }

      if (not(hasModulePermission) && not(isTestEnvironment)) {
        contentItem.routerProps.element = redirect;
      }

      if (isSingleModuleItem(contentItem)) {
        routesCache.push(resolveRouterProps(contentItem.routerProps));
      }

      if (isMultipleModuleItem(contentItem)) {
        contentItem.routerProps.paths.forEach((path) => {
          routesCache.push({
            element: contentItem.routerProps.element,
            path,
          });
        });
      }

      if (contentItem.layoutProps) {
        const moduleItem = {
          id: contentItem.id,
          parentId: contentItem.parentId || 'base',
          layoutProps: contentItem.layoutProps,
          path: resolvePath(contentItem.routerProps.path),
          alwaysShow: contentItem.alwaysShow,
        };

        if (hasContentPermission) {
          if (!contentItem.layoutProps.isPartOfCreateMenuItem) {
            filteredItems.push(moduleItem);
          } else {
            createMenuItems.push(moduleItem);
          }
        }
      }

      return filteredItems;
    }, []);

    if (hasModulePermission && isNotNil(item.layoutProps)) {
      const rootModule: MenuItemProps = {
        id,
        layoutProps: item.layoutProps,
        path: resolvePath(item.routerProps.path),
        content: makeContentThree(contentItems),
        alwaysShowContent: item.alwaysShowContent,
      };
      menuItems.push(rootModule);
    }
  });

  const routes = uniqBy((i) => i.path, routesCache);

  return {menuItems, createMenuItems, routes};
}

const isSingleModuleItem = hasPath(['routerProps', 'path']) as (
  module: ModuleItem
) => module is SingleModuleItem;

const isMultipleModuleItem = hasPath(['routerProps', 'paths']) as (
  module: ModuleItem
) => module is MultipleModuleItem;
