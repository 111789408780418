import {Nullish} from 'utility-types';

import {mergeAll} from 'ramda';

import {RentalVehicleResponseBody} from '@dms/api/rentalVehicles';
import {GetVehicleApiResponseV2} from '@dms/api/shared';

import {serviceIntervalsDefault} from '../constants/serviceIntervalsDefault';
import {RentalVehicleFormType} from '../types/RentalVehicleFormType';

export function convertVehicleToFormData(
  vehicleDetail: GetVehicleApiResponseV2 | Nullish,
  rentalVehicleData: RentalVehicleResponseBody | Nullish,
  note: string | Nullish
): Partial<RentalVehicleFormType> {
  if (!vehicleDetail) {
    return {};
  }

  const getManufacturedOnMonth = () => {
    const result = vehicleDetail.manufacturedOn?.split('-').at(1);
    return result ? parseInt(result, 10).toString() : null;
  };

  return {
    // required
    make: vehicleDetail.make || '',
    formMake: vehicleDetail.customMake || vehicleDetail.make || '',
    customMake: vehicleDetail.customMake || null,

    modelFamily: vehicleDetail.modelFamily || '',
    formModelFamily: vehicleDetail.customModelFamily || vehicleDetail.modelFamily || '',
    customModelFamily: vehicleDetail.customModelFamily || null,

    type: vehicleDetail.type ? [vehicleDetail.type] : [],

    // optional
    bodyStyle: vehicleDetail.bodyStyle ? [vehicleDetail.bodyStyle] : [],
    drive: vehicleDetail.drive ? [vehicleDetail.drive] : [],
    hybridType: vehicleDetail.hybridType ? [vehicleDetail.hybridType] : [],
    fuelType: vehicleDetail.fuelType ? [vehicleDetail.fuelType] : [],
    secondaryFuelType: vehicleDetail.secondaryFuelType ? [vehicleDetail.secondaryFuelType] : [],
    transmission: vehicleDetail.transmission ? [vehicleDetail.transmission] : [],
    serviceBookType: vehicleDetail.serviceBookType ? [vehicleDetail.serviceBookType] : [],
    serviceBookState: vehicleDetail.serviceBookState ? [vehicleDetail.serviceBookState] : [],
    features: vehicleDetail.features?.map((item) => item.key) || [],
    exteriorColorSpecification: vehicleDetail.exteriorColorSpecification
      ? [vehicleDetail.exteriorColorSpecification]
      : [],
    manufacturedOnYear: vehicleDetail.manufacturedOn?.split('-').at(0) || null,
    manufacturedOnMonth: getManufacturedOnMonth(),
    batteryCapacityAh: vehicleDetail.batteryCapacityAh || null,
    batteryCapacityWh: vehicleDetail.batteryCapacityWh || null,
    batteryCount: vehicleDetail.batteryCount || null,
    batteryMileageLimit: vehicleDetail.batteryMileageLimit || null,
    batteryOwnershipType: vehicleDetail.batteryOwnershipType || null,
    batteryType: vehicleDetail.batteryType || null,
    batteryVoltage: vehicleDetail.batteryVoltage || null,
    batteryWarrantyUntil: vehicleDetail.batteryWarrantyUntil || null,
    chargingAC: vehicleDetail.chargingAC || null,
    chargingDC: vehicleDetail.chargingDC || null,
    chargingConnectorType: vehicleDetail.chargingConnectorType || null,
    chargingTimeAC: vehicleDetail.chargingTimeAC || null,
    chargingTimeDC: vehicleDetail.chargingTimeDC || null,
    electricRange: vehicleDetail.electricRange || null,
    engineCode: vehicleDetail.engineCode || null,
    engineName: vehicleDetail.engineName || null,
    engineNumber: vehicleDetail.engineNumber || null,
    engineVolume: vehicleDetail.engineVolume || null,
    exteriorColor: vehicleDetail.exteriorColor || null,
    firstRegistrationOn: vehicleDetail.firstRegistrationOn || null,
    gasRevisionValidUntil: vehicleDetail.gasRevisionValidUntil || null,
    isAcceptableForTechnicalInspection: vehicleDetail.isAcceptableForTechnicalInspection || null,
    note: note || null,
    power: vehicleDetail.power || null,
    pressureVesselValidUntil: vehicleDetail.pressureVesselValidUntil || null,
    registrationPlate: vehicleDetail.registrationPlate || null,
    serviceContractMileageLimit: vehicleDetail.serviceContractMileageLimit || null,
    serviceContractNote: vehicleDetail.serviceContractNote || null,
    serviceContractUntil: vehicleDetail.serviceContractUntil || null,
    technicalInspectionNote: vehicleDetail.technicalInspectionNote || null,
    technicalInspectionValidUntil: vehicleDetail.technicalInspectionValidUntil || null,
    title: vehicleDetail.title || null,
    trim: vehicleDetail.trim || null,
    variant: vehicleDetail.variant || null,
    vin: vehicleDetail.vin || null,
    warrantyDate: vehicleDetail.warrantyDate || null,
    warrantyMileage: vehicleDetail.warrantyMileage || null,
    serviceIntervals: mergeAll([serviceIntervalsDefault, vehicleDetail.serviceIntervals]),
    rentalProperties: {
      availability: {
        from: rentalVehicleData?.availability?.from || null,
        to: rentalVehicleData?.availability?.to || null,
        mileageLimit: rentalVehicleData?.availability?.mileageLimit || null,
      },
      carParkId: rentalVehicleData?.carParkId || null,
      equipment: rentalVehicleData?.equipment || [],
      note: rentalVehicleData?.note || null,
      rentalPriceGroup: rentalVehicleData?.rentalPriceGroup
        ? {
            rentalPriceGroupId: rentalVehicleData?.rentalPriceGroup.rentalPriceGroupId,
            rentalPriceGroupItemId: rentalVehicleData?.rentalPriceGroup.rentalPriceGroupItemId,
          }
        : null,
    },
  };
}
