import {isNilOrEmpty} from 'ramda-adjunct';

import {TaskStateEnum, useGetTaskCountQuery} from '@dms/api/taskManagement';

import {TaskRelations} from '../types/TaskRelations';

interface UseTasksCountProps {
  entityId: string;
  relation: TaskRelations;
  state: TaskStateEnum;
}
export function useTasksCount(props: UseTasksCountProps, options: {skip?: boolean} = {}) {
  const {data, isLoading, isError} = useGetTaskCountQuery(
    {
      relatedResourceId: props.relation,
      relatedId: props.entityId,
      state: props.state,
    },
    {
      skip:
        options.skip ||
        isNilOrEmpty(props.entityId) ||
        isNilOrEmpty(props.relation) ||
        isNilOrEmpty(props.state),
    }
  );

  return {
    count: data?.count ?? 0,
    isLoading,
    isError,
  };
}
