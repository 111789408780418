export const WAREHOUSE_GRID_CODES = {
  warehouseCreditNoteItemAdd: 'warehouse-credit-note-item-add',
  warehouseCreditNoteItem: 'warehouse-credit-note-item',
  warehouseSupplierOrderItems: 'warehouse-supplier-order-items',
  warehouseIssueNoteCorrections: 'warehouse-issue-note-corrections',
  warehouseIssueNoteCorrectionItems: 'warehouse-issue-note-correction-items',
  warehouseServiceOrderReturns: 'warehouse-service-order-parts-return',
  warehouseServiceOrderReturnSelectItems: 'warehouse-service-order-return-select-items',
  warehouseServiceOrderPartsReturnItem: 'warehouse-service-order-parts-return-item',
  warehouseCostCorrections: 'warehouse-issue-note-cost-corrections-list',
  warehouseCostCorrectionItems: 'warehouse-issue-note-cost-correction-items',
  warehouseDirectSaleItem: 'direct-sale-item',
  issueNoteServiceOrderAddedItems: 'issue-note-service-order-added-items',
};
