import {Tooltip} from 'platform/components';

import {ColorBorder, StyledColorCircle} from './styles';

type ColorCircleProps = {
  className?: string;
  color: {
    value: string;
    label: string;
    shortLabel?: string | null;
    properties?: {name?: string; value?: string}[];
  };
  size?: 'default' | 'large';
  needBorder?: boolean;
  isSelected?: boolean;
  onClick?: () => void;
};

export function ColorCircle({
  color,
  size,
  onClick,
  isSelected,
  needBorder,
  ...rest
}: ColorCircleProps) {
  const colorCode = color.properties?.find((property) => property.name === 'color_code');

  return (
    <Tooltip label={color.label} placement="top">
      <ColorBorder {...rest} $isSelected={isSelected}>
        <StyledColorCircle
          $color={colorCode?.value || 'transparent'}
          $size={size === 'large' ? 6 : undefined}
          onClick={onClick}
          $needBorder={needBorder}
        />
      </ColorBorder>
    </Tooltip>
  );
}
