import {FetchBaseQueryError} from '@reduxjs/toolkit/query';
import {Button, openDialog, showNotification} from 'platform/components';

import {isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {CompGetInfoV3ApiResponse, useLazyCompGetInfoV3Query} from '@dms/api/company';
import i18n from '@dms/i18n';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {useTenant} from '../../hooks/useTenant';
import {handleApiError} from '../../utils/handleApiError';
import {BusinessesTable} from './components/BusinessesTable';
import {SelectLegalPerson} from './components/SelectLegalPerson';

type CountryCode = ('CZE' | 'SVK' | 'POL') | Nullish;

interface DecodeBusinessIdButtonProps extends TestIdProps {
  businessId?: string | Nullish;
  vatId?: string | Nullish;
  countryCode?: string | Nullish;
  onSuccess: (company: CompGetInfoV3ApiResponse) => void;
}

export function DecodeBusinessIdButton(props: DecodeBusinessIdButtonProps) {
  const [getBusinessInfo, {isLoading}] = useLazyCompGetInfoV3Query();
  const {tenantCountry} = useTenant();
  const getCountryCode = (value: string | Nullish): CountryCode | null =>
    isValidCountryCode(value) ? value : null;

  const isValidCountryCode = (value: string | Nullish): value is CountryCode =>
    value === 'CZE' || value === 'SVK' || value === 'POL';

  const countryCodeProps = getCountryCode(props.countryCode);
  const countryCodeTenant = getCountryCode(tenantCountry);

  const onDecodeClick = () => {
    if (isNil(props.businessId) && isNil(props.vatId)) {
      return;
    }
    getBusinessInfo({
      id: props.businessId ?? '',
      vatId: props.vatId ?? '',
      country: countryCodeProps ?? countryCodeTenant ?? null,
    })
      .unwrap()
      .then((data) => {
        if (!data) {
          showNotification.warning(
            i18n.t('entity.businessInfo.labels.registrationNumberCanNotBeDecoded')
          );
          return;
        }

        if (data?.length > 1) {
          openDialog(<BusinessesTable data={data} onSuccess={props.onSuccess} />, {
            title: i18n.t('entity.customer.labels.selectEntity'),
            scrollBehavior: 'inside',
            disableBodyPadding: true,
            withAdditionalFooter: true,
          });
          return;
        } else if (data[0]?.businessId || data[0]?.taxId) {
          openDialog(
            <SelectLegalPerson
              data-testid={props['data-testid']}
              data={[data[0]]}
              onSubmit={props.onSuccess}
            />,
            {
              title: i18n.t('entity.customer.labels.businessInformation'),
              scrollBehavior: 'outside',
            }
          );
          return;
        }
        props.onSuccess(data);
      })
      .catch((error: FetchBaseQueryError) => {
        if (error.status === 404 || error.status === 409) {
          showNotification.warning(
            i18n.t('entity.businessInfo.labels.registrationNumberCanNotBeDecoded')
          );
          return;
        }
        handleApiError(error);
      });
  };

  return (
    <Button
      variant="secondary"
      isDisabled={isNilOrEmpty(props.businessId) && isNilOrEmpty(props.vatId)}
      title={i18n.t('general.customer.decode')}
      isLoading={isLoading}
      onClick={onDecodeClick}
      data-testid={suffixTestId('decode', props)}
    />
  );
}
