import {
  Card,
  CustomRequestOption,
  DataStatus,
  DropdownItem,
  GridPhoto,
  isFile,
  openDeleteDialog,
  openDropdown,
  PhotoGrid,
  UploadState,
} from 'platform/components';
import {VStack} from 'platform/foundation';
import {Lightbox, useLightbox} from 'platform/lightbox';

import {MouseEvent} from 'react';
import {useSearchParams} from 'react-router-dom';

import {isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetAuditDataQuery} from '@dms/api/carAudit';
import {useUploadFileMutation} from '@dms/api/core';
import {useGetServiceCheckInQuery} from '@dms/api/metadaWorkshopCheckin';
import {useGetServiceHandOverQuery} from '@dms/api/metadaWorkshopHandover';
import {useGetMetadaServiceCaseQuery} from '@dms/api/metadaWorkshopServiceCase';
import {PartialVehiclePhotosRoutes} from '@dms/api/shared';
import {
  useGetServiceAlbumListQuery,
  useGetServiceAlbumPhotoListQuery,
  useAddFilesToServiceAlbumMutation,
  useDeleteServicePhotoMutation,
} from '@dms/api/vehicle';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {
  getGridServiceAlbumPhotos,
  handleApiError,
  Main,
  getGridPhotosFromAudit,
  useBatchDownload,
  workshopSearchParams,
  ServiceCasePhotos,
} from '@dms/shared';

import {Nullish, useBoolean} from 'shared';

import {HANDOVER_SECTION} from '../../../../constants/detailSections';
import {useWorkshopUrl} from '../../../../hooks/useWorkshopUrl';

export function Photos() {
  const {serviceCaseId} = useWorkshopUrl();
  const [, setSearchParams] = useSearchParams();
  const [isBulkSelectActive, , setBulkSelectFalse, toggleBulkSelect] = useBoolean();

  const {handleDownload, handleServiceAlbumDownload} = useBatchDownload();

  const {
    data: serviceCase,
    isLoading: isServiceCaseLoading,
    isError: isServiceCaseError,
  } = useGetMetadaServiceCaseQuery({serviceCaseId});

  const {
    data: checkin,
    isLoading: isCheckinLoading,
    isError: isCheckinError,
  } = useGetServiceCheckInQuery({
    serviceCaseId,
  });
  const {
    data: handover,
    isLoading: isHandoverLoading,
    isError: isHandoverError,
  } = useGetServiceHandOverQuery({
    serviceCaseId,
  });
  const {
    data: checkinAudit,
    isLoading: isCheckinAuditLoading,
    isError: isCheckinAuditError,
  } = useGetAuditDataQuery(
    {
      auditId: checkin?.inspectionId?.[0] ?? '',
    },
    {
      skip: isNil(checkin?.inspectionId?.[0]),
      refetchOnMountOrArgChange: true,
    }
  );
  const {
    data: handoverAudit,
    isLoading: isHandoverAuditLoading,
    isError: isHandoverAuditError,
  } = useGetAuditDataQuery(
    {
      auditId: handover?.inspectionId?.[0] ?? '',
    },
    {
      skip: isNil(handover?.inspectionId?.[0]),
      refetchOnMountOrArgChange: true,
    }
  );

  const {data: serviceCaseAlbums} = useGetServiceAlbumListQuery(
    {
      vehicleId: serviceCase?.vehicleId ?? '',
      serviceCaseId,
    },
    {
      skip: isNilOrEmpty(serviceCase?.vehicleId) || isNilOrEmpty(serviceCase?.id),
    }
  );

  const serviceCaseAlbum = serviceCaseAlbums?.find(
    (album) => album.serviceCaseId === serviceCaseId
  );

  const {data: serviceVehicleAlbumPhotos} = useGetServiceAlbumPhotoListQuery(
    {
      vehicleId: serviceCase?.vehicleId || '',
      serviceAlbumId: serviceCaseAlbum?.id || '',
    },
    {
      skip: isNilOrEmpty(serviceCase?.vehicleId) || isNilOrEmpty(serviceCaseAlbum?.id),
    }
  );

  const [addFilesToServiceVehicleAlbum] = useAddFilesToServiceAlbumMutation();
  const [uploadFile, {isLoading: isFileUploading}] = useUploadFileMutation();
  const [deletePhoto] = useDeleteServicePhotoMutation();

  const handleUpload = ({file}: CustomRequestOption) => {
    if (!isFile(file) || isNil(serviceCaseAlbum) || isNil(serviceCase)) {
      return;
    }

    uploadFile({file})
      .unwrap()
      .then(({fileId}) => {
        addFilesToServiceVehicleAlbum({
          serviceAlbumId: serviceCaseAlbum?.id || '',
          vehicleId: serviceCase?.vehicleId || '',
          addFilesToServiceAlbumRequestBody: {
            fileIds: [fileId],
          },
        })
          .unwrap()
          .catch(handleApiError);
      })
      .catch(handleApiError);
  };

  const handleContextMenu = (event: MouseEvent, photos: GridPhoto[]) =>
    openDropdown(
      event,
      <>
        <DropdownItem
          label={i18n.t('general.actions.delete')}
          onClick={() =>
            openDeleteDialog({
              onConfirm: () =>
                deletePhoto({
                  serviceAlbumId: serviceCaseAlbum?.id ?? '',
                  vehicleId: serviceCase?.vehicleId ?? '',
                  servicePhotoId: photos[0]?.id,
                })
                  .unwrap()
                  .then(() => setBulkSelectFalse())
                  .catch(handleApiError),
              'data-testid': testIds.workshop.serviceCaseDetail('deletePhoto'),
            })
          }
          data-testid={testIds.workshop.serviceCaseDetail('deletePhoto')}
        />
      </>
    );

  const handleVehicleGalleryBatchDownload = (serviceAlbumId?: string) =>
    handleServiceAlbumDownload({
      vehicleId: serviceCase?.vehicleId ?? '',
      serviceAlbumId: serviceAlbumId ?? '',
    });

  const handleInspectionGalleryBatchDownload = (auditId?: string) =>
    handleDownload({
      vehiclePhotoLocation: PartialVehiclePhotosRoutes.inspections,
      vehicleId: serviceCase?.vehicleId ?? '',
      auditId,
    });

  const vehiclePhotos = getGridServiceAlbumPhotos(serviceVehicleAlbumPhotos);
  const checkinPhotos = getGridPhotosFromAudit(checkinAudit?.[0]?.categories);
  const handoverPhotos = getGridPhotosFromAudit(handoverAudit?.[0]?.categories);

  const [vehicleLightboxControls, {onOpen: onVehiclePhotoOpen}] = useLightbox('vehiclePhotos');
  const [checkinPhotosLightboxControls, {onOpen: onCheckinPhotoOpen}] =
    useLightbox('checkinPhotos');
  const [handoverPhotosLightboxControls, {onOpen: onHandoverPhotoOpen}] =
    useLightbox('handoverPhotos');

  const isServiceCaseVehicleLoading = isServiceCaseLoading;
  const isServiceCaseVehicleError = isServiceCaseError;

  const isCheckinPhotosLoading =
    isServiceCaseVehicleLoading || isCheckinLoading || isCheckinAuditLoading;
  const isCheckinPhotosError = isServiceCaseVehicleError || isCheckinError || isCheckinAuditError;

  const isHandoverPhotosLoading =
    isServiceCaseVehicleLoading || isHandoverLoading || isHandoverAuditLoading;
  const isHandoverPhotosError =
    isServiceCaseVehicleError || isHandoverError || isHandoverAuditError;

  return (
    <Main>
      <VStack spacing={4}>
        <Card
          title={i18n.t('entity.photo.labels.photos')}
          actions={[
            {
              type: 'button',
              variant: 'link',
              title: i18n.t('general.actions.downloadAll'),
              leftIcon: 'file/download',
              onClick: () => {
                handleVehicleGalleryBatchDownload(serviceCaseAlbum?.id);
              },
              isDisabled: !vehiclePhotos.length,
            },
            {
              type: 'button',
              variant: 'link',
              title: isBulkSelectActive
                ? i18n.t('general.actions.cancel')
                : i18n.t('general.actions.bulkActions'),
              leftIcon: 'navigation/menu',
              onClick: toggleBulkSelect,
              isDisabled: !vehiclePhotos.length,
            },
          ]}
          data-testid={testIds.workshop.serviceCaseDetail('serviceCasePhotos')}
        >
          <DataStatus
            isLoading={isServiceCaseVehicleLoading}
            isError={isServiceCaseVehicleError}
            minHeight={43}
          >
            <PhotoGrid
              uploadProps={{
                customRequest: handleUpload,
                uploadState: isFileUploading ? UploadState.Uploading : UploadState.Idle,
                isMultiple: true,
              }}
              images={vehiclePhotos}
              onContextMenu={handleContextMenu}
              isBulkSelectActive={isBulkSelectActive}
              onSlideClick={(_, index) => onVehiclePhotoOpen(index)}
              data-testid={testIds.workshop.serviceCaseDetail('serviceCasePhotos')}
            />
            <Lightbox
              data-testid={testIds.workshop.serviceCaseDetail('serviceCasePhotos')}
              controls={vehicleLightboxControls}
              images={makeLightboxPhotos(vehiclePhotos)}
            />
          </DataStatus>
        </Card>
        <Card
          title={i18n.t('entity.workshop.labels.checkIn')}
          actions={[
            {
              type: 'button',
              variant: 'link',
              title: i18n.t('entity.workshop.actions.openCheckin'),
              leftIcon: 'image/remove_red_eye',
              onClick: () => {
                setSearchParams(
                  {section: workshopSearchParams.serviceDetail.checkinHandover},
                  {replace: true}
                );
              },
            },
            {
              type: 'button',
              variant: 'link',
              title: i18n.t('general.actions.downloadAll'),
              leftIcon: 'file/download',
              onClick: () => {
                handleInspectionGalleryBatchDownload(checkinAudit?.[0]?.id);
              },
              isDisabled: !checkinPhotos.length,
            },
          ]}
          data-testid={testIds.workshop.serviceCaseDetail('checkinPhotos')}
        >
          <DataStatus
            isLoading={isCheckinPhotosLoading}
            isError={isCheckinPhotosError}
            minHeight={24}
          >
            <PhotoGrid
              images={checkinPhotos}
              onSlideClick={(_, index) => onCheckinPhotoOpen(index)}
              data-testid={testIds.workshop.serviceCaseDetail('checkinPhotos')}
            />
            <Lightbox
              data-testid={testIds.workshop.serviceCaseDetail('checkinPhotos')}
              controls={checkinPhotosLightboxControls}
              images={makeLightboxPhotos(checkinPhotos)}
            />
          </DataStatus>
        </Card>
        <Card
          title={i18n.t('entity.workshop.labels.handover')}
          actions={[
            {
              type: 'button',
              variant: 'link',
              title: i18n.t('entity.workshop.actions.openHandover'),
              leftIcon: 'image/remove_red_eye',
              onClick: () => {
                setSearchParams(
                  {
                    section: workshopSearchParams.serviceDetail.checkinHandover,
                    checkinHandover: HANDOVER_SECTION,
                  },
                  {replace: true}
                );
              },
            },
            {
              type: 'button',
              variant: 'link',
              title: i18n.t('general.actions.downloadAll'),
              leftIcon: 'file/download',
              onClick: () => {
                handleInspectionGalleryBatchDownload(handoverAudit?.[0]?.id);
              },
              isDisabled: !handoverPhotos.length,
            },
          ]}
          data-testid={testIds.workshop.serviceCaseDetail('handoverPhotos')}
        >
          <DataStatus
            isLoading={isHandoverPhotosLoading}
            isError={isHandoverPhotosError}
            minHeight={24}
          >
            <PhotoGrid
              images={handoverPhotos}
              onSlideClick={(_, index) => onHandoverPhotoOpen(index)}
              data-testid={testIds.workshop.serviceCaseDetail('handoverPhotos')}
            />
            <Lightbox
              data-testid={testIds.workshop.serviceCaseDetail('handoverPhotos')}
              controls={handoverPhotosLightboxControls}
              images={makeLightboxPhotos(handoverPhotos)}
            />
          </DataStatus>
        </Card>
        <ServiceCasePhotos
          vehicleId={serviceCase?.vehicleId || ''}
          ignoredServiceCaseId={serviceCaseId}
          hideWhenEmpty
        />
      </VStack>
    </Main>
  );
}

const makeLightboxPhotos = (photos: GridPhoto[] | Nullish) =>
  photos?.map((image) => ({
    id: image.id,
    url: image.originalUrl?.replace('resize', 'get') ?? image.url.replace('resize', 'get'),
    resizeUrl: image.resizeUrl,
    title: image.title,
    fileName: image.title || image.id ? `${image.title || image.id}.jpg` : undefined,
  }));
