import {isFeatureEnabled} from 'feature-flags';
import {Button, DataStatus, Dropdown, DropdownItem, openDialog, Tooltip} from 'platform/components';

import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetCustomerV2Query} from '@dms/api/customer';
import {EntityResourceIds} from '@dms/api/shared';
import {useGetEmailConfigQuery, useGetKonzultaConfigQuery} from '@dms/api/tenant';
import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {SendEmail, SendSms} from '@dms/shared';

import {useBoolean} from 'shared';

interface ActivitySidebarSendMessageProps {
  customerId: string;
  resourceId?: EntityResourceIds;
  recordId?: string;
}

export function ActivitySidebarSendMessage(props: ActivitySidebarSendMessageProps) {
  const [, setOpen, setClosed] = useBoolean();

  const {
    data: emailConfig,
    isLoading: isEmailConfigLoading,
    isError: isEmailConfigErrored,
  } = useGetEmailConfigQuery();

  const {
    data: konzultaConfig,
    isLoading: isKonzultaConfigLoading,
    isError: isKonzultaConfigErrored,
  } = useGetKonzultaConfigQuery();

  const {
    data: customerData,
    isLoading: isCustomerLoading,
    isError: isCustomerErrored,
  } = useGetCustomerV2Query({customerId: props.customerId});

  const isLoading = isEmailConfigLoading || isKonzultaConfigLoading || isCustomerLoading;
  const isError = isEmailConfigErrored || isKonzultaConfigErrored || isCustomerErrored;

  if (isNilOrEmpty(props.customerId)) {
    return null;
  }

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Dropdown
        onOpen={setOpen}
        onClose={setClosed}
        closeOnBlur
        closeOnSelect
        dropdownControl={
          <Button
            leftIcon="communication/email"
            title={i18n.t('general.actions.sendMessage')}
            variant="link"
            size="small"
          />
        }
      >
        <Tooltip
          label={i18n.t('general.notifications.setupEmailProvider')}
          placement="top-start"
          isDisabled={emailConfig?.enabled}
        >
          <DropdownItem
            label={i18n.t('general.labels.email')}
            onClick={() =>
              openDialog(
                <SendEmail
                  customerId={props.customerId}
                  resourceId={props.resourceId}
                  recordId={props.recordId}
                  shouldDisplayTemplateChoice={isFeatureEnabled(featureFlags.MESSAGE_TEMPLATES)}
                />,
                {
                  scrollBehavior: 'outside',
                  title: i18n.t('general.labels.email'),
                }
              )
            }
            isDisabled={!emailConfig?.enabled}
            leftIcon="communication/email"
          />
        </Tooltip>

        <Tooltip
          label={i18n.t('general.notifications.setupSmsProvider')}
          placement="bottom-start"
          isDisabled={konzultaConfig?.enabled === true}
        >
          <DropdownItem
            label={i18n.t('general.labels.sms')}
            onClick={() =>
              openDialog(
                <SendSms
                  customerId={props.customerId}
                  resourceId={props.resourceId}
                  recordId={props.recordId}
                  selectedContactId={customerData?.foundingPerson?.id}
                  shouldDisplayTemplateChoice={isFeatureEnabled(featureFlags.MESSAGE_TEMPLATES)}
                />,
                {
                  scrollBehavior: 'outside',
                  title: i18n.t('general.labels.smsMessage'),
                }
              )
            }
            isDisabled={!konzultaConfig?.enabled}
            leftIcon="communication/textsms"
          />
        </Tooltip>
      </Dropdown>
    </DataStatus>
  );
}
