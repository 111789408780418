import {FormControl, FormField, Separator, TextInput} from 'platform/components';
import {Grid, Heading, VStack} from 'platform/foundation';

import {defaultTo} from 'ramda';
import {isTrue} from 'ramda-adjunct';

import {useGetManufacturersQuery} from '@dms/api/metadaAdminManufacturer';
import {GetWarehousesResponse} from '@dms/api/metadaWarehouse';
import {useGetWarehouseAccountsQuery} from '@dms/api/metadaWarehouseAccount';
import {BaseArticle} from '@dms/api/metadaWarehouseArticle';
import {useGetDiscountGroupsQuery} from '@dms/api/metadaWarehouseDiscountGroup';
import {useGetMarketingCodesQuery} from '@dms/api/metadaWarehouseMarketingCode';
import {useGetSuppliersQuery} from '@dms/api/metadaWarehouseSupplier';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {
  ArticleCreateTreeFolderPath as TreeFolderPath,
  getOptionsFromManufacturers,
  getOptionsFromSuppliers,
  getOptionsFromWarehouseAccounts,
  getOptionsFromWarehouses,
  useGetVatRatesOptions,
} from '@dms/shared';

import {Nullish, useNavigate} from 'shared';

import {getMatchingManufacturers} from '../../../../../utils/getMatchingManufacturers';
import {getOptionsFromDiscountGroups} from '../../../../../utils/getOptionsFromDiscountGroups';
import {getOptionsFromMarketingCodes} from '../../../../../utils/getOptionsFromMarketingCodes';

interface GeneralInformationTabBasicInformationFormProps {
  articleId: string;
  control: FormControl<BaseArticle>;
  manufacturerId: string;
  warehouseNumber: string | Nullish;
  warehouses: GetWarehousesResponse;
  leafId?: string | null;
  isRemovable: boolean;
  leafIdChange: (leafId?: string) => void;
}

export function GeneralInformationTabBasicInformationForm(
  props: GeneralInformationTabBasicInformationFormProps
) {
  const navigate = useNavigate();
  const {data: suppliers} = useGetSuppliersQuery();
  const {data: marketingCodes} = useGetMarketingCodesQuery();
  const {data: discountGroups} = useGetDiscountGroupsQuery();
  const {data: warehouseAccounts} = useGetWarehouseAccountsQuery();
  const {data: manufacturers} = useGetManufacturersQuery();

  const [vatOptions] = useGetVatRatesOptions({fullLabel: true});

  const matchingManufacturers = getMatchingManufacturers(manufacturers, props.manufacturerId);

  const isManufacturerEditable = isTrue(props.isRemovable);

  return (
    <>
      <VStack spacing={3}>
        <Heading size={4}>{i18n.t('general.labels.basicInformation')}</Heading>
        <Grid columns={2}>
          <FormField
            control={props.control}
            type="text"
            name="name"
            label={i18n.t('general.labels.name')}
            data-testid={testIds.warehouse.articleDetailOverview('name')}
          />

          <FormField
            control={props.control}
            type="text"
            name="description"
            label={i18n.t('general.labels.description')}
            data-testid={testIds.warehouse.articleDetailOverview('description')}
          />
        </Grid>
        <Grid columns={4}>
          <FormField
            control={props.control}
            isRequired
            isDisabled={!isManufacturerEditable}
            type="text"
            name="manufacturerNumber"
            label={i18n.t('entity.warehouse.labels.manufacturerNumber')}
            data-testid={testIds.warehouse.articleDetailOverview('manufacturerNumber')}
          />
          <FormField
            isNotClearable
            isRequired
            isDisabled={!isManufacturerEditable}
            control={props.control}
            name="manufacturerId"
            type="choice"
            label={i18n.t('entity.warehouse.labels.manufacturer')}
            options={getOptionsFromManufacturers(matchingManufacturers)}
            data-testid={testIds.warehouse.articleDetailOverview('manufacturer')}
          />

          <FormField
            isNotClearable
            isRequired
            control={props.control}
            type="choice"
            name="supplierId"
            label={i18n.t('general.labels.supplier')}
            data-testid={testIds.warehouse.articleDetailOverview('supplier')}
            options={getOptionsFromSuppliers(suppliers)}
          />

          <FormField
            control={props.control}
            type="choice"
            name="discountGroup"
            options={getOptionsFromDiscountGroups(discountGroups)}
            label={i18n.t('general.labels.discountGroup')}
            labelActions={[
              {
                title: i18n.t('general.labels.settings'),
                leftIcon: 'action/open_in_new',
                onClick: () => {
                  navigate(settingsRoutes.warehousesDiscountGroup);
                },
              },
            ]}
            data-testid={testIds.warehouse.articleDetailOverview('rabatGroup')}
          />
        </Grid>
        <Grid columns={4}>
          <FormField
            isDisabled
            control={props.control}
            type="choice"
            options={getOptionsFromWarehouses(props.warehouses)}
            name="warehouseId"
            label={i18n.t('entity.warehouse.labels.warehouse')}
            isNotClearable
            data-testid={testIds.warehouse.articleDetailOverview('warehouseId')}
          />
          <TextInput
            value={defaultTo(null, props.warehouseNumber)}
            label={i18n.t('entity.warehouse.labels.warehouseNumber')}
            isDisabled
            data-testid={testIds.warehouse.articleDetailOverview('warehouseNumber')}
          />

          <FormField
            control={props.control}
            type="text"
            name="storageLocation"
            label={i18n.t('general.warehouse.labels.storageLocation')}
            data-testid={testIds.warehouse.articleDetailOverview('storageLocation')}
          />
        </Grid>
        <TreeFolderPath
          leafId={props.leafId}
          onChange={(activeFolder) => props.leafIdChange(activeFolder?.id)}
          data-testid={testIds.warehouse.articleDetailOverview('treeFolderPath')}
        />
      </VStack>
      <Separator />
      <VStack spacing={3}>
        <Grid columns={4}>
          <FormField
            control={props.control}
            type="choice"
            name="marketingCode"
            options={getOptionsFromMarketingCodes(marketingCodes)}
            label={i18n.t('general.labels.marketingCode')}
            labelActions={[
              {
                title: i18n.t('general.labels.settings'),
                leftIcon: 'action/open_in_new',
                onClick: () => {
                  navigate(settingsRoutes.warehousesMarketingCode);
                },
              },
            ]}
            data-testid={testIds.warehouse.articleDetailOverview('marketingCode')}
          />

          <FormField
            control={props.control}
            type="choice"
            name="warehouseAccount"
            options={getOptionsFromWarehouseAccounts(warehouseAccounts)}
            label={i18n.t('entity.warehouse.labels.warehouseAccount')}
            labelActions={[
              {
                title: i18n.t('general.labels.settings'),
                leftIcon: 'action/open_in_new',
                onClick: () => {
                  navigate(settingsRoutes.warehousesAccount);
                },
              },
            ]}
            data-testid={testIds.warehouse.articleDetailOverview('warehouseAccount')}
            isNotClearable
          />

          <FormField
            control={props.control}
            type="choice"
            options={vatOptions}
            name="vatType"
            label={i18n.t('general.labels.vat')}
            isNotClearable
            data-testid={testIds.warehouse.articleDetailOverview('rates')}
          />
        </Grid>
      </VStack>
      <Separator />
    </>
  );
}
