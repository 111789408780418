import {Action, Card, closeCurrentDialog, openDeleteDialog, openDialog} from 'platform/components';
import {Box} from 'platform/foundation';

import {useMemo} from 'react';

import {assoc, path} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {useGetParticipationQuery} from '@dms/api/participation';
import {useDeleteEarningMutation} from '@dms/api/saleVehicle';
import {EntityResourceIds} from '@dms/api/shared';
import {useGetVehicleQuery} from '@dms/api/vehicle';
import i18n from '@dms/i18n';
import {
  ApplyVehiclePresetForm,
  CreateVehiclePresetForm,
  handleApiError,
  usePermissions,
  VehicleEarningCostForm,
} from '@dms/shared';

import {buildArray, suffixTestId, TestIdProps} from 'shared';

import {ActionCallback, DataGrid, useRefreshDataGrid} from 'features/datagrid';

import {EditEarningForm} from './EditEarningForm';

interface VehicleEarningsGridCardProps extends TestIdProps {
  vehicleId: string;
  isCostsEditable?: boolean;
}

export function VehicleEarningsGridCard(props: VehicleEarningsGridCardProps) {
  const [deleteEarning] = useDeleteEarningMutation();
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const {data: vehicleParticipation} = useGetParticipationQuery({
    resourceId: EntityResourceIds.vehicle,
    recordId: props.vehicleId,
  });

  const {data: vehicle} = useGetVehicleQuery({vehicleId: props.vehicleId});

  const [canAddEarning] = usePermissions({
    permissionKeys: ['vehicleAddEarning'],
    scopes: {
      vehicleAddEarning: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
    },
  });

  /**
   * Memo is used because of a bug in DataGrid. See https://carvago.atlassian.net/browse/T20-18662
   */
  const queryModifier = useMemo(() => assoc('vehicle', props.vehicleId), [props.vehicleId]);

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData, refreshData}) => {
    const earningId = isArray(rowId) ? rowId[0] : rowId;
    switch (actionKey) {
      case 'delete':
        openDeleteDialog({
          name: path(['name', 'value'], rowData),
          'data-testid': suffixTestId('deleteDialog', props),
          onConfirm: () =>
            deleteEarning({vehicleId: props.vehicleId, earningId})
              .unwrap()
              .then(refreshData)
              .catch(handleApiError),
        });
        break;

      case 'edit':
        openDialog(
          <EditEarningForm
            data-testid={suffixTestId('editDialog', props)}
            earningId={earningId}
            vehicleId={props.vehicleId}
            onCancel={closeCurrentDialog}
            onSuccess={() => {
              refreshData();
              closeCurrentDialog();
            }}
            isCostsEditable={props.isCostsEditable}
          />,
          {title: i18n.t('entity.vehicle.earnings.editEarning')}
        );
        break;
    }
  };

  const openAddDialog = () =>
    openDialog(
      <VehicleEarningCostForm
        data-testid={suffixTestId('addDialog', props)}
        entity="earning"
        vehicleId={props.vehicleId}
        onCancel={closeCurrentDialog}
        onSuccess={() => {
          refreshDataGrid();
          closeCurrentDialog();
        }}
        isCostsEditable={props.isCostsEditable}
      />,
      {title: i18n.t('entity.vehicle.earnings.addEarning')}
    );

  const openApplyPresetDialog = () =>
    openDialog(
      <ApplyVehiclePresetForm
        entity="earning"
        vehicleId={props.vehicleId}
        onCancel={closeCurrentDialog}
        onSuccess={() => {
          refreshDataGrid();
          closeCurrentDialog();
        }}
        data-testid={suffixTestId('applyPresetDialog', props)}
      />,

      {title: i18n.t('entity.earningsCosts.actions.addPreset'), size: 'small'}
    );

  const openCreatePresetDialog = () =>
    openDialog(
      <CreateVehiclePresetForm
        entity="earning"
        vehicleId={props.vehicleId}
        onCancel={closeCurrentDialog}
        onSuccess={closeCurrentDialog}
        data-testid={suffixTestId('createPresetDialog', props)}
      />,

      {title: i18n.t('entity.earningsCosts.actions.savePreset'), size: 'small'}
    );

  const actions = buildArray<Action>()
    .when(canAddEarning, {
      type: 'button',
      variant: 'link',
      onClick: openCreatePresetDialog,
      leftIcon: 'content/save',
      title: i18n.t('entity.earningsCosts.actions.savePreset'),
      'data-testid': suffixTestId('createPreset', props),
    })
    .when(canAddEarning, {
      type: 'button',
      variant: 'link',
      onClick: openApplyPresetDialog,
      leftIcon: 'content/add_circle',
      title: i18n.t('entity.earningsCosts.actions.addFromPreset'),
      'data-testid': suffixTestId('addPresetButton', props),
    })
    .when(canAddEarning, {
      type: 'button',
      variant: 'link',
      onClick: openAddDialog,
      leftIcon: 'content/add_circle',
      title: i18n.t('entity.earningsCosts.actions.addItem'),
      'data-testid': suffixTestId('addButton', props),
    });

  return (
    <>
      <Card title={i18n.t('entity.earningsCosts.labels.earnings')} actions={actions}>
        <Box height={65}>
          <DataGrid
            gridCode="vehicle_earnings"
            ref={dataGridRef}
            actionCallback={actionCallback}
            queryModifier={queryModifier}
            emptyState={{
              action: canAddEarning
                ? {
                    title: i18n.t('entity.earningsCosts.actions.addEarning'),
                    leftIcon: 'content/add_circle',
                    onClick: openAddDialog,
                  }
                : undefined,
            }}
            data-testid={suffixTestId('grid', props)}
          />
        </Box>
      </Card>
    </>
  );
}
