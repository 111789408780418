import {omneticApi} from '@dms/api/core';

export const publishingAutomaticUpdateApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    isPublishingAutomaticUpdateEnabled: build.query<{value: boolean}, void>({
      query: () => ({
        url: `/dms/v1/settings/publishing-automatic-update/tenant`,
      }),
      transformResponse: (response: {value: boolean}[]) => response?.[0] ?? {value: false},
    }),
    setPublishingAutomaticUpdateEnabled: build.mutation<void, boolean>({
      query: (enabled) => ({
        url: `/dms/v1/settings/publishing-automatic-update/tenant`,
        method: 'POST',
        body: {value: enabled, force: false},
      }),
      onQueryStarted: async (queryArg, {dispatch, queryFulfilled}) => {
        const patchResult = dispatch(
          publishingAutomaticUpdateApi.util.updateQueryData(
            'isPublishingAutomaticUpdateEnabled',
            undefined,
            (draft) => {
              draft.value = queryArg;
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useIsPublishingAutomaticUpdateEnabledQuery,
  useSetPublishingAutomaticUpdateEnabledMutation,
} = publishingAutomaticUpdateApi;
