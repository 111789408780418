import {Dialog} from 'platform/components';
import {Text} from 'platform/foundation';

import {usePostServicePackageCloneMutation} from '@dms/api/metadaWorkshopServicePackage';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {suffixTestId, RequiredTestIdProps} from 'shared';

interface ConfirmCloneDialogProps extends RequiredTestIdProps {
  id: string | null;
  onClose: () => void;
  onCloseComplete: () => void;
}

export function ConfirmCloneDialog(props: ConfirmCloneDialogProps) {
  const [postServicePackageClone, {isLoading}] = usePostServicePackageCloneMutation();

  return (
    <Dialog
      isOpen={!!props.id}
      size="small"
      onClose={props.onClose}
      buttons={[
        {
          variant: 'secondary',
          onClick: props.onClose,
          title: i18n.t('general.actions.cancel'),
        },
        {
          variant: 'primary',
          onClick: () => {
            postServicePackageClone({servicePackageId: props.id ?? ''})
              .unwrap()
              .then(props.onCloseComplete)
              .catch(handleApiError);
          },
          title: i18n.t('general.actions.duplicate'),
          isLoading,
        },
      ]}
      data-testid={suffixTestId('deleteDialog', props)}
    >
      <Text alternative>
        {i18n.t('entity.workshopServicePackages.labels.duplicateServicePackage')}
      </Text>
    </Dialog>
  );
}
