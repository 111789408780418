import {metadaApi} from '@dms/api/core';

import {
  AnnualMileageRequest,
  AnnualMileageResponse,
  GetHistoryOrderProfitabilityApiArg,
  GetHistoryOrderProfitabilityApiArgSchema,
  GetHistoryOrderProfitabilityApiResponse,
  GetHistoryOrderProfitabilityApiResponseSchema,
} from './types';

export const metadaWorkshopApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getHistoryOrderProfitability: build.query<
      GetHistoryOrderProfitabilityApiResponse,
      GetHistoryOrderProfitabilityApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/vehicle/${queryArg.vehicleId}/order-profitability`,
        queryArg,
      }),
      providesTags: ['profitabilityHistory'],
      extraOptions: {
        requestSchema: GetHistoryOrderProfitabilityApiResponseSchema,
        responseSchema: GetHistoryOrderProfitabilityApiArgSchema,
      },
    }),
    annualMileage: build.query<AnnualMileageResponse, AnnualMileageRequest>({
      query: (queryArg) => ({
        url: `/service/v1/vehicle/${queryArg.vehicleId}/annual-mileage`,
      }),
    }),
  }),
});

export const {useGetHistoryOrderProfitabilityQuery, useAnnualMileageQuery} = metadaWorkshopApi;
