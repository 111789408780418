import {
  Alert,
  Form,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Grid, Show, Space, VStack} from 'platform/foundation';
import {object} from 'yup';

import {isNil, isNotNil} from 'ramda';

import {GetWarehousesResponse} from '@dms/api/metadaWarehouse';
import {
  GetMarketingCodeResponse,
  usePatchMarketingCodeMutation,
  usePostMarketingCodeMutation,
} from '@dms/api/metadaWarehouseMarketingCode';
import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';
import {getOptionsFromWarehouses, handleApiError, useDuplicateErrorHandler} from '@dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId, useNavigate, yupString} from 'shared';

import {SettingsFooter} from '../../../components/SettingsFooter/SettingsFooter';
import {MarketingCodeForm} from '../types/MarketingCodeForm';

export interface MarketingCodeDetailFormProps extends RequiredTestIdProps {
  marketingCode: GetMarketingCodeResponse | Nullish;
  warehouses: GetWarehousesResponse | Nullish;
}

export function MarketingCodeDetailForm(props: MarketingCodeDetailFormProps) {
  const navigate = useNavigate();

  const {duplicateError, duplicateErrorHandler} = useDuplicateErrorHandler();

  const [postMarketingCode] = usePostMarketingCodeMutation();
  const [patchMarketingCode] = usePatchMarketingCodeMutation();

  const handleNavigateBack = () => navigate(settingsRoutes.warehousesMarketingCode);

  const handleSubmit: FormSubmitHandler<MarketingCodeForm> = async (formValues) => {
    if (isNotNil(props.marketingCode)) {
      return await patchMarketingCode({
        body: formValues,
        marketingCodeId: props.marketingCode.id,
      })
        .unwrap()
        .then(() =>
          showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'))
        )
        .then(handleNavigateBack)
        .catch(handleApiError);
    }

    await postMarketingCode(formValues)
      .unwrap()
      .then(() => showNotification.success(i18n.t('general.notifications.successfullyCreated')))
      .then(handleNavigateBack)
      .catch(duplicateErrorHandler);
  };

  return (
    <Form<MarketingCodeForm>
      schema={formSchema}
      defaultValues={props.marketingCode}
      onSubmit={handleSubmit}
    >
      {(control) => (
        <VStack spacing={4} width="50%" data-testid={props['data-testid']}>
          <Show when={duplicateError}>
            <Alert
              variant="error"
              title={i18n.t('entity.warehouse.labels.marketingCodeAlreadyExists')}
              data-testid={suffixTestId('duplicityAlert', props)}
            />
          </Show>

          <Grid columns={2}>
            <FormField
              isNotClearable
              isRequired
              isDisabled={isNotNil(props.marketingCode)}
              control={control}
              type="choice"
              name="warehouseId"
              label={i18n.t('entity.warehouse.labels.warehouse')}
              options={getOptionsFromWarehouses(props.warehouses)}
              data-testid={suffixTestId('inputs.warehouseId', props)}
            />
          </Grid>

          <Separator />

          <Grid columns={2}>
            <FormField
              isRequired
              control={control}
              type="text"
              name="name"
              label={i18n.t('general.labels.name')}
              data-testid={suffixTestId('inputs.name', props)}
            />
            <FormField
              control={control}
              type="text"
              name="externalCode"
              label={i18n.t('general.labels.externalCode')}
              data-testid={suffixTestId('inputs.externalCode', props)}
            />
          </Grid>

          <Grid columns={2}>
            <FormField
              control={control}
              type="number"
              isStepperVisible
              name="salesBasePriceMarkup"
              label={`${i18n.t('entity.warehouse.labels.salesBasePriceMarkup')} (%)`}
              data-testid={suffixTestId('inputs.salesBasePriceMarkup', props)}
            />
            <FormField
              control={control}
              type="number"
              isStepperVisible
              name="warrantyPriceMarkup"
              label={`${i18n.t('entity.warehouse.labels.warrantyPriceMarkup')} (%)`}
              data-testid={suffixTestId('inputs.warrantyPriceMarkup', props)}
            />
          </Grid>

          <Space fillAvailable />

          <SettingsFooter
            actions={[
              {
                type: 'button',
                variant: 'secondary',
                title: isNil(props.marketingCode)
                  ? i18n.t('general.actions.discard')
                  : i18n.t('general.actions.discardChanges'),
                onClick: handleNavigateBack,
                'data-testid': suffixTestId('actions.discard', props),
              },
              {
                type: 'form-button',
                control,
                buttonType: 'submit',
                variant: 'primary',
                title: isNil(props.marketingCode)
                  ? i18n.t('general.actions.save')
                  : i18n.t('general.actions.saveChanges'),
                'data-testid': suffixTestId('actions.submit', props),
              },
            ]}
          />
        </VStack>
      )}
    </Form>
  );
}

const formSchema = object({
  warehouseId: yupString.required(),
  name: yupString.required(),
});
