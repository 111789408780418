import {curry, keys, reduce} from 'ramda';

import {omneticApi} from '@dms/api/core';
import {
  GetCommissionRuleArg,
  GetCommissionRuleRes,
  GetCommissionRulesArg,
  GetCommissionRulesRes,
  UpdateCommissionRuleRes,
  UpdateCommissionRuleArg,
  CreateCommissionRuleArg,
  CreateCommissionRuleRes,
  DeleteCommissionRuleRes,
  DeleteCommissionRuleArg,
} from '@dms/api/shared';

import {applyCommissionRuleApiRes, applyCommissionRuleApiArg} from './types';

const mapKeys = curry((fn: (val: string) => string, obj: Record<string, any>) =>
  reduce<string, Record<string, any>>(
    (acc, key) => {
      acc[fn(key)] = obj[key];
      return acc;
    },
    {},
    keys<Record<string, any>>(obj)
  )
);

export const commissionApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getCommissionRule: build.query<GetCommissionRuleRes, GetCommissionRuleArg>({
      query: (queryArg) => ({
        url: `/dms/v1/commission-rule/${queryArg.commissionRuleId}`,
      }),
      providesTags: ['commissionRule'],
    }),

    getCommissionRules: build.query<GetCommissionRulesRes, GetCommissionRulesArg>({
      query: (queryArg) => {
        const params = mapKeys((key) => `priceWithVat[${key}]`, queryArg ?? {});
        return {
          url: `/dms/v1/commission-rule`,
          params,
        };
      },
    }),

    updateCommissionRule: build.mutation<UpdateCommissionRuleRes, UpdateCommissionRuleArg>({
      query: ({id, ...body}) => ({
        url: `/dms/v1/commission-rule/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['commissionRule'],
    }),

    createCommissionRule: build.mutation<CreateCommissionRuleRes, CreateCommissionRuleArg>({
      query: (body) => ({
        url: `/dms/v1/commission-rule`,
        method: 'POST',
        body,
      }),
    }),

    deleteCommissionRule: build.mutation<DeleteCommissionRuleRes, DeleteCommissionRuleArg>({
      query: ({id}) => ({
        url: `/dms/v1/commission-rule/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['commissionRule'],
    }),

    applyCommissionRules: build.mutation<applyCommissionRuleApiRes, applyCommissionRuleApiArg>({
      query: ({businessCaseId, offerId, vehicleId}) => ({
        url: `/dms/v1/business-case/${businessCaseId}/offer/${offerId}/purchase-brokerage-vehicle/${vehicleId}/apply-commission-rules`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, arg) => [
        {type: 'BusinessCaseDetail', id: arg.businessCaseId},
        'BusinessCasePurchasePrices',
      ],
    }),
  }),
});

export const {
  useGetCommissionRuleQuery,
  useLazyGetCommissionRulesQuery,
  useUpdateCommissionRuleMutation,
  useCreateCommissionRuleMutation,
  useDeleteCommissionRuleMutation,
  useApplyCommissionRulesMutation,
} = commissionApi;
