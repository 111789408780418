import {NumberInput, TextInput} from 'platform/components';
import {Box, HStack, Show, Text, VStack} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';
import {match} from 'ts-pattern';
import {z} from 'zod';

import {isNil, isNotNil, not} from 'ramda';

import i18n from '@dms/i18n';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {DEFAULT_CURRENCY} from '../../../constants/currency';
import {useBasketItemQuantity} from '../../../hooks/useBasketItemQuantity';
import {EitherQuantityOrError} from '../../../types/basket/EitherQuantityOrError';
import {MappedBasketItem} from '../../../types/basket/MappedBasketItem';
import {CorrectionBasketItem, CorrectionType} from '../types/CorrectionBasketItem';

interface AfterSalesCorrectionBasketItemQuantityWithPriceProps<T extends CorrectionBasketItem>
  extends RequiredTestIdProps {
  item: MappedBasketItem<T>;
  handlingUnit: string | Nullish;
  onQuantityChange: (itemId: string, quantity: EitherQuantityOrError) => Promise<void>;
}

export function AfterSalesCorrectionBasketItemQuantityWithPrice<T extends CorrectionBasketItem>(
  props: AfterSalesCorrectionBasketItemQuantityWithPriceProps<T>
) {
  const formatCurrency = useFormatCurrency();

  const {quantity, quantityError, handleQuantityChange} = useBasketItemQuantity({
    itemId: props.item.id,
    currentQuantity:
      props.item.correctionType === 'VALUE_CORRECTION'
        ? props.item.correctionValue
        : props.item.quantity,
    validationSchema: quantitySchema(
      props.item.minMaxQuantity?.minQuantity,
      props.item.minMaxQuantity?.maxQuantity,
      props.item.correctionType
    ),
    onQuantityChange: props.onQuantityChange,
  });

  const itemCurrency = props.item.totalPrice.withoutVat.currency ?? DEFAULT_CURRENCY;

  const totalPrice = formatCurrency(
    props.item.totalPrice.withoutVat.amount ?? 0,
    props.item.totalPrice.withoutVat.currency ?? DEFAULT_CURRENCY,
    2
  );

  return (
    <HStack justify="space-between">
      <HStack spacing={2}>
        <Box width={29}>
          <NumberInput
            value={quantity}
            errorMessage={quantityError}
            decimalPlaces={4}
            onChange={handleQuantityChange}
            isStepperVisible
            isInvalid={isNotNil(quantityError)}
            isDisabled={not(props.item.quantityEditingAllowed)}
            data-testid={suffixTestId('quantity', props)}
          />
        </Box>
        <Box width={11}>
          <Show when={props.item.correctionType === 'RETURN_TO_SUPPLIER'}>
            <TextInput
              value={props.handlingUnit ?? DEFAULT_HANDLING_UNIT}
              isDisabled
              data-testid={suffixTestId('unit', props)}
            />
          </Show>
          <Show when={props.item.correctionType === 'VALUE_CORRECTION'}>
            <TextInput
              value={itemCurrency ?? DEFAULT_CURRENCY}
              isDisabled
              data-testid={suffixTestId('currency', props)}
            />
          </Show>
        </Box>
      </HStack>

      <HStack>
        <VStack align="flex-end">
          <Text size="xSmall" color="tertiary">
            {i18n.t('general.labels.totalPrice')}
          </Text>
          <Text size="small" alternative data-testid={suffixTestId('price', props)}>
            {totalPrice}
          </Text>
        </VStack>
      </HStack>
    </HStack>
  );
}

const DEFAULT_HANDLING_UNIT = 'pcs';

const quantitySchema = (
  minQuantity: number,
  maxQuantity: number | null,
  correctionType: CorrectionType
) =>
  match(correctionType)
    .with('VALUE_CORRECTION', () =>
      z.number().superRefine((value, ctx) => {
        if (value === 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18n.t('entity.warehouse.validations.correctionZero'),
          });
        }
        if (value > 0 && value < minQuantity) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18n.t('entity.warehouse.validations.correctionMinPositive', {
              min: minQuantity,
            }),
          });
        }
        if (value < 0 && value > -minQuantity) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18n.t('entity.warehouse.validations.correctionMinNegative', {
              min: -minQuantity,
            }),
          });
        }
        if (isNotNil(maxQuantity) && value > maxQuantity) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18n.t('entity.warehouse.validations.correctionMax', {max: maxQuantity}),
          });
        }
      })
    )
    .otherwise(() =>
      z
        .number()
        .min(minQuantity, {
          message: i18n.t('entity.warehouse.validations.correctionMinPositive', {
            min: minQuantity,
          }),
        })
        .refine(
          (value) => {
            if (isNil(maxQuantity) || isNil(value)) {
              return true;
            }
            return value <= maxQuantity;
          },
          {
            message: i18n.t('entity.warehouse.validations.correctionMax', {max: maxQuantity}),
          }
        )
    );
