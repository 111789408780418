import {Box, HStack, Show} from 'platform/foundation';

import {MenuItemProps} from '@dms/api/shared';
import {DevSettings} from '@dms/dev-settings';
import {environment} from '@dms/environment';
import {featureFlags} from '@dms/feature-flags';
import {usePermissions} from '@dms/shared';
import {TaskCenterPopup} from '@dms/task-manager';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {NotificationsPopup} from '../../NotificationsPopup/NotificationsPopup';
import {BranchSelect} from './BranchSelect';
import {HeaderContent} from './HeaderContent';
import {MyProfileAndSettings} from './MyProfileAndSettings';
import {ReleasePopup} from './ReleasePopup';

interface HeaderProps extends RequiredTestIdProps {
  module?: MenuItemProps;
}

export function Header(props: HeaderProps) {
  const [canReadTasks] = usePermissions({
    permissionKeys: ['readTask'],
  });

  return (
    <HStack align="center">
      <Box flex={1}>
        <HeaderContent
          title={props.module?.layoutProps?.title}
          data-testid={suffixTestId('header-content', props)}
        />
      </Box>

      <BranchSelect />
      <Show whenFeatureEnabled={featureFlags.CORE_TASK_MANAGEMENT} when={canReadTasks}>
        <TaskCenterPopup data-testid="layout-header" iconSize="default" />
      </Show>
      <Show whenFeatureEnabled={featureFlags.NOTIFICATION_CENTER}>
        <NotificationsPopup data-testid="layout-header" />
      </Show>
      <ReleasePopup />
      <Show when={environment.ENV_TYPE === 'dev'}>
        <DevSettings />
      </Show>
      <MyProfileAndSettings />
    </HStack>
  );
}
