import {BaseFlagProps} from 'platform/components';
import {match} from 'ts-pattern';

import {always, isNil} from 'ramda';

import {SaleVehicleSellingState} from '@dms/api/saleVehiclePhoto';
import i18n from '@dms/i18n';

import {Nullish} from 'shared';

import {EMPTY_PLACEHOLDER} from '../constants/placeholders';

export const getVehicleSellingStateFlagProps = (state: SaleVehicleSellingState | Nullish) => {
  if (isNil(state)) {
    return null;
  }

  return match<SaleVehicleSellingState, BaseFlagProps>(state)
    .with(
      'not-set',
      always({
        isSubtle: true,
        colorScheme: 'neutral',
        label: i18n.t('entity.saleVehicle.labels.notSet'),
      })
    )
    .with(
      'for-sale',
      always({
        isSubtle: true,
        colorScheme: 'orange',
        label: i18n.t('entity.saleVehicle.labels.forSale'),
      })
    )
    .with(
      'selling',
      always({
        isSubtle: true,
        colorScheme: 'blue',
        label: i18n.t('entity.saleVehicle.labels.selling'),
      })
    )
    .with(
      'not-for-sale',
      always({
        isSubtle: true,
        colorScheme: 'red',
        label: i18n.t('entity.saleVehicle.labels.notForSale'),
      })
    )
    .with(
      'sold',
      always({
        isSubtle: true,
        colorScheme: 'green',
        label: i18n.t('entity.saleVehicle.labels.sold'),
      })
    )
    .with(
      'not-sold',
      always({
        isSubtle: true,
        colorScheme: 'red',
        label: i18n.t('entity.saleVehicle.labels.notSold'),
      })
    )
    .with(
      'contract',
      always({
        isSubtle: true,
        colorScheme: 'blue',
        label: i18n.t('entity.saleVehicle.labels.contract'),
      })
    )
    .with(
      'payment-pending',
      always({
        isSubtle: true,
        colorScheme: 'green',
        label: i18n.t('entity.saleVehicle.labels.paymentPending'),
      })
    )
    .with(
      'handed-over',
      always({
        isSubtle: true,
        colorScheme: 'green',
        label: i18n.t('entity.saleVehicle.labels.handedOver'),
      })
    )
    .with(
      'cannot-be-sold',
      always({
        isSubtle: true,
        colorScheme: 'red',
        label: i18n.t('entity.saleVehicle.labels.cannotBeSold'),
      })
    )
    .otherwise(
      always({
        isSubtle: true,
        colorScheme: 'neutral',
        label: EMPTY_PLACEHOLDER,
      })
    );
};
