import {match} from 'ts-pattern';

import {always, propEq, find} from 'ramda';

import {BasicCategoryOfStructureResponseBody} from '@dms/api/carAudit';
import i18n from '@dms/i18n';

import {Nullish} from 'shared';

import {CategoryUniqueKey, SECOND_SET_VALUES} from '../constants';
import {getTranslation} from './getTranslation';

export const getCategoryName = (
  category: BasicCategoryOfStructureResponseBody,
  parentCategory: BasicCategoryOfStructureResponseBody | Nullish,
  locale: string | Nullish
) =>
  match([category.uniqueKey, category.key])
    .when(
      ([uniqueKey]) => uniqueKey === CategoryUniqueKey.LF_WHEEL,
      always(i18n.t`entity.condition.labels.theFirstSet`)
    )
    .when(
      ([, key]) => key && SECOND_SET_VALUES[key],
      () => {
        const toggleParam = parentCategory?.paramDefinitions?.relatedActions?.[0];
        const radioButtonsParam = toggleParam?.values?.[0]?.content?.relatedActions?.[0];
        const label = find(
          propEq(SECOND_SET_VALUES[category.key || ''], 'value'),
          radioButtonsParam?.values ?? []
        )?.label;
        return label ? getTranslation(label, locale) : category.key;
      }
    )
    .otherwise(always(getTranslation(category.name, locale)));
