import {openDialog} from 'platform/components';

import {Helmet} from 'react-helmet-async';

import i18n from '@dms/i18n';
import {importRoutes} from '@dms/routes';
import {Main} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {ImportDialog} from './components/ImportDialog';

export function ImportsOverview() {
  const navigate = useNavigate();

  const openImportDialog = () => {
    openDialog(<ImportDialog dialogId="import-dialog" />, {
      title: i18n.t('entity.import.actions.createImportTask'),
      id: 'import-dialog',
    });
  };

  const handleActionCallback: ActionCallback = (action) => {
    const id = Array.isArray(action.rowId) ? action.rowId[0] : action.rowId;

    navigate(composePath(importRoutes.detail, {params: {id}}));
  };

  return (
    <>
      <Helmet title={i18n.t('module.import.title')} />
      <Main
        heading={i18n.t('entity.import.heading.title')}
        isFullHeight
        actions={[
          {
            type: 'button',
            title: i18n.t('entity.import.actions.createImportTask'),
            onClick: openImportDialog,
          },
        ]}
      >
        <DataGrid gridCode="import" actionCallback={handleActionCallback} />
      </Main>
    </>
  );
}
