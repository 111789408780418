import {MenuBadge} from 'platform/components';

import {useGetInterestCountQuery, useGetBusinessCaseCountQuery} from '@dms/api/businessCase';

import {Nullish} from 'shared';

interface useRecordCountProps {
  customerId: string | Nullish;
  vehicleId: string | Nullish;
}

export function useRecordCount(props: useRecordCountProps) {
  const {data: businessCaseCount} = useGetBusinessCaseCountQuery({
    customerId: props.customerId,
    vehicleId: props.vehicleId,
  });

  const {data: interestCount} = useGetInterestCountQuery({
    customerId: props.customerId,
    vehicleId: props.vehicleId,
  });

  const toMenuBadge = (count: number): MenuBadge => ({
    colorScheme: 'neutral',
    value: count,
  });

  return {
    toMenuBadge,
    businessCaseCount,
    interestCount,
  };
}
