import {Alert, Button, ButtonGroup, Dialog, Label, Separator} from 'platform/components';
import {Box, HStack, Show, Space} from 'platform/foundation';
import {formatPhoneNumber} from 'platform/locale';

import {ReactElement, use, useState} from 'react';

import {head} from 'ramda';

import {AddressRequestBodyV2, PersonRequestBody, PersonResponseBodyV2} from '@dms/api/customer';
import i18n from '@dms/i18n';
import {getNaturalPersonFullName} from '@dms/shared';
import {FormContext, useFormRenderer, FieldName, PossibleObject} from '@dms/teas';

import {EMPTY_PLACEHOLDER, Nullish, TestIdProps} from 'shared';

import {PersonForm} from './PersonForm';
import {PersonInfo} from './PersonInfo';

type PersonSelectProps<T extends PossibleObject> = {
  name: FieldName<T, undefined>;
  label: string | Nullish;
  person?: PersonResponseBodyV2[];
  initialValue?: PersonResponseBodyV2 | null;
  inlineEdit?: boolean;
  showPersonInfo?: boolean;
  showPersonalIdentifier?: boolean;
  showRole?: boolean;
  showPhoneNumbers?: boolean;
  showEmails?: boolean;
  showIdentityCards?: boolean;
  showAddress?: boolean;
  required?: boolean;
  showRemove?: boolean;
  handleRemove?: () => void;
  handlePersonSubmit: (
    personData: PersonRequestBody,
    personId: string | null
  ) => Promise<PersonResponseBodyV2 | null>;
  handleAddressSubmit?: (
    addressData: AddressRequestBodyV2,
    addressId?: string | null
  ) => Promise<string | null>;
  handleEdit?: (personId: string | null) => void;
  isDisabled?: boolean;
} & TestIdProps;

export const PersonSelect = <T extends PossibleObject>({
  label,
  person,
  required,
  showPersonInfo,
  showRole,
  showPhoneNumbers,
  showEmails,
  showIdentityCards = false,
  showAddress = false,
  showPersonalIdentifier = true,
  inlineEdit,
  showRemove,
  initialValue,
  handleRemove,
  handlePersonSubmit,
  handleAddressSubmit,
  handleEdit,
  isDisabled,
  ...props
}: PersonSelectProps<T>): ReactElement => {
  const {formId} = use(FormContext);

  // eslint-disable-next-line no-restricted-syntax
  const _name = props.name as unknown as string | string[];
  const name = typeof _name === 'string' ? _name : _name.join('.');

  const testId = props['data-testid'] ?? [formId, name.replace('.', '-')].join('-');

  const [personDialog, setPersonDialog] = useState<{open: boolean; id: string | null}>({
    open: false,
    id: null,
  });

  const closePersonDialog = () => setPersonDialog({open: false, id: null});

  const {form, Field, Subscribe} = useFormRenderer();

  const handleSubmitPerson = async (personData: PersonRequestBody) => {
    try {
      const person = await handlePersonSubmit(personData, personDialog.id);
      form.change(name, person?.id);
      closePersonDialog();
    } catch (e: any) {
      return e.validationErrors;
    }
  };

  return (
    <>
      {typeof initialValue !== 'undefined' &&
        !person?.find((person) => person.id === initialValue?.id) && (
          <Box paddingBottom={3}>
            <Alert
              data-testid="alert-customer.contactPersonSoftDeleted"
              variant="warning"
              title={i18n.t('general.notifications.contactPersonSoftDeleted')}
            />
          </Box>
        )}
      {!personDialog.open || !inlineEdit ? (
        <HStack spacing={3}>
          <Box flexGrow={1}>
            <Field<string, PersonResponseBodyV2>
              name={name}
              as="select"
              data-testid={testId}
              required={required}
              options={person}
              getOptionLabel={(person) => getPersonInformation(person) ?? EMPTY_PLACEHOLDER}
              getOptionValue={(person) => person.id}
              placeholder={getPersonInformation(initialValue)}
              label={label}
              selectProps={{
                creatableOption: true,
                handleCreate: () =>
                  setPersonDialog({
                    open: true,
                    id: null,
                  }),
              }}
              disabled={isDisabled}
            />
          </Box>
          <Subscribe
            name={name}
            component={({input: {value}}) => (
              <div>
                <Label>&nbsp;</Label>
                {value && person?.find((p) => p.id === value) ? (
                  <ButtonGroup data-testid={testId}>
                    <Button
                      variant="ghostLink"
                      leftIcon="image/edit"
                      onClick={() =>
                        handleEdit
                          ? handleEdit(value)
                          : setPersonDialog({
                              open: true,
                              id: value || null,
                            })
                      }
                      isDisabled={isDisabled}
                      data-testid={`${testId}-edit`}
                      title={i18n.t('general.actions.edit')}
                    />
                    <Show when={showRemove}>
                      <Button
                        variant="dangerGhost"
                        leftIcon="action/delete"
                        onClick={handleRemove}
                        data-testid={`${testId}-remove`}
                        title={i18n.t('general.actions.remove')}
                      />
                    </Show>
                  </ButtonGroup>
                ) : (
                  <Show when={showRemove}>
                    <Button
                      variant="dangerGhost"
                      leftIcon="action/delete"
                      onClick={handleRemove}
                      data-testid={`${testId}-remove`}
                      title={i18n.t('general.actions.remove')}
                    />
                  </Show>
                )}
              </div>
            )}
          />
          <Dialog
            data-testid={`${testId}-form`}
            isOpen={personDialog.open && !inlineEdit}
            onClose={closePersonDialog}
            title={i18n.t('general.labels.person')}
            scrollBehavior="outside"
          >
            <PersonForm
              data-testid={`${testId}-form`}
              showAddress={showAddress}
              showIdentityCards={showIdentityCards}
              showEmails={showEmails}
              showPhoneNumbers={showPhoneNumbers}
              showPersonalIdentifier={showPersonalIdentifier}
              showRole={showRole}
              initialValues={
                initialValue || person?.find((person) => person.id === personDialog.id)
              }
              onSubmit={handleSubmitPerson}
              handleAddressSubmit={handleAddressSubmit}
              WrapperComponent={({children, handleSubmit}) => (
                <>
                  {children}
                  <Space vertical={4} />
                  <ButtonGroup align="right" data-testid={`${testId}-form`}>
                    <Button
                      data-testid={`${testId}-form-discard`}
                      variant="secondary"
                      onClick={closePersonDialog}
                      title={i18n.t('general.actions.discard')}
                    />
                    <Button
                      data-testid={`${testId}-form-save`}
                      variant="primary"
                      onClick={handleSubmit}
                      title={i18n.t('general.actions.save')}
                    />
                  </ButtonGroup>
                </>
              )}
            />
          </Dialog>
        </HStack>
      ) : null}
      <Subscribe
        name={name}
        component={({input: {value}}) =>
          !personDialog.open && value && showPersonInfo ? (
            <PersonInfo
              data-testid={`${testId}-personInfo`}
              person={initialValue || person?.find((p) => p.id === value)}
              showEmails={showEmails}
              showPhoneNumbers={showPhoneNumbers}
              showPersonalIdentifier={showPersonalIdentifier}
              showRole={showRole}
            />
          ) : null
        }
      />
      {personDialog.open && inlineEdit ? (
        <PersonForm
          data-testid={`${testId}-form`}
          showAddress={false}
          showIdentityCards={false}
          showEmails
          showPhoneNumbers
          showPersonalIdentifier={showPersonalIdentifier}
          showRole
          initialValues={person?.find((person) => person.id === personDialog.id)}
          onSubmit={handleSubmitPerson}
          handleAddressSubmit={handleAddressSubmit}
          WrapperComponent={({children, handleSubmit}) => (
            <>
              {children}
              <Separator />
              <ButtonGroup align="right" data-testid={`${testId}-form`}>
                <Button
                  data-testid={`${testId}-form-discard`}
                  variant="secondary"
                  onClick={closePersonDialog}
                  title={i18n.t('general.actions.discard')}
                />
                <Button
                  data-testid={`${testId}-form-save`}
                  variant="primary"
                  onClick={handleSubmit}
                  title={i18n.t('general.actions.save')}
                />
              </ButtonGroup>
            </>
          )}
        />
      ) : null}
    </>
  );
};

const getPersonInformation = (person: PersonResponseBodyV2 | Nullish) => {
  if (!person) {
    return;
  }
  if (person?.firstName || person?.lastName) {
    return getNaturalPersonFullName(person) ?? EMPTY_PLACEHOLDER;
  } else if (person?.phoneNumbers?.length) {
    const phoneNumber = head(person.phoneNumbers);
    return formatPhoneNumber(`${phoneNumber?.prefix}${phoneNumber?.number}`) ?? EMPTY_PLACEHOLDER;
  } else if (person?.emails?.length) {
    return head(person.emails)?.email ?? EMPTY_PLACEHOLDER;
  }

  return EMPTY_PLACEHOLDER;
};
