import {CustomFilterProps, useGridFilter} from 'ag-grid-react';
import {Chips, NumberInput, Separator, Slider} from 'platform/components';
import {HStack, VStack} from 'platform/foundation';

import {useCallback, useState} from 'react';

import {isNil} from 'ramda';
import {isArray, isString} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {useFilterOnChipsChange} from '../../hooks/useFilterOnChipsChange';
import {useSliderFilter} from '../../hooks/useSliderFilter';
import {useSliderTicks} from '../../hooks/useSliderTicks';
import {QuickFilters} from '../../types/Api';

export type NumberSliderRangeProps = {
  min: number;
  max: number;
  minOpen: boolean;
  maxOpen: boolean;
  unit: string | null;
  stepSize: number | null;
  steps: number[];
  markerSize: number | null;
  markers: number[];
  decimalsAllowed: boolean;
  inputsVisible: boolean;
  isDisabled: boolean;
} & CustomFilterProps &
  QuickFilters &
  TestIdProps;

export function NumberSliderRangeFilter({
  model,
  onModelChange,
  min,
  max,
  unit,
  stepSize,
  steps,
  markerSize,
  markers,
  isDisabled,
  quickFilters,
  ...props
}: NumberSliderRangeProps) {
  const ticks = useSliderTicks(min, max, markerSize, markers);
  const valueFormatter = useCallback(
    (value: number) => (unit ? `${value} [${unit}]` : `${value}`),
    [unit]
  );

  const [localSliderValue, setLocalSliderValue] = useState<[number | null, number | null] | null>([
    min,
    max,
  ]);

  const [filterValue, setFilterValue] = useSliderFilter({model, onModelChange, min, max});

  const {onChipsChange} = useFilterOnChipsChange({
    setFilterValue: (newValue) => {
      setFilterValue(newValue);
      setLocalSliderValue([min, max]);
    },
    defaultValue: null,
  });

  useGridFilter({
    doesFilterPass: () => true,
    afterGuiAttached: () => setLocalSliderValue(filterValue),
  });

  const onSliderValueChange = (value: [number | null, number | null] | null) => {
    setLocalSliderValue(value);
    setFilterValue(value);
  };

  const onFromInputChange = (value: number | null) => {
    const valueToSet = value === null || isNaN(value) ? null : value;

    if (isNil(filterValue)) {
      onSliderValueChange([valueToSet, null]);
    }

    if (isArray(filterValue)) {
      const isFromNilOrLowestPossible = isNil(valueToSet) || valueToSet === min;
      const isToNilOrLowestPossible = isNil(filterValue?.[1]) || filterValue?.[1] === max;

      if (isFromNilOrLowestPossible && isToNilOrLowestPossible) {
        onSliderValueChange(null);
      } else {
        onSliderValueChange([valueToSet, filterValue?.[1] === 0 ? null : filterValue?.[1]]);
      }
    }

    if (isString(filterValue)) {
      onSliderValueChange([valueToSet, max]);
    }
  };

  const onToInputChange = (value: number | null) => {
    const valueToSet = value === null || isNaN(value) ? null : value;

    if (isNil(filterValue)) {
      onSliderValueChange([null, valueToSet]);
    }

    if (isArray(filterValue)) {
      const isFromNilOrLowestPossible = isNil(filterValue?.[0]) || filterValue?.[0] === min;
      const isToNilOrLowestPossible = isNil(valueToSet) || valueToSet === max;

      if (isFromNilOrLowestPossible && isToNilOrLowestPossible) {
        onSliderValueChange(null);
      } else {
        onSliderValueChange([filterValue?.[0] === 0 ? null : filterValue?.[0], valueToSet]);
      }
    }

    if (isString(filterValue)) {
      onSliderValueChange([min, valueToSet]);
    }
  };

  const isQuickFilterValue = isString(filterValue) ? [filterValue] : null;

  const isSliderValue = isArray(localSliderValue)
    ? [localSliderValue?.[0] ?? min, localSliderValue?.[1] ?? max]
    : [min, max];

  const isFromValue = isArray(filterValue) ? filterValue?.[0] : null;
  const isToValue = isArray(filterValue) ? filterValue?.[1] : null;

  return (
    <VStack spacing={5}>
      {quickFilters && quickFilters.length > 0 && (
        <>
          <HStack>
            <Chips
              isDisabled={isDisabled}
              value={isQuickFilterValue}
              options={quickFilters}
              onChange={onChipsChange}
              isMultiple={false}
              data-testid={suffixTestId('number-range-filter-quickFilters', props)}
              isDeselectable
            />
          </HStack>
          <Separator />
        </>
      )}
      <Slider
        isDisabled={isDisabled}
        isRange
        min={min}
        max={max}
        step={stepSize ?? steps ?? 1}
        value={isSliderValue as [number, number]}
        allowMoveStart
        onChange={onSliderValueChange}
        formatValue={valueFormatter}
        ticks={ticks}
        onlyEdges={true}
        displayValue="auto"
        data-testid={suffixTestId('number-range-filter-slider', props)}
      />
      <HStack spacing={2}>
        <NumberInput
          value={isFromValue}
          onChange={onFromInputChange}
          isStepperVisible
          minStepperValue={min}
          maxStepperValue={max}
          isDisabled={isDisabled}
          data-testid={suffixTestId('number-range-filter-from', props)}
        />
        <NumberInput
          value={isToValue}
          onChange={onToInputChange}
          isStepperVisible
          minStepperValue={min}
          maxStepperValue={max}
          isDisabled={isDisabled}
          data-testid={suffixTestId('number-range-filter-to', props)}
        />
      </HStack>
    </VStack>
  );
}
