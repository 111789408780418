import {ButtonProps, closeCurrentDialog, openDialog, showNotification} from 'platform/components';
import {Box, HStack} from 'platform/foundation';

import {equals, isNil, not} from 'ramda';
import {isArray, isNilOrEmpty, isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {GetWarehousesResponse} from '@dms/api/metadaWarehouse';
import {
  BaseDirectSaleMaterialItem,
  useBulkDeleteDirectSaleItemsMutation,
} from '@dms/api/metadaWarehouseDirectSale';
import {
  GetDirectSaleMaterialItemResponse,
  useLazyGetDirectSaleMaterialItemQuery,
} from '@dms/api/metadaWarehouseDirectSaleItemSparePart';
import {
  useDeleteDirectSaleMaterialBasketItemsMutation,
  useGetDirectSaleMaterialBasketItemsQuery,
  usePatchDirectSaleMaterialBasketItemQuantityMutation,
  usePostDirectSaleMaterialBasketCheckoutMutation,
  usePostDirectSaleMaterialDispatchMutation,
  usePostDirectSaleMaterialNonBindingMutation,
  usePostDirectSaleMaterialRequestMutation,
} from '@dms/api/metadaWarehouseDirectSaleSparePartsBasket';
import i18n from '@dms/i18n';
import {
  AfterSalesMaterialBasket,
  EitherQuantityOrError,
  FullScreenModal,
  handleApiError,
  logError,
  MaterialBasketItemEditingDetails,
  useBasketMechanic,
  useInvalidBasketItemsIds,
} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {RequestTabMaterialItemEdit} from './RequestTabMaterialItemEdit';
import {RequestTabMaterialList} from './RequestTabMaterialList';

interface RequestTabMaterialModalProps extends RequiredTestIdProps {
  directSaleId: string;
  authorizationProfileId?: string;
  onDiscard: () => void;
  availableWarehouses: GetWarehousesResponse;
}

export function RequestTabMaterialModal(props: RequestTabMaterialModalProps) {
  const [getDirectSaleMaterialItem] = useLazyGetDirectSaleMaterialItemQuery();

  const {
    data: basket,
    isLoading: isBasketLoading,
    isError: hasBasketError,
  } = useGetDirectSaleMaterialBasketItemsQuery({directSaleId: props.directSaleId});

  const [checkoutMaterialBasket, {isLoading: isCheckingOutBasket}] =
    usePostDirectSaleMaterialBasketCheckoutMutation();

  const [emptyBasket, {isLoading: isDiscarding}] = useDeleteDirectSaleMaterialBasketItemsMutation();

  const [dispatchMaterial] = usePostDirectSaleMaterialDispatchMutation();

  const [requestMaterial] = usePostDirectSaleMaterialRequestMutation();

  const [nonBindingMaterial] = usePostDirectSaleMaterialNonBindingMutation();

  const [changeMaterialBasketItemQuantity] = usePatchDirectSaleMaterialBasketItemQuantityMutation();

  const [deleteMaterialItems, {isLoading: isDeletingBasketItems}] =
    useBulkDeleteDirectSaleItemsMutation();

  const basketItems = basket?.sparePartsBasketItem ?? [];
  const basketTotalPrice = basket?.sparePartsBasketTotalPrice;
  const basketMechanicId = basket?.assignMechanicId;

  const {setInvalidBasketItemId, invalidBasketItemsIds} = useInvalidBasketItemsIds(basketItems);

  const {mechanics, areMechanicsLoading, mechanicPayload, inputMechanicId, handleMechanicChange} =
    useBasketMechanic({
      authorizationProfileId: props.authorizationProfileId,
      assignedMechanicId: basketMechanicId,
    });

  const handleAdd = async () => {
    if (isNotNil(mechanicPayload)) {
      return await checkoutMaterialBasket({
        directSaleId: props.directSaleId,
        body: {
          assignMechanics: mechanicPayload,
        },
      })
        .unwrap()
        .then(() =>
          showNotification.success(i18n.t('entity.warehouse.notifications.materialAdded'))
        )
        .then(props.onDiscard)
        .catch(handleApiError);
    }

    await checkoutMaterialBasket({
      directSaleId: props.directSaleId,
    })
      .unwrap()
      .then(() => showNotification.success(i18n.t('entity.warehouse.notifications.materialAdded')))
      .then(props.onDiscard)
      .catch(handleApiError);
  };

  const handleDiscard = async () => {
    await emptyBasket({directSaleId: props.directSaleId})
      .unwrap()
      .then(props.onDiscard)
      .catch(handleApiError);
  };

  const handleWarehouseDispatchMaterial = async (warehouseArticleId: string) => {
    await dispatchMaterial({directSaleId: props.directSaleId, body: {warehouseArticleId}})
      .unwrap()
      .catch(handleApiError);
  };

  const handleWarehouseRequestMaterial = async (warehouseArticleId: string) => {
    await requestMaterial({directSaleId: props.directSaleId, body: {warehouseArticleId}})
      .unwrap()
      .catch(handleApiError);
  };

  const handleWarehouseNonBindingMaterial = async (warehouseArticleId: string) => {
    await nonBindingMaterial({directSaleId: props.directSaleId, body: {warehouseArticleId}})
      .unwrap()
      .catch(handleApiError);
  };

  const handleQuantityChange = async (itemId: string, quantity: EitherQuantityOrError) => {
    setInvalidBasketItemId(itemId, quantity);

    const basketItem = basketItems.find((item) => equals(item.id, itemId));
    const hasQuantityChanged = not(equals(basketItem?.quantity, quantity.newQuantity));

    if (quantity.hasError || not(hasQuantityChanged)) {
      return;
    }

    await changeMaterialBasketItemQuantity({
      directSaleId: props.directSaleId,
      itemId,
      body: {quantity: quantity.newQuantity!},
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleEdit = async (editingDetails: MaterialBasketItemEditingDetails) => {
    if (isNil(editingDetails?.itemId)) {
      return logError("Missing itemId, can't open editing dialog");
    }

    const materialItem = (await getDirectSaleMaterialItem({
      itemId: editingDetails.itemId,
      directSaleId: props.directSaleId,
    })
      .unwrap()
      .catch(handleApiError)) as GetDirectSaleMaterialItemResponse;

    const itemName = materialItem ? `${materialItem?.name} (${materialItem?.number})` : '';

    openDialog(
      <RequestTabMaterialItemEdit
        directSaleId={props.directSaleId}
        item={materialItem}
        isEditingDisabled={false}
        authorizationProfileId={props.authorizationProfileId}
        onAfterSubmit={closeCurrentDialog}
        onClose={closeCurrentDialog}
        data-testid={suffixTestId('dialog.editMaterial', props)}
      />,
      {
        title: itemName,
      }
    );
  };

  const handleDelete = async (itemsIds: string | string[]) => {
    const ids = isArray(itemsIds) ? itemsIds : [itemsIds];

    const requestBody = {items: ids.map((id) => ({id}))};

    await deleteMaterialItems({
      directSaleId: props.directSaleId,
      body: requestBody,
    })
      .unwrap()
      .catch(handleApiError);
  };

  const actions: ButtonProps[] = [
    {
      title: i18n.t('general.actions.discard'),
      variant: 'secondary',
      onClick: handleDiscard,
      isLoading: isDiscarding,
      'data-testid': suffixTestId('headerActions.discard', props),
    },
    {
      title: i18n.t('general.actions.add'),
      variant: 'primary',
      onClick: handleAdd,
      isLoading: isCheckingOutBasket,
      isDisabled: isNilOrEmpty(basketItems) || isNotNilOrEmpty(invalidBasketItemsIds),
      'data-testid': suffixTestId('headerActions.add', props),
    },
  ];

  return (
    <FullScreenModal headline={i18n.t('general.actions.addMaterial')} actions={actions}>
      <Box padding={4} height="100%">
        <HStack spacing={4} height="100%">
          <Box flex={5}>
            <RequestTabMaterialList
              onWarehouseDispatch={handleWarehouseDispatchMaterial}
              onWarehouseRequest={handleWarehouseRequestMaterial}
              onWarehouseNonBinding={handleWarehouseNonBindingMaterial}
              availableWarehouses={props.availableWarehouses}
              data-testid={suffixTestId('itemList', props)}
            />
          </Box>
          <Box flex={1} minWidth={86}>
            <AfterSalesMaterialBasket<BaseDirectSaleMaterialItem>
              basket={{
                items: basketItems,
                totalPrice: basketTotalPrice,
                isLoading: isBasketLoading,
                hasError: hasBasketError,
                hasInvalidItems: isNotNilOrEmpty(invalidBasketItemsIds),
                isDeletingItems: isDeletingBasketItems,
              }}
              mechanic={{
                mechanics,
                areMechanicsLoading,
                selectedMechanicId: inputMechanicId,
                onMechanicChange: handleMechanicChange,
              }}
              onQuantityChange={handleQuantityChange}
              onEdit={handleEdit}
              onDelete={handleDelete}
              data-testid={suffixTestId('basket', props)}
            />
          </Box>
        </HStack>
      </Box>
    </FullScreenModal>
  );
}
