import {captureMessage} from '@sentry/browser';
import {
  CellStyleModule,
  ColumnApiModule,
  ColumnAutoSizeModule,
  CustomFilterModule,
  EventApiModule,
  GetRowIdFunc,
  IServerSideGetRowsParams,
  LocaleModule,
  ModuleRegistry,
  PaginationModule,
  PinnedRowModule,
  provideGlobalGridOptions,
  RenderApiModule,
  RowApiModule,
  RowAutoHeightModule,
  TextFilterModule,
  CellApiModule,
  ValidationModule,
} from 'ag-grid-community';
import {
  CellSelectionModule,
  ClipboardModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  MenuModule,
  MultiFilterModule,
  RichSelectModule,
  ServerSideRowModelApiModule,
  ServerSideRowModelModule,
  SetFilterModule,
  SideBarModule,
  StatusBarModule,
} from 'ag-grid-enterprise';
import {AgGridReact} from 'ag-grid-react';
import {diff} from 'deep-object-diff';
import {nanoid} from 'nanoid';
import {useTranslationContext} from 'platform/components';
import {useLocale} from 'platform/locale';
import styled, {css} from 'styled-components';

import {useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';

import {dissoc, isNil} from 'ramda';
import {
  isNilOrEmpty,
  isNotNil,
  isNotNilOrEmpty,
  isNumber,
  isPlainObj,
  isPositive,
} from 'ramda-adjunct';

import {noop, suffixTestId} from 'shared';

import {BulkActionsPanelWrapper} from './components/BulkActionsPanelWrapper';
import {DataGridAside} from './components/DataGridAside';
import {DataGridHeader} from './components/DataGridHeader';
import {DefaultLoadingCellRenderer} from './components/DefaultLoadingCellRenderer';
import {DetailRendererWrapper} from './components/DetailRendererWrapper';
import {EmptyStateRendererWrapper} from './components/EmptyStateRendererWrapper';
import {LoadingCellRendererWrapper} from './components/LoadingCellRendererWrapper';
import {PaginationWrapper} from './components/PaginationWrapper';
import {ToolPanelHeader} from './components/ToolPanelHeader';
import {sideBarOptions} from './constants/sideBarOptions';
import {SMART_SEARCH_KEY} from './constants/smartSearchKey';
import {useDataGridContext} from './context/useDataGridContext';
import {useDataGridFiltersModel} from './context/useDataGridFiltersModel';
import {useColumnDefs} from './hooks/useColumnDefs';
import {useContextMenu} from './hooks/useContextMenu';
import {useDefaultSettings} from './hooks/useDefaultSettings';
import {useGridApiEventListener} from './hooks/useGridApiEventListener';
import {useGridTheme} from './hooks/useGridTheme';
import {useHeaderHeight} from './hooks/useHeaderHeight';
import {useHttpCalls} from './hooks/useHttpCalls';
import {useRefreshServerSideStore} from './hooks/useRefreshServerSideStore';
import {useRowClickHandler} from './hooks/useRowClickHandler';
import {useSummaryRow} from './hooks/useSummaryRow';
import {GridTheme} from './styles/gridTheme';
import {
  AgGridEvent,
  ColumnMovedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  PaginationChangedEvent,
  SelectionChangedEvent,
} from './types/AgGridTypes';
import {AgGridWrapperProps} from './types/AgGridWrapperProps';
import {ActivePreset, ColumnsSetting, OrderByRequestBody, VirtualPreset} from './types/Api';
import {Filters} from './types/Filters';
import {ToolpanelType} from './types/ToolpanelType';
import {addTestIdsToAgGridElements} from './utils/addTestIdsToAgGridElements';
import {getColDefsVisibleInToolPanel} from './utils/getColDefsVisibleInToolPanel';
import {getColumnState} from './utils/getColumnState';
import {getGroupedColDefs} from './utils/getGroupedColDefs';
import {getMainMenuItems} from './utils/getMainMenuItems';
import {getPresetStates} from './utils/getPresetStates';
import {getRowSelection} from './utils/getRowSelection';
import {getStatusBar} from './utils/getStatusBar';
import {isPresetColumnsSettingsInvalid} from './utils/isPresetColumnsSettingsInvalid';
import {isPresetCustom} from './utils/isPresetCustom';
import {isTestEnvironment} from './utils/isTestEnvironment';
import {LineHeightMappingToPx} from './utils/lineHeightToPx';
import {objectHasEveryEqualPropOfAnother} from './utils/objectHasEveryEqualPropOfAnother';
import {transformActions} from './utils/transformActions';
import {translationMap} from './utils/translationMap';
import {getSearchParamsByGridCode} from './utils/url/getSearchParamsByGridCode';
import {setSearchParamsByGridCode} from './utils/url/setSearchParamsByGridCode';

provideGlobalGridOptions({theme: 'legacy'});
ModuleRegistry.registerModules([
  ServerSideRowModelModule,
  ServerSideRowModelApiModule,
  ClipboardModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  MenuModule,
  MultiFilterModule,
  CellSelectionModule,
  RichSelectModule,
  SetFilterModule,
  SideBarModule,
  StatusBarModule,
  ColumnAutoSizeModule,
  PaginationModule,
  RenderApiModule,
  RowApiModule,
  EventApiModule,
  ColumnApiModule,
  ValidationModule,
  CustomFilterModule,
  CellStyleModule,
  RowAutoHeightModule,
  PinnedRowModule,
  LocaleModule,
  TextFilterModule,
  CellApiModule,
]);

type Comparison = {
  filters: Filters;
  sortBy: OrderByRequestBody[];
};

const Wrapper = styled.div<{customStyles?: any}>`
  /* Inject custom styles */
  ${(props) =>
    props.customStyles &&
    css`
      ${props.customStyles}
    `};
`;

export const AgGridWrapper = (props: AgGridWrapperProps) => {
  const {
    loadingCellRenderer = DefaultLoadingCellRenderer,
    actionCallback,
    autoGroupColumnDef,
    onRowSelectionChange = noop,
    queryModifier,
    definition,
    setVirtualPreset,
  } = props;
  const http = useHttpCalls();
  const translate = useTranslationContext();
  const locale = useLocale();
  const translations = translationMap(translate);
  const internalId = useMemo(() => `dataGrid-${nanoid()}`, []);

  const [gridApi, setGridApi] = useState<GridApi>();
  const [count, setCount] = useState<number>();

  const {activePreset} = useDataGridContext();
  const {onFiltersChange} = useDataGridFiltersModel();

  const [settings, setSettings] = useDefaultSettings(gridApi);

  const getContextMenuItems = useContextMenu(gridApi, props, definition, props.gridCode);
  const lastUsedComparison = useRef<Comparison>(null);
  const lastUsedPreset = useRef<ActivePreset>(activePreset);
  const lastGroupedColDefs = useRef<ReturnType<typeof getGroupedColDefs>>([]);
  const refreshData = useRefreshServerSideStore(props.gridCode, gridApi);

  // Some functionality cannot be enabled when it was initially disabled
  // So we enable them on first render and then pass correct value (disabled/enabled)
  const isFirstRender = useRef(true);
  // eslint-disable-next-line eag/no-effect-without-cleanup
  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  useImperativeHandle(props.ref, () => ({
    refreshData,
  }));

  const onRowClicked = useRowClickHandler(
    gridApi,
    settings,
    actionCallback,
    transformActions(props.definition.actions),
    props.gridCode,
    definition
  );
  const searchParams2 = getSearchParamsByGridCode(props.gridCode);
  const initialPage = Math.ceil((Number(searchParams2?.rowIndex) + 1) / settings.itemsPerPage);

  const {gridTheme, footerTheme, wrapperTheme} = useGridTheme(settings, definition);
  const {colDefs, defaultColDef} = useColumnDefs(props, settings, props.columnConfig, definition);

  useHeaderHeight(settings, gridApi);
  useSummaryRow(gridApi, definition, props.gridCode);

  /*
   * Grid calls getRows when it requires more rows as specified in the params.
   * Params object contains callbacks for responding to the request.
   * */
  const handleGetRows = useCallback(
    (rowsParams: IServerSideGetRowsParams) => {
      let searchParams = getSearchParamsByGridCode(props.gridCode);
      if (isNilOrEmpty(searchParams)) {
        setSearchParamsByGridCode(props.gridCode, activePreset.dataQueryId);
        searchParams = getSearchParamsByGridCode(props.gridCode);
      }
      const {success, request, api} = rowsParams;

      const sortBy: OrderByRequestBody[] = request.sortModel.map((sort) => ({
        columnKey: sort.colId,
        order: sort.sort.toUpperCase() as OrderByRequestBody['order'],
      }));

      const filterModel = api.getFilterModel();
      const staticFilters = queryModifier ? queryModifier({}) : {};
      const modifiedFilters = queryModifier ? queryModifier(filterModel) : filterModel;

      const limit = settings.itemsPerPage;
      const offset = request.startRow ?? 0;

      const filters = {
        limit,
        offset,
        filters: dissoc(SMART_SEARCH_KEY, modifiedFilters),
        order: sortBy,
        smartSearch: modifiedFilters[SMART_SEARCH_KEY] ?? null,
      };

      const getDataByQueryId = (queryId: string) => {
        setSearchParamsByGridCode(props.gridCode, queryId, searchParams2?.rowIndex);

        http.getDataByQuery(queryId, offset, limit, !!filters.smartSearch).then((dataResponse) => {
          http.getCountByQuery(queryId, !!filters.smartSearch).then((rowCount) => {
            if (rowCount === 0) {
              api?.showNoRowsOverlay();
              api.dispatchEvent({type: 'storeRefreshed'});
              success({
                rowData: [],
                rowCount: 0,
              });
              return;
            }

            if (dataResponse && isNumber(rowCount)) {
              const rowData = dataResponse.map((response) => ({
                ...response.cells,
                actions: response.actions,
                id: response.id,
                rowNumber: response.rowNumber,
              }));

              try {
                setCount(rowCount);
                success({
                  rowData,
                  rowCount,
                });
              } catch (error) {
                if (isPlainObj(error) && 'message' in error) {
                  // eslint-disable-next-line no-console
                  console.error(error?.message);
                }
              }

              api.hideOverlay();
              api.dispatchEvent({type: 'storeRefreshed'});
            }
          });
        });
      };

      const comparison = {
        smartSearch: filters.smartSearch,
        filters,
        sortBy,
      };

      const didPresetChange = diff(lastUsedPreset.current, activePreset);
      const isFilterModified = isNilOrEmpty(lastUsedComparison.current)
        ? null
        : diff(lastUsedComparison.current ?? {}, comparison);

      const isAllStaticFilterPresent = objectHasEveryEqualPropOfAnother(
        staticFilters,
        props.dataQuery.filters
      );

      // when preset is changed, get data by active preset query id
      if (isNotNilOrEmpty(didPresetChange)) {
        getDataByQueryId(activePreset.dataQueryId);
        lastUsedPreset.current = activePreset;
        lastUsedComparison.current = comparison as Comparison;
        return;
      }

      // Use same query id only if filters are not modified and all static filters are present, otherwise create new query id
      if (isNilOrEmpty(isFilterModified) && isAllStaticFilterPresent) {
        getDataByQueryId(searchParams.queryId);
        lastUsedComparison.current = comparison as Comparison;
        return;
      }

      // New data query id
      http
        .createDataQuery({
          filters: filters.filters ?? {},
          order: filters.order ?? {},
          smartSearch: filters.smartSearch,
        })
        .then((queryIdResponseBody) => {
          if (!queryIdResponseBody || api.isDestroyed()) {
            return;
          }

          const newDataQueryId = queryIdResponseBody.dataQueryId;

          if (!isPresetCustom(activePreset)) {
            const modifiedPreset: VirtualPreset = {
              columnsSettings: filterTechnicalArray(getPresetStates(api)),
              gridSettings: settings,
              dataQueryId: newDataQueryId,
            };

            if (isPresetColumnsSettingsInvalid(modifiedPreset)) {
              /**
               * Hello traveler, if you arrive to this #Sentry log, this is how you debug it.
               * 0) remove api.isDestroyed() check
               * 1) enable CPU throttling in performance tab.
               * 2) open any grid
               * 3) prepare to be quick
               * 4) do sorting or filtering AND quickly jump to another tab or page
               * 5) message will pop out
               */
              captureMessage('updatePreset', (scope) =>
                scope.setLevel('error').setExtras({
                  userDescription: 'update virtual preset from agGridWrapper',
                  state: modifiedPreset,
                })
              );
              return;
            }

            http.updateVirtualPreset(modifiedPreset);
          }

          getDataByQueryId(newDataQueryId);

          lastUsedComparison.current = comparison as Comparison;
        });
    },
    [
      activePreset,
      http,
      props.dataQuery.filters,
      props.gridCode,
      queryModifier,
      searchParams2?.rowIndex,
      settings,
    ]
  );

  const disableActionColumnMove = useCallback((params: ColumnMovedEvent) => {
    if (params.column?.isPinnedRight()) {
      const columnCount = params.api.getAllGridColumns()?.filter((c) => c.isVisible()).length;

      // WTF, why 2?
      const maxIndex = isNotNil(columnCount) ? columnCount - 2 : 0;
      if (isNotNil(params.toIndex)) {
        if (params.toIndex > maxIndex) {
          params.api.moveColumnByIndex(params.toIndex, maxIndex);
        }
      }
    }
  }, []);

  const handleFiltersChanged = (event: AgGridEvent) => {
    const filterModel = event.api?.getFilterModel() || {};
    props.onFilterChanged?.(filterModel);
    onFiltersChange(filterModel);
  };

  const {addListeners} = useGridApiEventListener(gridApi, ['filterChanged'], handleFiltersChanged);

  useEffect(() => {
    if (isNil(gridApi) || isNilOrEmpty(colDefs)) {
      return;
    }

    const groupedDefs = getGroupedColDefs(colDefs);

    gridApi?.getToolPanelInstance('filters')?.setFilterLayout(groupedDefs);
    gridApi?.getToolPanelInstance('columns')?.setColumnLayout(groupedDefs);
    lastGroupedColDefs.current = groupedDefs;
  }, [gridApi, colDefs]);

  addListeners();

  /*
   * The grid has initialised and is ready for most api calls, but may not be fully rendered yet
   * */
  const onGridReady = ({api}: GridReadyEvent) => {
    api.setFilterModel({...props.dataQuery.filters, smartSearch: props.dataQuery.smartSearch});
    if (props.onFilterChanged) {
      props.onFilterChanged(props.dataQuery.filters || {});
    }
    setGridApi(api);
    addListeners();
  };

  /*
   * Row selection is changed. Use the grid API getSelectedNodes() to get the new list of selected nodes.
   * */
  const onSelectionChanged = useCallback(
    ({api}: SelectionChangedEvent) => {
      const selectedNodes = api.getSelectedNodes();
      onRowSelectionChange(selectedNodes.map((node) => node?.data));
    },
    [onRowSelectionChange]
  );

  const getRowId = useCallback<GetRowIdFunc>((row) => row?.data?.id, []);

  /*
   * Specifies the params to be used by the Detail Cell Renderer.
   * Can also be a function that provides the params to enable dynamic definitions of the params
   * */
  const detailCellRendererParams = useMemo<Record<string, unknown>>(
    () => ({
      gridProps: props,
    }),
    [props]
  );
  const disableKeyboardCallback = useMemo(() => {
    if (!settings.keyboardNavigationEnabled) {
      return () => null;
    }
    return undefined;
  }, [settings.keyboardNavigationEnabled]);

  const gridOptions: GridOptions = {
    rowSelection: getRowSelection(definition),
    statusBar: getStatusBar(definition),
    embedFullWidthRows: true,
    suppressRowTransform: true,
    chartThemes: ['alpine'],
    rowHeight: LineHeightMappingToPx[settings.rowHeight],
    rowModelType: 'serverSide',
    pagination: true,
    paginationPageSize: settings.itemsPerPage ?? 25,
    cacheBlockSize: settings.itemsPerPage ?? 25,
    serverSideDatasource: {
      getRows: handleGetRows,
    },
    onSelectionChanged,
    defaultColDef,
    getContextMenuItems,
    getRowId,
    columnMenu: 'legacy',
    detailCellRenderer: DetailRendererWrapper,
    detailCellRendererParams,
    noRowsOverlayComponent: EmptyStateRendererWrapper,
    // debug: true,
    noRowsOverlayComponentParams: {
      gridProps: props,
      settings,
    },
    loadingCellRenderer: LoadingCellRendererWrapper,
    loadingCellRendererParams: {
      gridProps: props,
      component: loadingCellRenderer,
    },
    context: {
      gridProps: props,
      settings,
      behavior: definition.behavior,
      getContextMenuItems,
    },
    suppressPaginationPanel: true,
    /*
     * For customising the main 'column header' menu
     * */
    getMainMenuItems: getMainMenuItems({
      gridApi,
      translationMap: translations,
      onResetColumns: () => {
        http.deleteVirtualPreset().then(async (response) => {
          if (!response) {
            return;
          }

          const presetFilters = await http.getDataQuery(response.dataQueryId);
          if (presetFilters) {
            gridApi?.applyColumnState({
              state: getColumnState(response, presetFilters, gridApi.getColumnState()),
              // Whether column order should be applied
              applyOrder: true,
              // State to apply to columns where state is missing for those columns
              defaultState: {
                sort: null,
                pinned: null,
              },
            });
          }

          setSearchParamsByGridCode(props.gridCode, response.dataQueryId);
          setVirtualPreset(response);
        });
      },
      definition,
    }),
    suppressMultiSort: definition.behavior.columnSortMode !== 'MULTI_SORT',
    navigateToNextCell: disableKeyboardCallback,
    tabToNextCell: () => settings.keyboardNavigationEnabled,
    navigateToNextHeader: disableKeyboardCallback,
    sideBar: sideBarOptions,
    onRowClicked,
    columnDefs: colDefs,
    autoGroupColumnDef,
    allowDragFromColumnsToolPanel: true,
    localeText: {
      ...translations,
      thousandSeparator: locale.localeConfig.number.thousandsSeparator,
      decimalSeparator: locale.localeConfig.number.decimalSeparator,
    },
    onColumnMoved: disableActionColumnMove,
    onPaginationChanged: (params: PaginationChangedEvent) => {
      if (params.newPage) {
        const page = params.api.paginationGetCurrentPage() + 1;
        (props.onPageChangeCallback ?? noop)(page);
      }
    },
    suppressContextMenu: !definition.behavior.contextMenuEnabled,
    enableRangeSelection: isFirstRender ? true : definition.behavior.rangeSelectAllowed,
    cellSelection: {
      suppressMultiRanges: isFirstRender ? false : !definition.behavior.rangeSelectAllowed,
    },
    detailRowAutoHeight: true,
    domLayout: props.autoHeight ? 'autoHeight' : 'normal',
    suppressColumnVirtualisation: true,
    ...(isTestEnvironment
      ? {
          onToolPanelVisibleChanged: () => {
            if (!lastGroupedColDefs.current) {
              return;
            }

            addTestIdsToAgGridElements(getColDefsVisibleInToolPanel(lastGroupedColDefs.current));
          },
        }
      : {}),
  };

  return (
    <Wrapper
      id={internalId}
      css={wrapperTheme}
      data-testid={suffixTestId('datagridWrapper', props)}
    >
      {gridApi && (
        <>
          <ToolPanelHeader
            gridApi={gridApi}
            toolPanelType={ToolpanelType.column}
            mountElement={document.querySelector(`#${internalId} .ag-column-panel`)}
          />
          <ToolPanelHeader
            gridApi={gridApi}
            toolPanelType={ToolpanelType.filter}
            mountElement={document.querySelector(`#${internalId} .ag-filter-toolpanel`)}
          />
          <ToolPanelHeader
            gridApi={gridApi}
            toolPanelType={ToolpanelType.settings}
            mountElement={document.querySelector(`#${internalId} .ag-custom-settings-toolpanel`)}
          />
        </>
      )}

      {gridApi && (
        <DataGridHeader
          dataGridSettings={settings}
          dataGridProps={props}
          gridApi={gridApi}
          data-testid={props['data-testid']}
          definition={definition}
          dataQuery={props.dataQuery}
          setSettings={setSettings}
          refreshData={refreshData}
        />
      )}

      {gridApi && settings && definition.behavior.actionColumnEnabled && (
        <BulkActionsPanelWrapper
          settings={settings}
          gridProps={props}
          api={gridApi}
          definition={definition}
        />
      )}
      <GridTheme data-testid={suffixTestId('datagridContentWrapper', props)}>
        <Wrapper customStyles={gridTheme}>
          <AgGridReact
            {...gridOptions}
            onGridReady={onGridReady}
            data-testid={suffixTestId('datagridAgGridComponent', props)}
            containerStyle={{
              // HACK: this is only way AGGRID will respect parent element width
              flexGrow: '999999999',
              width: '0px',
            }}
          />
          <DataGridAside
            dataGridSettings={settings}
            dataGridProps={props}
            gridApi={gridApi}
            internalGridId={internalId}
            data-testid={suffixTestId('datagridAside', props)}
            definition={definition}
            setSettings={setSettings}
          />
        </Wrapper>
      </GridTheme>

      {/** Footer */}
      {isNotNil(settings.itemsPerPage) &&
        definition.behavior.paginationEnabled &&
        gridApi &&
        isPositive(count) && (
          <PaginationWrapper
            initialPage={initialPage}
            data-testid={props['data-testid']}
            gridApi={gridApi}
            gridProps={props}
            footerTheme={footerTheme}
            count={Math.floor(count / settings.itemsPerPage) + 1}
          />
        )}
    </Wrapper>
  );
};

const filterTechnicalArray = (columnSettings: ColumnsSetting[]): ColumnsSetting[] =>
  columnSettings?.filter(
    (columnSetting) =>
      columnSetting.columnKey !== SMART_SEARCH_KEY && columnSetting.columnKey !== 'eag-col-actions'
  );
