import {
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {Heading, VStack} from 'platform/foundation';

import {usePostServiceOrderCustomDiscountsDeleteMutation} from '@dms/api/metadaWorkshopServiceOrderDiscount';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {TestIdProps, suffixTestId} from 'shared';

import {DISCOUNT_CHANGE_OPTIONS} from '../../../constants/discountChangeOptions';
import {DiscountFormType} from '../types/discountFormType';

interface DeactivateDiscountsProps extends TestIdProps {
  onSubmitted: () => Promise<void>;
  onClose: VoidFunction;
  serviceCaseId: string;
  serviceOrderId: string;
}

export function DeactivateDiscounts(props: DeactivateDiscountsProps) {
  const [deleteCustomDiscounts] = usePostServiceOrderCustomDiscountsDeleteMutation();

  const handleSubmit: FormSubmitHandler<DiscountFormType> = async (data) => {
    await deleteCustomDiscounts({
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.serviceOrderId,
      body: {
        isDiscountRemove: data.discount === 'remove',
      },
    })
      .unwrap()
      .catch(handleApiError);

    await props.onSubmitted();
  };

  return (
    <Form<DiscountFormType> defaultValues={{discount: 'remove'}} onSubmit={handleSubmit}>
      {(control) => (
        <VStack spacing={4}>
          <Heading size={4}>{i18n.t('entity.order.labels.deactivateDiscount')}</Heading>
          <FormField
            control={control}
            type="radio"
            name="discount"
            options={DISCOUNT_CHANGE_OPTIONS}
            data-testid={suffixTestId('discount', props)}
          />
          <ButtonGroup align="right">
            <Button
              title={i18n.t('general.actions.discard')}
              variant="secondary"
              onClick={props.onClose}
              data-testid={suffixTestId('discard', props)}
            />
            <FormButton
              control={control}
              type="submit"
              title={i18n.t('general.actions.confirm')}
              data-testid={suffixTestId('confirm', props)}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}
