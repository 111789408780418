import {
  Button,
  ButtonGroup,
  Dialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Space, Text} from 'platform/foundation';
import * as Yup from 'yup';

import {use} from 'react';

import {useCreateAssignmentMutation} from '@dms/api/assignment';
import {CreateExternalUserApiArg} from '@dms/api/shared';
import {useCreateExternalUserMutation} from '@dms/api/user';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {CreateExternalUserRequestBody} from '../../../types/CreateExternalUserRequestBody';
import {TExternalEvent} from '../types/AssignmentType';
import {AssignmentContext} from './AssignmentContext';

export const AssignmentCreatePopup = ({
  email,
  modalEvent,
  ...rest
}: Partial<CreateExternalUserRequestBody> & TExternalEvent & TestIdProps) => {
  const context = use(AssignmentContext);
  const [createExternalUser, {isLoading: isCreatingExternalUser}] = useCreateExternalUserMutation();
  const [createAssignment, {isLoading: isAssigningUser}] = useCreateAssignmentMutation();
  const isLoading = isCreatingExternalUser || isAssigningUser;

  const onSubmit: FormSubmitHandler<{email: string}> = async (submitValue) => {
    const arg = {
      createExternalUserRequestBody: {
        ...submitValue,
        identity: {id: context.assignmentIdentityId, type: context.type},
      },
    } as CreateExternalUserApiArg;

    await createExternalUser(arg)
      .unwrap()
      .then((externalUser) => {
        if (externalUser && externalUser?.id) {
          showNotification.success(i18n.t('general.notifications.success'));

          createAssignment({
            userId: externalUser.id,
            identity: {
              id: context.assignmentIdentityId,
              type: context.type,
            },
            email: submitValue.email,
          })
            .unwrap()
            .catch(handleApiError);

          closeModal();
        }
        closeModal();
      })
      .catch((error: Error & {status?: number}) => {
        if (error.status === 409) {
          showNotification.error(i18n.t('general.notifications.duplicateEmail'));
        } else {
          handleApiError(error);
        }
      });
  };

  const closeModal = () => {
    modalEvent(false);
  };

  return (
    <>
      <Dialog
        isOpen
        onClose={closeModal}
        title={i18n.t('general.labels.assignTitle')}
        size="small"
        data-testid={suffixTestId('assignModal', rest)}
      >
        <Form<{email: string}>
          defaultValues={{email}}
          onSubmit={onSubmit}
          schema={FormSchema}
          shouldWatchForUnsavedChanges
        >
          {(control) => (
            <>
              <Text size="xSmall" color="tertiary">
                {i18n.t('page.assignment.notifications.assignDescription')}
              </Text>
              <Space vertical={4} />
              <FormField
                control={control}
                name="email"
                type="email"
                isRequired
                label={i18n.t('page.assignment.notifications.enterEmail')}
                data-testid={suffixTestId('email', rest)}
              />
              <Space vertical={4} />
              <ButtonGroup align="right">
                <Button
                  variant="secondary"
                  onClick={() => modalEvent()}
                  data-testid={suffixTestId('cancel', rest)}
                  title={i18n.t('general.actions.cancel')}
                />
                <FormButton
                  control={control}
                  type="submit"
                  isLoading={isLoading}
                  data-testid={suffixTestId('confirm', rest)}
                  title={i18n.t('general.actions.confirm')}
                />
              </ButtonGroup>
            </>
          )}
        </Form>
      </Dialog>
    </>
  );
};

const FormSchema = Yup.object({
  email: Yup.string().email().required(),
});
