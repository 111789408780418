import {Avatar} from 'platform/components';
import {Center} from 'platform/foundation';

import {EMPTY_PLACEHOLDER, useUserFullName} from '@dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {HeaderButtonWrapper} from '../../HeaderButtonWrapper/HeaderButtonWrapper';
import {useIsMobileNavigation} from '../hooks/useIsMobileNavigation';

interface MyProfileAndSettingsButtonProps extends TestIdProps {
  isOpen: boolean;
  userAvatarUrl?: string;
}

function UserAvatarButtonMobile(props: MyProfileAndSettingsButtonProps) {
  const userFullName = useUserFullName();

  return (
    <Center width={12} height={12}>
      <Avatar
        name={userFullName}
        size="xSmall"
        variant="circle"
        color={props.isOpen ? 'palettes.neutral.40.100' : 'palettes.neutral.30.100'}
        imageUrl={props.userAvatarUrl}
      />
    </Center>
  );
}

interface AvatarIconControlProps extends TestIdProps {
  isOpen: boolean;
  userAvatarUrl?: string;
}

const UserAvatarButtonDesktop = (props: AvatarIconControlProps) => {
  const userFullName = useUserFullName();

  return (
    <Center width="100%" height="100%">
      <HeaderButtonWrapper
        $rounded
        $size="small"
        $isOpen={props.isOpen}
        data-testid={suffixTestId('myProfileAndSettings-button', props)}
      >
        <Avatar
          name={userFullName ?? EMPTY_PLACEHOLDER}
          size="xSmall"
          variant="circle"
          color={props.isOpen ? 'palettes.neutral.40.100' : 'palettes.neutral.30.100'}
          imageUrl={props.userAvatarUrl}
        />
      </HeaderButtonWrapper>
    </Center>
  );
};

export function UserAvatarButton(props: MyProfileAndSettingsButtonProps) {
  const isMobileNavigation = useIsMobileNavigation();

  if (isMobileNavigation) {
    return <UserAvatarButtonMobile {...props} />;
  }

  return <UserAvatarButtonDesktop {...props} />;
}
