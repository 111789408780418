import {isFeatureEnabled} from 'feature-flags';
import {Action, Card, DataStatus, showNotification} from 'platform/components';
import {VStack} from 'platform/foundation';

import {FC} from 'react';
import {useSelector} from 'react-redux';

import {isNil} from 'ramda';
import {isPositive} from 'ramda-adjunct';

import {useGetActiveSaleVehicleQuery} from '@dms/api/saleVehicle';
import {
  useCopyAuditPhotoToSaleVehicleAlbumMutation,
  useListSaleVehicleAlbumsByVehicleQuery,
} from '@dms/api/saleVehiclePhoto';
import {VehicleAlbums} from '@dms/api/shared';
import {useCopyAuditPhotoToAlbumMutation} from '@dms/api/vehicle';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';
import {
  AuditCategoryUniqueKey,
  selectAudit,
  selectCarAudit,
  selectSalesVehicleData,
  selectStructure,
  selectUserSelectedLanguage,
} from '@dms/teas';

import {suffixTestId, TestIdProps} from 'shared';

import {getPhotosData} from '../utils/getPhotosData';
import {AuditPhotosCategory} from './AuditPhotosCategory';

interface AuditPhotosProps extends TestIdProps {
  notFoundText?: string;
}

export const AuditPhotos: FC<AuditPhotosProps> = (props) => {
  const locale = useSelector(selectUserSelectedLanguage);
  const {loading} = useSelector(selectCarAudit);
  const auditStructure = useSelector(selectStructure);
  const auditData = useSelector(selectAudit);
  const vehicle = useSelector(selectSalesVehicleData);

  const {data: saleVehicle} = useGetActiveSaleVehicleQuery(
    {vehicleId: vehicle?.id ?? ''},
    {
      skip: isNil(vehicle) || !isFeatureEnabled(featureFlags.ACL_VEHICLE_PHOTOS),
    }
  );

  const {data: albums} = useListSaleVehicleAlbumsByVehicleQuery(
    {
      vehicleId: vehicle?.id ?? '',
    },
    {
      skip: isNil(vehicle) || !isFeatureEnabled(featureFlags.ACL_VEHICLE_PHOTOS),
    }
  );

  const saleVehicleSalesAlbumId = albums?.find(
    (album) => album.name === VehicleAlbums.PHOTOS_CAR_SALES && album.active
  )?.id;

  const salesAlbumId = vehicle?.albums?.find(
    (album) => album.name === VehicleAlbums.PHOTOS_CAR_SALES
  )?.id;

  const [copyAuditPhotoToAlbum, {isLoading: isCopying}] = useCopyAuditPhotoToAlbumMutation();

  const [copyAuditPhotoToSaleVehicleAlbum, {isLoading: isCopyingToSaleVehicleAlbum}] =
    useCopyAuditPhotoToSaleVehicleAlbumMutation();

  const categoriesAndPhotos = getPhotosData(
    locale,
    auditData ?? undefined,
    auditStructure ?? undefined
  ).sort((a, b) => {
    // Move PHOTODOCUMENTATION category on top of the list
    if (a.uniqueKey === AuditCategoryUniqueKey.PHOTODOCUMENTATION) {
      return -1;
    }
    if (b.uniqueKey === AuditCategoryUniqueKey.PHOTODOCUMENTATION) {
      return 1;
    }
    return 0;
  });

  const buildPhotoDocumentationActions = (categoryId: string): Action[] => [
    {
      type: 'button',
      variant: 'link',
      leftIcon: 'content/copy',
      title: i18n.t('entity.vehicle.actions.copyAuditPhoto'),
      onClick: () =>
        auditData &&
        vehicle &&
        salesAlbumId &&
        (isFeatureEnabled(featureFlags.ACL_VEHICLE_PHOTOS) &&
        saleVehicle?.id &&
        saleVehicleSalesAlbumId
          ? copyAuditPhotoToSaleVehicleAlbum({
              saleVehicleId: saleVehicle?.id ?? '',
              saleVehicleAuditPhotoCopyRequestBody: {
                auditId: auditData.id,
                auditCategoryId: categoryId,
                saleVehicleAlbumId: saleVehicleSalesAlbumId,
              },
            })
          : copyAuditPhotoToAlbum({
              vehicleId: vehicle.id,
              body: {
                auditId: auditData.id,
                auditCategoryId: categoryId,
                vehicleAlbumId: salesAlbumId,
              },
            })
        )
          .unwrap()
          .then(() => showNotification.success())
          .catch(handleApiError),
      isLoading: isCopying || isCopyingToSaleVehicleAlbum,
    },
  ];

  return (
    <DataStatus
      isLoading={loading.getCondition || loading.getAudit || loading.getAuditStructure}
      isEmpty={!isPositive(categoriesAndPhotos.length)}
      emptyMessage={props.notFoundText}
      minHeight={50}
    >
      <VStack spacing={3}>
        {categoriesAndPhotos?.map((category) => (
          <Card
            key={`${category.name}_${category.id}`}
            title={category.name}
            actions={
              category.uniqueKey === AuditCategoryUniqueKey.PHOTODOCUMENTATION
                ? buildPhotoDocumentationActions(category.id)
                : []
            }
            data-testid={suffixTestId(`[${category.name}]`, props)}
          >
            <VStack spacing={3}>
              {category.childCategories?.map((childCategory, index) => (
                <AuditPhotosCategory
                  key={`${category.name}_${childCategory.id}`}
                  category={childCategory}
                  data-testid={suffixTestId(`[${category.name}]-childCategories-[${index}]`, props)}
                />
              ))}
            </VStack>
          </Card>
        ))}
      </VStack>
    </DataStatus>
  );
};
