import {sortBy} from 'ramda';

import {omneticApi as api} from '@dms/api/core';

import {
  AddCodeApiArg,
  AddCodeApiResponse,
  ChangeCodePriorityApiArg,
  ChangeCodePriorityApiResponse,
  ChangeOrderOfCodesApiArg,
  ReadCodeApiArg,
  ReadCodeApiResponse,
  ReadCodelistApiArg,
  ReadCodelistApiResponse,
  ReadCodelistsApiArg,
  ReadCodelistsApiResponse,
  RemoveCodeApiArg,
  RemoveCodeApiResponse,
  RenameCodeApiArg,
  RenameCodeApiResponse,
} from './types';

export const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    readCodelists: build.query<ReadCodelistsApiResponse, ReadCodelistsApiArg>({
      query: () => ({
        url: `/dms/v1/codelist`,
      }),
      providesTags: (result) =>
        result
          ? [...result.map((cl) => ({type: 'Codelist' as const, id: cl.codelistId})), 'Codelist']
          : ['Codelist'],
    }),
    readCodelist: build.query<ReadCodelistApiResponse, ReadCodelistApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/codelist/${queryArg.codelistId}`,
      }),
      providesTags: (result) => [{type: 'Codelist', id: result?.codelistId}],
    }),
    addCode: build.mutation<AddCodeApiResponse, AddCodeApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/codelist/${queryArg.codelistId}/code`,
        method: 'POST',
        body: queryArg.addCodeRequestBody,
      }),

      invalidatesTags: (result, _, queryArg) => [{type: 'Codelist', id: queryArg.codelistId}],
    }),
    readCode: build.query<ReadCodeApiResponse, ReadCodeApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/codelist/${queryArg.codelistId}/code/${queryArg.codeId}`,
      }),
    }),
    removeCode: build.mutation<RemoveCodeApiResponse, RemoveCodeApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/codelist/${queryArg.codelistId}/code/${queryArg.codeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, __, queryArg) => [{type: 'Codelist', id: queryArg.codelistId}],
    }),
    renameCode: build.mutation<RenameCodeApiResponse, RenameCodeApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/codelist/${queryArg.codelistId}/code/${queryArg.codeId}/name`,
        method: 'PUT',
        body: queryArg.renameCodeRequestBody,
      }),
      invalidatesTags: (_, __, queryArg) => [{type: 'Codelist', id: queryArg.codelistId}],
    }),
    changeCodePriority: build.mutation<ChangeCodePriorityApiResponse, ChangeCodePriorityApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/codelist/${queryArg.codelistId}/code/${queryArg.codeId}/priority`,
        method: 'PUT',
        body: queryArg.changeCodePriorityRequestBody,
      }),
    }),
    changeOrderOfCodes: build.mutation<unknown, ChangeOrderOfCodesApiArg>({
      queryFn: (queryArg, _, __, fetchWithBaseQuery) =>
        // TODO waiting for api batch endpoint [T20-21168]
        Promise.all(
          queryArg.changeOrderOfCodesRequestBody.codeIds.map((codelistId, index) =>
            fetchWithBaseQuery({
              url: `/dms/v1/codelist/${queryArg.codelistId}/code/${codelistId}/priority`,
              method: 'PUT',
              body: {
                priority: queryArg.changeOrderOfCodesRequestBody.codeIds.length - index,
              },
            })
          )
        ).then(() => ({data: null})),
      onQueryStarted: async (
        {codelistId, changeOrderOfCodesRequestBody},
        {dispatch, queryFulfilled}
      ) => {
        // OPTIMISTIC RESPONSE
        const sortedCodeIds = changeOrderOfCodesRequestBody.codeIds;

        const patchResult = dispatch(
          injectedRtkApi.util.updateQueryData('readCodelist', {codelistId}, (draft) => {
            draft.codes = sortBy((a) => sortedCodeIds.indexOf(a.codeId), draft.codes);
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (_, __, queryArg) => [{type: 'Codelist', id: queryArg.codelistId}],
    }),
  }),
  overrideExisting: false,
});

export const {
  useReadCodelistsQuery,
  useReadCodelistQuery,
  useAddCodeMutation,
  useReadCodeQuery,
  useRemoveCodeMutation,
  useRenameCodeMutation,
  useChangeCodePriorityMutation,
  useChangeOrderOfCodesMutation,
} = injectedRtkApi;
