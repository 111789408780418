import {createAction} from '@reduxjs/toolkit';
import {showNotification} from 'platform/components';

import {isNotNil} from 'ramda-adjunct';

import {FullVehicle, VehiclePhoto} from '@dms/api/shared';
import {vehicleApi} from '@dms/api/vehicle';
import {handleApiError} from '@dms/shared';

import {CarFeaturesService} from '../../services/CarFeaturesService';
import {PhotoService} from '../../services/PhotoService';
import {SaleVehicleService} from '../../services/SaleVehicleService';
import {VehicleService} from '../../services/VehicleService';
import {CarFeatures} from '../../types/CarFeatures';
import {PatchVehicleRequestBody} from '../../types/PatchVehicleRequestBody';
import {TagRequestBody} from '../../types/TagRequestBody';
import {TagType} from '../../types/TagType';
import {createAsyncThunk} from '../../utils/createAsyncThunk';
import {asyncThunkAction} from '../../utils/reduxThunkUtils';
import {NAME} from './constants';
import {selectSalesVehicleData, selectSalesVehicleDetail} from './selectors';

type LoadCarDetailsVehicleDetailRequestPayload = {
  vehicleId: string;
  preventLoading?: boolean;
  shouldClearCacheEntry?: boolean;
};

type LoadCarDetailsHighlightsRequestPayload = {
  vehicleId: string;
};

type AddCarDetailsHighlightsRequestPayload = {
  vehicleId: string;
  highlights: TagRequestBody[];
};

type DeleteCarDetailsHighlightRequestPayload = {
  id: string;
  vehicleId: string;
};

type LoadCarDetailsShortcomingsRequestPayload = {
  vehicleId: string;
};

type AddCarDetailsShortcomingsRequestPayload = {
  vehicleId: string;
  shortcomings: TagRequestBody[];
};

type DeleteCarDetailsShortcomingRequestPayload = {
  vehicleId: string;
  id: string;
};

type AddPhotosToAlbumRequestPayload = {
  albumId: string;
  fileIds: string[];
};

type MovePhotosRequestPayload = {
  vehicleId: string;
  photos: string[];
  position: number | null;
  sourceAlbum: string;
  destinationAlbum: string;
};

type CopyPhotosToAlbumRequestPayload = {
  vehicleId: string;
  photos: string[];
  sourceAlbum: string;
  destinationAlbum: string;
  position: number | null;
};

type DeletePhotosRequestPayload = {
  vehicleId: string;
  albumId: string;
  photos: string[];
};

type UpdateVehicleIsArchived = {
  readonly archive: boolean;
  readonly vehicleId: string;
};

type PartiallyUpdateVehicleRequestPayload = {
  vehicleId: string;
  callback?: () => void;
  payload: Partial<PatchVehicleRequestBody>;
};

type SetPhotoAsCoverRequestPayload = {
  albumId: string;
  photoId: string;
};

type RemoveCarDetailsShortComingPayload = {
  id: string;
};

type SetCarDetailsFeatureHighlightedPayload = {
  key: string;
};

export const deleteCarDetailsShortcoming = createAction<RemoveCarDetailsShortComingPayload>(
  `${NAME}/deleteCarDetailsShortcoming`
);

export const deleteCarDetailsHighlight = createAction<RemoveCarDetailsShortComingPayload>(
  `${NAME}/deleteCarDetailsHighlight`
);

export const setCarDetailsFeatureHighlighted = createAction<SetCarDetailsFeatureHighlightedPayload>(
  `${NAME}/setCarDetailsFeatureHighlighted`
);

export const removeCarDetailsFeatureHighlighted =
  createAction<SetCarDetailsFeatureHighlightedPayload>(
    `${NAME}/removeCarDetailsFeatureHighlighted`
  );

export const setVehiclePhotos = createAction<VehiclePhoto[]>(`${NAME}/setVehiclePhotos`);

export const updateVehicleBranch = asyncThunkAction(
  'updateVehicleBranch',
  SaleVehicleService.changeBranchSaleVehicle
);

export const loadCarDetailsVehicleDetailRequest = createAsyncThunk<
  FullVehicle | undefined,
  LoadCarDetailsVehicleDetailRequestPayload
>(
  `${NAME}/loadCarDetailsVehicleDetailRequest`,
  async ({vehicleId, preventLoading, shouldClearCacheEntry}, {extra, rejectWithValue}) => {
    if (preventLoading) {
      return;
    }

    try {
      return await extra.callApi(VehicleService.getVehicle, {
        vehicleId,
        shouldClearCacheEntry,
      });
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const loadCarDetailsShortcomingsRequest = createAsyncThunk<
  TagType[],
  LoadCarDetailsShortcomingsRequestPayload
>(`${NAME}/loadCarDetailsShortcomingsRequest`, ({vehicleId}, {extra}) =>
  extra.callApi(VehicleService.listShortcomings, {
    vehicleId,
  })
);

export const addCarDetailsShortcomingsRequest = createAsyncThunk<
  TagType[],
  AddCarDetailsShortcomingsRequestPayload
>(`${NAME}/addCarDetailsShortcomingsRequest`, ({vehicleId, shortcomings}, {extra}) =>
  extra.callApi(VehicleService.bulkCreateShortcomings, {
    vehicleId,
    requestBody: {shortcomings},
  })
);

export const deleteCarDetailsShortcomingRequest = createAsyncThunk<
  void,
  DeleteCarDetailsShortcomingRequestPayload
>(`${NAME}/deleteCarDetailsShortcomingRequest`, ({vehicleId, id}, {extra, dispatch}) => {
  dispatch(deleteCarDetailsShortcoming({id}));

  return extra.callApi(VehicleService.deleteShortcoming, {vehicleId, shortcomingId: id});
});

export const loadCarDetailsHighlightsRequest = createAsyncThunk<
  TagType[],
  LoadCarDetailsHighlightsRequestPayload
>(`${NAME}/loadCarDetailsHighlightsRequest`, ({vehicleId}, {extra}) =>
  extra.callApi(VehicleService.listHighlights, {
    vehicleId,
  })
);

export const addCarDetailsHighlightsRequest = createAsyncThunk<
  TagType[],
  AddCarDetailsHighlightsRequestPayload
>(`${NAME}/addCarDetailsHighlightsRequest`, ({vehicleId, highlights}, {extra}) =>
  extra.callApi(VehicleService.bulkCreateHighlights, {
    vehicleId,
    requestBody: {highlights},
  })
);

export const deleteCarDetailsHighlightRequest = createAsyncThunk<
  void,
  DeleteCarDetailsHighlightRequestPayload
>(`${NAME}/deleteCarDetailsHighlightRequest`, ({vehicleId, id}, {extra, dispatch}) => {
  dispatch(deleteCarDetailsHighlight({id}));

  return extra.callApi(VehicleService.deleteHighlight, {vehicleId, highlightId: id});
});

export const addPhotosToAlbumRequest = createAsyncThunk<void, AddPhotosToAlbumRequestPayload>(
  `${NAME}/addPhotosToAlbumRequest`,
  async ({albumId, fileIds}, {extra, dispatch, getState, rejectWithValue}) => {
    try {
      const response = await extra.callApi(VehicleService.addFilesToVehicleAlbum, {
        vehicleAlbumId: albumId,
        requestBody: {fileIds},
      });

      const data = selectSalesVehicleData(getState());
      dispatch(vehicleApi.util.invalidateTags([{type: 'Vehicle', id: data?.id}]));

      dispatch(setVehiclePhotos([...(data?.photos || []), ...response?.photos]));
    } catch (error: any) {
      handleApiError(error.response);

      return rejectWithValue(error);
    }
  }
);

export const movePhotosRequest = createAsyncThunk<void, MovePhotosRequestPayload>(
  `${NAME}/movePhotosRequest`,
  async (
    {vehicleId, photos, position, sourceAlbum, destinationAlbum},
    {extra, dispatch, rejectWithValue}
  ) => {
    try {
      await extra.callApi(PhotoService.updatePhotoAlbum, {
        vehicleId,
        requestBody: {
          sourceAlbum,
          destinationAlbum,
          photos: photos.map((p, i) => ({fileId: p, position: i})),
          position,
        },
      });

      const response = await extra.callApi(VehicleService.getVehicle, {vehicleId});
      dispatch(vehicleApi.util.invalidateTags([{type: 'Vehicle', id: vehicleId}]));
      dispatch(
        vehicleApi.util.invalidateTags([
          {type: 'SaleVehicle', id: vehicleId},
          {type: 'SaleVehicleActions', id: vehicleId},
        ])
      );
      dispatch(vehicleApi.util.invalidateTags([{type: 'VehiclePhotos', id: vehicleId}]));
      dispatch(setVehiclePhotos(response.photos ?? []));
    } catch (error: any) {
      handleApiError(error.response);

      return rejectWithValue(error);
    }
  }
);

export const copyPhotosToAlbumRequest = createAsyncThunk<void, CopyPhotosToAlbumRequestPayload>(
  `${NAME}/copyPhotosToAlbumRequest`,
  async (
    {vehicleId, photos, position, sourceAlbum, destinationAlbum},
    {extra, dispatch, rejectWithValue}
  ) => {
    try {
      await extra.callApi(PhotoService.copyPhoto, {
        vehicleId,
        requestBody: {
          sourceAlbum,
          destinationAlbum,
          photos: photos.map((p, i) => ({fileId: p, position: i})),
          position,
        },
      });

      const response = await extra.callApi(VehicleService.getVehicle, {vehicleId});
      dispatch(vehicleApi.util.invalidateTags([{type: 'Vehicle', id: vehicleId}]));
      dispatch(
        vehicleApi.util.invalidateTags([
          {type: 'SaleVehicle', id: vehicleId},
          {type: 'SaleVehicleActions', id: vehicleId},
        ])
      );
      dispatch(
        vehicleApi.util.invalidateTags([{type: 'VehicleAlbumPhotos', id: destinationAlbum}])
      );

      dispatch(setVehiclePhotos(response.photos ?? []));

      showNotification.success();
    } catch (error: any) {
      handleApiError(error.response);

      return rejectWithValue(error);
    }
  }
);

export const deletePhotosRequest = createAsyncThunk<void, DeletePhotosRequestPayload>(
  `${NAME}/deletePhotosRequest`,
  async ({vehicleId, albumId, photos}, {extra, dispatch, rejectWithValue}) => {
    try {
      await extra.callApi(PhotoService.deletePhoto, {
        vehicleId,
        albumId,
        requestBody: {
          photos: photos.map((i) => ({fileId: i})),
        },
      });

      const response = await extra.callApi(VehicleService.getVehicle, {vehicleId});

      dispatch(
        vehicleApi.util.invalidateTags([
          {type: 'Vehicle', id: vehicleId},
          {type: 'SaleVehicle', id: vehicleId},
          {type: 'VehiclePhotos', id: vehicleId},
          {type: 'SaleVehicleActions', id: vehicleId},
        ])
      );
      dispatch(setVehiclePhotos(response.photos ?? []));

      showNotification.success();
    } catch (error: any) {
      handleApiError(error.response);

      return rejectWithValue(error);
    }
  }
);

export const setPhotoAsCover = createAsyncThunk<void, SetPhotoAsCoverRequestPayload>(
  `${NAME}/setPhotoAsCover`,
  async ({albumId, photoId}, {extra, dispatch, getState, rejectWithValue}) => {
    try {
      await extra.callApi(VehicleService.setVehicleAlbumCoverPhoto, {
        vehicleAlbumId: albumId,
        vehiclePhotoId: photoId,
      });

      const vehicleDetails = selectSalesVehicleData(getState());

      dispatch(
        movePhotosRequest({
          vehicleId: vehicleDetails?.id ?? '',
          photos: [photoId],
          position: 100,
          sourceAlbum: albumId,
          destinationAlbum: albumId,
        })
      );

      dispatch(
        vehicleApi.util.invalidateTags([
          {type: 'VehiclePhotos', id: vehicleDetails?.id},
          {type: 'VehicleAlbumPhotos', id: albumId},
          {type: 'SaleVehicle', id: vehicleDetails?.id ?? ''},
          {type: 'SaleVehicleActions', id: vehicleDetails?.id ?? ''},
        ])
      );
    } catch (error: any) {
      handleApiError(error.response);

      return rejectWithValue(error);
    }
  }
);

export const updateVehicleIsArchived = createAsyncThunk<void, UpdateVehicleIsArchived>(
  `${NAME}/updateVehicleIsArchived`,
  async ({archive, vehicleId}, {extra, dispatch, rejectWithValue}) => {
    try {
      if (archive) {
        await extra.callApi(VehicleService.archiveVehicle, {
          vehicleId,
        });
      } else {
        await extra.callApi(VehicleService.unarchiveVehicle, {
          vehicleId,
        });
      }
      dispatch(vehicleApi.util.invalidateTags([{type: 'Vehicle', id: vehicleId}]));
      showNotification.success();
    } catch (error: any) {
      handleApiError(error.response);

      return rejectWithValue(error);
    }
  }
);

export const partiallyUpdateVehicleRequest = createAsyncThunk<
  void,
  PartiallyUpdateVehicleRequestPayload
>(
  `${NAME}/partiallyUpdateVehicleRequest`,
  async ({vehicleId, payload, callback}, {extra, dispatch: _dispatch, rejectWithValue}) => {
    try {
      await extra.callApi(VehicleService.partialUpdateVehicle, {
        vehicleId,
        requestBody: payload,
      });

      showNotification.success();

      callback?.();
    } catch (error: any) {
      handleApiError(error.response);

      return rejectWithValue(error);
    }
  }
);

export const loadPremiumFeatures = createAsyncThunk<CarFeatures, void>(
  `${NAME}/loadPremiumFeatures`,
  async (_, {extra, getState, rejectWithValue}) => {
    try {
      const vehicleDetail = selectSalesVehicleDetail(getState());

      if (
        vehicleDetail?.data?.make &&
        vehicleDetail?.data?.modelFamily &&
        isNotNil(vehicleDetail?.data?.firstRegistrationOnYear)
      ) {
        const {make, modelFamily, firstRegistrationOnYear, features} = vehicleDetail.data;

        return await extra.callApi(CarFeaturesService.getCarFeatures, {
          requestBody: {
            make,
            model: modelFamily,
            year: firstRegistrationOnYear,
            features: features?.map(({key}) => key) ?? [],
          },
        });
      }

      return rejectWithValue(undefined);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getAutofillFeatures = asyncThunkAction(
  `${NAME}/getAutofillFeatures`,
  VehicleService.getAutofillFeatures
);

export const putAutofillFeature = asyncThunkAction(
  `${NAME}/getAutofillFeature`,
  VehicleService.putAutoFillFeature
);
