import {openDialog, openDeleteDialog, showNotification} from 'platform/components';
import {Box} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';
import {match} from 'ts-pattern';
import {isArray} from 'util';

import {useCallback} from 'react';

import {head, isEmpty, isNil, mergeAll} from 'ramda';

import {ReceiveNoteCorrectionTotalPrice} from '@dms/api/metadaWarehouseReceiveNoteCorrection';
import {
  useDeleteReceiveNoteCorrectionItemsMutation,
  PatchReceiveNoteCorrectionItemsDeleteRequest,
} from '@dms/api/metadaWarehouseReceiveNoteCorrectionItem';
import i18n from '@dms/i18n';
import {catchUnhandledDataGridActions, handleApiError, logError} from '@dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {ActionCallback, refreshDatagrid, QueryFilterObject, DataGrid} from 'features/datagrid';

import {OverviewTotalprice} from '../../../../../components/OverviewTotalPrice';
import {MISSING_EDITING_DETAILS_MESSAGE} from '../../../../../constants/missingEditingDetailsMessage';
import {WAREHOUSE_GRID_CODES} from '../../../../../constants/warehouseGridCodes';
import {useWarehouseParams} from '../../../../../hooks/useWarehouseParams';
import {ReceiveNoteCorrectionItemEdit} from './ReceiveNoteCorrectionItemEdit';

interface ListOfItemsTabProps extends RequiredTestIdProps {
  receiveNoteId: string;
  isCreatingReceiveNoteCorrection: boolean;
  isReceiveNoteCorrectionPending: boolean;
  totalPrice: ReceiveNoteCorrectionTotalPrice | Nullish;
  totalPriceCurrency: string;
}

export function ListOfItemsTab(props: ListOfItemsTabProps) {
  const {receiveNoteCorrectionId} = useWarehouseParams();

  const formatCurrency = useFormatCurrency();

  const [deleteReceiveNoteCorrectionItems] = useDeleteReceiveNoteCorrectionItemsMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId}) => {
    match(actionKey)
      .with('redirectDetail', () => {
        const receiveNoteCorrectionItemId = isArray(rowId) ? head(rowId) : rowId;

        if (
          isEmpty(receiveNoteCorrectionId) ||
          isNil(receiveNoteCorrectionItemId) ||
          isEmpty(receiveNoteCorrectionItemId)
        ) {
          return logError(MISSING_EDITING_DETAILS_MESSAGE);
        }

        openDialog(
          <ReceiveNoteCorrectionItemEdit
            receiveNoteCorrectionId={receiveNoteCorrectionId}
            receiveNoteCorrectionItemId={receiveNoteCorrectionItemId}
            onAfterSubmit={() =>
              refreshDatagrid(WAREHOUSE_GRID_CODES.warehouseCreditNoteItem, {deselectAll: true})
            }
            data-testid={suffixTestId('itemEditDialog', props)}
          />,
          {
            id: 'itemEditDialog',
            title: i18n.t('entity.warehouse.labels.editReceiveNoteCorrectionItem'),
            size: 'large',
            withAdditionalFooter: true,
            'data-testid': suffixTestId('itemEditDialog', props),
          }
        );
      })
      .with('delete', () => {
        const ids = isArray(rowId) ? rowId : [rowId];

        const requestBody: PatchReceiveNoteCorrectionItemsDeleteRequest['body'] = {
          state: 'ADDED',
          creditNoteItemId: ids,
        };

        openDeleteDialog({
          id: 'itemDeleteDialog',
          onConfirm: async () =>
            await deleteReceiveNoteCorrectionItems({
              creditNoteId: receiveNoteCorrectionId,
              body: requestBody,
            })
              .unwrap()
              .then(() =>
                showNotification.success(i18n.t('general.notifications.successfullyDeleted'))
              )
              .then(() =>
                refreshDatagrid(WAREHOUSE_GRID_CODES.warehouseCreditNoteItem, {deselectAll: true})
              )
              .catch(handleApiError),
          'data-testid': suffixTestId('itemDeleteDialog', props),
        });
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {creditNoteId: receiveNoteCorrectionId}]),
    [receiveNoteCorrectionId]
  );

  const totalPriceWithoutVat = formatCurrency(
    props.totalPrice?.totalPriceWithoutVat ?? 0,
    props.totalPriceCurrency,
    2
  );

  const totalPriceWithVat = formatCurrency(
    props.totalPrice?.totalPriceWithVat ?? 0,
    props.totalPriceCurrency,
    2
  );

  return (
    <Box height="100%">
      <Box height="95%">
        <DataGrid
          gridCode={WAREHOUSE_GRID_CODES.warehouseCreditNoteItem}
          actionCallback={actionCallback}
          queryModifier={queryModifier}
          data-testid={suffixTestId('receiveNoteCorrectionItems', props)}
        />
      </Box>
      <OverviewTotalprice
        totalPriceWithoutVat={totalPriceWithoutVat}
        totalPriceWithVat={totalPriceWithVat}
        data-testid={suffixTestId('totalPrice', props)}
      />
    </Box>
  );
}
