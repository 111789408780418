import {omneticApi} from '@dms/api/core';

import {
  AddRentalPriceGroupItemApiResponse,
  AddRentalPriceGroupItemApiArg,
  CreateRentalPriceGroupApiResponse,
  CreateRentalPriceGroupApiArg,
  ListRentalPriceGroupApiResponse,
  ListRentalPriceGroupApiArg,
  GetRentalPriceGroupApiResponse,
  GetRentalPriceGroupApiArg,
  RemoveRentalPriceGroupApiResponse,
  RemoveRentalPriceGroupApiArg,
  RemoveRentalPriceGroupItemApiResponse,
  RemoveRentalPriceGroupItemApiArg,
  UpdateRentalPriceGroupItemApiResponse,
  UpdateRentalPriceGroupItemApiArg,
  UpdateRentalPriceGroupApiResponse,
  UpdateRentalPriceGroupApiArg,
  ActivateRentalVehicleApiResponse,
  ActivateRentalVehicleApiArg,
  DeactivateRentalVehicleApiResponse,
  DeactivateRentalVehicleApiArg,
  GetRentalVehicleApiResponse,
  GetRentalVehicleApiArg,
  PutRentalVehicleApiResponse,
  PutRentalVehicleApiArg,
} from './types';

const vehicleRentalApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    addRentalPriceGroupItem: build.mutation<
      AddRentalPriceGroupItemApiResponse,
      AddRentalPriceGroupItemApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/rental-price-group/${queryArg.rentalPriceGroupId}/item`,
        method: 'POST',
        body: queryArg.rentalPriceGroupItemRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'RentalPriceGroup', id: queryArg.rentalPriceGroupId},
      ],
    }),
    createRentalPriceGroup: build.mutation<
      CreateRentalPriceGroupApiResponse,
      CreateRentalPriceGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/rental-price-group`,
        method: 'POST',
        body: queryArg.createRentalPriceGroupRequestBody,
      }),
    }),
    listRentalPriceGroup: build.query<ListRentalPriceGroupApiResponse, ListRentalPriceGroupApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/rental-price-group`,
      }),
    }),
    getRentalPriceGroup: build.query<GetRentalPriceGroupApiResponse, GetRentalPriceGroupApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/rental-price-group/${queryArg.rentalPriceGroupId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'RentalPriceGroup', id: queryArg.rentalPriceGroupId},
      ],
    }),
    removeRentalPriceGroup: build.mutation<
      RemoveRentalPriceGroupApiResponse,
      RemoveRentalPriceGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/rental-price-group/${queryArg.rentalPriceGroupId}`,
        method: 'DELETE',
      }),
    }),
    removeRentalPriceGroupItem: build.mutation<
      RemoveRentalPriceGroupItemApiResponse,
      RemoveRentalPriceGroupItemApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/rental-price-group/${queryArg.rentalPriceGroupId}/item/${queryArg.rentalPriceGroupItemId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'RentalPriceGroup', id: queryArg.rentalPriceGroupId},
      ],
    }),
    updateRentalPriceGroupItem: build.mutation<
      UpdateRentalPriceGroupItemApiResponse,
      UpdateRentalPriceGroupItemApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/rental-price-group/${queryArg.rentalPriceGroupId}/item/${queryArg.rentalPriceGroupItemId}`,
        method: 'PUT',
        body: queryArg.rentalPriceGroupItemRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'RentalPriceGroup', id: queryArg.rentalPriceGroupId},
      ],
    }),
    updateRentalPriceGroup: build.mutation<
      UpdateRentalPriceGroupApiResponse,
      UpdateRentalPriceGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/rental-price-group/${queryArg.rentalPriceGroupId}`,
        method: 'PUT',
        body: queryArg.updateRentalPriceGroupRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'RentalPriceGroup', id: queryArg.rentalPriceGroupId},
      ],
    }),
    activateRentalVehicle: build.mutation<
      ActivateRentalVehicleApiResponse,
      ActivateRentalVehicleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/rental-vehicle/${queryArg.vehicleId}/activate`,
        method: 'POST',
      }),
    }),
    deactivateRentalVehicle: build.mutation<
      DeactivateRentalVehicleApiResponse,
      DeactivateRentalVehicleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/rental-vehicle/${queryArg.vehicleId}/deactivate`,
        method: 'POST',
      }),
    }),
    getRentalVehicle: build.query<GetRentalVehicleApiResponse, GetRentalVehicleApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/rental-vehicle/${queryArg.vehicleId}`,
      }),
    }),
    putRentalVehicle: build.mutation<PutRentalVehicleApiResponse, PutRentalVehicleApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/rental-vehicle/${queryArg.vehicleId}`,
        method: 'PUT',
        body: queryArg.rentalVehicleRequestBody,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useAddRentalPriceGroupItemMutation,
  useCreateRentalPriceGroupMutation,
  useListRentalPriceGroupQuery,
  useGetRentalPriceGroupQuery,
  useRemoveRentalPriceGroupMutation,
  useRemoveRentalPriceGroupItemMutation,
  useUpdateRentalPriceGroupItemMutation,
  useUpdateRentalPriceGroupMutation,
  useActivateRentalVehicleMutation,
  useDeactivateRentalVehicleMutation,
  useGetRentalVehicleQuery,
  usePutRentalVehicleMutation,
} = vehicleRentalApi;
