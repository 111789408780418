import {metadaApi} from '@dms/api/core';

import {
  BulkDeleteReceiveNoteRequest,
  BulkDeleteReceiveNoteRequestSchema,
  BulkDeleteReceiveNoteResponse,
  BulkDeleteReceiveNoteResponseSchema,
  GetReceiveNoteRequest,
  GetReceiveNoteRequestSchema,
  GetReceiveNoteResponse,
  GetReceiveNoteResponseSchema,
  PatchReceiveNoteManualRequest,
  PatchReceiveNoteManualRequestSchema,
  PatchReceiveNoteManualResponse,
  PatchReceiveNoteManualResponseSchema,
  PatchReceiveNoteStateRequest,
  PatchReceiveNoteStateRequestSchema,
  PatchReceiveNoteStateResponse,
  PatchReceiveNoteStateResponseSchema,
  PostReceiveNoteManualRequest,
  PostReceiveNoteManualRequestSchema,
  PostReceiveNoteManualResponse,
  PostReceiveNoteManualResponseSchema,
} from './types';

export const metadaWarehouseReceiveNoteApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getReceiveNote: build.query<GetReceiveNoteResponse, GetReceiveNoteRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/receive-note/${queryArg.receiveNoteId}`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'receiveNote', id: queryArg.receiveNoteId},
      ],
      extraOptions: {
        requestSchema: GetReceiveNoteRequestSchema,
        responseSchema: GetReceiveNoteResponseSchema,
      },
    }),
    postReceiveNoteManual: build.mutation<
      PostReceiveNoteManualResponse,
      PostReceiveNoteManualRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/receive-note/manual`,
        method: 'POST',
        body: queryArg,
        queryArg,
      }),
      invalidatesTags: ['receiveNote'],
      extraOptions: {
        requestSchema: PostReceiveNoteManualRequestSchema,
        responseSchema: PostReceiveNoteManualResponseSchema,
      },
    }),
    patchReceiveNoteManual: build.mutation<
      PatchReceiveNoteManualResponse,
      PatchReceiveNoteManualRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/receive-note/${queryArg.receiveNoteId}/manual`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'receiveNote', id: queryArg.receiveNoteId},
        {type: 'deliveryNote', id: result?.deliveryNote.deliveryNoteId},
      ],
      extraOptions: {
        requestSchema: PatchReceiveNoteManualRequestSchema,
        responseSchema: PatchReceiveNoteManualResponseSchema,
      },
    }),
    bulkDeleteReceiveNote: build.mutation<
      BulkDeleteReceiveNoteResponse,
      BulkDeleteReceiveNoteRequest
    >({
      query: (queryArg) => ({
        url: 'warehouse/v1/receive-note/delete',
        method: 'POST',
        body: queryArg,
        queryArg,
      }),
      invalidatesTags: ['receiveNote', 'deliveryNote'],
      extraOptions: {
        requestSchema: BulkDeleteReceiveNoteRequestSchema,
        responseSchema: BulkDeleteReceiveNoteResponseSchema,
      },
    }),
    patchReceiveNoteState: build.mutation<
      PatchReceiveNoteStateResponse,
      PatchReceiveNoteStateRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/receive-note/${queryArg.receiveNoteId}/state`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'receiveNote', id: queryArg.receiveNoteId},
      ],
      extraOptions: {
        requestSchema: PatchReceiveNoteStateRequestSchema,
        responseSchema: PatchReceiveNoteStateResponseSchema,
      },
    }),
    patchReceiveNoteManualState: build.mutation<
      PatchReceiveNoteStateResponse,
      PatchReceiveNoteStateRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/receive-note/${queryArg.receiveNoteId}/manual/state`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'receiveNote', id: queryArg.receiveNoteId},
      ],
      extraOptions: {
        requestSchema: PatchReceiveNoteStateRequestSchema,
        responseSchema: PatchReceiveNoteStateResponseSchema,
      },
    }),
  }),
});

export const {
  useGetReceiveNoteQuery,
  useLazyGetReceiveNoteQuery,
  usePostReceiveNoteManualMutation,
  usePatchReceiveNoteManualMutation,
  useBulkDeleteReceiveNoteMutation,
  usePatchReceiveNoteStateMutation,
  usePatchReceiveNoteManualStateMutation,
} = metadaWarehouseReceiveNoteApi;
