import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetBusinessCaseQuery} from '@dms/api/businessCase';
import {useGetSaleVehicleQuery} from '@dms/api/saleVehicle';

import {getPurchaseVehicleIdFromBusinessCase} from '../utils/getPurchaseVehicleIdFromBusinessCase';
import {getSaleVehicleIdFromBusinessCase} from '../utils/getSaleVehicleIdFromBusinessCase';

export const useIsBusinessCaseVehicleDeductible = (businessCaseId: string) => {
  const {data: businessCase} = useGetBusinessCaseQuery({
    businessCaseId,
  });
  const saleVehicleId = getSaleVehicleIdFromBusinessCase(businessCase);
  const purchaseVehicleId = getPurchaseVehicleIdFromBusinessCase(businessCase);

  const vehicleId = saleVehicleId ?? purchaseVehicleId;
  const {data: saleVehicle} = useGetSaleVehicleQuery(
    {vehicleId: vehicleId ?? ''},
    {skip: isNilOrEmpty(vehicleId)}
  );

  const purchaseVehicleOffer = businessCase?.offers[0]?.purchaseVehicles?.[0];
  const saleVehicleOffer = businessCase?.offers[0]?.saleVehicles?.[0];

  return Boolean(
    purchaseVehicleOffer?.buying?.vatDeductible ??
      saleVehicleOffer?.vatDeductible ??
      saleVehicle?.isVatDeductible
  );
};
