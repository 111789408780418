import {dec, equals} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

export const isLastIndexInArray = <T>(
  array: readonly T[] | T[] | null | undefined,
  index: number
): boolean => {
  if (isNilOrEmpty(array)) {
    return false;
  }
  return equals(index, dec(array!.length));
};
