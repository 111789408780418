import {Tooltip} from 'platform/components';
import {Icon, ThemeIconKey} from 'platform/foundation';

import {Link} from 'react-router-dom';

import {UserSidebarIconSize} from '@dms/api/shared';
import i18n from '@dms/i18n';

import {composePath, pushGtmEvent, RequiredTestIdProps} from 'shared';

import {SIDEBAR_DESKTOP_ICON_SIZE} from '../constants/sidebarDesktopIconSize';
import {NavigationButton} from './NavigationButton';

interface SidebarItemProps extends RequiredTestIdProps {
  path: string;
  isActive: boolean;
  title: string;
  iconSize: UserSidebarIconSize;
  icon?: ThemeIconKey;
}

export function SidebarLink(props: SidebarItemProps) {
  return (
    <Tooltip offset={8} placement="right" label={i18n.t(props.title)}>
      <Link
        to={composePath(props.path, {isSearchParamsPreserved: false})}
        data-testid={props['data-testid']}
        onClick={() => {
          pushGtmEvent({
            event: 'button_click',
            event_id: 'sidebar_item_click',
            value: props.path,
          });
        }}
      >
        <NavigationButton isActive={props.isActive}>
          <Icon
            size={SIDEBAR_DESKTOP_ICON_SIZE[props.iconSize]}
            value={props.icon}
            color="palettes.neutral.10.100"
          />
        </NavigationButton>
      </Link>
    </Tooltip>
  );
}
