import {Checkbox, IconButton, TableRow} from 'platform/components';
import {Box, HStack, Integer, Show, Text, ThemeColorPath} from 'platform/foundation';

import {MouseEvent} from 'react';

import {isNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {useGetRoleQuery} from '@dms/api/accessControl';
import {
  useGrantResourcePermissionMutation,
  useRevokeResourcePermissionMutation,
} from '@dms/api/accessControlList';
import {testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {TestIdProps, suffixTestId, useQueryState, useToggle} from 'shared';

import {TableDataType} from '../hooks/useGetACLData';
import {NEXT_LINE_OFFSET} from '../utils/consts';
import {getResourceCheckboxValues} from '../utils/getResourceCheckboxValues';
import {ActionItem} from './ActionItem';

interface ResourceItemProps extends TestIdProps {
  data: TableDataType;
  level?: number;
  roleId: string;
}

export function ResourceItem(props: ResourceItemProps) {
  const [mode] = useQueryState('mode');

  const {data: role} = useGetRoleQuery({roleId: props.roleId});
  const isSystem = role?.system ?? false;

  const {isChecked, isIndeterminate} = getResourceCheckboxValues(props.data);
  const [isOpen, toggleOpen] = useToggle(isNil(mode));

  const [grantResource] = useGrantResourcePermissionMutation();
  const [revokeResource] = useRevokeResourcePermissionMutation();

  const level = props.level ?? 0;
  const nextLevel = level + 1;
  const hasChildren = isNotNilOrEmpty(props.data.children);
  const hasProtectedUnits = isNotNilOrEmpty(props.data.protectedUnits);

  const handleToggleButton = (e: MouseEvent) => {
    e.stopPropagation();
    toggleOpen();
  };

  const handleGrantGroup = (isSelected: boolean) => {
    const submitAction = isSelected ? grantResource : revokeResource;
    submitAction({resourceId: props.data.id, roleId: props.roleId}).unwrap().catch(handleApiError);
  };

  const rowColor: ThemeColorPath | undefined =
    isChecked || isIndeterminate ? undefined : 'palettes.red.10.100';

  return (
    <>
      <TableRow
        actions={{primary: []}}
        data-testid={suffixTestId('resourceRow', props)}
        color={rowColor}
      >
        <Box paddingHorizontal={2} paddingLeft={(1 + level * NEXT_LINE_OFFSET) as Integer}>
          <HStack align="center" spacing={8}>
            <Show when={hasChildren || hasProtectedUnits}>
              <IconButton
                onClick={handleToggleButton}
                icon={isOpen ? 'hardware/keyboard_arrow_up' : 'hardware/keyboard_arrow_down'}
                data-testid={suffixTestId('resourceToggle', props)}
              />
              <Checkbox
                isDisabled={isSystem}
                value={isChecked}
                isIndeterminate={isIndeterminate}
                onChange={handleGrantGroup}
                data-testid={suffixTestId('select-resource', props)}
              />
            </Show>
            <Text size="small" alternative>
              {props.data.name}
            </Text>
          </HStack>
        </Box>
        <Box />
      </TableRow>

      <Show when={isOpen}>
        <Show when={hasChildren}>
          {props.data.children.map((children) => (
            <ResourceItem
              data={children}
              level={nextLevel}
              data-testid={testIds.settings.roleManagementDetail('aclTable')}
              key={children.id}
              roleId={props.roleId}
            />
          ))}
        </Show>

        <Show when={hasProtectedUnits}>
          {props.data.protectedUnits.map((protectedUnit) => (
            <ActionItem
              protectedUnit={protectedUnit}
              resource={props.data}
              level={nextLevel}
              data-testid={testIds.settings.roleManagementDetail('aclTable')}
              key={`${props.data.id}-${protectedUnit.id}`}
              roleId={props.roleId}
            />
          ))}
        </Show>
      </Show>
    </>
  );
}
