import {Card, Segment} from 'platform/components';
import {Box, HStack, VStack} from 'platform/foundation';

import {useState} from 'react';

import {GetWarehousesResponse} from '@dms/api/metadaWarehouse';
import {GetDirectSaleResponse} from '@dms/api/metadaWarehouseDirectSale';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {RequestDetailSegment} from './RequestDetailSegment';
import {WorkItemsSegment} from './WorkItemsSegment';

enum Segments {
  WORK_ITEMS = 'workItems',
  REQUEST_DETAIL = 'requestDetail',
}

interface RequestTabProps extends TestIdProps {
  directSale: GetDirectSaleResponse;
  isDirectSaleLoading: boolean;
  isEditingDisabled: boolean;
  availableWarehouses: GetWarehousesResponse;
  isMaterialReturnModalVisible: boolean;
  onCloseMaterialReturnModal: VoidFunction;
}

export function RequestTab(props: RequestTabProps) {
  const [activeSegment, setActiveSegment] = useState<Segments>(Segments.WORK_ITEMS);

  const segments = [
    {
      value: Segments.WORK_ITEMS,
      label: i18n.t('entity.warehouse.labels.workItems'),
      content: (
        <WorkItemsSegment
          directSale={props.directSale}
          isEditingDisabled={props.isEditingDisabled}
          availableWarehouses={props.availableWarehouses}
          isMaterialReturnModalVisible={props.isMaterialReturnModalVisible}
          onCloseMaterialReturnModal={props.onCloseMaterialReturnModal}
          data-testid={suffixTestId('segments.workItems', props)}
        />
      ),
    },
    {
      value: Segments.REQUEST_DETAIL,
      label: i18n.t('entity.warehouse.labels.requestDetail'),
      content: (
        <RequestDetailSegment
          directSale={props.directSale}
          isDirectSaleLoading={props.isDirectSaleLoading}
          isEditingDisabled={props.isEditingDisabled}
          data-testid={suffixTestId('segments.requestDetail', props)}
        />
      ),
    },
  ];

  const segmentContent = segments.find((segment) => segment.value === activeSegment)?.content;

  return (
    <Card isFullHeight={activeSegment === Segments.WORK_ITEMS}>
      <VStack height="100%">
        <HStack width={75}>
          <Box width="100%">
            <Segment<Segments>
              value={activeSegment}
              onChange={setActiveSegment}
              options={segments}
              minWidth="50%"
            />
          </Box>
        </HStack>

        <Box height="100%">{segmentContent}</Box>
      </VStack>
    </Card>
  );
}
