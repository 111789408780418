import {Button, FlagSizeType, openDialog, showNotification} from 'platform/components';
import {Show} from 'platform/foundation';

import {head} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {EntityResourceIds} from '@dms/api/shared';
import {useGetTagsForEntityQuery, useGetTagsQuery} from '@dms/api/tag';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {CreateOrEditTagForm} from './CreateOrEditTagForm';
import {EditableTag} from './EditableTag';

interface ListEditableTagsProps extends TestIdProps {
  resourceId: EntityResourceIds;
  recordId: string;
  size?: FlagSizeType;
  buttonVariant: 'secondary' | 'ghostLink';
  isTagDeletable?: boolean;
  isTagUpdatable?: boolean;
  isTagCreatable?: boolean;
  isTagReadable?: boolean;
  isTagAssignable?: boolean;
  isTagUnassignable?: boolean;
}

export function ListEditableTags(props: ListEditableTagsProps) {
  const {tagId} = useGetTagsQuery(
    {resourceId: props.resourceId},
    {selectFromResult: ({data}) => ({tagId: head(data ?? [])?.id})}
  );
  const {data: entityTags} = useGetTagsForEntityQuery(props);

  const createTag = () => {
    if (!tagId) {
      showNotification.error();
      return;
    }

    openDialog(
      <CreateOrEditTagForm
        recordId={props.recordId}
        resourceId={props.resourceId}
        isCreatable={props.isTagCreatable}
        isDeletable={props.isTagDeletable}
      />,
      {
        title: i18n.t('general.labels.assignTag'),
        scrollBehavior: 'outside',
        size: 'small',
      }
    );
  };

  return (
    <Show when={isNotNil(tagId) && props.isTagReadable}>
      {entityTags?.map((tag, index) => (
        <EditableTag
          {...props}
          tagId={tagId!}
          key={`tag-[${tag.tagId}]-${tag.valueName}`}
          color={tag.valueColor}
          label={tag.valueName}
          tagValueId={tag.valueId}
          size={props.size}
          data-testid={suffixTestId(`tag-[${index}]-[${tag.tagId}]`, props)}
          isTagDeletable={props.isTagDeletable}
          isTagUpdatable={props.isTagUpdatable && props.isTagAssignable && props.isTagUnassignable}
          isTagCreatable={props.isTagCreatable}
          isTagUnassignable={props.isTagUnassignable}
        />
      ))}
      <Show when={props.isTagAssignable}>
        <Button
          title={i18n.t('general.actions.createNewTag')}
          onClick={createTag}
          variant={props.buttonVariant}
          leftIcon={props.buttonVariant === 'ghostLink' ? 'content/add_circle' : undefined}
          size="small"
          data-testid={suffixTestId('add-tag', props)}
        />
      </Show>
    </Show>
  );
}
