import {AttributesRow} from 'platform/components';
import {useDateTimeFormatter, useFormatCurrency} from 'platform/locale';
import {match} from 'ts-pattern';

import {always, indexBy, isNil} from 'ramda';
import {isBoolean, isString, isTrue} from 'ramda-adjunct';

import {
  useGetCustomFieldsPayloadQuery,
  useGetFieldDefinitionListQuery,
} from '@dms/api/customFields';
import {EntityResourceIds, FieldValue} from '@dms/api/shared';
import i18n from '@dms/i18n';

import {CurrencyCodeType, parseDate} from 'shared';

import {isCurrency} from '../utils/isCurrency';

type CustomFieldsParameterRowProps = {
  resourceId: EntityResourceIds;
  recordId: string;
};

const getBooleanString = (value: unknown) =>
  isBoolean(value) ? i18n.t(`general.labels.${value ? 'yes' : 'no'}`) : null;

export const useCustomFieldsParameterRow = (props: CustomFieldsParameterRowProps) => {
  const formatCurrency = useFormatCurrency();
  const formatDateTime = useDateTimeFormatter();

  const {data: payload} = useGetCustomFieldsPayloadQuery(props);
  const {data: definitions} = useGetFieldDefinitionListQuery({
    resourceId: props.resourceId,
    type: 'tenant',
  });

  const getFieldValue = (field: FieldValue): string | null => {
    const value = field?.value?.value;
    const type = field?.value?.type;

    if (isNil(value)) {
      return null;
    }

    const returnValue = match(type)
      .with('datetime', () =>
        isString(value) ? formatDateTime('dateTimeShort', parseDate(value)) : null
      )
      .with('date', () => (isString(value) ? formatDateTime('dateShort', parseDate(value)) : null))
      .with('boolean', always(getBooleanString(value)))
      .with(
        'money',
        always(
          isCurrency(value)
            ? formatCurrency(Number(value.amount), value.currency as CurrencyCodeType)
            : null
        )
      )
      .otherwise(always(value.toString()));

    return returnValue;
  };

  const payloadByDefinitionId = indexBy((item) => item.definitionId, payload ?? []);

  const hasUnfilledRequiredFields = definitions?.some(
    (definition) => isTrue(definition.isRequired) && !payloadByDefinitionId[definition.id]
  );

  const rows: AttributesRow[] =
    definitions?.map((definition) => {
      const definitionValue = payloadByDefinitionId[definition.id];

      return {
        label: definition.name,
        value: getFieldValue(definitionValue),
      };
    }) ?? [];

  return [rows, hasUnfilledRequiredFields] as const;
};
