/**
 * Checks if the browser has sufficient storage space available.
 *
 * @param {number} minRequiredBytes - The minimum number of bytes required to be available.
 * @returns {Promise<boolean | null>} A Promise that resolves to:
 *   - `true` if there is enough storage available (available space >= minRequiredBytes)
 *   - `false` if there is not enough storage available (available space < minRequiredBytes)
 *   - `null` if the browser doesn't support storage estimation API
 *
 * @example
 * // Check if at least 15MB is available
 * const hasEnoughStorage = await isEnoughStorageAvailable(15 * 1024 * 1024);
 * if (hasEnoughStorage === true) {
 *   // Proceed with storage-intensive operations
 * } else if (hasEnoughStorage === false) {
 *   // Show warning about insufficient storage
 * } else {
 *   // Storage estimation not supported by browser
 * }
 */
export async function isEnoughStorageAvailable(minRequiredBytes: number): Promise<boolean | null> {
  if (minRequiredBytes === undefined || minRequiredBytes === null || minRequiredBytes <= 0) {
    throw new Error('minRequiredBytes must be a positive number');
  }

  if (navigator.storage?.estimate) {
    const {quota = 0, usage = 0} = (await navigator.storage.estimate()) ?? {};
    const available = quota - usage;
    return available >= minRequiredBytes;
  }
  return null;
}
