import {isNotNilOrEmpty} from 'shared';

import {GenericRTKQState} from '../types/GenericRTKQState';

export const isRTKState = (state: unknown): state is GenericRTKQState => {
  if (!isNotNilOrEmpty(state)) {
    return false;
  }

  if (typeof state !== 'object') {
    return false;
  }

  if ('queries' in state === false || 'config' in state === false) {
    return false;
  }

  return true;
};
