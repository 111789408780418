import {DayMonthType} from 'shared';

import {useLocale} from '../../hooks/useLocale';
import {LocaleDayMonthFormat} from './types/LocaleDayMonthFormat';
import {formatDayMonth} from './utils/formatDayMonth';

interface DayMonthFormatProps {
  value: DayMonthType;
  format: LocaleDayMonthFormat;
}

export function DayMonthFormat(props: DayMonthFormatProps) {
  const {localeConfig, language} = useLocale();

  return <>{formatDayMonth(localeConfig, language, props.format, props.value)}</>;
}
