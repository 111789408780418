import {FlagProps, showNotification} from 'platform/components';

import {Helmet} from 'react-helmet-async';

import {isNilOrEmpty} from 'ramda-adjunct';

import {
  useGetWarehouseHeaderDynamicActionsQuery,
  usePutWarehouseHeaderDynamicActionsMutation,
} from '@dms/api/metadaWarehouseHeaderDynamicActions';
import {useGetSupplierOrderQuery} from '@dms/api/metadaWarehouseSupplierOrder';
import i18n from '@dms/i18n';
import {testIds, warehouseRoutes} from '@dms/routes';
import {DetailTemplate, DetailTemplateHeader, handleApiError, NavigationItem} from '@dms/shared';

import {buildArray, composePath, generateHashFromObjects, useNavigate} from 'shared';

import {useWarehouseParams} from '../../hooks/useWarehouseParams';
import {createHeaderActions} from '../../utils/createHeaderActions';
import {Documents} from './(sections)/Documents/Documents';
import {Overview} from './(sections)/Overview/Overview';

export function SupplierOrderDetail() {
  const {supplierOrderId} = useWarehouseParams();

  const navigate = useNavigate();

  const {
    data: supplierOrder,
    isLoading: isSupplierOrderLoading,
    isError: isSupplierOrderError,
  } = useGetSupplierOrderQuery({orderId: supplierOrderId}, {skip: isNilOrEmpty(supplierOrderId)});

  const {
    data: supplierOrderActions,
    isLoading: isSupplierOrderActionsLoading,
    isError: isSupplierOrderActionsError,
  } = useGetWarehouseHeaderDynamicActionsQuery({id: supplierOrderId, resource: 'supplier-order'});

  const [putSupplierOrderAction, {isLoading: isPutSupplierOrderActionLoading}] =
    usePutWarehouseHeaderDynamicActionsMutation();

  const isLoading = isSupplierOrderLoading || isSupplierOrderActionsLoading;
  const isError = isSupplierOrderError || isSupplierOrderActionsError;

  const handleNavigateBack = () => {
    navigate(composePath(warehouseRoutes.supplierOrderList));
  };

  const isPending = supplierOrder?.order?.state === 'PENDING';
  const isSent = supplierOrder?.order?.state === 'SENT';
  const isClosed = supplierOrder?.order?.state === 'CLOSED';

  const headerFlags = buildArray<FlagProps>()
    .when(isPending, {
      colorScheme: 'blue',
      label: i18n.t('general.labels.pending'),
    })
    .when(isSent, {
      colorScheme: 'yellow',
      label: i18n.t('general.labels.sent'),
    })
    .when(isClosed, {
      colorScheme: 'green',
      label: i18n.t('entity.warehouse.labels.closedState'),
    });

  const handleActionClick = (actionKey: string) => {
    return putSupplierOrderAction({
      id: supplierOrderId,
      resource: 'supplier-order',
      body: {actionKey},
    })
      .unwrap()
      .then(() => {
        showNotification.success();

        if (actionKey === 'CANCEL') {
          handleNavigateBack();
        }
      })
      .catch(handleApiError);
  };

  const headerActions = createHeaderActions({
    actions: supplierOrderActions?.actions,
    callback: handleActionClick,
    isLoading: isPutSupplierOrderActionLoading,
  });

  const header: DetailTemplateHeader = {
    title: `${i18n.t('entity.warehouse.labels.supplierOrder')} ${supplierOrder?.order?.orderNumber ?? ''}`,
    controls: ['ASSIGNEE'],
    flags: headerFlags,
    actions: headerActions,
  };

  const navigation: NavigationItem[] = [
    {
      id: 'supplierOrderDetail',
      label: i18n.t('page.warehouse.labels.overview'),
      href: composePath(warehouseRoutes.supplierOrderDetailOverview, {
        params: {id: supplierOrderId},
      }),
      content: (
        <Overview
          data-testid={testIds.warehouse.supplierOrderDetailOverview('section.overview')}
          supplierOrder={supplierOrder}
          isPending={isPending}
          isOrderLoading={isSupplierOrderLoading}
          hasOrderError={isSupplierOrderError}
        />
      ),
      'data-testid': testIds.warehouse.supplierOrderDetailOverview('navigation.supplierOrder'),
    },
    {
      id: 'supplierOrderDocuments',
      label: i18n.t('page.warehouse.labels.documents'),
      href: composePath(warehouseRoutes.supplierOrderDetailDocuments, {
        params: {id: supplierOrderId},
      }),
      content: (
        <Documents
          supplierOrderId={supplierOrderId}
          data-testid={testIds.warehouse.supplierOrderDetailOverview('section.documents')}
        />
      ),
      'data-testid': testIds.warehouse.supplierOrderDetailOverview('navigation.documents'),
    },
  ];

  return (
    <>
      <Helmet title={i18n.t('entity.warehouse.labels.supplierOrder')} />
      <DetailTemplate
        key={generateHashFromObjects(supplierOrder)}
        isLoading={isLoading}
        isError={isError}
        header={header}
        navigation={navigation}
        isLocked={isPutSupplierOrderActionLoading}
        data-testid={testIds.warehouse.supplierOrderDetailOverview('page')}
      />
    </>
  );
}
