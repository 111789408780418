import {match} from 'ts-pattern';

import {useBusinessCaseContactCustomerViaSmsMutation} from '@dms/api/businessCase';
import {useInterestContactCustomerViaSmsMutation} from '@dms/api/interest';
import {EntityResourceIds} from '@dms/api/shared';
import {usePostKonzultaSmsMutation} from '@dms/api/tenant';

interface useSendSmsProps {
  resourceId?: EntityResourceIds;
  recordId?: string;
}

export function useSendSms(props: useSendSmsProps) {
  const [postSms, {isLoading: isLoadingKonzulta}] = usePostKonzultaSmsMutation();
  const [postSmsInterest, {isLoading: isLoadingInterest}] =
    useInterestContactCustomerViaSmsMutation();
  const [postSmsBusinessCase, {isLoading: isLoadingBusinessCase}] =
    useBusinessCaseContactCustomerViaSmsMutation();

  const isLoading = isLoadingKonzulta || isLoadingInterest || isLoadingBusinessCase;

  const submitSms = (phone: string, text: string) =>
    match(props.resourceId)
      .with(EntityResourceIds.interest, () =>
        postSmsInterest({interestId: props.recordId ?? '', salesSmsRequestBody: {phone, text}})
      )
      .with(EntityResourceIds.businessCase, () =>
        postSmsBusinessCase({
          businessCaseId: props.recordId ?? '',
          salesSmsRequestBody: {phone, text},
        })
      )
      .otherwise(() => postSms({konzultaSmsSenderRequestBody: {phone, text}}));

  return {submitSms, isLoading};
}
