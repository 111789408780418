import {useParams} from 'react-router-dom';

import {isNil} from 'ramda';
import {isNilOrEmpty, isNotNil} from 'ramda-adjunct';

import {useGetWarehousesQuery} from '@dms/api/metadaWarehouse';
import {useGetDiscountGroupQuery} from '@dms/api/metadaWarehouseDiscountGroup';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';
import {DiscountGroupDetailForm} from './components/DiscountGroupDetailForm';

export function WarehouseDiscountGroupDetail() {
  const {id} = useParams();

  const {
    data: discountGroup,
    isLoading: isDiscountGroupLoading,
    isError: isDiscountGroupError,
  } = useGetDiscountGroupQuery({discountGroupId: id as string}, {skip: isNilOrEmpty(id)});

  const {
    data: warehouses,
    isLoading: isWarehousesLoading,
    isError: isWarehousesError,
  } = useGetWarehousesQuery();

  const isLoading = isNotNil(id)
    ? isWarehousesLoading || isDiscountGroupLoading
    : isWarehousesLoading;

  const isError = isNotNil(id) ? isWarehousesError || isDiscountGroupError : isWarehousesError;

  const header: SettingsTemplateHeader = {
    title: isNil(id) ? i18n.t('entity.warehouse.labels.newDiscountGroup') : discountGroup?.name,
  };

  return (
    <SettingsTemplate
      header={header}
      isLoading={isLoading}
      isError={isError}
      data-testid={testIds.settings.workshopPresetsCategory('template')}
    >
      <DiscountGroupDetailForm
        discountGroup={discountGroup}
        warehouses={warehouses}
        data-testid={testIds.settings.warehousesDiscountGroupDetail('discountGroupDetailForm')}
      />
    </SettingsTemplate>
  );
}
