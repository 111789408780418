import {z} from 'zod';

import i18n from '@dms/i18n';

export const CreateTransactionPresetFormSchema = z.object({
  name: z.string().min(1),
  transactionsIds: z.array(z.string()).min(1, {
    message: i18n.t('general.validations.chooseAtLeastOne'),
  }),
});

export type CreateTransactionPresetFormSchemaType = z.infer<
  typeof CreateTransactionPresetFormSchema
>;
