import {Card, DataStatus} from 'platform/components';
import {Show} from 'platform/foundation';
import {match} from 'ts-pattern';

import {always, path} from 'ramda';

import {useGetBusinessCaseQuery, useGetCustomerRequestedPriceQuery} from '@dms/api/businessCase';
import {useGetSaleVehicleQuery} from '@dms/api/saleVehicle';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

import {useRequiredParams, useToggle} from 'shared';

import {getHeaderActions} from '../../SimplifiedPricing/utils/formGetters';
import {PricingDetail} from './PricingDetail';
import {PricingForm} from './PricingForm';

export function PricerWidget() {
  const {id: businessCaseId} = useRequiredParams();
  const {data: businessCase} = useGetBusinessCaseQuery({businessCaseId});
  const purchaseVehicle =
    businessCase?.businessCaseInternalType === 'PURCHASE_BROKERAGE'
      ? (businessCase?.offers?.[0]?.purchaseBrokerageVehicles?.[0] ?? null)
      : (businessCase?.offers?.[0]?.purchaseVehicles?.[0] ?? null);

  const editable = businessCase?.actions.purchaseEditVehicle ?? false;

  const [isDetail, toggleDetail] = useToggle(!!purchaseVehicle?.pricing.confirmed);

  const {
    data: requestedPrice,
    isLoading,
    isError,
    error,
  } = useGetCustomerRequestedPriceQuery({
    businessCaseId,
    offerId: businessCase?.offers?.[0]?.id ?? '',
    offerPurchaseVehicleId: purchaseVehicle?.id ?? '',
  });
  const isRequestedPriceEmpty = path(['status'], error) === 404;

  const {data: saleVehicle} = useGetSaleVehicleQuery({vehicleId: purchaseVehicle?.vehicleId ?? ''});

  // TODO - T20-39109 - SALE_VEHICLE_STATES - 24
  const shouldHidePricingForm = match([saleVehicle?.buyingState ?? ''])
    .with(['buying'], ['buying'], ['not-set'], always(true))
    .otherwise(always(false));

  if (shouldHidePricingForm) {
    return null;
  }

  return (
    <Card
      data-testid={testIds.businessCase.buying('pricer-card')}
      actions={getHeaderActions(isDetail, editable, toggleDetail)}
      title={i18n.t('page.businessCase.labels.pricing')}
      variant="inlineWhite"
    >
      <Show when={isDetail}>
        <DataStatus isLoading={isLoading} isError={isError && !isRequestedPriceEmpty}>
          <PricingDetail
            requestedPrice={requestedPrice}
            data-testid={testIds.businessCase.buying('pricer-detail')}
          />
        </DataStatus>
      </Show>
      <Show when={!isDetail}>
        <DataStatus isError={isError && !isRequestedPriceEmpty}>
          <PricingForm
            toggleDetail={toggleDetail}
            data-testid={testIds.businessCase.buying('pricer-form')}
          />
        </DataStatus>
      </Show>
    </Card>
  );
}
