import {omneticApi as api} from '@dms/api/core';

import {VinDecoderDecodeApiArg, VinDecoderDecodeApiResponse} from './types';

export const vinDecoderApi = api.injectEndpoints({
  endpoints: (build) => ({
    vinDecoderDecode: build.query<VinDecoderDecodeApiResponse, VinDecoderDecodeApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vin-decoder/${queryArg.vin}`,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {useVinDecoderDecodeQuery, useLazyVinDecoderDecodeQuery} = vinDecoderApi;
