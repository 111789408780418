import {z} from 'zod';

import {defaultTo} from 'ramda';

import i18n from '@dms/i18n';

const MIN_VALUE = 0.01;

export const ReceiveNoteCorrectionItemFormSchema = z
  .object({
    correctionType: z.enum(['RETURN_TO_SUPPLIER', 'VALUE_CORRECTION']),
    receiveItem: z.object({
      receiveCorrectionQuantity: z.number(),
      receiveCorrectionUnitPrice: z.number(),
      receiveCorrectionStockValue: z.number(),
      receiveAfterQuantity: z.number(),
      receiveAfterUnitPrice: z.number(),
      receiveAfterStockValue: z.number(),
      receiveBeforeQuantity: z.number(),
      receiveBeforeUnitPrice: z.number(),
      receiveBeforeStockValue: z.number(),
    }),
    deliveryItem: z.object({
      deliveryCorrectionQuantity: z.number(),
      deliveryCorrectionUnitPrice: z.number(),
      deliveryCorrectionStockValue: z.number(),
      deliveryAfterQuantity: z.number(),
      deliveryAfterUnitPrice: z.number(),
      deliveryAfterStockValue: z.number(),
      deliveryBeforeQuantity: z.number(),
      deliveryBeforeUnitPrice: z.number(),
      deliveryBeforeStockValue: z.number(),
    }),
    supplierArticle: z.object({
      supplierQuantity: z.number(),
      supplierUnitId: z.string(),
      warehouseQuantity: z.number(),
      warehouseUnitId: z.string(),
      supplierOrderingNumber: z.string(),
      supplierBulkPackageQuantity: z.number(),
      supplier: z.string(),
    }),
  })
  .superRefine((data, ctx) => {
    if (data.correctionType === 'VALUE_CORRECTION') {
      if (data.receiveItem.receiveAfterStockValue <= MIN_VALUE) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['receiveItem', 'receiveAfterStockValue'],
          message: `${i18n.t('entity.warehouse.labels.numberMustBeAtLeast')} (${MIN_VALUE})`,
        });
      }

      if (data.deliveryItem.deliveryAfterStockValue <= MIN_VALUE) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['deliveryItem', 'deliveryAfterStockValue'],
          message: `${i18n.t('entity.warehouse.labels.mustBeAtLeast')} (${MIN_VALUE})`,
        });
      }
    }

    if (data.correctionType === 'RETURN_TO_SUPPLIER') {
      if (
        defaultTo(0, data.receiveItem.receiveAfterQuantity) >=
        data.receiveItem.receiveBeforeQuantity
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['receiveItem', 'receiveAfterQuantity'],
          message: `${i18n.t('entity.warehouse.labels.mustBeLessThanBeforeCorrection')} (<${data.receiveItem.receiveBeforeQuantity})`,
        });
      }

      if (
        defaultTo(0, data.deliveryItem.deliveryAfterQuantity) >=
        data.deliveryItem.deliveryBeforeQuantity
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['deliveryItem', 'deliveryAfterQuantity'],
          message: `${i18n.t('entity.warehouse.labels.mustBeLessThanBeforeCorrection')} (<${data.deliveryItem.deliveryBeforeQuantity})`,
        });
      }
    }
  });

export type ReceiveNoteCorrectionItemForm = z.infer<typeof ReceiveNoteCorrectionItemFormSchema>;
