import {Option} from 'platform/components';

import {isNil} from 'ramda';

import {BaseSupplier} from '@dms/api/metadaWarehouseSupplier';

import {Nullish} from 'shared';

export const getOptionsFromSuppliers = (suppliers: BaseSupplier[] | Nullish): Option[] => {
  if (isNil(suppliers)) {
    return [];
  }
  return suppliers.map((supplier) => ({
    label: supplier.name,
    value: supplier.id,
  }));
};
