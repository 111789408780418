import {
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Grid} from 'platform/foundation';

import {useGetSalesFinanceSettingsQuery, useSetSalesFinanceSettingsMutation} from '@dms/api/sales';
import {i18n} from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {useNavigate} from 'shared';

import {SettingsFooter} from '../../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../../components/SettingsSection/SettingsSection';
import {FinancingFormSchema, FinancingFormSchemaType} from './FinancingFormSchema';

const PERCENTAGE_SIGN = '%';

export function FinancingForm() {
  const navigate = useNavigate();

  const {
    data: salesFinanceSettings,
    isLoading: isSalesFinanceSettingsLoading,
    isError: isSalesFinanceSettingsError,
  } = useGetSalesFinanceSettingsQuery();

  const [setSalesFinanceSettings] = useSetSalesFinanceSettingsMutation();

  const handleSubmit: FormSubmitHandler<FinancingFormSchemaType> = async (values, _, reset) => {
    await setSalesFinanceSettings({
      salesFinanceSettingsRequestBody: {
        defaultDailyInterestRate: values.defaultDailyInterestRate,
        expectedDaysToSell: values.expectedDaysToSell,
      },
    })
      .unwrap()
      .then(() => {
        showNotification.success();
        reset(undefined, {keepValues: true});
      })
      .catch(handleApiError);
  };

  const defaultValues: FinancingFormSchemaType = {
    defaultDailyInterestRate: salesFinanceSettings?.defaultDailyInterestRate ?? null,
    expectedDaysToSell: salesFinanceSettings?.expectedDaysToSell ?? null,
  };

  return (
    <SettingsSection>
      <DataStatus isLoading={isSalesFinanceSettingsLoading} isError={isSalesFinanceSettingsError}>
        <Form<FinancingFormSchemaType>
          defaultValues={defaultValues}
          onSubmit={handleSubmit}
          shouldWatchForUnsavedChanges
          experimentalZodSchema={FinancingFormSchema}
        >
          {(control) => (
            <Grid spacing={4} columns={2}>
              <FormField
                name="defaultDailyInterestRate"
                type="text"
                label={i18n.t('entity.financing.labels.defaultDailyInterestRate')}
                suffix={PERCENTAGE_SIGN}
                control={control}
              />

              <FormField
                name="expectedDaysToSell"
                type="number"
                label={i18n.t('entity.financing.labels.expectedDaysToSell')}
                suffix={i18n.t('entity.financing.labels.days')}
                control={control}
              />

              <SettingsFooter
                actions={[
                  {
                    type: 'button',
                    variant: 'secondary',
                    title: i18n.t('general.actions.discardChanges'),
                    onClick: () => {
                      navigate(settingsRoutes.financing);
                    },
                  },
                  {
                    type: 'form-button',
                    buttonType: 'submit',
                    title: i18n.t('general.actions.save'),
                    control,
                  },
                ]}
              />
            </Grid>
          )}
        </Form>
      </DataStatus>
    </SettingsSection>
  );
}
