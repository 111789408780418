import {Option} from 'platform/components';

import {isNil} from 'ramda';

import {GetWarehouseHandlingUnitsResponse} from '@dms/api/metadaWarehouseHandlingUnit';

import {Nullish} from 'shared';

export const getOptionsFromHandlingUnits = (
  handlingUnits: GetWarehouseHandlingUnitsResponse | Nullish
): Option[] => {
  if (isNil(handlingUnits)) {
    return [];
  }

  return handlingUnits.map((handlingUnit) => ({
    label: handlingUnit.name,
    value: handlingUnit.id,
  }));
};

export const getActiveOptionsFromHandlingUnits = (
  handlingUnits: GetWarehouseHandlingUnitsResponse | Nullish
): Option[] => {
  if (isNil(handlingUnits)) {
    return [];
  }

  return handlingUnits
    .filter((handlingUnit) => handlingUnit.isActive)
    .map((activeHandlingUnit) => ({
      label: activeHandlingUnit.name,
      value: activeHandlingUnit.id,
    }));
};

export const getDefaultOptionFromHandlingUnits = (
  handlingUnits: GetWarehouseHandlingUnitsResponse | Nullish
): Option[] => {
  if (isNil(handlingUnits)) {
    return [];
  }

  return handlingUnits
    .filter((handlingUnit) => handlingUnit.isDefault)
    .map((defaultHandlingUnit) => ({
      label: defaultHandlingUnit.name,
      value: defaultHandlingUnit.id,
    }));
};
