import {
  BreadcrumbType,
  Card,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Box, HStack, VStack} from 'platform/foundation';
import {object, SchemaOf, string} from 'yup';

import {CebiaRokvyCredentials} from '@dms/api/shared';
import {useGetCebiaRokvyConfigQuery, usePatchCebiaRokvyConfigMutation} from '@dms/api/tenant';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('page.integrations.labels.vehicleServices'),
    href: settingsRoutes.vehicleServices,
  },
];

export function CebiaRokvy() {
  const {data: cebiaRokvy, isLoading} = useGetCebiaRokvyConfigQuery();
  const [patchCebiaRokvyConfig] = usePatchCebiaRokvyConfigMutation();

  const handleSubmit: FormSubmitHandler<CebiaRokvyCredentials> = (credentials) =>
    patchCebiaRokvyConfig({
      cebiaRokvyConfigRequestBody: {...cebiaRokvy, credentials},
    })
      .unwrap()
      .then(() =>
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'))
      )
      .catch(handleApiError);

  return (
    <SettingsTemplate
      header={{title: i18n.t('page.integrations.labels.cebiaRokvy'), breadcrumbs}}
      data-testid={testIds.settings.vehicleServicesCebia('cebiaRokvy')}
    >
      <DataStatus isLoading={isLoading}>
        <Card title={i18n.t('general.labels.accessDataAuth')}>
          <Form<CebiaRokvyCredentials>
            onSubmit={handleSubmit}
            schema={$RokvyConfigSchema}
            defaultValues={cebiaRokvy?.credentials}
          >
            {(control) => (
              <VStack spacing={4}>
                <HStack spacing={4}>
                  <Box flex={1}>
                    <FormField
                      isRequired
                      type="text"
                      name="username"
                      control={control}
                      label={i18n.t('entity.user.labels.userName')}
                      data-testid={testIds.settings.vehicleServicesCebia('username')}
                    />
                  </Box>

                  <Box flex={1}>
                    <FormField
                      isRequired
                      type="password"
                      name="password"
                      control={control}
                      label={i18n.t('general.labels.password')}
                      data-testid={testIds.settings.vehicleServicesCebia('password')}
                    />
                  </Box>
                </HStack>
                <HStack spacing={4}>
                  <Box flex={1}>
                    <FormField
                      isRequired
                      type="text"
                      name="clientId"
                      control={control}
                      label={i18n.t('general.labels.clientId')}
                      data-testid={testIds.settings.vehicleServicesCebia('clientId')}
                    />
                  </Box>

                  <Box flex={1}>
                    <FormField
                      isRequired
                      type="password"
                      name="clientSecret"
                      control={control}
                      label={i18n.t('general.labels.clientSecret')}
                      data-testid={testIds.settings.vehicleServicesCebia('clientSecret')}
                    />
                  </Box>
                </HStack>

                <HStack justify="flex-end">
                  <FormButton
                    type="submit"
                    control={control}
                    data-testid={testIds.settings.vehicleServicesCebia('submitForm')}
                    title={i18n.t('general.actions.save')}
                  />
                </HStack>
              </VStack>
            )}
          </Form>
        </Card>
      </DataStatus>
    </SettingsTemplate>
  );
}

const $RokvyConfigSchema: SchemaOf<CebiaRokvyCredentials> = object({
  username: string().required(i18n.t('general.notifications.errorFieldRequired')),
  password: string().required(i18n.t('general.notifications.errorFieldRequired')),
  clientId: string().required(i18n.t('general.notifications.errorFieldRequired')),
  clientSecret: string().required(i18n.t('general.notifications.errorFieldRequired')),
});
