import {Button, closeCurrentDialog, DataStatus} from 'platform/components';
import {Box, Center, Heading, Icon, Image, Text, VStack} from 'platform/foundation';

import {isNil, isNotNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  useGetPairedDeviceQrCodeQuery,
  useGetPairedDevicesListQuery,
} from '@dms/api/digitalSignature';
import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId, useOnMount} from 'shared';

import {PairDeviceStep} from '../../types/PairDeviceStep';

interface ConnectionDeviceStepProps extends RequiredTestIdProps {
  setCurrentStep: (step: PairDeviceStep) => void;
  pairedDeviceId: string | null;
}

export function ConnectDeviceStep(props: ConnectionDeviceStepProps) {
  const {
    data: qrCodeSrc,
    isLoading,
    isError,
  } = useGetPairedDeviceQrCodeQuery(
    {pairedDeviceId: props.pairedDeviceId ?? ''},
    {skip: isNilOrEmpty(props.pairedDeviceId)}
  );

  const {refetch: refetchDevices} = useGetPairedDevicesListQuery();

  const handleFinishPairing = () => {
    refetchDevices();
    closeCurrentDialog();
  };

  useOnMount(() => {
    if (isNil(props.pairedDeviceId)) {
      props.setCurrentStep('setup');
    }
  });

  return (
    <VStack spacing={10}>
      <VStack spacing={4} align="center" justify="center">
        <Icon size={20} value="content/qr-code" color="text.white" />
        <Heading size={3}>{i18n.t('entity.settings.labels.connectDevice')}</Heading>
        <Box width={140}>
          <Text size="small" align="center" color="secondary">
            {i18n.t('entity.settings.labels.connectDeviceDescription')}
          </Text>
        </Box>
      </VStack>

      <VStack>
        <Text size="small" color="secondary" align="center">
          {i18n.t('entity.settings.labels.connectDeviceFirstStep')}
        </Text>
        <Text size="small" color="secondary" align="center">
          {i18n.t('entity.settings.labels.connectDeviceSecondStep')}
        </Text>
        <Text size="small" color="secondary" align="center">
          {i18n.t('entity.settings.labels.connectDeviceThirdStep')}
        </Text>
        <Text size="small" color="secondary" align="center">
          {i18n.t('entity.settings.labels.connectDeviceFourthStep')}
        </Text>
      </VStack>

      <DataStatus isLoading={isLoading} isEmpty={isError} minHeight={52}>
        <Center>
          {isNotNil(qrCodeSrc) && (
            <Image
              src={qrCodeSrc}
              width={52}
              height={52}
              data-testid={suffixTestId('qrCode', props)}
            />
          )}
        </Center>
      </DataStatus>

      <Center>
        <Button
          title={i18n.t('general.actions.finish')}
          onClick={handleFinishPairing}
          data-testid={suffixTestId('finish', props)}
        />
      </Center>
    </VStack>
  );
}
