import {Option} from 'platform/components';

import {isNil} from 'ramda';

import {SeriesResponseBody, SeriesType} from '@dms/api/series';

import {Nullish} from 'shared';

export const getOptionsFromSeriesList = (
  seriesList: SeriesResponseBody[] | Nullish,
  type: SeriesType
): Option[] => {
  if (isNil(seriesList)) {
    return [];
  }

  return seriesList
    .filter((serie) => serie.type === type)
    .map((serie) => ({
      label: serie.name,
      value: serie.id,
    }));
};
