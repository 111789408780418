import {Currency} from 'platform/locale';

import {CountryAlpha3CodeEnum, CountryResponseBody} from '@dms/api/shared';

import type {CancelablePromise} from '../types/CancelablePromise';
import type {CountryVatRate} from '../types/CountryVatRate';
import type {Gender} from '../types/Gender';
import {PhoneNumber} from '../types/PhoneNumber';
import type {SourcingServer} from '../types/SourcingServer';
import type {VatCalculation} from '../types/VatCalculation';
import {request as __request} from '../utils/request';

export class DefaultService {
  /**
   * @returns Country
   * @throws ApiError
   */
  public static countries({
    countryCode,
  }: {
    countryCode?: CountryAlpha3CodeEnum;
  }): CancelablePromise<Array<CountryResponseBody>> {
    return __request({
      method: 'GET',
      url: '/common/v1/countries',
      query: {
        countryCode,
      },
    });
  }

  /**
   * @returns Currency
   * @throws ApiError
   */
  public static currencies({
    countryCode,
  }: {
    countryCode?: CountryAlpha3CodeEnum;
  }): CancelablePromise<Array<Currency>> {
    return __request({
      method: 'GET',
      url: '/common/v1/currencies',
      query: {
        countryCode,
      },
    });
  }

  /**
   * For DevOps debug
   *
   * @returns any
   * @throws ApiError
   */
  public static debug(): CancelablePromise<any> {
    return __request({
      method: 'GET',
      url: '/common/v1/debug',
    });
  }

  /**
   * @returns Gender
   * @throws ApiError
   */
  public static genders(): CancelablePromise<Array<Gender>> {
    return __request({
      method: 'GET',
      url: '/common/v1/genders',
    });
  }

  /**
   * @returns PhoneNumber
   * @throws ApiError
   */
  public static phoneNumbers({
    countryCode,
  }: {
    countryCode?: CountryAlpha3CodeEnum;
  }): CancelablePromise<Array<PhoneNumber>> {
    return __request({
      method: 'GET',
      url: '/common/v1/phone-numbers',
      query: {
        countryCode,
      },
    });
  }

  /**
   * @returns SourcingServer
   * @throws ApiError
   */
  public static sourcingServers({
    countryCode,
  }: {
    countryCode?: CountryAlpha3CodeEnum;
  }): CancelablePromise<Array<SourcingServer>> {
    return __request({
      method: 'GET',
      url: '/common/v1/sourcing-servers',
      query: {
        countryCode,
      },
    });
  }

  /**
   * @returns VatCalculation
   * @throws ApiError
   */
  public static calculateFromWithVat({
    countryCode,
    vatRateType,
    amount,
    date,
  }: {
    countryCode: string;
    vatRateType: string;
    amount: string;
    date?: string;
  }): CancelablePromise<VatCalculation> {
    return __request({
      method: 'GET',
      url: '/common/v1/vat-calculation/from-with-vat',
      query: {
        countryCode,
        vatRateType,
        amount,
        date,
      },
    });
  }

  /**
   * @returns VatCalculation
   * @throws ApiError
   */
  public static calculateFromWithoutVat({
    countryCode,
    vatRateType,
    amount,
    date,
  }: {
    countryCode: string;
    vatRateType: string;
    amount: string;
    date?: string;
  }): CancelablePromise<VatCalculation> {
    return __request({
      method: 'GET',
      url: '/common/v1/vat-calculation/from-without-vat',
      query: {
        countryCode,
        vatRateType,
        amount,
        date,
      },
    });
  }

  /**
   * @returns CountryVatRate
   * @throws ApiError
   */
  public static vatRate({
    countryCode,
    date,
  }: {
    countryCode: string;
    date?: string;
  }): CancelablePromise<CountryVatRate> {
    return __request({
      method: 'GET',
      url: '/common/v1/vat-rates/{countryCode}',
      path: {
        countryCode,
      },
      query: {
        date,
      },
    });
  }

  /**
   * @returns CountryVatRate
   * @throws ApiError
   */
  public static vatRates({date}: {date?: string}): CancelablePromise<Array<CountryVatRate>> {
    return __request({
      method: 'GET',
      url: '/common/v1/vat-rates',
      query: {
        date,
      },
    });
  }
}
