import {format, endOfWeek, startOfWeek, isSameMonth, isSameYear} from 'date-fns';
import {
  Button,
  DropdownDivider,
  DropdownGroup,
  DropdownItem,
  IconButton,
  openDropdown,
  Segment,
  useTranslationContext,
} from 'platform/components';
import {
  Box,
  getValueByDevice,
  Hide,
  HStack,
  Right,
  Show,
  Stack,
  Text,
  useDevice,
} from 'platform/foundation';
import {convertStringLocaleToDateFns, useLocale} from 'platform/locale';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import {parseDate, pushGtmEvent, RequiredTestIdProps, suffixTestId, useBoolean} from 'shared';

import {BigCalendarUser} from '../types/BigCalendarUser';
import {BigCalendarUsersProps} from '../types/BigCalendarUsersProps';
import {HeaderUserControll} from './HeaderUserControll';
import {HeaderUserControllDialog} from './HeaderUserControllDialog';

interface HeaderProps extends BigCalendarUsersProps, RequiredTestIdProps {
  currentDate: Date;
  view: 'month' | 'week' | 'day';
  onViewChange: (view: 'month' | 'week' | 'day') => void;
  onNavigate: (date: Date) => void;
}

export function Header(props: HeaderProps) {
  const t = useTranslationContext();
  const device = useDevice();
  const {language} = useLocale();
  const [isUserControllDialogOpen, openUserControllDialog, closeUserControllDialog] =
    useBoolean(false);

  const handlePrevious = () => {
    const newDate = parseDate(props.currentDate);
    match(props.view)
      .with('month', () => newDate.setMonth(props.currentDate.getMonth() - MONTH_OFFSET))
      .with('week', () => newDate.setDate(props.currentDate.getDate() - DAYS_IN_WEEK))
      .with('day', () => newDate.setDate(props.currentDate.getDate() - DAY_OFFSET))
      .exhaustive();

    pushGtmEvent({
      event: 'button_click',
      event_id: 'big_calendar_navigate_previous_click',
      value: {
        view: props.view,
        newDate: newDate.toISOString(),
      },
    });

    props.onNavigate(newDate);
  };

  const handleNext = () => {
    const newDate = parseDate(props.currentDate);
    match(props.view)
      .with('month', () => newDate.setMonth(props.currentDate.getMonth() + MONTH_OFFSET))
      .with('week', () => newDate.setDate(props.currentDate.getDate() + DAYS_IN_WEEK))
      .with('day', () => newDate.setDate(props.currentDate.getDate() + DAY_OFFSET))
      .exhaustive();

    pushGtmEvent({
      event: 'button_click',
      event_id: 'big_calendar_navigate_next_click',
      value: {
        view: props.view,
        newDate: newDate.toISOString(),
      },
    });

    props.onNavigate(newDate);
  };

  const onViewChange = (view: 'month' | 'week' | 'day') => {
    pushGtmEvent({
      event: 'button_click',
      event_id: 'big_calendar_view_change',
      new_value: view,
      previous_value: props.view,
    });
    props.onViewChange(view);
  };

  const onTodayClick = () => {
    pushGtmEvent({
      event: 'button_click',
      event_id: 'big_calendar_navigate_today_click',
      value: {
        view: props.view,
      },
    });
    props.onNavigate(new Date());
  };

  const onUserControlChange = (selectedUsers: BigCalendarUser[]) => {
    pushGtmEvent({
      event: 'button_click',
      event_id: 'big_calendar_user_control_change',
      new_value: {
        selectedUsers: selectedUsers.map((user) => user.email),
        numberOfSelectedUsers: selectedUsers.length,
      },
      previous_value: {
        selectedUsers: props.selectedUsers?.map((user) => user.email),
        numberOfSelectedUsers: props.selectedUsers?.length,
      },
    });

    props.onUserControlChange?.(selectedUsers);
  };

  const getWeekTitle = () => {
    const start = startOfWeek(props.currentDate, {weekStartsOn: 1});
    const end = endOfWeek(props.currentDate, {weekStartsOn: 1});
    if (isSameMonth(start, end)) {
      return `${format(start, 'LLLL yyyy', {
        locale: convertStringLocaleToDateFns(language),
      })}`;
    }
    if (isSameYear(start, end)) {
      return `${format(start, 'LLLL', {
        locale: convertStringLocaleToDateFns(language),
      })} - ${format(end, 'LLLL', {
        locale: convertStringLocaleToDateFns(language),
      })}`;
    }
    return `${format(start, 'LLLL yyyy', {
      locale: convertStringLocaleToDateFns(language),
    })} - ${format(end, 'LLLL yyyy', {
      locale: convertStringLocaleToDateFns(language),
    })}`;
  };

  const title = match([props.view, device === 'mobile'])
    .with(
      ['month', false],
      always(
        format(props.currentDate, 'LLLL yyyy', {locale: convertStringLocaleToDateFns(language)})
      )
    )
    .with(['week', false], getWeekTitle)
    .with(
      ['day', false],
      always(
        format(props.currentDate, 'd. LLLL yyyy, EEEE', {
          locale: convertStringLocaleToDateFns(language),
        })
      )
    )
    .with(
      ['month', true],
      always(format(props.currentDate, 'LLLL', {locale: convertStringLocaleToDateFns(language)}))
    )
    .with(
      ['week', true],
      always(format(props.currentDate, 'LLLL', {locale: convertStringLocaleToDateFns(language)}))
    )
    .with(
      ['day', true],
      always(
        format(props.currentDate, 'd. LLL yyyy', {
          locale: convertStringLocaleToDateFns(language),
        })
      )
    )
    .otherwise(always(null));

  return (
    <Stack
      data-testid={suffixTestId('header', props)}
      direction={['column', 'row']}
      align={['flex-start', 'center']}
      justify={['center', 'space-between']}
    >
      <HStack width="100%" spacing={2} align="center">
        <Hide onMobile>
          <Button
            data-testid={suffixTestId('header-todayButton', props)}
            variant="secondary"
            title={t('bigCalendar.today')}
            onClick={onTodayClick}
          />
        </Hide>
        <Show onMobile>
          <IconButton
            data-testid={suffixTestId('header-todayButton', props)}
            icon="action/calendar_today"
            priority="secondary"
            onClick={onTodayClick}
          />
        </Show>
        <IconButton
          data-testid={suffixTestId('header-chevronLeftButton', props)}
          icon="navigation/chevron_left"
          priority="secondary"
          onClick={handlePrevious}
        />
        <IconButton
          data-testid={suffixTestId('header-chevronRightButton', props)}
          icon="navigation/chevron_right"
          priority="secondary"
          onClick={handleNext}
        />
        <Box width={['100%', 'auto']}>
          <Text
            data-testid={suffixTestId('header-title', props)}
            size={getValueByDevice(device, 'base', 'large')}
          >
            {title}
          </Text>
        </Box>
        <Hide onMobile>
          {props.users?.length && typeof props.onUserControlChange === 'function' ? (
            <HeaderUserControll
              data-testid={suffixTestId('header', props)}
              currentUser={props.currentUser}
              selectedUsers={props.selectedUsers || []}
              users={props.users}
              onUserControlChange={onUserControlChange}
              onDialogOpen={openUserControllDialog}
            />
          ) : null}
        </Hide>
        <Show onMobile>
          <Right width="100%">
            <IconButton
              data-testid={suffixTestId('header-userControll-iconButton', props)}
              icon="action/settings"
              priority="secondary"
              onClick={(event) =>
                openDropdown(
                  event,
                  <>
                    <DropdownGroup label={t('general.actions.view')}>
                      <DropdownItem
                        data-testid={suffixTestId('header-view-month', props)}
                        isActive={props.view === 'month'}
                        leftIcon="automotive/calendar"
                        label={t('bigCalendar.month')}
                        onClick={() => onViewChange('month')}
                      />
                      <DropdownItem
                        data-testid={suffixTestId('header-view-week', props)}
                        isActive={props.view === 'week'}
                        leftIcon="automotive/calendar"
                        label={t('bigCalendar.week')}
                        onClick={() => onViewChange('week')}
                      />
                      <DropdownItem
                        data-testid={suffixTestId('header-view-day', props)}
                        isActive={props.view === 'day'}
                        leftIcon="automotive/calendar"
                        label={t('bigCalendar.day')}
                        onClick={() => onViewChange('day')}
                      />
                    </DropdownGroup>
                    {props.users?.length && typeof props.onUserControlChange === 'function' ? (
                      <>
                        <DropdownDivider />
                        <HeaderUserControll
                          data-testid={suffixTestId('header', props)}
                          currentUser={props.currentUser}
                          selectedUsers={props.selectedUsers || []}
                          users={props.users}
                          onDialogOpen={openUserControllDialog}
                          onUserControlChange={onUserControlChange}
                        />
                      </>
                    ) : null}
                  </>
                )
              }
            />
          </Right>
        </Show>
      </HStack>
      <Hide onMobile>
        <Segment
          data-testid={suffixTestId('header-segment', props)}
          value={props.view}
          onChange={onViewChange}
          options={[
            {value: 'month', label: t('bigCalendar.month')},
            {value: 'week', label: t('bigCalendar.week')},
            {value: 'day', label: t('bigCalendar.day')},
          ]}
        />
      </Hide>
      <HeaderUserControllDialog
        data-testid={suffixTestId('header', props)}
        isOpen={isUserControllDialogOpen}
        currentUser={props.currentUser}
        users={props.users || []}
        selectedUsers={props.selectedUsers || []}
        onClose={closeUserControllDialog}
        onUserControlChange={onUserControlChange}
      />
    </Stack>
  );
}

const DAYS_IN_WEEK = 7;
const MONTH_OFFSET = 1;
const DAY_OFFSET = 1;
