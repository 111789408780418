import {metadaApi} from '@dms/api/core';

import {
  GetServiceCaseScheduleRepairApiArg,
  GetServiceCaseScheduleRepairApiArgSchema,
  GetServiceCaseScheduleRepairApiResponse,
  GetServiceCaseScheduleRepairApiResponseSchema,
  PostServiceCaseScheduleRepairApiArg,
  PostServiceCaseScheduleRepairApiArgSchema,
  PostServiceCaseScheduleRepairApiResponse,
  PostServiceCaseScheduleRepairApiResponseSchema,
} from './types';

export const metadaWorkshopSheduleRepairApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postServiceCaseScheduleRepair: build.mutation<
      PostServiceCaseScheduleRepairApiResponse,
      PostServiceCaseScheduleRepairApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/schedule-repair`,
        method: 'POST',
        params: {redirectUrl: queryArg.redirectUrl},
        queryArg,
      }),
      extraOptions: {
        responseSchema: PostServiceCaseScheduleRepairApiResponseSchema,
        requestSchema: PostServiceCaseScheduleRepairApiArgSchema,
      },
    }),
    getServiceCaseScheduleRepair: build.query<
      GetServiceCaseScheduleRepairApiResponse,
      GetServiceCaseScheduleRepairApiArg
    >({
      query: (queryArg) => ({
        params: {extRepairId: queryArg.extRepairId},
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/schedule-repair`,
        queryArg,
      }),
      extraOptions: {
        responseSchema: GetServiceCaseScheduleRepairApiResponseSchema,
        requestSchema: GetServiceCaseScheduleRepairApiArgSchema,
      },
    }),
  }),
});

export const {usePostServiceCaseScheduleRepairMutation, useGetServiceCaseScheduleRepairQuery} =
  metadaWorkshopSheduleRepairApi;
