import {isFeatureEnabled} from 'feature-flags';
import {
  Button,
  ButtonGroup,
  closeDialog,
  Form,
  FormButton,
  FormField,
  openDialog,
} from 'platform/components';
import {VStack} from 'platform/foundation';

import {isNil} from 'ramda';

import {useGetEmployeeMechanicsQuery} from '@dms/api/metadaEmployee';
import {useGetServiceOrderQuery} from '@dms/api/metadaWorkshopServiceOrder';
import {useGetServiceOrderVariantQuery} from '@dms/api/metadaWorkshopServiceOrderVariant';
import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {ConfirmAssignMechanic} from './ConfirmAssignMechanic';

interface AssignMechanicFormProps extends TestIdProps {
  onClose: () => void;
  selectedMechanics: string[];
  orderId: string;
  serviceJobId: string;
  serviceCaseId: string;
}

type FormType = {mechanics: string[]};

export function AssignMechanicForm(props: AssignMechanicFormProps) {
  const {data: order} = useGetServiceOrderQuery({
    serviceCaseId: props.serviceCaseId,
    serviceOrderId: props.orderId,
  });
  const {data: orderVariant} = useGetServiceOrderVariantQuery(
    {serviceOrderVariantId: order?.serviceOrderVariantId ?? ''},
    {skip: isNil(order?.serviceOrderVariantId)}
  );
  const {data: employeeMechanics} = useGetEmployeeMechanicsQuery(
    {authorizationProfileId: orderVariant?.authorizationProfileId ?? ''},
    {
      skip:
        isNil(orderVariant?.authorizationProfileId) ||
        !isFeatureEnabled(featureFlags.ACL_EMPLOYEE_MANAGEMENT),
    }
  );

  const handleSubmit = async (data: FormType) => {
    await openDialog(
      <ConfirmAssignMechanic
        onConfirm={() => {
          closeDialog('confirmAssignMechanic');
          props.onClose();
        }}
        onClose={() => closeDialog('confirmAssignMechanic')}
        mechanics={data.mechanics}
        employeeMechanics={employeeMechanics}
        orderId={props.orderId}
        serviceJobId={props.serviceJobId}
        serviceCaseId={props.serviceCaseId}
        data-testid={suffixTestId('confirmAssignMechanic', props)}
      />,
      {
        title: i18n.t('entity.orderRequest.labels.assignMechanic'),
        size: 'small',
        id: 'confirmAssignMechanic',
      }
    );
  };

  const mechanicOptions = employeeMechanics?.employees?.map((mechanic) => ({
    label: mechanic?.name,
    value: mechanic?.id,
  }));

  return (
    <Form<FormType> onSubmit={handleSubmit} defaultValues={{mechanics: props.selectedMechanics}}>
      {(control) => (
        <VStack spacing={4}>
          <FormField
            control={control}
            name="mechanics"
            type="multiChoice"
            options={mechanicOptions}
            menuInPortal
            data-testid={suffixTestId('mechanics', props)}
            isNotClearable
          />
          <ButtonGroup align="right">
            <Button
              title={i18n.t('general.actions.discard')}
              onClick={props.onClose}
              variant="secondary"
              data-testid={suffixTestId('close', props)}
            />
            <FormButton
              control={control}
              type="submit"
              title={i18n.t('general.actions.save')}
              data-testid={suffixTestId('save', props)}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}
