import {QueryStatus} from '@reduxjs/toolkit/query';

import {GenericRTKQState} from '../types/GenericRTKQState';
import {isRTKState} from './isRTKState';

/**
 * Prepares an RTK Query state for rehydration
 *
 * This function:
 * 1. Filters out queries that don't have valid data
 * 2. Updates timestamps to make RTK Query treat the data as fresh
 */
export const sanitizeRtkQueryState = <S extends GenericRTKQState = GenericRTKQState>(state: S) => {
  if (!isRTKState(state)) {
    return state;
  }

  const now = Date.now();

  const filteredQueries = {} as GenericRTKQState['queries'];
  Object.entries(state.queries).reduce((filtered, [queryKey, query]) => {
    if (query?.status === QueryStatus.fulfilled && query.data !== undefined) {
      filtered[queryKey] = {
        ...query,
        status: QueryStatus.fulfilled,
        fulfilledTimeStamp: now,
      };
    }
    return filtered;
  }, filteredQueries);

  return {
    ...state,
    queries: filteredQueries,
  };
};
