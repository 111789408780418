import {omneticApi} from '@dms/api/core';

import {
  AddTemplateApiArg,
  AddTemplateApiResponse,
  DeleteDocumentTemplateApiArg,
  DeleteDocumentTemplateApiResponse,
  GetDocumentKindsApiArg,
  GetDocumentKindsApiResponse,
  GetTemplateGroupsApiArg,
  GetTemplateGroupsApiResponse,
  GetTemplatesApiArg,
  GetTemplatesApiResponse,
  HideSystemTemplateApiArg,
  HideSystemTemplateApiResponse,
  PatchDocumentTemplateApiArg,
  PatchDocumentTemplateApiResponse,
  SetPrimaryTemplateApiArg,
  SetPrimaryTemplateApiResponse,
  UnhideSystemTemplateApiArg,
  UnhideSystemTemplateApiResponse,
} from './types';

export const documentApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getDocumentKinds: build.query<GetDocumentKindsApiResponse, GetDocumentKindsApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/document/document-kinds`,
        params: {documentKindGroup: queryArg.documentKindGroup},
      }),
    }),
    getTemplateGroups: build.query<GetTemplateGroupsApiResponse, GetTemplateGroupsApiArg>({
      query: () => ({
        url: `/dms/v2/document/template-group`,
      }),
    }),
    getTemplates: build.query<GetTemplatesApiResponse, GetTemplatesApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/document/templates`,
        params: {documentKindCode: queryArg.documentKindCode},
      }),
      providesTags: ['DocumentTemplates'],
    }),
    setPrimaryTemplate: build.mutation<SetPrimaryTemplateApiResponse, SetPrimaryTemplateApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/document/template/set-primary`,
        method: 'PUT',
        body: queryArg.setPrimaryTemplateRequestBody,
      }),
      async onQueryStarted(data, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          documentApi.util.updateQueryData('getTemplates', {}, (draft) =>
            draft.map((item) => {
              if (item.id === data.setPrimaryTemplateRequestBody.templateId) {
                return {...item, primary: true, hidden: false};
              }

              if (data.documentKindCode === item.documentKindCode && item.primary) {
                return {...item, primary: false};
              }

              return item;
            })
          )
        );
        await queryFulfilled.catch(patchResult.undo);
      },
    }),
    hideSystemTemplate: build.mutation<HideSystemTemplateApiResponse, HideSystemTemplateApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/document/template/default/${queryArg.templateId}/hide`,
        method: 'PUT',
      }),
      async onQueryStarted(data, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          documentApi.util.updateQueryData('getTemplates', {}, (draft) =>
            draft.map((item) => {
              if (item.id === data.templateId) {
                return {...item, hidden: true};
              }
              return item;
            })
          )
        );
        await queryFulfilled.catch(patchResult.undo);
      },
    }),
    deleteDocumentTemplate: build.mutation<
      DeleteDocumentTemplateApiResponse,
      DeleteDocumentTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/document/template/${queryArg.templateId}`,
        method: 'DELETE',
      }),
      async onQueryStarted(data, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          documentApi.util.updateQueryData('getTemplates', {}, (draft) =>
            draft.filter((item) => item.id !== data.templateId)
          )
        );
        await queryFulfilled.catch(patchResult.undo);
      },
    }),
    unhideSystemTemplate: build.mutation<
      UnhideSystemTemplateApiResponse,
      UnhideSystemTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/document/template/default/${queryArg.templateId}/unhide`,
        method: 'PUT',
      }),
      async onQueryStarted(data, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          documentApi.util.updateQueryData('getTemplates', {}, (draft) =>
            draft.map((item) => {
              if (item.id === data.templateId) {
                return {...item, hidden: false};
              }
              return item;
            })
          )
        );
        await queryFulfilled.catch(patchResult.undo);
      },
    }),
    addDocumentTemplate: build.mutation<AddTemplateApiResponse, AddTemplateApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/document/template`,
        method: 'POST',
        body: queryArg.addTemplateRequestBody,
      }),
    }),
    patchDocumentTemplate: build.mutation<
      PatchDocumentTemplateApiResponse,
      PatchDocumentTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/document/template`,
        method: 'PATCH',
        body: queryArg.patchTemplateRequestBody,
      }),
    }),
  }),
});

export const {
  useDeleteDocumentTemplateMutation,
  useGetDocumentKindsQuery,
  useGetTemplateGroupsQuery,
  useGetTemplatesQuery,
  useAddDocumentTemplateMutation,
  usePatchDocumentTemplateMutation,
  useSetPrimaryTemplateMutation,
  useHideSystemTemplateMutation,
  useUnhideSystemTemplateMutation,
} = documentApi;
