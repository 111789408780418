import {isFeatureEnabled} from 'feature-flags';
import {NotificationItemData} from 'platform/components';
import {useDateTimeFormatter, useLocale} from 'platform/locale';
import {match} from 'ts-pattern';

import {useCallback} from 'react';
import {Trans} from 'react-i18next';
import {useDispatch} from 'react-redux';

import {always} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {Record, SentToastNotificationResponseBody} from '@dms/api/messaging';
import {useLazyGetServiceCaseByServiceOrderIdQuery} from '@dms/api/metadaWorkshopServiceCase';
import {taskManagementApi} from '@dms/api/taskManagement';
import {useLazyGetUserAvatarUrlQuery} from '@dms/api/user';
import featureFlags from '@dms/feature-flags';
import {openEditTaskFormDialog} from '@dms/features/tasks';
import i18n from '@dms/i18n';
import {
  businessCaseRoutes,
  customerRoutes,
  interestsRoutes,
  vehiclesRoutes,
  workshopRoutes,
} from '@dms/routes';
import {
  downloadDataGridExport,
  ORDER_TABS,
  queryParams,
  useGetRecordUrlForSignature,
  notificationTypes,
} from '@dms/shared';

import {composePath, parseDate} from 'shared';

import {formatDataGridExportDate} from 'features/datagrid';

import {composeEntityPath} from '../utils/composeEntityPath';
import {formatDueDateToNaturalLanguage} from '../utils/formatDueDateToNaturalLanguage';

interface UseGetNotificationItemDataProps {
  onNotificationClick?: () => void;
}

export function useGetNotificationItemData(props?: UseGetNotificationItemDataProps) {
  const [getRecordUrlForSignature] = useGetRecordUrlForSignature();
  const [getServiceCaseByServiceOrderId] = useLazyGetServiceCaseByServiceOrderIdQuery();
  const [getUserAvatarUrl] = useLazyGetUserAvatarUrlQuery();
  const formatDateTime = useDateTimeFormatter();
  const dispatch = useDispatch();

  const {language} = useLocale();

  const getURLFromRecord = useCallback(
    (record: Record, commentId?: string) =>
      match(record)
        .with({resourceId: 'VEHICLE'}, () =>
          composePath(vehiclesRoutes.detail, {
            params: {id: record.id},
            queryParams: {[queryParams.COMMENT_ID]: commentId},
            isSearchParamsPreserved: false,
          })
        )
        .with({resourceId: 'CUSTOMER'}, () =>
          composePath(customerRoutes.detail, {
            params: {id: record.id},
            queryParams: {[queryParams.COMMENT_ID]: commentId},
            isSearchParamsPreserved: false,
          })
        )
        .with({resourceId: 'BUSINESS_CASE'}, () =>
          composePath(businessCaseRoutes.overview, {
            params: {id: record.id},
            queryParams: {[queryParams.COMMENT_ID]: commentId},
            isSearchParamsPreserved: false,
          })
        )
        .with({resourceId: 'INTEREST'}, () =>
          composePath(interestsRoutes.overview, {
            params: {id: record.id},
            queryParams: {[queryParams.COMMENT_ID]: commentId},
            isSearchParamsPreserved: false,
          })
        )
        .with({resourceId: 'SERVICE_CASE'}, () =>
          composePath(workshopRoutes.serviceCaseDetail, {
            params: {id: record.id},
            queryParams: {[queryParams.COMMENT_ID]: commentId},
            isSearchParamsPreserved: false,
          })
        )
        .with({resourceId: 'SERVICE_ORDER'}, async () => {
          const serviceCaseId = await getServiceCaseByServiceOrderId({serviceOrderId: record.id})
            .unwrap()
            .then((data) => data?.id);

          return isNotNilOrEmpty(serviceCaseId)
            ? composePath(workshopRoutes.serviceCaseDetail, {
                params: {
                  id: serviceCaseId,
                },
                queryParams: {
                  [queryParams.SERVICE_CASE_ORDER_ID]: record.id,
                  [queryParams.SERVICE_CASE_ORDER_TAB]: ORDER_TABS.COMMENTS_NOTES,
                  [queryParams.COMMENT_ID]: commentId,
                },
                isSearchParamsPreserved: false,
              })
            : null;
        })
        .otherwise(always(undefined)),
    [getServiceCaseByServiceOrderId]
  );

  const handleGetUserAvatarUrl = useCallback(
    (userId: string) => async () => {
      const {data: avatarUrl} = await getUserAvatarUrl({userId});

      return avatarUrl;
    },
    [getUserAvatarUrl]
  );

  return useCallback(
    (notification: SentToastNotificationResponseBody, userId: string) =>
      match<
        SentToastNotificationResponseBody,
        Promise<NotificationItemData> | NotificationItemData | null
      >(notification)
        .with({type: notificationTypes.PARTICIPATION_ASSIGNEE_ASSIGNED}, async (data) => {
          const recordUrl = await getURLFromRecord(data.payload.record);

          return {
            id: data.id,
            isRead: data.isRead,
            created: parseDate(data.createdAt),
            resource: data.payload.record.name,
            url: recordUrl,
            getAvatarUrl: handleGetUserAvatarUrl(data.payload.actor.id),
            userName: data.payload.actor.name,
            title: (
              <Trans
                i18nKey="general.notifications.labels.participationAssigneeAssigned"
                values={{userName: data.payload.actor.name}}
                components={{bold: <strong />}}
              />
            ),
          };
        })
        .with({type: notificationTypes.DATAGRID_EXPORT}, (data) => {
          const exportedAtDate = data.payload.exportedAt
            ? formatDataGridExportDate(parseDate(data.payload.exportedAt))
            : '';

          return {
            id: data.id,
            isRead: data.isRead,
            icon: 'notification/terminal',
            created: parseDate(data.createdAt),
            resource: i18n.t('general.notifications.labels.datagridExportDownloadFile', {
              resourceName: data.payload.dataGridCode,
              dateTime: exportedAtDate,
              fileFormat: match(data.payload.fileType)
                .with('CSV', () => 'csv')
                .with('PDF', () => 'pdf')
                .otherwise(() => 'xlsx'),
            }),
            onClick: async () => {
              await downloadDataGridExport({
                exportId: data.payload.exportId,
                gridCode: data.payload.dataGridCode,
                exportedAt: exportedAtDate,
              });
              props?.onNotificationClick?.();
            },
            shouldShowPendingState: true,
            title: (
              <Trans
                i18nKey="general.notifications.labels.datagridExport"
                values={{
                  exportDate: formatDateTime('dateTimeShort', parseDate(data.payload.exportedAt)),
                }}
              />
            ),
          };
        })
        .with({type: notificationTypes.PARTICIPATION_ASSIGNEEE_UNASSIGNED}, async (data) => {
          const recordUrl = await getURLFromRecord(data.payload.record);

          return {
            id: data.id,
            isRead: data.isRead,
            created: parseDate(data.createdAt),
            resource: data.payload.record.name,
            url: recordUrl,
            userName: data.payload.actor.name,
            getAvatarUrl: handleGetUserAvatarUrl(data.payload.actor.id),
            title: (
              <Trans
                i18nKey="general.notifications.labels.participationAssigneeUnassigned"
                values={{userName: data.payload.actor.name}}
                components={{bold: <strong />}}
              />
            ),
          };
        })
        .with(
          {type: notificationTypes.PARTICIPATION_OWNER_CHANGED, payload: {newOwner: {id: userId}}},
          async (data) => {
            const recordUrl = await getURLFromRecord(data.payload.record);

            return {
              id: data.id,
              isRead: data.isRead,
              created: parseDate(data.createdAt),
              resource: data.payload.record.name,
              url: recordUrl,
              userName: data.payload.actor.name,
              getAvatarUrl: handleGetUserAvatarUrl(data.payload.actor.id),
              title: (
                <Trans
                  i18nKey="general.notifications.labels.participationOwnerAssigned"
                  values={{userName: data.payload.actor.name}}
                  components={{bold: <strong />}}
                />
              ),
            };
          }
        )
        .with({type: notificationTypes.PARTICIPATION_OWNER_CHANGED}, async (data) => {
          const recordUrl = await getURLFromRecord(data.payload.record);

          return {
            id: data.id,
            isRead: data.isRead,
            created: parseDate(data.createdAt),
            resource: data.payload.record.name,
            url: recordUrl,
            userName: data.payload.actor.name,
            getAvatarUrl: handleGetUserAvatarUrl(data.payload.actor.id),
            title: (
              <Trans
                i18nKey="general.notifications.labels.participationOwnerUnassigned"
                values={{userName: data.payload.actor.name}}
                components={{bold: <strong />}}
              />
            ),
          };
        })
        .with({type: notificationTypes.VEHICLE_INSPECTION_EXPIRATION_NOTICE}, (data) => ({
          id: data.id,
          isRead: data.isRead,
          created: parseDate(data.createdAt),
          resource: data.payload.vehicle.name,
          url: composePath(vehiclesRoutes.detail, {params: {id: data.payload.vehicle.id}}),
          icon: 'notification/terminal',
          title: (
            <Trans
              count={data.payload.daysUntilExpiration}
              i18nKey="general.notifications.labels.vehicleInspectionExpirationNotice"
              components={{bold: <strong />}}
            />
          ),
        }))
        .with({type: notificationTypes.DATAGRID_CUSTOM_PRESET_SHARED}, (data) => ({
          id: data.id,
          isRead: data.isRead,
          created: parseDate(data.createdAt),
          getAvatarUrl: handleGetUserAvatarUrl(data.payload.sharedByUser.id),
          title: (
            <Trans
              i18nKey="general.notifications.labels.datagridCustomPresetShared"
              values={{
                name: data.payload.sharedByUser.name,
                grid: data.payload.datagridCode,
                presetName: data.payload.datagridPresetTitle,
              }}
              components={{bold: <strong />}}
            />
          ),
        }))
        .with({type: notificationTypes.VEHICLE_NEXT_SERVICE_NOTICE}, (data) => ({
          id: data.id,
          isRead: data.isRead,
          icon: 'action/today',
          created: parseDate(data.createdAt),
          resource: data.payload.vehicle.name,
          url: composePath(vehiclesRoutes.detail, {params: {id: data.payload.vehicle.id}}),
          title: (
            <Trans
              count={data.payload.daysUntilNextService}
              i18nKey="general.notifications.labels.vehicleServiceNotice"
              components={{bold: <strong />}}
            />
          ),
        }))
        .with({type: notificationTypes.VEHICLE_FILE_OPREATION_FINISHED}, (data) => {
          if (data.payload.operation === 'ROTATE') {
            return {
              id: data.id,
              isRead: data.isRead,
              created: parseDate(data.createdAt),
              resource: data.payload.vehicle.name,
              url: composePath(vehiclesRoutes.photos, {
                params: {id: data.payload.vehicle.id, tab: 'vehicle-photos'},
              }),
              icon: 'image/photo',
              title: i18n.t('general.notifications.labels.vehiclePhotoRotationFinished'),
            };
          }

          if (data.payload.operation === 'REMOVE_BACKGROUND') {
            return {
              id: data.id,
              isRead: data.isRead,
              created: parseDate(data.createdAt),
              resource: data.payload.vehicle.name,
              url: composePath(vehiclesRoutes.photos, {
                params: {id: data.payload.vehicle.id, tab: 'vehicle-photos'},
              }),
              icon: 'image/photo',
              title: i18n.t('general.notifications.labels.vehicleBackgroundReplacementFinished'),
            };
          }

          return null;
        })
        .with({type: notificationTypes.VEHICLE_PHOTO_ROTATE_FINISHED}, (data) => ({
          id: data.id,
          isRead: data.isRead,
          created: parseDate(data.createdAt),
          resource: data.payload.vehicle.name,
          url: composePath(vehiclesRoutes.photos, {
            params: {id: data.payload.vehicle.id, tab: 'vehicle-photos'},
          }),
          icon: 'image/photo',
          title: i18n.t('general.notifications.labels.vehiclePhotoRotationFinished'),
        }))
        .with({type: notificationTypes.VEHICLE_BACKGROUND_REMOVAL_FINISHED}, (data) => ({
          id: data.id,
          isRead: data.isRead,
          created: parseDate(data.createdAt),
          resource: data.payload.vehicle.name,
          url: composePath(vehiclesRoutes.photos, {
            params: {id: data.payload.vehicle.id, tab: 'vehicle-photos'},
          }),
          icon: 'image/photo',
          title: i18n.t('general.notifications.labels.vehicleBackgroundReplacementFinished'),
        }))
        .with({type: notificationTypes.SALE_VEHICLE_BACKGROUND_REMOVAL_FINISHED}, (data) => ({
          id: data.id,
          isRead: data.isRead,
          created: parseDate(data.createdAt),
          resource: data.payload.saleVehicle.vehicleName,
          url: composePath(vehiclesRoutes.photos, {
            params: {id: data.payload.saleVehicle.vehicleId, tab: 'vehicle-photos'},
          }),
          icon: 'image/photo',
          title: i18n.t('general.notifications.labels.vehicleBackgroundReplacementFinished'),
        }))
        .with({type: notificationTypes.COMMENT_MENTION}, async (data) => {
          const recordUrl = await getURLFromRecord(data.payload.record, data.payload.commentId);

          const commonProps = {
            id: data.id,
            isRead: data.isRead,
            created: parseDate(data.createdAt),
            resource: data.payload.record.name,
            userName: data.payload.author.name,
            getAvatarUrl: handleGetUserAvatarUrl(data.payload.author.id),
            message: data.payload.commentHtml,
            title: (
              <Trans
                i18nKey="general.notifications.labels.mention"
                values={{user: data.payload.author.name}}
                components={{bold: <strong />}}
              />
            ),
          } as const;

          if (data.payload.record.resourceId === 'TASK') {
            return {
              ...commonProps,
              onClick: () => {
                if (isFeatureEnabled(featureFlags.CORE_TASK_MANAGEMENT)) {
                  dispatch(taskManagementApi.util.invalidateTags(['comments']));
                  props?.onNotificationClick?.();
                  openEditTaskFormDialog({
                    id: data.payload.record.id,
                    'data-testid': 'task-notification',
                  });
                }
              },
            };
          }

          return {
            ...commonProps,
            url: recordUrl,
          };
        })
        .with({type: notificationTypes.COMMENT_RECORD_OWNER}, async (data) => {
          const recordUrl = await getURLFromRecord(data.payload.record, data.payload.commentId);

          return {
            id: data.id,
            isRead: data.isRead,
            created: parseDate(data.createdAt),
            resource: data.payload.record.name,
            url: recordUrl,
            getAvatarUrl: handleGetUserAvatarUrl(data.payload.author.id),
            userName: data.payload.author.name,
            message: data.payload.commentHtml,
            title: i18n.t('general.notifications.labels.serviceOrderComment', {
              user: data.payload.author.name,
            }),
          };
        })
        .with({type: notificationTypes.SALE_VEHICLE_RESERVATION_CANCELLED}, (data) => ({
          id: data.id,
          isRead: data.isRead,
          created: parseDate(data.createdAt),
          resource: data.payload.vehicle.name,
          icon: 'action/settings',
          url: composePath(vehiclesRoutes.detail, {params: {id: data.payload.vehicle.id}}),
          title: i18n.t('general.notifications.labels.saleVehicleReservationCancelled'),
        }))
        .with({type: notificationTypes.VEHICLE_DAYS_SINCE_LAST_REPRICE_NOTICE}, (data) => ({
          id: data.id,
          isRead: data.isRead,
          icon: 'notification/terminal',
          created: parseDate(data.createdAt),
          resource: data.payload.vehicle.name,
          url: composePath(vehiclesRoutes.detail, {params: {id: data.payload.vehicle.id}}),
          title: (
            <Trans
              count={data.payload.daysSinceLastReprice}
              i18nKey="general.notifications.labels.vehicleDaysSinceLastReprice"
              components={{bold: <strong />}}
            />
          ),
        }))
        .with({type: notificationTypes.VEHICLE_WARRANTY_EXPIRATION_NOTICE}, (data) => ({
          id: data.id,
          isRead: data.isRead,
          icon: 'notification/terminal',
          created: parseDate(data.createdAt),
          resource: data.payload.vehicle.name,
          url: composePath(vehiclesRoutes.detail, {params: {id: data.payload.vehicle.id}}),
          title: (
            <Trans
              count={data.payload.daysUntilExpiration}
              i18nKey="general.notifications.labels.customFieldExpiration"
              components={{bold: <strong />}}
            />
          ),
        }))
        .with({type: notificationTypes.CUSTOM_FIELDS_NOTICE}, (data) => ({
          id: data.id,
          isRead: data.isRead,
          icon: 'notification/terminal',
          created: parseDate(data.createdAt),
          resource: data.payload.record.name,
          url: composeEntityPath(data.payload.record.resourceId, data.payload.record.id),
          title: (
            <Trans
              values={{
                count: data.payload.daysUntilExpiration,
                fieldName: data.payload.customFieldName,
                entityName: data.payload.record.name,
              }}
              i18nKey="general.notifications.labels.customFieldExpiration"
              components={{bold: <strong />}}
            />
          ),
        }))
        .with({type: notificationTypes.DIGITAL_DOCUMENT_SIGNED}, async (data) => {
          const recordUrl = await getRecordUrlForSignature(data.payload.record);

          const transactionTypeTitle =
            data.payload.transactionType === 'remote'
              ? i18n.t('entity.document.labels.remote')
              : i18n.t('entity.document.labels.onSite');

          const documentMessage = `${i18n.t('general.notifications.labels.documentHasBeenSignedBy')} ${data.payload.signedBy.name} (${transactionTypeTitle})`;

          return {
            id: data.id,
            isRead: data.isRead,
            url: recordUrl,
            resource: data.payload.documentName,
            message: documentMessage,
            created: parseDate(data.createdAt),
            userName: data.payload.signedBy.name,
            getAvatarUrl: handleGetUserAvatarUrl(data.payload.signedBy.id),
            title: i18n.t('general.notifications.labels.documentHasBeenSigned'),
          };
        })
        .with({type: notificationTypes.TASK_CREATED}, (data) => ({
          id: data.id,
          isRead: data.isRead,
          created: parseDate(data.createdAt),
          resource: data.payload.task.title,
          onClick: () => {
            if (isFeatureEnabled(featureFlags.CORE_TASK_MANAGEMENT)) {
              props?.onNotificationClick?.();
              openEditTaskFormDialog({
                id: data.payload.task.id,
                'data-testid': 'task-notification',
              });
            }
          },
          title: (
            <Trans
              i18nKey="general.notifications.labels.assignedTaskToYou"
              values={{name: data.payload.user.name}}
              components={{bold: <strong />}}
            />
          ),
          userName: data.payload.user.name,
          getAvatarUrl: handleGetUserAvatarUrl(data.payload.user.id),
        }))
        .with({type: notificationTypes.TASK_UPDATED}, (data) => ({
          id: data.id,
          isRead: data.isRead,
          created: parseDate(data.createdAt),
          resource: data.payload.task.title,
          onClick: () => {
            if (isFeatureEnabled(featureFlags.CORE_TASK_MANAGEMENT)) {
              props?.onNotificationClick?.();
              openEditTaskFormDialog({
                id: data.payload.task.id,
                'data-testid': 'task-notification',
              });
            }
          },
          title: (
            <Trans
              i18nKey="general.notifications.labels.updatedTask"
              values={{name: data.payload.user.name}}
              components={{bold: <strong />}}
            />
          ),
          userName: data.payload.user.name,
          getAvatarUrl: handleGetUserAvatarUrl(data.payload.user.id),
        }))
        .with({type: notificationTypes.TASK_DUE_DATE_NOTICE}, (data) => ({
          id: data.id,
          isRead: data.isRead,
          created: parseDate(data.createdAt),
          resource: data.payload.task.title,
          onClick: () => {
            if (isFeatureEnabled(featureFlags.CORE_TASK_MANAGEMENT)) {
              props?.onNotificationClick?.();
              openEditTaskFormDialog({
                id: data.payload.task.id,
                'data-testid': 'task-notification',
              });
            }
          },
          title: formatDueDateToNaturalLanguage(parseDate(data.payload.dueDate), language),
        }))
        .with({type: notificationTypes.TASK_COMMENTED}, (data) => ({
          id: data.id,
          isRead: data.isRead,
          created: parseDate(data.createdAt),
          resource: data.payload.task.title,
          message: data.payload.commentHtml,
          onClick: () => {
            dispatch(taskManagementApi.util.invalidateTags(['comments']));
            props?.onNotificationClick?.();
            openEditTaskFormDialog({
              id: data.payload.task.id,
              'data-testid': 'task-notification',
            });
          },
          title: (
            <Trans
              i18nKey="general.notifications.labels.serviceOrderComment"
              values={{user: data.payload.user.name}}
              components={{bold: <strong />}}
            />
          ),
          userName: data.payload.user.name,
          getAvatarUrl: handleGetUserAvatarUrl(data.payload.user.id),
        }))
        .with({type: notificationTypes.VEHICLE_UNREGISTERED_NOTICE}, (data) => ({
          id: data.id,
          isRead: data.isRead,
          icon: 'notification/terminal',
          created: parseDate(data.createdAt),
          resource: data.payload.vehicle.name,
          url: composePath(vehiclesRoutes.detail, {params: {id: data.payload.vehicle.id}}),
          title: (
            <Trans
              count={data.payload.daysUntilExpiration}
              i18nKey="general.notifications.labels.vehicleUnregisteredNotice"
              components={{bold: <strong />}}
            />
          ),
        }))
        .with({type: notificationTypes.ADVERTISEMENT_PUBLISHING_FAILED}, (data) => ({
          id: data.id,
          isRead: data.isRead,
          created: parseDate(data.createdAt),
          resource: data.payload.vehicle.name,
          url: composePath(vehiclesRoutes.publishing, {
            params: {id: data.payload.vehicle.id},
          }),
          icon: 'alert/error_outline',
          title: i18n.t('general.notifications.labels.advertisementPublishingFailed'),
        }))
        .otherwise(always(null)),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- formatDateTime rerenders on every render, but its stable
    [getRecordUrlForSignature, getURLFromRecord, handleGetUserAvatarUrl]
  );
}
