import {metadaApi} from '@dms/api/core';

import {
  DeleteEmployeeCompetenceApiArg,
  DeleteEmployeeCompetenceApiResponse,
  DeleteEmployeeCompetenceLinkApiArg,
  DeleteEmployeeCompetenceLinkApiResponse,
  GetEmployeeCompetenceApiArg,
  GetEmployeeCompetenceApiResponse,
  GetEmployeeCompetenceIntegrationApiArg,
  GetEmployeeCompetenceIntegrationApiResponse,
  GetEmployeeWorkCompetencesListApiResponse,
  GetIntegrationCompetencesApiResponse,
  PatchEmployeeCompetenceApiArg,
  PatchEmployeeCompetenceApiResponse,
  PostEmployeeCompetenceApiArg,
  PostEmployeeCompetenceApiResponse,
  PostEmployeeCompetenceSetAsActiveApiArg,
  PostEmployeeCompetenceSetAsActiveApiResponse,
  PostEmployeeCompetenceSetAsInactiveApiArg,
  PostEmployeeCompetenceSetAsInactiveApiResponse,
  PutEmployeeCompetenceLinkApiArg,
  PutEmployeeCompetenceLinkApiResponse,
} from './types';

export const metadaEmployeeCompetenceApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postEmployeeCompetence: build.mutation<
      PostEmployeeCompetenceApiResponse,
      PostEmployeeCompetenceApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-competence`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['employeeCompetences'],
    }),
    getEmployeeCompetence: build.query<
      GetEmployeeCompetenceApiResponse,
      GetEmployeeCompetenceApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-competence/${queryArg.id}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'employeeCompetence', projectId: queryArg.id},
      ],
    }),
    deleteEmployeeCompetence: build.mutation<
      DeleteEmployeeCompetenceApiResponse,
      DeleteEmployeeCompetenceApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-competence/${queryArg.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'employeeCompetences',
        {type: 'employeeCompetence', projectId: queryArg.id},
      ],
    }),
    patchEmployeeCompetence: build.mutation<
      PatchEmployeeCompetenceApiResponse,
      PatchEmployeeCompetenceApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-competence/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'employeeCompetences',
        {type: 'employeeCompetence', projectId: queryArg.id},
      ],
    }),
    postEmployeeCompetenceSetAsActive: build.mutation<
      PostEmployeeCompetenceSetAsActiveApiResponse,
      PostEmployeeCompetenceSetAsActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-competence/${queryArg.competenceId}/set-as-active`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'employeeCompetences',
        {type: 'employeeCompetence', projectId: queryArg.competenceId},
      ],
    }),
    postEmployeeCompetenceSetAsInactive: build.mutation<
      PostEmployeeCompetenceSetAsInactiveApiResponse,
      PostEmployeeCompetenceSetAsInactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-competence/${queryArg.competenceId}/set-as-inactive`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'employeeCompetences',
        {type: 'employeeCompetence', projectId: queryArg.competenceId},
      ],
    }),
    getEmployeeWorkCompetencesList: build.query<GetEmployeeWorkCompetencesListApiResponse, void>({
      query: () => ({
        url: `/core/v1/employee-competences`,
      }),
      providesTags: ['employeeCompetences'],
    }),
    getEmployeeCompetenceIntegration: build.query<
      GetEmployeeCompetenceIntegrationApiResponse,
      GetEmployeeCompetenceIntegrationApiArg
    >({
      query: (queryArg) => ({
        url: '/core/v1/employee-competence/link',
        params: {
          competenceId: queryArg.competenceId,
          contextId: queryArg.contextId,
          contextTarget: queryArg.contextTarget,
        },
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'employeeCompetenceLink', id: queryArg.competenceId},
      ],
    }),
    getIntegrationCompetences: build.query<GetIntegrationCompetencesApiResponse, void>({
      query: () => ({
        url: `/integration/v1/integration/PLANNER/employee-competence`,
      }),
    }),
    deleteEmployeeCompetenceLink: build.mutation<
      DeleteEmployeeCompetenceLinkApiResponse,
      DeleteEmployeeCompetenceLinkApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-competence/${queryArg.competenceId}/link/${queryArg.contextTarget}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'employeeCompetenceLink', id: queryArg.competenceId},
      ],
    }),
    putEmployeeCompetenceLink: build.mutation<
      PutEmployeeCompetenceLinkApiResponse,
      PutEmployeeCompetenceLinkApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-competence/${queryArg.competenceId}/link/${queryArg.contextTarget}/${queryArg.contextId}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'employeeCompetenceLink', id: queryArg.competenceId},
      ],
    }),
  }),
});

export const {
  usePostEmployeeCompetenceMutation,
  useGetEmployeeCompetenceQuery,
  useDeleteEmployeeCompetenceMutation,
  usePatchEmployeeCompetenceMutation,
  usePostEmployeeCompetenceSetAsActiveMutation,
  usePostEmployeeCompetenceSetAsInactiveMutation,
  useGetEmployeeWorkCompetencesListQuery,
  useGetEmployeeCompetenceIntegrationQuery,
  useGetIntegrationCompetencesQuery,
  useDeleteEmployeeCompetenceLinkMutation,
  usePutEmployeeCompetenceLinkMutation,
} = metadaEmployeeCompetenceApi;
