import {Checkbox, DataStatus, Separator} from 'platform/components';
import {Box, Integer, Scroll, Show, VStack, useElementSize} from 'platform/foundation';
import {useTheme} from 'styled-components';

import {useEffect, useState} from 'react';

import {isNotNil} from 'ramda';
import {round} from 'ramda-adjunct';

import {useGetTreeFoldersQuery} from '@dms/api/metadaTreeFolder';
import {TreeFolderContextTarget} from '@dms/api/shared';
import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {FolderItem} from './components/FolderItem';
import {FolderSearch} from './components/FolderSearch';
import {FolderCallback} from './types/FolderCallback';

interface FolderTreeProps extends RequiredTestIdProps {
  contextTarget: TreeFolderContextTarget;
  contextIds: string[];
  activeFolderId?: string;
  shouldIncludeSubfolders?: boolean;
  onFolderChange: FolderCallback;
  onIncludeSubfoldersChange?: (shouldIncludeSubfolders: boolean) => void;
}

const DEFAULT_PARENT_HEIGHT: Integer = 50;
const DEFAULT_FOLDER_TREE_PADDING: Integer = 12;

export function FolderTree(props: FolderTreeProps) {
  const theme = useTheme();

  const [scrollableContentWrapperRef, scrollableContentWrapperHeight] =
    useElementSize<HTMLDivElement>();

  const [scrollableContentHeight, setScrollableContentHeight] =
    useState<Integer>(DEFAULT_PARENT_HEIGHT);

  const {data, isLoading, isError} = useGetTreeFoldersQuery({
    contextTarget: props.contextTarget,
    body: {contextIds: props.contextIds},
  });

  const scrollableContentWrapperHeightInSteps = getHeightInSteps(
    scrollableContentWrapperHeight ?? DEFAULT_PARENT_HEIGHT,
    theme.space.step
  );

  // Update scrollableContentHeight once content loads (initially scrollableContentWrapperHeight is null).
  useEffect(() => {
    if (scrollableContentWrapperHeight) {
      const height = getHeightInSteps(scrollableContentWrapperHeight, theme.space.step);
      setScrollableContentHeight(height);
    }
  }, [scrollableContentWrapperHeight, theme.space.step]);

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <VStack height="100%" spacing={4}>
        <FolderSearch
          folders={data}
          onSelect={props.onFolderChange}
          data-testid={suffixTestId('search', props)}
        />

        <Box
          ref={scrollableContentWrapperRef}
          minHeight={scrollableContentWrapperHeightInSteps}
          height="100%"
        >
          <Scroll auto minHeight="100%" height={scrollableContentHeight}>
            <FolderItem
              label={i18n.t('entity.folderTree.labels.allCatalogues')}
              subfolders={data}
              contextTarget={props.contextTarget}
              activeId={props.activeFolderId}
              onClick={props.onFolderChange}
              isRoot
              data-testid={suffixTestId('root', props)}
            />
          </Scroll>
        </Box>

        <Show when={isNotNil(props.shouldIncludeSubfolders)}>
          <Separator spacing={0} />
          <Checkbox
            label={i18n.t('entity.folderTree.labels.includeSubfolders')}
            value={props.shouldIncludeSubfolders!}
            onChange={props.onIncludeSubfoldersChange}
            data-testid={suffixTestId('includeSubfolders', props)}
          />
        </Show>
      </VStack>
    </DataStatus>
  );
}

const getHeightInSteps = (heightInPixels: number, step: number) =>
  (round(heightInPixels * step) - DEFAULT_FOLDER_TREE_PADDING) as Integer;
