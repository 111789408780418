import {Card, Action, DataStatus} from 'platform/components';
import {Box, Show, VStack} from 'platform/foundation';

import {isNilOrEmpty} from 'ramda-adjunct';

import {useListLatestVehiclesQuery} from '@dms/api/dashboard';
import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {vehiclesRoutes} from '@dms/routes';
import {useDataQuery, usePermissions} from '@dms/shared';

import {TestIdProps, composePath, useNavigate} from 'shared';

import {LatestVehicleItem} from './LatestVehicleItem';

interface LatestVehiclesProps extends TestIdProps {
  branchId: string | undefined;
}

export function LatestVehicles(props: LatestVehiclesProps) {
  const navigate = useNavigate();

  const [canReadVehicle] = usePermissions({permissionKeys: ['vehicleRead']});

  const {
    data: latestVehicles,
    isLoading: isLatestVehiclesLoading,
    isError: isLatestVehiclesError,
  } = useListLatestVehiclesQuery(
    {
      branchId: props.branchId,
    },
    {refetchOnMountOrArgChange: true}
  );

  const {queryParams} = useDataQuery('vehicle', {
    // Latest vehicles are sorted by createdAt in descending order
    order: [{columnKey: 'createdAt', order: 'DESC'}],
  });

  const onClickOpenLatestVehicles = () => {
    navigate(composePath(vehiclesRoutes.home, {queryParams}));
  };

  const displayLatestVehiclesAction: Action = {
    title: i18n.t('page.homepage.labels.view'),
    onClick: onClickOpenLatestVehicles,
    variant: 'link',
    type: 'button',
    rightIcon: 'navigation/chevron_right',
  };

  return (
    <Show when={canReadVehicle} whenFeatureEnabled={featureFlags.ACL_CORE}>
      <Card
        title={i18n.t('page.homepage.labels.latestVehicles')}
        hasSeparator={false}
        actions={[displayLatestVehiclesAction]}
      >
        <Box minHeight={56}>
          <DataStatus
            minHeight="100%"
            isLoading={isLatestVehiclesLoading}
            isError={isLatestVehiclesError}
            isEmpty={isNilOrEmpty(latestVehicles)}
          >
            <VStack spacing={4}>
              {latestVehicles?.map((vehicle) => (
                <LatestVehicleItem key={vehicle.id} vehicle={vehicle} />
              ))}
            </VStack>
          </DataStatus>
        </Box>
      </Card>
    </Show>
  );
}
