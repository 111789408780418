import {isFeatureEnabled} from 'feature-flags';
import {Alert} from 'platform/components';
import {Show} from 'platform/foundation';
import {Lightbox} from 'platform/lightbox';
import {useDateTimeFormatter} from 'platform/locale';

import {useSelector} from 'react-redux';

import {isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetVehicleLocalActionNoteQuery} from '@dms/api/metadaVehicleIntegrations';
import {useGetParticipationQuery} from '@dms/api/participation';
import {selectSaleVehicle} from '@dms/api/saleVehicle';
import {EntityResourceIds} from '@dms/api/shared';
import {useGetVehicleV2Query} from '@dms/api/vehicle';
import {featureFlags} from '@dms/feature-flags';
import {testIds} from '@dms/routes';
import {
  EntityHeader,
  usePermissions,
  usePricePermissions,
  useTenant,
  useVehicleConditionEvaluation,
} from '@dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {useGetVehicleControls} from '../hooks/useGetVehicleControls';
import {useGetVehicleHeaderActions} from '../hooks/useGetVehicleHeaderActions';
import {useGetVehicleHeaderFlags} from '../hooks/useGetVehicleHeaderFlags';
import {useGetVehicleHeaderInformationParameters} from '../hooks/useGetVehicleHeaderInformationParameters';
import {useGetVehicleHeaderParameters} from '../hooks/useGetVehicleHeaderParameters';
import {useGetVehicleSnippets} from '../hooks/useGetVehicleSnippets';
import {useVehicleHeaderImage} from '../hooks/useVehicleHeaderImage';
import {getRegistrationAlertData} from './getRegistrationAlertData';
import {TechnicalInspectionAlert} from './TechnicalInspectionAlert';

interface VehicleHeaderProps extends TestIdProps {
  vehicleId: string;
}

export function VehicleHeader(props: VehicleHeaderProps) {
  const {data: vehicle} = useGetVehicleV2Query({vehicleId: props.vehicleId});
  const {data: saleVehicle} = useSelector(selectSaleVehicle(props.vehicleId));
  const {tenantCountry} = useTenant();
  const formatDateTime = useDateTimeFormatter();

  const isSaleVehicle = !!saleVehicle;

  const actions = useGetVehicleHeaderActions(props.vehicleId);
  const {primaryParameter, secondaryParameter, tertiaryParameter} =
    useGetVehicleHeaderParameters(saleVehicle);
  const vehicleHeaderParameters = useGetVehicleHeaderInformationParameters(isSaleVehicle, vehicle);
  const flags = useGetVehicleHeaderFlags(props.vehicleId);
  const {snippets, isLoading} = useGetVehicleSnippets(props.vehicleId);
  const [vehicleConditionEvaluation, {isLoading: isAuditLoading}] = useVehicleConditionEvaluation(
    props.vehicleId
  );

  const {data: vehicleParticipation} = useGetParticipationQuery({
    resourceId: EntityResourceIds.vehicle,
    recordId: props.vehicleId,
  });

  const [
    hasVehicleTagReadPermission,
    hasVehicleTagCreatePermission,
    hasVehicleTagUpdatePermission,
    hasVehicleTagDeletePermission,
    hasVehicleTagAssignPermission,
    hasVehicleTagUnassignPermission,
    canReadBusinessCase,
    canChangeVehicleOwner,
    canAssignVehicleUser,
    canUnassignVehicleUser,
  ] = usePermissions({
    permissionKeys: [
      'vehicleTagRead',
      'vehicleTagCreate',
      'vehicleTagUpdate',
      'vehicleTagDelete',
      'vehicleTagAssign',
      'vehicleTagUnassign',
      'businessCaseRead',
      'vehicleChangeOwner',
      'vehicleAssignUser',
      'vehicleUnassignUser',
    ],
    scopes: {
      vehicleTagAssign: {
        participation: vehicleParticipation,
        // since default vehicle (without saleVehicle) doesn't have branch, we skip branchId scope check
        branchId: isSaleVehicle ? saleVehicle?.branchId : 'SKIP',
      },
      vehicleTagUnassign: {
        participation: vehicleParticipation,
        // since default vehicle (without saleVehicle) doesn't have branch, we skip branchId scope check
        branchId: isSaleVehicle ? saleVehicle?.branchId : 'SKIP',
      },
      vehicleTagRead: {
        participation: vehicleParticipation,
        // since default vehicle (without saleVehicle) doesn't have branch, we skip branchId scope check
        branchId: isSaleVehicle ? saleVehicle?.branchId : 'SKIP',
      },
      vehicleChangeOwner: {
        participation: vehicleParticipation,
        // since default vehicle (without saleVehicle) doesn't have branch, we skip branchId scope check
        branchId: isSaleVehicle ? saleVehicle?.branchId : 'SKIP',
      },
      vehicleAssignUser: {
        participation: vehicleParticipation,
        // since default vehicle (without saleVehicle) doesn't have branch, we skip branchId scope check
        branchId: isSaleVehicle ? saleVehicle?.branchId : 'SKIP',
      },
      vehicleUnassignUser: {
        participation: vehicleParticipation,
        // since default vehicle (without saleVehicle) doesn't have branch, we skip branchId scope check
        branchId: isSaleVehicle ? saleVehicle?.branchId : 'SKIP',
      },
    },
  });

  const {canReadVehicleSellingPrice} = usePricePermissions({
    vehicleRecordId: props.vehicleId,
    businessCaseRecordId: null,
  });

  const canSeeVehiclePrice =
    canReadVehicleSellingPrice && canReadBusinessCase && isFeatureEnabled(featureFlags.ACL_SALES);

  const controls = useGetVehicleControls({
    isSaleVehicle,
    vehicleId: props.vehicleId,
    saleVehicle,
    branchId: saleVehicle?.branchId,
  });

  const [lightboxControls, coverPhoto, onCoverPhotoOpen, vehicleImages] = useVehicleHeaderImage(
    props.vehicleId
  );

  const vehicleLocalActionNoteQuery = useGetVehicleLocalActionNoteQuery(
    {
      vehicleId: props.vehicleId,
    },
    {skip: !isFeatureEnabled(featureFlags.ACL_WORKSHOP)}
  );

  const recallMessage = vehicleLocalActionNoteQuery.currentData?.local_action_note_text;

  const wasSold = saleVehicle && isNilOrEmpty(saleVehicle.soldAt);

  const shouldShowRegistrationWarning =
    tenantCountry === 'POL' &&
    saleVehicle?.type === 'IN_STOCK' &&
    !vehicle?.isRegistered &&
    wasSold;

  const registrationAlertData =
    shouldShowRegistrationWarning && saleVehicle?.purchaseDate
      ? getRegistrationAlertData({purchaseDateAt: saleVehicle.purchaseDate, formatDateTime})
      : null;

  return (
    <>
      <EntityHeader
        title={vehicle?.title}
        icon={isNil(coverPhoto) ? 'maps/directions_car' : undefined}
        image={coverPhoto}
        imageActions={[{icon: 'action/visibility', onClick: () => onCoverPhotoOpen()}]}
        resourceId={EntityResourceIds.vehicle}
        primaryParameter={canSeeVehiclePrice ? primaryParameter : undefined}
        secondaryParameter={canSeeVehiclePrice ? secondaryParameter : undefined}
        tertiaryParameter={canSeeVehiclePrice ? tertiaryParameter : undefined}
        flags={flags}
        parameters={vehicleHeaderParameters}
        actions={actions}
        recordId={props.vehicleId}
        snippets={snippets}
        isLoadingSnippets={isLoading || isAuditLoading}
        controls={controls}
        vehicleConditionEvaluation={
          isSaleVehicle || !isFeatureEnabled(featureFlags.AFTS_SERVICE_VEHICLE_ENTITY_V2)
            ? vehicleConditionEvaluation
            : undefined
        }
        data-testid={suffixTestId('vehicleHeader', props)}
        permissions={{
          tag: {
            isReadable: hasVehicleTagReadPermission,
            isCreatable: hasVehicleTagCreatePermission,
            isUpdatable: hasVehicleTagUpdatePermission,
            isDeletable: hasVehicleTagDeletePermission,
            isAssignable: hasVehicleTagAssignPermission,
            isUnassignable: hasVehicleTagUnassignPermission,
          },
          participation: {
            canAssignUser: canAssignVehicleUser,
            canChangeOwner: canChangeVehicleOwner,
            canUnassignUser: canUnassignVehicleUser,
          },
        }}
        alerts={
          !isSaleVehicle && recallMessage ? [{type: 'banner', message: recallMessage}] : undefined
        }
      />
      <Lightbox
        data-testid={testIds.vehicles.detail('header')}
        controls={lightboxControls}
        images={vehicleImages}
      />
      <Show whenFeatureEnabled={featureFlags.SALES_MOT_VALIDITY}>
        <TechnicalInspectionAlert date={vehicle?.technicalInspectionValidUntil} />
      </Show>
      <Show when={shouldShowRegistrationWarning && registrationAlertData}>
        <Alert
          variant={registrationAlertData?.variant}
          title={registrationAlertData?.title}
          message={registrationAlertData?.description}
        />
      </Show>
    </>
  );
}
