import {Reducer, UnknownAction} from 'redux';

import {REHYDRATE_RTK_QUERY} from '../constants/REHYDRATE_RTK_QUERY';
import {saveToIDB} from '../storage';
import {GenericRTKQState} from '../types/GenericRTKQState';
import {PersistEnhancerConfig} from '../types/PersistEnhancerConfig';
import {sanitizeRtkQueryState} from '../utils/sanitizeRtkQueryState';

/**
 * Creates a reducer enhancer that persists the reducer's state to IndexedDB
 */
export const createPersistReducerEnhancer = <
  S extends GenericRTKQState,
  A extends UnknownAction & {payload?: {reducerPath?: string}},
>(
  config: PersistEnhancerConfig<S>
) => {
  const {key, transforms = [], shouldPersist = () => true, debounceTime = 1000} = config;

  let lastState: S | undefined = undefined;
  let persistTimeout: NodeJS.Timeout | null = null;

  return (reducer: Reducer<S, A>): Reducer<S, A> =>
    (state, action) => {
      // Apply the reducer to get the next state
      const nextState = reducer(state, action);

      if (action.type === REHYDRATE_RTK_QUERY && action.payload?.reducerPath === key) {
        return nextState;
      }

      // Only persist if the state has changed and passes the shouldPersist check
      if (nextState !== lastState && shouldPersist(nextState)) {
        lastState = nextState;

        if (persistTimeout) {
          clearTimeout(persistTimeout);
        }

        persistTimeout = setTimeout(() => {
          let stateToSave = sanitizeRtkQueryState(nextState);
          transforms.forEach((transform) => {
            stateToSave = transform.serialize(stateToSave);
          });

          saveToIDB(key, stateToSave);
        }, debounceTime);
      }

      return nextState;
    };
};
