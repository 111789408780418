import {Alpha3CountryCode} from 'platform/components';

import {always, isNotNil} from 'ramda';

import {omneticApi} from '@dms/api/core';
import {
  BlockUserApiArg,
  BlockUserApiResponse,
  ChangeCurrentUserPasswordApiArg,
  ChangeCurrentUserPasswordApiResponse,
  CreateExternalUserApiArg,
  CreateExternalUserApiResponse,
  CreateUserApiArg,
  CreateUserApiResponse,
  GetCurrentUserInfoApiResponse,
  GetOtherUserSettingsApiArg,
  GetOtherUserSettingsApiResponse,
  GetUserApiArg,
  GetUserApiResponse,
  GetUserAuctionStatsApiResponse,
  GetUserDashboardSettingsApiArg,
  GetUserDashboardSettingsApiResponse,
  GetUserMenuPinsArg,
  GetUserMenuPinsResponse,
  GetUsersApiResponse,
  GetUserSettingsApiResponse,
  GetUserStats,
  GetUserStatsApiResponse,
  InactivityPeriodType,
  MenuItemPin,
  PatchCurrentUserApiArg,
  PatchCurrentUserApiResponse,
  PatchUserApiArg,
  PatchUserApiResponse,
  SetUserMenuPinsArg,
  PutUserDashboardSettingsApiArg,
  PutUserDashboardSettingsApiResponse,
  SetOtherUserSettingsItemApiArg,
  SetOtherUserSettingsItemApiResponse,
  SetUserSettingsItemApiArg,
  SetUserSettingsItemApiResponse,
  UnblockUserApiArg,
  UnblockUserApiResponse,
  UserResponseBody,
  SetUserMenuPinsResponse,
  GetUserAvatarApiResponse,
  GetUserAvatarApiArg,
  TRANSLATED_LANGUAGES,
  COMMON_CURRENCY,
  UserSidebarIconSize,
} from '@dms/api/shared';

export const userApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getCurrentUserInfo: build.query<GetCurrentUserInfoApiResponse, void>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBaseQuery) {
        const userInfoResponse = await fetchWithBaseQuery({url: `/dms/v1/user/info`});

        if (userInfoResponse.error) {
          throw userInfoResponse.error;
        }

        const userSettingsResponse = await fetchWithBaseQuery({url: `/dms/v1/user/settings`});

        if (userSettingsResponse.error) {
          throw userSettingsResponse.error;
        }

        const userInfo = userInfoResponse.data as UserResponseBody;
        const userSettings = userSettingsResponse.data as GetUserSettingsApiResponse;

        const data: GetCurrentUserInfoApiResponse = Object.assign({}, userInfo, {
          settings: {
            currency: (userSettings?.sourcingCurrency?.value ??
              COMMON_CURRENCY.czk) as COMMON_CURRENCY,
            insurerNumber: userSettings?.insurerNumber?.value as string | undefined,
            customId: userSettings?.customId?.value as string | undefined,
            branch: (userSettings?.activeBranch?.value ?? '') as string,
            country: (userSettings?.sourcingBuyerCountry?.value ?? '') as Alpha3CountryCode,
            language: (userSettings?.selectedLanguage?.value ??
              TRANSLATED_LANGUAGES.english) as TRANSLATED_LANGUAGES,
            inactivityPeriod: (userSettings?.inactivityPeriod?.value ?? '') as InactivityPeriodType,
            sidebarIconSize: (userSettings?.sidebarIconSize?.value ??
              'default') as UserSidebarIconSize,
          },
        });

        return {data};
      },
      providesTags: ['CurrentUser'],
    }),
    getUserStats: build.query<GetUserStats, void>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBaseQuery) {
        const [userAuctionStatsResponse, userStatsResponse] = await Promise.all([
          fetchWithBaseQuery({url: `/dms/v1/user/user-auction-stats`}),
          fetchWithBaseQuery({url: `/dms/v1/user/user-stats`}),
        ]);

        if (userAuctionStatsResponse.error) {
          throw userAuctionStatsResponse.error;
        }
        if (userStatsResponse.error) {
          throw userStatsResponse.error;
        }

        const userAuctionStats = userAuctionStatsResponse.data as GetUserAuctionStatsApiResponse;
        const userStats = userStatsResponse.data as GetUserStatsApiResponse;

        const data = Object.assign({}, {userAuctionStats, userStats});

        return {data};
      },
      providesTags: ['User'],
    }),
    createExternalUser: build.mutation<CreateExternalUserApiResponse, CreateExternalUserApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/user/external`,
        method: 'POST',
        body: queryArg.createExternalUserRequestBody,
      }),
    }),
    setUserSettingsItem: build.mutation<SetUserSettingsItemApiResponse, SetUserSettingsItemApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/user/settings/${queryArg.settingKey}`,
        method: 'PUT',
        body: queryArg.userSettingRequestBody,
      }),
      invalidatesTags: ['CurrentUser'],
    }),
    blockUser: build.mutation<BlockUserApiResponse, BlockUserApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/user/${queryArg.userId}/block`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, {userId}) => [{type: 'User', id: userId}, 'Users'],
    }),
    changeCurrentUserPassword: build.mutation<
      ChangeCurrentUserPasswordApiResponse,
      ChangeCurrentUserPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/user/me/password`,
        method: 'PATCH',
        body: queryArg.changeCurrentUserPasswordRequestBody,
      }),
    }),
    createUser: build.mutation<CreateUserApiResponse, CreateUserApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/user`,
        method: 'POST',
        body: queryArg.createUserRequestBody,
      }),
    }),
    getUsers: build.query<GetUsersApiResponse, void>({
      query: () => ({
        url: `/dms/v1/user`,
      }),
      providesTags: ['Users'],
    }),
    getUser: build.query<GetUserApiResponse, GetUserApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/user/${queryArg.id}`,
      }),
      providesTags: (result, error, {id}) => [{type: 'User', id}],
    }),
    patchCurrentUser: build.mutation<PatchCurrentUserApiResponse, PatchCurrentUserApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/user/me`,
        method: 'PATCH',
        body: queryArg.updateCurrentUserRequestBody,
      }),
      invalidatesTags: ['CurrentUser'],
    }),
    patchUser: build.mutation<PatchUserApiResponse, PatchUserApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/user/${queryArg.userId}`,
        method: 'PATCH',
        body: queryArg.updateUserRequestBody,
      }),
      invalidatesTags: (result, error, {userId}) => [{type: 'User', id: userId}, 'CurrentUser'],
    }),
    getOtherUserSettings: build.query<GetOtherUserSettingsApiResponse, GetOtherUserSettingsApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/user/${queryArg.userId}/settings`,
      }),
      providesTags: (result, error, {userId}) => [{type: 'OtherUserSettings', id: userId}],
    }),
    setOtherUserSettingsItem: build.mutation<
      SetOtherUserSettingsItemApiResponse,
      SetOtherUserSettingsItemApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/user/${queryArg.userId}/settings/${queryArg.settingKey}`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, {userId}) => [
        'CurrentUser',
        {type: 'OtherUserSettings', id: userId},
      ],
    }),
    unblockUser: build.mutation<UnblockUserApiResponse, UnblockUserApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/user/${queryArg.userId}/unblock`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, {userId}) => [{type: 'User', id: userId}, 'Users'],
    }),
    getUserMenuPins: build.query<GetUserMenuPinsResponse, GetUserMenuPinsArg>({
      query: () => ({
        url: `/dms/v1/web-app-user/pins`,
      }),
      providesTags: ['UserMenuPins', 'User'],
      transformResponse: (response: MenuItemPin[] | undefined) =>
        response ? response.map((i) => i?.item).filter(isNotNil) : [],
    }),
    pinUserMenuItem: build.mutation<Array<MenuItemPin>, Required<MenuItemPin>>({
      query: (queryArg) => ({
        url: `/dms/v1/web-app-user/pin`,
        method: 'PUT',
        body: queryArg,
      }),
      async onQueryStarted({item}, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          userApi.util.updateQueryData('getUserMenuPins', undefined, (draft) => [...draft, item])
        );
        await queryFulfilled.catch(patchResult.undo);
      },
    }),
    unpinUserMenuItem: build.mutation<Array<MenuItemPin>, Required<MenuItemPin>>({
      query: (queryArg) => ({
        url: `/dms/v1/web-app-user/unpin`,
        method: 'PUT',
        body: queryArg,
      }),
      async onQueryStarted({item}, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          userApi.util.updateQueryData('getUserMenuPins', undefined, (draft) =>
            draft.filter((pinnedItem) => pinnedItem !== item)
          )
        );
        await queryFulfilled.catch(patchResult.undo);
      },
    }),
    setUserMenuPins: build.mutation<SetUserMenuPinsResponse, SetUserMenuPinsArg>({
      query: (queryArg) => ({
        url: `/dms/v1/web-app-user/pins`,
        method: 'PUT',
        body: queryArg,
      }),
      async onQueryStarted({items: pins}, {dispatch, queryFulfilled}) {
        const newOrderedPins = pins.map(({item: pinId}) => pinId).filter(isNotNil);

        const patchResult = dispatch(
          userApi.util.updateQueryData('getUserMenuPins', undefined, always(newOrderedPins))
        );
        await queryFulfilled.catch(patchResult.undo);
      },
    }),
    getUserDashboardSettings: build.query<
      GetUserDashboardSettingsApiResponse,
      GetUserDashboardSettingsApiArg
    >({
      query: () => ({
        url: `/dms/v1/user/dashboard/settings`,
      }),
      providesTags: ['DashboardSettings'],
    }),
    putUserDashboardSettings: build.mutation<
      PutUserDashboardSettingsApiResponse,
      PutUserDashboardSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/user/dashboard/settings`,
        method: 'PUT',
        body: queryArg,
      }),
      invalidatesTags: ['DashboardSettings'],
    }),
    getUserAvatarUrl: build.query<GetUserAvatarApiResponse, GetUserAvatarApiArg>({
      query: ({userId}) => ({
        url: `/dms/v1/user/${userId}/avatar`,
        responseHandler: async (response) => {
          const blob = await response.blob();
          return URL.createObjectURL(blob);
        },
      }),
      providesTags: ['EmployeePhoto'],
    }),
  }),
});

export const selectCurrentUserInfo = userApi.endpoints.getCurrentUserInfo.select();

export const {
  useGetUserAvatarUrlQuery,
  useLazyGetUserAvatarUrlQuery,
  useGetCurrentUserInfoQuery,
  useLazyGetCurrentUserInfoQuery,
  useGetUserStatsQuery,
  useCreateExternalUserMutation,
  useSetUserSettingsItemMutation,
  useBlockUserMutation,
  useChangeCurrentUserPasswordMutation,
  useCreateUserMutation,
  useGetUsersQuery,
  useGetUserQuery,
  useLazyGetUserQuery,
  usePatchCurrentUserMutation,
  usePatchUserMutation,
  useUnblockUserMutation,
  useGetUserMenuPinsQuery,
  useSetUserMenuPinsMutation,
  usePinUserMenuItemMutation,
  useUnpinUserMenuItemMutation,
  useGetOtherUserSettingsQuery,
  useSetOtherUserSettingsItemMutation,
  useGetUserDashboardSettingsQuery,
  usePutUserDashboardSettingsMutation,
} = userApi;
