import {Card, FormControl, Option} from 'platform/components';
import {VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {GetWarehouseResponse, GetWarehousesResponse} from '@dms/api/metadaWarehouse';
import {BaseArticle, GetArticleResponse} from '@dms/api/metadaWarehouseArticle';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {GeneralInformationTabBasicInformationForm} from './GeneralInformationTabBasicInformationForm';
import {GeneralInformationTabUnitsForm} from './GeneralInformationTabUnitsForm';

interface OverviewGeneralInformationTabProps extends TestIdProps {
  articleId: string;
  article: GetArticleResponse;
  control: FormControl<BaseArticle>;
  formApi: UseFormReturn<BaseArticle>;
  manufacturerId: string;
  warehouse: GetWarehouseResponse;
  warehouses: GetWarehousesResponse;
  leafId: string | Nullish;
  leafIdChange: (leafId: string | Nullish) => void;
  presetDispensingUnits: Option[] | Nullish;
  arePresetDispensingUnitsLoading: boolean;
}

export function GeneralInformationTab(props: OverviewGeneralInformationTabProps) {
  return (
    <Card>
      <VStack data-testid={suffixTestId('articleCatalogs', props)} width="100%">
        <GeneralInformationTabBasicInformationForm
          articleId={props.articleId}
          control={props.control}
          manufacturerId={props.manufacturerId}
          warehouseNumber={props.warehouse.warehouseNumber}
          warehouses={props.warehouses}
          isRemovable={props.article.isRemovable}
          leafId={props.leafId}
          leafIdChange={props.leafIdChange}
        />

        <GeneralInformationTabUnitsForm
          formApi={props.formApi}
          control={props.control}
          article={props.article}
          presetDispensingUnits={props.presetDispensingUnits}
          arePresetDispensingUnitsLoading={props.arePresetDispensingUnitsLoading}
          data-testid={suffixTestId('units', props)}
        />
      </VStack>
    </Card>
  );
}
