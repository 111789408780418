import {Option} from 'platform/components';

import {defaultTo, isNil} from 'ramda';

import {GetMarketingCodesResponse} from '@dms/api/metadaWarehouseMarketingCode';

import {Nullish} from 'shared';

export const getOptionsFromMarketingCodes = (
  marketingCodes: GetMarketingCodesResponse | Nullish
): Option[] => {
  if (isNil(marketingCodes)) {
    return [];
  }

  return marketingCodes.map((marketingCode) => ({
    label: defaultTo('', marketingCode.name),
    value: marketingCode.id,
  }));
};
