import {z} from 'zod';

import i18n from '@dms/i18n';

export const importFormSchema = z.object({
  fileId: z.string().nonempty({message: i18n.t('errors.field_is_required')}),
  fileType: z.string().nonempty({message: i18n.t('errors.field_is_required')}),
});

export type ImportFormValue = z.infer<typeof importFormSchema>;
