import {Button, DataStatus, Flag, FormControl} from 'platform/components';
import {Box, HStack, VStack, Text, Heading} from 'platform/foundation';
import {useFormatCurrency, useFormatNumber} from 'platform/locale';

import {useWatch} from 'react-hook-form';

import {defaultTo, isNil, isNotNil, not} from 'ramda';

import {
  GetDirectSaleLabourItemCalculatePriceRequest,
  useGetDirectSaleLabourItemCalculatePriceQuery,
  useLazyGetDirectSaleLabourItemCalculatePriceQuery,
} from '@dms/api/metadaWarehouseDirectSaleItemLabour';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {
  EMPTY_PLACEHOLDER,
  Nullish,
  TestIdProps,
  isNotNilOrZero,
  suffixTestId,
  useDebouncedValue,
} from 'shared';

import {LabourMechanic, EditLabourItemForm} from '../../../../types/EditLabourItemForm';

interface LabourItemPriceProps extends TestIdProps {
  control: FormControl<EditLabourItemForm>;
  isEditingDisabled: boolean;
  directSaleId: string;
  itemId: string;
  onPriceChange: (currentPrices: LabourItemCurrentPrices) => void;
}

export type LabourItemCurrentPrices = {
  sellingPricePerUnit: number;
  isCustomPrice: boolean;
  isDiscountEnabled: boolean;
  isDoNotApplyDiscount: boolean;
};

const DEBOUNCE_DELAY = 500;

export function RequestTabLabourItemPrice(props: LabourItemPriceProps) {
  const formatCurrency = useFormatCurrency();
  const formatNumber = useFormatNumber();

  const formValues = useWatch({control: props.control});

  const debouncedFormValues = useDebouncedValue(formValues, DEBOUNCE_DELAY);

  const isDiscountRateValid =
    isNil(debouncedFormValues.discount?.discountRate) ||
    (debouncedFormValues.discount.discountRate <= 100 &&
      debouncedFormValues.discount.discountRate >= 0);

  const isQuantityValid = isNotNilOrZero(debouncedFormValues.quantity);

  const requestArgs: GetDirectSaleLabourItemCalculatePriceRequest = {
    directSaleId: props.directSaleId,
    itemId: props.itemId,
    body: {
      quantity: debouncedFormValues.quantity,
      workType: debouncedFormValues.workType,
      sellingPricePerUnit: debouncedFormValues.sellingPricePerUnit,
      isCustomPrice: debouncedFormValues.isCustomPrice,
      vatType: debouncedFormValues.vatType,
      assignMechanics: debouncedFormValues.assignMechanics
        ?.filter((mechanic: LabourMechanic) => isNotNil(mechanic.id))
        .map((mechanic: LabourMechanic, index: number) => ({
          id: mechanic.id,
          isDefault: index === 0,
          costCenterId: mechanic.costCenterId,
          amount: mechanic.amount,
        })),
      discount: {
        isDoNotApplyDiscount: debouncedFormValues.discount?.isDoNotApplyDiscount,
        isDiscountEnable: debouncedFormValues.discount?.isDiscountEnable,
        discountSourceType: debouncedFormValues.discount?.discountSourceType,
        discountRate: debouncedFormValues.discount?.isDiscountEnable
          ? defaultTo(0, debouncedFormValues.discount.discountRate) / 100
          : 0,
      },
      getCurrentPrice: false,
    },
  };

  const labourItemPrices = useGetDirectSaleLabourItemCalculatePriceQuery(requestArgs, {
    skip: isNil(debouncedFormValues) || not(isDiscountRateValid) || not(isQuantityValid),
  });

  const [calculatePrice, calculatePriceQuery] = useLazyGetDirectSaleLabourItemCalculatePriceQuery();

  const customCurrencyFormat = (amount: number | Nullish, currency: string | Nullish) => {
    if (isNil(amount) || isNil(currency)) {
      return EMPTY_PLACEHOLDER;
    }

    return formatCurrency(amount, currency, 2);
  };

  const marginTotalPercentage =
    defaultTo(0, labourItemPrices?.data?.marginTotal?.marginPercentage) * 100;

  const doesMarginExist = labourItemPrices?.data?.marginTotal?.isMarginExisting;

  const isMarginUnderMinimum = labourItemPrices?.data?.marginTotal?.isMarginUnderMinimum;

  return (
    <DataStatus
      isLoading={labourItemPrices?.isLoading}
      isError={labourItemPrices?.isError}
      minHeight={15}
    >
      <VStack spacing={4}>
        <HStack spacing={4}>
          <Box flex={1}>
            <VStack spacing={1}>
              <Text color="secondary" size="xSmall">
                {i18n.t('general.labels.purchasePrice')}
              </Text>
              <Heading size={5} data-testid={suffixTestId('purchasePriceWithoutVat', props)}>
                {customCurrencyFormat(
                  labourItemPrices?.data?.purchaseTotalPrice?.withoutVat?.amount,
                  labourItemPrices?.data?.purchaseTotalPrice?.withoutVat?.currency
                )}
              </Heading>
              <Text
                color="secondary"
                size="xSmall"
                data-testid={suffixTestId('purchasePriceWithVat', props)}
              >
                {`${customCurrencyFormat(
                  labourItemPrices?.data?.purchaseTotalPrice?.withVat?.amount,
                  labourItemPrices?.data?.purchaseTotalPrice?.withVat?.currency
                )} ${i18n.t('general.labels.withVat')}`}
              </Text>
            </VStack>
          </Box>

          <Box flex={1}>
            <VStack spacing={1} align="flex-end">
              <Text color="secondary" size="xSmall">
                {i18n.t('entity.warehouse.labels.marginTotalUnitPercent')}
              </Text>

              <HStack spacing={1} align="center">
                <Heading
                  size={5}
                  color={isMarginUnderMinimum ? 'danger' : 'success'}
                  data-testid={suffixTestId('marginWithoutVat', props)}
                >
                  {`${customCurrencyFormat(
                    labourItemPrices?.data?.marginTotal?.withoutVat?.amount,
                    labourItemPrices?.data?.marginTotal?.withoutVat?.currency
                  )}/${customCurrencyFormat(
                    labourItemPrices?.data?.marginPerUnit?.withoutVat?.amount,
                    labourItemPrices?.data?.marginPerUnit?.withoutVat?.currency
                  )}`}
                </Heading>
                <Flag
                  label={
                    doesMarginExist
                      ? `${formatNumber(marginTotalPercentage, 2)} %`
                      : EMPTY_PLACEHOLDER
                  }
                  colorScheme={isMarginUnderMinimum ? 'red' : 'green'}
                  isSubtle
                  size="small"
                  data-testid={suffixTestId('marginPercentage', props)}
                />
              </HStack>

              <Text
                color="secondary"
                size="xSmall"
                data-testid={suffixTestId('marginWithVat', props)}
              >
                {`${customCurrencyFormat(
                  labourItemPrices?.data?.marginTotal?.withVat?.amount,
                  labourItemPrices?.data?.marginTotal?.withVat?.currency
                )}/${customCurrencyFormat(
                  labourItemPrices?.data?.marginPerUnit?.withVat?.amount,
                  labourItemPrices?.data?.marginPerUnit?.withVat?.currency
                )} ${i18n.t('general.labels.withVat')}`}
              </Text>
            </VStack>
          </Box>

          <Box flex={1}>
            <VStack spacing={1} align="flex-end">
              <Text color="secondary" size="xSmall">
                {i18n.t('general.labels.sellingPrice')}
              </Text>
              <Heading size={5} data-testid={suffixTestId('sellingPriceWithoutVat', props)}>
                {customCurrencyFormat(
                  labourItemPrices?.data?.sellingTotalPrice?.withoutVat?.amount,
                  labourItemPrices?.data?.sellingTotalPrice?.withoutVat?.currency
                )}
              </Heading>
              <Text
                color="secondary"
                size="xSmall"
                data-testid={suffixTestId('sellingPriceWithVat', props)}
              >
                {`${customCurrencyFormat(
                  labourItemPrices?.data?.sellingTotalPrice?.withVat?.amount,
                  labourItemPrices?.data?.sellingTotalPrice?.withVat?.currency
                )} ${i18n.t('general.labels.withVat')}`}
              </Text>
            </VStack>
          </Box>
        </HStack>

        <HStack>
          <Button
            isDisabled={props.isEditingDisabled}
            isLoading={calculatePriceQuery.isLoading}
            variant="link"
            leftIcon="navigation/refresh"
            title={i18n.t('entity.warehouse.actions.getCurrentPrice')}
            onClick={() =>
              calculatePrice({...requestArgs, body: {...requestArgs.body, getCurrentPrice: true}})
                .unwrap()
                .then((result) => {
                  props.onPriceChange({
                    sellingPricePerUnit: result.sellingPricePerUnit.amount,
                    isCustomPrice: result.isCustomPrice,
                    isDiscountEnabled: result.itemsDiscounts.isDiscountEnable,
                    isDoNotApplyDiscount: result.itemsDiscounts.isDoNotApplyDiscount,
                  });
                })
                .catch(handleApiError)
            }
            data-testid={suffixTestId('actions.getCurrentPrice', props)}
          />
        </HStack>
      </VStack>
    </DataStatus>
  );
}
