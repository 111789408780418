import {add} from 'date-fns';
import {Form, DataStatus} from 'platform/components';
import {Show} from 'platform/foundation';
import {match} from 'ts-pattern';
import {object} from 'yup';

import {useCallback} from 'react';

import {always} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {
  AftersalesPaymentMethod,
  useGetCheckoutByIdQuery,
  useGetCheckoutPaymentByIdQuery,
  useGetCheckoutPaymentIssueRestrictionQuery,
  usePatchCheckoutPaymentMutation,
  usePostCheckoutPaymentIssueMutation,
} from '@dms/api/metadaAftersalesCheckout';
import {useGetCustomerPaymentQuery} from '@dms/api/metadaCustomerPayment';

import {
  buildArray,
  getApiDateString,
  parseDate,
  precisionCalculation,
  RequiredTestIdProps,
  suffixTestId,
  useDebouncedCallback,
  yupDate,
  yupNumber,
  yupString,
} from 'shared';

import {DEFAULT_CURRENCY, DEFAULT_FOREIGN_CURRENCY} from '../../../constants/currency';
import {useBank} from '../../../hooks/useBank';
import {handleApiError} from '../../../utils/handleApiError';
import {CheckoutPaymentFormType} from '../types/CheckoutPaymentFormType';
import {CheckoutPaymentFormBody} from './CheckoutPaymentFormBody';
import {PaymentOverview} from './PaymentOverview';

interface CheckoutPaymentFormProps extends RequiredTestIdProps {
  paymentId: string;
  checkoutId: string;
  customerId: string;
  onAddAnotherDepositClick?: () => Promise<unknown>;
  onRemoveDepositClick?: () => Promise<unknown>;
  isSubmitButtonDisabled?: boolean;
  disallowedPaymentMethods?: AftersalesPaymentMethod[];
  defaultPaymentType?: AftersalesPaymentMethod;
  isForeignCurrencyAllowed?: boolean;
}

export function CheckoutPaymentForm(props: CheckoutPaymentFormProps) {
  const {getBankAccountByNumber, bankAccountOptions} = useBank();

  const {
    data: customerPayment,
    isFetching: isCustomerPaymentFetching,
    isError: isCustomerPaymentError,
  } = useGetCustomerPaymentQuery({customerId: props.customerId});

  const {
    data: paymentData,
    isLoading: isPaymentLoading,
    isError: isPaymentError,
  } = useGetCheckoutPaymentByIdQuery({
    checkoutId: props.checkoutId,
    paymentId: props.paymentId,
  });

  const {
    data: checkoutData,
    isLoading: isCheckoutLoading,
    isError: isCheckoutError,
  } = useGetCheckoutByIdQuery({checkoutId: props.checkoutId});

  const {
    data: issueRestrictions,
    isLoading: isIssueRestrictionsLoading,
    isError: isIssueRestrictionsError,
  } = useGetCheckoutPaymentIssueRestrictionQuery(
    {
      checkoutId: props.checkoutId,
      paymentId: props.paymentId,
    },
    {refetchOnMountOrArgChange: true}
  );

  const [patchCheckoutPayment] = usePatchCheckoutPaymentMutation();
  const [postCheckoutPaymentIssue] = usePostCheckoutPaymentIssueMutation();

  const handleSaveChanges = useCallback(
    (data: CheckoutPaymentFormType) => {
      const bankAccount = getBankAccountByNumber(data.bankAccount);

      const foreignCurrencyAmount = parseFloat(
        precisionCalculation.divide(data.amount, data.exchangeRate ?? 1).toFixed(2)
      );

      return patchCheckoutPayment({
        checkoutId: props.checkoutId,
        paymentId: props.paymentId,
        body: {
          paymentAmount: {
            amount: data.amount,
            currency:
              paymentData?.paymentAmount?.currency ?? checkoutData?.currency ?? DEFAULT_CURRENCY,
          },
          foreignCurrencyPaymentAmount: checkoutData?.isForeignCurrencyPayment
            ? {
                amount: foreignCurrencyAmount,
                currency:
                  paymentData?.foreignCurrencyPaymentAmount?.currency ?? DEFAULT_FOREIGN_CURRENCY,
              }
            : undefined,
          dateOfTaxableSupply: getApiDateString(data.dateOfTaxableSupply),
          dueDate: getApiDateString(data.dueDate),
          issueDate: getApiDateString(data.issueDate),
          method: data.method,
          bankAccount: bankAccount
            ? {
                currency: bankAccount.currency ?? checkoutData?.currency ?? DEFAULT_CURRENCY,
                isPrimary: !!bankAccount.isPrimary,
                bankName: bankAccount.bankName,
                iban: bankAccount.iban,
                name: bankAccount.accountName,
                number: bankAccount.accountNumber,
                swift: bankAccount.swift,
              }
            : undefined,
          cashRegisterId: data.cashRegisterId,
          due: data.due,
          exchangeRate: data.exchangeRate ? String(data.exchangeRate) : undefined,
          note: data.note,
        },
      })
        .unwrap()
        .catch(handleApiError);
    },
    [
      checkoutData?.currency,
      checkoutData?.isForeignCurrencyPayment,
      getBankAccountByNumber,
      patchCheckoutPayment,
      paymentData?.foreignCurrencyPaymentAmount?.currency,
      paymentData?.paymentAmount?.currency,
      props.checkoutId,
      props.paymentId,
    ]
  );

  const debouncedSaveChanges = useDebouncedCallback(handleSaveChanges, 1000);

  const handleIssuePayment = async (data: CheckoutPaymentFormType) => {
    await handleSaveChanges(data);

    await postCheckoutPaymentIssue({
      checkoutId: props.checkoutId,
      paymentId: props.paymentId,
      body: {issuedOn: getApiDateString(data.issueDate)},
    })
      .unwrap()
      .catch(handleApiError);
  };

  // Fallback when bank payment is set as default value but not allowed by customer payment settings
  const defaultPaymentMethod = match<[AftersalesPaymentMethod, boolean], AftersalesPaymentMethod>([
    paymentData?.method ?? props?.defaultPaymentType ?? 'CARD',
    !!customerPayment?.allowBankTransfer,
  ])
    .with(['BANK_TRANSFER', false], always('CARD'))
    .otherwise(([paymentType]) => paymentType);

  const disallowedPaymentMethods = buildArray(props.disallowedPaymentMethods ?? []).whenNot(
    customerPayment?.allowBankTransfer,
    'BANK_TRANSFER'
  );

  const defaultBankAccount =
    bankAccountOptions.length === 1 ? bankAccountOptions[0]?.value : undefined;

  const isLoading =
    isPaymentLoading ||
    isCustomerPaymentFetching ||
    isCheckoutLoading ||
    isIssueRestrictionsLoading;
  const isError =
    isPaymentError || isCustomerPaymentError || isCheckoutError || isIssueRestrictionsError;

  return (
    <DataStatus isLoading={isLoading} isError={isError} minHeight={100}>
      <Show when={paymentData?.paymentState === 'CONCEPT'}>
        <Form<CheckoutPaymentFormType>
          mode="onSubmit"
          defaultValues={{
            amount: paymentData?.paymentAmount.amount ?? undefined,
            bankAccount: paymentData?.bankAccount?.id ?? defaultBankAccount,
            note: paymentData?.note ?? undefined,
            method: defaultPaymentMethod,
            due: customerPayment?.invoiceMaturity ?? paymentData?.due ?? 0,
            dueDate: isNotNil(customerPayment?.invoiceMaturity)
              ? add(new Date(), {days: customerPayment?.invoiceMaturity})
              : parseDate(paymentData?.dueDate ?? new Date().toISOString()),
            issueDate: parseDate(paymentData?.issuedOn ?? new Date().toISOString()),
            dateOfTaxableSupply: parseDate(
              paymentData?.dateOfTaxableSupply ?? new Date().toISOString()
            ),
            currency: paymentData?.foreignCurrencyPaymentAmount?.currency,
            exchangeRate: paymentData?.exchangeRate
              ? parseFloat(paymentData.exchangeRate)
              : undefined,
          }}
          schema={formSchema}
          onChange={debouncedSaveChanges}
          onSubmit={handleIssuePayment}
        >
          {(control, formApi) => (
            <CheckoutPaymentFormBody
              control={control}
              formApi={formApi}
              checkoutId={props.checkoutId}
              disallowedPaymentMethods={disallowedPaymentMethods}
              isSubmitButtonDisabled={props.isSubmitButtonDisabled}
              issueButtonBlockers={issueRestrictions?.map((restriction) => restriction.message)}
              onSaveChanges={debouncedSaveChanges}
              payment={paymentData!}
              onAddAnotherDepositClick={props.onAddAnotherDepositClick}
              onRemoveDepositClick={props.onRemoveDepositClick}
              isForeignCurrencyAllowed={props.isForeignCurrencyAllowed}
              totalAmount={checkoutData?.totalPrice?.withVat?.amount ?? 0}
              data-testid={suffixTestId('body', props)}
            />
          )}
        </Form>
      </Show>
      <Show when={paymentData?.paymentState === 'PENDING' || paymentData?.paymentState === 'PAID'}>
        {paymentData && (
          <PaymentOverview
            payment={paymentData}
            checkoutId={props.checkoutId}
            onAddAnotherDepositClick={props.onAddAnotherDepositClick}
            data-testid={suffixTestId('overview', props)}
          />
        )}
      </Show>
    </DataStatus>
  );
}

const formSchema = object({
  amount: yupNumber.required(),
  method: yupString.required(),
  issueDate: yupDate.required(),
  bankAccount: yupString.when('method', {
    is: 'BANK_TRANSFER',
    then: yupString.required().nullable(),
    otherwise: yupString.optional().nullable(),
  }),
  cashRegisterId: yupString.when('method', {
    is: 'CASH',
    then: yupString.required().nullable(),
    otherwise: yupString.optional().nullable(),
  }),
  due: yupNumber.required(),
  dueDate: yupDate.required(),
  dateOfTaxableSupply: yupDate.required(),
  note: yupString.optional(),
  exchangeRate: yupNumber.when('currency', {
    is: isNotNil,
    then: yupNumber.required().min(0),
    otherwise: yupNumber,
  }),
  currency: yupString,
});
