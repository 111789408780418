import {Box, Heading, HStack} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {useCallback} from 'react';

import {defaultTo, mergeAll} from 'ramda';

import {BasePrice} from '@dms/api/shared';
import i18n from '@dms/i18n';
import {DEFAULT_CURRENCY} from '@dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {DataGrid, QueryFilterObject} from 'features/datagrid';

import {WAREHOUSE_GRID_CODES} from '../../../../../constants/warehouseGridCodes';
import {useWarehouseParams} from '../../../../../hooks/useWarehouseParams';

interface ListOfItemsTabProps extends RequiredTestIdProps {
  totalValue: BasePrice | Nullish;
}

export function ListOfItemsTab(props: ListOfItemsTabProps) {
  const {costCorrectionId} = useWarehouseParams();

  const formatCurrency = useFormatCurrency();

  const queryModifier = useCallback(
    (filter: QueryFilterObject) =>
      mergeAll([filter, {issueNoteValueCorrectionId: costCorrectionId}]),
    [costCorrectionId]
  );

  const totalValue = formatCurrency(
    defaultTo(0, props.totalValue?.amount),
    defaultTo(DEFAULT_CURRENCY, props.totalValue?.currency),
    2
  );
  return (
    <>
      <Box height="95%">
        <DataGrid
          gridCode={WAREHOUSE_GRID_CODES.warehouseCostCorrectionItems}
          queryModifier={queryModifier}
          data-testid={suffixTestId('items', props)}
        />
      </Box>
      <HStack height={10} align="center" justify="space-between">
        <Heading size={5}>{i18n.t('general.labels.totalValue')}</Heading>
        <Heading size={5}>{totalValue}</Heading>
      </HStack>
    </>
  );
}
