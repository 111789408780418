import {Button, Dropdown, DropdownItem} from 'platform/components';

import {GetAssignmentsResponse, useDeleteAssignmentMutation} from '@dms/api/assignment';
import i18n from '@dms/i18n';
import {getNaturalPersonFullName} from '@dms/shared';

import {TestIdProps} from 'shared';

interface AssignUsersProps extends TestIdProps {
  assignment: GetAssignmentsResponse[number];
}

export function AssignUsers(props: AssignUsersProps) {
  const [deleteAssignment] = useDeleteAssignmentMutation();
  const menuList = [
    {
      label: i18n.t('general.labels.unassign'),
      callBack: () => {
        if (props.assignment) {
          deleteAssignment(props.assignment.id);
        }
      },
    },
  ];

  const userName =
    props.assignment?.user &&
    (getNaturalPersonFullName(props.assignment?.user) || props.assignment?.user?.email || '');

  return (
    <Dropdown dropdownControl={<Button title={userName} variant="ghostLink" size="small" />}>
      {menuList?.map(({callBack, label}) => (
        <DropdownItem label={label} key={`menu-item-${label}`} onClick={callBack} />
      ))}
    </Dropdown>
  );
}
