import {useAnimatedPopper} from 'platform/components';
import {Center, Clickable, Hide, Icon} from 'platform/foundation';
import styled from 'styled-components';

import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';

import {MenuItemProps} from '@dms/api/shared';
import {featureFlags} from '@dms/feature-flags';

import {composePath, useNavigate} from 'shared';

import {isMenuItemActive} from '../utils/isMenuItemActive';
import {CreateMenuItem} from './CreateMenuItem';
import {MenuItem} from './MenuItem';

function MenuIconButton(props: MenuItemProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const [isActive] = useActiveMenuItem(props.path);
  const {openPopper, referenceRef, popperProps, Popper, closePopper, isOpen} = useAnimatedPopper({
    placement: 'right-start',
  });

  const handleRedirect = () => {
    const isSamePath = location.pathname === props.path;

    if (isSamePath) {
      return;
    }

    navigate(composePath(props.path, {isSearchParamsPreserved: false}));
  };

  const onMouseWheelClick = () => {
    window.open(composePath(props.path, {isSearchParamsPreserved: false}));
  };

  return (
    <Clickable
      onClick={handleRedirect}
      onMouseWheelClick={onMouseWheelClick}
      onMouseEnter={openPopper}
      onMouseLeave={closePopper}
      data-testid={`layout-menuItemContainer-${props.layoutProps?.title}`}
    >
      <StyledMenuItem
        ref={referenceRef}
        $isOpen={isOpen}
        $isActive={isActive}
        data-testid={`layout-menuItem-${props.layoutProps?.title}`}
      >
        <Center height={12} width={12}>
          <Icon size={6} value={props.layoutProps?.icon} color="palettes.neutral.10.100" />
        </Center>
      </StyledMenuItem>
      <Popper {...popperProps}>
        {props?.content?.map((module) => (
          <MenuItem key={`${module.id}_${module.path}_${module.parentId}`} module={module} />
        ))}
      </Popper>
    </Clickable>
  );
}

type StyledMenuItemProps = {
  $isActive: boolean;
  $isOpen: boolean;
};

const StyledMenuItem = styled.div<StyledMenuItemProps>`
  background-color: ${(props) =>
    props.$isOpen || props.$isActive
      ? props.theme.colors.palettes.blue['70']['100']
      : props.theme.colors.palettes.neutral['900']['100']};
  &:hover {
    background-color: ${(props) => props.theme.colors.palettes.blue['70']['100']};
  }
`;

interface MenuProps {
  menuItems: MenuItemProps[];
  createMenuItems: MenuItemProps[];
  pinnedMenuItems?: (string | undefined)[] | undefined;
}

export function Menu(props: MenuProps) {
  return (
    <MenuContainer>
      {props.menuItems.map((item) => {
        if (
          !props.pinnedMenuItems?.includes(item.id) ||
          (!item.content?.length && item.alwaysShowContent)
        ) {
          return null;
        }

        return (
          <MenuIconButton
            key={`${item.id}_${item.path}`}
            id={item.id}
            path={item.path}
            layoutProps={item.layoutProps}
            content={item.content}
          />
        );
      })}

      <Hide whenFeatureEnabled={featureFlags.SIDEBAR_REMOVE_QUICK_ACTIONS}>
        <CreateMenuItem createMenuItems={props.createMenuItems} />
      </Hide>
    </MenuContainer>
  );
}

function useActiveMenuItem(path: string) {
  const location = useLocation();

  const isActive = useMemo(
    () => isMenuItemActive(location.pathname, path),
    [location.pathname, path]
  );

  return [isActive];
}

const MenuContainer = styled.div`
  height: 100%;
  background-color: ${({theme}) => theme.colors.palettes.neutral[900][100]};
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;
