import {useApiEnums} from '../../../../../hooks/useApiEnums';
import {ApiEnum} from '../../../../../types/ApiEnum';
import {PropertyName} from '../../../../../utils/getEnumByProperty';
import {FiltersType} from '../../../types/FiltersType';
import {useFilters} from '../../FiltersContext/FiltersContext';

export type StringBasedFilterName =
  | 'sellerCountry'
  | 'sellerType'
  | 'fuelType'
  | 'transmission'
  | 'bodyType'
  | 'drive'
  | 'features';

export const getStringBasedTags = (
  filterName: StringBasedFilterName,
  apiEnum: ApiEnum,
  filters: FiltersType,
  onUpdateFilters: (path: string[], value: any) => void
) => {
  const filterValues = filters[filterName];

  return (
    filterValues?.map((value) => ({
      label: apiEnum.find(({key}) => key === value)?.label,
      onDelete: () =>
        onUpdateFilters(
          [filterName],
          filterValues?.filter((item) => value !== item)
        ),
    })) ?? []
  );
};

export const useGetStringBasedTags = () => {
  const {filters, onUpdateFilters} = useFilters();
  const {getEnum} = useApiEnums({flat: true});

  return (filterName: StringBasedFilterName, propertyName: PropertyName) =>
    getStringBasedTags(filterName, getEnum(propertyName), filters, onUpdateFilters);
};
