import axios from 'axios';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import {i18n} from '@dms/i18n';

import {downloadBlobFile} from './downloadBlobFile';
import {handleApiError} from './handleApiError';

type DownloadDataGridExportProps = {
  exportId: string;
  gridCode: string;
  exportedAt: string;
};

export const downloadDataGridExport = async (props: DownloadDataGridExportProps) => {
  const blob = await axios
    .get(`/v6/data-grid/${props.gridCode}/export/${props.exportId}/download`, {
      responseType: 'arraybuffer',
    })
    .catch(handleApiError);

  if (!blob) {
    return;
  }

  const fileName = i18n.t('general.notifications.labels.datagridExportDownloadFileName', {
    resourceName: props.gridCode,
    dateTime: props.exportedAt,
    fileFormat: match(blob.headers['content-type'] as string)
      .with('text/csv; charset=UTF-8', always('csv'))
      .with('application/pdf', always('pdf'))
      .otherwise(always('xlsx')),
  });

  downloadBlobFile(blob.data, fileName);
};
