import {z} from 'zod';

import {
  AssignedMechanicSchema,
  BasketTooltipSchema,
  PriceWithAndWithoutVatSchema,
  BaseVoidResponseSchema,
} from '@dms/api/shared';

const PartsRequestReturnBasketItemSchema = z.object({
  id: z.string(),
  warehouseId: z.string(),
  name: z.string(),
  manufacturerNumber: z.string(),
  manufacturerId: z.string(),
  quantity: z.number(),
  unit: z.string(),
  dispensingUnit: z.number(),
  quantityEditingAllowed: z.boolean(),
  itemEditingAllowed: z.boolean(),
  unitPrice: PriceWithAndWithoutVatSchema,
  totalPrice: PriceWithAndWithoutVatSchema,
  tooltip: z.array(BasketTooltipSchema),
});
export type PartsRequestReturnBasketItem = z.infer<typeof PartsRequestReturnBasketItemSchema>;

/**
 * GET
 */
export const GetPartsRequestReturnBasketRequestSchema = z.object({
  originEntityHeaderId: z.string(),
});
export type GetPartsRequestReturnBasketRequest = z.infer<
  typeof GetPartsRequestReturnBasketRequestSchema
>;

export const GetPartsRequestReturnBasketResponseSchema = z
  .object({
    requestReturnBasketItem: z.array(PartsRequestReturnBasketItemSchema).optional(),
    requestReturnBasketTotalPrice: PriceWithAndWithoutVatSchema.optional(),
    assignMechanicId: z.string().optional(),
  })
  .nullish();
export type GetPartsRequestReturnBasketResponse = z.infer<
  typeof GetPartsRequestReturnBasketResponseSchema
>;

/**
 * POST
 */
export const PostPartsRequestReturnBasketCheckoutRequestSchema = z.object({
  originEntityHeaderId: z.string(),
  body: z
    .object({
      assignMechanic: AssignedMechanicSchema,
    })
    .optional(),
});
export type PostPartsRequestReturnBasketCheckoutRequest = z.infer<
  typeof PostPartsRequestReturnBasketCheckoutRequestSchema
>;

export const PostPartsRequestReturnBasketCheckoutResponseSchema = BaseVoidResponseSchema;
export type PostPartsRequestReturnBasketCheckoutResponse = z.infer<
  typeof PostPartsRequestReturnBasketCheckoutResponseSchema
>;

/**
 * PATCH
 */
export const PatchPartsRequestReturnBasketItemQuantityRequestSchema = z.object({
  originEntityHeaderId: z.string(),
  requestItemId: z.string(),
  body: z.object({
    quantity: z.number(),
  }),
});
export type PatchPartsRequestReturnBasketItemQuantityRequest = z.infer<
  typeof PatchPartsRequestReturnBasketItemQuantityRequestSchema
>;

export const PatchPartsRequestReturnBasketItemQuantityResponseSchema = BaseVoidResponseSchema;
export type PatchPartsRequestReturnBasketItemQuantityResponse = z.infer<
  typeof PatchPartsRequestReturnBasketItemQuantityResponseSchema
>;

/**
 * DELETE
 */
export const DeletePartsRequestReturnBasketRequestSchema = z.object({
  originEntityHeaderId: z.string(),
});
export type DeletePartsRequestReturnBasketRequest = z.infer<
  typeof DeletePartsRequestReturnBasketRequestSchema
>;

export const DeletePartsRequestReturnBasketResponseSchema = BaseVoidResponseSchema;
export type DeletePartsRequestReturnBasketResponse = z.infer<
  typeof DeletePartsRequestReturnBasketResponseSchema
>;

/**
 * PUT
 */
