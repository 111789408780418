import {Action, Actions, BaseFlagProps, Flags, Parameters} from 'platform/components';
import {Box, Hide, HStack, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {isNotNil} from 'ramda-adjunct';

import i18n from '@dms/i18n';

import {buildArray, Nullish, parseDate, suffixTestId, TestIdProps} from 'shared';

export interface CreateInvoiceHeaderProps extends TestIdProps {
  flags?: BaseFlagProps[];
  actions?: Action[];
  subActions?: Action[];
  createdAt?: string | Nullish;
  author?: string;
  subFlags?: BaseFlagProps[];
}

export function InvoiceDetailSubHeader(props: CreateInvoiceHeaderProps) {
  const formatDateTime = useDateTimeFormatter();
  const createdAt: Date = props.createdAt ? parseDate(props.createdAt) : new Date();

  const invoiceParameters = buildArray<string>()
    .when(
      isNotNil(props.createdAt),
      `${i18n.t('entity.order.createdAt')}: ${formatDateTime('dateShort', createdAt)}`
    )
    .when(isNotNil(props.author), `${i18n.t('general.labels.author')}: ${props.author}`);

  return (
    <VStack>
      <Box padding={4}>
        <VStack>
          <HStack justify="space-between">
            <VStack>
              <HStack spacing={4} align="center">
                <HStack spacing={1}>
                  <Flags
                    flags={props.flags || props.subFlags}
                    data-testid={suffixTestId('flags', props)}
                  />
                </HStack>
                <Parameters
                  size="small"
                  parameters={invoiceParameters}
                  data-testid={suffixTestId('parameters', props)}
                />
              </HStack>
              <Hide when={!props.subFlags?.length || !props.flags?.length}>
                <Box paddingTop={3}>
                  <Flags flags={props.subFlags} data-testid={suffixTestId('subFlags', props)} />
                </Box>
              </Hide>
            </VStack>

            <HStack spacing={4} align="flex-start">
              <VStack justify="flex-end">
                <Actions
                  actions={props.actions || props.subActions}
                  data-testid={props['data-testid']}
                />
                <Hide when={!props.subActions?.length || !props.subActions?.length}>
                  <Actions
                    actions={props.subActions}
                    data-testid={suffixTestId('subActions', props)}
                    align="right"
                  />
                </Hide>
              </VStack>
            </HStack>
          </HStack>
        </VStack>
      </Box>
    </VStack>
  );
}
