import {Dropdown, DropdownItem, DropdownSearchItem} from 'platform/components';
import {Link} from 'platform/foundation';

import {useState} from 'react';

import {filter, includes, toUpper} from 'ramda';

import {BranchResponseBody} from '@dms/api/shared';
import {useGetCurrentUserInfoQuery} from '@dms/api/user';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {NoPermissionTooltip} from '../../NoPermissionTooltip/NoPermissionTooltip';

export interface BranchChangerProps extends TestIdProps {
  recordId: string;
  onChange?: (branchId: string) => Promise<unknown> | void;
  activeBranch?: BranchResponseBody;
  isLoading?: boolean;
  isEditDisabled?: boolean;
  hideTooltip?: boolean;
}

export function BranchChanger(props: BranchChangerProps) {
  const {data: currentUserInfo} = useGetCurrentUserInfoQuery();
  const [searchQuery, setSearchQuery] = useState<string | null>(null);

  const branches = !searchQuery
    ? currentUserInfo?.branches
    : filter(
        (branch) => includes(toUpper(searchQuery), toUpper(branch.name)),
        currentUserInfo?.branches ?? []
      );

  return (
    <NoPermissionTooltip shouldShowTooltip={Boolean(props.isEditDisabled) && !props.hideTooltip}>
      <Dropdown
        hasSearch
        dropdownControl={
          <Link
            size="small"
            leftIcon="maps/place"
            title={props.activeBranch?.name ?? i18n.t('entity.branch.labels.selectedBranch')}
            isDisabled={props.isLoading || props.isEditDisabled}
            data-testid={suffixTestId('branch', props)}
          />
        }
        isDisabled={props.isEditDisabled}
        onClose={() => setSearchQuery(null)}
        data-testid={suffixTestId('branch', props)}
      >
        <DropdownSearchItem value={searchQuery} onChange={setSearchQuery} />
        {branches?.map((branch) => (
          <DropdownItem
            key={branch.id}
            label={branch.name}
            onClick={() => props.onChange?.(branch.id)}
            data-testid={suffixTestId(`branch-${branch.id}`, props)}
          />
        ))}
      </Dropdown>
    </NoPermissionTooltip>
  );
}
