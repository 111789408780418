import {DataStatus} from 'platform/components';

import {FC, PropsWithChildren} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {any, flatten} from 'ramda';
import {isTrue} from 'ramda-adjunct';

import {handleApiError, Main} from '@dms/shared';
import {
  loadVehicleDetailsData,
  useThunkDispatch,
  getComplaintInspections,
  getValidationInspections,
  getNonValidationInspections,
  getAllAuditStructureTemplates,
  InspectionType,
  VehicleInspection,
  getCheckinInspections,
  getHandoverInspections,
  selectCarAudit,
  selectStructures,
  getAuditStructure,
} from '@dms/teas';

import {isDefined, useBoolean, useOnMount} from 'shared';

export const Inspections: FC<PropsWithChildren<any>> = () => {
  const dispatch = useThunkDispatch();

  const {id: vehicleId} = useParams();

  const {loading} = useSelector(selectCarAudit);
  const structures = useSelector(selectStructures);
  const [isLoadingVehicleDetailData, startLoadingVehicleData, stopLoadingVehicleData] =
    useBoolean(false);

  const isAuditLoading = any(isTrue, [
    loading.getComplaintInspections,
    loading.getValidationInspections,
    loading.getNonValidationInspections,
    loading.getCheckinInspections,
    loading.getHandoverInspections,
    loading.getAllAuditStructureTemplates,
    loading.getAuditStructure,
    loading['sales/vehicleDetail/loadCarDetailsHighlightsRequest'],
    loading['sales/vehicleDetail/loadCarDetailsShortcomingsRequest'],
    loading['sales/vehicleDetail/loadCarDetailsVehicleDetailRequest'],
    loading['sales/vehicleDetail/loadPremiumFeatures'],
    loading['vehicleCatalogue/getCustomTenantCatalogue'],
  ]);

  useOnMount(() => {
    if (!isDefined(vehicleId)) {
      return;
    }

    startLoadingVehicleData();
    loadVehicleDetailsData(vehicleId, dispatch).then(() =>
      Promise.all([
        dispatch(
          getComplaintInspections.action({
            requestBody: {vehicleIds: [vehicleId]},
            inspectionType: InspectionType.complaint,
          })
        ).unwrap(),
        dispatch(
          getValidationInspections.action({
            requestBody: {vehicleIds: [vehicleId]},
            inspectionType: InspectionType.validationInspection,
          })
        ).unwrap(),
        dispatch(
          getNonValidationInspections.action({
            requestBody: {vehicleIds: [vehicleId]},
            inspectionType: InspectionType.nonValidationInspection,
          })
        ).unwrap(),
        dispatch(
          getCheckinInspections.action({
            requestBody: {vehicleIds: [vehicleId]},
            inspectionType: InspectionType.checkin,
          })
        ).unwrap(),
        dispatch(
          getHandoverInspections.action({
            requestBody: {vehicleIds: [vehicleId]},
            inspectionType: InspectionType.handover,
          })
        ).unwrap(),
      ])
        .then((response) => {
          const inspections = flatten(response ?? []).sort((a, b) =>
            (a?.updateAt ?? 0) < (b?.updateAt ?? 0) ? 1 : -1
          );
          inspections.forEach((inspection) => {
            if (!structures?.[inspection.id]) {
              dispatch(
                getAuditStructure.action({
                  auditStructureId: inspection.controlStructureId,
                })
              )
                .unwrap()
                .catch(handleApiError);
            }
          });

          dispatch(getAllAuditStructureTemplates.action({}));
        })
        .finally(stopLoadingVehicleData)
    );
  });

  return (
    <Main>
      <DataStatus isLoading={isAuditLoading || isLoadingVehicleDetailData} minHeight={100}>
        <VehicleInspection shouldUseAuditIdForUpdateTemplate={false} />
      </DataStatus>
    </Main>
  );
};
