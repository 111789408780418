/* eslint-disable eag/no-css-property */
import {formatDistanceToNow} from 'date-fns';
import {ColorSchemeType, Flag, Separator} from 'platform/components';
import {
  Box,
  getColorByPath,
  Icon,
  HStack,
  ThemeColorPath,
  VStack,
  Text,
  Show,
} from 'platform/foundation';
import {
  useFormatCurrency,
  DateTimeFormat,
  useLocale,
  convertStringLocaleToDateFns,
} from 'platform/locale';
import {css, useTheme} from 'styled-components';
import {match} from 'ts-pattern';

import {isNotNil} from 'ramda';

import {parseDate, suffixTestId, TestIdProps} from 'shared';

import {Card} from '../types/Card';
import {repeatingGradient} from '../utils/repeatingGradient';

interface KanbanCardProps extends TestIdProps {
  card: Card;
  backgroundColor: ThemeColorPath | null;
  colorStripe: ColorSchemeType;
}

export function KanbanCard(props: KanbanCardProps) {
  const theme = useTheme();
  const locale = useLocale();

  const formatCurrency = useFormatCurrency();

  const backgroundColor = getColorByPath(props.backgroundColor, theme);
  const colorStripe = getColorStripePathByScheme(props.colorStripe);
  const hoverColorStripe = getColorStripePathByScheme(props.colorStripe, true);

  return (
    <div
      css={css`
        border-radius: ${theme.radii[theme.components.Card.borderRadius]};
        box-shadow: ${theme.shadows[theme.components.Card.elevation]};
        background-color: ${backgroundColor ? 'transparent' : theme.colors.palettes.white[10][100]};
        background-image: ${backgroundColor ? `${repeatingGradient(backgroundColor)}` : 'none'};
        border-left: 4px solid ${getColorByPath(colorStripe, theme)};

        &:hover {
          box-shadow: ${theme.shadows.elevation_2};
          border-left: 4px solid ${getColorByPath(hoverColorStripe, theme)};
        }
      `}
      data-testid={suffixTestId('cardWrapper', props)}
    >
      <Box flex={1} padding={2} data-testid={suffixTestId('cardBody', props)}>
        <VStack spacing={1}>
          <Text size="small" color="primary" alternative>
            {props.card.title}
          </Text>
          <Text size="small" color="tertiary">
            {props.card.subTitle}
          </Text>
          <Show when={props.card.flags?.length}>
            <HStack spacing={1}>
              {props.card.flags?.map((flag) => <Flag key={flag.label} {...flag} />)}
            </HStack>
          </Show>
          <Show when={props.card.hasChat || props.card.hasComments || props.card.price}>
            <HStack spacing={1} justify="space-between" align="center">
              <HStack spacing={1}>
                <Show when={props.card.hasChat}>
                  <Icon value="communication/chat" size={3} color="text.tertiary" />
                </Show>
                <Show when={props.card.hasComments}>
                  <Icon value="communication/comment" size={3} color="text.tertiary" />
                </Show>
              </HStack>
              {isNotNil(props.card.price?.amount) && isNotNil(props.card.price?.currency) ? (
                <Text size="small" color="primary" alternative>
                  {formatCurrency(Number(props.card.price.amount), props.card.price.currency)}
                </Text>
              ) : null}
            </HStack>
          </Show>
          <Show when={props.card.date}>
            <Separator spacing={0} />
            <HStack spacing={1}>
              <Text size="xSmall" color="tertiary">
                <DateTimeFormat format="dateShort" value={parseDate(props.card.date)} />
              </Text>
              <Text size="xSmall" color="tertiary">
                (
                {formatDistanceToNow(parseDate(props.card.date), {
                  addSuffix: true,
                  locale: convertStringLocaleToDateFns(locale.language),
                })}
                )
              </Text>
            </HStack>
          </Show>
        </VStack>
      </Box>
    </div>
  );
}

function getColorStripePathByScheme(
  colorScheme: ColorSchemeType,
  isHover: boolean = false
): ThemeColorPath {
  return match<ColorSchemeType, ThemeColorPath>(colorScheme)
    .with('black', () => (isHover ? 'general.black' : 'palettes.neutral.900.100'))
    .with('neutral', () => (isHover ? 'palettes.neutral.500.100' : 'palettes.neutral.300.100'))
    .with('blue', () => 'palettes.blue.70.100')
    .with('teal', () => (isHover ? 'palettes.teal.70.100' : 'palettes.teal.60.100'))
    .with('green', () => (isHover ? 'palettes.green.70.100' : 'palettes.green.60.100'))
    .with('orange', () => (isHover ? 'palettes.orange.70.100' : 'palettes.orange.60.100'))
    .with('yellow', () => (isHover ? 'palettes.yellow.70.100' : 'palettes.yellow.60.100'))
    .with('magenta', () => (isHover ? 'palettes.magenta.70.100' : 'palettes.magenta.60.100'))
    .with('purple', () => (isHover ? 'palettes.purple.70.100' : 'palettes.purple.60.100'))
    .with('red', () => (isHover ? 'palettes.red.70.100' : 'palettes.red.60.100'))
    .exhaustive();
}
