import {Module} from '@dms/api/shared';
import {featureFlags} from '@dms/feature-flags';

import {BigCalendarPlayground} from './pages/BigCalendarPlayground/BigCalendarPlayground';
import {DataGridDev} from './pages/DataGridDev/DataGridDev';
import {DataGridPlayground} from './pages/DataGridPlayground/DataGridPlayground';
import {DynamicUiBasicForm} from './pages/DemoDynamicUi/DynamicUiBasicForm';
import {NotificationPreview} from './pages/DemoNotifications/NotificationPreview';
import {OpenSearchDataGrid} from './pages/OpenSearchDataGrid/OpenSearchDataGrid';

export const DemoModule: Module = {
  id: 'demo-module',
  requiredFeatureFlag: featureFlags.DEMO_PAGE_DATAGRID_V4,
  layoutProps: {
    icon: 'custom/tractor',
    iconV2: 'sidebar/demo',
    title: 'page.demo.datagrid',
  },
  routerProps: {
    path: '/demo/datagrid',
  },
  content: [
    {
      id: 'demo-module-dg',
      routerProps: {
        path: '/demo/datagrid',
        element: <DataGridPlayground />,
      },
      layoutProps: {
        title: 'page.demo.datagrid',
        icon: 'sidebar/demo',
      },
    },
    {
      id: 'demo-openSearch-dg',
      routerProps: {
        path: '/demo/openSearchDatagrid',
        element: <OpenSearchDataGrid />,
      },
      layoutProps: {
        title: 'page.demo.openSearchDatagrid',
        icon: 'sidebar/demo',
      },
    },
    {
      id: 'demo-dev-dg',
      routerProps: {
        path: '/demo/devDatagrid',
        element: <DataGridDev />,
      },
      layoutProps: {
        title: 'page.demo.devDatagrid',
        icon: 'sidebar/demo',
      },
    },
    {
      id: 'demo-dynamic-UI',
      routerProps: {
        path: '/demo/demoDynamicUI',
        element: <DynamicUiBasicForm />,
      },
      layoutProps: {
        title: 'page.demo.demoDynamicUI',
        icon: 'sidebar/demo',
      },
    },
    {
      id: 'demo-notification-preview',
      routerProps: {
        path: '/demo/notificationPreview',
        element: <NotificationPreview />,
      },
      layoutProps: {
        title: 'page.demo.notificationPreview',
        icon: 'sidebar/demo',
      },
    },
    {
      id: 'demo-big-calendar',
      routerProps: {
        path: '/demo/big-calendar',
        element: <BigCalendarPlayground />,
      },
      layoutProps: {
        title: 'page.demo.bigCalendar',
        icon: 'action/schedule',
      },
    },
  ],
};
