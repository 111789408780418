import {Actions, Card, DataStatus, Form, FormField, FormSubmitHandler} from 'platform/components';
import {VStack, Grid, Show, Space, Hide, HStack} from 'platform/foundation';
import {object} from 'yup';

import {isFalsy} from 'ramda-adjunct';

import {
  useGetBasketLabourItemLabourApiQuery,
  usePatchBasketItemLabourApiMutation,
} from '@dms/api/metadaWorkshopBasket';
import {
  useGetServicePackageItemLabourQuery,
  usePatchServicePackageItemLabourMutation,
} from '@dms/api/metadaWorkshopServicePackage';
import i18n from '@dms/i18n';
import {handleApiError, useGetVatRatesOptions, useWorkTypeOptions} from '@dms/shared';

import {Nullish, suffixTestId, TestIdProps, yupNumber, yupString} from 'shared';

interface EditWorkItemProps extends TestIdProps {
  itemId: string;
  context: 'basket' | 'request';
  servicePackageId?: string;
  onAfterSubmit?: () => void;
  onClose: () => void;
}

type FormType = {
  name: string;
  number: string;
  isPriceUnitManual: boolean;
  vatType: string;
  quantity: number;
  purchasePricePerUnit: number;
  sellingPricePerUnit: number;
  workType: string;
  isDoNotApplyDiscount: boolean;
};

export function EditWorkItem(props: EditWorkItemProps) {
  const {
    data: basketItemData,
    isLoading: isBasketItemLoading,
    isError: isBasketItemError,
  } = useGetBasketLabourItemLabourApiQuery(
    {basketItemId: props.itemId},
    {skip: props.context !== 'basket'}
  );
  const {
    data: requestItemData,
    isLoading: isRequestItemLoading,
    isError: isRequestItemError,
  } = useGetServicePackageItemLabourQuery(
    {servicePackageItemId: props.itemId, servicePackageId: props.servicePackageId ?? ''},
    {skip: props.context !== 'request' || !props.servicePackageId}
  );

  const {getOptionsWithSelectedValue} = useWorkTypeOptions();

  const [patchBasketItemLabour] = usePatchBasketItemLabourApiMutation();
  const [patchServicePackageItemLabour] = usePatchServicePackageItemLabourMutation();

  const [vatOptions] = useGetVatRatesOptions({fullLabel: true});

  const data =
    props.context === 'basket'
      ? basketItemData?.labourBasketItem
      : requestItemData?.servicePackageItem;

  const onSubmit: FormSubmitHandler<FormType> = (formData) => {
    const request =
      props.context === 'basket'
        ? patchBasketItemLabour({
            basketItemId: props.itemId,
            body: {
              ...formData,
              unit: data?.unit ?? '',
              isUnitPriceWithVat: false,
            },
          })
        : patchServicePackageItemLabour({
            servicePackageItemId: props.itemId,
            servicePackageId: props.servicePackageId ?? '',
            body: {
              ...formData,
              unit: data?.unit ?? '',
              isUnitPriceWithVat: false,
            },
          });

    return request.unwrap().then(props.onAfterSubmit).then(props.onClose).catch(handleApiError);
  };

  const workTypeOptions = getOptionsWithSelectedValue(data?.workType);

  const isLoading = isBasketItemLoading || isRequestItemLoading;
  const isError = isBasketItemError || isRequestItemError;

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<FormType>
        schema={formSchema(data?.priceType)}
        defaultValues={{
          isPriceUnitManual: !!data?.isPriceUnitManual,
          name: data?.name ?? undefined,
          number: data?.number ?? undefined,
          purchasePricePerUnit: data?.purchasePriceUnitWithoutVat ?? undefined,
          quantity: data?.quantity ?? undefined,
          sellingPricePerUnit: data?.priceUnitWithoutVat ?? undefined,
          vatType: data?.vatType ?? undefined,
          workType: data?.workType ?? undefined,
          isDoNotApplyDiscount: data?.isDoNotApplyDiscount ?? undefined,
        }}
        onSubmit={onSubmit}
      >
        {(control, formApi) => (
          <VStack spacing={4}>
            <FormField
              control={control}
              name="name"
              type="text"
              label={i18n.t('general.labels.name')}
              data-testid={suffixTestId('name', props)}
            />
            <Grid columns={2}>
              <FormField
                control={control}
                name="number"
                type="text"
                data-testid={suffixTestId('number', props)}
                label={i18n.t('entity.workshopServicePackages.labels.number')}
              />
              <FormField
                control={control}
                name="workType"
                type="choice"
                menuInPortal
                options={workTypeOptions}
                label={i18n.t('entity.workshopServicePackages.labels.workType')}
                data-testid={suffixTestId('name', props)}
              />
            </Grid>
            <Card variant="inlineGrey">
              <Grid spacing={4} columns={4}>
                <Hide when={data?.priceType === 'SVCPRICETYPE_COOPERATION'}>
                  <FormField
                    control={control}
                    name="quantity"
                    type="integer"
                    minStepperValue={1}
                    label={i18n.t('entity.workshopServicePackages.labels.quantity')}
                    data-testid={suffixTestId('quantity', props)}
                  />
                </Hide>
                <Show when={formApi.watch('isPriceUnitManual')}>
                  <Show
                    when={[
                      'SVCPRICETYPE_CALCULATION_PRICE',
                      'SVCPRICETYPE_DIRECT_PRICE',
                      'SVCPRICETYPE_COOPERATION',
                    ].includes(data?.priceType ?? '')}
                  >
                    <FormField
                      control={control}
                      name="purchasePricePerUnit"
                      type="number"
                      minStepperValue={0}
                      label={i18n.t('entity.workshopServicePackages.labels.purchasePricePerUnit')}
                      data-testid={suffixTestId('purchasePricePerUnit', props)}
                    />
                  </Show>
                  <FormField
                    control={control}
                    name="sellingPricePerUnit"
                    type="number"
                    minStepperValue={0}
                    label={i18n.t('entity.workshopServicePackages.labels.sellingPricePerUnit')}
                    data-testid={suffixTestId('sellingPricePerUnit', props)}
                  />
                  <FormField
                    control={control}
                    name="vatType"
                    type="choice"
                    options={vatOptions}
                    menuInPortal
                    label={i18n.t('general.labels.vatType')}
                    data-testid={suffixTestId('vatType', props)}
                  />
                </Show>
              </Grid>
              <Hide
                when={
                  data?.priceType === 'SVCPRICETYPE_COOPERATION' &&
                  !formApi.watch('isPriceUnitManual')
                }
              >
                <Space vertical={4} />
              </Hide>
              <HStack align="center" justify="space-between">
                <FormField
                  control={control}
                  name="isPriceUnitManual"
                  type="switch"
                  label={i18n.t('entity.workshopServicePackages.labels.customPrice')}
                  data-testid={suffixTestId('isPriceUnitManual', props)}
                />
                <FormField
                  control={control}
                  name="isDoNotApplyDiscount"
                  type="checkbox"
                  isDisabled={isFalsy(data?.isDoNotApplyDiscountEditable)}
                  label={i18n.t('general.labels.doNotApplyDiscount')}
                  data-testid={suffixTestId('isDoNotApplyDiscount', props)}
                />
              </HStack>
            </Card>
            <Actions
              align="right"
              actions={[
                {
                  type: 'button',
                  title: i18n.t('general.actions.discard'),
                  onClick: props.onClose,
                  variant: 'secondary',
                },
                {
                  type: 'form-button',
                  title: i18n.t('general.actions.saveChanges'),
                  buttonType: 'submit',
                  control,
                },
              ]}
              data-testid={suffixTestId('actions', props)}
            />
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}

const formSchema = (
  priceType:
    | 'SVCPRICETYPE_TIME_NORM'
    | 'SVCPRICETYPE_CALCULATION_PRICE'
    | 'SVCPRICETYPE_DIRECT_PRICE'
    | 'SVCPRICETYPE_COOPERATION'
    | Nullish
) =>
  object({
    name: yupString.required(),
    quantity: priceType !== 'SVCPRICETYPE_COOPERATION' ? yupNumber.required().min(1) : yupNumber,
    purchasePricePerUnit: ['SVCPRICETYPE_CALCULATION_PRICE', 'SVCPRICETYPE_DIRECT_PRICE'].includes(
      priceType ?? ''
    )
      ? yupNumber.when('isPriceUnitManual', {
          is: true,
          then: yupNumber.required(),
          otherwise: yupNumber,
        })
      : yupNumber,
    sellingPricePerUnit: yupNumber.when('isPriceUnitManual', {
      is: true,
      then: yupNumber.required(),
      otherwise: yupNumber,
    }),
    vatType: yupString.when('isPriceUnitManual', {
      is: true,
      then: yupString.required(),
      otherwise: yupString,
    }),
  });
