import {
  Action,
  Alert,
  Button,
  closeCurrentDialog,
  DataStatus,
  openDialog,
} from 'platform/components';
import {Box, Heading, Hide, HStack, Right, Show, Space, VStack} from 'platform/foundation';
import {match, Pattern} from 'ts-pattern';

import {useNavigate} from 'react-router-dom';

import {always, not} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {UserRole} from '@dms/api/metadaInsurance';
import i18n from '@dms/i18n';
import {businessCaseRoutes} from '@dms/routes';
import {Main} from '@dms/shared';

import {useRequiredParams, composePath} from 'shared';

import {RecommendCustomerForm} from '../../components/RecommendCustomerForm';
import {useInsuranceComparison} from '../../hooks/useInsuranceComparison';
import {useInsuranceData} from '../../hooks/useInsuranceData';
import {BecomeSellerCard} from './components/BecomeSellerCard';
import {InsuranceCard} from './components/InsuranceCard';
import {InsuranceOfferCard} from './components/InsuranceOfferCard';
import {LogoStack} from './components/LogoStack';
import {TipperCard} from './components/TipperCard';

export function BusinessCaseInsurance() {
  const navigate = useNavigate();
  const {id: businessCaseId} = useRequiredParams();

  const insuranceData = useInsuranceData(businessCaseId);

  const {compareOffers, isComparisonLoading} = useInsuranceComparison(
    businessCaseId,
    insuranceData
  );

  const recommendCustomer = () => {
    openDialog(
      <RecommendCustomerForm
        insuranceData={insuranceData}
        businessCaseId={businessCaseId}
        onDone={closeCurrentDialog}
        onCancel={closeCurrentDialog}
      />,
      {
        title: i18n.t('entity.insurance.actions.recommendCustomer'),
      }
    );
  };

  const actions = match<[UserRole, boolean], Action[] | undefined>([
    insuranceData.role,
    insuranceData.isFinished,
  ])
    .with([UserRole.SELLER, Pattern.any], () => [
      {
        type: 'button',
        title: i18n.t('entity.insurance.actions.compareOffers'),
        leftIcon: 'action/view_carousel',
        onClick: compareOffers,
        isLoading: isComparisonLoading,
      },
    ])
    .with([UserRole.TIPPER, false], () => [
      {
        type: 'button',
        title: i18n.t('entity.insurance.actions.recommendCustomer'),
        leftIcon: 'action/thumb_up',
        onClick: recommendCustomer,
      },
    ])
    .with([UserRole.TIPPER, true], () => [
      {
        type: 'button',
        title: i18n.t('entity.insurance.actions.recommendedCustomer'),
        leftIcon: 'action/check_circle',
        iconColor: 'severity.success',
        variant: 'outlined',
      },
    ])
    .otherwise(always(undefined));

  const hasMissingFields =
    not(insuranceData.isLoading) &&
    (isNilOrEmpty(insuranceData.insuranceVehicle?.vin) ||
      isNilOrEmpty(insuranceData.insuranceVehicle?.firstRegistrationOnDay) ||
      isNilOrEmpty(insuranceData.insuranceVehicle?.firstRegistrationOnDay) ||
      isNilOrEmpty(insuranceData.insuranceVehicle?.firstRegistrationOnMonth) ||
      isNilOrEmpty(insuranceData.insuranceVehicle?.firstRegistrationOnYear) ||
      isNilOrEmpty(insuranceData.insuranceVehicle?.sellingPrice));

  return (
    <Main
      heading={i18n.t('entity.insurance.labels.insuranceOffer')}
      actions={not(insuranceData.isLoading) && not(insuranceData.isError) ? actions : undefined}
    >
      <Heading size={5} alternative color="secondary">
        {i18n.t('entity.insurance.labels.offerFor', {vehicle: insuranceData.vehicleTitle})}
      </Heading>
      <Space vertical={6} />

      <Show when={hasMissingFields}>
        <Alert
          type="banner"
          variant="warning"
          title={i18n.t('entity.insurance.errors.fillMandatoryFields')}
        />
      </Show>
      <Hide when={hasMissingFields}>
        <DataStatus
          minHeight={80}
          isLoading={insuranceData.isLoading}
          isError={insuranceData.isError}
          errorMessage={i18n.t('entity.insurance.errors.vehicleNotInsurable')}
        >
          <InsuranceOfferCard insurance={insuranceData.insuranceOffer} />
          <Hide when={insuranceData.role === UserRole.SELLER}>
            <Space vertical={8} />
            <Heading size={3} alternative>
              {i18n.t('entity.insurance.labels.startSelling')}
            </Heading>
            <Space vertical={4} />
            <Heading size={5} alternative color="secondary">
              {i18n.t('entity.insurance.labels.sellDirectlyFromOmnetic')}
            </Heading>
            <Space vertical={6} />
            <InsuranceCard>
              <Box padding={6}>
                <VStack spacing={6}>
                  <HStack spacing={6}>
                    <BecomeSellerCard insuranceData={insuranceData} />
                    <TipperCard insuranceData={insuranceData} onRecommend={recommendCustomer} />
                  </HStack>
                  <LogoStack />
                </VStack>
              </Box>
            </InsuranceCard>
          </Hide>
        </DataStatus>
      </Hide>
      <Space vertical={4} />
      <Right>
        <Button
          variant="outlined"
          rightIcon="hardware/keyboard_arrow_right"
          title={i18n.t('general.actions.continue')}
          onClick={() =>
            navigate(composePath(businessCaseRoutes.checkout, {params: {id: businessCaseId}}))
          }
        />
      </Right>
    </Main>
  );
}
