import {Avatar, Dropdown, DropdownItem, Separator} from 'platform/components';
import {Box, Center, HStack, Heading, Show, Space, Text, VStack} from 'platform/foundation';

import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';

import {isNil} from 'ramda';

import {getWorkspaceFromUri, buildUrl} from '@dms/api/core';
import {useLogoutMutation} from '@dms/api/public';
import {selectCurrentUserInfo, useGetUserAvatarUrlQuery} from '@dms/api/user';
import {browserStorageKey} from '@dms/config';
import i18n from '@dms/i18n';
import {loginRoutes, myProfileRoutes, settingsRoutes} from '@dms/routes';
import {EMPTY_PLACEHOLDER, handleApiError, usePermissions, useUserFullName} from '@dms/shared';

import {useBoolean} from 'shared';

import {HeaderButtonWrapper} from '../../HeaderButtonWrapper/HeaderButtonWrapper';

export function MyProfileAndSettings() {
  const navigate = useNavigate();
  const location = useLocation();
  const [hasReadSettingsPermission] = usePermissions({permissionKeys: ['readSettings']});

  const [logoutUser, {isLoading: isLoggingOut}] = useLogoutMutation();
  const {data: userInfo} = useSelector(selectCurrentUserInfo);
  const {data: userAvatarUrl} = useGetUserAvatarUrlQuery(
    {userId: userInfo?.id ?? ''},
    {skip: isNil(userInfo)}
  );

  const userFullName = useUserFullName();

  const [isOpen, setOpen, setClosed] = useBoolean();

  const handleLogout = () => {
    const accessToken = sessionStorage.getItem(browserStorageKey.ACCESS_TOKEN);
    const {workspace} = getWorkspaceFromUri();

    logoutUser({workspace, accessToken})
      .unwrap()
      .then(() => {
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem(browserStorageKey.LAST_KNOWN_LANGUAGE, i18n.language);
        window.location.href = buildUrl(loginRoutes.login);
      })
      .catch(handleApiError);
  };

  const onMenuItemClick = (url: string) => {
    localStorage.setItem(browserStorageKey.BACKUP_URL, location.pathname + location.search);
    navigate(url);
  };

  if (!userInfo) {
    return null;
  }

  return (
    <Dropdown
      onOpen={setOpen}
      onClose={setClosed}
      closeOnBlur
      closeOnSelect
      dropdownControl={<AvatarIconControl isOpen={isOpen} userAvatarUrl={userAvatarUrl} />}
      data-testid="layout-header-myProfileAndSettings-dropdown"
    >
      <Box padding={2}>
        <Box minWidth={75}>
          <HStack align="center">
            <Avatar
              data-testid="layout-header-myProfileAndSettings"
              name={userFullName ?? EMPTY_PLACEHOLDER}
              size="default"
              imageUrl={userAvatarUrl}
              variant="circle"
            />
            <Space horizontal={4} />
            <VStack>
              <Heading
                alternative
                headingLevel={2}
                size={4}
                data-testid="layout-header-myProfileAndSettings-name"
              >
                {userFullName ?? EMPTY_PLACEHOLDER}
              </Heading>
              <Text
                size="small"
                color="secondary"
                data-testid="layout-header-myProfileAndSettings-email"
              >
                {userInfo.email}
              </Text>
            </VStack>
          </HStack>
        </Box>
        <Space vertical={4} />
        <DropdownItem
          label={i18n.t('entity.user.labels.myProfile')}
          onClick={() => onMenuItemClick(myProfileRoutes.personalInformation)}
          data-testid="layout-header-myProfileAndSettings-personalInformation"
          leftIcon="social/person"
        />
        <Show when={hasReadSettingsPermission}>
          <DropdownItem
            label={i18n.t('page.settings.labels.title')}
            onClick={() => onMenuItemClick(settingsRoutes.userManagement)}
            data-testid="layout-header-myProfileAndSettings-userManagement"
            leftIcon="action/settings"
          />
        </Show>
        <Separator spacing={2} />

        <DropdownItem
          label={i18n.t('general.actions.logout')}
          onClick={handleLogout}
          isLoading={isLoggingOut}
          data-testid="layout-header-myProfileAndSettings-logout"
          leftIcon="action/exit_to_app"
        />
      </Box>
    </Dropdown>
  );
}

interface AvatarIconControlProps {
  isOpen: boolean;
  userAvatarUrl?: string;
}
const AvatarIconControl = (props: AvatarIconControlProps) => {
  const userFullName = useUserFullName();

  return (
    <HeaderButtonWrapper
      $isOpen={props.isOpen}
      data-testid="layout-header-myProfileAndSettings-button"
    >
      <Center width="100%" height="100%">
        <Avatar
          size="xSmall"
          variant="circle"
          imageUrl={props.userAvatarUrl}
          name={userFullName ?? EMPTY_PLACEHOLDER}
        />
      </Center>
    </HeaderButtonWrapper>
  );
};
