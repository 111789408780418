import {captureMessage} from '@sentry/browser';
import {showNotification} from 'platform/components';

import {environment} from '@dms/environment';

const isDev = environment.ENV_TYPE === 'dev';
const isStage = environment.ENV_TYPE === 'stage';

/**
 * Logs an error message based on environment type.
 * In dev/stage environments, logs to console and shows notification.
 * In production, sends error to Sentry.
 * @param errorMessage - The error message to log
 */
export function logError(errorMessage: string) {
  if (isDev || isStage) {
    console.error(errorMessage);

    showNotification.error(errorMessage);

    return;
  }

  captureMessage('updatePreset', (scope) =>
    scope.setLevel('error').setExtras({
      userDescription: errorMessage,
    })
  );
}
