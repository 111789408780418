import {BaseFlagProps} from 'platform/components';
import {match} from 'ts-pattern';

import {always, isNil} from 'ramda';

import {SaleVehicleBuyingState} from '@dms/api/saleVehiclePhoto';
import i18n from '@dms/i18n';

import {Nullish} from 'shared';

import {EMPTY_PLACEHOLDER} from '../constants/placeholders';

export const getVehicleBuyingStateFlagProps = (state: SaleVehicleBuyingState | Nullish) => {
  if (isNil(state)) {
    return null;
  }

  return match<SaleVehicleBuyingState, BaseFlagProps>(state)
    .with(
      'not-set',
      always({
        isSubtle: true,
        colorScheme: 'neutral',
        label: i18n.t('entity.saleVehicle.labels.notSet'),
      })
    )
    .with(
      'buying',
      always({
        isSubtle: true,
        colorScheme: 'blue',
        label: i18n.t('entity.saleVehicle.labels.buying'),
      })
    )
    .with(
      'for-pricing',
      always({
        isSubtle: true,
        colorScheme: 'yellow',
        label: i18n.t('entity.saleVehicle.labels.forPricing'),
      })
    )
    .with(
      'priced',
      always({
        isSubtle: true,
        colorScheme: 'yellow',
        label: i18n.t('entity.saleVehicle.labels.priced'),
      })
    )
    .with(
      'not-bought',
      always({
        isSubtle: true,
        colorScheme: 'red',
        label: i18n.t('entity.saleVehicle.labels.notBought'),
      })
    )
    .with(
      'bought',
      always({
        isSubtle: true,
        colorScheme: 'green',
        label: i18n.t('entity.saleVehicle.labels.bought'),
      })
    )
    .with(
      'returned-to-customer',
      always({
        isSubtle: true,
        colorScheme: 'red',
        label: i18n.t('entity.saleVehicle.labels.returnedToCustomer'),
      })
    )
    .with(
      'to-buy',
      always({
        colorScheme: 'green',
        label: i18n.t('entity.saleVehicle.labels.toBuy'),
        isSubtle: false,
      })
    )
    .with(
      'contract',
      always({
        colorScheme: 'blue',
        label: i18n.t('entity.saleVehicle.labels.buyingContract'),
        isSubtle: false,
      })
    )
    .otherwise(
      always({
        isSubtle: true,
        colorScheme: 'neutral',
        label: EMPTY_PLACEHOLDER,
      })
    );
};
