import {Avatar, DropdownItem, IconButton, useTranslationContext} from 'platform/components';
import {Box, Clickable, Hide, HStack, Show, Stack, useDevice} from 'platform/foundation';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {BigCalendarUser} from '../types/BigCalendarUser';
import {BigCalendarUsersProps} from '../types/BigCalendarUsersProps';

interface HeaderUserControllProps extends Required<BigCalendarUsersProps>, RequiredTestIdProps {
  onDialogOpen: () => void;
}

export function HeaderUserControll(props: HeaderUserControllProps) {
  const t = useTranslationContext();
  const device = useDevice();

  const handleUserVisibilityChange = (user: BigCalendarUser) => {
    props.onUserControlChange(
      props.selectedUsers.map((selectedUser) =>
        selectedUser.id === user.id
          ? {...selectedUser, isHidden: !selectedUser.isHidden}
          : selectedUser
      )
    );
  };

  return (
    <Stack
      direction={['column', 'row']}
      spacing={2}
      align="center"
      data-testid={suffixTestId('headerUserControll', props)}
    >
      <Show onMobile>
        <DropdownItem
          data-testid={suffixTestId('headerUserControll-dropdownItem', props)}
          leftIcon="social/person_add"
          label={t('general.presets.userShare')}
          onClick={props.onDialogOpen}
        />
      </Show>
      <Show when={props.selectedUsers.length > 1} onMobile={false}>
        <HStack spacing={2} width="100%" justify="flex-start">
          {props.selectedUsers.map((user, userIndex) => (
            <Box
              key={`big-calendar-header-user-control-${user.id}`}
              opacity={user.isHidden ? 0.2 : 1}
            >
              <Clickable
                data-testid={suffixTestId(`headerUserControll-avatar-[${userIndex}]`, props)}
                isDisabled={
                  device === 'mobile' ||
                  (!user.isHidden &&
                    props.selectedUsers.filter((user) => !user.isHidden).length === 1)
                }
                onClick={(event) => {
                  event.stopPropagation();
                  handleUserVisibilityChange(user);
                }}
              >
                <Avatar
                  key={`big-calendar-header-user-control-${user.id}`}
                  textColor="white"
                  color={user.color}
                  name={user.fullName}
                  size="small"
                />
              </Clickable>
            </Box>
          ))}
        </HStack>
      </Show>
      <Hide onMobile>
        <IconButton
          data-testid={suffixTestId('headerUserControll-iconButton', props)}
          icon={props.selectedUsers.length > 1 ? 'navigation/more_vert' : 'social/person_add'}
          priority="default"
          onClick={props.onDialogOpen}
        />
      </Hide>
    </Stack>
  );
}
