import {insert, isNotNil} from 'ramda';

import {DrillDownLevelName} from '@dms/api/sourcing';

import {Nullish} from 'shared';

import {LevelConfiguration} from '../types';
import {getLevelConfigurations} from './getLevelConfigurations';

// Merge modelFamily with model for the configurator
export const getConfigurationForConfigurator = (levelConfigurations: LevelConfiguration[]) =>
  levelConfigurations.filter(
    (levelConfiguration) => levelConfiguration.name !== 'model_family_group'
  );
export const getConfigurationFromConfigurator = (
  levelConfigurations: LevelConfiguration[],
  hiddenLevels: DrillDownLevelName[] | Nullish
) => {
  const modelFamilyLevelConfiguration = getLevelConfigurations(hiddenLevels).find(
    (levelConfiguration) => levelConfiguration.name === 'model_family_group'
  );
  const modelLevelConfigurationIndex = levelConfigurations.findIndex(
    (levelConfiguration) => levelConfiguration.name === 'model'
  );

  if (isNotNil(modelFamilyLevelConfiguration)) {
    return insert(modelLevelConfigurationIndex, modelFamilyLevelConfiguration, levelConfigurations);
  } else {
    return levelConfigurations;
  }
};
