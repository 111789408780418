import {
  Action,
  Button,
  Card,
  closeDialog,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  openDialog,
  RadioOption,
  Separator,
  showNotification,
} from 'platform/components';
import {Align, Box, Grid, Heading, Hide, Show, Text, VStack} from 'platform/foundation';
import {DeepPartial} from 'utility-types';

import {useEffect, useState} from 'react';

import {isNil, isNotNil} from 'ramda';
import {isArray, isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  BaseDirectSale,
  DiscountWorkRate,
  PutDirectSaleDiscountRequest,
  useGetDirectSaleDiscountQuery,
  usePutDirectSaleDiscountMutation,
} from '@dms/api/metadaWarehouseDirectSale';
import {
  DeleteCustomerContractRequest,
  PostCustomerContractRequest,
  useDeleteDirectSaleCustomerContractMutation,
  useGetCustomerContractQuery,
  usePutCustomerContractMutation,
} from '@dms/api/metadaWarehouseDirectSaleCustomerContract';
import {DictionaryOption} from '@dms/api/metadaWorkshopCodelist';
import {useGetTenantQuery} from '@dms/api/tenant';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {CustomerContractModal, handleApiError, useWorkTypeOptions} from '@dms/shared';

import {CurrencyCodeType, RequiredTestIdProps, suffixTestId, useBoolean} from 'shared';

import {DiscountTabsChangeDiscountType} from './DiscountsTabChangeDiscountType';
import {DiscountsTabCustomerContract} from './DiscountsTabCustomerContract';
import {DiscountsTabDeactivateContract} from './DiscountsTabDeactivateContract';
import {DiscountsTabSavingCustomDiscounts} from './DiscountsTabSavingCustomDiscounts';
import {DiscountsTabWorkRateListField} from './DiscountsTabWorkRateListField';

type DiscountType = 'percentage' | 'workRate';

type DiscountPercentage = number;

type WorkDiscount = {
  discountType: DiscountType;
  discountValue: DiscountWorkRate[] | DiscountPercentage;
};

type MaterialDiscountType = 'discountToSalePrice' | 'markupToPurchasePrice';

type MaterialDiscount = {
  discountType: MaterialDiscountType;
  discountValue: number;
};

type CustomDiscountForm = {
  workDiscount: WorkDiscount;
  materialDiscount: MaterialDiscount;
};

const ASSIGN_CONTRACT_ID = 'assignContractId';
const DEACTIVATE_CONTRACT_ID = 'deactivateContractId';
const SAVING_CUSTOM_DISCOUNT_ID = 'savingCustomDiscountId';
const CHANGE_DISCOUNT_TYPE_ID = 'changeDiscountTypeId';

interface DiscountsTabProps extends RequiredTestIdProps {
  directSale?: BaseDirectSale;
  isEditingDisabled: boolean;
}

const PERCENTAGE_BASE = 100;
export function DiscountsTab(props: DiscountsTabProps) {
  const [contractDetailId, setContractDetailId] = useState<string>();

  const [
    isDiscountOnWorkOpen,
    setDiscountOnWorkOpen,
    setDiscountOnWorkClose,
    toggleDiscountOnWork,
  ] = useBoolean(false);
  const [
    isDiscountOnMaterialOpen,
    setDiscountOnMaterialOpen,
    setDiscountOnMaterialClose,
    toggleDiscountOnMaterial,
  ] = useBoolean(false);

  const {
    data: tenantData,
    isLoading: isTenantLoading,
    isError: isTenantError,
  } = useGetTenantQuery();
  const {workTypes} = useWorkTypeOptions();
  const {
    data: directSaleDiscount,
    isLoading: isDirectSaleDiscountLoading,
    isError: isDirectSaleDiscountError,
  } = useGetDirectSaleDiscountQuery(
    {
      directSaleId: props.directSale?.id as string,
    },
    {skip: !props.directSale?.id}
  );

  const {
    data: customerContract,
    isLoading: isCustomerContractLoading,
    isError: isLoadingisCustomerContractError,
  } = useGetCustomerContractQuery(
    {
      directSaleId: props.directSale?.id as string,
    },
    {skip: !props.directSale?.id}
  );

  const currency = props.directSale?.totalPrice?.withVat?.currency;

  const [putDirectSaleDiscount] = usePutDirectSaleDiscountMutation();
  const [putCustomerContract] = usePutCustomerContractMutation();
  const [deleteCustomerContract] = useDeleteDirectSaleCustomerContractMutation();

  const isLoading = isDirectSaleDiscountLoading || isCustomerContractLoading || isTenantLoading;
  const isError = isDirectSaleDiscountError || isLoadingisCustomerContractError || isTenantError;

  const hasDiscountToSalePrice = isNotNil(
    directSaleDiscount?.materialDiscount?.discountToSalePrice
  );
  const hasMarkupToPurchasePrice = isNotNil(
    directSaleDiscount?.materialDiscount?.markupToPurchasePrice
  );

  useEffect(() => {
    if (isNil(directSaleDiscount)) {
      return;
    }

    if (
      isNotNil(directSaleDiscount.workDiscount?.percentageDiscount) ||
      isNotNil(directSaleDiscount.workDiscount?.discountedWorkRate)
    ) {
      setDiscountOnWorkOpen();
    }

    if (hasDiscountToSalePrice || hasMarkupToPurchasePrice) {
      setDiscountOnMaterialOpen();
    }
  }, [directSaleDiscount]);

  const defaultValues: DeepPartial<CustomDiscountForm> = {
    workDiscount: {
      discountType: isNilOrEmpty(directSaleDiscount?.workDiscount?.discountedWorkRate)
        ? 'percentage'
        : 'workRate',
      discountValue:
        directSaleDiscount?.workDiscount?.discountedWorkRate ??
        (directSaleDiscount?.workDiscount?.percentageDiscount ?? 0) * PERCENTAGE_BASE,
    },
    materialDiscount: {
      discountType: hasMarkupToPurchasePrice ? 'markupToPurchasePrice' : 'discountToSalePrice',
      discountValue: hasMarkupToPurchasePrice
        ? Number(directSaleDiscount?.materialDiscount?.markupToPurchasePrice) * PERCENTAGE_BASE
        : Number(directSaleDiscount?.materialDiscount?.discountToSalePrice) * PERCENTAGE_BASE,
    },
  };

  const onCustomDiscountsSubmit = (isCustomDiscountApplied: boolean, data: CustomDiscountForm) => {
    const workDiscounts: DiscountWorkRate[] | undefined = isArray(data.workDiscount.discountValue)
      ? (data.workDiscount.discountValue as DiscountWorkRate[])
          .filter((discountWorkRate) => isNotNilOrEmpty(discountWorkRate.workType))
          .map((discountWorkRate) => ({
            workType: discountWorkRate.workType,
            workRateWithoutVat: {
              amount: discountWorkRate.workRateWithoutVat.amount,
              currency: tenantData?.currency as string,
            },
          }))
      : undefined;

    const putDirectSaleDiscountRequest: PutDirectSaleDiscountRequest = {
      directSaleId: props.directSale?.id as string,
      body: {
        directSaleDiscount: {
          workDiscount: isDiscountOnWorkOpen
            ? {
                percentageDiscount:
                  data.workDiscount.discountType === 'percentage'
                    ? (data.workDiscount.discountValue as number) / PERCENTAGE_BASE
                    : undefined,
                discountedWorkRate:
                  data.workDiscount.discountType === 'workRate' ? workDiscounts : undefined,
              }
            : null,
          materialDiscount: isDiscountOnMaterialOpen
            ? {
                discountToSalePrice:
                  data.materialDiscount.discountType === 'discountToSalePrice'
                    ? data.materialDiscount.discountValue / PERCENTAGE_BASE
                    : undefined,
                markupToPurchasePrice:
                  data.materialDiscount.discountType === 'markupToPurchasePrice'
                    ? data.materialDiscount.discountValue / PERCENTAGE_BASE
                    : undefined,
              }
            : null,
        },
        isDiscountApply: isCustomDiscountApplied,
      },
    };

    return putDirectSaleDiscount(putDirectSaleDiscountRequest)
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);
  };

  const handleFormSubmit: FormSubmitHandler<CustomDiscountForm> = (data, _, reset) =>
    new Promise<void>((resolve) => {
      const submit = (isCustomDiscountApplied: boolean) =>
        onCustomDiscountsSubmit(isCustomDiscountApplied, data)
          .then(() => closeDialog(SAVING_CUSTOM_DISCOUNT_ID))
          .then(() => reset(undefined, {keepValues: true}))
          .then(() => resolve());

      const discard = () => {
        closeDialog(SAVING_CUSTOM_DISCOUNT_ID);
        resolve();
      };

      openDialog(
        <DiscountsTabSavingCustomDiscounts
          onSavingCustomDiscounts={submit}
          onDiscard={discard}
          data-testid="savingCustomDiscount-modal"
        />,
        {
          id: SAVING_CUSTOM_DISCOUNT_ID,
          onCloseComplete: discard,
          title: i18n.t('general.actions.savingCustomDiscount'),
          size: 'small',
          'data-testid': suffixTestId('savingCustomDiscount-modal', props),
        }
      );
    });

  const assignContract = (customerContractId: string, isDiscountApply: boolean) => {
    const putCustomerContractRequest: PostCustomerContractRequest = {
      directSaleId: props.directSale?.id as string,
      body: {
        customerContractId,
        isDiscountApply,
      },
    };

    return putCustomerContract(putCustomerContractRequest)
      .unwrap()
      .then(() => closeDialog(ASSIGN_CONTRACT_ID))
      .then(() => showNotification.success())
      .catch(handleApiError);
  };

  const onHandleAssignContract = (customerContractId: string, isDiscountApply: boolean) => {
    if (!isDiscountOnWorkOpen && !isDiscountOnMaterialOpen) {
      assignContract(customerContractId, isDiscountApply);
      return;
    }
    const discard = () => {
      closeDialog(CHANGE_DISCOUNT_TYPE_ID);
      closeDialog(ASSIGN_CONTRACT_ID);
    };
    const accept = () => {
      assignContract(customerContractId, isDiscountApply).then(() => {
        setDiscountOnWorkClose();
        setDiscountOnMaterialClose();
        discard();
      });
    };

    openDialog(<DiscountTabsChangeDiscountType onDiscard={discard} onAcceptChange={accept} />, {
      id: CHANGE_DISCOUNT_TYPE_ID,
      title: i18n.t('entity.warehouse.actions.changeDiscountTypeDialogHeader'),
      size: 'small',
      'data-testid': suffixTestId('changeDiscountType-modal', props),
    });
  };

  const onDeactivateContract = (isDiscountRemove: boolean) => {
    const deleteCustomerContractRequest: DeleteCustomerContractRequest = {
      directSaleId: props.directSale?.id as string,
      body: {
        isDiscountRemove,
      },
    };

    return deleteCustomerContract(deleteCustomerContractRequest)
      .unwrap()
      .then(() => closeDialog(DEACTIVATE_CONTRACT_ID))
      .then(() => showNotification.success())
      .catch(handleApiError);
  };

  const handleAssignContract = () => {
    openDialog(
      <DiscountsTabCustomerContract
        onHandleAssignContract={onHandleAssignContract}
        mode="assign"
        assignContractDialogId={ASSIGN_CONTRACT_ID}
        data-testid="changeContract-modal"
        onContractSelect={handleContractDetail}
        directSale={props.directSale}
      />,
      {
        id: ASSIGN_CONTRACT_ID,
        title: i18n.t('general.actions.assignContract'),
        'data-testid': suffixTestId('changeContract-modal', props),
      }
    );
  };

  const handleChangeContract = () => {
    openDialog(
      <DiscountsTabCustomerContract
        onHandleAssignContract={onHandleAssignContract}
        mode="change"
        assignContractDialogId={ASSIGN_CONTRACT_ID}
        data-testid="changeContract-modal"
        onContractSelect={handleContractDetail}
        directSale={props.directSale}
      />,
      {
        id: ASSIGN_CONTRACT_ID,
        title: i18n.t('general.actions.assignContract'),
        'data-testid': suffixTestId('changeContract-modal', props),
      }
    );
  };
  const handleDeactivateContract = () => {
    openDialog(
      <DiscountsTabDeactivateContract
        onDeactivateContract={onDeactivateContract}
        deactivateContractDialogId={DEACTIVATE_CONTRACT_ID}
        data-testid="deactivateContract-modal"
      />,
      {
        id: DEACTIVATE_CONTRACT_ID,
        title: i18n.t('general.actions.deactivateContract'),
        size: 'small',
        'data-testid': suffixTestId('deactivateContract-modal', props),
      }
    );
  };

  const handleContractDetail = (customerContractId: string) => {
    setContractDetailId(customerContractId);
  };

  const handleToggleDiscountOnWork = () => {
    if (isNil(customerContract)) {
      toggleDiscountOnWork();
      return;
    }

    const discard = () => closeDialog(CHANGE_DISCOUNT_TYPE_ID);
    const accept = () => {
      onDeactivateContract(true).then(() => {
        toggleDiscountOnWork();
        discard();
      });
    };

    openDialog(<DiscountTabsChangeDiscountType onDiscard={discard} onAcceptChange={accept} />, {
      id: CHANGE_DISCOUNT_TYPE_ID,
      title: i18n.t('entity.warehouse.actions.changeDiscountTypeDialogHeader'),
      size: 'small',
      'data-testid': suffixTestId('changeDiscountType-modal', props),
    });
  };

  const handleToggleDiscountOnMaterial = () => {
    if (isNil(customerContract)) {
      toggleDiscountOnMaterial();
      return;
    }

    const discard = () => closeDialog(CHANGE_DISCOUNT_TYPE_ID);
    const accept = () => {
      onDeactivateContract(true).then(() => {
        toggleDiscountOnMaterial();
        discard();
      });
    };

    openDialog(<DiscountTabsChangeDiscountType onDiscard={discard} onAcceptChange={accept} />, {
      id: CHANGE_DISCOUNT_TYPE_ID,
      title: i18n.t('entity.warehouse.actions.changeDiscountTypeDialogHeader'),
      size: 'small',
      'data-testid': suffixTestId('changeDiscountType-modal', props),
    });
  };

  const headerActions: Action[] = [
    {
      type: 'button',
      title: i18n.t('general.actions.changeContract'),
      onClick: handleChangeContract,
      isDisabled: props.isEditingDisabled,
      buttonType: 'button',
      variant: 'link',
      leftIcon: 'navigation/refresh',
    },
    {
      type: 'button',
      title: i18n.t('general.actions.deactivateContract'),
      onClick: handleDeactivateContract,
      isDisabled: props.isEditingDisabled,
      buttonType: 'button',
      variant: 'errorLink',
      leftIcon: 'navigation/close',
    },
  ];

  const workDiscountOptions: RadioOption[] = [
    {
      value: 'percentage',
      label: i18n.t('entity.warehouse.labels.percentageDiscount'),
      isDisabled: props.isEditingDisabled,
    },
    {
      value: 'workRate',
      label: i18n.t('entity.warehouse.labels.discountWorkRate'),
      isDisabled: props.isEditingDisabled,
    },
  ];

  const materialDiscountOptions: RadioOption[] = [
    {
      value: 'discountToSalePrice',
      label: i18n.t('entity.warehouse.labels.discountOnSalePrice'),
      isDisabled: props.isEditingDisabled,
    },
    {
      value: 'markupToPurchasePrice',
      label: i18n.t('entity.warehouse.labels.markUpToPurchasePrice'),
      isDisabled: props.isEditingDisabled,
    },
  ];

  const isCustomDiscountDisabled =
    isNilOrEmpty(directSaleDiscount) && !isDiscountOnWorkOpen && !isDiscountOnMaterialOpen;

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Show when={isNotNil(contractDetailId)}>
        <CustomerContractModal
          customerContractId={contractDetailId ?? ''}
          onClose={() => setContractDetailId(undefined)}
          data-testid={testIds.customer.detail('contractDetailModal')}
        />
      </Show>
      <VStack spacing={4}>
        <Show when={props.directSale?.customerId}>
          <Heading size={4}>{i18n.t('entity.warehouse.labels.customerContract')}</Heading>
          <Text size="small">{i18n.t('entity.warehouse.labels.customerContractDescription')}</Text>
          <Show when={customerContract}>
            <Card
              variant="inlineGrey"
              title={i18n.t('entity.warehouse.labels.customerContract')}
              actions={headerActions}
            >
              <VStack spacing={4}>
                <Grid columns={4}>
                  <VStack>
                    <Text size="small">{i18n.t('general.labels.name')}</Text>
                    <Heading size={4}>{customerContract?.name}</Heading>
                  </VStack>
                  <VStack>
                    <Text size="small">{i18n.t('general.labels.description')}</Text>
                    <Heading size={4}>{customerContract?.description}</Heading>
                  </VStack>
                  <VStack>
                    <Text size="small">
                      {i18n.t('entity.warehouse.labels.customerGroupForMaterialDelivery')}
                    </Text>
                    <Heading size={4}>{customerContract?.materialDiscountGroup?.name}</Heading>
                  </VStack>
                </Grid>
                <Align left>
                  <Button
                    onClick={() => handleContractDetail(customerContract?.id as string)}
                    variant="link"
                    title={i18n.t('general.actions.viewDetail')}
                    data-testid={suffixTestId('viewDetail', props)}
                  />
                </Align>
              </VStack>
            </Card>
          </Show>
          <Hide when={customerContract}>
            <Box width={20}>
              <Button
                onClick={handleAssignContract}
                variant="secondary"
                title={i18n.t('entity.warehouse.labels.assignContract')}
                isDisabled={props.isEditingDisabled}
                data-testid={suffixTestId('assignContract', props)}
              />
            </Box>
            <Separator />
          </Hide>
        </Show>
        <Heading size={4}>{i18n.t('entity.warehouse.labels.customDiscounts')}</Heading>
        <Text size="small">{i18n.t('entity.warehouse.labels.customDiscountsDescription')}</Text>
        <Form<CustomDiscountForm>
          defaultValues={defaultValues}
          onSubmit={handleFormSubmit}
          shouldWatchForUnsavedChanges
        >
          {(control, formApi) => {
            const workDiscountType = formApi.watch('workDiscount.discountType');

            return (
              <VStack spacing={4}>
                <Card
                  title={i18n.t('entity.warehouse.labels.discountOnWork')}
                  control={{
                    type: 'switch',
                    value: isDiscountOnWorkOpen,
                    onChange: handleToggleDiscountOnWork,
                    isDisabled: props.isEditingDisabled,
                  }}
                  isExpanded={isDiscountOnWorkOpen}
                >
                  <VStack spacing={4}>
                    <FormField
                      control={control}
                      name="workDiscount.discountType"
                      type="radio"
                      options={workDiscountOptions}
                      data-testid={suffixTestId('workDiscount.discountType', props)}
                    />
                    <Show when={workDiscountType === 'percentage'}>
                      <Grid columns={4}>
                        <FormField
                          control={control}
                          name="workDiscount.discountValue"
                          type="number"
                          label={i18n.t('entity.warehouse.labels.percentage')}
                          suffix="%"
                          isDisabled={props.isEditingDisabled}
                          data-testid={suffixTestId('workDiscount.discountValue', props)}
                        />
                      </Grid>
                    </Show>
                    <Show when={workDiscountType === 'workRate'}>
                      <DiscountsTabWorkRateListField<CustomDiscountForm>
                        control={control}
                        formApi={formApi}
                        name="workDiscount.discountValue"
                        categoryOptions={workTypes as DictionaryOption[]}
                        isDisabled={props.isEditingDisabled}
                        currency={currency as CurrencyCodeType}
                      />
                    </Show>
                  </VStack>
                </Card>
                <Card
                  title={i18n.t('entity.warehouse.labels.discountOnMaterial')}
                  control={{
                    type: 'switch',
                    value: isDiscountOnMaterialOpen,
                    onChange: handleToggleDiscountOnMaterial,
                    isDisabled: props.isEditingDisabled,
                  }}
                  isExpanded={isDiscountOnMaterialOpen}
                >
                  <VStack spacing={4}>
                    <FormField
                      control={control}
                      name="materialDiscount.discountType"
                      type="radio"
                      options={materialDiscountOptions}
                      data-testid={suffixTestId('materialDiscount.discountType', props)}
                    />
                    <Grid columns={4}>
                      <FormField
                        control={control}
                        name="materialDiscount.discountValue"
                        type="number"
                        label={i18n.t('entity.warehouse.labels.percentage')}
                        suffix="%"
                        isDisabled={props.isEditingDisabled}
                        data-testid={suffixTestId('materialDiscount.discountValue', props)}
                      />
                    </Grid>
                  </VStack>
                </Card>
                <Align right>
                  <FormButton
                    isDisabled={
                      isNotNil(customerContract) ||
                      isCustomDiscountDisabled ||
                      props.isEditingDisabled
                    }
                    control={control}
                    type="submit"
                    title={i18n.t('general.actions.saveChanges')}
                    data-testid={suffixTestId('saveChanges', props)}
                  />
                </Align>
              </VStack>
            );
          }}
        </Form>
      </VStack>
    </DataStatus>
  );
}
