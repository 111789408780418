import {IconButton} from 'platform/components';
import {Stack, Text} from 'platform/foundation';
import {NumberFormat, useFormatCurrency} from 'platform/locale';
import styled from 'styled-components';

import {MouseEvent, useState} from 'react';

import {head} from 'ramda';

import {useDeleteUpsellMutation, useGetBusinessCaseQuery} from '@dms/api/businessCase';
import {OfferResponseBody, UpsellResponseBody} from '@dms/api/commission';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {EMPTY_PLACEHOLDER, handleApiError, useBusinessCaseActions} from '@dms/shared';
import {DataType, PriceBox, SimpleTable, Td, Tr} from '@dms/teas';

const PriceHiglight = styled.div<{$amount: number}>`
  color: ${({theme, $amount}) =>
    $amount > 0
      ? theme.colors.palettes.green[60][100]
      : $amount < 0
        ? theme.colors.palettes.red[60][100]
        : theme.colors.palettes.neutral[900][100]};
`;

const TableContainer = styled.div`
  .simple-table-row {
    background: ${({theme}) => theme.colors.palettes.white[10][100]};
    cursor: pointer;

    &:last-child > td {
      border-bottom: 0;
    }

    &:hover {
      background: ${({theme}) => theme.colors.palettes.neutral[10][100]};
    }
  }

  .simple-table-th {
    padding: ${({theme}) => theme.getSize(3)} ${({theme}) => theme.getSize(2)};

    &:not(:first-of-type) {
      text-align: right;
    }
  }

  .simple-table-td {
    padding: 10px ${({theme}) => theme.getSize(2)};
    font-size: ${({theme}) => theme.fontSizes.text.small};
    border-bottom: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};

    &:not(:first-of-type) {
      text-align: right;
    }
  }

  tfoot {
    /* stylelint-disable-next-line no-descending-specificity */
    tr:last-child > td {
      padding-left: 0;
      padding-top: ${({theme}) => theme.getSize(4)};
      vertical-align: top;
    }
  }
`;

type UpsellSimpleTableProps = {
  businessCaseId: string;
  upsells?: UpsellResponseBody[] | null;
  totalUpsell?: OfferResponseBody['totalUpsell'];
  onRowClick?: (upsell: UpsellResponseBody) => void;
};

export function UpsellSimpleTable(props: UpsellSimpleTableProps) {
  const formatCurrency = useFormatCurrency();

  const {data: businessCase} = useGetBusinessCaseQuery({businessCaseId: props.businessCaseId});

  const {isActionEnabled} = useBusinessCaseActions(props.businessCaseId);

  const [deleteId, setDeleteId] = useState<string>();

  const offer = head(businessCase?.offers ?? []);
  const saleVehicle = head(offer?.saleVehicles ?? []);

  const [deleteUpsell, {isLoading: isDeleteLoading}] = useDeleteUpsellMutation();

  const columns = [
    {
      Header: i18n.t('entity.person.labels.title'),
      id: 'title',
    },
    {
      Header: i18n.t('general.labels.discount'),
      id: 'discount',
    },
    {
      Header: i18n.t('entity.vehicle.labels.margin'),
      id: 'margin',
    },
    {
      Header: i18n.t('page.businessCase.labels.profit'),
      id: 'profit',
    },
    {
      Header: i18n.t('general.labels.sellingPrice'),
      id: 'sellingPrice',
    },
    {
      Header: '',
      id: 'action',
    },
  ];

  const removeUpsell = (upsellId: string) => (e: MouseEvent) => {
    e.stopPropagation();
    if (saleVehicle?.id) {
      setDeleteId(upsellId);

      deleteUpsell({
        businessCaseId: props.businessCaseId,
        offerId: offer?.id ?? '',
        offerSaleVehicleId: saleVehicle?.id ?? '',
        upsellId,
      })
        .unwrap()
        .catch(handleApiError);
    }
  };

  const createRow = (upsellData: UpsellResponseBody, index: number): DataType => ({
    id: upsellData.id,
    upsell: upsellData, // FIXME: find better way
    title: upsellData.name,
    accountingContextCustomItemCode: upsellData.accountingContextCustomItemCode,
    discount: upsellData.discountPrice?.withVat
      ? formatCurrency(
          Number(upsellData.discountPrice.withVat.amount),
          upsellData.discountPrice.withVat.currency
        )
      : EMPTY_PLACEHOLDER,
    margin: upsellData.margin ? (
      <PriceHiglight $amount={Number(upsellData.margin)}>
        <NumberFormat decimals={2} number={Number(upsellData.margin)} /> %
      </PriceHiglight>
    ) : (
      EMPTY_PLACEHOLDER
    ),
    profit: upsellData.profitPrice?.withVat ? (
      <PriceHiglight $amount={Number(upsellData.profitPrice.withVat.amount)}>
        {formatCurrency(
          Number(upsellData.profitPrice.withVat.amount),
          upsellData.profitPrice.withVat.currency
        )}
      </PriceHiglight>
    ) : (
      EMPTY_PLACEHOLDER
    ),
    sellingPrice: upsellData.sellingPrice?.withVat?.amount
      ? formatCurrency(
          Number(upsellData.sellingPrice.withVat.amount),
          upsellData.sellingPrice.withVat.currency
        )
      : EMPTY_PLACEHOLDER,
    action: isActionEnabled('REMOVE_UPSELL_ITEM') ? (
      <Stack direction="row" justify="flex-end">
        <IconButton
          icon="action/delete"
          severity="danger"
          onClick={removeUpsell(upsellData.id)}
          isDisabled={upsellData.id === deleteId && isDeleteLoading}
          data-testid={testIds.businessCase.selling(`removeUpsell-${index}`)}
        />
      </Stack>
    ) : null,
  });

  return (
    <TableContainer>
      <SimpleTable
        columns={columns}
        data-testid={testIds.businessCase.selling('upsell-list')}
        rows={[{data: props.upsells?.map(createRow) || []}]}
        onRowClick={(row) => props.onRowClick?.(row.upsell as UpsellResponseBody)}
        footerRenderer={
          props.totalUpsell
            ? () => (
                <Tr>
                  <Td>
                    <Text size="xSmall" color="secondary">
                      {i18n.t('general.labels.total')}
                    </Text>
                  </Td>
                  <Td colSpan={5}>
                    <PriceBox
                      data-testid={testIds.businessCase.selling('upsell-totalUpsell')}
                      size="small"
                      align="right"
                      price={props.totalUpsell?.withVat}
                      priceSub={props.totalUpsell?.withoutVat}
                    />
                  </Td>
                </Tr>
              )
            : undefined
        }
      />
    </TableContainer>
  );
}
