import {useEffect, useState} from 'react';

import {defaultTo, isNil, pipe, trim} from 'ramda';

import {useGetTenantV2Query} from '@dms/api/core';
import {useGetCustomerV2Query} from '@dms/api/customer';
import {PhoneNumber} from '@dms/api/shared';
import {useGetCurrentUserInfoQuery} from '@dms/api/user';
import {getNaturalPersonFullName} from '@dms/shared';

import {Nullish} from 'shared';

type StateFormValues = Record<
  string,
  {name: string | null; email: string | null; phoneNumber: PhoneNumber; id: string}
>;

export const useSignatoryFormState = (customerId: string | Nullish) => {
  const [formData, setFormData] = useState<StateFormValues>({});
  const [inputKey, setInputKey] = useState<number>(0);
  const updateComponentKey = () => setInputKey((prev) => prev + 1);

  const {data: currentUser} = useGetCurrentUserInfoQuery();
  const {data: tenant} = useGetTenantV2Query();
  const {data: customer} = useGetCustomerV2Query(
    {
      customerId: customerId ?? '',
    },
    {skip: isNil(customerId)}
  );

  useEffect(() => {
    const customerInfo = customer?.foundingPerson ?? customer?.contacts?.[0];

    if (!customerInfo || !customer) {
      return;
    }

    const email = customerInfo.emails?.[0]?.email;
    const customerPhoneNumber = customerInfo.phoneNumbers?.[0];
    const phoneNumber = customerPhoneNumber ?? tenant?.publicContacts?.phoneNumber;
    const name =
      customer.contractInformation?.[0]?.legalForm === 'NATURAL_PERSON'
        ? getNaturalPersonFullName(customerInfo)
        : customer.businessInfo?.businessInfo?.tradeName;

    setFormData((prevState) => ({
      ...prevState,
      customer: {
        email,
        name: name ?? '',
        phoneNumber: {
          countryCode: getFormPhoneValue(phoneNumber?.countryCode),
          number: getFormPhoneValue(phoneNumber?.number),
          prefix: getFormPhoneValue(phoneNumber?.prefix),
        },
        id: customer.id,
      },
    }));
    updateComponentKey();
  }, [customer]);

  useEffect(() => {
    if (isNil(currentUser)) {
      console.error('Current user is not defined');
      return;
    }

    const phoneNumber = currentUser.phoneNumber;
    const tenantFallbackPhoneNumber =
      currentUser.phoneNumber ?? tenant?.publicContacts?.phoneNumber;

    const userValues = {
      name: getNaturalPersonFullName(currentUser) ?? '',
      email: getFormPhoneValue(currentUser.email),
      id: currentUser.id,
      phoneNumber: {
        number: getFormPhoneValue(phoneNumber?.number),
        prefix: getFormPhoneValue(tenantFallbackPhoneNumber?.prefix),
        countryCode: getFormPhoneValue(tenantFallbackPhoneNumber?.countryCode),
      },
    };

    setFormData((prevState) => ({
      ...prevState,
      tenant: userValues,
    }));
    updateComponentKey();
  }, [currentUser]);

  return [formData, setFormData, inputKey] as const;
};

const getFormPhoneValue = pipe(defaultTo<string | undefined>(''), trim);
