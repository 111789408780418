import {Grid} from 'platform/foundation';

import {useGetActiveSaleVehicleQuery} from '@dms/api/saleVehicle';
import i18n from '@dms/i18n';
import {usePricePermissions} from '@dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {SummaryCard} from './SummaryCard';

interface EarningsCostsSummaryProps extends TestIdProps {
  vehicleId: string;
}

export function EarningsCostsSummary(props: EarningsCostsSummaryProps) {
  const {
    data,
    isLoading: isLoadingFinancialIndicators,
    isError: hasFinancialIndicatorsError,
  } = useGetActiveSaleVehicleQuery({
    vehicleId: props.vehicleId,
  });

  const financialIndicators = data?.financialIndicators;

  const {canReadVehicleCosts, canReadVehicleEarnings, canReadVehicleTotalGrossMargin} =
    usePricePermissions({
      vehicleRecordId: props.vehicleId,
      businessCaseRecordId: null,
    });

  return (
    <Grid columns={[1, 2]}>
      <SummaryCard
        isError={hasFinancialIndicatorsError}
        isLoading={isLoadingFinancialIndicators}
        earnings={financialIndicators?.expectedEarnings}
        costs={financialIndicators?.expectedCosts}
        margin={financialIndicators?.expectedTotalGrossMargin}
        areCostsHidden={!canReadVehicleCosts}
        areEarningsHidden={!canReadVehicleEarnings}
        isTotalMarginHidden={!canReadVehicleTotalGrossMargin}
        title={i18n.t('entity.earningsCosts.labels.expectedState')}
        data-testid={suffixTestId('expectedState', props)}
      />
      <SummaryCard
        isError={hasFinancialIndicatorsError}
        isLoading={isLoadingFinancialIndicators}
        earnings={financialIndicators?.realEarnings}
        earningsDifference={financialIndicators?.expectedAndRealEarningsDifference}
        costs={financialIndicators?.realCosts}
        costsDifference={financialIndicators?.expectedAndRealCostsDifference}
        areCostsHidden={!canReadVehicleCosts}
        areEarningsHidden={!canReadVehicleEarnings}
        isTotalMarginHidden={!canReadVehicleTotalGrossMargin}
        margin={financialIndicators?.totalGrossMargin}
        title={i18n.t('entity.earningsCosts.labels.realState')}
        data-testid={suffixTestId('realState', props)}
      />
    </Grid>
  );
}
