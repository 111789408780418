import {Alert, Card, DataStatus, FlagProps, Flags, Tabs} from 'platform/components';
import {Box, Display, Heading, Hide, HStack, Icon, Text, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';
import {match} from 'ts-pattern';

import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import {mergeAll} from 'ramda';

import {ImportResponseBody, useGetImportQuery} from '@dms/api/import';
import i18n from '@dms/i18n';

import {DOT_CHARACTER, Nullish, parseDate} from 'shared';

import {DataGrid, QueryFilterObject} from 'features/datagrid';

import {FileList} from '../../components/FileList';
import {ClockImage} from './components/ClockImage';
import {ErrorImage} from './components/ErrorImage';
import {TesseractImage} from './components/TesseractImage';
import {computeElapsedTime} from './utils/computeElapsedTime';

export function Detail() {
  const {id} = useParams();
  const formatDateTime = useDateTimeFormatter();

  const getImportQuery = useGetImportQuery({id: id || ''});

  const startedAt = getImportQuery.data?.startedAt || undefined;
  const finishedAt = getImportQuery.data?.finishedAt || undefined;
  const status = getImportQuery.data?.status || undefined;

  const [elapsedTime, setElapsedTime] = useState('');

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const runTick = () => {
      setElapsedTime(computeElapsedTime({startedAt, finishedAt, status}));
      timeoutId = setTimeout(runTick, 1000);
    };

    runTick();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [startedAt, finishedAt, status]);

  const sanitizeDateTime = (dateTime: string | Nullish) => {
    if (!dateTime) {
      return i18n.t('entity.import.labels.notStarted');
    }

    return formatDateTime('dateTimeMedium', parseDate(dateTime));
  };

  const getImportType = () => {
    if (!getImportQuery.data) {
      return '';
    }

    return match(getImportQuery.data.fileType)
      .with('carisSaleVehicleCosts', () => i18n.t('entity.import.labels.carisCost'))
      .otherwise(() => '');
  };

  const getFlag = (): FlagProps[] => {
    if (!getImportQuery.data) {
      return [];
    }

    return match<ImportResponseBody['status'], FlagProps[]>(getImportQuery.data.status)
      .with('created', () => [{label: i18n.t('general.labels.created'), colorScheme: 'neutral'}])
      .with('scheduled', () => [
        {label: i18n.t('entity.import.labels.scheduled'), colorScheme: 'orange'},
      ])
      .with('inProgress', () => [{label: i18n.t('general.labels.inProgress'), colorScheme: 'blue'}])
      .with('finished', () => [
        {label: i18n.t('entity.import.labels.finished'), colorScheme: 'green'},
      ])
      .with('failed', () => [{label: i18n.t('entity.import.labels.failed'), colorScheme: 'red'}])
      .otherwise(() => []);
  };

  const getAlert = () => {
    if (!getImportQuery.data) {
      return null;
    }

    return match<ImportResponseBody['status']>(getImportQuery.data.status)
      .with('created', 'scheduled', () => (
        <Alert
          title={i18n.t('entity.import.alertWarning.title')}
          message={getImportQuery.data?.message || i18n.t('entity.import.alertWarning.message')}
          variant="warning"
          type="inline"
        />
      ))
      .with('failed', () => (
        <Alert
          title={i18n.t('entity.import.alertError.title')}
          message={getImportQuery.data?.message || i18n.t('entity.import.alertError.message')}
          variant="error"
          type="inline"
        />
      ))
      .otherwise(() => null);
  };

  const changelogQueryModifier = (filter: QueryFilterObject) => mergeAll([filter, {importId: id}]);
  const errorQueryModifier = (filter: QueryFilterObject) => mergeAll([filter, {importId: id}]);

  return (
    <DataStatus isLoading={getImportQuery.isLoading} isError={getImportQuery.isError}>
      <VStack>
        <Box backgroundColor="general.white" padding={4}>
          <HStack align="center" spacing={4}>
            <Box
              backgroundColor="palettes.neutral.30.100"
              borderRadius="medium"
              height={23}
              width={23}
              flexShrink={0}
            >
              <HStack justify="center" align="center" height="100%">
                <Icon value="sidebar/import" size={12} />
              </HStack>
            </Box>
            <VStack spacing={2}>
              <Flags flags={getFlag()} />
              <Heading size={2}>{getImportQuery.data?.name}</Heading>
              <HStack spacing={1}>
                <HStack spacing={1}>
                  <Text size="small">{i18n.t('general.labels.created')}:</Text>
                  <Text size="small" alternative>
                    {sanitizeDateTime(getImportQuery.data?.createdAt)}
                  </Text>
                </HStack>
                <Text size="small">{DOT_CHARACTER}</Text>
                <HStack spacing={1}>
                  <Text size="small">{i18n.t('entity.import.labels.started')}</Text>
                  <Text size="small" alternative>
                    {sanitizeDateTime(getImportQuery.data?.startedAt)}
                  </Text>
                </HStack>
                <Text size="small">{DOT_CHARACTER}</Text>
                <HStack spacing={1}>
                  <Text size="small">{i18n.t('entity.import.labels.ended')}</Text>
                  <Text size="small" alternative>
                    {sanitizeDateTime(getImportQuery.data?.finishedAt)}
                  </Text>
                </HStack>
              </HStack>
            </VStack>
          </HStack>
        </Box>
        <Box padding={4}>
          <VStack spacing={4}>
            <Card>
              <VStack spacing={4}>
                {getAlert()}
                <HStack justify="space-between" spacing={4}>
                  <Box flex={1} padding={4}>
                    <VStack spacing={6}>
                      <VStack spacing={2}>
                        <Text size="xSmall" color="secondary">
                          {i18n.t('entity.import.labels.importType')}
                        </Text>
                        <Heading size={3}>{getImportType()}</Heading>
                      </VStack>
                      <FileList
                        contentType={getImportQuery.data?.fileContentType}
                        url={getImportQuery.data?.fileUri || ''}
                        name={getImportQuery.data?.fileName || ''}
                      />
                    </VStack>
                  </Box>
                  <Box flex={1} padding={4}>
                    <VStack spacing={2}>
                      <Text size="xSmall" color="secondary">
                        {i18n.t('general.labels.description')}
                      </Text>
                      <Text size="small">{getImportQuery.data?.description}</Text>
                    </VStack>
                  </Box>
                </HStack>
                <HStack spacing={4}>
                  <Box backgroundColor="palettes.blue.10.100" width="100%" padding={4}>
                    <HStack align="center" spacing={4}>
                      <Box flexShrink={0}>
                        <TesseractImage />
                      </Box>
                      <VStack spacing={2}>
                        <Text size="xSmall" color="secondary">
                          {i18n.t('entity.import.labels.noChangedRecords')}
                        </Text>
                        <Display size={1}>{getImportQuery.data?.affectedRecordCount}</Display>
                      </VStack>
                    </HStack>
                  </Box>
                  <Box backgroundColor="palettes.blue.10.100" width="100%" padding={4}>
                    <HStack align="center" spacing={4}>
                      <Box flexShrink={0}>
                        <ClockImage />
                      </Box>
                      <VStack spacing={2}>
                        <Text size="xSmall" color="secondary">
                          {i18n.t('entity.import.labels.duration')}
                        </Text>
                        <Display size={1}>{elapsedTime}</Display>
                      </VStack>
                    </HStack>
                  </Box>
                  <Box backgroundColor="palettes.red.10.100" width="100%" padding={4}>
                    <HStack align="center" spacing={4}>
                      <Box flexShrink={0}>
                        <ErrorImage />
                      </Box>
                      <VStack spacing={2}>
                        <Text size="xSmall" color="secondary">
                          {i18n.t('entity.import.labels.noErrors')}
                        </Text>
                        <Display size={1}>{getImportQuery.data?.errorCount}</Display>
                      </VStack>
                    </HStack>
                  </Box>
                </HStack>
              </VStack>
            </Card>
            <Hide when={getImportQuery.data?.status === 'failed'}>
              <Tabs
                variant="condensed"
                tabs={[
                  {
                    id: 'changelog',
                    title: i18n.t('entity.import.labels.changelog'),
                    content: (
                      <Box height="100%">
                        <DataGrid
                          gridCode="import-change-log"
                          queryModifier={changelogQueryModifier}
                          autoHeight
                        />
                      </Box>
                    ),
                  },
                  {
                    id: 'logs',
                    title: i18n.t('entity.import.labels.errors'),
                    content: (
                      <Box height="100%">
                        <DataGrid
                          gridCode="import-error"
                          queryModifier={errorQueryModifier}
                          autoHeight
                        />
                      </Box>
                    ),
                  },
                ]}
              />
            </Hide>
          </VStack>
        </Box>
      </VStack>
    </DataStatus>
  );
}
