import {Card, RadioItem} from 'platform/components';
import {Clickable, Heading, HStack, Text} from 'platform/foundation';

import {useGetServiceOrderQuery} from '@dms/api/metadaWorkshopServiceOrder';
import {useGetServiceOrderVariantQuery} from '@dms/api/metadaWorkshopServiceOrderVariant';

import {suffixTestId, TestIdProps} from 'shared';

import {OrderType} from '../../../types/OrderType';

interface MoveRequestOrderProps extends TestIdProps {
  order: OrderType;
  isSelected?: boolean;
  onSelect?: () => void;
}

export function RequestOrder(props: MoveRequestOrderProps) {
  const {data: serviceOrder} = useGetServiceOrderQuery({
    serviceCaseId: String(props.order.serviceCaseId),
    serviceOrderId: String(props.order.id),
  });
  const {data: serviceOrderVariant} = useGetServiceOrderVariantQuery(
    {serviceOrderVariantId: serviceOrder?.serviceOrderVariantId ?? ''},
    {skip: !serviceOrder?.serviceOrderVariantId}
  );

  return (
    <Card variant="inlineWhite">
      <Clickable onClick={props.onSelect} data-testid={suffixTestId('selectionArea', props)}>
        <HStack spacing={3} align="center">
          <RadioItem value={!!props.isSelected} data-testid={suffixTestId('selected', props)} />
          <HStack spacing={2}>
            <Heading size={4} data-testid={suffixTestId('number', props)}>
              {props.order?.number}
            </Heading>
            <Heading size={4}>•</Heading>
            <Text data-testid={suffixTestId('variant', props)}>{serviceOrderVariant?.name}</Text>
          </HStack>
        </HStack>
      </Clickable>
    </Card>
  );
}
