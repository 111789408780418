import {DataStatus} from 'platform/components';

import {defaultTo} from 'ramda';

import {useGetAuditDataQuery} from '@dms/api/carAudit';
import {useGetServiceCheckInQuery} from '@dms/api/metadaWorkshopCheckin';
import {useGetServiceHandOverQuery} from '@dms/api/metadaWorkshopHandover';
import {useGetMetadaServiceCaseQuery} from '@dms/api/metadaWorkshopServiceCase';
import {GetServiceOrderApiResponse} from '@dms/api/metadaWorkshopServiceOrder';
import {useGetCurrentUserInfoQuery} from '@dms/api/user';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {useContactPersons} from '../../hooks/useContactPersons';
import {EmailSmsNotification} from './components/EmailSmsNotification';
import {NotificationCategory} from './types/EmailSmsNotificationForm';

interface EmailSmsNotificationBoundaryProps extends RequiredTestIdProps {
  orderId?: string;
  serviceCaseId: string;
  dialogId: string;
  serviceOrder?: GetServiceOrderApiResponse;
  defaultCategory?: NotificationCategory;
}

export function EmailSmsNotificationBoundary(props: EmailSmsNotificationBoundaryProps) {
  const serviceCaseQuery = useGetMetadaServiceCaseQuery({
    serviceCaseId: props.serviceCaseId,
  });

  const serviceCheckinQuery = useGetServiceCheckInQuery({serviceCaseId: props.serviceCaseId});
  const serviceHandoverQuery = useGetServiceHandOverQuery({serviceCaseId: props.serviceCaseId});

  const checkingInspectionId = defaultTo('', serviceCheckinQuery.data?.inspectionId?.[0]);
  const handoverInspectionId = defaultTo('', serviceHandoverQuery.data?.inspectionId?.[0]);

  const checkinAuditDataQuery = useGetAuditDataQuery(
    {auditId: checkingInspectionId},
    {skip: !checkingInspectionId, refetchOnMountOrArgChange: true}
  );
  const handoverAuditDataQuery = useGetAuditDataQuery(
    {auditId: handoverInspectionId},
    {skip: !handoverInspectionId, refetchOnMountOrArgChange: true}
  );

  const currentUserQuery = useGetCurrentUserInfoQuery();
  const selectedLang = defaultTo('en', currentUserQuery.data?.settings.language as string);

  const {
    isLoading: isContactPersonLoading,
    isError: isContactPersonError,
    contactPersons,
  } = useContactPersons(props.serviceCaseId);

  const isLoading =
    serviceCaseQuery.isLoading ||
    serviceCheckinQuery.isLoading ||
    serviceHandoverQuery.isLoading ||
    checkinAuditDataQuery.isLoading ||
    handoverAuditDataQuery.isLoading ||
    currentUserQuery.isLoading ||
    isContactPersonLoading;

  const isError =
    serviceCaseQuery.isError ||
    serviceCheckinQuery.isError ||
    serviceHandoverQuery.isError ||
    checkinAuditDataQuery.isError ||
    handoverAuditDataQuery.isError ||
    currentUserQuery.isError ||
    isContactPersonError;

  const serviceCaseState = serviceCaseQuery.data?.state;

  const checkinAudit = checkinAuditDataQuery?.data?.find(
    (item) => item.id === checkingInspectionId
  );
  const isCheckinFinished = checkinAudit?.state === 'finished_audit';

  const handoverAudit = handoverAuditDataQuery?.data?.find(
    (item) => item.id === handoverInspectionId
  );
  const isHandoverFinished = handoverAudit?.state === 'finished_audit';

  return (
    <DataStatus isLoading={isLoading} isError={isError} minHeight={200}>
      <EmailSmsNotification
        orderId={props.orderId}
        serviceCaseId={props.serviceCaseId}
        dialogId={props.dialogId}
        serviceOrder={props.serviceOrder}
        serviceCaseState={serviceCaseState || undefined}
        isCheckinFinished={isCheckinFinished}
        isHandoverFinished={isHandoverFinished}
        contactPersons={contactPersons}
        checkinInspectionId={checkingInspectionId}
        handoverInspectionId={handoverInspectionId}
        selectedLang={selectedLang}
        defaultCategory={props.defaultCategory}
        data-testid={suffixTestId('sms-email-boundary', props)}
      />
    </DataStatus>
  );
}
