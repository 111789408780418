import {metadaApi} from '@dms/api/core';

import {
  PostHandlingUnitRequest,
  PostHandlingUnitRequestSchema,
  PostHandlingUnitResponse,
  PostHandlingUnitResponseSchema,
  GetWarehouseHandlingUnitRequest,
  GetWarehouseHandlingUnitRequestSchema,
  GetWarehouseHandlingUnitResponse,
  GetWarehouseHandlingUnitResponseSchema,
  GetWarehouseHandlingUnitsRequest,
  GetWarehouseHandlingUnitsRequestSchema,
  GetWarehouseHandlingUnitsResponse,
  GetWarehouseHandlingUnitsResponseSchema,
  PatchHandlingUnitRequest,
  PatchHandlingUnitRequestSchema,
  PatchHandlingUnitResponse,
  PatchHandlingUnitResponseSchema,
} from './types';

export const metadaWarehouseHandlingUnitApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getHandlingUnits: build.query<
      GetWarehouseHandlingUnitsResponse,
      GetWarehouseHandlingUnitsRequest
    >({
      query: () => ({
        url: `core/v1/codelist/wrh-handling-units`,
        method: 'GET',
      }),
      providesTags: ['handlingUnits'],
      extraOptions: {
        requestSchema: GetWarehouseHandlingUnitsRequestSchema,
        responseSchema: GetWarehouseHandlingUnitsResponseSchema,
      },
    }),
    getHandlingUnit: build.query<GetWarehouseHandlingUnitResponse, GetWarehouseHandlingUnitRequest>(
      {
        query: (queryArg) => ({
          url: `core/v1/codelist/wrh-handling-units/${queryArg.id}`,
          method: 'GET',
          queryArg,
        }),
        providesTags: (result, error, queryArg) => [{type: 'handlingUnits', id: queryArg.id}],
        extraOptions: {
          requestSchema: GetWarehouseHandlingUnitRequestSchema,
          responseSchema: GetWarehouseHandlingUnitResponseSchema,
        },
      }
    ),
    postHandlingUnit: build.mutation<PostHandlingUnitResponse, PostHandlingUnitRequest>({
      query: (queryArg) => ({
        url: `core/v1/codelist/wrh-handling-units`,
        method: 'POST',
        body: queryArg,
        invalidatesTags: ['handlingUnits'],
        queryArg,
      }),
      extraOptions: {
        requestSchema: PostHandlingUnitRequestSchema,
        responseSchema: PostHandlingUnitResponseSchema,
      },
    }),
    patchHandlingUnit: build.mutation<PatchHandlingUnitResponse, PatchHandlingUnitRequest>({
      query: (queryArg) => ({
        url: `core/v1/codelist/wrh-handling-units/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'handlingUnits', id: queryArg.id}],
      extraOptions: {
        requestSchema: PatchHandlingUnitRequestSchema,
        responseSchema: PatchHandlingUnitResponseSchema,
      },
    }),
    patchHandlingUnitDefault: build.mutation<PatchHandlingUnitResponse, PatchHandlingUnitRequest>({
      query: (queryArg) => ({
        url: `core/v1/codelist/wrh-handling-units/${queryArg.id}/set-default`,
        method: 'PATCH',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'handlingUnits', id: queryArg.id}],
      extraOptions: {
        requestSchema: PatchHandlingUnitRequestSchema,
        responseSchema: PatchHandlingUnitResponseSchema,
      },
    }),
    patchHandlingUnitActive: build.mutation<PatchHandlingUnitResponse, PatchHandlingUnitRequest>({
      query: (queryArg) => ({
        url: `core/v1/codelist/wrh-handling-units/${queryArg.id}/set-active`,
        method: 'PATCH',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'handlingUnits', id: queryArg.id}],
      extraOptions: {
        requestSchema: PatchHandlingUnitRequestSchema,
        responseSchema: PatchHandlingUnitResponseSchema,
      },
    }),
    patchHandlingUnitInActive: build.mutation<PatchHandlingUnitResponse, PatchHandlingUnitRequest>({
      query: (queryArg) => ({
        url: `core/v1/codelist/wrh-handling-units/${queryArg.id}/set-inactive`,
        method: 'PATCH',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'handlingUnits', id: queryArg.id}],
      extraOptions: {
        requestSchema: PatchHandlingUnitRequestSchema,
        responseSchema: PatchHandlingUnitResponseSchema,
      },
    }),
  }),
});

export const {
  useGetHandlingUnitsQuery,
  useGetHandlingUnitQuery,
  usePostHandlingUnitMutation,
  usePatchHandlingUnitMutation,
  usePatchHandlingUnitDefaultMutation,
  usePatchHandlingUnitActiveMutation,
  usePatchHandlingUnitInActiveMutation,
} = metadaWarehouseHandlingUnitApi;
