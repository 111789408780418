/* eslint-disable eag/match-named-export */
import {z} from 'zod';

const assignmentTypeSchema = z.enum(['vehicle', 'vehicle_audit']);

export const assignmentUserSchema = z.object({
  id: z.string(),
  email: z.string(),
  firstName: z.string().nullable().optional(),
  lastName: z.string().nullable().optional(),
});

export const assignmentIdentitySchema = z.object({
  type: assignmentTypeSchema,
  id: z.string(),
});

export const assignmentSchema = z.object({
  id: z.string(),
  identity: assignmentIdentitySchema,
  user: assignmentUserSchema,
});

export const getAssignmentsArgsSchema = z.object({
  assignmentIdentityType: assignmentTypeSchema,
  assignmentIdentityId: z.string(),
});
export const getAssignmentsResponseSchema = z.array(assignmentSchema);

export const searchAssignmentUsersArgsSchema = z.object({
  assignmentIdentityType: assignmentTypeSchema,
  assignmentIdentityId: z.string(),
  search: z.string().optional(),
  external: z.boolean().optional(),
  limit: z.number().optional(),
});
export const searchAssignmentUsersResponseSchema = z.array(assignmentUserSchema);

export const createAssignmentArgsSchema = z.object({
  userId: z.string(),
  identity: z.object({type: assignmentTypeSchema, id: z.string()}),
  // Optional fields for optimistic update
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  email: z.string().optional(),
});

export const createAssignmentResponseSchema = assignmentSchema;

export type GetAssignmentsArgs = z.infer<typeof getAssignmentsArgsSchema>;
export type GetAssignmentsResponse = z.infer<typeof getAssignmentsResponseSchema>;

export type SearchAssignmentUsersArgs = z.infer<typeof searchAssignmentUsersArgsSchema>;
export type SearchAssignmentUsersResponse = z.infer<typeof searchAssignmentUsersResponseSchema>;

export type CreateAssignmentArgs = z.infer<typeof createAssignmentArgsSchema>;
export type CreateAssignmentResponse = z.infer<typeof createAssignmentResponseSchema>;
