export enum AuditCategoryUniqueKey {
  VEHICLE_DOCUMENTATION = 'VEHICLE_DOCUMENTATION',
  OVERALL_COMMENT = 'OVERALL_COMMENT',
  TESTDRIVE = 'TESTDRIVE',
  PHOTODOCUMENTATION = 'PHOTODOCUMENTATION',
  TECHNICAL_CONDITION = 'TECHNICAL_CONDITION',
  EXTERIOR = 'EXTERIOR',
  INTERIOR = 'INTERIOR',
  EXTERIOR_DAMAGE = 'EXTERIOR_DAMAGE',
  INTERIOR_DAMAGE = 'DAMAGE_TO_THE_INTERIOR',
  WHEELS_AND_TIRES = 'WHEELS_AND_TIRES',
  PAINT_THICKNESS = 'PAINT_THICKNESS',
  THE_SECOND_SET = 'THE_SECOND_SET',
  LF_WHEEL = 'LF_WHEEL',
  LR_WHEEL = 'LR_WHEEL',
  RR_WHEEL = 'RR_WHEEL',
  RF_WHEEL = 'RF_WHEEL',
  OVERALL_EVALUATION_OF_THE_EXTERIOR = 'OVERALL_EVALUATION_OF_THE_EXTERIOR',
  OVERALL_EVALUATION_OF_THE_INTERIOR = 'OVERALL_EVALUATION_OF_THE_INTERIOR',
  OVERALL_EVALUATION_OF_THE_TECHNICAL_CONDITION = 'OVERALL_EVALUATION_OF_THE_TECHNICAL_CONDITION',
  EQUIPMENT = 'EQUIPMENT',
  EQUIPMENT_VALIDATION = 'EQUIPMENT_VALIDATION',
  EQUIPMENT_INSPECTION = 'EQUIPMENT_INSPECTION',
  THE_DIMENSION_CORRESPONDS_TO_THE_REGISTRATION_CERTIFICATE_CERTIFICATE_OF_CONFORMITY = 'THE_DIMENSION_CORRESPONDS_TO_THE_REGISTRATION_CERTIFICATE_CERTIFICATE_OF_CONFORMITY',

  LF_LIGHT = 'LF_LIGHT',
  LF_DOOR = 'LF_DOOR',
  L_MIRROR = 'L_MIRROR',
  RF_DOOR = 'RF_DOOR',
  R_MIRROR = 'R_MIRROR',
  OTHER = 'OTHER',

  COLUMN_TRIM_ON_THE_LEFT = 'COLUMN_TRIM_ON_THE_LEFT',
  DRIVING_WHEEL = 'DRIVING_WHEEL',
  COLUMN_TRIM_ON_THE_RIGHT = 'COLUMN_TRIM_ON_THE_RIGHT',
  DRIVERS_SEAT = 'DRIVERS_SEAT',
  PASSENGER_SEAT = 'PASSENGER_SEAT',
  CEILINGS = 'CEILINGS',
  CARPET = 'CARPET',
  OTHERS = 'OTHERS',

  VEHICLE_NON_SMOKING = 'VEHICLE_NON_SMOKING',
  NO_ODOR_INDOORS = 'NO_ODOR_INDOORS',

  MORE_EQUIPMENT = 'MORE_EQUIPMENT',
  MECHANICS = 'MECHANICS',

  OTHER_DEFINE_IN_NOTE = 'OTHER_DEFINE_IN_NOTE',
  OTHER_DEFINE_IN_THE_NOTE = 'OTHER_DEFINE_IN_THE_NOTE',

  MORE_PHOTOS = 'MORE_PHOTOS',
  YEAR_OF_PRODUCTION = 'YEAR_OF_PRODUCTION',
  NUMBER_OF_VEHICLE_KEYS = 'NUMBER_OF_VEHICLE_KEYS',
  NUMBER_OF_KEYS_FROM_MECHANICAL_SECURITY = 'NUMBER_OF_KEYS_FROM_MECHANICAL_SECURITY',
  REGISTRATION_CERTIFICATE = 'REGISTRATION_CERTIFICATE',
  NUMBER_OF_KEYS_FROM_THE_TOWING_DEVICE = 'NUMBER_OF_KEYS_FROM_THE_TOWING_DEVICE',
  VEHICLE_CRASHED_IN_THE_PAST = 'VEHICLE_CRASHED_IN_THE_PAST',
  THE_VEHICLE_SUFFERED_HAIL_DAMAGE = 'THE_VEHICLE_SUFFERED_HAIL_DAMAGE',
  THE_VEHICLE_MODIFIED_AGAINST_PRODUCTION_TUNING = 'THE_VEHICLE_MODIFIED_AGAINST_PRODUCTION_TUNING',
  OPERATING_INSTRUCTIONS = 'OPERATING_INSTRUCTIONS',
  ADDITIONAL_VEHICLE_DOCUMENTATION = 'ADDITIONAL_VEHICLE_DOCUMENTATION',
  VAT_DEDUCTION = 'VAT_DEDUCTION',
  SERVICE_BOOK = 'SERVICE_BOOK',
  ALL_CHILDREN = 'ALL_CHILDREN',

  VIDEOS = 'VIDEOS',

  ACCUMULATOR = 'ACCUMULATOR',
  AFTER_STARTING_ALL_LIGHTS_WENT_OUT = 'AFTER_STARTING_ALL_LIGHTS_WENT_OUT',
  BACKBENCH = 'BACKBENCH',
  BODY_CONDITION = 'BODY_CONDITION',
  BRAKES = 'BRAKES',
  BRAKE_DISC_BRAKE_DRUM_RF = 'BRAKE_DISC/BRAKE DRUM_RF',
  BRAKE_DISC_BRAKE_DRUM_LF = 'BRAKE_DISC/BRAKE_DRUM_LF',
  BRAKE_DISC_BRAKE_DRUM_LZ = 'BRAKE_DISC_BRAKE_DRUM_LZ',
  BRAKE_DISC_BRAKE_DRUM_RR = 'BRAKE_DISC_BRAKE_DRUM_RR',
  BRAKE_FLUID = 'BRAKE_FLUID',
  BRAKE_SYSTEM_FUNCTIONALITY = 'BRAKE_SYSTEM_FUNCTIONALITY',
  CERTIFICATE_OF_CONFORMITY = 'CERTIFICATE_OF_CONFORMITY',
  CHASSIS = 'CHASSIS',
  CHASSIS_CONDITION = 'CHASSIS_CONDITION',
  CHASSIS_PLAY = 'CHASSIS_PLAY',
  CONJUCTION = 'CONJUCTION',
  COOLING_SYSTEM = 'COOLING_SYSTEM',
  CORROSION = 'CORROSION',
  CURRENT_MILEAGE = 'CURRENT_MILEAGE',
  DASHBOARD = 'DASHBOARD',
  DATE_OF_FIRST_REGISTRATION = 'DATE_OF_FIRST_REGISTRATION',
  DIAGNOSTICS = 'DIAGNOSTICS',
  DRIVE_SYSTEM = 'DRIVE_SYSTEM',
  ELECTRICAL_SYSTEM = 'ELECTRICAL_SYSTEM',
  EMERGENCY_PARKING_BRAKE_SYSTEM_FUNCTIONALITY_E_BRAKE = 'EMERGENCY_PARKING_BRAKE_SYSTEM_FUNCTIONALITY_E_BRAKE',
  ENGINE = 'ENGINE',
  EXHAUST_SYSTEM = 'EXHAUST_SYSTEM',
  EXHAUST_SYSTEM_CORROSION = 'EXHAUST_SYSTEM_CORROSION',
  EXHAUST_SYSTEM_LEAK = 'EXHAUST_SYSTEM_LEAK',
  FEATURE_220V_SOCKET = 'FEATURE_220V_SOCKET',
  FEATURE_22KW_ONBOARD_CHARGER = 'FEATURE_22KW_ONBOARD_CHARGER',
  FEATURE_ABS = 'FEATURE_ABS',
  FEATURE_AC = 'FEATURE_AC',
  FEATURE_ACTIVE_STEERING = 'FEATURE_ACTIVE_STEERING',
  FEATURE_AC_1ZONE = 'FEATURE_AC_1ZONE',
  FEATURE_AC_2ZONE = 'FEATURE_AC_2ZONE',
  FEATURE_AC_3ZONE = 'FEATURE_AC_3ZONE',
  FEATURE_AC_4ZONE = 'FEATURE_AC_4ZONE',
  FEATURE_AC_AUTOMATIC = 'FEATURE_AC_AUTOMATIC',
  FEATURE_AC_MANUAL = 'FEATURE_AC_MANUAL',
  FEATURE_AC_MULTIZONE = 'FEATURE_AC_MULTIZONE',
  FEATURE_ADAPTIVE_LIGHTING = 'FEATURE_ADAPTIVE_LIGHTING',
  FEATURE_ADDITIONAL_LIGHTS = 'FEATURE_ADDITIONAL_LIGHTS',
  FEATURE_ADJUSTABLE_STEERING_WHEEL = 'FEATURE_ADJUSTABLE_STEERING_WHEEL',
  FEATURE_AIR_CONDITIONED_GLOVE_BOX = 'FEATURE_AIR_CONDITIONED_GLOVE_BOX',
  FEATURE_AIR_SUSPENSION = 'FEATURE_AIR_SUSPENSION',
  FEATURE_ALARM = 'FEATURE_ALARM',
  FEATURE_ALLOY_WHEELS = 'FEATURE_ALLOY_WHEELS',
  FEATURE_AMBIENT_LIGHTING = 'FEATURE_AMBIENT_LIGHTING',
  FEATURE_ANDROID_AUTO = 'FEATURE_ANDROID_AUTO',
  FEATURE_ANTIROLLBARS_ACTIVE = 'FEATURE_ANTIROLLBARS_ACTIVE',
  FEATURE_APPLE_CARPLAY = 'FEATURE_APPLE_CARPLAY',
  FEATURE_ARMOURED_CAR = 'FEATURE_ARMOURED_CAR',
  FEATURE_ARMREST = 'FEATURE_ARMREST',
  FEATURE_ARMREST_REAR = 'FEATURE_ARMREST_REAR',
  FEATURE_ATHERMIC_WINDSHIELD = 'FEATURE_ATHERMIC_WINDSHIELD',
  FEATURE_AUDIO_BANG_OLUFSEN = 'FEATURE_AUDIO_BANG_OLUFSEN',
  FEATURE_AUDIO_BOSE = 'FEATURE_AUDIO_BOSE',
  FEATURE_AUDIO_BOWERS_WILKINS = 'FEATURE_AUDIO_BOWERS_WILKINS',
  FEATURE_AUDIO_BURMESTER = 'FEATURE_AUDIO_BURMESTER',
  FEATURE_AUDIO_CANTON = 'FEATURE_AUDIO_CANTON',
  FEATURE_AUDIO_EXTRA = 'FEATURE_AUDIO_EXTRA',
  FEATURE_AUDIO_HARMAN_KARDON = 'FEATURE_AUDIO_HARMAN_KARDON',
  FEATURE_AUDIO_JBL = 'FEATURE_AUDIO_JBL',
  FEATURE_AUDIO_MARK_LEVINSON = 'FEATURE_AUDIO_MARK_LEVINSON',
  FEATURE_AUDIO_MERIDIAN = 'FEATURE_AUDIO_MERIDIAN',
  FEATURE_AUTOMATIC_ACTIVATION_OF_WARNING_LIGHTS = 'FEATURE_AUTOMATIC_ACTIVATION_OF_WARNING_LIGHTS',
  FEATURE_AUTOMATIC_DIFF_LOCK = 'FEATURE_AUTOMATIC_DIFF_LOCK',
  FEATURE_AUTOMATIC_LIGHTS = 'FEATURE_AUTOMATIC_LIGHTS',
  FEATURE_AUTOMATIC_PARKING_BRAKE = 'FEATURE_AUTOMATIC_PARKING_BRAKE',
  FEATURE_AUTONOMOUS_DRIVING = 'FEATURE_AUTONOMOUS_DRIVING',
  FEATURE_AUX = 'FEATURE_AUX',
  FEATURE_AUXILIARYHEATING = 'FEATURE_AUXILIARYHEATING',
  FEATURE_AUXILIARYHEATING_WITHOUT_REMOTE_CONTROL = 'FEATURE_AUXILIARYHEATING_WITHOUT_REMOTE_CONTROL',
  FEATURE_AUXILIARYHEATING_WITH_REMOTE_CONTROL = 'FEATURE_AUXILIARYHEATING_WITH_REMOTE_CONTROL',
  FEATURE_BIKE_CARRIER = 'FEATURE_BIKE_CARRIER',
  FEATURE_BLIND_SPOT_ASSIST = 'FEATURE_BLIND_SPOT_ASSIST',
  FEATURE_BLUETOOTH = 'FEATURE_BLUETOOTH',
  FEATURE_BRAKE_PAD_WEAR_SENSOR = 'FEATURE_BRAKE_PAD_WEAR_SENSOR',
  FEATURE_CARGO_BARRIER = 'FEATURE_CARGO_BARRIER',
  FEATURE_CAR_REFRIGERATOR = 'FEATURE_CAR_REFRIGERATOR',
  FEATURE_CATEGORY_ACCESSORIES_AND_EXTRA_FEATURES = 'FEATURE_CATEGORY_ACCESSORIES_AND_EXTRA_FEATURES',
  FEATURE_CATEGORY_COMFORT_CONVENIENCE = 'FEATURE_CATEGORY_COMFORT_CONVENIENCE',
  FEATURE_CATEGORY_SECURITY_SAFETY_ASSISTANCE = 'FEATURE_CATEGORY_SECURITY_SAFETY_ASSISTANCE',
  FEATURE_CATEGORY_TRANSMISSION_AND_SUSPENSION = 'FEATURE_CATEGORY_TRANSMISSION_AND_SUSPENSION',
  FEATURE_CD_CHANGER = 'FEATURE_CD_CHANGER',
  FEATURE_CD_PLAYER = 'FEATURE_CD_PLAYER',
  FEATURE_CEILINGMATERIAL_ALCANTARA = 'FEATURE_CEILINGMATERIAL_ALCANTARA',
  FEATURE_CEILINGMATERIAL_LEATHER = 'FEATURE_CEILINGMATERIAL_LEATHER',
  FEATURE_CEILINGMATERIAL_PREMIUM = 'FEATURE_CEILINGMATERIAL_PREMIUM',
  FEATURE_CENTRAL_LOCKING = 'FEATURE_CENTRAL_LOCKING',
  FEATURE_CENTRAL_LOCKING_WITH_REMOTE = 'FEATURE_CENTRAL_LOCKING_WITH_REMOTE',
  FEATURE_CONTROL_OF_GARAGE_DOORS = 'FEATURE_CONTROL_OF_GARAGE_DOORS',
  FEATURE_CORNERING_LIGHTS = 'FEATURE_CORNERING_LIGHTS',
  FEATURE_CRUISECONTROL = 'FEATURE_CRUISECONTROL',
  FEATURE_CRUISECONTROL_ADAPTIVE = 'FEATURE_CRUISECONTROL_ADAPTIVE',
  FEATURE_CRUISECONTROL_BASIC = 'FEATURE_CRUISECONTROL_BASIC',
  FEATURE_DAB_RADIO = 'FEATURE_DAB_RADIO',
  FEATURE_DAYTIME_RUNNING_LIGHTS = 'FEATURE_DAYTIME_RUNNING_LIGHTS',
  FEATURE_DIFF_LOCK_FRONT = 'FEATURE_DIFF_LOCK_FRONT',
  FEATURE_DIFF_LOCK_INTER_AXLE = 'FEATURE_DIFF_LOCK_INTER_AXLE',
  FEATURE_DIFF_LOCK_REAR = 'FEATURE_DIFF_LOCK_REAR',
  FEATURE_DIGITAL_COCKPIT = 'FEATURE_DIGITAL_COCKPIT',
  FEATURE_DISABLED_ACCESSIBLE = 'FEATURE_DISABLED_ACCESSIBLE',
  FEATURE_DISPLAY = 'FEATURE_DISPLAY',
  FEATURE_DISPLAY_FIXED = 'FEATURE_DISPLAY_FIXED',
  FEATURE_DISPLAY_TILTING = 'FEATURE_DISPLAY_TILTING',
  FEATURE_DVD_PLAYER = 'FEATURE_DVD_PLAYER',
  FEATURE_DYNAUDIO = 'FEATURE_DYNAUDIO',
  FEATURE_EASY_OPEN_POWER_TAILGATE = 'FEATURE_EASY_OPEN_POWER_TAILGATE',
  FEATURE_EDS = 'FEATURE_EDS',
  FEATURE_ELECTRIC_TAILGATE = 'FEATURE_ELECTRIC_TAILGATE',
  FEATURE_ELECTRIC_WINDOWS_FRONT_ELECTRIC_WINDOWS = 'FEATURE_ELECTRIC_WINDOWS_FRONT_ELECTRIC_WINDOWS',
  FEATURE_ELECTRIC_WINDOWS_REAR_ELECTRIC_WINDOWS = 'FEATURE_ELECTRIC_WINDOWS_REAR_ELECTRIC_WINDOWS',
  FEATURE_EMERGENCY_BRAKING_ASSIST_EBA_BAS = 'FEATURE_EMERGENCY_BRAKING_ASSIST_EBA_BAS',
  FEATURE_EMERGENCY_CALL = 'FEATURE_EMERGENCY_CALL',
  FEATURE_ESP = 'FEATURE_ESP',
  FEATURE_EXTERIOR_LIGHT_PACKET = 'FEATURE_EXTERIOR_LIGHT_PACKET',
  FEATURE_FATIGUE_WARNING_SYSTEM = 'FEATURE_FATIGUE_WARNING_SYSTEM',
  FEATURE_FLAT_LOAD_EDGE = 'FEATURE_FLAT_LOAD_EDGE',
  FEATURE_FOIL = 'FEATURE_FOIL',
  FEATURE_FOLDINGROOF = 'FEATURE_FOLDINGROOF',
  FEATURE_FOLDINGROOF_CLOTH = 'FEATURE_FOLDINGROOF_CLOTH',
  FEATURE_FOLDINGROOF_SOLID = 'FEATURE_FOLDINGROOF_SOLID',
  FEATURE_FORGED_BUMPER_FRONT = 'FEATURE_FORGED_BUMPER_FRONT',
  FEATURE_FORGED_BUMPER_REAR = 'FEATURE_FORGED_BUMPER_REAR',
  FEATURE_FRONT_COLLISION_WARNING_SYSTEM = 'FEATURE_FRONT_COLLISION_WARNING_SYSTEM',
  FEATURE_FRONT_FOG_LIGHTS = 'FEATURE_FRONT_FOG_LIGHTS',
  FEATURE_FRONT_SEATS_AGR = 'FEATURE_FRONT_SEATS_AGR',
  FEATURE_FRONT_SEATS_ELECTRIC_ADJUSTABLE = 'FEATURE_FRONT_SEATS_ELECTRIC_ADJUSTABLE',
  FEATURE_FRONT_SEATS_HEATED = 'FEATURE_FRONT_SEATS_HEATED',
  FEATURE_FRONT_SEATS_HEIGHT_ADJUSTABLE_DRIVER = 'FEATURE_FRONT_SEATS_HEIGHT_ADJUSTABLE_DRIVER',
  FEATURE_FRONT_SEATS_HEIGHT_ADJUSTABLE_PASSENGER = 'FEATURE_FRONT_SEATS_HEIGHT_ADJUSTABLE_PASSENGER',
  FEATURE_FRONT_SEATS_PASSENGER_SEAT_ISOFIX_POINT = 'FEATURE_FRONT_SEATS_PASSENGER_SEAT_ISOFIX_POINT',
  FEATURE_FRONT_SEATS_SPORT_SEATS = 'FEATURE_FRONT_SEATS_SPORT_SEATS',
  FEATURE_FRONT_SEATS_VENTILATED = 'FEATURE_FRONT_SEATS_VENTILATED',
  FEATURE_FRONT_SEATS_WITH_MASSAGE_FUNCTION_DRIVER = 'FEATURE_FRONT_SEATS_WITH_MASSAGE_FUNCTION_DRIVER',
  FEATURE_FRONT_SEATS_WITH_MASSAGE_FUNCTION_PASSENGER = 'FEATURE_FRONT_SEATS_WITH_MASSAGE_FUNCTION_PASSENGER',
  FEATURE_FRONT_SEATS_WITH_MEMORY = 'FEATURE_FRONT_SEATS_WITH_MEMORY',
  FEATURE_GEAR_SHIFT_LOCK = 'FEATURE_GEAR_SHIFT_LOCK',
  FEATURE_GESTURE_CONTROL = 'FEATURE_GESTURE_CONTROL',
  FEATURE_GLARE_FREE_HIGH_BEAM_HEADLIGHTS = 'FEATURE_GLARE_FREE_HIGH_BEAM_HEADLIGHTS',
  FEATURE_GPS_TRACKING_SYSTEM = 'FEATURE_GPS_TRACKING_SYSTEM',
  FEATURE_HANDSFREE = 'FEATURE_HANDSFREE',
  FEATURE_HANDSFREE_MOUNTED = 'FEATURE_HANDSFREE_MOUNTED',
  FEATURE_HANDSFREE_ORIGINAL = 'FEATURE_HANDSFREE_ORIGINAL',
  FEATURE_HEADLAMP_WASHERS = 'FEATURE_HEADLAMP_WASHERS',
  FEATURE_HEADLIGHTSTYPE_BI_XENON = 'FEATURE_HEADLIGHTSTYPE_BI_XENON',
  FEATURE_HEADLIGHTSTYPE_EXTRA = 'FEATURE_HEADLIGHTSTYPE_EXTRA',
  FEATURE_HEADLIGHTSTYPE_LASER = 'FEATURE_HEADLIGHTSTYPE_LASER',
  FEATURE_HEADLIGHTSTYPE_LED = 'FEATURE_HEADLIGHTSTYPE_LED',
  FEATURE_HEADLIGHTSTYPE_XENON = 'FEATURE_HEADLIGHTSTYPE_XENON',
  FEATURE_HEAD_UP_DISPLAY = 'FEATURE_HEAD_UP_DISPLAY',
  FEATURE_HEATED_STEERING_WHEEL = 'FEATURE_HEATED_STEERING_WHEEL',
  FEATURE_HEATED_WINDSHIELD = 'FEATURE_HEATED_WINDSHIELD',
  FEATURE_HEAT_PUMP = 'FEATURE_HEAT_PUMP',
  FEATURE_HEIGHT_ADJUSTABLE_SUSPENSION = 'FEATURE_HEIGHT_ADJUSTABLE_SUSPENSION',
  FEATURE_HIGH_BEAM_ASSIST = 'FEATURE_HIGH_BEAM_ASSIST',
  FEATURE_HILL_DESCEND_ASSIST = 'FEATURE_HILL_DESCEND_ASSIST',
  FEATURE_HILL_START_ASSIST = 'FEATURE_HILL_START_ASSIST',
  FEATURE_IMMOBILIZER = 'FEATURE_IMMOBILIZER',
  FEATURE_INDOOR_TEMPERATURE_SENSOR = 'FEATURE_INDOOR_TEMPERATURE_SENSOR',
  FEATURE_INDUCTION_CHARGING_FOR_SMARTPHONES = 'FEATURE_INDUCTION_CHARGING_FOR_SMARTPHONES',
  FEATURE_INTEGRATED_MUSIC_STREAMING = 'FEATURE_INTEGRATED_MUSIC_STREAMING',
  FEATURE_INTERNET_CONNECTION = 'FEATURE_INTERNET_CONNECTION',
  FEATURE_KEYLESS_ENTRY = 'FEATURE_KEYLESS_ENTRY',
  FEATURE_KEYLESS_IGNITION = 'FEATURE_KEYLESS_IGNITION',
  FEATURE_LEATHER_STEERING_WHEEL = 'FEATURE_LEATHER_STEERING_WHEEL',
  FEATURE_LIGHT_SENSOR = 'FEATURE_LIGHT_SENSOR',
  FEATURE_LINE_ASSIST = 'FEATURE_LINE_ASSIST',
  FEATURE_MONITORS_IN_HEADRESTS = 'FEATURE_MONITORS_IN_HEADRESTS',
  FEATURE_MP3_INTERFACE = 'FEATURE_MP3_INTERFACE',
  FEATURE_MULTIFUNCTIONAL_STEERING_WHEEL = 'FEATURE_MULTIFUNCTIONAL_STEERING_WHEEL',
  FEATURE_NAVIGATION_SYSTEM = 'FEATURE_NAVIGATION_SYSTEM',
  FEATURE_NIGHT_VISSION_ASSIST = 'FEATURE_NIGHT_VISSION_ASSIST',
  FEATURE_NUMBER_OF_AIRBAGS = 'FEATURE_NUMBER_OF_AIRBAGS',
  FEATURE_NUMBER_OF_AIRBAGS_1 = 'FEATURE_NUMBER_OF_AIRBAGS_1',
  FEATURE_NUMBER_OF_AIRBAGS_10 = 'FEATURE_NUMBER_OF_AIRBAGS_10',
  FEATURE_NUMBER_OF_AIRBAGS_12 = 'FEATURE_NUMBER_OF_AIRBAGS_12',
  FEATURE_NUMBER_OF_AIRBAGS_14 = 'FEATURE_NUMBER_OF_AIRBAGS_14',
  FEATURE_NUMBER_OF_AIRBAGS_2 = 'FEATURE_NUMBER_OF_AIRBAGS_2',
  FEATURE_NUMBER_OF_AIRBAGS_4 = 'FEATURE_NUMBER_OF_AIRBAGS_4',
  FEATURE_NUMBER_OF_AIRBAGS_6 = 'FEATURE_NUMBER_OF_AIRBAGS_6',
  FEATURE_NUMBER_OF_AIRBAGS_7 = 'FEATURE_NUMBER_OF_AIRBAGS_7',
  FEATURE_NUMBER_OF_AIRBAGS_8 = 'FEATURE_NUMBER_OF_AIRBAGS_8',
  FEATURE_NUMBER_OF_AIRBAGS_9 = 'FEATURE_NUMBER_OF_AIRBAGS_9',
  FEATURE_ON_BOARD_COMPUTER = 'FEATURE_ON_BOARD_COMPUTER',
  FEATURE_OUTDOOR_TEMPERATURE_SENSOR = 'FEATURE_OUTDOOR_TEMPERATURE_SENSOR',
  FEATURE_PADDLE_SHIFTERS = 'FEATURE_PADDLE_SHIFTERS',
  FEATURE_PANORAMIC_ROOF = 'FEATURE_PANORAMIC_ROOF',
  FEATURE_PARKINGCAMERA = 'FEATURE_PARKINGCAMERA',
  FEATURE_PARKINGCAMERA_3600 = 'FEATURE_PARKINGCAMERA_3600',
  FEATURE_PARKINGCAMERA_FRONT_REAR = 'FEATURE_PARKINGCAMERA_FRONT_REAR',
  FEATURE_PARKINGCAMERA_REAR_ONLY = 'FEATURE_PARKINGCAMERA_REAR_ONLY',
  FEATURE_PARKINGSENSORS = 'FEATURE_PARKINGSENSORS',
  FEATURE_PARKINGSENSORS_FRONT_REAR = 'FEATURE_PARKINGSENSORS_FRONT_REAR',
  FEATURE_PARKINGSENSORS_REAR_ONLY = 'FEATURE_PARKINGSENSORS_REAR_ONLY',
  FEATURE_PARKING_ASSIST_SYSTEM_SELF_STEERING = 'FEATURE_PARKING_ASSIST_SYSTEM_SELF_STEERING',
  FEATURE_PASSENGER_AIRBAG_DEACTIVATION = 'FEATURE_PASSENGER_AIRBAG_DEACTIVATION',
  FEATURE_PEDESTRIAN_PROTECTION_SYSTEM = 'FEATURE_PEDESTRIAN_PROTECTION_SYSTEM',
  FEATURE_POWER_ASSISTED_STEERING = 'FEATURE_POWER_ASSISTED_STEERING',
  FEATURE_PREDICTIVE_EMERGENCY_BRAKING_SYSTEM_PEBS = 'FEATURE_PREDICTIVE_EMERGENCY_BRAKING_SYSTEM_PEBS',
  FEATURE_RACING_MODIFICATIONS = 'FEATURE_RACING_MODIFICATIONS',
  FEATURE_RADIO = 'FEATURE_RADIO',
  FEATURE_RAIN_SENSOR = 'FEATURE_RAIN_SENSOR',
  FEATURE_REARLIGHTS_LED = 'FEATURE_REARLIGHTS_LED',
  FEATURE_REARSPOILER = 'FEATURE_REARSPOILER',
  FEATURE_REARSPOILER_ACTIVE = 'FEATURE_REARSPOILER_ACTIVE',
  FEATURE_REARSPOILER_FIXED = 'FEATURE_REARSPOILER_FIXED',
  FEATURE_REAR_CROSS_TRAFFIC_ALERT_RCTA = 'FEATURE_REAR_CROSS_TRAFFIC_ALERT_RCTA',
  FEATURE_REAR_DOOR_SUN_SHADES = 'FEATURE_REAR_DOOR_SUN_SHADES',
  FEATURE_REAR_SEATS_DIVIDED = 'FEATURE_REAR_SEATS_DIVIDED',
  FEATURE_REAR_SEATS_ELECTRIC_ADJUSTABLE_REAR_SEATS = 'FEATURE_REAR_SEATS_ELECTRIC_ADJUSTABLE_REAR_SEATS',
  FEATURE_REAR_SEATS_FOLDABLE = 'FEATURE_REAR_SEATS_FOLDABLE',
  FEATURE_REAR_SEATS_HEATED_REAR_SEATS = 'FEATURE_REAR_SEATS_HEATED_REAR_SEATS',
  FEATURE_REAR_SEATS_ISOFIX = 'FEATURE_REAR_SEATS_ISOFIX',
  FEATURE_REAR_SEATS_REMOVABLE = 'FEATURE_REAR_SEATS_REMOVABLE',
  FEATURE_REAR_SEATS_VENTILATION = 'FEATURE_REAR_SEATS_VENTILATION',
  FEATURE_REAR_SEATS_WITH_MASSAGES = 'FEATURE_REAR_SEATS_WITH_MASSAGES',
  FEATURE_REAR_WHEEL_STEERING = 'FEATURE_REAR_WHEEL_STEERING',
  FEATURE_REAR_WIPER = 'FEATURE_REAR_WIPER',
  FEATURE_REDUCTION_GEARBOX = 'FEATURE_REDUCTION_GEARBOX',
  FEATURE_ROLL_BAR = 'FEATURE_ROLL_BAR',
  FEATURE_ROOF_CROSS_BARS = 'FEATURE_ROOF_CROSS_BARS',
  FEATURE_ROOF_RACK = 'FEATURE_ROOF_RACK',
  FEATURE_ROOF_RAILS = 'FEATURE_ROOF_RAILS',
  FEATURE_SIDE_MIRRORS_AUTO_DIMMING_SIDE_MIRRORS = 'FEATURE_SIDE_MIRRORS_AUTO_DIMMING_SIDE_MIRRORS',
  FEATURE_SIDE_MIRRORS_ELECTRICALLY_FOLDABLE = 'FEATURE_SIDE_MIRRORS_ELECTRICALLY_FOLDABLE',
  FEATURE_SIDE_MIRRORS_ELECTRICALLY_HEATED = 'FEATURE_SIDE_MIRRORS_ELECTRICALLY_HEATED',
  FEATURE_SIDE_MIRRORS_WITHOUT_ELECTRIC_ADJUSTMENT = 'FEATURE_SIDE_MIRRORS_WITHOUT_ELECTRIC_ADJUSTMENT',
  FEATURE_SIDE_MIRRORS_WITH_ELECTRIC_ADJUSTMENT = 'FEATURE_SIDE_MIRRORS_WITH_ELECTRIC_ADJUSTMENT',
  FEATURE_SIDE_STEPS = 'FEATURE_SIDE_STEPS',
  FEATURE_SKI_BAG = 'FEATURE_SKI_BAG',
  FEATURE_SLIDINGDOORS = 'FEATURE_SLIDINGDOORS',
  FEATURE_SLIDINGDOORS_BOTH_SIDES = 'FEATURE_SLIDINGDOORS_BOTH_SIDES',
  FEATURE_SLIDINGDOORS_LEFT = 'FEATURE_SLIDINGDOORS_LEFT',
  FEATURE_SLIDINGDOORS_RIGHT = 'FEATURE_SLIDINGDOORS_RIGHT',
  FEATURE_SLOT_FOR_MEMORY_CARD = 'FEATURE_SLOT_FOR_MEMORY_CARD',
  FEATURE_SMART_KEY = 'FEATURE_SMART_KEY',
  FEATURE_SNORKEL = 'FEATURE_SNORKEL',
  FEATURE_SOFT_CLOSE_ALL_DOORS = 'FEATURE_SOFT_CLOSE_ALL_DOORS',
  FEATURE_SOFT_CLOSE_TAILGATE = 'FEATURE_SOFT_CLOSE_TAILGATE',
  FEATURE_SPARETYRE = 'FEATURE_SPARETYRE',
  FEATURE_SPARETYRE_EMERGENCY_SPARE_TYRE = 'FEATURE_SPARETYRE_EMERGENCY_SPARE_TYRE',
  FEATURE_SPARETYRE_FULL_SPARE_TYRE = 'FEATURE_SPARETYRE_FULL_SPARE_TYRE',
  FEATURE_SPARETYRE_TYRE_REPAIR_KIT = 'FEATURE_SPARETYRE_TYRE_REPAIR_KIT',
  FEATURE_SPORT_EXHAUST_SYSTEM = 'FEATURE_SPORT_EXHAUST_SYSTEM',
  FEATURE_SPORT_PACKAGE = 'FEATURE_SPORT_PACKAGE',
  FEATURE_SPORT_SUSPENSION_SYSTEM = 'FEATURE_SPORT_SUSPENSION_SYSTEM',
  FEATURE_START_STOP_SYSTEM = 'FEATURE_START_STOP_SYSTEM',
  FEATURE_SUNROOF = 'FEATURE_SUNROOF',
  FEATURE_SUNROOF_ELECTRIC = 'FEATURE_SUNROOF_ELECTRIC',
  FEATURE_SUNROOF_MANUAL = 'FEATURE_SUNROOF_MANUAL',
  FEATURE_TAILGATEOPENING = 'FEATURE_TAILGATEOPENING',
  FEATURE_TAILGATEOPENING_SIDEWAYS = 'FEATURE_TAILGATEOPENING_SIDEWAYS',
  FEATURE_TAILGATEOPENING_UP = 'FEATURE_TAILGATEOPENING_UP',
  FEATURE_TAILGATEOPENING_UP_AND_DOWN = 'FEATURE_TAILGATEOPENING_UP_AND_DOWN',
  FEATURE_TAXI = 'FEATURE_TAXI',
  FEATURE_TELEPHONE = 'FEATURE_TELEPHONE',
  FEATURE_THIRD_ROW_SEATS = 'FEATURE_THIRD_ROW_SEATS',
  FEATURE_TINTED_WINDOWS = 'FEATURE_TINTED_WINDOWS',
  FEATURE_TIRE_CHAINS = 'FEATURE_TIRE_CHAINS',
  FEATURE_TOUCH_SCREEN = 'FEATURE_TOUCH_SCREEN',
  FEATURE_TRACTION_CONTROL_TC_ASR = 'FEATURE_TRACTION_CONTROL_TC_ASR',
  FEATURE_TRAFFIC_SIGN_RECOGNITION = 'FEATURE_TRAFFIC_SIGN_RECOGNITION',
  FEATURE_TRAILERCOUPLING = 'FEATURE_TRAILERCOUPLING',
  FEATURE_TRAILERCOUPLING_DETACHABLE = 'FEATURE_TRAILERCOUPLING_DETACHABLE',
  FEATURE_TRAILERCOUPLING_FIXED = 'FEATURE_TRAILERCOUPLING_FIXED',
  FEATURE_TRAILERCOUPLING_WITH_ELECTRIC_FOLDING = 'FEATURE_TRAILERCOUPLING_WITH_ELECTRIC_FOLDING',
  FEATURE_TRAILER_STABILITY_ASSIST_TSA = 'FEATURE_TRAILER_STABILITY_ASSIST_TSA',
  FEATURE_TUNING = 'FEATURE_TUNING',
  FEATURE_TV = 'FEATURE_TV',
  FEATURE_TYRE_PRESSURE_MONITORING = 'FEATURE_TYRE_PRESSURE_MONITORING',
  FEATURE_USB = 'FEATURE_USB',
  FEATURE_VARIABLE_STIFFNESS_SUSPENSION = 'FEATURE_VARIABLE_STIFFNESS_SUSPENSION',
  FEATURE_VOICE_CONTROL = 'FEATURE_VOICE_CONTROL',
  FEATURE_WINCH = 'FEATURE_WINCH',
  FEATURE_WINTER_TYRES = 'FEATURE_WINTER_TYRES',
  FEATURE_WINTER_WHEEL_SET = 'FEATURE_WINTER_WHEEL_SET',
  FEATURE_WLAN_WIFI_HOTSPOT = 'FEATURE_WLAN_WIFI_HOTSPOT',
  FEATURE_WOOD_TRIM = 'FEATURE_WOOD_TRIM',
  FRONT_BUMPER = 'FRONT_BUMPER',
  FRONT_HOOD = 'FRONT_HOOD',
  FUEL_LEAK_FROM_FUEL_SYSTEM = 'FUEL_LEAK_FROM_FUEL_SYSTEM',
  FUEL_SYSTEM = 'FUEL_SYSTEM',
  LAST_CHANGE_OF_BREAK_FLUIDS = 'LAST_CHANGE_OF_BREAK_FLUIDS',
  LAST_CHANGE_OF_DIFFERENTIAL_OIL = 'LAST_CHANGE_OF_DIFFERENTIAL_OIL',
  LAST_CHANGE_OF_GEARBOX_OIL = 'LAST_CHANGE_OF_GEARBOX_OIL',
  LAST_OIL_CHANGE = 'LAST_OIL_CHANGE',
  LEAKAGE_OF_OPERATING_FLUIDS_FROM_OTHER_PARTS_OF_THE_DRIVE_TRAIIN = 'LEAKAGE_OF_OPERATING_FLUIDS_FROM_OTHER_PARTS_OF_THE_DRIVE_TRAIIN',
  LEAKAGE_OF_OPERATING_FLUIDS_FROM_THE_ENGINE = 'LEAKAGE_OF_OPERATING_FLUIDS_FROM_THE_ENGINE',
  LEAKAGE_OF_OPERATING_FLUIDS_FROM_THE_GEARBOX_AUXILIARY_GEARBOX = 'LEAKAGE_OF_OPERATING_FLUIDS_FROM_THE_GEARBOX_AUXILIARY_GEARBOX',
  LF_DOOR_TRIM = 'LF_DOOR_TRIM',
  LF_FENDER = 'LF_FENDER',
  LF_WINDOW = 'LF_WINDOW',
  LR_DOOR = 'LR_DOOR',
  LR_DOOR_TRIM = 'LR_DOOR_TRIM',
  LR_FENDER = 'LR_FENDER',
  LR_LIGHT = 'LR_LIGHT',
  LR_WINDOW = 'LR_WINDOW',
  MANDATORY_EQUIPMENT_AND_BASIC_ACCESSORIES = 'MANDATORY_EQUIPMENT_AND_BASIC_ACCESSORIES',
  MOT_VALIDITY = 'MOT_VALIDITY',
  OTHER_PARTS_OF_THE_DRIVE_SYSTEM_DIFFRENTIALS_SEMI_AXLES_CARDAN_SHAFTS = 'OTHER_PARTS_OF_THE_DRIVE_SYSTEM_DIFFRENTIALS_SEMI_AXLES_CARDAN_SHAFTS',
  PAD_BRAKE_LINING_THICKNESS_IN_MM = 'PAD/BRAKE LINING_THICKNESS_IN_MM',
  PAD_BRAKE_LINING_WEAR_IN_PERC = 'PAD/BRAKE LINING_WEAR_IN_PERC',
  PANORAMATIC_ROOF_WINDOW = 'PANORAMATIC_ROOF/WINDOW',
  PLAY_IN_GEARBOX_AUXILIARY_GEARBOX = 'PLAY_IN_GEARBOX_AUXILIARY_GEARBOX',
  PLAY_IN_OTHER_PARTS_OF_THE_DRIVE_TRAIN = 'PLAY_IN_OTHER_PARTS_OF_THE_DRIVE_TRAIN',
  REAR_BUMPER = 'REAR_BUMPER',
  REFRIGERANT_LEAK_FROM_COOLING_SYSTEM = 'REFRIGERANT_LEAK_FROM_COOLING_SYSTEM',
  RF_DOOR_TRIM = 'RF_DOOR_TRIM',
  RF_FENDER = 'RF_FENDER',
  RF_WINDOW = 'RF_WINDOW',
  ROOF = 'ROOF',
  RR_DOOR = 'RR_DOOR',
  RR_DOOR_TRIM = 'RR_DOOR_TRIM',
  RR_FENDER = 'RR_FENDER',
  RR_WINDOW = 'RR_WINDOW',
  SAFETY_NUTS_WHEEL_BOLTS = 'SAFETY_NUTS/WHEEL_BOLTS',
  SERVICE_HISTORY = 'SERVICE_HISTORY',
  SERVICE_INSPECTIONS = 'SERVICE_INSPECTIONS',
  SPARE_WHEEL = 'SPARE_WHEEL',
  STANDARD_HEATING = 'STANDARD_HEATING',
  STATE_OF_DPF_FILTERS = 'STATE_OF_DPF_FILTERS',
  STATE_OF_ENGINE = 'STATE_OF_ENGINE',
  STATE_OF_THE_ELECTRICAL_SYSTEM = 'STATE_OF_THE_ELECTRICAL_SYSTEM',
  STEERING_WHEEL_STATE = 'STEERING_WHEEL_STATE',
  SUGGESTED_CHANGE_OF_WHEELS_AND_TIRES = 'SUGGESTED_CHANGE_OF_WHEELS_AND_TIRES',
  SUITCASE = 'SUITCASE',
  SUSPENSION = 'SUSPENSION',
  THE_SUITCASE_LID = 'THE_SUITCASE_LID',
  TRACES_OF_ACCIDENT_REPAIR = 'TRACES_OF_ACCIDENT_REPAIR',
  TRANSMISSION_AUXILIARY_TRANSMISSION = 'TRANSMISSION_AUXILIARY_TRANSMISSION',
  VEHICLE_AXEL = 'VEHICLE_AXEL',
  VEHICLE_DRIVE = 'VEHICLE_DRIVE',
  VEHICLE_IDENTIFICATION_NUMBER = 'VEHICLE_IDENTIFICATION_NUMBER',
  VEHICLE_LIGHTNING = 'VEHICLE_LIGHTNING',
  VEHICLE_UNDER_FACTORY_WARRANTY = 'VEHICLE_UNDER_FACTORY_WARRANTY',
  WINDSHIELD = 'WINDSHIELD',
}
