import {AddressData} from '@dms/api/customer';

import {Nullish} from 'shared';

type CustomerWithAddressProperties = {
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  zip: string | null;
  country: string | null;
  [key: string]: unknown;
};

export const accountingCustomerToAddressData = <T extends CustomerWithAddressProperties>(
  customer: T | Nullish
): AddressData => ({
  addressLine1: customer?.addressLine1 ?? null,
  addressLine2: customer?.addressLine2 ?? null,
  city: customer?.city ?? null,
  countryCode: customer?.country ?? null,
  postalCode: customer?.zip ?? null,
  invalid: null,
  type: null,
  province: null,
});
