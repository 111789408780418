import {FormControl, FormField, isCurrency} from 'platform/components';
import {Grid, GridItem, Heading, VStack} from 'platform/foundation';

import {not} from 'ramda';

import i18n from '@dms/i18n';
import {useGetVatRatesOptions} from '@dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {FALLBACK_HANDLING_UNIT} from '../../../../../constants/fallbackHandlingUnit';
import {ReceiveNoteItemFormType} from './types/ReceiveNoteItemDetailSchema';

export interface ReceiveNoteItemFormProps extends RequiredTestIdProps {
  control: FormControl<ReceiveNoteItemFormType>;
  handlingUnit: string | Nullish;
  currency: string | Nullish;
  isChecked: boolean;
  isEditingDisabled: boolean;
}

export function ReceiveNoteItemForm(props: ReceiveNoteItemFormProps) {
  const [vatOptions] = useGetVatRatesOptions({fullLabel: true});

  return (
    <VStack spacing={4} data-testid={props['data-testid']}>
      <Heading size={5}>{i18n.t('entity.warehouse.labels.receiveNoteItem')}</Heading>
      <Grid columns={4}>
        <GridItem span={1}>
          <FormField
            type="number"
            name="receiveNoteItem.quantity"
            label={i18n.t('general.labels.quantity')}
            control={props.control}
            isDisabled={not(props.isChecked) || props.isEditingDisabled}
            decimalPlaces={4}
            suffix={props.handlingUnit ?? FALLBACK_HANDLING_UNIT}
            data-testid={suffixTestId('inputs.quantity', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            type="currency"
            name="receiveNoteItem.unitPrice"
            label={i18n.t('general.labels.unitPrice')}
            control={props.control}
            isDisabled={not(props.isChecked) || props.isEditingDisabled}
            currency={isCurrency(props.currency) ? props.currency : undefined}
            decimalPlaces={2}
            data-testid={suffixTestId('inputs.unitPrice', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            type="currency"
            name="receiveNoteItem.totalPrice"
            label={i18n.t('general.labels.totalPrice')}
            control={props.control}
            isDisabled={not(props.isChecked) || props.isEditingDisabled}
            currency={isCurrency(props.currency) ? props.currency : undefined}
            decimalPlaces={2}
            data-testid={suffixTestId('inputs.totalPrice', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            type="choice"
            name="receiveNoteItem.vatType"
            label={i18n.t('general.labels.vat')}
            options={vatOptions}
            control={props.control}
            isNotClearable
            isDisabled={not(props.isChecked) || props.isEditingDisabled}
            menuInPortal
            data-testid={suffixTestId('inputs.vatType', props)}
          />
        </GridItem>
      </Grid>
    </VStack>
  );
}
