import {
  addDays,
  Day,
  eachWeekOfInterval,
  endOfMonth,
  format,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import {Box, Heading, Center, Show, Hide, useDevice, getValueByDevice} from 'platform/foundation';
import {convertStringLocaleToDateFns, DayOfWeek, useLocale} from 'platform/locale';
import {css} from 'styled-components';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {MAX_DAY_WIDTH} from '../constants/maxDayWidth';
import {BigCalendarEvent} from '../types/BigCalendarEvent';
import {compareDates} from '../utils/compareDates';
import {sortEvents} from '../utils/sortEvents';
import {MonthCell} from './MonthCell';

interface MonthViewProps extends RequiredTestIdProps {
  currentDate: Date;
  events: BigCalendarEvent[];
  isReadOnly?: boolean;
  onEventClick?: (event: BigCalendarEvent) => void;
  onDateClick?: (date: Date) => void;
}

export function MonthView(props: MonthViewProps) {
  const {language, localeConfig} = useLocale();
  const device = useDevice();

  const weekStartsOn = match<DayOfWeek, Day>(localeConfig.calendar.firstDayOfTheWeek)
    .with(DayOfWeek.MONDAY, always(1))
    .with(DayOfWeek.TUESDAY, always(2))
    .with(DayOfWeek.WEDNESDAY, always(3))
    .with(DayOfWeek.THURSDAY, always(4))
    .with(DayOfWeek.FRIDAY, always(5))
    .with(DayOfWeek.SATURDAY, always(6))
    .with(DayOfWeek.SUNDAY, always(0))
    .otherwise(always(1));

  const monthStart = startOfMonth(props.currentDate);
  const monthEnd = endOfMonth(props.currentDate);
  const monthStartWeek = startOfWeek(monthStart, {
    weekStartsOn,
  });

  const weekDays = Array.from({length: NUMBER_OF_DAYS_IN_WEEK}, (_, i) =>
    addDays(monthStartWeek, i)
  );

  const weeks = eachWeekOfInterval(
    {
      start: monthStart,
      end: monthEnd,
    },
    {weekStartsOn}
  );

  const getEventsForDate = (date: Date) =>
    props.events.filter((event) => compareDates(event.start, date)).sort(sortEvents);

  return (
    <Box position="relative" width="100%" height="100%">
      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(${NUMBER_OF_DAYS_IN_WEEK}, 1fr);
          gap: 0 0;
          overflow: auto;
          -webkit-overflow-scrolling: touch;
        `}
      >
        {weekDays.map((date, weekDayIndex) => (
          <Box
            key={`month-cell-heading-${date.toString()}`}
            height={10}
            minWidth={MAX_DAY_WIDTH}
            maxWidth={getValueByDevice(device, MAX_DAY_WIDTH, undefined)}
            paddingHorizontal={2}
            borderBottom="1px solid"
            borderRight={weekDayIndex === 6 ? '1px solid' : 'none'}
            borderLeft="1px solid"
            borderColor="general.separator"
            position="relative"
          >
            <Center height="100%" justify="flex-start">
              <Show onMobile>
                <Heading
                  size={4}
                  data-testid={suffixTestId(`monthView-dayHeader-[${weekDayIndex}]`, props)}
                >
                  {format(date, 'E', {locale: convertStringLocaleToDateFns(language)})}
                </Heading>
              </Show>
              <Hide onMobile>
                <Heading
                  size={4}
                  data-testid={suffixTestId(`monthView-dayHeader-[${weekDayIndex}]`, props)}
                >
                  {format(date, 'EEEE', {locale: convertStringLocaleToDateFns(language)})}
                </Heading>
              </Hide>
            </Center>
          </Box>
        ))}
        {weeks.map((week, weekIndex) =>
          Array.from({length: NUMBER_OF_DAYS_IN_WEEK}, (_, dayIndex) => {
            const date = addDays(week, dayIndex);
            return (
              <Box
                key={`month-cell-${date.toISOString()}`}
                minWidth={MAX_DAY_WIDTH}
                maxWidth={getValueByDevice(device, MAX_DAY_WIDTH, undefined)}
                borderRight={dayIndex === 6 ? '1px solid' : 'none'}
                borderColor="general.separator"
                position="relative"
              >
                <MonthCell
                  data-testid={suffixTestId(`monthView-[${weekIndex}]-[${dayIndex}]`, props)}
                  date={date}
                  currentMonth={props.currentDate}
                  events={getEventsForDate(date)}
                  isReadOnly={props.isReadOnly}
                  onDateClick={props.onDateClick}
                  onEventClick={props.onEventClick}
                />
              </Box>
            );
          })
        )}
      </div>
    </Box>
  );
}

const NUMBER_OF_DAYS_IN_WEEK = 7;
