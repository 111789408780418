import {Attributes, Separator} from 'platform/components';
import {
  getColorFromSeverity,
  getIconFromSeverity,
  Heading,
  HStack,
  Icon,
  Link,
  Severity,
  Show,
  Space,
  Text,
} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {useDeprecatedGetVerificationRegistersQuery} from '@dms/api/businessCase';
import {VerificationResultType} from '@dms/api/customer';
import i18n from '@dms/i18n';

import {parseDate, TestIdProps} from 'shared';

interface CustomerVerificationResultProps extends TestIdProps {
  contractInformationId: string;
  businessCaseId: string;
}

export function CustomerVerificationResult(props: CustomerVerificationResultProps) {
  const formatDateTime = useDateTimeFormatter();

  const {data: registers} = useDeprecatedGetVerificationRegistersQuery({
    contractInformationId: props.contractInformationId,
    businessCaseId: props.businessCaseId,
  });

  const verificatedRegisters = registers?.filter(
    (register) =>
      !!(register.verificationInfo?.requestedAt && register.verificationInfo?.requestedBy)
  );

  if (!verificatedRegisters?.length) {
    return null;
  }
  const verificationInfo = verificatedRegisters?.[0]?.verificationInfo;

  return (
    <>
      <Separator />
      <Heading size={5}>{i18n.t('entity.customerVerification.labels.results')}</Heading>
      <Space vertical={1} />
      <Text size="xSmall" color="tertiary">
        {i18n.t('entity.customerVerification.labels.lastUpdate', {
          date: formatDateTime('dateShort', parseDate(verificationInfo?.requestedAt ?? '')),
          name: verificationInfo?.requestedBy,
        })}
      </Text>
      <Space vertical={4} />
      <Attributes
        size="quarter"
        rows={verificatedRegisters.map((register) => {
          const severity = getSeverityFromResultType(register.verificationInfo?.resultType);

          return {
            label: i18n.t(
              `entity.customerVerification.labels.registers.${register.verificationRegisterType}`
            ),
            content: (
              <HStack spacing={2}>
                <Icon
                  value={getIconFromSeverity(severity)}
                  color={getColorFromSeverity(severity)}
                  size={4}
                />
                <Text size="xSmall" color={severity}>
                  {register.verificationInfo?.resultDescription}
                </Text>
                <Show when={register.verificationInfo?.resultUrl}>
                  <Space fillAvailable />
                  <Link
                    size="xSmall"
                    href={register.verificationInfo?.resultUrl}
                    target="_blank"
                    title={i18n.t('entity.customerVerification.actions.showResults')}
                  />
                </Show>
              </HStack>
            ),
          };
        })}
      />
      <Separator />
    </>
  );
}

const getSeverityFromResultType = (type?: VerificationResultType): Severity => {
  if ('error' === type) {
    return 'danger';
  }
  return type ?? 'informational';
};
