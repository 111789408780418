import {Menu, MenuItem} from 'platform/components';
import {Box, HStack, Show} from 'platform/foundation';

import {ReactNode, useEffect} from 'react';

import {isNil} from 'ramda';

import featureFlags from '@dms/feature-flags';

import {RequiredTestIdProps, suffixTestId, useQueryState} from 'shared';

import {queryParams} from '../../utils/queryParams';
import {ControlledSection} from './types/ControlledSection';

export type SectionItem = {
  content: ReactNode;
} & MenuItem;

export type SectionsProps = {
  sections: SectionItem[];
  defaultSection?: string;
  activityPanel?: ReactNode;
} & RequiredTestIdProps &
  ControlledSection;

export function Sections(props: SectionsProps) {
  const [innerActiveSectionId, setInnerActiveSectionId] = useQueryState(
    queryParams.COMPONENT_SECTIONS_SECTION
  );

  const activeSectionId =
    props.activeSectionId ?? innerActiveSectionId ?? props.defaultSection ?? props.sections[0].id;

  const getSectionById = props.sections.find(({id}) => id === activeSectionId);

  useEffect(() => {
    if (props.activeSectionId === activeSectionId) {
      return;
    }

    if (isNil(props.activeSectionId)) {
      const isSelectedSection = props.sections.some((section) => section.id === activeSectionId);

      if (isSelectedSection) {
        return;
      }

      setInnerActiveSectionId(props.defaultSection ?? props.sections[0].id);
      return;
    }

    setInnerActiveSectionId(props.activeSectionId);
  }, [props.activeSectionId]);

  const setActiveSectionId = props.onSectionChange ?? setInnerActiveSectionId;

  const handleSectionChange = (sectionId: string) => {
    setActiveSectionId(sectionId);
  };

  return (
    <HStack>
      <Show when={props.sections}>
        <Box minWidth={54} width={54}>
          {props.sections && (
            <Box paddingVertical={4} paddingLeft={4}>
              <Menu
                items={props.sections}
                activeItemId={activeSectionId}
                onItemChange={handleSectionChange}
                data-testid={suffixTestId('page-navigation', props)}
              />
            </Box>
          )}
        </Box>
      </Show>
      <HStack spacing={4} width="100%">
        {getSectionById?.content}
        <Show when={props.activityPanel} whenFeatureEnabled={featureFlags.CRM_SALES_ACTIVITIES}>
          {props.activityPanel}
        </Show>
      </HStack>
    </HStack>
  );
}
