import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  DialogFooter,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Box, HStack, Space, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {UseFormReturn} from 'react-hook-form';

import {isNil, mergeRight} from 'ramda';

import {useGetTenantV2Query} from '@dms/api/core';
import {AddressRequestBodyV2} from '@dms/api/customer';
import {AddrDetailApiResponse} from '@dms/api/metadaAddress';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps, yupString} from 'shared';

import {emptyAddressPol} from '../../constants/emptyValues';
import {AddressSearch} from '../AddressSearch/AddressSearch';
import {CountrySelect} from '../CountrySelect/CountrySelect';

interface AddressFormPolProps extends TestIdProps {
  isLoading?: boolean;
  isEdit?: boolean;
  address?: AddressRequestBodyV2;
  onSubmit: FormSubmitHandler<AddressRequestBodyV2>;
  onClose?: () => void;
}

export function AddressFormPol(props: AddressFormPolProps) {
  const defaultValues = mergeRight(emptyAddressPol, props.address ?? {});
  const {data: tenant} = useGetTenantV2Query();

  const setAddressFormSearch =
    (formApi: UseFormReturn<AddressRequestBodyV2>) => (address: AddrDetailApiResponse) => {
      if (isNil(tenant)) {
        showNotification.error();
        return;
      }

      formApi.setValue(
        'address',
        {
          city: address?.city ?? '',
          street: address?.street ?? '',
          zip: address?.zipCode ?? '',
          state: address?.state ?? null,
          country: address?.country ?? tenant.contactInformation.address.country,
          descriptiveNumber: address?.descriptiveNumber ?? null,
          orientationNumber: address?.orientationNumber ?? null,
          addressComplement: null,
          district: null,
          coordinates: null,
          postal: null,
        },
        {shouldValidate: true}
      );
    };
  return (
    <Form<AddressRequestBodyV2>
      onSubmit={props.onSubmit}
      schema={schema}
      defaultValues={defaultValues}
    >
      {(control, formApi) => (
        <VStack spacing={4}>
          <AddressSearch onSelect={setAddressFormSearch(formApi)} />
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                isRequired
                control={control}
                name="address.street"
                type="text"
                label={i18n.t('entity.address.labels.street')}
                data-testid={suffixTestId('street', props)}
              />
            </Box>
            <Box flex={1}>
              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    control={control}
                    name="address.descriptiveNumber"
                    type="text"
                    label={i18n.t('entity.address.labels.descriptiveNumber')}
                    data-testid={suffixTestId('descriptiveNumber', props)}
                  />
                </Box>
                <Box flex={1}>
                  <FormField
                    control={control}
                    name="address.orientationNumber"
                    type="text"
                    label={i18n.t('entity.address.labels.orientationNumber')}
                    data-testid={suffixTestId('orientationNumber', props)}
                  />
                </Box>
              </HStack>
            </Box>
          </HStack>
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                isRequired
                control={control}
                name="address.city"
                type="text"
                label={i18n.t('entity.address.labels.city')}
                data-testid={suffixTestId('city', props)}
              />
            </Box>
            <Box flex={1}>
              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    control={control}
                    name="address.district"
                    type="text"
                    label={i18n.t('entity.address.labels.district')}
                    data-testid={suffixTestId('postalCode', props)}
                  />
                </Box>
                <Box flex={1}>
                  <FormField
                    isRequired
                    control={control}
                    name="address.zip"
                    type="text"
                    label={i18n.t('entity.address.labels.zipCode')}
                    data-testid={suffixTestId('zipCode', props)}
                  />
                </Box>
              </HStack>
            </Box>
          </HStack>
          <HStack spacing={4}>
            <Box flex={1}>
              <CountrySelect<AddressRequestBodyV2>
                isRequired
                control={control}
                name="address.country"
                label={i18n.t('entity.address.labels.country')}
                menuInPortal
                data-testid={suffixTestId('country', props)}
              />
            </Box>
            <Box flex={1}>
              <FormField
                control={control}
                name="address.addressComplement"
                type="text"
                label={i18n.t('entity.address.labels.addressComplement')}
                data-testid={suffixTestId('addressComplement', props)}
              />
            </Box>
          </HStack>
          <FormField
            control={control}
            name="type"
            type="text"
            label={i18n.t('entity.address.labels.type')}
            data-testid={suffixTestId('type', props)}
          />
          <Space fillAvailable />
          <DialogFooter data-testid={props['data-testid']}>
            <ButtonGroup align="right">
              <Button
                title={i18n.t('general.actions.discard')}
                onClick={props.onClose ?? closeCurrentDialog}
                variant="secondary"
                isDisabled={props.isLoading}
                data-testid={suffixTestId('discard', props)}
              />
              <FormButton
                control={control}
                title={
                  props.isEdit
                    ? i18n.t('general.actions.saveChanges')
                    : i18n.t('general.actions.create')
                }
                variant="primary"
                type="submit"
                isLoading={props.isLoading}
                data-testid={suffixTestId('submit', props)}
              />
            </ButtonGroup>
          </DialogFooter>
        </VStack>
      )}
    </Form>
  );
}

const schema = Yup.object().shape({
  address: Yup.object().shape({
    street: yupString.required(),
    descriptiveNumber: yupString,
    orientationNumber: yupString,
    city: yupString.required(),
    district: yupString.optional(),
    zip: yupString.required(),
    country: yupString.required(),
    addressComplement: yupString.optional(),
  }),
  type: yupString.optional(),
});
