import {Align, Box, Hide, Icon, Text} from 'platform/foundation';

interface TableHeaderCellProps {
  label?: string;
}

export function TableHeaderCell(props: TableHeaderCellProps) {
  return (
    <Align left>
      <Box backgroundColor="palettes.neutral.10.100" paddingVertical={3} paddingHorizontal={2}>
        <Text size="small" color="secondary">
          {props.label}
        </Text>
        <Hide when={props.label}>
          <Icon value="AV/branding_watermark" color="general.transparent" />
        </Hide>
      </Box>
    </Align>
  );
}
