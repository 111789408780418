import {useSelector} from 'react-redux';

import {selectActiveBranchId} from '@dms/api/features';
import {useGetBranchQuery} from '@dms/api/tenant';

export const useGetCurrentBranch = () => {
  const activeBranchId = useSelector(selectActiveBranchId);

  const activeBranch = useGetBranchQuery({branchId: activeBranchId});

  return {activeBranchId, ...activeBranch};
};
