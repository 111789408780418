import {DataStatus} from 'platform/components';
import {Show} from 'platform/foundation';

import {useEffect} from 'react';
import {useSelector} from 'react-redux';

import {any, flatten, isNotNil} from 'ramda';
import {isTrue} from 'ramda-adjunct';

import {testIds, vehiclesRoutes} from '@dms/routes';
import {handleApiError, usePermissions} from '@dms/shared';
import {
  InspectionType,
  Inspections,
  NewInspectionButton,
  getAllAuditStructureTemplates,
  getAuditStructure,
  getCheckinInspections,
  getComplaintInspections,
  getHandoverInspections,
  getNonValidationInspections,
  getValidationInspections,
  loadVehicleDetailsData,
  selectStructures,
  useThunkDispatch,
  selectCarAudit,
} from '@dms/teas';

import {composePath} from 'shared';

interface InspectionsTabProps {
  vehicleId: string;
}

export function InspectionsTab(props: InspectionsTabProps) {
  const dispatch = useThunkDispatch();

  const structures = useSelector(selectStructures);

  const [hasCreateInspectionPermission] = usePermissions({
    permissionKeys: ['createInspection'],
  });

  const {loading} = useSelector(selectCarAudit);

  const isAuditLoading = any(isTrue, [
    loading.getComplaintInspections,
    loading.getValidationInspections,
    loading.getNonValidationInspections,
    loading.getCheckinInspections,
    loading.getHandoverInspections,
    loading.getAllAuditStructureTemplates,
    loading.getAuditStructure,
    loading['sales/vehicleDetail/loadCarDetailsHighlightsRequest'],
    loading['sales/vehicleDetail/loadCarDetailsShortcomingsRequest'],
    loading['sales/vehicleDetail/loadCarDetailsVehicleDetailRequest'],
    loading['sales/vehicleDetail/loadPremiumFeatures'],
    loading['vehicleCatalogue/getCustomTenantCatalogue'],
  ]);

  useEffect(() => {
    if (isNotNil(props.vehicleId) && !isAuditLoading) {
      loadVehicleDetailsData(props.vehicleId, dispatch).then(() =>
        Promise.all([
          dispatch(
            getComplaintInspections.action({
              requestBody: {vehicleIds: [props.vehicleId]},
              inspectionType: InspectionType.complaint,
            })
          ).unwrap(),
          dispatch(
            getValidationInspections.action({
              requestBody: {vehicleIds: [props.vehicleId]},
              inspectionType: InspectionType.validationInspection,
            })
          ).unwrap(),
          dispatch(
            getNonValidationInspections.action({
              requestBody: {vehicleIds: [props.vehicleId]},
              inspectionType: InspectionType.nonValidationInspection,
            })
          ).unwrap(),
          dispatch(
            getCheckinInspections.action({
              requestBody: {vehicleIds: [props.vehicleId]},
              inspectionType: InspectionType.checkin,
            })
          ).unwrap(),
          dispatch(
            getHandoverInspections.action({
              requestBody: {vehicleIds: [props.vehicleId]},
              inspectionType: InspectionType.handover,
            })
          ).unwrap(),
        ]).then((response) => {
          const inspections = flatten(response ?? [])?.sort((a, b) =>
            (a?.updateAt ?? 0) < (b?.updateAt ?? 0) ? 1 : -1
          );
          inspections?.forEach((inspection) => {
            if (!structures?.[inspection.id]) {
              dispatch(
                getAuditStructure.action({
                  auditStructureId: inspection.controlStructureId,
                })
              )
                .unwrap()
                .catch(handleApiError);
            }
          });
          dispatch(getAllAuditStructureTemplates.action({}));
        })
      );
    }
  }, [props.vehicleId]);

  return (
    <DataStatus isLoading={isAuditLoading} minHeight={100}>
      <Show when={hasCreateInspectionPermission}>
        <NewInspectionButton vehicleId={props.vehicleId} openLinkInNewTab />
      </Show>
      <Inspections
        data-testid={testIds.workshop.serviceCaseDetail('inspections')}
        openLinksInNewTab
        getRedirectRoute={(_id, inspectionId) =>
          composePath(vehiclesRoutes.inspectionDetailTab, {
            params: {
              id: props.vehicleId,
              inspectionId,
              inspectionTab: '_',
            },
          })
        }
      />
    </DataStatus>
  );
}
