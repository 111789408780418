import {Action, Actions, Avatar, Choice, Dialog, IconButton} from 'platform/components';
import {VStack, Text, Box, HStack, Space, Heading} from 'platform/foundation';

import {MouseEvent} from 'react';

import i18n from '@dms/i18n';

import {buildArray} from 'shared';

import {avatarColors} from '../constants/kanbanColors';
import {KanbanUser} from '../types/KanbanUser';

interface KanbanControlDialogProps {
  isOpen: boolean;
  currentUserId: string;
  users: KanbanUser[];
  selectedUsers: KanbanUser[];
  onUserSelect: (selectedUsers: KanbanUser[]) => void;
  onClose: () => void;
}

export function KanbanControlDialog(props: KanbanControlDialogProps) {
  const handleUserVisibilityChange = (user: KanbanUser) => {
    props.onUserSelect(
      props.selectedUsers.map((selectedUser) =>
        selectedUser.id === user.id
          ? {...selectedUser, isHidden: !selectedUser.isHidden}
          : selectedUser
      )
    );
  };

  const handleRemoveUser = (user: KanbanUser) => {
    const filteredUsers = props.selectedUsers.filter((u) => u.id !== user.id);
    if (filteredUsers.length === 1) {
      props.onUserSelect([{...filteredUsers[0], isHidden: false}]);
    } else {
      props.onUserSelect(filteredUsers);
    }
  };

  const onUserSelect = (userId: string | null) => {
    const user = props.users.find((user) => user.id === userId);
    if (!user) {
      return;
    }
    props.onUserSelect([...props.selectedUsers, {...user, isHidden: false}]);
  };

  const options = props.users.map((user) => ({
    label: `${user.fullName} <${user.email}>`,
    value: user.id,
  }));

  return (
    <Dialog isOpen={props.isOpen} disableBodyPadding scrollBehavior="outside">
      <Box padding={3}>
        <VStack spacing={3}>
          <HStack justify="space-between">
            <Heading size={5}>{i18n.t('general.presets.userShare')}</Heading>
            <IconButton icon="navigation/close" priority="default" onClick={props.onClose} />
          </HStack>
          <Choice
            value=""
            autoFocus
            options={options}
            isSearchable
            isNotClearable
            isDisabled={props.selectedUsers.length === 6}
            placeholder={i18n.t('general.labels.searchPlaceholder')}
            onChange={onUserSelect}
          />
          <VStack spacing={2}>
            {props.selectedUsers.map((user, userIndex) => (
              <Box
                key={user.id}
                backgroundColor="general.white"
                borderLeft="2px solid"
                borderColor={avatarColors[userIndex]}
                paddingHorizontal={2}
              >
                <HStack align="center" minHeight={9}>
                  <Box opacity={user.isHidden ? 0.2 : 1}>
                    <Avatar
                      name={user.fullName}
                      size="small"
                      color={avatarColors[userIndex]}
                      textColor="white"
                    />
                  </Box>
                  <Space horizontal={2} />
                  <Text size="small">{user.fullName}</Text>
                  <Space fillAvailable />
                  <Actions
                    size="small"
                    actions={buildArray<Action>()
                      .add({
                        type: 'iconButton',
                        isDisabled:
                          !user.isHidden &&
                          props.selectedUsers.filter((selectedUser) => !selectedUser.isHidden)
                            .length === 1,
                        icon: user.isHidden ? 'action/visibility_off' : 'action/visibility',
                        onClick: (event: MouseEvent<HTMLButtonElement>) => {
                          event.stopPropagation();
                          handleUserVisibilityChange(user);
                        },
                      })
                      .when(user.id !== props.currentUserId, {
                        type: 'iconButton',
                        icon: 'navigation/close',
                        onClick: (event: MouseEvent<HTMLButtonElement>) => {
                          event.stopPropagation();
                          handleRemoveUser(user);
                        },
                      })}
                  />
                </HStack>
              </Box>
            ))}
          </VStack>
        </VStack>
      </Box>
    </Dialog>
  );
}
