import {isFeatureEnabled} from 'feature-flags';
import {DataStatus, Segment, SegmentProps} from 'platform/components';
import {Box, Hide, HStack, Show, Text, useDevice, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {Fragment, useState} from 'react';
import {Helmet} from 'react-helmet-async';

import {always, defaultTo, filter, length} from 'ramda';
import {isPositive} from 'ramda-adjunct';

import {useGetCurrentUserInfoQuery, useGetUserDashboardSettingsQuery} from '@dms/api/user';
import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {myProfileRoutes, testIds} from '@dms/routes';
import {usePermissions} from '@dms/shared';
import {TaskCenterCard} from '@dms/task-manager';

import {useNavigate} from 'shared';

import {Clock} from './components/Clock';
import {LatestBusinessCases} from './components/LatestBusinessCases/LatestBusinessCases';
import {LatestInterests} from './components/LatestInterests/LatestInterests';
import {LatestVehicles} from './components/LatestVehicles/LatestVehicles';
import {MyNotifications} from './components/MyNotifications';
import {OpportunitiesFunnel} from './components/OpportunitiesFunnel';
import {Support} from './components/Support';
import {VehicleWarehouse} from './components/VehicleWarehouse';
import {SEGMENT_MIN_WIDTH} from './consts/segmentMinWidth';
import {DashboardLayoutConfig} from './types/layoutBlocks';
import {splitBlocksToCols} from './utils/splitBlocksToCols';

enum DashboardSource {
  activeBranch = 'activeBranch',
  tenant = 'tenant',
}

const getDashboardSourceOptions = (
  branchName?: string
): NonNullable<SegmentProps<DashboardSource>['options']> => [
  {
    value: DashboardSource.activeBranch,
    label: branchName ?? i18n.t('page.homepage.labels.myBranch'),
  },
  {
    value: DashboardSource.tenant,
    label: i18n.t('page.homepage.labels.all'),
  },
];

const defaultToFalse = defaultTo(false);

export function Dashboard() {
  const [
    canReadTasks,
    canReadInterest,
    canReadBusinessCase,
    canViewBusinessCasePurchase,
    canViewBusinessCaseSelling,
  ] = usePermissions({
    permissionKeys: [
      'readTask',
      'interestRead',
      'businessCaseRead',
      'viewBusinessCasePurchase',
      'viewBusinessCaseSelling',
    ],
    scopes: {
      interestRead: {
        // EP for latest interests handles participation scope
        participation: 'SKIP',
        // EP for latest interests handles branch scope
        branchId: 'SKIP',
      },
      viewBusinessCasePurchase: {
        // EP for latest BCs handles participation scope
        participation: 'SKIP',
        // EP for latest BCs handles branch scope
        branchId: 'SKIP',
      },
      viewBusinessCaseSelling: {
        // EP for latest BCs handles participation scope
        participation: 'SKIP',
        // EP for latest BCs handles branch scope
        branchId: 'SKIP',
      },
    },
  });

  const [dashboardSource, setDashboardSource] = useState(DashboardSource.activeBranch);
  const navigate = useNavigate();
  const device = useDevice();

  const {data: userInfo} = useGetCurrentUserInfoQuery();
  const {data: userDashboardSettings, isLoading: isUserDashboardSettingsLoading} =
    useGetUserDashboardSettingsQuery();

  const selectedBranchId = match(dashboardSource)
    .with(
      DashboardSource.activeBranch,
      // There is edge case, when user is new and has no branch set, you have to check defaultBranch
      () => userInfo?.settings?.branch || userInfo?.defaultBranch?.id
    )
    .otherwise(always(undefined));

  const branchName = userInfo?.branches?.find(
    (branch) => branch.id === userInfo?.settings?.branch
  )?.name;

  const sourceOptions = getDashboardSourceOptions(branchName);

  const isMobileResolution = device === 'mobile';

  const isFunnelInterestsEnabled = defaultToFalse(userDashboardSettings?.salesFunnelInterests);
  const isFunnelBusinessCasesEnabled = defaultToFalse(
    userDashboardSettings?.salesFunnelBusinessCases
  );

  const isFunnelVisible = defaultToFalse(
    (isFunnelInterestsEnabled || isFunnelBusinessCasesEnabled) &&
      canReadBusinessCase &&
      isFeatureEnabled(featureFlags.ACL_SALES)
  );

  const canViewLatestBusinessCase = canViewBusinessCasePurchase || canViewBusinessCaseSelling;

  const NotificationsAndTasks = (
    <HStack width="100%" spacing={4} justify="stretch">
      <Show
        when={
          isFeatureEnabled(featureFlags.CORE_TASK_MANAGEMENT) &&
          canReadTasks &&
          defaultToFalse(userDashboardSettings?.myTasks)
        }
      >
        <Box flexGrow={1}>
          <TaskCenterCard data-testid={testIds.dashboard.home('tasks')} />
        </Box>
      </Show>
      <Show when={defaultToFalse(userDashboardSettings?.notifications)}>
        <Box flexGrow={1} maxWidth="50%">
          <MyNotifications />
        </Box>
      </Show>
    </HStack>
  );

  const blocksDefinition: DashboardLayoutConfig = [
    {
      id: 'vehicle-warehouse',
      component: (
        <VehicleWarehouse
          isActiveBranch={dashboardSource === 'activeBranch'}
          branchId={selectedBranchId}
        />
      ),
      priority: 'left',
      isVisible: defaultToFalse(
        userDashboardSettings?.vehicleStock &&
          isFeatureEnabled(featureFlags.ACL_SALES) &&
          canReadBusinessCase
      ),
    },
    {
      id: 'latest-vehicles',
      component: <LatestVehicles branchId={selectedBranchId} />,
      priority: 'left',
      isVisible: defaultToFalse(userDashboardSettings?.latestVehicles),
    },
    {
      id: 'support',
      component: <Support />,
      priority: 'right',
      isVisible: true,
    },
    {
      id: 'notifications-and-tasks',
      component: NotificationsAndTasks,
      priority: 'right',
      isVisible: defaultToFalse(
        userDashboardSettings?.notifications || userDashboardSettings?.myTasks
      ),
    },
    {
      id: 'latest-business-cases',
      component: <LatestBusinessCases branchId={selectedBranchId} />,
      priority: 'left',
      isVisible: defaultToFalse(
        userDashboardSettings?.latestBusinessCases &&
          canViewLatestBusinessCase &&
          canReadBusinessCase &&
          isFeatureEnabled(featureFlags.ACL_SALES)
      ),
    },
    {
      id: 'latest-interests',
      component: <LatestInterests branchId={selectedBranchId} />,
      priority: 'left',
      isVisible: defaultToFalse(
        userDashboardSettings?.latestInterests &&
          canReadInterest &&
          canReadBusinessCase &&
          isFeatureEnabled(featureFlags.ACL_SALES)
      ),
    },
  ];
  const visibleBlocks = filter((block) => block.isVisible, blocksDefinition);

  const blocksInColumns = splitBlocksToCols(visibleBlocks);

  return (
    <>
      <Helmet>
        <title>{i18n.t('page.homepage.labels.title')}</title>
      </Helmet>
      <Box padding={4} data-testid={testIds.dashboard.home('wrapper')}>
        <VStack spacing={4}>
          <HStack spacing={2} justify="space-between" align="center">
            <Segment
              value={dashboardSource}
              onChange={(value) => setDashboardSource(value)}
              options={sourceOptions}
              minWidth={SEGMENT_MIN_WIDTH}
            />
            <Text data-testid={testIds.dashboard.home('date')} size="small">
              <Clock />
            </Text>
          </HStack>

          <DataStatus
            isEmpty={!isFunnelVisible && !isPositive(length(visibleBlocks))}
            emptySubheadline={i18n.t('page.homepage.labels.noDataDashboard')}
            action={{
              title: i18n.t('general.labels.goToSettings'),
              variant: 'secondary',
              onClick: () => navigate(myProfileRoutes.dashboard),
            }}
            minHeight={80}
            isLoading={isUserDashboardSettingsLoading}
          >
            <Show when={isFunnelVisible}>
              <OpportunitiesFunnel
                branchId={selectedBranchId}
                isFunnelInterestsEnabled={isFunnelInterestsEnabled}
                isFunnelBusinessCasesEnabled={isFunnelBusinessCasesEnabled}
              />
            </Show>

            <Show when={isMobileResolution}>
              {blocksDefinition.map((block) => (
                <Fragment key={block.id}>{block.component}</Fragment>
              ))}
            </Show>

            <Hide when={isMobileResolution}>
              <HStack spacing={4}>
                <VStack spacing={4} basis="50%">
                  {blocksInColumns.left.map((block) => (
                    <Fragment key={block.id}>{block.component}</Fragment>
                  ))}
                </VStack>
                <VStack spacing={4} basis="50%">
                  {blocksInColumns.right.map((block) => (
                    <Fragment key={block.id}>{block.component}</Fragment>
                  ))}
                </VStack>
              </HStack>
            </Hide>
          </DataStatus>
        </VStack>
      </Box>
    </>
  );
}
