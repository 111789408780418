import {Integer} from 'platform/foundation';
import {z} from 'zod';

export const BasePriceSchema = z.object({
  amount: z.number(),
  currency: z.string(),
});

export type BasePrice = z.infer<typeof BasePriceSchema>;

export const PriceWithAndWithoutVatSchema = z.object({
  withoutVat: BasePriceSchema,
  withVat: BasePriceSchema,
});

export const AbortSignalSchema = z.custom<AbortSignal>((value) => value instanceof AbortSignal);

export type PriceWithAndWithoutVat = z.infer<typeof PriceWithAndWithoutVatSchema>;

export const MechanicSchema = z.object({
  id: z.string(),
  name: z.string(),
  isDefault: z.boolean(),
  costCenterId: z.string().optional(),
});

export type Mechanic = z.infer<typeof MechanicSchema>;

export const AssignedMechanicSchema = z.object({
  id: z.string(),
  isDefault: z.boolean(),
  costCenterId: z.string().nullish(),
});

export type AssignedMechanic = z.infer<typeof AssignedMechanicSchema>;

export const BasketTooltipSchema = z.object({
  label: z.string(),
  value: z.string(),
});

export type BasketTooltip = z.infer<typeof BasketTooltipSchema>;

export const MinMaxQuantitySchema = z.object({
  minQuantity: z.number(),
  maxQuantity: z.number(),
});
export type MinMaxQuantity = z.infer<typeof MinMaxQuantitySchema>;

export const BaseIdSchema = z.object({
  id: z.string(),
});

export type BaseId = z.infer<typeof BaseIdSchema>;

/**
 * Defines the type of the entity being interacted with.
 *
 * @example
 * To add a spare part from the warehouse to the basket, use the type 'wrh_sale_item'.
 * For example: originEntityType = 'wrh_sale_item'.
 */
export const OriginEntityTypeSchema = z.enum([
  'svc_job_item',
  'wrh_sale_item',
  'warehouse',
  'customer',
  'other',
  'supplier_order_item',
  'receive_note_item',
]);
export type OriginEntityType = z.infer<typeof OriginEntityTypeSchema>;

/**
 * Specifies the origin type of the entity, such as service cases, direct sales,
 * service order issues, or service order returns.
 *
 * This is used by the backend to identify the origin of the request,
 * as the same operation is reused for returning parts across multiple entity types.
 *
 * @example
 * We might want to return a spare part from direct sales. This is done through Direct Sale Returns entity.
 * The origin entity for that spare part was direct sale, therefore the originEntityHeaderType should be set as 'direct-sale'.
 *
 */
export const OriginEntityHeaderTypeSchema = z
  .enum(['service-case-order', 'direct-sale', 'service-order-issue-note', 'service-order-return'])
  .optional();
export type OriginEntityHeaderType = z.infer<typeof OriginEntityHeaderTypeSchema>;

export const HeaderDynamicActionsFlagColoursSchema = z.union([
  z.literal('neutral'),
  z.literal('red'),
  z.literal('magenta'),
  z.literal('purple'),
  z.literal('blue'),
  z.literal('teal'),
  z.literal('green'),
  z.literal('yellow'),
  z.literal('orange'),
  z.literal('black'),
]);
export type HeaderDynamicActionsFlagsColours = z.infer<
  typeof HeaderDynamicActionsFlagColoursSchema
>;

export const HeaderDynamicActionsFlagsSchema = z.array(
  z.object({
    title: z.string(),
    color: HeaderDynamicActionsFlagColoursSchema,
  })
);
export type HeaderDynamicActionsFlags = z.infer<typeof HeaderDynamicActionsFlagsSchema>;

export const ArticleTreeFolderSchema = z.object({
  leafId: z.string().nullable(),
});
export type ArticleTreeFolder = z.infer<typeof ArticleTreeFolderSchema>;

export const BasePriceSourceSchema = z.enum([
  'AVG_PURCHASE',
  'LAST_PURCHASE',
  'RECOMMENDED',
  'MANUAL',
]);
export const SalesPriceCalculationSchema = z.enum([
  'MARGIN_ON_BASE',
  'MARKUP_ON_BASE',
  'PROFIT_ON_BASE',
  'MANUAL',
  'RECOMMENDED_PRICE',
  'MARKETING_CODE',
]);

export const PricesSettingsSchema = z.object({
  avgPurchasePriceWithoutVat: z.number().optional(),
  avgPurchasePriceWithVat: z.number().optional(),
  lastPurchasePriceWithoutVat: z.number().optional(),
  lastPurchasePriceWithVat: z.number().optional(),
  recommendedPurchasePriceWithoutVat: z.number().optional(),
  recommendedPurchasePriceWithVat: z.number().optional(),
  salesPriceCalculation: SalesPriceCalculationSchema,
  basePriceSource: BasePriceSourceSchema,
  unitSalesPriceWithVat: z.boolean(),
});

export const DispensingPricesSchema = z.object({
  basePriceWithoutVat: z.number().optional(),
  basePriceWithVat: z.number().optional(),
  saleBasePriceWithoutVat: z.number().optional(),
  saleBasePriceWithVat: z.number().optional(),
  saleBaseProfit: z.number().nullish(),
  saleBaseMarginPercent: z.number().nullish(),
  saleBaseMarkUpPercent: z.number().nullish(),
  warrantyPriceWithoutVat: z.number().optional(),
  warrantyPriceWithVat: z.number().optional(),
  warrantyProfit: z.number().optional(),
  warrantyMarginPercent: z.number().optional(),
  warrantyMarkUpPercent: z.number().optional(),
});

export const BaseArticleSchema = z.object({
  id: z.string(),
  warehouseId: z.string(),
  supplierId: z.string().optional(),
  manufacturerId: z.string(),
  makeCode: z.string().optional(),
  name: z.string(),
  description: z.string().optional(),
  stockNumber: z.string().optional(),
  manufacturerNumber: z.string(),
  manufacturerName: z.string().optional(),
  handlingUnit: z.string(),
  dispensingUnit: z.number().nullable(),
  receivedHandlingQuantity: z.number().optional(),
  vatType: z.string().optional(),
  storageLocation: z.string().optional(),
  discountGroup: z.string().optional(),
  treeFolder: ArticleTreeFolderSchema,
  marketingCode: z.string().optional(),
  warehouseAccount: z.string().optional(),
  pricesSettings: PricesSettingsSchema.partial(),
  dispensingPrices: DispensingPricesSchema.partial(),
  isTemporary: z.boolean().optional(), // exception where bool could be undefined,
  isRemovable: z.boolean(),
});

export const CreatedAndUpdatedBySchema = z.object({
  created: z.string(),
  createdBy: z.string(),
  updated: z.string().optional(),
  updatedBy: z.string().optional(),
});

export const GetArticleResponseSchema = BaseArticleSchema.merge(CreatedAndUpdatedBySchema);

export const CreatedAtAndUpdatedBySchema = z.object({
  createdAt: z.string(),
  createdBy: z.string(),
  updatedAt: z.string(),
  updatedBy: z.string(),
});

export type CreatedAtAndUpdatedBy = z.infer<typeof CreatedAtAndUpdatedBySchema>;

export const DiscountSourceTypeSchema = z.enum([
  'customer-contract',
  'custom-discount',
  'manual-discount',
]);

export type DiscountSourceType = z.infer<typeof DiscountSourceTypeSchema>;

export const DiscountSchema = z.object({
  isDoNotApplyDiscount: z.boolean(),
  isDiscountEnable: z.boolean(),
  discountSourceType: DiscountSourceTypeSchema,
  discountRate: z.number(),
});
export type Discount = z.infer<typeof DiscountSchema>;

export const AvailableDiscountSchema = z.object({
  discountSourceType: DiscountSourceTypeSchema,
  discountSourceTypeName: z.string(),
  discountRate: z.number(),
  isSet: z.boolean(),
  isChangeable: z.boolean(),
});

export const ItemsDiscountsSchema = z.object({
  isDoNotApplyDiscount: z.boolean(),
  isDiscountEnable: z.boolean(),
  availableDiscounts: z.array(AvailableDiscountSchema).optional(),
});
export const BaseCategorySchema = z.object({
  id: z.string(),
  name: z.string(),
});

const BaseDirectSaleLabourCatalogueSchema = z.object({
  id: z.string(),
  category: BaseCategorySchema,
});

export const BaseDirectSaleMaterialItemSchema = z.object({
  id: z.string(),
  warehouse_id: z.string(),
  itemEditingAllowed: z.boolean(),
  priceType: z.string().nullish(),
  requestType: z.string(),
  name: z.string(),
  manufacturerNumber: z.string(),
  manufacturerId: z.string(),
  quantity: z.number(),
  unit: z.string().nullish(),
  dispensingUnit: z.number(),
  quantityEditingAllowed: z.boolean(),
  unitPrice: PriceWithAndWithoutVatSchema,
  discount: PriceWithAndWithoutVatSchema.extend({
    rate: z.number(),
  }),
  totalPrice: PriceWithAndWithoutVatSchema,
  tooltip: z.array(BasketTooltipSchema),
});
export const BaseDirectSaleAssignMechanicsRequestSchema = z.object({
  directSaleId: z.string(),
  body: z
    .object({
      assignMechanics: AssignedMechanicSchema,
    })
    .optional(),
});

export type BaseDirectSaleMaterialItem = z.infer<typeof BaseDirectSaleMaterialItemSchema>;

export const BaseDirectSaleLabourItemSchema = z.object({
  id: z.string(),
  itemEditingAllowed: z.boolean(),
  priceType: z.string(),
  priceTypeName: z.string(),
  name: z.string(),
  number: z.string(),
  labourCatalog: BaseDirectSaleLabourCatalogueSchema,
  technican: z.string().nullish(),
  quantity: z.number(),
  unit: z.string(),
  quantityEditingAllowed: z.boolean(),
  unitPrice: PriceWithAndWithoutVatSchema,
  discount: PriceWithAndWithoutVatSchema.extend({
    rate: z.number(),
  }),
  totalPrice: PriceWithAndWithoutVatSchema,
  tooltip: z.array(BasketTooltipSchema),
});

export type TreeFolderAction = 'addFolder' | 'addRelation' | 'edit' | 'delete';
export type TreeFolder = {
  id?: string | null;
  contextId?: string;
  label?: string | null;
  level?: Integer | null;
  parentId?: string | null;
  actions?: (TreeFolderAction | null)[];
  subfolders?: (TreeFolder | null)[];
};

export type TreeFolderContextTarget = 'labour-catalog' | 'spare-parts-catalog';
export type GetTreeFolderApiResponse =
  | /** status 200 Standard response */ ({
      treeFolder?: TreeFolder | null;
    } | null)
  | /** status 204 Standard response */ undefined;
