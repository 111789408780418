import {isFeatureEnabled} from 'feature-flags';
import {
  closeCurrentDialog,
  DropdownActionMenuItem,
  openConfirmDialog,
  openDeleteDialog,
  showNotification,
} from 'platform/components';

import {useSelector} from 'react-redux';
import {createSearchParams, useNavigate} from 'react-router-dom';

import {isNotNil, isTrue} from 'ramda-adjunct';

import {useGetParticipationQuery} from '@dms/api/participation';
import {
  selectSaleVehicle,
  useMakeSaleVehicleAvailableForSaleMutation,
  useMakeSaleVehicleIsNotForSaleMutation,
  useMakeSaleVehicleIsNotSoldMutation,
  useReRegisterVehicleForSaleMutation,
} from '@dms/api/saleVehicle';
import {EntityResourceIds} from '@dms/api/shared';
import {
  useArchiveVehicleMutation,
  useDeleteVehicleMutation,
  useGetVehicleV2Query,
  useUnarchiveVehicleMutation,
} from '@dms/api/vehicle';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {testIds, vehiclesRoutes} from '@dms/routes';
import {handleApiError, usePermissions, useSaleVehicleActions} from '@dms/shared';

import {buildArray, composePath} from 'shared';

export const useGetSecondaryVehicleHeaderActions = (vehicleId: string) => {
  const navigate = useNavigate();

  const {data: saleVehicle} = useSelector(selectSaleVehicle(vehicleId));
  const {data: vehicle} = useGetVehicleV2Query({vehicleId});
  const isArchived = isTrue(vehicle?.isArchived);
  const isSaleVehicle = isNotNil(saleVehicle);

  const {data: vehicleParticipation} = useGetParticipationQuery({
    resourceId: EntityResourceIds.vehicle,
    recordId: vehicleId,
  });

  const [canReregister, canDeleteVehicle, canReadBusinessCase, canUpdateVehicle] = usePermissions({
    permissionKeys: [
      'vehicleReregisterForSale',
      'deleteVehicle',
      'businessCaseRead',
      'updateVehicle',
    ],
    scopes: {
      deleteVehicle: {
        participation: vehicleParticipation,
        branchId: saleVehicle?.branchId,
      },
      updateVehicle: {
        participation: vehicleParticipation,
        branchId: saleVehicle?.branchId,
      },
    },
  });

  const isSalesModuleEnabled = isFeatureEnabled(featureFlags.ACL_SALES) && canReadBusinessCase;
  const [makeSaleVehicleNotForSale] = useMakeSaleVehicleIsNotForSaleMutation();
  const [deleteVehicle] = useDeleteVehicleMutation();
  const [makeSaleVehicleIsNotSold] = useMakeSaleVehicleIsNotSoldMutation();
  const [makeSaleVehicleAvailableForSale] = useMakeSaleVehicleAvailableForSaleMutation();
  const [reRegisterVehicleForSale] = useReRegisterVehicleForSaleMutation();
  const [unarchiveVehicle, {isLoading: isLoadingArchiveVehicle}] = useUnarchiveVehicleMutation();
  const [archiveVehicle, {isLoading: isLoadingUnarchiveVehicle}] = useArchiveVehicleMutation();

  const {isSaleActionEnabled} = useSaleVehicleActions(vehicleId);

  const isArchiveLoading = isLoadingArchiveVehicle || isLoadingUnarchiveVehicle;
  const handleArchiveVehicle = isArchived ? unarchiveVehicle : archiveVehicle;
  const isRemoveVehicleFromSaleFlagEnabled = isFeatureEnabled(
    featureFlags.REMOVE_VEHICLE_FROM_SALE
  );

  const handleClone = () => {
    if (!isSaleVehicle && isFeatureEnabled(featureFlags.AFTS_SERVICE_VEHICLE_ENTITY_V2)) {
      return navigate({
        pathname: composePath(vehiclesRoutes.cloneServiceVehicle, {
          params: {
            id: vehicleId,
          },
          isSearchParamsPreserved: false,
        }),
      });
    }

    navigate({
      pathname: vehiclesRoutes.clone,
      search: createSearchParams({
        id: vehicleId,
      }).toString(),
    });
  };

  return buildArray<DropdownActionMenuItem>()
    .when(
      isSalesModuleEnabled &&
        isSaleActionEnabled('SALE_VEHICLE_RE_REGISTER_FOR_SALE') &&
        isSaleVehicle &&
        canReregister,
      {
        label: i18n.t('entity.vehicle.labels.reRegisterToSell'),
        onClick: () =>
          openConfirmDialog({
            onConfirm: () =>
              reRegisterVehicleForSale({vehicleId})
                .unwrap()
                .then(() => showNotification.success())
                .catch(handleApiError),
            text: i18n.t('entity.vehicle.labels.confirmReRegisterToSell'),
            'data-testid': testIds.vehicles.detail('vehicleHeader-ReRegisterConfirmDialog'),
          }),
      }
    )
    .when(
      isSalesModuleEnabled &&
        isSaleActionEnabled('SALE_VEHICLE_PUT_BACK_UP_FOR_SALE') &&
        isSaleVehicle &&
        canReregister,
      {
        label: i18n.t('entity.vehicle.labels.AddToSell'),
        onClick: () =>
          openConfirmDialog({
            onConfirm: async () => {
              await makeSaleVehicleAvailableForSale({vehicleId})
                .unwrap()
                .then(() => showNotification.success())
                .catch(handleApiError);
              closeCurrentDialog();
            },
            text: i18n.t('entity.vehicle.labels.AddToSellConfirmDialogSubTitle'),
            'data-testid': testIds.vehicles.detail('vehicleHeader-AddToSaleConfirmDialog'),
          }),

        'data-testid': testIds.vehicles.detail('vehicleHeader-toSell'),
      }
    )

    .when(
      isSalesModuleEnabled &&
        isSaleActionEnabled('SALE_VEHICLE_REMOVE_FROM_SALE') &&
        isSaleVehicle &&
        isRemoveVehicleFromSaleFlagEnabled,
      {
        label: i18n.t('entity.vehicle.labels.removeFromSale'),
        onClick: () =>
          openConfirmDialog({
            onConfirm: async () => {
              await makeSaleVehicleIsNotSold({vehicleId})
                .unwrap()
                .then(() => showNotification.success())
                .catch(handleApiError);
              closeCurrentDialog();
            },
            text: i18n.t('entity.vehicle.actions.notForSaleConfirmDialogSubTitle'),
            'data-testid': testIds.vehicles.detail('vehicleHeader-notForSaleConfirmDialog'),
          }),
        'data-testid': testIds.vehicles.detail('vehicleHeader-markNotSold'),
      }
    )
    .when(
      canUpdateVehicle &&
        (!isFeatureEnabled(featureFlags.AFTS_SERVICE_VEHICLE_ENTITY_V2) ||
          (isFeatureEnabled(featureFlags.AFTS_SERVICE_VEHICLE_ENTITY_V2) && isSaleVehicle)),
      {
        label: isArchived
          ? i18n.t('entity.vehicle.labels.unarchive')
          : i18n.t('entity.vehicle.labels.archive'),
        onClick: () => handleArchiveVehicle({vehicleId}),
        isLoading: isArchiveLoading,
        'data-testid': testIds.vehicles.detail(
          isArchived ? 'vehicleHeader-unarchive' : 'vehicleHeader-archive'
        ),
      }
    )
    .when(canUpdateVehicle && !isArchived, {
      label: i18n.t('entity.vehicle.labels.clone'),
      onClick: handleClone,
      'data-testid': testIds.vehicles.detail('vehicleHeader-clone'),
    })
    .when(
      isSalesModuleEnabled &&
        isSaleActionEnabled('SALE_VEHICLE_TEMPORARY_REMOVE_FROM_SALE') &&
        isSaleVehicle,
      {
        label: i18n.t('entity.vehicle.labels.markTemporarilyNotForSale'),
        onClick: () =>
          openConfirmDialog({
            onConfirm: async () => {
              await makeSaleVehicleNotForSale({vehicleId})
                .unwrap()
                .then(() => showNotification.success())
                .catch(handleApiError);
              closeCurrentDialog();
            },
            text: i18n.t('entity.vehicle.actions.notForSaleConfirmDialogSubTitle'),
            'data-testid': testIds.vehicles.detail('vehicleHeader-notForSaleConfirmDialog'),
          }),
        'data-testid': testIds.vehicles.detail('vehicleHeader-markNotForSale'),
      }
    )
    .when(canDeleteVehicle, {
      label: i18n.t('entity.vehicle.actions.delete'),
      severity: isFeatureEnabled(featureFlags.AFTS_SERVICE_VEHICLE_ENTITY_V2)
        ? 'danger'
        : undefined,
      onClick: () =>
        openDeleteDialog({
          onConfirm: () =>
            deleteVehicle({
              vehicleId,
            })
              .unwrap()
              .then(() => {
                showNotification.success(i18n.t('entity.vehicle.notifications.deleteSuccess'));
              })
              .then(() => navigate(vehiclesRoutes.home))
              .catch(handleApiError),
        }),
      'data-testid': testIds.vehicles.detail('vehicleHeader-delete'),
    });
};
