import {isNotNil, last, sort} from 'ramda';

import {DrillDownResponse} from '@dms/api/sourcing';

export const sortModelFamilyLevel = (data: DrillDownResponse | undefined) =>
  isNotNil(data)
    ? sort((a, b) => {
        const aLastLevel = last(a.levels);
        const bLastLevel = last(b.levels);

        if (
          isNotNil(aLastLevel) &&
          isNotNil(bLastLevel) &&
          aLastLevel.name === 'model_family_group' &&
          bLastLevel.name === 'model_family_group'
        ) {
          const aTranslation = data.translations[aLastLevel.value];
          const bTranslation = data.translations[bLastLevel.value];

          return isNotNil(aTranslation) && isNotNil(bTranslation)
            ? aTranslation.localeCompare(bTranslation)
            : 0;
        }

        return 0;
      }, data.statisticsAggregated)
    : undefined;
