import {Button, ButtonGroup, closeCurrentDialog, IconButton} from 'platform/components';
import {VStack, Text} from 'platform/foundation';

import i18n from '@dms/i18n';

interface IncompleteChecklistDialogProps {
  title: string;
}

export function IncompleteChecklistDialog(props: IncompleteChecklistDialogProps) {
  return (
    <>
      <VStack align="flex-end">
        <IconButton
          data-testid="republishInfoDialog"
          icon="navigation/close"
          onClick={closeCurrentDialog}
        />
      </VStack>
      <VStack spacing={1}>
        <Text>{props.title}</Text>
      </VStack>
      <ButtonGroup align="right">
        <Button
          data-testid="incompleteChecklist-dialog-confirm"
          variant="primary"
          onClick={closeCurrentDialog}
          title={i18n.t('general.actions.confirm')}
        />
      </ButtonGroup>
    </>
  );
}
