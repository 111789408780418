import {FormControl, FormField} from 'platform/components';
import {Grid, GridItem, Heading, VStack} from 'platform/foundation';

import {GetWarehouseHandlingUnitsResponse} from '@dms/api/metadaWarehouseHandlingUnit';
import i18n from '@dms/i18n';
import {getOptionsFromHandlingUnits} from '@dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {ReceiveNoteCorrectionItemForm} from '../types/ReceiveNoteCorrectionItemForm';

export interface SupplierArticleFormProps extends RequiredTestIdProps {
  control: FormControl<ReceiveNoteCorrectionItemForm>;
  handlingUnits: GetWarehouseHandlingUnitsResponse | Nullish;
  areHandlingUnitsLoading: boolean;
}

export function SupplierArticleForm(props: SupplierArticleFormProps) {
  return (
    <VStack spacing={4} data-testid={props['data-testid']}>
      <Heading size={5}>{i18n.t('entity.warehouse.labels.supplierArticle')}</Heading>

      <Grid columns={4}>
        <GridItem span={1}>
          <FormField
            control={props.control}
            isDisabled
            type="number"
            name="supplierArticle.supplierQuantity"
            label={i18n.t('entity.warehouse.labels.supplierQuantity')}
            decimalPlaces={4}
            data-testid={suffixTestId('inputs.supplierQuantity', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            control={props.control}
            isRequired
            isDisabled
            isLoading={props.areHandlingUnitsLoading}
            type="choice"
            name="supplierArticle.supplierUnitId"
            label={i18n.t('entity.warehouse.labels.supplierUnit')}
            options={getOptionsFromHandlingUnits(props.handlingUnits)}
            data-testid={suffixTestId('inputs.supplierUnit', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            control={props.control}
            isDisabled
            type="number"
            name="supplierArticle.warehouseQuantity"
            label={i18n.t('entity.warehouse.labels.warehouseQuantity')}
            decimalPlaces={4}
            data-testid={suffixTestId('inputs.warehouseQuantity', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            control={props.control}
            isLoading={props.areHandlingUnitsLoading}
            isDisabled
            isNotClearable
            type="choice"
            name="supplierArticle.warehouseUnitId"
            label={i18n.t('entity.warehouse.labels.warehouseUnit')}
            options={getOptionsFromHandlingUnits(props.handlingUnits)}
            menuInPortal
            data-testid={suffixTestId('inputs.warehouseUnit', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            control={props.control}
            isDisabled
            type="text"
            name="supplierArticle.supplierOrderingNumber"
            label={i18n.t('entity.warehouse.labels.supplierOrderingNumber')}
            data-testid={suffixTestId('inputs.supplierOrderingNumber', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            control={props.control}
            isDisabled
            type="number"
            name="supplierArticle.supplierBulkPackageQuantity"
            label={i18n.t('entity.warehouse.labels.supplierBulkPackageQuantity')}
            decimalPlaces={4}
            data-testid={suffixTestId('inputs.supplierBulkPackageQuantity', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            control={props.control}
            isDisabled
            type="text"
            name="supplierArticle.supplier"
            label={i18n.t('entity.warehouse.labels.supplier')}
            data-testid={suffixTestId('inputs.supplier', props)}
          />
        </GridItem>
      </Grid>
    </VStack>
  );
}
