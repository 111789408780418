import {Option} from 'platform/components';

import {isNil} from 'ramda';

import {GetCodeListResponse} from '@dms/api/metadaWarehouseCodeList';

import {Nullish} from 'shared';

export const getOptionsFromMaterialPriceTypes = (
  materialPriceTypes: GetCodeListResponse | Nullish
): Option[] => {
  if (isNil(materialPriceTypes)) {
    return [];
  }

  return materialPriceTypes.map((materialPriceType) => ({
    label: materialPriceType.name,
    value: materialPriceType.code,
  }));
};
