import {BaseFlagProps, ColorSchemeType} from 'platform/components';

import {DashboardTagResponseBody} from '@dms/api/dashboard';

export const convertTagToFlag = (tag: DashboardTagResponseBody): BaseFlagProps => ({
  label: tag.label,
  size: 'small',
  isSubtle: tag.color.includes('.subtle'),
  colorScheme: (tag.color.includes('.subtle')
    ? tag.color.replace('.subtle', '')
    : tag.color) as ColorSchemeType,
});
