import {z} from 'zod';

import i18n from '@dms/i18n';

export interface ChecklistTask {
  description: string;
  isMandatory: boolean;
  fileRequired: boolean;
  id?: string;
}

export const checklistFormSchema = z.object({
  name: z.string().nonempty({message: i18n.t('errors.field_is_required')}),
  resourceId: z.string().nonempty({message: i18n.t('errors.field_is_required')}),
  description: z.string().nullish(),
  tasks: z.array(
    z.object({
      description: z.string().nonempty({message: i18n.t('errors.field_is_required')}),
      isMandatory: z.boolean(),
      fileRequired: z.boolean(),
      id: z.string().nullish(),
    })
  ),
});

export type ChecklistFormValue = z.infer<typeof checklistFormSchema>;
