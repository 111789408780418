import {GridApi} from 'ag-grid-community';

import {RowV5ResponseBody, TransformedDefinitionApiResponse} from '../types/Api';
import {getSearchParamsByGridCode} from '../utils/url/getSearchParamsByGridCode';
import {useGridApiEventListener} from './useGridApiEventListener';
import {useHttpCalls} from './useHttpCalls';

export const useSummaryRow = (
  gridApi: GridApi | undefined,
  definition: TransformedDefinitionApiResponse,
  gridCode: string
) => {
  const http = useHttpCalls();

  const updateSummaryRowData = (data: RowV5ResponseBody['cells'] | void) => {
    if (!data) {
      return;
    }

    gridApi?.setGridOption('pinnedBottomRowData', [data]);

    const bottomRow = gridApi?.getPinnedBottomRow(0);
    const rowNodes = bottomRow ? [bottomRow] : undefined;
    gridApi?.redrawRows({rowNodes});
  };

  const getSummaryRow = () => {
    const dataQueryId = getSearchParamsByGridCode(gridCode)?.queryId;

    if (!definition?.behavior?.summaryRowEnabled || !gridApi || !dataQueryId) {
      return;
    }

    http
      .getSummaryRow(dataQueryId)
      .then(updateSummaryRowData)
      .catch(() => updateSummaryRowData({}));
  };

  const {addListeners: addSummaryRowListener} = useGridApiEventListener(
    gridApi,
    ['filterChanged', 'gridReady', 'storeRefreshed', 'stateUpdated'],
    getSummaryRow
  );

  const {addListeners: addSettingsChangedListener} = useGridApiEventListener(
    gridApi,
    ['columnPinned'],
    () => {
      // this is the only way to change styles of the pinned row:
      // https://www.ag-grid.com/javascript-data-grid/row-height/#height-for-pinned-rows
      const currentBottomRowData = gridApi?.getPinnedBottomRow(0);
      if (!currentBottomRowData) {
        return;
      }
      gridApi?.refreshCells({rowNodes: [currentBottomRowData]});
    }
  );

  addSettingsChangedListener();
  addSummaryRowListener();
};
