import {getHoursAndMinutesFromTime} from 'platform/components';

import {parseDate} from 'shared';

export const castTimeToDateISOString = (date: string, time: string) => {
  const parsedDate = parseDate(date);
  const parsedTime = getHoursAndMinutesFromTime(time);

  if (!parsedTime) {
    return parsedDate.toISOString();
  }

  const {hour, minute} = parsedTime;
  parsedDate.setHours(Number(hour));
  parsedDate.setMinutes(Number(minute));

  return parsedDate.toISOString();
};
