import {OptionType} from 'platform/components';

import {applySpec, map} from 'ramda';

import {GetUsersApiResponse} from '@dms/api/shared';

type User = GetUsersApiResponse[number];

export const mapUsersToSelectOptions = map<User, OptionType>(
  applySpec<OptionType>({
    label: (user: User) => `${user.firstName} ${user.lastName}`,
    value: (user: User) => user.id,
  })
);
