import {z} from 'zod';

import {BaseVoidResponseSchema} from '@dms/api/shared';

export const GetServiceCampaignHyundaiApiResponseSchema = z
  .object({
    description: z.string().nullable().optional(),
    state: z.string().nullable().optional(),
    manufacturerActionNumber: z.string().nullable().optional(),
    repairActionNumber: z.string().nullable().optional(),
    vinOrderActionNumber: z.string().nullable().optional(),
    releaseDate: z.string().nullable().optional(),
    defectCode: z.string().nullable().optional(),
    damageCode: z.string().nullable().optional(),
    startDate: z.string().nullable().optional(),
    dueDate: z.string().nullable().optional(),
    submitDate: z.string().nullable().optional(),
    active: z.boolean().nullable().optional(),
    canceled: z.boolean().nullable().optional(),
    vinRecallCheck: z.boolean().nullable().optional(),
    current: z.boolean().nullable().optional(),
    batchDate: z.string().nullable().optional(),
  })
  .nullable();

export type GetServiceCampaignHyundaiApiResponse = z.infer<
  typeof GetServiceCampaignHyundaiApiResponseSchema
>;

export const GetServiceCampaignHyundaiApiArgSchema = z.object({
  manufacturerActionNumber: z.string(),
});

export type GetServiceCampaignHyundaiApiArg = z.infer<typeof GetServiceCampaignHyundaiApiArgSchema>;

export const ServiceCampaignSchema = z.object({
  id: z.string().nullable().optional(),
  name: z.string().nullable().optional(),
  kind: z.enum(['PREVIOUS_CAMPAIGN', 'ACTIVE_CAMPAIGN']).nullable().optional(),
  state: z
    .enum(['DONE', 'CANCELLED', 'CLOSED', 'ACTIVE', 'RELEASED_NON_ACTIVE'])
    .nullable()
    .optional(),
  stateDate: z.string().nullable().optional(),
  defectCode: z.string().nullable().optional(),
  startDate: z.string().nullable().optional(),
  dueDate: z.string().nullable().optional(),
  serviceOrder: z
    .object({
      id: z.string().nullable().optional(),
      state: z.string().nullable().optional(),
      serviceCaseId: z.string().nullable().optional(),
      serviceJob: z
        .object({
          id: z.string().nullable().optional(),
        })
        .nullable()
        .optional(),
    })
    .nullable()
    .optional(),
});

export type ServiceCampaign = z.infer<typeof ServiceCampaignSchema>;

export const GetVehicleServiceCampaignApiResponseSchema = z
  .object({
    platformCode: z.string().nullable().optional(),
    serviceCode: z.string().nullable().optional(),
    serviceCampaign: z.array(ServiceCampaignSchema).optional(),
  })
  .nullable();

export type GetVehicleServiceCampaignApiResponse = z.infer<
  typeof GetVehicleServiceCampaignApiResponseSchema
>;

export const GetVehicleServiceCampaignApiArgSchema = z.object({
  vehicleId: z.string(),
});

export type GetVehicleServiceCampaignApiArg = z.infer<typeof GetVehicleServiceCampaignApiArgSchema>;

export const PostServiceCaseJobServiceCampaignApiResponseSchema = BaseVoidResponseSchema;

export type PostServiceCaseJobServiceCampaignApiResponse = z.infer<
  typeof PostServiceCaseJobServiceCampaignApiResponseSchema
>;

export const PostServiceCaseJobServiceCampaignApiArgSchema = z.object({
  serviceCaseId: z.string(),
  body: z.object({
    serviceCampaign: z.object({
      platformCode: z.string(),
      serviceCode: z.string(),
      serviceCampaignId: z.string(),
    }),
  }),
});

export type PostServiceCaseJobServiceCampaignApiArg = z.infer<
  typeof PostServiceCaseJobServiceCampaignApiArgSchema
>;

export const PostServiceCaseOrderJobServiceCampaignApiResponseSchema = BaseVoidResponseSchema;

export type PostServiceCaseOrderJobServiceCampaignApiResponse = z.infer<
  typeof PostServiceCaseOrderJobServiceCampaignApiResponseSchema
>;

export const PostServiceCaseOrderJobServiceCampaignApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  body: z.object({
    serviceCampaign: z.object({
      platformCode: z.string(),
      serviceCode: z.string(),
      serviceCampaignId: z.string(),
    }),
  }),
});

export type PostServiceCaseOrderJobServiceCampaignApiArg = z.infer<
  typeof PostServiceCaseOrderJobServiceCampaignApiArgSchema
>;
