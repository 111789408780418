import {Attributes, AttributesRow, Button, Card, DataStatus} from 'platform/components';
import {Space} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {isEmpty, isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetWarehouseQuery} from '@dms/api/metadaWarehouse';
import {GetCostCorrectionResponse} from '@dms/api/metadaWarehouseCostCorrection';
import {useGetUserQuery} from '@dms/api/user';
import i18n from '@dms/i18n';
import {warehouseRoutes} from '@dms/routes';
import {getUserName} from '@dms/shared';

import {
  composePath,
  EMPTY_PLACEHOLDER,
  Nullish,
  parseDate,
  RequiredTestIdProps,
  suffixTestId,
} from 'shared';

interface GeneralInformationTabProps extends RequiredTestIdProps {
  costCorrection: GetCostCorrectionResponse | Nullish;
  isCostCorrectionLoading: boolean;
}

export function GeneralInformationTab(props: GeneralInformationTabProps) {
  const formatDateTime = useDateTimeFormatter();

  const {data: costCorrectionWarehouse, isLoading: isCostCorrectionWarehouseLoading} =
    useGetWarehouseQuery(
      {warehouseId: props.costCorrection?.costCorrectionInfo.warehouseId as string},
      {skip: isNilOrEmpty(props.costCorrection?.costCorrectionInfo.warehouseId)}
    );

  const {data: costCorrectionCreatedBy, isLoading: isCostCorrectionCreatedByLoading} =
    useGetUserQuery(
      {id: props.costCorrection?.costCorrectionInfo.createdBy as string},
      {skip: isNilOrEmpty(props.costCorrection?.costCorrectionInfo.createdBy)}
    );

  const {data: receiveNoteCorrectionCreatedBy, isLoading: isReceiveNoteCorrectionCreatedByLoading} =
    useGetUserQuery(
      {id: props.costCorrection?.receiveNoteCorrectionInfo.createdBy as string},
      {skip: isNilOrEmpty(props.costCorrection?.receiveNoteCorrectionInfo.createdBy)}
    );

  const isLoading =
    props.isCostCorrectionLoading ||
    isCostCorrectionWarehouseLoading ||
    isCostCorrectionCreatedByLoading ||
    isReceiveNoteCorrectionCreatedByLoading;

  const formatToDateTimeShort = (date: string | Nullish) => {
    if (isNil(date) || isEmpty(date)) {
      return EMPTY_PLACEHOLDER;
    }
    return formatDateTime('dateTimeShort', parseDate(date));
  };

  const costCorrectionCreatedByName = getUserName(costCorrectionCreatedBy);

  const receiveNoteCorrectionCreatedByName = getUserName(receiveNoteCorrectionCreatedBy);

  const costCorrectionAttributes: AttributesRow[] = [
    {
      label: i18n.t('entity.warehouse.labels.warehouse'),
      value: costCorrectionWarehouse?.name,
    },
    {
      label: i18n.t('entity.warehouse.labels.createdBy'),
      value: costCorrectionCreatedByName,
    },
    {
      label: i18n.t('entity.warehouse.labels.dateAndTimeOfCreation'),
      value: formatToDateTimeShort(
        props.costCorrection?.costCorrectionInfo.dateAndTimeOfTheCreation
      ),
    },
  ];

  const receiveNoteCorrectionAttributes: AttributesRow[] = [
    {
      label: i18n.t('entity.warehouse.labels.number'),
      content: (
        <Button
          variant="link"
          size="small"
          title={props.costCorrection?.receiveNoteCorrectionInfo.number}
          rightIcon="action/launch"
          onClick={() =>
            window.open(
              composePath(warehouseRoutes.receiveNoteCorrectionDetailOverview, {
                params: {
                  id: props.costCorrection?.receiveNoteCorrectionInfo.receiveNoteCorrectionId,
                },
              }),
              '_blank'
            )
          }
          data-testid={suffixTestId('linkToReceiveNoteCorrection', props)}
        />
      ),
    },
    {
      label: i18n.t('entity.warehouse.labels.createdBy'),
      value: receiveNoteCorrectionCreatedByName,
    },
    {
      label: i18n.t('entity.warehouse.labels.dateAndTimeOfCreation'),
      value: formatToDateTimeShort(props.costCorrection?.receiveNoteCorrectionInfo.createdDate),
    },
  ];
  return (
    <DataStatus isLoading={isLoading} minHeight="100vh">
      <Card title={i18n.t('entity.warehouse.labels.costCorrectionInformation')}>
        <Attributes
          rows={costCorrectionAttributes}
          size="quarter"
          data-testid={suffixTestId('costCorrectionAttributes', props)}
        />
        <Space vertical={4} />
        <Card title={i18n.t('entity.warehouse.labels.receiveNoteCorrection')} variant="inlineGrey">
          <Attributes
            rows={receiveNoteCorrectionAttributes}
            size="quarter"
            data-testid={suffixTestId('receiveNoteCorrectionAttributes', props)}
          />
        </Card>
      </Card>
    </DataStatus>
  );
}
