import {differenceInMinutes} from 'date-fns';

/**
 * Calculates the number of 15-minute intervals between two dates
 *
 * @param startDate - The start date
 * @param endDate - The end date
 * @returns The number of 15-minute intervals, rounded up to ensure partial intervals are counted as full
 *
 * @example
 * ```ts
 * const intervals = calculateIntervalCount(new Date('2024-03-20 10:00'), new Date('2024-03-20 10:45'))
 * console.log(intervals) // 3
 * ```
 */
export function calculateIntervalCount(startDate: Date, endDate: Date): number {
  return Math.ceil(differenceInMinutes(endDate, startDate) / 15);
}
