import {
  Button,
  closeCurrentDialog,
  DataStatus,
  FormControl,
  openDialog,
  Search,
  Table,
  TableRow,
} from 'platform/components';
import {Box, Heading, HStack, Text, VStack} from 'platform/foundation';
import {useCurrencySymbolFormatter, useFormatCurrency, useFormatNumber} from 'platform/locale';

import {useState} from 'react';
import {useFieldArray} from 'react-hook-form';

import {isEmpty, isNil, values} from 'ramda';

import i18n from '@dms/i18n';
import {useTenant} from '@dms/shared';

import {FormValues} from '../types/FormValues';
import {PriceListItem} from '../types/PriceListItem';
import {getPriceListColumns} from '../utils/getPriceListColumns';
import {PriceListItemForm} from './PriceListItemForm';

interface PriceListProps {
  control: FormControl<FormValues>;
}

export function PriceList(props: PriceListProps) {
  const {tenantCurrency} = useTenant();
  const formatCurrencySymbol = useCurrencySymbolFormatter();
  const formatCurrency = useFormatCurrency();
  const formatNumber = useFormatNumber();

  const [searchString, setSearchString] = useState<string | null>(null);

  const {fields, append, remove, update} = useFieldArray({
    name: 'priceList',
    control: props.control,
    keyName: 'id',
  });

  const handleAddNewPriceListItem = () =>
    openDialog(
      <PriceListItemForm
        items={fields.map((field, index) => ({...field, originalIndex: index}))}
        onSubmit={async (data) => {
          await append(data);
          closeCurrentDialog();
        }}
      />,
      {
        title: i18n.t('entity.rental.labels.newPriceList'),
      }
    );

  const handleEditPriceListItem = (field: PriceListItem & {originalIndex: number}) => {
    openDialog(
      <PriceListItemForm
        defaultValues={field}
        items={fields.map((field, index) => ({...field, originalIndex: index}))}
        onSubmit={async (data) => {
          await update(field.originalIndex, data);
          closeCurrentDialog();
        }}
      />,
      {
        title: i18n.t('entity.rental.labels.editPriceList'),
      }
    );
  };

  const filteredAndSortedFields = fields
    .map((field, index) => ({...field, originalIndex: index}))
    .filter((item) => {
      if (isNil(searchString) || isEmpty(searchString)) {
        return true;
      }

      return values(item).some((value) => value.toString().includes(searchString));
    })
    .sort((a, b) => a.from - b.from);

  return (
    <VStack maxWidth="100%" spacing={4}>
      <Heading size={4}>{i18n.t('entity.rental.labels.priceList')}</Heading>

      <DataStatus
        isEmpty={isEmpty(filteredAndSortedFields)}
        minHeight={50}
        action={{
          title: i18n.t('entity.rental.labels.newPriceList'),
          leftIcon: 'content/add_circle',
          onClick: handleAddNewPriceListItem,
        }}
      >
        <HStack justify="space-between" width="100%" align="center">
          <Search value={searchString} onChange={setSearchString} />

          <Button
            variant="link"
            title={i18n.t('entity.rental.labels.newPriceList')}
            leftIcon="content/add_circle"
            onClick={handleAddNewPriceListItem}
          />
        </HStack>

        <Table
          columns={getPriceListColumns(formatCurrencySymbol(tenantCurrency))}
          variant="bordered"
        >
          {filteredAndSortedFields.map((field) => (
            <TableRow
              key={field.id}
              onRowClick={() => handleEditPriceListItem(field)}
              actionsWidth="5%"
              actions={{
                primary: [
                  {
                    icon: 'image/edit',
                    onClick: () => handleEditPriceListItem(field),
                    title: i18n.t('general.actions.edit'),
                  },
                  {
                    icon: 'action/delete',
                    onClick: () => remove(field.originalIndex),
                    title: i18n.t('general.actions.remove'),
                    severity: 'danger',
                  },
                ],
              }}
            >
              <Box padding={2}>
                <Text alternative>{field.name}</Text>
              </Box>
              <Box padding={2}>
                <Text>{formatNumber(field.from)}</Text>
              </Box>
              <Box padding={2}>
                <Text>{formatNumber(field.to)}</Text>
              </Box>
              <Box padding={2}>
                <Text>{i18n.t(`entity.rental.labels.${field.period}`)}</Text>
              </Box>
              <Box padding={2}>
                <Text>{formatCurrency(field.rate, tenantCurrency)}</Text>
              </Box>
              <Box padding={2}>
                <Text>{formatCurrency(field.ratePerKilometer, tenantCurrency, 2)}</Text>
              </Box>
              <Box padding={2}>
                <Text>{formatNumber(field.includedKilometers)} Km</Text>
              </Box>
            </TableRow>
          ))}
        </Table>
      </DataStatus>
    </VStack>
  );
}
