import {compareAsc} from 'date-fns';

import {parseDate} from 'shared';

import {BigCalendarEvent} from '../types/BigCalendarEvent';

/**
 * Compares two events and sorts them by their start date in ascending order
 * @param {BigCalendarEvent} firstEvent - The first event to compare
 * @param {BigCalendarEvent} secondEvent - The second event to compare
 * @returns {number} Negative number if firstEvent is earlier, positive if secondEvent is earlier, 0 if equal
 */
export function sortEvents(firstEvent: BigCalendarEvent, secondEvent: BigCalendarEvent): number {
  const firstDate = parseDate(firstEvent.start);
  const secondDate = parseDate(secondEvent.start);
  return compareAsc(firstDate, secondDate);
}
