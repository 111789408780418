import {z} from 'zod';

import {BasePriceSchema} from '@dms/api/shared';

/**
 * GET
 */
export const GetIssueNoteCorrectionRequestSchema = z.object({
  issueNoteCorrectionId: z.string(),
});
export type GetIssueNoteCorrectionRequest = z.infer<typeof GetIssueNoteCorrectionRequestSchema>;

export const GetIssueNoteCorrectionResponseSchema = z.object({
  issueNoteCorrection: z.object({
    number: z.string(),
    returnedBy: z.string().nullish(),
    receivedBy: z.string().nullish(),
    warehouseId: z.string(),
    createdAt: z.string(),
    returnedAt: z.string().nullish(),
  }),
  issueNote: z.object({
    id: z.string(),
    number: z.string(),
    issuedBy: z.string(),
    receivedBy: z.string(),
    warehouseId: z.string(),
    createdAt: z.string(),
    issuedAt: z.string(),
  }),
  originDocTypeInformation: z.object({
    directSale: z
      .object({
        id: z.string(),
        number: z.string(),
        type: z.string(),
        variant: z.string(),
        customerId: z.string(),
        vehicleId: z.string(),
      })
      .nullish(),
    serviceOrder: z
      .object({
        id: z.string(),
        number: z.string(),
        type: z.string(),
        variant: z.string(),
        customerId: z.string(),
        vehicleId: z.string(),
      })
      .nullish(),
    serviceOrderIssue: z
      .object({
        id: z.string(),
        number: z.string(),
        type: z.string(),
        variant: z.string(),
        customerId: z.string(),
        vehicleId: z.string(),
      })
      .nullish(),
  }),
  summaryInformation: z.object({
    totalValue: BasePriceSchema,
  }),
});
export type GetIssueNoteCorrectionResponse = z.infer<typeof GetIssueNoteCorrectionResponseSchema>;
