export function CloudImage() {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <mask id="arrowMask">
          <rect x="25" y="20" width="30" height="60" fill="white" />
        </mask>
      </defs>

      <path
        d="M58.2587 30.0369C58.2834 30.0367 58.3084 30.0367 58.3334 30.0367C66.6177 30.0367 73.3334 36.7647 73.3334 45.0643C73.3334 52.7993 67.5 59.1693 60 60M58.2587 30.0369C58.308 29.4869 58.3334 28.9299 58.3334 28.367C58.3334 18.2232 50.1254 10 40 10C30.4108 10 22.5411 17.3756 21.7348 26.7731M58.2587 30.0369C57.9177 33.8253 56.4287 37.282 54.1427 40.055M21.7348 26.7731C13.28 27.5791 6.66669 34.713 6.66669 43.3943C6.66669 51.4723 12.3926 58.2107 20 59.7577M21.7348 26.7731C22.2609 26.7229 22.7941 26.6972 23.3334 26.6972C27.0861 26.6972 30.5492 27.9398 33.335 30.0367"
        stroke="#BFE0FF"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animate
          attributeName="stroke-dasharray"
          from="0 200"
          to="200 200"
          dur="3s"
          fill="freeze"
        />
      </path>
      <g mask="url(#arrowMask)">
        <path
          d="M40 43.3334V70M40 43.3334C37.666 43.3334 33.3051 49.981 31.6667 51.6667M40 43.3334C42.334 43.3334 46.695 49.981 48.3334 51.6667"
          stroke="#0065FF"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <animateTransform
            attributeName="transform"
            type="translate"
            values="0 60; 0 0; 0 0; 0 -60; 0 60; 0 60"
            keyTimes="0; 0.2; 0.85; 0.975; 0.9751; 1"
            dur="4s"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="0.4 0 0.2 1; 0 0 0 0; 0.4 0 0.2 1; 0 0 1 1; 0 0 0 0"
          />
          <animate
            attributeName="opacity"
            values="1; 1; 1; 0; 0; 1"
            keyTimes="0; 0.75; 0.95; 0.951; 0.999; 1"
            dur="4s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </svg>
  );
}
