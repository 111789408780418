import {closeCurrentDialog} from 'platform/components';

import {useInvalidateBusinessCaseOnlineOfferMutation} from '@dms/api/businessCase';
import i18n from '@dms/i18n';

import {handleApiError} from '../../utils/handleApiError';
import {DestroyDialog} from '../DestroyDialog/DestroyDialog';

interface InvalidateOnlineOfferDialogProps {
  offerId: string;
}

export function InvalidateOnlineOfferDialog(props: InvalidateOnlineOfferDialogProps) {
  const [invalidateOnlineOffer, {isLoading: isInvalidatingOnlineOffer}] =
    useInvalidateBusinessCaseOnlineOfferMutation();

  return (
    <DestroyDialog
      iconPath="alert_icon/circle"
      heading={i18n.t('entity.invalidateOnlineOfferDialog.heading')}
      description={i18n.t('entity.invalidateOnlineOfferDialog.description')}
      isLoading={isInvalidatingOnlineOffer}
      onSubmitButtonTitle={i18n.t('entity.invalidateOnlineOfferDialog.confirm')}
      onSubmit={() => {
        invalidateOnlineOffer({offerId: props.offerId})
          .unwrap()
          .then(closeCurrentDialog)
          .catch(handleApiError);
      }}
    />
  );
}
