import {metadaApi} from '@dms/api/core';

import {
  GetDirectSaleMaterialItemCalculatePriceRequest,
  GetDirectSaleMaterialItemCalculatePriceRequestSchema,
  GetDirectSaleMaterialItemCalculatePriceResponse,
  GetDirectSaleMaterialItemCalculatePriceResponseSchema,
  GetDirectSaleMaterialItemRequest,
  GetDirectSaleMaterialItemRequestSchema,
  GetDirectSaleMaterialItemResponse,
  GetDirectSaleMaterialItemResponseSchema,
  PatchDirectSaleMaterialItemRequest,
  PatchDirectSaleMaterialItemRequestSchema,
  PatchDirectSaleMaterialItemResponse,
  PatchDirectSaleMaterialItemResponseSchema,
} from './types';

export const metadaWarehouseDirectSaleItemSparePart = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getDirectSaleMaterialItem: build.query<
      GetDirectSaleMaterialItemResponse,
      GetDirectSaleMaterialItemRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/item-spare-part/${queryArg.itemId}`,
        method: 'GET',
      }),
      providesTags: ['directSaleMaterialItems'],
      extraOptions: {
        requestSchema: GetDirectSaleMaterialItemRequestSchema,
        responseSchema: GetDirectSaleMaterialItemResponseSchema,
      },
    }),
    patchDirectSaleMaterialItem: build.mutation<
      PatchDirectSaleMaterialItemResponse,
      PatchDirectSaleMaterialItemRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/item-spare-part/${queryArg.itemId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: [
        'directSales',
        'directSaleMaterialBasket',
        'afsCheckout',
        'afsPaymentsList',
        'afsPaymentById',
      ],
      extraOptions: {
        requestSchema: PatchDirectSaleMaterialItemRequestSchema,
        responseSchema: PatchDirectSaleMaterialItemResponseSchema,
      },
    }),
    getDirectSaleMaterialItemCalculatePrice: build.query<
      GetDirectSaleMaterialItemCalculatePriceResponse,
      GetDirectSaleMaterialItemCalculatePriceRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/item-spare-part/${queryArg.itemId}/calculate-price`,
        method: 'POST',
        body: queryArg.body,
      }),
      extraOptions: {
        requestSchema: GetDirectSaleMaterialItemCalculatePriceRequestSchema,
        responseSchema: GetDirectSaleMaterialItemCalculatePriceResponseSchema,
      },
    }),
  }),
});

export const {
  useGetDirectSaleMaterialItemQuery,
  useLazyGetDirectSaleMaterialItemQuery,
  usePatchDirectSaleMaterialItemMutation,
  useGetDirectSaleMaterialItemCalculatePriceQuery,
  useLazyGetDirectSaleMaterialItemCalculatePriceQuery,
} = metadaWarehouseDirectSaleItemSparePart;
