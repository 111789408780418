import {Choice, Dialog, Label} from 'platform/components';
import {Space} from 'platform/foundation';

import {CodeResponseBody} from '@dms/api/codelist';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

interface EditSourceDialogProps extends TestIdProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  updateSource: (newSource: string | null) => void;
  source: string | null;
  sources: CodeResponseBody[] | undefined;
}

export function EditSourceDialog(props: EditSourceDialogProps) {
  const handleSelect = (value: string | null) => {
    props.updateSource(value);
  };

  const sourceOptions = props.sources?.map((source) => ({
    label: source.name,
    value: source.codeId,
  }));

  return (
    <Dialog
      isOpen={props.isOpen}
      onClose={props.onClose}
      size="small"
      scrollBehavior="outside"
      title={i18n.t('entity.businessCase.labels.sourceDialogTitle')}
      buttons={[
        {variant: 'secondary', onClick: props.onClose, title: i18n.t('general.actions.discard')},
        {variant: 'primary', onClick: props.onSave, title: i18n.t('general.actions.save')},
      ]}
      data-testid={suffixTestId('dialog', props)}
    >
      <Choice
        value={props.source}
        options={sourceOptions ?? []}
        onChange={handleSelect}
        label={i18n.t('entity.businessCase.labels.source')}
        data-testid={suffixTestId('assignee', props)}
      />
      <Space vertical={4} />
      <Label>{i18n.t('entity.businessCase.labels.sourceDialogDescription')}</Label>
    </Dialog>
  );
}
