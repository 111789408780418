import {DragOverlay} from '@dnd-kit/core';

import {useLayoutEffect} from 'react';

import {isNotNil, not} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {LAYOUT_CONTENT_ID, RequiredTestIdProps, suffixTestId} from 'shared';

import {useActiveId} from '../hooks/useActiveId';
import {useEvents} from '../hooks/useEvents';
import {AllDayEventItem} from './AllDayEventItem';
import {EventItem} from './EventItem';

export function BigCalendarDragOverlay(props: RequiredTestIdProps) {
  const activeId = useActiveId();
  const optimisticEvents = useEvents();

  useLayoutEffect(() => {
    const layoutContent = document.getElementById(LAYOUT_CONTENT_ID);

    if (activeId && document.body.style.overflow !== 'hidden') {
      document.body.style.overflow = 'hidden';
    }
    if (isNilOrEmpty(activeId) && document.body.style.overflow === 'hidden') {
      document.body.style.overflow = 'auto';
    }

    if (activeId && layoutContent && layoutContent?.style.overflow !== 'hidden') {
      layoutContent.style.overflow = 'hidden';
    }
    if (isNilOrEmpty(activeId) && layoutContent && layoutContent?.style.overflow === 'hidden') {
      layoutContent.style.overflow = 'auto';
    }
  }, [activeId]);

  const activeOptimisticEvent = activeId ? optimisticEvents.find((e) => e.id === activeId) : null;

  const draggedEventDuration = isNotNil(activeOptimisticEvent)
    ? activeOptimisticEvent.end.getTime() - activeOptimisticEvent.start.getTime()
    : null;

  return (
    <DragOverlay data-testid={suffixTestId('bigCalendarDragOverlay', props)}>
      {isNotNil(activeOptimisticEvent) &&
      isNotNil(draggedEventDuration) &&
      not(activeOptimisticEvent.isAllDay) ? (
        <EventItem
          key={activeOptimisticEvent.id}
          event={activeOptimisticEvent}
          data-testid={suffixTestId('bigCalendarDragOverlayEventItem', props)}
        />
      ) : null}
      {isNotNil(activeOptimisticEvent) && activeOptimisticEvent.isAllDay ? (
        <AllDayEventItem
          event={activeOptimisticEvent}
          position={0}
          data-testid={suffixTestId('bigCalendarDragOverlayAllDayEventItem', props)}
        />
      ) : null}
    </DragOverlay>
  );
}
