import {isSameDay} from 'date-fns';
import {Box, getSize, Grid, GridItem, useDevice, getValueByDevice} from 'platform/foundation';
import {css, useTheme} from 'styled-components';

import {useMemo} from 'react';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {useActiveId} from '../hooks/useActiveId';
import {useDnd} from '../hooks/useDnd';
import {useIsDragging} from '../hooks/useIsDragging';
import {BigCalendarEvent} from '../types/BigCalendarEvent';
import {calculateAllDayEventsHeight} from '../utils/calculateAllDayEventsHeight';
import {calculateTimePosition} from '../utils/calculateTimePosition';
import {getEventsForDate} from '../utils/getEventsForDate';
import {AllDayEventItem} from './AllDayEventItem';
import {CurrentTimeIndicator} from './CurrentTimeIndicator';
import {DroppableCell} from './DroppableCell';
import {EventItem} from './EventItem';
import {TimeSlot} from './TimeSlot';

interface DayViewProps extends RequiredTestIdProps {
  currentDate: Date;
  events: BigCalendarEvent[];
  isReadOnly?: boolean;
  onEventClick?: (event: BigCalendarEvent) => void;
  onDateClick?: (date: Date) => void;
}

export function DayView(props: DayViewProps) {
  const theme = useTheme();
  const device = useDevice();

  const isDragging = useIsDragging();
  const activeId = useActiveId();
  const {activeDnDId} = useDnd();

  const draggedEvent = props.events.find((e) => e.id === activeId || e.id === activeDnDId);

  const allDayHeight = useMemo(
    () => calculateAllDayEventsHeight(props.events, [props.currentDate]),
    [props.events, props.currentDate]
  );

  return (
    <Box position="relative" width="100%" height="100%">
      <div
        data-testid={suffixTestId('dayView-allDayEvents', props)}
        css={css`
          position: sticky;
          top: ${getSize(getValueByDevice(device, 10, 15))};
          z-index: ${theme.zIndices.BIG_CALENDAR_STICKY_ALLDAY_SLOT};
          background-color: ${theme.colors.palettes.neutral[10][100]};
          overflow: hidden;
        `}
      >
        <Grid columns={20} spacing={0}>
          <GridItem span={[3, 1]}>
            <div
              css={css`
                width: 100%;
                height: ${allDayHeight}px;
                background-color: ${theme.colors.palettes.neutral[10][100]};
                border-bottom: ${allDayHeight > 0
                  ? `1px solid ${theme.colors.general.separator}`
                  : 'none'};
              `}
            />
          </GridItem>

          <GridItem span={[17, 19]}>
            <div
              css={css`
                position: relative;
                min-width: ${getSize(25)};
                max-width: ${getValueByDevice(device, 25, undefined)};
                height: ${allDayHeight}px;
                border-left: ${allDayHeight > 0
                  ? `1px solid ${theme.colors.general.separator}`
                  : 'none'};
                border-bottom: ${allDayHeight > 0
                  ? `1px solid ${theme.colors.general.separator}`
                  : 'none'};
                z-index: ${theme.zIndices.BIG_CALENDAR_STICKY_ALLDAY_SLOT};
              `}
            >
              {getEventsForDate(props.events, props.currentDate).allDayEvents.map(
                (event, eventIndex) => {
                  if (isDragging && event.id === activeId) {
                    return null;
                  }

                  return (
                    <AllDayEventItem
                      key={event.id}
                      data-testid={suffixTestId(`dayView-[${eventIndex}]`, props)}
                      event={event}
                      position={event.allDayPosition}
                      onClick={props.onEventClick}
                    />
                  );
                }
              )}
            </div>
          </GridItem>
        </Grid>
      </div>

      <div
        data-testid={props['data-testid']}
        css={css`
          display: grid;
          grid-template-columns: repeat(20, 1fr);
          gap: 0 0;
          overflow-x: hidden;
          overflow-y: ${isDragging ? 'hidden' : 'auto'};
          -webkit-overflow-scrolling: touch;
        `}
      >
        <GridItem span={[3, 1]}>
          {TIME_SLOTS.map((hour) => (
            <TimeSlot
              key={hour}
              hour={hour}
              data-testid={suffixTestId(`dayView-[${hour}]`, props)}
            />
          ))}
        </GridItem>

        <GridItem span={[17, 19]}>
          <Box position="relative">
            {TIME_SLOTS.map((hour) => (
              <DroppableCell
                key={`droppableCell-${hour}`}
                data-testid={suffixTestId(`dayView-[${hour}]`, props)}
                date={props.currentDate}
                hour={hour}
                draggedEvent={draggedEvent}
                onDateClick={props.onDateClick}
              />
            ))}

            <div
              css={css`
                position: absolute;
                top: 0;
                left: 4px;
                right: 3px;
                pointer-events: none;
              `}
            >
              {isSameDay(props.currentDate, new Date()) ? (
                <CurrentTimeIndicator data-testid={suffixTestId('dayView', props)} />
              ) : null}

              {getEventsForDate(props.events, props.currentDate).regularEvents.map(
                (event, eventIndex) => {
                  if (isDragging && event.id === activeId) {
                    return null;
                  }

                  return (
                    <EventItem
                      key={event.id}
                      data-testid={suffixTestId(`dayView-[${eventIndex}]`, props)}
                      event={event}
                      top={calculateTimePosition(event.start)}
                      onClick={props.onEventClick}
                    />
                  );
                }
              )}
            </div>
          </Box>
        </GridItem>
      </div>
    </Box>
  );
}
const NUMBER_OF_HOURS = 24;
const TIME_SLOTS = Array.from({length: NUMBER_OF_HOURS}, (_, hour) => hour);
