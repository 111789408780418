import {motion} from 'framer-motion';
import styled from 'styled-components';

import {UserSidebarIconSize} from '@dms/api/shared';

import {TOP_BAR_ITEM_SIZE} from '../constants/topBarItemSize';
import {useIsMobileNavigation} from '../hooks/useIsMobileNavigation';

interface SidebarMenuTriggerProps {
  iconSize: UserSidebarIconSize;
  isOpen: boolean;
}

export function SidebarToggleMenuIcon(props: SidebarMenuTriggerProps) {
  const isMobileNavigation = useIsMobileNavigation();

  const lineProps = {
    initial: 'closed',
    animate: props.isOpen ? 'opened' : 'closed',
    transition: {ease: 'easeOut', duration: 0.1},
  };

  const isLargeIconSize = !isMobileNavigation && props.iconSize === 'large';

  return (
    <motion.svg
      viewBox={`0 0 ${isLargeIconSize ? LARGE_UNIT_WIDTH : DEFAULT_UNIT_WIDTH} ${isLargeIconSize ? LARGE_UNIT_HEIGHT : DEFAULT_UNIT_HEIGHT}`}
      overflow="visible"
      preserveAspectRatio="none"
      width={isLargeIconSize ? LARGE_WIDTH : DEFAULT_WIDTH}
      height={isLargeIconSize ? LARGE_HEIGHT : DEFAULT_HEIGHT}
    >
      <Line
        x1="0"
        x2={isLargeIconSize ? LARGE_UNIT_WIDTH : DEFAULT_UNIT_WIDTH}
        y1="0"
        y2="0"
        variants={VARIANT.TOP}
        {...lineProps}
      />
      <Line
        x1="0"
        x2={isLargeIconSize ? LARGE_UNIT_WIDTH : DEFAULT_UNIT_WIDTH}
        y1="2"
        y2="2"
        variants={VARIANT.CENTER}
        {...lineProps}
      />
      <Line
        x1="0"
        x2={isLargeIconSize ? LARGE_UNIT_WIDTH : DEFAULT_UNIT_WIDTH}
        y1="4"
        y2="4"
        variants={VARIANT.BOTTOM}
        {...lineProps}
      />
    </motion.svg>
  );
}

const LARGE_WIDTH = 20;
const LARGE_HEIGHT = TOP_BAR_ITEM_SIZE;
const LARGE_UNIT_HEIGHT = 4;
const LARGE_UNIT_WIDTH = (LARGE_UNIT_HEIGHT * LARGE_WIDTH) / LARGE_HEIGHT;

const DEFAULT_WIDTH = 18;
const DEFAULT_HEIGHT = 10;
const DEFAULT_UNIT_HEIGHT = 4;
const DEFAULT_UNIT_WIDTH = (DEFAULT_UNIT_HEIGHT * DEFAULT_WIDTH) / DEFAULT_HEIGHT;

const VARIANT = {
  TOP: {closed: {rotate: 0, translateY: 0}, opened: {rotate: 45, translateY: 2}},
  CENTER: {closed: {opacity: 1}, opened: {opacity: 0}},
  BOTTOM: {closed: {rotate: 0, translateY: 0}, opened: {rotate: -45, translateY: -2}},
};

const Line = styled(motion.line)`
  vector-effect: non-scaling-stroke;
  stroke: ${(props) => props.theme.colors.general.white};
  stroke-width: 2;
`;
