import {metadaApi} from '@dms/api/core';

import {
  DeleteEmployeeCostCenterApiArg,
  DeleteEmployeeCostCenterApiResponse,
  GetEmployeeCostCenterApiArg,
  GetEmployeeCostCenterApiResponse,
  GetEmployeeCostCentersListApiArg,
  GetEmployeeCostCentersListApiResponse,
  PatchEmployeeCostCenterApiArg,
  PatchEmployeeCostCenterApiResponse,
  PostEmployeeCostCenterApiArg,
  PostEmployeeCostCenterApiResponse,
  PostEmployeeCostCenterSetAsActiveApiArg,
  PostEmployeeCostCenterSetAsActiveApiResponse,
  PostEmployeeCostCenterSetAsInactiveApiArg,
  PostEmployeeCostCenterSetAsInactiveApiResponse,
} from './types';

export const metadaEmployeeCostCenterApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postEmployeeCostCenter: build.mutation<
      PostEmployeeCostCenterApiResponse,
      PostEmployeeCostCenterApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-cost-center`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['employeeCostCenters'],
    }),
    getEmployeeCostCenter: build.query<
      GetEmployeeCostCenterApiResponse,
      GetEmployeeCostCenterApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-cost-center/${queryArg.id}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'employeeCostCenter', projectId: queryArg.id},
      ],
    }),
    deleteEmployeeCostCenter: build.mutation<
      DeleteEmployeeCostCenterApiResponse,
      DeleteEmployeeCostCenterApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-cost-center/${queryArg.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'employeeCostCenters',
        {type: 'employeeCostCenter', projectId: queryArg.id},
      ],
    }),
    patchEmployeeCostCenter: build.mutation<
      PatchEmployeeCostCenterApiResponse,
      PatchEmployeeCostCenterApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-cost-center/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'employeeCostCenters',
        {type: 'employeeCostCenter', projectId: queryArg.id},
      ],
    }),
    postEmployeeCostCenterSetAsActive: build.mutation<
      PostEmployeeCostCenterSetAsActiveApiResponse,
      PostEmployeeCostCenterSetAsActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-cost-center/${queryArg.costCenterId}/set-as-active`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'employeeCostCenters',
        {type: 'employeeCostCenter', projectId: queryArg.costCenterId},
      ],
    }),
    postEmployeeCostCenterSetAsInactive: build.mutation<
      PostEmployeeCostCenterSetAsInactiveApiResponse,
      PostEmployeeCostCenterSetAsInactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-cost-center/${queryArg.costCenterId}/set-as-inactive`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'employeeCostCenters',
        {type: 'employeeCostCenter', projectId: queryArg.costCenterId},
      ],
    }),
    getEmployeeCostCentersList: build.query<
      GetEmployeeCostCentersListApiResponse,
      GetEmployeeCostCentersListApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-cost-centers`,
        params: {company: queryArg.company, branch: queryArg.branch},
      }),
      providesTags: ['employeeCostCenters'],
    }),
  }),
});

export const {
  usePostEmployeeCostCenterMutation,
  useGetEmployeeCostCenterQuery,
  useDeleteEmployeeCostCenterMutation,
  usePatchEmployeeCostCenterMutation,
  usePostEmployeeCostCenterSetAsActiveMutation,
  usePostEmployeeCostCenterSetAsInactiveMutation,
  useGetEmployeeCostCentersListQuery,
} = metadaEmployeeCostCenterApi;
