import {openDeleteDialog} from 'platform/components';
import {VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useState} from 'react';

import {isArray} from 'ramda-adjunct';

import {
  useDeleteServicePackageDeleteMutation,
  usePostServicePackageSetActiveMutation,
  usePostServicePackageSetInActiveMutation,
} from '@dms/api/metadaWorkshopServicePackage';
import {settingsRoutes} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {RequiredTestIdProps, composePath, suffixTestId, useNavigate} from 'shared';

import {ActionCallback, DataGrid, refreshDatagrid} from 'features/datagrid';

import {ConfirmCloneDialog} from '../../components/ConfirmCloneDialog';

export function Packages(props: RequiredTestIdProps) {
  const navigate = useNavigate();
  const [editId, setEditId] = useState<string | null>(null);

  const [deleteServicePackageDelete] = useDeleteServicePackageDeleteMutation();
  const [postServicePackageSetActive] = usePostServicePackageSetActiveMutation();
  const [postServicePackageSetInActive] = usePostServicePackageSetInActiveMutation();

  const actionCallback: ActionCallback = ({rowId, actionKey, refreshData}) => {
    const id = isArray(rowId) ? rowId[0] : rowId;

    match(actionKey)
      .with('redirectDetail', 'edit', () =>
        navigate(composePath(settingsRoutes.workshopEditServicePackages, {params: {id}}))
      )
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () =>
            deleteServicePackageDelete({servicePackageId: id})
              .unwrap()
              .then(refreshData)
              .catch(handleApiError),
        })
      )
      .with('setAsInactive', () =>
        postServicePackageSetInActive({servicePackageId: id})
          .unwrap()
          .then(refreshData)
          .catch(handleApiError)
      )
      .with('setAsActive', () =>
        postServicePackageSetActive({servicePackageId: id})
          .unwrap()
          .then(refreshData)
          .catch(handleApiError)
      )
      .with('clone', () => setEditId(id))
      .otherwise(() => undefined);
  };

  return (
    <VStack>
      <ConfirmCloneDialog
        id={editId}
        onClose={() => setEditId(null)}
        onCloseComplete={() => {
          refreshDatagrid('service-packages');
          setEditId(null);
        }}
        data-testid={suffixTestId('confirmCloneDialog', props)}
      />
      <DataGrid
        gridCode="service-packages"
        autoHeight
        actionCallback={actionCallback}
        data-testid={suffixTestId('service-packages-dataGrid', props)}
      />
    </VStack>
  );
}
