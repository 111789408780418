import {AuditData} from '../../../store/carAudit/reducerUtils';
import {getCategoryIdFromFormFieldName} from './getCategoryIdFromFormFieldName';

/**
 * Categories can be enabled or disabled in settings in the app.
 * For example Documentation category can be disabled for vehicle condition inspection.
 * But some fields from this category can be still present in the audit fields (like VIN number).
 * Since the entire category is not in the UI and user cannot change these fields,
 * it's needed to exclude these fields from the template to avoid overriding them.
 */
export function getCategoryIdsToExcludeFromTemplate(
  auditFields: AuditData['fields'],
  allCategoriesIds: string[]
) {
  const categoryIdsFromAuditFields = Object.keys(auditFields).map((key) => {
    const {categoryId} = getCategoryIdFromFormFieldName(key);
    return categoryId;
  });
  return categoryIdsFromAuditFields.filter((id) => !allCategoriesIds.includes(id));
}
