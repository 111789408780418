import {
  Actions,
  Button,
  Card,
  DataStatus,
  Dialog,
  openDialog,
  showNotification,
} from 'platform/components';
import {HStack, Heading, Hide, Show, Space, Text} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useCallback, useState} from 'react';

import {mergeAll} from 'ramda';
import {isArray, isPositive} from 'ramda-adjunct';

import {
  useGetServiceOrderPreviousRequestCountQuery,
  usePostServiceOrderPreviousRequestDeleteAllMutation,
  usePostServiceOrderPreviousRequestDeleteMutation,
} from '@dms/api/metadaWorkshopServiceOrderClaim';
import i18n from '@dms/i18n';
import {workshopRoutes} from '@dms/routes';
import {ORDER_TABS, handleApiError, queryParams, workshopSearchParams} from '@dms/shared';

import {TestIdProps, composePath, suffixTestId} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject} from 'features/datagrid';

import {PreviousRequestsRowData} from '../types/previousRequestsRowData';
import {AssignPreviousRequests} from './AssignPreviousRequests';

interface PreviousRequestsSectionProps extends TestIdProps {
  serviceCaseId: string;
  orderId: string;
  isReadOnly?: boolean;
  isAssignDisabled?: boolean;
}

export function PreviousRequestsSection(props: PreviousRequestsSectionProps) {
  const [deactivateAllModal, showDeactivateAllModal] = useState<boolean>(false);
  const [deactivateRequestId, setDeactivateRequestId] = useState<string | null>(null);

  const {data, isLoading, isError, isFetching} = useGetServiceOrderPreviousRequestCountQuery({
    serviceCaseId: props.serviceCaseId,
    serviceOrderId: props.orderId,
  });

  const [postServiceOrderPreviousRequestDelete] =
    usePostServiceOrderPreviousRequestDeleteMutation();

  const [postServiceOrderPreviousRequestDeleteAll] =
    usePostServiceOrderPreviousRequestDeleteAllMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData}) => {
    const castedRowData = rowData as PreviousRequestsRowData;
    const requestId = isArray(rowId) ? rowId[0] : rowId;

    const path = composePath(workshopRoutes.serviceCaseDetail, {
      params: {
        id: castedRowData.serviceCaseId.value,
      },
      queryParams: {
        source: 'service-orders',
        section: workshopSearchParams.serviceDetail.orders,
        [queryParams.SERVICE_CASE_ORDER_ID]: castedRowData.serviceOrderId.value,
        [queryParams.SERVICE_CASE_ORDER_TAB]: ORDER_TABS.JOBS,
        [queryParams.SERVICE_CASE_OPEN_JOB_ID]: requestId,
      },
    });

    match(actionKey)
      .with('open', () => window.open(path))
      .with('delete', () => setDeactivateRequestId(requestId))
      .otherwise(() =>
        showNotification.error(`Action callback was not specified for action ${actionKey}`)
      );
  };

  const handleAssign = () =>
    openDialog(
      <AssignPreviousRequests
        serviceCaseId={props.serviceCaseId}
        orderId={props.orderId}
        data-testid={suffixTestId('assignDialog', props)}
      />,
      {
        title: i18n.t('entity.order.labels.assignPreviousRequests'),
        size: 'large',
      }
    );

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {serviceOrderId: props.orderId}]),
    [props.orderId]
  );

  return (
    <DataStatus isLoading={isLoading || isFetching} isError={isError} minHeight={24}>
      <Heading size={4}>{i18n.t('entity.order.labels.previousRequests')}</Heading>
      <Space vertical={1} />
      <Text size="small" color="secondary">
        {i18n.t('entity.order.labels.previousRequestsDescription')}
      </Text>
      <Space vertical={4} />
      <Hide when={isPositive(data?.totalPreviousRequests)}>
        <Button
          title={i18n.t('entity.order.actions.assignRequest')}
          variant="secondary"
          onClick={handleAssign}
          isDisabled={props.isReadOnly || props.isAssignDisabled}
          data-testid={suffixTestId('assignContract', props)}
        />
      </Hide>
      <Show when={isPositive(data?.totalPreviousRequests)}>
        <Card variant="inlineGrey">
          <HStack justify="space-between">
            <Heading size={4}>{i18n.t('page.workshop.labels.requests')}</Heading>
            <Actions
              actions={[
                {
                  type: 'button',
                  variant: 'dangerLink',
                  leftIcon: 'navigation/close',
                  size: 'small',
                  title: i18n.t('entity.order.actions.deactivateAll'),
                  onClick: () => showDeactivateAllModal(true),
                  isDisabled: props.isReadOnly,
                  'data-testid': suffixTestId('deactivateContract', props),
                },
                {
                  title: i18n.t('entity.order.actions.assignRequest'),
                  leftIcon: 'content/add_circle',
                  variant: 'link',
                  type: 'button',
                  onClick: handleAssign,
                  'data-testid': suffixTestId('detail', props),
                },
              ]}
              data-testid={suffixTestId('customerContractActions', props)}
            />
          </HStack>
          <Space vertical={4} />
          <DataGrid
            // DG must be re-rendered on folder change to update the query modifier
            // eslint-disable-next-line no-restricted-syntax
            key={data?.totalPreviousRequests}
            gridCode="service-order-previous-request"
            actionCallback={actionCallback}
            queryModifier={queryModifier}
            data-testid={suffixTestId('grid', props)}
            autoHeight
          />
          <Space vertical={4} />
        </Card>
      </Show>
      <Dialog
        size="small"
        isOpen={deactivateAllModal}
        buttons={[
          {
            variant: 'secondary',
            title: i18n.t('general.actions.discard'),
            onClick: () => showDeactivateAllModal(false),
            'data-testid': suffixTestId('actions.discard', props),
          },
          {
            variant: 'danger',
            title: i18n.t('general.actions.deactivate'),
            onClick: () =>
              postServiceOrderPreviousRequestDeleteAll({
                serviceCaseId: props.serviceCaseId,
                serviceOrderId: props.orderId,
              })
                .unwrap()
                .then(() => showNotification.success())
                .catch(handleApiError)
                .finally(() => showDeactivateAllModal(false)),

            'data-testid': suffixTestId('actions.save', props),
          },
        ]}
        onClose={() => showDeactivateAllModal(false)}
        data-testid={suffixTestId('deactivateAllDialog', props)}
      >
        <Text alternative>{`${i18n.t('entity.order.labels.deactivateAllPreviousRequests')}?`}</Text>
      </Dialog>
      <Dialog
        size="small"
        isOpen={Boolean(deactivateRequestId)}
        buttons={[
          {
            variant: 'secondary',
            title: i18n.t('general.actions.discard'),
            onClick: () => setDeactivateRequestId(null),
            'data-testid': suffixTestId('deactivateDialog-actions-discard', props),
          },
          {
            variant: 'danger',
            title: i18n.t('general.actions.deactivate'),
            onClick: () =>
              postServiceOrderPreviousRequestDelete({
                serviceCaseId: props.serviceCaseId,
                serviceOrderId: props.orderId,
                body: {
                  serviceOrderPreviousJobId: [deactivateRequestId || ''],
                },
              })
                .unwrap()
                .then(() => showNotification.success())
                .catch(handleApiError)
                .finally(() => setDeactivateRequestId(null)),
            'data-testid': suffixTestId('deactivateDialog-actions-save', props),
          },
        ]}
        onClose={() => setDeactivateRequestId(null)}
        data-testid={suffixTestId('deactivateDialog', props)}
      >
        <Text alternative>{`${i18n.t('entity.order.labels.deactivateServiceRequest')}?`}</Text>
      </Dialog>
    </DataStatus>
  );
}
