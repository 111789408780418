import {Card, DataStatus, FormControl, FormField} from 'platform/components';
import {Box, Grid, GridItem, Heading, Space, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {isEmpty} from 'ramda';

import {useReadCodelistQuery} from '@dms/api/codelist';
import i18n from '@dms/i18n';

import {RequiredTestIdProps} from 'shared';

import {RentalVehicleFormType} from '../../types/RentalVehicleFormType';
import {PriceGroupCard} from './components/PriceGroupCard';

interface RentalInformationProps extends RequiredTestIdProps {
  formApi: UseFormReturn<RentalVehicleFormType>;
  control: FormControl<RentalVehicleFormType>;
  isLoading: boolean;
}

export function RentalInformation(props: RentalInformationProps) {
  const {
    data: equipmentData,
    isLoading: isLoadingEquipment,
    isError: isEquipmentError,
  } = useReadCodelistQuery({codelistId: 'equipment'});

  const {
    data: carParkData,
    isLoading: isLoadingCarParkData,
    isError: isCarParkDataError,
  } = useReadCodelistQuery({codelistId: 'carPark'});

  const carParkOptions =
    carParkData?.codes?.map((code) => ({value: code.codeId, label: code.name})) || [];

  const equipmentOptions =
    equipmentData?.codes?.map((code) => ({
      value: code.codeId,
      label: code.name,
    })) || [];

  return (
    <Box padding={4}>
      <Grid columns={3}>
        <GridItem span={2}>
          <DataStatus isLoading={props.isLoading}>
            <VStack spacing={4}>
              <Card title={i18n.t('entity.vehicle.labels.rentalInformation')}>
                <Grid columns={4}>
                  <FormField
                    type="choice"
                    control={props.control}
                    name="rentalProperties.carParkId"
                    label={i18n.t('entity.rental.labels.carParkId')}
                    isRequired
                    isLoading={isLoadingCarParkData}
                    options={carParkOptions}
                    isDisabled={isCarParkDataError}
                  />

                  <FormField
                    type="number"
                    control={props.control}
                    name="rentalProperties.availability.mileageLimit"
                    suffix="Km"
                    label={i18n.t('entity.rental.labels.mileageLimit')}
                  />

                  <FormField
                    type="apiDate"
                    control={props.control}
                    name="rentalProperties.availability.from"
                    label={i18n.t('entity.rental.labels.createFrom')}
                  />

                  <FormField
                    type="apiDate"
                    control={props.control}
                    name="rentalProperties.availability.to"
                    label={i18n.t('entity.rental.labels.createTo')}
                  />

                  <GridItem span={4}>
                    <FormField
                      type="textarea"
                      control={props.control}
                      name="rentalProperties.note"
                      label={i18n.t('entity.rental.labels.note')}
                    />
                  </GridItem>
                </Grid>

                <Space vertical={4} />

                <VStack spacing={4}>
                  <Heading size={4}>{i18n.t('entity.rental.labels.equipment')}</Heading>

                  <DataStatus
                    isEmpty={isEmpty(equipmentData?.codes)}
                    isLoading={isLoadingEquipment}
                    isError={isEquipmentError}
                    spacing={20}
                  >
                    <FormField
                      type="checkboxes"
                      control={props.control}
                      name="rentalProperties.equipment"
                      options={equipmentOptions}
                      direction="vertical"
                    />
                  </DataStatus>
                </VStack>
              </Card>

              <PriceGroupCard {...props} />
            </VStack>
          </DataStatus>
        </GridItem>
      </Grid>
    </Box>
  );
}
