import {
  Attributes,
  Button,
  ButtonGroup,
  Card,
  Chips,
  closeCurrentDialog,
  DataStatus,
  FormSubmitHandler,
  openDialog,
  showNotification,
} from 'platform/components';
import {Heading, HStack, Show, VStack} from 'platform/foundation';

import {useCallback, useEffect, useState} from 'react';

import {equals, head, isNil} from 'ramda';
import {isNilOrEmpty, isNotNil} from 'ramda-adjunct';

import {
  ContractInformationResponseBodyV2,
  CustomerRequestBody,
  CustomerResponseBodyV2,
  PatchCustomerApiArg,
  useGetCustomerV2Query,
  usePatchCustomerMutation,
} from '@dms/api/customer';
import i18n from '@dms/i18n';
import {
  ContractInformationForm,
  CustomerBillingInformationCard,
  CustomerMatchOrCreate,
  EditLegalPersonForm,
  EditPhysicalPersonForm,
  getNaturalPersonFullName,
  handleApiError,
  isCustomerNaturalPerson,
  useAddress,
} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {CustomerType} from '../types';

interface AssignCustomerDialogProps extends RequiredTestIdProps {
  onSave: (
    customer: CustomerResponseBodyV2,
    contractInformation: ContractInformationResponseBodyV2,
    type: CustomerType | null
  ) => void;
  isAdditionalCustomer?: boolean;
  defaultValues?: Partial<{
    customerId: string;
    type: CustomerType;
    contractInformationId: string;
  }>;
}

export function AssignCustomerForm(props: AssignCustomerDialogProps) {
  const [patchCustomer, {isLoading: isCustomerPatchLoading}] = usePatchCustomerMutation();

  const [contractInformation, setContractInformation] =
    useState<ContractInformationResponseBodyV2 | null>(null);
  const [customer, setCustomer] = useState<CustomerResponseBodyV2 | null>(null);
  const [customerType, setCustomerType] = useState<CustomerType | null>(
    props.isAdditionalCustomer ? (props.defaultValues?.type ?? 'operator') : null
  );

  const customerId = props.defaultValues?.customerId ?? customer?.id;

  const {
    data: fetchedCustomer,
    isLoading,
    isError,
  } = useGetCustomerV2Query({customerId: customerId ?? ''}, {skip: isNil(customerId)});

  const handleSetCustomer = useCallback(
    (newCustomer: CustomerResponseBodyV2 | null) => {
      setContractInformation(
        newCustomer?.contractInformation?.find(
          (info) => info.id === props.defaultValues?.contractInformationId
        ) ??
          newCustomer?.contractInformation.find((info) => info.permanent) ??
          head(newCustomer?.contractInformation ?? []) ??
          null
      );
      setCustomer(newCustomer);
    },
    [props.defaultValues?.contractInformationId]
  );

  const {composeAddress} = useAddress();

  const onSubmit = () => {
    if (isNil(customer) || isNil(contractInformation)) {
      showNotification.error();
      return;
    }
    props.onSave(customer, contractInformation, customerType);
    closeCurrentDialog();
  };

  const onPatchCustomer: FormSubmitHandler<CustomerRequestBody> = async (values) => {
    if (isNilOrEmpty(values.foundingPerson?.phoneNumbers?.[0]?.phoneNumber?.number)) {
      values.foundingPerson!.phoneNumbers = [];
    }

    if (isNilOrEmpty(values.foundingPerson?.emails?.[0]?.email)) {
      values.foundingPerson!.emails = [];
    }

    const args: PatchCustomerApiArg = {
      customerId: customer!.id,
      customerRequestBody: values,
    };

    await patchCustomer(args)
      .unwrap()
      .then(() => showNotification.success())
      .then(closeCurrentDialog)
      .catch(handleApiError);
  };

  const handleEdit = () =>
    openDialog(
      isCustomerNaturalPerson(customer!) ? (
        <EditPhysicalPersonForm
          isLoading={isCustomerPatchLoading}
          customer={customer!}
          onSubmit={onPatchCustomer}
        />
      ) : (
        <EditLegalPersonForm
          isLoading={isCustomerPatchLoading}
          customer={customer!}
          onSubmit={onPatchCustomer}
        />
      ),
      {
        title: i18n.t('entity.customer.labels.editCustomerInformation'),
        withAdditionalFooter: true,
        scrollBehavior: isCustomerNaturalPerson(customer!) ? 'inside' : 'outside',
      }
    );

  const onCreateContract = () =>
    openDialog(<ContractInformationForm customerId={customer?.id ?? ''} />, {
      title: i18n.t('entity.customer.actions.newContractInformation'),
      withAdditionalFooter: true,
    });

  useEffect(() => {
    if (isNotNil(fetchedCustomer)) {
      handleSetCustomer(fetchedCustomer);
    }
  }, [fetchedCustomer, handleSetCustomer]);

  const getCardTitle = () => {
    if (isNil(customer)) {
      return undefined;
    }

    return isCustomerNaturalPerson(customer)
      ? getNaturalPersonFullName(customer?.foundingPerson)
      : customer.businessInfo?.businessInfo?.tradeName;
  };

  return (
    <DataStatus minHeight={30} isLoading={isLoading} isError={isError}>
      <Show when={isNil(customer)}>
        <CustomerMatchOrCreate
          secondStepComponentType="BUSINESS_CASE"
          onCustomer={setCustomer}
          data-testid={suffixTestId('CustomerMatchOrCreate', props)}
        />
      </Show>
      <Show when={isNotNil(customer)}>
        <VStack spacing={4}>
          <Card
            variant="inlineGrey"
            title={getCardTitle()}
            actions={[
              {
                type: 'button',
                title: i18n.t('general.actions.edit'),
                leftIcon: 'image/edit',
                onClick: handleEdit,
                size: 'small',
                variant: 'link',
              },
              {
                type: 'button',
                title: i18n.t('general.actions.changeCustomer'),
                leftIcon: 'action/cached',
                onClick: () => setCustomer(null),
                size: 'small',
                variant: 'link',
              },
            ]}
          >
            <Attributes
              size="quarter"
              rows={[
                {
                  label: i18n.t('entity.customer.labels.address'),
                  value: isCustomerNaturalPerson(customer!)
                    ? composeAddress(customer?.foundingPerson?.permanentAddress?.address)
                    : composeAddress(customer?.businessInfo?.address?.address),
                },

                {
                  label: i18n.t('contractInformation.labels.registrationNumber'),
                  value: customer?.businessInfo?.businessInfo?.registrationNumber,
                },
                {
                  label: i18n.t('contractInformation.labels.vatNumber'),
                  value: customer?.businessInfo?.businessInfo?.vatNumber,
                },
              ]}
              data-testid={suffixTestId('contractInformation', props)}
            />
          </Card>

          <VStack spacing={4}>
            <HStack align="center" justify="space-between">
              <Heading size={4}>
                {i18n.t('entity.customer.labels.billingAndContractInformation')}
              </Heading>
              <Button
                title={i18n.t('entity.customer.actions.addNewBillingInformation')}
                leftIcon="content/add_circle"
                variant="link"
                onClick={onCreateContract}
                data-testid={suffixTestId('addNewBillingInformation', props)}
              />
            </HStack>

            {customer?.contractInformation?.map((contract) => (
              <CustomerBillingInformationCard
                key={contract.id}
                customerId={customer?.id}
                isSelected={equals(contractInformation?.id, contract.id)}
                contract={contract}
                onSelect={setContractInformation}
              />
            ))}
          </VStack>

          <Show when={props.isAdditionalCustomer}>
            <Heading size={4}>{i18n.t('entity.person.labels.role')}</Heading>
            <Chips
              options={CUSTOMER_TYPE_CHIPS}
              value={customerType ? [customerType] : null}
              onChange={(values) => {
                const parsedValue = isNotNil(values)
                  ? head(values)
                  : head(CUSTOMER_TYPE_CHIPS)?.value;
                const value: CustomerType = isCustomerTypeChip(parsedValue)
                  ? parsedValue
                  : 'operator';

                setCustomerType(value);
              }}
              data-testid={suffixTestId('customerType', props)}
            />
          </Show>

          <ButtonGroup
            align="right"
            buttons={[
              {
                variant: 'secondary',
                title: i18n.t('general.actions.discard'),
                onClick: closeCurrentDialog,
              },
              {
                title: i18n.t('general.actions.assignCustomer'),
                onClick: onSubmit,
                'data-testid': suffixTestId('assign-customer', props),
              },
            ]}
          />
        </VStack>
      </Show>
    </DataStatus>
  );
}

const isCustomerTypeChip = (value: unknown): value is CustomerType =>
  value === 'operator' || value === 'recipient';

const CUSTOMER_TYPE_CHIPS: {value: CustomerType; label: string}[] = [
  {value: 'operator', label: i18n.t('entity.accounting.labels.operator')},
  {value: 'recipient', label: i18n.t('entity.accounting.labels.recipient')},
];
