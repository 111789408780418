import {metadaApi} from '@dms/api/core';

import {
  GetCostCorrectionRequest,
  GetCostCorrectionRequestSchema,
  GetCostCorrectionResponse,
  GetCostCorrectionResponseSchema,
} from './types';

export const metadaWarehouseCostCorrectionApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getCostCorrection: build.query<GetCostCorrectionResponse, GetCostCorrectionRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/issue-note-value-correction/${queryArg.costCorrectionId}`,
        method: 'GET',
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'costCorrections', id: queryArg.costCorrectionId},
      ],
      extraOptions: {
        requestSchema: GetCostCorrectionRequestSchema,
        responseSchema: GetCostCorrectionResponseSchema,
      },
    }),
  }),
});

export const {useGetCostCorrectionQuery} = metadaWarehouseCostCorrectionApi;
