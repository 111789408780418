import {metadaApi} from '@dms/api/core';

import {
  GetServiceOrderPreviousRequestCountApiArg,
  GetServiceOrderPreviousRequestCountApiArgSchema,
  GetServiceOrderPreviousRequestCountApiResponse,
  GetServiceOrderPreviousRequestCountApiResponseSchema,
  PostServiceOrderAssignPreviousRequestApiArg,
  PostServiceOrderAssignPreviousRequestApiArgSchema,
  PostServiceOrderAssignPreviousRequestApiResponse,
  PostServiceOrderAssignPreviousRequestApiResponseSchema,
  PostServiceOrderPreviousRequestDeleteAllApiArg,
  PostServiceOrderPreviousRequestDeleteAllApiArgSchema,
  PostServiceOrderPreviousRequestDeleteAllApiResponse,
  PostServiceOrderPreviousRequestDeleteAllApiResponseSchema,
  PostServiceOrderPreviousRequestDeleteApiArg,
  PostServiceOrderPreviousRequestDeleteApiArgSchema,
  PostServiceOrderPreviousRequestDeleteApiResponse,
  PostServiceOrderPreviousRequestDeleteApiResponseSchema,
} from './types';

export const metadaWorkshopServiceOrderClaimApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getServiceOrderPreviousRequestCount: build.query<
      GetServiceOrderPreviousRequestCountApiResponse,
      GetServiceOrderPreviousRequestCountApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/previous-request/count`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'previousRequestsCount', id: queryArg.serviceOrderId},
      ],
      extraOptions: {
        responseSchema: GetServiceOrderPreviousRequestCountApiResponseSchema,
        requestSchema: GetServiceOrderPreviousRequestCountApiArgSchema,
      },
    }),
    postServiceOrderPreviousRequestDeleteAll: build.mutation<
      PostServiceOrderPreviousRequestDeleteAllApiResponse,
      PostServiceOrderPreviousRequestDeleteAllApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/previous-request/delete-all`,
        method: 'POST',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'previousRequestsCount', id: queryArg.serviceOrderId},
      ],
      extraOptions: {
        responseSchema: PostServiceOrderPreviousRequestDeleteAllApiResponseSchema,
        requestSchema: PostServiceOrderPreviousRequestDeleteAllApiArgSchema,
      },
    }),
    postServiceOrderPreviousRequestDelete: build.mutation<
      PostServiceOrderPreviousRequestDeleteApiResponse,
      PostServiceOrderPreviousRequestDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/previous-request/delete-selected`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'previousRequestsCount', id: queryArg.serviceOrderId},
      ],
      extraOptions: {
        responseSchema: PostServiceOrderPreviousRequestDeleteApiResponseSchema,
        requestSchema: PostServiceOrderPreviousRequestDeleteApiArgSchema,
      },
    }),
    postServiceOrderAssignPreviousRequest: build.mutation<
      PostServiceOrderAssignPreviousRequestApiResponse,
      PostServiceOrderAssignPreviousRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/service-case/${queryArg.serviceCaseId}/order/${queryArg.serviceOrderId}/previous-request/assign`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'previousRequestsCount', id: queryArg.serviceOrderId},
      ],
      extraOptions: {
        responseSchema: PostServiceOrderAssignPreviousRequestApiResponseSchema,
        requestSchema: PostServiceOrderAssignPreviousRequestApiArgSchema,
      },
    }),
  }),
});

export const {
  useGetServiceOrderPreviousRequestCountQuery,
  usePostServiceOrderPreviousRequestDeleteAllMutation,
  usePostServiceOrderPreviousRequestDeleteMutation,
  usePostServiceOrderAssignPreviousRequestMutation,
} = metadaWorkshopServiceOrderClaimApi;
