import {ButtonProps, openDialog, showNotification} from 'platform/components';
import {Box, HStack} from 'platform/foundation';

import {equals, isNil, not} from 'ramda';
import {isArray, isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {GetWarehouseResponse} from '@dms/api/metadaWarehouse';
import {GetWarehouseAccountResponse} from '@dms/api/metadaWarehouseAccount';
import {
  ReceiveNoteBasketItem,
  useBulkDeleteReceiveNoteBasketItemsMutation,
  useDeleteReceiveNoteBasketMutation,
  useGetReceiveNoteBasketQuery,
  usePatchReceiveNoteBasketItemQuantityMutation,
  usePostReceiveNoteBasketCheckoutMutation,
} from '@dms/api/metadaWarehouseReceiveNoteBasket';
import {TenantResponseBody} from '@dms/api/shared';
import i18n from '@dms/i18n';
import {
  AfterSalesMaterialBasket,
  EitherQuantityOrError,
  FullScreenModal,
  handleApiError,
  logError,
  MaterialBasketItemEditingDetails,
  useInvalidBasketItemsIds,
} from '@dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {RECEIVE_NOTE_ITEM_DIALOG} from '../constants/receiveNoteItemDialog';
import {ItemRowData} from '../types/ItemRowData';
import {ReceiveNoteItemDetail} from './ReceiveNoteItemDetail';
import {ReceiveNoteMaterialList} from './ReceiveNoteMaterialList';

interface ReceiveNoteMaterialModalProps extends RequiredTestIdProps {
  receiveNoteId: string;
  warehouse: GetWarehouseResponse | Nullish;
  warehouseAccount: GetWarehouseAccountResponse | Nullish;
  tenant: TenantResponseBody | Nullish;
  state: 'PENDING' | 'COMPLETED' | Nullish;
  onDiscard: VoidFunction;
}

export function ReceiveNoteMaterialModal(props: ReceiveNoteMaterialModalProps) {
  const {
    data: basket,
    isLoading: isBasketLoading,
    isError: hasBasketError,
  } = useGetReceiveNoteBasketQuery({receiveNoteId: props.receiveNoteId});

  const [checkoutBasket, {isLoading: isCheckingOutBasket}] =
    usePostReceiveNoteBasketCheckoutMutation();

  const [emptyBasket, {isLoading: isDiscarding}] = useDeleteReceiveNoteBasketMutation();

  const [changeBasketItemQuantity] = usePatchReceiveNoteBasketItemQuantityMutation();

  const [deleteBasketItems, {isLoading: isDeletingBasketItems}] =
    useBulkDeleteReceiveNoteBasketItemsMutation();

  const basketItems = basket?.receiveNoteBasketItem ?? [];
  const basketTotalPrice = basket?.receiveNoteBasketTotalPrice;

  const {setInvalidBasketItemId, invalidBasketItemsIds} = useInvalidBasketItemsIds(basketItems);

  const handleDiscard = () =>
    emptyBasket({receiveNoteId: props.receiveNoteId})
      .unwrap()
      .then(props.onDiscard)
      .catch(handleApiError);

  const handleAdd = () =>
    checkoutBasket({
      receiveNoteId: props.receiveNoteId,
    })
      .unwrap()
      .then(() => showNotification.success(i18n.t('entity.warehouse.notifications.itemsAdded')))
      .then(props.onDiscard)
      .catch(handleApiError);

  const handleQuantityChange = async (itemId: string, quantity: EitherQuantityOrError) => {
    setInvalidBasketItemId(itemId, quantity);

    const basketItem = basketItems.find((item) => equals(item.id, itemId));
    const hasQuantityChanged = not(equals(basketItem?.quantity, quantity.newQuantity));

    if (quantity.hasError || not(hasQuantityChanged)) {
      return;
    }

    await changeBasketItemQuantity({
      receiveNoteId: props.receiveNoteId,
      itemId,
      body: {quantity: quantity.newQuantity as number},
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleEdit = (editingDetails: MaterialBasketItemEditingDetails) => {
    if (isNil(editingDetails?.itemId)) {
      return logError('itemId is not defined');
    }

    if (isNil(editingDetails?.warehouseId)) {
      return logError('warehouseId is not defined');
    }

    const itemRowData: ItemRowData = {
      warehouseId: editingDetails.warehouseId,
      articleId: editingDetails.itemId,
      catalogNumber: editingDetails.itemNumber,
      name: editingDetails.itemName,
    };

    openDialog(
      <ReceiveNoteItemDetail
        isEditing
        receiveNoteId={props.receiveNoteId}
        receiveNoteItemId={editingDetails.itemId}
        itemRowData={itemRowData}
        warehouse={props.warehouse}
        warehouseAccount={props.warehouseAccount}
        tenant={props.tenant}
        state={props.state}
        data-testid={suffixTestId('dialog.editMaterial', props)}
      />,
      {
        id: RECEIVE_NOTE_ITEM_DIALOG,
        title: i18n.t('general.labels.edit'),
        size: 'large',
        withAdditionalFooter: true,
        'data-testid': suffixTestId('dialogs.editItem', props),
      }
    );
  };

  const handleDelete = async (itemsIds: string | string[]) => {
    const ids = isArray(itemsIds) ? itemsIds : [itemsIds];

    const requestBody = {receiveNoteItemId: ids.map((id) => id)};

    await deleteBasketItems({
      receiveNoteId: props.receiveNoteId,
      body: requestBody,
    })
      .unwrap()
      .catch(handleApiError);
  };

  const actions: ButtonProps[] = [
    {
      title: i18n.t('general.actions.discard'),
      variant: 'secondary',
      onClick: handleDiscard,
      isLoading: isDiscarding,
      'data-testid': suffixTestId('headerActions.discard', props),
    },
    {
      title: i18n.t('general.actions.add'),
      variant: 'primary',
      onClick: handleAdd,
      isLoading: isCheckingOutBasket,
      isDisabled: isNilOrEmpty(basketItems) || isNotNilOrEmpty(invalidBasketItemsIds),
      'data-testid': suffixTestId('headerActions.add', props),
    },
  ];

  return (
    <FullScreenModal
      headline={i18n.t('general.actions.addMaterial')}
      actions={actions}
      data-testid={props['data-testid']}
    >
      <Box padding={4} height="100%">
        <HStack spacing={4} height="100%">
          <Box flex={5}>
            <ReceiveNoteMaterialList
              receiveNoteId={props.receiveNoteId}
              warehouse={props.warehouse}
              warehouseAccount={props.warehouseAccount}
              tenant={props.tenant}
              state={props.state}
              data-testid={suffixTestId('itemList', props)}
            />
          </Box>
          <Box flex={1} minWidth={86}>
            <AfterSalesMaterialBasket<ReceiveNoteBasketItem>
              basket={{
                items: basketItems,
                totalPrice: basketTotalPrice,
                isLoading: isBasketLoading,
                hasError: hasBasketError,
                hasInvalidItems: isNotNilOrEmpty(invalidBasketItemsIds),
                isDeletingItems: isDeletingBasketItems,
              }}
              mechanic={{isMechanicHidden: true}}
              onQuantityChange={handleQuantityChange}
              onEdit={handleEdit}
              onDelete={handleDelete}
              data-testid={suffixTestId('basket', props)}
            />
          </Box>
        </HStack>
      </Box>
    </FullScreenModal>
  );
}
