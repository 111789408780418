import {DataStatus, showNotification, useDialog} from 'platform/components';
import {HStack, Link} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useState} from 'react';

import {
  useGetBusinessCaseQuery,
  usePatchBusinessCaseSourceCodeIdMutation,
} from '@dms/api/businessCase';
import {useGetInterestQuery, useUpdateInterestMutation} from '@dms/api/interest';
import {EntityResourceIds} from '@dms/api/shared';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {useCodeList} from '../../hooks/useCodeList';
import {handleApiError} from '../../utils/handleApiError';
import {EditSourceDialog} from './components/EditSourceDialog';

interface SourceProps extends TestIdProps {
  recordId: string;
  resourceId?: EntityResourceIds;
}

export function Source(props: SourceProps) {
  const {
    data: businessCase,
    isLoading: businessCaseLoading,
    isError: businessCaseError,
  } = useGetBusinessCaseQuery(
    {businessCaseId: props.recordId},
    {
      skip: props.resourceId !== EntityResourceIds.businessCase,
    }
  );
  const {
    data: interest,
    isLoading: interestLoading,
    isError: interestError,
  } = useGetInterestQuery(
    {id: props.recordId},
    {
      skip: props.resourceId !== EntityResourceIds.interest,
    }
  );

  const [codes, {isLoading: codesLoading, isError: codesError}] = useCodeList('interest_source');
  const [patchBusinessCaseSourceCodeId] = usePatchBusinessCaseSourceCodeIdMutation();
  const [selectedSourceId, setSelectedSourceId] = useState<string | null>(() =>
    match(props.resourceId)
      .with(EntityResourceIds.interest, () => interest?.sourceCodeId ?? null)
      .with(EntityResourceIds.businessCase, () => businessCase?.sourceCodeId ?? null)
      .otherwise(() => null)
  );
  const [updateInterest] = useUpdateInterestMutation();
  const [isOpen, setOpen, setClose] = useDialog();

  const handleOpenDialog = () => {
    setOpen();
  };

  const handleSave = () => {
    if (!props.resourceId) {
      return;
    }

    const updatePromise =
      props.resourceId === EntityResourceIds.interest
        ? updateInterest({
            id: props.recordId,
            updateInterestRequestBody: {
              sourceCodeId: selectedSourceId,
            },
          })
        : patchBusinessCaseSourceCodeId({
            businessCaseId: props.recordId,
            patchBusinessCaseSourceCodeIdRequestBody: {
              sourceCodeId: selectedSourceId,
            },
          });

    updatePromise
      .unwrap()
      .then(() => {
        showNotification.success();
        setClose();
      })
      .catch(handleApiError);
  };

  const usedSourceCodeId = match(props.resourceId)
    .with(EntityResourceIds.interest, () => interest?.sourceCodeId)
    .with(EntityResourceIds.businessCase, () => businessCase?.sourceCodeId)
    .otherwise(() => undefined);

  const selectedSource = codes?.find((code) => code.codeId === usedSourceCodeId) ?? null;

  const isLoading =
    (props.resourceId === EntityResourceIds.interest ? interestLoading : businessCaseLoading) ||
    codesLoading;
  const isError =
    (props.resourceId === EntityResourceIds.interest ? interestError : businessCaseError) ||
    codesError;

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <HStack align="center" spacing={1}>
        <Link
          size="small"
          leftIcon="action/language"
          onClick={handleOpenDialog}
          title={selectedSource?.name ?? i18n.t('entity.businessCase.labels.source')}
          data-testid={suffixTestId('source', props)}
        />

        <EditSourceDialog
          isOpen={isOpen}
          onClose={setClose}
          source={selectedSourceId ?? null}
          sources={codes ?? []}
          data-testid={suffixTestId('editUser', props)}
          updateSource={(value) => setSelectedSourceId(value)}
          onSave={handleSave}
        />
      </HStack>
    </DataStatus>
  );
}
