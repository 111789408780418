import {showNotification} from 'platform/components';

import {isArray} from 'ramda-adjunct';

import {useAccPatchDocumentExportDateSetNullMutation} from '@dms/api/metadaAccounting';
import {AccPatchDocumentExportDateSetNullApiArg} from '@dms/api/metadaErrorLogs';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

type RowData = {
  documentId: {
    value: string;
  };
  typDokladu: {
    value: {
      key: AccPatchDocumentExportDateSetNullApiArg['type'];
    };
  };
};

export function AccountingActivityLog() {
  const [patchConfiguration] = useAccPatchDocumentExportDateSetNullMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId: _, rowData, refreshData}) => {
    if (isArray(rowData)) {
      return;
    }
    if (actionKey === 'reexport') {
      patchConfiguration({
        id: (rowData as RowData).documentId.value,
        type: (rowData as RowData).typDokladu.value.key,
      })
        .unwrap()
        .then(() => {
          showNotification.success();
          refreshData();
        })
        .catch(handleApiError);
    }
  };

  return (
    <SettingsTemplate
      data-testid={testIds.settings.accountingActivityLog('header')}
      header={{
        title: i18n.t('page.accounting.labels.accountingActivityLog'),
      }}
    >
      <DataGrid gridCode="documents-pre-accounting" actionCallback={actionCallback} />
    </SettingsTemplate>
  );
}
