import {filter, map, pipe, toPairs} from 'ramda';
import {isTrue} from 'ramda-adjunct';

import {PermissionScopeResponseBody} from '@dms/api/accessControlList';

type ProtectedUnitEntries = [string, {value: boolean; scopes: PermissionScopeResponseBody[]}];
type ProtectedUnitRecord = Record<
  string,
  {
    value: boolean;
    scopes: PermissionScopeResponseBody[];
  }
>;

export const getSubmitPermissionsData = pipe(
  toPairs<ProtectedUnitRecord>,
  filter(([_, protectedUnit]: ProtectedUnitEntries) => isTrue(protectedUnit.value)),
  map(([protectedUnitId, {scopes}]: ProtectedUnitEntries) => ({
    protectedUnitId,
    scopes:
      scopes?.map((scope) => ({id: scope.id, values: scope.values.map((item) => item.id)})) ?? [],
  }))
);
