import {Option} from 'platform/components';

import {map} from 'ramda';

import {metadaApi} from '@dms/api/core';

import {
  PostTireOrderApiResponse,
  PostTireOrderApiArg,
  GetTireOrderApiResponse,
  GetTireOrderApiArg,
  GetTireOptionsApiResponse,
  GetTireOptionsApiArg,
  GetTireManufacturerApiResponse,
  GetEmployeeMechanicsByBranchApiResponse,
  GetEmployeeMechanicsByBranchApiArg,
  PostTireSetApiResponse,
  PostTireSetApiArg,
  PostTiresArchiveApiResponse,
  PostTiresArchiveApiArg,
  PostTireSetDeliveryNoteApiResponse,
  PostTireSetDeliveryNoteApiArg,
  PostTireSetLabelApiResponse,
  PostTireSetLabelApiArg,
  PostTiresTransferToOrderApiResponse,
  PostTiresTransferToOrderApiArg,
  GetTireSetApiResponse,
  GetTireSetApiArg,
  PatchTireSetApiResponse,
  PatchTireSetApiArg,
  PostTireSetAddWheelsApiResponse,
  PostTireSetAddWheelsApiArg,
  PostTiresReceiptApiResponse,
  PostTiresReceiptApiArg,
  PostTiresIssueApiResponse,
  PostTiresIssueApiArg,
  PostTiresRestoreApiResponse,
  PostTiresRestoreApiArg,
  GetCodelistTireLocationApiResponse,
  GetTiresGeneralSettingsApiArg,
  GetTiresGeneralSettingsApiResponse,
  PutTiresGeneralSettingsApiArg,
  PutTiresGeneralSettingsApiResponse,
  PostTireManufacturerApiResponse,
  PostTireManufacturerApiArg,
  PatchTireManufacturerApiResponse,
  PatchTireManufacturerApiArg,
  PatchTireManufacturerSetActiveApiResponse,
  PatchTireManufacturerSetActiveApiArg,
  PatchTireManufacturerSetDefaultApiResponse,
  PatchTireManufacturerSetDefaultApiArg,
  PatchTireManufacturerSetInactiveApiResponse,
  PatchTireManufacturerSetInactiveApiArg,
  PostTireLocationApiResponse,
  PostTireLocationApiArg,
  PatchTireLocationApiResponse,
  PatchTireLocationApiArg,
  PatchTireLocationPositionDownApiResponse,
  PatchTireLocationPositionDownApiArg,
  PatchTireLocationPositionUpApiResponse,
  PatchTireLocationPositionUpApiArg,
  PatchTireLocationSetActiveApiResponse,
  PatchTireLocationSetActiveApiArg,
  PatchTireLocationSetInactiveApiResponse,
  PatchTireLocationSetInactiveApiArg,
  GetTireVehicleCountApiResponse,
  GetTireVehicleCountApiArg,
  GetOrderSetDefaultContactPersonApiArg,
  GetOrderSetDefaultContactPersonApiResponse,
  GetTireIdsOrdersApiApiResponse,
  GetTireIdsOrdersApiApiArg,
  GetTireWarehousesApiArg,
  GetTireWarehousesApiResponse,
  PostWarehouseEnablingSetAsDisallowApiResponse,
  PostWarehouseEnablingSetAsAllowApiArg,
  GetTireWarehouseApiArg,
  GetTireWarehouseApiResponse,
  PostWarehouseEnablingSetAsDisallowApiArg,
  PostTireWarehouseApiArg,
  PostTireWarehouseApiResponse,
  DeleteTireWarehouseApiArg,
  DeleteTireWarehouseApiResponse,
  PatchTireWarehouseApiArg,
  PatchTireWarehouseApiResponse,
  GetTireExternalWarehouseApiArg,
  GetTireExternalWarehouseApiResponse,
  PostWarehouseEnablingSetAsAllowApiResponse,
  GetTireWarehouseSettingsApiResponse,
  GetTireWarehouseSettingsApiArg,
  GetDefaultStoreUntilByOrderIdApiResponse,
  GetDefaultStoreUntilByOrderIdApiArg,
  DeleteGeneralSettingApiArg,
  DeleteGeneralSettingApiResponse,
  GetGeneralSettingApiArg,
  GetGeneralSettingApiResponse,
  PatchGeneralSettingApiArg,
  PatchGeneralSettingApiResponse,
  PostGeneralSettingApiArg,
  PostGeneralSettingApiResponse,
  GetTireSetLocationResponse,
  GetTireSetLocationArg,
  UpdateTireSetLocationResponse,
  UpdateTireSetLocationArg,
  PostTireSetRequestApiResponse,
  PostTireSetRequestApiArg,
  GetTireSetActionsApiArg,
  GetTireSetActionsApiResponse,
  PostTireSetCancelRequestApiArg,
  PostTireSetCancelRequestApiResponse,
  PatchTireSetRequestApiResponse,
  PatchTireSetRequestApiArg,
  GetTireSetRequestApiResponse,
  GetTireSetRequestApiArg,
  GetTireAspectRatiosApiResponse,
  GetTireAspectRatiosApiArg,
  PostTireAspectRatioApiResponse,
  PostTireAspectRatioApiArg,
  GetTireAspectRatioApiResponse,
  GetTireAspectRatioApiArg,
  DeleteTireAspectRatioApiResponse,
  DeleteTireAspectRatioApiArg,
  PatchTireAspectRatioApiResponse,
  PatchTireAspectRatioApiArg,
  PostTireAspectRatioSetActiveApiResponse,
  PostTireAspectRatioSetActiveApiArg,
  PostTireAspectRatioSetInactiveApiResponse,
  PostTireAspectRatioSetInactiveApiArg,
  PatchTireCategoryApiResponse,
  PatchTireCategoryApiArg,
  DeleteTireCategoryApiResponse,
  DeleteTireCategoryApiArg,
  GetTireCategoryApiResponse,
  GetTireCategoryApiArg,
  PostTireCategoryApiArg,
  PostTireCategoryApiResponse,
  PostTireCategorySetInactiveApiArg,
  PostTireCategorySetDefaultApiArg,
  PostTireCategorySetDefaultApiResponse,
  PostTireCategorySetActiveApiArg,
  PostTireLoadIndexApiResponse,
  PostTireLoadIndexApiArg,
  GetTireLoadIndexApiResponse,
  GetTireLoadIndexApiArg,
  DeleteTireLoadIndexApiResponse,
  DeleteTireLoadIndexApiArg,
  GetTireLoadIndexesApiArg,
  GetTireLoadIndexesApiResponse,
  PostTireLoadIndexSetActiveApiResponse,
  PostTireLoadIndexSetActiveApiArg,
  PostTireLoadIndexSetInactiveApiResponse,
  PostTireLoadIndexSetInactiveApiArg,
  GetTireRimsApiArg,
  GetTireRimsApiResponse,
  PostTireRimApiResponse,
  PostTireRimApiArg,
  GetTireRimApiArg,
  GetTireRimApiResponse,
  DeleteTireRimApiArg,
  DeleteTireRimApiResponse,
  PatchTireRimApiArg,
  PatchTireRimApiResponse,
  PostTireRimSetActiveApiResponse,
  PostTireRimSetActiveApiArg,
  PostTireRimSetInactiveApiResponse,
  PostTireRimSetInactiveApiArg,
  GetTireSpeedRatiosApiArg,
  GetTireSpeedRatiosApiResponse,
  PostTireSpeedRatioApiResponse,
  PostTireSpeedRatioApiArg,
  GetTireSpeedRatioApiArg,
  GetTireSpeedRatioApiResponse,
  PostTireCategorySetActiveApiResponse,
  PostTireCategorySetInactiveApiResponse,
  PostTireSpeedRatioSetActiveApiResponse,
  PostTireSpeedRatioSetActiveApiArg,
  PostTireSpeedRatioSetInactiveApiResponse,
  PostTireSpeedRatioSetInactiveApiArg,
  GetTireWidthsApiArg,
  GetTireWidthsApiResponse,
  PostTireWidthApiResponse,
  PostTireWidthApiArg,
  GetTireWidthApiArg,
  GetTireWidthApiResponse,
  DeleteTireWidthApiArg,
  DeleteTireWidthApiResponse,
  PostTireWidthSetActiveApiResponse,
  PostTireWidthSetActiveApiArg,
  PostTireWidthSetInactiveApiArg,
  PostTireWidthSetInactiveApiResponse,
  PatchTireWidthApiResponse,
  PatchTireWidthApiArg,
  PatchTireSpeedRatioApiArg,
  DeleteTireSpeedRatioApiResponse,
  DeleteTireSpeedRatioApiArg,
  PatchTireSpeedRatioApiResponse,
  PatchTireLoadIndexApiArg,
  PatchTireLoadIndexApiResponse,
  GetTireCategoriesApiArg,
  GetTireCategoriesApiResponse,
  PostTireSetsSetUnreadyApiResponse,
  PostTireSetsSetReadyApiArg,
  PostTireSetsSetReadyApiResponse,
  PostTireSetsSetUnreadyApiArg,
  PostTireSetsCancelRequestApiArg,
  PostTireSetsCancelRequestApiResponse,
  PostTireSetsRequestApiArg,
  PostTireSetsRequestApiResponse,
} from './types';

export const metadaTiresApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postTireOrder: build.mutation<PostTireOrderApiResponse, PostTireOrderApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'tireIds', id: `${queryArg.body.customerId}-${queryArg.body.vehicleId}`},
      ],
    }),
    getTireIds: build.query<GetTireIdsOrdersApiApiResponse, GetTireIdsOrdersApiApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order`,
        params: {
          customerId: queryArg.customerId,
          vehicleId: queryArg.vehicleId,
        },
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'tireIds', id: `${queryArg.customerId}-${queryArg.vehicleId}`},
      ],
    }),
    getTireOrder: build.query<GetTireOrderApiResponse, GetTireOrderApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}`,
      }),
      providesTags: (result, error, queryArg) => [{type: 'tireOrder', id: queryArg.orderId}],
    }),
    getTireOptions: build.query<Option[], GetTireOptionsApiArg>({
      query: (queryArg) => ({
        url: `/core/v1/codelist`,
        params: {category: queryArg.category},
      }),
      transformResponse: (response: GetTireOptionsApiResponse) =>
        map((item) => ({label: item?.name ?? '', value: item?.code ?? ''}), response ?? []),
    }),
    getTireLocations: build.query<GetCodelistTireLocationApiResponse, void>({
      query: () => ({
        url: `/core/v1/codelist/tire-location`,
      }),
      providesTags: ['tireLocations'],
    }),
    getTireManufacturer: build.query<GetTireManufacturerApiResponse, unknown>({
      query: () => ({
        url: `/core/v1/codelist/tire-manufacturer`,
      }),
      providesTags: ['tireManufacturers'],
    }),
    getEmployeeMechanicsByBranch: build.query<Option[], GetEmployeeMechanicsByBranchApiArg>({
      query: (queryArg) => ({
        url: `/core/v1/employee/mechanics-by-branch`,
        params: {branchId: queryArg.branchId},
      }),
      transformResponse: (response: GetEmployeeMechanicsByBranchApiResponse) =>
        map(
          (item) => ({label: item?.name ?? '', value: item?.id ?? ''}),
          response?.employees ?? []
        ),
    }),
    postTireSet: build.mutation<PostTireSetApiResponse, PostTireSetApiArg>({
      query: (queryArg) => ({
        url: `/tires/v3/tire-order/${queryArg.orderId}/tire-set`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'tireOrder', id: queryArg.orderId}],
    }),
    postTiresArchive: build.mutation<PostTiresArchiveApiResponse, PostTiresArchiveApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}/tire-set/${queryArg.setId}/archive`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'tireSet', id: queryArg.setId},
        {type: 'tireOrder', id: queryArg.orderId},
        {type: 'tireSetLocation', id: queryArg.setId},
      ],
    }),
    postTireSetDeliveryNote: build.mutation<
      PostTireSetDeliveryNoteApiResponse,
      PostTireSetDeliveryNoteApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}/tire-set/${queryArg.setId}/delivery-note`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'tireSet', id: queryArg.setId}],
    }),
    postTireSetLabel: build.mutation<PostTireSetLabelApiResponse, PostTireSetLabelApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}/tire-set/${queryArg.setId}/label`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'tireSet', id: queryArg.setId}],
    }),
    postTireSetsSetReady: build.mutation<
      PostTireSetsSetReadyApiResponse,
      PostTireSetsSetReadyApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/tire-sets/set-ready`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        ...queryArg.body.tireSets.map((tireSet) => ({
          type: 'tireSet' as const,
          id: tireSet.tireSetId,
        })),
        ...queryArg.body.tireSets.map((tireSet) => ({
          type: 'tireOrder' as const,
          id: tireSet.tireOrderId,
        })),
      ],
    }),
    postTireSetsSetUnready: build.mutation<
      PostTireSetsSetUnreadyApiResponse,
      PostTireSetsSetUnreadyApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/tire-sets/set-unready`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        ...queryArg.body.tireSets.map((tireSet) => ({
          type: 'tireSet' as const,
          id: tireSet.tireSetId,
        })),
        ...queryArg.body.tireSets.map((tireSet) => ({
          type: 'tireOrder' as const,
          id: tireSet.tireOrderId,
        })),
      ],
    }),
    postTiresTransferToOrder: build.mutation<
      PostTiresTransferToOrderApiResponse,
      PostTiresTransferToOrderApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/tire-sets/transfer-to-order/${queryArg.targetOrderId}`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        ...queryArg.body.tireSets.map((tireSet) => ({
          type: 'tireSet' as const,
          id: tireSet.tireSetId,
        })),
        ...queryArg.body.tireSets.map((tireSet) => ({
          type: 'tireOrder' as const,
          id: tireSet.tireOrderId,
        })),
        {type: 'tireOrder', id: queryArg.targetOrderId},
      ],
    }),
    postTireSetsCancelRequest: build.mutation<
      PostTireSetsCancelRequestApiResponse,
      PostTireSetsCancelRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/tire-sets/cancel-request`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        ...queryArg.body.tireSets.map((tireSet) => ({
          type: 'tireSet' as const,
          id: tireSet.setId,
        })),
        ...queryArg.body.tireSets.map((tireSet) => ({
          type: 'tireOrder' as const,
          id: tireSet.orderId,
        })),
      ],
    }),
    postTireSetsRequest: build.mutation<PostTireSetsRequestApiResponse, PostTireSetsRequestApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-sets/request`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        ...queryArg.body.tireSets.map((tireSet) => ({
          type: 'tireSet' as const,
          id: tireSet.setId,
        })),
        ...queryArg.body.tireSets.map((tireSet) => ({
          type: 'tireOrder' as const,
          id: tireSet.orderId,
        })),
      ],
    }),
    getTireSet: build.query<GetTireSetApiResponse, GetTireSetApiArg>({
      query: (queryArg) => ({
        url: `/tires/v3/tire-order/${queryArg.orderId}/tire-set/${queryArg.setId}`,
      }),
      providesTags: (result, error, queryArg) => [{type: 'tireSet', id: queryArg.setId}],
    }),
    patchTireSet: build.mutation<PatchTireSetApiResponse, PatchTireSetApiArg>({
      query: (queryArg) => ({
        url: `/tires/v3/tire-order/${queryArg.orderId}/tire-set/${queryArg.setId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'tireSet', id: queryArg.setId},
        {type: 'tireOrder', id: queryArg.orderId},
      ],
    }),
    postTireSetAddWheels: build.mutation<
      PostTireSetAddWheelsApiResponse,
      PostTireSetAddWheelsApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}/tire-set/${queryArg.setId}/add-wheels`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'tireSet', id: queryArg.setId},
        {type: 'tireOrder', id: queryArg.orderId},
      ],
    }),
    postTiresReceipt: build.mutation<PostTiresReceiptApiResponse, PostTiresReceiptApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}/tire-set/${queryArg.setId}/receipt`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'tireSet', id: queryArg.setId}],
    }),
    postTiresIssue: build.mutation<PostTiresIssueApiResponse, PostTiresIssueApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}/tire-set/${queryArg.setId}/issue`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'tireSet', id: queryArg.setId},
        {type: 'tireSetLocation', id: queryArg.setId},
      ],
    }),
    postTiresRestore: build.mutation<PostTiresRestoreApiResponse, PostTiresRestoreApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}/tire-set/${queryArg.setId}/restore`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'tireSet', id: queryArg.setId}],
    }),
    getTireSetLocation: build.query<GetTireSetLocationResponse, GetTireSetLocationArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}/tire-set/${queryArg.setId}/location`,
      }),
      providesTags: (result, error, queryArg) => [{type: 'tireSetLocation', id: queryArg.setId}],
    }),
    updateTireSetLocation: build.mutation<UpdateTireSetLocationResponse, UpdateTireSetLocationArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}/tire-set/${queryArg.setId}/location`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'tireSetLocation', id: queryArg.setId}],
    }),
    getTiresGeneralSettings: build.query<
      GetTiresGeneralSettingsApiResponse,
      GetTiresGeneralSettingsApiArg
    >({
      query: () => ({
        url: `/tires/v1/general-settings`,
      }),
      providesTags: ['tiresInventoryGeneralSettings'],
    }),
    putTiresGeneralSettings: build.mutation<
      PutTiresGeneralSettingsApiResponse,
      PutTiresGeneralSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/general-settings`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: ['tiresInventoryGeneralSettings', 'tiresInventoryDefaultStoreUntil'],
    }),
    postTireManufacturer: build.mutation<
      PostTireManufacturerApiResponse,
      PostTireManufacturerApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/codelist/tire-manufacturer`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['tireManufacturers'],
    }),
    patchTireManufacturer: build.mutation<
      PatchTireManufacturerApiResponse,
      PatchTireManufacturerApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/codelist/tire-manufacturer/${queryArg.codelistId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: ['tireManufacturers'],
    }),
    patchTireManufacturerSetActive: build.mutation<
      PatchTireManufacturerSetActiveApiResponse,
      PatchTireManufacturerSetActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/codelist/tire-manufacturer/${queryArg.codelistId}/set-active`,
        method: 'PATCH',
      }),
      invalidatesTags: ['tireManufacturers'],
    }),
    patchTireManufacturerSetDefault: build.mutation<
      PatchTireManufacturerSetDefaultApiResponse,
      PatchTireManufacturerSetDefaultApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/codelist/tire-manufacturer/${queryArg.codelistId}/set-default`,
        method: 'PATCH',
      }),
      invalidatesTags: ['tireManufacturers'],
    }),
    patchTireManufacturerSetInactive: build.mutation<
      PatchTireManufacturerSetInactiveApiResponse,
      PatchTireManufacturerSetInactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/codelist/tire-manufacturer/${queryArg.codelistId}/set-inactive`,
        method: 'PATCH',
      }),
      invalidatesTags: ['tireManufacturers'],
    }),
    postTireLocation: build.mutation<PostTireLocationApiResponse, PostTireLocationApiArg>({
      query: (queryArg) => ({
        url: `/core/v1/codelist/tire-location`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['tireLocations'],
    }),
    patchTireLocation: build.mutation<PatchTireLocationApiResponse, PatchTireLocationApiArg>({
      query: (queryArg) => ({
        url: `/core/v1/codelist/tire-location/${queryArg.codelistId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: ['tireLocations'],
    }),
    patchTireLocationPositionDown: build.mutation<
      PatchTireLocationPositionDownApiResponse,
      PatchTireLocationPositionDownApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/codelist/tire-location/${queryArg.codelistId}/position-down`,
        method: 'PATCH',
      }),
      invalidatesTags: ['tireLocations'],
    }),
    patchTireLocationPositionUp: build.mutation<
      PatchTireLocationPositionUpApiResponse,
      PatchTireLocationPositionUpApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/codelist/tire-location/${queryArg.codelistId}/position-up`,
        method: 'PATCH',
      }),
      invalidatesTags: ['tireLocations'],
    }),
    patchTireLocationSetActive: build.mutation<
      PatchTireLocationSetActiveApiResponse,
      PatchTireLocationSetActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/codelist/tire-location/${queryArg.codelistId}/set-active`,
        method: 'PATCH',
      }),
      invalidatesTags: ['tireLocations'],
    }),
    patchTireLocationSetInactive: build.mutation<
      PatchTireLocationSetInactiveApiResponse,
      PatchTireLocationSetInactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/codelist/tire-location/${queryArg.codelistId}/set-inactive`,
        method: 'PATCH',
      }),
      invalidatesTags: ['tireLocations'],
    }),
    getTireVehicleCount: build.query<GetTireVehicleCountApiResponse, GetTireVehicleCountApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-wheel-count`,
        params: {vehicleId: queryArg.vehicleId, customerId: queryArg.customerId},
      }),
    }),
    getDefaultStoreUntilByOrderId: build.query<
      GetDefaultStoreUntilByOrderIdApiResponse,
      GetDefaultStoreUntilByOrderIdApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}/default-store-until`,
      }),
      providesTags: ['tiresInventoryDefaultStoreUntil'],
    }),
    getOrderSetDefaultContactPerson: build.query<
      GetOrderSetDefaultContactPersonApiResponse,
      GetOrderSetDefaultContactPersonApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}/default-contact-person`,
      }),
    }),
    getAllTireWarehouses: build.query<GetTireWarehousesApiResponse, GetTireWarehousesApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/warehouse`,
        params: queryArg?.branchId ? {branchId: queryArg.branchId} : undefined,
      }),
      providesTags: ['tireWarehousesAll'],
    }),
    postTireWarehouse: build.mutation<PostTireWarehouseApiResponse, PostTireWarehouseApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/warehouse`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['tireWarehousesAll'],
    }),
    getTireWarehouseSettings: build.query<
      GetTireWarehouseSettingsApiResponse,
      GetTireWarehouseSettingsApiArg
    >({
      query: () => ({
        url: `/tires/v1/warehouse-setting`,
      }),
      providesTags: ['tireWarehouseSettings'],
    }),
    postWarehouseEnablingSetAsAllow: build.mutation<
      PostWarehouseEnablingSetAsAllowApiResponse,
      PostWarehouseEnablingSetAsAllowApiArg
    >({
      query: () => ({
        url: `/tires/v1/warehouse-setting/allow-storage-location`,
        method: 'POST',
      }),
      invalidatesTags: ['tireWarehouseSettings'],
    }),
    postWarehouseEnablingSetAsDisallow: build.mutation<
      PostWarehouseEnablingSetAsDisallowApiResponse,
      PostWarehouseEnablingSetAsDisallowApiArg
    >({
      query: () => ({
        url: `/tires/v1/warehouse-setting/disallow-storage-location`,
        method: 'POST',
      }),
      invalidatesTags: ['tireWarehouseSettings'],
    }),
    getTireWarehouse: build.query<GetTireWarehouseApiResponse, GetTireWarehouseApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/warehouse/${queryArg.warehouseId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'tireWarehouse', id: queryArg.warehouseId},
      ],
    }),
    deleteTireWarehouse: build.mutation<DeleteTireWarehouseApiResponse, DeleteTireWarehouseApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/warehouse/${queryArg.warehouseId}`,
        method: 'DELETE',
      }),
    }),
    patchTireWarehouse: build.mutation<PatchTireWarehouseApiResponse, PatchTireWarehouseApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/warehouse/${queryArg.warehouseId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'tireWarehouse', id: queryArg.warehouseId},
      ],
    }),
    getTireExternalWarehouse: build.query<
      GetTireExternalWarehouseApiResponse,
      GetTireExternalWarehouseApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/warehouse/${queryArg.warehouseId}/external-warehouse`,
      }),
    }),
    postGeneralSetting: build.mutation<PostGeneralSettingApiResponse, PostGeneralSettingApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/general-setting`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getGeneralSetting: build.query<GetGeneralSettingApiResponse, GetGeneralSettingApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/general-setting/${queryArg.generalSettingId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'generalSettings', id: queryArg.generalSettingId},
      ],
    }),
    deleteGeneralSetting: build.mutation<
      DeleteGeneralSettingApiResponse,
      DeleteGeneralSettingApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/general-setting/${queryArg.generalSettingId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'generalSettings', id: queryArg.generalSettingId},
      ],
    }),
    patchGeneralSetting: build.mutation<PatchGeneralSettingApiResponse, PatchGeneralSettingApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/general-setting/${queryArg.generalSettingId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'generalSettings', id: queryArg.generalSettingId},
      ],
    }),
    postTireSetRequest: build.mutation<PostTireSetRequestApiResponse, PostTireSetRequestApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}/tire-set/${queryArg.setId}/request`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'tireSet', id: queryArg.setId}],
    }),
    patchTireSetRequest: build.mutation<PatchTireSetRequestApiResponse, PatchTireSetRequestApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}/tire-set/${queryArg.setId}/request`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'tireSet', id: queryArg.setId}],
    }),
    getTireSetRequest: build.query<GetTireSetRequestApiResponse, GetTireSetRequestApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}/tire-set/${queryArg.setId}/request`,
      }),
      providesTags: (result, error, queryArg) => [{type: 'tireSet', id: queryArg.setId}],
    }),
    postTireSetCancelRequest: build.mutation<
      PostTireSetCancelRequestApiResponse,
      PostTireSetCancelRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}/tire-set/${queryArg.setId}/cancel-request`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'tireSet', id: queryArg.setId}],
    }),
    getTireSetActions: build.query<GetTireSetActionsApiResponse, GetTireSetActionsApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-order/${queryArg.orderId}/tire-set/${queryArg.setId}/actions`,
      }),
      providesTags: (result, error, queryArg) => [{type: 'tireSet', id: queryArg.setId}],
    }),
    getTireAspectRatios: build.query<GetTireAspectRatiosApiResponse, GetTireAspectRatiosApiArg>({
      query: () => ({
        url: `/tires/v1/tire-aspect-ratio`,
      }),
      providesTags: ['tireAspectRatios'],
    }),
    postTireAspectRatio: build.mutation<PostTireAspectRatioApiResponse, PostTireAspectRatioApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-aspect-ratio`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['tireAspectRatios'],
    }),
    getTireAspectRatio: build.query<GetTireAspectRatioApiResponse, GetTireAspectRatioApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-aspect-ratio/${queryArg.tireAspectRatioId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'tireAspectRatio', id: queryArg.tireAspectRatioId},
      ],
    }),
    deleteTireAspectRatio: build.mutation<
      DeleteTireAspectRatioApiResponse,
      DeleteTireAspectRatioApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/tire-aspect-ratio/${queryArg.tireAspectRatioId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'tireAspectRatios',
        {type: 'tireAspectRatio', id: queryArg.tireAspectRatioId},
      ],
    }),
    patchTireAspectRatio: build.mutation<
      PatchTireAspectRatioApiResponse,
      PatchTireAspectRatioApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/tire-aspect-ratio/${queryArg.tireAspectRatioId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'tireAspectRatios',
        {type: 'tireAspectRatio', id: queryArg.tireAspectRatioId},
      ],
    }),
    postTireAspectRatioSetActive: build.mutation<
      PostTireAspectRatioSetActiveApiResponse,
      PostTireAspectRatioSetActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/tire-aspect-ratio/${queryArg.tireAspectRatioId}/set-active`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'tireAspectRatios',
        {type: 'tireAspectRatio', id: queryArg.tireAspectRatioId},
      ],
    }),
    postTireAspectRatioSetInactive: build.mutation<
      PostTireAspectRatioSetInactiveApiResponse,
      PostTireAspectRatioSetInactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/tire-aspect-ratio/${queryArg.tireAspectRatioId}/set-inactive`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'tireAspectRatios',
        {type: 'tireAspectRatio', id: queryArg.tireAspectRatioId},
      ],
    }),
    getTireCategories: build.query<GetTireCategoriesApiResponse, GetTireCategoriesApiArg>({
      query: () => ({
        url: `/tires/v1/tire-category`,
      }),
      providesTags: ['tireCategories'],
    }),
    postTireCategory: build.mutation<PostTireCategoryApiResponse, PostTireCategoryApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-category`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['tireCategories'],
    }),
    getTireCategory: build.query<GetTireCategoryApiResponse, GetTireCategoryApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-category/${queryArg.tireCategoryId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'tireCategory', id: queryArg.tireCategoryId},
      ],
    }),
    deleteTireCategory: build.mutation<DeleteTireCategoryApiResponse, DeleteTireCategoryApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-category/${queryArg.tireCategoryId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'tireCategories',
        {type: 'tireCategory', id: queryArg.tireCategoryId},
      ],
    }),
    patchTireCategory: build.mutation<PatchTireCategoryApiResponse, PatchTireCategoryApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-category/${queryArg.tireCategoryId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'tireCategories',
        {type: 'tireCategory', id: queryArg.tireCategoryId},
      ],
    }),
    postTireCategorySetActive: build.mutation<
      PostTireCategorySetActiveApiResponse,
      PostTireCategorySetActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/tire-category/${queryArg.tireCategoryId}/set-active`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'tireCategories',
        {type: 'tireCategory', id: queryArg.tireCategoryId},
      ],
    }),
    postTireCategorySetDefault: build.mutation<
      PostTireCategorySetDefaultApiResponse,
      PostTireCategorySetDefaultApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/tire-category/${queryArg.tireCategoryId}/set-default`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'tireCategories',
        {type: 'tireCategory', id: queryArg.tireCategoryId},
      ],
    }),
    postTireCategorySetInactive: build.mutation<
      PostTireCategorySetInactiveApiResponse,
      PostTireCategorySetInactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/tire-category/${queryArg.tireCategoryId}/set-inactive`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'tireCategories',
        {type: 'tireCategory', id: queryArg.tireCategoryId},
      ],
    }),
    getTireLoadIndexes: build.query<GetTireLoadIndexesApiResponse, GetTireLoadIndexesApiArg>({
      query: () => ({
        url: `/tires/v1/tire-load-index`,
      }),
      providesTags: ['tireLoadIndexes'],
    }),
    postTireLoadIndex: build.mutation<PostTireLoadIndexApiResponse, PostTireLoadIndexApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-load-index`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['tireLoadIndexes'],
    }),
    getTireLoadIndex: build.query<GetTireLoadIndexApiResponse, GetTireLoadIndexApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-load-index/${queryArg.tireLoadIndexId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'tireLoadIndex', id: queryArg.tireLoadIndexId},
      ],
    }),
    deleteTireLoadIndex: build.mutation<DeleteTireLoadIndexApiResponse, DeleteTireLoadIndexApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-load-index/${queryArg.tireLoadIndexId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'tireLoadIndexes',
        {type: 'tireLoadIndex', id: queryArg.tireLoadIndexId},
      ],
    }),
    patchTireLoadIndex: build.mutation<PatchTireLoadIndexApiResponse, PatchTireLoadIndexApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-load-index/${queryArg.tireLoadIndexId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'tireLoadIndexes',
        {type: 'tireLoadIndex', id: queryArg.tireLoadIndexId},
      ],
    }),
    postTireLoadIndexSetActive: build.mutation<
      PostTireLoadIndexSetActiveApiResponse,
      PostTireLoadIndexSetActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/tire-load-index/${queryArg.tireLoadIndexId}/set-active`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'tireLoadIndexes',
        {type: 'tireLoadIndex', id: queryArg.tireLoadIndexId},
      ],
    }),
    postTireLoadIndexSetInactive: build.mutation<
      PostTireLoadIndexSetInactiveApiResponse,
      PostTireLoadIndexSetInactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/tire-load-index/${queryArg.tireLoadIndexId}/set-inactive`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'tireLoadIndexes',
        {type: 'tireLoadIndex', id: queryArg.tireLoadIndexId},
      ],
    }),
    getTireRims: build.query<GetTireRimsApiResponse, GetTireRimsApiArg>({
      query: () => ({
        url: `/tires/v1/tire-rim`,
      }),
      providesTags: ['tireRims'],
    }),
    postTireRim: build.mutation<PostTireRimApiResponse, PostTireRimApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-rim`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['tireRims'],
    }),
    getTireRim: build.query<GetTireRimApiResponse, GetTireRimApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-rim/${queryArg.tireRimId}`,
      }),
      providesTags: (result, error, queryArg) => [{type: 'tireRim', id: queryArg.tireRimId}],
    }),
    deleteTireRim: build.mutation<DeleteTireRimApiResponse, DeleteTireRimApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-rim/${queryArg.tireRimId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'tireRims',
        {type: 'tireRim', id: queryArg.tireRimId},
      ],
    }),
    patchTireRim: build.mutation<PatchTireRimApiResponse, PatchTireRimApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-rim/${queryArg.tireRimId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'tireRims',
        {type: 'tireRim', id: queryArg.tireRimId},
      ],
    }),
    postTireRimSetActive: build.mutation<
      PostTireRimSetActiveApiResponse,
      PostTireRimSetActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/tire-rim/${queryArg.tireRimId}/set-active`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'tireRims',
        {type: 'tireRim', id: queryArg.tireRimId},
      ],
    }),
    postTireRimSetInactive: build.mutation<
      PostTireRimSetInactiveApiResponse,
      PostTireRimSetInactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/tire-rim/${queryArg.tireRimId}/set-inactive`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'tireRims',
        {type: 'tireRim', id: queryArg.tireRimId},
      ],
    }),
    getTireSpeedRatios: build.query<GetTireSpeedRatiosApiResponse, GetTireSpeedRatiosApiArg>({
      query: () => ({
        url: `/tires/v1/tire-speed-ratio`,
      }),
      providesTags: ['tireSpeedRatios'],
    }),
    postTireSpeedRatio: build.mutation<PostTireSpeedRatioApiResponse, PostTireSpeedRatioApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-speed-ratio`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['tireSpeedRatios'],
    }),
    getTireSpeedRatio: build.query<GetTireSpeedRatioApiResponse, GetTireSpeedRatioApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-speed-ratio/${queryArg.tireSpeedRatioId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'tireSpeedRatio', id: queryArg.tireSpeedRatioId},
      ],
    }),
    patchTireSpeedRatio: build.mutation<PatchTireSpeedRatioApiResponse, PatchTireSpeedRatioApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-speed-ratio/${queryArg.tireSpeedRatioId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'tireSpeedRatios',
        {type: 'tireSpeedRatio', id: queryArg.tireSpeedRatioId},
      ],
    }),
    deleteTireSpeedRatio: build.mutation<
      DeleteTireSpeedRatioApiResponse,
      DeleteTireSpeedRatioApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/tire-speed-ratio/${queryArg.tireSpeedRatioId}/delete`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'tireSpeedRatios',
        {type: 'tireSpeedRatio', id: queryArg.tireSpeedRatioId},
      ],
    }),
    postTireSpeedRatioSetActive: build.mutation<
      PostTireSpeedRatioSetActiveApiResponse,
      PostTireSpeedRatioSetActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/tire-speed-ratio/${queryArg.tireSpeedRatioId}/set-active`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'tireSpeedRatios',
        {type: 'tireSpeedRatio', id: queryArg.tireSpeedRatioId},
      ],
    }),
    postTireSpeedRatioSetInactive: build.mutation<
      PostTireSpeedRatioSetInactiveApiResponse,
      PostTireSpeedRatioSetInactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/tire-speed-ratio/${queryArg.tireSpeedRatioId}/set-inactive`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'tireSpeedRatios',
        {type: 'tireSpeedRatio', id: queryArg.tireSpeedRatioId},
      ],
    }),
    getTireWidths: build.query<GetTireWidthsApiResponse, GetTireWidthsApiArg>({
      query: () => ({
        url: `/tires/v1/tire-width`,
      }),
      providesTags: ['tireWidths'],
    }),
    postTireWidth: build.mutation<PostTireWidthApiResponse, PostTireWidthApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-width`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['tireWidths'],
    }),
    getTireWidth: build.query<GetTireWidthApiResponse, GetTireWidthApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-width/${queryArg.tireWidthId}`,
      }),
      providesTags: (result, error, queryArg) => [{type: 'tireWidth', id: queryArg.tireWidthId}],
    }),
    deleteTireWidth: build.mutation<DeleteTireWidthApiResponse, DeleteTireWidthApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-width/${queryArg.tireWidthId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'tireWidths',
        {type: 'tireWidth', id: queryArg.tireWidthId},
      ],
    }),
    patchTireWidth: build.mutation<PatchTireWidthApiResponse, PatchTireWidthApiArg>({
      query: (queryArg) => ({
        url: `/tires/v1/tire-width/${queryArg.tireWidthId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'tireWidths',
        {type: 'tireWidth', id: queryArg.tireWidthId},
      ],
    }),
    postTireWidthSetActive: build.mutation<
      PostTireWidthSetActiveApiResponse,
      PostTireWidthSetActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/tire-width/${queryArg.tireWidthId}/set-active`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'tireWidths',
        {type: 'tireWidth', id: queryArg.tireWidthId},
      ],
    }),
    postTireWidthSetInactive: build.mutation<
      PostTireWidthSetInactiveApiResponse,
      PostTireWidthSetInactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/tires/v1/tire-width/${queryArg.tireWidthId}/set-inactive`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'tireWidths',
        {type: 'tireWidth', id: queryArg.tireWidthId},
      ],
    }),
  }),
});

export const {
  usePostTireOrderMutation,
  useGetTireOrderQuery,
  useGetTireOptionsQuery,
  useGetTireLocationsQuery,
  useGetTireManufacturerQuery,
  useGetEmployeeMechanicsByBranchQuery,
  usePostTireSetMutation,
  usePostTiresArchiveMutation,
  usePostTireSetDeliveryNoteMutation,
  usePostTireSetLabelMutation,
  usePostTiresTransferToOrderMutation,
  useGetTireSetQuery,
  useLazyGetTireSetQuery,
  usePatchTireSetMutation,
  usePostTireSetAddWheelsMutation,
  usePostTireSetsSetReadyMutation,
  usePostTireSetsSetUnreadyMutation,
  usePostTiresReceiptMutation,
  usePostTiresIssueMutation,
  usePostTiresRestoreMutation,
  useGetTiresGeneralSettingsQuery,
  usePutTiresGeneralSettingsMutation,
  usePostTireManufacturerMutation,
  usePatchTireManufacturerMutation,
  usePatchTireManufacturerSetActiveMutation,
  usePatchTireManufacturerSetDefaultMutation,
  usePatchTireManufacturerSetInactiveMutation,
  usePostTireLocationMutation,
  usePatchTireLocationMutation,
  usePatchTireLocationPositionDownMutation,
  usePatchTireLocationPositionUpMutation,
  usePatchTireLocationSetActiveMutation,
  usePatchTireLocationSetInactiveMutation,
  useGetTireVehicleCountQuery,
  useGetOrderSetDefaultContactPersonQuery,
  useGetTireIdsQuery,
  useGetAllTireWarehousesQuery,
  usePostTireWarehouseMutation,
  usePostWarehouseEnablingSetAsAllowMutation,
  usePostWarehouseEnablingSetAsDisallowMutation,
  useGetTireWarehouseQuery,
  useDeleteTireWarehouseMutation,
  usePatchTireWarehouseMutation,
  useGetTireExternalWarehouseQuery,
  useGetTireWarehouseSettingsQuery,
  useGetDefaultStoreUntilByOrderIdQuery,
  usePostGeneralSettingMutation,
  useGetGeneralSettingQuery,
  useDeleteGeneralSettingMutation,
  usePatchGeneralSettingMutation,
  useGetTireSetLocationQuery,
  useUpdateTireSetLocationMutation,
  usePostTireSetRequestMutation,
  usePostTireSetCancelRequestMutation,
  usePostTireSetsRequestMutation,
  usePostTireSetsCancelRequestMutation,
  useGetTireSetActionsQuery,
  usePatchTireSetRequestMutation,
  useGetTireSetRequestQuery,
  useGetTireAspectRatiosQuery,
  usePostTireAspectRatioMutation,
  useGetTireAspectRatioQuery,
  useDeleteTireAspectRatioMutation,
  usePatchTireAspectRatioMutation,
  usePostTireAspectRatioSetActiveMutation,
  usePostTireAspectRatioSetInactiveMutation,
  usePostTireCategoryMutation,
  useGetTireCategoryQuery,
  useDeleteTireCategoryMutation,
  usePatchTireCategoryMutation,
  usePostTireCategorySetActiveMutation,
  usePostTireCategorySetDefaultMutation,
  usePostTireCategorySetInactiveMutation,
  useGetTireLoadIndexesQuery,
  usePostTireLoadIndexMutation,
  useGetTireLoadIndexQuery,
  useDeleteTireLoadIndexMutation,
  usePatchTireLoadIndexMutation,
  usePostTireLoadIndexSetActiveMutation,
  usePostTireLoadIndexSetInactiveMutation,
  useGetTireRimsQuery,
  usePostTireRimMutation,
  useGetTireRimQuery,
  useDeleteTireRimMutation,
  usePatchTireRimMutation,
  usePostTireRimSetActiveMutation,
  usePostTireRimSetInactiveMutation,
  useGetTireSpeedRatiosQuery,
  usePostTireSpeedRatioMutation,
  useGetTireSpeedRatioQuery,
  usePatchTireSpeedRatioMutation,
  useDeleteTireSpeedRatioMutation,
  usePostTireSpeedRatioSetActiveMutation,
  usePostTireSpeedRatioSetInactiveMutation,
  useGetTireWidthsQuery,
  usePostTireWidthMutation,
  useGetTireWidthQuery,
  useDeleteTireWidthMutation,
  usePatchTireWidthMutation,
  usePostTireWidthSetActiveMutation,
  usePostTireWidthSetInactiveMutation,
  useGetTireCategoriesQuery,
} = metadaTiresApi;
