import {match} from 'ts-pattern';

import {isNotNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {GetDataByDataQueryApiResponse} from '@dms/api/datagrid';
import {EMPTY_PLACEHOLDER} from '@dms/shared';

import {Card} from '../types/Card';
import {getColumnForState} from './getColumnForState';

export function mapInterestsToCards(interests: GetDataByDataQueryApiResponse): Card[] {
  return interests.rows
    .filter((row) => isNotNilOrEmpty(row.cells))
    .map((row) => ({
      id: row.id,
      column: row.cells.state?.value ? getColumnForState(row.cells.state.value.key) : null,
      title: match(row.cells.vehicle?.value)
        .when(
          (value: string[]) => Array.isArray(value) && value.length > 0,
          (value) => value.join(', ')
        )
        .when(
          (value: string | string[]) => typeof value === 'string',
          (value) => value
        )
        .otherwise(() => EMPTY_PLACEHOLDER),
      subTitle: row.cells.customer?.value?.label || EMPTY_PLACEHOLDER,
      flags:
        row.cells.tag?.value?.map((tag: Record<string, string>) => ({
          label: tag.label,
          colorScheme: tag.color,
        })) || null,
      hasChat: false,
      hasComments: isNotNil(row.cells.comment?.value),
      price: null,
      date: row.cells.updatedAt?.value || row.cells.createdAt?.value || null,
      ownerId: row.cells.owner?.value?.id || null,
      origin: 'interest',
    }));
}
