import {Form, showNotification} from 'platform/components';
import {Show} from 'platform/foundation';
import {array, object} from 'yup';

import {isNotEmpty, isNotNil, omit} from 'ramda';

import {useCreateServiceVehicleMutation} from '@dms/api/metadaServiceVehicle';
import {GetVehicleApiResponseV2, VehicleTypeEnumObject} from '@dms/api/shared';
import {useLazySourcingGetCarFeaturesQuery} from '@dms/api/sourcing';
import {
  useCreateVehicleV2Mutation,
  useDeleteVehicleMutation,
  useSetAdditionalNoteMutation,
  useUpdateVehicleV2Mutation,
} from '@dms/api/vehicle';
import i18n from '@dms/i18n';

import {ApiError, parseDate, RequiredTestIdProps, suffixTestId, yupString} from 'shared';

import {VehicleRecommendationType} from '../../../types/VehicleRecommendationType';
import {handleApiError} from '../../../utils/handleApiError';
import {serviceIntervalsDefault} from '../constants/serviceIntervalsDefault';
import {ServiceVehicleFormMode} from '../types/ServiceVehicleFormMode';
import {ServiceVehicleFormType} from '../types/ServiceVehicleFormType';
import {ServiceVehicleFormVariant} from '../types/ServiceVehicleFormVariant';
import {convertFormVehicleToVehicleV2} from '../utils/convertFormVehicleToVehicleV2';
import {ExtendedView} from './ExtendedView';
import {SimpleView} from './SimpleView';

interface DefaultProps extends RequiredTestIdProps {
  variant: ServiceVehicleFormVariant;
  extendedDefaultFormData?: Partial<ServiceVehicleFormType>;
  shouldRecommendVehiclesFor?: VehicleRecommendationType;
  onSuccess: (newVehicleId: string) => void;
  onDiscard: () => void;
}

type ServiceVehicleFormLogicProps = DefaultProps &
  (
    | {
        mode: ServiceVehicleFormMode.NEW;
      }
    | {
        mode:
          | ServiceVehicleFormMode.NEW_FROM_SALE
          | ServiceVehicleFormMode.EDIT
          | ServiceVehicleFormMode.CLONE;
        vehicleData: GetVehicleApiResponseV2;
        defaultFormData: Partial<ServiceVehicleFormType>;
      }
  );

export function ServiceVehicleFormLogic(props: ServiceVehicleFormLogicProps) {
  const [createVehicle, createVehicleStatus] = useCreateVehicleV2Mutation();
  const [createServiceVehicle, createServiceVehicleStatus] = useCreateServiceVehicleMutation();
  const [deleteVehicle] = useDeleteVehicleMutation();
  const [setAdditionalNote] = useSetAdditionalNoteMutation();

  const [updateVehicle, updateVehicleStatus] = useUpdateVehicleV2Mutation();

  const [getCarFeaturesQuery] = useLazySourcingGetCarFeaturesQuery();

  const isVehicleCreating = createVehicleStatus.isLoading || createServiceVehicleStatus.isLoading;
  const isVehicleUpdating = updateVehicleStatus.isLoading;

  const getFormDefaultValues = (): Partial<ServiceVehicleFormType> => {
    if (
      props.mode === ServiceVehicleFormMode.EDIT ||
      props.mode === ServiceVehicleFormMode.NEW_FROM_SALE
    ) {
      return props.defaultFormData;
    }

    if (props.mode === ServiceVehicleFormMode.CLONE) {
      return omit(['registrationPlate', 'vin'], props.defaultFormData);
    }

    return {
      type: [VehicleTypeEnumObject.VEHICLETYPE_PASSENGER_CAR],
      features: [],
      serviceIntervals: serviceIntervalsDefault,
    };
  };

  const getCarFeatures = (formData: ServiceVehicleFormType) => {
    if (!formData.make || !formData.modelFamily || !formData.firstRegistrationOn) {
      return Promise.reject(new Error('Missing required fields'));
    }

    return getCarFeaturesQuery(
      {
        body: {
          make: formData.make,
          model: formData.modelFamily,
          year: parseDate(formData.firstRegistrationOn).getFullYear(),
          features: formData.features || [],
        },
      },
      true
    ).unwrap();
  };

  const handleVehicleCreation = async (formData: ServiceVehicleFormType) => {
    const staticCarFeatures = await getCarFeatures(formData).catch(() => undefined);

    const saneVehicle = convertFormVehicleToVehicleV2({
      formVehicle: formData,
      givenCarFeatures: [],
      staticCarFeatures,
    });

    await createVehicle(saneVehicle)
      .unwrap()
      .then(async ({id: vehicleId}) => {
        await createServiceVehicle({vehicleId})
          .unwrap()
          .then(async () => {
            if (isNotNil(formData.note) && isNotEmpty(formData.note)) {
              await setAdditionalNote({
                vehicleId,
                additionalNoteRequestBody: {note: formData.note},
              });
            }

            showNotification.success();
            props.onSuccess?.(vehicleId);
          })
          .catch(async (error: ApiError) => {
            await deleteVehicle({vehicleId});
            handleApiError(error);
          });
      })
      .catch(handleApiError);
  };

  const handleVehicleUpdate = async (formData: ServiceVehicleFormType) => {
    if (
      props.mode !== ServiceVehicleFormMode.EDIT &&
      props.mode !== ServiceVehicleFormMode.NEW_FROM_SALE
    ) {
      return;
    }

    const staticCarFeatures = await getCarFeatures(formData).catch(() => undefined);

    const saneVehicle = convertFormVehicleToVehicleV2({
      formVehicle: formData,
      givenCarFeatures: props.vehicleData.features,
      staticCarFeatures,
    });

    await updateVehicle({id: props.vehicleData.id, ...saneVehicle})
      .unwrap()
      .then(async () => {
        if (isNotNil(formData.note) && isNotEmpty(formData.note)) {
          await setAdditionalNote({
            vehicleId: props.vehicleData.id,
            additionalNoteRequestBody: {note: formData.note},
          });
        }

        showNotification.success();
        props.onSuccess?.(props.vehicleData.id);
      })
      .catch(handleApiError);
  };

  const handleCreateServiceVehicleFromSale = async (formData: ServiceVehicleFormType) => {
    if (props.mode !== ServiceVehicleFormMode.NEW_FROM_SALE) {
      return;
    }

    await createServiceVehicle({vehicleId: props.vehicleData.id}).unwrap().catch(handleApiError);
    await handleVehicleUpdate(formData);
  };

  return (
    <Form<ServiceVehicleFormType>
      defaultValues={{...getFormDefaultValues(), ...props.extendedDefaultFormData}}
      schema={schema}
    >
      {(control, formApi) => (
        <>
          <Show when={props.variant === ServiceVehicleFormVariant.EXTENDED}>
            <ExtendedView
              control={control}
              formApi={formApi}
              mode={props.mode}
              variant={props.variant}
              isVehicleCreating={isVehicleCreating}
              isVehicleUpdating={isVehicleUpdating}
              onVehicleCreation={handleVehicleCreation}
              onVehicleUpdate={handleVehicleUpdate}
              onCreateServiceVehicleFromSale={handleCreateServiceVehicleFromSale}
              shouldRecommendVehiclesFor={props.shouldRecommendVehiclesFor}
              onDiscard={props.onDiscard}
              data-testid={suffixTestId('extendedView', props)}
            />
          </Show>
          <Show when={props.variant === ServiceVehicleFormVariant.SIMPLE}>
            <SimpleView
              control={control}
              formApi={formApi}
              mode={props.mode}
              variant={props.variant}
              isVehicleCreating={isVehicleCreating}
              isVehicleUpdating={isVehicleUpdating}
              onVehicleCreation={handleVehicleCreation}
              onVehicleUpdate={handleVehicleUpdate}
              onDiscard={props.onDiscard}
              data-testid={suffixTestId('simpleView', props)}
            />
          </Show>
        </>
      )}
    </Form>
  );
}

const schema = object({
  formMake: yupString.required(),
  formModelFamily: yupString.required(),
  type: array().of(yupString.required()).required().length(1, i18n.t('general.errors.required')),
});
