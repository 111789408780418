import {Attributes, AttributesRow, Button, showNotification} from 'platform/components';
import {Show, Space} from 'platform/foundation';

import {useParams} from 'react-router-dom';

import {isNil} from 'ramda';

import {useGetErrorLogQuery, useReprocessErrorLogMutation} from '@dms/api/metadaErrorLogs';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {useNavigate} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

const CREATE_DOCUMENT_TYPE = 'CREATE_ACC';

export function AccountingErrorLogDetail() {
  const {id} = useParams();
  const navigate = useNavigate();

  if (!id) {
    throw new Error('missing activity log Id');
  }

  const {data: errorLog, isLoading, isError} = useGetErrorLogQuery({id});
  const [reprocessErrorLog, {isLoading: isReprocessing}] = useReprocessErrorLogMutation();

  const handleReprocess = async () => {
    const documentId = errorLog?.documentId;

    if (isNil(documentId)) {
      showNotification.error();
      return;
    }

    await reprocessErrorLog({errorLogId: id, documentId})
      .unwrap()
      .then(() => showNotification.success())
      .then(() => navigate(settingsRoutes.accountingErrorLog))
      .catch(handleApiError);
  };

  const attributesRows: AttributesRow[] = [
    {label: i18n.t('page.accountingSettings.labels.id'), value: errorLog?.id},
    {label: i18n.t('page.accountingSettings.labels.documentId'), value: errorLog?.documentId},
    {
      label: i18n.t('page.accountingSettings.labels.documentType.title'),
      value: errorLog?.documentType,
    },
    {label: i18n.t('page.accountingSettings.labels.interface'), value: errorLog?.interface},
    {label: i18n.t('page.accountingSettings.labels.operation'), value: errorLog?.operation},
    {label: i18n.t('page.accountingSettings.labels.errorMessage'), value: errorLog?.errorMessage},
    {label: i18n.t('page.accountingSettings.labels.notes'), value: errorLog?.notes},
  ];

  return (
    <SettingsTemplate
      data-testid={testIds.settings.accountingErrorLog('header')}
      header={{
        title: errorLog?.documentType || i18n.t('page.accounting.labels.accountingErrorLog'),
      }}
      isLoading={isLoading}
      isError={isError}
    >
      <Attributes
        rows={attributesRows}
        size="quarter"
        data-testid={testIds.settings.accountingErrorLog('attributes')}
      />

      <Show when={errorLog?.interface === CREATE_DOCUMENT_TYPE}>
        <Space vertical={4} />
        <Button
          title={i18n.t('page.accountingSettings.labels.reprocess')}
          onClick={handleReprocess}
          isLoading={isReprocessing}
          variant="secondary"
          data-testid={testIds.settings.accountingErrorLog('reprocess')}
        />
      </Show>

      <SettingsFooter
        actions={[
          {
            type: 'button',
            title: i18n.t('general.actions.close'),
            onClick: () => navigate(settingsRoutes.accountingErrorLog),
          },
        ]}
      />
    </SettingsTemplate>
  );
}
