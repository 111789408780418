import {UserResponseBody} from '@dms/api/shared';
import {getNaturalPersonFullName} from '@dms/shared';

import {EMPTY_PLACEHOLDER} from 'shared';

export function getBigCalendarUser(user: UserResponseBody, isHidden?: boolean) {
  const fullName = getNaturalPersonFullName({
    firstName: user.firstName,
    lastName: user.lastName,
  });

  return {
    id: user.id,
    fullName: fullName || EMPTY_PLACEHOLDER,
    email: user.email,
    isHidden,
  };
}
