import {omneticApi as api} from '@dms/api/core';

import {
  GetAssignmentsArgs,
  GetAssignmentsResponse,
  getAssignmentsResponseSchema,
  SearchAssignmentUsersArgs,
  SearchAssignmentUsersResponse,
  searchAssignmentUsersResponseSchema,
  assignmentSchema,
  CreateAssignmentResponse,
  CreateAssignmentArgs,
} from './types';

const assignmentApi = api.injectEndpoints({
  endpoints: (build) => ({
    searchAssignmentUsers: build.query<SearchAssignmentUsersResponse, SearchAssignmentUsersArgs>({
      query: (queryArg) => ({
        url: `/dms/v1/assignment/${queryArg.assignmentIdentityType}/${queryArg.assignmentIdentityId}/user-search`,
        params: {
          search: queryArg.search,
          limit: queryArg.limit,
          external: queryArg.external,
        },
      }),
      extraOptions: {
        responseSchema: searchAssignmentUsersResponseSchema,
      },
      providesTags: ['SearchAssignmentUsers'],
    }),
    getAssignments: build.query<GetAssignmentsResponse, GetAssignmentsArgs>({
      query: (queryArg) => ({
        url: `/dms/v1/assignment/${queryArg.assignmentIdentityType}/${queryArg.assignmentIdentityId}`,
      }),
      extraOptions: {
        responseSchema: getAssignmentsResponseSchema,
      },
      providesTags: ['GetAssignments'],
    }),
    createAssignment: build.mutation<CreateAssignmentResponse, CreateAssignmentArgs>({
      query: (queryArg) => ({
        url: '/dms/v1/assignment',
        method: 'POST',
        body: {
          userId: queryArg.userId,
          identity: queryArg.identity,
        },
      }),
      extraOptions: {
        responseSchema: assignmentSchema,
      },
      invalidatesTags: ['GetAssignments', 'SearchAssignmentUsers'],
      async onQueryStarted(args, {dispatch, queryFulfilled}) {
        const optimisticValue = {
          id: new Date().toISOString(),
          identity: args.identity,
          user: {
            id: args.userId,
            email: args.email ?? '-',
            firstName: args.firstName ?? undefined,
            lastName: args.lastName ?? undefined,
          },
        };

        const patchResults = dispatch(
          assignmentApi.util.updateQueryData(
            'getAssignments',
            {
              assignmentIdentityType: args.identity.type,
              assignmentIdentityId: args.identity.id,
            },
            (draft) =>
              [...draft, optimisticValue].toSorted((a, b) =>
                a.user.email.localeCompare(b.user.email)
              )
          )
        );

        try {
          const {data} = await queryFulfilled;

          dispatch(
            assignmentApi.util.updateQueryData(
              'getAssignments',
              {
                assignmentIdentityType: args.identity.type,
                assignmentIdentityId: args.identity.id,
              },
              (draft) =>
                draft.map((assignment) =>
                  assignment.id === optimisticValue.id ? data : assignment
                )
            )
          );
        } catch {
          patchResults.undo();
        }
      },
    }),
    deleteAssignment: build.mutation<void, string>({
      query: (assignmentId) => ({
        url: `/dms/v1/assignment/${assignmentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['GetAssignments', 'SearchAssignmentUsers'],
      async onQueryStarted(assignmentId, {dispatch, queryFulfilled, getState}) {
        const matches = api.util.selectInvalidatedBy(getState(), [{type: 'GetAssignments'}]);

        const patchResults = matches.map((match) => {
          const {endpointName, originalArgs} = match;
          if (endpointName !== 'getAssignments') {
            return null;
          }

          return dispatch(
            assignmentApi.util.updateQueryData('getAssignments', originalArgs, (draft) =>
              draft.filter((assignment) => assignment.id !== assignmentId)
            )
          );
        });

        try {
          await queryFulfilled;
        } catch {
          patchResults?.forEach((patchResult) => patchResult?.undo());
        }
      },
    }),
  }),
});

export const {
  useSearchAssignmentUsersQuery,
  useLazySearchAssignmentUsersQuery,
  useGetAssignmentsQuery,
  useLazyGetAssignmentsQuery,
  useCreateAssignmentMutation,
  useDeleteAssignmentMutation,
} = assignmentApi;
