import {isFeatureEnabled} from 'feature-flags';

import {useParams} from 'react-router-dom';

import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetWatermarkDetailQuery} from '@dms/api/vehicle';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {WatermarkForm} from './components/WatermarkForm';

export function PhotosWatermarkingDetail() {
  const {id} = useParams();
  const {data, isLoading} = useGetWatermarkDetailQuery(
    {watermarkId: id ?? ''},
    {skip: isNilOrEmpty(id)}
  );

  return (
    <SettingsTemplate
      isLoading={isLoading}
      header={{
        title: data?.name
          ? i18n.t('page.salesSettings.labels.watermarks.configuration', {name: data?.name})
          : i18n.t('page.salesSettings.labels.watermarks.newConfiguration'),
        breadcrumbs: [
          {
            label: i18n.t('page.salesSettings.labels.advertising'),
            href: settingsRoutes.advertising,
          },
          {
            label: i18n.t('page.salesSettings.labels.watermarks.name'),
            href: isFeatureEnabled(featureFlags.SETTINGS_WATERMARKS)
              ? settingsRoutes.advertisingWatermarking
              : settingsRoutes.advertisingWatermarkingOld,
          },
        ],
      }}
      description={i18n.t('page.salesSettings.labels.watermarks.formDescription')}
      data-testid="settings-advertising-watermarking-detail"
    >
      <WatermarkForm />
    </SettingsTemplate>
  );
}
