import {Option} from 'platform/components';

import {isNil} from 'ramda';

import {GetCodeListResponse} from '@dms/api/metadaWarehouseCodeList';

import {Nullish} from 'shared';

export const getOptionsFromWorkRateTypes = (
  workRateTypes: GetCodeListResponse | Nullish
): Option[] => {
  if (isNil(workRateTypes)) {
    return [];
  }

  return workRateTypes.map((workRateType) => ({
    label: workRateType.name,
    value: workRateType.code,
  }));
};
