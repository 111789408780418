import {jwtDecode} from 'jwt-decode';

import {useEffect} from 'react';

import {isNil} from 'ramda';

import {useFlagsmith} from './useFlagsmith';

type InitFlagsmithProps = {
  userEmail: string | null | undefined;
  accessTokenStorageKey: string;
  environmentId: string;
  flagsmithUrl: string;
};

export function useInitFlagsmith(props: InitFlagsmithProps) {
  const {isFlagSmithInitialized, handleInitFlagsmith, areFeatureFlagsLoadedFromApi} = useFlagsmith({
    environmentID: props.environmentId,
    apiUrl: props.flagsmithUrl,
    skip: true,
  });

  useEffect(() => {
    const accessToken = sessionStorage.getItem(props.accessTokenStorageKey);

    if (isNil(props.userEmail) || !accessToken || !props.environmentId) {
      return;
    }

    const decodedToken = jwtDecode<{
      wsp?: string;
    }>(accessToken);
    const workspace = decodedToken?.wsp ?? 'no-workspace';
    const email = props.userEmail;

    handleInitFlagsmith(`${email}__${workspace}`, {workspace, email});
  }, [props.userEmail, handleInitFlagsmith]);

  return {isFlagSmithInitialized, areFeatureFlagsLoadedFromApi};
}
