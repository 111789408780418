import {CustomFilterProps} from 'ag-grid-react';
import {Chips, Separator, Switch} from 'platform/components';
import {HStack, VStack} from 'platform/foundation';

import {useCallback} from 'react';

import {isBoolean, isString} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {useFilterOnChipsChange} from '../../hooks/useFilterOnChipsChange';
import {QuickFilters} from '../../types/Api';

export type SwitchFilterProps = {
  isDisabled: boolean;
} & CustomFilterProps &
  QuickFilters &
  TestIdProps;

export function SwitchFilter(props: SwitchFilterProps) {
  const setValue = useCallback((value: typeof props.model) => {
    const newModel = value ? value : null;
    props.onModelChange(newModel);
  }, []);

  const {onChipsChange} = useFilterOnChipsChange({
    setFilterValue: setValue,
    defaultValue: false,
  });

  const onChange = (value: boolean) => {
    setValue(value);
  };

  const isQuickFilterValue = isString(props.model) ? [props.model] : null;

  const isValue = isBoolean(props.model) ? props.model : false;

  return (
    <VStack>
      {props.quickFilters && props.quickFilters.length > 0 && (
        <>
          <HStack>
            <Chips
              isDisabled={props.isDisabled}
              value={isQuickFilterValue}
              options={props.quickFilters}
              onChange={onChipsChange}
              isMultiple={false}
              data-testid={suffixTestId('switch-filter-quickFilters', props)}
              isDeselectable
            />
          </HStack>
          <Separator />
        </>
      )}
      <Switch
        isDisabled={props.isDisabled}
        value={isValue}
        onChange={onChange}
        data-testid={suffixTestId('switch-filter', props)}
      />
    </VStack>
  );
}
