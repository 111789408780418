import {Button, Card, DataStatus, FormControl, FormField, Separator} from 'platform/components';
import {Box, Heading, Show, VStack} from 'platform/foundation';

import {useFieldArray, UseFormReturn} from 'react-hook-form';

import {isNil, mergeRight} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetEmployeeWorkProfessionsListQuery} from '@dms/api/metadaEmployeeProfession';
import {useGetBranchListQuery} from '@dms/api/tenant';
import i18n from '@dms/i18n';
import {getActiveOptions} from '@dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {EMPTY_BRANCH} from '../../../constants/emptyBranchObject';
import {useBillingInformation} from '../hooks/useBillingInformation';
import {EmployeeOrganizationalForm} from '../types/employeeOrganizationalFormType';
import {EmployeeAllocationWorkshopData} from './EmployeeAllocationWorkshopData';
import {EmployeeAuthoriationProfiles} from './EmployeeAuthoriationProfiles';

interface EmployeeAllocationProps extends TestIdProps {
  control: FormControl<EmployeeOrganizationalForm>;
  formApi: UseFormReturn<EmployeeOrganizationalForm>;
}

export function EmployeeAllocation(props: EmployeeAllocationProps) {
  const {fields, append, remove, update} = useFieldArray({
    name: 'branches',
    control: props.control,
  });

  const selectedCompany = props.formApi.watch('companyId');

  const {getNameById: getCompanyNameById} = useBillingInformation();

  const {branchOptions} = useGetBranchListQuery(undefined, {
    selectFromResult: ({data}) => {
      if (isNil(selectedCompany)) {
        return {branchOptions: undefined};
      }

      return {
        branchOptions: data?.branchListItems
          ?.filter((branch) => branch.companyName === getCompanyNameById(selectedCompany))
          .map((branch) => ({label: branch.marketingName, value: branch.id})),
      };
    },
  });

  const {professionOptions} = useGetEmployeeWorkProfessionsListQuery(undefined, {
    selectFromResult: ({data}) => ({
      professionOptions: getActiveOptions(data?.professions),
    }),
  });

  const hanldeBranchChange = (index: number) => (value: string | number | string[] | null) =>
    update(index, mergeRight(EMPTY_BRANCH, {branchId: String(value)}));

  return (
    <VStack spacing={4}>
      {fields.map((field, index) => (
        <Card
          title={`${i18n.t('page.employeeDetail.labels.employeeAllocation')}${
            fields.length > 1 ? ` (${index + 1})` : ''
          }`}
          variant="inlineGrey"
          key={field.id}
        >
          <VStack spacing={4}>
            <FormField
              type="choice"
              control={props.control}
              name={`branches.${index}.branchId`}
              label={i18n.t('entity.branch.labels.branch')}
              isRequired
              options={branchOptions}
              onChange={hanldeBranchChange(index)}
              isNotClearable
              data-testid={suffixTestId(`${index}-branch`, props)}
            />
            <DataStatus
              isEmpty={isNilOrEmpty(props.formApi.watch(`branches.${index}.branchId`))}
              emptyMessage={i18n.t(
                'page.employeeDetail.labels.employeeAllocationAndAuthorizationIsNotAvailable'
              )}
              emptySubheadline={i18n.t('page.employeeDetail.labels.selectBranchToAccess')}
              minHeight={33}
            >
              <FormField
                type="choice"
                control={props.control}
                name={`branches.${index}.professionId`}
                label={i18n.t('entity.employeeProfessions.labels.title')}
                options={professionOptions}
                isNotClearable
                data-testid={suffixTestId(`${index}-profession`, props)}
              />
              <Separator spacing={0} />
              <Heading size={5}>
                {i18n.t('page.employeeDetail.labels.authorizationClassification')}
              </Heading>
              <EmployeeAuthoriationProfiles
                control={props.control}
                index={index}
                data-testid={suffixTestId(`${index}-authoriationProfiles`, props)}
              />
              <Separator spacing={0} />
              <EmployeeAllocationWorkshopData
                control={props.control}
                formApi={props.formApi}
                index={index}
                data-testid={suffixTestId(`${index}-workshopData`, props)}
              />
            </DataStatus>
            <Show when={fields.length > 1}>
              <Box flex={1}>
                <Button
                  variant="dangerLink"
                  title={i18n.t('general.actions.remove')}
                  onClick={() => remove(index)}
                  data-testid={suffixTestId(`${index}-remove`, props)}
                />
              </Box>
            </Show>
          </VStack>
        </Card>
      ))}
      <Box flex={1}>
        <Button
          variant="link"
          leftIcon="content/add_circle"
          title={i18n.t('page.employeeDetail.actions.addEmployeeAllocation')}
          onClick={() => append(EMPTY_BRANCH)}
          data-testid={suffixTestId('addEmployeeAllocation', props)}
        />
      </Box>
    </VStack>
  );
}
