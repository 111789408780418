import {
  Button,
  closeDialog,
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
  openDeleteDialog,
  openDialog,
  Separator,
} from 'platform/components';
import {Box, Heading, HStack, Space, Text, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';
import {object} from 'yup';

import {useParams} from 'react-router-dom';

import {head, mergeAll} from 'ramda';
import {isArray, isNilOrEmpty, isString, noop} from 'ramda-adjunct';

import {
  PostMaterialDiscountGroupApiResponse,
  useDeleteMaterialDiscountGroupItemMutation,
  useGetMaterialDiscountGroupByIdQuery,
  usePatchMaterialDiscountGroupItemMutation,
  usePatchMaterialDiscountGroupMutation,
  usePostMaterialDiscountGroupItemMutation,
  usePostMaterialDiscountGroupMutation,
} from '@dms/api/metadaWorkshopMaterialDiscountGroup';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {EMPTY_PLACEHOLDER, handleApiError} from '@dms/shared';

import {composePath, useNavigate, yupString} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject, useRefreshDataGrid} from 'features/datagrid';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';
import {MaterialDiscountForm} from './components/MaterialDiscountForm';

type CustomerGroupFormType = {
  code: string;
  name: string;
};

export function WorkshopCustomerGroupFormPage() {
  const {id} = useParams();
  const navigate = useNavigate();
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const isCreating = isNilOrEmpty(id);

  const [postMaterialDiscountGroup] = usePostMaterialDiscountGroupMutation();
  const [patchMaterialDiscountGroup] = usePatchMaterialDiscountGroupMutation();
  const [postMaterialDiscountGroupItem] = usePostMaterialDiscountGroupItemMutation();
  const [patchMaterialDiscountGroupItem] = usePatchMaterialDiscountGroupItemMutation();
  const [deleteMaterialDiscountGroupItem] = useDeleteMaterialDiscountGroupItemMutation();
  const {data, isLoading, isError} = useGetMaterialDiscountGroupByIdQuery(
    {groupId: id ?? ''},
    {skip: isCreating}
  );

  const header: SettingsTemplateHeader = {
    title: isCreating
      ? i18n.t('entity.rabatDiscounts.actions.newRabatDiscount')
      : (data?.name ?? EMPTY_PLACEHOLDER),
    breadcrumbs: [
      {
        label: i18n.t('entity.rabatDiscounts.labels.rabatDiscounts'),
        href: settingsRoutes.workshopCustomerGroups,
      },
    ],
  };

  const queryModifier = (filter: QueryFilterObject) =>
    mergeAll([filter, {materialDiscountGroupId: id}]);

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData}) => {
    const discountId = isArray(rowId) ? head(rowId) : rowId;

    match(actionKey)
      .with('redirectDetail', 'edit', () => {
        if (isString(discountId)) {
          openDialog(
            <MaterialDiscountForm
              groupId={id}
              discountId={discountId}
              onClose={() => closeDialog('editMaterialDiscountDialog')}
              onSubmit={async (data) => {
                await patchMaterialDiscountGroupItem({
                  discountId,
                  groupId: id ?? '',
                  body: {
                    ...data,
                    discountRatio: data.discountRatio / 100,
                  },
                })
                  .unwrap()
                  .then(() => {
                    refreshDataGrid();
                    closeDialog('editMaterialDiscountDialog');
                  })
                  .catch(handleApiError);
              }}
              data-testid={testIds.settings.workshopCustomerGroupDetail(
                'editMaterialDiscountDialog'
              )}
            />,
            {
              id: 'editMaterialDiscountDialog',
              title: i18n.t('general.labels.edit'),
            }
          );
        }
      })
      .with('delete', () => {
        if (isString(discountId)) {
          openDeleteDialog({
            onConfirm: () =>
              deleteMaterialDiscountGroupItem({groupId: id ?? '', discountId})
                .unwrap()
                .then(refreshData)
                .catch(handleApiError),
            'data-testid': testIds.settings.workshopCustomerGroupDetail('deleteMaterialDiscount'),
          });
        }
      })
      .otherwise(noop);
  };

  const onCloseDialog = (id: string) => {
    closeDialog('newMaterialDiscountDialog');
    navigate(composePath(settingsRoutes.workshopCustomerGroupDetail, {params: {id}}));
  };

  const openMaterialDiscountDialog = (discountGroup: PostMaterialDiscountGroupApiResponse) =>
    openDialog(
      <MaterialDiscountForm
        onClose={() => onCloseDialog(discountGroup?.id ?? '')}
        onSubmit={(data) =>
          postMaterialDiscountGroupItem({
            materialDiscountGroupId: discountGroup?.id ?? '',
            body: {
              ...data,
              discountRatio: data.discountRatio / 100,
            },
          })
            .unwrap()
            .then(() => onCloseDialog(discountGroup?.id ?? ''))
            .catch(handleApiError)
        }
        data-testid={testIds.settings.workshopCustomerGroupDetail('newMaterialDiscountDialog')}
      />,
      {
        id: 'newMaterialDiscountDialog',
        title: i18n.t('entity.workshopCustomerGroup.labels.newMaterialDiscount'),
      }
    );

  const handleAddMaterialDiscount = (formValues: CustomerGroupFormType) => () => {
    if (isCreating) {
      return postMaterialDiscountGroup({body: formValues})
        .unwrap()
        .then(openMaterialDiscountDialog)
        .catch(handleApiError);
    }

    openDialog(
      <MaterialDiscountForm
        onClose={() => closeDialog('newMaterialDiscountDialog')}
        onSubmit={(data) =>
          postMaterialDiscountGroupItem({
            materialDiscountGroupId: id ?? '',
            body: {
              ...data,
              discountRatio: data.discountRatio / 100,
            },
          })
            .unwrap()
            .then(() => {
              refreshDataGrid();
              closeDialog('newMaterialDiscountDialog');
            })
            .catch(handleApiError)
        }
        data-testid={testIds.settings.workshopCustomerGroupDetail('newMaterialDiscountDialog')}
      />,
      {
        id: 'newMaterialDiscountDialog',
        title: i18n.t('entity.workshopCustomerGroup.labels.newMaterialDiscount'),
      }
    );
  };

  const handleSubmit: FormSubmitHandler<CustomerGroupFormType> = (data) => {
    const method = isCreating
      ? postMaterialDiscountGroup({body: data})
      : patchMaterialDiscountGroup({groupId: id ?? '', body: data});

    return method
      .unwrap()
      .then(() => navigate(settingsRoutes.workshopCustomerGroups))
      .catch(handleApiError);
  };

  return (
    <SettingsTemplate
      header={header}
      isCreating={isCreating}
      data-testid={testIds.settings.workshopCustomerGroupDetail('page')}
      isLoading={isLoading}
      isError={isError}
    >
      <VStack>
        <Form<CustomerGroupFormType>
          defaultValues={{code: data?.code ?? undefined, name: data?.name ?? undefined}}
          onSubmit={handleSubmit}
          schema={schema}
        >
          {(control, formApi) => (
            <VStack>
              <SettingsSection>
                <VStack spacing={6}>
                  <Text size="small" color="secondary">
                    {i18n.t('entity.rabatDiscounts.actions.overview')}
                  </Text>
                  <HStack spacing={4}>
                    <Box flex={1}>
                      <FormField
                        control={control}
                        name="name"
                        type="text"
                        label={i18n.t('general.labels.name')}
                        isRequired
                        data-testid={testIds.settings.workshopCustomerGroupDetail('name')}
                      />
                    </Box>
                    <Box flex={1}>
                      <FormField
                        control={control}
                        name="code"
                        type="text"
                        label={i18n.t('entity.workshopCustomerGroup.labels.code')}
                        data-testid={testIds.settings.workshopCustomerGroupDetail('code')}
                      />
                    </Box>
                  </HStack>
                </VStack>
              </SettingsSection>
              <Separator spacing={6} />
              <VStack spacing={6}>
                <HStack justify="space-between" align="center">
                  <Heading size={4}>
                    {i18n.t('entity.workshopCustomerGroup.labels.listOfDiscounts')}
                  </Heading>
                  <Button
                    title={i18n.t('general.actions.add')}
                    leftIcon="content/add_circle"
                    variant="link"
                    size="small"
                    onClick={handleAddMaterialDiscount(formApi.watch() as CustomerGroupFormType)}
                    isDisabled={isNilOrEmpty(formApi.watch('name'))}
                    data-testid={testIds.settings.workshopCustomerGroupDetail('addDiscount')}
                  />
                </HStack>
              </VStack>
              <SettingsFooter
                actions={[
                  {
                    type: 'button',
                    title: i18n.t('general.actions.discard'),
                    variant: 'secondary',
                    onClick: () => navigate(settingsRoutes.workshopCustomerGroups),
                    'data-testid': testIds.settings.workshopCustomerGroupDetail('discard'),
                  },
                  {
                    type: 'form-button',
                    title: isCreating
                      ? i18n.t('general.actions.create')
                      : i18n.t('general.actions.save'),
                    control,
                    buttonType: 'submit',
                    'data-testid': testIds.settings.workshopCustomerGroupDetail('save'),
                  },
                ]}
              />
            </VStack>
          )}
        </Form>
        <Space vertical={6} />
        <DataStatus
          isEmpty={isCreating}
          emptyMessage={i18n.t('entity.workshopCustomerGroup.labels.noDiscountsOnMaterial')}
          minHeight={33}
        >
          <DataGrid
            autoHeight
            ref={dataGridRef}
            actionCallback={actionCallback}
            gridCode="material-discount-group-item"
            queryModifier={queryModifier}
            data-testid={testIds.settings.workshopCustomerGroupDetail(
              'material-discount-group-item'
            )}
          />
        </DataStatus>
        <Space vertical={20} />
      </VStack>
    </SettingsTemplate>
  );
}

const schema = object({
  name: yupString.required(),
  code: yupString.optional(),
});
