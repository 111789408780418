import {
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  closeCurrentDialog,
} from 'platform/components';
import {Box, HStack, VStack} from 'platform/foundation';

import {isNotNil, not} from 'ramda';
import {isArray, isFalse} from 'ramda-adjunct';

import {useGetCurrenciesQuery} from '@dms/api/cached';
import {useGetWarehousesQuery} from '@dms/api/metadaWarehouse';
import {BulkPatchDeliveryNoteRequest} from '@dms/api/metadaWarehouseDeliveryNote';
import i18n from '@dms/i18n';
import {getOptionsFromCurrencies, getOptionsFromWarehouses} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {
  DeliveryNoteListBulkEditForm,
  DeliveryNoteListBulkEditFormSchema,
} from '../types/DeliveryNoteListBulkEditForm';

interface DeliveryNoteListBulkEditProps extends RequiredTestIdProps {
  rowId: string | string[];
  onSubmit: (requestBody: BulkPatchDeliveryNoteRequest) => Promise<void>;
}

export function DeliveryNoteListBulkEdit(props: DeliveryNoteListBulkEditProps) {
  const {data: warehouses, isLoading: areWarehousesLoading} = useGetWarehousesQuery();
  const {data: currencies, isLoading: areCurrenciesLoading} = useGetCurrenciesQuery();

  const handleDiscard = () => closeCurrentDialog();

  const handleSubmit: FormSubmitHandler<DeliveryNoteListBulkEditForm> = (formValues) =>
    props.onSubmit({
      deliveryNoteId: isArray(props.rowId) ? props.rowId : [props.rowId],
      warehouseId: formValues.warehouse.warehouseId,
      currency: formValues.currency.currency,
    });

  const defaultValues: DeliveryNoteListBulkEditForm = {
    warehouse: {
      isWarehouseEnabled: false,
    },
    currency: {
      isCurrencyEnabled: false,
    },
  };

  return (
    <Form<DeliveryNoteListBulkEditForm>
      experimentalZodSchema={DeliveryNoteListBulkEditFormSchema}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
    >
      {(control, formApi) => {
        const isWarehouseEnabled = formApi.watch('warehouse.isWarehouseEnabled');
        const isCurrencyEnabled = formApi.watch('currency.isCurrencyEnabled');
        const warehouseId = formApi.watch('warehouse.warehouseId');
        const currency = formApi.watch('currency.currency');
        const hasChanges = isNotNil(warehouseId) || isNotNil(currency);

        return (
          <VStack spacing={4}>
            <HStack spacing={4} align="flex-end">
              <FormField
                control={control}
                type="switch"
                name="warehouse.isWarehouseEnabled"
                onChange={(value) =>
                  isFalse(value) && formApi.setValue('warehouse.warehouseId', null)
                }
                data-testid={suffixTestId('inputs.isWarehouseEnabled', props)}
              />
              <Box flex={2}>
                <FormField
                  isDisabled={not(isWarehouseEnabled)}
                  isLoading={areWarehousesLoading}
                  control={control}
                  type="choice"
                  name="warehouse.warehouseId"
                  label={i18n.t('entity.warehouse.labels.warehouse')}
                  options={getOptionsFromWarehouses(warehouses)}
                  menuInPortal
                  data-testid={suffixTestId('inputs.warehouseId', props)}
                />
              </Box>
            </HStack>

            <HStack spacing={4} align="flex-end">
              <FormField
                control={control}
                type="switch"
                name="currency.isCurrencyEnabled"
                onChange={(value) => isFalse(value) && formApi.setValue('currency.currency', null)}
                data-testid={suffixTestId('inputs.isCurrencyEnabled', props)}
              />
              <Box flex={2}>
                <FormField
                  isDisabled={not(isCurrencyEnabled)}
                  isLoading={areCurrenciesLoading}
                  control={control}
                  type="choice"
                  name="currency.currency"
                  label={i18n.t('entity.warehouse.labels.currency')}
                  options={getOptionsFromCurrencies(currencies)}
                  menuInPortal
                  data-testid={suffixTestId('inputs.currency', props)}
                />
              </Box>
            </HStack>

            <ButtonGroup align="right">
              <Button
                variant="secondary"
                title={i18n.t('general.actions.discard')}
                onClick={handleDiscard}
                data-testid={suffixTestId('actions.discard', props)}
              />
              <FormButton
                type="submit"
                control={control}
                title={i18n.t('general.actions.apply')}
                isDisabled={not(hasChanges)}
                data-testid={suffixTestId('actions.submit', props)}
              />
            </ButtonGroup>
          </VStack>
        );
      }}
    </Form>
  );
}
