import {Nullish} from 'utility-types';

import {isNotNil} from 'ramda';

import {Key, VehicleTypeEnumObject} from '@dms/api/shared';
import {CarFeaturesResponseBody} from '@dms/api/sourcing';
import {useVehicleFeaturesV2} from '@dms/shared';

export function useFeatureAnalysis(
  vehicleType: VehicleTypeEnumObject | Nullish,
  sourcingCarQueryData: CarFeaturesResponseBody | Nullish
) {
  const {flatFeaturesArray} = useVehicleFeaturesV2(vehicleType);

  const premiumItems =
    sourcingCarQueryData?.premiumFeatures
      .map((item) => flatFeaturesArray.find((feature) => feature.const_key === item))
      .filter<Key>(isNotNil)
      .sort((a, b) => a.labels[0].label.localeCompare(b.labels[0].label)) || [];

  const highlightedItems =
    sourcingCarQueryData?.highlightedFeatures
      .map((item) => flatFeaturesArray.find((feature) => feature.const_key === item))
      .filter<Key>(isNotNil)
      .sort((a, b) => a.labels[0].label.localeCompare(b.labels[0].label)) || [];

  return {premiumItems, highlightedItems};
}
