import {isToday, isAfter} from 'date-fns';
import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  closeDialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Label,
  TimeType,
} from 'platform/components';
import {Box, HStack, Show, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {
  BusinessCaseActivityResponseBody,
  InterestActivityResponseBody,
  useCreateInterestActivityMutation,
  useCreateBusinessCaseActivityMutation,
  usePatchSalesActivityMutation,
  useDeleteSalesActivityMutation,
  SalesActivityManualTypeEnum,
} from '@dms/api/sales';
import i18n from '@dms/i18n';
import {PredefinedNotes} from '@dms/shared';

import {TestIdProps, parseDate, suffixTestId} from 'shared';

import {LogContactDialogBox} from './LogContactDialogBox';
import {LogContactDialogSchemaType, LogContactDialogSchema} from './LogContactDialogSchema';

interface LogContactDialogProps extends TestIdProps {
  businessCaseId?: string;
  interestId?: string;
  salesActivity?: BusinessCaseActivityResponseBody | InterestActivityResponseBody;
}

export function LogContactDialog(props: LogContactDialogProps) {
  const [createInterestSalesActivity, {isLoading: isCreateInterestActivityLoading}] =
    useCreateInterestActivityMutation();
  const [createBusinessCaseActivity, {isLoading: isCreateBusinessCaseActivityLoading}] =
    useCreateBusinessCaseActivityMutation();
  const [updateSalesActivity, {isLoading: isUpdateSalesActivityLoading}] =
    usePatchSalesActivityMutation();
  const [deleteSalesActivity, {isLoading: isDeleteSalesActivityLoading}] =
    useDeleteSalesActivityMutation();
  const formatDateTime = useDateTimeFormatter();

  const handleClose = () => {
    closeDialog('LOG_CONTACT_DIALOG');
  };

  const handleDelete = async () => {
    await deleteSalesActivity({salesActivityId: props.salesActivity!.id})
      .unwrap()
      .then(handleClose);
  };

  const handleSubmit: FormSubmitHandler<LogContactDialogSchemaType> = async (data) => {
    const isCreate = !props.salesActivity;
    const dateTime = parseDate(data.date);
    const [hours, minutes] = data.time?.split(':').map(Number) ?? [0, 0];
    dateTime.setHours(hours, minutes);

    if (isCreate) {
      const createSalesActivityRequestBody = {
        activityType: data.type[0],
        createdAt: dateTime.toISOString(),
        description: data.note,
        subject: data.subject,
      };

      if (props.businessCaseId) {
        await createBusinessCaseActivity({
          businessCaseId: props.businessCaseId,
          createSalesActivityRequestBody,
        })
          .unwrap()
          .then(handleClose);
      } else if (props.interestId) {
        await createInterestSalesActivity({
          interestId: props.interestId,
          createSalesActivityRequestBody,
        })
          .unwrap()
          .then(handleClose);
      }
    } else {
      await updateSalesActivity({
        salesActivityId: props.salesActivity!.id,
        patchSalesActivityRequestBody: {
          createdAt: dateTime.toISOString(),
          description: data.note,
          subject: data.subject,
          activityType: data.type[0],
        },
      })
        .unwrap()
        .then(handleClose);
    }
  };

  const isLoading =
    isCreateBusinessCaseActivityLoading ||
    isCreateInterestActivityLoading ||
    isUpdateSalesActivityLoading ||
    isDeleteSalesActivityLoading;

  const currentDate = parseDate(new Date());
  const currentTime =
    `${currentDate?.getHours() ?? '00'}:${currentDate?.getMinutes() ?? '00'}` as TimeType;

  const defaultValues = {
    subject: props.salesActivity?.subject ?? '',
    type: [(props.salesActivity?.activityType as SalesActivityManualTypeEnum) ?? null],
    date: props.salesActivity?.timestamp ? parseDate(props.salesActivity?.timestamp) : new Date(),
    time: props.salesActivity?.timestamp
      ? formatDateTime('timeShort', parseDate(props.salesActivity?.timestamp))
      : currentTime,
    note: props.salesActivity?.description ?? '',
  };

  return (
    <>
      <LogContactDialogBox />
      <Form<LogContactDialogSchemaType>
        onSubmit={handleSubmit}
        defaultValues={defaultValues}
        experimentalZodSchema={LogContactDialogSchema}
      >
        {(control, formApi) => {
          const selectedDate = formApi.watch('date');
          const selectedTime = formApi.watch('time');

          // Check if date is today and time is in the future
          if (isToday(selectedDate as Date) && selectedTime) {
            // Create date objects for comparison
            const now = new Date();
            const today = new Date();

            // Parse the selected time and set it to today's date for comparison
            const [hours, minutes] = selectedTime.split(':').map(Number);
            today.setHours(hours, minutes, 0, 0);

            // If selected time is in the future, reset to current time
            if (isAfter(today, now)) {
              setTimeout(() => formApi.setValue('time', currentTime), 0);
            }
          }

          return (
            <Box paddingBottom={6}>
              <VStack spacing={4}>
                <VStack spacing={1}>
                  <HStack justify="space-between" align="flex-end">
                    <Label>{`* ${i18n.t('entity.activityPanel.logContact.subject')}`}</Label>
                    <PredefinedNotes
                      note={formApi.watch('subject') ?? null}
                      onPrefill={(note) => formApi.setValue('subject', note)}
                      resource="LOG_CONTACT"
                      context="log_contact_subject"
                      isLinkVariant
                      data-testid={suffixTestId('predefinedNotes', props)}
                    />
                  </HStack>
                  <FormField control={control} isRequired name="subject" type="text" />
                </VStack>

                <FormField
                  isRequired
                  isMultiple={false}
                  control={control}
                  label={i18n.t('entity.activityPanel.logContact.type')}
                  name="type"
                  type="chips"
                  options={typeOptions}
                />

                <HStack spacing={4}>
                  <FormField
                    isRequired
                    control={control}
                    name="date"
                    type="date"
                    label={i18n.t('entity.activityPanel.logContact.date')}
                    maxDate={currentDate}
                  />
                  <FormField
                    control={control}
                    name="time"
                    type="time"
                    label={i18n.t('entity.activityPanel.logContact.time')}
                    maxTime={
                      isToday(formApi.watch('date') as Date) || formApi.watch('date') === null
                        ? currentTime
                        : undefined
                    }
                  />
                </HStack>

                <VStack spacing={1}>
                  <HStack justify="space-between" align="flex-end">
                    <Label>{`* ${i18n.t('general.labels.note')}`}</Label>
                    <PredefinedNotes
                      note={formApi.watch('note') ?? null}
                      onPrefill={(note) => formApi.setValue('note', note)}
                      resource="LOG_CONTACT"
                      context="log_contact_note"
                      isLinkVariant
                      data-testid={suffixTestId('predefinedNotes', props)}
                    />
                  </HStack>
                  <FormField control={control} isRequired name="note" type="text" />
                </VStack>

                <HStack justify={props.salesActivity ? 'space-between' : 'flex-end'} width="100%">
                  <Show when={props.salesActivity}>
                    <Button
                      title={i18n.t('general.actions.delete')}
                      variant="dangerOutlined"
                      isDisabled={isLoading}
                      onClick={handleDelete}
                    />
                  </Show>
                  <ButtonGroup align="right">
                    <Button
                      variant="secondary"
                      onClick={closeCurrentDialog}
                      isDisabled={isLoading}
                      title={i18n.t('general.actions.cancel')}
                    />
                    <FormButton
                      variant="primary"
                      type="submit"
                      control={control}
                      isLoading={isLoading}
                      title={i18n.t('general.actions.save')}
                    />
                  </ButtonGroup>
                </HStack>
              </VStack>
            </Box>
          );
        }}
      </Form>
    </>
  );
}

const typeOptions = [
  {
    label: i18n.t('entity.activityPanel.logContact.callToCustomer'),
    value: 'CALL_TO_CUSTOMER',
  },
  {
    label: i18n.t('entity.activityPanel.logContact.callFromCustomer'),
    value: 'CALL_FROM_CUSTOMER',
  },
  {
    label: i18n.t('entity.activityPanel.logContact.customerVisit'),
    value: 'CUSTOMER_VISIT',
  },
  {
    label: i18n.t('entity.activityPanel.logContact.messageToCustomer'),
    value: 'MESSAGE_TO_CUSTOMER',
  },
];
