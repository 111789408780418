export const customerSearchParams = {
  customerDetail: {
    checklists: 'checklists',
    customerInformation: 'customerInformation',
    GDPRConsents: 'GDPRConsents',
    businessCases: 'businessCases',
    interests: 'interests',
    vehicles: 'vehicles',
    documents: 'documents',
    invoices: 'invoices',
    serviceCases: 'serviceCases',
    customerContracts: 'customerContracts',
    activityLog: 'activityLog',
  },
};
