import {ButtonGroup, closeCurrentDialog, DataStatus, DialogFooter} from 'platform/components';
import {Box, Center, Heading, HStack, Icon, Image, Text, VStack} from 'platform/foundation';

import {isNilOrEmpty, isNotNil} from 'ramda-adjunct';

import {useGetTransactionQrCodeQuery} from '@dms/api/digitalSignature';
import i18n from '@dms/i18n';

import {suffixTestId} from 'shared';

import {CancelTransactionButton} from '../../components/CancelTransactionButton';
import {useCurrentTransaction} from '../../hooks/useCurrentTransaction';
import {StepContentProps} from '../../types/StepContentProps';

export function SignByQrCodeStep(props: StepContentProps) {
  const {
    transaction,
    isLoading: isLoadingTransaction,
    isError: isTransactionError,
  } = useCurrentTransaction();

  const {
    data: qrCodeSrc,
    isLoading: isLoadingQrCode,
    isError: isErrorQrCode,
  } = useGetTransactionQrCodeQuery(
    {transactionId: transaction?.transactionId ?? ''},
    {skip: isNilOrEmpty(transaction?.transactionId)}
  );

  const isLoading = isLoadingTransaction || isLoadingQrCode;
  const isError = isTransactionError || isErrorQrCode;

  return (
    <>
      <VStack spacing={4} align="center" width="100%">
        <Icon value="content/qr-code" size={20} color="general.white" />
        <Box>
          <Heading size={3}>{i18n.t('entity.document.actions.qrCodeSent')}</Heading>
        </Box>

        <Box>
          <Text size="small" color="secondary">
            {i18n.t('entity.document.actions.qrCodeSentStep1')}
          </Text>
          <Text size="small" color="secondary">
            {i18n.t('entity.document.actions.qrCodeSentStep2')}
          </Text>
        </Box>

        <Box minWidth={52} height={52}>
          <DataStatus isLoading={isLoading} isEmpty={isError} minHeight={52}>
            <Center>
              {isNotNil(qrCodeSrc) && (
                <Image
                  src={qrCodeSrc}
                  width={52}
                  height={52}
                  data-testid={suffixTestId('qrCode', props)}
                />
              )}
            </Center>
          </DataStatus>
        </Box>
      </VStack>

      <DialogFooter>
        <HStack justify="space-between">
          <CancelTransactionButton
            isLoading={isLoadingTransaction}
            transactionId={transaction?.transactionId}
            refreshData={props.refreshData}
            data-testid={suffixTestId('seller', props)}
          />
          <ButtonGroup
            align="right"
            buttons={[
              {
                title: i18n.t('general.actions.finish'),
                onClick: closeCurrentDialog,
                'data-testid': suffixTestId('finish', props),
              },
            ]}
          />
        </HStack>
      </DialogFooter>
    </>
  );
}
