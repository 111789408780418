import {FormControl, FormField, Label, TextInput} from 'platform/components';
import {Box, Hide, HStack, Show, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {head, pipe, uniq} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {useGetAllApiEnumsQuery, useGetMakeModelWithMakeQuery} from '@dms/api/vehicleCatalogue';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

import {VehicleGroup} from '../types/VehicleGroup';
import {VehicleModel} from '../types/VehicleModel';

const VEHICLE_TYPE_PROPERTY = 'vehicle_type';

interface VehicleGroupFormProps {
  control: FormControl<VehicleGroup>;
  formApi: UseFormReturn<VehicleGroup>;
}

export function VehicleGroupForm(props: VehicleGroupFormProps) {
  const vehicleType = props.formApi.watch('vehicleType');
  const vehicleMake = props.formApi.watch('vehicleMake');

  const {data: makes, isLoading: isMakesLoading} = useGetMakeModelWithMakeQuery(
    {
      vehicleType: vehicleType ?? '',
      lang: [i18n.language],
    },
    {
      skip: !vehicleType,
    }
  );
  const {data: models, isLoading: isModelsLoading} = useGetMakeModelWithMakeQuery(
    {
      vehicleType: vehicleType ?? '',
      make: vehicleMake ?? undefined,
      lang: [i18n.language],
    },
    {
      skip: !vehicleMake || !vehicleType,
      selectFromResult: (result) => ({
        ...result,
        data: head(result?.data ?? [])?.models,
      }),
    }
  );
  const {data: vehicleTypes, isLoading: isVehicleTypesLoading} = useGetAllApiEnumsQuery(
    {lang: [i18n.language]},
    {
      selectFromResult: (result) => ({
        ...result,
        data: result.data?.find((item) => item.property_name === VEHICLE_TYPE_PROPERTY)?.keys,
      }),
    }
  );

  const isMakeSelectEnabled = isNotNil(vehicleType);
  const isModelSelectEnabled = isNotNil(vehicleMake);

  return (
    <VStack spacing={4}>
      <HStack spacing={4}>
        <Box width={85}>
          <FormField
            control={props.control}
            type="text"
            name="name"
            label={i18n.t('general.labels.name')}
            isRequired
            data-testid={testIds.settings.vehicleGroupsDetail('name')}
          />
        </Box>
        <Box width={85}>
          <FormField
            control={props.control}
            isLoading={isVehicleTypesLoading}
            type="choice"
            name="vehicleType"
            label={i18n.t('entity.vehicle.labels.type')}
            options={vehicleTypes?.map((type) => ({
              label: head(type.labels)?.label,
              value: type.const_key,
            }))}
            onChange={() => props.formApi.setValue('vehicleMake', undefined)}
            data-testid={testIds.settings.vehicleGroupsDetail('type')}
          />
        </Box>
      </HStack>
      <HStack spacing={4}>
        <Box width={85}>
          <Show when={isMakeSelectEnabled}>
            <FormField
              control={props.control}
              isLoading={isMakesLoading}
              type="choice"
              name="vehicleMake"
              label={i18n.t('entity.vehicle.labels.vehicleMake')}
              options={makes?.map((make) => ({
                label: make.default_label,
                value: make.make,
              }))}
              onChange={() => {
                props.formApi.setValue('vehicleModels', undefined);
                props.formApi.setValue('vehicleModel_families', undefined);
              }}
              data-testid={testIds.settings.vehicleGroupsDetail('makes')}
            />
          </Show>
          <Hide when={isMakeSelectEnabled}>
            <Label>{i18n.t('entity.vehicle.labels.vehicleMake')}</Label>
            <TextInput value={null} isDisabled />
          </Hide>
        </Box>
        <Box width={85}>
          <Show when={isModelSelectEnabled}>
            <FormField
              control={props.control}
              isLoading={isModelsLoading}
              type="multiChoice"
              name="vehicleModel_families"
              label={i18n.t('entity.vehicle.labels.vehicleModelFamily')}
              options={getModelFamilies(models ?? []).map((group) => ({
                label: group?.default_label,
                value: group?.group,
              }))}
              data-testid={testIds.settings.vehicleGroupsDetail('modelFamilies')}
              isNotClearable
            />
          </Show>
          <Hide when={isModelSelectEnabled}>
            <Label>{i18n.t('entity.vehicle.labels.vehicleModelFamily')}</Label>
            <TextInput value={null} isDisabled />
          </Hide>
        </Box>
      </HStack>
      <HStack spacing={4}>
        <Box width={85}>
          <Show when={isModelSelectEnabled}>
            <FormField
              control={props.control}
              isLoading={isModelsLoading}
              type="multiChoice"
              name="vehicleModels"
              label={i18n.t('entity.vehicle.labels.vehicleModel')}
              options={models?.map((model) => ({
                label: model.default_label,
                value: model.model,
              }))}
              data-testid={testIds.settings.vehicleGroupsDetail('models')}
              isNotClearable
            />
          </Show>
          <Hide when={isModelSelectEnabled}>
            <Label>{i18n.t('entity.vehicle.labels.vehicleModel')}</Label>
            <TextInput value={null} isDisabled />
          </Hide>
        </Box>
      </HStack>
    </VStack>
  );
}

const getModelFamilies = pipe(
  (models: VehicleModel[]) => models.map((model) => model.model_group),
  (groups) => groups.filter(isNotNil),
  uniq
);
