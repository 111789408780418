import {Flags} from 'platform/components';
import {HStack, Text} from 'platform/foundation';

import {EntityTag} from '@dms/api/shared';

import {Nullish} from 'shared';

interface ValueWithTagsProps {
  value: string | Nullish;
  tags: EntityTag[] | Nullish;
}

export function ValueWithTags(props: ValueWithTagsProps) {
  return (
    <HStack spacing={2}>
      <Text size="xSmall">{props.value}</Text>
      <Flags
        size="small"
        flags={props.tags?.map((tag) => ({
          label: tag.valueName,
          colorScheme: tag.valueColor,
        }))}
      />
    </HStack>
  );
}
