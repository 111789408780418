import {motion, useMotionValueEvent, useScroll} from 'framer-motion';
import {Box, HStack} from 'platform/foundation';

import {useRef} from 'react';

import {RequiredTestIdProps, useBoolean, useThrottledCallback} from 'shared';

import {useAppContainerRef} from '../hooks/useAppContainerRef';
import {PageNavigation} from './PageNavigation';

export function PageNavigationBar(props: RequiredTestIdProps) {
  const ref = useAppContainerRef();

  const {scrollY} = useScroll({
    container: ref,
  });
  const [isVisible, show, hide] = useBoolean(true);
  const prevScroll = useRef(0);
  const currentScroll = useRef(0);

  const throttledScrollChange = useThrottledCallback(() => {
    const prev = prevScroll.current;
    const current = currentScroll.current;

    if (current - prev > SCROLL_DIFF_THRESHOLD && isVisible && current > TOP_THRESHOLD) {
      hide();
    }
    if (prev - current > SCROLL_DIFF_THRESHOLD && !isVisible) {
      show();
    }
    prevScroll.current = currentScroll.current;
  }, THROTTLE_TIME);

  useMotionValueEvent(scrollY, 'change', (scrollYValue) => {
    currentScroll.current = scrollYValue;

    if (currentScroll.current < TOP_THRESHOLD && !isVisible) {
      return show();
    }

    throttledScrollChange();
  });

  return (
    <Box position="fixed" top={12} width="100%" zIndex="PAGE_NAVIGATION" overflow="hidden">
      <motion.div
        variants={variants}
        animate={isVisible ? VARIANT_KEY.VISIBLE : VARIANT_KEY.HIDDEN}
        transition={transition}
      >
        <Box
          height={12}
          paddingHorizontal={3}
          position="relative"
          backgroundColor="general.white"
          borderBottom="1px solid"
          borderColor="general.separator"
        >
          <HStack width="100%" justify="space-between" align="center" height="100%">
            <PageNavigation data-testid={props['data-testid']} />
          </HStack>
        </Box>
      </motion.div>
    </Box>
  );
}

const THROTTLE_TIME = 250;
const TOP_THRESHOLD = 48;
const SCROLL_DIFF_THRESHOLD = 10;

const VARIANT_KEY = {
  VISIBLE: 'visible',
  HIDDEN: 'hidden',
};

const variants = {
  [VARIANT_KEY.VISIBLE]: {opacity: 1, y: 0, height: 'auto'},
  [VARIANT_KEY.HIDDEN]: {opacity: 0, y: -TOP_THRESHOLD, height: 0},
};

const transition = {
  ease: 'easeInOut',
  duration: 0.2,
};
