import {FormControl, FormField} from 'platform/components';

import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetDiscountGroupsQuery} from '@dms/api/metadaWarehouseDiscountGroup';
import i18n from '@dms/i18n';

import {TestIdProps} from 'shared';

import {MaterialDiscountFormType} from '../types/materialDiscountFormType';

interface DiscountGroupSelectProps extends TestIdProps {
  control: FormControl<MaterialDiscountFormType>;
  warehouseId?: string;
}

export function DiscountGroupSelect(props: DiscountGroupSelectProps) {
  const {discountGroupsOptions, isDiscountGroupsLoading} = useGetDiscountGroupsQuery(
    {warehouseId: props.warehouseId},
    {
      skip: isNilOrEmpty(props.warehouseId),
      selectFromResult: ({data, isLoading}) => ({
        isDiscountGroupsLoading: isLoading,
        discountGroupsOptions: isNilOrEmpty(props.warehouseId)
          ? []
          : data?.map((discountGroup) => ({
              label: discountGroup?.name,
              value: discountGroup?.id,
            })),
      }),
    }
  );

  return (
    <FormField
      control={props.control}
      name="discountGroupIds"
      type="multiChoice"
      menuInPortal
      options={discountGroupsOptions}
      isLoading={isDiscountGroupsLoading}
      label={i18n.t('entity.workshopCustomerGroup.labels.rabatGroup')}
      data-testid={props['data-testid']}
      isNotClearable
    />
  );
}
