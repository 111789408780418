import {isFeatureEnabled} from 'feature-flags';
import {openDialog} from 'platform/components';
import {useLocale} from 'platform/locale';

import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {useGetBusinessCaseQuery} from '@dms/api/businessCase';
import {getWorkspaceFromUri, urlBuilder, UrlBuilderConfig} from '@dms/api/core';
import {useGetParticipationQuery} from '@dms/api/participation';
import {EntityResourceIds} from '@dms/api/shared';
import {useGetUserQuery} from '@dms/api/user';
import {useGetVehicleQuery} from '@dms/api/vehicle';
import {environment} from '@dms/environment';
import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {
  createSearchParamsString,
  InvalidateOnlineOfferDialog,
  usePermissions,
  SendEmailOnlineOfferDialog,
} from '@dms/shared';

const {workspace} = getWorkspaceFromUri();

export const useBcToOnlineOffer = (businessCaseId: string) => {
  const {language} = useLocale();

  const {data: businessCaseData} = useGetBusinessCaseQuery({businessCaseId});

  const {data: vehicleData} = useGetVehicleQuery(
    {vehicleId: businessCaseData?.offers[0]?.saleVehicles?.[0]?.vehicleId ?? ''},
    {skip: isNilOrEmpty(businessCaseData?.offers[0]?.saleVehicles?.[0]?.vehicleId)}
  );

  const {data: participation} = useGetParticipationQuery({
    recordId: businessCaseId,
    resourceId: EntityResourceIds.businessCase,
  });

  const {data: ownerInfo} = useGetUserQuery(
    {id: participation?.owner?.userId || ''},
    {skip: isNilOrEmpty(participation?.owner?.userId)}
  );

  const [canSendOnlineOffer, canInvalidateOnlineOffer] = usePermissions({
    permissionKeys: ['sendOnlineOffer', 'invalidateOnlineOffer'],
    scopes: {
      sendOnlineOffer: {
        participation,
        branchId: businessCaseData?.branchId,
      },
      invalidateOnlineOffer: {
        participation,
        branchId: businessCaseData?.branchId,
      },
    },
  });

  const onlineOfferUri = urlBuilder({
    workspace,
    protocol: window.location.protocol,
    domain: environment.ONLINE_OFFER_DOMAIN,
    route: `/${businessCaseData?.offerId}`,
    searchParam: createSearchParamsString({
      lang: language,
      sellerEmail: ownerInfo?.email,
    }),
  });

  const canSendOffer =
    businessCaseData?.businessCaseState !== 'CONCEPT' &&
    businessCaseData?.businessCaseState !== 'UNSUCCESSFUL' &&
    isNilOrEmpty(businessCaseData?.offerId) &&
    isFeatureEnabled(featureFlags.BUSINESS_CASE_ONLINE_OFFER) &&
    canSendOnlineOffer;

  const isOnlineOfferSent =
    isNotNilOrEmpty(businessCaseData?.offerId) &&
    isFeatureEnabled(featureFlags.BUSINESS_CASE_ONLINE_OFFER);

  const canResendOffer = isOnlineOfferSent && canSendOnlineOffer;
  const canInvalidateOffer = isOnlineOfferSent && canInvalidateOnlineOffer;

  const sendOnlineOfferToCustomer = ({
    shouldCreateOffer = false,
  }: {
    shouldCreateOffer?: boolean;
  } = {}) => {
    // should not happen, just type guard check
    if (businessCaseData && participation?.owner?.userId && vehicleData && ownerInfo?.email) {
      const coverPhotoOfVehicle = vehicleData?.photos?.find((photo) => photo.isCoverPhoto);
      openDialog(
        <SendEmailOnlineOfferDialog
          {...(shouldCreateOffer
            ? {
                sellerEmail: ownerInfo.email,
                shouldCreateOffer: true,
              }
            : {
                onlineOfferUri,
                shouldCreateOffer: false,
              })}
          businessCaseData={businessCaseData}
          sellerId={participation.owner.userId}
          vehicleId={vehicleData.id}
          photoId={coverPhotoOfVehicle?.fileId}
        />,
        {
          title: i18n.t('entity.sendEmailOnlineOfferDialog.heading'),
          id: 'sendEmailOnlineOfferDialog',
          'data-testid': 'sendEmailOnlineOfferDialog',
          scrollBehavior: 'outside',
        }
      );
    }
  };

  const invalidateOnlineOfferToCustomer = () => {
    if (businessCaseData?.offerId) {
      openDialog(<InvalidateOnlineOfferDialog offerId={businessCaseData.offerId} />, {
        title: i18n.t('entity.invalidateOnlineOfferDialog.title'),
        id: 'invalidateOnlineOfferDialog',
        'data-testid': 'invalidateOnlineOfferDialog',
      });
    }
  };

  const openOffer = () => {
    if (isOnlineOfferSent) {
      const builderConfig: UrlBuilderConfig = {
        workspace,
        protocol: window.location.protocol,
        domain: environment.ONLINE_OFFER_DOMAIN,
        route: `/${businessCaseData?.offerId}`,
        searchParam: createSearchParamsString({
          lang: language,
          sellerEmail: ownerInfo?.email,
        }),
      };

      const digitalCertificateUrl = urlBuilder(builderConfig);

      window.open(digitalCertificateUrl, '_blank')?.focus();
    }
  };

  return {
    canSendOffer,
    canResendOffer,
    canInvalidateOffer,
    isOnlineOfferSent,
    actions: {
      sendOnlineOfferToCustomer,
      invalidateOnlineOfferToCustomer,
      openOffer,
    },
  };
};
