import * as yup from 'yup';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {TaskPriorityEnumSchema, TaskStateEnumSchema, TaskTypeEnumSchema} from '@dms/api/shared';
import i18n from '@dms/i18n';

const head = (arr: string[] | string) => (Array.isArray(arr) ? arr?.[0] : arr);

export const yupTaskFormSchema = yup.object().shape({
  title: yup.string().nullable().max(255).required(),
  priority: yup.string().oneOf(TaskPriorityEnumSchema.options).transform(head).required(),
  type: yup.string().oneOf(TaskTypeEnumSchema.options).transform(head).required(),
  state: yup.string().oneOf(TaskStateEnumSchema.options).required(),
  reportedBy: yup.string().nullable().required(),
  assignedTo: yup.string().nullable().required(),
  dueDate: yup.string().when(['dueFrom', 'dueTo'], {
    is: (dueFrom: string, dueTo: string) => isNotNilOrEmpty(dueFrom) || isNotNilOrEmpty(dueTo),
    then: yup.string().nullable().required(),
    otherwise: yup.string().nullable(),
  }),
  dueFrom: yup.string().nullable(),
  dueTo: yup.string().when('dueFrom', {
    is: isNotNilOrEmpty,
    then: yup
      .string()
      .nullable()
      .required()
      .test('not-same-time', i18n.t('entity.task.dueToNotSameTime'), function (dueTo) {
        const dueFrom = this.parent.dueFrom;
        return dueFrom !== dueTo;
      }),
    otherwise: yup.string().nullable(),
  }),
  description: yup.string().nullable(),
  relatedRecordId: yup
    .object()
    .shape({
      id: yup.string().required(),
      resourceId: yup.string().required(),
    })
    .nullable(),
});
