import {match} from 'ts-pattern';

import {useBusinessCaseContactCustomerViaEmailMutation} from '@dms/api/businessCase';
import {useInterestContactCustomerViaEmailMutation} from '@dms/api/interest';
import {EntityResourceIds} from '@dms/api/shared';
import {useSendEmailMutation} from '@dms/api/tenant';

import {DocumentItem} from '../types/DocumentItem';

interface useSendEmailProps {
  resourceId?: EntityResourceIds;
  recordId?: string;
}

export function useSendEmail(props: useSendEmailProps) {
  const [sendEmail, {isLoading: isLoadingSendEmail}] = useSendEmailMutation();
  const [sendEmailBusinessCase, {isLoading: isLoadingBusinessCase}] =
    useBusinessCaseContactCustomerViaEmailMutation();
  const [sendEmailInterest, {isLoading: isLoadingInterest}] =
    useInterestContactCustomerViaEmailMutation();

  const submitEmail = (
    sendAsPdf: boolean,
    subject: string,
    body: string,
    to: string[],
    documents?: DocumentItem[]
  ) => {
    const attachmentFiles = documents?.map((d: DocumentItem) => ({
      fileId: d.fileId,
      sendAsPdf,
    }));

    const args = {
      to,
      body: body.replaceAll('\n', '<br>'),
      subject,
      attachmentFiles,
    };

    return match(props.resourceId)
      .with(EntityResourceIds.interest, () =>
        sendEmailInterest({interestId: props.recordId ?? '', salesEmailRequestBody: args})
      )
      .with(EntityResourceIds.businessCase, () =>
        sendEmailBusinessCase({businessCaseId: props.recordId ?? '', salesEmailRequestBody: args})
      )
      .otherwise(() => sendEmail(args));
  };

  const isLoading = isLoadingSendEmail || isLoadingBusinessCase || isLoadingInterest;

  return {submitEmail, isLoading};
}
