import {DataStatus, showNotification} from 'platform/components';

import {useCallback, useMemo} from 'react';

import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetJbrConfigQuery, usePatchJbrConfigMutation} from '@dms/api/tenant';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {
  SettingsStackItemProps,
  SettingsStackItems,
} from '../../../components/SettingsStackItems/SettingsStackItems';

export type FormValues = {
  subscriptionKey: string;
};

export function JbrSettings() {
  const {
    data: jbrQueryData,
    isLoading: isJbrQueryLoading,
    isError: isJbrQueryError,
    refetch: jbrQueryRefetch,
  } = useGetJbrConfigQuery();

  const [patchJbrMutation] = usePatchJbrConfigMutation();

  const handleSwitchChange = useCallback(
    (mutation: typeof patchJbrMutation, enabled: boolean, refetch?: typeof jbrQueryRefetch) => {
      mutation({
        jbrConfigRequestBody: {
          enabled,
          ...(!enabled && {credentials: null}),
        },
      })
        .unwrap()
        .then(() => {
          showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
          refetch?.();
        })
        .catch(handleApiError);
    },
    []
  );

  const items = useMemo(
    () =>
      [
        {
          cyId: testIds.settings.jbr('link'),
          title: i18n.t('page.integrations.labels.jbrAuth'),
          url: settingsRoutes.jbrSettings,
          switchProps: {
            name: 'jbr-settings',
            // This condition was required by the original acceptance criteria
            // isDisabled: !jbrQueryData?.credentials?.subscriptionKey,
            isDisabled: false,
            value: jbrQueryData?.enabled,
            onChange: (enabled) => handleSwitchChange(patchJbrMutation, enabled, jbrQueryRefetch),
          },
        },
      ] as SettingsStackItemProps[],
    [jbrQueryData?.enabled, jbrQueryRefetch, handleSwitchChange, patchJbrMutation]
  );

  return (
    <DataStatus
      isLoading={isJbrQueryLoading}
      isError={isJbrQueryError}
      isEmpty={isNilOrEmpty(items)}
      minHeight={50}
    >
      <SettingsStackItems items={items} />
    </DataStatus>
  );
}
