import {Button, Dropdown, DropdownItem} from 'platform/components';
import {Center, Heading} from 'platform/foundation';

import {useDispatch} from 'react-redux';

import {setActiveBranchId} from '@dms/api/features';
import {SetUserSettingsItemApiArg} from '@dms/api/shared';
import {useGetCurrentUserInfoQuery, useSetUserSettingsItemMutation} from '@dms/api/user';
import i18n from '@dms/i18n';
import {useGetCurrentBranch, handleApiError, EMPTY_PLACEHOLDER} from '@dms/shared';

import {GtmEvent, pushGtmEvent, useBoolean} from 'shared';

import {HeaderButtonWrapper} from './HeaderButtonWrapper';
import {TopBarActionContainer} from './TopBarActionContainer';

export function BranchSelect() {
  const dispatch = useDispatch();
  const [isDropdownOpen, openDropdown, closeDropdown] = useBoolean();

  const {data: currentUserInfo} = useGetCurrentUserInfoQuery();
  const {activeBranchId} = useGetCurrentBranch();
  const [updateUserSettings, {isLoading: isChangingBranch}] = useSetUserSettingsItemMutation();

  const getBranchByActiveId =
    currentUserInfo?.branches?.find((b) => b.id === activeBranchId) ?? null;

  const handleBranchChange = (value: string) => {
    const arg: SetUserSettingsItemApiArg = {
      settingKey: 'active_branch',
      userSettingRequestBody: {value},
    };

    const gtmEvent: GtmEvent = {
      event: 'button_click',
      event_id: 'top_bar_branch_select',
      previous_value: {
        branchId: activeBranchId,
      },
      new_value: {
        branchId: value,
      },
    };

    updateUserSettings(arg)
      .unwrap()
      .then(() => {
        dispatch(setActiveBranchId(value));
        pushGtmEvent(gtmEvent);
        closeDropdown();
      })
      .catch(handleApiError);
  };

  if (!currentUserInfo?.branches || currentUserInfo?.branches?.length === 0) {
    return (
      <Heading
        data-testid="layout-header-myProfileAndSettings-activeBranch"
        headingLevel={2}
        size={4}
      >
        {getBranchByActiveId?.name ?? EMPTY_PLACEHOLDER}
      </Heading>
    );
  }

  return (
    <Dropdown
      closeOnSelect
      isOpen={isDropdownOpen}
      onOpen={openDropdown}
      onClose={closeDropdown}
      dropdownControl={
        <TopBarActionContainer>
          <Center height={12}>
            <HeaderButtonWrapper $isOpen={isDropdownOpen}>
              <Button
                aria-label="topBar"
                isLoading={isChangingBranch}
                variant="ghost"
                rightIcon={
                  isDropdownOpen ? 'navigation/arrow_drop_up' : 'navigation/arrow_drop_down'
                }
                title={`${i18n.t('entity.branch.labels.branch')}: ${getBranchByActiveId?.name ?? EMPTY_PLACEHOLDER}`}
              />
            </HeaderButtonWrapper>
          </Center>
        </TopBarActionContainer>
      }
      placement="bottom-start"
      isLazy
      lazyBehavior="unmount"
    >
      {currentUserInfo.branches.map((branch) => (
        <DropdownItem
          key={branch.id}
          label={branch.name}
          onClick={() => handleBranchChange(branch.id)}
        />
      ))}
    </Dropdown>
  );
}
