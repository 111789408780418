import {differenceInDays} from 'date-fns';

import {isNil} from 'ramda';

export function calculateSimpleRecurringCosts(
  endDate: string,
  startDate: string,
  cost: number
): number {
  if (isNil(endDate) || isNil(startDate) || isNil(cost)) {
    return 0;
  }

  const daysBetween = differenceInDays(endDate, startDate);
  return daysBetween * cost;
}
