import {Actions, openDialog, Separator} from 'platform/components';
import {Heading, HStack, Show, VStack, Space} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useCallback, useEffect, useState} from 'react';

import {isNil, isNotNil, mergeAll} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {useLazyGetCustomerV2Query} from '@dms/api/customer';
import {GetWarehousesResponse} from '@dms/api/metadaWarehouse';
import {
  GetDirectSaleResponse,
  useBulkDeleteDirectSaleItemsMutation,
  useBulkReturnDirectSaleSparePartsMutation,
  useLazyGetDirectSaleQuery,
  usePatchDirectSaleGetCurrentPriceMutation,
} from '@dms/api/metadaWarehouseDirectSale';
import {usePostIssueNotesMutation} from '@dms/api/metadaWarehouseIssueNote';
import {
  BasePartsRequest,
  usePatchPartsRequestCancelByOriginEntityMutation,
  usePostPartsRequestMutation,
} from '@dms/api/metadaWarehousePartsRequest';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {catchUnhandledDataGridActions, getNaturalPersonFullName, handleApiError} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId, useBoolean} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject, refreshDatagrid} from 'features/datagrid';

import {WAREHOUSE_DIALOG_IDS} from '../../../../constants/warehouseDialogIds';
import {WAREHOUSE_GRID_CODES} from '../../../../constants/warehouseGridCodes';
import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';
import {DirectSaleMaterialReturnModal} from '../../components/DirectSaleMaterialReturnModal';
import {BulkAssignMechanic} from './BulkAssignMechanic';
import {RequestTabEdit, RequestTabEditProps} from './RequestTabEdit';
import {RequestTabLabourModal} from './RequestTabLabourModal';
import {RequestTabMaterialModal} from './RequestTabMaterialModal';

type DirectSaleItemRowData = {
  id: string;
  type: {value: {key: string; label: string}};
  name: {value: string};
  directSaleId?: {value: string};
  articleId?: {value: string};
  warehouseId?: {value: string};
  unitPrice?: {value: {amount: number; currency: string}};
  amount?: {value: number};
  state: {value: {key: string; label: string}};
};

interface WorkItemsSegmentProps extends RequiredTestIdProps {
  directSale: GetDirectSaleResponse;
  isEditingDisabled: boolean;
  availableWarehouses: GetWarehousesResponse;
  isMaterialReturnModalVisible: boolean;
  onCloseMaterialReturnModal: VoidFunction;
}

export function WorkItemsSegment(props: WorkItemsSegmentProps) {
  const {directSaleId} = useWarehouseParams();

  const [isLabourModalVisible, openLabourModal, closeLabourModal] = useBoolean();
  const [isMaterialModalVisible, openMaterialModal, closeMaterialModal] = useBoolean();
  const [isMaterialReturnModalVisible, openMaterialReturnModal, closeMaterialReturnModal] =
    useBoolean();

  const [editDialogProps, setEditDialogProps] = useState<RequestTabEditProps | null>(null);

  const [getDirectSaleDetail] = useLazyGetDirectSaleQuery();
  const [getCustomer] = useLazyGetCustomerV2Query();
  const [createIssueNote] = usePostIssueNotesMutation();
  const [createPartsRequest] = usePostPartsRequestMutation();
  const [deleteDirectSalesItems] = useBulkDeleteDirectSaleItemsMutation();
  const [patchDirectSaleGetCurrentPrice] = usePatchDirectSaleGetCurrentPriceMutation();
  const [cancelPartsRequest] = usePatchPartsRequestCancelByOriginEntityMutation();
  const [bulkReturnSpareParts] = useBulkReturnDirectSaleSparePartsMutation();

  useEffect(() => {
    if (props.isMaterialReturnModalVisible) {
      openMaterialReturnModal();
    }
  }, [props.isMaterialReturnModalVisible, openMaterialReturnModal]);

  const mapDirectSaleItemToPartsRequest = (
    directSaleItem: DirectSaleItemRowData,
    neededAt?: string
  ): {directSaleId: string; body: BasePartsRequest} => ({
    directSaleId,
    body: {
      originEntityType: 'wrh_sale_item',
      requestItems: [
        {
          originEntityId: directSaleItem.id,
          articleId: directSaleItem.articleId?.value ?? '',
          originEntityHeaderType: 'direct-sale',
          originEntityHeaderId: directSaleId,
          itemNeededAtDate: neededAt,
          proposedSalePrice: directSaleItem.unitPrice?.value?.amount,
          quantity: directSaleItem.amount?.value ?? 0,
        },
      ],
    },
  });

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {directSaleId}]),
    [directSaleId]
  );

  const actionCallback: ActionCallback = async ({
    actionKey,
    rowId,
    rowData,
    refreshData,
    queryId,
    deselectAll,
  }) => {
    const id = isArray(rowId) ? rowId[0] : rowId;
    const directSaleItems = (isArray(rowData) ? rowData : [rowData]) as DirectSaleItemRowData[];

    await match(actionKey)
      .with('getCurrentPrices', async () => {
        await patchDirectSaleGetCurrentPrice({
          directSaleId,
          body: {directSaleItemId: rowId as string[]},
        })
          .unwrap()
          .then(refreshData)
          .catch(handleApiError);
      })
      .with('redirectDetail', 'edit', async () => {
        const directSaleDetail = getDirectSaleDetail({directSaleId}).unwrap().catch(handleApiError);

        await directSaleDetail.then((data) => {
          const isEditingDisabled =
            data?.state === 'LOCKED' || data?.state === 'ARCHIVED' || data?.state === 'CLOSED';

          setEditDialogProps({
            itemId: id,
            isEditingDisabled,
            dataQueryId: queryId,
            directSaleId,
            authorizationProfileId: data?.authorizationProfileId,
            onAfterSubmit: refreshData,
            onClose: () => setEditDialogProps(null),
            'data-testid': props['data-testid'] as string,
          });
        });
      })
      .with('delete', async () => {
        const itemsIds = directSaleItems.map((directSaleItem) => ({id: directSaleItem.id}));

        await deleteDirectSalesItems({
          directSaleId,
          body: {
            items: itemsIds,
          },
        })
          .unwrap()
          .then(refreshData)
          .catch(handleApiError);
      })
      .with('issue', async () => {
        const directSaleNumber = props.directSale?.number;
        const directSaleAuthorizationProfileId = props.directSale?.authorizationProfileId;

        if (isNil(directSaleNumber)) {
          throw new Error('directSaleNumber is not defined');
        }

        if (isNil(directSaleAuthorizationProfileId)) {
          throw new Error('directSaleAuthorizationProfileId is not defined');
        }

        const customerDataPromise = isNotNil(props.directSale?.customerId)
          ? getCustomer({customerId: props.directSale?.customerId ?? ''})
              .unwrap()
              .catch(handleApiError)
          : Promise.resolve(null);
        await customerDataPromise.then(async (customerData) => {
          await createIssueNote({
            directSaleId,
            body: {
              originDocId: directSaleId,
              originDocType: 'direct-sale',
              originDocNumber: directSaleNumber,
              authorizationProfileId: directSaleAuthorizationProfileId,
              customerName: getNaturalPersonFullName(customerData?.foundingPerson) ?? undefined,
              items: directSaleItems.map((directSaleItem) => ({
                warehouseId: directSaleItem.warehouseId?.value ?? '',
                originItemId: directSaleItem.id,
                articleId: directSaleItem.articleId?.value ?? '',
                requestItemOriginEntityType: 'wrh_sale_item',
              })),
            },
          })
            .unwrap()
            .then(refreshData)
            .catch(handleApiError);
        });
      })
      .with('requestCancellation', async () => {
        const originEntityIds = directSaleItems.map((directSaleItem) => directSaleItem.id);

        await cancelPartsRequest({
          directSaleId,
          body: {
            originEntityId: originEntityIds,
          },
        })
          .unwrap()
          .then(refreshData)
          .catch(handleApiError);
      })
      .with('request', async () => {
        const directSaleItem = directSaleItems[0];

        await getDirectSaleDetail({directSaleId})
          .unwrap()
          .then((directSale) =>
            createPartsRequest(
              mapDirectSaleItemToPartsRequest(directSaleItem, directSale?.neededAt)
            )
          )
          .then(refreshData)
          .catch(handleApiError);
      })
      .with('return', async () => {
        const ids = isArray(rowId) ? rowId : [rowId];

        await bulkReturnSpareParts({directSaleId, body: {directSaleItemId: ids}})
          .unwrap()
          .then(deselectAll)
          .then(openMaterialReturnModal)
          .catch(handleApiError);
      })
      .with('assignMechanic', () => {
        const ids = isArray(rowId) ? rowId : [rowId];

        const authorizationProfileId = props.directSale?.authorizationProfileId;

        if (isNil(authorizationProfileId)) {
          throw new Error('Cannot assign mechanic. The authorization profile id is missing.');
        }

        openDialog(
          <BulkAssignMechanic
            directSaleId={directSaleId}
            authorizationProfileId={authorizationProfileId}
            rowIds={ids}
            onSuccess={() => {
              refreshData();
              deselectAll();
            }}
            data-testid={suffixTestId('dialogs.assignMechanic', props)}
          />,
          {
            id: WAREHOUSE_DIALOG_IDS.bulkAssignMechanicDialog,
            title: i18n.t('entity.warehouse.labels.assignMechanic'),
            size: 'small',
            scrollBehavior: 'outside',
          }
        );
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  const handleCloseMaterialReturnModal = () => {
    closeMaterialReturnModal();
    props.onCloseMaterialReturnModal();
    refreshDatagrid(WAREHOUSE_GRID_CODES.warehouseDirectSaleItem);
  };

  return (
    <VStack height="100%">
      <RequestTabEdit {...(editDialogProps as RequestTabEditProps)} />

      <Separator />

      <HStack justify="space-between">
        <Heading size={4}>{i18n.t('entity.warehouse.labels.workItems')}</Heading>
        <Actions
          actions={[
            {
              type: 'button',
              title: i18n.t('general.actions.addWork'),
              variant: 'ghostLink',
              leftIcon: 'content/add_circle',
              onClick: openLabourModal,
              isDisabled: props.isEditingDisabled,
              'data-testid': suffixTestId('requestAction.addLabour', props),
            },
            {
              type: 'button',
              title: i18n.t('general.actions.addMaterial'),
              variant: 'ghostLink',
              leftIcon: 'content/add_circle',
              onClick: openMaterialModal,
              isDisabled: props.isEditingDisabled,
              'data-testid': suffixTestId('requestAction.addMaterial', props),
            },
          ]}
          data-testid={suffixTestId('listActions', props)}
        />
      </HStack>

      <Space vertical={4} />

      <DataGrid
        gridCode={WAREHOUSE_GRID_CODES.warehouseDirectSaleItem}
        actionCallback={actionCallback}
        queryModifier={queryModifier}
        emptyState={{
          headline: i18n.t('entity.warehouse.notifications.noWorkItems'),
          subheadline: i18n.t('entity.warehouse.notifications.addWorkItems'),
        }}
        data-testid={suffixTestId('requestedItems', props)}
      />

      <Show when={isLabourModalVisible}>
        <RequestTabLabourModal
          directSaleId={directSaleId}
          authorizationProfileId={props.directSale.authorizationProfileId}
          onDiscard={closeLabourModal}
          data-testid={testIds.warehouse.directSalesDetailOverview('dialogs.addLabour')}
        />
      </Show>

      <Show when={isMaterialModalVisible}>
        <RequestTabMaterialModal
          directSaleId={directSaleId}
          availableWarehouses={props.availableWarehouses}
          authorizationProfileId={props.directSale?.authorizationProfileId}
          onDiscard={closeMaterialModal}
          data-testid={testIds.warehouse.directSalesDetailOverview('dialogs.addMaterial')}
        />
      </Show>

      <Show when={isMaterialReturnModalVisible}>
        <DirectSaleMaterialReturnModal
          directSaleId={directSaleId}
          authorizationProfileId={props.directSale?.authorizationProfileId}
          onClose={handleCloseMaterialReturnModal}
          data-testid={testIds.warehouse.directSalesDetailOverview('dialogs.returnMaterial')}
        />
      </Show>
    </VStack>
  );
}
