import {Card, Separator} from 'platform/components';
import {Grid} from 'platform/foundation';

import {FC, useEffect, Fragment} from 'react';
import {useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {OnChangeValue} from 'react-select';

import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {
  Dropdown,
  useFields,
  useFieldArray,
  FieldLabel,
  selectIdentityCardTypes,
  CustomerPersonIdentificationTypeCodeEnum,
  DeputyContactPerson,
  IdentityCardType,
} from '@dms/teas';

import {AddPersonId, RemoveButton, RemovePersonID} from '../styles';

export interface DeputyPersonProps {
  defaultValues?: DeputyContactPerson;
  contactPerson?: DeputyContactPerson[];
  handleSelectContactPerson?: (person: DeputyContactPerson, index: number) => void;
  handleRemoveDeputyPerson: (index: number) => () => void;
  onChangeContactPerson?: (contactPerson: DeputyContactPerson) => void;
  index: number;
  length: number;
  singlePersonIdentification?: boolean;
}

const mapIdentityCardTypesToPersonIdTypes: {
  [key: string]: CustomerPersonIdentificationTypeCodeEnum;
} = {
  ID_OTHER: CustomerPersonIdentificationTypeCodeEnum.ID_TYPE_OTHER,
  ID_IDENTITY_CARD: CustomerPersonIdentificationTypeCodeEnum.ID_TYPE_ID,
  ID_PASSPORT: CustomerPersonIdentificationTypeCodeEnum.ID_TYPE_PASSPORT,
  ID_DRIVER_LICENSE: CustomerPersonIdentificationTypeCodeEnum.ID_TYPE_DRIVING_LICENSE,
};

export const DeputyPersonForm: FC<DeputyPersonProps> = ({
  defaultValues,
  contactPerson,
  handleSelectContactPerson,
  onChangeContactPerson,
  handleRemoveDeputyPerson,
  length,
  index,
  singlePersonIdentification,
}) => {
  // Note: not so nice solution, but since the original EP for identity card types was deleted there is no other solution for now
  const identityCardTypes = useSelector(selectIdentityCardTypes)
    ?.filter((id) => Object.keys(mapIdentityCardTypesToPersonIdTypes).includes(id.code))
    .map((id) => ({
      ...id,
      code: mapIdentityCardTypesToPersonIdTypes[id.code],
    }));

  const form = useForm<Record<string, any>>({
    mode: 'onTouched',
    reValidateMode: 'onBlur',
    defaultValues,
  });

  const {reset, control, getValues, watch, setValue} = form;

  const getField = useFields<Record<string, any>>(form);

  const getPersonIdentificationField = useFields<
    Record<string, any>,
    ['personIdentifications', number]
  >(form);

  const {
    append: appendpersonIdentification,
    remove: removePersonIdentification,
    reset: resetPersonIdentification,
    fields: personIdentificationFields,
  } = useFieldArray<Record<string, string>[]>(control, setValue, 'personIdentifications', true);

  const currentDeputyPerson = watch();

  const handleChangeDeputyPerson = (values: DeputyContactPerson) => {
    if (onChangeContactPerson) {
      onChangeContactPerson(values);
    }
  };

  const handleRemovePersonIdentification = (index: number) => () => {
    removePersonIdentification(index);

    if (onChangeContactPerson) {
      const values = getValues() as any;
      values.personIdentifications.splice(index, 1);
      onChangeContactPerson(values);
    }
  };

  const onSelectContactPerson = (value: OnChangeValue<DeputyContactPerson, false>) => {
    const personId = value?.personIdentifications;

    reset(emptyPropertiesToString(value));
    resetPersonIdentification((personId?.length ? value?.personIdentifications : {}) as unknown[]);

    if (handleSelectContactPerson) {
      handleSelectContactPerson(value as DeputyContactPerson, index);
    }
  };

  const getContactPersonLabel = (contact: DeputyContactPerson) =>
    `${contact.firstName || ''} ${contact.lastName || ''}`;

  useEffect(() => {
    reset(emptyPropertiesToString(defaultValues));
  }, [defaultValues?.uuid]);

  return (
    <Card
      variant="inlineGrey"
      title={
        currentDeputyPerson?.firstName || currentDeputyPerson?.lastName
          ? `${currentDeputyPerson?.firstName || ''} ${currentDeputyPerson?.lastName || ''}`
          : i18n.t('page.businessCase.labels.newDeputyPerson')
      }
      isExpandable
    >
      {!!contactPerson?.length && (
        <div>
          <div>
            <FieldLabel>{i18n.t('general.labels.selectFromList')}</FieldLabel>
            <Dropdown<any>
              options={contactPerson}
              onChange={onSelectContactPerson}
              getOptionLabel={getContactPersonLabel}
              value={defaultValues}
            />
          </div>
          <Separator spacing={5} />
        </div>
      )}
      <Grid columns={2} spacing={4}>
        {getField.hidden({
          name: ['uuid'],
        })}
        {getField.text({
          name: ['firstName'],
          onBlur: handleChangeDeputyPerson,
          inputProps: {
            required: true,
            label: i18n.t('entity.person.labels.firstName'),
          },
        })}
        {getField.text({
          name: ['lastName'],
          onBlur: handleChangeDeputyPerson,
          inputProps: {
            required: true,
            label: i18n.t('entity.person.labels.lastName'),
          },
        })}
        {getField.date({
          name: ['dateOfBirth'],
          onChange: handleChangeDeputyPerson,
          inputProps: {
            fullWidth: true,
            label: i18n.t('entity.person.labels.dateOfBirth'),
          },
        })}
        {getField.text({
          name: ['personalNumber'],
          onBlur: handleChangeDeputyPerson,
          inputProps: {
            label: i18n.t('entity.person.labels.birthNumber'),
          },
        })}
        {personIdentificationFields?.map((_, index) => (
          <Fragment key={_.uuid}>
            {getPersonIdentificationField.hidden({
              name: ['personIdentifications', index, 'uuid'],
            })}
            {getPersonIdentificationField.hidden({
              name: ['personIdentifications', index, 'note'],
              defaultValue: null,
            })}
            {getPersonIdentificationField.dropdown({
              name: ['personIdentifications', index, 'typeCode'],
              options: identityCardTypes,
              getOptionLabel: (idType) => (idType as IdentityCardType).name,
              getOptionValue: (idType) => (idType as IdentityCardType).code,
              onChange: handleChangeDeputyPerson,
              dropdownProps: {
                label: i18n.t('entity.person.labels.personalIdType'),
              },
            })}
            {getPersonIdentificationField.text({
              name: ['personIdentifications', index, 'code'],
              onBlur: handleChangeDeputyPerson,
              inputProps: {
                label: i18n.t('entity.person.labels.idNumber'),
              },
            })}
            {getPersonIdentificationField.date({
              name: ['personIdentifications', index, 'validTill'],
              onChange: handleChangeDeputyPerson,
              inputProps: {
                fullWidth: true,
                label: i18n.t('general.labels.validUntil'),
              },
            })}
            {getPersonIdentificationField.text({
              name: ['personIdentifications', index, 'identificationIssuer'],
              onBlur: handleChangeDeputyPerson,
              inputProps: {
                label: i18n.t('general.labels.issuedBy'),
              },
            })}
            {!singlePersonIdentification && personIdentificationFields.length > 1 && (
              <RemovePersonID type="button" onClick={handleRemovePersonIdentification(index)}>
                {i18n.t('page.businessCase.actions.removePersonIdentification')}
              </RemovePersonID>
            )}
          </Fragment>
        ))}
        {!singlePersonIdentification && personIdentificationFields?.length > 0 && (
          <AddPersonId type="button" onClick={() => appendpersonIdentification([{}])}>
            {i18n.t('entity.person.actions.addPersonalId')}
          </AddPersonId>
        )}
      </Grid>
      {length > 1 && (
        <RemoveButton
          data-testid={testIds.settings.tenant(`deputy-person-${index}-delete-button`)}
          type="button"
          onClick={handleRemoveDeputyPerson(index)}
        >
          {i18n.t('page.businessCase.actions.removeDeputyPerson')}
        </RemoveButton>
      )}
    </Card>
  );
};

const isempty = (obj: Record<string, unknown>, key: string) =>
  obj[key] == null || obj[key] === undefined;

type EmptyDynamicType<T> = {[k: string]: T};
type EmptyPropertiesToStringFunc = (
  value?: {[key: string]: unknown} | null
) => EmptyDynamicType<unknown>;

const emptyPropertiesToString: EmptyPropertiesToStringFunc = (value) => {
  if (typeof value !== 'object' || !value) {
    return {};
  }

  const object = {...value};

  const objKeys = Object.keys(object);

  objKeys.forEach((key) => {
    if (isempty(object, key)) {
      object[key] = '';
    }
    const keyVal = object[key] as EmptyDynamicType<string>[];
    if (Array.isArray(keyVal)) {
      object[key] = keyVal.map((item) => emptyPropertiesToString(item));
    } else if (typeof keyVal == 'object') {
      object[key] = emptyPropertiesToString(keyVal as EmptyDynamicType<string>);
    }
  });
  return object;
};
