import {isFeatureEnabled} from 'feature-flags';
import {Card, Separator, DataStatus, Alert} from 'platform/components';
import {
  Box,
  Display,
  HStack,
  VStack,
  Text,
  Show,
  getCssSize,
  Clickable,
  Hide,
} from 'platform/foundation';
import styled from 'styled-components';

import {Fragment} from 'react';

import {isNil} from 'ramda';

import {
  VehicleStockOverviewResponseBody,
  useGetVehicleStockOverviewQuery,
} from '@dms/api/dashboard';
import {useGetCurrentUserInfoQuery} from '@dms/api/user';
import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {vehiclesRoutes} from '@dms/routes';
import {useDataQuery, useGetCurrentBranch} from '@dms/shared';

import {
  Nullish,
  TestIdProps,
  composePath,
  parseDate,
  suffixTestId,
  useNavigate,
  isLastIndexInArray,
} from 'shared';

import {useRedirectFromOpportunities} from '../hooks/useRedirectFromOpportunities/useRedirectFromOpportunities';

interface VehicleWarehouseProps extends TestIdProps {
  branchId: string | undefined;
  isActiveBranch: boolean;
}

const FROM_DATE = '2000-01-01';
const DAYS_TO_EXPIRE = 30;

export function VehicleWarehouse(props: VehicleWarehouseProps) {
  const navigate = useNavigate();
  const {activeBranchId} = useGetCurrentBranch();
  const {data: user} = useGetCurrentUserInfoQuery();

  const [handleRedirectFromOpportunity, isRedirecting] = useRedirectFromOpportunities({
    branchId: props.branchId,
    ownerSegment: null,
    typeSegment: null,
  });

  const {
    data: vehicleStockOverview,
    isLoading: isVehicleStockOverviewLoading,
    isError: isVehicleStockOverviewError,
  } = useGetVehicleStockOverviewQuery(
    {
      branchId: props.branchId,
    },
    {refetchOnMountOrArgChange: true}
  );

  const redirectGridCode =
    (isFeatureEnabled(featureFlags.SALES_360_VEHICLES) &&
      !isFeatureEnabled(featureFlags.REPLACE_VEHICLE_DG_WITH_OPENSEARCH)) ||
    isFeatureEnabled(featureFlags.REPLACE_VEHICLE_DG_WITH_OPENSEARCH)
      ? 'vehicle-opensearch'
      : 'vehicle';

  const branchForDataQuery = props.isActiveBranch
    ? [activeBranchId]
    : (user?.branches?.map((branch) => branch.id) ?? []);

  const {queryParams: queryParamsValidUntil} = useDataQuery(redirectGridCode, {
    // Filter vehicles with MOT expiration this month
    filters: {
      validUntil: {relativeInputKey: 'NEXT_N_DAYS', relativeInputValue: DAYS_TO_EXPIRE},
      saleState: ['for-sale', 'selling'],
      archived: ['0'],
      branch: branchForDataQuery,
    },
  });

  const yesterdaysDate = ((d) =>
    parseDate(d.setDate(d.getDate() - 1))
      .toISOString()
      .split('T')[0])(new Date());

  const {queryParams: queryParamsExpired} = useDataQuery(redirectGridCode, {
    // Filter available vehicles for sale with expired MOT
    filters: {
      validUntil: {from: FROM_DATE, to: yesterdaysDate},
      saleState: ['for-sale', 'selling'],
      archived: ['0'],
      branch: branchForDataQuery,
    },
    order: [{columnKey: 'createdAt', order: 'DESC'}],
  });

  const onWarningAlertClick = (queryParams?: string | Record<string, string | Nullish>) => {
    navigate(composePath(vehiclesRoutes.home, {queryParams}));
  };

  const columns = getColumns(vehicleStockOverview);
  const numberOfCarsWhichMotExpirating = vehicleStockOverview?.motExpirationWarning ?? 0;
  const numberOfCarsWhichMotExpired = vehicleStockOverview?.motExpiredWarning ?? 0;

  return (
    <Card title={i18n.t('page.homepage.labels.vehicleWarehouse')} hasSeparator={false}>
      <Box>
        <DataStatus
          minHeight="100%"
          isLoading={isVehicleStockOverviewLoading}
          isError={isVehicleStockOverviewError}
        >
          <VStack spacing={4}>
            <HStack>
              {columns.map((column, index) => (
                <Fragment key={`${column.id}_${column.label}`}>
                  <HStack basis="100%" align="stretch" spacing={4}>
                    <HoverBox>
                      <Clickable
                        onClick={() => handleRedirectFromOpportunity(column.id)}
                        isDisabled={isRedirecting}
                      >
                        <VStack spacing={4} justify="space-between" grow={1}>
                          <Text color="secondary" size="xSmall">
                            {column.label}
                          </Text>
                          <Display size={1}>{String(column.value)}</Display>
                        </VStack>
                      </Clickable>
                    </HoverBox>
                  </HStack>
                  <Hide when={isLastIndexInArray(columns, index)}>
                    <Separator spacing={4} orientation="vertical" />
                  </Hide>
                </Fragment>
              ))}
            </HStack>
            <Show when={numberOfCarsWhichMotExpirating > 0}>
              <Alert
                variant="warning"
                type="inline"
                title={i18n.t('page.homepage.vehicleWarehouse.labels.motExpiresIn30Days')}
                message={i18n.t('page.homepage.vehicleWarehouse.labels.carCount', {
                  count: numberOfCarsWhichMotExpirating,
                })}
                hyperlinks={[
                  {
                    title: i18n.t('page.homepage.labels.view'),
                    onClick: () => onWarningAlertClick(queryParamsValidUntil),
                  },
                ]}
                data-testid={suffixTestId('motExpiresIn30DaysAlert', props)}
              />
            </Show>
            <Show when={numberOfCarsWhichMotExpired > 0}>
              <Alert
                variant="error"
                type="inline"
                title={i18n.t('page.homepage.vehicleWarehouse.labels.motExpired')}
                message={i18n.t('page.homepage.vehicleWarehouse.labels.carCount', {
                  count: numberOfCarsWhichMotExpired,
                })}
                hyperlinks={[
                  {
                    title: i18n.t('page.homepage.labels.view'),
                    onClick: () => onWarningAlertClick(queryParamsExpired),
                  },
                ]}
                data-testid={suffixTestId('motExpiredAlert', props)}
              />
            </Show>
          </VStack>
        </DataStatus>
      </Box>
    </Card>
  );
}

function getColumns(vehicleStockOverview: VehicleStockOverviewResponseBody | Nullish) {
  if (isNil(vehicleStockOverview)) {
    return [];
  }

  return [
    {
      label: i18n.t('page.homepage.vehicleWarehouse.labels.warehouseTotal'),
      value: vehicleStockOverview.totalForSale,
      id: 'warehouseTotal',
    },
    {
      label: i18n.t('page.homepage.vehicleWarehouse.labels.inSale'),
      value: vehicleStockOverview.selling,
      id: 'inSale',
    },
    {
      label: i18n.t('page.homepage.vehicleWarehouse.labels.inBuying'),
      value: vehicleStockOverview.buying,
      id: 'inBuying',
    },
  ] as const;
}

const HoverBox = styled.div`
  padding: ${getCssSize(2)};
  width: 100%;
  border-radius: ${({theme}) => theme.radii.medium};
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: ${({theme}) => theme.colors.palettes.neutral[40][40]};
  }
`;
