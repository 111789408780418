import {Parameters, Rank} from 'platform/components';
import {Align, Box, Heading, HStack, Image, Space, Text} from 'platform/foundation';
import {useFormatCurrency, useFormatNumber} from 'platform/locale';

import {isNil} from 'ramda';

import {SourcingVehicleDetailResponseBody} from '@dms/api/sourcing';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

import {CountryFlag} from '../../../../../../../components/CountryFlag/CountryFlag';
import {detailFirstLine} from '../../../../../../../utils/detailFirstLine';
import {detailSecondLine} from '../../../../../../../utils/detailSecondLine';
import {getVehiclePrice} from '../../../../../../../utils/getVehiclePrice';
import {getVehicleSellerCountry} from '../../../../../../../utils/getVehicleSellerCountry';

export interface PriceMapTooltipProps {
  active?: boolean;
  isInComparison?: boolean;
  payload?: {payload?: SourcingVehicleDetailResponseBody}[];
  currentVehicle?: SourcingVehicleDetailResponseBody;
}

export function PriceMapTooltip({
  active,
  payload,
  currentVehicle,
  isInComparison = false,
}: PriceMapTooltipProps) {
  const formatNumber = useFormatNumber();
  const formatCurrency = useFormatCurrency();

  if (!active || isNil(payload?.[0]?.payload)) {
    return null;
  }

  const [{payload: vehicle}] = payload;
  const {summary} = vehicle;
  const vehicleData = vehicle.sourcingVehicle;
  const isCurrentVehicle =
    isNil(currentVehicle) || currentVehicle?.sourcingVehicle?.id === vehicleData?.id;
  const vehiclePrice = getVehiclePrice({vehicle});
  const vehicleCurrency = vehicle.sourcingVehicle?.price?.currency?.key;

  const formattedVehiclePrice = vehicleCurrency
    ? formatCurrency(vehiclePrice, vehicleCurrency)
    : formatNumber(vehiclePrice, 0);
  const sellerCountry = getVehicleSellerCountry(vehicleData);

  return (
    <Box
      height={26}
      minWidth={140}
      overflow="hidden"
      borderRadius="small"
      backgroundColor="palettes.white.10.100"
      boxShadow="elevation_1"
      data-testid="price-map-tooltip"
    >
      <HStack>
        <Image
          src={vehicleData?.thumbnailUrl?.full}
          alt={vehicleData?.make?.translation}
          width={35}
          height={26}
          fit="cover"
        />

        <Box flex={1} paddingHorizontal={3} paddingVertical={2}>
          <HStack align="center" spacing={2}>
            <Heading size={4}>{detailFirstLine(vehicleData)}</Heading>
            {sellerCountry && <CountryFlag country={sellerCountry} />}
          </HStack>

          <Parameters
            parameters={detailSecondLine(formatNumber, vehicleData)}
            color="secondary"
            data-testid={testIds.sourcing.vehicleDetail('priceMapTooltip-Parameters')}
          />

          <Space vertical={2} />

          <HStack>
            <Box flex={2}>
              <HStack>
                <Box flex={7}>
                  <Text size="xSmall" color="secondary">
                    {isInComparison
                      ? i18n.t('entity.vehicle.labels.adPrice')
                      : isCurrentVehicle
                        ? i18n.t('entity.vehicle.labels.retailPrice')
                        : i18n.t('entity.vehicle.labels.originalPrice')}
                  </Text>
                </Box>
                <Box flex={5} data-testid="price-map-tooltip-retail-price">
                  <Heading size={6} alternative isSingleLine>
                    {formattedVehiclePrice}
                  </Heading>
                </Box>
              </HStack>

              {'similarity' in vehicleData &&
                typeof vehicleData?.similarity?.score === 'number' && (
                  <>
                    <Space vertical={1} />
                    <HStack>
                      <Box flex={7}>
                        <Text size="xSmall" color="secondary">
                          {i18n.t('entity.vehicle.labels.carSimilarity')}
                        </Text>
                      </Box>
                      <Box flex={5} data-testid="price-map-tooltip-car-similarity">
                        <Heading size={6} alternative>
                          {`${String(Math.round(vehicleData?.similarity?.score * 100))} %`}
                        </Heading>
                      </Box>
                    </HStack>
                  </>
                )}
            </Box>

            <Box flex={1}>
              <HStack>
                <Box flex={1}>
                  <Align right>
                    <Text size="xSmall" color="secondary">
                      {i18n.t('entity.vehicle.labels.features')}
                    </Text>
                  </Align>
                </Box>
                <Box flex={1}>
                  <HStack justify="flex-end">
                    <Rank
                      percentage={(summary?.rank ?? 0) * 100}
                      data-testid={testIds.sourcing.vehicleDetail('priceMapTooltip-Rank')}
                    />
                  </HStack>
                </Box>
              </HStack>
            </Box>
          </HStack>
        </Box>
      </HStack>
    </Box>
  );
}
