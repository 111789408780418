import {Button, ButtonGroup, Card, Separator} from 'platform/components';
import {Grid, GridItem} from 'platform/foundation';
import {DeepPartial} from 'utility-types';
import {array, mixed, object, string} from 'yup';

import {FC} from 'react';
import {FormSpy} from 'react-final-form';

import {isNilOrEmpty} from 'ramda-adjunct';

import {
  AddressRequestBodyV2,
  BusinessInfoRequestBody,
  CustomerBankAccountRequestBody,
  IdentityCardResponseBodyV2,
  LegalForm,
  PersonRequestBody,
  PersonResponseBodyV2,
  useGetCustomerV2Query,
} from '@dms/api/customer';
import i18n from '@dms/i18n';
import {
  $BankAccountRequestBody,
  $BusinessInfoRequestBody,
  CheckoutContractInformationBody,
  Form,
  IdentityCardData,
  LegalFormEnum,
  WithValidationErrors,
} from '@dms/teas';

import {suffixTestId, TestIdProps} from 'shared';

import {BusinessInfoForm} from '../../ContractInformation/BusinessInfoForm';
import {
  CheckoutPersonContractInformationForm,
  CheckoutPersonContractInformationFormState,
} from './CheckoutPersonContractInformationForm';

export type CheckoutContractInformationFormState = {
  id: string | null;
  businessInfo: BusinessInfoRequestBody | null;
  legalForm: LegalForm;
  bankAccounts: CustomerBankAccountRequestBody[];
  personInfo: CheckoutPersonContractInformationFormState;
};

export interface CheckoutContractInformationFormProps {
  customerId: string;
  contractIndex?: number;
  checkoutContractInformation?: CheckoutContractInformationBody;
  onSubmit: (
    requestBody: CheckoutContractInformationFormState
  ) => Promise<WithValidationErrors<void>>;
  handleDiscard?: () => void;
  handlePersonSubmit: (
    requestBody: PersonRequestBody,
    personId: string | null
  ) => Promise<PersonResponseBodyV2 | null>;
  handleAddressSubmit: (
    addressData: AddressRequestBodyV2,
    addressId?: string | null
  ) => Promise<string | null>;
  handleIdentityCardSubmit: (
    person: PersonResponseBodyV2
  ) => (
    cardData: IdentityCardData,
    id: string | null
  ) => Promise<IdentityCardResponseBodyV2 | null>;
  permanentPersonId?: string;
  isAdditionalCustomer?: boolean;
}

export const CheckoutContractInformationForm: FC<
  CheckoutContractInformationFormProps & TestIdProps
> = ({
  contractIndex,
  checkoutContractInformation,
  onSubmit,
  handleDiscard,
  handlePersonSubmit,
  handleAddressSubmit,
  handleIdentityCardSubmit,
  permanentPersonId,
  ...props
}) => {
  const {data: customer} = useGetCustomerV2Query(
    {customerId: props.customerId},
    {skip: isNilOrEmpty(props.customerId)}
  );

  return (
    <Form<CheckoutContractInformationFormState, CheckoutContractInformationBody>
      formId={props['data-testid']}
      onSubmit={onSubmit}
      getFormValues={getFormValues}
      schema={schema}
      initialValues={checkoutContractInformation}
      defaultValues={{
        legalForm: LegalFormEnum.NATURAL_PERSON,
      }}
      keepDirtyOnReinitialize={false}
      render={({Field, Condition, handleSubmit}) => (
        <>
          <Grid columns={1}>
            <GridItem>
              {!checkoutContractInformation?.customerContractInformation?.id && (
                <Grid columns={1}>
                  <Field
                    name="legalForm"
                    as="chips"
                    options={[
                      {
                        value: LegalFormEnum.NATURAL_PERSON,
                        label: i18n.t('entity.person.labels.physicalPerson'),
                      },
                      {
                        value: LegalFormEnum.SELF_EMPLOYED,
                        label: i18n.t('entity.customer.labels.isSelfEmployed'),
                      },
                      {
                        value: LegalFormEnum.LEGAL_ENTITY,
                        label: i18n.t('general.labels.company'),
                      },
                    ]}
                  />
                </Grid>
              )}
              <Grid columns={1}>
                <GridItem>
                  <Condition when="legalForm" not={LegalFormEnum.NATURAL_PERSON}>
                    <Field<BusinessInfoRequestBody>
                      name="businessInfo"
                      component={({input}) => (
                        <Card
                          data-testid={suffixTestId('businessInfo', props)}
                          variant="inlineGrey"
                        >
                          <BusinessInfoForm
                            data-testid={suffixTestId('businessInfo', props)}
                            compactSelect
                            businessInfo={input.value}
                            onSubmit={input.onChange}
                            handleAddressSubmit={handleAddressSubmit}
                            address={customer?.addresses}
                            selectedBusinessAddress={
                              checkoutContractInformation?.customerContractInformation?.businessInfo
                                ?.businessAddress
                                ? {
                                    id: checkoutContractInformation?.customerContractInformation
                                      .businessInfo.businessAddress.id,
                                    type: checkoutContractInformation?.customerContractInformation
                                      .businessInfo.businessAddress.addressData.type,
                                    invalid:
                                      checkoutContractInformation?.customerContractInformation
                                        .businessInfo.businessAddress.addressData.invalid,
                                    address: {
                                      street: '',
                                      descriptiveNumber: null,
                                      orientationNumber: null,
                                      city:
                                        checkoutContractInformation?.customerContractInformation
                                          .businessInfo.businessAddress.addressData.city ?? '',
                                      zip:
                                        checkoutContractInformation?.customerContractInformation
                                          .businessInfo.businessAddress.addressData.postalCode ??
                                        '',
                                      country:
                                        checkoutContractInformation?.customerContractInformation
                                          .businessInfo.businessAddress.addressData.countryCode ??
                                        '',
                                      addressComplement: null,
                                      prefix: null,
                                      district: null,
                                      state: null,
                                    },
                                  }
                                : null
                            }
                            saveOnChange
                          />
                        </Card>
                      )}
                    />
                  </Condition>
                  <Condition when="legalForm" is={LegalFormEnum.NATURAL_PERSON}>
                    <Field<CheckoutPersonContractInformationFormState>
                      name="personInfo"
                      component={({input}) => (
                        <>
                          <CheckoutPersonContractInformationForm
                            customerId={props.customerId}
                            data-testid={suffixTestId('personInfo', props)}
                            contractIndex={contractIndex}
                            saveOnChange
                            showPersonData={
                              !!checkoutContractInformation?.customerContractInformation?.id
                            }
                            handlePersonSubmit={handlePersonSubmit}
                            handleAddressSubmit={handleAddressSubmit}
                            handleIdentityCardSubmit={handleIdentityCardSubmit}
                            handleSubmit={input.onChange}
                            selectedPerson={
                              checkoutContractInformation?.customerContractInformation?.person
                            }
                            selectedIdentityCards={
                              checkoutContractInformation?.selectedIdentityCards
                            }
                            person={customer?.persons}
                            permanentPersonId={permanentPersonId}
                            address={customer?.addresses}
                            initialValues={input.value}
                            isAdditionalCustomer={props.isAdditionalCustomer}
                          />
                        </>
                      )}
                    />
                  </Condition>
                </GridItem>
              </Grid>
            </GridItem>
          </Grid>
          <Separator />
          <FormSpy
            subscription={{submitting: true}}
            render={({submitting}) => (
              <ButtonGroup align="right">
                <Button
                  data-testid={suffixTestId('discard', props)}
                  variant="secondary"
                  onClick={handleDiscard}
                  title={i18n.t('general.actions.discard')}
                />
                <Button
                  data-testid={suffixTestId('save', props)}
                  variant="primary"
                  onClick={handleSubmit}
                  isLoading={submitting}
                  title={i18n.t('general.actions.save')}
                />
              </ButtonGroup>
            )}
          />
        </>
      )}
    />
  );
};

const schema = object({
  id: string().defined().nullable(),
  legalForm: mixed().required(),
  bankAccounts: array().of($BankAccountRequestBody).defined(),
  businessInfo: $BusinessInfoRequestBody.defined().nullable(),
  personInfo: object({
    personId: string().defined().nullable(),
    selectedIdentityCardIds: array().of(string()).defined(),
  }).defined(),
});

const getFormValues = (
  checkoutContractInformation?: CheckoutContractInformationBody
): DeepPartial<CheckoutContractInformationFormState> => ({
  id: checkoutContractInformation?.customerContractInformation?.id,
  legalForm: checkoutContractInformation?.customerContractInformation?.legalForm,
  bankAccounts: checkoutContractInformation?.customerContractInformation?.bankAccounts,
  businessInfo: checkoutContractInformation?.customerContractInformation?.businessInfo
    ? {
        businessAddressId:
          checkoutContractInformation?.customerContractInformation?.businessInfo?.businessAddress
            ?.id || null,
        businessInfoData:
          checkoutContractInformation?.customerContractInformation?.businessInfo?.businessInfoData,
      }
    : null,
  personInfo: checkoutContractInformation?.customerContractInformation?.person
    ? {
        personId: checkoutContractInformation?.customerContractInformation?.person?.id,
        person: checkoutContractInformation?.customerContractInformation?.person
          ? {
              personData: {
                firstName:
                  checkoutContractInformation?.customerContractInformation?.person?.personData
                    ?.firstName,
                lastName:
                  checkoutContractInformation?.customerContractInformation?.person?.personData
                    ?.lastName,
                middleName:
                  checkoutContractInformation?.customerContractInformation?.person?.personData
                    ?.middleName,
                titleBefore:
                  checkoutContractInformation?.customerContractInformation?.person?.personData
                    ?.titleBefore,
                titleAfter:
                  checkoutContractInformation?.customerContractInformation?.person?.personData
                    ?.titleAfter,
                genderKey:
                  checkoutContractInformation?.customerContractInformation?.person?.personData
                    ?.genderKey,
                roles:
                  checkoutContractInformation?.customerContractInformation?.person?.personData
                    ?.roles,
                citizenshipCode:
                  checkoutContractInformation?.customerContractInformation?.person?.personData
                    ?.citizenshipCode,
                birthdate:
                  checkoutContractInformation?.customerContractInformation?.person?.personData
                    ?.birthdate,
                personalIdentifier:
                  checkoutContractInformation?.customerContractInformation?.person?.personData
                    ?.personalIdentifier,
              },
              phoneNumbers:
                checkoutContractInformation?.customerContractInformation?.person?.phoneNumbers?.map(
                  (phoneNumber) => ({
                    type: phoneNumber.type,
                    phoneNumber: {
                      countryCode: phoneNumber.phoneNumber.countryCode,
                      prefix: phoneNumber.phoneNumber.prefix,
                      number: phoneNumber.phoneNumber.number,
                    },
                  })
                ) ?? null,
              emails: checkoutContractInformation?.customerContractInformation?.person?.emails,
              identityCards:
                checkoutContractInformation?.customerContractInformation?.person?.identityCards.map(
                  (identityCard) => ({
                    id: identityCard.id,
                    cardData: {
                      type: identityCard.identityCardData.type,
                      cardNumber: identityCard.identityCardData.cardNumber,
                      issuedOn: identityCard.identityCardData.issuedOn,
                      validUntil: identityCard.identityCardData.validUntil,
                      issuer: identityCard.identityCardData.issuer,
                      issuedInCountryCode: identityCard.identityCardData.issuedInCountryCode,
                      note: identityCard.identityCardData.note,
                    },
                  })
                ) ?? null,
              permanentAddressId:
                checkoutContractInformation?.customerContractInformation?.person?.permanentAddress
                  ?.id ?? null,
            }
          : null,
        selectedIdentityCardIds: checkoutContractInformation?.selectedIdentityCards.map(
          (card) => card.id
        ),
      }
    : undefined,
});
