import {DataStatus} from 'platform/components';
import {Box, HStack} from 'platform/foundation';

import {useGetTireOrderQuery} from '@dms/api/metadaTires';
import {testIds} from '@dms/routes';
import {CustomerInformationCard, Section, CustomerContactPersonCard} from '@dms/shared';

import {useTiresInventoryUrl} from '../../hooks/useTiresInventoryUrl';

export function Customer() {
  const {orderId} = useTiresInventoryUrl();

  const {
    data: tireOrder,
    isLoading: isTireOrderLoading,
    isError: isTireOrderError,
  } = useGetTireOrderQuery({orderId});

  return (
    <Section data-testid={testIds.tiresInventory.tireOrderDetail('vehicle')}>
      <DataStatus isLoading={isTireOrderLoading} isError={isTireOrderError} minHeight={100}>
        <HStack spacing={4}>
          <Box flex={1}>
            <CustomerInformationCard
              customerId={tireOrder?.customerId ?? ''}
              isDetailLinkVisible
              data-testid={testIds.tiresInventory.tireOrderDetail('customerInformation')}
            />
          </Box>
          <Box flex={1}>
            <CustomerContactPersonCard
              customerId={tireOrder?.customerId ?? ''}
              data-testid={testIds.tiresInventory.tireOrderDetail('customerContactPerson')}
            />
          </Box>
        </HStack>
      </DataStatus>
    </Section>
  );
}
