import isPropValid from '@emotion/is-prop-valid';
import {DynamicUiProvider} from 'platform/components';
import {Box} from 'platform/foundation';
import {StyleSheetManager} from 'styled-components';

import {Helmet, HelmetProvider} from 'react-helmet-async';
import {Provider} from 'react-redux';
import {
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import {APPLICATION_NAME} from '@dms/config';
import i18n from '@dms/i18n';
import {loginRoutes} from '@dms/routes';
import {CommonSkeleton} from '@dms/shared';

import {PersistGate} from 'features/rtkq-persist';

import {Initialize} from './components/Initialize/Initialize';
import {ModulesContainer} from './components/ModulesContainer/ModulesContainer';
import {OfflineDetector} from './components/OfflineDetector/OfflineDetector';
import {WorkspaceInit} from './components/WorkspaceInit/WorkspaceInit';
import {GlobalStyles} from './GlobalStyles';
import {ChangePassword} from './pages/ChangePassword/ChangePassword';
import {ForgotPassword} from './pages/ForgotPassword/ForgotPassword';
import {Login} from './pages/Login/Login';
import {Workspace} from './pages/Workspace/Workspace';
import {reducersDefinitions} from './store/reducers';
import {store} from './store/store';
import {ComponentsProviderWrapper} from './utils/ComponentsProviderWrapper';

const router = createBrowserRouter([
  {
    element: (
      <Provider store={store}>
        <StyleSheetManager shouldForwardProp={shouldForwardProp} enableVendorPrefixes>
          <DynamicUiProvider>
            <ComponentsProviderWrapper>
              <PersistGate
                reducers={reducersDefinitions}
                loadingComponent={
                  <Box height="100vh">
                    <CommonSkeleton />
                  </Box>
                }
              >
                <Outlet />
                <OfflineDetector />
                <GlobalStyles />
              </PersistGate>
            </ComponentsProviderWrapper>
          </DynamicUiProvider>
        </StyleSheetManager>
      </Provider>
    ),
    children: createRoutesFromElements(
      <Route element={<WorkspaceInit />} id="WorkspaceInit">
        <Route path={loginRoutes.login} element={<Login />} />
        <Route path={loginRoutes.loginChangePassword} element={<ChangePassword />} />
        <Route path={loginRoutes.loginForgotPassword} element={<ForgotPassword />} />
        <Route path={loginRoutes.loginWorkspace} element={<Workspace />} />

        <Route element={<Initialize />} id="init">
          <Route path="*" element={<ModulesContainer />} />
        </Route>
      </Route>
    ),
  },
]);

export function App() {
  return (
    <HelmetProvider>
      <Helmet titleTemplate={`%s • ${APPLICATION_NAME}`} htmlAttributes={{lang: i18n.language}}>
        <meta name="description" content={APPLICATION_NAME} />
      </Helmet>

      <RouterProvider router={router} />
    </HelmetProvider>
  );
}

/**
 * A function to determine whether a prop should be forwarded to the underlying DOM element.
 * Default behavior was removed in styled-components v6, so we need to provide our own implementation.
 * @see https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default
 */
function shouldForwardProp(propName: string, target: unknown) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}
