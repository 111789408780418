import {DataStatus} from 'platform/components';
import {Grid, Show, VStack} from 'platform/foundation';

import {isNotNil} from 'ramda-adjunct';

import {useGetVehicleV2Query} from '@dms/api/vehicle';

import {suffixTestId, TestIdProps, useRequiredParams} from 'shared';

import {DetailsSideColumn} from './DetailsSideColumn';
import {VehicleDetail} from './VehicleDetail';

interface VehicleContentDetailsProps extends TestIdProps {
  isSaleVehicle: boolean;
  isRentalVehicle: boolean;
}

export function VehicleContentDetails(props: VehicleContentDetailsProps) {
  const {id: vehicleId} = useRequiredParams();
  const {data: vehicle, isLoading, isError} = useGetVehicleV2Query({vehicleId});

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Show when={isNotNil(vehicle)}>
        <Grid columns={2} spacing={2}>
          <VehicleDetail
            vehicle={vehicle!}
            vehicleId={vehicleId}
            isSaleVehicle={props.isSaleVehicle}
            isRentalVehicle={props.isRentalVehicle}
          />
          <VStack spacing={4}>
            <DetailsSideColumn
              vehicleId={vehicleId}
              isIntegrationsShown
              isDetailsTab
              data-testid={suffixTestId('detailsSideColumn', props)}
            />
          </VStack>
        </Grid>
      </Show>
    </DataStatus>
  );
}
