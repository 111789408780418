import {match} from 'ts-pattern';

import {IconColors} from '../constants/activityItem';

type IconColor = keyof typeof IconColors;

export function getActivityIconColor(color: IconColor) {
  return match(color)
    .with(IconColors.RED, () => IconColors.RED_ICON)
    .with(IconColors.GREEN, () => IconColors.GREEN_ICON)
    .with(IconColors.BLUE, () => IconColors.BLUE_ICON)
    .otherwise(() => undefined);
}
