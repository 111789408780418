export function ErrorImage() {
  return (
    <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g style={{transformOrigin: 'center', animation: 'bounce 8s infinite 4.25s'}}>
        <path
          d="M36.0232 9.9962C33.2841 8.97966 32.0679 4.91112 28.9462 4.67718C28.7607 4.66327 28.574 4.66327 28.3885 4.67718C25.2667 4.91114 24.0506 8.97942 21.3115 9.9962C18.4112 11.0723 14.4455 8.86647 11.9897 11.3223C9.62588 13.6861 11.7176 17.8033 10.6635 20.6442C9.58595 23.5469 5.07977 24.7476 5.34451 28.2789C5.57845 31.4007 9.64699 32.6168 10.6635 35.3559C11.7177 38.1968 9.62576 42.3142 11.9897 44.6778C14.4451 47.1337 18.4111 44.9287 21.3115 46.0039C24.0499 47.0219 25.2672 51.0894 28.3885 51.3229C28.574 51.3369 28.7607 51.3369 28.9462 51.3229C32.0675 51.0894 33.285 47.0217 36.0232 46.0039C38.8642 44.9506 42.9819 47.0417 45.3451 44.6778C47.8821 42.1413 45.4377 38.029 46.778 35.0981C48.0167 32.3996 52.248 31.1358 51.9902 27.7212C51.7566 24.5999 47.6891 23.3824 46.6711 20.6442C45.5959 17.7438 47.8009 13.7778 45.3451 11.3223C42.8895 8.86635 38.9235 11.0724 36.0232 9.9962Z"
          stroke="#FFC3C3"
          strokeWidth="3"
        />
      </g>
      <g style={{transformOrigin: 'center', animation: 'bounceExclamation 8s infinite 4.375s'}}>
        <path
          d="M28.6666 37.3333H28.6876"
          stroke="#E32C1E"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.6666 30.3334V18.6667"
          stroke="#E32C1E"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <style>
        {`
          @keyframes bounce {
            0% { transform: scale(1); }
            5% { transform: scale(1.175); }
            10%, 100% { transform: scale(1); }
          }
          @keyframes bounceExclamation {
            0% { transform: scale(1); }
            5% { transform: scale(1.3); }
            10%, 100% { transform: scale(1); }
          }
        `}
      </style>
    </svg>
  );
}
