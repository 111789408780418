import {BaseFlagProps, Flags} from 'platform/components';
import {Box, Center, Clickable, HStack, Hide, Icon, Show, Text, VStack} from 'platform/foundation';

import {isNotNil} from 'ramda';

import {DashboardBusinessCaseResponseBody} from '@dms/api/dashboard';
import {useGetParticipationQuery} from '@dms/api/participation';
import {EntityResourceIds} from '@dms/api/shared';
import i18n from '@dms/i18n';
import {businessCaseRoutes} from '@dms/routes';
import {
  getBusinessCaseStateFlag,
  getBusinessCaseTradeTypeFlag,
  getBusinessCaseTypeFlag,
  usePermissions,
} from '@dms/shared';

import {TestIdProps, buildArray, composePath, suffixTestId, useNavigate} from 'shared';

import {usePriceFormatter} from '../../hooks/usePriceFormatter';
import {convertTagToFlag} from '../../utils/convertTagToFlag';
import {HoverableBackground} from '../HoverableBackground';

interface LatestBusinessCaseItemProps extends TestIdProps {
  businessCase: DashboardBusinessCaseResponseBody;
}

export const LatestBusinessCaseItem = (props: LatestBusinessCaseItemProps) => {
  const navigate = useNavigate();
  const {formatPrice} = usePriceFormatter();

  const {data: businessCaseParticipation} = useGetParticipationQuery({
    recordId: props.businessCase.id,
    resourceId: EntityResourceIds.businessCase,
  });

  const [canReadTag] = usePermissions({
    permissionKeys: ['businessCaseTagRead'],
    scopes: {
      businessCaseTagRead: {
        participation: businessCaseParticipation,
        // BCs are fed to this component based on branchId, no need for scope
        branchId: 'SKIP',
      },
    },
  });

  const onClickOpenBusinessCaseOverview = () => {
    navigate(composePath(businessCaseRoutes.overview, {params: {id: props.businessCase.id}}));
  };

  const flags = generateBusinessCaseFlags(props.businessCase, canReadTag);

  return (
    <Clickable onClick={onClickOpenBusinessCaseOverview}>
      <HoverableBackground>
        <HStack spacing={4} minWidth={100}>
          <Box
            minWidth={10}
            maxWidth={10}
            height={10}
            backgroundColor="palettes.neutral.30.100"
            borderRadius="small"
            overflow="hidden"
            flex={1}
          >
            <Center height="100%">
              <Icon value="places/business_center" size={6} color="palettes.neutral.800.100" />
            </Center>
          </Box>
          <Box flex={3}>
            <VStack spacing={1}>
              <HStack spacing={1}>
                <Flags
                  spacing={1}
                  flags={flags}
                  shouldWrap
                  data-testid={suffixTestId('latest-businessCase-item-flags', props)}
                />
              </HStack>
              <Text alternative>{props.businessCase.customer}</Text>
            </VStack>
          </Box>
          <Box flex={3}>
            <VStack spacing={1} grow={1}>
              <HStack>
                <Show when={isNotNil(props.businessCase.owner)}>
                  <Text size="xSmall">{props.businessCase.owner}</Text>
                </Show>
                <Hide when={isNotNil(props.businessCase.owner)}>
                  <Text size="xSmall">{i18n.t('general.labels.unassigned')}</Text>
                </Hide>
              </HStack>
              <Text alternative>{props.businessCase.vehicle}</Text>
            </VStack>
          </Box>
          <Box flex={3} paddingTop={4}>
            <VStack align="flex-end">
              <HStack grow={1} align="flex-end">
                <Text alternative>{formatPrice(props.businessCase.price)}</Text>
              </HStack>
            </VStack>
          </Box>
        </HStack>
      </HoverableBackground>
    </Clickable>
  );
};

function generateBusinessCaseFlags(
  businessCase: DashboardBusinessCaseResponseBody,
  canReadTag?: boolean
): BaseFlagProps[] {
  const typeFlag = getBusinessCaseTypeFlag(businessCase.type);
  const stateFlag = getBusinessCaseStateFlag(businessCase.state);
  const stockTypeFlag = getBusinessCaseTradeTypeFlag(businessCase.tradeType);

  const flags = buildArray<BaseFlagProps>()
    .when(isNotNil(typeFlag), typeFlag as BaseFlagProps)
    .when(isNotNil(stockTypeFlag), stockTypeFlag as BaseFlagProps)
    .when(isNotNil(stateFlag), stateFlag as BaseFlagProps);

  flags.forEach((flag) => (flag.size = 'small'));

  if (canReadTag) {
    businessCase.tags.forEach((tag) => flags.add(convertTagToFlag(tag)));
  }

  return flags;
}
