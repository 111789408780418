import {differenceInDays} from 'date-fns';

import {isNil} from 'ramda';

export function calculateInterestRecurringCosts(
  endDate: string,
  startDate: string,
  baseValue: number,
  interestPA: number
): number {
  if (isNil(endDate) || isNil(startDate) || isNil(baseValue) || isNil(interestPA)) {
    return 0;
  }

  const daysBetween = differenceInDays(endDate, startDate);
  return baseValue * (1 + interestPA / 365) ** daysBetween;
}
