import {
  Button,
  ButtonGroup,
  Card,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {Box, Grid, Show, VStack} from 'platform/foundation';
import {array, boolean, object} from 'yup';

import {
  AddFieldDefinitionRequestBody,
  useAddFieldDefinitionMutation,
  useGetFieldDefinitionListQuery,
} from '@dms/api/customFields';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId, useBoolean, yupString} from 'shared';

import {VALUE_TYPE_OPTIONS} from '../constants/valueTypeOptions';
import {DefinitionFormValues} from '../types/DefinitionFormValues';
import {AdditionalFieldsByType} from './AdditionalFieldsByType';

interface CreateDefinitionFormProps extends RequiredTestIdProps {
  resourceId: string;
  onAfterSubmit: () => void;
  areDefinitionsEmpty: boolean;
}

export function CreateDefinitionForm(props: CreateDefinitionFormProps) {
  const [isFormOpen, openForm, closeForm] = useBoolean(props.areDefinitionsEmpty);
  const [createDefinition] = useAddFieldDefinitionMutation();
  const {refetch} = useGetFieldDefinitionListQuery({type: 'tenant'});

  const handleCreateDefinition: FormSubmitHandler<DefinitionFormValues> = async (
    submitData,
    _,
    reset
  ) => {
    const body: AddFieldDefinitionRequestBody = {
      hint: submitData.hint,
      name: submitData.name,
      valueType: submitData.valueType,
      allowedValues: submitData.allowedValues.map((value) => ({type: 'string', value})) ?? [],
      resourceId: props.resourceId,
      isRequired: submitData.isRequired,
      isNotified: submitData.isNotified,
      rules: [],
    };

    await createDefinition({body})
      .unwrap()
      // NOTE: refetching manually so form leaves loading state only after new data is fetched
      .then(refetch)
      .then(closeForm)
      .then(() => reset())
      .then(props.onAfterSubmit)
      .catch(handleApiError);
  };

  if (!isFormOpen) {
    return (
      <Box>
        <Button
          variant="link"
          leftIcon="content/add_circle"
          title={i18n.t('page.accounting.labels.newDefinition')}
          onClick={openForm}
        />
      </Box>
    );
  }

  return (
    <Form<DefinitionFormValues> schema={schema} onSubmit={handleCreateDefinition}>
      {(control, formApi) => {
        const selectedValueType = formApi.watch('valueType');

        return (
          <Card variant="inlineWhite" title={i18n.t('page.accounting.labels.newDefinition')}>
            <VStack spacing={4}>
              <Grid columns={3}>
                <FormField
                  type="choice"
                  control={control}
                  options={VALUE_TYPE_OPTIONS}
                  onChange={() => {
                    formApi.setValue('allowedValues', []);
                  }}
                  name="valueType"
                  label={i18n.t('page.generalSettings.labels.valueType')}
                  data-testid={suffixTestId('valueType', props)}
                  isNotClearable
                  isRequired
                />

                <FormField
                  type="text"
                  control={control}
                  name="name"
                  label={i18n.t('page.generalSettings.labels.name')}
                  data-testid={suffixTestId('name', props)}
                  isRequired
                />

                <FormField
                  type="text"
                  control={control}
                  name="hint"
                  label={i18n.t('page.generalSettings.labels.hint')}
                  data-testid={suffixTestId('hint', props)}
                  isRequired
                />

                <FormField
                  type="checkbox"
                  control={control}
                  name="isRequired"
                  label={i18n.t('page.generalSettings.labels.isFieldRequired')}
                  data-testid={suffixTestId('isFieldRequired', props)}
                />

                <Show when={['date', 'datetime'].includes(selectedValueType)}>
                  <FormField
                    type="checkbox"
                    control={control}
                    name="isNotified"
                    label={i18n.t('page.generalSettings.labels.isNotified')}
                    data-testid={suffixTestId('isNotified', props)}
                  />
                </Show>
              </Grid>

              <AdditionalFieldsByType
                control={control}
                formApi={formApi}
                data-testid={suffixTestId('additionalFields', props)}
              />

              <ButtonGroup align="right">
                <Button
                  title={i18n.t('general.actions.discardChanges')}
                  variant="secondary"
                  onClick={closeForm}
                  data-testid={suffixTestId('discardChanges', props)}
                />
                <FormButton
                  leftIcon="content/save"
                  title={i18n.t('general.actions.saveChanges')}
                  control={control}
                  data-testid={suffixTestId('save', props)}
                  type="submit"
                />
              </ButtonGroup>
            </VStack>
          </Card>
        );
      }}
    </Form>
  );
}

const schema = object({
  name: yupString.required(),
  valueType: yupString.oneOf(VALUE_TYPE_OPTIONS.map((option) => option.value)).required(),
  hint: yupString.required(),
  isRequired: boolean().default(false),
  isNotified: boolean().default(false),
  allowedValues: array().when('valueType', {
    is: 'string',
    then: (schema) => schema.of(yupString),
  }),
});
