import {z} from 'zod';

export const SupplierArticlesAddNewFormSchema = z.object({
  supplierInfo: z.object({
    supplierId: z.string(),
    supplierOrderingNumber: z.string().nullish(),
    supplierBulkPackageQuantity: z.number().nullish(),
  }),
  quantityAndUnitsMapping: z.object({
    supplierQuantity: z.number().positive(),
    supplierUnitId: z.string(),
    warehouseQuantity: z.number().positive(),
    warehouseUnitId: z.string(),
  }),
});

export type SupplierArticlesAddNewForm = z.infer<typeof SupplierArticlesAddNewFormSchema>;
