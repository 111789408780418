import {
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Grid, HStack, Show, VStack} from 'platform/foundation';

import {MouseEvent} from 'react';

import {isNil, sortBy} from 'ramda';

import {
  SeriesResponseBody,
  useCreateSeriesMutation,
  useGetSeriesTypeListQuery,
  usePatchSeriesMutation,
} from '@dms/api/series';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';
import {SeriesTypeEnum} from '@dms/teas';

import {useNavigate} from 'shared';

import {SettingsFooter} from '../../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../../components/SettingsSection/SettingsSection';
import {DocumentSeriesFormValues} from '../types/DocumentSeriesFormValues';
import {getEmptyItem} from '../utils/getEmptyItem';
import {transformPatternsToDate} from '../utils/transformPatternsToDate';
import {transformPatternsToString} from '../utils/transformPatternsToString';
import {documentSeriesFormSchema} from './documentSeriesFormSchema';
import {PatternsList} from './PatternsList';

const createDefaultValues = (series: SeriesResponseBody | undefined): DocumentSeriesFormValues => {
  if (!series) {
    return {
      name: '',
      type: '' as SeriesTypeEnum,
      withPrefix: 'onlyStartingNumber',
      patterns: [getEmptyItem(new Date())],
      unique: true,
    };
  }

  const patterns = sortBy(
    (pattern) => pattern.from ?? '',
    transformPatternsToDate(series.patterns)
  );

  return {
    ...series,
    withPrefix: series.withPrefix ? 'prefixWithStartingNumber' : 'onlyStartingNumber',
    patterns,
  };
};

interface DocumentSeriesFormProps {
  series?: SeriesResponseBody;
}

export const DocumentSeriesForm = (props: DocumentSeriesFormProps) => {
  const isCreating = isNil(props.series);
  const navigate = useNavigate();

  const {
    data: documentSeriesTypes,
    isLoading: isDocumentSeriesTypesLoading,
    isError: isDocumentSeriesTypesError,
  } = useGetSeriesTypeListQuery();

  const [createSeries, {isLoading: isCreateSeriesPending}] = useCreateSeriesMutation();
  const [patchSeries, {isLoading: isPatchSeriesPending}] = usePatchSeriesMutation();

  const handleCancel = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigateToList();
  };

  const navigateToList = () => navigate(settingsRoutes.seriesDefinition);

  const handleSubmit: FormSubmitHandler<DocumentSeriesFormValues> = async (data, setErrors) => {
    const formData = {
      ...data,
      patterns: transformPatternsToString(data.patterns),
      withPrefix:
        data.type === 'accounting/invoice'
          ? data.withPrefix === 'prefixWithStartingNumber'
          : undefined,
    };

    if (isCreating) {
      await createSeries({
        createSeriesRequestBody: {
          ...formData,
          withPrefix: formData.type === 'accounting/invoice' ? formData.withPrefix : undefined,
        },
      })
        .unwrap()
        .then(() => {
          showNotification.success();
          navigateToList();
        })
        .catch((error: Error) => {
          handleApiError(error, {
            setErrors,
          });
        });
    } else if (props.series) {
      await patchSeries({
        seriesId: props.series.id,
        patchSeriesRequestBody: {
          ...formData,
          withPrefix: formData.type === 'accounting/invoice' ? formData.withPrefix : undefined,
        },
      })
        .unwrap()
        .then(() => {
          showNotification.success();
          navigateToList();
        })
        .catch((error: Error) => {
          handleApiError(error, {
            setErrors,
          });
        });
    }
  };

  const documentTypeOptions =
    documentSeriesTypes?.map(({code, name}) => ({
      label: name,
      value: code,
    })) || [];

  return (
    <DataStatus isLoading={isDocumentSeriesTypesLoading} isError={isDocumentSeriesTypesError}>
      <SettingsSection>
        <Form<DocumentSeriesFormValues>
          defaultValues={createDefaultValues(props.series)}
          onSubmit={handleSubmit}
          schema={documentSeriesFormSchema}
        >
          {(control, formApi) => (
            <VStack spacing={4}>
              <Grid columns={formApi.watch('type') === 'accounting/invoice' ? 4 : 3}>
                <FormField
                  control={control}
                  name="name"
                  label={i18n.t('entity.documentSeries.name')}
                  type="text"
                  data-testid={testIds.settings.seriesDefinitionEdit('name')}
                  isRequired
                />

                <FormField
                  control={control}
                  name="type"
                  label={i18n.t('entity.documentSeries.type')}
                  type="choice"
                  options={documentTypeOptions || []}
                  data-testid={testIds.settings.seriesDefinitionEdit('type')}
                  isDisabled={!!props.series}
                  isRequired
                />

                <Show when={formApi.watch('type') === 'accounting/invoice'}>
                  <FormField
                    control={control}
                    name="withPrefix"
                    label={i18n.t('entity.documentSeries.variableSymbol.title')}
                    type="choice"
                    options={[
                      {
                        label: i18n.t('entity.documentSeries.variableSymbol.onlyStartingNumber'),
                        value: 'onlyStartingNumber',
                      },
                      {
                        label: i18n.t(
                          'entity.documentSeries.variableSymbol.prefixWithStartingNumber'
                        ),
                        value: 'prefixWithStartingNumber',
                      },
                    ]}
                    data-testid={testIds.settings.seriesDefinitionEdit('withPrefix')}
                    isRequired
                  />
                </Show>
                <HStack align="flex-end">
                  <FormField
                    control={control}
                    name="unique"
                    label={i18n.t('entity.documentSeries.unique')}
                    type="checkbox"
                    data-testid={testIds.settings.seriesDefinitionEdit('unique')}
                    isDisabled={!!props.series}
                    isRequired
                  />
                </HStack>
              </Grid>
              <PatternsList control={control} formApi={formApi} />
              <SettingsFooter
                actions={[
                  {
                    title: i18n.t('general.actions.cancel'),
                    onClick: handleCancel,
                    variant: 'secondary',
                    type: 'button',
                    'data-testid': testIds.settings.seriesDefinitionEdit('cancel'),
                  },
                  {
                    title: isCreating
                      ? i18n.t('general.actions.create')
                      : i18n.t('general.actions.update'),
                    type: 'button',
                    buttonType: 'submit',
                    variant: 'primary',
                    'data-testid': testIds.settings.seriesDefinitionEdit('submit'),
                    isLoading: isCreateSeriesPending || isPatchSeriesPending,
                  },
                ]}
              />
            </VStack>
          )}
        </Form>
      </SettingsSection>
    </DataStatus>
  );
};
