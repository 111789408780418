import {metadaApi} from '@dms/api/core';

import {
  PostWarehouseRequest,
  PostWarehouseRequestSchema,
  PostWarehouseResponse,
  PostWarehouseResponseSchema,
  DeleteWarehouseRequest,
  DeleteWarehouseRequestSchema,
  DeleteWarehouseResponse,
  GetWarehouseRequest,
  GetWarehouseRequestSchema,
  GetWarehouseResponse,
  GetWarehouseResponseSchema,
  GetWarehousesRequestSchema,
  GetWarehousesResponse,
  GetWarehousesResponseSchema,
  GeWarehousesRequest,
  PatchWarehouseRequest,
  PatchWarehouseRequestSchema,
  PatchWarehouseResponse,
  DeleteWarehouseResponseSchema,
  PatchWarehouseResponseSchema,
} from './types';

export const metadaWarehouseApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getWarehouses: build.query<GetWarehousesResponse, GeWarehousesRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/warehouses`,
        method: 'GET',
        params: {
          serviceOrderVariantId: queryArg?.serviceOrderVariantId,
          directSaleVariantId: queryArg?.directSaleVariantId,
          authorizationProfileId: queryArg?.authorizationProfileId,
          supplierId: queryArg?.supplierId,
        },
        queryArg,
      }),
      providesTags: ['warehouses'],
      extraOptions: {
        requestSchema: GetWarehousesRequestSchema,
        responseSchema: GetWarehousesResponseSchema,
      },
    }),
    getWarehouse: build.query<GetWarehouseResponse, GetWarehouseRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/warehouse/${queryArg.warehouseId}`,
        method: 'GET',
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [{type: 'warehouse', id: queryArg.warehouseId}],
      extraOptions: {
        requestSchema: GetWarehouseRequestSchema,
        responseSchema: GetWarehouseResponseSchema,
      },
    }),
    postWarehouse: build.mutation<PostWarehouseResponse, PostWarehouseRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/warehouse`,
        method: 'POST',
        body: queryArg,
        queryArg,
      }),
      invalidatesTags: ['warehouses'],
      extraOptions: {
        requestSchema: PostWarehouseRequestSchema,
        responseSchema: PostWarehouseResponseSchema,
      },
    }),
    patchWarehouse: build.mutation<PatchWarehouseResponse, PatchWarehouseRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/warehouse/${queryArg.warehouseId}`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'warehouses',
        {type: 'warehouse', id: queryArg.warehouseId ?? ''},
      ],
      extraOptions: {
        requestSchema: PatchWarehouseRequestSchema,
        responseSchema: PatchWarehouseResponseSchema,
      },
    }),
    deleteWarehouse: build.mutation<DeleteWarehouseResponse, DeleteWarehouseRequest>({
      query: (queryArg) => ({
        url: 'warehouse/v1/warehouse/delete',
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: ['warehouses'],
      extraOptions: {
        requestSchema: DeleteWarehouseRequestSchema,
        responseSchema: DeleteWarehouseResponseSchema,
      },
    }),
  }),
});

export const {
  useGetWarehousesQuery,
  useLazyGetWarehousesQuery,
  useGetWarehouseQuery,
  useLazyGetWarehouseQuery,
  usePostWarehouseMutation,
  usePatchWarehouseMutation,
  useDeleteWarehouseMutation,
} = metadaWarehouseApi;
