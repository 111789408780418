import {Card, Choice, Separator} from 'platform/components';
import {Box, Heading, HStack, Show, VStack} from 'platform/foundation';

import {useState} from 'react';
import {Helmet} from 'react-helmet-async';

import {mergeAll} from 'ramda';

import {useGetAllTireWarehousesQuery} from '@dms/api/metadaTires';
import i18n from '@dms/i18n';
import {Main, useBranches} from '@dms/shared';

import {DataGrid, QueryFilterObject} from 'features/datagrid';

import {Statistics} from './components/Statistics';

export function StorageLocationCapacity() {
  const branchesQuery = useBranches();

  const [selectedBranchId, setSelectedBranchId] = useState<string | null>(null);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState<string | null>(null);

  const warehousesQuery = useGetAllTireWarehousesQuery(
    {branchId: selectedBranchId || ''},
    {skip: !selectedBranchId}
  );

  const warehousesOptions =
    warehousesQuery.currentData?.map((warehouse) => ({
      label: warehouse.name,
      value: warehouse.warehouseId,
    })) || [];

  const queryModifier = (filter: QueryFilterObject) =>
    mergeAll([filter, {tireWarehouse: selectedWarehouseId}]);

  return (
    <>
      <Helmet title={i18n.t('page.tiresInventory.labels.storageLocationCapacity')} />
      <Main>
        <VStack spacing={4}>
          <Heading size={3} alternative>
            {i18n.t('page.tiresInventory.labels.storageLocationCapacity')}
          </Heading>
          <Card>
            <HStack spacing={6}>
              <HStack spacing={4} width="100%">
                <Box flex={1}>
                  <Choice
                    options={branchesQuery.branchOptions}
                    value={selectedBranchId}
                    isLoading={branchesQuery.isLoading}
                    onChange={(value) => {
                      setSelectedBranchId(value);
                      setSelectedWarehouseId(null);
                    }}
                    label={i18n.t('entity.branch.labels.branch')}
                    isNotClearable
                  />
                </Box>
                <Box flex={1}>
                  <Choice
                    options={warehousesOptions}
                    value={selectedWarehouseId}
                    isLoading={warehousesQuery.isLoading || warehousesQuery.isFetching}
                    onChange={(value) => setSelectedWarehouseId(value)}
                    label={i18n.t('entity.warehouse.labels.warehouse')}
                    isNotClearable
                  />
                </Box>
              </HStack>
              <Separator orientation="vertical" spacing={0} />
              <Statistics warehouseId={selectedWarehouseId} />
            </HStack>
          </Card>
          <Show when={selectedWarehouseId}>
            <Box key={selectedWarehouseId}>
              <DataGrid
                gridCode="storage-location-tire-inventory"
                queryModifier={queryModifier}
                autoHeight
              />
            </Box>
          </Show>
        </VStack>
      </Main>
    </>
  );
}
