import {Separator} from 'platform/components';
import {Heading, HStack, Show, Text, VStack} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {SourcingPriceResponseBody} from '@dms/api/sourcing';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {CURRENCY_CZK} from '@dms/shared';

import {useSourcingCurrency} from '../../../hooks/useSourcingCurrency';

interface PriceTableProps {
  originalPrice: number;
  price: SourcingPriceResponseBody;
}

export function PriceTable(props: PriceTableProps) {
  const formatPrice = useFormatCurrency();
  const currency = useSourcingCurrency();

  const priceWithVat = props.price.originalWithVat ?? 0;
  const vatRate = props.price.vatRate ?? 0;

  const priceWithoutVat = priceWithVat > 0 ? (priceWithVat / (100 + vatRate)) * 100 : 0;

  return (
    <VStack data-testid={testIds.sourcing.classifieds('priceTable')}>
      <HStack
        justify="space-between"
        align="center"
        spacing={2}
        data-testid={testIds.sourcing.classifieds('priceTableRow-1')}
      >
        <Text size="xSmall">{i18n.t('entity.sourcing.vehiclePreview.labels.priceWithVat')}</Text>
        <Heading size={2} isSingleLine>
          {formatPrice(props.originalPrice, currency?.code ?? CURRENCY_CZK, 0)}
        </Heading>
      </HStack>
      <Separator spacing={2} />
      <HStack
        justify="space-between"
        align="center"
        data-testid={testIds.sourcing.classifieds('priceTableRow-2')}
      >
        <Text size="xSmall">
          {`${i18n.t('entity.sourcing.vehiclePreview.labels.priceWithVat')} (${i18n.t(
            'entity.sourcing.vehiclePreview.labels.inOriginalCurrency'
          )})`}
        </Text>
        <Text size="small" noWrap>
          {formatPrice(priceWithVat, props.price.originalCurrency?.key ?? CURRENCY_CZK, 0)}
        </Text>
      </HStack>
      <Separator spacing={2} />
      <Show when={props.price.vatReclaimable}>
        <HStack
          justify="space-between"
          align="center"
          data-testid={testIds.sourcing.classifieds('priceTableRow-3')}
        >
          <Text size="xSmall">
            {`${i18n.t('entity.sourcing.vehiclePreview.labels.priceWithoutVat')} (${i18n.t(
              'entity.sourcing.vehiclePreview.labels.inOriginalCurrency'
            )})`}
          </Text>
          <Text size="small" noWrap alternative>
            {formatPrice(priceWithoutVat, props.price.originalCurrency?.key ?? CURRENCY_CZK, 0)}
          </Text>
        </HStack>
        <Separator spacing={2} />
      </Show>
      <HStack justify="space-between" data-testid={testIds.sourcing.classifieds('priceTableRow-4')}>
        <Text size="xSmall">{i18n.t('entity.sourcing.vehiclePreview.labels.vatDeductible')}</Text>
        <Text size="small" noWrap>
          {props.price.vatReclaimable ? i18n.t('general.labels.yes') : i18n.t('general.labels.no')}
        </Text>
      </HStack>
    </VStack>
  );
}
