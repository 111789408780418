import {isNil} from 'ramda';

import {
  VehicleAdvertisingSettingsCodeEnum,
  VehicleGeneralAdvertisingAttributeResponseBody,
  VehicleWindshieldAdvertisingAttributeResponseBody,
} from '@dms/api/sales';

export function getAttributeByCode<T>(
  code: VehicleAdvertisingSettingsCodeEnum,
  attributes:
    | Array<
        | VehicleWindshieldAdvertisingAttributeResponseBody
        | VehicleGeneralAdvertisingAttributeResponseBody
      >
    | undefined
): T | undefined {
  if (isNil(attributes)) {
    return;
  }

  return (
    (attributes.find(
      (
        attribute:
          | VehicleWindshieldAdvertisingAttributeResponseBody
          | VehicleGeneralAdvertisingAttributeResponseBody
      ) => attribute.code === code
    ) as T) ?? undefined
  );
}
