import {Alert, DataStatus, FormControl, FormField, Separator} from 'platform/components';
import {Grid, GridItem, Hide, Show, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {UseFormReturn} from 'react-hook-form';

import {always, isNotNil} from 'ramda';

import {SimilarVehicleResponseBody, VehicleTypeEnumObject} from '@dms/api/shared';
import {VinDecoderResponseBody} from '@dms/api/vinDecoder';
import i18n from '@dms/i18n';
import {vehiclesRoutes} from '@dms/routes';

import {
  Nullish,
  RequiredTestIdProps,
  composePath,
  getApiDateString,
  handleFormReset,
  suffixTestId,
  useNavigate,
} from 'shared';

import {monthOptions} from '../../../../../constants/monthOptions';
import {yearOptions} from '../../../../../constants/yearOptions';
import {useVehicleCatalogue} from '../../../../../hooks/useVehicleCatalogue';
import {VehicleRecommendationType} from '../../../../../types/VehicleRecommendationType';
import {VehicleSelectionType} from '../../../../VinDecoderField/components/VehiclePreview';
import {VinDecoderField} from '../../../../VinDecoderField/VinDecoderField';
import {ServiceVehicleFormMode} from '../../../types/ServiceVehicleFormMode';
import {ServiceVehicleFormType} from '../../../types/ServiceVehicleFormType';
import {useLazyVehicleMake} from '../hooks/useLazyVehicleMake';
import {useLazyVehicleModel} from '../hooks/useLazyVehicleModel';
import {createVehicleTitle} from '../utils/createVehicleTitle';
import {CreatableMakeSelect} from './CreatableMakeSelect';
import {CreatableModelSelect} from './CreatableModelSelect';
import {FuelField} from './FuelField';

interface MainProps extends RequiredTestIdProps {
  control: FormControl<ServiceVehicleFormType>;
  formApi: UseFormReturn<ServiceVehicleFormType>;
  shouldRecommendVehiclesFor?: VehicleRecommendationType;
  mode: ServiceVehicleFormMode;
}

export function Main(props: MainProps) {
  const vehicleType = props.formApi.watch('type');
  const typedVehicleType = vehicleType.at(0) as VehicleTypeEnumObject;
  const navigate = useNavigate();
  const [vehicleUtils, {isLoading, isError}] = useVehicleCatalogue(vehicleType.at(0));
  const isTrailerOrSemiTrailer = ['VEHICLETYPE_TRAILER', 'VEHICLETYPE_SEMI_TRAILER'].includes(
    typedVehicleType
  );

  const defaultValues = props.control._defaultValues;

  const isRegistrationPlateDisabled =
    isNotNil(defaultValues?.registrationPlate) &&
    props.mode === ServiceVehicleFormMode.NEW_FROM_SALE;

  const isVinDisabled =
    isNotNil(defaultValues?.vin) && props.mode === ServiceVehicleFormMode.NEW_FROM_SALE;

  const {decodeMake} = useLazyVehicleMake();
  const {decodeModel} = useLazyVehicleModel();

  const handleSelectVehicle = (
    vehicle: SimilarVehicleResponseBody,
    selectionType: VehicleSelectionType
  ) => {
    const redirectRoute = match(selectionType)
      .with(
        'createServiceProcess',
        always(composePath(vehiclesRoutes.createServiceVehicleFromSale, {params: {id: vehicle.id}}))
      )
      .otherwise(
        always(composePath(vehiclesRoutes.editServiceVehicle, {params: {id: vehicle.id}}))
      );

    navigate(redirectRoute);
  };

  const handleDecodeVin =
    (formApi: UseFormReturn<ServiceVehicleFormType>) =>
    async (decodedData?: VinDecoderResponseBody) => {
      const decodedMake = await decodeMake({
        vehicleType: decodedData?.vehicleType as VehicleTypeEnumObject | Nullish,
      });

      const decodedModel = await decodeModel({
        make: decodedData?.make,
        vehicleType: decodedData?.vehicleType as VehicleTypeEnumObject | Nullish,
      });

      const newVehicleTitle = createVehicleTitle({
        flatMakes: decodedMake.detail.flatMakes,
        flatModels: decodedModel.detail.flatModels,
        formMake: decodedData?.make,
        formModel: decodedData?.modelFamily,
      });

      if (newVehicleTitle) {
        formApi.setValue('title', newVehicleTitle);
      }

      if (decodedData?.vehicleType) {
        formApi.setValue('type', [decodedData.vehicleType]);
      }

      if (decodedData?.make) {
        formApi.setValue('formMake', decodedData.make);
        formApi.setValue('make', decodedData.make);
        formApi.setValue('customMake', null);
      }

      if (decodedData?.modelFamily) {
        formApi.setValue('formModelFamily', decodedData.modelFamily);
        formApi.setValue('modelFamily', decodedData.modelFamily);
        formApi.setValue('customModelFamily', null);
      }

      if (decodedData?.trim) {
        formApi.setValue('trim', decodedData.trim);
      }

      if (decodedData?.variant) {
        formApi.setValue('variant', decodedData.variant);
      }

      if (
        decodedData?.firstRegistrationOnYear &&
        decodedData?.firstRegistrationOnMonth &&
        decodedData?.firstRegistrationOnDay
      ) {
        formApi.setValue(
          'firstRegistrationOn',
          getApiDateString(
            new Date(
              parseInt(decodedData.firstRegistrationOnYear, 10),
              parseInt(decodedData.firstRegistrationOnMonth, 10),
              parseInt(decodedData.firstRegistrationOnDay, 10)
            )
          )
        );
      }

      if (decodedData?.manufacturedOnYear) {
        formApi.setValue('manufacturedOnYear', decodedData.manufacturedOnYear);
      }

      if (decodedData?.manufacturedOnMonth) {
        formApi.setValue('manufacturedOnMonth', decodedData.manufacturedOnMonth);
      }

      if (decodedData?.otherRecords) {
        formApi.setValue('note', decodedData.otherRecords);
      }

      if (decodedData?.bodyStyle) {
        formApi.setValue('bodyStyle', [decodedData.bodyStyle]);
      }

      if (decodedData?.fuelType) {
        formApi.setValue('fuelType', [decodedData.fuelType]);
      }

      if (decodedData?.transmission) {
        formApi.setValue('transmission', [decodedData.transmission]);
      }

      if (decodedData?.drive) {
        formApi.setValue('drive', [decodedData.drive]);
      }

      if (decodedData?.exteriorColor) {
        formApi.setValue('exteriorColor.value', decodedData.exteriorColor);
      }

      if (decodedData?.engineCode) {
        formApi.setValue('engineCode', decodedData.engineCode);
      }

      if (decodedData?.power) {
        formApi.setValue('power', parseInt(decodedData.power));
      }

      if (decodedData?.engineVolume) {
        formApi.setValue('engineVolume', parseInt(decodedData.engineVolume));
      }

      if (decodedData?.secondaryFuelType) {
        formApi.setValue('secondaryFuelType', [decodedData.secondaryFuelType]);
      }

      formApi.clearErrors();
    };

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <VStack spacing={4}>
        <VinDecoderField<ServiceVehicleFormType>
          control={props.control}
          formApi={props.formApi}
          onVehicleSelect={handleSelectVehicle}
          shouldRecommendVehiclesFor={props.shouldRecommendVehiclesFor}
          registrationPlateField={{
            name: 'registrationPlate',
            label: i18n.t('entity.vehicle.labels.licensePlate'),
            isDisabled: isRegistrationPlateDisabled,
          }}
          vinField={{
            name: 'vin',
            label: i18n.t('entity.vehicle.labels.vin'),
            isDisabled: isVinDisabled,
          }}
          onDecodedData={handleDecodeVin(props.formApi)}
          data-testid={suffixTestId('vinDecoder', props)}
        />

        <Show when={props.mode === ServiceVehicleFormMode.NEW_FROM_SALE}>
          <Alert
            variant="info"
            message={i18n.t('entity.vehicle.labels.creatingServiceForSaleVehicleMessage')}
            title={i18n.t('entity.vehicle.labels.creatingServiceForSaleVehicle')}
          />
        </Show>

        <FormField
          control={props.control}
          type="chips"
          name="type"
          options={vehicleUtils.vehicleTypeOptions ?? []}
          label={i18n.t('entity.vehicle.labels.vehicleType')}
          isRequired
          onChange={() =>
            handleFormReset<ServiceVehicleFormType>(props.formApi, [
              {name: 'formMake', value: null},
              {name: 'make', value: null},
              {name: 'customMake', value: null},
              {name: 'formModelFamily', value: null},
              {name: 'modelFamily', value: null},
              {name: 'customModelFamily', value: null},
              {name: 'trim', value: null},
              {name: 'title', value: null},
              {name: 'variant', value: null},
              {name: 'bodyStyle', value: null},
              {name: 'transmission', value: null},
              {name: 'drive', value: null},
              {name: 'fuelType', value: null},
              {name: 'chargingAC', value: null},
              {name: 'chargingTimeAC', value: null},
              {name: 'chargingDC', value: null},
              {name: 'chargingTimeDC', value: null},
              {name: 'batteryType', value: null},
              {name: 'batteryCapacityWh', value: null},
              {name: 'batteryCapacityAh', value: null},
              {name: 'batteryVoltage', value: null},
              {name: 'batteryCount', value: null},
              {name: 'chargingConnectorType', value: null},
              {name: 'electricRange', value: null},
              {name: 'batteryMileageLimit', value: null},
              {name: 'batteryWarrantyUntil', value: null},
              {name: 'batteryOwnershipType', value: null},
              {name: 'secondaryFuelType', value: null},
              {name: 'hybridType', value: null},
              {name: 'gasRevisionValidUntil', value: null},
              {name: 'pressureVesselValidUntil', value: null},
              {name: 'engineName', value: null},
              {name: 'engineCode', value: null},
              {name: 'power', value: null},
              {name: 'engineVolume', value: null},
            ])
          }
          data-testid={suffixTestId('vehicleType', props)}
        />
        <Grid columns={4}>
          <CreatableMakeSelect
            control={props.control}
            formApi={props.formApi}
            vehicleType={typedVehicleType}
            data-testid={suffixTestId('make', props)}
          />
          <CreatableModelSelect
            control={props.control}
            formApi={props.formApi}
            vehicleType={typedVehicleType}
            data-testid={suffixTestId('modelFamily', props)}
          />
          <Hide when={isTrailerOrSemiTrailer}>
            <FormField
              control={props.control}
              type="text"
              name="trim"
              label={i18n.t('entity.vehicle.labels.trimLevel')}
              data-testid={suffixTestId('trim', props)}
            />
          </Hide>
          <FormField
            control={props.control}
            type="text"
            name="variant"
            label={i18n.t('entity.vehicle.labels.variantName')}
            data-testid={suffixTestId('variant', props)}
          />
          <GridItem span={2}>
            <FormField
              control={props.control}
              type="text"
              name="title"
              label={i18n.t('entity.vehicle.labels.vehicleTitle')}
              data-testid={suffixTestId('title', props)}
            />
          </GridItem>
        </Grid>
        <Grid columns={4}>
          <FormField
            control={props.control}
            type="apiDate"
            name="firstRegistrationOn"
            label={i18n.t('entity.vehicle.labels.firstRegistration')}
            data-testid={suffixTestId('firstRegistrationOn', props)}
          />
          <FormField
            control={props.control}
            type="choice"
            name="manufacturedOnYear"
            label={i18n.t('entity.vehicle.labels.realProductionYear')}
            options={yearOptions}
            data-testid={suffixTestId('manufacturedOnYear', props)}
          />
          <FormField
            control={props.control}
            type="choice"
            name="manufacturedOnMonth"
            label={i18n.t('entity.vehicle.labels.realProductionMonth')}
            options={monthOptions}
            data-testid={suffixTestId('manufacturedOnMonth', props)}
          />
        </Grid>
        <FormField
          control={props.control}
          type="textarea"
          name="note"
          label={i18n.t('entity.invoice.labels.internalNote')}
          data-testid={suffixTestId('note', props)}
          isResizable
        />
        <Separator spacing={0} />
        <FormField
          control={props.control}
          type="chips"
          name="bodyStyle"
          options={vehicleUtils.vehicleStyleOptions ?? []}
          label={i18n.t('entity.vehicle.labels.body')}
          data-testid={suffixTestId('bodyStyle', props)}
        />
        <Hide
          when={
            typedVehicleType === 'VEHICLETYPE_SEMI_TRAILER' ||
            typedVehicleType === 'VEHICLETYPE_TRAILER'
          }
        >
          <FuelField
            control={props.control}
            formApi={props.formApi}
            vehicleType={typedVehicleType}
            data-testid={suffixTestId('fuel', props)}
          />
          <Grid columns={2}>
            <FormField
              control={props.control}
              type="chips"
              name="transmission"
              options={vehicleUtils.transmissionOptions ?? []}
              label={i18n.t('entity.vehicle.labels.transmission')}
              data-testid={suffixTestId('transmission', props)}
            />
            <FormField
              control={props.control}
              type="chips"
              name="drive"
              options={vehicleUtils.driveOptions ?? []}
              label={i18n.t('entity.vehicle.labels.drive')}
              data-testid={suffixTestId('drive', props)}
            />
            <FormField
              control={props.control}
              type="chips"
              name="exteriorColorSpecification"
              options={vehicleUtils.bodyColorTypeOptions ?? []}
              label={i18n.t('entity.vehicle.labels.bodyColorType')}
              data-testid={suffixTestId('exteriorColorSpecification', props)}
            />
          </Grid>
          <Grid columns={4}>
            <FormField
              control={props.control}
              type="choice"
              name="exteriorColor.value"
              options={vehicleUtils.bodyColorOptions ?? []}
              menuInPortal
              label={i18n.t('entity.vehicle.labels.bodyColor')}
              data-testid={suffixTestId('bodyColor', props)}
            />
            <FormField
              control={props.control}
              type="text"
              name="exteriorColor.name"
              label={i18n.t('entity.vehicle.labels.manufacturerColorName')}
              data-testid={suffixTestId('manufacturerColorName', props)}
            />
            <FormField
              control={props.control}
              type="text"
              name="exteriorColor.code"
              label={i18n.t('entity.vehicle.labels.manufacturerColorCode')}
              data-testid={suffixTestId('manufacturerColorCode', props)}
            />
          </Grid>
        </Hide>
      </VStack>
    </DataStatus>
  );
}
