import type {PermissionsRecordType} from '../permissions';

export const warehousePermissions = {
  vehicleWarehouseRead: {
    actionId: 'READ',
    resourceId: 'VEHICLE_WAREHOUSE',
    scopes: [],
  },
  vehicleWarehouseCreate: {
    actionId: 'CREATE',
    resourceId: 'VEHICLE_WAREHOUSE',
    scopes: [],
  },
  vehicleWarehouseEdit: {
    actionId: 'UPDATE',
    resourceId: 'VEHICLE_WAREHOUSE',
    scopes: [],
  },
  pneuhotelRead: {
    resourceId: 'TIRE_ORDER',
    actionId: 'READ',
    scopes: [],
  },
  articleRead: {
    resourceId: 'ARTICLE',
    actionId: 'READ',
    scopes: [],
  },
  receiveNoteCreate: {
    resourceId: 'RECEIVE_NOTE',
    actionId: 'CREATE',
    scopes: [],
  },
  receiveNoteRead: {
    resourceId: 'RECEIVE_NOTE',
    actionId: 'READ',
    scopes: [],
  },
  receiveNoteEdit: {
    resourceId: 'RECEIVE_NOTE',
    actionId: 'EDIT',
    scopes: [],
  },
  receiveNoteDelete: {
    resourceId: 'RECEIVE_NOTE',
    actionId: 'DELETE',
    scopes: [],
  },
  receiveNoteComplete: {
    resourceId: 'RECEIVE_NOTE',
    actionId: 'COMPLETE',
    scopes: [],
  },
  receiveNoteCorrective: {
    resourceId: 'RECEIVE_NOTE',
    actionId: 'CORRECTIVE',
    scopes: [],
  },
} satisfies PermissionsRecordType;
