import {ALL_DAY_EVENT_HEIGHT_IN_PIXELS} from '../constants/allDayEventHeight';
import {BigCalendarEvent} from '../types/BigCalendarEvent';
import {getEventsForDate} from './getEventsForDate';

export function calculateAllDayEventsHeight(events: BigCalendarEvent[], weekDays: Date[]): number {
  if (!events.length) {
    return 0;
  }

  const maxAllDayEvents = weekDays.reduce((max, date) => {
    const allDayEventsCount = getEventsForDate(events, date).allDayEvents?.length;
    return Math.max(max, allDayEventsCount);
  }, 0);

  if (!maxAllDayEvents) {
    return 0;
  }

  return maxAllDayEvents * ALL_DAY_EVENT_HEIGHT_IN_PIXELS;
}
