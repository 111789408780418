import {Card, Choice, DataStatus, FormControl, FormField} from 'platform/components';
import {Box, Heading, HStack, Space, Text, VStack} from 'platform/foundation';

import {Path, UseFormReturn} from 'react-hook-form';

import {not} from 'ramda';
import {isNilOrEmpty, isPositive} from 'ramda-adjunct';

import {useGetServiceOrderQuery} from '@dms/api/metadaWorkshopServiceOrder';
import {useGetServiceOrderVariantQuery} from '@dms/api/metadaWorkshopServiceOrderVariant';
import i18n from '@dms/i18n';
import {EMPTY_PLACEHOLDER} from '@dms/shared';

import {DOT_CHARACTER, Nullish, suffixTestId, TestIdProps} from 'shared';

import {GoodwillFormType} from '../types/GoodwillFormType';

interface GoodwillOrderItemProps extends TestIdProps {
  serviceCaseId: string;
  id: string;
  control: FormControl<GoodwillFormType>;
  formApi: UseFormReturn<GoodwillFormType>;
  index: number;
  isCurrentOrder: boolean;
  requestName: string | Nullish;
  onRatioChange: () => void;
}

export function GoodwillOrderItem(props: GoodwillOrderItemProps) {
  const {
    data: serviceOrder,
    isLoading: isServiceOrderLoading,
    isError: isServiceOrderError,
  } = useGetServiceOrderQuery(
    {
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.id ?? '',
    },
    {skip: isNilOrEmpty(props.id)}
  );
  const {
    data: serviceOrderVariant,
    isLoading: isServiceOrderVariantLoading,
    isError: isServiceOrderVariantError,
  } = useGetServiceOrderVariantQuery(
    {serviceOrderVariantId: serviceOrder?.serviceOrderVariantId ?? ''},
    {skip: isNilOrEmpty(serviceOrder?.serviceOrderVariantId)}
  );

  const handleRatioChange = () => {
    if (not(props.isCurrentOrder)) {
      props.onRatioChange();
    }
  };

  const isLoading = isServiceOrderLoading || isServiceOrderVariantLoading;
  const isError = isServiceOrderError || isServiceOrderVariantError;

  const ratioName: Path<GoodwillFormType> = `orders.${props.index}.ratio`;

  return (
    <Card variant={props.isCurrentOrder ? 'inlineGrey' : 'inlineWhite'}>
      <DataStatus isLoading={isLoading} isError={isError}>
        <HStack>
          <Box flex={1}>
            <VStack spacing={1}>
              <HStack spacing={2}>
                <Heading size={4} data-testid={suffixTestId('orderName', props)}>
                  {serviceOrder?.number}
                </Heading>
                <Heading size={4}>{DOT_CHARACTER}</Heading>
                <Text data-testid={suffixTestId('orderVariantName', props)}>
                  {serviceOrderVariant?.name}
                </Text>
              </HStack>
              <Text color="tertiary" size="small">
                {`${i18n.t('general.labels.request')}: ${props.requestName ?? EMPTY_PLACEHOLDER}`}
              </Text>
            </VStack>
          </Box>
          <VStack spacing={1} align="flex-end">
            <Text color="tertiary" size="small">
              {i18n.t('entity.orderRequest.labels.ratio')}
            </Text>
            <Heading
              size={3}
              color={isPositive(props.formApi.watch(ratioName)) ? 'primary' : 'tertiary'}
              data-testid={suffixTestId('orderName', props)}
            >
              {`${props.formApi.watch(ratioName) ?? 0} %`}
            </Heading>
          </VStack>
        </HStack>
        <Space vertical={4} />
        <HStack spacing={4}>
          <Box flex={1}>
            <FormField
              control={props.control}
              name={ratioName}
              type="number"
              label={`${i18n.t('entity.orderRequest.labels.ratio')} (%)`}
              onChange={handleRatioChange}
              data-testid={suffixTestId('ratio', props)}
            />
          </Box>
          <Box flex={1}>
            <Choice
              label={i18n.t('entity.orderRequest.labels.orderVariant')}
              value={serviceOrderVariant?.id ?? ''}
              options={[{label: serviceOrderVariant?.name ?? '', value: serviceOrderVariant?.id}]}
              data-testid={suffixTestId('orderVariant', props)}
              isDisabled
              menuInPortal
            />
          </Box>
        </HStack>
      </DataStatus>
    </Card>
  );
}
