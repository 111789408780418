import {Card, Chips, DataStatus, Option} from 'platform/components';

import {head, isNil} from 'ramda';

import {
  AbroadSaleType,
  useGetCheckoutBillingInformationQuery,
  usePostAllowAbroadSaleMutation,
  usePostDisallowAbroadSaleMutation,
} from '@dms/api/metadaAftersalesCheckout';
import i18n from '@dms/i18n';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {handleApiError} from '../../../utils/handleApiError';

const ABROAD_SALE_OPTIONS: Option<AbroadSaleType>[] = [
  {
    label: i18n.t('entity.checkout.labels.abroadSaleEU'),
    value: 'IN_EUROPE',
  },
  {
    label: i18n.t('entity.checkout.labels.abroadSaleOutsideEU'),
    value: 'OUT_OF_EUROPE',
  },
];

interface AbroadSaleProps extends RequiredTestIdProps {
  checkoutId: string;
}

export function AbroadSale(props: AbroadSaleProps) {
  const {
    data: billingInformation,
    isLoading: isBillingInformationLoading,
    isFetching: isBillingInformationFeatching,
    isError: isBillingInformationError,
  } = useGetCheckoutBillingInformationQuery({checkoutId: props.checkoutId});

  const [allowAbroadSale, {isLoading: isAllowingLoading}] = usePostAllowAbroadSaleMutation();
  const [disallowAbroadSale, {isLoading: isDisallowingLoading}] =
    usePostDisallowAbroadSaleMutation();

  const handleAbroadSale = (shouldAllow: boolean) => {
    const request = shouldAllow
      ? allowAbroadSale({checkoutId: props.checkoutId, body: {abroadSale: 'IN_EUROPE'}})
      : disallowAbroadSale({checkoutId: props.checkoutId});

    request.unwrap().catch(handleApiError);
  };

  const hanldeAbroadSaleChange = (value: string[] | Nullish) => {
    const abroadSale = head(value ?? []);

    if (isNil(abroadSale)) {
      return disallowAbroadSale({checkoutId: props.checkoutId}).unwrap().catch(handleApiError);
    }

    return allowAbroadSale({
      checkoutId: props.checkoutId,
      body: {abroadSale: abroadSale as AbroadSaleType},
    })
      .unwrap()
      .catch(handleApiError);
  };

  const isAbroadSaleAllowed = !!billingInformation?.billingInformation.isAbroadSaleAllowed;
  const abroadSale = billingInformation?.billingInformation.abroadSale;

  return (
    <DataStatus
      isLoading={
        isAllowingLoading ||
        isDisallowingLoading ||
        isBillingInformationLoading ||
        isBillingInformationFeatching
      }
      isError={isBillingInformationError}
    >
      <Card
        variant="inlineGrey"
        title={i18n.t('entity.checkout.labels.abroadSale')}
        control={{type: 'switch', value: isAbroadSaleAllowed, onChange: handleAbroadSale}}
        isExpanded={isAbroadSaleAllowed}
        data-testid={suffixTestId('abroadSale', props)}
      >
        <Chips
          options={ABROAD_SALE_OPTIONS}
          value={abroadSale && [abroadSale]}
          onChange={hanldeAbroadSaleChange}
          data-testid={suffixTestId('abroadSale', props)}
        />
      </Card>
    </DataStatus>
  );
}
