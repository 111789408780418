import {openDialog} from 'platform/components';

import i18n from '@dms/i18n';

import {RequiredTestIdProps} from 'shared';

import {CreateTask} from '../components/CreateTask/CreateTask';
import {Task} from '../types/TaskForm';
import {TaskRelationProps} from '../types/TaskRelationProps';

interface OpenTaskFormDialogProps extends RequiredTestIdProps, TaskRelationProps {
  values?: Partial<Task>;
  onCreate?: () => void;
}

export function openCreateTaskFormDialog(props: OpenTaskFormDialogProps) {
  openDialog(
    <CreateTask
      onCreate={props.onCreate}
      data-testid={props['data-testid']}
      values={props.values}
      relatedRecordId={props.relatedRecordId}
      resourceId={props.resourceId}
    />,
    {
      'data-testid': props['data-testid'],
      title: i18n.t('entity.task.actions.createTask'),
      size: 'large',
      scrollBehavior: 'outside',
      withAdditionalFooter: true,
    }
  );
}
