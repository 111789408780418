import {MenuBadge} from 'platform/components';

import {useEffect, useState} from 'react';

export interface UseChecklistProgress {
  totalCount: number;
  doneCount: number;
  progress: number;
}

interface UseChecklistBadgeProps {
  progress: UseChecklistProgress[];
}

export function useChecklistBadge(props: UseChecklistBadgeProps) {
  const [badgeSettings, setBadgeSettings] = useState<MenuBadge | undefined>();
  const {progress} = props;

  const progressCount = progress.map((item) => item.progress).reduce((acc, item) => acc + item, 0);
  const totalCount = progress.map((item) => item.totalCount).reduce((acc, item) => acc + item, 0);
  const doneCount = progress.map((item) => item.doneCount).reduce((acc, item) => acc + item, 0);

  useEffect(() => {
    if (totalCount === 0 && doneCount === 0) {
      setBadgeSettings(undefined);
      return;
    }

    if (progressCount < 0.5) {
      setBadgeSettings({
        colorScheme: 'red',
        value: totalCount - doneCount,
      });
      return;
    }

    if (doneCount === totalCount) {
      setBadgeSettings({
        icon: 'navigation/check',
        colorScheme: 'green',
      });
      return;
    }

    setBadgeSettings({
      colorScheme: 'orange',
      value: totalCount - doneCount,
    });
  }, [progressCount, totalCount, doneCount]);

  return {
    badgeSettings,
  };
}
