import {z} from 'zod';

import {BaseVoidResponseSchema} from '@dms/api/shared';

const PriceSchema = z.object({
  amount: z.number(),
  currency: z.string(),
});

const DiscountSchema = z.object({
  rate: z.number().nullable().optional(),
  withoutVat: PriceSchema,
  withVat: PriceSchema,
});

const LabourCatalogCategorySchema = z.object({
  id: z.string(),
  name: z.string().nullable().optional(),
});

const LabourCatalogSchema = z.object({
  id: z.string(),
  category: LabourCatalogCategorySchema.nullable().optional(),
});

const TooltipSchema = z.object({
  label: z.string(),
  value: z.string(),
});

const MuloConversionSchema = z
  .object({
    conversionRatio: z.number(),
    quantity: z.number(),
    mulo: z.string(),
  })
  .nullable()
  .optional();

const WorkBasketItemSchema = z.object({
  id: z.string(),
  itemEditingAllowed: z.boolean(),
  priceType: z.string(),
  priceTypeName: z.string().nullable().optional(),
  name: z.string(),
  number: z.string(),
  labourCatalog: LabourCatalogSchema.nullable().optional(),
  quantity: z.number(),
  unit: z.string(),
  quantityEditingAllowed: z.boolean(),
  isDoNotApplyDiscount: z.boolean(),
  workType: z.string(),
  workTypeName: z.string().nullable().optional(),
  unitPrice: z.object({
    withoutVat: PriceSchema,
    withVat: PriceSchema,
  }),
  discount: DiscountSchema,
  totalPrice: z.object({
    withoutVat: PriceSchema,
    withVat: PriceSchema,
  }),
  purchasePrice: z.object({
    withoutVat: PriceSchema,
    withVat: PriceSchema,
  }),
  muloConversion: MuloConversionSchema,
  tooltip: z.array(TooltipSchema),
});

export const GetWorkBasketItemsApiResponseSchema = z
  .object({
    workBasketItem: z.array(WorkBasketItemSchema).nullable().optional(),
    workBasketTotalPrice: z.object({
      withoutVat: PriceSchema,
      withVat: PriceSchema,
    }),
    workBasketTotalPurchasePrice: z.object({
      withoutVat: PriceSchema,
      withVat: PriceSchema,
    }),
  })
  .nullable();

export type GetWorkBasketItemsApiResponse = z.infer<typeof GetWorkBasketItemsApiResponseSchema>;

export const GetWorkBasketItemsApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  serviceJobId: z.string(),
});

export type GetWorkBasketItemsApiArg = z.infer<typeof GetWorkBasketItemsApiArgSchema>;

export const DeleteWorkBasketItemsApiResponseSchema = BaseVoidResponseSchema;

export type DeleteWorkBasketItemsApiResponse = z.infer<
  typeof DeleteWorkBasketItemsApiResponseSchema
>;

export const DeleteWorkBasketItemsApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  serviceJobId: z.string(),
  body: z
    .object({
      serviceItemId: z.array(z.string()),
    })
    .nullable(),
});

export type DeleteWorkBasketItemsApiArg = z.infer<typeof DeleteWorkBasketItemsApiArgSchema>;

export const PostWorkBasketItemsToOrderApiResponseSchema = BaseVoidResponseSchema;

export type PostWorkBasketItemsToOrderApiResponse = z.infer<
  typeof PostWorkBasketItemsToOrderApiResponseSchema
>;

export const PostWorkBasketItemsToOrderApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  serviceJobId: z.string(),
  body: z
    .object({
      assignMechanics: z.array(
        z
          .object({
            id: z.string(),
            isDefault: z.boolean(),
            costCenterId: z.string().nullable().optional(),
          })
          .nullable()
      ),
    })
    .optional(),
});

export type PostWorkBasketItemsToOrderApiArg = z.infer<
  typeof PostWorkBasketItemsToOrderApiArgSchema
>;

export const PostWorkBasketItemFromLabourCatalogApiResponseSchema = z
  .object({
    workBasketItem: z
      .object({
        id: z.string().nullable().optional(),
      })
      .nullable()
      .optional(),
  })
  .nullable();

export type PostWorkBasketItemFromLabourCatalogApiResponse = z.infer<
  typeof PostWorkBasketItemFromLabourCatalogApiResponseSchema
>;

export const PostWorkBasketItemFromLabourCatalogApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  serviceJobId: z.string(),
  body: z.object({
    labourCatalogId: z.array(z.string()),
  }),
});

export type PostWorkBasketItemFromLabourCatalogApiArg = z.infer<
  typeof PostWorkBasketItemFromLabourCatalogApiArgSchema
>;

export const PatchWorkBasketItemApiResponseSchema = z
  .object({
    workBasketItem: z
      .object({
        id: z.string().nullable().optional(),
        itemEditingAllowed: z.boolean().nullable().optional(),
        priceType: z.string().nullable().optional(),
        name: z.string().nullable().optional(),
        labourCatalog: LabourCatalogSchema.nullable().optional(),
        technican: z.string().nullable().optional(),
        quantity: z.number().nullable().optional(),
        unit: z.string().nullable().optional(),
        quantityEditingAllowed: z.boolean().nullable().optional(),
        unitPrice: z
          .object({
            withVat: PriceSchema.nullable().optional(),
            withoutVat: PriceSchema.nullable().optional(),
            vat: PriceSchema.nullable().optional(),
          })
          .nullable()
          .optional(),
        totalPrice: z
          .object({
            withVat: PriceSchema.nullable().optional(),
            withoutVat: PriceSchema.nullable().optional(),
            vat: PriceSchema.nullable().optional(),
          })
          .nullable()
          .optional(),
      })
      .nullable()
      .optional(),
  })
  .nullable();

export type PatchWorkBasketItemApiResponse = z.infer<typeof PatchWorkBasketItemApiResponseSchema>;

export const PatchWorkBasketItemApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  serviceJobId: z.string(),
  serviceItemId: z.string(),
  body: z
    .object({
      quantity: z.number().nullable().optional(),
      technican: z.string().nullable().optional(),
    })
    .nullable(),
});

export type PatchWorkBasketItemApiArg = z.infer<typeof PatchWorkBasketItemApiArgSchema>;

export const PostWorkBasketItemWithCalculationPriceApiResponseSchema = z
  .object({
    workBasketItem: z
      .object({
        id: z.string().nullable().optional(),
      })
      .nullable()
      .optional(),
  })
  .nullable();

export type PostWorkBasketItemWithCalculationPriceApiResponse = z.infer<
  typeof PostWorkBasketItemWithCalculationPriceApiResponseSchema
>;

export const PostWorkBasketItemWithCalculationPriceApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  serviceJobId: z.string(),
  body: z
    .object({
      id: z.string().nullable().optional(),
      name: z.string().nullable(),
      number: z.string().nullable().optional(),
      workType: z.string().nullable().optional(),
      unit: z.string().nullable().optional(),
      purchaseUnitPrice: z.number().nullable().optional(),
      sellingUnitPrice: z.number().nullable().optional(),
      vatType: z.string().nullable().optional(),
      timeOutputPerUnit: z.number().nullable().optional(),
      timeOutputUnit: z.string().nullable().optional(),
      isDoNotApplyDiscount: z.boolean().nullable().optional(),
      isUnitPriceWithVat: z.boolean().nullable().optional(),
      quantity: z.number().nullable().optional(),
      currency: z.string().nullable().optional(),
    })
    .nullable(),
});

export type PostWorkBasketItemWithCalculationPriceApiArg = z.infer<
  typeof PostWorkBasketItemWithCalculationPriceApiArgSchema
>;

export const PostWorkBasketItemWithCooperationApiResponseSchema = z
  .object({
    workBasketItem: z
      .object({
        id: z.string().nullable().optional(),
      })
      .nullable()
      .optional(),
  })
  .nullable();

export type PostWorkBasketItemWithCooperationApiResponse = z.infer<
  typeof PostWorkBasketItemWithCooperationApiResponseSchema
>;

export const PostWorkBasketItemWithCooperationApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  serviceJobId: z.string(),
  body: z
    .object({
      id: z.string().nullable().optional(),
      name: z.string().nullable().optional(),
      number: z.string().nullable().optional(),
      workType: z.string().nullable().optional(),
      purchaseUnitPrice: z.number().nullable().optional(),
      sellingUnitPrice: z.number().nullable().optional(),
      vatType: z.string().nullable().optional(),
      currency: z.string().nullable().optional(),
      timeOutputPerUnit: z.number().nullable().optional(),
      timeOutputUnit: z.string().nullable().optional(),
      isDoNotApplyDiscount: z.boolean().nullable().optional(),
      isUnitPriceWithVat: z.boolean().nullable().optional(),
      unit: z.string().nullable(),
    })
    .nullable(),
});

export type PostWorkBasketItemWithCooperationApiArg = z.infer<
  typeof PostWorkBasketItemWithCooperationApiArgSchema
>;

export const PostWorkBasketItemWithDirectPriceApiResponseSchema = z
  .object({
    workBasketItem: z
      .object({
        id: z.string().nullable().optional(),
      })
      .nullable()
      .optional(),
  })
  .nullable();

export type PostWorkBasketItemWithDirectPriceApiResponse = z.infer<
  typeof PostWorkBasketItemWithDirectPriceApiResponseSchema
>;

export const PostWorkBasketItemWithDirectPriceApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  serviceJobId: z.string(),
  body: z
    .object({
      id: z.string().nullable().optional(),
      name: z.string().nullable().optional(),
      number: z.string().nullable().optional(),
      workType: z.string().nullable().optional(),
      purchaseUnitPrice: z.number().nullable().optional(),
      sellingUnitPrice: z.number().nullable().optional(),
      vatType: z.string().nullable().optional(),
      timeOutputPerUnit: z.number().nullable().optional(),
      timeOutputUnit: z.string().nullable().optional(),
      isDoNotApplyDiscount: z.boolean().nullable().optional(),
      isUnitPriceWithVat: z.boolean().nullable().optional(),
      unit: z.string().nullable().optional(),
      currency: z.string().nullable().optional(),
    })
    .nullable(),
});

export type PostWorkBasketItemWithDirectPriceApiArg = z.infer<
  typeof PostWorkBasketItemWithDirectPriceApiArgSchema
>;

export const PostWorkBasketItemWithTimeNormApiResponseSchema = z
  .object({
    workBasketItem: z
      .object({
        id: z.string().nullable().optional(),
      })
      .nullable()
      .optional(),
  })
  .nullable();

export type PostWorkBasketItemWithTimeNormApiResponse = z.infer<
  typeof PostWorkBasketItemWithTimeNormApiResponseSchema
>;

export const PostWorkBasketItemWithTimeNormApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  serviceJobId: z.string(),
  body: z
    .object({
      labourCatalogCategoryId: z.string().nullable().optional(),
      name: z.string().nullable().optional(),
      number: z.string().nullable().optional(),
      workType: z.string().nullable().optional(),
      unit: z.string().nullable().optional(),
      quantity: z.number().nullable().optional(),
      vatType: z.string().nullable().optional(),
      isDoNotApplyDiscount: z.boolean().nullable().optional(),
    })
    .nullable(),
});

export type PostWorkBasketItemWithTimeNormApiArg = z.infer<
  typeof PostWorkBasketItemWithTimeNormApiArgSchema
>;

export const PostServiceItemWorkBasketWithSubcontractApiResponseSchema = z
  .object({
    workBasketItem: z
      .object({
        id: z.string().nullable().optional(),
      })
      .nullable()
      .optional(),
  })
  .nullable();

export type PostServiceItemWorkBasketWithSubcontractApiResponse = z.infer<
  typeof PostServiceItemWorkBasketWithSubcontractApiResponseSchema
>;

export const PostServiceItemWorkBasketWithSubcontractApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  serviceJobId: z.string(),
  body: z
    .object({
      name: z.string(),
      number: z.string().nullable().optional(),
      workType: z.string().nullable().optional(),
      unit: z.string().nullable().optional(),
      purchaseUnitPrice: z.number().nullable().optional(),
      sellingUnitPrice: z.number(),
      vatType: z.string(),
      vatFreeCode: z.string().nullable().optional(),
      isUnitPriceWithVat: z.boolean(),
      currency: z.string(),
      isDoNotApplyDiscount: z.boolean().nullable().optional(),
      discountRate: z.number().nullable().optional(),
    })
    .nullable(),
});

export type PostServiceItemWorkBasketWithSubcontractApiArg = z.infer<
  typeof PostServiceItemWorkBasketWithSubcontractApiArgSchema
>;
