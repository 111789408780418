import {RentalVehicleRequestBody} from '@dms/api/rentalVehicles';

import {RentalVehicleFormType} from '../types/RentalVehicleFormType';

export const convertFormVehicleToRentalVehicle = ({
  rentalProperties,
}: Pick<RentalVehicleFormType, 'rentalProperties'>): RentalVehicleRequestBody => ({
  availability: {
    from: rentalProperties.availability.from || null,
    mileageLimit: rentalProperties.availability.mileageLimit || null,
    to: rentalProperties.availability.to || null,
  },
  carParkId: rentalProperties.carParkId || null,
  equipment: rentalProperties.equipment || [],
  note: rentalProperties.note || null,
  rentalPriceGroup: rentalProperties.rentalPriceGroup
    ? {
        rentalPriceGroupId: rentalProperties.rentalPriceGroup.rentalPriceGroupId,
        rentalPriceGroupItemId: rentalProperties.rentalPriceGroup.rentalPriceGroupItemId,
      }
    : null,
});
