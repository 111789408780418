import {Box, VStack, Center, Clickable, Icon, getColorByPath} from 'platform/foundation';
import styled from 'styled-components';

import {ActivitySidebarAction} from '../types/ActivitySidebarAction';

interface ActivitySidebarMenuProps {
  activeTab: string | null;
  actions: ActivitySidebarAction[];
  onClick: (tab: string) => void;
}

export function ActivitySidebarMenu(props: ActivitySidebarMenuProps) {
  return (
    <Box width={12} padding={2} backgroundColor="general.white" height="100%" zIndex="SIDEBAR">
      <VStack spacing={4}>
        {props.actions.map((action) => (
          <Clickable
            key={action.label}
            isDisabled={action.disabled}
            onClick={() => props.onClick(action.label)}
          >
            <HoverableBox isActive={props.activeTab === action.label} disabled={action.disabled}>
              <Center width="100%" height="100%">
                <Icon size={5} value={action.icon} color="palettes.neutral.800.100" />
              </Center>
            </HoverableBox>
          </Clickable>
        ))}
      </VStack>
    </Box>
  );
}

interface HoverableBoxProps {
  isActive: boolean;
  disabled?: boolean;
}

const HoverableBox = styled.div<HoverableBoxProps>`
  height: ${({theme}) => theme.getSize(8)};
  width: ${({theme}) => theme.getSize(8)};
  padding: ${({theme}) => theme.getSize(2)};
  border-radius: ${({theme}) => theme.radii.small};
  background-color: ${({theme, isActive}) =>
    isActive ? theme.colors.palettes.blue[20][100] : getColorByPath('general.white', theme)};
  opacity: ${({disabled}) => (disabled ? 0.4 : 1)};

  &:hover {
    background-color: ${({theme, isActive}) =>
      !isActive ? theme.colors.palettes.neutral[20][100] : undefined};
  }
`;
