import {Avatar, DataStatus, TableCellBaseProps} from 'platform/components';
import {Box, Hide, HStack, Show, Text, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {useGetUserAvatarUrlQuery, useGetUserQuery} from '@dms/api/user';

import {EMPTY_PLACEHOLDER, Nullish, parseDate} from 'shared';

import {TableCellType} from '../types/TableCellType';

interface UserCellProps extends TableCellBaseProps<TableCellType> {
  userId: string | Nullish;
  finishedAt: string | Nullish;
}

export function UserCell(props: UserCellProps) {
  const formatDate = useDateTimeFormatter();
  const finishedAt = props.finishedAt
    ? formatDate('dateTimeShort', parseDate(props.finishedAt))
    : EMPTY_PLACEHOLDER;

  const userQuery = useGetUserQuery({id: props.userId || ''}, {skip: !props.userId});

  const userAvatarUrlQuery = useGetUserAvatarUrlQuery(
    {userId: props.userId || ''},
    {skip: !props.userId}
  );

  const user = userQuery.data;
  const userName =
    user?.firstName && user?.lastName ? `${user.firstName} ${user.lastName}` : EMPTY_PLACEHOLDER;

  return (
    <>
      <Show when={props.userId}>
        <DataStatus isLoading={userQuery.isLoading}>
          <Box paddingHorizontal={2} paddingVertical={2}>
            <HStack spacing={2} align="center">
              <Avatar size="xSmall" imageUrl={userAvatarUrlQuery.data} />
              <VStack>
                <Text size="xSmall">{userName}</Text>
                <Text color="tertiary" size="xxSmall">
                  {finishedAt}
                </Text>
              </VStack>
            </HStack>
          </Box>
        </DataStatus>
      </Show>
      <Hide when={props.userId}>
        <Box paddingHorizontal={2} paddingVertical={2}>
          <Text>{EMPTY_PLACEHOLDER}</Text>
        </Box>
      </Hide>
    </>
  );
}
