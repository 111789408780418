import {useGetParticipationQuery} from '@dms/api/participation';
import {EntityResourceIds} from '@dms/api/shared';
import {useGetVehicleQuery} from '@dms/api/vehicle';

import {usePermissions} from './usePermissions/usePermissions';

interface VehiclePhotosPermissions {
  vehicleId: string;
}

export function useVehiclePhotosPermissions(props: VehiclePhotosPermissions) {
  const {data: vehicleParticipation} = useGetParticipationQuery({
    resourceId: EntityResourceIds.vehicle,
    recordId: props.vehicleId,
  });

  const {data: vehicle} = useGetVehicleQuery({vehicleId: props.vehicleId});

  const [canReadVehicleAlbumPhotos] = usePermissions({
    permissionKeys: ['readVehicleAlbumPhotos'],
    scopes: {
      readVehicleAlbumPhotos: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
    },
  });

  const permissionsMap = {
    canReadVehicleAlbumPhotos,
  } as const;

  return permissionsMap;
}
