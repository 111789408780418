import {showNotification} from 'platform/components';
import {match, Pattern} from 'ts-pattern';

import {always, isNil, isNotNil} from 'ramda';

import {
  useCreateManuallyTaxDocumentForPaymentMutation,
  useGetTaxDocumentSettingListQuery,
} from '@dms/api/accounting';
import {
  useGetBusinessCaseQuery,
  useGetBusinessCaseSeriesDefinitionListQuery,
} from '@dms/api/businessCase';
import {OrderResponseBody} from '@dms/api/commission';
import {IssuedType} from '@dms/api/metadaErrorLogs';
import {useGetSaleVehicleFromSaleVehicleIdQuery} from '@dms/api/saleVehicle';
import {useGetVehicleWarehouseQuery} from '@dms/api/vehicleWarehouse';
import {handleApiError, useGetCurrentBranch} from '@dms/shared';

import {Nullish} from 'shared';

export const useGetCreateTaxDocumentForPayment = (
  order: OrderResponseBody | Nullish,
  businessCaseId: string | Nullish
) => {
  const {data: taxDocumentGenerationSettings} = useGetTaxDocumentSettingListQuery();
  const {activeBranchId} = useGetCurrentBranch();
  const {data: businessCase} = useGetBusinessCaseQuery(
    {businessCaseId: businessCaseId ?? ''},
    {skip: isNil(businessCaseId)}
  );

  const saleVehicleId = businessCase?.saleVehicleId;
  const {data: saleVehicle, isLoading: isLoadingSaleVehicle} =
    useGetSaleVehicleFromSaleVehicleIdQuery(
      {vehicleId: saleVehicleId as string},
      {skip: isNil(saleVehicleId)}
    );

  const vehicleWarehouseId = saleVehicle?.vehicleWarehouse?.id;
  const {data: vehicleWareHouse, isLoading: isLoadingWareHouse} = useGetVehicleWarehouseQuery(
    {vehicleWarehouseId: vehicleWarehouseId as string},
    {skip: isNil(vehicleWarehouseId)}
  );

  const isBrokerageBusinessCase = isNotNil(businessCase?.brokerageBusinessCase);

  const {data: businessCaseDefinitions} = useGetBusinessCaseSeriesDefinitionListQuery();

  const activeDefinition =
    businessCaseDefinitions?.find((item) => item.branchId === businessCase?.branchId) ??
    businessCaseDefinitions?.find((item) => item.systemDefault) ??
    null;

  const seriesId = match([
    order?.orderDiscriminator,
    isBrokerageBusinessCase,
    vehicleWareHouse?.overrideBusinessCaseSeries,
  ])
    .with(
      ['SALE', false, true],
      () => vehicleWareHouse?.taxDocumentSeriesId || activeDefinition?.taxDocumentSeriesId
    )
    .with(
      ['SALE', true, false],
      ['SALE', true, undefined],
      always(activeDefinition?.saleBrokerageTaxDocumentSeriesId)
    )
    .with(
      ['PURCHASE_BROKERAGE_SALE', Pattern.boolean, Pattern._],
      ['PURCHASE_BROKERAGE_FEES', Pattern.boolean, Pattern._],
      always(activeDefinition?.brokerageFeesTaxDocumentSeriesId)
    )
    .otherwise(always(activeDefinition?.taxDocumentSeriesId));

  const [createDocument, {isLoading: isCreatingDocument}] =
    useCreateManuallyTaxDocumentForPaymentMutation();

  const defaultSettings = taxDocumentGenerationSettings?.find((item) => item.isSystem);
  const branchSettings = taxDocumentGenerationSettings?.find(
    (item) => item.branchId === activeBranchId
  );

  const usedTaxDocumentGenerationSettings = branchSettings ?? defaultSettings;

  const issuedType: IssuedType = usedTaxDocumentGenerationSettings?.issuedType ?? 'not_create';

  const isLoading = isLoadingSaleVehicle || isLoadingWareHouse;

  const createTaxDocumentFormPayment = async (transactionId: string) => {
    if (issuedType !== 'manual') {
      return;
    }
    // should never happen, only to satisfy typescript
    if (isNil(seriesId) || isNil(usedTaxDocumentGenerationSettings)) {
      return showNotification.error('Cannot create document. Generation settings are not found');
    }

    await createDocument({
      transactionId,
      seriesId,
    })
      .unwrap()
      .catch(handleApiError);
  };

  return [createTaxDocumentFormPayment, issuedType, isCreatingDocument, isLoading] as const;
};
