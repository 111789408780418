import {Mutator} from 'final-form';
import {Button, Card, Dropdown, DropdownItem, IconButton, Separator} from 'platform/components';
import {Box, Grid, GridItem, HStack, Right} from 'platform/foundation';
import {match} from 'ts-pattern';

import {FC, ReactElement} from 'react';
import {useSelector} from 'react-redux';

import {always} from 'ramda';

import {
  AddressRequestBodyV2,
  AddressResponseBodyV2,
  ParsePersonalIdApiArg,
  PersonRequestBody,
  PersonResponseBodyV2,
  useParsePersonalIdMutation,
} from '@dms/api/customer';
import i18n from '@dms/i18n';
import {handleApiError, useTenant} from '@dms/shared';
import {
  $EmailData,
  $IdentityCardRequestBody,
  $PersonRequestBody,
  $PhoneNumberData,
  AutoSave,
  COUNTRY_CZE,
  EmailData,
  Form,
  FormRenderProps,
  getDefaultValuesFromSchema,
  IdentityCardData,
  IdentityCardRequestBody,
  noop,
  PhoneNumberData,
  selectGenders,
} from '@dms/teas';

import {appendSuffix, suffixTestId, TestIdProps} from 'shared';

import {AddressSelect} from '../Address/AddressSelect';
import {IdentityCardForm} from '../IdentityCard/IdentityCardForm';

export interface PersonFormProps {
  initialValues?: PersonResponseBodyV2;
  saveOnChange?: boolean;
  showRole?: boolean;
  showPersonalIdentifier?: boolean;
  showPhoneNumbers?: boolean;
  showEmails?: boolean;
  showIdentityCards?: boolean;
  showPersonData?: boolean;
  showAddress?: boolean;
  compactSelect?: boolean;
  useInitialValueAsOption?: boolean;
  address?: AddressResponseBodyV2[];
  selfEmployed?: ReactElement;
  WrapperComponent?: FC<FormRenderProps<PersonRequestBody, PersonResponseBodyV2>>;
  onSubmit?: (requestBody: PersonRequestBody) => Promise<Record<string, string> | void>;
  handleAddressSubmit?: (
    addressData: AddressRequestBodyV2,
    addressId?: string | null
  ) => Promise<string | null>;
  onChange?: (requestBody: PersonRequestBody) => void;
  loading?: boolean;
}

const isCountrySupported = (country: string) =>
  match(country).with('CZE', 'SVK', always(true)).otherwise(always(false));

export const PersonForm: FC<PersonFormProps & TestIdProps> = ({
  initialValues,
  onSubmit,
  handleAddressSubmit,
  onChange,
  loading,
  saveOnChange,
  showRole,
  showPhoneNumbers,
  showEmails,
  showPersonalIdentifier = true,
  showIdentityCards = true,
  showPersonData = true,
  showAddress = true,
  compactSelect = false,
  selfEmployed,
  WrapperComponent,
  useInitialValueAsOption,
  address,
  ...rest
}) => {
  const {tenantCountry, isTenantCountrySupported} = useTenant();
  const genderList = useSelector(selectGenders);

  const [parsePersonalId] = useParsePersonalIdMutation();

  const setBirthDateAndGender: Mutator<PersonRequestBody> = (
    [value]: string,
    state,
    {changeValue}
  ) => {
    let country = state.fields['personData.citizenshipCode']?.lastFieldState?.value;
    const personalId = value?.replace('/', '');

    if (isTenantCountrySupported(COUNTRY_CZE)) {
      country = tenantCountry;
    }

    if (!personalId || !isCountrySupported(country)) {
      return;
    }

    const args: ParsePersonalIdApiArg = {
      parsePersonalIdRequestBody: {
        personalId,
        country,
      },
    };

    parsePersonalId(args)
      .unwrap()
      .then(({birthdate, genderKey}) => {
        changeValue(state, 'personData.genderKey', () => genderKey);
        changeValue(state, 'personData.birthdate', () => birthdate);
      })
      .catch((error: Error) => handleApiError(error, {silent: true}));
  };

  const getFormValues = (person: PersonResponseBodyV2): PersonRequestBody => ({
    personData: {
      firstName: person.firstName,
      lastName: person.lastName,
      middleName: person.middleName,
      titleBefore: person.titleBefore,
      titleAfter: person.titleAfter,
      genderKey: person.genderKey,
      roles: person.roles,
      citizenshipCode: person.citizenshipCode,
      birthdate: person.birthdate,
      personalIdentifier: person.personalIdentifier,
    },
    phoneNumbers: person.phoneNumbers.map((phoneNumber) => ({
      type: phoneNumber.type,
      phoneNumber: {
        countryCode: phoneNumber.countryCode,
        prefix: phoneNumber.prefix,
        number: phoneNumber.number,
      },
    })),
    emails: person.emails,
    identityCards: person.identityCards.map((identityCard) => ({
      id: identityCard.id,
      cardData: {
        type: identityCard.type,
        cardNumber: identityCard.cardNumber,
        issuedOn: identityCard.issuedOn,
        validUntil: identityCard.validUntil,
        issuer: identityCard.issuer,
        issuedInCountryCode: identityCard.issuedInCountryCode,
        note: identityCard.note,
      },
    })),
    permanentAddressId: person.permanentAddress?.id ?? null,
  });

  return (
    <Form<PersonRequestBody, PersonResponseBodyV2>
      formId={appendSuffix('form', rest['data-testid'])}
      onSubmit={onSubmit}
      initialValues={initialValues}
      schema={$PersonRequestBody}
      defaultValues={{
        identityCards: [{}],
        phoneNumbers: [{}],
        emails: [{}],
      }}
      mutators={{setBirthDateAndGender}}
      getFormValues={getFormValues}
      render={(formRendererProps) => {
        const {
          Field,
          FieldArray,
          form: {
            mutators: {push, setBirthDateAndGender},
            getState,
          },
          handleSubmit,
        } = formRendererProps;

        const form = (
          <>
            {saveOnChange && (
              <AutoSave<PersonRequestBody> save={onChange || noop} saveOnChange={saveOnChange} />
            )}
            {showPersonData && (
              <Grid columns={2}>
                <HStack justify="space-between" spacing={2}>
                  <Box width={20}>
                    <Field
                      name="personData.titleBefore"
                      label={i18n.t('entity.person.labels.titleBefore')}
                    />
                  </Box>
                  <Box flex={1}>
                    <Field
                      name="personData.firstName"
                      label={i18n.t('entity.person.labels.firstName')}
                    />
                  </Box>
                </HStack>
                <HStack justify="space-between" spacing={2}>
                  <Box flex={1}>
                    <Field
                      name="personData.lastName"
                      label={i18n.t('entity.person.labels.lastName')}
                      required
                    />
                  </Box>
                  <Box width={20}>
                    <Field
                      name="personData.titleAfter"
                      label={i18n.t('entity.person.labels.titleAfter')}
                    />
                  </Box>
                </HStack>
              </Grid>
            )}

            {showPersonData && showRole && (
              <Grid columns={2}>
                <Field name="personData.roles" label={i18n.t('entity.person.labels.roles')} />
              </Grid>
            )}
            {showPersonData && (
              <>
                <Grid columns={2}>
                  <Field
                    as="country-select"
                    name="personData.citizenshipCode"
                    label={i18n.t('entity.person.labels.citizenshipCode')}
                  />
                  {showPersonalIdentifier && (
                    <Field
                      name="personData.personalIdentifier"
                      label={i18n.t('entity.person.labels.personalIdentifier')}
                      onBlur={() => {
                        const {personalIdentifier} = getState().values.personData;
                        setBirthDateAndGender(personalIdentifier);
                      }}
                    />
                  )}
                </Grid>
                <Grid columns={2}>
                  <Field
                    as="select"
                    name="personData.genderKey"
                    label={i18n.t('entity.person.labels.genderKey')}
                    options={genderList}
                    getOptionLabel={(genderType) => `${genderType.name}`}
                    getOptionValue={(genderType) => `${genderType.code}`}
                  />
                  <Field
                    as="date"
                    name="personData.birthdate"
                    label={i18n.t('entity.person.labels.birthdate')}
                  />
                </Grid>
              </>
            )}

            {selfEmployed && (
              <Grid columns={1}>
                <GridItem data-testid={suffixTestId('personForm-selfEmployed', rest)}>
                  {selfEmployed}
                </GridItem>
              </Grid>
            )}

            {(showPhoneNumbers || showEmails) && (
              <Grid columns={1}>
                <Separator />
              </Grid>
            )}

            {showPhoneNumbers && (
              <Grid columns={1}>
                <GridItem>
                  <FieldArray<PhoneNumberData> name="phoneNumbers">
                    {({fields, Field}) =>
                      fields.map((name, i: number) => (
                        <Grid columns={2} key={`${name}_${i}`}>
                          <Field
                            as="phone"
                            name={[name, 'phoneNumber']}
                            label={i18n.t('entity.phoneNumber.labels.number')}
                          />
                          <HStack spacing={3}>
                            <Field
                              name={[name, 'type']}
                              label={i18n.t('entity.phoneNumber.labels.type')}
                            />
                            <Box paddingTop={5}>
                              <Dropdown
                                data-testid={suffixTestId(`personForm-phoneNumbers-[${i}]`, rest)}
                                dropdownControl={
                                  <IconButton
                                    icon="navigation/more_vert"
                                    data-testid={suffixTestId(
                                      `personForm-phoneNumbers-[${i}]-more`,
                                      rest
                                    )}
                                  />
                                }
                              >
                                <DropdownItem
                                  label={i18n.t('general.actions.delete')}
                                  isDisabled={fields.length === 1}
                                  onClick={() => fields.remove(i)}
                                  data-testid={suffixTestId(
                                    `personForm-phoneNumbers-[${i}]-delete`,
                                    rest
                                  )}
                                />
                              </Dropdown>
                            </Box>
                          </HStack>
                        </Grid>
                      ))
                    }
                  </FieldArray>
                  <Button
                    variant="link"
                    size="small"
                    leftIcon="content/add_circle"
                    onClick={() =>
                      push('phoneNumbers', getDefaultValuesFromSchema($PhoneNumberData))
                    }
                    data-testid={suffixTestId('personForm-addPhoneNumber', rest)}
                    title={i18n.t('general.customer.addPhoneNumber')}
                  />
                </GridItem>
              </Grid>
            )}

            {showEmails && (
              <Grid columns={1}>
                <GridItem>
                  <FieldArray<EmailData> name="emails">
                    {({fields, Field}) =>
                      fields.map((name, i: number) => (
                        <Grid columns={2} key={`${name}_${i}`}>
                          <Field
                            name={[name, 'email']}
                            label={i18n.t('entity.email.labels.email')}
                          />
                          <HStack spacing={3} align="center">
                            <Field
                              name={[name, 'type']}
                              label={i18n.t('entity.email.labels.type')}
                            />
                            <Box paddingTop={1}>
                              <Dropdown
                                data-testid={suffixTestId(`personForm-emails-[${i}]`, rest)}
                                dropdownControl={
                                  <IconButton
                                    icon="navigation/more_vert"
                                    data-testid={suffixTestId(
                                      `personForm-emails-[${i}]-more`,
                                      rest
                                    )}
                                  />
                                }
                              >
                                <DropdownItem
                                  label={i18n.t('general.actions.delete')}
                                  isDisabled={fields.length === 1}
                                  onClick={() => fields.remove(i)}
                                  data-testid={suffixTestId(
                                    `personForm-emails-[${i}]-delete`,
                                    rest
                                  )}
                                />
                              </Dropdown>
                            </Box>
                          </HStack>
                        </Grid>
                      ))
                    }
                  </FieldArray>
                  <Button
                    variant="link"
                    size="small"
                    leftIcon="content/add_circle"
                    onClick={() => push('emails', getDefaultValuesFromSchema($EmailData))}
                    data-testid={suffixTestId(`personForm-addEmail`, rest)}
                    title={i18n.t('general.customer.addEmail')}
                  />
                </GridItem>
              </Grid>
            )}
            {showAddress && handleAddressSubmit && (
              <>
                <Separator />
                <Grid columns={!compactSelect ? 1 : 2}>
                  <GridItem>
                    {!compactSelect ? (
                      <Card variant="inlineGrey">
                        <AddressSelect<PersonRequestBody>
                          name="permanentAddressId"
                          label={i18n.t('entity.person.permanentAddressId')}
                          address={address}
                          handleAddressSubmit={handleAddressSubmit}
                          initialValue={initialValues?.permanentAddress}
                          useInitialValueAsOption={useInitialValueAsOption}
                          data-testid={suffixTestId(`personForm`, rest)}
                        />
                      </Card>
                    ) : (
                      <div style={{marginRight: '-100px'}}>
                        <AddressSelect<PersonRequestBody>
                          name="permanentAddressId"
                          label={i18n.t('entity.person.permanentAddressId')}
                          address={address}
                          handleAddressSubmit={handleAddressSubmit}
                          initialValue={initialValues?.permanentAddress}
                          useInitialValueAsOption={useInitialValueAsOption}
                          data-testid={suffixTestId(`personForm`, rest)}
                        />
                      </div>
                    )}
                  </GridItem>
                </Grid>
              </>
            )}

            {showIdentityCards && (
              <Grid columns={1}>
                <GridItem>
                  <FieldArray<IdentityCardRequestBody> name="identityCards">
                    {({fields, Field}) =>
                      fields.map((name, i) => (
                        <Field<IdentityCardData>
                          key={name}
                          name={[name, 'cardData']}
                          component={({input}) => (
                            <>
                              <Separator />
                              <IdentityCardForm
                                data-testid={suffixTestId(`personForm`, rest)}
                                initialValues={input.value}
                                onSubmit={input?.onChange}
                                saveOnChange
                              />

                              {(fields.length ?? 0) > 1 && (
                                <Button
                                  variant="dangerLink"
                                  size="small"
                                  leftIcon="action/delete"
                                  onClick={() => fields.remove(i)}
                                  data-testid={suffixTestId(
                                    `personForm-identityCards-[${i}]-delete`,
                                    rest
                                  )}
                                  title={i18n.t('entity.person.actions.removePersonalId')}
                                />
                              )}
                            </>
                          )}
                        />
                      ))
                    }
                  </FieldArray>
                </GridItem>
                <Button
                  variant="link"
                  size="small"
                  leftIcon="content/add_circle"
                  onClick={() =>
                    push('identityCards', getDefaultValuesFromSchema($IdentityCardRequestBody))
                  }
                  data-testid={suffixTestId('personForm-addIdentityCard', rest)}
                  title={i18n.t('general.customer.addIdentityCard')}
                />
              </Grid>
            )}
          </>
        );

        if (WrapperComponent) {
          return (
            <WrapperComponent
              {...formRendererProps}
              data-testid={suffixTestId('personForm-formWrapper', rest)}
            >
              {form}
            </WrapperComponent>
          );
        }

        return (
          <>
            {form}
            {!saveOnChange && (
              <Right>
                <Button
                  variant="primary"
                  isDisabled={loading}
                  onClick={handleSubmit}
                  data-testid={suffixTestId('personForm-save', rest)}
                  title={i18n.t('general.actions.save')}
                />
              </Right>
            )}
          </>
        );
      }}
    />
  );
};
