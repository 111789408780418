import {Box} from 'platform/foundation';

import {KeyboardEventHandler} from 'react';

import {Search, SearchProps} from '../../Search/Search';

interface DropdownSearchItem extends SearchProps {}

const ARROW_DOWN_KEY = 'ArrowDown';

/**
 * Allow typing in the search input when focused without triggering the dropdown keyboard events
 */
const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
  const isSearchInputFocused = event.currentTarget.contains(document.activeElement);
  const isNotArrowDownKey = event.key !== ARROW_DOWN_KEY;
  if (isSearchInputFocused && isNotArrowDownKey) {
    event.stopPropagation();
  }
};

/**
 * Set `hasSearch` to true in the Dropdown component to use this component
 */
export function DropdownSearchItem(props: DropdownSearchItem) {
  return (
    <Box
      position="sticky"
      top={0}
      left={0}
      padding={2}
      backgroundColor="general.white"
      zIndex="OVERLAY"
    >
      <div onKeyDown={handleKeyDown}>
        <Search {...props} />
      </div>
    </Box>
  );
}
