import {startOfToday} from 'date-fns';
import {
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  openConfirmDialog,
  showNotification,
} from 'platform/components';
import {Grid, GridItem, Hide, VStack} from 'platform/foundation';

import {defaultTo, equals, isNotNil, not} from 'ramda';

import {
  GetDirectSaleResponse,
  PatchDirectSaleRequest,
  usePatchDirectSaleMutation,
} from '@dms/api/metadaWarehouseDirectSale';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {getApiDateString, isNilOrZero, parseDate, RequiredTestIdProps, suffixTestId} from 'shared';

import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';
import {
  RequestDetailSegmentFormType,
  RequestDetailSegmentFormTypeSchema,
} from './types/RequestDetailSegmentFormType';

export interface RequestDetailSegmentFormProps extends RequiredTestIdProps {
  directSale: GetDirectSaleResponse;
  isEditingDisabled: boolean;
}

export function RequestDetailSegmentForm(props: RequestDetailSegmentFormProps) {
  const {directSaleId} = useWarehouseParams();

  const today = startOfToday();

  const [saveDirectSale] = usePatchDirectSaleMutation();

  const defaultValues: RequestDetailSegmentFormType = {
    name: props.directSale?.name,
    description: props.directSale?.description,
    neededAt: parseDate(props.directSale?.neededAt),
  };

  const isEditingDisabled = defaultTo(false, props.isEditingDisabled);

  const handleSubmit: FormSubmitHandler<RequestDetailSegmentFormType> = async (formValues) => {
    const formNeededAt = parseDate(formValues.neededAt || null);

    const requestData: PatchDirectSaleRequest = {
      directSaleId,
      body: {
        ...formValues,
        neededAt: isNotNil(formNeededAt) ? getApiDateString(formNeededAt) : formNeededAt,
      },
    };

    if (isNilOrZero(props.directSale?.itemsCount)) {
      return handleSaveDirectSale(requestData);
    }

    // Needed at date changed, we want to let user to update this date for all direct sale items
    if (not(equals(defaultValues.neededAt, formNeededAt))) {
      const shouldUpdateNeededAtDateOnAllItems = await openConfirmUpdateNeededAtDateDialog();
      requestData.body.isItemNeededAtUpdate = shouldUpdateNeededAtDateOnAllItems;
      return handleSaveDirectSale(requestData);
    }

    handleSaveDirectSale(requestData);
  };

  const openConfirmUpdateNeededAtDateDialog = () =>
    new Promise<boolean>((resolve) => {
      openConfirmDialog({
        text: i18n.t('page.warehouse.labels.updateNeededAtDateOnExistingItems'),
        onConfirm: () => resolve(true),
        onCloseComplete: () => resolve(false),
      });
    });

  const handleSaveDirectSale = (requestData: PatchDirectSaleRequest) =>
    saveDirectSale(requestData)
      .unwrap()
      .then(() =>
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'))
      )
      .catch(handleApiError);

  return (
    <Form<RequestDetailSegmentFormType>
      experimentalZodSchema={RequestDetailSegmentFormTypeSchema}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
    >
      {(control, formApi) => (
        <VStack spacing={4}>
          <Grid spacing={4} columns={2}>
            <GridItem span={1}>
              <FormField
                isDisabled={isEditingDisabled}
                control={control}
                type="text"
                name="name"
                label={i18n.t('entity.warehouse.labels.name')}
                data-testid={suffixTestId('inputs.name', props)}
              />
            </GridItem>
            <GridItem span={1}>
              <FormField
                isDisabled={isEditingDisabled}
                control={control}
                type="date"
                name="neededAt"
                label={i18n.t('entity.warehouse.labels.neededAt')}
                minDate={today}
                data-testid={suffixTestId('inputs.neededAt', props)}
              />
            </GridItem>
            <GridItem span={2}>
              <FormField
                isDisabled={isEditingDisabled}
                control={control}
                type="textarea"
                name="description"
                label={i18n.t('entity.warehouse.labels.description')}
                minRows={5}
                data-testid={suffixTestId('inputs.description', props)}
              />
            </GridItem>
          </Grid>
          <Hide when={isEditingDisabled}>
            <ButtonGroup align="right">
              <Button
                type="button"
                variant="secondary"
                title={i18n.t('general.actions.discardChanges')}
                onClick={() => formApi.reset(defaultValues)}
                data-testid={suffixTestId('actions.discard', props)}
              />
              <FormButton
                control={control}
                type="submit"
                title={i18n.t('general.actions.saveChanges')}
                data-testid={suffixTestId('actions.submit', props)}
              />
            </ButtonGroup>
          </Hide>
        </VStack>
      )}
    </Form>
  );
}
