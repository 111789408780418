import {match, Pattern} from 'ts-pattern';

import {always} from 'ramda';

import {
  businessCaseRoutes,
  customerRoutes,
  dashboardRoutes,
  interestsRoutes,
  notFoundRoute,
  taskManagerRoutes,
  vehiclesRoutes,
  workshopRoutes,
} from '@dms/routes';
import {queryParams} from '@dms/shared';

import {composePath} from 'shared';

import {RedirectModule} from '../types/RedirectModule';
import {RedirectSubmodule} from '../types/RedirectSubmodule';
import {RedirectView} from '../types/RedirectView';

export type GetPathToRedirectProps = {
  module: RedirectModule | null;
  view: RedirectView | null;
  id: string | null;
  submodule: RedirectSubmodule | null;
  submoduleId: string | null;
};

export const getPathToRedirect = (props: GetPathToRedirectProps) =>
  match(props)
    .with(
      {
        module: 'vehicle',
        view: 'detail',
        id: Pattern.string,
        submodule: 'vehicleInspection',
        submoduleId: Pattern.string,
      },
      ({id, submoduleId: inspectionId}) =>
        composePath(vehiclesRoutes.inspectionDetail, {
          params: {id, inspectionId},
        })
    )
    .with({module: 'vehicle', view: 'detail', id: Pattern.string}, ({id}) =>
      composePath(vehiclesRoutes.detail, {params: {id}, isSearchParamsPreserved: false})
    )
    .with({module: 'customer', view: 'detail', id: Pattern.string}, ({id}) =>
      composePath(customerRoutes.detail, {params: {id}, isSearchParamsPreserved: false})
    )
    .with({module: 'businessCase', view: 'detail', id: Pattern.string}, ({id}) =>
      composePath(businessCaseRoutes.overview, {params: {id}, isSearchParamsPreserved: false})
    )
    .with({module: 'interest', view: 'detail', id: Pattern.string}, ({id}) =>
      composePath(interestsRoutes.overview, {params: {id}, isSearchParamsPreserved: false})
    )
    .with(
      {
        module: 'serviceCase',
        view: 'detail',
        id: Pattern.string,
        submodule: 'serviceCaseOrder',
        submoduleId: Pattern.string,
      },
      ({id, submoduleId}) =>
        composePath(workshopRoutes.serviceCaseDetail, {
          params: {id},
          queryParams: {[queryParams.SERVICE_CASE_ORDER_ID]: submoduleId},
          isSearchParamsPreserved: false,
        })
    )
    .with({module: 'serviceCase', view: 'detail', id: Pattern.string}, ({id}) =>
      composePath(workshopRoutes.serviceCaseDetail, {params: {id}, isSearchParamsPreserved: false})
    )
    .with(
      {module: 'notification', view: 'emailUnsubscribe', id: Pattern.string},
      always(dashboardRoutes.home)
    )
    .with({module: 'taskManager', view: 'detail', id: Pattern.string}, ({id}) =>
      composePath(taskManagerRoutes.taskOverview, {
        queryParams: {taskId: id},
        isSearchParamsPreserved: false,
      })
    )
    .otherwise(always(notFoundRoute));
