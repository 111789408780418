import {Card} from 'platform/components';
import {Heading, VStack} from 'platform/foundation';

import {
  VehicleActiveReservationApiResponse,
  VehicleReservationResponseBody,
} from '@dms/api/saleVehiclePhoto';
import i18n from '@dms/i18n';

import {RefundedReservationDepositDetail} from './RefundedReservationDepositDetail';

interface RefundedReservationDepositsProps {
  reservation: VehicleReservationResponseBody | VehicleActiveReservationApiResponse;
  vehicleId: string;
}

export function RefundedReservationDeposits(props: RefundedReservationDepositsProps) {
  return (
    <Card variant="inlineGrey">
      <VStack spacing={4}>
        <Heading size={4}>{i18n.t('entity.vehicle.labels.refundedAdvanceDeposits')}</Heading>
        {props.reservation.refundedDeposits.map((refundedAdvanceDeposit) => (
          <RefundedReservationDepositDetail
            key={refundedAdvanceDeposit.expenseCashReceiptId}
            refundedAdvanceDeposit={refundedAdvanceDeposit}
          />
        ))}
      </VStack>
    </Card>
  );
}
