import {isFeatureEnabled} from 'feature-flags';
import {Card, DataStatus, showNotification} from 'platform/components';
import {Show, VStack} from 'platform/foundation';

import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {isNotNil} from 'ramda-adjunct';

import {useGetParticipationQuery} from '@dms/api/participation';
import {selectSaleVehicle} from '@dms/api/saleVehicle';
import {EntityResourceIds} from '@dms/api/shared';
import {
  useGetCarVerticalConfigQuery,
  useGetCebiaAutotracerAndReportConfigQuery,
  useGetCebiaRokvyConfigQuery,
} from '@dms/api/tenant';
import {
  useBulkCreateHighlightsMutation,
  useBulkCreateShortcomingsMutation,
  useDeleteHighlightMutation,
  useDeleteShortcomingMutation,
  useGetVehicleQuery,
  useListHighlightsForTenantQuery,
  useListHighlightsQuery,
  useListShortcomingsForTenantQuery,
  useListShortcomingsQuery,
} from '@dms/api/vehicle';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {
  CardTagSelection,
  CardTagSelectionTagType,
  CommentsWidget,
  handleApiError,
  PriceReportRequestWidget,
  PriceReportWidget,
  usePermissions,
  VehicleNoteWidget,
} from '@dms/shared';
import {
  CebiaAutotracerWidget,
  CebiaProductionYearWidget,
  CebiaReportWidget,
  loadCarDetailsVehicleDetailRequest,
  TagType,
  useThunkDispatch,
} from '@dms/teas';

import {suffixTestId, TestIdProps} from 'shared';

import {CarVerticalWidget} from '../../../../../components/CarVerticalWidget/CarVerticalWidget';
import {VehicleFinancialInstitution} from './VehicleFinancialInstitution';
import {VehicleInsurance} from './VehicleInsurance';

const remapLabelToText = ({id, title}: TagType): CardTagSelectionTagType => ({id, label: title});

interface DetailsSideColumnProps extends TestIdProps {
  isIntegrationsShown?: boolean;
  isInsuranceShown?: boolean;
  isDetailsTab?: boolean;
  vehicleId: string;
}

export function DetailsSideColumn(props: DetailsSideColumnProps) {
  const {data: vehicle} = useGetVehicleQuery({vehicleId: props.vehicleId});
  const {data: vehicleHighlights} = useListHighlightsQuery({vehicleId: props.vehicleId});
  const {data: vehicleShortcoming} = useListShortcomingsQuery({vehicleId: props.vehicleId});

  const {data: vehicleParticipation} = useGetParticipationQuery({
    resourceId: EntityResourceIds.vehicle,
    recordId: props.vehicleId,
  });

  const dispatch = useThunkDispatch();
  const navigate = useNavigate();
  const [
    hasVehicleCommentReadPermission,
    hasVehicleCommentAddPermission,
    hasVehicleCommentDeletePermission,
    hasVehicleEnableLeasingAndCreditCompany,
    hasVehicleDisableLeasingAndCreditCompany,
    hasVehicleSetLeasingAndCreditCompany,
    hasVehicleSetFleetInsurance,
    hasVehicleUpdateStorageSpace,
    canEditHighlights,
    canEditShortcomings,
    canReadBusinessCase,
    canReadPriceReport,
    canReadAdvertisingSettings,
  ] = usePermissions({
    permissionKeys: [
      'vehicleCommentRead',
      'vehicleCommentAdd',
      'vehicleCommentDelete',
      'vehicleEnableLeasingAndCreditCompany',
      'vehicleDisableLeasingAndCreditCompany',
      'vehicleSetLeasingAndCreditCompany',
      'vehicleSetFleetInsurance',
      'vehicleUpdateStorageSpace',
      'editHighlights',
      'editShortcomings',
      'businessCaseRead',
      'createVehiclePriceReport',
      'readAdvertisingSettings',
    ],
    scopes: {
      vehicleCommentRead: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
      vehicleCommentAdd: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
      vehicleEnableLeasingAndCreditCompany: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
      vehicleDisableLeasingAndCreditCompany: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
      vehicleSetLeasingAndCreditCompany: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
      vehicleSetFleetInsurance: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
      vehicleUpdateStorageSpace: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
      editHighlights: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
      editShortcomings: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
      readAdvertisingSettings: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
      vehicleCommentDelete: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
    },
  });

  const canEditSaleVehicle =
    hasVehicleEnableLeasingAndCreditCompany &&
    hasVehicleDisableLeasingAndCreditCompany &&
    hasVehicleSetLeasingAndCreditCompany &&
    hasVehicleSetFleetInsurance &&
    hasVehicleUpdateStorageSpace;

  const [createShortcomings] = useBulkCreateShortcomingsMutation();
  const [deleteShortcoming] = useDeleteShortcomingMutation();
  const [createHighlights] = useBulkCreateHighlightsMutation();
  const [deleteHighlight] = useDeleteHighlightMutation();
  const {data: highlights} = useListHighlightsForTenantQuery({size: 1000});
  const {data: shortcomings} = useListShortcomingsForTenantQuery({size: 1000});

  const {data: cebiaAutotracerAndReportConfig, isLoading: isCebiaAutotracerAndReportConfigLoading} =
    useGetCebiaAutotracerAndReportConfigQuery();
  const {data: cebiaRokvyConfig, isLoading: isCebiaRokvyConfigLoading} =
    useGetCebiaRokvyConfigQuery();
  const {data: carVerticalConfig, isLoading: isCarVerticalConfigLoading} =
    useGetCarVerticalConfigQuery();

  const {data: saleVehicle} = useSelector(selectSaleVehicle(props.vehicleId));
  const isSaleVehicle = isNotNil(saleVehicle);

  const handleAddHighlights = (values: CardTagSelectionTagType[]) =>
    createHighlights({
      vehicleId: props.vehicleId,
      highlightBulkRequestBody: {highlights: values.map(({label}) => ({text: label}))},
    })
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

  const handleAddShortcomings = (values: CardTagSelectionTagType[]) =>
    createShortcomings({
      vehicleId: props.vehicleId,
      shortcomingBulkRequestBody: {shortcomings: values.map(({label}) => ({text: label}))},
    })
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

  const handleDeleteHighlight = (value: CardTagSelectionTagType) =>
    deleteHighlight({vehicleId: props.vehicleId, highlightId: value.id})
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

  const handleDeleteHighlightBulk = (values: CardTagSelectionTagType[]) =>
    Promise.all(
      values.map((value) =>
        deleteHighlight({vehicleId: props.vehicleId, highlightId: value.id}).unwrap()
      )
    )
      .then(() => showNotification.success())
      .catch(handleApiError);

  const handleDeleteShortcoming = (value: CardTagSelectionTagType) =>
    deleteShortcoming({vehicleId: props.vehicleId, shortcomingId: value.id})
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

  const handleDeleteShortcomingBulk = (values: CardTagSelectionTagType[]) =>
    Promise.all(
      values.map((value) =>
        deleteShortcoming({vehicleId: props.vehicleId, shortcomingId: value.id}).unwrap()
      )
    )
      .then(() => showNotification.success())
      .catch(handleApiError);

  const isWidgetEmpty =
    !isFeatureEnabled(featureFlags.PRICE_REPORT_WIDGET) &&
    !isFeatureEnabled(featureFlags.VEHICLE_PRICEREPORT_WIDGET_FORM) &&
    !cebiaAutotracerAndReportConfig?.enabled &&
    !cebiaRokvyConfig?.enabled;
  const isWidgetLoading =
    isCebiaAutotracerAndReportConfigLoading ||
    isCebiaRokvyConfigLoading ||
    isCarVerticalConfigLoading;

  return (
    <>
      <Show when={isSaleVehicle && isFeatureEnabled(featureFlags.ACL_SALES) && canReadBusinessCase}>
        {props.isIntegrationsShown && props.vehicleId && (
          <Card
            title={i18n.t('entity.vehicle.labels.integrations')}
            actions={[
              {
                type: 'button',
                variant: 'link',
                leftIcon: 'action/settings',
                onClick: () => navigate(settingsRoutes.vehicleServices),
                'data-testid': testIds.vehicles.detail('integrationSettingsButton'),
                title: i18n.t('general.labels.settings'),
              },
            ]}
            isExpandable
          >
            <VStack spacing={3}>
              <DataStatus
                isEmpty={isWidgetEmpty}
                isLoading={isWidgetLoading}
                emptyMessage={i18n.t('entity.vehicle.emptyIntegration.noServicesAvailable')}
                emptySubheadline={i18n.t('entity.vehicle.emptyIntegration.enableInSettings')}
              >
                <>
                  <Show
                    when={isFeatureEnabled(featureFlags.PRICE_REPORT_WIDGET) && canReadPriceReport}
                  >
                    <PriceReportWidget
                      resourceId={props.vehicleId}
                      vehicleType={vehicle?.type}
                      context="vehicle"
                      data-testid={testIds.vehicles.detail('priceReportWidget')}
                    />
                  </Show>
                  <Show whenFeatureEnabled={featureFlags.VEHICLE_PRICEREPORT_WIDGET_FORM}>
                    <PriceReportRequestWidget
                      vehicleId={props.vehicleId}
                      vehicleType={vehicle?.type}
                      data-testid={testIds.vehicles.detail('priceReportRequestWidget')}
                    />
                  </Show>
                  <Show when={isNotNil(vehicle)}>
                    <Show
                      when={carVerticalConfig?.enabled}
                      whenFeatureEnabled={featureFlags.CAR_VERTICAL_INTEGRATION}
                    >
                      <CarVerticalWidget
                        vehicleId={vehicle?.id}
                        data-testid={testIds.vehicles.detail('carVericalWidget')}
                      />
                    </Show>
                    <Show when={cebiaAutotracerAndReportConfig?.enabled}>
                      <CebiaAutotracerWidget
                        vehicle={vehicle!}
                        data-testid={testIds.vehicles.detail('cebiaAutotracerWidget')}
                      />
                    </Show>
                    <CebiaReportWidget
                      vehicleId={vehicle?.id}
                      data-testid={testIds.vehicles.detail('cebiaReportWidget')}
                    />
                    <Show when={cebiaRokvyConfig?.enabled}>
                      <CebiaProductionYearWidget
                        data-testid={testIds.vehicles.detail('cebiaProductionYearWidget')}
                        vehicle={vehicle!}
                        onSuccess={() =>
                          dispatch(loadCarDetailsVehicleDetailRequest({vehicleId: props.vehicleId}))
                        }
                      />
                    </Show>
                  </Show>
                </>
              </DataStatus>
            </VStack>
          </Card>
        )}
      </Show>
      <Show when={hasVehicleCommentReadPermission}>
        <CommentsWidget
          title={i18n.t('entity.vehicle.labels.vehicleComments')}
          resourceId={EntityResourceIds.vehicle}
          recordId={props.vehicleId}
          isAddDisabled={!hasVehicleCommentAddPermission}
          isDeleteDisabled={!hasVehicleCommentDeletePermission}
        />
      </Show>
      <VehicleNoteWidget
        vehicleId={props.vehicleId}
        title={i18n.t('entity.vehicle.labels.vehicleAdditionalNotes')}
      />
      <Show when={isSaleVehicle} whenFeatureEnabled={featureFlags.VEHICLE_FINANCIAL_INSTITUTION}>
        <VehicleFinancialInstitution
          canEditVehicle={canEditSaleVehicle}
          vehicleId={props.vehicleId}
          data-testid={suffixTestId('financing', props)}
        />
      </Show>
      <Show
        when={!isSaleVehicle && !props.isDetailsTab}
        whenFeatureEnabled={featureFlags.AFTS_SERVICE_VEHICLE_ENTITY_V2}
      >
        <VehicleFinancialInstitution
          vehicleId={props.vehicleId}
          canEditVehicle={canEditSaleVehicle}
          data-testid={suffixTestId('financing', props)}
        />
      </Show>
      <Show when={props.isInsuranceShown} whenFeatureEnabled={featureFlags.SALES_VEHICLE_INSURANCE}>
        <VehicleInsurance
          data-testid="detailsSideColumn-vehicleInsurance"
          canEditVehicle={canEditSaleVehicle}
          vehicleId={props.vehicleId}
        />
      </Show>
      <Show
        when={
          isSaleVehicle &&
          canReadAdvertisingSettings &&
          saleVehicle?.vehicleId &&
          isFeatureEnabled(featureFlags.ACL_SALES) &&
          canReadBusinessCase
        }
      >
        <CardTagSelection
          tags={vehicleHighlights?.map(remapLabelToText) ?? []}
          predefinedTags={highlights?.map(remapLabelToText) || []}
          onDelete={handleDeleteHighlight}
          onCreateBulk={handleAddHighlights}
          onDeleteBulk={handleDeleteHighlightBulk}
          isDisabled={!canEditHighlights}
          data-testid={testIds.vehicles.detail('highlights')}
          title={i18n.t('entity.vehicle.labels.carHighlights')}
          editDialogTitle={i18n.t('entity.vehicle.labels.carHighlights')}
          emptyMessage={i18n.t('entity.vehicle.labels.highlightedFeaturesPlaceholder')}
          tagsColorScheme="neutral"
        />

        <CardTagSelection
          tags={vehicleShortcoming?.map(remapLabelToText) ?? []}
          predefinedTags={shortcomings?.map(remapLabelToText) || []}
          onDelete={handleDeleteShortcoming}
          onCreateBulk={handleAddShortcomings}
          onDeleteBulk={handleDeleteShortcomingBulk}
          isDisabled={!canEditShortcomings}
          data-testid={testIds.vehicles.detail('shortcomings')}
          title={i18n.t('entity.vehicle.labels.shortcomings')}
          editDialogTitle={i18n.t('entity.vehicle.labels.shortcomings')}
          emptyMessage={i18n.t('entity.vehicle.labels.shortcomingsPlaceholder')}
          tagsColorScheme="red"
        />
      </Show>
    </>
  );
}
