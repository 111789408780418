import {AxiosError} from 'axios';
import {Button, showNotification} from 'platform/components';

import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import {useToRevisionInspectionMutation} from '@dms/api/carAudit';
import {ApiException} from '@dms/api/shared';
import i18n from '@dms/i18n';
import {vehiclesRoutes} from '@dms/routes';

import {composePath} from 'shared';

import {updateAuditState} from '../../../store/carAudit/reducer';
import {selectAudit} from '../../../store/carAudit/selectors';
import {LoadAuditDataResponseItemBody} from '../../../types/LoadAuditDataResponseItemBody';
import {getStringErrorMessage} from '../../../utils/getStringErrorMessage';
import {useConditionContext} from '../hooks/useConditionContext';

export function ToRevisionInspection() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {loadActualDataAndValidate} = useConditionContext();

  const vehicleId = String(params.id);

  const audit = useSelector(selectAudit);

  const [toRevisionInspection, {isLoading: isLoadingToRevisionInspection}] =
    useToRevisionInspectionMutation();

  const handleToRevision = async () => {
    if (!audit) {
      return;
    }
    try {
      Promise.all([
        await loadActualDataAndValidate(),
        await toRevisionInspection({
          inspectionId: audit.id,
        }),
      ])
        .then(() => {
          dispatch(
            updateAuditState({
              state: LoadAuditDataResponseItemBody.state.TO_REVISION,
            })
          );

          navigate(composePath(vehiclesRoutes.conditionsInspections, {params: {id: vehicleId}}));
        })
        .catch((error: AxiosError<ApiException>) =>
          showNotification.error(getStringErrorMessage(error))
        );
    } catch (_) {
      //
    }
  };

  return (
    <Button
      onClick={handleToRevision}
      isLoading={isLoadingToRevisionInspection}
      title={i18n.t('general.actions.toRevision')}
    />
  );
}
