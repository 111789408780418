import {Form, FormField, FormSubmitHandler, Separator, showNotification} from 'platform/components';
import {Grid, VStack} from 'platform/foundation';
import {z} from 'zod';

import {useCreateRentalPriceGroupMutation} from '@dms/api/rentalVehicles';
import {VatTypeEnum} from '@dms/api/shared';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError, useVatRatesOptions} from '@dms/shared';

import {useNavigate} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {PriceList} from './components/PriceList';
import {FormValues} from './types/FormValues';

export function PriceGroupsNew() {
  const navigate = useNavigate();
  const [vatTypeOptions] = useVatRatesOptions();
  const [createRentalPriceGroup] = useCreateRentalPriceGroupMutation();

  const handleSubmit: FormSubmitHandler<FormValues> = async (values) => {
    const items = values.priceList.map((item) => ({
      name: item.name,
      price: {
        includedKilometers: item.includedKilometers,
        rate: item.rate.toString(),
        ratePerKilometer: item.ratePerKilometer.toString(),
      },
      range: {
        from: item.from,
        to: item.to,
        period: item.period,
      },
    }));

    await createRentalPriceGroup({
      createRentalPriceGroupRequestBody: {name: values.name, vatType: values.vatType, items},
    })
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.successfullyCreated'));
        navigate(settingsRoutes.priceGroupList);
      })
      .catch(handleApiError);
  };

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('entity.rental.labels.priceGroupsNew'),
        breadcrumbs: [
          {label: i18n.t('entity.rental.labels.priceGroups'), href: settingsRoutes.priceGroupList},
        ],
      }}
      data-testid={testIds.settings.priceGroupNew('page')}
      isCreating
    >
      <Form<FormValues>
        onSubmit={handleSubmit}
        experimentalZodSchema={schema}
        defaultValues={{priceList: [], vatType: VatTypeEnum.S}}
      >
        {(control) => (
          <VStack spacing={6}>
            <SettingsSection>
              <Grid columns={2}>
                <FormField
                  control={control}
                  name="name"
                  type="text"
                  isRequired
                  label={i18n.t('general.labels.name')}
                />

                <FormField
                  control={control}
                  name="vatType"
                  type="choice"
                  isRequired
                  label={i18n.t('general.labels.vat')}
                  options={vatTypeOptions}
                  isNotClearable
                />
              </Grid>
            </SettingsSection>

            <Separator spacing={0} />

            <PriceList control={control} />

            <SettingsFooter
              actions={[
                {
                  type: 'button',
                  title: i18n.t('general.actions.discard'),
                  onClick: () => navigate(settingsRoutes.priceGroupList),
                  variant: 'secondary',
                  'data-testid': testIds.settings.priceGroupNew('discard'),
                },
                {
                  type: 'form-button',
                  control,
                  buttonType: 'submit',
                  title: i18n.t('general.actions.create'),
                  'data-testid': testIds.settings.priceGroupNew('create'),
                },
              ]}
            />
          </VStack>
        )}
      </Form>
    </SettingsTemplate>
  );
}

const schema = z.object({
  name: z.string().nonempty(),
  vatType: z.enum([VatTypeEnum.S, VatTypeEnum.Z, VatTypeEnum.R, VatTypeEnum.MR, VatTypeEnum.E]),
  priceList: z.array(z.any()),
});
