import {showNotification, useDeepCompareEffect, useTranslationContext} from 'platform/components';

import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import {isNil} from 'ramda';

import {Nullish} from 'shared';

import {dragStore} from '../store/dragStore';
import {BigCalendarEvent} from '../types/BigCalendarEvent';
import {useEvents} from './useEvents';
import {useJobQueue} from './useJobQueue';

/**
 * Arguments for optimistic event update operation
 */
type OptimisticEventUpdateArgs = {
  /** Calendar event to be updated */
  event: BigCalendarEvent;
  /** New start date for the event */
  start: Date;
  /** New end date for the event */
  end: Date;
  /** Callback function to handle event drag and move operation */
  onEventDragMove: (event: BigCalendarEvent, start: Date, end: Date) => Promise<void>;
};

/**
 *  * Hook for managing calendar events with optimistic updates.
 *
 * This implementation uses optimistic updates to provide immediate UI feedback
 * when users interact with calendar events (like dragging/moving), while the actual
 * server update happens in the background. This pattern:
 *
 * 1. Improves perceived performance by showing changes instantly
 * 2. Provides better UX by not blocking the UI during server operations
 * 3. Handles error cases by reverting to previous state if server update fails
 *
 * The implementation flow:
 * 1. When user drags an event, optimistic state updates immediately
 * 2. Server request starts in background
 * 3. UI remains responsive during server operation
 * 4. On success: optimistic state becomes permanent
 * 5. On failure: optimistic state reverts to previous
 *
 * @param events Array of calendar events or null/undefined
 * @returns Object containing optimistic events, and update function
 */
export const useOptimisticEventManager = (events: BigCalendarEvent[] | Nullish) => {
  const t = useTranslationContext();
  const location = useLocation();

  const optimisticEvents = useEvents();
  const jobQueue = useJobQueue();
  /**
   * Effect hook that synchronizes the external events with the drag store's internal state.
   * Uses deep comparison to prevent unnecessary updates, because dependencies are memoized.
   *
   * The effect is skipped if:
   * 1. The events prop is null/undefined
   * 2. The optimistic events are the same reference as incoming events
   * 3. There are pending jobs in the queue (to prevent overwriting optimistic updates)
   *
   * @param events - External events array to be synchronized
   * @param optimisticEvents - Current optimistic events in the drag store
   * @param jobQueue - Queue of pending optimistic updates
   */
  useDeepCompareEffect(() => {
    if (isNil(events) || optimisticEvents === events || jobQueue.length > 0) {
      return;
    }
    dragStore.setEvents(events || []);
  }, [events]);

  /**
   * Effect hook that cleans up the drag store state when component unmounts.
   *
   * Since we're using optimistic UI updates, the drag store state persists in memory
   * even after component unmount. This can lead to stale data when the component
   * remounts. To prevent this, we need to programmatically reset the store state
   * when the component unmounts.
   */
  useEffect(() => () => dragStore.cleanup(), [location.pathname]);

  const optimisticEventUpdate = (args: OptimisticEventUpdateArgs) => {
    const jobId = `${new Date().getTime().toString()}-${args.event.id}`;

    dragStore.addJob(jobId, {
      ...args.event,
      start: args.start,
      end: args.end,
    });

    args
      .onEventDragMove(args.event, args.start, args.end)
      .then(() => {
        dragStore.resolveJob(jobId);
      })
      .catch(() => {
        dragStore.rejectJob(jobId);
        showNotification.error(t('bigCalendar.failedToUpdateEvent', {title: args.event.title}));
      });
  };

  return {optimisticEvents, optimisticEventUpdate};
};
