import {
  Button,
  ButtonGroup,
  CustomRequestOption,
  Dialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  IconButton,
  Upload,
  UploadState,
} from 'platform/components';
import {HStack, Icon, Link, Space, VStack} from 'platform/foundation';
import {match, Pattern} from 'ts-pattern';
import * as Yup from 'yup';

import {useState} from 'react';

import {always, isEmpty} from 'ramda';
import {isNonEmptyArray, isNotNil} from 'ramda-adjunct';

import {useUploadFileMutation} from '@dms/api/core';
import {
  ContextTarget,
  useCreateDocumentContextBulkMutation,
  useCreateDocumentContextMutation,
} from '@dms/api/documentContext';
import {UploadFileResponse} from '@dms/api/shared';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps, useBoolean} from 'shared';

import {getFileIcon} from '../../utils/getFileIcon';
import {handleApiError} from '../../utils/handleApiError';

interface UploadDocumentsDialogProps extends TestIdProps {
  contextId: string;
  contextTarget: ContextTarget;
  isOpen: boolean;
  handleClose: () => void;
  onDocumentCreated: (uploadedDocuments: UploadFileResponse[]) => void;
  bulkContext?: {
    target: ContextTarget;
    targetId: string;
  }[];
}

type UploadDocumentsFormType = {
  notes: {[fileId: string]: string | null};
};

export function UploadDocumentsDialog(props: UploadDocumentsDialogProps) {
  const [isUploading, setIsUploading, stopIsUploading] = useBoolean();
  const [hasUploadError, showUploadError, hideUploadError] = useBoolean();
  const [documents, setDocuments] = useState<UploadFileResponse[]>([]);

  const [uploadFile] = useUploadFileMutation();
  const [createDocument] = useCreateDocumentContextMutation();
  const [createBulkDocument] = useCreateDocumentContextBulkMutation();

  const handleUpload = async ({file}: CustomRequestOption) => {
    try {
      const data = await uploadFile({file: file as File})
        .unwrap()
        .catch(handleApiError);

      if (!data) {
        return;
      }

      if (isNotNil(data)) {
        setDocuments((prevState) => [...prevState, data]);
      }
    } catch (e) {
      showUploadError();
    } finally {
      stopIsUploading();
    }
  };

  const handleClose = () => {
    setDocuments([]);
    props.handleClose();
  };

  const handleCreateDocument: FormSubmitHandler<UploadDocumentsFormType> = async ({
    notes,
  }: UploadDocumentsFormType) => {
    const actions = documents.map((document) => {
      const fileName = document.file.name;

      if (isNonEmptyArray(props.bulkContext)) {
        return createBulkDocument({
          documentContextBulkRequestBody: {
            fileName,
            note: notes[document.fileId] || null,
            fileRemoteId: document?.fileId ?? '',
            contexts: props.bulkContext,
            contextSourceId: props.contextId,
            contextSource: props.contextTarget,
            manual: true,
          },
        })
          .unwrap()
          .catch(handleApiError);
      }

      return createDocument({
        documentContextRequestBody: {
          fileName,
          note: notes[document.fileId] || null,
          fileRemoteId: document?.fileId ?? '',
          contextId: props.contextId,
          contextTarget: props.contextTarget,
        },
      })
        .unwrap()
        .catch(handleApiError);
    });

    await Promise.all(actions)
      .then(() => props.onDocumentCreated(documents))
      .then(handleClose);
  };

  const uploadState = match<[boolean, boolean], UploadState | undefined>([
    hasUploadError,
    isUploading,
  ])
    .with([true, Pattern.boolean], always(UploadState.Error))
    .with([Pattern.boolean, true], always(UploadState.Uploading))
    .with([Pattern.boolean, false], always(UploadState.Success))
    .otherwise(always(undefined));

  const removeDocument = (fileId: string) =>
    setDocuments((prevState) => prevState.filter((document) => document.fileId !== fileId));

  return (
    <Dialog
      isOpen={props.isOpen}
      onClose={handleClose}
      title={i18n.t('entity.document.labels.addDocuments')}
      data-testid={suffixTestId('uploadDocumentsDialog', props)}
    >
      <Form<UploadDocumentsFormType>
        onSubmit={handleCreateDocument}
        schema={uploadDocumentFormSchema}
        data-testid={suffixTestId('uploadDocumentsDialog', props)}
      >
        {(control) => (
          <VStack spacing={4}>
            {documents.map((document, index) => (
              <VStack key={document.fileId} spacing={2}>
                <HStack align="center" spacing={3}>
                  <Icon value={getFileIcon(document?.file.type)} size={8} />
                  <Link
                    size="small"
                    href={document?.signedUrl}
                    target="_blank"
                    title={document?.file.name}
                    data-testid={suffixTestId(`uploadDocumentsDialog-dialog-[${index}]`, props)}
                  />
                  <Space fillAvailable />
                  <IconButton
                    data-testid={suffixTestId(`uploadDocumentsDialog-dialog-[${index}]`, props)}
                    severity="danger"
                    icon="action/delete"
                    onClick={() => removeDocument(document.fileId)}
                    size="small"
                  />
                </HStack>

                <FormField
                  type="text"
                  control={control}
                  name={`notes.${document.fileId}`}
                  label={i18n.t('entity.document.labels.note')}
                  data-testid={suffixTestId(`uploadDocumentsDialog-dialog-[${index}]`, props)}
                />
              </VStack>
            ))}

            <Upload
              name="document"
              type="card"
              size="minHeight"
              isMultiple
              onStart={() => {
                hideUploadError();
                setIsUploading();
              }}
              uploadState={uploadState}
              uploadText={i18n.t('entity.document.labels.addDocuments')}
              customRequest={handleUpload}
              data-testid={suffixTestId('uploadDocumentsDialog', props)}
            />

            <ButtonGroup align="right" data-testid={suffixTestId('uploadDocumentsDialog', props)}>
              <Button
                onClick={handleClose}
                variant="secondary"
                title={i18n.t('general.actions.cancel')}
                isDisabled={isUploading}
                data-testid={suffixTestId('uploadDocumentsDialog-cancel', props)}
              />
              <FormButton
                control={control}
                type="submit"
                title={i18n.t('general.actions.add')}
                isLoading={isUploading}
                isDisabled={isEmpty(documents)}
                data-testid={suffixTestId('uploadDocumentsDialog-add', props)}
              />
            </ButtonGroup>
          </VStack>
        )}
      </Form>
    </Dialog>
  );
}

const uploadDocumentFormSchema = Yup.object().shape({
  note: Yup.string().default(''),
});
