import {Attributes, AttributesRow} from 'platform/components';
import {useDateTimeFormatter} from 'platform/locale';

import {FC} from 'react';
import {useSelector} from 'react-redux';

import {
  BusinessInfoResponseBodyV2,
  IdentityCardResponseBody,
  PersonResponseBodyV2,
} from '@dms/api/customer';
import i18n from '@dms/i18n';
import {EMPTY_PLACEHOLDER, getNaturalPersonFullName, useAddress} from '@dms/shared';
import {
  selectCountries,
  selectCountry,
  selectGender,
  selectIdentityCardTypes,
  getPersonIdentityCard,
} from '@dms/teas';

import {buildArray, parseDate, suffixTestId, TestIdProps} from 'shared';

export interface CheckoutContractInfoProps {
  person?: PersonResponseBodyV2 | null;
  businessInfo?: BusinessInfoResponseBodyV2 | null;
  identityCards?: IdentityCardResponseBody[];
  isAdditionalCustomer?: boolean;
}

export const CheckoutContractInfo: FC<CheckoutContractInfoProps & TestIdProps> = ({
  person,
  businessInfo,
  identityCards,
  ...props
}) => {
  const {composeAddress} = useAddress();
  const formatDateTime = useDateTimeFormatter();
  const countries = useSelector(selectCountries);
  const identityCardTypes = useSelector(selectIdentityCardTypes);
  const citizenship = useSelector(selectCountry(person?.citizenshipCode));
  const countryOfRegistration = useSelector(
    selectCountry(businessInfo?.businessInfo?.countryOfRegistrationCode)
  );
  const gender = useSelector(selectGender(person?.genderKey));

  const birthDate = parseDate(person?.birthdate);

  const naturalPersonRows = buildArray<AttributesRow>([
    {
      label: i18n.t('entity.person.labels.fullName'),
      value: person ? getNaturalPersonFullName(person) : EMPTY_PLACEHOLDER,
      testId: 'fullName',
    },
    {
      label: i18n.t('entity.person.labels.genderKey'),
      value: gender?.name,
      testId: 'genderKey',
    },
    {
      label: i18n.t('entity.person.labels.citizenshipCode'),
      value: citizenship?.name,
      testId: 'citizenshipCode',
    },
    {
      label: i18n.t('entity.person.labels.birthdate'),
      value: birthDate ? formatDateTime('dateShort', birthDate) : undefined,
      testId: 'birthdate',
    },
  ])
    .whenNot(props.isAdditionalCustomer, {
      label: i18n.t('entity.person.labels.personalIdentifier'),
      value: person?.personalIdentifier || undefined,
      testId: 'personalIdentifier',
    })
    .add({
      label: i18n.t('entity.address.labels.fullAddress'),
      value: composeAddress(person?.permanentAddress?.address),
      testId: 'fullAddress',
    });

  const companyRows = buildArray<AttributesRow>([
    {
      label: i18n.t('contractInformation.labels.tradeName'),
      value: businessInfo?.businessInfo?.tradeName,
      testId: 'tradeName',
    },
    {
      label: i18n.t('contractInformation.labels.registrationNumber'),
      value: businessInfo?.businessInfo?.registrationNumber,
      testId: 'registrationNumber',
    },
    {
      label: i18n.t('contractInformation.labels.vatNumber'),
      value: businessInfo?.businessInfo?.vatNumber,
      testId: 'vatNumber',
    },
    {
      label: i18n.t('contractInformation.labels.fileNumber'),
      value: businessInfo?.businessInfo?.fileNumber,
      testId: 'fileNumber',
    },
    {
      label: i18n.t('contractInformation.labels.countryOfRegistrationCode'),
      value: countryOfRegistration?.name,
      testId: 'countryOfRegistrationCode',
    },
    {
      label: i18n.t('contractInformation.labels.businessAddressId'),
      value: composeAddress(businessInfo?.address?.address),
      testId: 'businessAddressId',
    },
  ]);

  const rows = businessInfo ? companyRows : naturalPersonRows;

  if (person && !props.isAdditionalCustomer) {
    if (!identityCards?.length) {
      rows.add({
        label: i18n.t('general.labels.identityCard'),
        value: undefined,
        testId: 'identityCard',
      });
    } else {
      identityCards?.map((identityCard) =>
        rows.add({
          label: i18n.t('general.labels.identityCard'),
          value: getPersonIdentityCard(identityCard.identityCardData, countries, identityCardTypes),
          testId: 'identityCard',
        })
      );
    }
  }

  return (
    <Attributes
      data-testid={suffixTestId('parameters', props)}
      rows={rows}
      size={businessInfo ? 'third' : 'quarter'}
    />
  );
};
