import {Card, DataStatus} from 'platform/components';
import {VStack} from 'platform/foundation';

import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetHandlingUnitQuery} from '@dms/api/metadaWarehouseHandlingUnit';

import {RequiredTestIdProps} from 'shared';

import {EitherQuantityOrError} from '../../../types/basket/EitherQuantityOrError';
import {MappedBasketItem} from '../../../types/basket/MappedBasketItem';
import {MaterialReturnBasketItem} from '../types/MaterialReturnBasketItem';
import {AfterSalesMaterialReturnBasketItemQuantityWithPrice} from './AfterSalesMaterialReturnBasketItemQuantityWithPrice';
import {AfterSalesMaterialReturnBasketItemTitleWithActions} from './AfterSalesMaterialReturnBasketItemTitleWithActions';

interface AfterSalesMaterialReturnBasketItemProps<T extends MaterialReturnBasketItem>
  extends RequiredTestIdProps {
  item: MappedBasketItem<T>;
  onSelect: (itemId: string, isSelected: boolean) => void;
  onQuantityChange: (itemId: string, quantity: EitherQuantityOrError) => Promise<void>;
  onDelete: (itemsIds: string[]) => Promise<void>;
}

export function AfterSalesMaterialReturnBasketItem<T extends MaterialReturnBasketItem>(
  props: AfterSalesMaterialReturnBasketItemProps<T>
) {
  const {data: handlingUnit, isLoading: isHandlingUnitLoading} = useGetHandlingUnitQuery(
    {id: props.item.unit as string},
    {skip: isNilOrEmpty(props.item.unit)}
  );

  return (
    <Card variant="inlineGrey" data-testid={props['data-testid']}>
      <DataStatus isLoading={isHandlingUnitLoading} minHeight={25}>
        <VStack spacing={1}>
          <AfterSalesMaterialReturnBasketItemTitleWithActions
            item={props.item}
            onSelect={props.onSelect}
            onDelete={props.onDelete}
            data-testid={props['data-testid']}
          />
          <AfterSalesMaterialReturnBasketItemQuantityWithPrice
            item={props.item}
            handlingUnit={handlingUnit?.name}
            onQuantityChange={props.onQuantityChange}
            data-testid={props['data-testid']}
          />
        </VStack>
      </DataStatus>
    </Card>
  );
}
