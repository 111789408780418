import {omneticApi} from '@dms/api/core';

import {
  GetMyCalendarSelectedUsersApiResponse,
  SetMyCalendarSelectedUsersApiArg,
  SetMyCalendarSelectedUsersApiResponse,
  getMyCalendarSelectedUsersResponseSchema,
  setMyCalendarSelectedUsersRequestBodySchema,
} from './types';

export const calendarApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getMyCalendarSelectedUsers: build.query<GetMyCalendarSelectedUsersApiResponse, void>({
      query: () => ({
        url: `/dms/v1/calendar/selected-users`,
      }),
      providesTags: ['CalendarUsers'],
      extraOptions: {
        responseSchema: getMyCalendarSelectedUsersResponseSchema,
      },
    }),
    setMyCalendarSelectedUsers: build.mutation<
      SetMyCalendarSelectedUsersApiResponse,
      SetMyCalendarSelectedUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/calendar/selected-users`,
        method: 'PUT',
        body: queryArg.setMyCalendarSelectedUsersRequestBody,
      }),
      invalidatesTags: ['CalendarUsers'],
      extraOptions: {
        requestSchema: setMyCalendarSelectedUsersRequestBodySchema,
      },
    }),
  }),
});

export const {useGetMyCalendarSelectedUsersQuery, useSetMyCalendarSelectedUsersMutation} =
  calendarApi;
