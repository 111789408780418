import {omneticApi} from '@dms/api/core';

import {
  CreateVehicleCostPresetApiArg,
  CreateVehicleCostPresetApiResponse,
  CreateVehicleEarningPresetApiArg,
  CreateVehicleEarningPresetApiResponse,
  ListVehicleCostsApiArg,
  ListVehicleCostsApiResponse,
} from './types';

const injectedRtkApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    createVehicleCostPreset: build.mutation<
      CreateVehicleCostPresetApiResponse,
      CreateVehicleCostPresetApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/finance/cost-preset`,
        method: 'POST',
        body: queryArg.createVehicleCostPresetRequestBody,
      }),
      invalidatesTags: (_results, _error, queryArg) => [
        {type: 'VehicleCosts', id: queryArg.createVehicleCostPresetRequestBody.vehicleId},
      ],
    }),
    createVehicleEarningPreset: build.mutation<
      CreateVehicleEarningPresetApiResponse,
      CreateVehicleEarningPresetApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/finance/earning-preset`,
        method: 'POST',
        body: queryArg.createVehicleEarningPresetRequestBody,
      }),
    }),
    listVehicleCosts: build.query<ListVehicleCostsApiResponse, ListVehicleCostsApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/cost`,
        params: {type: queryArg.type},
      }),
      providesTags: (_result, _error, queryArg) => [{type: 'VehicleCosts', id: queryArg.vehicleId}],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateVehicleCostPresetMutation,
  useCreateVehicleEarningPresetMutation,
  useListVehicleCostsQuery,
} = injectedRtkApi;
