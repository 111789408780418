import {BigCalendarEvent} from 'platform/big-calendar';
import {ThemeColorPath} from 'platform/foundation';

import {parseDate} from 'shared';

const getRandomDuration = (minMinutes: number, maxMinutes: number) =>
  Math.floor(Math.random() * (maxMinutes - minMinutes + 1) + minMinutes);

const getRandomTime = (start: number, end: number) =>
  Math.floor(Math.random() * (end - start + 1) + start);

const eventColors: ThemeColorPath[] = [
  'palettes.blue.60.100',
  'palettes.green.60.100',
  'palettes.purple.60.100',
  'palettes.orange.60.100',
  'palettes.red.60.100',
  'palettes.yellow.60.100',
  'palettes.teal.60.100',
  'palettes.black.900.40',
  'palettes.magenta.90.100',
  'palettes.neutral.50.100',
  'palettes.purple.90.100',
];

const generateEventsForDay = (baseDate: Date, dayOffset: number): BigCalendarEvent[] => {
  const date = parseDate(baseDate);
  date.setDate(date.getDate() + dayOffset);

  const events: BigCalendarEvent[] = [];

  // Generate 1-3 all-day events
  const allDayEventsCount = Math.floor(Math.random() * 3) + 1;
  for (let i = 0; i < allDayEventsCount; i++) {
    events.push({
      id: `d${dayOffset}_all${i}`,
      title: ['Vacation', 'Conference', 'Training', 'Holiday', 'Out of Office'][
        Math.floor(Math.random() * 5)
      ],
      start: parseDate(parseDate(date).setHours(0, 0, 0)),
      end: parseDate(parseDate(date).setHours(0, 0, 0)),
      icon: 'action/today',
      color: eventColors[Math.floor(Math.random() * eventColors.length)],
      isAllDay: true,
      isReadOnly: i === 0,
    });
  }

  // Generate 3-5 overlapping morning events
  const morningEventsCount = Math.floor(Math.random() * 3) + 3;
  for (let i = 0; i < morningEventsCount; i++) {
    const startHour = getRandomTime(9, 11);
    const duration = getRandomDuration(30, 180);

    events.push({
      id: `d${dayOffset}_m${i}`,
      title: ['Team Standup', 'Planning', 'Review', 'Workshop', 'Meeting'][
        Math.floor(Math.random() * 5)
      ],
      start: parseDate(parseDate(date).setHours(startHour, getRandomTime(0, 30), 0)),
      end: parseDate(
        parseDate(date).setHours(startHour + Math.floor(duration / 60), duration % 60, 0)
      ),
      icon: 'action/event',
      color: eventColors[Math.floor(Math.random() * eventColors.length)],
      isReadOnly: i === 0,
    });
  }

  // Generate 2-4 overlapping afternoon events
  const afternoonEventsCount = Math.floor(Math.random() * 3) + 2;
  for (let i = 0; i < afternoonEventsCount; i++) {
    const startHour = getRandomTime(13, 15);
    const duration = getRandomDuration(45, 240);

    events.push({
      id: `d${dayOffset}_a${i}`,
      title: ['Development Time', 'Code Review', 'Testing', 'Documentation', 'Research'][
        Math.floor(Math.random() * 5)
      ],
      start: parseDate(parseDate(date).setHours(startHour, getRandomTime(0, 30), 0)),
      end: parseDate(
        parseDate(date).setHours(startHour + Math.floor(duration / 60), duration % 60, 0)
      ),
      icon: 'content/add',
      color: eventColors[Math.floor(Math.random() * eventColors.length)],
    });
  }

  // Generate 1-2 alternative events
  const alternativeEventsCount = Math.floor(Math.random() * 2) + 1;
  for (let i = 0; i < alternativeEventsCount; i++) {
    const startHour = getRandomTime(10, 16);
    const duration = getRandomDuration(45, 180);

    events.push({
      id: `d${dayOffset}_alt${i}`,
      title: ['Team Lunch', 'Coffee Break', 'Quick Sync', 'Optional Meeting'][
        Math.floor(Math.random() * 4)
      ],
      start: parseDate(parseDate(date).setHours(startHour, getRandomTime(0, 30), 0)),
      end: parseDate(
        parseDate(date).setHours(startHour + Math.floor(duration / 60), duration % 60, 0)
      ),
      icon: 'action/check_circle',
      color: eventColors[Math.floor(Math.random() * eventColors.length)],
      alternative: true,
    });
  }

  return events;
};

const today = new Date();
export const mockEvents: BigCalendarEvent[] = [
  // Generate events for 3 months before and after today
  ...Array.from({length: 180}, (_, i) => i - 90).flatMap((offset) =>
    generateEventsForDay(today, offset)
  ),
];
