import {Button, ColorSchemeType, DataStatus, Flag, FormControl, Tooltip} from 'platform/components';
import {Box, HStack, VStack, Text, Heading, Hide} from 'platform/foundation';
import {useFormatCurrency, useFormatNumber} from 'platform/locale';
import {match} from 'ts-pattern';

import {useWatch} from 'react-hook-form';

import {always, defaultTo, isNil, isNotNil, not} from 'ramda';

import {
  useLazyGetDirectSaleMaterialItemCalculatePriceQuery,
  useGetDirectSaleMaterialItemCalculatePriceQuery,
  GetDirectSaleMaterialItemCalculatePriceRequest,
} from '@dms/api/metadaWarehouseDirectSaleItemSparePart';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {
  EMPTY_PLACEHOLDER,
  Nullish,
  TestIdProps,
  precisionCalculation,
  suffixTestId,
  useDebouncedValue,
} from 'shared';

import {EditMaterialItemForm} from '../../../../types/EditMaterialItemForm';

interface MaterialItemPriceProps extends TestIdProps {
  control: FormControl<EditMaterialItemForm>;
  isEditingDisabled: boolean;
  directSaleId: string;
  itemId: string;
  dispensingUnit: number;
  onPriceChange: (currentPrices: MaterialItemCurrentPrices) => void;
}

export type MaterialItemCurrentPrices = {
  sellingPricePerUnit: number;
  isCustomPrice: boolean;
  isDiscountEnabled: boolean;
  isDoNotApplyDiscount: boolean;
};

const DEBOUNCE_DELAY = 500;

export function RequestTabMaterialItemPrice(props: MaterialItemPriceProps) {
  const formatCurrency = useFormatCurrency();
  const formatNumber = useFormatNumber();

  const formValues = useWatch({control: props.control});

  const debouncedFormValues = useDebouncedValue(formValues, DEBOUNCE_DELAY);

  const isDiscountRateValid =
    isNil(debouncedFormValues.discount?.discountRate) ||
    (debouncedFormValues.discount.discountRate <= 100 &&
      debouncedFormValues.discount.discountRate >= 0);

  const isQuantityDivisibleByDispensingUnit =
    precisionCalculation.modulo(
      defaultTo(0, debouncedFormValues.quantity),
      props.dispensingUnit
    ) === 0;

  const isQuantityValid =
    isNotNil(debouncedFormValues.quantity) && isQuantityDivisibleByDispensingUnit;

  const requestArgs: GetDirectSaleMaterialItemCalculatePriceRequest = {
    directSaleId: props.directSaleId,
    itemId: props.itemId,
    body: {
      quantity: debouncedFormValues.quantity,
      sellingPricePerUnit: debouncedFormValues.sellingPricePerUnit,
      vatType: debouncedFormValues.vatType,
      isCustomPrice: debouncedFormValues.isCustomPrice,
      discount: {
        isDoNotApplyDiscount: debouncedFormValues.discount?.isDoNotApplyDiscount,
        isDiscountEnable: debouncedFormValues.discount?.isDiscountEnable,
        discountSourceType: debouncedFormValues.discount?.discountSourceType,
        discountRate: debouncedFormValues.discount?.isDiscountEnable
          ? defaultTo(0, debouncedFormValues.discount?.discountRate) / 100
          : 0,
      },
      getCurrentPrice: false,
    },
  };

  const materialItemPrices = useGetDirectSaleMaterialItemCalculatePriceQuery(requestArgs, {
    skip:
      isNil(debouncedFormValues) ||
      not(isDiscountRateValid) ||
      not(isQuantityValid) ||
      isNil(debouncedFormValues.sellingPricePerUnit),
  });

  const [calculatePrice, calculatePriceQuery] =
    useLazyGetDirectSaleMaterialItemCalculatePriceQuery();

  const customCurrencyFormat = (amount: number | Nullish, currency: string | Nullish) => {
    if (isNil(amount) || isNil(currency)) {
      return EMPTY_PLACEHOLDER;
    }

    return formatCurrency(amount, currency, 2);
  };

  const articlePriceStatusIndicatorTooltip = match(materialItemPrices?.data?.articlePriceIndicator)
    .with('EQUAL', always(i18n.t('entity.warehouse.labels.equalPriceTooltip')))
    .with('HIGHER', always(i18n.t('entity.warehouse.labels.highPriceTooltip')))
    .with('LOWER', always(i18n.t('entity.warehouse.labels.lowPriceTooltip')))
    .with('NO_PRICE', always(i18n.t('entity.warehouse.labels.noPriceTooltip')))
    .otherwise(always(null));

  const articlePriceStatusIndicatorLabel = match(materialItemPrices?.data?.articlePriceIndicator)
    .with('EQUAL', always(i18n.t('entity.warehouse.labels.equal')))
    .with('HIGHER', always(i18n.t('entity.warehouse.labels.high')))
    .with('LOWER', always(i18n.t('entity.warehouse.labels.low')))
    .with('NO_PRICE', always(i18n.t('entity.warehouse.labels.noPrice')))
    .otherwise(always(EMPTY_PLACEHOLDER));

  const articlePriceStatusIndicatorColor = match(materialItemPrices?.data?.articlePriceIndicator)
    .returnType<ColorSchemeType | undefined>()
    .with('EQUAL', always('green'))
    .with('HIGHER', always('orange'))
    .with('LOWER', always('red'))
    .with('NO_PRICE', always('neutral'))
    .otherwise(always(undefined));

  const marginTotalPercentage =
    defaultTo(0, materialItemPrices?.data?.marginTotal?.marginPercentage) * 100;

  const doesMarginExist = materialItemPrices?.data?.marginTotal?.isMarginExisting;

  const isMarginUnderMinimum = materialItemPrices?.data?.marginTotal?.isMarginUnderMinimum;

  return (
    <DataStatus
      isLoading={materialItemPrices?.isLoading}
      isError={materialItemPrices?.isError}
      minHeight={15}
    >
      <VStack spacing={4}>
        <HStack spacing={4}>
          <Box flex={1}>
            <VStack spacing={1}>
              <Text color="secondary" size="xSmall">
                {i18n.t('general.labels.averagePurchasePrice')}
              </Text>
              <Heading
                size={5}
                color="secondary"
                data-testid={suffixTestId('purchasePriceWithoutVat', props)}
              >
                {customCurrencyFormat(
                  materialItemPrices?.data?.purchaseTotalPrice?.withoutVat?.amount,
                  materialItemPrices?.data?.purchaseTotalPrice?.withoutVat?.currency
                )}
              </Heading>
              <Text
                color="secondary"
                size="xSmall"
                data-testid={suffixTestId('purchasePriceWithVat', props)}
              >
                {`${customCurrencyFormat(
                  materialItemPrices?.data?.purchaseTotalPrice?.withVat?.amount,
                  materialItemPrices?.data?.purchaseTotalPrice?.withVat?.currency
                )} ${i18n.t('general.labels.withVat')}`}
              </Text>
            </VStack>
          </Box>

          <Box flex={1}>
            <VStack spacing={1}>
              <Text color="secondary" size="xSmall">
                {i18n.t('entity.warehouse.labels.articleSalePrice')}
              </Text>

              <HStack spacing={2} align="center">
                <Heading size={5} data-testid={suffixTestId('articlePriceWithoutVat', props)}>
                  {customCurrencyFormat(
                    materialItemPrices?.data?.articlePrice?.withoutVat?.amount,
                    materialItemPrices?.data?.articlePrice?.withoutVat?.currency
                  )}
                </Heading>
                <Tooltip placement="top" label={articlePriceStatusIndicatorTooltip}>
                  <Hide when={isNil(articlePriceStatusIndicatorColor)}>
                    <Flag
                      label={articlePriceStatusIndicatorLabel}
                      colorScheme={articlePriceStatusIndicatorColor}
                      size="small"
                      data-testid={suffixTestId('articleSalePriceStatus', props)}
                    />
                  </Hide>
                </Tooltip>
              </HStack>

              <Text
                color="secondary"
                size="xSmall"
                data-testid={suffixTestId('articlePriceWithVat', props)}
              >
                {`${customCurrencyFormat(
                  materialItemPrices?.data?.articlePrice?.withVat?.amount,
                  materialItemPrices?.data?.articlePrice?.withVat?.currency
                )} ${i18n.t('general.labels.withVat')}`}
              </Text>
            </VStack>
          </Box>

          <Box flex={1}>
            <VStack spacing={1}>
              <Text color="secondary" size="xSmall">
                {i18n.t('general.labels.sellingPrice')}
              </Text>
              <Heading size={5} data-testid={suffixTestId('sellingPriceWithoutVat', props)}>
                {customCurrencyFormat(
                  materialItemPrices?.data?.sellingTotalPrice?.withoutVat?.amount,
                  materialItemPrices?.data?.sellingTotalPrice?.withoutVat?.currency
                )}
              </Heading>
              <Text
                color="secondary"
                size="xSmall"
                data-testid={suffixTestId('sellingPriceWithVat', props)}
              >
                {`${customCurrencyFormat(
                  materialItemPrices?.data?.sellingTotalPrice?.withVat?.amount,
                  materialItemPrices?.data?.sellingTotalPrice?.withVat?.currency
                )} ${i18n.t('general.labels.withVat')}`}
              </Text>
            </VStack>
          </Box>

          <Box flex={1}>
            <VStack spacing={1}>
              <Text color="secondary" size="xSmall">
                {i18n.t('entity.warehouse.labels.marginTotalUnitPercent')}
              </Text>

              <HStack spacing={1} align="center">
                <Heading
                  size={5}
                  color={isMarginUnderMinimum ? 'danger' : 'success'}
                  data-testid={suffixTestId('marginWithoutVat', props)}
                >
                  {`${customCurrencyFormat(
                    materialItemPrices?.data?.marginTotal?.withoutVat?.amount,
                    materialItemPrices?.data?.marginTotal?.withoutVat?.currency
                  )}/${customCurrencyFormat(
                    materialItemPrices?.data?.marginPerUnit?.withoutVat?.amount,
                    materialItemPrices?.data?.marginPerUnit?.withoutVat?.currency
                  )}`}
                </Heading>
                <Flag
                  label={
                    doesMarginExist
                      ? `${formatNumber(marginTotalPercentage, 2)} %`
                      : EMPTY_PLACEHOLDER
                  }
                  colorScheme={isMarginUnderMinimum ? 'red' : 'green'}
                  size="small"
                  data-testid={suffixTestId('marginPercentage', props)}
                />
              </HStack>

              <Text
                color="secondary"
                size="xSmall"
                data-testid={suffixTestId('marginWithVat', props)}
              >
                {`${customCurrencyFormat(
                  materialItemPrices?.data?.marginTotal?.withVat?.amount,
                  materialItemPrices?.data?.marginTotal?.withVat?.currency
                )}/${customCurrencyFormat(
                  materialItemPrices?.data?.marginPerUnit?.withVat?.amount,
                  materialItemPrices?.data?.marginPerUnit?.withVat?.currency
                )} ${i18n.t('general.labels.withVat')}`}
              </Text>
            </VStack>
          </Box>
        </HStack>

        <HStack>
          <Button
            isDisabled={props.isEditingDisabled}
            isLoading={calculatePriceQuery.isLoading}
            variant="link"
            leftIcon="navigation/refresh"
            title={i18n.t('entity.warehouse.actions.getCurrentPrice')}
            onClick={() =>
              calculatePrice({...requestArgs, body: {...requestArgs.body, getCurrentPrice: true}})
                .unwrap()
                .then((result) => {
                  props.onPriceChange({
                    sellingPricePerUnit: result.sellingPricePerUnit.amount,
                    isCustomPrice: result.isCustomPrice,
                    isDiscountEnabled: result.itemsDiscounts.isDiscountEnable,
                    isDoNotApplyDiscount: result.itemsDiscounts.isDoNotApplyDiscount,
                  });
                })
                .catch(handleApiError)
            }
            data-testid={suffixTestId('actions.getCurrentPrice', props)}
          />
        </HStack>
      </VStack>
    </DataStatus>
  );
}
