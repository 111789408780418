import {minutesToHours} from 'date-fns';
import {AttributesRow} from 'platform/components';
import {useDateTimeFormatter} from 'platform/locale';

import {defaultTo, isNil} from 'ramda';

import {useGetVehicleQuery} from '@dms/api/vehicle';
import i18n from '@dms/i18n';

import {parseDate, Nullish, buildArray} from 'shared';

import {useVehicleCatalogue} from '../../../hooks/useVehicleCatalogue';

const convertMinutesToTime = (minutes: number | Nullish): string | undefined => {
  if (isNil(minutes) || minutes < 0) {
    return;
  }

  const timeHours = minutesToHours(minutes).toString().padStart(2, '0');
  const timeMinutes = (minutes % 60).toString().padStart(2, '0');

  return `${timeHours}:${timeMinutes}`;
};

export function useFuelVehicleParameters(vehicleId: string | Nullish) {
  const formatDateTime = useDateTimeFormatter();

  const {data: vehicle} = useGetVehicleQuery(
    {vehicleId: defaultTo('', vehicleId)},
    {skip: !vehicleId}
  );

  const [vehicleUtils] = useVehicleCatalogue(vehicle?.type);

  const fuelType = vehicleUtils.getFuelType(vehicle?.fuelType);
  const secondaryFuelType = vehicleUtils.getFuelType(vehicle?.secondaryFuelType);
  const hybridType = vehicleUtils.getHybridType(vehicle?.vehicle?.hybridType);
  const batteryType = vehicleUtils.getBatteryType(vehicle?.vehicle.battery?.batteryType);
  const chargingConnectorType = vehicleUtils.getChargingConnectorType(
    vehicle?.vehicle.battery?.chargingConnectorType
  );
  const batteryOwnershipType = vehicleUtils.getBatteryOwnershipType(
    vehicle?.vehicle.battery?.batteryOwnershipType
  );

  const isFuelTypeLpgOrCng =
    vehicle?.fuelType === 'FUELTYPE_LPG' || vehicle?.fuelType === 'FUELTYPE_CNG';
  const isFuelTypeLpgOrCngorHydrogen =
    vehicle?.fuelType === 'FUELTYPE_LPG' ||
    vehicle?.fuelType === 'FUELTYPE_CNG' ||
    vehicle?.fuelType === 'FUELTYPE_HYDROGEN';
  const isElectricVehicle = vehicle?.fuelType === 'FUELTYPE_ELECTRIC' && vehicle?.vehicle.battery;
  const isHybrid = vehicle?.fuelType === 'FUELTYPE_HYBRID' && vehicle?.vehicle.battery;
  const isFullHybrid = isHybrid && vehicle?.vehicle?.hybridType === 'HYBRIDTYPE_FULL';
  const isPhevHybrid = isHybrid && vehicle?.vehicle?.hybridType === 'HYBRIDTYPE_PHEV';

  return buildArray<AttributesRow>()
    .add({
      label: i18n.t('entity.vehicle.labels.fuel'),
      value: fuelType,
      testId: 'fuel',
    })
    .when(vehicle?.secondaryFuelType, {
      label: i18n.t('entity.vehicle.labels.secondaryFuelType'),
      value: secondaryFuelType,
      testId: 'secondaryFuelType',
    })
    .when(isFuelTypeLpgOrCng, {
      label: i18n.t('entity.vehicle.labels.gasRevisionValidUntil'),
      value:
        vehicle?.state?.gasRevisionValidUntil &&
        formatDateTime('dateShort', parseDate(vehicle?.state?.gasRevisionValidUntil)),
      testId: 'gasRevisionValidUntil',
    })
    .when(isFuelTypeLpgOrCngorHydrogen, {
      label: i18n.t('entity.vehicle.labels.pressureVesselExpires'),
      value:
        vehicle?.state?.pressureVesselValidUntil &&
        formatDateTime('dateShort', parseDate(vehicle?.state?.pressureVesselValidUntil)),
      testId: 'pressureVesselExpires',
    })
    .when(vehicle?.fuelType === 'FUELTYPE_HYBRID', {
      label: i18n.t('general.labels.hybridType'),
      value: hybridType,
      testId: 'hybridType',
    })
    .when(isElectricVehicle, {
      label: `${i18n.t('entity.vehicle.labels.batteryChargingAc')} (${i18n.t(
        'general.metric.kW'
      )})`,
      value: vehicle?.vehicle.electricVehicleBattery?.chargingAC,
      testId: 'batteryChargingAc',
    })
    .when(isElectricVehicle, {
      label: `${i18n.t('entity.vehicle.labels.batteryChargingTimeAc')} (${i18n.t(
        'general.metric.h'
      )})`,
      value: vehicle?.vehicle.battery?.chargingTimeAC,
      testId: 'batteryChargingTimeAc',
    })
    .when(isElectricVehicle, {
      label: `${i18n.t('entity.vehicle.labels.batteryChargingDc')} (${i18n.t(
        'general.metric.kW'
      )})`,
      value: vehicle?.vehicle.electricVehicleBattery?.chargingDC,
      testId: 'batteryChargingDc',
    })
    .when(isElectricVehicle, {
      label: `${i18n.t('entity.vehicle.labels.batteryChargingTimeDc')} (${i18n.t(
        'general.metric.min'
      )})`,
      value: vehicle?.vehicle.battery?.chargingTimeDC,
      testId: 'batteryChargingTimeDc',
    })
    .when(isElectricVehicle, {
      label: i18n.t('entity.vehicle.labels.batteryType'),
      value: batteryType,
      testId: 'batteryType',
    })
    .when(isElectricVehicle, {
      label: `${i18n.t('entity.vehicle.labels.batteryCapacity')} (${i18n.t('general.metric.kW')})`,
      value: vehicle?.vehicle.electricVehicleBattery?.batteryCapacityWh,
      testId: 'batteryCapacityWh',
    })
    .when(isElectricVehicle, {
      label: `${i18n.t('entity.vehicle.labels.batteryCapacity')} (${i18n.t('general.metric.Ah')})`,
      value: vehicle?.vehicle.electricVehicleBattery?.batteryCapacityAh,
      testId: 'batteryCapacityAh',
    })
    .when(isElectricVehicle, {
      label: `${i18n.t('entity.vehicle.labels.batteryVoltage')} (${i18n.t('general.metric.kW')})`,
      value: vehicle?.vehicle.electricVehicleBattery?.batteryVoltage,
      testId: 'batteryVoltage',
    })
    .when(isElectricVehicle, {
      label: i18n.t('entity.vehicle.labels.batteryCount'),
      value: vehicle?.vehicle.battery?.batteryCount,
      testId: 'batteryCount',
    })
    .when(isElectricVehicle, {
      label: i18n.t('entity.vehicle.labels.batteryChargingConnectoryType'),
      value: chargingConnectorType,
      testId: 'batteryChargingConnectoryType',
    })
    .when(isElectricVehicle, {
      label: `${i18n.t('entity.vehicle.labels.batteryElectricRange')} (${i18n.t(
        'general.metric.km'
      )})`,
      value: vehicle?.vehicle.battery?.electricRange,
      testId: 'batteryElectricRange',
    })
    .when(isElectricVehicle, {
      label: `${i18n.t('entity.vehicle.labels.batteryMileageLimit')} (${i18n.t(
        'general.metric.km'
      )})`,
      value: vehicle?.vehicle.battery?.batteryMileageLimit,
      testId: 'batteryMileageLimit',
    })
    .when(isElectricVehicle, {
      label: i18n.t('entity.vehicle.labels.batteryWarrantyUntil'),
      value:
        vehicle?.vehicle.battery?.batteryWarrantyUntil &&
        formatDateTime('dateShort', parseDate(vehicle?.vehicle.battery?.batteryWarrantyUntil)),
      testId: 'batteryWarrantyUntil',
    })
    .when(isElectricVehicle, {
      label: i18n.t('entity.vehicle.labels.batteryOwnershipType'),
      value: batteryOwnershipType,
      testId: 'batteryOwnershipType',
    })
    .when(isElectricVehicle, {
      label: i18n.t('entity.vehicle.labels.hasRangeExtender'),
      value: vehicle?.vehicle.battery?.hasRangeExtender
        ? i18n.t('general.labels.yes')
        : i18n.t('general.labels.no'),
      testId: 'hasRangeExtender',
    })
    .when(isFullHybrid, {
      label: i18n.t('entity.vehicle.labels.batteryType'),
      value: batteryType,
      testId: 'batteryType',
    })
    .when(isFullHybrid, {
      label: `${i18n.t('entity.vehicle.labels.batteryCapacity')} (${i18n.t('general.metric.kW')})`,
      value: vehicle?.vehicle.electricVehicleBattery?.batteryCapacityWh,
      testId: 'batteryCapacityWh',
    })
    .when(isFullHybrid, {
      label: `${i18n.t('entity.vehicle.labels.batteryCapacity')} (${i18n.t('general.metric.Ah')})`,
      value: vehicle?.vehicle.electricVehicleBattery?.batteryCapacityAh,
      testId: 'batteryCapacityAh',
    })
    .when(isFullHybrid, {
      label: `${i18n.t('entity.vehicle.labels.batteryVoltage')} (${i18n.t('general.metric.kW')})`,
      value: vehicle?.vehicle.electricVehicleBattery?.batteryVoltage,
      testId: 'batteryVoltage',
    })
    .when(isFullHybrid, {
      label: i18n.t('entity.vehicle.labels.batteryCount'),
      value: vehicle?.vehicle.battery?.batteryCount,
      testId: 'batteryCount',
    })
    .when(isFullHybrid, {
      label: `${i18n.t('entity.vehicle.labels.batteryElectricRange')} (${i18n.t(
        'general.metric.km'
      )})`,
      value: vehicle?.vehicle.battery?.electricRange,
      testId: 'batteryElectricRange',
    })
    .when(isFullHybrid, {
      label: `${i18n.t('entity.vehicle.labels.batteryMileageLimit')} (${i18n.t(
        'general.metric.km'
      )})`,
      value: vehicle?.vehicle.battery?.batteryMileageLimit,
      testId: 'batteryMileageLimit',
    })
    .when(isFullHybrid, {
      label: i18n.t('entity.vehicle.labels.batteryWarrantyUntil'),
      value:
        vehicle?.vehicle.battery?.batteryWarrantyUntil &&
        formatDateTime('dateShort', parseDate(vehicle?.vehicle.battery?.batteryWarrantyUntil)),
      testId: 'batteryWarrantyUntil',
    })
    .when(isPhevHybrid, {
      label: `${i18n.t('entity.vehicle.labels.batteryChargingAc')} (${i18n.t(
        'general.metric.kW'
      )})`,
      value: vehicle?.vehicle.electricVehicleBattery?.chargingAC,
      testId: 'batteryChargingAc',
    })
    .when(isPhevHybrid, {
      label: `${i18n.t('entity.vehicle.labels.batteryChargingTimeAc')} (${i18n.t(
        'general.metric.h'
      )})`,
      value: convertMinutesToTime(vehicle?.vehicle.battery?.chargingTimeAC),
      testId: 'batteryChargingTimeAc',
    })
    .when(isPhevHybrid, {
      label: `${i18n.t('entity.vehicle.labels.batteryChargingDc')} (${i18n.t(
        'general.metric.kW'
      )})`,
      value: vehicle?.vehicle.electricVehicleBattery?.chargingDC,
      testId: 'batteryChargingDc',
    })
    .when(isPhevHybrid, {
      label: `${i18n.t('entity.vehicle.labels.batteryChargingTimeDc')} (${i18n.t(
        'general.metric.min'
      )})`,
      value: convertMinutesToTime(vehicle?.vehicle.battery?.chargingTimeDC),
      testId: 'batteryChargingTimeDc',
    })
    .when(isPhevHybrid, {
      label: i18n.t('entity.vehicle.labels.batteryType'),
      value: batteryType,
      testId: 'batteryType',
    })
    .when(isPhevHybrid, {
      label: `${i18n.t('entity.vehicle.labels.batteryCapacity')} (${i18n.t('general.metric.kW')})`,
      value: vehicle?.vehicle.electricVehicleBattery?.batteryCapacityWh,
      testId: 'batteryCapacityWh',
    })
    .when(isPhevHybrid, {
      label: `${i18n.t('entity.vehicle.labels.batteryCapacity')} (${i18n.t('general.metric.Ah')})`,
      value: vehicle?.vehicle.electricVehicleBattery?.batteryCapacityAh,
      testId: 'batteryCapacityAh',
    })
    .when(isPhevHybrid, {
      label: `${i18n.t('entity.vehicle.labels.batteryVoltage')} (${i18n.t('general.metric.kW')})`,
      value: vehicle?.vehicle.electricVehicleBattery?.batteryVoltage,
      testId: 'batteryVoltage',
    })
    .when(isPhevHybrid, {
      label: i18n.t('entity.vehicle.labels.batteryCount'),
      value: vehicle?.vehicle.battery?.batteryCount,
      testId: 'batteryCount',
    })
    .when(isPhevHybrid, {
      label: i18n.t('entity.vehicle.labels.batteryChargingConnectoryType'),
      value: chargingConnectorType,
      testId: 'batteryChargingConnectoryType',
    })
    .when(isPhevHybrid, {
      label: `${i18n.t('entity.vehicle.labels.batteryElectricRange')} (${i18n.t(
        'general.metric.km'
      )})`,
      value: vehicle?.vehicle.battery?.electricRange,
      testId: 'batteryElectricRange',
    })
    .when(isPhevHybrid, {
      label: `${i18n.t('entity.vehicle.labels.batteryMileageLimit')} (${i18n.t(
        'general.metric.km'
      )})`,
      value: vehicle?.vehicle.battery?.batteryMileageLimit,
      testId: 'batteryMileageLimit',
    })
    .when(isPhevHybrid, {
      label: i18n.t('entity.vehicle.labels.batteryWarrantyUntil'),
      value:
        vehicle?.vehicle.battery?.batteryWarrantyUntil &&
        formatDateTime('dateShort', parseDate(vehicle?.vehicle.battery?.batteryWarrantyUntil)),
      testId: 'batteryWarrantyUntil',
    });
}
