import {DynamicUi} from 'platform/components';

import {forwardRef, useImperativeHandle} from 'react';
import {createPortal} from 'react-dom';

import {isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {useTimeout} from 'shared';

import {useDataGridContext} from '../context/useDataGridContext';
import {useDataGridFiltersModel} from '../context/useDataGridFiltersModel';
import {IToolPanelParams} from '../types/AgGridTypes';
import {dynamicUiReturnValuesToFormFilterValues} from '../utils/dynamicUiReturnValuesToFormFilterValues';
import {processFilterValues} from '../utils/processFilterValues';
import {validateExternalFiltersKeys} from '../utils/validateExternalFiltersKeys';

export const CustomFiltersPanelWrapper = forwardRef(({api}: IToolPanelParams, ref) => {
  const {
    externalFilterId,
    filterSchema,
    onExternalFilterChange,
    filterComponent: FilterComponent,
  } = useDataGridContext();

  useImperativeHandle(ref, () => ({}));

  const el = document.getElementById(externalFilterId || '');

  const {filterValues} = useDataGridFiltersModel();

  useTimeout(() => {
    if (onExternalFilterChange) {
      onExternalFilterChange(api.getFilterModel());
    }
  }, 10);

  const handleFilterValue = (newFilterValues: Record<string, any>) => {
    validateExternalFiltersKeys(newFilterValues, api);

    // Convert false values to undefined in the filter values
    const processedFilterValues = processFilterValues(newFilterValues);

    if (
      JSON.stringify(filterValues) === JSON.stringify(processedFilterValues) ||
      isNilOrEmpty(processedFilterValues)
    ) {
      return;
    }

    if (onExternalFilterChange) {
      onExternalFilterChange(dynamicUiReturnValuesToFormFilterValues(processedFilterValues));
    }

    api?.setFilterModel(dynamicUiReturnValuesToFormFilterValues(processedFilterValues));
    api.onFilterChanged();
  };

  const handleFilterFormValue = (newFilterValues: Record<string, any>) => {
    validateExternalFiltersKeys(newFilterValues, api);

    // Convert false values to undefined in the filter values
    const processedFilterValues = processFilterValues(newFilterValues);

    if (
      JSON.stringify(filterValues) === JSON.stringify(processedFilterValues) ||
      isNilOrEmpty(processedFilterValues)
    ) {
      return;
    }

    if (onExternalFilterChange) {
      onExternalFilterChange(processedFilterValues);
    }

    api?.setFilterModel(processedFilterValues);
    api.onFilterChanged();
  };

  if (isNil(el)) {
    return null;
  }

  if (FilterComponent) {
    return createPortal(
      <div data-testid="externalFilterId">
        <FilterComponent
          onChange={handleFilterFormValue}
          values={filterValues ?? {}}
          saveOnChange
        />
      </div>,
      el
    );
  }

  if (filterSchema) {
    return createPortal(
      <DynamicUi
        key={JSON.stringify(filterValues)}
        defaultValues={filterValues ?? {}}
        schema={filterSchema}
        onChange={handleFilterValue}
      />,
      el
    );
  }
});
