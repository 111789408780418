import {omneticApi} from '@dms/api/core';

import {
  CreateSectorApiArg,
  CreateSectorApiResponse,
  DeleteSectorApiArg,
  DeleteSectorApiResponse,
  GetOccupiedSpaceApiArg,
  GetOccupiedSpaceApiResponse,
  GetOccupiedSpacesApiArg,
  GetOccupiedSpacesApiResponse,
  GetSectorApiArg,
  GetSectorApiResponse,
  GetSectorsApiArg,
  GetSectorsApiResponse,
  UpdateOccupiedSpaceApiArg,
  UpdateOccupiedSpaceApiResponse,
  UpdateSectorApiArg,
  UpdateSectorApiResponse,
} from './types';

export const storageApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    createSector: build.mutation<CreateSectorApiResponse, CreateSectorApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/storage/sector`,
        method: 'POST',
        body: queryArg.createSectorRequestBody,
      }),
      invalidatesTags: ['storageSectors'],
    }),
    getSectors: build.query<GetSectorsApiResponse, GetSectorsApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/storage/sector`,
        params: {type: queryArg.type, branchId: queryArg.branchId},
      }),
      providesTags: ['storageSectors'],
    }),
    deleteSector: build.mutation<DeleteSectorApiResponse, DeleteSectorApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/storage/sector/${queryArg.sectorId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'storageSectors',
        {type: 'storageSector', id: queryArg.sectorId},
      ],
    }),
    getSector: build.query<GetSectorApiResponse, GetSectorApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/storage/sector/${queryArg.sectorId}`,
      }),
      providesTags: (result, error, queryArg) => [{type: 'storageSector', id: queryArg.sectorId}],
    }),
    updateSector: build.mutation<UpdateSectorApiResponse, UpdateSectorApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/storage/sector/${queryArg.sectorId}`,
        method: 'PUT',
        body: queryArg.updateSectorRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'storageSectors',
        {type: 'storageSector', id: queryArg.sectorId},
      ],
    }),
    getOccupiedSpace: build.query<GetOccupiedSpaceApiResponse, GetOccupiedSpaceApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/storage/space/occupied/${queryArg.spaceType}/${queryArg.recordId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'storageOccupiedSpace', id: queryArg.recordId},
      ],
    }),
    updateOccupiedSpace: build.mutation<UpdateOccupiedSpaceApiResponse, UpdateOccupiedSpaceApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/storage/space/occupied/${queryArg.spaceType}/${queryArg.recordId}`,
        method: 'PUT',
        body: queryArg.updateOccupiedSpaceRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'storageOccupiedSpace', id: queryArg.recordId},
        {type: 'storageSector', id: queryArg.updateOccupiedSpaceRequestBody.space?.sectorId ?? ''},
        {
          type: 'storageOccupiedSpaces',
          id: queryArg.updateOccupiedSpaceRequestBody.space?.sectorId ?? '',
        },
      ],
    }),
    getOccupiedSpaces: build.query<GetOccupiedSpacesApiResponse, GetOccupiedSpacesApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/storage/sector/${queryArg.sectorId}/space/occupied`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'storageOccupiedSpaces', id: queryArg.sectorId},
      ],
    }),
  }),
});

export const {
  useCreateSectorMutation,
  useGetSectorsQuery,
  useDeleteSectorMutation,
  useGetSectorQuery,
  useUpdateSectorMutation,
  useGetOccupiedSpaceQuery,
  useUpdateOccupiedSpaceMutation,
  useGetOccupiedSpacesQuery,
  useLazyGetOccupiedSpaceQuery,
} = storageApi;
