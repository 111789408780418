import {match} from 'ts-pattern';

import {always} from 'ramda';

import {interestRoutes, businessCaseRoutes, vehiclesRoutes} from '@dms/routes';

import {OpportunityType} from '../types/OpportunityType';

export const getRedirectUrl = (opportunityType: OpportunityType) =>
  match(opportunityType)
    .with('newInterests', always(interestRoutes.overview))
    .with('activeInterests', always(interestRoutes.overview))
    .with('offers', always(businessCaseRoutes.home))
    .with('contracts', always(businessCaseRoutes.home))
    .with('salesFunnel', always(businessCaseRoutes.home))
    .with('closed', always(businessCaseRoutes.home))
    .with('inBuying', always(vehiclesRoutes.home))
    .with('inSale', always(vehiclesRoutes.home))
    .with('warehouseTotal', always(vehiclesRoutes.home))
    .exhaustive();
