import {DataStatus} from 'platform/components';

import {forwardRef, useImperativeHandle, useState} from 'react';

import {isNotNil} from 'ramda';

import {useGetArticleCategoryListQuery} from '@dms/api/metadaWarehouseArticleCategory';

import {TestIdProps, suffixTestId} from 'shared';

import {DataGridTreeFolderHandle} from '../../types/treeFolder/DataGridTreeFolderHandle';
import {FolderTree} from '../FolderTree/FolderTree';
import {ActiveFolder} from '../MoveToFolder/types/activeFolder';

const FOLDER_CONTEXT_TARGET = 'spare-parts-catalog';

interface ArticleFolderTreeProps extends TestIdProps {}

export const ArticleFolderTree = forwardRef<DataGridTreeFolderHandle, ArticleFolderTreeProps>(
  (props, ref) => {
    const [activeFolder, setActiveFolder] = useState<ActiveFolder | undefined>();
    const [includeSubfolders, setIncludeSubfolders] = useState<boolean>(true);

    useImperativeHandle(ref, () => ({activeFolderId: activeFolder?.id, includeSubfolders}), [
      activeFolder?.id,
      includeSubfolders,
    ]);

    const {
      data: articleCategoryList,
      isLoading: isArticleCategoryListLoading,
      isError: isArticleCategoryListError,
    } = useGetArticleCategoryListQuery();

    const catalogueIds = articleCategoryList?.categories?.map((catalogue) => catalogue.id) ?? [];

    return (
      <DataStatus isLoading={isArticleCategoryListLoading} isError={isArticleCategoryListError}>
        <FolderTree
          contextIds={catalogueIds}
          contextTarget={FOLDER_CONTEXT_TARGET}
          onFolderChange={(id, contextId) =>
            setActiveFolder(isNotNil(id) && isNotNil(contextId) ? {id, contextId} : undefined)
          }
          activeFolderId={activeFolder?.id}
          shouldIncludeSubfolders={includeSubfolders}
          onIncludeSubfoldersChange={setIncludeSubfolders}
          data-testid={suffixTestId('catalogueTree', props)}
        />
      </DataStatus>
    );
  }
);
