import {FormSubmitHandler} from 'platform/components';

import {useParams} from 'react-router-dom';

import {isNil} from 'ramda';

import {
  useGetTireRimQuery,
  usePostTireRimMutation,
  usePatchTireRimMutation,
} from '@dms/api/metadaTires';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {useNavigate} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {TireDictionaryForm} from '../../components/TireDictionaryForm/TireDictionaryForm';
import {TireDictionaryFormType} from '../../types/TireDictionaryFormType';

export function TireInventoryRimDetail() {
  const {id} = useParams();
  const navigate = useNavigate();
  const {data, isLoading, isError} = useGetTireRimQuery({tireRimId: id ?? ''}, {skip: isNil(id)});
  const [postTireRim] = usePostTireRimMutation();
  const [patchTireRim] = usePatchTireRimMutation();

  const handleSubmit: FormSubmitHandler<TireDictionaryFormType> = async (formData) => {
    const body = {
      name: formData.name,
    };

    if (isNil(id)) {
      return await postTireRim({
        body,
      })
        .unwrap()
        .then(() => navigate(settingsRoutes.tireRimSize))
        .catch(handleApiError);
    }

    await patchTireRim({
      tireRimId: id,
      body,
    })
      .unwrap()
      .then(() => navigate(settingsRoutes.tireRimSize))
      .catch(handleApiError);
  };

  return (
    <SettingsTemplate
      header={{
        title: data?.name ?? i18n.t('entity.tiresInventory.labels.newTireRimSize'),
        breadcrumbs: [
          {
            label: i18n.t('module.tiresInventory.title'),
            isCurrentPage: true,
          },
          {
            label: i18n.t('entity.tiresInventory.labels.tireRimSize'),
            href: settingsRoutes.tireRimSize,
          },
        ],
      }}
      isLoading={isLoading}
      isError={isError}
      isCreating={isNil(id)}
      data-testid={testIds.settings.tireRimSizeDetail('template')}
    >
      <TireDictionaryForm
        onSubmit={handleSubmit}
        defaultValues={{name: data?.name}}
        onDiscard={() => navigate(settingsRoutes.tireRimSize)}
        data-testid={testIds.settings.tireRimSizeDetail('form')}
      />
    </SettingsTemplate>
  );
}
