import {Action, Actions, DataStatus, DeleteDialog, TextInput} from 'platform/components';
import {Box, Hide, HStack, Icon, Text, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';
import styled from 'styled-components';

import {useCallback, useEffect, useRef, useState} from 'react';

import {SourcingComment} from '@dms/api/sourcing';
import i18n from '@dms/i18n';

import {parseDate, RequiredTestIdProps, suffixTestId} from 'shared';

export interface CommentItemProps extends RequiredTestIdProps {
  comment: SourcingComment;
  onDelete: (comment: SourcingComment) => void;
  onEdit: (comment: SourcingComment, newMessage: string) => void;
}

export function CommentItem(props: CommentItemProps) {
  const formatDateTime = useDateTimeFormatter();
  const inputRef = useRef<HTMLInputElement>(null);

  const [editMode, toggleEditMode] = useState(false);
  const [inputValue, setInputValue] = useState<string | null>(props.comment.message);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const closeConfirmModal = useCallback(() => setIsConfirmModalOpen(false), []);

  const handleEdit = () => {
    props.onEdit(props.comment, inputValue ?? '');
    toggleEditMode(false);
  };

  const handleOnEditMode = useCallback(() => {
    toggleEditMode(true);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }, []);

  const actions: Action[] = [
    {
      type: 'dropdown-iconButton',
      icon: 'navigation/more_vert',
      priority: 'secondary',
      menuItems: [
        {
          leftIcon: 'image/edit',
          label: i18n.t('general.actions.edit'),
          onClick: handleOnEditMode,
        },
        {
          leftIcon: 'action/delete',
          label: i18n.t('general.actions.delete'),
          onClick: () => setIsConfirmModalOpen(true),
        },
      ],
    },
  ];

  useEffect(() => {
    setInputValue(props.comment.message);
  }, [props.comment.message]);

  return (
    <>
      <DataStatus isLoading={!props.comment.uuid}>
        <HStack justify="space-between" spacing={2}>
          <HStack spacing={2}>
            <Box>
              <Avatar>
                <Icon value="social/person" size={10} />
              </Avatar>
            </Box>
            <VStack>
              <Text alternative>
                {props.comment?.author?.firstName} {props.comment?.author?.lastName}
              </Text>
              <Text size="small" color="secondary">
                {props.comment.createdAt
                  ? formatDateTime('dateTimeLong', parseDate(props.comment.createdAt))
                  : null}
              </Text>
              {editMode ? (
                <TextInput
                  ref={inputRef}
                  value={inputValue}
                  onChange={setInputValue}
                  onBlur={handleEdit}
                />
              ) : (
                <Text size="small" color="secondary">
                  {props.comment.message}
                </Text>
              )}
            </VStack>
          </HStack>
          <Hide when={editMode}>
            <Actions
              size="small"
              actions={actions}
              data-testid={suffixTestId('commentActions', props)}
            />
          </Hide>
        </HStack>
      </DataStatus>

      <DeleteDialog
        isOpen={isConfirmModalOpen}
        data-testid={suffixTestId('deleteDialog', props)}
        text={i18n.t('page.comments.notifications.deleteComment')}
        onConfirm={() => {
          props.onDelete(props.comment);
          closeConfirmModal();
        }}
        onClose={closeConfirmModal}
      />
    </>
  );
}

const Avatar = styled.div`
  background-color: ${({theme}) => theme.colors.palettes.neutral[30][100]};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
