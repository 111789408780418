import {metadaApi} from '@dms/api/core';

import {
  AddrDetailApiResponse,
  AddrDetailApiArg,
  AddrSuggestionsApiResponse,
  AddrSuggestionsApiArg,
} from './types';

export const metadaAddressApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    addrDetail: build.query<AddrDetailApiResponse, AddrDetailApiArg>({
      query: (queryArg) => ({
        url: `address/v1/detail/${queryArg.placeId}`,
        params: {language: queryArg.language},
      }),
    }),
    addrSuggestions: build.query<AddrSuggestionsApiResponse, AddrSuggestionsApiArg>({
      query: (queryArg) => ({
        url: `address/v1/suggestions`,
        params: {term: queryArg.term, country: queryArg.country, language: queryArg.language},
      }),
    }),
  }),
});

export const {
  useAddrDetailQuery,
  useAddrSuggestionsQuery,
  useLazyAddrSuggestionsQuery,
  useLazyAddrDetailQuery,
} = metadaAddressApi;
