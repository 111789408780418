import {Box, BoxProps} from 'platform/foundation';

import {UserSidebarIconSize} from '@dms/api/shared';

import {TestIdProps} from 'shared';

import {DevSettingsSidebar} from './DevSettingsSidebar';
import {DeveloperSettingsProvider} from './hooks/DeveloperSettingsProvider';

interface DevSettingsProps extends TestIdProps {
  width?: BoxProps['width'];
  height?: BoxProps['height'];
  iconSize?: UserSidebarIconSize;
}

export function DevSettings(props: DevSettingsProps) {
  return (
    <Box width={props.width} height={props.height}>
      <DeveloperSettingsProvider>
        <DevSettingsSidebar data-testid={props['data-testid']} iconSize={props.iconSize} />
      </DeveloperSettingsProvider>
    </Box>
  );
}
