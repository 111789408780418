import {Option} from 'platform/components';

import {VehicleTypeEnumObject} from '@dms/api/shared';
import {useGetTopMakesQuery} from '@dms/api/topMakes';
import {
  useGetCustomTenantCatalogueQuery,
  useGetMakeModelWithMakeQuery,
} from '@dms/api/vehicleCatalogue';
import i18n, {FALLBACK_LANGUAGE} from '@dms/i18n';

import {vehicleMakeParser} from '../utils/vehicleMakeParser';

interface UseVehicleMakeProps {
  vehicleType: VehicleTypeEnumObject;
  unknownMakes?: Option[];
}

export function useVehicleMake(props: UseVehicleMakeProps) {
  const vehicleMakesQuery = useGetMakeModelWithMakeQuery(
    {
      vehicleType: props.vehicleType,
      lang: [i18n.resolvedLanguage ?? FALLBACK_LANGUAGE],
    },
    {skip: !props.vehicleType}
  );

  const customTenantMakeModelsQuery = useGetCustomTenantCatalogueQuery();
  const topMakesQuery = useGetTopMakesQuery();

  const isLoading =
    vehicleMakesQuery.isLoading || customTenantMakeModelsQuery.isLoading || topMakesQuery.isLoading;

  const isError =
    vehicleMakesQuery.isError || customTenantMakeModelsQuery.isError || topMakesQuery.isError;

  const parsedData = vehicleMakeParser({
    vehicleType: props.vehicleType,
    unknownMakes: props.unknownMakes || [],
    vehicleMakeData: vehicleMakesQuery.data,
    customTenantMakeModelData: customTenantMakeModelsQuery.data,
    topMakesData: topMakesQuery.data,
  });

  return {
    ...parsedData,
    isLoading,
    isError,
  };
}
