import {unwrapResult} from '@reduxjs/toolkit';

import {useCallback} from 'react';
import {useDispatch as useReduxDispatch} from 'react-redux';

import {AppDispatch, Thunk} from './useThunkDispatch';

type ApiDispatch = <Returned, Params>(
  action: Thunk<Returned, Params>,
  params: Params,
  args?: {
    throwOff?: boolean;
    allowedStatusCodes?: number[];
  }
) => Promise<Returned>;

/**
 * @deprecated You should use RTK query
 */
export const useApiDispatch = (): ApiDispatch => {
  const dispatch = useReduxDispatch<AppDispatch>();
  //@ts-expect-error Unable to type "throw error"
  return useCallback(
    (action, params, args) =>
      dispatch(action(params))
        .then(unwrapResult)
        .catch((err: {response?: {status?: number}}) => {
          if (
            args?.throwOff ||
            (args?.allowedStatusCodes &&
              err?.response?.status &&
              args?.allowedStatusCodes.includes(err?.response?.status))
          ) {
            return null;
          }
          throw err;
        }),
    [dispatch]
  );
};
