import {Button, closeDialog, DataStatus, openDialog} from 'platform/components';
import {Heading, Hide, HStack, Show, Space, VStack} from 'platform/foundation';

import {isNil} from 'ramda';

import {useGetCustomerV2Query} from '@dms/api/customer';
import i18n from '@dms/i18n';
import {
  CustomerMatchOrCreate,
  getCustomerName,
  InfoCard,
  useAddress,
  useCustomerInformationAttributes,
} from '@dms/shared';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {SettingsSection} from '../SettingsSection/SettingsSection';

interface DefaultCustomerProps extends TestIdProps {
  customerId: string | Nullish;
  onCustomerChange: (customerId: string | null) => void;
}

export function DefaultCustomer(props: DefaultCustomerProps) {
  const {data, isLoading, isError} = useGetCustomerV2Query(
    {customerId: props.customerId ?? ''},
    {skip: isNil(props.customerId)}
  );
  const attributes = useCustomerInformationAttributes(data);
  const {composeAddress} = useAddress();

  const handleAssignCustomer = () =>
    openDialog(
      <CustomerMatchOrCreate
        onCustomer={(customer) => {
          closeDialog('customer-dialog');
          props.onCustomerChange(customer.id);
        }}
        secondStepComponentType="SERVICE_CASE"
        data-testid={suffixTestId('dialog', props)}
      />,
      {title: i18n.t('entity.customer.labels.customer'), id: 'customer-dialog'}
    );

  const handleRemoveCustomer = () => props.onCustomerChange(null);

  return (
    <VStack>
      <HStack justify="space-between">
        <Heading size={4}>{i18n.t('entity.customer.labels.defaultCustomer')}</Heading>
        <Show when={isNil(props.customerId)}>
          <Button
            title={i18n.t('general.actions.assignCustomer')}
            variant="link"
            leftIcon="social/person_add"
            onClick={handleAssignCustomer}
            data-testid={suffixTestId('add', props)}
          />
        </Show>
        <Hide when={isNil(props.customerId)}>
          <Button
            title={i18n.t('general.actions.remove')}
            variant="dangerLink"
            onClick={handleRemoveCustomer}
            data-testid={suffixTestId('remove', props)}
          />
        </Hide>
      </HStack>
      <Space vertical={4} />
      <SettingsSection>
        <DataStatus
          isEmpty={isNil(props.customerId)}
          isError={isError}
          isLoading={isLoading}
          emptyMessage={i18n.t('entity.serviceOrder.labels.noDefaultCustomer')}
        >
          {data && (
            <InfoCard
              title={getCustomerName(data)}
              description={[
                composeAddress(
                  data.foundingPerson?.permanentAddress?.address ||
                    data.businessInfo?.address?.address
                ),
              ]}
              attributes={attributes}
            />
          )}
        </DataStatus>
      </SettingsSection>
    </VStack>
  );
}
