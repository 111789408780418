import {Button, Card, closeDialog, openDialog, TabProps, TabsHeader} from 'platform/components';
import {Box, HStack, Show, VStack} from 'platform/foundation';

import {useRef, useState} from 'react';

import {isNotNilOrEmpty, isString} from 'ramda-adjunct';

import {GetWarehouseResponse} from '@dms/api/metadaWarehouse';
import {GetWarehouseAccountResponse} from '@dms/api/metadaWarehouseAccount';
import {
  PostReceiveNoteBasketItemRequest,
  usePostReceiveNoteBasketItemMutation,
} from '@dms/api/metadaWarehouseReceiveNoteBasket';
import {TenantResponseBody} from '@dms/api/shared';
import i18n from '@dms/i18n';
import {warehouseRoutes} from '@dms/routes';
import {
  ArticleCreate,
  ArticleCreateDefaultValues,
  getSingleOrUndefined,
  handleApiError,
} from '@dms/shared';

import {composePath, Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {Filters, refreshDatagrid} from 'features/datagrid';

import {RECEIVE_NOTE_ITEM_DIALOG} from '../constants/receiveNoteItemDialog';
import {ItemRowData} from '../types/ItemRowData';
import {ReceiveNoteItemDetail} from './ReceiveNoteItemDetail';
import {ReceiveNoteMaterialPriceListTab} from './ReceiveNoteMaterialPriceListTab';
import {ReceiveNoteMaterialWarehouseTab} from './ReceiveNoteMaterialWarehouseTab';

interface ReceiveNoteMaterialListProps extends RequiredTestIdProps {
  receiveNoteId: string;
  warehouse: GetWarehouseResponse | Nullish;
  warehouseAccount: GetWarehouseAccountResponse | Nullish;
  tenant: TenantResponseBody | Nullish;
  state: 'PENDING' | 'COMPLETED' | Nullish;
}

const TABS_IDS = {
  warehouseTab: 'receiveNote-addMaterial-warehouseTab',
  priceListTab: 'receiveNote-addMaterial-priceListTab',
};
const CREATE_ARTICLE_DIALOG = 'createArticleDialog';

export function ReceiveNoteMaterialList(props: ReceiveNoteMaterialListProps) {
  const [activeTabId, setActiveTabId] = useState(TABS_IDS.warehouseTab);
  const [activeFolderId, setActiveFolderId] = useState<string>();
  const filters = useRef<ArticleCreateDefaultValues>({});

  const [postReceiveNoteBasketItem, {isLoading: isAddingToBasket}] =
    usePostReceiveNoteBasketItemMutation();

  const handleCreateArticle = () => {
    openDialog(
      <ArticleCreate
        defaultValues={{
          activeFolderId,
          catalogNumber: filters.current?.catalogNumber,
          manufacturerId: filters.current?.manufacturerId,
          warehouseId: props.warehouse?.id,
          name: filters.current?.name,
        }}
        onArticleCreate={() => {
          closeDialog(CREATE_ARTICLE_DIALOG);
          refreshDatagrid('receive-note-basket-article');
        }}
        onAddToBasket={() => {}}
        hasAddToBasketButton
        onClose={() => closeDialog(CREATE_ARTICLE_DIALOG)}
        data-testid={suffixTestId('dialogs.createArticle', props)}
      />,
      {
        id: CREATE_ARTICLE_DIALOG,
        title: i18n.t('entity.warehouse.labels.createNewArticle'),
        withAdditionalFooter: true,
      }
    );
  };

  const handleOpenReceiveNoteItemDetail = (itemRowData: ItemRowData) => {
    openDialog(
      <ReceiveNoteItemDetail
        context="BASKET"
        receiveNoteId={props.receiveNoteId}
        itemRowData={itemRowData}
        warehouse={props.warehouse}
        warehouseAccount={props.warehouseAccount}
        tenant={props.tenant}
        state={props.state}
        onAddToBasket={handleAddToBasket}
        isAddingToBasket={isAddingToBasket}
        data-testid={suffixTestId('dialogs.addItem', props)}
      />,
      {
        id: RECEIVE_NOTE_ITEM_DIALOG,
        title: i18n.t('entity.warehouse.labels.addReceiveItem'),
        size: 'large',
        withAdditionalFooter: true,
        'data-testid': suffixTestId('dialogs.addReceiveItem', props),
      }
    );
  };

  const handleArticleDetail = (warehouseId: string, articleId: string) => {
    const path = composePath(warehouseRoutes.articleDetailOverview, {
      params: {warehouseId, id: articleId},
    });
    window.open(path, '_blank');
  };

  const handleAddToBasket = async (requestBody: PostReceiveNoteBasketItemRequest['body']) => {
    await postReceiveNoteBasketItem({body: requestBody})
      .unwrap()
      .then(() => closeDialog(RECEIVE_NOTE_ITEM_DIALOG))
      .catch(handleApiError);
  };

  const handleFiltersChange = (newFilters: Filters) => {
    const articleCreateDefaultValues: ArticleCreateDefaultValues = {};

    if (isString(newFilters.catalogNumber)) {
      articleCreateDefaultValues.catalogNumber = newFilters.catalogNumber;
    }

    if (isNotNilOrEmpty(newFilters.manufacturerId)) {
      articleCreateDefaultValues.manufacturerId = getSingleOrUndefined(newFilters.manufacturerId);
    }

    if (isString(newFilters.name)) {
      articleCreateDefaultValues.name = newFilters.name;
    }

    filters.current = articleCreateDefaultValues;
  };

  const tabs: TabProps[] = [
    {
      id: TABS_IDS.warehouseTab,
      title: i18n.t('entity.warehouse.labels.warehouse'),
      content: (
        <ReceiveNoteMaterialWarehouseTab
          onAddToBasket={handleOpenReceiveNoteItemDetail}
          onArticleDetail={handleArticleDetail}
          onActiveFolderIdChange={setActiveFolderId}
          onExternalFilterChange={handleFiltersChange}
          data-testid={suffixTestId('tabs.warehouse', props)}
        />
      ),
    },
    {
      id: TABS_IDS.priceListTab,
      title: i18n.t('entity.warehouse.labels.priceList'),
      content: (
        <ReceiveNoteMaterialPriceListTab
          warehouseId={props.warehouse?.id ?? ''}
          onAddToBasket={handleOpenReceiveNoteItemDetail}
          data-testid={suffixTestId('tabs.priceList', props)}
        />
      ),
    },
  ];

  const activeTabContent = tabs.find(
    (tab) => tab.id === (activeTabId ?? TABS_IDS.warehouseTab)
  )?.content;

  return (
    <Card isFullHeight>
      <VStack spacing={4} height="100%">
        <HStack justify="space-between">
          <TabsHeader
            variant="condensed"
            tabs={tabs}
            activeTabId={activeTabId}
            onChange={setActiveTabId}
            data-testid={suffixTestId('tabs', props)}
          />
          <Show when={activeTabId === TABS_IDS.warehouseTab}>
            <Button
              variant="secondary"
              leftIcon="content/add_circle"
              onClick={handleCreateArticle}
              title={i18n.t('entity.warehouse.labels.createNewArticle')}
              data-testid={suffixTestId('button.createNewArticle', props)}
            />
          </Show>
        </HStack>
        <Box height="100%" flex={1}>
          <Card key={activeTabId} isFullHeight>
            {activeTabContent}
          </Card>
        </Box>
      </VStack>
    </Card>
  );
}
