import {Button} from 'platform/components';

import {useGetDocument360RedirectUrlQuery} from '@dms/api/auth';
import i18n from '@dms/i18n';
import {omneticReleaseNotes} from '@dms/releaseNotes';

import {TestIdProps, suffixTestId} from 'shared';

const LATEST_RELEASE_NOTE = omneticReleaseNotes[0];

interface ReleaseItemProps extends TestIdProps {}

export function LatestReleaseLink(props: ReleaseItemProps) {
  const {data, isLoading} = useGetDocument360RedirectUrlQuery({
    redirectTo: LATEST_RELEASE_NOTE.url,
  });

  return (
    <Button
      to={data?.redirectUrl}
      target="_blank"
      textAlign="left"
      title={i18n.t('general.labels.releaseNotesTitle')}
      variant="ghost"
      isFullWidth
      leftIcon="action/grade"
      isLoading={isLoading}
      data-testid={suffixTestId('release-link', props)}
    />
  );
}
