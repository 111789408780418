import {Card, DataStatus, Separator} from 'platform/components';
import {Grid, GridItem, Heading, Box} from 'platform/foundation';
import {css} from 'styled-components';

import {FC, useCallback} from 'react';
import {useSelector} from 'react-redux';

import {isNil, isNotEmpty} from 'ramda';

import {useGetActiveBusinessCasesByVehicleQuery} from '@dms/api/businessCase';
import i18n from '@dms/i18n';
import {businessCaseRoutes, testIds} from '@dms/routes';
import {BusinessCaseSummary, Main} from '@dms/shared';
import {selectSalesVehicleData} from '@dms/teas';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject} from 'features/datagrid';

export const VehicleBusinessCases: FC = () => {
  const navigate = useNavigate();

  const vehicle = useSelector(selectSalesVehicleData);

  const {
    data: activeBusinessCases,
    isLoading,
    isError,
  } = useGetActiveBusinessCasesByVehicleQuery(
    {vehicleId: vehicle?.id ?? ''},
    {skip: isNil(vehicle)}
  );

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => ({
      ...filter,
      vehicle: [vehicle?.id as string],
    }),
    [vehicle?.id]
  );

  const actionCallback: ActionCallback = ({actionKey, rowId}) => {
    switch (actionKey) {
      case 'detail':
        if (typeof rowId === 'string') {
          navigate(
            composePath(businessCaseRoutes.overview, {
              params: {id: rowId},
              isSearchParamsPreserved: false,
            })
          );
        }
        break;
    }
  };

  return (
    <Main>
      <Grid columns={1}>
        <DataStatus isLoading={isLoading} isError={isError}>
          <GridItem>
            {isNotEmpty(activeBusinessCases) ? (
              <Box paddingTop={1}>
                <Heading size={3} alternative>
                  {i18n.t('page.vehicle.activeBusinessCases.title')}
                </Heading>
              </Box>
            ) : (
              <div
                css={css`
                  margin-top: 4px;
                  margin-bottom: -32px;
                `}
              >
                <Heading size={3} alternative>
                  {i18n.t('page.vehicle.labels.noActiveBusinessCases')}
                </Heading>
                <Separator />
              </div>
            )}
          </GridItem>
          {activeBusinessCases?.map((activeBusinessCase, index) => (
            <BusinessCaseSummary
              key={activeBusinessCase.id}
              activeBusinessCase={activeBusinessCase}
              variant="customer"
              data-testid={testIds.vehicles.businessCases(`businessCaseSummary-${index}`)}
            />
          ))}
        </DataStatus>

        <Box paddingTop={3}>
          <Heading size={3} alternative>
            {i18n.t('page.vehicle.previousBusinessCases.title')}
          </Heading>
        </Box>
        <Card variant="inlineWhite">
          <Box height={100}>
            <DataGrid
              gridCode="vehicle-business-case-history"
              actionCallback={actionCallback}
              queryModifier={queryModifier}
              data-testid={testIds.vehicles.businessCases('businessCaseHistory')}
            />
          </Box>
        </Card>
      </Grid>
    </Main>
  );
};
