import {match} from 'ts-pattern';

import {always, isEmpty} from 'ramda';

/**
 * Convert false values to undefined in the filter values
 * @param filterValues - The filter values to process
 * @returns The processed filter values
 */
export const processFilterValues = (
  filterValues: Record<string, unknown>
): Record<string, unknown> =>
  Object.entries(filterValues).reduce(
    (acc, [key, value]) => {
      acc[key] = match(value)
        .with(false, always(undefined))
        .when(isEmpty, always(null))
        .otherwise(always(value));

      return acc;
    },
    {} as Record<string, unknown>
  );
