import {Card, FormControl, showNotification} from 'platform/components';
import {Box} from 'platform/foundation';
import {match} from 'ts-pattern';
import {isArray} from 'util';

import {useCallback} from 'react';

import {head, isNil, mergeAll, path} from 'ramda';

import {
  BaseArticle,
  usePatchArticleDefaultSupplierMutation,
  usePatchArticleInfoMutation,
} from '@dms/api/metadaWarehouseArticle';
import i18n from '@dms/i18n';
import {catchUnhandledDataGridActions, logError} from '@dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject} from 'features/datagrid';

import {useWarehouseParams} from '../../../../../hooks/useWarehouseParams';
import {CatalogFilter} from '../../../../../types/CatalogFilter';

interface OverviewCatalogsInformationTabProps extends TestIdProps {
  control: FormControl<BaseArticle>;
  catalogFilter: CatalogFilter;
}

export function CatalogsInformationTab(props: OverviewCatalogsInformationTabProps) {
  const {articleId} = useWarehouseParams();

  const [patchArticleInfo] = usePatchArticleInfoMutation();
  const [setDefaultSupplier] = usePatchArticleDefaultSupplierMutation();

  const actionCallback: ActionCallback = ({actionKey, rowId, rowData, refreshData}) => {
    const id = isArray(rowId) ? head(rowId) : rowId;
    const name = path(['name', 'value'], rowData) as string;
    const supplierId = path(['supplierId', 'value'], rowData) as string;

    if (isNil(id)) {
      return logError('Row id is not defined');
    }

    if (isNil(name)) {
      return logError('Name is not defined');
    }

    if (isNil(supplierId)) {
      return logError('Supplier id is not defined');
    }

    match(actionKey)
      .with('updateArticle', () => {
        patchArticleInfo({articleId, body: {name}})
          .unwrap()
          .then(() =>
            showNotification.success(i18n.t('entity.warehouse.notifications.articleInfoUpdated'))
          )
          .then(refreshData);
      })
      .with('setAsDefault', () => {
        setDefaultSupplier({articleId, body: {supplierId}})
          .unwrap()
          .then(() =>
            showNotification.success(i18n.t('entity.warehouse.notifications.defaultSupplierSet'))
          )
          .then(refreshData);
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  const queryModifier = useCallback(
    (filter: QueryFilterObject) =>
      mergeAll([
        filter,
        {
          branchId: props.catalogFilter.branchId,
          supplierId: props.catalogFilter.supplierId,
          manufacturerId: props.catalogFilter.manufacturerId,
          oemCode: props.catalogFilter.manufacturerNumber,
        },
      ]),
    [
      props.catalogFilter.branchId,
      props.catalogFilter.supplierId,
      props.catalogFilter.manufacturerId,
      props.catalogFilter.manufacturerNumber,
    ]
  );

  return (
    <Card isFullHeight>
      <Box height="100%">
        <DataGrid
          gridCode="warehouse-article-catalogs-info"
          actionCallback={actionCallback}
          queryModifier={queryModifier}
          data-testid={suffixTestId('datagrid', props)}
        />
      </Box>
    </Card>
  );
}
