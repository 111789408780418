import {isNil} from 'ramda';

import {GetWarehouseHandlingUnitsResponse} from '@dms/api/metadaWarehouseHandlingUnit';

import {Nullish} from 'shared';

export const getHandlingUnitLabel = (
  handlingUnitId: string | undefined,
  handlingUnits: GetWarehouseHandlingUnitsResponse | Nullish
): string | null => {
  if (isNil(handlingUnits)) {
    return null;
  }

  const selectedHandlingUnit = handlingUnits.find(
    (handlingUnit) => handlingUnit.id === handlingUnitId
  );
  if (isNil(selectedHandlingUnit)) {
    return null;
  }

  return selectedHandlingUnit?.name;
};
