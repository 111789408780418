import {Button, Flag} from 'platform/components';
import {Box, HStack, Show, useWindowDimensions} from 'platform/foundation';

import {useRef, useState} from 'react';

import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps, useToggle} from 'shared';

import {HighlightItemType} from './types/HighlightItemType';
import {getFeaturesVisible} from './utils/getFeaturesVisible';

const SHOWN_ROWS = 2;
const SPACING = 8;
const EXPAND_BUTTON_WIDTH = 44;

interface VehicleHighlightsProps extends TestIdProps {
  data: HighlightItemType[];
}

export function VehicleHighlights(props: VehicleHighlightsProps) {
  const [isExpanded, toggleExpanded] = useToggle(false);
  const [highlightsVisibleCount, setFeaturesVisibleCount] = useState(props.data.length);
  const lastVisibleElementRef = useRef<HTMLDivElement>(null);
  const lastVisibleElementIndexRef = useRef<number>(null);
  const {width: windowWidth} = useWindowDimensions();

  const calculateVisibleElement = (ref: HTMLDivElement | null, index: number) => {
    if (ref) {
      requestAnimationFrame(() => {
        const rect = ref.getBoundingClientRect();
        const parentRect = ref.parentElement?.getBoundingClientRect();

        const row = Math.floor((rect.top - (parentRect?.top ?? 0)) / rect.height) + 1;
        const isRowLastVisible = row === SHOWN_ROWS;
        const isRowFirstNonVisible = row === SHOWN_ROWS + 1;
        const isElementLastAndStillVisible = index === props.data.length - 1 && row <= SHOWN_ROWS;

        if (isRowLastVisible) {
          lastVisibleElementRef.current = ref;
          lastVisibleElementIndexRef.current = index;
        }

        if (isRowFirstNonVisible) {
          const lastIndex = lastVisibleElementIndexRef.current ?? 0;
          const lastRect = lastVisibleElementRef.current?.getBoundingClientRect();

          setFeaturesVisibleCount(
            getFeaturesVisible(EXPAND_BUTTON_WIDTH + SPACING, lastIndex, lastRect, parentRect)
          );
        }

        if (isElementLastAndStillVisible) {
          setFeaturesVisibleCount(
            isRowLastVisible
              ? getFeaturesVisible(EXPAND_BUTTON_WIDTH + SPACING, index, rect, parentRect)
              : index + 1
          );
        }
      });
    }
  };

  const visibleHighlights = isExpanded ? props.data : props.data.slice(0, highlightsVisibleCount);
  const hiddenItemsCount = props.data.length - highlightsVisibleCount;
  const hasMoreItems = hiddenItemsCount > 0;

  return (
    <Box>
      {/* Hidden container for calculating item positioning */}
      <Box height={0} overflow="hidden" key={`calculator-${windowWidth}`}>
        <HStack spacing={1} wrap>
          {props.data.map((item, index) => (
            <div key={item.key} ref={(ref) => calculateVisibleElement(ref, index)}>
              <Flag label={item.label} isSubtle />
            </div>
          ))}
        </HStack>
      </Box>

      {/* Visible highlights */}
      <HStack spacing={1} wrap>
        {visibleHighlights.map((item, index) => (
          <Flag
            key={item.key}
            label={item.label}
            isSubtle
            colorScheme="blue"
            data-testid={suffixTestId(`feature[${index}]`, props)}
          />
        ))}

        <Show when={!isExpanded && hasMoreItems}>
          <Button
            title={`${hiddenItemsCount} +`}
            size="small"
            onClick={toggleExpanded}
            data-testid={suffixTestId('expandButton', props)}
          />
        </Show>

        <Show when={isExpanded && hasMoreItems}>
          <Button
            title={i18n.t('general.actions.hide')}
            size="small"
            onClick={toggleExpanded}
            data-testid={suffixTestId('collapseButton', props)}
          />
        </Show>
      </HStack>
    </Box>
  );
}
