import {ColorSchemeType, Flag, Tooltip} from 'platform/components';
import {Show} from 'platform/foundation';
import {match} from 'ts-pattern';

import {FC} from 'react';

import {always} from 'ramda';

import {AdvertisementStatusEnum} from '@dms/api/sales';
import i18n from '@dms/i18n';

type TStatusItem = {
  color: ColorSchemeType;
  text: string;
};

type StatusItemProps = {
  currentStatus: AdvertisementStatusEnum | `${AdvertisementStatusEnum}`;
  statusDescription: string | null;
  platformCode: string;
};

export const StatusItem: FC<StatusItemProps> = ({
  currentStatus,
  statusDescription,
  platformCode,
}) => {
  const statusItemValue = match(currentStatus)
    .with(
      AdvertisementStatusEnum.NOT_PUBLISHED,
      AdvertisementStatusEnum.UNKNOWN,
      always<TStatusItem>({
        color: 'neutral',
        text: i18n.t('page.advertisement.actions.notPublished'),
      })
    )
    .with(
      AdvertisementStatusEnum.PUBLISHING,
      always<TStatusItem>({
        color: 'blue',
        text: `${i18n.t('page.advertisement.actions.publishing')}...`,
      })
    )
    .with(
      AdvertisementStatusEnum.PUBLISHED,
      always<TStatusItem>({
        color: 'green',
        text:
          platformCode === 'tipcars'
            ? i18n.t('page.advertisement.actions.transferred')
            : i18n.t('page.advertisement.actions.published'),
      })
    )
    .with(
      AdvertisementStatusEnum.UPDATING,
      always<TStatusItem>({
        color: 'blue',
        text: `${i18n.t('page.advertisement.actions.updating')}...`,
      })
    )
    .with(
      AdvertisementStatusEnum.UNPUBLISHING,
      always<TStatusItem>({
        color: 'blue',
        text: `${i18n.t('page.advertisement.actions.unPublishing')}...`,
      })
    )
    .with(
      AdvertisementStatusEnum.PUBLISH_FAILED,
      AdvertisementStatusEnum.UNPUBLISH_FAILED,
      AdvertisementStatusEnum.UPDATE_FAILED,
      always<TStatusItem>({
        color: 'red',
        text: i18n.t('general.actions.error'),
      })
    )
    .otherwise(always(null));

  return (
    <Tooltip placement="top" label={statusDescription}>
      <Show when={statusItemValue}>
        <Flag
          colorScheme={statusItemValue?.color}
          data-testid={`statusText-advertisement.platforms.${platformCode}`}
          label={statusItemValue?.text ?? '-'}
          isSubtle
        />
      </Show>
    </Tooltip>
  );
};
