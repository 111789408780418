import {Form, FormButton, FormField, FormSubmitHandler} from 'platform/components';
import {Grid, HStack, Link, Show, Text, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';
import {object, string} from 'yup';

import {isNotNil} from 'ramda-adjunct';

import {
  CommonDescriptionAttribute,
  CommonTitleExtensionAttribute,
  VehicleWindshieldAdvertisingSettingsResponseBody,
} from '@dms/api/sales';
import i18n from '@dms/i18n';

import {parseDate, DOT_CHARACTER, suffixTestId, TestIdProps} from 'shared';

import {getAttributeByCode} from '../utils/getAttributeByCode';

export interface WindshieldSettingsFormValues {
  title: string | null;
  description: string | null;
}

interface WindshieldSettingsFormProps extends TestIdProps {
  vehicleId: string;
  descriptionLabel: string;
  data: VehicleWindshieldAdvertisingSettingsResponseBody | undefined;
  onSubmit: FormSubmitHandler<WindshieldSettingsFormValues>;
  onHandleEdit: () => void;
  isDisabled: boolean;
}

export function WindshieldSettingsForm(props: WindshieldSettingsFormProps) {
  const formatDateTime = useDateTimeFormatter();

  const defaultValues: WindshieldSettingsFormValues = {
    description:
      getAttributeByCode<CommonTitleExtensionAttribute>('description', props.data?.attributes)
        ?.value ?? null,
    title:
      getAttributeByCode<CommonDescriptionAttribute>('title_extension', props.data?.attributes)
        ?.value ?? null,
  };

  return (
    <Form<WindshieldSettingsFormValues>
      defaultValues={defaultValues}
      schema={schema}
      onSubmit={props.onSubmit}
      shouldWatchForUnsavedChanges
    >
      {(control) => (
        <VStack spacing={4}>
          <FormField
            control={control}
            label={props.descriptionLabel}
            placeholder={i18n.t('page.advertisement.labels.descriptionPlaceholder')}
            name="description"
            type="textarea"
            isDisabled={props.isDisabled}
            minRows={2}
            maxRows={15}
            data-testid={suffixTestId('description', props)}
          />
          <Grid columns={2}>
            <FormField
              control={control}
              label={i18n.t('page.advertisement.labels.titleExtension')}
              name="title"
              type="text"
              isDisabled={props.isDisabled}
              tooltip={i18n.t('page.advertisement.labels.titleExtensionTooltip')}
              maxLength={30}
              isCounterVisible
              data-testid={suffixTestId('title', props)}
            />
          </Grid>
          {props.data?.updatedAt && (
            <Show when={isNotNil(props.data?.updatedBy)}>
              <Text size="xSmall" color="secondary">
                {`${i18n.t(`page.advertisement.labels.lastUpdateBy`)} ${
                  props.data?.updatedBy
                } ${DOT_CHARACTER} ${formatDateTime(
                  'dateTimeShort',
                  parseDate(props.data?.updatedAt)
                )}`}
              </Text>
            </Show>
          )}
          <HStack justify="space-between">
            <Link
              leftIcon="image/edit"
              title={i18n.t('page.advertisement.actions.editParameters')}
              size="small"
              onClick={props.onHandleEdit}
              data-testid="button-advertisement.listingAttributes.edit"
            />
            <Show when={!props.isDisabled}>
              <FormButton
                type="submit"
                control={control}
                isDisabled={props.isDisabled}
                title={i18n.t('general.actions.confirm')}
                data-testid={props['data-testid']}
              />
            </Show>
          </HStack>
        </VStack>
      )}
    </Form>
  );
}

const schema = object().shape({title: string().nullable(), description: string().nullable()});
