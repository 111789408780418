import {metadaApi} from '@dms/api/core';

import {
  HeaderDynamicActionsEventRequest,
  HeaderDynamicActionsEventRequestSchema,
  HeaderDynamicActionsEventResponse,
  HeaderDynamicActionsEventResponseSchema,
  HeaderDynamicActionsRequest,
  HeaderDynamicActionsRequestSchema,
  HeaderDynamicActionsResponse,
  HeaderDynamicActionsResponseSchema,
} from './types';

export const metadaWarehouseHeaderDynamicActionsApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getWarehouseHeaderDynamicActions: build.query<
      HeaderDynamicActionsResponse,
      HeaderDynamicActionsRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/${queryArg.resource}/${queryArg.id}/actions`,
        method: 'GET',
      }),
      providesTags: ['headerDynamicActions'],
      extraOptions: {
        requestSchema: HeaderDynamicActionsRequestSchema,
        responseSchema: HeaderDynamicActionsResponseSchema,
      },
    }),
    putWarehouseHeaderDynamicActions: build.mutation<
      HeaderDynamicActionsEventResponse,
      HeaderDynamicActionsEventRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/${queryArg.resource}/${queryArg.id}/action`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: [
        'deliveryNote',
        'receiveNote',
        'receiveNoteCorrections',
        'directSales',
        'serviceOrderIssueNotes',
        'serviceOrderReturns',
        'partsRequestReturnBasket',
        'headerDynamicActions',
        'supplierOrders',
      ],
      extraOptions: {
        requestSchema: HeaderDynamicActionsEventRequestSchema,
        responseSchema: HeaderDynamicActionsEventResponseSchema,
      },
    }),
  }),
});

export const {
  useGetWarehouseHeaderDynamicActionsQuery,
  usePutWarehouseHeaderDynamicActionsMutation,
} = metadaWarehouseHeaderDynamicActionsApi;
