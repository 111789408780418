import {Alert, Button, ButtonGroup, Form, FormButton, FormSubmitHandler} from 'platform/components';
import {HStack, Space, VStack} from 'platform/foundation';

import {useSelector} from 'react-redux';

import {defaultTo, not} from 'ramda';
import {isNilOrEmpty, isTrue} from 'ramda-adjunct';

import {selectActiveBranchId} from '@dms/api/features';
import {useGetEmployeeMechanicsQuery} from '@dms/api/metadaEmployee';
import {useGetEmployeeCostCentersListQuery} from '@dms/api/metadaEmployeeCostCenter';
import {BaseDirectSaleLabourItem} from '@dms/api/metadaWarehouseDirectSale';
import {
  BulkPatchDirectSaleLabourBasketItemRequest,
  useBulkPatchDirectSaleLabourBasketItemMutation,
} from '@dms/api/metadaWarehouseDirectSaleLabourBasket';
import {useGetDictionaryWorktypesQuery} from '@dms/api/metadaWorkshopWorkType';
import {useGetBranchQuery} from '@dms/api/tenant';
import i18n from '@dms/i18n';
import {getActiveOptions, getDecimalFromPercentage, handleApiError, useTenant} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {getOptionsFromMechanics} from '../../../../utils/getOptionsFromMechanics';
import {RequestTabLabourBulkEditItemCostCenter} from './RequestTabLabourBulkEditItemCostCenter';
import {RequestTabLabourBulkEditItemDiscount} from './RequestTabLabourBulkEditItemDiscount';
import {RequestTabLabourBulkEditItemMechanic} from './RequestTabLabourBulkEditItemMechanic';
import {RequestTabLabourBulkEditItemPurchasePrice} from './RequestTabLabourBulkEditItemPurchasePrice';
import {RequestTabLabourBulkEditItemSellingPrice} from './RequestTabLabourBulkEditItemSellingPrice';
import {RequestTabLabourBulkEditItemVat} from './RequestTabLabourBulkEditItemVat';
import {RequestTabLabourBulkEditItemWorkType} from './RequestTabLabourBulkEditItemWorkType';
import {RequestTabLabourBulkEditItemForm} from './types/RequestTabLabourBulkEditItemForm';

export interface RequestTabLabourBulkEditItemProps extends RequiredTestIdProps {
  itemsToEdit: BaseDirectSaleLabourItem[];
  directSaleId: string;
  authorizationProfileId: string;
  onClose: VoidFunction;
}

export function RequestTabLabourBulkEditItem(props: RequestTabLabourBulkEditItemProps) {
  const branchId = useSelector(selectActiveBranchId);

  const {data: branch} = useGetBranchQuery({branchId});

  const {tenantCurrency} = useTenant();

  const {workTypeOptions, isLoading: areWorkTypesLoading} = useGetDictionaryWorktypesQuery(
    undefined,
    {
      selectFromResult: (result) => ({
        workTypeOptions:
          result.data?.map((workType) => ({
            label: workType?.name ?? '',
            value: workType?.id ?? '',
          })) ?? [],
        isLoading: result.isLoading,
      }),
    }
  );

  const {mechanicOptions, areMechanicOptionsLoading} = useGetEmployeeMechanicsQuery(
    {authorizationProfileId: props.authorizationProfileId},
    {
      selectFromResult: (result) => ({
        mechanicOptions: getOptionsFromMechanics(result.data),
        areMechanicOptionsLoading: result.isLoading,
      }),
      skip: isNilOrEmpty(props.authorizationProfileId),
    }
  );

  const {costCenterOptions, areCostCenterOptionsLoading} = useGetEmployeeCostCentersListQuery(
    {company: branch?.billingInformation?.id as string, branch: branch?.id as string},
    {
      selectFromResult: (result) => ({
        costCenterOptions: getActiveOptions(result.data?.costCenters),
        areCostCenterOptionsLoading: result.isLoading,
      }),
      skip: isNilOrEmpty(branch?.billingInformation?.id) || isNilOrEmpty(branch?.id),
    }
  );

  const [patchDirectSaleLabourBasketItems, {isLoading: isPatchingItems}] =
    useBulkPatchDirectSaleLabourBasketItemMutation();

  const handleSubmit: FormSubmitHandler<RequestTabLabourBulkEditItemForm> = async (formValues) => {
    const requestBody: BulkPatchDirectSaleLabourBasketItemRequest['body'] = {
      itemId: props.itemsToEdit.map((item) => item.id),
      itemSettings: {
        isMechanic: formValues.isMechanicEnabled,
        mechanicId: formValues.isMechanicEnabled ? formValues.mechanicId : null,
        isCostCenter: formValues.isCostCenterEnabled,
        costCenterId: formValues.isCostCenterEnabled ? formValues.costCenterId : null,
        isDiscount: formValues.isDiscountEnabled,
        discountRate: formValues.isDiscountEnabled
          ? defaultTo(null, getDecimalFromPercentage(formValues.discountRate))
          : null,
        isVatType: formValues.isVatTypeEnabled,
        vatType: formValues.isVatTypeEnabled ? formValues.vatType : null,
        isWorkType: formValues.isWorkTypeEnabled,
        workType: formValues.isWorkTypeEnabled ? formValues.workType : null,
        isPurchasePricePerUnit: formValues.isPurchasePricePerUnitEnabled,
        purchasePricePerUnit: formValues.isPurchasePricePerUnitEnabled
          ? formValues.purchasePricePerUnit
          : null,
        isSellingPricePerUnit: formValues.isSellingPricePerUnitEnabled,
        sellingPricePerUnit: formValues.isSellingPricePerUnitEnabled
          ? formValues.sellingPricePerUnit
          : null,
      },
    };

    await patchDirectSaleLabourBasketItems({
      directSaleId: props.directSaleId,
      body: requestBody,
    })
      .unwrap()
      .then(props.onClose)
      .catch(handleApiError);
  };

  const defaultValues: Partial<RequestTabLabourBulkEditItemForm> = {
    isMechanicEnabled: false,
    isCostCenterEnabled: false,
    isDiscountEnabled: false,
    isVatTypeEnabled: false,
    isWorkTypeEnabled: false,
    isPurchasePricePerUnitEnabled: false,
    isSellingPricePerUnitEnabled: false,
  };

  return (
    <Form<RequestTabLabourBulkEditItemForm> defaultValues={defaultValues} onSubmit={handleSubmit}>
      {(control, formApi) => {
        const dependentFieldsForEditing = formApi.watch([
          'isMechanicEnabled',
          'isCostCenterEnabled',
          'isDiscountEnabled',
          'isVatTypeEnabled',
          'isWorkTypeEnabled',
          'isPurchasePricePerUnitEnabled',
          'isSellingPricePerUnitEnabled',
        ]);

        const atLeastOneDependentFieldForEditingEnabled = dependentFieldsForEditing.some((field) =>
          isTrue(field)
        );

        return (
          <VStack spacing={4} data-testid={props['data-testid']}>
            <HStack spacing={4}>
              <RequestTabLabourBulkEditItemMechanic
                control={control}
                formApi={formApi}
                mechanicOptions={mechanicOptions}
                areMechanicOptionsLoading={areMechanicOptionsLoading}
                data-testid={suffixTestId('mechanic', props)}
              />
              <RequestTabLabourBulkEditItemCostCenter
                control={control}
                formApi={formApi}
                costCenterOptions={costCenterOptions}
                areCostCenterOptionsLoading={areCostCenterOptionsLoading}
                data-testid={suffixTestId('costCenter', props)}
              />
            </HStack>

            <HStack spacing={4}>
              <RequestTabLabourBulkEditItemDiscount
                control={control}
                formApi={formApi}
                data-testid={suffixTestId('discount', props)}
              />
              <RequestTabLabourBulkEditItemVat
                control={control}
                formApi={formApi}
                data-testid={suffixTestId('vat', props)}
              />
            </HStack>

            <Alert
              type="inline"
              variant="info"
              title={i18n.t('entity.warehouse.labels.labourBulkEditItemTimeNormAlert')}
              data-testid={suffixTestId('timeNormAlert', props)}
            />

            <HStack spacing={4}>
              <RequestTabLabourBulkEditItemWorkType
                control={control}
                formApi={formApi}
                workTypeOptions={workTypeOptions}
                areWorkTypesLoading={areWorkTypesLoading}
                data-testid={suffixTestId('workType', props)}
              />
              <Space fillAvailable />
            </HStack>

            <HStack spacing={4}>
              <RequestTabLabourBulkEditItemPurchasePrice
                control={control}
                formApi={formApi}
                currency={tenantCurrency}
                data-testid={suffixTestId('purchasePrice', props)}
              />
              <RequestTabLabourBulkEditItemSellingPrice
                control={control}
                formApi={formApi}
                currency={tenantCurrency}
                data-testid={suffixTestId('sellingPrice', props)}
              />
            </HStack>

            <ButtonGroup align="right">
              <Button
                type="button"
                variant="secondary"
                title={i18n.t('general.actions.discard')}
                onClick={props.onClose}
                data-testid={suffixTestId('actions.discard', props)}
              />
              <FormButton
                isDisabled={not(atLeastOneDependentFieldForEditingEnabled)}
                control={control}
                type="submit"
                title={i18n.t('general.actions.apply')}
                isLoading={isPatchingItems}
                data-testid={suffixTestId('actions.submit', props)}
              />
            </ButtonGroup>
          </VStack>
        );
      }}
    </Form>
  );
}
