import {Box, HStack, Show} from 'platform/foundation';
import {useTheme} from 'styled-components';

import {MenuItemProps, UserSidebarIconSize} from '@dms/api/shared';
import {DevSettings} from '@dms/dev-settings';
import {environment} from '@dms/environment';
import {featureFlags} from '@dms/feature-flags';
import {usePermissions} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {TOP_BAR_ITEM_SIZE} from '../constants/topBarItemSize';
import {useIsMobileNavigation} from '../hooks/useIsMobileNavigation';
import {BranchSelect} from './BranchSelect';
import {HeaderContent} from './HeaderContent';
import {HideOnMobileNavigation} from './HideOnMobileNavigation';
import {Notifications} from './Notifications';
import {ReleasePopup} from './ReleasePopup';
import {Settings} from './Settings';
import {TaskCenter} from './TaskCenter';
import {UserAvatar} from './UserAvatar';

interface TopBarProps extends RequiredTestIdProps {
  iconSize: UserSidebarIconSize;
  activeModule?: MenuItemProps;
  settingsModule?: MenuItemProps;
}

export function TopBar(props: TopBarProps) {
  const {colors} = useTheme();

  const isMobileNavigation = useIsMobileNavigation();

  const [hasReadSettingsPermission] = usePermissions({permissionKeys: ['readSettings']});
  const [canReadTasks] = usePermissions({
    permissionKeys: ['readTask'],
  });

  return (
    <>
      <meta name="theme-color" content={colors.palettes.neutral[900][100]} />
      <Box
        zIndex="HEADER"
        paddingLeft={[0, 0, 4]}
        height="100%"
        width="100%"
        overflow="hidden"
        backgroundColor={isMobileNavigation ? 'palettes.neutral.900.100' : 'general.white'}
        borderColor={isMobileNavigation ? undefined : 'general.separator'}
        borderBottom={isMobileNavigation ? undefined : '1px solid'}
      >
        <HStack width="100%" align="center" justify="space-between" height="100%">
          <HeaderContent
            title={props.activeModule?.layoutProps?.title}
            data-testid={suffixTestId('header-content', props)}
          />
          <HStack shrink={0} align="center" justify="flex-end">
            <HideOnMobileNavigation>
              <BranchSelect />
            </HideOnMobileNavigation>
            <Show whenFeatureEnabled={featureFlags.CORE_TASK_MANAGEMENT} when={canReadTasks}>
              <TaskCenter iconSize={props.iconSize} />
            </Show>
            <Show whenFeatureEnabled={featureFlags.NOTIFICATION_CENTER}>
              <Notifications iconSize={props.iconSize} data-testid="layout-header" />
            </Show>
            <HideOnMobileNavigation>
              <ReleasePopup data-testid="layout-header" iconSize={props.iconSize} />
              <Show when={hasReadSettingsPermission}>
                <Settings
                  settingsModule={props.settingsModule}
                  iconSize={props.iconSize}
                  data-testid="layout-header"
                />
              </Show>
              <Show when={environment.ENV_TYPE === 'dev'}>
                <DevSettings
                  width={TOP_BAR_ITEM_SIZE}
                  height={TOP_BAR_ITEM_SIZE}
                  iconSize={props.iconSize}
                  data-testid="layout-header"
                />
              </Show>
            </HideOnMobileNavigation>
            <UserAvatar data-testid="layout-header" />
          </HStack>
        </HStack>
      </Box>
    </>
  );
}
