import {GetSchemaApiResponse, GetSchemaApiArg} from 'platform/components';

import {omneticApi} from '@dms/api/core';

export const modelSchemaApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getSchema: build.query<GetSchemaApiResponse, GetSchemaApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/model-schema/${queryArg.id}`,
      }),
    }),
  }),
});

export const {useGetSchemaQuery} = modelSchemaApi;
