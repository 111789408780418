import {DataStatus, Separator} from 'platform/components';
import {match} from 'ts-pattern';

import {find} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetServiceCaseOrderVariantsQuery} from '@dms/api/metadaWorkshopServiceCase';
import {
  useGetServiceOrderContractQuery,
  useGetServiceOrderInsuranceQuery,
  useGetServiceOrderQuery,
} from '@dms/api/metadaWorkshopServiceOrder';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {CustomerContractSection} from './CustomerContractSection';
import {DiscountSection} from './DiscountSection';

interface DiscountsProps extends RequiredTestIdProps {
  serviceCaseId: string;
  serviceOrderId: string;
  isReadOnly?: boolean;
}

export function Discounts(props: DiscountsProps) {
  const {
    data: serviceOrder,
    isError: isServiceOrderError,
    isLoading: isServiceOrderLoading,
  } = useGetServiceOrderQuery({
    serviceCaseId: props.serviceCaseId,
    serviceOrderId: props.serviceOrderId,
  });
  const {
    data: orderVariants,
    isLoading: isOrderVariantsLoading,
    isError: isOrderVariantsError,
  } = useGetServiceCaseOrderVariantsQuery({
    serviceCaseId: props.serviceCaseId,
  });

  const type = find(
    (variant) => serviceOrder?.serviceOrderVariantId === variant?.id,
    orderVariants ?? []
  )?.type;

  const {
    data: serviceOrderInsurance,
    isFetching: isServiceOrderInsuranceFetching,
    isError: isServiceOrderInsuranceError,
  } = useGetServiceOrderInsuranceQuery(
    {
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.serviceOrderId,
    },
    {skip: type !== 'INS'}
  );
  const {
    data: serviceOrderContract,
    isFetching: isServiceOrderContractFetching,
    isError: isServiceOrderContractError,
  } = useGetServiceOrderContractQuery(
    {
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.serviceOrderId,
    },
    {skip: type !== 'CON'}
  );

  const isAssignCustomerContractDisabled = match(type)
    .with('CON', () => isNilOrEmpty(serviceOrderContract?.provider))
    .with('INS', () => isNilOrEmpty(serviceOrderInsurance?.insuranceCompany))
    .otherwise(() => false);

  const isLoading =
    isServiceOrderLoading ||
    isOrderVariantsLoading ||
    isServiceOrderInsuranceFetching ||
    isServiceOrderContractFetching;
  const isError =
    isServiceOrderError ||
    isOrderVariantsError ||
    isServiceOrderInsuranceError ||
    isServiceOrderContractError;

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <CustomerContractSection
        orderId={props.serviceOrderId}
        serviceCaseId={props.serviceCaseId}
        isReadOnly={props.isReadOnly}
        isAssignDisabled={isAssignCustomerContractDisabled}
        data-testid={suffixTestId('customerContract', props)}
      />
      <Separator />
      <DiscountSection
        serviceCaseId={props.serviceCaseId}
        serviceOrderId={props.serviceOrderId}
        isReadOnly={props.isReadOnly}
        data-testid={suffixTestId('discounts', props)}
      />
    </DataStatus>
  );
}
