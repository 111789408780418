import {format} from 'date-fns';
import {Box, Right, Text} from 'platform/foundation';

import {parseDate, RequiredTestIdProps, suffixTestId} from 'shared';

import {SLOT_HEIGHT} from '../constants/slotHeight';

interface TimeSlotProps extends RequiredTestIdProps {
  hour: number;
}

export function TimeSlot(props: TimeSlotProps) {
  const time = parseDate(new Date().setHours(props.hour, 0));

  return (
    <Box padding={2} height={SLOT_HEIGHT} borderBottom="1px solid" borderColor="general.separator">
      <Right align="flex-start">
        <Text data-testid={suffixTestId('timeSlot', props)} size="xSmall" color="secondary">
          {format(time, 'H:mm')}
        </Text>
      </Right>
    </Box>
  );
}
