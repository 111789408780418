import {metadaApi} from '@dms/api/core';

import {
  GetIssueNoteRequest,
  GetIssueNoteRequestSchema,
  GetIssueNoteResponse,
  GetIssueNoteResponseSchema,
  PostIssueNoteRequest,
  PostIssueNoteRequestSchema,
  PostIssueNoteResponse,
  PostIssueNoteResponseSchema,
} from './types';

export const metadaWarehouseIssueNoteApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getIssueNote: build.query<GetIssueNoteResponse, GetIssueNoteRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/issue-note/${queryArg.issueNoteId}`,
        method: 'GET',
        queryArg,
      }),
      providesTags: ['issueNotes'],
      extraOptions: {
        requestSchema: GetIssueNoteRequestSchema,
        responseSchema: GetIssueNoteResponseSchema,
      },
    }),
    postIssueNotes: build.mutation<PostIssueNoteResponse, PostIssueNoteRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/issue-notes`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'directSales', id: queryArg.directSaleId},
      ],
      extraOptions: {
        requestSchema: PostIssueNoteRequestSchema,
        responseSchema: PostIssueNoteResponseSchema,
      },
    }),
  }),
});

export const {usePostIssueNotesMutation, useGetIssueNoteQuery} = metadaWarehouseIssueNoteApi;
