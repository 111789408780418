import {isDate} from 'date-fns';

import {isNil} from 'ramda';
import {isArray, isPlainObj} from 'ramda-adjunct';

import {getApiDateString} from 'shared';

/**
 * @description
 * Returns value for DataGrid filters form DynamicUI component
 *
 * @example
 * // result {obj: {movementAt: {from: '2023-05-10', to: '2023-05-03'}}}
 * dynamicUiReturnValuesToFormFilterValues({obj: {movementAt: ['2023-05-10', '2023-05-03']}})
 */
export const dynamicUiReturnValuesToFormFilterValues = (
  obj: Record<string, any>
): Record<string, any> =>
  Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];

    if (isArray(value) && value.length === 2) {
      if (isDate(value[0]) && isDate(value[1])) {
        return {...acc, [key]: {from: getApiDateString(value[0]), to: getApiDateString(value[1])}};
      }

      return {...acc, [key]: value};
    }

    if (isDate(value)) {
      return {...acc, [key]: getApiDateString(value)};
    }

    if (isPlainObj(value)) {
      if ('from' in value && 'to' in value) {
        return {...acc, [key]: value};
      }

      return {...acc, ...dynamicUiReturnValuesToFormFilterValues(value)};
    }

    if (isNil(value)) {
      return {...acc};
    }

    return {...acc, [key]: value};
  }, {});
