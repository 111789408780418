import {
  Button,
  closeCurrentDialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {Grid, GridItem, HStack, VStack} from 'platform/foundation';
import {useCurrencySymbolFormatter} from 'platform/locale';
import {Nullish} from 'utility-types';
import {z} from 'zod';

import {isNil, isNotNil} from 'ramda';

import {RentalPriceGroupItemResponseBody} from '@dms/api/rentalVehicles';
import i18n from '@dms/i18n';
import {useTenant} from '@dms/shared';

import {PriceListItem} from '../types/PriceListItem';

type DefaultValues =
  | (PriceListItem & {
      originalIndex: number;
    })
  | RentalPriceGroupItemResponseBody;

interface PriceListItemFormProps {
  defaultValues?: DefaultValues;
  onSubmit: FormSubmitHandler<PriceListItem>;
  items: DefaultValues[];
}

export function PriceListItemForm(props: PriceListItemFormProps) {
  const {tenantCurrency} = useTenant();
  const formatCurrency = useCurrencySymbolFormatter();

  const isCreating = isNil(props.defaultValues);

  const defaultValues = areDefaultValuesFromApi(props.defaultValues)
    ? {
        name: props.defaultValues.name,
        from: props.defaultValues?.range.from,
        includedKilometers: props.defaultValues?.price.includedKilometers,
        period: props.defaultValues?.range.period,
        rate: props.defaultValues?.price.rate
          ? parseFloat(props.defaultValues.price.rate)
          : undefined,
        ratePerKilometer: props.defaultValues?.price.ratePerKilometer
          ? parseFloat(props.defaultValues.price.ratePerKilometer)
          : undefined,
        to: props.defaultValues?.range.to,
      }
    : props.defaultValues;

  return (
    <Form<PriceListItem>
      defaultValues={defaultValues}
      onSubmit={props.onSubmit}
      experimentalZodSchema={schema}
    >
      {(control) => (
        <VStack spacing={4} width="100%">
          <Grid columns={2}>
            <GridItem span={2}>
              <FormField
                control={control}
                name="name"
                type="text"
                label={i18n.t('general.labels.name')}
                isRequired
              />
            </GridItem>

            <FormField
              control={control}
              name="from"
              type="number"
              label={i18n.t('entity.rental.labels.from')}
              isStepperVisible
              minStepperValue={0}
              isRequired
            />

            <FormField
              control={control}
              name="to"
              type="number"
              label={i18n.t('entity.rental.labels.to')}
              isStepperVisible
              minStepperValue={0}
              isRequired
            />

            <FormField
              control={control}
              name="rate"
              type="currency"
              label={i18n.t('entity.rental.labels.rate')}
              currency={tenantCurrency}
              isRequired
            />

            <FormField
              control={control}
              name="ratePerKilometer"
              type="currency"
              label={`${formatCurrency(tenantCurrency)}${i18n.t('entity.rental.labels.ratePerKilometer')}`}
              currency={tenantCurrency}
              isRequired
            />

            <FormField
              control={control}
              name="includedKilometers"
              type="number"
              suffix="Km"
              label={i18n.t('entity.rental.labels.includedKilometers')}
              isRequired
            />

            <FormField
              control={control}
              name="period"
              type="choice"
              menuInPortal
              label={i18n.t('entity.rental.labels.period')}
              options={[
                {value: 'day', label: i18n.t('entity.rental.labels.day')},
                {value: 'month', label: i18n.t('entity.rental.labels.month')},
              ]}
              isRequired
            />
          </Grid>
          <HStack justify="flex-end" spacing={2} minWidth="100%">
            <Button
              variant="secondary"
              onClick={closeCurrentDialog}
              title={i18n.t('general.actions.discard')}
            />
            <FormButton
              control={control}
              title={isCreating ? i18n.t('general.actions.create') : i18n.t('general.actions.save')}
              type="submit"
            />
          </HStack>
        </VStack>
      )}
    </Form>
  );
}

const areDefaultValuesFromApi = (
  values: DefaultValues | Nullish
): values is RentalPriceGroupItemResponseBody =>
  isNotNil((values as RentalPriceGroupItemResponseBody)?.rentalPriceGroupItemId);

const schema = z
  .object({
    from: z.number().min(0),
    to: z.number().min(0),
    rate: z.number().min(0),
    ratePerKilometer: z.number().min(0),
    includedKilometers: z.number().min(0),
    period: z.enum(['day', 'month']),
    name: z.string().nonempty(),
  })
  .refine((data) => data.from <= data.to, {
    path: ['from'],
    message: i18n.t('entity.rental.labels.validationFrom'),
  });
