import {omneticApi} from '@dms/api/core';

import {
  CreateImportApiArg,
  CreateImportApiResponse,
  GetImportApiArg,
  GetImportApiResponse,
} from './types';

const injectedRtkApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    createImport: build.mutation<CreateImportApiResponse, CreateImportApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/import/import`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getImport: build.query<GetImportApiResponse, GetImportApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/import/import/${queryArg.id}`,
      }),
    }),
  }),
});

export {injectedRtkApi as importApi};

export const {useCreateImportMutation, useGetImportQuery} = injectedRtkApi;
