export function TesseractImage() {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <style>
        {`
          @keyframes waveOpacity1 {
            0% { opacity: 0.8; }
            10% { opacity: 0; }
            11% { opacity: 0; }
            50% { opacity: 1;}
            100% { opacity: 1; }
          }          
          @keyframes waveOpacity2 {
            0% { opacity: 0.6; }
            25% { opacity: 0; }
            100% { opacity: 0; }
          }
          @keyframes waveOpacity3 {
            0% { opacity: 0.4; }
            25% { opacity: 0; }
            100% { opacity: 0; }
          }
          .wave-1 { 
            animation: waveOpacity1 8s infinite;
          }
          .wave-2 { 
            animation: waveOpacity2 8s infinite 0.25s;
          }
          .wave-3 { 
            animation: waveOpacity3 8s infinite 0.5s;
          }
        `}
      </style>
      <path
        className="wave-1"
        d="M7 28L13.7537 31.6365C15.0088 32.3125 15.6364 32.6503 15.9848 33.2575C16.3333 33.8648 16.3333 34.6204 16.3333 36.1317V45"
        stroke="#0065FF"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="wave-2"
        d="M7 24L16.4069 28.92C18.1551 29.8345 19.0292 30.2916 19.5146 31.113C20 31.9348 20 32.957 20 35.0017V47"
        stroke="#0065FF"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="wave-3"
        d="M7 20L19.3013 26.4174C21.5874 27.6102 22.7305 28.2065 23.3653 29.2779C24 30.3497 24 31.683 24 34.35V49.5"
        stroke="#0065FF"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="wave-1"
        d="M49 28L42.2464 31.6365C40.9913 32.3125 40.3637 32.6503 40.0151 33.2575C39.6667 33.8648 39.6667 34.6204 39.6667 36.1317V44.3333"
        stroke="#0065FF"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="wave-2"
        d="M49 24L40.6988 28.4566C38.4127 29.6568 37.2695 30.2567 36.6345 31.3347C36 32.4131 36 33.7545 36 36.4379V47"
        stroke="#0065FF"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="wave-3"
        d="M49 20L39.5904 25.1694C35.8975 27.1001 34.0507 28.0651 33.025 29.7993C32 31.5341 32 33.6921 32 38.0087V49"
        stroke="#0065FF"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="wave-1"
        d="M36.1666 9.9646L30.646 12.9515C29.354 13.6505 28.7081 14 28 14C27.2918 14 26.6459 13.6505 25.354 12.9515L19.8333 9.9646"
        stroke="#0065FF"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="wave-2"
        d="M40 12L31.888 16.441C29.9896 17.4803 29.0406 18 28 18C26.9594 18 26.0104 17.4803 24.112 16.441L16 12"
        stroke="#0065FF"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="wave-3"
        d="M44 14L33.184 19.9214C30.6528 21.3071 29.3874 22 28 22C26.6126 22 25.3472 21.3071 22.816 19.9214L12 14"
        stroke="#0065FF"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.3057 5.78166C26.6205 5.03838 27.2781 4.66675 28 4.66675C28.7219 4.66675 29.3795 5.03838 30.6943 5.78166L46.3057 14.6064C47.6205 15.3496 48.2781 15.7213 48.639 16.3334C49 16.9455 49 17.6888 49 19.1754V36.8247C49 38.3113 49 39.0547 48.639 39.6668C48.2781 40.2788 47.6205 40.6505 46.3057 41.3939L30.6943 50.2186C29.3795 50.9617 28.7219 51.3334 28 51.3334C27.2781 51.3334 26.6205 50.9617 25.3057 50.2186L9.6943 41.3939C8.3794 40.6505 7.72193 40.2788 7.36097 39.6668C7 39.0547 7 38.3113 7 36.8247V19.1754C7 17.6888 7 16.9455 7.36097 16.3334C7.72193 15.7213 8.3794 15.3496 9.6943 14.6064L25.3057 5.78166Z"
        stroke="#BFE0FF"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28 27.1025V51.3333M28 27.1025L47.8334 16.3333M28 27.1025L8.16669 16.3333"
        stroke="#BFE0FF"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
