import {Option} from 'platform/components';

import {isNil} from 'ramda';

import {BaseServiceOrderVariant} from '@dms/api/metadaWarehouseServiceOrderVariants';

import {Nullish} from 'shared';

export const getOptionsFromServiceOrderVariants = (
  serviceOrderVariants: BaseServiceOrderVariant[] | Nullish
): Option[] => {
  if (isNil(serviceOrderVariants)) {
    return [];
  }

  return serviceOrderVariants.map((serviceOrderVariant) => ({
    label: serviceOrderVariant.name,
    value: serviceOrderVariant.id,
  }));
};
