import {z} from 'zod';

import {
  BasePriceSchema,
  BasketTooltipSchema,
  PriceWithAndWithoutVatSchema,
  HeaderDynamicActionsFlagsSchema,
  BaseIdSchema,
  BaseVoidResponseSchema,
  BaseDirectSaleIdSchema,
} from '@dms/api/shared';

/**
 * Base types
 */

export const BaseDirectSaleActionSchema = z.object({
  actionKey: z.string(),
  newDirectSaleVariantId: z.string().optional(),
});
export type BaseDirectSaleAction = z.infer<typeof BaseDirectSaleActionSchema>;

export const DirectSaleStateSchema = z.enum(['ARCHIVED', 'OPEN', 'LOCKED', 'CLOSED']);
export type DirectSaleState = z.infer<typeof DirectSaleStateSchema>;

const BaseDirectSaleSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  branchId: z.string(),
  directSaleVariantId: z.string(),
  authorizationProfileId: z.string(),
  number: z.string(),
  state: DirectSaleStateSchema,
  name: z.string().optional(),
  vehicleId: z.string().optional(),
  customerId: z.string().optional(),
  description: z.string().optional(),
  neededAt: z.string().optional(),
  createdAt: z.string(),
  createdBy: z.string(),
  variantName: z.string(),
  hasPendingReturn: z.boolean(),
  markers: HeaderDynamicActionsFlagsSchema.optional(),
  tooltip: z.object({
    authorizationProfile: z.string().optional(),
    directSaleType: z.string().optional(),
    isUnitPriceWithVat: z.string().optional(),
    priceType: z.string().optional(),
    priceTypeRatio: z.number().optional(),
    minimalMaterialMargin: z.string().optional(),
    rateType: z.string().optional(),
    rateTypeRatio: z.number().optional(),
    minimalWorkMargin: z.string().optional(),
    note: z.string().optional(),
  }),
  totalPrice: PriceWithAndWithoutVatSchema,
  originObjectType: z.string(),
});
export type BaseDirectSale = z.infer<typeof BaseDirectSaleSchema>;

export const BaseCategorySchema = z.object({
  id: z.string(),
  name: z.string(),
});

const BaseDirectSaleLabourCatalogueSchema = z.object({
  id: z.string(),
  category: BaseCategorySchema,
});
export type BaseDirectSaleLabourCatalogue = z.infer<typeof BaseDirectSaleLabourCatalogueSchema>;

export const BaseDirectSaleMaterialItemSchema = z.object({
  id: z.string(),
  warehouse_id: z.string(),
  itemEditingAllowed: z.boolean(),
  priceType: z.string().nullish(),
  requestType: z.string(),
  name: z.string(),
  manufacturerNumber: z.string(),
  manufacturerId: z.string(),
  quantity: z.number(),
  unit: z.string().nullish(),
  dispensingUnit: z.number(),
  quantityEditingAllowed: z.boolean(),
  unitPrice: PriceWithAndWithoutVatSchema,
  discount: PriceWithAndWithoutVatSchema.extend({
    rate: z.number(),
  }),
  totalPrice: PriceWithAndWithoutVatSchema,
  tooltip: z.array(BasketTooltipSchema),
});
export type BaseDirectSaleMaterialItem = z.infer<typeof BaseDirectSaleMaterialItemSchema>;

export const BaseDirectSaleLabourItemSchema = z.object({
  id: z.string(),
  itemEditingAllowed: z.boolean(),
  priceType: z.string(),
  priceTypeName: z.string(),
  name: z.string(),
  number: z.string(),
  labourCatalog: BaseDirectSaleLabourCatalogueSchema,
  technican: z.string().nullish(),
  quantity: z.number(),
  unit: z.string(),
  quantityEditingAllowed: z.boolean(),
  unitPrice: PriceWithAndWithoutVatSchema,
  discount: PriceWithAndWithoutVatSchema.extend({
    rate: z.number(),
  }),
  totalPrice: PriceWithAndWithoutVatSchema,
  tooltip: z.array(BasketTooltipSchema),
});
export type BaseDirectSaleLabourItem = z.infer<typeof BaseDirectSaleLabourItemSchema>;

/**
 * GET requests
 */
export const GetDirectSaleRequestSchema = BaseDirectSaleIdSchema;
export type GetDirectSaleRequest = z.infer<typeof GetDirectSaleRequestSchema>;

export const GetDirectSaleResponseSchema = BaseDirectSaleSchema.merge(
  z.object({itemsCount: z.number()})
);
export type GetDirectSaleResponse = z.infer<typeof GetDirectSaleResponseSchema>;

export const GetDirectSaleDiscountRequestSchema = z.object({
  directSaleId: z.string(),
});
export type GetDirectSaleDiscountRequest = z.infer<typeof GetDirectSaleDiscountRequestSchema>;

const DiscountWorkRateSchema = z.object({
  workType: z.string(),
  workRateWithoutVat: BasePriceSchema,
});
export type DiscountWorkRate = z.infer<typeof DiscountWorkRateSchema>;

const MaterialDiscountSchema = z
  .object({
    discountToSalePrice: z.number().optional(),
    markupToPurchasePrice: z.number().optional(),
  })
  .nullable();

const WorkDiscountSchema = z
  .object({
    percentageDiscount: z.number().optional(),
    discountedWorkRate: z.array(DiscountWorkRateSchema).optional(),
  })
  .nullable();

const DirectSaleDiscountSchema = z.object({
  workDiscount: WorkDiscountSchema,
  materialDiscount: MaterialDiscountSchema,
});

export const GetDirectSaleDiscountResponseSchema = DirectSaleDiscountSchema;
export type GetDirectSaleDiscountResponse = z.infer<typeof GetDirectSaleDiscountResponseSchema>;

export const GetDirectSaleLastActiveByCustomerIdResponseSchema = z
  .object({
    id: z.string(),
    branchId: z.string(),
    directSaleVariantId: z.string(),
    number: z.string(),
    state: z.enum([
      'DEMAND',
      'DRAFT',
      'HANDED_OVER',
      'PLANNED',
      'CANCELLED',
      'CHECKED_IN',
      'CREATED',
    ]),
    name: z.string().nullable().optional(),
    vehicle_id: z.string().nullable().optional(),
    customerId: z.string(),
    description: z.string().nullable().optional(),
    markers: z
      .array(
        z
          .object({
            title: z.string().nullable().optional(),
            color: z.enum([
              'neutral',
              'red',
              'magenta',
              'purple',
              'blue',
              'teal',
              'green',
              'yellow',
              'orange',
              'black',
            ]),
          })
          .nullable()
      )
      .optional(),
    created_at: z.string(),
    created_by: z.string(),
    updated_at: z.string().nullable().optional(),
    updated_by: z.string().nullable().optional(),
    closed_at: z.string().nullable().optional(),
    closed_by: z.string().nullable().optional(),
  })
  .nullable();
export type GetDirectSaleLastActiveByCustomerIdResponse = z.infer<
  typeof GetDirectSaleLastActiveByCustomerIdResponseSchema
>;

export const GetDirectSaleLastActiveByCustomerIdArgSchema = z.object({
  customerId: z.string(),
});
export type GetDirectSaleLastActiveByCustomerIdArg = z.infer<
  typeof GetDirectSaleLastActiveByCustomerIdArgSchema
>;

export const GetDirectSaleCheckoutRequestSchema = z.object({
  directSaleId: z.string(),
});
export type GetDirectSaleCheckoutRequest = z.infer<typeof GetDirectSaleCheckoutRequestSchema>;

export const GetDirectSaleCheckoutResponseSchema = z
  .object({
    checkoutId: z.string(),
    contextTarget: z.enum(['service-order', 'direct-sale']),
    contextId: z.string(),
  })
  .nullable();
export type GetDirectSaleCheckoutResponse = z.infer<typeof GetDirectSaleCheckoutResponseSchema>;

/**
 * POST requests
 */
export const PostDirectSaleRequestSchema = z.object({
  directSaleVariantId: z.string(),
});
export type PostDirectSaleRequest = z.infer<typeof PostDirectSaleRequestSchema>;

export const PostDirectSaleResponseSchema = BaseIdSchema;
export type PostDirectSaleResponse = z.infer<typeof PostDirectSaleResponseSchema>;

export const BulkReturnDirectSaleSparePartsRequestSchema = z.object({
  directSaleId: z.string(),
  body: z.object({
    directSaleItemId: z.array(z.string()),
  }),
});
export type BulkReturnDirectSaleSparePartsRequest = z.infer<
  typeof BulkReturnDirectSaleSparePartsRequestSchema
>;

export const BulkReturnDirectSaleSparePartsResponseSchema = BaseVoidResponseSchema;
export type BulkReturnDirectSaleSparePartsResponse = z.infer<
  typeof BulkReturnDirectSaleSparePartsResponseSchema
>;

export const PostDirectSaleCheckoutRequestSchema = z.object({
  directSaleId: z.string(),
  body: z.object({
    customerId: z.string(),
  }),
});
export type PostDirectSaleCheckoutRequest = z.infer<typeof PostDirectSaleCheckoutRequestSchema>;

export const PostDirectSaleCheckoutResponseSchema = z.object({
  checkoutid: z.string(),
});
export type PostDirectSaleCheckoutResponse = z.infer<typeof PostDirectSaleCheckoutResponseSchema>;

/**
 * PATCH requests
 */
const PatchDirectSaleRequestBodySchema = z.object({
  name: z.string().nullish(),
  description: z.string().nullish(),
  vehicleId: z.string().nullish(),
  customerId: z.string().nullish(),
  neededAt: z.string().nullish(),
  isItemNeededAtUpdate: z.boolean().nullish(),
});

export const PatchDirectSaleRequestSchema = z.object({
  directSaleId: z.string(),
  body: PatchDirectSaleRequestBodySchema,
});
export type PatchDirectSaleRequest = z.infer<typeof PatchDirectSaleRequestSchema>;

export const PatchDirectSaleResponseSchema = BaseVoidResponseSchema;
export type PatchDirectSaleResponse = z.infer<typeof PatchDirectSaleResponseSchema>;

export const PatchDirectSaleGetCurrentPriceRequestSchema = z.object({
  directSaleId: z.string(),
  body: z.object({
    directSaleItemId: z.array(z.string()),
  }),
});
export type PatchDirectSaleGetCurrentPriceRequest = z.infer<
  typeof PatchDirectSaleGetCurrentPriceRequestSchema
>;

export const PatchDirectSaleGetCurrentPriceResponseSchema = BaseVoidResponseSchema;
export type PatchDirectSaleGetCurrentPriceResponse = z.infer<
  typeof PatchDirectSaleGetCurrentPriceResponseSchema
>;

export const BulkAssignMechanicRequestSchema = z.object({
  directSaleId: z.string(),
  body: z.object({
    itemId: z.array(z.string()),
    mechanicId: z.string(),
  }),
});
export type BulkAssignMechanicRequest = z.infer<typeof BulkAssignMechanicRequestSchema>;

export const BulkAssignMechanicResponseSchema = BaseVoidResponseSchema;
export type BulkAssignMechanicResponse = z.infer<typeof BulkAssignMechanicResponseSchema>;

/**
 * DELETE requests
 */
export const DeleteDirectSaleRequestSchema = z.object({
  directSaleId: z.array(z.string()),
});
export type DeleteDirectSaleRequest = z.infer<typeof DeleteDirectSaleRequestSchema>;

export const DeleteDirectSaleResponseSchema = BaseVoidResponseSchema;
export type DeleteDirectSaleResponse = z.infer<typeof DeleteDirectSaleResponseSchema>;

export const BulkDeleteDirectSaleItemsRequestSchema = z.object({
  directSaleId: z.string(),
  body: z.object({
    items: z.array(z.object({id: z.string()})),
  }),
});
export type BulkDeleteDirectSaleItemsRequest = z.infer<
  typeof BulkDeleteDirectSaleItemsRequestSchema
>;

export const BulkDeleteDirectSaleItemsResponseSchema = BaseVoidResponseSchema;
export type BulkDeleteDirectSaleItemsResponse = z.infer<
  typeof BulkDeleteDirectSaleItemsResponseSchema
>;

/**
 * PUT requests
 */
export const PutDirectSaleDiscountRequestSchema = z.object({
  directSaleId: z.string(),
  body: z.object({
    directSaleDiscount: DirectSaleDiscountSchema,
    isDiscountApply: z.boolean(),
  }),
});
export type PutDirectSaleDiscountRequest = z.infer<typeof PutDirectSaleDiscountRequestSchema>;

export const PutDirectSaleDiscountResponseSchema = BaseVoidResponseSchema;
export type PutDirectSaleDiscountResponse = z.infer<typeof PutDirectSaleDiscountResponseSchema>;
