import {isFeatureEnabled} from 'feature-flags';
import {Box, Grid, GridItem, Hide, Show, Text, VStack} from 'platform/foundation';
import {CurrencyFormat} from 'platform/locale';

import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {path} from 'ramda';
import {isArray, isNotNil, isString} from 'ramda-adjunct';

import {useGetTenantQuery} from '@dms/api/tenant';
import {
  GetVehicleInWarehouseHistorySummaryApiResponseBody,
  useLazyGetVehicleInWarehouseHistorySummaryQuery,
} from '@dms/api/vehicleWarehouse';
import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {vehiclesRoutes} from '@dms/routes';
import {DEFAULT_CURRENCY, EMPTY_PLACEHOLDER, handleApiError} from '@dms/shared';

import {composePath, getApiDateString} from 'shared';

import {ActionCallback, DataGrid, Filters} from 'features/datagrid';

import {getApiDateFromRelativeDate} from './utils/getApiDateFromRelativeDate';

export function VehicleWarehouses() {
  const navigate = useNavigate();

  const [summary, setSummary] = useState<GetVehicleInWarehouseHistorySummaryApiResponseBody>();

  const {data: {currency: tenantCurrency} = {}} = useGetTenantQuery();

  const [getHistorySummary] = useLazyGetVehicleInWarehouseHistorySummaryQuery();

  const defaultCurrency = tenantCurrency ?? DEFAULT_CURRENCY;

  const handleFilterChange = (filters: Filters) => {
    const relativeApiDate = isString(filters?.stateOnDate)
      ? getApiDateFromRelativeDate(filters.stateOnDate)
      : null;

    getHistorySummary({
      body: {
        date: relativeApiDate ?? getApiDateString(new Date()),
        vehicleWarehouseIds: isArray(filters.vehicleWarehouse) ? filters.vehicleWarehouse : [],
      },
    })
      .unwrap()
      .then(setSummary)
      .catch(handleApiError);
  };

  const actionCallback: ActionCallback = ({actionKey, rowData}) => {
    if (actionKey !== 'vehicleDetail') {
      return;
    }

    const vehicleId = path<string>(['vehiclePublicId', 'value', 'id'], rowData);

    navigate(
      composePath(vehiclesRoutes.vehicleWarehouseInfo, {
        params: {id: vehicleId},
        isSearchParamsPreserved: false,
      })
    );
  };

  return (
    <VStack spacing={4} height="100%">
      <Grid columns={5}>
        <GridItem span={2}>
          <div id={EXTERNAL_FILTER_ID} />
        </GridItem>
        <GridItem span={2}>
          <Grid columns={isFeatureEnabled(featureFlags.SALES_VEHICLE_WAREHOUSE_SALESINFO) ? 3 : 2}>
            <VStack spacing={2}>
              <Text color="secondary" size="xSmall">
                {i18n.t('entity.vehicleWarehouse.labels.warehouseValue')}
              </Text>
              <Text alternative>
                <Show when={isNotNil(summary?.value.amount)}>
                  <CurrencyFormat
                    number={Number(summary?.value.amount) ?? 0}
                    currency={summary?.value.currency ?? defaultCurrency}
                  />
                </Show>
                <Hide when={isNotNil(summary?.value.amount)}>{EMPTY_PLACEHOLDER}</Hide>
              </Text>
            </VStack>

            <Show whenFeatureEnabled={featureFlags.SALES_VEHICLE_WAREHOUSE_SALESINFO}>
              <VStack spacing={2}>
                <Text color="secondary" size="xSmall">
                  {i18n.t('entity.vehicleWarehouse.labels.salesValue')}
                </Text>
                <Text alternative>
                  <Show when={isNotNil(summary?.saleValueWithoutVat.amount)}>
                    <CurrencyFormat
                      number={Number(summary?.saleValueWithoutVat.amount) ?? 0}
                      currency={summary?.saleValueWithoutVat.currency ?? defaultCurrency}
                    />
                  </Show>
                  <Hide when={isNotNil(summary?.saleValueWithoutVat.amount)}>
                    {EMPTY_PLACEHOLDER}
                  </Hide>
                </Text>
              </VStack>
            </Show>

            <VStack spacing={2}>
              <Text color="secondary" size="xSmall">
                {i18n.t('entity.vehicleWarehouse.labels.vehicleAmount')}
              </Text>
              <Text alternative>{summary?.count ?? EMPTY_PLACEHOLDER}</Text>
            </VStack>
          </Grid>
        </GridItem>
      </Grid>
      <Box height="100%">
        <DataGrid
          gridCode="vehicle_in_warehouse_history"
          actionCallback={actionCallback}
          externalFilterId={EXTERNAL_FILTER_ID}
          onFilterChanged={handleFilterChange}
        />
      </Box>
    </VStack>
  );
}

const EXTERNAL_FILTER_ID = 'vehicleWarehousesFilter';
