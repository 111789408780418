import {Module} from '@dms/api/shared';
import {moduleKeys} from '@dms/config';
import {dashboardRoutes, sharePresetRoute} from '@dms/routes';

import {Homepage} from '../../../pages/Homepage/Homepage';
import {SharePreset} from '../../../pages/SharePreset/SharePreset';

export const CoreModule: Module = {
  id: moduleKeys.core,
  layoutProps: {
    title: 'page.homepage.labels.title',
    icon: 'action/dashboard',
    iconV2: 'sidebar/dashboard',
  },
  routerProps: {
    path: dashboardRoutes.home,
    element: <Homepage />,
  },
  content: [
    {
      id: 'core-share-preset',
      routerProps: {
        path: sharePresetRoute,
        element: <SharePreset />,
      },
    },
  ],
};
