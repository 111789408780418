import {Action, Button, DataStatus, openDeleteDialog} from 'platform/components';
import {Heading, HStack} from 'platform/foundation';

import {useState} from 'react';

import {isNilOrEmpty} from 'ramda-adjunct';

import {
  AdditionalCustomerType,
  OrderAdditionalCustomerResponseBody,
  useDeleteCheckoutOrderAdditionalCustomerMutation,
  usePatchCheckoutOrderAdditionalCustomerMutation,
} from '@dms/api/checkout';
import {OrderResponseBody} from '@dms/api/commission';
import {
  AddressRequestBodyV2,
  ContractInformationResponseBody,
  IdentityCardResponseBodyV2,
  PersonRequestBody,
  PersonResponseBodyV2,
  useGetCustomerV2Query,
} from '@dms/api/customer';
import i18n from '@dms/i18n';
import {
  CHECKOUT_ADDITIONAL_CUSTOMER_DEFAULT_ROLE,
  composeAddressLineFromCommonAddress,
  handleApiError,
} from '@dms/shared';
import {CheckoutContractInformationBody, IdentityCardData, WithValidationErrors} from '@dms/teas';

import {buildArray, Nullish, suffixTestId, TestIdProps} from 'shared';

import {CheckoutContractInformationFormState} from './CheckoutContractInformationForm';
import {CheckoutContractInformationItem} from './CheckoutContractInformationItem';

interface CheckoutAdditionalCustomerProps extends TestIdProps {
  additionalCustomer: OrderAdditionalCustomerResponseBody;
  index: number;
  counterStartAt: number;
  customerId: string;
  order: OrderResponseBody;
  checkoutId: string;
  permanentPersonId?: string;
  refreshBusinessCaseCheckoutInfo: (customerId: string | Nullish) => void;
  handleAdditionalPersonIdentityCardSubmit: (
    customerId: string,
    person: PersonResponseBodyV2,
    cardData: IdentityCardData,
    id: string | null
  ) => Promise<IdentityCardResponseBodyV2 | null>;
  handlePersonSubmit: (
    requestBody: PersonRequestBody,
    personId: string | null
  ) => Promise<PersonResponseBodyV2 | null>;
  handleAdditionalPersonSubmit: (
    customerId: string,
    requestBody: PersonRequestBody,
    personId: string | null
  ) => Promise<PersonResponseBodyV2 | null>;
  handleAdditionalPersonAddressSubmit: (
    customerId: string,
    addressData: AddressRequestBodyV2,
    addressId?: string | null
  ) => Promise<string | null>;
  handleEditCheckoutContractInformation: (
    customerId: string,
    contractInformationId: string,
    orderId: string,
    isContractChange?: boolean
  ) => (
    values: CheckoutContractInformationFormState
  ) => Promise<ContractInformationResponseBody | null>;
  handleCreateAdditionalCheckoutContractInformation: (
    customerId: string
  ) => (
    values: CheckoutContractInformationFormState
  ) => Promise<ContractInformationResponseBody | null>;
}

export function CheckoutAdditionalCustomer(props: CheckoutAdditionalCustomerProps) {
  const [checked, setChecked] = useState<string>(props.additionalCustomer.contractInformationId);
  const [editOpen, setEditOpen] = useState<string[]>([]);
  const [expanded, setExpanded] = useState<string[]>([checked]);
  const [createOpen, setCreateOpen] = useState(false);

  const {
    data: customer,
    isLoading,
    isError,
  } = useGetCustomerV2Query(
    {
      customerId: props.additionalCustomer.customerId,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !props.additionalCustomer.customerId,
    }
  );

  const [updateAdditionalCustomer, {isLoading: isLoadingUpdate}] =
    usePatchCheckoutOrderAdditionalCustomerMutation();
  const [deleteAdditionalCustomer] = useDeleteCheckoutOrderAdditionalCustomerMutation();

  const customerContractInformation: CheckoutContractInformationBody[] | undefined =
    customer?.contractInformation?.map((contract) => ({
      customerContractInformation: {
        id: contract.id,
        isDeleted: contract.isDeleted,
        person: contract.person?.id
          ? {
              id: contract.person.id,
              personData: {
                firstName: contract.person.firstName,
                lastName: contract.person.lastName,
                middleName: contract.person.middleName,
                titleBefore: contract.person.titleBefore,
                titleAfter: contract.person.titleAfter,
                genderKey: contract.person.genderKey,
                roles: contract.person.roles,
                citizenshipCode: contract.person.citizenshipCode,
                birthdate: contract.person.birthdate,
                personalIdentifier: contract.person.personalIdentifier,
              },
              phoneNumbers: contract.person.phoneNumbers.map((phoneNumber) => ({
                type: phoneNumber.type,
                phoneNumber: {
                  countryCode: phoneNumber.countryCode,
                  prefix: phoneNumber.prefix,
                  number: phoneNumber.number,
                },
              })),
              emails: contract.person.emails,
              identityCards: contract.person.identityCards.map((identityCard) => ({
                id: identityCard.id,
                identityCardData: {
                  type: identityCard.type,
                  cardNumber: identityCard.cardNumber,
                  issuedOn: identityCard.issuedOn,
                  validUntil: identityCard.validUntil,
                  issuer: identityCard.issuer,
                  issuedInCountryCode: identityCard.issuedInCountryCode,
                  note: identityCard.note,
                },
              })),
              permanentAddress: contract.person.permanentAddress
                ? {
                    id: contract.person.permanentAddress.id,
                    addressData: {
                      city: contract.person.permanentAddress.address.city,
                      postalCode: contract.person.permanentAddress.address.zip,
                      countryCode: contract.person.permanentAddress.address.country,
                      addressLine1: contract.person.permanentAddress.address
                        ? composeAddressLineFromCommonAddress(
                            contract.person.permanentAddress.address
                          )
                        : null,
                      addressLine2: null,
                      type: contract.person.permanentAddress.type,
                      province: null,
                      invalid: contract.person.permanentAddress.invalid,
                    },
                  }
                : null,
            }
          : null,
        permanent: contract.permanent,
        legalForm: contract.legalForm,
        bankAccounts: contract.bankAccounts.map((bankAccount) => ({
          id: bankAccount.id,
          bankAccountData: {
            name: bankAccount.name,
            countryCode: bankAccount.countryCode,
            ownerName: bankAccount.ownerName,
            iban: bankAccount.iban,
            swiftBic: bankAccount.swiftBic,
            currency: bankAccount.currency,
            number: bankAccount.number,
            bankCode: bankAccount.bankCode,
            isDeleted: bankAccount.isDeleted,
          },
        })),
        businessInfo: contract.businessInfo
          ? {
              id: contract.businessInfo.id,
              businessAddress: contract.businessInfo.address
                ? {
                    id: contract.businessInfo.address.id,
                    addressData: {
                      city: contract.businessInfo.address.address.city,
                      postalCode: contract.businessInfo.address.address.zip,
                      countryCode: contract.businessInfo.address.address.country,
                      addressLine1: contract.businessInfo.address.address
                        ? composeAddressLineFromCommonAddress(contract.businessInfo.address.address)
                        : null,
                      addressLine2: null,
                      type: contract.businessInfo.address.type,
                      province: null,
                      invalid: contract.businessInfo.address.invalid,
                    },
                  }
                : null,
              businessInfoData: contract.businessInfo.businessInfo,
            }
          : null,
        customFieldsPayload: contract.customFieldsPayload,
      },
      deputyPersons: [], // currently not applicable for additional customers
      selectedIdentityCards: [], // currently not applicable for additional customers
    }));

  const toggleCreateOpen = () => {
    setCreateOpen(!createOpen);
  };

  const updateContractInformation = (
    contractInformationId: string,
    additionalCustomerType?: AdditionalCustomerType
  ) => {
    updateAdditionalCustomer({
      checkoutId: props.checkoutId,
      orderId: props.order.id,
      additionalCustomerId: props.additionalCustomer.id,
      patchAdditionalCustomerRequestBody: {
        additionalCustomerType: additionalCustomerType ?? CHECKOUT_ADDITIONAL_CUSTOMER_DEFAULT_ROLE,
        contractInformationId,
      },
    })
      .unwrap()
      .then(() => props.refreshBusinessCaseCheckoutInfo(null))
      .catch(handleApiError);
  };

  const createContractInformation = async (
    values: CheckoutContractInformationFormState
  ): Promise<WithValidationErrors<void>> => {
    try {
      const contract = await props.handleCreateAdditionalCheckoutContractInformation(
        props.additionalCustomer.customerId
      )(values);
      if (!contract) {
        return;
      }
      setCreateOpen(false);
      setChecked(contract.id);
      toggleExpand(contract.id)();

      updateContractInformation(contract.id);
      props.refreshBusinessCaseCheckoutInfo(props.additionalCustomer.customerId);
    } catch (e: any) {
      handleApiError(e.response);
    }
  };

  const handleAdditionalPersonSubmit = (requestBody: PersonRequestBody, personId: string | null) =>
    props.handleAdditionalPersonSubmit(props.additionalCustomer.customerId, requestBody, personId);

  const handleAdditionalPersonIdentityCardSubmit =
    (person: PersonResponseBodyV2) =>
    (cardData: IdentityCardData, id: string | null): Promise<IdentityCardResponseBodyV2 | null> =>
      props.handleAdditionalPersonIdentityCardSubmit(
        props.additionalCustomer.customerId,
        person,
        cardData,
        id
      );

  const handleAdditionalPersonAddressSubmit = (
    addressData: AddressRequestBodyV2,
    addressId?: string | null
  ): Promise<string | null> =>
    props.handleAdditionalPersonAddressSubmit(
      props.additionalCustomer.customerId,
      addressData,
      addressId
    );

  const editContractInformation =
    (contractInformationId: string) =>
    async (values: CheckoutContractInformationFormState): Promise<WithValidationErrors<void>> => {
      try {
        await props.handleEditCheckoutContractInformation(
          props.additionalCustomer.customerId,
          contractInformationId,
          props.order.id,
          false
        )(values);
        toggleEdit(contractInformationId)();
        props.refreshBusinessCaseCheckoutInfo(props.additionalCustomer.customerId);
      } catch (e: any) {
        handleApiError(e.response);
      }
    };

  const toggleExpand = (contactPersonId: string) => () => {
    if (expanded.includes(contactPersonId)) {
      setExpanded(expanded.filter((id) => id !== contactPersonId));
    } else {
      setExpanded([...expanded, contactPersonId]);
    }
  };

  const toggleEdit = (contactPersonId: string) => () => {
    if (editOpen.includes(contactPersonId)) {
      setEditOpen(editOpen.filter((id) => id !== contactPersonId));
    } else {
      setEditOpen([...editOpen, contactPersonId]);
    }
  };

  const handleDelete = () => {
    openDeleteDialog({
      text: i18n.t('entity.checkout.labels.removeCustomerFromInvoiceQuestion'),
      onConfirm: () =>
        deleteAdditionalCustomer({
          orderId: props.order.id,
          checkoutId: props.checkoutId,
          additionalCustomerId: props.additionalCustomer.id,
        })
          .unwrap()
          .then(() => props.refreshBusinessCaseCheckoutInfo(null))
          .catch(handleApiError),
    });
  };

  return (
    <>
      <HStack justify="space-between">
        <Heading size={4}>
          {i18n.t('entity.accounting.labels.nthCustomerOnInvoice', {
            n: props.index + props.counterStartAt,
          })}
        </Heading>
        <Button
          onClick={handleDelete}
          title={i18n.t('entity.checkout.actions.removeCustomer')}
          variant="dangerLink"
          leftIcon="action/delete"
        />
      </HStack>
      <DataStatus isEmpty={isNilOrEmpty(customer)} isLoading={isLoading} isError={isError}>
        {customerContractInformation?.map((contract, i) => {
          const contractInformationId = contract.customerContractInformation.id;
          const isChecked = checked === contractInformationId;

          return (
            <CheckoutContractInformationItem
              customerId={customer!.id}
              key={contractInformationId}
              data-testid={suffixTestId(`[${i}]`, props)}
              permanentPersonId={props.permanentPersonId}
              checkoutContractInformation={contract}
              checked={isChecked}
              toggleEdit={toggleEdit(contractInformationId)}
              toggleExpand={toggleExpand(contractInformationId)}
              editOpen={editOpen.includes(contractInformationId)}
              expanded={expanded.includes(contractInformationId)}
              onControlChange={(checked) => {
                if (checked) {
                  setCreateOpen(false);
                  setEditOpen([]);
                  setChecked(contractInformationId);
                  setExpanded([contractInformationId]);
                  updateContractInformation(contractInformationId);
                }
              }}
              handleIdentityCardSubmit={handleAdditionalPersonIdentityCardSubmit}
              handlePersonSubmit={props.handlePersonSubmit}
              handleAddressSubmit={handleAdditionalPersonAddressSubmit}
              onSubmit={editContractInformation(contractInformationId)}
              actions={buildArray<Action>().when(isChecked, {
                type: 'dropdown-button',
                variant: 'outlined',
                size: 'default',
                rightIcon: 'navigation/expand_more',
                title: i18n.t(
                  `entity.accounting.labels.${props.additionalCustomer.additionalCustomerType}`
                ),
                isLoading: isLoadingUpdate,
                menuItems: [
                  {
                    label: i18n.t('entity.accounting.labels.operator'),
                    isDisabled: props.additionalCustomer.additionalCustomerType === 'operator',
                    onClick: () => updateContractInformation(contractInformationId, 'operator'),
                  },
                  {
                    label: i18n.t('entity.accounting.labels.recipient'),
                    isDisabled: props.additionalCustomer.additionalCustomerType === 'recipient',
                    onClick: () => updateContractInformation(contractInformationId, 'recipient'),
                  },
                ],
              })}
              isAdditionalCustomer
              isDeputyPersonHidden
            />
          );
        })}
      </DataStatus>
      <CheckoutContractInformationItem
        customerId={props.customerId}
        data-testid={suffixTestId(`createNewAdditional`, props)}
        id="createNewAdditionalContract"
        toggleEdit={toggleCreateOpen}
        toggleExpand={toggleCreateOpen}
        checked={checked === 'create'}
        editOpen
        expanded={createOpen}
        onControlChange={(checked) => {
          if (checked) {
            setChecked('create');
            setExpanded([]);
            setCreateOpen(true);
          }
        }}
        permanentPersonId={props.permanentPersonId}
        handlePersonSubmit={handleAdditionalPersonSubmit}
        handleIdentityCardSubmit={handleAdditionalPersonIdentityCardSubmit}
        handleAddressSubmit={handleAdditionalPersonAddressSubmit}
        onSubmit={createContractInformation}
        isDeputyPersonHidden
      />
    </>
  );
}
