import {Tooltip} from 'platform/components';
import {Icon, ThemeIconKey} from 'platform/foundation';

import {isNil} from 'ramda';

import {useApiEnums} from '../../hooks/useApiEnums';

type CountryFlagType = {
  country?: string;
  disableTooltip?: boolean;
};

export function CountryFlag(props: CountryFlagType) {
  const {getEnum} = useApiEnums();
  const apiEnum = getEnum('country');
  const countryLabel = apiEnum.find(({key}) => key === props.country)?.label;
  const iconKey = `flags/${props.country}` as ThemeIconKey;

  if (props.disableTooltip || isNil(countryLabel)) {
    return <Icon value={iconKey} size={5} />;
  }

  return (
    <Tooltip label={countryLabel}>
      <Icon value={iconKey} size={5} />
    </Tooltip>
  );
}
