import {Checkbox} from 'platform/components';
import {Box} from 'platform/foundation';

import {FeatureKey} from '@dms/api/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {hasChildWithSelectedFeature} from '../utils/hasChildWithSelectedFeature';

interface FeatureItemProps extends RequiredTestIdProps {
  selectedFeature: string | undefined;
  updateSelectedFeature: (newFeature: string) => void;
  feature: FeatureKey;
}

export function FeatureItem(props: FeatureItemProps) {
  const hasChildren = !!props.feature.children.length;
  const isSelected = props.selectedFeature === props.feature.const_key;

  const isIndeterminate =
    hasChildren && hasChildWithSelectedFeature(props.feature, props.selectedFeature);

  return (
    <Box key={props.feature.const_key} paddingLeft={4}>
      <Box paddingBottom={4}>
        <Checkbox
          value={isSelected}
          onChange={() => props.updateSelectedFeature(props.feature.const_key)}
          isIndeterminate={isIndeterminate}
          label={props.feature.labels[0].label}
          data-testid={suffixTestId('feature', props)}
        />
      </Box>
      {hasChildren &&
        props.feature.children.map((subItem, index) => (
          <FeatureItem
            key={subItem.const_key}
            selectedFeature={props.selectedFeature}
            updateSelectedFeature={props.updateSelectedFeature}
            feature={subItem}
            data-testid={suffixTestId(`level-${index}`, props)}
          />
        ))}
    </Box>
  );
}
