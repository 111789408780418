import {Button, Card, showNotification} from 'platform/components';
import {Heading, Icon, Show} from 'platform/foundation';

import {FC, use, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {isNotNil} from 'ramda';

import {BillingInformationResponseBody} from '@dms/api/shared';
import {useGetTenantQuery} from '@dms/api/tenant';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {
  BillingInformationService,
  inlineEditingContext,
  InlineEditingContextType,
  LinkButton,
  noop,
  PutBillingInformationRequestBody,
} from '@dms/teas';

import {useNavigate} from 'shared';

import {BillingInfoCache} from '../../BillingInfoDetail/types';
import {
  BillingInformationDetailContainer,
  Container,
  Divider,
  EmptyContainer,
  EmptyTextContainer,
  H4WithMargin,
  StyledCard,
  StyledLink,
} from '../styles';
import {BillingCompanyCard} from './BillingCompanyCard';
import {BillingInformationCard} from './BillingInformationCard';
import {SelectBillingInformation} from './SelectBillingInformation';

export type BillingInformationProps = {
  selectedProfileId?: string;
  accountRadio?: boolean;
  redirectQuery?: string;
};

export const BillingInformation: FC<BillingInformationProps> = ({
  selectedProfileId,
  accountRadio,
  redirectQuery = 'from=tenant',
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {data: {country: countryCode, billingInformation = []} = {}} = useGetTenantQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const inlineEdit = use(inlineEditingContext) as InlineEditingContextType<
    unknown,
    BillingInfoCache
  >;
  const [expanded, setExpanded] = useState<number | null>(null);
  const [expandedBankAccount, setExpandedBankAccount] = useState<number | null>(null);
  const [showSelectBillingInformationModal, setShowSelectBillingInformationModal] =
    useState<boolean>(false);

  const [selectedBankAccounts, setSelectedBankAccounts] = useState<number[]>(
    billingInformation &&
      billingInformation.map((bi) => bi.bankAccounts.findIndex((ac) => ac.isPrimary))
  );
  const [selectedProfile, setSelectedProfile] = useState<number>(
    selectedProfileId
      ? billingInformation && billingInformation.findIndex((i) => i.id === selectedProfileId)
      : billingInformation && billingInformation.findIndex((i) => i.isPrimary)
  );

  const handleExpand = (index: number) => {
    setExpanded(index === expanded ? null : index);
  };

  const handleExpandAccount = (index: number) => {
    setExpandedBankAccount(index === expandedBankAccount ? null : index);
  };

  const newBillingInformationHeaderProps = {
    to: `${settingsRoutes.billingInformationNew}?${redirectQuery}`,
  };

  useEffect(() => {
    if (selectedProfile === -1 && billingInformation.length === 1) {
      handleChangePrimaryProfile(0);
    }

    // Only call useEffect on mount
  }, [dispatch, countryCode]);

  const handleChangePrimaryProfile = (index: number) => {
    const newBI = billingInformation.map((bi, i) => ({
      ...bi,
      isPrimary: i === index,
    }));

    setSelectedProfile(index);

    const updatedBI = {
      billingInformation: newBI,
      billingInformationId: newBI[index].id,
    };

    inlineEdit.updateMethod(updatedBI, updatedBI);

    setShowSelectBillingInformationModal(false);
  };

  const handleChangePrimaryAccount = (profileIndex: number, accountIndex: number) => {
    const oldItem = billingInformation.find((_bi, i) => i === profileIndex);
    const newItem = {
      ...oldItem,
      bankAccounts: oldItem?.bankAccounts?.map((account, i) => ({
        ...account,
        isPrimary: i === accountIndex,
      })),
    };
    const {id, isPrimary, ...body} = newItem;

    BillingInformationService.putBillingInformation({
      billingInformationId: newItem.id as string,
      requestBody: body as PutBillingInformationRequestBody,
    }).catch((error) => {
      showNotification.error(error.response?.data?.error?.message || error.toString());
    });

    setSelectedBankAccounts(
      selectedBankAccounts.map((oldVal, index) => (index === profileIndex ? accountIndex : oldVal))
    );
  };

  const renderBillingInformationDetail = (
    billingInfo: BillingInformationResponseBody,
    profileIndex: number
  ) => (
    <Container data-testid={testIds.settings.tenant('detailContent')}>
      <BillingCompanyCard
        city={billingInfo?.contactInformation?.address?.city}
        vatNumber={billingInfo?.contactInformation?.vatNumber}
        fileNumber={billingInfo?.contactInformation?.fileNumber}
        street={billingInfo?.contactInformation?.address?.street}
        companyName={billingInfo?.contactInformation?.companyName}
        registrationNumber={billingInfo?.contactInformation?.registrationNumber}
      />

      <Divider />

      <BillingInformationCard
        billingInfo={billingInfo}
        profileIndex={profileIndex}
        expandedBankAccount={expandedBankAccount}
        accountRadio={accountRadio}
        selectedBankAccounts={selectedBankAccounts}
        handleChangePrimaryAccount={handleChangePrimaryAccount}
        handleExpandAccount={handleExpandAccount}
      />
    </Container>
  );

  const renderBillingInformation = () => {
    if (!selectedProfileId || selectedProfileId === 'new') {
      return billingInformation?.map(
        (billingInfo: BillingInformationResponseBody, profileIndex: number) => (
          <Card
            key={profileIndex}
            data-testid={testIds.settings.tenant(`billingInformation-${profileIndex}`)}
            isExpanded={expanded === profileIndex}
            isExpandable
            control={
              isNotNil(selectedProfileId)
                ? {
                    type: 'radio',
                    value: profileIndex === selectedProfile,
                    onChange: () => handleChangePrimaryProfile(profileIndex),
                  }
                : undefined
            }
            title={`${i18n.t('page.settings.labels.profile')} ${profileIndex + 1} : ${
              billingInfo?.contactInformation?.companyName || ''
            }`}
            parameters={[
              billingInfo.bankAccounts[0]?.bankName,
              billingInfo.bankAccounts[0]?.accountNumber,
            ]}
            onExpandButtonClick={() => {
              setExpandedBankAccount(0);
              handleExpand(profileIndex);
            }}
          >
            <Show when={expanded === profileIndex}>
              {renderBillingInformationDetail(billingInfo, profileIndex)}
            </Show>
          </Card>
        )
      );
    }

    return selectedProfile > -1 ? (
      <BillingInformationDetailContainer>
        <H4WithMargin>
          {i18n.t('page.settings.labels.profile')} {selectedProfile + 1}:{' '}
          {billingInformation[selectedProfile]?.contactInformation?.companyName}
        </H4WithMargin>

        {renderBillingInformationDetail(billingInformation[selectedProfile], selectedProfile)}
      </BillingInformationDetailContainer>
    ) : null;
  };

  return (
    <>
      <StyledCard
        name="billing-information-card"
        title={i18n.t('entity.customer.labels.billingInformation')}
        headerContent={
          !selectedProfileId || selectedProfileId === 'new' ? (
            <Button
              data-testid={testIds.settings.tenant('addNewBillingInformation')}
              onClick={() => {
                navigate(`${settingsRoutes.billingInformationNew}?${redirectQuery}`);
              }}
              variant="link"
              title={i18n.t('entity.customer.actions.addNewBillingInformation')}
              leftIcon="content/add_circle"
            />
          ) : (
            <StyledLink
              icon={<Icon value="image/edit" />}
              data-testid={testIds.settings.tenant('change')}
              onClick={() => setShowSelectBillingInformationModal(true)}
            >
              {i18n.t('entity.customer.actions.changeBillingInformation')}
            </StyledLink>
          )
        }
      >
        {!billingInformation?.length ? (
          <EmptyContainer>
            <div>
              <Icon value="action/credit_card" />
            </div>
            <EmptyTextContainer>
              <Heading size={5} align="center">
                {i18n.t('entity.person.notifications.listWillAppearHere.')}
              </Heading>
              <LinkButton onClick={noop}>
                {i18n.t('entity.customer.actions.enterBilling')}
              </LinkButton>
            </EmptyTextContainer>
          </EmptyContainer>
        ) : (
          renderBillingInformation()
        )}
      </StyledCard>

      {showSelectBillingInformationModal && (
        <SelectBillingInformation
          billingInformation={billingInformation}
          selectedBillingInformationIndex={selectedProfile}
          onBillingInformationChange={handleChangePrimaryProfile}
          onClose={() => setShowSelectBillingInformationModal(false)}
        />
      )}
    </>
  );
};
