import {UnknownAction} from 'redux';

import {GenericRTKQState} from '../types/GenericRTKQState';
import {PersistEnhancerConfig} from '../types/PersistEnhancerConfig';
import {isRTKState} from '../utils/isRTKState';
import {createPersistReducerEnhancer} from './createPersistReducerEnhancer';

/**
 * RTK Query specific enhancer - applies additional filtering for RTK Query state
 */
export const createRTKQueryPersistEnhancer = <S extends GenericRTKQState, A extends UnknownAction>(
  config: PersistEnhancerConfig<S>
) => {
  const {key, transforms = [], debounceTime = 1000} = config;

  return createPersistReducerEnhancer<S, A>({
    key,
    transforms,
    debounceTime,
    shouldPersist: (state) => {
      // Only persist if this looks like an RTK Query state
      if (!isRTKState(state)) {
        return false;
      }

      // Check if this has the structure of an RTK Query state
      const hasConfig = 'config' in state;

      // Check for non-empty queries that are worth saving
      const hasNonEmptyQueries = Object.values(state.queries).some(
        (query) => query && query.status === 'fulfilled' && query.data !== undefined
      );

      return hasConfig && hasNonEmptyQueries;
    },
  });
};
