import {Option} from 'platform/components';
import {Nullish} from 'utility-types';

import {partition} from 'ramda';

import {GetApiMakeModelWithMakeApiResponse, VehicleTypeEnumObject} from '@dms/api/shared';
import {GetTopMakesApiResponse} from '@dms/api/topMakes';
import {GetCustomTenantCatalogueApiResponse} from '@dms/api/vehicleCatalogue';
import i18n from '@dms/i18n';

import {GroupType} from '../types/GroupType';

interface VehicleMakeDescriptor {
  /** Finalized data for UI picker */
  groupedMakeOptions: GroupType[];
  /** Ungrouped parts */
  detail: {
    /** Only globally recognized makes from Alpha catalogue */
    globalMakes: Option[];
    /** Only custom makes known in this tenant */
    customMakes: Option[];
    /** All makes combined [global, custom, unknown] */
    flatMakes: Option[];
  };
}

interface VehicleMakeParserProps {
  vehicleType: VehicleTypeEnumObject | Nullish;
  unknownMakes?: Option[];
  vehicleMakeData: GetApiMakeModelWithMakeApiResponse | Nullish;
  customTenantMakeModelData: GetCustomTenantCatalogueApiResponse | Nullish;
  topMakesData: GetTopMakesApiResponse | Nullish;
}

export function vehicleMakeParser(props: VehicleMakeParserProps): VehicleMakeDescriptor {
  const unknownMakes = props.unknownMakes || [];

  const globalMakes =
    props.vehicleMakeData?.map<Option>((item) => ({
      label: item.default_label,
      value: item.make,
    })) || [];

  const tenantCustomMakeSlice = props.customTenantMakeModelData?.[props.vehicleType || '']?.makes;

  const customMakes = tenantCustomMakeSlice
    ? Object.entries(tenantCustomMakeSlice).reduce<Option[]>(
        (acc, [key, value]) => (value.isCustom ? [...acc, {label: key, value: key}] : acc),
        []
      )
    : [];

  const flatMakesOptions = [...globalMakes, ...customMakes];

  const [topMakesRaw, otherMakesRaw] = partition(
    (option) => props.topMakesData?.some((item) => item.code === option.value) || false,
    flatMakesOptions
  );

  const topMakes = topMakesRaw.sort((a, b) => a.label.localeCompare(b.label));
  const otherMakes = otherMakesRaw.sort((a, b) => a.label.localeCompare(b.label));

  const flatMakes = [...topMakes, ...otherMakes, ...unknownMakes];

  const groupedMakeOptions: GroupType[] = [
    {
      label: i18n.t('general.labels.topMakes'),
      options: topMakes,
    },
    {
      label: i18n.t('general.labels.allMakes'),
      options: [...otherMakes, ...unknownMakes],
    },
  ];

  return {
    groupedMakeOptions,
    detail: {
      globalMakes,
      customMakes,
      flatMakes,
    },
  };
}
