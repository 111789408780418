import {
  Action,
  AttributesRow,
  Card,
  DataStatus,
  DropdownActionMenuItem,
  Parameter,
} from 'platform/components';
import {VStack} from 'platform/foundation';

import {isNil} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty, isTrue} from 'ramda-adjunct';

import {AddressResponseBodyV2, useGetCustomerV2Query} from '@dms/api/customer';
import i18n from '@dms/i18n';
import {InfoCard, useAddress, useCountry, useCustomerAddress} from '@dms/shared';

import {buildArray, EMPTY_PLACEHOLDER, suffixTestId, TestIdProps} from 'shared';

interface AddressesCardProps extends TestIdProps {
  customerId: string;
}

export function AddressesCard(props: AddressesCardProps) {
  const {data, isLoading, isError} = useGetCustomerV2Query({
    customerId: props.customerId,
  });
  const {
    openCreateAddressDialog,
    openEditAddressDialog,
    openDeleteAddressDialog,
    switchAddressValidity,
  } = useCustomerAddress(props.customerId);

  const {composeAddress} = useAddress();
  const {getCountryName} = useCountry();

  return (
    <Card
      title={i18n.t('entity.customer.labels.address')}
      actions={[
        {
          type: 'button',
          variant: 'link',
          leftIcon: 'content/add_circle',
          isDisabled: isLoading,
          title: i18n.t('general.actions.add'),
          onClick: () => openCreateAddressDialog(),
          'data-testid': suffixTestId('create', props),
        },
      ]}
      data-testid={props['data-testid']}
    >
      <DataStatus
        isLoading={isLoading}
        isError={isError}
        isEmpty={!data?.addresses?.length}
        emptyMessage={i18n.t('general.labels.noData')}
        emptySubheadline={i18n.t('entity.customer.labels.noAddresses')}
        minHeight={23}
      >
        <VStack spacing={4}>
          {data?.addresses?.map((addressData: AddressResponseBodyV2, index) => (
            <InfoCard
              key={addressData.id}
              title={composeAddress(addressData?.address)}
              description={buildArray<Parameter>()
                .when(isNotNilOrEmpty(addressData?.type), addressData?.type)
                .when(isNilOrEmpty(addressData?.type), EMPTY_PLACEHOLDER)}
              status={buildArray<Parameter>().when(
                isTrue(addressData?.invalid),
                i18n.t('entity.address.labels.invalid')
              )}
              actions={buildArray<Action>([
                {
                  type: 'iconButton',
                  severity: 'danger',
                  icon: 'action/delete',
                  onClick: () => openDeleteAddressDialog(addressData.id),
                },
                {
                  type: 'iconButton',
                  icon: 'image/edit',
                  onClick: () => openEditAddressDialog(addressData.id),
                },
              ]).whenNot(isNil(addressData?.invalid), {
                type: 'dropdown-iconButton',
                icon: 'navigation/more_vert',
                menuItems: buildArray<DropdownActionMenuItem>()
                  .whenNot(addressData?.invalid, {
                    label: i18n.t('entity.address.labels.invalid'),
                    onClick: () => switchAddressValidity(addressData, addressData.id),
                    severity: 'danger',
                  })
                  .when(isTrue(addressData?.invalid), {
                    label: i18n.t('entity.address.labels.valid'),
                    onClick: () => switchAddressValidity(addressData, addressData.id),
                    severity: 'success',
                  }),
              })}
              attributes={buildArray<AttributesRow>()
                .when(addressData.address.prefix, {
                  label: i18n.t('entity.address.labels.prefix'),
                  value: addressData.address.prefix,
                })
                .when(addressData.address.street, {
                  label: i18n.t('entity.address.labels.street'),
                  value: addressData.address.street,
                })
                .when(addressData.address.descriptiveNumber, {
                  label: i18n.t('entity.address.labels.descriptiveNumber'),
                  value: addressData.address.descriptiveNumber,
                })
                .when(addressData.address.orientationNumber, {
                  label: i18n.t('entity.address.labels.orientationNumber'),
                  value: addressData.address.orientationNumber,
                })
                .when(addressData.address?.city, {
                  label: i18n.t('entity.address.labels.city'),
                  value: addressData.address.city,
                })
                .when(addressData.address?.zip, {
                  label: i18n.t('entity.address.labels.zipCode'),
                  value: addressData.address?.zip,
                })
                .when(addressData.address?.addressComplement, {
                  label: i18n.t('entity.address.labels.addressComplement'),
                  value: addressData.address?.addressComplement,
                })
                .when(addressData.address?.country, {
                  label: i18n.t('entity.address.labels.country'),
                  value: getCountryName(addressData.address?.country),
                })
                .when(addressData.address?.district, {
                  label: i18n.t('entity.address.labels.district'),
                  value: addressData.address?.district,
                })
                .when(addressData.address?.state, {
                  label: i18n.t('entity.address.labels.stateProvince'),
                  value: addressData.address?.state,
                })
                .when(addressData?.type, {
                  label: i18n.t('entity.address.labels.type'),
                  value: addressData?.type,
                })}
              data-testid={suffixTestId(`address-${index}`, props)}
            />
          ))}
        </VStack>
      </DataStatus>
    </Card>
  );
}
