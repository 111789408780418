/* eslint-disable eag/match-named-export */
import {ColorSchemeType} from 'platform/components';

import {TaskPriorityEnum, TaskStateEnum, TaskTypeEnum} from '@dms/api/taskManagement';

export const TASK_STATE_LABEL_MAP: Record<TaskStateEnum, string> = {
  TODO: 'entity.task.state.todo',
  IN_PROGRESS: 'entity.task.state.inProgress',
  DONE: 'entity.task.state.done',
} as const;

export const TASK_STATE_COLOR_MAP: Record<TaskStateEnum, ColorSchemeType> = {
  TODO: 'neutral',
  IN_PROGRESS: 'blue',
  DONE: 'green',
} as const;

export const TASK_TYPE_LABEL_MAP: Record<TaskTypeEnum, string> = {
  TASK: 'entity.task.type.task',
  MEETING: 'entity.task.type.meeting',
  CALL: 'entity.task.type.call',
} as const;

export const TASK_PRIORITY_LABEL_MAP: Record<TaskPriorityEnum, string> = {
  LOW: 'entity.task.priority.low',
  MEDIUM: 'entity.task.priority.medium',
  HIGH: 'entity.task.priority.high',
} as const;
