import {z} from 'zod';

export const DeliveryNoteListBulkEditFormSchema = z.object({
  warehouse: z.object({
    isWarehouseEnabled: z.boolean(),
    warehouseId: z.string().nullish(),
  }),
  currency: z.object({
    isCurrencyEnabled: z.boolean(),
    currency: z.string().nullish(),
  }),
});

export type DeliveryNoteListBulkEditForm = z.infer<typeof DeliveryNoteListBulkEditFormSchema>;
