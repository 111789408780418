import {ThemeColorPath} from 'platform/foundation';
import {match, Pattern} from 'ts-pattern';

import {Nullish} from 'shared';

const CURRENT_USER_COLOR_MAP: Record<string, ThemeColorPath> = {
  HIGH: 'palettes.blue.80.100',
  MEDIUM: 'palettes.blue.60.100',
  LOW: 'palettes.blue.40.100',
  USER: 'palettes.blue.60.100',
};

const FIRST_USER_COLOR_MAP: Record<string, ThemeColorPath> = {
  HIGH: 'palettes.orange.80.100',
  MEDIUM: 'palettes.orange.60.100',
  LOW: 'palettes.orange.40.100',
  USER: 'palettes.orange.60.100',
};

const SECOND_USER_COLOR_MAP: Record<string, ThemeColorPath> = {
  HIGH: 'palettes.magenta.80.100',
  MEDIUM: 'palettes.magenta.60.100',
  LOW: 'palettes.magenta.40.100',
  USER: 'palettes.magenta.60.100',
};

const THIRD_USER_COLOR_MAP: Record<string, ThemeColorPath> = {
  HIGH: 'palettes.green.80.100',
  MEDIUM: 'palettes.green.60.100',
  LOW: 'palettes.green.40.100',
  USER: 'palettes.green.60.100',
};

const FOURTH_USER_COLOR_MAP: Record<string, ThemeColorPath> = {
  HIGH: 'palettes.purple.80.100',
  MEDIUM: 'palettes.purple.60.100',
  LOW: 'palettes.purple.40.100',
  USER: 'palettes.purple.60.100',
};

const OTHER_USER_COLOR_MAP: Record<string, ThemeColorPath> = {
  HIGH: 'palettes.neutral.80.100',
  MEDIUM: 'palettes.neutral.60.100',
  LOW: 'palettes.neutral.40.100',
  USER: 'palettes.neutral.60.100',
};

export const colors = [
  CURRENT_USER_COLOR_MAP,
  FIRST_USER_COLOR_MAP,
  SECOND_USER_COLOR_MAP,
  THIRD_USER_COLOR_MAP,
  FOURTH_USER_COLOR_MAP,
  OTHER_USER_COLOR_MAP,
];

export function getUserColorVariants(userIndex: number | Nullish) {
  if (userIndex === undefined || userIndex === null) {
    return colors[0];
  }

  const colorMapIndex = match(userIndex)
    .with(-1, () => 5)
    .with(
      Pattern.when((n: number) => n > 0 && n <= 5),
      () => userIndex
    )
    .with(
      Pattern.when((n: number) => n > 5),
      () => 5
    )
    .otherwise(() => 0);

  return colors[colorMapIndex];
}
