import {ColorSchemeType, DataStatus, Segment} from 'platform/components';
import {Box, HStack, Spinner, Show, Text, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {Helmet} from 'react-helmet-async';
import {Link} from 'react-router-dom';

import {always} from 'ramda';

import {BusinessCaseType} from '@dms/api/commission';
import i18n from '@dms/i18n';
import {businessCaseRoutes, interestRoutes} from '@dms/routes';
import {Main} from '@dms/shared';

import {composePath, DOT_CHARACTER, isLastIndexInArray, pushGtmEvent} from 'shared';

import {KanbanCard} from './components/KanbanCard';
import {KanbanUserControl} from './components/KanbanUserControl';
import {cardColors} from './constants/kanbanColors';
import {useKanbanControl} from './hooks/useKanbanControl';
import {useKanbanUsers} from './hooks/useKanbanUsers';
import {getCardsForColumn} from './utils/getCardsForColumn';
import {getNonHiddenSelectedUserIds} from './utils/getNonHiddenSelectedUserIds';

export function OpportunitiesKanban() {
  const {
    businessCaseType,
    businessCases,
    interests,
    isLoading,
    isFetching,
    isError,
    onFilterChange,
  } = useKanbanControl();

  const handleFilterChange = (nonHiddenSelectedUserIds: string[]) => {
    onFilterChange(businessCaseType, nonHiddenSelectedUserIds);
  };

  const {
    currentUser,
    users,
    selectedUsers,
    isLoading: isUsersLoading,
    isError: isUsersErrored,
    onSelectUsers,
  } = useKanbanUsers(handleFilterChange);

  return (
    <>
      <Helmet title={i18n.t('page.opportunitiesKanban.title')} />
      <Main isFullHeight padding={0}>
        <Box
          position="sticky"
          backgroundColor="palettes.neutral.10.100"
          top={0}
          left={0}
          right={0}
          paddingHorizontal={[2, 4]}
        >
          <HStack width="100%" minHeight={[12, 16]} align="center" justify="space-between">
            <Segment<BusinessCaseType>
              value={businessCaseType}
              options={[
                {
                  label: i18n.t('page.opportunitiesKanban.selling'),
                  value: 'SELLING',
                },
                {
                  label: i18n.t('page.opportunitiesKanban.buying'),
                  value: 'BUYING',
                },
              ]}
              onChange={(value) => {
                onFilterChange(value, getNonHiddenSelectedUserIds(selectedUsers));
              }}
            />
            <HStack spacing={2}>
              <Show when={isFetching}>
                <Spinner />
              </Show>
              <DataStatus
                data={currentUser}
                isLoading={isUsersLoading}
                isError={isUsersErrored}
                spinnerSize="small"
              >
                {({data}) => (
                  <KanbanUserControl
                    currentUserId={data.id}
                    users={users}
                    selectedUsers={selectedUsers}
                    onUserSelect={onSelectUsers}
                  />
                )}
              </DataStatus>
            </HStack>
          </HStack>
        </Box>
        <DataStatus
          isLoading={isLoading}
          isError={isError}
          isEmpty={!isLoading && !businessCases?.rows?.length && !interests?.rows?.length}
          emptyMessage={i18n.t('page.vehicle.notifications.noBusinessCases')}
          minHeight={200}
        >
          <Box width="100%" height="100%" overflow="auto" paddingBottom={[2, 4]}>
            <HStack spacing={2} height="100%" width="100%">
              {COLUMNS.map((column, columnIndex) => {
                const cards = getCardsForColumn(columnIndex, businessCases, interests);
                return (
                  <Box
                    key={`business-case-kanban-${column}`}
                    width="100%"
                    height="100%"
                    paddingLeft={columnIndex === 0 ? [2, 4] : 0}
                    paddingRight={isLastIndexInArray(COLUMNS, columnIndex) ? [2, 4] : 0}
                  >
                    <Box
                      key={`business-case-kanban-${column}`}
                      borderRadius="small"
                      minWidth={[65, 55]}
                      width="100%"
                      height="100%"
                      backgroundColor="palettes.neutral.30.100"
                      padding={2}
                    >
                      <VStack width="100%" spacing={3}>
                        <HStack spacing={1} align="center">
                          <Text size="small" alternative color="primary">
                            {i18n.t(column)}
                          </Text>
                          <Text size="small" color="tertiary">
                            {DOT_CHARACTER}
                          </Text>
                          <Text size="small" color="tertiary">
                            {cards.length || 0}
                          </Text>
                        </HStack>
                        {cards.map((card) => (
                          <Link
                            key={card.id}
                            to={composePath(
                              match([card.origin, businessCaseType])
                                .with(['business-case', 'BUYING'], () => businessCaseRoutes.buying)
                                .with(
                                  ['business-case', 'SELLING'],
                                  () => businessCaseRoutes.selling
                                )
                                .otherwise(() => interestRoutes.detail),
                              {
                                params: {id: card.id},
                                queryParams: {
                                  source: 'opportunities-kanban',
                                },
                              }
                            )}
                            onClick={() => {
                              pushGtmEvent({
                                event: 'button_click',
                                event_id: 'opportunities_kanban_card_click',
                                value: {
                                  card_id: card.id,
                                  card_origin: card.origin,
                                  card_business_case_type: businessCaseType,
                                },
                              });
                            }}
                          >
                            <KanbanCard
                              card={card}
                              backgroundColor={
                                selectedUsers.length > 1
                                  ? cardColors[
                                      selectedUsers.findIndex((user) => user.id === card.ownerId)
                                    ]
                                  : null
                              }
                              colorStripe={match<number, ColorSchemeType>(columnIndex)
                                .with(0, always('neutral'))
                                .with(1, always('yellow'))
                                .with(2, always('orange'))
                                .with(3, always('teal'))
                                .with(4, always('blue'))
                                .with(5, always('purple'))
                                .with(6, always('magenta'))
                                .with(7, always('green'))
                                .otherwise(always('black'))}
                            />
                          </Link>
                        ))}
                      </VStack>
                    </Box>
                  </Box>
                );
              })}
            </HStack>
          </Box>
        </DataStatus>
      </Main>
    </>
  );
}

const COLUMNS = [
  'page.opportunitiesKanban.newInterest',
  'page.opportunitiesKanban.activeInterest',
  'page.opportunitiesKanban.offer',
  'page.opportunitiesKanban.contract',
  'page.opportunitiesKanban.paymentRequest',
  'page.opportunitiesKanban.paymentReceived',
  'page.opportunitiesKanban.invoice',
  'page.opportunitiesKanban.paid',
];
