import {useSubscription} from '@apollo/client';
import {DndContext, DragOverlay, PointerSensor, useSensor, useSensors} from '@dnd-kit/core';
import {isFeatureEnabled} from 'feature-flags';
import {DataStatus, DropdownItem, DropdownSubmenu, showNotification} from 'platform/components';
import {Box, Heading, Icon, Show, Spinner, Text, VStack} from 'platform/foundation';
import {Lightbox} from 'platform/lightbox';
import {useDateTimeFormatter} from 'platform/locale';

import {useCallback, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';

import {flatten, groupBy, isNotNil, partition, pipe, values} from 'ramda';
import {isNonEmptyArray, isNotNilOrEmpty, isTrue} from 'ramda-adjunct';

import {SEND_NOTIFICATION_SUBSCRIPTION} from '@dms/api/graphQl';
import {
  useLazyGetSentBackgroundNotificationQuery,
  useLazyGetSentToastNotificationQuery,
} from '@dms/api/messaging';
import {useGetParticipationQuery} from '@dms/api/participation';
import {
  saleVehiclePhotoApi,
  useCopySaleVehiclePhotoMutation,
  useListSaleVehicleAlbumsByVehicleQuery,
  useUpdateSaleVehiclePhotoAlbumMutation,
} from '@dms/api/saleVehiclePhoto';
import {EntityResourceIds, VehicleAlbums} from '@dms/api/shared';
import {useGetVehicleQuery, useGetWatermarkListQuery} from '@dms/api/vehicle';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {notificationTypes, handleApiError, usePermissions} from '@dms/shared';

import {parseDate, useRequiredParams} from 'shared';

import {BannerBgRemoval} from '../../components/BannerBgRemoval/BannerBgRemoval';
import {IMAGE_SIZE} from '../../constants/imageSize';
import {useVehicleAlbumWatermarkPreview} from '../../hooks/useVehicleAlbumWatermarkPreview';
import {Album} from './Album';
import {AlbumImage} from './AlbumImage';
import {BackgroundRemovalStatus} from './BackgroundRemovalStatus';
import {parseDndItemId} from './dragndrop/utils/parseDndItemId';
import {getNotificationText} from './utils/getNotificationText';
import {prepareAlbumImageUrl} from './utils/prepareAlbumImageUrl';

export function VehiclePhotoGallery() {
  const {id: vehicleId} = useRequiredParams();
  const formatDate = useDateTimeFormatter();

  // useDispatch is not correctly typed, so any is used to override errors thrown from the updateQueryData function
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch = useDispatch<any>();

  const [activeDnDUrl, setActiveDnDUrl] = useState<string | null>(null);

  const {data: vehicleParticipation} = useGetParticipationQuery({
    resourceId: EntityResourceIds.vehicle,
    recordId: vehicleId,
  });

  const {data: vehicle} = useGetVehicleQuery({vehicleId});

  const [hasRemoveVehiclePhotoBackgroundPermission] = usePermissions({
    permissionKeys: ['removeVehiclePhotoBackground'],
    scopes: {
      removeVehiclePhotoBackground: {
        participation: vehicleParticipation,
        branchId: vehicle?.branchId,
      },
    },
  });

  const {data: watermarkList} = useGetWatermarkListQuery();

  const {
    getLightboxProps,
    openWatermarkPreview,
    isLoading: isWatermarkPreviewLoading,
  } = useVehicleAlbumWatermarkPreview();

  const [hasBackgroundRemovalErrorFileOperationIds, setHasBackgroundRemovalErrorFileOperationIds] =
    useState<Set<string>>(new Set());
  const [hasBackgroundRemovalInProgressAlbumsIds, setHasBackgroundRemovalInProgressAlbumsIds] =
    useState<Set<string>>(new Set());

  const [getBackgroundNotification] = useLazyGetSentBackgroundNotificationQuery();
  const [getToastNotification] = useLazyGetSentToastNotificationQuery();

  const [globallySelectedImagesIds, setGloballySelectedImagesIds] = useState<
    Record<string, string[]>
  >({});

  useSubscription(SEND_NOTIFICATION_SUBSCRIPTION, {
    onData: ({data: subscription}) => {
      const toastNotificationId: string | null =
        subscription.data?.onSendNotification?.toastNotificationId;
      const backgroundNotificationId: string | null =
        subscription.data?.onSendNotification?.backgroundNotificationId;

      if (isNotNil(toastNotificationId)) {
        getToastNotification({id: toastNotificationId})
          .unwrap()
          .then((notification) => {
            if (notification.type === notificationTypes.SALE_VEHICLE_BACKGROUND_REMOVAL_FINISHED) {
              dispatch(
                saleVehiclePhotoApi.util.invalidateTags([
                  {type: 'SaleVehicleAlbumPhotos'},
                  {type: 'Vehicle'},
                  {type: 'SaleVehicleFlags'},
                ])
              );
            }
          })
          .catch(handleApiError);
      }

      if (isNotNil(backgroundNotificationId)) {
        getBackgroundNotification({id: backgroundNotificationId})
          .unwrap()
          .then((notification) => {
            const notificationText = getNotificationText(notification);
            if (isNotNil(notificationText)) {
              showNotification.success(notificationText);
            }
            dispatch(
              saleVehiclePhotoApi.util.invalidateTags([
                {type: 'SaleVehicleAlbumPhotos'},
                {type: 'Vehicle'},
                {type: 'SaleVehicleFlags'},
              ])
            );
          })
          .catch(handleApiError);
      }
    },
  });

  const {
    data: albums,
    isLoading: areAlbumsLoading,
    isError: hasAlbumsError,
  } = useListSaleVehicleAlbumsByVehicleQuery({
    vehicleId,
  });

  const [activeAlbums, previousAlbums] = useMemo(
    () => partition((album) => isTrue(album.active), albums ?? []),
    [albums]
  );

  const previousAlbumIds = useMemo(() => previousAlbums.map((album) => album.id), [previousAlbums]);

  const previousAlbumsGroupedByDate = useMemo(
    () => groupBy((album) => album.saleVehicleCreatedAt, previousAlbums),
    [previousAlbums]
  );

  const [copySaleVehiclePhoto] = useCopySaleVehiclePhotoMutation();
  const [updateSaleVehicleAlbum] = useUpdateSaleVehiclePhotoAlbumMutation();

  const copyImage = ({
    fileIds,
    sourceAlbumId,
    destinationAlbumId,
    saleVehicleId,
  }: {
    fileIds: string[];
    saleVehicleId: string;
    sourceAlbumId: string;
    destinationAlbumId: string;
    position?: number;
  }) => {
    if (isNonEmptyArray(fileIds)) {
      return copySaleVehiclePhoto({
        saleVehicleId,
        saleVehiclePhotoCopyRequestBody: {
          photos: fileIds.map((fileId, index) => ({
            fileId,
            position: index,
          })),
          sourceAlbum: sourceAlbumId,
          destinationAlbum: destinationAlbumId,
        },
      })
        .unwrap()
        .catch((error: Error) => {
          handleApiError(error);
        });
    }
  };

  const moveImage = ({
    fileIds,
    sourceAlbumId,
    destinationAlbumId,
  }: {
    fileIds: string[];
    sourceAlbumId: string;
    destinationAlbumId: string;
  }) => {
    if (isNonEmptyArray(fileIds)) {
      return updateSaleVehicleAlbum({
        saleVehiclePhotoUpdateAlbumRequestBody: {
          photos: fileIds.map((fileId, index) => ({
            fileId,
            position: index,
          })),
          sourceAlbum: sourceAlbumId,
          destinationAlbum: destinationAlbumId,
        },
      })
        .unwrap()
        .catch((error: Error) => {
          handleApiError(error);
        });
    }
  };

  const buildContextMenu = (payload: {
    images: string[];
    sourceAlbumId: string;
    saleVehicleId: string;
    onSuccess?: () => void;
    onEventFired?: () => void;
    isSalesAlbum?: boolean;
  }) => (
    <>
      <DropdownSubmenu
        label={i18n.t('general.actions.copyTo')}
        prefix={<Icon value="file/folder" />}
        suffix={<Icon value="navigation/arrow_right" />}
      >
        {activeAlbums?.map((album) =>
          album.id === payload.sourceAlbumId ? null : (
            <DropdownItem
              key={`copy_${album.id}`}
              label={album.translation}
              onClick={() => {
                payload.onEventFired?.();
                copyImage({
                  fileIds: payload.images || [],
                  sourceAlbumId: payload.sourceAlbumId,
                  destinationAlbumId: album.id,
                  saleVehicleId: payload.saleVehicleId,
                  position: 0,
                })?.then(() => payload.onSuccess?.());
              }}
            />
          )
        )}
      </DropdownSubmenu>
      <DropdownSubmenu
        label={i18n.t('general.actions.moveTo')}
        prefix={<Icon value="file/folder" />}
        suffix={<Icon value="navigation/arrow_right" />}
      >
        {activeAlbums?.map((album) =>
          album.id === payload.sourceAlbumId ? null : (
            <DropdownItem
              key={`copy_${album.id}`}
              label={album.translation}
              onClick={() => {
                payload.onEventFired?.();
                if (previousAlbumIds.includes(payload.sourceAlbumId)) {
                  return copyImage({
                    fileIds: payload.images || [],
                    sourceAlbumId: payload.sourceAlbumId,
                    destinationAlbumId: album.id,
                    saleVehicleId: payload.saleVehicleId,
                    position: 0,
                  })?.then(() => payload.onSuccess?.());
                }
                return moveImage({
                  fileIds: payload.images || [],
                  sourceAlbumId: payload.sourceAlbumId,
                  destinationAlbumId: album.id,
                })?.then(() => payload.onSuccess?.());
              }}
            />
          )
        )}
      </DropdownSubmenu>
      <Show
        when={payload.images.length === 1 && payload.isSalesAlbum && isNotNilOrEmpty(watermarkList)}
      >
        <DropdownItem
          label={i18n.t('entity.vehicle.labels.showWithWatermark')}
          prefix={<Icon value="AV/branding_watermark" />}
          onClick={() => openWatermarkPreview(payload.images[0])}
        />
      </Show>
    </>
  );

  const handleBackgroundRemoval = useCallback(
    (albumId: string, isInProgress: boolean, hasErrorOperationIds?: string[]) => {
      if (hasErrorOperationIds?.length) {
        setHasBackgroundRemovalErrorFileOperationIds(
          (prev) => new Set([...prev, ...hasErrorOperationIds])
        );
      }
      if (isInProgress) {
        setHasBackgroundRemovalInProgressAlbumsIds((prev) => new Set([...prev, albumId]));
      } else {
        setHasBackgroundRemovalInProgressAlbumsIds((prev) => {
          const newInProgressAlbums = new Set(prev);
          newInProgressAlbums.delete(albumId);
          return newInProgressAlbums;
        });
      }
    },
    []
  );

  const handleUpdateGlobalImageSelection = (albumId: string, ids: string[]) => {
    setGloballySelectedImagesIds((prev) => ({
      ...prev,
      [albumId]: ids,
    }));
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  return (
    <>
      <VStack spacing={4}>
        <Show
          when={
            (isFeatureEnabled(featureFlags.SALES_BACKGROUND_REMOVAL) ||
              hasRemoveVehiclePhotoBackgroundPermission) &&
            isFeatureEnabled(featureFlags.SALES_BGR_BANNER)
          }
        >
          <BannerBgRemoval />
        </Show>
        <BackgroundRemovalStatus
          isInProgress={hasBackgroundRemovalInProgressAlbumsIds.size > 0}
          failedOperationsIds={Array.from(hasBackgroundRemovalErrorFileOperationIds)}
          onAcknowledgeOperation={() => {
            setHasBackgroundRemovalErrorFileOperationIds(new Set());
          }}
        />
        <DataStatus isLoading={areAlbumsLoading} isError={hasAlbumsError}>
          <DndContext
            sensors={sensors}
            onDragStart={(event) => {
              const {url} = parseDndItemId(event.active.id);
              if (url) {
                setActiveDnDUrl(url);
              }
            }}
          >
            {activeAlbums?.map((album) => (
              <Album
                key={album.id}
                isActiveSaleVehicle
                data-testid={testIds.vehicles.photos(
                  `${album.name === VehicleAlbums.PHOTOS_CAR_PURCHASE ? 'purchase' : 'sales'}-album`
                )}
                saleVehicleId={album.saleVehicleId}
                vehicleId={vehicleId}
                saleVehicleAlbumId={album.id}
                title={album.translation}
                canUpload
                canDelete={album.name === VehicleAlbums.PHOTOS_CUSTOM}
                showPromotionalPhotos={album.name === VehicleAlbums.PHOTOS_CAR_SALES}
                buildAlbumActionsContextMenu={(payload) =>
                  buildContextMenu({
                    ...payload,
                    sourceAlbumId: album.id,
                    saleVehicleId: album.saleVehicleId,
                    isSalesAlbum: album.name === VehicleAlbums.PHOTOS_CAR_SALES,
                  })
                }
                globallySelectedImages={mergeValues(globallySelectedImagesIds)}
                inactiveAlbumIds={previousAlbumIds}
                onBackgroundRemoval={handleBackgroundRemoval}
                onUpdateGlobalImageSelection={(ids) =>
                  handleUpdateGlobalImageSelection(album.id, ids)
                }
                canApplyWatermark={album.name === VehicleAlbums.PHOTOS_CAR_SALES}
              />
            ))}
            <Show when={previousAlbums?.length > 0}>
              <Heading size={3}>{i18n.t('entity.vehicle.labels.previousPhotos')}</Heading>
              <Text size="xSmall">{i18n.t('entity.vehicle.labels.previousPhotosExplainer')}</Text>
              {Object.entries(previousAlbumsGroupedByDate).map(([date, albums]) => (
                <div key={date}>
                  <Heading size={4}>
                    {i18n.t(`entity.vehicle.albums.createdAt`, {
                      date: formatDate('dateShort', parseDate(date)),
                    })}
                  </Heading>
                  {albums?.map((album) => (
                    <Box key={album.id} paddingTop={4}>
                      <Album
                        data-testid={testIds.vehicles.photos(
                          `${album.name === 'PHOTOS_CAR_PURCHASE' ? 'purchase' : 'sales'}-previous-album`
                        )}
                        vehicleId={vehicleId}
                        isActiveSaleVehicle={false}
                        saleVehicleId={album.saleVehicleId}
                        saleVehicleAlbumId={album.id}
                        title={album.translation}
                        canUpload={false}
                        inactiveAlbumIds={previousAlbumIds}
                        buildAlbumActionsContextMenu={(payload) =>
                          buildContextMenu({
                            ...payload,
                            sourceAlbumId: album.id,
                            saleVehicleId: album.saleVehicleId,
                          })
                        }
                        onBackgroundRemoval={handleBackgroundRemoval}
                        onUpdateGlobalImageSelection={(ids) =>
                          handleUpdateGlobalImageSelection(album.id, ids)
                        }
                      />
                    </Box>
                  ))}
                </div>
              ))}
            </Show>
            <DragOverlay>
              <Show when={isNotNil(activeDnDUrl)}>
                <Box width={IMAGE_SIZE.width} height={IMAGE_SIZE.height}>
                  <AlbumImage id="sortable:dragOverlay" src={prepareAlbumImageUrl(activeDnDUrl!)} />
                </Box>
              </Show>
            </DragOverlay>
          </DndContext>
          <Lightbox
            data-testid={testIds.vehicles.photos('watermarkPreview')}
            {...getLightboxProps()}
          />
        </DataStatus>
      </VStack>
      <Show when={isWatermarkPreviewLoading}>
        <Spinner variant="overlay" />
      </Show>
    </>
  );
}

const mergeValues = pipe(values, flatten);
