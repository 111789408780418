import {getHours, getMinutes, isValid} from 'date-fns';

import {SLOT_HEIGHT_IN_PIXELS} from '../constants/slotHeight';

/**
 * Calculates the vertical position (in pixels) for a given time
 * @param date The date to calculate position for
 * @returns Position in pixels from top
 */
export function calculateTimePosition(date: Date): number {
  if (!isValid(date)) {
    throw new Error('Invalid date provided');
  }

  const hours = getHours(date);
  const minutes = getMinutes(date);

  if (hours === 0 && minutes === 0) {
    return 0;
  }

  return hours * SLOT_HEIGHT_IN_PIXELS + (minutes * SLOT_HEIGHT_IN_PIXELS) / 60;
}
