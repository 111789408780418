function roundUpCurrency(amount: number) {
  const cents = amount * 100;
  return Math.ceil(cents) / 100;
}
/**
 * Calculates the expected financing cost for a vehicle.
 *
 * Formula: Interest = Financed Amount × (Interest Rate / 100 × Days in Storage / 365)
 *
 * @param financedValue - The amount being financed (e.g., vehicle purchase price without VAT)
 * @param interestRate - Annual interest rate in percentage (e.g., 5 for 5%)
 * @param daysInStorage - Number of days the vehicle is financed
 * @returns The calculated financing cost in the same currency as the financed value
 * @example
 * // Calculate financing cost for 500,000 Kč at 5% for 90 days
 * const financingCost = calculateFinancingCost(500000, 5, 90);
 * // Result: 6164.38 Kč
 */
export function calculateFinancingCost(
  financedValue: number,
  interestRate: number,
  daysInStorage: number
): number {
  if (isNaN(financedValue) || isNaN(interestRate) || isNaN(daysInStorage)) {
    return 0;
  }
  if (financedValue <= 0 || interestRate <= 0 || daysInStorage <= 0) {
    return 0;
  }

  const interestRateDecimal = interestRate / 100;

  const dailyRateFactor = daysInStorage / 365;

  const interestAmount = financedValue * interestRateDecimal * dailyRateFactor;

  return roundUpCurrency(interestAmount);
}
