import {Card} from 'platform/components';
import {Box, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {RefObject, useCallback} from 'react';

import {mergeAll, path} from 'ramda';
import {isArray, isNilOrEmpty} from 'ramda-adjunct';

import {RequestItem} from '@dms/api/metadaWarehousePartsRequestReturnItem';
import {warehouseRoutes} from '@dms/routes';
import {catchUnhandledDataGridActions, logError} from '@dms/shared';

import {composePath, RequiredTestIdProps} from 'shared';

import {ActionCallback, DataGrid, DataGridRef, QueryFilterObject} from 'features/datagrid';

import {WAREHOUSE_GRID_CODES} from '../../../../../constants/warehouseGridCodes';

interface ServiceOrderReturnsMaterialReturnListProps extends RequiredTestIdProps {
  serviceOrderId: string;
  dataGridRef: RefObject<DataGridRef | null>;
  onAddToBasket: (selectedItems: RequestItem[]) => Promise<void>;
}

type ServiceOrderMaterialReturnItemRow = {
  id: string;
  articleId: {value: string};
  warehouseId: {value: string};
  availableForReturn: {value: number};
  unit: {value: {key: string}};
  priceUnitWithoutVat: {value: number};
  priceUnitWithVat: {value: number};
};

export function ServiceOrderReturnsMaterialReturnList(
  props: ServiceOrderReturnsMaterialReturnListProps
) {
  const actionCallback: ActionCallback = ({actionKey, rowData, refreshData, deselectAll}) => {
    match(actionKey)
      .with('add', () => {
        const castedRowData = (
          isArray(rowData) ? rowData : [rowData]
        ) as ServiceOrderMaterialReturnItemRow[];

        const requestPayload: RequestItem[] = [];

        castedRowData.forEach((row) => {
          requestPayload.push({
            originEntityId: row.id,
            articleId: row.articleId.value,
            quantity: row.availableForReturn.value,
            handlingUnit: row.unit.value.key,
            priceUnitWithoutVat: row.priceUnitWithoutVat.value,
            priceUnitWithVat: row.priceUnitWithVat.value,
          });
        });

        return props.onAddToBasket(requestPayload).then(deselectAll).then(refreshData);
      })
      .with('details', () => {
        const articleId = path(['articleId', 'value'], rowData) as string;
        const warehouseId = path(['warehouseId', 'value'], rowData) as string;

        if (isNilOrEmpty(articleId)) {
          return logError('articleId is not defined');
        }

        if (isNilOrEmpty(warehouseId)) {
          return logError('warehouseId is not defined');
        }

        return window.open(
          composePath(warehouseRoutes.articleDetailOverview, {
            params: {
              id: articleId,
              warehouseId,
            },
          }),
          '_blank'
        );
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {serviceOrderId: props.serviceOrderId}]),
    [props.serviceOrderId]
  );

  return (
    <Card isFullHeight data-testid={props['data-testid']}>
      <VStack spacing={4} height="100%">
        <Box flex={1}>
          <DataGrid
            ref={props.dataGridRef}
            gridCode={WAREHOUSE_GRID_CODES.warehouseServiceOrderReturnSelectItems}
            actionCallback={actionCallback}
            queryModifier={queryModifier}
            data-testid={WAREHOUSE_GRID_CODES.warehouseServiceOrderReturnSelectItems}
          />
        </Box>
      </VStack>
    </Card>
  );
}
