import {
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Separator,
  Tooltip,
} from 'platform/components';
import {Box, Hide, HStack, Space, Text, VStack} from 'platform/foundation';
import {boolean, object} from 'yup';

import {head} from 'ramda';

import {
  GetLabourCatalogApiResponse,
  usePatchLabourCatalogSubcontractMutation,
  usePostLabourCatalogSubcontractMutation,
} from '@dms/api/metadaWorkshopLabourCatalog';
import {usePostServiceItemWorkBasketWithSubcontractMutation} from '@dms/api/metadaWorkshopWorkBasket';
import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId, yupNumber, yupString} from 'shared';

import {DEFAULT_CURRENCY} from '../../../constants/currency';
import {useGetVatRatesOptions} from '../../../hooks/useGetVatRatesOptions';
import {useWorkTypeOptions} from '../../../hooks/useWorkTypeOptions';
import {handleApiError} from '../../../utils/handleApiError';
import {TreeFolderPath} from '../../TreeFolderPath/TreeFolderPath';
import {CatalogueFolder} from '../types/catalogueFolder';
import {FreePositionData} from '../types/freePositionData';

type SubcontractFormType = {
  name: string;
  number: string;
  labourCatalogCategoryId: string;
  workType: string;
  purchaseUnitPrice: number;
  sellingUnitPrice: number;
  vatType: string;
  isDoNotApplyDiscount: boolean;
};

interface SubcontractFormProps extends RequiredTestIdProps {
  initalValues: Partial<GetLabourCatalogApiResponse>;
  freePositionData?: FreePositionData;
  editedData?: GetLabourCatalogApiResponse;
  isFreePosition?: boolean;
  onClose: () => void;
  onSubmitted: (itemId?: string | null) => Promise<void>;
  catalogueFolder?: CatalogueFolder;
  onCatalogueFolderChange: VoidFunction;
  onInitialValuesChange: (values: Partial<GetLabourCatalogApiResponse>) => void;
  canEditCatalogueItem: boolean;
}

export function SubcontractForm(props: SubcontractFormProps) {
  const [postLabourCatalogSubcontract, {isLoading: isPostLabourCatalogSubcontractLoading}] =
    usePostLabourCatalogSubcontractMutation();
  const [patchLabourCatalogSubcontract, {isLoading: isPatchLabourCatalogSubcontractLoading}] =
    usePatchLabourCatalogSubcontractMutation();
  const [
    postServiceItemWorkBasketWithSubcontract,
    {isLoading: isPostServiceItemWorkBasketWithSubcontractLoading},
  ] = usePostServiceItemWorkBasketWithSubcontractMutation();
  const {getOptionsWithSelectedValue, defaultWorkTypeId} = useWorkTypeOptions();

  const [vatOptions] = useGetVatRatesOptions({fullLabel: true});

  const workTypeOptions = getOptionsWithSelectedValue(props.editedData?.workType);

  const initialValues = props.editedData ?? props.initalValues;

  const handleSubmit: FormSubmitHandler<SubcontractFormType> = async (data) => {
    const body = {
      ...data,
      labourCatalogCategoryId:
        props.catalogueFolder?.contextId ?? props.editedData?.labourCatalogCategoryId,
    };

    if (props.editedData) {
      await patchLabourCatalogSubcontract({labourCatalogItemId: props.editedData.id ?? '', body})
        .unwrap()
        .catch(handleApiError);
      await props.onSubmitted?.(props.editedData.id);
      return;
    }

    if (props.isFreePosition && props.freePositionData) {
      await postServiceItemWorkBasketWithSubcontract({
        serviceCaseId: props.freePositionData.serviceCaseId,
        serviceJobId: props.freePositionData.serviceJobId,
        serviceOrderId: props.freePositionData.serviceOrderId,
        body: {...data, unit: null, currency: DEFAULT_CURRENCY, isUnitPriceWithVat: false},
      })
        .unwrap()
        .then(() => {
          props.onSubmitted?.();
          props.onClose();
        })
        .catch(handleApiError);
      return;
    }

    const response = await postLabourCatalogSubcontract({body}).unwrap().catch(handleApiError);
    await props.onSubmitted?.(response?.id);
  };

  const defaultValues: Partial<SubcontractFormType> = {
    name: initialValues?.name ?? undefined,
    number: initialValues?.number ?? undefined,
    workType: initialValues?.workType ?? defaultWorkTypeId ?? undefined,
    purchaseUnitPrice: initialValues?.purchaseUnitPrice ?? undefined,
    sellingUnitPrice: initialValues?.sellingUnitPrice ?? undefined,
    vatType: initialValues?.vatType ?? head(vatOptions)?.value,
    isDoNotApplyDiscount: initialValues?.isDoNotApplyDiscount ?? undefined,
  };

  const isSubmitLoading =
    isPostLabourCatalogSubcontractLoading ||
    isPatchLabourCatalogSubcontractLoading ||
    isPostServiceItemWorkBasketWithSubcontractLoading;

  return (
    <Form<SubcontractFormType>
      schema={SubcontractFormSchema}
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
    >
      {(control, formApi) => {
        formApi.watch((data) => props.onInitialValuesChange(data));

        return (
          <VStack spacing={4}>
            <FormField
              control={control}
              type="text"
              name="name"
              label={i18n.t('general.labels.name')}
              isRequired
              data-testid={suffixTestId('name', props)}
            />
            <HStack spacing={4}>
              <Box flex={1}>
                <FormField
                  control={control}
                  type="text"
                  name="number"
                  label={i18n.t('general.labels.number')}
                  isRequired
                  data-testid={suffixTestId('number', props)}
                />
              </Box>
              <Box flex={1}>
                <FormField
                  control={control}
                  type="choice"
                  name="workType"
                  options={workTypeOptions}
                  label={i18n.t('entity.addWork.lables.workCategory')}
                  placeholder={i18n.t('general.labels.select')}
                  isRequired
                  menuInPortal
                  data-testid={suffixTestId('workType', props)}
                />
              </Box>
            </HStack>
            <Hide when={props.isFreePosition}>
              <TreeFolderPath
                leafId={props.catalogueFolder?.id ?? props.editedData?.treeFolder?.id}
                onEdit={props.onCatalogueFolderChange}
                data-testid={suffixTestId('categoryPath', props)}
              />
            </Hide>
            <Separator spacing={0} />
            <HStack spacing={4}>
              <Box flex={1}>
                <FormField
                  control={control}
                  type="number"
                  name="purchaseUnitPrice"
                  label={i18n.t('entity.addWork.lables.purchasePricePerUnit')}
                  isRequired
                  data-testid={suffixTestId('purchaseUnitPrice', props)}
                />
              </Box>
              <Box flex={1}>
                <FormField
                  control={control}
                  type="number"
                  name="sellingUnitPrice"
                  label={i18n.t('entity.addWork.lables.sellingPricePerUnit')}
                  isRequired
                  data-testid={suffixTestId('sellingUnitPrice', props)}
                />
              </Box>
            </HStack>
            <HStack spacing={4}>
              <Box flex={1}>
                <FormField
                  control={control}
                  type="choice"
                  name="vatType"
                  options={vatOptions}
                  label={i18n.t('entity.addWork.lables.vat')}
                  placeholder={i18n.t('general.labels.select')}
                  isRequired
                  menuInPortal
                  data-testid={suffixTestId('vatType', props)}
                />
              </Box>
              <Space fillAvailable />
            </HStack>
            <Separator spacing={0} />
            <HStack align="flex-start">
              <Box paddingRight={4}>
                <FormField
                  control={control}
                  type="checkbox"
                  name="isDoNotApplyDiscount"
                  data-testid={suffixTestId('isDoNotApplyDiscount', props)}
                />
              </Box>
              <Text size="small">{i18n.t('entity.addWork.lables.dontApplyDiscount')}</Text>
            </HStack>
            <ButtonGroup align="right">
              <Button
                title={i18n.t('general.actions.discard')}
                variant="secondary"
                onClick={props.onClose}
                data-testid={suffixTestId('discard', props)}
              />
              <Tooltip
                label={i18n.t('general.labels.noPermission')}
                isDisabled={props.canEditCatalogueItem}
              >
                <FormButton
                  control={control}
                  type="submit"
                  title={
                    props.editedData
                      ? i18n.t('general.actions.save')
                      : i18n.t('general.actions.create')
                  }
                  isLoading={isSubmitLoading}
                  isDisabled={!props.canEditCatalogueItem}
                  data-testid={suffixTestId('create', props)}
                />
              </Tooltip>
            </ButtonGroup>
          </VStack>
        );
      }}
    </Form>
  );
}

const SubcontractFormSchema = object({
  name: yupString.required(),
  number: yupString.required(),
  workType: yupString.required(),
  purchaseUnitPrice: yupNumber.required(),
  sellingUnitPrice: yupNumber.required(),
  vatType: yupString.required(),
  isDoNotApplyDiscount: boolean().nullable(),
});
