import {Dispatch, Reducer} from 'react';

import {without} from 'ramda';

import {SourcingVehicleListItemResponseBody} from '@dms/api/sourcing';

import {SourcingTableName} from '../../types/SourcingTableName';
import {keyExtractor} from '../../utils/keyExtractor';

export interface SourcingTableState {
  tableName: SourcingTableName;
  orderBy: string;
  page: number | null;
  checkedRows: SourcingVehicleListItemResponseBody[];
  selectedRow: SourcingVehicleListItemResponseBody | null;
  expandedRowIds: string[];
}

type Action<Row> =
  | {type: 'sort'; column: string}
  | {type: 'changePage'; page: number}
  | {type: 'nextPage'}
  | {type: 'changeCheckedRows'; rows: Row[]}
  | {type: 'selectRow'; row: Row}
  | {type: 'expandRow'; row: Row; isExpanded: boolean};

export type SourcingTableDispatch = Dispatch<Action<any>>;

export const reducer: Reducer<SourcingTableState, Action<any>> = (state, action) => {
  switch (action.type) {
    case 'sort':
      return {
        ...state,
        orderBy: action.column,
        page: 1,
      };
    case 'changePage':
      return {
        ...state,
        page: action.page,
      };
    case 'nextPage':
      return {
        ...state,
        page: (state.page ?? 1) + 1,
      };
    case 'selectRow':
      return {
        ...state,
        selectedRow: action.row,
      };
    case 'changeCheckedRows':
      return {
        ...state,
        checkedRows: action.rows,
      };
    case 'expandRow':
      return {
        ...state,
        expandedRowIds: action.isExpanded
          ? [...state.expandedRowIds, keyExtractor(action.row)]
          : without([keyExtractor(action.row)], state.expandedRowIds),
      };
    default:
      return state;
  }
};
