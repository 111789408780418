export type GtmEvent = {
  event: 'button_click' | 'drag_and_drop';
  event_id: string;
  value?: unknown;
  new_value?: unknown;
  previous_value?: unknown;
};

/**
 * Pushes an event to the Google Tag Manager (GTM) dataLayer.
 *
 * Google Tag Manager (GTM) and Google Analytics 4 (GA4) do not handle `undefined` values well because:
 *  - `undefined` values are **not stored** in the `dataLayer`, meaning values from previous events may persist.
 *  - GA4 does not support `undefined`, which can lead to incomplete or misleading analytics reports.
 *  - Explicitly setting values to `null` ensures that GTM recognizes missing data properly.
 *
 * To prevent these issues, all optional values (`value`, `new_value`, `previous_value`) are replaced
 * with `null` if they are `undefined`.
 *
 * @param eventData - The event data to push to the dataLayer.
 */
export function pushGtmEvent(eventData: GtmEvent) {
  const dataLayer = {
    event: eventData.event,
    event_id: eventData.event_id,
    value: eventData?.value ?? null,
    new_value: eventData?.new_value ?? null,
    previous_value: eventData?.previous_value ?? null,
  };

  if (window && window.dataLayer) {
    window.dataLayer.push(dataLayer);
  }
}
