import {Card, Action, DataStatus} from 'platform/components';
import {Show, VStack} from 'platform/foundation';

import {GetVehicleCustomerApiResponse} from '@dms/api/metadaVehicleCustomer';
import {FullVehicleResponseBody} from '@dms/api/shared';
import i18n from '@dms/i18n';

import {buildArray, suffixTestId, TestIdProps} from 'shared';

import {SuggestedVehicle} from './SuggestedVehicle';

interface SuggestedVehiclesProps extends TestIdProps {
  selectedVehicle: FullVehicleResponseBody | null;
  suggestedVehicles: GetVehicleCustomerApiResponse;
  onVehicleChange: (vehicleId: FullVehicleResponseBody | null) => void;
  onAddNew: () => void;
  isLoading: boolean;
  isError: boolean;
  allowNoVehicle?: boolean;
}

export function SuggestedVehicles(props: SuggestedVehiclesProps) {
  return (
    <Card
      actions={buildArray<Action>().add({
        type: 'button',
        variant: 'link',
        leftIcon: 'content/add_circle',
        title: i18n.t('general.actions.addNew'),
        onClick: props.onAddNew,
      })}
      title={i18n.t('entity.vehicle.labels.vehicle')}
      data-testid={suffixTestId('header', props)}
    >
      <DataStatus isLoading={props.isLoading} isError={props.isError} minHeight={80}>
        <VStack spacing={4}>
          <Show when={props.allowNoVehicle}>
            <Card
              variant="inlineWhite"
              title={i18n.t('page.workshop.placeholders.noVehicle')}
              control={{
                type: 'radio',
                value: props.selectedVehicle === null,
                onChange: () => props.onVehicleChange(null),
              }}
            />
          </Show>
          {props.suggestedVehicles?.map(
            (suggested) =>
              suggested?.vehicleId && (
                <SuggestedVehicle
                  key={suggested.id}
                  type={suggested?.customerType}
                  vehicleId={suggested.vehicleId}
                  isSelected={props.selectedVehicle?.id === suggested?.vehicleId}
                  onSelect={props.onVehicleChange}
                  isExpanded={props.suggestedVehicles?.length === 1}
                />
              )
          )}
        </VStack>
      </DataStatus>
    </Card>
  );
}
