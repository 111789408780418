import {FormControl, FormField} from 'platform/components';
import {Grid, Heading, VStack} from 'platform/foundation';

import {
  BaseArticle,
  basePriceSourceObject,
  salesPriceCalculationObject,
} from '@dms/api/metadaWarehouseArticle';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';

interface PricesTabSettingsFormProps {
  control: FormControl<BaseArticle>;
}

export function PricesTabSettingsForm(props: PricesTabSettingsFormProps) {
  return (
    <>
      <VStack height="100%" spacing={3}>
        <Heading size={4}>{i18n.t('general.labels.settings')}</Heading>
        <Grid columns={4} spacing={8}>
          <VStack spacing={3}>
            <FormField
              control={props.control}
              isDisabled
              type="currency"
              name="pricesSettings.avgPurchasePriceWithoutVat"
              label={i18n.t('warehouse.labels.avgPurchasePriceWithoutVat')}
              data-testid={testIds.warehouse.articleDetailOverview('avgPurchasePriceWithoutVat')}
            />
            <FormField
              control={props.control}
              isDisabled
              type="currency"
              name="pricesSettings.lastPurchasePriceWithoutVat"
              label={i18n.t('warehouse.labels.lastPurchasePriceWithoutVat')}
              data-testid={testIds.warehouse.articleDetailOverview('lastPurchasePriceWithoutVat')}
            />
            <FormField
              control={props.control}
              isDisabled
              type="currency"
              name="pricesSettings.recommendedPurchasePriceWithoutVat"
              label={i18n.t('warehouse.labels.recommendedPurchasePriceWithoutVat')}
              data-testid={testIds.warehouse.articleDetailOverview(
                'recommendedPurchasePriceWithoutVat'
              )}
            />
          </VStack>
          <VStack spacing={3}>
            <FormField
              control={props.control}
              isDisabled
              type="currency"
              name="pricesSettings.avgPurchasePriceWithVat"
              label={i18n.t('warehouse.labels.avgPurchasePriceWithVat')}
              data-testid={testIds.warehouse.articleDetailOverview('avgPurchasePriceWithVat')}
            />
            <FormField
              control={props.control}
              isDisabled
              type="currency"
              name="pricesSettings.lastPurchasePriceWithVat"
              label={i18n.t('warehouse.labels.lastPurchasePriceWithVat')}
              data-testid={testIds.warehouse.articleDetailOverview('lastPurchasePriceWithVat')}
            />
            <FormField
              control={props.control}
              isDisabled
              type="currency"
              name="pricesSettings.recommendedPurchasePriceWithVat"
              label={i18n.t('warehouse.labels.recommendedPurchasePriceWithVat')}
              data-testid={testIds.warehouse.articleDetailOverview(
                'recommendedPurchasePriceWithVat'
              )}
            />
          </VStack>
          <VStack spacing={3}>
            <FormField
              control={props.control}
              type="radio"
              direction="column"
              spacing={2}
              name="pricesSettings.salesPriceCalculation"
              label={i18n.t('warehouse.labels.salesPriceCalculation')}
              options={salesPriceCalculationOptions}
              data-testid={testIds.warehouse.articleDetailOverview('salesPriceCalculation')}
            />
            <FormField
              control={props.control}
              type="switch"
              name="pricesSettings.unitSalesPriceWithVat"
              label={i18n.t('warehouse.labels.unitSalesPriceWithVat')}
              data-testid={testIds.warehouse.articleDetailOverview('unitSalesPriceWithVat')}
            />
          </VStack>
          <VStack spacing={3}>
            <FormField
              control={props.control}
              type="radio"
              direction="column"
              spacing={2}
              name="pricesSettings.basePriceSource"
              label={i18n.t('warehouse.labels.basePriceSource')}
              options={basePriceCalculationOptions}
              data-testid={testIds.warehouse.articleDetailOverview('basePriceSource')}
            />
          </VStack>
        </Grid>
      </VStack>
    </>
  );
}

const salesPriceCalculationOptions = [
  {
    label: i18n.t('general.labels.salesPriceCalculationMargin'),
    value: salesPriceCalculationObject.MARGIN_ON_BASE,
  },
  {
    label: i18n.t('general.labels.salesPriceCalculationMarkup'),
    value: salesPriceCalculationObject.MARKUP_ON_BASE,
  },
  {
    label: i18n.t('general.labels.salesPriceCalculationProfitOnBase'),
    value: salesPriceCalculationObject.PROFIT_ON_BASE,
  },
  {
    label: i18n.t('general.labels.salesPriceCalculationManual'),
    value: salesPriceCalculationObject.MANUAL,
  },
  {
    label: i18n.t('general.labels.salesPriceCalculationRecommendedPrice'),
    value: salesPriceCalculationObject.RECOMMENDED_PRICE,
  },
  {
    label: i18n.t('general.labels.salesPriceCalculationMarketingCode'),
    value: salesPriceCalculationObject.MARKETING_CODE,
  },
];

const basePriceCalculationOptions = [
  {
    label: i18n.t('general.labels.basePriceCalculationAveragePurchasePrice'),
    value: basePriceSourceObject.AVG_PURCHASE,
  },
  {
    label: i18n.t('general.labels.basePriceCalculationLastPurchasePrice'),
    value: basePriceSourceObject.LAST_PURCHASE,
  },
  {
    label: i18n.t('general.labels.basePriceCalculationLastRecommendedPrice'),
    value: basePriceSourceObject.RECOMMENDED,
  },
  {
    label: i18n.t('general.labels.basePriceCalculationManual'),
    value: basePriceSourceObject.MANUAL,
  },
];
