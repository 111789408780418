import {Flag} from 'platform/components';
import {HStack} from 'platform/foundation';

import {isNotNil} from 'ramda';
import {isFalse, isTrue} from 'ramda-adjunct';

import {MinipurchaseFilteredSpecifications} from '@dms/api/interest';
import i18n from '@dms/i18n';

import {RequiredTestIdProps, sortByAsciiNumberLast, suffixTestId} from 'shared';

import {useRequestSpecification} from '../hooks/useRequestSpecification';

interface RequestSpecificationProps extends RequiredTestIdProps {
  filteredSpecifications: MinipurchaseFilteredSpecifications;
}

export function RequestSpecification(props: RequestSpecificationProps) {
  const {
    type,
    make,
    models,
    transmissions,
    cubicCapacity,
    power,
    mileage,
    price,
    year,
    fuelTypes,
    drives,
    features,
    bodyStyles,
    bodyColors,
    vatDeductible,
  } = useRequestSpecification(props.filteredSpecifications);
  const sortedFeatures = features?.sort((a, b) => sortByAsciiNumberLast(a.label, b.label));

  return (
    <HStack spacing={2} wrap>
      {isNotNil(type) && (
        <Flag
          colorScheme="blue"
          label={type}
          data-testid={suffixTestId(`requestSpecification-type`, props)}
        />
      )}
      {make?.map((flag, index) => (
        <Flag
          key={flag.value}
          colorScheme="blue"
          label={flag.label}
          data-testid={suffixTestId(`requestSpecification-make-[${index}]`, props)}
        />
      ))}
      {models?.map((flag, index) => (
        <Flag
          key={flag.value}
          colorScheme="blue"
          label={flag.label}
          data-testid={suffixTestId(`requestSpecification-models-[${index}]`, props)}
        />
      ))}
      {bodyStyles?.map((flag, index) => (
        <Flag
          key={flag.value}
          colorScheme="blue"
          label={flag.label}
          data-testid={suffixTestId(`requestSpecification-bodyStyles-[${index}]`, props)}
        />
      ))}
      {bodyColors?.map((flag, index) => (
        <Flag
          key={flag.value}
          colorScheme="blue"
          label={flag.label}
          data-testid={suffixTestId(`requestSpecification-bodyColors-[${index}]`, props)}
        />
      ))}
      {fuelTypes?.map((flag, index) => (
        <Flag
          key={flag.value}
          colorScheme="blue"
          label={flag.label}
          data-testid={suffixTestId(`requestSpecification-fuelTypes-[${index}]`, props)}
        />
      ))}
      {transmissions?.map((flag, index) => (
        <Flag
          key={flag.value}
          colorScheme="blue"
          label={flag.label}
          data-testid={suffixTestId(`requestSpecification-transmissions-[${index}]`, props)}
        />
      ))}
      {drives?.map((flag, index) => (
        <Flag
          key={flag.value}
          colorScheme="blue"
          label={flag.label}
          data-testid={suffixTestId(`requestSpecification-drives-[${index}]`, props)}
        />
      ))}
      {isTrue(vatDeductible) && (
        <Flag
          colorScheme="blue"
          label={i18n.t('entity.vehicle.labels.VATDeductible')}
          data-testid={suffixTestId(`requestSpecification-vatDeductible`, props)}
        />
      )}
      {isFalse(vatDeductible) && (
        <Flag
          colorScheme="blue"
          label={i18n.t('entity.vehicle.labels.withoutVatDeductible')}
          data-testid={suffixTestId(`requestSpecification-vatDeductible`, props)}
        />
      )}
      {isNotNil(price) && (
        <Flag
          colorScheme="blue"
          label={price}
          data-testid={suffixTestId(`requestSpecification-price`, props)}
        />
      )}
      {isNotNil(year) && (
        <Flag
          colorScheme="blue"
          label={year}
          data-testid={suffixTestId(`requestSpecification-year`, props)}
        />
      )}
      {isNotNil(mileage) && (
        <Flag
          colorScheme="blue"
          label={mileage}
          data-testid={suffixTestId(`requestSpecification-mileage`, props)}
        />
      )}
      {isNotNil(power) && (
        <Flag
          colorScheme="blue"
          label={power}
          data-testid={suffixTestId(`requestSpecification-power`, props)}
        />
      )}
      {isNotNil(cubicCapacity) && (
        <Flag
          colorScheme="blue"
          label={cubicCapacity}
          data-testid={suffixTestId(`requestSpecification-cubicCapacity`, props)}
        />
      )}
      {sortedFeatures?.map((flag, index) => (
        <Flag
          key={flag.value}
          colorScheme="blue"
          label={flag.label}
          data-testid={suffixTestId(`requestSpecification-features-[${index}]`, props)}
        />
      ))}
    </HStack>
  );
}
