import {head} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  useGetSignableDocumentTransactionQuery,
  useGetTransactionQuery,
} from '@dms/api/digitalSignature';

import {useDocumentSelection} from '../contexts/DocumentSelectionProvider';

export const useCurrentTransaction = () => {
  const {signatureDocumentFileIds} = useDocumentSelection();
  const signatureFileId = head(signatureDocumentFileIds);

  const {currentData: signableDocument, isLoading: isLoadingDocument} =
    useGetSignableDocumentTransactionQuery(
      {fileId: signatureFileId ?? ''},
      {skip: isNilOrEmpty(signatureFileId), refetchOnMountOrArgChange: true}
    );

  const {
    currentData: transaction,
    isLoading: isLoadingTransaction,
    isError: isTransactionError,
  } = useGetTransactionQuery(
    {transactionId: signableDocument?.transactionId ?? ''},
    {skip: isNilOrEmpty(signableDocument?.transactionId), refetchOnMountOrArgChange: true}
  );

  return {
    transaction,
    isLoading: isLoadingDocument || isLoadingTransaction,
    isError: isTransactionError,
  };
};
