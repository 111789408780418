import {IconButton} from 'platform/components';
import {Center} from 'platform/foundation';

import {Link, useLocation} from 'react-router-dom';

import {MenuItemProps, UserSidebarIconSize} from '@dms/api/shared';
import {settingsRoutes} from '@dms/routes';

import {composePath, suffixTestId, TestIdProps} from 'shared';

import {HeaderButtonWrapper} from './HeaderButtonWrapper';
import {TopBarActionContainer} from './TopBarActionContainer';

interface SettingsProps extends TestIdProps {
  settingsModule: MenuItemProps | undefined;
  iconSize: UserSidebarIconSize;
}

export function Settings(props: SettingsProps) {
  const location = useLocation();

  if (!props.settingsModule) {
    return null;
  }

  return (
    <TopBarActionContainer>
      <Link
        to={composePath(props.settingsModule.path, {isSearchParamsPreserved: false})}
        data-testid={suffixTestId('settings-link', props)}
      >
        <Center width="100%" height="100%">
          <HeaderButtonWrapper $isOpen={location.pathname.includes(settingsRoutes.home)}>
            <IconButton
              aria-label="topBar"
              icon={props.settingsModule.layoutProps.icon || 'action/settings'}
              iconSize={props.iconSize}
              data-testid={suffixTestId('settings-button', props)}
            />
          </HeaderButtonWrapper>
        </Center>
      </Link>
    </TopBarActionContainer>
  );
}
