import {Card, openConfirmDialog, showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {mergeAll} from 'ramda';

import {useDeleteTaskMutation} from '@dms/api/taskManagement';
import i18n from '@dms/i18n';
import {handleApiError, Main} from '@dms/shared';

import {RequiredTestIdProps} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject} from 'features/datagrid';

import {openEditTaskFormDialog} from '../../utils/openEditTaskFormDialog';

type SupportedRelations = 'customer' | 'vehicle' | 'business-case' | 'interest';

interface TaskDataGridProps extends RequiredTestIdProps {
  gridCode: `${SupportedRelations}-task` | 'task';
  entityId: string;
}

export function TaskGridPage(props: TaskDataGridProps) {
  const [deleteTask] = useDeleteTaskMutation();

  const queryModifier = (filter: QueryFilterObject) =>
    mergeAll([filter, {relatedTo: props.entityId}]);

  const dataGridActionsHandler: ActionCallback = ({actionKey, rowId, refreshData}) => {
    match(actionKey)
      .with('detail', () => {
        if (Array.isArray(rowId)) {
          // This should never happen, but just in case
          throw new Error('Cannot view multiple tasks at once');
        }
        openEditTaskFormDialog({
          id: rowId,
          onEdit: refreshData,
          onDelete: refreshData,
          'data-testid': props['data-testid'],
        });
      })
      .with('edit', () => {
        if (Array.isArray(rowId)) {
          // This should never happen, but just in case
          throw new Error('Cannot edit multiple tasks at once');
        }
        openEditTaskFormDialog({
          id: rowId,
          onEdit: refreshData,
          onDelete: refreshData,
          'data-testid': props['data-testid'],
        });
      })
      .with('delete', () => {
        openConfirmDialog({
          onConfirm: () => {
            if (Array.isArray(rowId)) {
              // This should never happen, but just in case
              throw new Error('Cannot delete multiple tasks at once');
            }
            deleteTask({id: rowId})
              .unwrap()
              .then(() => {
                refreshData();
                showNotification.success(i18n.t('general.notifications.successfullyDeleted'));
              })
              .catch(handleApiError);
          },
        });
      });
  };
  return (
    <Main isFullHeight data-testid={props['data-testid']}>
      <Card title={i18n.t('entity.task.labels.tasks')}>
        <DataGrid
          data-testid={props['data-testid']}
          gridCode={props.gridCode}
          actionCallback={dataGridActionsHandler}
          autoHeight
          queryModifier={queryModifier}
          emptyState={{
            headline: i18n.t('general.labels.noData'),
          }}
        />
      </Card>
    </Main>
  );
}
