import {
  openDialog,
  closeCurrentDialog,
  openDeleteDialog,
  showNotification,
} from 'platform/components';
import {Box, Heading, HStack, Text} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useCallback} from 'react';

import {mergeAll, head, isNil, defaultTo, path} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {
  GetServiceOrderIssueNoteResponse,
  useBulkDeleteServiceOrderIssueNoteItemsMutation,
} from '@dms/api/metadaWarehouseServiceOrderIssueNote';
import i18n from '@dms/i18n';
import {EditMaterialItem, handleApiError, catchUnhandledDataGridActions} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject} from 'features/datagrid';

import {MISSING_EDITING_DETAILS_MESSAGE} from '../../../../../constants/missingEditingDetailsMessage';
import {WAREHOUSE_GRID_CODES} from '../../../../../constants/warehouseGridCodes';
import {useWarehouseParams} from '../../../../../hooks/useWarehouseParams';

interface ListOfItemTabProps extends RequiredTestIdProps {
  priceWithoutVat: string | null;
  priceWithVat: string | null;
  serviceOrderIssueNote?: GetServiceOrderIssueNoteResponse;
}

export function ListOfItemTab(props: ListOfItemTabProps) {
  const {serviceOrderIssueNoteId} = useWarehouseParams();
  const [deleteItems] = useBulkDeleteServiceOrderIssueNoteItemsMutation();

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {serviceOrderIssueNoteId}]),
    [serviceOrderIssueNoteId]
  );

  const actionCallback: ActionCallback = async ({actionKey, rowId, rowData, refreshData}) => {
    const id = isArray(rowId) ? head(rowId) : rowId;

    if (isNil(id)) {
      throw new Error('Row id is not defined');
    }

    await match(actionKey)
      .with('edit', () => {
        const serviceCaseId = defaultTo('', props.serviceOrderIssueNote?.serviceCaseId);
        const serviceOrderId = defaultTo('', props.serviceOrderIssueNote?.serviceOrderId);

        const itemName = path(['itemName', 'value'], rowData) as string;
        const serviceOrderJobItemId = path(['serviceOrderJobItemId', 'value'], rowData) as string;
        const serviceCaseJobId = path(['serviceCaseJobId', 'value'], rowData) as string;

        if (isNil(itemName) || isNil(serviceCaseJobId) || isNil(serviceOrderJobItemId)) {
          throw new Error(MISSING_EDITING_DETAILS_MESSAGE);
        }

        openDialog(
          <EditMaterialItem
            itemId={serviceOrderJobItemId}
            serviceCaseId={serviceCaseId}
            serviceOrderId={serviceOrderId}
            serviceJobId={serviceCaseJobId}
            onEdited={refreshData}
            onClose={closeCurrentDialog}
          />,
          {title: itemName, scrollBehavior: 'outside'}
        );
      })
      .with('delete', () => {
        const ids = isArray(rowId) ? rowId : [rowId];

        openDeleteDialog({
          onConfirm: async () =>
            await deleteItems({
              serviceOrderIssueNoteId,
              body: {serviceOrderIssueNoteItemIds: ids},
            })
              .unwrap()
              .then(() =>
                showNotification.success(i18n.t('general.notifications.successfullyDeleted'))
              )
              .then(refreshData)
              .catch(handleApiError),
          'data-testid': suffixTestId('deleteItems', props),
        });
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  const priceWithoutVat = `${props.priceWithoutVat} ${i18n.t('general.labels.w/oVat')}`;

  return (
    <Box height="100%">
      <Box height="95%">
        <DataGrid
          gridCode={WAREHOUSE_GRID_CODES.issueNoteServiceOrderAddedItems}
          actionCallback={actionCallback}
          queryModifier={queryModifier}
          data-testid="issueNoteServiceOrderAddedItemsDatagrid"
        />
      </Box>

      <HStack minHeight={15} align="center" justify="space-between">
        <Heading size={5}>{i18n.t('general.labels.totalPrice')}</Heading>
        <HStack spacing={2}>
          <Text size="small" color="tertiary">
            {priceWithoutVat}
          </Text>
          <Heading size={5}>{props.priceWithVat}</Heading>
        </HStack>
      </HStack>
    </Box>
  );
}
