import {Form, FormField, FormSubmitHandler, showNotification} from 'platform/components';
import {Box, HStack, VStack, Text} from 'platform/foundation';
import {object} from 'yup';

import {useParams} from 'react-router-dom';

import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetAuthorizationProfilesQuery} from '@dms/api/metadaAdminAuthorizationProfile';
import {useGetServiceOrderTypesQuery} from '@dms/api/metadaWorkshopServiceOrder';
import {
  useGetServiceOrderGroupByIdQuery,
  usePostServiceOrderGroupMutation,
  usePatchServiceOrderGroupMutation,
} from '@dms/api/metadaWorkshopServiceOrderGroup';
import {useGetSettingsServiceOrderVariantsQuery} from '@dms/api/metadaWorkshopServiceOrderVariant';
import {useGetTenantQuery} from '@dms/api/tenant';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError, EMPTY_PLACEHOLDER} from '@dms/shared';

import {getApiDateString, parseDate, PartialWithNull, useNavigate, yupString} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

type ServiceOrderGroupFormType = PartialWithNull<{
  name: string;
  code: string;
  validityFrom: Date;
  validityTo: Date;
  authorizationProfileIds: string[];
  serviceOrderTypeIds: string[];
  serviceOrderVariantIds: string[];
}>;

export function WorkshopServiceOrderGroupDetail() {
  const {id} = useParams();
  const navigate = useNavigate();
  const {data: tenant} = useGetTenantQuery();

  const isCreating = isNilOrEmpty(id);

  const {data, isLoading, isError} = useGetServiceOrderGroupByIdQuery(
    {serviceOrderGroupId: id as string},
    {skip: isCreating}
  );
  const {data: authorizationProfiles, isLoading: isAuthorizationProfilesLoading} =
    useGetAuthorizationProfilesQuery(
      {'x-tenant': tenant?.id as string},
      {
        skip: isNilOrEmpty(tenant),
        selectFromResult: (result) => ({
          ...result,
          data: result.data?.map((profile) => ({
            label: profile?.name,
            value: profile?.id,
          })),
        }),
      }
    );
  const {data: orderTypes, isLoading: isOrderTypesLoading} = useGetServiceOrderTypesQuery(
    undefined,
    {
      selectFromResult: (result) => ({
        ...result,
        data: result.data?.map((orderType) => ({
          label: orderType?.name,
          value: orderType?.id,
        })),
      }),
    }
  );
  const {data: orderVariants, isLoading: isOrderVariantsLoading} =
    useGetSettingsServiceOrderVariantsQuery();
  const [postServiceOrderGroupOperation] = usePostServiceOrderGroupMutation();
  const [patchServiceOrderGroupOperation] = usePatchServiceOrderGroupMutation();

  const handleSubmit: FormSubmitHandler<ServiceOrderGroupFormType> = (data) => {
    const body = {
      ...data,
      name: data.name ?? '',
      validityFrom: data.validityFrom ? getApiDateString(data.validityFrom) : null,
      validityTo: data.validityTo ? getApiDateString(data.validityTo) : null,
      authorizationProfileIds: data.authorizationProfileIds ?? [],
      serviceOrderTypeIds: data.serviceOrderTypeIds ?? [],
      serviceOrderVariantIds: data.serviceOrderVariantIds ?? [],
    };

    const request = isCreating
      ? postServiceOrderGroupOperation({
          body,
        })
      : patchServiceOrderGroupOperation({
          serviceOrderGroupId: id ?? '',
          body,
        });

    return request
      .unwrap()
      .then(() => showNotification.success())
      .then(() => navigate(settingsRoutes.workshopServiceOrderGroups))
      .catch(handleApiError);
  };

  return (
    <SettingsTemplate
      header={{
        title: isCreating
          ? i18n.t('page.settings.actions.newServiceOrderGroup')
          : (data?.name ?? EMPTY_PLACEHOLDER),
        breadcrumbs: [
          {
            label: i18n.t('page.settings.labels.serviceOrderGroups'),
            href: settingsRoutes.workshopServiceOrderGroups,
          },
        ],
      }}
      isError={isError}
      isLoading={isLoading}
      isCreating={isCreating}
      data-testid={testIds.settings.workshopServiceOrderGroupDetail('template')}
    >
      <Form<ServiceOrderGroupFormType>
        defaultValues={{
          name: data?.name,
          code: data?.code,
          validityFrom: data?.validityFrom ? parseDate(data?.validityFrom) : null,
          validityTo: data?.validityTo ? parseDate(data?.validityTo) : null,
          authorizationProfileIds: data?.authorizationProfileIds ?? [],
          serviceOrderTypeIds: data?.serviceOrderTypeIds ?? [],
          serviceOrderVariantIds: data?.serviceOrderVariantIds ?? [],
        }}
        onSubmit={handleSubmit}
        schema={formSchema}
      >
        {(control) => (
          <SettingsSection>
            <VStack spacing={4}>
              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    control={control}
                    name="name"
                    type="text"
                    isRequired
                    label={i18n.t('general.labels.name')}
                    data-testid={testIds.settings.workshopServiceOrderGroupDetail('name')}
                  />
                </Box>
                <Box flex={1}>
                  <FormField
                    control={control}
                    name="code"
                    type="text"
                    label={i18n.t('page.settings.labels.code')}
                    data-testid={testIds.settings.workshopServiceOrderGroupDetail('code')}
                  />
                </Box>
              </HStack>
              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    control={control}
                    name="validityFrom"
                    type="date"
                    label={i18n.t('page.settings.labels.validityFrom')}
                    data-testid={testIds.settings.workshopServiceOrderGroupDetail('validityFrom')}
                  />
                </Box>
                <Box flex={1}>
                  <FormField
                    control={control}
                    name="validityTo"
                    type="date"
                    label={i18n.t('page.settings.labels.validityTo')}
                    data-testid={testIds.settings.workshopServiceOrderGroupDetail('validityTo')}
                  />
                </Box>
              </HStack>
              <Box>
                <FormField
                  control={control}
                  name="authorizationProfileIds"
                  type="multiChoice"
                  options={authorizationProfiles}
                  isLoading={isAuthorizationProfilesLoading}
                  label={i18n.t('entity.settings.labels.authorizationProfile')}
                  data-testid={testIds.settings.workshopServiceOrderGroupDetail(
                    'authorizationProfile'
                  )}
                />
                <Text color="secondary" size="xSmall">
                  {i18n.t('entity.settings.labels.authorizationProfileInfo')}
                </Text>
              </Box>
              <Box>
                <FormField
                  control={control}
                  name="serviceOrderTypeIds"
                  type="multiChoice"
                  options={orderTypes}
                  isLoading={isOrderTypesLoading}
                  label={i18n.t('entity.settings.labels.serviceOrderTypes')}
                  data-testid={testIds.settings.workshopServiceOrderGroupDetail(
                    'serviceOrderTypes'
                  )}
                />
                <Text color="secondary" size="xSmall">
                  {i18n.t('entity.settings.labels.serviceOrderTypesInfo')}
                </Text>
              </Box>
              <Box>
                <FormField
                  control={control}
                  name="serviceOrderVariantIds"
                  type="multiChoice"
                  options={orderVariants}
                  isLoading={isOrderVariantsLoading}
                  label={i18n.t('entity.settings.labels.serviceOrderVariants')}
                  data-testid={testIds.settings.workshopServiceOrderGroupDetail(
                    'serviceOrderVariants'
                  )}
                />
                <Text color="secondary" size="xSmall">
                  {i18n.t('entity.settings.labels.serviceOrderVariantsInfo')}
                </Text>
              </Box>
            </VStack>
            <SettingsFooter
              actions={[
                {
                  type: 'button',
                  variant: 'secondary',
                  title: i18n.t('general.actions.discard'),
                  onClick: () => navigate(settingsRoutes.workshopServiceOrderGroups),
                },
                {
                  control,
                  type: 'form-button',
                  buttonType: 'submit',
                  title: isCreating
                    ? i18n.t('general.actions.create')
                    : i18n.t('general.actions.saveChanges'),
                },
              ]}
            />
          </SettingsSection>
        )}
      </Form>
    </SettingsTemplate>
  );
}

const formSchema = object({
  name: yupString.required(),
});
