import {Action, Card, DataStatus} from 'platform/components';
import {Box, VStack} from 'platform/foundation';

import {isNilOrEmpty} from 'ramda-adjunct';

import {useListLatestBusinessCasesQuery} from '@dms/api/dashboard';
import i18n from '@dms/i18n';
import {businessCaseRoutes} from '@dms/routes';
import {useDataQuery} from '@dms/shared';

import {TestIdProps, composePath, useNavigate} from 'shared';

import {LatestBusinessCaseItem} from './LatestBusinessCaseItem';

interface LatestBusinessCasesProps extends TestIdProps {
  branchId: string | undefined;
}

export function LatestBusinessCases(props: LatestBusinessCasesProps) {
  const navigate = useNavigate();

  const {
    data: latestBusinessCases,
    isLoading: isLatestBusinessCases,
    isError: isLatestBusinessCasesError,
  } = useListLatestBusinessCasesQuery(
    {
      branchId: props.branchId,
    },
    {refetchOnMountOrArgChange: true}
  );

  const {queryParams} = useDataQuery('business-case', {
    // Latest business cases sorted by createdAt in descending order
    order: [{columnKey: 'businessCaseCreatedAt', order: 'DESC'}],
  });

  const onClickOpenLatestBusinessCases = () => {
    navigate(composePath(businessCaseRoutes.home, {queryParams}));
  };

  const displayLatestBusinessCasesAction: Action = {
    title: i18n.t('page.homepage.labels.view'),
    onClick: onClickOpenLatestBusinessCases,
    variant: 'link',
    type: 'button',
    rightIcon: 'navigation/chevron_right',
  };

  return (
    <Card
      title={i18n.t('page.homepage.labels.latestBusinessCases')}
      hasSeparator={false}
      actions={[displayLatestBusinessCasesAction]}
    >
      <Box minHeight={45}>
        <DataStatus
          minHeight="100%"
          isLoading={isLatestBusinessCases}
          isError={isLatestBusinessCasesError}
          isEmpty={isNilOrEmpty(latestBusinessCases)}
        >
          <VStack spacing={4}>
            {latestBusinessCases?.map((businessCase) => (
              <LatestBusinessCaseItem key={businessCase.id} businessCase={businessCase} />
            ))}
          </VStack>
        </DataStatus>
      </Box>
    </Card>
  );
}
