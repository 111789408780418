import {Card, DataStatus, Form, FormField, showNotification} from 'platform/components';
import {Grid} from 'platform/foundation';

import {
  useGetCheckoutByIdQuery,
  usePatchCheckoutForeignCurrencyPaymentDisallowMutation,
  usePostCheckoutForeignCurrencyPaymentAllowMutation,
} from '@dms/api/metadaAftersalesCheckout';
import i18n from '@dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {DEFAULT_FOREIGN_CURRENCY} from '../../../constants/currency';
import {useCurrencies} from '../../../hooks/useCurrencies';
import {handleApiError} from '../../../utils/handleApiError';

interface ForeginCurrencyPaymentCardProp extends RequiredTestIdProps {
  checkoutId: string;
  areAllPaymentsConcept: boolean;
}

export function ForeginCurrencyPaymentCard(props: ForeginCurrencyPaymentCardProp) {
  const {currencyOptions} = useCurrencies();

  const {
    data: checkoutData,
    isLoading: isCheckoutLoading,
    isError: isCheckoutError,
  } = useGetCheckoutByIdQuery({checkoutId: props.checkoutId});

  const [postCheckoutForeignCurrencyPaymentAllow, {isLoading: isAllowingLoading}] =
    usePostCheckoutForeignCurrencyPaymentAllowMutation();
  const [atchCheckoutForeignCurrencyPaymentDisallow, {isLoading: isDisallowingLoading}] =
    usePatchCheckoutForeignCurrencyPaymentDisallowMutation();

  const handleSwitchForeginCurrency = (shouldAllow: boolean) => {
    if (shouldAllow) {
      return postCheckoutForeignCurrencyPaymentAllow({
        checkoutId: props.checkoutId,
        body: {exchangeRateRatio: 1, exchangeCurrency: DEFAULT_FOREIGN_CURRENCY},
      })
        .unwrap()
        .then(() => showNotification.success())
        .catch(handleApiError);
    }

    return atchCheckoutForeignCurrencyPaymentDisallow({
      checkoutId: props.checkoutId,
    })
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);
  };

  const handleSaveForm = (data: ForeignCurrencyForm) =>
    postCheckoutForeignCurrencyPaymentAllow({
      checkoutId: props.checkoutId,
      body: {exchangeRateRatio: 1, exchangeCurrency: data.currency},
    })
      .unwrap()
      .catch(handleApiError);

  return (
    <DataStatus isLoading={isCheckoutLoading} isError={isCheckoutError}>
      <Card
        control={{
          type: 'switch',
          onChange: handleSwitchForeginCurrency,
          value: !!checkoutData?.isForeignCurrencyPayment,
          isDisabled: isAllowingLoading || isDisallowingLoading || !props.areAllPaymentsConcept,
        }}
        title={i18n.t('general.labels.foreignCurrency')}
        variant="inlineGrey"
        isExpanded={checkoutData?.isForeignCurrencyPayment ?? false}
        isClosedByDefault
      >
        <Form<ForeignCurrencyForm>
          defaultValues={{
            currency: checkoutData?.foreignCurrencyPayment?.exchangeCurrency ?? undefined,
            exchangeRateRatioAmount:
              checkoutData?.foreignCurrencyPayment?.exchangeRateRatio ?? undefined,
          }}
          onChange={handleSaveForm}
        >
          {(control) => (
            <Grid columns={4}>
              <FormField
                name="currency"
                label={i18n.t('general.labels.currency')}
                type="choice"
                options={currencyOptions}
                isNotClearable
                control={control}
                data-testid={suffixTestId('currency', props)}
              />
              <FormField
                name="exchangeRateRatioAmount"
                label={i18n.t('general.labels.amount')}
                type="number"
                isDisabled
                control={control}
                data-testid={suffixTestId('exchangeRateRatioAmount', props)}
              />
            </Grid>
          )}
        </Form>
      </Card>
    </DataStatus>
  );
}

type ForeignCurrencyForm = {
  currency: string;
  exchangeRateRatioAmount: number;
};
