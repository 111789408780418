import {metadaApi} from '@dms/api/core';

import {
  DeleteEmployeeWorkTeamApiArg,
  DeleteEmployeeWorkTeamApiResponse,
  GetEmployeeWorkTeamApiArg,
  GetEmployeeWorkTeamApiResponse,
  GetEmployeeWorkTeamsListApiArg,
  GetEmployeeWorkTeamsListApiResponse,
  PatchEmployeeWorkTeamApiArg,
  PatchEmployeeWorkTeamApiResponse,
  PostEmployeeWorkTeamApiArg,
  PostEmployeeWorkTeamApiResponse,
  PostEmployeeWorkTeamSetAsActiveApiArg,
  PostEmployeeWorkTeamSetAsActiveApiResponse,
  PostEmployeeWorkTeamSetAsInactiveApiArg,
  PostEmployeeWorkTeamSetAsInactiveApiResponse,
} from './types';

export const metadaEmployeeWorkTeamApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postEmployeeWorkTeam: build.mutation<
      PostEmployeeWorkTeamApiResponse,
      PostEmployeeWorkTeamApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-work-team`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['employeeWorkTeams'],
    }),
    getEmployeeWorkTeam: build.query<GetEmployeeWorkTeamApiResponse, GetEmployeeWorkTeamApiArg>({
      query: (queryArg) => ({
        url: `/core/v1/employee-work-team/${queryArg.id}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'employeeWorkTeam', projectId: queryArg.id},
      ],
    }),
    deleteEmployeeWorkTeam: build.mutation<
      DeleteEmployeeWorkTeamApiResponse,
      DeleteEmployeeWorkTeamApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-work-team/${queryArg.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'employeeWorkTeams',
        {type: 'employeeWorkTeam', projectId: queryArg.id},
      ],
    }),
    patchEmployeeWorkTeam: build.mutation<
      PatchEmployeeWorkTeamApiResponse,
      PatchEmployeeWorkTeamApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-work-team/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'employeeWorkTeams',
        {type: 'employeeWorkTeam', projectId: queryArg.id},
      ],
    }),
    postEmployeeWorkTeamSetAsActive: build.mutation<
      PostEmployeeWorkTeamSetAsActiveApiResponse,
      PostEmployeeWorkTeamSetAsActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-work-team/${queryArg.workTeamId}/set-as-active`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'employeeWorkTeams',
        {type: 'employeeWorkTeam', projectId: queryArg.workTeamId},
      ],
    }),
    postEmployeeWorkTeamSetAsInactive: build.mutation<
      PostEmployeeWorkTeamSetAsInactiveApiResponse,
      PostEmployeeWorkTeamSetAsInactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-work-team/${queryArg.workTeamId}/set-as-inactive`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [
        'employeeWorkTeams',
        {type: 'employeeWorkTeam', projectId: queryArg.workTeamId},
      ],
    }),
    getEmployeeWorkTeamsList: build.query<
      GetEmployeeWorkTeamsListApiResponse,
      GetEmployeeWorkTeamsListApiArg
    >({
      query: (queryArg) => ({
        url: `/core/v1/employee-work-teams`,
        params: {company: queryArg.company, branch: queryArg.branch},
      }),
      providesTags: ['employeeWorkTeams'],
    }),
  }),
});

export const {
  usePostEmployeeWorkTeamMutation,
  useGetEmployeeWorkTeamQuery,
  useDeleteEmployeeWorkTeamMutation,
  usePatchEmployeeWorkTeamMutation,
  usePostEmployeeWorkTeamSetAsActiveMutation,
  usePostEmployeeWorkTeamSetAsInactiveMutation,
  useGetEmployeeWorkTeamsListQuery,
} = metadaEmployeeWorkTeamApi;
