import {DataStatus} from 'platform/components';
import {Box, Show, VStack} from 'platform/foundation';

import {useGetChecklistQuery} from '@dms/api/checklist';

import {EmptyState} from './components/EmptyState';
import {Overview} from './components/Overview';
import {ChecklistResourceType} from './types/ChecklistResourceType';

export interface ChecklistsProps {
  recordId: string;
  resourceId: keyof typeof ChecklistResourceType;
}

export function Checklists(props: ChecklistsProps) {
  const checklistDetailQuery = useGetChecklistQuery({
    recordId: props.recordId,
    resourceId: props.resourceId,
  });

  return (
    <Box width="100%">
      <VStack align="center">
        <DataStatus isLoading={checklistDetailQuery.isLoading}>
          <Show when={checklistDetailQuery.data}>
            <Overview
              recordId={props.recordId}
              resourceId={props.resourceId}
              data={checklistDetailQuery.data!}
              isUpdating={checklistDetailQuery.isFetching}
            />
          </Show>
          <Show when={!checklistDetailQuery.data}>
            <EmptyState recordId={props.recordId} resourceId={props.resourceId} />
          </Show>
        </DataStatus>
      </VStack>
    </Box>
  );
}
