import {useDraggable} from '@dnd-kit/core';
import {CSS} from '@dnd-kit/utilities';
import {format, differenceInMinutes, isPast} from 'date-fns';
import {getSize, Hide, HStack, Icon, Show, Text} from 'platform/foundation';
import {css, useTheme} from 'styled-components';
import {match} from 'ts-pattern';

import {__, divide, pipe} from 'ramda';
import {ceil} from 'ramda-adjunct';

import {isNotNilOrZero, pushGtmEvent, RequiredTestIdProps, suffixTestId} from 'shared';

import {EVENT_FILTER_WHEN_IS_PAST} from '../constants/eventFilterWhenIsPast';
import {useResizeHandler} from '../hooks/useResizeHandler';
import {BigCalendarEvent} from '../types/BigCalendarEvent';
import {BigCalendarEventWithLayout} from '../types/BigCalendarEventWithLayout';
import {getThemeToken} from '../utils/getThemeToken';
import {repeatingGradient} from '../utils/repeatingGradient';
import {ResizeHandler} from './ResizeHandler';

interface EventItemProps extends RequiredTestIdProps {
  event: BigCalendarEventWithLayout;
  isOnWeekView?: boolean;
  top?: number;
  onClick?: (event: BigCalendarEvent) => void;
}

export function EventItem(props: EventItemProps) {
  const theme = useTheme();
  const {startDate, endDate, height} = useResizeHandler({event: props.event});

  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: props.event.id,
    data: {
      type: 'draggable',
      eventId: props.event.id,
    },
    disabled: props.event.isReadOnly,
  });

  const onClick = () => {
    if (props.event.isReadOnly) {
      return;
    }

    pushGtmEvent({
      event: 'button_click',
      event_id: 'big_calendar_event_click',
      value: {
        eventId: props.event.id,
      },
    });

    props.onClick?.(props.event);
  };

  /**
   * Calculates the duration of the event in minutes by getting the difference
   * between end and start times and converting from milliseconds
   */
  const durationInMinutes = differenceInMinutes(endDate, startDate);

  const EVENT_COLOR = props.event.color || 'general.black';
  const EVENT_COLOR_HEX = getThemeToken(theme, `colors.${EVENT_COLOR}`);

  return (
    <div
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      data-testid={suffixTestId('eventItem', props)}
      onClick={onClick}
      css={css`
        position: absolute;
        top: ${props.top}px;
        left: ${props.event.left ?? 0}%;
        width: ${props.event.width ?? 100}%;
        height: ${height}px;
        background-color: ${props.event.alternative ? 'transparent' : EVENT_COLOR_HEX};
        background-image: ${props.event.alternative
          ? `${repeatingGradient(EVENT_COLOR_HEX)}`
          : 'none'};
        border: ${match([props.event.alternative, isNotNilOrZero(props.event.left)])
          .with([true, true], () => `1px solid ${theme.colors.general.black}`)
          .with([true, false], () => `2px solid ${EVENT_COLOR_HEX}`)
          .with([false, true], () => `1px solid ${theme.colors.general.black}`)
          .with([false, false], () => 'none')
          .otherwise(() => 'none')};
        border-radius: ${theme.radii.small};
        padding: ${durationInMinutes < MAX_DURATION_FOR_SINGLE_LINE
          ? `0 ${getSize(1)} 0 ${getSize(1)}`
          : getSize(1)};
        cursor: pointer;
        pointer-events: auto;
        transform: ${CSS.Transform.toString(transform)};
        transform-origin: 0 0;
        opacity: 1;
        filter: ${isPast(endDate) ? EVENT_FILTER_WHEN_IS_PAST : 'none'};
        will-change: transform, opacity, height;
        overflow: hidden;
        z-index: ${isNotNilOrZero(props.event.left) ? dividerAndCeil(props.event.left) : 0};
      `}
    >
      <Show when={durationInMinutes >= MAX_DURATION_FOR_SINGLE_LINE}>
        <HStack spacing={1} align="center" wrap="nowrap">
          <Show when={props.event.icon}>
            <Icon
              data-testid={suffixTestId('eventItem-icon', props)}
              value={props.event.icon!}
              size={3}
              color={props.event.alternative ? 'general.black' : 'general.white'}
            />
          </Show>
          <Text
            data-testid={suffixTestId('eventItem-title', props)}
            size="xSmall"
            color={props.event.alternative ? 'primary' : 'white'}
            alternative
            noWrap
          >
            {props.event.title}
          </Text>
          <Show when={durationInMinutes < MAX_DURATION_FOR_SINGLE_LINE}>
            <Text
              data-testid={suffixTestId('eventItem-time', props)}
              size="xSmall"
              color={props.event.alternative ? 'primary' : 'white'}
            >
              {format(startDate, 'H:mm')} - {format(endDate, 'H:mm')}
            </Text>
          </Show>
        </HStack>

        <Text
          data-testid={suffixTestId('eventItem-time', props)}
          size="xSmall"
          color={props.event.alternative ? 'primary' : 'white'}
        >
          {format(startDate, 'H:mm')} - {format(endDate, 'H:mm')}
        </Text>
      </Show>

      <Show when={durationInMinutes <= MAX_DURATION_FOR_SINGLE_LINE}>
        <HStack height="100%" spacing={1} align="center" wrap="nowrap">
          <Show when={props.event.icon}>
            <Icon
              data-testid={suffixTestId('eventItem-icon', props)}
              value={props.event.icon}
              size={3}
              color={props.event.alternative ? 'general.black' : 'general.white'}
            />
          </Show>
          <Text
            data-testid={suffixTestId('eventItem-title', props)}
            size="xSmall"
            color={props.event.alternative ? 'primary' : 'white'}
            alternative
            noWrap
          >
            {props.event.title} {format(startDate, 'H:mm')} - {format(endDate, 'H:mm')}
          </Text>
        </HStack>
      </Show>
      <Hide when={props.event.isReadOnly} onMobile>
        <ResizeHandler
          data-testid={suffixTestId('eventItem', props)}
          direction="bottom"
          eventId={props.event.id}
          isReadOnly={props.event.isReadOnly}
        />
      </Hide>
    </div>
  );
}

const MAX_DURATION_FOR_SINGLE_LINE = 42;
const dividerAndCeil = pipe(divide(__, 10), ceil);
