import {useNavigate} from 'react-router-dom';

import {testIds, vehiclesRoutes} from '@dms/routes';

import {composePath, useRequiredParams} from 'shared';

import {RentalVehicleForm} from '../../components/RentalVehicleForm/RentalVehicleForm';
import {RentalVehicleFormMode} from '../../components/RentalVehicleForm/types/RentalVehicleFormMode';

export function NewRentalVehicleFromExistingVehicle() {
  const navigate = useNavigate();
  const {id} = useRequiredParams();

  return (
    <RentalVehicleForm
      mode={RentalVehicleFormMode.NEW_FROM_SALE_OR_SERVICE}
      vehicleId={id}
      onDiscard={() => navigate(-1)}
      onSuccess={(newVehicleId) =>
        navigate(composePath(vehiclesRoutes.detail, {params: {id: newVehicleId}}))
      }
      data-testid={testIds.vehicles.createRentalVehicleFromExistingVehicle('create')}
    />
  );
}
