import {isFeatureEnabled} from 'feature-flags';
import {MenuBadge} from 'platform/components';

import {useGetChecklistQuery} from '@dms/api/checklist';
import featureFlags from '@dms/feature-flags';

import {ChecklistsProps} from '../Checklists';
import {useChecklistBadge, UseChecklistProgress} from './useChecklistBadge';

interface UseChecklistProps extends ChecklistsProps {}

interface UseChecklistReturn {
  badgeSettings: MenuBadge | undefined;
  progress: UseChecklistProgress;
  checklistSettings: ChecklistsProps;
}

export function useChecklist(props: UseChecklistProps): UseChecklistReturn {
  const checklistDetailQuery = useGetChecklistQuery(
    {
      recordId: props.recordId,
      resourceId: props.resourceId,
    },
    {
      skip: !isFeatureEnabled(featureFlags.SALE_TASKS_CHECKLIST) || !props.recordId,
    }
  );

  const {currentData} = checklistDetailQuery;

  const mandatoryTasks = currentData?.tasks.filter((task) => task.isMandatory) || [];

  const totalCount = mandatoryTasks.length;
  const doneCount = mandatoryTasks.filter((task) => task.markedAsDone).length;
  const progressCount = totalCount === 0 ? 0 : Math.floor((doneCount / totalCount) * 100) / 100;

  const progress = [
    {
      totalCount,
      doneCount,
      progress: progressCount,
    },
  ];

  const {badgeSettings} = useChecklistBadge({
    progress,
  });

  return {
    badgeSettings,
    progress: progress[0],
    checklistSettings: {
      recordId: props.recordId,
      resourceId: props.resourceId,
    },
  };
}
