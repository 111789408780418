import {FlagProps} from 'platform/components';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import {DeviceState} from '@dms/api/digitalSignature';
import i18n from '@dms/i18n';

export const getDeviceStateFlagProps = (state: DeviceState): FlagProps =>
  match<DeviceState, FlagProps>(state)
    .with(
      'paired',
      always({label: i18n.t('entity.employee.labels.flagActive'), colorScheme: 'green'})
    )
    .with(
      'unpaired',
      always({label: i18n.t('entity.employee.labels.unpaired'), colorScheme: 'orange'})
    )
    .otherwise(always({label: i18n.t('entity.employee.labels.flagInactive'), colorScheme: 'red'}));
