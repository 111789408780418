import {metadaApi} from '@dms/api/core';
import {
  CreateServiceVehicleApiRes,
  CreateServiceVehicleApiArg,
  LastActiveServiceCaseApiRes,
  LastActiveServiceCaseApiArg,
  GetServiceVehicleApiRes,
  GetServiceVehicleApiArg,
  CustomerLastActiveServiceCaseApiRes,
  CustomerLastActiveServiceCaseApiArg,
} from '@dms/api/shared';

const metadaServiceVehicleApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    createServiceVehicle: build.mutation<CreateServiceVehicleApiRes, CreateServiceVehicleApiArg>({
      query: (body) => ({
        url: `/service/v1/service-vehicle`,
        method: 'POST',
        body,
      }),
    }),
    getServiceVehicle: build.query<GetServiceVehicleApiRes, GetServiceVehicleApiArg>({
      query: (params) => ({
        url: `/service/v1/service-vehicle`,
        params,
      }),
    }),
    getLastActiveServiceCase: build.query<LastActiveServiceCaseApiRes, LastActiveServiceCaseApiArg>(
      {
        query: ({vehicleId}) => ({
          url: `/service/v1/service-case/by-vehicle/${vehicleId}/last-active`,
        }),
      }
    ),
    getCustomerLastActiveServiceCase: build.query<
      CustomerLastActiveServiceCaseApiRes,
      CustomerLastActiveServiceCaseApiArg
    >({
      query: ({customerId}) => ({
        url: `/service/v1/service-case/by-customer/${customerId}/last-active`,
      }),
    }),
  }),
});

export const {
  useCreateServiceVehicleMutation,
  useGetLastActiveServiceCaseQuery,
  useGetCustomerLastActiveServiceCaseQuery,
  useGetServiceVehicleQuery,
  useLazyGetServiceVehicleQuery,
} = metadaServiceVehicleApi;
