import {
  Attributes,
  Card,
  DataStatus,
  getAlpha2CodeByAlpha3code,
  openDialog,
} from 'platform/components';
import {Grid, GridItem} from 'platform/foundation';
import {formatPhoneNumber, useDateTimeFormatter} from 'platform/locale';

import {useGetEmployeePersonalDataQuery} from '@dms/api/metadaEmployee';
import {EntityResourceIds} from '@dms/api/shared';
import i18n from '@dms/i18n';
import {CommentsWidget, Section} from '@dms/shared';

import {parseDate, RequiredTestIdProps, suffixTestId} from 'shared';

import {useEmployeeUrl} from '../../hooks/useEmployeeUrl';
import {getEmployeeName} from '../../utils/getEmployeeName';
import {EditPersonalInfo} from './components/EditPersonalInfo';
import {getPermanentAddress} from './utils/getPermanentAddress';

interface PersonalInfoProps extends RequiredTestIdProps {}

export function PersonalInfo(props: PersonalInfoProps) {
  const dateTimeFormater = useDateTimeFormatter();
  const {employeeId} = useEmployeeUrl();

  const {data: employee, isLoading, isError} = useGetEmployeePersonalDataQuery({employeeId});

  return (
    <Section>
      <Grid columns={2}>
        <DataStatus isError={isError} isLoading={isLoading}>
          <GridItem>
            <Card
              title={i18n.t('entity.employee.labels.informationTab')}
              actions={[
                {
                  type: 'button',
                  variant: 'link',
                  leftIcon: 'image/edit',
                  title: i18n.t('general.actions.edit'),
                  'data-testid': suffixTestId('edit', props),
                  onClick: () =>
                    openDialog(
                      <EditPersonalInfo
                        employeeId={employeeId}
                        dialogId="edit-personal-info"
                        data-testid={suffixTestId('edit', props)}
                      />,
                      {
                        title: i18n.t('entity.employee.labels.informationTab'),
                        id: 'edit-personal-info',
                      }
                    ),
                },
              ]}
            >
              <Attributes
                data-testid={suffixTestId('attributes', props)}
                rows={[
                  {
                    label: i18n.t('entity.person.labels.fullName'),
                    value: getEmployeeName(employee),
                  },
                  {
                    label: i18n.t('entity.person.labels.birthdate'),
                    value: employee?.personalData?.birthdate
                      ? dateTimeFormater('dateShort', parseDate(employee.personalData.birthdate))
                      : null,
                  },
                  {
                    label: i18n.t('entity.person.labels.genderKey'),
                    value: employee?.personalData?.genderKey
                      ? i18n.t(
                          `entity.person.labels.${employee.personalData.genderKey.toLowerCase()}`
                        )
                      : null,
                  },
                  {
                    label: i18n.t('entity.person.labels.fte'),
                    value: employee?.personalData?.fte,
                  },
                  {
                    label: i18n.t('entity.person.labels.employeeId'),
                    value: employee?.publicId,
                  },
                  {
                    label: i18n.t('entity.employee.labels.startDate'),
                    value: employee?.employmentData?.startDate
                      ? dateTimeFormater('dateShort', parseDate(employee.employmentData.startDate))
                      : null,
                  },
                  {
                    label: i18n.t('entity.employee.labels.endDate'),
                    value: employee?.employmentData?.endDate
                      ? dateTimeFormater('dateShort', parseDate(employee.employmentData.endDate))
                      : null,
                  },
                  {
                    label: i18n.t('page.employeeDetail.labels.permanentAddressOfResidence'),
                    value: getPermanentAddress(employee),
                  },
                  {
                    label: i18n.t('entity.employee.labels.workEmail'),
                    value: employee?.contactData?.email_work,
                  },
                  {
                    label: i18n.t('entity.employee.labels.privateEmail'),
                    value: employee?.contactData?.email_private,
                  },
                  {
                    label: i18n.t('entity.employee.labels.workPhone'),
                    value: formatPhoneNumber(
                      employee?.contactData?.phone_number_work?.number,
                      employee?.contactData?.phone_number_work?.countryCode &&
                        getAlpha2CodeByAlpha3code(
                          employee.contactData.phone_number_work.countryCode
                        )
                    ),
                  },
                  {
                    label: i18n.t('entity.employee.labels.privatePhone'),
                    value: formatPhoneNumber(
                      employee?.contactData?.phone_number_private?.number,
                      employee?.contactData?.phone_number_private?.countryCode &&
                        getAlpha2CodeByAlpha3code(
                          employee.contactData.phone_number_private.countryCode
                        )
                    ),
                  },
                  {
                    label: i18n.t('general.labels.note'),
                    value: employee?.note,
                  },
                ]}
              />
            </Card>
          </GridItem>
          <GridItem>
            <CommentsWidget resourceId={EntityResourceIds.employee} recordId={employee?.id || ''} />
          </GridItem>
        </DataStatus>
      </Grid>
    </Section>
  );
}
