import {
  Button,
  closeCurrentDialog,
  DataStatus,
  Form,
  FormField,
  openDeleteDialog,
  openDialog,
  Separator,
} from 'platform/components';
import {Box, Heading, HStack, Text, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useParams} from 'react-router-dom';

import {mergeAll} from 'ramda';
import {isArray, noop} from 'ramda-adjunct';

import {
  useDeleteCustomerContractAssignedCustomerMutation,
  useGetCustomerContractAssignedCustomerQuery,
  usePatchCustomerContractAssignedCustomerMutation,
  usePostCustomerContractAssignedCustomerMutation,
} from '@dms/api/metadaCustomerContract';
import i18n from '@dms/i18n';
import {customerRoutes, testIds} from '@dms/routes';
import {CustomerMatchOrCreate, handleApiError} from '@dms/shared';

import {Nullish, composePath, useNavigate} from 'shared';

import {DataGrid, QueryFilterObject, ActionCallback, useRefreshDataGrid} from 'features/datagrid';

import {ValidityForm} from './components/ValidityForm';

type AssignedCustomerFormType = {
  defaultValidityDate: string | Nullish;
};

type AssignedCustomerRowData = {
  customerName: {value: {id: string}};
};

export function AssignedCustomersTab() {
  const {id} = useParams();
  const navigate = useNavigate();
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const {data, isLoading, isError} = useGetCustomerContractAssignedCustomerQuery({
    customerContractId: id ?? '',
  });

  const [patchAssignedCustomer] = usePatchCustomerContractAssignedCustomerMutation();
  const [postAssignedCustomer] = usePostCustomerContractAssignedCustomerMutation();
  const [deleteAssignedCustomer] = useDeleteCustomerContractAssignedCustomerMutation();

  const queryModifier = (filter: QueryFilterObject) =>
    mergeAll([filter, {customerContractId: id ?? ''}]);

  const actionCallback: ActionCallback = ({actionKey, rowData}) => {
    const castedRowData = (isArray(rowData) ? rowData[0] : rowData) as AssignedCustomerRowData;
    const customerId = castedRowData.customerName.value.id;

    match(actionKey)
      .with('redirectDetail', 'customerDetail', () =>
        navigate(composePath(customerRoutes.detail, {params: {id: customerId}}))
      )
      .with('editValidity', () =>
        openDialog(
          <ValidityForm
            customerContractId={id ?? ''}
            customerId={customerId}
            onClose={closeCurrentDialog}
            afterSubmit={refreshDataGrid}
            data-testid={testIds.settings.customerContractsDetail('editValidity')}
          />,
          {
            title: i18n.t('entity.customerContract.labels.editValidity'),
            size: 'small',
          }
        )
      )
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () =>
            deleteAssignedCustomer({
              customerContractId: id ?? '',
              customerId,
            })
              .unwrap()
              .then(refreshDataGrid)
              .catch(handleApiError),
        })
      )
      .otherwise(noop);
  };

  const handleAssign = () =>
    openDialog(
      <CustomerMatchOrCreate
        onCustomer={(customer) =>
          postAssignedCustomer({
            customerContractId: id ?? '',
            customerId: customer.id,
          })
            .unwrap()
            .then(() => {
              refreshDataGrid();
              closeCurrentDialog();
            })
            .catch(handleApiError)
        }
        secondStepComponentType="SERVICE_CASE"
        data-testid={testIds.settings.customerContractsDetail('assignCustomerDialog')}
      />,
      {
        title: i18n.t('entity.customer.labels.customer'),
      }
    );

  const handleFormChange = (data: AssignedCustomerFormType) =>
    patchAssignedCustomer({
      customerContractId: id ?? '',
      body: {
        defaultValidityDate: data.defaultValidityDate ?? '',
      },
    })
      .unwrap()
      .catch(handleApiError);

  return (
    <VStack spacing={4}>
      <Text color="secondary" size="small">
        {i18n.t('entity.customerContracts.labels.defaultValidityDateDescription')}
      </Text>
      <Box width={98}>
        <DataStatus isLoading={isLoading} isError={isError}>
          <Form<AssignedCustomerFormType> defaultValues={data} onChange={handleFormChange}>
            {(control) => (
              <FormField
                control={control}
                type="apiDate"
                name="defaultValidityDate"
                label={i18n.t('entity.customerContract.labels.defaultValidityDate')}
                data-testid={testIds.settings.customerContractsDetail('defaultValidityDate')}
              />
            )}
          </Form>
        </DataStatus>
      </Box>
      <Separator spacing={0} />
      <HStack justify="space-between" align="center">
        <Heading size={4}>{i18n.t('entity.customerContract.labels.assignedCustomers')}</Heading>
        <Button
          title={i18n.t('general.actions.assignCustomer')}
          variant="link"
          leftIcon="social/person_add"
          size="small"
          onClick={handleAssign}
          data-testid={testIds.settings.customerContractsDetail('assignCustomer')}
        />
      </HStack>
      <DataGrid
        ref={dataGridRef}
        autoHeight
        gridCode="customer-contract-assigned-customer"
        queryModifier={queryModifier}
        actionCallback={actionCallback}
        data-testid={testIds.settings.customerContractsDetail('assignCustomersDG')}
      />
    </VStack>
  );
}
