import {ListPermissionsV2ApiResponse} from '@dms/api/accessControlList';

export const getGrantedProtectedUnits = (permissions: ListPermissionsV2ApiResponse | undefined) =>
  (permissions ?? []).reduce(
    (acc, permission) => ({
      ...acc,
      [permission.protectedUnit.id]: {
        value: true,
        scopes: permission.scopes,
        id: permission.protectedUnit.id,
      },
    }),
    {}
  );
