import {FormControl, FormField, Separator} from 'platform/components';
import {Grid, GridItem, Heading, Hide, Show, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {BaseSupplier} from '@dms/api/metadaWarehouseSupplier';
import i18n from '@dms/i18n';
import {getOptionsFromSuppliers} from '@dms/shared';

import {Nullish, TestIdProps, suffixTestId} from 'shared';

import {WarehouseSettingsForm} from '../types/WarehouseSettingsForm';
import {SupplierFieldList} from './SupplierFieldList';

const EMPTY_SUPPLIER = {id: null};

interface SuppliersFormProps extends TestIdProps {
  control: FormControl<WarehouseSettingsForm>;
  formApi: UseFormReturn<WarehouseSettingsForm>;
  suppliers: BaseSupplier[] | Nullish;
}

export function SuppliersForm(props: SuppliersFormProps) {
  const areAllSuppliersEnabled = props.formApi.watch('allSuppliers');
  return (
    <VStack spacing={6}>
      <Heading size={3}>{i18n.t('entity.warehouse.labels.suppliers')}</Heading>
      <Grid columns={4}>
        <GridItem span={1}>
          <FormField
            name="allSuppliers"
            type="checkbox"
            label={i18n.t('entity.warehouse.labels.enableForAllSuppliers')}
            onChange={() => props.formApi.setValue('suppliers', [EMPTY_SUPPLIER])}
            control={props.control}
            data-testid={suffixTestId('allSuppliers', props)}
          />
        </GridItem>
      </Grid>

      <Show when={areAllSuppliersEnabled}>
        <Grid columns={2}>
          <Separator spacing={0} />
        </Grid>
        <Grid columns={4}>
          <FormField
            name="defaultSupplierId"
            type="choice"
            label={i18n.t('entity.warehouse.labels.defaultSupplier')}
            options={getOptionsFromSuppliers(props.suppliers)}
            control={props.control}
            isRequired
            isNotClearable
            data-testid={suffixTestId('defaultSupplierId', props)}
          />
        </Grid>
      </Show>

      <Hide when={areAllSuppliersEnabled}>
        <Grid columns={2}>
          <Separator spacing={0} />
        </Grid>
        <SupplierFieldList<WarehouseSettingsForm>
          name="suppliers"
          control={props.control}
          formApi={props.formApi}
          isRequired
          suppliers={props.suppliers}
          data-testid={suffixTestId('supplierFieldList', props)}
        />
      </Hide>
    </VStack>
  );
}
