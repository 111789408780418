import {compose, isNil, prop, sortBy, toLower} from 'ramda';

import {ListContextParametersApiArg, useListContextParametersQuery} from '@dms/api/messaging';

import {ContextParameters} from '../types/ContextParameters';

export const useContextParameters = (context: ListContextParametersApiArg['context'] | null) => {
  const {data, isLoading, isError} = useListContextParametersQuery(
    {
      context: context as ListContextParametersApiArg['context'],
    },
    {
      skip: isNil(context),
    }
  );

  const contextParameters: ContextParameters = sortBy(
    compose(toLower, prop('value')),
    data?.map((param) => ({
      value: param.label,
      id: param.code,
      ...param,
    })) || []
  );

  return {
    contextParameters,
    isLoading,
    isError,
  };
};
