import {Action, TabProps, Tabs} from 'platform/components';
import {Show} from 'platform/foundation';

import {GetSupplierOrderResponse} from '@dms/api/metadaWarehouseSupplierOrder';
import i18n from '@dms/i18n';
import {queryParams, Section} from '@dms/shared';

import {buildArray, Nullish, suffixTestId, TestIdProps, useBoolean, useQueryState} from 'shared';

import {refreshDatagrid} from 'features/datagrid';

import {WAREHOUSE_GRID_CODES} from '../../../../constants/warehouseGridCodes';
import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';
import {GeneralInformationTab} from './components/GeneralInformationTab';
import {ListOfItemsTab} from './components/ListOfItemsTab';
import {MaterialModal} from './components/MaterialModal';

interface OverviewProps extends TestIdProps {
  supplierOrder: GetSupplierOrderResponse | Nullish;
  isPending: boolean;
  isOrderLoading: boolean;
  hasOrderError: boolean;
}

export function Overview(props: OverviewProps) {
  const [activeTabId, setActiveTabId] = useQueryState(queryParams.COMPONENT_SECTIONS_TAB);
  const {supplierOrderId} = useWarehouseParams();
  const [isAddMaterialModalVisible, , , toggleAddMaterialModal] = useBoolean();

  const handleDiscardAddMaterialModal = () => {
    toggleAddMaterialModal();
    refreshDatagrid(WAREHOUSE_GRID_CODES.warehouseSupplierOrderItems, {deselectAll: true});
  };

  const handleAddItem = () => {
    toggleAddMaterialModal();
  };

  const supplierListOfItemsActions = buildArray<Action>().when(props.isPending, {
    type: 'button',
    variant: 'outlined',
    title: i18n.t('entity.warehouse.labels.addMaterial'),
    onClick: handleAddItem,
    'data-testid': suffixTestId('labels.addMaterial', props),
  });

  const tabs: TabProps[] = [
    {
      title: i18n.t('entity.warehouse.labels.generalInformation'),
      id: 'generalInformation',
      content: (
        <GeneralInformationTab
          supplierOrder={props.supplierOrder}
          isPending={props.isPending}
          isOrderLoading={props.isOrderLoading}
          hasOrderError={props.hasOrderError}
          data-testid={suffixTestId('generalInformation', props)}
        />
      ),
    },
    {
      title: i18n.t('entity.warehouse.labels.listOfItems'),
      id: 'listOfItems',
      actions: supplierListOfItemsActions,
      content: (
        <ListOfItemsTab
          totalPrice={props.supplierOrder?.totals}
          isPending={props.isPending}
          data-testid={suffixTestId('listOfItems', props)}
        />
      ),
    },
  ];

  return (
    <Section isFullHeight>
      <Tabs
        isFullHeight
        tabs={tabs}
        activeTabId={activeTabId}
        onChange={setActiveTabId}
        isLazy
        variant="condensed"
      />
      <Show when={isAddMaterialModalVisible}>
        <MaterialModal
          orderId={supplierOrderId}
          onDiscard={handleDiscardAddMaterialModal}
          data-testid={suffixTestId('dialogs.addMaterial', props)}
        />
      </Show>
    </Section>
  );
}
