import {Module} from '@dms/api/shared';
import {moduleKeys} from '@dms/config';
import {myProfileRoutes} from '@dms/routes';

import {Dashboard} from './pages/Dashboard/Dashboard';
import {Language} from './pages/Language/Language';
import {NotificationDetail} from './pages/NotificationDetail/NotificationDetail';
import {NotificationOverview} from './pages/NotificationOverview/NotificationOverview';
import {PersonalInformation} from './pages/PersonalInformation/PersonalInformation';
import {Security} from './pages/Security/Security';
import {MyProfileModuleProps} from './types/MyProfileModuleProps';

export const MyProfileModule = (props: MyProfileModuleProps): Module => ({
  id: moduleKeys.myProfile,
  routerProps: {
    path: myProfileRoutes.personalInformation,
    element: <PersonalInformation />,
  },
  content: [
    {
      id: 'my-profile-personal-information',
      routerProps: {
        path: myProfileRoutes.personalInformation,
        element: <PersonalInformation />,
      },
    },
    {
      id: 'my-profile-security',
      routerProps: {
        path: myProfileRoutes.security,
        element: <Security />,
      },
    },
    {
      id: 'my-profile-dashboard',
      routerProps: {
        path: myProfileRoutes.dashboard,
        element: <Dashboard />,
      },
    },
    {
      id: 'my-profile-language',
      routerProps: {
        path: myProfileRoutes.language,
        element: <Language purge={props.purge} />,
      },
    },
    {
      id: 'my-profile-notification',
      routerProps: {
        path: myProfileRoutes.notifications,
        element: <NotificationOverview />,
      },
    },
    {
      id: 'my-profile-notification-detail',
      routerProps: {
        path: myProfileRoutes.notificationDetail,
        element: <NotificationDetail />,
      },
    },
  ],
});
