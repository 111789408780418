import {showNotification} from 'platform/components';

import {useState} from 'react';

import {isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {PermissionScopeResponseBody, ProtectedUnitResponseBody} from '@dms/api/accessControlList';
import i18n from '@dms/i18n';

import {Nullish} from 'shared';

const EMPTY_SCOPE = {scopeId: '', optionIds: []};

export type Scope = {
  scopeId: string;
  optionIds: string[];
  errorMessages?: {
    scopeIdMessage?: string;
    optionIdsMessage?: string;
  };
};

export const useScopes = (
  defaultScopes: PermissionScopeResponseBody[] | Nullish,
  protectedUnit: ProtectedUnitResponseBody
) => {
  const defaultValues: Scope[] = isNilOrEmpty(defaultScopes)
    ? [EMPTY_SCOPE]
    : (defaultScopes?.map((item) => ({
        scopeId: item.id,
        optionIds: item.values.map((val) => val.id),
      })) ?? []);

  const [scopes, setScopes] = useState<Scope[]>(defaultValues);

  const validateScopes = () => {
    let areScopeValuesValid = true;

    if (isNilOrEmpty(scopes)) {
      // should never happen, only for safety
      showNotification.error();
      return false;
    }

    scopes.forEach((scope) => {
      if (isNilOrEmpty(scope.scopeId) || isNilOrEmpty(scope.optionIds)) {
        areScopeValuesValid = false;
        setScopes((prevState) =>
          prevState.map((item) => {
            if (item.scopeId === scope.scopeId) {
              return {
                ...item,
                errorMessages: {
                  optionIdsMessage: isNilOrEmpty(scope.optionIds)
                    ? i18n.t('general.validations.fieldIsRequired')
                    : undefined,
                  scopeIdMessage: isNilOrEmpty(scope.scopeId)
                    ? i18n.t('general.validations.fieldIsRequired')
                    : undefined,
                },
              };
            }

            return item;
          })
        );
      }
    });

    return areScopeValuesValid;
  };

  const getAvailableScopesForIndex = (index: number) =>
    protectedUnit.scopes
      .filter(
        (scope) =>
          !scopes.some((currentScope, i) => currentScope.scopeId === scope.id && index !== i)
      )
      .map((item) => ({label: item.name, value: item.id}));

  const isRowScopeIdNotSelectedForIndex = (index: number) => isNilOrEmpty(scopes[index]?.scopeId);

  const scopeOptionsForIndex = (index: number) => {
    const scopeId = scopes?.[index]?.scopeId;
    if (isNilOrEmpty(scopeId)) {
      return [];
    }

    const scope = protectedUnit.scopes.find((item) => item.id === scopeId);
    if (isNil(scope)) {
      return [];
    }

    return scope.options.map((option) => ({label: option.name, value: option.id}));
  };

  const handleUpdateScopeId = (scope: Scope) => (scopeId: string | null) => {
    if (isNil(scopeId)) {
      return;
    }

    setScopes((prevState) =>
      prevState.map((item) =>
        scope.scopeId === item.scopeId
          ? {
              scopeId,
              optionIds: [],
              errorMessages: {...item.errorMessages, scopeIdMessage: undefined},
            }
          : item
      )
    );
  };

  const handleUpdateOptionIds = (scope: Scope) => (value: string[] | null) =>
    setScopes((prevState) =>
      prevState.map((item) =>
        scope.scopeId === item.scopeId
          ? {
              scopeId: scope.scopeId,
              optionIds: value ?? [],
              errorMessages: {...item.errorMessages, optionIdsMessage: undefined},
            }
          : item
      )
    );

  const handleRemoveScope = (scopeId: string) =>
    setScopes((prevState) => prevState.filter((item) => item.scopeId !== scopeId));

  const appendEmptyScope = () => setScopes((prevState) => [...prevState, EMPTY_SCOPE]);

  return {
    scopes,
    validateScopes,
    getAvailableScopesForIndex,
    isRowScopeIdNotSelectedForIndex,
    scopeOptionsForIndex,
    handleUpdateScopeId,
    handleUpdateOptionIds,
    handleRemoveScope,
    appendEmptyScope,
  };
};
