import {FC} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {
  businessCaseApi,
  useChangeToPurchaseBrokeragePurchaseVehicleMutation,
  useCreateBusinessCaseOfferMutation,
  useCreateOfferPurchaseVehicleMutation,
  useGetBusinessCaseQuery,
} from '@dms/api/businessCase';
import {useLazyGetVehicleQuery} from '@dms/api/vehicle';
import {businessCaseRoutes, testIds} from '@dms/routes';
import {handleApiError, useLazyGetAuthorizationProfilesAndCKKPermissions} from '@dms/shared';
import {SimilarVehicle, VehicleCreateContext, VehicleCreateForm} from '@dms/teas';

import {composePath, suffixTestId, TestIdProps, useRequiredParams} from 'shared';

import {useSelectVehicleContext} from '../../hooks/useSelectVehicleContext';

export interface BusinessCaseOffersCreateVehicleProps extends TestIdProps {
  onClose: () => void;
}

export const BusinessCaseOffersCreateVehicle: FC<BusinessCaseOffersCreateVehicleProps> = ({
  onClose,
  ...props
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {id: businessCaseId} = useRequiredParams();

  const [createPurchaseVehicle] = useCreateOfferPurchaseVehicleMutation();
  const [changeToBrokerageVehicle] = useChangeToPurchaseBrokeragePurchaseVehicleMutation();
  const [createOffer] = useCreateBusinessCaseOfferMutation();

  const {data: businessCase} = useGetBusinessCaseQuery({
    businessCaseId,
  });

  const offer = businessCase?.offers?.[0];
  const {purchaseVehicleType} = useSelectVehicleContext();
  const {handleSearchAuthorizationProfiles} = useLazyGetAuthorizationProfilesAndCKKPermissions();
  const [getVehicle] = useLazyGetVehicleQuery();

  const handleCreatePurchaseVehicle = async (
    vehicleId: string,
    makeSaleVehicleAvailableForSale?: boolean
  ): Promise<void> => {
    const offerId = offer?.id;
    const vehicleData = await getVehicle({vehicleId});

    // Offer already exists
    if (offerId) {
      await createPurchaseVehicle({businessCaseId, offerId, vehicleId})
        .unwrap()
        .catch(handleApiError);

      if (purchaseVehicleType === 'BROKERAGE') {
        await changeToBrokerageVehicle({businessCaseId, offerId, offerPurchaseVehicleId: vehicleId})
          .unwrap()
          .catch(handleApiError);
      }
    } else {
      await createOffer(
        purchaseVehicleType === 'IN_STOCK'
          ? {vehicleToPurchaseId: vehicleId, businessCaseId}
          : {vehicleToPurchaseBrokerageId: vehicleId, businessCaseId}
      )
        .unwrap()
        .catch(handleApiError);
      handleSearchAuthorizationProfiles(
        {
          vehicleMake: vehicleData?.data?.make ?? '',
          vehicleType: vehicleData?.data?.type ?? null,
          vehicleModelFamily: vehicleData?.data?.vehicle?.modelFamily ?? null,
          vehicleModelFamilyGroup: vehicleData?.data?.vehicle?.modelFamilyGroup ?? null,
        },
        businessCase?.customerId,
        {bcNumber: businessCase?.code, bcCreatedAt: businessCase?.createdAt}
      );
    }

    dispatch(businessCaseApi.util.invalidateTags(['SaleVehicleActions']));

    navigate(composePath(businessCaseRoutes.buying, {params: {id: businessCaseId}}), {
      state: {makeSaleVehicleAvailableForSale, ignoreUnmountEvents: true},
    });

    onClose();
  };

  const onVehicleSelect = async (
    vehicle: SimilarVehicle,
    makeSaleVehicleAvailableForSale?: boolean
  ) => {
    try {
      await handleCreatePurchaseVehicle(vehicle.id, makeSaleVehicleAvailableForSale);
    } catch (e: any) {
      handleApiError(e.response);
    }
  };

  return (
    <VehicleCreateContext onCreate={handleCreatePurchaseVehicle} onClose={onClose}>
      <VehicleCreateForm
        selectVehicleToPurchase
        source="PURCHASE_OR_PURCHASE_BROKERAGE_BUSINESS_CASE"
        cyId={testIds.businessCase.detail('addPurchaseVehicle')}
        onVehicleSelect={onVehicleSelect}
        data-testid={suffixTestId('vehicleCreateForm', props)}
      />
    </VehicleCreateContext>
  );
};
