import {omneticApi} from '@dms/api/core';
import {GetWindshieldApiArg, GetWindshieldApiRes, PutWindshieldApiArg} from '@dms/api/shared';

export const windshieldApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getWindshieldUrl: build.query<GetWindshieldApiRes, GetWindshieldApiArg>({
      query: ({branchId}) => ({
        url: `/dms/v1/sales/windshield`,
        params: {branchId},
      }),
      providesTags: ['windshield'],
    }),
    putWindshieldUrl: build.mutation<void, PutWindshieldApiArg>({
      query: ({branchId, body}) => ({
        url: `/dms/v1/sales/windshield`,
        params: {branchId},
        body,
        method: 'PUT',
      }),
      invalidatesTags: ['windshield'],
    }),
  }),
});

export const {useGetWindshieldUrlQuery, usePutWindshieldUrlMutation} = windshieldApi;
