import {DataStatus} from 'platform/components';

import {useParams} from 'react-router-dom';

import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetBackgroundRemovalV2Query} from '@dms/api/vehicle';
import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {BackgroundRemovalForm} from './components/BackgroundRemovalForm';

export function BackgroundRemovalDetail() {
  const {id} = useParams();

  const {data, isLoading, isError, isFetching} = useGetBackgroundRemovalV2Query(
    {settingId: id ?? ''},
    {
      skip: isNilOrEmpty(id),
      refetchOnMountOrArgChange: true,
    }
  );

  return (
    <SettingsTemplate
      header={{
        title: data?.name
          ? i18n.t('page.salesSettings.labels.backgroundRemovalDetailName', {name: data?.name})
          : i18n.t('page.salesSettings.backgroundRemoval.common.newConfiguration'),
        breadcrumbs: [
          {
            label: i18n.t('page.salesSettings.labels.advertising'),
            href: settingsRoutes.advertising,
          },
          {
            label: i18n.t('page.salesSettings.labels.backgroundRemoval'),
            href: settingsRoutes.backgroundRemoval,
          },
        ],
      }}
      data-testid="settings-backgroundRemoval"
      description={i18n.t('page.salesSettings.backgroundRemoval.common.descriptionDetail')}
    >
      <DataStatus isLoading={isLoading || isFetching} isError={isError}>
        <BackgroundRemovalForm settings={data} />
      </DataStatus>
    </SettingsTemplate>
  );
}
