import {FlagProps} from 'platform/components';

import {isNil} from 'ramda';

import {HeaderDynamicActionsFlags} from '@dms/api/shared';

import {Nullish} from 'shared';

/**
 * Transforms an array of flag objects into a format suitable for header display.

 * @param {HeaderFlags} flags - An array of flag objects to be converted.
 * @returns {FlagProps[]} An array of transformed flag objects, each containing label and colorScheme properties.
 *
 * @example
 * const flags = [
 *   { title: "New", color: "blue" },
 *   { title: "Sale", color: "red" }
 * ];
 * const headerFlags = createHeaderFlags(flags);
 * // headerFlags => [
 * //   { label: "New", colorScheme: "blue" },
 * //   { label: "Sale", colorScheme: "red" }
 * // ]
 */
export const createHeaderFlags = (flags: HeaderDynamicActionsFlags | Nullish): FlagProps[] => {
  if (isNil(flags)) {
    return [];
  }

  return flags.map((flag) => ({label: flag.title, colorScheme: flag.color}));
};
