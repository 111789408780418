import {omneticApi} from '@dms/api/core';

import {
  ChangeChecklistApiResponse,
  ChangeChecklistApiArg,
  CreateChecklistApiResponse,
  CreateChecklistApiArg,
  GetChecklistApiResponse,
  GetChecklistApiArg,
  CreateChecklistDefinitionApiResponse,
  CreateChecklistDefinitionApiArg,
  ListChecklistDefinitionApiResponse,
  ListChecklistDefinitionApiArg,
  DeleteChecklistDefinitionApiResponse,
  DeleteChecklistDefinitionApiArg,
  GetChecklistDefinitionApiResponse,
  GetChecklistDefinitionApiArg,
  UpdateChecklistDefinitionApiResponse,
  UpdateChecklistDefinitionApiArg,
  DeleteTaskFileApiResponse,
  DeleteTaskFileApiArg,
  UpdateTaskFileApiResponse,
  UpdateTaskFileApiArg,
  MarkTaskDoneApiResponse,
  MarkTaskDoneApiArg,
  MarkTaskUndoneApiResponse,
  MarkTaskUndoneApiArg,
  UpdateTaskNoteApiResponse,
  UpdateTaskNoteApiArg,
} from './types';

const injectedRtkApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    // Master checklist
    changeChecklist: build.mutation<ChangeChecklistApiResponse, ChangeChecklistApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/${queryArg.resourceId}/${queryArg.recordId}/checklist`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'Checklist', id: queryArg.recordId}],
    }),
    createChecklist: build.mutation<CreateChecklistApiResponse, CreateChecklistApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/${queryArg.resourceId}/${queryArg.recordId}/checklist`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'Checklist', id: queryArg.recordId}],
    }),
    getChecklist: build.query<GetChecklistApiResponse, GetChecklistApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/${queryArg.resourceId}/${queryArg.recordId}/checklist`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'Checklist', id: queryArg.recordId},
        {type: 'Checklist'},
      ],
    }),
    // Checklist settings
    createChecklistDefinition: build.mutation<
      CreateChecklistDefinitionApiResponse,
      CreateChecklistDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/checklist/definition`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: [{type: 'ChecklistDefinition'}, {type: 'Checklist'}],
    }),
    listChecklistDefinition: build.query<
      ListChecklistDefinitionApiResponse,
      ListChecklistDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/checklist/definition`,
        params: queryArg.resourceId ? {resourceId: queryArg.resourceId} : undefined,
      }),
    }),
    deleteChecklistDefinition: build.mutation<
      DeleteChecklistDefinitionApiResponse,
      DeleteChecklistDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/checklist/definition/${queryArg.definitionId}`,
        method: 'DELETE',
      }),
    }),
    getChecklistDefinition: build.query<
      GetChecklistDefinitionApiResponse,
      GetChecklistDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/checklist/definition/${queryArg.definitionId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'ChecklistDefinition', id: queryArg.definitionId},
      ],
    }),
    updateChecklistDefinition: build.mutation<
      UpdateChecklistDefinitionApiResponse,
      UpdateChecklistDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/checklist/definition/${queryArg.definitionId}`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'ChecklistDefinition', id: queryArg.definitionId},
      ],
    }),
    // Tasks
    deleteTaskFile: build.mutation<DeleteTaskFileApiResponse, DeleteTaskFileApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/${queryArg.resourceId}/${queryArg.recordId}/checklist/${queryArg.checklistId}/task/${queryArg.taskId}/file`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'Checklist', id: queryArg.recordId}],
    }),
    updateTaskFile: build.mutation<UpdateTaskFileApiResponse, UpdateTaskFileApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/${queryArg.resourceId}/${queryArg.recordId}/checklist/${queryArg.checklistId}/task/${queryArg.taskId}/file`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'Checklist', id: queryArg.recordId}],
    }),
    markTaskDone: build.mutation<MarkTaskDoneApiResponse, MarkTaskDoneApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/${queryArg.resourceId}/${queryArg.recordId}/checklist/${queryArg.checklistId}/task/${queryArg.taskId}/done`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'Checklist', id: queryArg.recordId}],
    }),
    markTaskUndone: build.mutation<MarkTaskUndoneApiResponse, MarkTaskUndoneApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/${queryArg.resourceId}/${queryArg.recordId}/checklist/${queryArg.checklistId}/task/${queryArg.taskId}/undone`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'Checklist', id: queryArg.recordId}],
    }),
    updateTaskNote: build.mutation<UpdateTaskNoteApiResponse, UpdateTaskNoteApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/${queryArg.resourceId}/${queryArg.recordId}/checklist/${queryArg.checklistId}/task/${queryArg.taskId}/note`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'Checklist', id: queryArg.recordId}],
    }),
  }),
  overrideExisting: false,
});
export {injectedRtkApi as checklistApi};

export const {
  useChangeChecklistMutation,
  useCreateChecklistMutation,
  useGetChecklistQuery,
  useLazyGetChecklistQuery,
  useCreateChecklistDefinitionMutation,
  useListChecklistDefinitionQuery,
  useDeleteChecklistDefinitionMutation,
  useGetChecklistDefinitionQuery,
  useUpdateChecklistDefinitionMutation,
  useDeleteTaskFileMutation,
  useUpdateTaskFileMutation,
  useMarkTaskDoneMutation,
  useMarkTaskUndoneMutation,
  useUpdateTaskNoteMutation,
} = injectedRtkApi;
