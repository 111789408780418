import {BaseQueryFn, fetchBaseQuery, FetchBaseQueryError} from '@reduxjs/toolkit/query';
import flagsmith from 'flagsmith';

// eslint-disable-next-line no-restricted-imports
import {not, omit, path} from 'ramda';

import {appWorkspaceKey, browserStorageKey, redirectLink} from '@dms/config';
import {environment} from '@dms/environment';
import {loginRoutes} from '@dms/routes';

import {BaseQueryExtraOptions, CI_MODE_ON, parseZodSchema, ParseZodSchemaQueryInfo} from 'shared';

import {publicApi} from '../modules/publicApi/publicApi';
import {RefreshTokenApiArg} from '../modules/publicApi/types';
import {initializeAxios} from './initializeAxios';
import {FetchBaseQueryArgs} from './types/FetchBaseQueryArgs';
import {buildUrl} from './utils/buildUrl';
import {getWorkspaceFromUri} from './utils/getWorkspaceFromUri';

const isDev = environment.ENV_TYPE === 'dev';
let isCI = false;
try {
  isCI = Boolean(process?.env?.GITLAB_CI);
} catch {}

const getArgsWithAuthorization = (args: FetchBaseQueryArgs, branch?: string) => {
  const accessToken = sessionStorage.getItem(browserStorageKey.ACCESS_TOKEN);
  const isCiModeOn = localStorage.getItem(browserStorageKey.CI_MODE) === CI_MODE_ON;
  const {workspace} = getWorkspaceFromUri();

  const headers = {
    ...args.headers,
    'accept-language': localStorage.getItem(browserStorageKey.LAST_KNOWN_LANGUAGE),
    'x-country-format': 'iso', // https://carvago.slack.com/archives/G01AN5XCXGX/p1634202539053900
    credentials: 'include',
    ...(accessToken ? {Authorization: `Bearer ${accessToken}`} : {}),
    ...(branch ? {'X-Branch': branch} : {}),
    ...(workspace ? {'X-Workspace': workspace} : {}),
    ...(isCiModeOn ? {'x-debug-mode': 'true'} : {}),
  };

  return {
    ...omit(['omitHeaders'], args),
    headers: omit(args.omitHeaders ?? [], headers),
  };
};

const redirectToLoginPage = () => {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.delete(redirectLink);

  const query = searchParams.toString();

  const redirectUrl = `${window.location.pathname}${query ? `?${query}` : ''}${
    window.location.hash
  }`;

  sessionStorage.setItem(redirectLink, redirectUrl);

  return `${loginRoutes.login}`;
};

const fetchBaseQueryInstance = fetchBaseQuery({baseUrl: environment.API_URL});

export const teasBaseQuery: BaseQueryFn<
  FetchBaseQueryArgs,
  unknown,
  FetchBaseQueryError,
  BaseQueryExtraOptions
> = async (args, api, extraOptions) => {
  initializeAxios(api);
  const activeBranchId = path<string>(['branch', 'activeBranchId'], api.getState());

  const queryInfo: Omit<ParseZodSchemaQueryInfo, 'schemaType'> = {
    method: args.method,
    endpointName: api.endpoint,
    url: args.url,
  };

  if (isDev && not(isCI) && extraOptions && extraOptions.requestSchema) {
    parseZodSchema({
      data: args.params,
      schema: extraOptions.requestSchema,
      queryInfo: {
        ...queryInfo,
        schemaType: 'request',
      },
    });
  }

  let result = await fetchBaseQueryInstance(
    getArgsWithAuthorization(args, activeBranchId),
    api,
    extraOptions
  );

  if (isDev && not(isCI) && extraOptions && extraOptions.responseSchema && 'data' in result) {
    parseZodSchema({
      data: result.data,
      schema: extraOptions.responseSchema,
      queryInfo: {
        ...queryInfo,
        schemaType: 'response',
      },
    });
  }

  if (result.error && result.error.status === 401) {
    const getRefreshToken = localStorage.getItem(browserStorageKey.REFRESH_TOKEN);
    const {workspace, shouldRedirectToAppWorkspace} = getWorkspaceFromUri();

    if (!workspace || shouldRedirectToAppWorkspace) {
      window.location.href = buildUrl(loginRoutes.loginWorkspace, appWorkspaceKey);
      return result;
    }

    if (!getRefreshToken) {
      window.location.href = buildUrl(redirectToLoginPage(), undefined, false);
      return result;
    }

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('directLink');

    const refreshTokenArgs: RefreshTokenApiArg = {
      workspace,
      refreshTokenRequestBody: {
        refreshToken: getRefreshToken,
      },
    };

    await api
      .dispatch(publicApi.endpoints.refreshToken.initiate(refreshTokenArgs))
      .unwrap()
      .then(({refreshToken, token}) => {
        sessionStorage.setItem(browserStorageKey.ACCESS_TOKEN, token);
        localStorage.setItem(browserStorageKey.REFRESH_TOKEN, refreshToken);
      })
      .catch(() => {
        sessionStorage.removeItem(browserStorageKey.ACCESS_TOKEN);
        localStorage.removeItem(browserStorageKey.REFRESH_TOKEN);
        flagsmith.logout();
        window.location.href = buildUrl(redirectToLoginPage(), undefined, false);
      });

    result = await fetchBaseQueryInstance(
      getArgsWithAuthorization(args, activeBranchId),
      api,
      extraOptions
    );
  }

  return result;
};
