import {combineReducers} from 'redux';

import {cachedApi} from '@dms/api/cached';
import {publicApi, omneticApi, metadaApi, fileStorageApi} from '@dms/api/core';
import {branchSlice} from '@dms/api/features';
import i18n from '@dms/i18n';
import {teasReducers} from '@dms/teas';

import {
  createLanguageCheckTransform,
  createDuplicateKeysCheckTransform,
  persistRTKQReducer,
} from 'features/rtkq-persist';

// Export transforms so they can be used for rehydration
export const languageTransform = createLanguageCheckTransform(() => i18n.language);
export const duplicateKeysTransform = createDuplicateKeysCheckTransform(() => i18n.language);

export const reducersDefinitions = {
  // RTK Query
  [publicApi.reducerPath]: publicApi.reducer,
  [omneticApi.reducerPath]: omneticApi.reducer,
  [metadaApi.reducerPath]: metadaApi.reducer,
  [fileStorageApi.reducerPath]: fileStorageApi.reducer,
  [cachedApi.reducerPath]: persistRTKQReducer(
    {
      key: cachedApi.reducerPath,
      transforms: [languageTransform, duplicateKeysTransform],
    },
    cachedApi.reducer
  ),
  [branchSlice.name]: branchSlice.reducer,
};

export const reducers = combineReducers({
  ...teasReducers,
  ...reducersDefinitions,
});

export type RootState = ReturnType<typeof reducers>;
