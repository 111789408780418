import {calculateTimePosition} from './calculateTimePosition';

/**
 * Calculates the duration in pixels between start and end time
 * @param start Start date
 * @param end End date
 * @returns Duration in pixels
 */
export function calculateDuration(start: Date, end: Date): number {
  const startPosition = calculateTimePosition(start);
  const endPosition = calculateTimePosition(end);
  return endPosition - startPosition;
}
