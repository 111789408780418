import {FormControl, HelperText, isFile, Label, Upload} from 'platform/components';
import {Box, Show} from 'platform/foundation';

import {useState} from 'react';
import {useController, UseFormReturn} from 'react-hook-form';

import {useUploadFileMutation} from '@dms/api/core';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {FileList} from '../../../components/FileList';
import {ImportFormValue} from '../types/ImportFormValue';

interface ImportFile {
  id: string;
  name: string;
  contentType: string;
  url: string;
}

interface UploadComponentProps {
  name: keyof ImportFormValue;
  control: FormControl<ImportFormValue>;
  formApi: UseFormReturn<ImportFormValue>;
}

export function UploadComponent(props: UploadComponentProps) {
  const [file, setFile] = useState<ImportFile | undefined>(undefined);

  const [uploadFile] = useUploadFileMutation();

  const field = useController({
    name: props.name,
    control: props.control,
  });

  return (
    <>
      <Show when={file}>
        <FileList
          contentType={file?.contentType}
          url={file?.url || ''}
          name={file?.name || ''}
          onDelete={() => {
            field.field.onChange('');
            setFile(undefined);
          }}
        />
      </Show>
      <Show when={!file}>
        <Box width="100%">
          <Label isRequired>{i18n.t('entity.document.actions.uploadDocument')}</Label>
          <Upload
            type="card"
            size="minHeight"
            customRequest={async ({file, onSuccess, onError}) => {
              if (!isFile(file)) {
                return;
              }

              await uploadFile({file})
                .unwrap()
                .then((data) => {
                  setFile({
                    id: data.fileId,
                    name: data.file.name,
                    contentType: data.file.type,
                    url: data.fileUri,
                  });

                  field.field.onChange(data.fileId);
                  onSuccess?.(data);
                })
                .catch((error: Error) => {
                  handleApiError(error);
                  onError?.(error);
                });
            }}
          />
          <HelperText errorMessage={field.fieldState.error?.message} />
        </Box>
      </Show>
    </>
  );
}
