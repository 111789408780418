import {
  BreadcrumbType,
  Form,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Grid, VStack} from 'platform/foundation';
import {object} from 'yup';

import {usePostConfigurationPaymentImportModeMutation} from '@dms/api/metadaAccounting';
import {PaymentImportMode} from '@dms/api/metadaErrorLogs';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError, useBranches} from '@dms/shared';

import {useNavigate, yupString} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {importTypeOptions} from './utils/importTypeOptions';

type FormValues = {
  branchId: string;
  paymentImportMode: PaymentImportMode;
};

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('page.accountingSettings.labels.importType'),
    href: settingsRoutes.taxDocumentIssuedType,
  },
];

export function TaxDocumentForPaymentImportTypeNew() {
  const navigate = useNavigate();
  const [createImportTypeDefinition] = usePostConfigurationPaymentImportModeMutation();

  const {branchOptions, isLoading, isError} = useBranches();

  const handleSubmit: FormSubmitHandler<FormValues> = async (data) =>
    await createImportTypeDefinition({body: data})
      .unwrap()
      .then(() => showNotification.success())
      .then(() => navigate(settingsRoutes.taxDocumentImportType))
      .catch(handleApiError);

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('page.accounting.labels.newDefinition'),
        breadcrumbs,
      }}
      data-testid={testIds.settings.taxDocumentImportTypeNew('page')}
      isLoading={isLoading}
      isError={isError}
      isCreating
    >
      <Form<FormValues> onSubmit={handleSubmit} schema={schema}>
        {(control) => (
          <VStack spacing={4}>
            <SettingsSection>
              <Grid columns={2}>
                <FormField
                  control={control}
                  type="choice"
                  name="branchId"
                  isNotClearable
                  options={branchOptions}
                  label={i18n.t('entity.cashRegister.parameters.branch')}
                  data-testid={testIds.settings.taxDocumentImportTypeNew('branch')}
                  isRequired
                />
                <FormField
                  control={control}
                  type="choice"
                  options={importTypeOptions}
                  name="paymentImportMode"
                  isRequired
                  label={i18n.t('page.accountingSettings.labels.importType')}
                  data-testid={testIds.settings.taxDocumentImportTypeNew('importType')}
                />
              </Grid>
            </SettingsSection>
            <SettingsFooter
              actions={[
                {
                  type: 'button',
                  title: i18n.t('general.actions.discardChanges'),
                  onClick: () => navigate(settingsRoutes.taxDocumentImportType),
                  variant: 'secondary',
                  'data-testid': testIds.settings.taxDocumentImportTypeNew('discard'),
                },
                {
                  type: 'form-button',
                  control,
                  buttonType: 'submit',
                  title: i18n.t('general.actions.saveChanges'),
                  'data-testid': testIds.settings.taxDocumentImportTypeNew('save'),
                },
              ]}
            />
          </VStack>
        )}
      </Form>
    </SettingsTemplate>
  );
}

const schema = object({
  branchId: yupString.required(),
  paymentImportMode: yupString.required(),
});
