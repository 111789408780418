import {Dialog, showNotification} from 'platform/components';

import {useState} from 'react';
import {Helmet} from 'react-helmet-async';

import {head, isEmpty, isNil} from 'ramda';

import {usePostServiceOrderReturnMutation} from '@dms/api/metadaWarehouseServiceOrderReturn';
import i18n from '@dms/i18n';
import {testIds, warehouseRoutes} from '@dms/routes';
import {handleApiError, logError} from '@dms/shared';

import {composePath, Nullish, useNavigate} from 'shared';

import {DataGrid, RowData} from 'features/datagrid';

export function ServiceOrderReturnsCreate() {
  const navigate = useNavigate();

  const [selectedServiceOrderId, setSelectedServiceOrderId] = useState<string | Nullish>(null);

  const [postServiceOrderReturn, {isLoading: isCreatingServiceOrderReturn}] =
    usePostServiceOrderReturnMutation();

  const handleOnRowSelectionChange = (rows: RowData[]) => {
    setSelectedServiceOrderId(head(rows)?.id);
  };

  const handleDiscard = () => {
    navigate(warehouseRoutes.serviceOrderReturns);
  };

  const handleAssignServiceOrder = () => {
    if (isNil(selectedServiceOrderId) || isEmpty(selectedServiceOrderId)) {
      return logError('No service order is selected');
    }

    postServiceOrderReturn({body: {serviceOrderId: selectedServiceOrderId}})
      .unwrap()
      .then((result) => {
        showNotification.success(i18n.t('general.notifications.successfullyCreated'));

        navigate(
          composePath(warehouseRoutes.serviceOrderReturnsDetailOverview, {params: {id: result.id}})
        );
      })
      .catch(handleApiError);
  };

  return (
    <>
      <Helmet title={i18n.t('page.warehouse.actions.newServiceOrderReturn')} />
      <Dialog
        isOpen
        title={i18n.t('entity.warehouse.labels.assignServiceOrder')}
        size="large"
        onClose={handleDiscard}
        data-testid={testIds.warehouse.serviceOrderReturnsCreate('assignServiceOrder')}
        buttons={[
          {
            type: 'button',
            variant: 'secondary',
            title: i18n.t('general.labels.discard'),
            onClick: handleDiscard,
            isLoading: isCreatingServiceOrderReturn,
          },
          {
            type: 'button',
            title: i18n.t('general.labels.assign'),
            onClick: handleAssignServiceOrder,
            isDisabled: isNil(selectedServiceOrderId),
            isLoading: isCreatingServiceOrderReturn,
            'data-testid': testIds.warehouse.serviceOrderReturnsCreate('assign'),
          },
        ]}
      >
        <DataGrid
          gridCode="warehouse-service-order-parts-return-select-order"
          onRowSelectionChange={handleOnRowSelectionChange}
          autoHeight
          data-testid={testIds.warehouse.serviceOrderReturnsCreate('serviceOrders')}
        />
      </Dialog>
    </>
  );
}
