import {omneticApi} from '../../baseApi/omneticApi';
import {
  GetBillingInformationV2ApiArg,
  GetBillingInformationV2ApiRes,
  GetBranchV2ApiRes,
  GetTenantV2ApiRes,
  GetBranchV2ApiArg,
  PostBillingInformationV2ApiArg,
  PutBillingInformationV2ApiArg,
  PutTenantV2ApiReq,
  PutBranchV2ApiRes,
  PutBranchV2ApiArg,
  PostBranchV2ApiRes,
  PostBranchV2ApiArg,
} from './types';

export const tenantNewApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getTenantV2: build.query<GetTenantV2ApiRes, void>({
      query: () => ({
        url: `/dms/v2/tenant`,
      }),
      providesTags: ['Tenant'],
    }),
    putTenantV2: build.mutation<void, PutTenantV2ApiReq>({
      query: (body) => ({
        url: `/dms/v2/tenant`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Tenant'],
    }),
    postBillingInformationV2: build.mutation<
      GetBillingInformationV2ApiRes,
      PostBillingInformationV2ApiArg
    >({
      query: (body) => ({
        url: `/dms/v2/tenant/billing-information`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result) => [
        {type: 'BillingInformation', id: result?.id},
        'BillingInformationList',
      ],
    }),
    putBillingInformationV2: build.mutation<
      GetBillingInformationV2ApiRes,
      PutBillingInformationV2ApiArg
    >({
      query: ({id, ...body}) => ({
        url: `/dms/v2/tenant/billing-information/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'BillingInformation', id: queryArg.id},
        'BillingInformationList',
      ],
    }),
    getBillingInformationV2: build.query<
      GetBillingInformationV2ApiRes,
      GetBillingInformationV2ApiArg
    >({
      query: ({id}) => ({
        url: `/dms/v2/tenant/billing-information/${id}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'BillingInformation', id: queryArg.id},
        'BillingInformationList',
      ],
    }),
    getBranchV2: build.query<GetBranchV2ApiRes, GetBranchV2ApiArg>({
      query: ({id}) => ({
        url: `/dms/v2/tenant/branch/${id}`,
      }),
      providesTags: (result, error, queryArg) => [{type: 'Branch', id: queryArg.id}],
    }),
    putBranchV2: build.mutation<PutBranchV2ApiRes, PutBranchV2ApiArg>({
      query: ({id, ...body}) => ({
        url: `/dms/v2/tenant/branch/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'Branch', id: queryArg.id},
        'BranchList',
      ],
    }),
    postBranchV2: build.mutation<PostBranchV2ApiRes, PostBranchV2ApiArg>({
      query: (body) => ({
        url: `/dms/v2/tenant/branch`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result) => [{type: 'Branch', id: result?.id}, 'BranchList'],
    }),
  }),
});

export const {
  usePostBranchV2Mutation,
  useGetBranchV2Query,
  usePutBranchV2Mutation,
  useGetTenantV2Query,
  usePutTenantV2Mutation,
  usePostBillingInformationV2Mutation,
  usePutBillingInformationV2Mutation,
  useGetBillingInformationV2Query,
} = tenantNewApi;
