export function TaskCompletedImage() {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M45 66.6666C45 66.6666 48.3333 66.6666 51.6667 73.3333C51.6667 73.3333 62.255 56.6666 71.6667 53.3333"
        stroke="#0065FF"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.3333 53.3334H36.6666M23.3333 36.6667H49.9999"
        stroke="#BFE0FF"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M21.6667 11.6667C16.4807 11.8223 13.3887 12.3995 11.2492 14.541C8.32031 17.4726 8.32031 22.1911 8.32031 31.628V53.3148C8.32031 62.7518 8.32031 67.4701 11.2492 70.4018C14.1782 73.3334 18.8922 73.3334 28.3203 73.3334H36.6537M51.6407 11.6667C56.8267 11.8223 59.9187 12.3995 62.058 14.541C64.987 17.4726 64.987 22.1911 64.987 31.628V45.0001"
        stroke="#BFE0FF"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M21.6536 12.5001C21.6536 9.27841 24.2653 6.66675 27.4869 6.66675H45.8203C49.0419 6.66675 51.6536 9.27841 51.6536 12.5001C51.6536 15.7217 49.0419 18.3334 45.8203 18.3334H27.4869C24.2653 18.3334 21.6536 15.7217 21.6536 12.5001Z"
        stroke="#BFE0FF"
        strokeWidth="3"
        strokeLinejoin="round"
      />
    </svg>
  );
}
