import {AttributesRow} from 'platform/components';

import {defaultTo, find} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {EntityResourceIds} from '@dms/api/shared';
import {useGetTagsForEntityQuery} from '@dms/api/tag';
import {useGetVehicleQuery} from '@dms/api/vehicle';
import {useGetMakeModelWithMakeQuery} from '@dms/api/vehicleCatalogue';
import i18n from '@dms/i18n';

import {Nullish, buildArray} from 'shared';

import {ValueWithTags} from '../components/ValueWithTags/ValueWithTags';
import {useVehicleCatalogue} from './useVehicleCatalogue';

export function useGeneralVehicleParameters(vehicleId: string | Nullish) {
  const {data: vehicle} = useGetVehicleQuery(
    {vehicleId: defaultTo('', vehicleId)},
    {skip: !vehicleId}
  );
  const [vehicleUtils] = useVehicleCatalogue(vehicle?.type);
  const {make, model} = useGetMakeModelWithMakeQuery(
    {
      make: vehicle?.make ?? '',
      vehicleType: vehicle?.type ?? '',
      lang: [i18n.language],
    },
    {
      skip: !vehicle?.make || !vehicle?.type,
      selectFromResult: ({data, ...other}) => ({
        ...other,
        make: find((makeItem) => makeItem.make === vehicle?.make, data ?? [])?.default_label,
        model: find(
          (modelItem) => modelItem.model === vehicle?.modelFamily,
          find((makeItem) => makeItem.make === vehicle?.make, data ?? [])?.models ?? []
        )?.default_label,
      }),
    }
  );
  const {data: tags} = useGetTagsForEntityQuery(
    {
      resourceId: EntityResourceIds.vehicle,
      recordId: vehicleId ?? '',
    },
    {skip: !vehicleId}
  );

  const type = vehicleUtils.getVehicleType(vehicle?.type);

  return buildArray<AttributesRow>()
    .add({
      label: i18n.t('entity.vehicle.labels.licensePlate'),
      value: vehicle?.state?.registrationPlate,
      testId: 'licensePlate',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.vin'),
      value: vehicle?.vin,
      testId: 'vin',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.type'),
      value: type,
      testId: 'type',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.title'),
      content: <ValueWithTags value={vehicle?.title} tags={tags} />,
      testId: 'title',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.make'),
      value: make,
      testId: 'make',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.model'),
      value: model,
      testId: 'model',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.trim'),
      value: vehicle?.trim,
      testId: 'trim',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.variantName'),
      value: vehicle?.variant,
      testId: 'variantName',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.firstRegistration'),
      value: `${
        isNotNil(vehicle?.firstRegistrationOnDay) ? `${vehicle?.firstRegistrationOnDay}.` : ''
      }${
        isNotNil(vehicle?.firstRegistrationOnMonth) ? `${vehicle?.firstRegistrationOnMonth}.` : ''
      }${vehicle?.firstRegistrationOnYear ?? ''}`,
      testId: 'firstRegistration',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.realProduction'),
      value: `${isNotNil(vehicle?.manufacturedOnMonth) ? `${vehicle?.manufacturedOnMonth}/` : ''}${
        vehicle?.manufacturedOnYear ?? ''
      }`,
      testId: 'realProduction',
    });
}
