import {useEffect} from 'react';

import {isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  useGetTransactionQuery,
  useGetSignableDocumentTransactionQuery,
} from '@dms/api/digitalSignature';

import {Nullish} from 'shared';

import {DEFAULT_SIGNING_STEP} from '../constants/DEFAULT_SIGNING_STEP';
import {SignDocumentStep} from '../types/SignDocumentStep';

type GetInitialStepProps = {
  fileId: string | Nullish;
  signingStep: SignDocumentStep;
  setSigningStep: (step: SignDocumentStep) => void;
};

export const useSetupDefaultTransactionValues = (props: GetInitialStepProps) => {
  const {currentData: signableDocument, isLoading: isLoadingDocument} =
    useGetSignableDocumentTransactionQuery(
      {fileId: props.fileId ?? ''},
      {skip: isNilOrEmpty(props.fileId), refetchOnMountOrArgChange: true}
    );

  const {
    currentData: transaction,
    isLoading: isLoadingTransaction,
    isError: isTransactionError,
  } = useGetTransactionQuery(
    {transactionId: signableDocument?.transactionId ?? ''},
    {skip: isNilOrEmpty(signableDocument?.transactionId), refetchOnMountOrArgChange: true}
  );

  const isLoading = isLoadingDocument || isLoadingTransaction;

  useEffect(() => {
    if (props.signingStep !== DEFAULT_SIGNING_STEP || isNil(transaction) || isTransactionError) {
      return;
    }

    const currentStep =
      transaction.tasks?.[0]?.transactionType === 'local'
        ? 'OnSite_qrCode'
        : 'Remote_sentToSignatories';

    props.setSigningStep(currentStep);
    // rules want [signingStep], we use it for checking initial state, and using it would cause infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction]);

  return {isLoading, transaction, isTransactionError};
};
