import {Button, ConfirmDialog, DataStatus, useDialog} from 'platform/components';
import {Box, Heading, HStack} from 'platform/foundation';
import {useFormatNumber} from 'platform/locale';

import {Helmet} from 'react-helmet-async';

import {isNil} from 'ramda';

import {
  SourcingVehicleDetailResponseBody,
  useSourcingBulkDeleteUserComparisonMutation,
  useSourcingListUserComparisonQuery,
  useSourcingReorderUserComparisonMutation,
} from '@dms/api/sourcing';
import {useGetUserStatsQuery} from '@dms/api/user';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {CURRENCY_CZK, formatDocumentTitle} from '@dms/shared';
import {COUNTRY_CZE} from '@dms/teas';

import {useSourcingCurrency} from '../../hooks/useSourcingCurrency';
import {useSourcingTargetCountry} from '../../hooks/useSourcingTargetCountry';
import {MinMaxVehicles} from '../../types/MinMaxVehicles';
import {SectionType} from '../../types/SectionType';
import {ColumnHeader} from './components/ColumnHeader';
import {ComparisonTable} from './components/ComparisonTable';
import {getComparisonSections} from './utils/getComparisonSections';
import {sortAndUpdateVehicleFeatures} from './utils/sortAndUpdateVehicleFeatures';

const reorder = (
  arr: SourcingVehicleDetailResponseBody[],
  startIndex: number,
  endIndex: number
): (SourcingVehicleDetailResponseBody & {position: number})[] => {
  const result = [...arr];
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result.map((vehicle, index) => ({...vehicle, position: index + 1}));
};

const initialMinMaxValues = {
  minMileage: {},
  maxMileage: {},
  minOriginalPrice: {},
  maxOriginalPrice: {},
  minManufactureYear: {},
  maxManufactureYear: {},
};

export function UserComparisonPage() {
  const formatNumber = useFormatNumber();
  const {data: userStats, isLoading: isUserStatsLoading} = useGetUserStatsQuery();
  const comparisonCount = userStats?.userStats.comparisonCount;

  const currency = useSourcingCurrency();
  const targetCountry = useSourcingTargetCountry();

  const {data, isLoading} = useSourcingListUserComparisonQuery(
    {
      currency: currency?.code ?? CURRENCY_CZK,
      buyerCountry: targetCountry?.code ?? COUNTRY_CZE,
      size: comparisonCount,
    },
    {skip: isNil(comparisonCount)}
  );
  const vehicles = data?.data ?? [];

  const [isOpen, open, close] = useDialog();
  const [deleteUserComparison] = useSourcingBulkDeleteUserComparisonMutation();
  const [reorderVehicles] = useSourcingReorderUserComparisonMutation();

  const minMaxVehicles = isLoading
    ? {...initialMinMaxValues}
    : vehicles.reduce(
        (
          {
            minMileage,
            maxMileage,
            minOriginalPrice,
            maxOriginalPrice,
            minManufactureYear,
            maxManufactureYear,
          },
          current
        ) => ({
          minOriginalPrice:
            (minOriginalPrice.sourcingVehicle?.price?.withVat ?? 0) <
            (current.sourcingVehicle?.price?.withVat ?? 0)
              ? minOriginalPrice
              : current,
          maxOriginalPrice:
            (maxOriginalPrice.sourcingVehicle?.price?.withVat ?? 0) >
            (current.sourcingVehicle?.price?.withVat ?? 0)
              ? maxOriginalPrice
              : current,
          minMileage:
            (minMileage.sourcingVehicle?.mileage ?? 0) < (current.sourcingVehicle?.mileage ?? 0)
              ? minMileage
              : current,
          maxMileage:
            (maxMileage.sourcingVehicle?.mileage ?? 0) > (current.sourcingVehicle?.mileage ?? 0)
              ? maxMileage
              : current,
          minManufactureYear:
            (minManufactureYear.sourcingVehicle?.year ?? 0) < (current.sourcingVehicle?.year ?? 0)
              ? minManufactureYear
              : current,
          maxManufactureYear:
            (maxManufactureYear.sourcingVehicle?.year ?? 0) > (current.sourcingVehicle?.year ?? 0)
              ? maxManufactureYear
              : current,
        }),
        {...initialMinMaxValues} as MinMaxVehicles<SourcingVehicleDetailResponseBody>
      );

  const vehiclesWithFeatures = sortAndUpdateVehicleFeatures(vehicles);

  const sections = vehicles.length
    ? getComparisonSections(formatNumber, {
        minMaxVehicles: minMaxVehicles as MinMaxVehicles<SourcingVehicleDetailResponseBody>,
      })
    : [];

  const handleDragEnd = ({source, destination}: any) => {
    if (!destination || source.index === destination.index) {
      return;
    }

    const reorderedVehicles = reorder(vehicles, source.index, destination.index);
    reorderVehicles(reorderedVehicles);
  };

  return (
    <>
      <Helmet>
        <title>
          {formatDocumentTitle(
            i18n.t('general.actions.comparison'),
            i18n.t('page.sourcing.labels.classifieds'),
            i18n.t('page.sourcing.labels.sourcing')
          )}
        </title>
      </Helmet>

      <DataStatus
        isEmpty={vehicles.length === 0}
        isLoading={isUserStatsLoading || isLoading}
        emptyMessage={i18n.t('entity.vehicle.notifications.nothingInComparison')}
        minHeight="100%"
      >
        <Box
          paddingHorizontal={4}
          paddingVertical={2}
          backgroundColor="palettes.neutral.20.100"
          borderBottom="1px solid"
          borderColor="general.separator"
        >
          <HStack align="center" justify="space-between">
            <Heading alternative size={3}>
              {i18n.t('entity.vehicle.labels.comparisionPage')}
            </Heading>
            <Button
              variant="secondary"
              title={i18n.t('general.actions.deleteList')}
              leftIcon="action/delete_outline"
              isDisabled={isUserStatsLoading || isLoading}
              onClick={open}
              data-testid={testIds.sourcing.classifieds_comparison('deleteButton')}
            />
          </HStack>
        </Box>

        <ComparisonTable
          sections={sections as SectionType[]}
          data={vehiclesWithFeatures}
          onDragEnd={handleDragEnd}
          renderHeaderCell={(vehicle) => (
            <ColumnHeader
              vehicle={vehicle}
              data-testid={testIds.sourcing.classifieds_comparison('headerCell')}
            />
          )}
          data-testid={testIds.sourcing.classifieds_comparison('comparisonTable')}
        />

        <ConfirmDialog
          text={i18n.t('general.actions.deleteList')}
          onConfirm={() => {
            deleteUserComparison({
              body: {externalIds: vehicles.map((vehicle) => vehicle.sourcingVehicle.adId)},
            });
          }}
          onClose={close}
          isOpen={isOpen}
          data-testid={testIds.sourcing.classifieds_comparison('deleteDialog')}
        />
      </DataStatus>
    </>
  );
}
