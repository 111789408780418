import {ThunkDispatch} from '@reduxjs/toolkit';
import {RootState} from '@reduxjs/toolkit/query';

import {UnknownAction} from 'redux';

import {CreateUserVehicleSummaryRequestBody, SourcingVehicleListResponseBody} from '../types';

export type OmneticMutationLifecycleApi<T> = {
  dispatch: ThunkDispatch<any, any, UnknownAction>;
  getState: () => RootState<any, any, 'rtk_omneticApi'>;
  queryFulfilled: Promise<any>;
};

export type List = 'favourite' | 'comparison' | 'inCart' | 'hidden';

const LIST_QUERY_NAMES = [
  'sourcingVehicleList',
  'sourcingListUserFavourite',
  'sourcingListUserToBuy',
  'sourcingListUserHidden',
  'sourcingListUserComparison',
] as const;

export function isSourcingListEndpointName(
  endpointName: string
): endpointName is (typeof LIST_QUERY_NAMES)[number] {
  return LIST_QUERY_NAMES.includes(endpointName as (typeof LIST_QUERY_NAMES)[number]);
}

export function setUserVehicleSummary(
  vehicleId: string,
  body: CreateUserVehicleSummaryRequestBody,
  response: SourcingVehicleListResponseBody
) {
  response.data.forEach((vehicle) => {
    if (vehicle.sourcingVehicle.adId === vehicleId) {
      vehicle.vehicleSummary = {
        comment: body.comment,
        purchasePrice: body.purchasePrice,
        sellingPrice: body.sellingPrice,
      };
    }
  });
}
