import {
  Card,
  Form,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Grid, VStack} from 'platform/foundation';
import {boolean, object} from 'yup';

import {isFalse} from 'ramda-adjunct';

import {
  useGetPreAccountingProcessingSettingsQuery,
  useUpdatePreAccountingProcessingSettingsMutation,
} from '@dms/api/accounting';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError, useBranches} from '@dms/shared';

import {NullishBoolean, useNavigate, useRequiredParams, yupString} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

type FormValues = {
  branchId: string | null;
  notifyAccountingDocuments: boolean;
  notifyStockReceipts: boolean;
  notifyStockIssues: boolean;
  notifyStockTransfers: boolean;
};

const breadcrumbs = [
  {
    label: i18n.t('page.businessCases.title'),
    href: settingsRoutes.businessCase,
  },
  {
    label: i18n.t('page.accounting.labels.preProcessingCalculation'),
    href: settingsRoutes.preAccountingProcessingOverview,
  },
];

export function PreAccountingProcessingDetail() {
  const navigate = useNavigate();
  const {id} = useRequiredParams();

  const {
    data: processingItem,
    isLoading: isProcessingItemLoading,
    isError: isProcessingItemError,
  } = useGetPreAccountingProcessingSettingsQuery({id});

  const {
    data: branches,
    branchOptions,
    isLoading: isLoadingBranches,
    isError: isBranchesError,
  } = useBranches();

  const [updateProcessingItem] = useUpdatePreAccountingProcessingSettingsMutation();

  const handleSubmit: FormSubmitHandler<FormValues> = async (values) => {
    await updateProcessingItem({
      id,
      updatePreAccountingProcessingSettingsRequestBody: values,
    })
      .unwrap()
      .then(() =>
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'))
      )
      .then(() => navigate(settingsRoutes.preAccountingProcessingOverview))
      .catch(handleApiError);
  };

  const defaultValues = {
    branchId: processingItem?.branchId,
    notifyAccountingDocuments: processingItem?.notifyAccountingDocuments,
    notifyStockReceipts: processingItem?.notifyStockReceipts,
    notifyStockIssues: processingItem?.notifyStockIssues,
    notifyStockTransfers: processingItem?.notifyStockTransfers,
  };

  const isLoading = isProcessingItemLoading || isLoadingBranches;
  const isError = isProcessingItemError || isBranchesError;

  const branchName =
    branches?.branchListItems.find((branch) => branch.id === processingItem?.branchId)
      ?.marketingName ?? i18n.t('page.accounting.labels.tenantDefinition');

  return (
    <SettingsTemplate
      isError={isError}
      isLoading={isLoading}
      header={{
        title: branchName,
        breadcrumbs,
      }}
      data-testid={testIds.settings.preAccountingProcessingDetail('settings-template')}
    >
      <Form<FormValues>
        onSubmit={handleSubmit}
        schema={getSchema(processingItem?.isSystem)}
        defaultValues={defaultValues}
      >
        {(control) => (
          <VStack spacing={4}>
            <SettingsSection>
              <Grid columns={2}>
                <FormField
                  control={control}
                  type="choice"
                  name="branchId"
                  isNotClearable
                  options={branchOptions}
                  label={i18n.t('entity.cashRegister.parameters.branch')}
                  isRequired
                  isDisabled={processingItem?.isSystem}
                  data-testid={testIds.settings.preAccountingProcessingDetail('branch')}
                  placeholder={
                    processingItem?.isSystem
                      ? i18n.t('page.accounting.labels.tenantDefinition')
                      : undefined
                  }
                />
              </Grid>
              <Separator />

              <VStack spacing={4}>
                <Card variant="inlineGrey" title={i18n.t('entity.accounting.labels.accounting')}>
                  <FormField
                    control={control}
                    type="checkbox"
                    name="notifyAccountingDocuments"
                    label={i18n.t('entity.accounting.labels.notifyAccountingDocuments')}
                    data-testid={testIds.settings.preAccountingProcessingDetail(
                      'notifyAccountingDocuments'
                    )}
                  />
                </Card>

                <Card
                  variant="inlineGrey"
                  title={i18n.t('page.accountingSettings.labels.warehouseMovements')}
                >
                  <VStack spacing={2}>
                    <FormField
                      control={control}
                      type="checkbox"
                      name="notifyStockReceipts"
                      label={i18n.t('page.accountingSettings.labels.notifyReceipt')}
                      data-testid={testIds.settings.preAccountingProcessingDetail('notifyReceipt')}
                    />
                    <FormField
                      control={control}
                      type="checkbox"
                      name="notifyStockIssues"
                      label={i18n.t('page.accountingSettings.labels.notifyIssue')}
                      data-testid={testIds.settings.preAccountingProcessingDetail('notifyIssue')}
                    />
                    <FormField
                      control={control}
                      type="checkbox"
                      name="notifyStockTransfers"
                      label={i18n.t('page.accountingSettings.labels.notifyTransfer')}
                      data-testid={testIds.settings.preAccountingProcessingDetail('notifyTransfer')}
                    />
                  </VStack>
                </Card>
              </VStack>
            </SettingsSection>

            <SettingsFooter
              actions={[
                {
                  type: 'button',
                  title: i18n.t('general.actions.discardChanges'),
                  onClick: () => navigate(settingsRoutes.preAccountingProcessingOverview),
                  variant: 'secondary',
                },
                {
                  type: 'form-button',
                  control,
                  buttonType: 'submit',
                  title: i18n.t('general.actions.saveChanges'),
                },
              ]}
            />
          </VStack>
        )}
      </Form>
    </SettingsTemplate>
  );
}

const getSchema = (isSystem: NullishBoolean) =>
  object({
    branchId: yupString.when([], {
      is: () => isFalse(isSystem),
      then: (schema) => schema.required(),
    }),
    notifyAccountingDocuments: boolean().default(false).required(),
    notifyStockReceipts: boolean().default(false).required(),
    notifyStockIssues: boolean().default(false).required(),
    notifyStockTransfers: boolean().default(false).required(),
  });
