import {match} from 'ts-pattern';

import {always, isNil} from 'ramda';

import {IntegrationExternalStatus} from '@dms/api/metadaWorkshopServiceOrder';
import i18n from '@dms/i18n';

import {Nullish} from 'shared';

export const getExternalStatusLabel = (key?: IntegrationExternalStatus | Nullish) =>
  match(key)
    .when(isNil, always(''))
    .with('PRE_APPROVAL', always(i18n.t('entity.orderIntegration.labels.preApproval')))
    .with('SUBMITTED', always(i18n.t('entity.orderIntegration.labels.submitted')))
    .with('REOPENED', always(i18n.t('entity.orderIntegration.labels.reopened')))
    .with('APPEALED', always(i18n.t('entity.orderIntegration.labels.appealed')))
    .with('PENDING_PAYMENT', always(i18n.t('entity.orderIntegration.labels.pendingPayment')))
    .with('DRAFT', always(i18n.t('entity.orderIntegration.labels.draft')))
    .with('PENDING_APPROVAL', always(i18n.t('entity.orderIntegration.labels.pendingApproval')))
    .with('PENDING_REVIEW', always(i18n.t('entity.orderIntegration.labels.pendingReview')))
    .with('RETURNED', always(i18n.t('entity.orderIntegration.labels.returned')))
    .with('INFORMATION_NEEDED', always(i18n.t('entity.orderIntegration.labels.informationNeeded')))
    .with('REJECTED', always(i18n.t('entity.orderIntegration.labels.rejected')))
    .with('NOT_CREATED', always(i18n.t('entity.orderIntegration.labels.notCreated')))
    .with('APPROVED', always(i18n.t('entity.orderIntegration.labels.approved')))
    .with('PAID', always(i18n.t('entity.orderIntegration.labels.paid')))
    .with('CLOSED', always(i18n.t('entity.orderIntegration.labels.closed')))
    .exhaustive();
