import {z} from 'zod';

import {
  BaseVoidResponseSchema,
  OriginEntityHeaderTypeSchema,
  OriginEntityTypeSchema,
} from '@dms/api/shared';

/**
 * Base types
 */
export const ArticleIdSchema = z.object({
  articleId: z.string(),
});

export const RequestItemIdSchema = z.object({
  requestItemId: z.string(),
});

const RequestItemsSchema = z.object({
  originEntityId: z.string(),
  articleId: z.string(),
  originEntityHeaderType: OriginEntityHeaderTypeSchema,
  originEntityHeaderId: z.string().optional(),
  proposedSalePrice: z.number().optional(),
  quantity: z.number(),
  itemNeededAtDate: z.string().optional(),
  requestExpiresAtDate: z.string().optional(),
  note: z.string().optional(),
});

const BasePartsRequestSchema = z.object({
  originEntityType: OriginEntityTypeSchema,
  requestItems: z.array(RequestItemsSchema),
});
export type BasePartsRequest = z.infer<typeof BasePartsRequestSchema>;

const BaseServiceOrderSchema = z.object({
  caseId: z.string(),
  orderId: z.string(),
});
export type BaseServiceOrder = z.infer<typeof BaseServiceOrderSchema>;

export const ReservationRequestSchema = z.object({
  articleId: z.string(),
  requestId: z.string().optional(),
});

/**
 * GET requests
 */
export const GetPartsRequestRequestSchema = ArticleIdSchema;
export type GetPartsRequestRequest = z.infer<typeof GetPartsRequestRequestSchema>;

export const GetPartsRequestResponseSchema = z.object({
  total: z.number(),
  serviceOrder: z.number(),
  serviceOrderIssue: z.number(),
  warehouseSale: z.number(),
  vehicle: z.number(),
  customer: z.number(),
  contact: z.number(),
  other: z.number(),
});
export type GetPartsRequestResponse = z.infer<typeof GetPartsRequestResponseSchema>;

export const GetReservationOtherRequestSchema = ReservationRequestSchema;
export type GetReservationOtherRequest = z.infer<typeof GetReservationOtherRequestSchema>;

export const GetReservationOtherResponseSchema = z.object({
  articleId: z.string(),
  requestItemId: z.string(),
  quantity: z.number(),
  requestExpiresAtDate: z.string().optional(),
  note: z.string(),
});
export type GetReservationOtherResponse = z.infer<typeof GetReservationOtherResponseSchema>;

export const GetReservationCustomerRequestSchema = ReservationRequestSchema;
export type GetReservationCustomerRequest = z.infer<typeof GetReservationCustomerRequestSchema>;

export const GetReservationCustomerResponseSchema = z.object({
  articleId: z.string(),
  requestItemId: z.string(),
  quantity: z.number(),
  requestExpiresAtDate: z.string().optional(),
  note: z.string().optional(),
  customerId: z.string(),
});
export type GetReservationCustomerResponse = z.infer<typeof GetReservationCustomerResponseSchema>;

export const GetReservationServiceOrderRequestSchema = ReservationRequestSchema;
export type GetReservationServiceOrderRequest = z.infer<
  typeof GetReservationServiceOrderRequestSchema
>;

export const GetReservationServiceOrderResponseSchema = z.object({
  articleId: z.string(),
  requestItemId: z.string(),
  quantity: z.number(),
  requestExpiresAtDate: z.string().optional(),
  note: z.string().optional(),
  customerId: z.string().optional(),
  vehicleId: z.string().optional(),
  serviceOrder: BaseServiceOrderSchema.merge(
    z.object({
      number: z.string(),
      type: z.string(),
      variant: z.string(),
    })
  ),
  serviceOrderIssue: z.object({serviceOrderIssueId: z.string(), number: z.string()}).optional(),
});
export type GetReservationServiceOrderResponse = z.infer<
  typeof GetReservationServiceOrderResponseSchema
>;

export const GetReservationDirectSalesRequestSchema = ReservationRequestSchema;
export type GetReservationDirectSalesRequest = z.infer<
  typeof GetReservationDirectSalesRequestSchema
>;

export const GetReservationDirectSalesResponseSchema = z.object({
  articleId: z.string(),
  requestItemId: z.string(),
  quantity: z.number(),
  requestExpiresAtDate: z.string().optional(),
  note: z.string().optional(),
  customerId: z.string().optional(),
  vehicleId: z.string().optional(),
  directSale: z.object({
    directSaleId: z.string(),
    number: z.string(),
    type: z.string(),
    variant: z.string(),
  }),
});
export type GetReservationDirectSalesResponse = z.infer<
  typeof GetReservationDirectSalesResponseSchema
>;

/**
 * POST requests
 */
export const PostPartsRequestRequestSchema = z.object({
  directSaleId: z.string(),
  body: BasePartsRequestSchema,
});
export type PostPartsRequestRequest = z.infer<typeof PostPartsRequestRequestSchema>;

export const PostPartsRequestResponseSchema = z.object({
  requestItems: z.array(
    z.object({
      articleId: z.string(),
      originEntityId: z.string(),
      requests: z.array(
        z.object({
          requestId: z.string(),
          state: z.string(),
          quantity: z.number(),
        })
      ),
    })
  ),
});
export type PostPartsRequestResponse = z.infer<typeof PostPartsRequestResponseSchema>;

const ReservationServiceOrderSchema = z.object({
  articleId: z.string(),
  quantity: z.number(),
  requestExpiresAtDate: z.string().nullish(),
  note: z.string().nullish(),
  orders: z.array(BaseServiceOrderSchema),
});

export const PostReservationServiceOrderRequestSchema = ArticleIdSchema.merge(
  ReservationServiceOrderSchema
);

export type PostReservationServiceOrderRequest = z.infer<
  typeof PostReservationServiceOrderRequestSchema
>;

export const PostReservationServiceOrderResponseSchema = z.object({
  articleId: z.string(),
  articleQuantity: z.number(),
  createdItems: z.array(
    BaseServiceOrderSchema.merge(
      z.object({
        jobId: z.string(),
        itemId: z.string(),
        requestItemId: z.array(z.string()),
      })
    )
  ),
});
export type PostReservationServiceOrderResponse = z.infer<
  typeof PostReservationServiceOrderResponseSchema
>;

const ReservationOtherSchema = z.object({
  quantity: z.number(),
  requestExpiresAtDate: z.string().nullish(),
  note: z.string(),
});

export const PostReservationOtherRequestSchema = ArticleIdSchema.merge(ReservationOtherSchema);
export type PostReservationOtherRequest = z.infer<typeof PostReservationOtherRequestSchema>;

export const PostReservationOtherResponseSchema = RequestItemIdSchema;
export type PostReservationOtherResponse = z.infer<typeof PostReservationOtherResponseSchema>;

const ReservationCustomerSchema = z.object({
  quantity: z.number(),
  requestExpiresAtDate: z.string().nullish(),
  note: z.string().nullish(),
  customerId: z.string(),
});

export const PostReservationCustomerRequestSchema =
  ArticleIdSchema.merge(ReservationCustomerSchema);
export type PostReservationCustomerRequest = z.infer<typeof PostReservationCustomerRequestSchema>;

export const PostReservationCustomerResponseSchema = RequestItemIdSchema;
export type PostReservationCustomerResponse = z.infer<typeof PostReservationCustomerResponseSchema>;

const ReservationDirectSalesSchema = z.object({
  articleId: z.string(),
  quantity: z.number(),
  requestExpiresAtDate: z.string().nullish(),
  note: z.string().nullish(),
  directSales: z.array(z.object({directSaleId: z.string()})),
});

export const PostReservationDirectSalesRequestSchema = ReservationDirectSalesSchema;
export type PostReservationDirectSalesRequest = z.infer<
  typeof PostReservationDirectSalesRequestSchema
>;

export const PostReservationDirectSalesResponseSchema = z.object({
  articleId: z.string(),
  articleQuantity: z.number(),
  createdItems: z.array(
    z.object({
      itemId: z.string(),
      requestItemId: z.array(z.string()),
    })
  ),
});
export type PostReservationDirectSalesResponse = z.infer<
  typeof PostReservationDirectSalesResponseSchema
>;

/**
 * PATCH requests
 */
export const PatchPartsRequestItemCancelRequestSchema = z.object({
  requestItemId: z.string(),
});
export type PatchPartsRequestItemCancelRequest = z.infer<
  typeof PatchPartsRequestItemCancelRequestSchema
>;

export const PatchPartsRequestItemCancelResponseSchema = BaseVoidResponseSchema;
export type PatchPartsRequestItemCancelResponse = z.infer<
  typeof PatchPartsRequestItemCancelResponseSchema
>;

export const PatchPartsRequestItemDeleteRequestSchema = RequestItemIdSchema;
export type PatchPartsRequestItemDeleteRequest = z.infer<
  typeof PatchPartsRequestItemDeleteRequestSchema
>;

export const PatchPartsRequestItemDeleteResponseSchema = BaseVoidResponseSchema;
export type PatchPartsRequestItemDeleteResponse = z.infer<
  typeof PatchPartsRequestItemDeleteResponseSchema
>;

export const PatchPartsRequestCancelByOriginEntityRequestSchema = z.object({
  directSaleId: z.string(),
  body: z.object({
    originEntityId: z.array(z.string()),
  }),
});
export type PatchPartsRequestCancelByOriginEntityRequest = z.infer<
  typeof PatchPartsRequestCancelByOriginEntityRequestSchema
>;

export const PatchPartsRequestCancelByOriginEntityResponseSchema = BaseVoidResponseSchema;
export type PatchPartsRequestCancelByOriginEntityResponse = z.infer<
  typeof PatchPartsRequestCancelByOriginEntityResponseSchema
>;

export const PatchReservationOtherRequestSchema = ReservationRequestSchema.merge(
  z.object({
    body: ReservationOtherSchema,
  })
);
export type PatchReservationOtherRequest = z.infer<typeof PatchReservationOtherRequestSchema>;

export const PatchReservationOtherResponseSchema = BaseVoidResponseSchema;
export type PatchReservationOtherResponse = z.infer<typeof PatchReservationOtherResponseSchema>;

export const PatchReservationCustomerRequestSchema = ReservationRequestSchema.merge(
  z.object({
    body: ReservationCustomerSchema,
  })
);
export type PatchReservationCustomerRequest = z.infer<typeof PatchReservationCustomerRequestSchema>;

export const PatchReservationCustomerResponseSchema = BaseVoidResponseSchema;
export type PatchReservationCustomerResponse = z.infer<
  typeof PatchReservationCustomerResponseSchema
>;

export const PatchReservationServiceOrderRequestSchema = ReservationRequestSchema.merge(
  z.object({
    body: z.object({
      requestExpiresAtDate: z.string().nullish(),
      note: z.string().nullish(),
    }),
  })
);
export type PatchReservationServiceOrderRequest = z.infer<
  typeof PatchReservationServiceOrderRequestSchema
>;

export const PatchReservationServiceOrderResponseSchema = BaseVoidResponseSchema;
export type PatchReservationServiceOrderResponse = z.infer<
  typeof PatchReservationServiceOrderResponseSchema
>;

export const PatchReservationDirectSalesRequestSchema = ReservationRequestSchema.merge(
  z.object({
    body: z.object({
      requestExpiresAtDate: z.string().nullish(),
      note: z.string().nullish(),
    }),
  })
);
export type PatchReservationDirectSalesRequest = z.infer<
  typeof PatchReservationDirectSalesRequestSchema
>;

export const PatchReservationDirectSalesResponseSchema = BaseVoidResponseSchema;
export type PatchReservationDirectSalesResponse = z.infer<
  typeof PatchReservationDirectSalesResponseSchema
>;
