import {Button, closeCurrentDialog} from 'platform/components';
import {Box, Heading, HStack, Icon, Text, ThemeIconKey, VStack} from 'platform/foundation';

import i18n from '@dms/i18n';

interface DestroyDialogProps {
  iconPath: Extract<ThemeIconKey, 'alert_icon/circle'>;
  heading: string;
  description: string;
  onSubmit: () => void;
  onSubmitButtonTitle: string;
  isLoading?: boolean;
}

export function DestroyDialog(props: DestroyDialogProps) {
  return (
    <Box paddingVertical={4}>
      <VStack spacing={4} align="center">
        <Icon value={props.iconPath} size={20} isNotFilled />
        <Heading size={3}>{i18n.t('entity.invalidateOnlineOfferDialog.heading')}</Heading>
        <Text color="secondary">{i18n.t('entity.invalidateOnlineOfferDialog.description')}</Text>
        <Box paddingTop={4}>
          <HStack spacing={2}>
            <Button
              variant="secondary"
              title={i18n.t('general.actions.back')}
              isDisabled={props.isLoading}
              onClick={closeCurrentDialog}
            />
            <Button
              variant="danger"
              title={props.onSubmitButtonTitle}
              isLoading={props.isLoading}
              onClick={props.onSubmit}
            />
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
}
