import {captureException} from '@sentry/browser';

import {browserStorageKey} from '@dms/config';

import {FileUri} from '../types/FileUri';
import {FileStorageService} from './FileStorageService';

export type UploadedFile = FileUri & {fileId: string; name: string};

const getAccessTokenFromCookie = () => sessionStorage.getItem(browserStorageKey.ACCESS_TOKEN);

// TODO: remove and replace with RTKQ fileStorageApi - https://carvago.atlassian.net/browse/T20-45824
export const uploadFileService = async (
  file: File,
  onError: onErrorType,
  onFinishS3Put?: onFinishS3PutType,
  onProgress?: onProgressType,
  cancelCallback?: (cb: () => void) => void,
  onGetUploadedFile?: (result: boolean) => void
): Promise<UploadedFile | null> => {
  const authorization = getAccessTokenFromCookie();
  const data = await FileStorageService.uploadFile({
    authorization: authorization ?? undefined,
    requestBody: {filename: file.name || ''},
  });

  let XhrRequest: XMLHttpRequest;
  let isUploaded = false;
  let isError = false;
  let errorArgs: Parameters<onErrorType>;

  let cancelRequest = () => {
    XhrRequest?.abort();
    isError = true;
  };

  const startUploadToS3 = () => {
    XhrRequest?.abort();
    isUploaded = false;
    isError = false;

    uploadToS3(
      file,
      data.uploadUri,
      (...args) => {
        isError = true;
        errorArgs = args;
      },
      (...args) => {
        onFinishS3Put?.(...args);
        isUploaded = true;
      },
      onProgress,
      (req) => {
        XhrRequest = req;
        cancelRequest = () => {
          XhrRequest?.abort();
          isError = true;
        };
        if (typeof cancelCallback === 'function') {
          cancelCallback(cancelRequest);
        }
      }
    );
  };

  startUploadToS3();

  const retry = <T>(maxRetries: number, fn: () => Promise<T>): Promise<T | null> => {
    if (isError) {
      if (maxRetries <= 0) {
        onError(...errorArgs);
        return Promise.reject();
      }
      XhrRequest?.abort();
      return sleep(RETRY_SLEEP_TIME).then(() => {
        startUploadToS3();
        return retry(maxRetries - 1, fn);
      });
    }

    if (!isUploaded) {
      return sleep(READ_STATE_SLEEP_TIME).then(() => retry(maxRetries, fn));
    }

    return fn();
  };

  return retry(NUMBER_OF_RETRIES, (): Promise<UploadedFile | null> => {
    if (!data.fileId) {
      return Promise.resolve(null);
    }

    if (isError) {
      onError(...errorArgs);
      return Promise.reject();
    }

    return FileStorageService.getFileUri({
      authorization: authorization ?? undefined,
      fileId: data.fileId,
    })
      .then((response) => {
        onGetUploadedFile?.(true);
        return Promise.resolve({...response, fileId: data.fileId, name: file.name});
      })
      .catch(() => {
        onError('Failed to get uploaded file', file, null);
        return Promise.reject();
      });
  });
};

const sleep = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

const READ_STATE_SLEEP_TIME = 1500;
const RETRY_SLEEP_TIME = 6000;
const NUMBER_OF_RETRIES = 2;

/**
 * This file is from https://github.com/odysseyscience/react-s3-uploader/blob/master/s3upload.js
 *
 * Taken, CommonJS-ified, and heavily modified from:
 * https://github.com/flyingsparx/NodeDirectUploader
 */
const createCORSRequest = (method: string, url: string): XMLHttpRequest => {
  const xhr = new XMLHttpRequest();

  if (xhr.withCredentials != null) {
    xhr.open(method, url, true);
  } else {
    throw new Error('XMLHttpRequest variant not supported');
  }
  return xhr;
};

export type ErrorRequestContext = {
  response: string;
  status: number;
  statusText: string;
  readyState: number;
};

const _getErrorRequestContext = (xhr: XMLHttpRequest): ErrorRequestContext => ({
  response: xhr.responseText,
  status: xhr.status,
  statusText: xhr.statusText,
  readyState: xhr.readyState,
});

export type onErrorType = (
  message: string,
  file: File,
  errorRequestContext: ErrorRequestContext | null
) => void;
export type onFinishS3PutType = (signedUrl: string, file: File) => void;
export type onProgressType = (percentage: number, message: string, file: File) => void;
export type onRequestReceivedType = (xhr: XMLHttpRequest) => void;

export const uploadToS3 = (
  file: File,
  signedUrl: string,
  onError: onErrorType,
  onFinishS3Put: onFinishS3PutType,
  onProgress?: onProgressType,
  onRequestReceived?: onRequestReceivedType
) => {
  const xhr = createCORSRequest('PUT', signedUrl);
  const successResponses = [200, 201];
  if (!xhr) {
    onError('CORS not supported', file, null);
  } else {
    xhr.onload = () => {
      if (successResponses.includes(xhr.status)) {
        onProgress?.(100, 'Upload completed', file);
        return onFinishS3Put(signedUrl, file);
      } else {
        onError(`Upload error: ${xhr.status}`, file, _getErrorRequestContext(xhr));
        console.error('Error when uploading to S3. XHR:', xhr);
        captureException({
          type: 'onLoadError',
          url: signedUrl,
          xhr,
          fileInfo: {
            name: file.name,
            type: file.type,
            size: file.size,
          },
        });

        return;
      }
    };
    xhr.onerror = (err) => {
      console.error('Error when uploading to S3:', err);
      captureException({
        error: err ?? {},
        url: signedUrl,
        xhr,
        fileInfo: {
          name: file.name,
          type: file.type,
          size: file.size,
        },
      });
      onError('XHR error', file, _getErrorRequestContext(xhr));
    };
    xhr.upload.onprogress = (e) => {
      let percentLoaded;
      if (e.lengthComputable) {
        percentLoaded = Math.round((e.loaded / e.total) * 100);
        return onProgress?.(
          percentLoaded,
          percentLoaded === 100 ? 'Finalizing' : 'Uploading',
          file
        );
      }
    };
  }
  xhr.setRequestHeader('Content-Type', file.type);
  onRequestReceived?.(xhr);
  return xhr.send(file);
};
