import {Attributes, Button, Card, closeCurrentDialog, DialogFooter} from 'platform/components';
import {Box, Heading, HStack, Icon, Show, Text, VStack} from 'platform/foundation';

import {indexBy, isNotNil} from 'ramda';

import i18n from '@dms/i18n';

import {suffixTestId, useOnMount} from 'shared';

import {CancelTransactionButton} from '../../components/CancelTransactionButton';
import {useDocumentValidationState} from '../../contexts/DocumentStateProvider';
import {useCurrentTransaction} from '../../hooks/useCurrentTransaction';
import {StepContentProps} from '../../types/StepContentProps';

export function SendToSignatoriesStep(props: StepContentProps) {
  const {activeDocumentId, setActiveDocumentId} = useDocumentValidationState();
  const {transaction, isLoading: isLoadingTransaction} = useCurrentTransaction();

  const metadataById = indexBy((item) => item.signedDocumentId, transaction?.tasks ?? []);
  const activeDocument = metadataById[activeDocumentId ?? ''];

  useOnMount(() => {
    if (props.signingStep === 'Remote_sentToSignatories') {
      setActiveDocumentId(props.fileId ?? null);
    }
  });
  return (
    <VStack spacing={8}>
      <VStack spacing={4} align="center">
        <Icon value="action/mail_upload" size={20} color="general.white" />
        <Heading size={3}>{i18n.t('entity.document.labels.sentByMail')}</Heading>

        <Box width={140}>
          <Text size="small" color="secondary" align="center">
            {i18n.t('entity.document.labels.sentByMailDescription')}
          </Text>
        </Box>
      </VStack>

      <VStack spacing={4}>
        <Heading size={3}>{i18n.t('entity.document.labels.signatories')}</Heading>

        <Show when={isNotNil(activeDocument)}>
          {activeDocument?.metadata.map((signatory) => (
            <Card variant="inlineGrey" title={signatory.id} key={signatory.id}>
              <Attributes
                size="quarter"
                rows={[
                  {label: i18n.t('entity.person.labels.firstName'), value: signatory?.name},
                  {label: i18n.t('general.labels.email'), value: signatory?.email},
                  {label: i18n.t('general.labels.phone'), value: signatory?.phone},
                ]}
                data-testid={suffixTestId('customer', props)}
              />
            </Card>
          ))}
        </Show>
      </VStack>

      <DialogFooter>
        <HStack justify="space-between">
          <CancelTransactionButton
            transactionId={transaction?.transactionId}
            isLoading={isLoadingTransaction}
            refreshData={props.refreshData}
            data-testid={suffixTestId('seller', props)}
          />
          <Button
            title={i18n.t('general.actions.finish')}
            onClick={closeCurrentDialog}
            data-testid={suffixTestId('finish', props)}
          />
        </HStack>
      </DialogFooter>
    </VStack>
  );
}
