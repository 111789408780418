import {MenuItemProps} from '@dms/api/shared';

import {MenuItemWithPin} from './addPinsToMenuItems';

export function flattenPinnedMenuItems(
  menuItems: MenuItemWithPin<MenuItemProps>[]
): MenuItemWithPin<MenuItemProps>[] {
  const seenIds = new Set<string>();

  const flatten = (items: MenuItemWithPin<MenuItemProps>[]): MenuItemWithPin<MenuItemProps>[] =>
    items.reduce<MenuItemWithPin<MenuItemProps>[]>((acc, item) => {
      // Recursively process content if it exists
      const nestedPinned = item.content && item.content.length > 0 ? flatten(item.content) : [];

      if (item.isPinned && !seenIds.has(item.id)) {
        seenIds.add(item.id);
        const {content: _, ...itemWithoutContent} = item;
        return [...acc, itemWithoutContent, ...nestedPinned];
      }

      return [...acc, ...nestedPinned];
    }, []);

  return flatten(menuItems);
}
