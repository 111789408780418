import {match} from 'ts-pattern';

import {useCallback, useMemo} from 'react';

import {always, isEmpty, isNil} from 'ramda';

import {
  PermissionsExpressionScopeResponseBody,
  ResourceActionPermissionExpressionResponseBody,
  ResourceFieldPermissionExpressionResponseBody,
} from '@dms/api/accessControlList';
import {ScopeKeys, ScopeValues} from '@dms/api/shared';
import {useGetCurrentUserInfoQuery} from '@dms/api/user';

import {Nullish} from 'shared';

import {evaluateBranch} from './evaluators/evaluateBranch';
import {evaluateInspectionType} from './evaluators/evaluateInspectionType';
import {evaluateParticipation} from './evaluators/evaluateParticipation';
import {evaluatePaymentMethod} from './evaluators/evaluatePaymentMethod';
import {evaluateServiceCaseType} from './evaluators/evaluateServiceCaseType';

type WithKey<T> = T & {key: string};

type EvaluatingPermission =
  | WithKey<ResourceActionPermissionExpressionResponseBody>
  | WithKey<ResourceFieldPermissionExpressionResponseBody>
  | Nullish;

type EvaluatorParams = Record<string, ScopeValues> | Nullish;

export const useScopeEvaluator = () => {
  const {data: currentUser} = useGetCurrentUserInfoQuery();
  const currentUserId = currentUser?.id;
  const currentUserBranches = useMemo(
    () => currentUser?.branches?.map((item) => item.id),
    [currentUser]
  );

  const evaluateScope = useCallback(
    (scopeParams: ScopeValues | Nullish) => (scope: PermissionsExpressionScopeResponseBody) => {
      const id = scope.scopeId as ScopeKeys;
      const values = scope.values;

      // cannot resolve scope, if no values are provided
      if (isNil(scopeParams) || isEmpty(scopeParams)) {
        return false;
      }

      return match(id)
        .with('INSPECTION_TYPE', 'CAR_AUDIT_INSPECTION.INSPECTION_TYPE', () =>
          evaluateInspectionType(values, scopeParams)
        )
        .with('SERVICE_CASE_TYPE', () => evaluateServiceCaseType(values, scopeParams))
        .with('PARTICIPATION', () => evaluateParticipation(values, scopeParams, currentUserId))
        .with('BRANCH', 'CASH_REGISTER_DOCUMENT.BRANCH', 'INVOICE.BRANCH', () =>
          evaluateBranch(values, scopeParams, currentUserBranches ?? [])
        )
        .with('PAYMENT_METHOD', () => evaluatePaymentMethod(values, scopeParams))
        .exhaustive();
    },
    [currentUserBranches, currentUserId]
  );

  const evaluate = useCallback(
    (permission: EvaluatingPermission, evaluatorParams: EvaluatorParams) => {
      const conditions = permission?.permissionsExpression.conditions;
      if (isNil(conditions)) {
        return true;
      }
      if (isNil(permission)) {
        return false;
      }

      const params = evaluatorParams?.[permission.key];

      // RULES:
      // user must have at least one condition to be granted permission
      // user must have every alternative to be granted permission
      // user must have every scope to be granted permission
      return conditions.some(
        ({alternatives}) =>
          alternatives.length > 0 &&
          alternatives.every(({scopes}) => scopes.every(evaluateScope(params)))
      );
    },
    [evaluateScope]
  );

  const isPermissionGranted = useCallback(
    (evaluatingPermission: EvaluatingPermission[], evaluatorParams: EvaluatorParams) =>
      evaluatingPermission.map((permission) =>
        match(permission?.permissionsExpression?.result)
          .with('ALLOW', always(true))
          .with('SCOPES_MUST_BE_EVALUATED', () => evaluate(permission, evaluatorParams))
          .with('DISALLOW', always(false))
          .otherwise(always(false))
      ),
    [evaluate]
  );

  return [isPermissionGranted];
};
