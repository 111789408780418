import {Button, DataStatus} from 'platform/components';
import {Grid, Hide, Right, Text, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {FC, useEffect, useRef, useState} from 'react';

import {always, groupBy} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {CheckoutSubEntityClass} from '@dms/api/checkout';
import {BusinessCaseResponseBody} from '@dms/api/commission';
import {
  BusinessCaseDocumentsSettingsResponseBody,
  useGetBusinessCaseDocumentsSettingsQuery,
} from '@dms/api/sales';
import {useGetSaleVehicleFromSaleVehicleIdQuery} from '@dms/api/saleVehicle';
import {NoteResponseBody} from '@dms/api/shared';
import {useGetVehicleQuery} from '@dms/api/vehicle';
import i18n from '@dms/i18n';
import {DocumentTemplateBox, useLazyGetAuthorizationProfilesAndCKKPermissions} from '@dms/shared';
import {FileData, NoteRequestBody, TemplateListItem, TemplatesListItem} from '@dms/teas';

import {convertStringToCamelCase, suffixTestId, TestIdProps} from 'shared';

import {DocumentType} from './types/types';

export interface DocumentsProps {
  readonly?: boolean;
  templates: Array<TemplatesListItem & {title?: string; id?: string}>;
  documents?: FileData[];
  documentNotes?: {[index: string]: NoteResponseBody | undefined};
  handleCreateDocuments: (documentsByType: Record<string, DocumentType[]>) => void;
  onNoteChange?: (documentType: CheckoutSubEntityClass, note: NoteRequestBody) => void;
  isLoading?: boolean;
  emptySubHeadline?: string;
  isCreateDisabled?: boolean;
  businessCase?: BusinessCaseResponseBody;
}

const documentNoteSubEntityClass: {[index: string]: CheckoutSubEntityClass} = {
  'purchase-contract': 'PURCHASE_CONTRACT',
  'purchase-brokerage-contract': 'PURCHASE_BROKERAGE_CONTRACT',
  'sale-contract': 'SALE_CONTRACT',
  'sale-brokerage-contract': 'SALE_BROKERAGE_CONTRACT',
};

const NOTE_DEBOUNCE_TIMEOUT_TIME = 1000;

export const Documents: FC<DocumentsProps & TestIdProps> = ({
  templates,
  documentNotes = {},
  handleCreateDocuments,
  isLoading,
  readonly,
  isCreateDisabled,
  businessCase,
  ...props
}) => {
  const [documentTemplates, setDocumentTemplates] = useState<DocumentType[]>([]);
  const noteChangeDebounceTimeout = useRef<ReturnType<typeof setTimeout>>(null);
  const {data: bcDocumentsSettings, isLoading: isBcDocumentsSettingsLoading} =
    useGetBusinessCaseDocumentsSettingsQuery();
  const isAnyTemplateSelected = documentTemplates.some((template) => template.isSelected);
  const {data: saleVehicle} = useGetSaleVehicleFromSaleVehicleIdQuery(
    {
      vehicleId: businessCase?.saleVehicleId ?? '',
    },
    {skip: isNilOrEmpty(businessCase?.saleVehicleId)}
  );
  const {data: vehicleData} = useGetVehicleQuery(
    {vehicleId: saleVehicle?.vehicleId ?? ''},
    {skip: isNilOrEmpty(saleVehicle?.vehicleId)}
  );
  const {handleSearchAuthorizationProfiles} = useLazyGetAuthorizationProfilesAndCKKPermissions();
  useEffect(() => {
    if (!isBcDocumentsSettingsLoading) {
      const newDocumentTemplates = templates
        ?.filter((template) => template.templates.length)
        .map((template) => ({
          ...template,
          title: template.title
            ? template.title
            : i18n.t(`entity.document.labels.${convertStringToCamelCase(template.type)}`),
          isSelected:
            documentTemplates.find(
              (document) =>
                (document.id && document.id === template.id) ||
                (!document.id && document.type === template.type)
            )?.isSelected ??
            (businessCase && bcDocumentsSettings
              ? getIsSelectedOption(businessCase, template.type, bcDocumentsSettings)
              : true),
          selectedTemplate:
            documentTemplates.find(
              (document) =>
                (document.id && document.id === template.id) ||
                (!document.id && document.type === template.type)
            )?.selectedTemplate ??
            (template.templates?.find((template) => !!template.primary) || template.templates[0]),
          note: documentNotes[documentNoteSubEntityClass[template.type]],
        }));

      setDocumentTemplates(newDocumentTemplates);
    }
    // Dependency `documentTemplates` is omitted intentionally. Effect should run only when templates changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates, isBcDocumentsSettingsLoading]);

  const onNoteChange = (subEntityClass: CheckoutSubEntityClass) => (note: string | null) => {
    if (noteChangeDebounceTimeout.current) {
      clearTimeout(noteChangeDebounceTimeout.current);
    }

    noteChangeDebounceTimeout.current = setTimeout(() => {
      const body: NoteRequestBody = {
        note: note ?? '',
      };

      props.onNoteChange?.(subEntityClass, body);
    }, NOTE_DEBOUNCE_TIMEOUT_TIME);
  };

  const onSelect = (template: DocumentType) => (isSelected: boolean) => {
    setDocumentTemplates(
      documentTemplates.map((document) => ({
        ...document,
        isSelected:
          (document.id && template.id === document.id) ||
          (!document.id && template.type === document.type)
            ? isSelected
            : document.isSelected,
      }))
    );
  };

  const onTemplateChange = (template: DocumentType) => (selectedTemplate: TemplateListItem) => {
    setDocumentTemplates(
      documentTemplates.map((document) => ({
        ...document,
        selectedTemplate:
          (document.id && template.id === document.id) ||
          (!document.id && template.type === document.type)
            ? selectedTemplate
            : document.selectedTemplate,
      }))
    );
  };

  const handleCreate = () => {
    const selected = documentTemplates?.filter((template) => !!template.isSelected);
    const documentsByType = groupBy((document) => document.type, selected) as Record<
      string,
      DocumentType[]
    >;

    handleCreateDocuments(documentsByType);
  };

  return (
    <Grid columns={1}>
      <Hide when={readonly}>
        <Text size="small" color="tertiary">
          {i18n.t('general.labels.selectDocumentsInformation')}
        </Text>
        <DataStatus isLoading={isBcDocumentsSettingsLoading}>
          <VStack spacing={4}>
            {documentTemplates.map((document) => (
              <DocumentTemplateBox
                type={document.type}
                title={document.title}
                templates={document.templates}
                isSelected={document.isSelected}
                selectedTemplate={document.selectedTemplate || null}
                onSelect={onSelect(document)}
                onTemplateChange={onTemplateChange(document)}
                isWithNote={Object.keys(documentNoteSubEntityClass).includes(document.type)}
                noteLabel={i18n.t('entity.document.labels.contractNote')}
                onNoteChange={onNoteChange(documentNoteSubEntityClass[document.type])}
                key={`${document.id ?? 'noId'}-${document.type}`}
                data-testid={suffixTestId(
                  `templates-${convertStringToCamelCase(document.type)}`,
                  props
                )}
              />
            ))}
          </VStack>
        </DataStatus>
        <Right>
          {/* TODO: tooltip for disabled button cuz of perms */}
          <Button
            data-testid={suffixTestId('create', props)}
            isDisabled={!isAnyTemplateSelected || isCreateDisabled}
            isLoading={isLoading}
            onClick={() => {
              handleCreate();
              handleSearchAuthorizationProfiles(
                {
                  vehicleType: vehicleData?.vehicle?.type ?? null,
                  vehicleMake: vehicleData?.vehicle?.make ?? '',
                  vehicleModelFamily: vehicleData?.vehicle?.modelFamily ?? null,
                  vehicleModelFamilyGroup: vehicleData?.vehicle?.modelFamilyGroup ?? null,
                },
                businessCase?.customerId,
                {bcNumber: businessCase?.code, bcCreatedAt: businessCase?.createdAt}
              );
            }}
            title={i18n.t('general.action.createDocuments')}
          />
        </Right>
      </Hide>
    </Grid>
  );
};

const getIsSelectedOption = (
  businessCase: BusinessCaseResponseBody,
  templateType: string,
  savedDocumentsOptions: BusinessCaseDocumentsSettingsResponseBody
) => {
  if (
    businessCase.businessCaseInternalType === 'SELLING' &&
    isNotNilOrEmpty(businessCase.brokerageBusinessCaseId)
  ) {
    // selling brokerage
    return match<string, boolean>(templateType)
      .with('vehicle-condition', always(savedDocumentsOptions.sellingBrokerage.vehicleCondition))
      .with('power-of-attorney', always(savedDocumentsOptions.sellingBrokerage.powerOfAttorney))
      .with(
        'sale-handover-protocol',
        always(savedDocumentsOptions.sellingBrokerage.saleHandoverProtocol)
      )
      .with(
        'sale-brokerage-contract',
        always(savedDocumentsOptions.sellingBrokerage.saleBrokerageContract)
      )
      .with('GDPR_MARKETING_CONSENT', always(savedDocumentsOptions.sellingBrokerage.consent))
      .otherwise(always(true));
  }
  if (businessCase.businessCaseInternalType === 'SELLING') {
    // selling
    return match<string, boolean>(templateType)
      .with('vehicle-condition', always(savedDocumentsOptions.selling.vehicleCondition))
      .with('power-of-attorney', always(savedDocumentsOptions.selling.powerOfAttorney))
      .with('sale-handover-protocol', always(savedDocumentsOptions.selling.saleHandoverProtocol))
      .with('sale-contract', always(savedDocumentsOptions.selling.saleContract))
      .with('GDPR_MARKETING_CONSENT', always(savedDocumentsOptions.selling.consent))
      .otherwise(always(true));
  }
  if (businessCase.businessCaseInternalType === 'BUYING') {
    // buying
    return match<string, boolean>(templateType)
      .with('vehicle-condition', always(savedDocumentsOptions.buying.vehicleCondition))
      .with('power-of-attorney', always(savedDocumentsOptions.buying.powerOfAttorney))
      .with('handover-protocol', always(savedDocumentsOptions.buying.handoverProtocol))
      .with('purchase-contract', always(savedDocumentsOptions.buying.purchaseContract))
      .with('GDPR_MARKETING_CONSENT', always(savedDocumentsOptions.buying.consent))
      .otherwise(always(true));
  }
  if (businessCase.businessCaseInternalType === 'PURCHASE_BROKERAGE') {
    return match<string, boolean>(templateType)
      .with('vehicle-condition', always(savedDocumentsOptions.purchaseBrokerage.vehicleCondition))
      .with('power-of-attorney', always(savedDocumentsOptions.purchaseBrokerage.powerOfAttorney))
      .with(
        'purchase-brokerage-handover-protocol',
        always(savedDocumentsOptions.purchaseBrokerage.purchaseBrokerageHandoverProtocol)
      )
      .with(
        'purchase-brokerage-contract',
        always(savedDocumentsOptions.purchaseBrokerage.purchaseBrokerageContract)
      )
      .with('GDPR_MARKETING_CONSENT', always(savedDocumentsOptions.purchaseBrokerage.consent))
      .otherwise(always(true));
  }
  return true;
};
