import {match} from 'ts-pattern';

import {useEffect, useState} from 'react';

import {isNil} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {GetSupplierOrderResponse} from '@dms/api/metadaWarehouseSupplierOrder';
import {catchUnhandledDataGridActions, logError, useWarehouseDataGridTreeFolder} from '@dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {ActionCallback, DataGrid, Filters} from 'features/datagrid';

import {FullscreenDataGridWrapper} from '../../../../../components/FullscreenDataGridWrapper';
import {MaterialPurchaseListTabExternalFilters} from './MaterialPurchaseListTabExternalFilters';

const SUPPLIER_ORDER_PURCHASE_LIST_FILTER_ID = 'supplier-order-purchase-list-filter';

interface MaterialPurchaseListTabProps extends TestIdProps {
  onAddMaterialFromPurchaseListToBasket: (requestItemId: string[]) => Promise<void>;
  onActiveFolderIdChange?: (activeFolderId?: string) => void;
  onFilterChange: (filters: Filters) => void;
  supplierOrder: GetSupplierOrderResponse;
}

export const MaterialPurchaseListTab = (props: MaterialPurchaseListTabProps) => {
  // T20-58899 -The bulk selector does not reset after the DG refresh, so we need to reset the entire component using a random key.
  const [randomString, setRandomString] = useState<string>('');
  const [folderTreeRef, dataGridModifier, activeFolderId] = useWarehouseDataGridTreeFolder({
    warehouseId: props.supplierOrder.order.warehouseId,
  });

  useEffect(() => {
    props.onActiveFolderIdChange?.(activeFolderId);
  }, [activeFolderId]);

  const warehouseActionCallback: ActionCallback = async ({actionKey, rowId}) => {
    const ids = isArray(rowId) ? rowId : [rowId];

    if (isNil(ids)) {
      return logError('Selected ids are not defined');
    }

    await match(actionKey)
      .with(
        'addToBasket',
        async () =>
          await props.onAddMaterialFromPurchaseListToBasket(ids).then(() => {
            setRandomString(String(Math.random()));
          })
      )
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <FullscreenDataGridWrapper
      externalFilterId={SUPPLIER_ORDER_PURCHASE_LIST_FILTER_ID}
      externalFilterHeight={90}
      folderTreeRef={folderTreeRef}
      hideCardWrapper
      data-testid={props['data-testid']}
      key={randomString}
    >
      <DataGrid
        // DG must be re-rendered on folder change to update the query modifier
        // eslint-disable-next-line no-restricted-syntax
        key={dataGridModifier?.key}
        gridCode="warehouse-supplier-order-purchase-list-cart"
        actionCallback={warehouseActionCallback}
        externalFilterId={SUPPLIER_ORDER_PURCHASE_LIST_FILTER_ID}
        queryModifier={dataGridModifier?.queryModifier}
        _useAsLastResort_definitionBehaviorOverrides={{filterMode: 'NATIVE'}}
        data-testid={suffixTestId('warehouseSupplierOrderPurchaseListBasket', props)}
        filterComponent={MaterialPurchaseListTabExternalFilters}
        onFilterChanged={props.onFilterChange}
      />
    </FullscreenDataGridWrapper>
  );
};
