import {Button, closeCurrentDialog, openDialog} from 'platform/components';
import {Box, Hide, Right, Show, Space, Stack, VStack} from 'platform/foundation';

import {useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';

import {find, isNil, not, uniqBy} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {CustomerResponseBodyV2, useGetCustomerV2Query} from '@dms/api/customer';
import {
  useCreateServiceVehicleMutation,
  useLazyGetServiceVehicleQuery,
} from '@dms/api/metadaServiceVehicle';
import {
  GetVehicleCustomerApiResponse,
  useLazyGetVehicleCustomerQuery,
} from '@dms/api/metadaVehicleCustomer';
import {useGetVehicleQuery} from '@dms/api/vehicle';
import i18n from '@dms/i18n';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {handleApiError} from '../../utils/handleApiError';
import {AftersalesVehicleWidgetCard} from '../AftersalesVehicleWidgetCard/AftersalesVehicleWidgetCard';
import {VehicleMatchOrCreate} from '../AftersalesVehicleWidgetCard/components/VehicleMatchOrCreate';
import {CustomerMatchOrCreate} from '../CustomerWidgetCard/components/CustomerMatchOrCreate';
import {CustomerWidgetCard} from '../CustomerWidgetCard/CustomerWidgetCard';
import {SuggestedVehicles} from './components/SuggestedVehicles';

interface CustomerVehicleSelectionProps extends TestIdProps {
  customerId: string | Nullish;
  vehicleId: string | Nullish;
  suggestedVehicles: GetVehicleCustomerApiResponse | null;
  allowNoVehicle?: boolean;
  onCustomerChange: (customerId: string | Nullish) => void;
  onVehicleChange: (vehicleId: string | Nullish) => void;
  onSuggestedVehiclesChange: (suggestedVehicles: GetVehicleCustomerApiResponse | null) => void;
  onContinueClick?: () => void;
}

export function CustomerVehicleSelection(props: CustomerVehicleSelectionProps) {
  const [searchParams] = useSearchParams();

  const defaultCustomerId = searchParams.get('customerId');
  const defaultVehicleId = searchParams.get('vehicleId');

  const [getVehicleCustomer, getVehicleCustomerQuery] = useLazyGetVehicleCustomerQuery();
  const [getServiceVehicle] = useLazyGetServiceVehicleQuery();
  const [createServiceVehicle] = useCreateServiceVehicleMutation();

  const {currentData: customer} = useGetCustomerV2Query(
    {customerId: props.customerId ?? ''},
    {skip: !props.customerId}
  );
  const {data: vehicle, refetch: refetchVehicle} = useGetVehicleQuery(
    {vehicleId: props.vehicleId ?? ''},
    {
      skip: not(props.vehicleId),
      selectFromResult: (result) => (props.vehicleId ? result : {...result, data: null}),
    }
  );

  useEffect(() => {
    if (isNotNilOrEmpty(defaultVehicleId)) {
      getVehicleCustomer({vehicleId: defaultVehicleId})
        .unwrap()
        .then((customers) => {
          const vehicleOperator = find(
            (ownership) => ownership?.customerType === 'CORVEHICLECUSTOMERTYPE_OPERATOR',
            customers ?? []
          );
          props.onCustomerChange(vehicleOperator?.customerId ?? null);
        })
        .catch(handleApiError);
    }
  }, [defaultVehicleId]);

  useEffect(() => {
    if (isNotNilOrEmpty(defaultCustomerId)) {
      getVehicleCustomer({customerId: defaultCustomerId})
        .unwrap()
        .then((vehicles) => {
          if (vehicles?.length) {
            props.onSuggestedVehiclesChange(uniqBy((e) => e?.vehicleId, vehicles));
          }
        })
        .catch(handleApiError);
    }
  }, [defaultCustomerId]);

  const handleVehicleSelect = async (vehicleId: string | Nullish) => {
    props.onVehicleChange(vehicleId ?? null);

    if (isNil(vehicleId)) {
      return;
    }

    await getServiceVehicle({vehicleId})
      .unwrap()
      .then(async (data) => {
        // METADA returns 200 with data: null, when service vehicle is not found from how :(
        if (isNil(data)) {
          await createServiceVehicle({vehicleId}).unwrap().catch(handleApiError);
        }
      })
      .catch(async (error: Error & {status?: number}) => {
        if (error?.status === 404) {
          await createServiceVehicle({vehicleId}).unwrap().catch(handleApiError);
        } else {
          handleApiError(error);
        }
      });

    if (isNil(customer)) {
      if (props.vehicleId === vehicleId) {
        refetchVehicle();
      }

      await getVehicleCustomer({vehicleId})
        .unwrap()
        .then((customers) => {
          const vehicleOperator = find(
            (ownership) => ownership?.customerType === 'CORVEHICLECUSTOMERTYPE_OPERATOR',
            customers ?? []
          );
          props.onCustomerChange(vehicleOperator?.customerId ?? null);
        })
        .catch(handleApiError);
    }
  };

  const handleCustomerChange = () =>
    openDialog(
      <CustomerMatchOrCreate
        secondStepComponentType="SERVICE_CASE"
        onCustomer={(customer) => {
          closeCurrentDialog();
          handleCustomerSelect(customer);
        }}
        data-testid={suffixTestId('customerChange', props)}
      />,
      {
        title: i18n.t('entity.customer.labels.customer'),
      }
    );

  const handleCustomerSelect = async (selectedCustomer: CustomerResponseBodyV2) => {
    props.onCustomerChange(selectedCustomer.id);

    if (!vehicle) {
      const vehicles = await getVehicleCustomer({customerId: selectedCustomer.id})
        .unwrap()
        .catch(handleApiError);

      if (!vehicles) {
        return;
      }

      const saneVehicles = uniqBy((e) => e?.vehicleId, vehicles);

      if (saneVehicles?.length === 1) {
        props.onVehicleChange(vehicles[0]?.vehicleId ?? null);
      }

      if (saneVehicles?.length) {
        props.onSuggestedVehiclesChange(saneVehicles);
      }
    }
  };

  const handleSelectVehicle = () => {
    openDialog(
      <VehicleMatchOrCreate
        onVehicleSelect={(vehicleId) => {
          closeCurrentDialog();
          props.onVehicleChange(vehicleId);
          props.onSuggestedVehiclesChange(null);
        }}
        data-testid={suffixTestId('finder', props)}
      />,
      {title: i18n.t('entity.vehicle.labels.vehicle')}
    );
  };

  return (
    <VStack spacing={2} height="100%">
      <Stack direction={['column', 'column', 'column', 'row']} spacing={4}>
        <Box flex={1}>
          <Show when={props.suggestedVehicles}>
            {props.suggestedVehicles && (
              <SuggestedVehicles
                selectedVehicle={vehicle ?? null}
                suggestedVehicles={props.suggestedVehicles}
                onVehicleChange={(vehicle) => props.onVehicleChange(vehicle?.id ?? null)}
                onAddNew={handleSelectVehicle}
                allowNoVehicle={props.allowNoVehicle}
                isLoading={getVehicleCustomerQuery.isLoading || getVehicleCustomerQuery.isFetching}
                isError={getVehicleCustomerQuery.isError}
                data-testid={suffixTestId('suggestedVehicles', props)}
              />
            )}
          </Show>
          <Hide when={props.suggestedVehicles}>
            <AftersalesVehicleWidgetCard
              onChange={handleVehicleSelect}
              vehicle={vehicle}
              data-testid={suffixTestId('vehicleWidgetCard', props)}
            />
          </Hide>
        </Box>
        <Box flex={1}>
          <CustomerWidgetCard
            onCustomer={handleCustomerSelect}
            onChange={handleCustomerChange}
            customer={customer}
            secondStepComponentType="SERVICE_CASE"
            hasVerification
            isDetailLinkVisible
            data-testid={suffixTestId('customerWidgetCard', props)}
          />
        </Box>
      </Stack>
      <Show when={props.onContinueClick}>
        <Space fillAvailable />
        <Right>
          <Button
            onClick={props.onContinueClick}
            variant="outlined"
            isDisabled={not(props.vehicleId) || not(props.customerId)}
            title={i18n.t('general.actions.continue')}
            data-testid={suffixTestId('continue', props)}
          />
        </Right>
      </Show>
    </VStack>
  );
}
