import {
  Card,
  closeCurrentDialog,
  DataStatus,
  FormSubmitHandler,
  openDeleteDialog,
  openDialog,
  Parameter,
  showNotification,
} from 'platform/components';
import {VStack} from 'platform/foundation';
import {formatPhoneNumber} from 'platform/locale';

import {always, ifElse, pathOr, pipe} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  CreateCustomerContactApiArg,
  PatchCustomerContactApiArg,
  PersonRequestBody,
  PersonResponseBodyV2,
  useCreateCustomerContactMutation,
  useDeleteCustomerContactMutation,
  useGetCustomerV2Query,
  usePatchCustomerContactMutation,
} from '@dms/api/customer';
import i18n from '@dms/i18n';

import {buildArray, suffixTestId, TestIdProps} from 'shared';

import {useCustomerPersonAttributes} from '../../hooks/useCustomerPersonAttributes';
import {getNaturalPersonFullName} from '../../utils/getNaturalPersonFullName';
import {handleApiError} from '../../utils/handleApiError';
import {ContactPersonForm} from '../ContactPersonForm/ContactPersonForm';
import {InfoCard} from '../InfoCard/InfoCard';

interface CustomerContactPersonCardProps extends TestIdProps {
  customerId: string;
  isEditingDisabled?: boolean;
}

export function CustomerContactPersonCard(props: CustomerContactPersonCardProps) {
  const [getAttributes] = useCustomerPersonAttributes();

  const {data, isLoading, isError} = useGetCustomerV2Query({customerId: props.customerId});
  const [createContact, {isLoading: isCreateContactLoading}] = useCreateCustomerContactMutation();
  const [editContact, {isLoading: isEditContactLoading}] = usePatchCustomerContactMutation();
  const [deleteContact, {isLoading: isDeleteContactLoading}] = useDeleteCustomerContactMutation();

  const onCreateSubmit: FormSubmitHandler<PersonRequestBody> = async (values) => {
    if (isNilOrEmpty(values.phoneNumbers?.[0]?.phoneNumber?.number)) {
      values.phoneNumbers = [];
    }

    if (isNilOrEmpty(values.emails?.[0]?.email)) {
      values.emails = [];
    }

    if (isNilOrEmpty(values?.identityCards?.[0]?.cardData?.cardNumber)) {
      values.identityCards = [];
    }

    const args: CreateCustomerContactApiArg = {
      customerId: props.customerId,
      personRequestBody: values,
    };
    await createContact(args)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.success'));
        closeCurrentDialog();
      })
      .catch(handleApiError);
  };

  const onEditSubmit =
    (contactId: string): FormSubmitHandler<PersonRequestBody> =>
    async (values) => {
      if (isNilOrEmpty(values.phoneNumbers?.[0]?.phoneNumber?.number)) {
        values.phoneNumbers = [];
      }

      if (isNilOrEmpty(values.emails?.[0]?.email)) {
        values.emails = [];
      }

      if (isNilOrEmpty(values?.identityCards?.[0]?.cardData?.cardNumber)) {
        values.identityCards = [];
      }

      const args: PatchCustomerContactApiArg = {
        customerId: props.customerId,
        contactId,
        personRequestBody: values,
      };
      await editContact(args)
        .unwrap()
        .then(() => {
          showNotification.success(i18n.t('general.notifications.success'));
          closeCurrentDialog();
        })
        .catch(handleApiError);
    };

  const onDelete = (contactId: string) =>
    openDeleteDialog({
      onConfirm: () =>
        deleteContact({
          customerId: props.customerId,
          contactId,
        })
          .unwrap()
          .then(() => showNotification.success())
          .catch(handleApiError),
    });

  const getPhoneNumber = pipe(
    pathOr(null, ['phoneNumbers', 0]),
    ifElse(
      isNotNilOrEmpty,
      (value) => formatPhoneNumber(`${value?.prefix}${value?.number}`),
      always(i18n.t('entity.customer.labels.noPhoneNumber'))
    )
  );

  const getEmail = pipe(
    pathOr(null, ['emails', 0]),
    ifElse(
      isNotNilOrEmpty,
      (value) => value.email,
      always(i18n.t('entity.customer.labels.noEmail'))
    )
  );

  return (
    <Card
      title={i18n.t('entity.customer.labels.contactPersons')}
      actions={[
        {
          type: 'button',
          variant: 'link',
          leftIcon: 'content/add_circle',
          isDisabled: isLoading || props.isEditingDisabled,
          title: i18n.t('general.actions.add'),
          onClick: () =>
            openDialog(
              <ContactPersonForm
                customerId={props.customerId}
                addresses={data?.addresses}
                isLoading={isCreateContactLoading}
                onSubmit={onCreateSubmit}
                data-testid={suffixTestId('createContact', props)}
              />,
              {
                title: i18n.t('entity.customer.actions.newContactPerson'),
                withAdditionalFooter: true,
                scrollBehavior: 'inside',
                'data-testid': suffixTestId('createContact', props),
              }
            ),
        },
      ]}
      data-testid={props['data-testid']}
    >
      <DataStatus
        isLoading={isLoading}
        isError={isError}
        isEmpty={!data?.contacts?.length}
        emptyMessage={i18n.t('general.labels.noData')}
        emptySubheadline={i18n.t('entity.customer.labels.noContact')}
        minHeight={23}
      >
        <VStack spacing={4}>
          {data?.contacts.map((contact: PersonResponseBodyV2, index) => (
            <InfoCard
              key={contact.id}
              subTitle={contact.roles}
              title={getNaturalPersonFullName(contact)}
              description={buildArray<Parameter>()
                .add(getPhoneNumber(contact))
                .add(getEmail(contact))}
              actions={[
                {
                  type: 'iconButton',
                  severity: 'danger',
                  icon: 'action/delete',
                  isDisabled: isDeleteContactLoading,
                  onClick: () => onDelete(contact.id),
                },
                {
                  type: 'iconButton',
                  icon: 'image/edit',
                  onClick: () =>
                    openDialog(
                      <ContactPersonForm
                        customerId={props.customerId}
                        addresses={data?.addresses}
                        isLoading={isEditContactLoading}
                        contact={contact}
                        onSubmit={onEditSubmit(contact.id)}
                        data-testid={suffixTestId('editContact', props)}
                      />,
                      {
                        title: i18n.t('entity.customer.labels.editContactPerson'),
                        scrollBehavior: 'inside',
                        withAdditionalFooter: true,
                        'data-testid': suffixTestId('editContact', props),
                      }
                    ),
                },
              ]}
              attributes={getAttributes(contact)}
              data-testid={suffixTestId(`contact-${index}`, props)}
              shouldShowActionParameters
              customerId={data.id}
              selectedContactId={contact.id}
            />
          ))}
        </VStack>
      </DataStatus>
    </Card>
  );
}
