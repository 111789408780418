import {CustomerResponseBodyV2} from '@dms/api/customer';

import {EMPTY_PLACEHOLDER, Nullish} from 'shared';

import {getNaturalPersonFullName} from './getNaturalPersonFullName';
import {isCustomerNaturalPerson} from './isCustomerNaturalPerson';

export function getCustomerName(customer: CustomerResponseBodyV2 | Nullish): string | undefined {
  if (!customer) {
    return undefined;
  }

  if (isCustomerNaturalPerson(customer)) {
    return getNaturalPersonFullName(customer?.foundingPerson) ?? EMPTY_PLACEHOLDER;
  }

  return customer.businessInfo?.businessInfo?.tradeName ?? EMPTY_PLACEHOLDER;
}
