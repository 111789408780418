import {
  Button,
  ButtonGroup,
  DataStatus,
  DialogFooter,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {Grid, Show, Space} from 'platform/foundation';
import {z} from 'zod';

import {
  useGetServiceCaseIntegrationsClaraClaimQuery,
  usePostServiceCaseIntegrationsClaraClaimMutation,
} from '@dms/api/metadaWorkshopIntegration';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {TestIdProps, suffixTestId} from 'shared';

interface ClaraSendDialogProps extends TestIdProps {
  serviceCaseId: string;
  serviceOrderId: string;
  onClose: () => void;
}

const formSchema = z.object({
  claimType: z.string(),
  repairStartDate: z.string(),
  mileageStart: z.number(),
  repairEndDate: z.string(),
  mileageEnd: z.number(),
  partPurchaseDate: z.string().optional(),
  mileagePartPurchase: z.number().optional(),
  partNumber: z.string().optional(),
});

type FormType = z.infer<typeof formSchema>;

export function ClaraSendDialog(props: ClaraSendDialogProps) {
  const {
    data: defaultValues,
    isLoading: isLoadingDefaultValues,
    isError: isDefaultValuesError,
  } = useGetServiceCaseIntegrationsClaraClaimQuery(
    {
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.serviceOrderId,
    },
    {refetchOnMountOrArgChange: true}
  );

  const [postServiceCaseIntegrationsClaraClaim] =
    usePostServiceCaseIntegrationsClaraClaimMutation();

  const handleSubmit: FormSubmitHandler<FormType> = async (data) => {
    await postServiceCaseIntegrationsClaraClaim({
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.serviceOrderId,
      body: {
        ...data,
        partNumber: data.claimType === '1' ? data.partNumber : undefined,
        partPurchaseDate: data.claimType === '1' ? data.partPurchaseDate : undefined,
        mileagePartPurchase: data.claimType === '1' ? data.mileagePartPurchase : undefined,
      },
    })
      .unwrap()
      .then(props.onClose)
      .catch(handleApiError);
  };

  return (
    <DataStatus isLoading={isLoadingDefaultValues} isError={isDefaultValuesError}>
      <Form<FormType>
        onSubmit={handleSubmit}
        defaultValues={defaultValues}
        experimentalZodSchema={formSchema}
      >
        {(control, formApi) => (
          <>
            <Grid spacing={4} columns={2}>
              <FormField
                control={control}
                name="claimType"
                type="choice"
                label={i18n.t('entity.claimType.labels.claimType')}
                options={[
                  {label: i18n.t('entity.vehicle.labels.vehicle'), value: '0'},
                  {label: i18n.t('entity.claimType.labels.part'), value: '1'},
                ]}
                isNotClearable
                isRequired
              />
              <Show when={formApi.watch('claimType') === '0'}>
                <Space />
              </Show>
              <Show when={formApi.watch('claimType') === '1'}>
                <FormField
                  control={control}
                  name="partNumber"
                  type="text"
                  label={i18n.t('entity.claimType.labels.partNumber')}
                  isRequired
                />
                <FormField
                  control={control}
                  name="partPurchaseDate"
                  type="apiDate"
                  label={i18n.t('entity.claimType.labels.partPurchaseDate')}
                  isRequired
                />
                <FormField
                  control={control}
                  name="mileagePartPurchase"
                  type="number"
                  label={i18n.t('entity.claimType.labels.mileagePartPurchase')}
                  suffix={i18n.t('general.metric.km')}
                  isRequired
                />
              </Show>
              <FormField
                control={control}
                name="repairStartDate"
                type="apiDate"
                label={i18n.t('entity.claimType.labels.repairStartDate')}
                isRequired
              />
              <FormField
                control={control}
                name="mileageStart"
                type="number"
                label={i18n.t('entity.claimType.labels.mileageStart')}
                suffix={i18n.t('general.metric.km')}
                isRequired
              />
              <FormField
                control={control}
                name="repairEndDate"
                type="apiDate"
                label={i18n.t('entity.claimType.labels.repairEndDate')}
                isRequired
              />
              <FormField
                control={control}
                name="mileageEnd"
                type="number"
                label={i18n.t('entity.claimType.labels.mileageEnd')}
                suffix={i18n.t('general.metric.km')}
                isRequired
              />
            </Grid>
            <DialogFooter>
              <ButtonGroup align="right">
                <Button
                  title={i18n.t('general.actions.discard')}
                  variant="secondary"
                  onClick={props.onClose}
                  data-testid={suffixTestId('discard', props)}
                />
                <FormButton
                  control={control}
                  title={i18n.t('entity.orderIntegration.actions.send')}
                  type="submit"
                  data-testid={suffixTestId('confirm', props)}
                />
              </ButtonGroup>
            </DialogFooter>
          </>
        )}
      </Form>
    </DataStatus>
  );
}
