import {
  Button,
  ButtonGroup,
  Card,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
  UploadedFileFormatIsNotAllowedBySpecifiedAcceptedField,
} from 'platform/components';
import {HStack, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {useNavigate} from 'react-router-dom';

import {useUploadFileMutation} from '@dms/api/core';
import {useGetWatermarkQuery, useUpdateWatermarkMutation} from '@dms/api/watermark';
import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {WatermarkFormTypeOld} from '../types/watermarkForm';
import {getDefaultOldValue} from '../utils/getDefaultValue';
import {getRequestOldData} from '../utils/getRequestData';
import {addToOptions, getPositionOptions} from '../utils/radioOptions';
import {WatermarkDetailPreview} from './WatermarkDetailPreview';

export function WatermarkFormOld() {
  const navigate = useNavigate();

  const [uploadFile] = useUploadFileMutation();
  const [updateWatermark, {isLoading: isUpdatingWatermark}] = useUpdateWatermarkMutation();
  const {defaultValues} = useGetWatermarkQuery(undefined, {
    selectFromResult: ({data}) => ({
      defaultValues: getDefaultOldValue(data),
    }),
  });

  const handleUpload = async (file: File) => {
    const data = await uploadFile({file}).unwrap();

    return {id: data.fileId, url: data.fileUri};
  };

  const handleSubmit: FormSubmitHandler<WatermarkFormTypeOld> = async (data) => {
    await updateWatermark({updateWatermarkRequestBody: getRequestOldData(data)})
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);
  };

  const handleDiscard = () => navigate(settingsRoutes.advertising);

  const handleUploadError = (error: Error) => {
    if (error instanceof UploadedFileFormatIsNotAllowedBySpecifiedAcceptedField) {
      showNotification.error(i18n.t('entity.watermark.notifications.imageFormatIsNotAllowed'));
    }
  };

  return (
    <Form<WatermarkFormTypeOld>
      onSubmit={handleSubmit}
      schema={WatermarkFormSchema}
      defaultValues={defaultValues}
    >
      {(control, formApi) => (
        <Card title={i18n.t('page.salesSettings.labels.watermark')}>
          <FormField
            type="uploadImage"
            control={control}
            name="fileId"
            uploadFileRequest={handleUpload}
            onUploadError={handleUploadError}
            helperText={i18n.t('entity.photo.labels.uploadOrDragAndDropImage')}
            uploadingText={i18n.t('general.labels.uploadingWithDots')}
            uploadIcon="image/photo_camera"
            linkValueFormatter={(url) => url.replace('resize', 'get')}
            srcValueFormatter={(url) => `${url}${url.includes('resize') ? '&height=108' : ''}`}
            onPreviewClick={(url) => window.open(url.replace('resize', 'get'), '_blank')}
            isRequired
            data-testid="watermarks-old-configurationName"
          />
          <Separator />
          <HStack align="center" spacing={4}>
            <VStack spacing={2} width={80}>
              <FormField
                type="slider"
                name="size"
                control={control}
                min={0}
                max={100}
                step={1}
                ticks={2}
                label={i18n.t('entity.watermark.labels.size')}
                isDisabled={formApi.watch('fitToImage').includes('fitToImage')}
                data-testid="watermarks-old-size"
              />
            </VStack>
            <FormField
              type="checkboxes"
              name="fitToImage"
              control={control}
              options={[
                {
                  value: 'fitToImage',
                  label: i18n.t('entity.watermark.labels.fitToImage'),
                },
              ]}
              data-testid="watermarks-old-fitToImage"
            />
          </HStack>
          <Separator />
          <VStack spacing={4}>
            <FormField
              label={i18n.t('entity.watermark.labels.position')}
              type="radio"
              name="position"
              control={control}
              options={getPositionOptions('top')}
              data-testid="watermarks-old-position-top"
            />
            <FormField
              type="radio"
              name="position"
              control={control}
              options={getPositionOptions('center')}
              data-testid="watermarks-old-position-center"
            />
            <FormField
              type="radio"
              name="position"
              control={control}
              options={getPositionOptions('bottom')}
              data-testid="watermarks-old-position-bottom"
            />
          </VStack>
          <Separator />
          <FormField
            type="radio"
            name="addOnlyForFirstPhoto"
            control={control}
            options={addToOptions}
            data-testid="watermarks-old-addOnlyForFirstPhoto"
          />
          <Separator />
          <VStack spacing={4}>
            <WatermarkDetailPreview watermarkData={getRequestOldData(formApi.watch())} />
          </VStack>
          <Separator />
          <VStack justify="flex-end">
            <ButtonGroup>
              <Button
                variant="secondary"
                onClick={handleDiscard}
                title={i18n.t('general.actions.discard')}
                data-testid="watermarks-old-discard"
              />
              <FormButton
                type="submit"
                isLoading={isUpdatingWatermark}
                title={i18n.t('general.actions.save')}
                control={control}
                data-testid="watermarks-old-submit"
              />
            </ButtonGroup>
          </VStack>
        </Card>
      )}
    </Form>
  );
}

const WatermarkFormSchema = Yup.object({
  fileId: Yup.mixed()
    .nullable()
    .test(
      'fileId-required',
      i18n.t('general.errors.mixed.required'),
      (value) => value !== null && value !== undefined
    ),
  size: Yup.number().required(),
  position: Yup.string().required(),
  fitToImage: Yup.array().required(),
  addOnlyForFirstPhoto: Yup.string().required(),
});
