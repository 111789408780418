import {omneticApi as api} from '@dms/api/core';

import {
  CreateTestDriveApiArg,
  CreateTestDriveApiResponse,
  GetTestDriveApiFromBusinessCaseArg,
  BCGetTestDriveApiResponse,
} from './types';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTestDriveFromBusinessCase: build.query<
      BCGetTestDriveApiResponse,
      GetTestDriveApiFromBusinessCaseArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/test-drive`,
        headers: {Authorization: queryArg.authorization},
        params: {businessCaseId: queryArg.businessCaseId},
      }),
    }),
    createTestDrive: build.mutation<CreateTestDriveApiResponse, CreateTestDriveApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/test-drive`,
        method: 'POST',
        body: queryArg.testDriveRequestBody,
        headers: {Authorization: queryArg.authorization},
        params: {businessCaseId: queryArg.businessCaseId},
      }),
    }),
  }),
  overrideExisting: false,
});

export const {useGetTestDriveFromBusinessCaseQuery, useCreateTestDriveMutation} = injectedRtkApi;
