import {isPast, startOfDay} from 'date-fns';
import {Text} from 'platform/foundation';
import {DateTimeFormat, LocaleDateTimeFormat, useLocale} from 'platform/locale';

import {formatDateToNaturalLanguage} from '@dms/shared';

import {parseDate, RequiredTestIdProps, suffixTestId} from 'shared';

interface TaskCenterTaskDateProps extends RequiredTestIdProps {
  value: string;
  hideTime?: boolean;
}

export function TaskCenterTaskDate(props: TaskCenterTaskDateProps) {
  const {language} = useLocale();
  const parsedDate = parseDate(props.value);

  const isOverdue = isPast(startOfDay(parsedDate));

  const dateInNaturalLanguage = formatDateToNaturalLanguage(parsedDate, language);

  const dateFormat: LocaleDateTimeFormat = props.hideTime ? 'dateShort' : 'dateTimeShort';

  return (
    <Text
      size="xSmall"
      color={isOverdue ? 'danger' : 'secondary'}
      data-testid={suffixTestId('task-center-date', props)}
    >
      <DateTimeFormat format={dateFormat} value={parsedDate} />
      {dateInNaturalLanguage ? ` (${dateInNaturalLanguage})` : ''}
    </Text>
  );
}
