import {CustomFilterProps} from 'ag-grid-react';
import {isDate} from 'date-fns';
import {Chips, DatePicker, Separator, useTranslationContext} from 'platform/components';
import {HStack, VStack} from 'platform/foundation';

import {useMemo} from 'react';

import {intersection} from 'ramda';

import {getApiDateString, isApiDateString, parseDate, suffixTestId, TestIdProps} from 'shared';

import {quickFiltersConst} from '../../constants/quickFilters';
import {useFilterOnChipsChange} from '../../hooks/useFilterOnChipsChange';
import {QuickFilters} from '../../types/Api';
import {parseDateFilter} from '../../utils/parseDateFilter';

export type DateFilterProps = {
  min: string | null;
  max: string | null;
  relativeDates: Array<{value: string; label: string}>;
  isDisabled: boolean;
} & CustomFilterProps &
  QuickFilters &
  TestIdProps;

export function DateFilter(props: DateFilterProps) {
  const t = useTranslationContext();
  const minDate = useMemo(() => parseDateFilter(props.min), [props.min]);
  const maxDate = useMemo(() => parseDateFilter(props.max), [props.max]);

  const setFilterValue = (model: Date | string | null) => {
    const newModel = isDate(model) ? getApiDateString(model) : model;
    props.onModelChange(newModel);
  };

  const {onChipsChange} = useFilterOnChipsChange({
    setFilterValue,
    defaultValue: null,
  });

  const [datePickerValue, chipsValue] = useMemo(
    () => [
      isApiDateString(props.model) ? parseDate(props.model) : null,
      typeof props.model === 'string' ? [props.model] : [],
    ],
    [props.model]
  );

  const isQuickFilterValue = intersection([props.model], quickFiltersConst) as string[];

  return (
    <VStack spacing={4}>
      {props.quickFilters && props.quickFilters.length > 0 && (
        <>
          <HStack>
            <Chips
              isDisabled={props.isDisabled}
              value={isQuickFilterValue}
              options={props.quickFilters}
              onChange={onChipsChange}
              isMultiple={false}
              data-testid={suffixTestId('date-filter-quickFilters', props)}
              isDeselectable
            />
          </HStack>
          <Separator spacing={0} />
        </>
      )}
      {props.relativeDates && props.relativeDates.length > 0 && (
        <Chips
          isDisabled={props.isDisabled}
          value={chipsValue}
          options={props.relativeDates}
          onChange={onChipsChange}
          data-testid={suffixTestId('date-filter-relativeDates', props)}
          isDeselectable
        />
      )}
      <DatePicker
        isDisabled={props.isDisabled}
        placeholder={t('page.datagrid.filter.datePlaceholder', {
          field: props.column.getColDef().headerName,
        })}
        value={datePickerValue}
        onChange={setFilterValue}
        minDate={minDate}
        maxDate={maxDate}
        data-testid={suffixTestId('date-filter', props)}
      />
    </VStack>
  );
}
