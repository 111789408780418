import {useDispatch} from 'react-redux';

import {documentContextApi} from '@dms/api/documentContext';

export function useRefreshDocumentCount(serviceCaseId: string) {
  const dispatch = useDispatch();

  return () => {
    dispatch(documentContextApi.util.invalidateTags([{type: 'documentsCount', id: serviceCaseId}]));
  };
}
