import {Dropdown, DropdownItem, DropdownSubmenu, IconButton} from 'platform/components';
import {Icon} from 'platform/foundation';

import {GetAssignmentsResponse, useDeleteAssignmentMutation} from '@dms/api/assignment';
import i18n from '@dms/i18n';
import {getNaturalPersonFullName, handleApiError} from '@dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

interface AssignOptionProps extends TestIdProps {
  assignments: GetAssignmentsResponse;
}

export function AssignOption(props: AssignOptionProps) {
  const [deleteAssignment] = useDeleteAssignmentMutation();

  const handleUnassign = (assignmentId: string) => {
    deleteAssignment(assignmentId).unwrap().catch(handleApiError);
  };

  return (
    <Dropdown
      dropdownControl={
        <IconButton
          priority="secondary"
          size="small"
          icon="navigation/more_vert"
          aria-haspopup="true"
          data-testid={suffixTestId('asigned-usersList-button', props)}
        />
      }
    >
      {props.assignments?.map((assignment) => {
        const userName =
          getNaturalPersonFullName(assignment?.user) || assignment?.user?.email || '';

        return (
          <DropdownSubmenu
            label={userName}
            key={`submenuItem-${assignment.id}`}
            suffix={<Icon value="navigation/arrow_right" />}
          >
            <DropdownItem
              label={i18n.t('general.labels.unassign')}
              key={`submenuItem-unassign-${assignment.id}`}
              onClick={() => handleUnassign(assignment.id)}
            />
          </DropdownSubmenu>
        );
      })}
    </Dropdown>
  );
}
