import {match, Pattern} from 'ts-pattern';

import {isNil} from 'ramda';

export const getFeaturesVisible = (
  spaceNeeded: number,
  index: number,
  rect?: DOMRect,
  parentRect?: DOMRect
) => {
  if (isNil(rect) || isNil(parentRect)) {
    return index + 1;
  }

  const spaceRight = parentRect.right - rect.right;
  const spaceLeft = parentRect.right - rect.left;

  const isEnoughSpaceWithLast = spaceRight >= spaceNeeded;
  const isEnoughSpaceWithoutLast = spaceLeft >= spaceNeeded;

  const deductCount = match([isEnoughSpaceWithLast, isEnoughSpaceWithoutLast])
    .with([true, Pattern.any], () => 0)
    .with([false, true], () => 1)
    .with([false, false], () => 2)
    .exhaustive();

  return index - deductCount + 1;
};
