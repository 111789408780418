import {GetAssignmentsResponse} from '@dms/api/assignment';
import {getNaturalPersonFullName} from '@dms/shared';

export function getInspectionAssignees(assignments: GetAssignmentsResponse) {
  const assignees = assignments.map(
    (assignment) => getNaturalPersonFullName(assignment.user) || assignment.user.email
  );

  if (assignees?.length > 0) {
    return assignees.join(', ');
  }

  return null;
}
