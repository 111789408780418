import {
  Card,
  Checkbox,
  Choice,
  DataStatus,
  FormControl,
  Label,
  Separator,
  Table,
  TableRow,
} from 'platform/components';
import {Box, Grid, Heading, HStack, Show, Text, VStack} from 'platform/foundation';
import {useCurrencySymbolFormatter, useFormatCurrency, useFormatNumber} from 'platform/locale';

import {UseFormReturn, useWatch} from 'react-hook-form';

import {indexBy, isNil, isNotNil} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {useGetRentalPriceGroupQuery, useListRentalPriceGroupQuery} from '@dms/api/rentalVehicles';
import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';
import {EMPTY_PLACEHOLDER, useTenant, useVatRatesOptions} from '@dms/shared';

import {RequiredTestIdProps, useNavigate} from 'shared';

import {RentalVehicleFormType} from '../../../types/RentalVehicleFormType';
import {getPriceListColumns} from '../utils/getPriceListColumns';

interface RentalInformationProps extends RequiredTestIdProps {
  formApi: UseFormReturn<RentalVehicleFormType>;
  control: FormControl<RentalVehicleFormType>;
  isLoading: boolean;
}

export function PriceGroupCard(props: RentalInformationProps) {
  const formatNumber = useFormatNumber();
  const formatCurrency = useFormatCurrency();
  const formatCurrencySymbol = useCurrencySymbolFormatter();
  const {tenantCurrency} = useTenant();
  const navigate = useNavigate();

  const priceGroup = useWatch({control: props.control, name: 'rentalProperties.rentalPriceGroup'});

  const {data: rentalPriceGroups, isLoading, isError} = useListRentalPriceGroupQuery({});

  const {
    data: priceGroupDetail,
    isLoading: isLoadingPriceGroupDetail,
    isError: isPriceGroupDetailError,
    isFetching: isFetchingPriceGroupDetail,
  } = useGetRentalPriceGroupQuery(
    {rentalPriceGroupId: priceGroup?.rentalPriceGroupId ?? ''},
    {skip: isNil(priceGroup?.rentalPriceGroupId)}
  );

  const [vatRates] = useVatRatesOptions();
  const vatRatesByValue = indexBy((item) => item.value, vatRates);

  const priceGroupOptions =
    rentalPriceGroups?.map((priceGroup) => ({
      value: priceGroup.rentalPriceGroupId,
      label: priceGroup.name,
      fieldLabel: priceGroup.vatType,
    })) ?? [];

  const handleCheckRow = (priceGroupItemId: string) => {
    props.formApi.setValue(
      'rentalProperties.rentalPriceGroup.rentalPriceGroupItemId',
      priceGroupItemId
    );
  };

  return (
    <Card
      title={i18n.t('entity.rental.labels.priceGroup')}
      actions={[
        {
          type: 'button',
          title: i18n.t('general.labels.settings'),
          onClick: () => navigate(settingsRoutes.priceGroupList),
          variant: 'link',
          leftIcon: 'action/settings',
        },
      ]}
    >
      <VStack spacing={4}>
        <DataStatus isLoading={isLoading} isError={isError}>
          <Grid columns={4} align="center">
            <VStack>
              <Choice
                label={i18n.t('entity.rental.labels.name')}
                value={priceGroup?.rentalPriceGroupId}
                options={priceGroupOptions}
                formatOptionLabel={(option, meta) => {
                  const vatLabel = vatRatesByValue[option.fieldLabel ?? '']?.label;

                  if (meta.context === 'value') {
                    return option.label;
                  }

                  return (
                    <HStack spacing={2} align="baseline">
                      <Text size="small">{option.label}</Text>

                      <Show when={isNotNilOrEmpty(vatLabel)}>
                        <Text color="secondary" size="xSmall">
                          {vatLabel}
                        </Text>
                      </Show>
                    </HStack>
                  );
                }}
                onChange={(value) => {
                  if (isNil(value)) {
                    props.formApi.setValue('rentalProperties.rentalPriceGroup', null);
                    return;
                  }

                  props.formApi.setValue(
                    'rentalProperties.rentalPriceGroup.rentalPriceGroupId',
                    value
                  );
                }}
              />
            </VStack>

            <VStack>
              <Label>{i18n.t('general.labels.vat')}</Label>
              <Heading size={4} alternative>
                {isNotNil(priceGroup?.rentalPriceGroupId)
                  ? (vatRatesByValue[priceGroupDetail?.vatType ?? '']?.label ?? EMPTY_PLACEHOLDER)
                  : EMPTY_PLACEHOLDER}
              </Heading>
            </VStack>
          </Grid>

          <DataStatus
            spacing={10}
            isEmpty={isNilOrEmpty(priceGroup) || isNilOrEmpty(priceGroupDetail?.items)}
            isLoading={isLoadingPriceGroupDetail || isFetchingPriceGroupDetail}
            isError={isPriceGroupDetailError}
            emptyMessage={
              isNilOrEmpty(priceGroupDetail?.items) && isNotNilOrEmpty(priceGroup)
                ? i18n.t('entity.rental.labels.priceGroupWithoutItems')
                : i18n.t('entity.rental.labels.notAssignedPriceGroup')
            }
          >
            <Separator spacing={0} />

            <Table
              columns={getPriceListColumns(formatCurrencySymbol(tenantCurrency))}
              variant="bordered"
            >
              {priceGroupDetail?.items.map((item) => (
                <TableRow
                  key={item.rentalPriceGroupItemId}
                  actionsWidth="5%"
                  onRowClick={() => handleCheckRow(item.rentalPriceGroupItemId)}
                >
                  <Box padding={2}>
                    <HStack spacing={4} align="center">
                      <Checkbox
                        value={priceGroup?.rentalPriceGroupItemId === item.rentalPriceGroupItemId}
                      />
                      <Text alternative>{item.name || EMPTY_PLACEHOLDER}</Text>
                    </HStack>
                  </Box>
                  <Box padding={2}>
                    <Text>{formatNumber(item.range.from)}</Text>
                  </Box>
                  <Box padding={2}>
                    <Text>{formatNumber(item.range.to)}</Text>
                  </Box>
                  <Box padding={2}>
                    <Text>{i18n.t(`entity.rental.labels.${item.range.period}`)}</Text>
                  </Box>
                  <Box padding={2}>
                    <Text>{formatCurrency(parseFloat(item.price.rate), tenantCurrency)}</Text>
                  </Box>
                  <Box padding={2}>
                    <Text>
                      {formatCurrency(parseFloat(item.price.ratePerKilometer), tenantCurrency, 2)}
                    </Text>
                  </Box>
                  <Box padding={2}>
                    <Text>{formatNumber(item.price.includedKilometers)} Km</Text>
                  </Box>
                </TableRow>
              ))}
            </Table>
          </DataStatus>
        </DataStatus>
      </VStack>
    </Card>
  );
}
