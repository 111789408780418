import {useLocalStorage} from 'platform/components';

import {ReactNode, useEffect, useState, useCallback, useMemo} from 'react';

import {assocPath} from 'ramda';

import {FiltersContextProvider} from '../../pages/Classifieds/components/FiltersContext/FiltersContext';

export function SourcingPageWithFiltersContext(props: {children: ReactNode}) {
  const [initialFilters, saveFiltersToLocalStorage] = useLocalStorage('sourcingFilters', {});
  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    saveFiltersToLocalStorage(filters);
  }, [saveFiltersToLocalStorage, filters]);

  const onUpdateFilters = useCallback(
    (path: string[], value: any) => setFilters((filters) => assocPath(path, value, filters)),
    []
  );

  const onClearFilters = useCallback(() => setFilters({}), []);

  const contextValue = useMemo(
    () => ({
      filters,
      onChange: setFilters,
      onUpdateFilters,
      onClearFilters,
    }),
    [filters, onUpdateFilters, onClearFilters]
  );

  return <FiltersContextProvider value={contextValue}>{props.children}</FiltersContextProvider>;
}
