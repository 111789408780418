import {addDays, differenceInCalendarDays, subDays} from 'date-fns';
import {FormControl, FormField} from 'platform/components';
import {Grid} from 'platform/foundation';

import {UseFormReturn, useWatch} from 'react-hook-form';

import {isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import i18n from '@dms/i18n';

import {getApiDateString, parseDate, suffixTestId, TestIdProps} from 'shared';

import {AccountingDocumentFormValues} from '../types';

export interface CreateInvoiceDatesFieldsProps extends TestIdProps {
  control: FormControl<AccountingDocumentFormValues>;
  formApi: UseFormReturn<AccountingDocumentFormValues>;
}

export function PaymentDates(props: CreateInvoiceDatesFieldsProps) {
  const {setValue} = props.formApi;
  const [issueDate, dueSinceIssueDateInDays, dueDate] = useWatch({
    control: props.control,
    name: ['issueDate', 'dueSinceIssueDateInDays', 'dueDate'],
  });

  const handleOnIssueDateChange = (value: string | null) => {
    if (isNil(value)) {
      return;
    }

    if (isNotNil(dueSinceIssueDateInDays)) {
      setValue('dueDate', getApiDateString(addDays(parseDate(value), dueSinceIssueDateInDays)));
    } else if (isNotNil(issueDate)) {
      setValue(
        'dueSinceIssueDateInDays',
        differenceInCalendarDays(parseDate(value), parseDate(dueDate))
      );
    }
  };

  const handleOnDueDateChange = (value: string | null) => {
    if (isNil(value)) {
      return;
    }

    if (isNotNil(dueSinceIssueDateInDays)) {
      setValue('issueDate', getApiDateString(subDays(parseDate(value), dueSinceIssueDateInDays)));
    } else if (isNotNil(issueDate)) {
      setValue(
        'dueSinceIssueDateInDays',
        differenceInCalendarDays(parseDate(issueDate), parseDate(value))
      );
    }
  };

  const handleOnDueChange = (value: number | null) => {
    if (isNil(value)) {
      return;
    }

    if (isNil(issueDate) && isNotNil(dueDate)) {
      setValue('issueDate', getApiDateString(subDays(parseDate(dueDate), value)));
    } else if (isNotNil(issueDate)) {
      setValue('dueDate', getApiDateString(addDays(parseDate(issueDate), value)));
    }
  };

  const minDueDate = issueDate ? parseDate(issueDate) : undefined;

  return (
    <Grid columns={4}>
      <FormField
        control={props.control}
        name="issueDate"
        onChange={handleOnIssueDateChange}
        type="apiDate"
        label={i18n.t('entity.accounting.labels.issueDate')}
        isRequired
        data-testid={suffixTestId('issueDate', props)}
      />
      <FormField
        control={props.control}
        name="dueSinceIssueDateInDays"
        type="number"
        label={i18n.t('entity.accounting.labels.due')}
        onChange={handleOnDueChange}
        isStepperVisible
        step={1}
        minStepperValue={0}
        isRequired
        data-testid={suffixTestId('dueSinceIssueDateInDays', props)}
      />
      <FormField
        control={props.control}
        name="dueDate"
        type="apiDate"
        label={i18n.t('entity.accounting.labels.dueDate')}
        minDate={minDueDate}
        onChange={handleOnDueDateChange}
        isRequired
        data-testid={suffixTestId('dueDate', props)}
      />
      <FormField
        control={props.control}
        name="dateOfTaxableSupply"
        type="apiDate"
        label={i18n.t('entity.accounting.labels.dots')}
        isRequired
        data-testid={suffixTestId('dateOfTaxableSupply', props)}
      />
    </Grid>
  );
}
