import {useAnimatedPopper} from 'platform/components';
import {Box, Clickable} from 'platform/foundation';
import styled from 'styled-components';

import {useLocation} from 'react-router-dom';

import {MenuItemProps} from '@dms/api/shared';

import {composePath, useNavigate} from 'shared';

import {MenuItemContent} from './MenuItemContent';

interface Props {
  module: MenuItemProps;
  isPinned?: boolean;
  isHamburgerMenuItem?: boolean;
}

export function MenuItem(props: Props) {
  const location = useLocation();
  const navigate = useNavigate();

  const {openPopper, referenceRef, popperProps, Popper, closePopper, isOpen} = useAnimatedPopper({
    placement: 'right-start',
  });

  const {content, path, layoutProps, id, alwaysShowContent} = props.module;

  const testIdBase = `routePath=${path};id=${id}-menuItem`;

  const handleRedirect = () => {
    const isSamePath = location.pathname === path;

    if (isSamePath) {
      return;
    }

    navigate(composePath(path, {isSearchParamsPreserved: false}));
  };

  if (!content?.length && alwaysShowContent) {
    return null;
  }

  if (content?.length && (content.length > 1 || alwaysShowContent)) {
    return (
      <Clickable
        onClick={handleRedirect}
        onMouseEnter={openPopper}
        onMouseLeave={closePopper}
        data-testid={`${testIdBase}-container`}
      >
        <StyledMenuItem
          ref={referenceRef}
          $isHamburgerMenuItem={props.isHamburgerMenuItem}
          $isOpen={isOpen}
        >
          <Box height={12} width={80} paddingHorizontal={4} data-testid={testIdBase}>
            <MenuItemContent
              withChevronRightIcon
              id={id}
              title={layoutProps.title}
              path={path}
              isHamburgerMenuItem={props.isHamburgerMenuItem}
              isPinned={props.isPinned}
              data-testid={testIdBase}
            />
          </Box>
        </StyledMenuItem>
        <Popper {...popperProps}>
          <Box paddingBottom={12} paddingRight={12} data-testid={`${testIdBase}-popperContainer`}>
            {content.map((item) => (
              <MenuItem
                key={`${item.id}_${item.path}_${item.parentId}`}
                module={item}
                isHamburgerMenuItem={props.isHamburgerMenuItem}
              />
            ))}
          </Box>
        </Popper>
      </Clickable>
    );
  }

  return (
    <StyledMenuItem $isHamburgerMenuItem={props.isHamburgerMenuItem} $isOpen={isOpen}>
      <Box height={12} width={80} paddingLeft={4} paddingRight={4} data-testid={testIdBase}>
        <MenuItemContent
          id={id}
          isPinned={props.isPinned}
          title={layoutProps?.title}
          path={path}
          isHamburgerMenuItem={props.isHamburgerMenuItem}
          data-testid={testIdBase}
        />
      </Box>
    </StyledMenuItem>
  );
}

type StyledMenuItemProps = {
  $isHamburgerMenuItem?: boolean;
  $isOpen: boolean;
};

const StyledMenuItem = styled.div<StyledMenuItemProps>`
  background-color: ${(props) =>
    props.$isHamburgerMenuItem
      ? props.$isOpen
        ? props.theme.colors.palettes.neutral['30']['100']
        : props.theme.colors.palettes.neutral['10']['100']
      : props.theme.colors.palettes.neutral['900']['100']};
  box-shadow: ${(props) => props.theme.shadows.elevation_2};
  &:hover {
    background: ${(props) =>
      props.$isHamburgerMenuItem
        ? props.theme.colors.palettes.neutral['30']['100']
        : props.theme.colors.palettes.blue['70']['100']};
  }
`;
