import {Action} from 'platform/components';

import {useEffect, useRef} from 'react';
import {Helmet} from 'react-helmet-async';

import {isFalse, isNilOrEmpty} from 'ramda-adjunct';

import featureFlags from '@dms/feature-flags';
import {openCreateTaskFormDialog, openEditTaskFormDialog} from '@dms/features/tasks';
import i18n from '@dms/i18n';
import {taskManagerRoutes, testIds} from '@dms/routes';
import {Main, PageTab, usePermissions} from '@dms/shared';

import {buildArray, useQueryState} from 'shared';

import {DataGridRef} from 'features/datagrid';

import {TaskCalendar} from './(sections)/TaskCalendar/TaskCalendar';
import {TasksDataGrid} from './(sections)/TasksDataGrid/TasksDataGrid';

export function TaskOverview() {
  const [canCreateTask] = usePermissions({
    permissionKeys: ['createTask'],
  });
  const dataGridRef = useRef<DataGridRef>(null);

  const [taskId] = useQueryState('taskId');

  const wasTaskDetailShown = useRef(isNilOrEmpty(taskId));

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (taskId && isFalse(dataGridRef.current?.isLoading) && isFalse(wasTaskDetailShown.current)) {
      timeout = setTimeout(() => {
        openEditTaskFormDialog({
          id: taskId,
          onEdit: () => dataGridRef.current?.refreshData?.(),
          'data-testid': testIds.taskManager.taskOverview('editTaskDialog'),
        });
        wasTaskDetailShown.current = true;
      });
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [taskId, dataGridRef.current?.isLoading]);

  const Actions = buildArray<Action>().when(canCreateTask, {
    type: 'button',
    title: i18n.t('entity.task.actions.createTask'),
    variant: 'primary',
    'data-testid': testIds.taskManager.taskOverview('createTask'),
    onClick: () =>
      openCreateTaskFormDialog({
        onCreate: () => dataGridRef.current?.refreshData?.(),
        'data-testid': testIds.taskManager.taskOverview('createTaskDialog'),
      }),
  });

  const Tabs = buildArray<PageTab>()
    .add({
      id: 'task-list',
      title: i18n.t('entity.task.labels.tasks'),
      href: taskManagerRoutes.taskOverview,
      content: (
        <TasksDataGrid
          dataGridRef={dataGridRef}
          data-testid={testIds.taskManager.taskOverview('grid')}
        />
      ),
    })
    .whenFeatureEnabled(featureFlags.CORE_TASK_CALENDAR, {
      id: 'task-calendar',
      title: i18n.t('entity.task.labels.tasksCalendar'),
      href: taskManagerRoutes.taskCalendar,
      content: <TaskCalendar />,
    });

  return (
    <>
      <Helmet title={i18n.t('entity.task.labels.taskManagement')} />
      <Main
        isFullHeight
        data-testid={testIds.taskManager.taskOverview()}
        actions={Actions}
        tabs={Tabs}
      />
    </>
  );
}
