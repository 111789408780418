import {Trend} from 'platform/components';
import {Show} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {isFalse} from 'ramda-adjunct';

import {Money} from '@dms/api/shared';

import {EMPTY_PLACEHOLDER, RequiredTestIdProps, suffixTestId} from 'shared';

import {isPositiveValue} from '../utils/isPositiveValue';

export type TagVariant = {tag: number; tagType: 'percent'} | {tag: Money; tagType: 'amount'};

type TagProps = TagVariant &
  RequiredTestIdProps & {size?: 'default' | 'large'; areInvertedNumbers?: boolean};

export const Tag = (props: TagProps) => {
  const formatCurrency = useFormatCurrency();

  if (!props.tag || !props.tagType) {
    return null;
  }

  const tagValue = getTagValue(props.tagType, props.tag);
  const isTagPositive = isPositiveValue(tagValue, props.areInvertedNumbers);

  const formattedTagValue =
    props.tagType === 'percent'
      ? `${Math.abs(tagValue)}%`
      : (formatCurrency(tagValue, props.tag?.currency!) ?? EMPTY_PLACEHOLDER);

  return (
    <Show when={!isNaN(tagValue)}>
      <Trend
        label={formattedTagValue}
        isPositive={isTagPositive}
        isNegative={isFalse(isTagPositive)}
        isSubtle
        isSmall={props.size !== 'large'}
        data-testid={suffixTestId('trend', props)}
      />
    </Show>
  );
};

const getTagValue = (tagType: string, tag: number | Money) =>
  tagType === 'percent' ? (tag as number) : Number((tag as Money)?.amount);
