import {isFeatureEnabled} from 'feature-flags';
import {Card, Separator, Tooltip} from 'platform/components';
import {Grid, Box, Show} from 'platform/foundation';
import {css} from 'styled-components';

import {FC, ReactElement} from 'react';
import {useSelector} from 'react-redux';

import {toUpper, isNotNil, sortBy} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetParticipationQuery} from '@dms/api/participation';
import {useGetSalePresetSettingsListQuery} from '@dms/api/sales';
import {useGetSaleVehicleQuery} from '@dms/api/saleVehicle';
import {EntityResourceIds} from '@dms/api/shared';
import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {useCodeList, usePermissions} from '@dms/shared';

import {Nullish} from 'shared';

import {
  selectConditions,
  selectServiceBooks,
  selectServiceBookState,
} from '../../../store/vehicleCatalogue/selectors';
import {CreateVehicleRequestBody} from '../../../types/CreateVehicleRequestBody';
import {ServiceBookEnum} from '../../../types/ServiceBookEnum';
import {VehicleType} from '../../../types/VehicleType';
import {useFormRenderer} from '../../FinalForm/hooks/useFormRenderer';

/**
 * Filters non-alphanumeric symbols like and invalid letters.
 * It's used for registration and registration certificate fields
 *
 * @example +@#$~^&*{}ěščřžýáí
 */
const NOT_ALPHANUMERIC_REGEX_FILTER = /[^A-Z0-9]+/;

const ServiceBookTypeField: FC<{vehicleType: VehicleType}> = ({vehicleType}) => {
  const serviceBookTypes = useSelector(selectServiceBooks(vehicleType));

  const {Field} = useFormRenderer<CreateVehicleRequestBody>();

  return (
    <Field
      name="state.serviceBookType"
      as="chips"
      label={i18n.t('general.labels.type')}
      options={serviceBookTypes}
      enabledDeselect
    />
  );
};

const ServiceBookStateField: FC<{vehicleType: VehicleType}> = ({vehicleType}) => {
  const serviceBookState = useSelector(selectServiceBookState(vehicleType));

  const {Field} = useFormRenderer<CreateVehicleRequestBody>();

  return (
    <Field
      name="state.serviceBookState"
      as="chips"
      label={i18n.t('entity.vehicle.labels.serviceBookStateLabel')}
      options={serviceBookState}
      enabledDeselect
    />
  );
};

interface ConditionProps {
  vehicleId: string | Nullish;
}

export const Condition = (props: ConditionProps): ReactElement => {
  const {Field, Condition, Subscribe} = useFormRenderer<CreateVehicleRequestBody>();

  const {data: vehicleParticipation} = useGetParticipationQuery(
    {
      recordId: props.vehicleId ?? '',
      resourceId: EntityResourceIds.vehicle,
    },
    {skip: isNilOrEmpty(props.vehicleId)}
  );
  const {data: saleVehicle} = useGetSaleVehicleQuery(
    {vehicleId: props.vehicleId ?? ''},
    {skip: isNilOrEmpty(props.vehicleId)}
  );

  const [canReadAdvertisingSettings] = usePermissions({
    permissionKeys: ['readAdvertisingSettings'],
    scopes: {
      readAdvertisingSettings: {
        participation: vehicleParticipation,
        branchId: saleVehicle?.branchId,
      },
    },
  });

  const [vehicleSourceCodes] = useCodeList('vehicle_source');

  const {data: salePresetList} = useGetSalePresetSettingsListQuery();

  const salePresetOptions = salePresetList?.map(({id, name}) => ({id, label: name})) ?? [];

  const vehicleSourceOptions = sortBy((code) => code.priority, vehicleSourceCodes ?? []).map(
    (source) => ({
      id: source.codeId,
      label: source.name,
    })
  );

  return (
    <>
      <Subscribe
        name="type"
        component={({input: {value: vehicleType}}) => {
          /* eslint-disable react-hooks/rules-of-hooks */
          const conditions = useSelector(selectConditions(vehicleType));
          return (
            <Field
              name="state.condition"
              as="chips"
              label={i18n.t('entity.vehicle.labels.condition')}
              options={conditions}
              enabledDeselect
            />
          );
        }}
      />
      <Grid columns={4}>
        <Field
          as="country-select"
          name="modelSpecification.originCountry"
          label={i18n.t('entity.vehicle.labels.countryOfOrigin')}
        />
        <Field
          as="country-select"
          name="modelSpecification.firstRegistrationCountry"
          label={i18n.t('entity.vehicle.labels.firstRegistrationCountry')}
        />
        <Field
          as="country-select"
          name="modelSpecification.lastRegistrationCountry"
          label={i18n.t('entity.vehicle.labels.lastRegistrationCountry')}
        />
      </Grid>
      <Grid columns={4}>
        <Field
          name="modelSpecification.isRegistered"
          as="checkbox"
          label={i18n.t('entity.vehicle.labels.registered')}
        />
        <Field name="state.hasCoc" as="checkbox" label={i18n.t('entity.vehicle.labels.hasCoc')} />
      </Grid>
      <Grid columns={4}>
        <Subscribe
          name="type"
          component={({input: {value: vehicleType}}) => {
            if (
              vehicleType === 'VEHICLETYPE_TRAILER' ||
              vehicleType === 'VEHICLETYPE_SEMI_TRAILER'
            ) {
              return null;
            }
            return (
              <>
                <Field
                  as="integer"
                  name="state.mileage"
                  label={i18n.t('entity.vehicle.labels.mileage')}
                  suffix={i18n.t('general.metric.km')}
                />
                <Tooltip
                  label={i18n.t('entity.vehicle.labels.realMileage')}
                  description={i18n.t('entity.vehicle.descriptions.realMileage')}
                  placement="top-start"
                >
                  <Field
                    as="integer"
                    name="state.realMileage"
                    label={i18n.t('entity.vehicle.labels.realMileage')}
                    suffix={i18n.t('general.metric.km')}
                  />
                </Tooltip>
              </>
            );
          }}
        />
        <Field
          as="integer"
          name="state.ownerCount"
          label={i18n.t('entity.vehicle.labels.previousOwners')}
        />
        <Field
          as="integer"
          name="state.primaryKeyCount"
          label={i18n.t('entity.vehicle.labels.numberOfKeysFull')}
        />
      </Grid>
      <Grid columns={4}>
        <Field
          name="state.vehicleRegistrationNumber"
          label={i18n.t('entity.vehicle.labels.vehicleRegistration')}
          parse={(value) =>
            isNotNil(value) ? toUpper(value).replace(NOT_ALPHANUMERIC_REGEX_FILTER, '') : null
          }
          maxLength={17}
        />
        <Field
          name="state.registrationCertificateNumber"
          label={i18n.t('entity.vehicle.labels.registrationCertificate')}
          parse={(value) =>
            isNotNil(value) ? toUpper(value).replace(NOT_ALPHANUMERIC_REGEX_FILTER, '') : null
          }
          maxLength={17}
        />
      </Grid>
      <Box>
        <Field
          name="state.otherRecords"
          label={i18n.t('entity.vehicle.labels.otherRecords')}
          multiline
          textareaProps={{rows: 3, isScrollable: true, isResizable: true}}
        />
        <Separator spacing={0} orientation="horizontal" />
      </Box>
      <Grid columns={4}>
        <Show when={vehicleSourceCodes?.length}>
          <Field
            as="select"
            options={vehicleSourceOptions}
            getOptionValue={(option) => option.id}
            name="vehicleSourceCodeId"
            label={i18n.t('entity.vehicle.labels.vehicleSource')}
          />
        </Show>
        <Show
          when={
            salePresetOptions?.length > 0 &&
            isFeatureEnabled(featureFlags.SALES_SALE_PROGRAMS) &&
            canReadAdvertisingSettings
          }
        >
          <Field
            as="select"
            options={salePresetOptions}
            getOptionValue={(option) => option.id}
            name="salePresetId"
            label={i18n.t('entity.vehicle.labels.salePreset')}
          />
        </Show>
      </Grid>
      <Box paddingBottom={4}>
        <Card
          variant="inlineGrey"
          data-testid="conditionService-cardHeader-title"
          title={i18n.t('entity.vehicle.labels.serviceBook')}
        >
          <div
            css={css`
              margin-bottom: -16px;
            `}
          >
            <Grid columns={2}>
              <Subscribe
                name="type"
                component={({input: {value: vehicleType}}) => (
                  <ServiceBookTypeField vehicleType={vehicleType} />
                )}
              />
              <Condition when="state.serviceBookType" not={ServiceBookEnum.SERVICEBOOKTYPE_NONE}>
                <Subscribe
                  name="type"
                  component={({input: {value: vehicleType}}) => (
                    <ServiceBookStateField vehicleType={vehicleType} />
                  )}
                />
              </Condition>
            </Grid>
          </div>
        </Card>
      </Box>
      <Card
        variant="inlineGrey"
        data-testid="conditionInspection-cardHeader-title"
        title={i18n.t('entity.vehicle.labels.serviceInspection')}
      >
        <div
          css={css`
            margin-bottom: -16px;
          `}
        >
          <Grid columns={4}>
            <Field
              name="state.lastServiceDate"
              as="date"
              label={i18n.t('entity.vehicle.labels.lastService')}
            />
            <Field
              as="integer"
              name="state.lastServiceMileage"
              label={i18n.t('entity.vehicle.labels.lastService')}
              suffix={i18n.t('general.metric.km')}
            />
            <Field
              name="state.nextServiceDate"
              as="date"
              label={i18n.t('entity.vehicle.labels.nextService')}
            />
            <Field
              as="integer"
              name="state.nextServiceMileage"
              label={i18n.t('entity.vehicle.labels.nextService')}
              suffix={i18n.t('general.metric.km')}
            />
          </Grid>
        </div>
      </Card>
    </>
  );
};
