import {useParams} from 'react-router-dom';

import {isNil} from 'ramda';

import {useGetCustomerContractByIdQuery} from '@dms/api/metadaCustomerContract';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {AssignedCustomersTab} from './(sections)/AssignedCustomersTab/AssignedCustomersTab';
import {BasicInformationTab} from './(sections)/BasicInformationTab/BasicInformationTab';
import {IndividualPricesTab} from './(sections)/IndividualPricesTab/IndividualPricesTab';
import {MaterialDiscountsTab} from './(sections)/MaterialDiscountsTab/MaterialDiscountsTab';
import {WorkDiscountsTab} from './(sections)/WorkDiscountTab/WorkDiscountsTab';

export function CustomerContractsDetail() {
  const {id} = useParams();
  const isCreate = isNil(id);

  const {data} = useGetCustomerContractByIdQuery({customerContractId: id ?? ''}, {skip: isCreate});
  return (
    <SettingsTemplate
      header={{
        title: isCreate
          ? i18n.t('entity.customerContract.actions.newCustomerContract')
          : (data?.name ?? undefined),
        breadcrumbs: [
          {
            label: i18n.t('entity.customerContract.labels.customerContracts'),
            href: settingsRoutes.customerContracts,
          },
        ],
      }}
      isCreating={isCreate}
      tabs={[
        {
          title: i18n.t('general.labels.basicInformation'),
          content: <BasicInformationTab />,
          queryId: 'basic-information',
        },
        {
          title: i18n.t('entity.customerContract.labels.workDiscounts'),
          content: <WorkDiscountsTab />,
          queryId: 'work-discounts',
          isDisabled: isCreate,
        },
        {
          title: i18n.t('entity.customerContract.labels.materialDiscounts'),
          content: <MaterialDiscountsTab />,
          queryId: 'material-discounts',
          isDisabled: isCreate,
        },
        {
          title: i18n.t('entity.customerContract.labels.individualPrices'),
          content: <IndividualPricesTab />,
          queryId: 'individual-prices',
          isDisabled: isCreate,
        },
        {
          title: i18n.t('entity.customerContract.labels.assignedCustomers'),
          content: <AssignedCustomersTab />,
          queryId: 'assigned-customers',
          isDisabled: isCreate,
        },
      ]}
      data-testid={testIds.settings.customerContractsDetail('template')}
    />
  );
}
