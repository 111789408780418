import {isNotNil} from 'ramda';

import {useLazyCreateDataQueryQuery} from '@dms/api/datagrid';
import {useGetCurrentUserInfoQuery} from '@dms/api/user';
import {handleApiError} from '@dms/shared';

import {buildObject, composePath, useNavigate} from 'shared';

import {convertToSearchParams} from 'features/datagrid';

import {OpportunityType} from './types/OpportunityType';
import {OwnerSegment} from './types/OwnerSegment';
import {TypeSegment} from './types/TypeSegment';
import {getGridCode} from './utils/getGridCode';
import {getOrder} from './utils/getOrder';
import {getRedirectUrl} from './utils/getRedirectUrl';
import {getSpecificFilters} from './utils/getSpecificFilters';

type RedirectFromOpportunitiesProps = {
  typeSegment: TypeSegment | null;
  ownerSegment: OwnerSegment | null;
  branchId: string | undefined;
};

export const useRedirectFromOpportunities = (props: RedirectFromOpportunitiesProps) => {
  const [createDataQuery, {isLoading}] = useLazyCreateDataQueryQuery();
  const {data: userInfo} = useGetCurrentUserInfoQuery();

  const navigate = useNavigate();
  const currentUserId = userInfo?.id;

  const handleRedirectFromFunnel = (opportunityType: OpportunityType) => {
    const gridCode = getGridCode(opportunityType);
    const url = getRedirectUrl(opportunityType);
    const order = getOrder(opportunityType);

    const filters = buildObject<Record<string, string[]>>(
      getSpecificFilters(opportunityType, props.typeSegment === 'selling')
    )
      .owner([currentUserId ?? ''], isNotNil(currentUserId) && props.ownerSegment === 'my')
      .branch([props.branchId ?? ''], isNotNil(props.branchId))
      .build();

    createDataQuery({
      code: gridCode,
      createDataQueryRequestBody: {
        filters,
        order: [order],
        smartSearch: null,
      },
    })
      .unwrap()
      .then((data) => {
        const queryParams = convertToSearchParams({
          dataGrids: {
            gridCode,
            queryId: data.dataQueryId,
            rowIndex: '1',
          },
        });
        navigate(composePath(url, {queryParams}));
      })
      .catch(handleApiError);
  };

  return [handleRedirectFromFunnel, isLoading] as const;
};
