import {FormControl, FormField, useFormContext} from 'platform/components';
import {Grid, GridItem, Heading, VStack} from 'platform/foundation';

import {GetWarehouseAccountResponse} from '@dms/api/metadaWarehouseAccount';
import {
  BaseArticle,
  basePriceSourceObject,
  GetArticleResponse,
} from '@dms/api/metadaWarehouseArticle';
import {TenantResponseBody} from '@dms/api/shared';
import i18n from '@dms/i18n';

import {CurrencyCodeType} from 'shared';

interface PricesTabPricesFormProps {
  control: FormControl<BaseArticle>;
  article: GetArticleResponse;
  tenant: TenantResponseBody;
  warehouseAccount: GetWarehouseAccountResponse;
}

export function PricesTabPricesForm(props: PricesTabPricesFormProps) {
  const {watch} = useFormContext();

  const currency = props.tenant?.currency as CurrencyCodeType;
  const basePriceSource = watch('pricesSettings.basePriceSource');
  const isBasePriceDisabled = basePriceSource !== basePriceSourceObject.MANUAL;

  return (
    <>
      <VStack spacing={3}>
        <Heading size={4}>{i18n.t('general.labels.prices')}</Heading>
        {/* Base price */}
        <Grid columns={4}>
          <GridItem span={1}>
            <FormField
              control={props.control}
              isDisabled={isBasePriceDisabled}
              type="currency"
              decimalPlaces={2}
              currency={currency}
              name="dispensingPrices.basePriceWithoutVat"
              label={`${i18n.t('general.labels.basePrice')} ${i18n.t('general.labels.w/oVat')}`}
            />
          </GridItem>
          <GridItem span={1}>
            <FormField
              control={props.control}
              isDisabled
              type="currency"
              decimalPlaces={2}
              currency={currency}
              name="dispensingPrices.basePriceWithVat"
              label={`${i18n.t('general.labels.basePrice')} ${i18n.t('general.labels.withVat')}`}
            />
          </GridItem>
        </Grid>
        {/* Sales base price */}
        <Grid columns={8}>
          <GridItem span={2}>
            <FormField
              control={props.control}
              type="currency"
              decimalPlaces={2}
              currency={currency}
              name="dispensingPrices.saleBasePriceWithoutVat"
              label={`${i18n.t('general.labels.salesBasePrice')} ${i18n.t('general.labels.w/oVat')}`}
            />
          </GridItem>
          <GridItem span={2}>
            <FormField
              control={props.control}
              type="currency"
              decimalPlaces={2}
              currency={currency}
              name="dispensingPrices.saleBasePriceWithVat"
              label={`${i18n.t('general.labels.salesBasePrice')} ${i18n.t('general.labels.withVat')}`}
            />
          </GridItem>
          <GridItem span={1}>
            <FormField
              control={props.control}
              type="currency"
              name="dispensingPrices.saleBaseProfit"
              label={i18n.t('general.labels.profit')}
            />
          </GridItem>
          <GridItem span={1}>
            <FormField
              control={props.control}
              type="number"
              decimalPlaces={2}
              isStepperVisible
              name="dispensingPrices.saleBaseMarginPercent"
              label={`${i18n.t('general.labels.margin')} (%)`}
            />
          </GridItem>
          <GridItem span={1}>
            <FormField
              control={props.control}
              type="number"
              decimalPlaces={2}
              isStepperVisible
              name="dispensingPrices.saleBaseMarkUpPercent"
              label={`${i18n.t('general.labels.markupPrice')} (%)`}
            />
          </GridItem>
        </Grid>
        {/* Warranty price */}
        <Grid columns={8}>
          <GridItem span={2}>
            <FormField
              control={props.control}
              type="currency"
              currency={currency}
              decimalPlaces={2}
              name="dispensingPrices.warrantyPriceWithoutVat"
              label={`${i18n.t('general.labels.warrantyPrice')} ${i18n.t('general.labels.w/oVat')}`}
            />
          </GridItem>
          <GridItem span={2}>
            <FormField
              control={props.control}
              type="currency"
              currency={currency}
              decimalPlaces={2}
              name="dispensingPrices.warrantyPriceWithVat"
              label={`${i18n.t('general.labels.warrantyPrice')} ${i18n.t('general.labels.withVat')}`}
            />
          </GridItem>
          <GridItem span={1}>
            <FormField
              isDisabled
              control={props.control}
              type="currency"
              name="dispensingPrices.warrantyProfit"
              label={i18n.t('general.labels.profit')}
            />
          </GridItem>
          <GridItem span={1}>
            <FormField
              isDisabled
              control={props.control}
              type="number"
              decimalPlaces={2}
              isStepperVisible
              name="dispensingPrices.warrantyMarginPercent"
              label={`${i18n.t('general.labels.margin')} (%)`}
            />
          </GridItem>
          <GridItem span={1}>
            <FormField
              isDisabled
              control={props.control}
              type="number"
              decimalPlaces={2}
              isStepperVisible
              name="dispensingPrices.warrantyMarkUpPercent"
              label={`${i18n.t('general.labels.markupPrice')} (%)`}
            />
          </GridItem>
        </Grid>
      </VStack>
    </>
  );
}
