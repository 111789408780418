import {Box, HStack, Icon, Text} from 'platform/foundation';

import {SIDEBAR_DESKTOP_ICON_SIZE} from '../constants/sidebarDesktopIconSize';
import {SIDEBAR_MOBILE_ICON_SIZE} from '../constants/sidebarMobileIconSize';
import {useIsMobileNavigation} from '../hooks/useIsMobileNavigation';
import {MenuItemProps} from '../types/MenuItemProps';
import {getMenuItemDepthPadding} from '../utils/getMenuItemDepthPadding';

interface MenuItemContentProps
  extends Pick<MenuItemProps, 'depth' | 'iconSize'>,
    Pick<MenuItemProps['layoutProps'], 'title' | 'icon'> {}

export function MenuItemContent(props: MenuItemContentProps) {
  const isMobileNavigation = useIsMobileNavigation();

  return (
    <Box overflow="hidden" paddingLeft={getMenuItemDepthPadding(props.depth)}>
      <HStack spacing={2} justify="space-between" align="center">
        <Icon
          size={
            isMobileNavigation
              ? SIDEBAR_MOBILE_ICON_SIZE
              : SIDEBAR_DESKTOP_ICON_SIZE[props.iconSize]
          }
          color="text.white"
          value={props.icon}
        />
        <Box width="100%" overflow="hidden">
          <Text size="small" color="white" noWrap>
            {props.title}
          </Text>
        </Box>
      </HStack>
    </Box>
  );
}
