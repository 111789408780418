import {ButtonProps, FormControl, TabsHeader} from 'platform/components';
import {Box, Show} from 'platform/foundation';
import {match} from 'ts-pattern';

import {SubmitHandler, UseFormReturn} from 'react-hook-form';

import i18n from '@dms/i18n';
import {FullScreenModal} from '@dms/shared';

import {buildArray, RequiredTestIdProps, suffixTestId} from 'shared';

import {BasicInformation} from '../(sections)/BasicInformation/BasicInformation';
import {Features} from '../(sections)/Features/Features';
import {RentalInformation} from '../(sections)/RentalInformation/RentalInformation';
import {ServiceIntervals} from '../(sections)/ServiceIntervals/ServiceIntervals';
import {VehicleData} from '../(sections)/VehicleData/VehicleData';
import {useTabs} from '../hooks/useTabs';
import {RentalVehicleFormMode} from '../types/RentalVehicleFormMode';
import {RentalVehicleFormTabs} from '../types/RentalVehicleFormTabs';
import {RentalVehicleFormType} from '../types/RentalVehicleFormType';

interface ExtendedViewProps extends RequiredTestIdProps {
  mode: RentalVehicleFormMode;
  isVehicleCreating: boolean;
  isVehicleUpdating: boolean;
  control: FormControl<RentalVehicleFormType>;
  formApi: UseFormReturn<RentalVehicleFormType>;
  onVehicleCreation: SubmitHandler<RentalVehicleFormType>;
  onVehicleUpdate: SubmitHandler<RentalVehicleFormType>;
  onCreateRentalVehicleFromSaleOrService: SubmitHandler<RentalVehicleFormType>;
  onDiscard: () => void;
}

export function FormStructure(props: ExtendedViewProps) {
  const {selectedTab, isPending, setSelectedTab} = useTabs();

  const title = match(props.mode)
    .with(RentalVehicleFormMode.NEW, () => i18n.t('entity.vehicle.labels.createRentalVehicle'))
    .with(RentalVehicleFormMode.EDIT, () => i18n.t('entity.vehicle.labels.editRentalVehicle'))
    .with(RentalVehicleFormMode.CLONE, () => i18n.t('entity.vehicle.labels.cloneRentalVehicle'))
    .with(RentalVehicleFormMode.NEW_FROM_SALE_OR_SERVICE, () =>
      i18n.t('entity.vehicle.actions.createRentalProcess')
    )
    .exhaustive();

  return (
    <FullScreenModal
      headline={title}
      actions={buildArray<ButtonProps>()
        .add({
          variant: 'secondary',
          title: i18n.t('general.actions.discard'),
          onClick: props.onDiscard,
          'data-testid': suffixTestId('main-discard', props),
        })
        .when(props.mode === RentalVehicleFormMode.NEW, {
          variant: 'primary',
          title: i18n.t('general.actions.create'),
          type: 'submit',
          isLoading: props.isVehicleCreating,
          onClick: props.formApi.handleSubmit(props.onVehicleCreation),
          'data-testid': suffixTestId('main-create', props),
        })
        .when(props.mode === RentalVehicleFormMode.EDIT, {
          variant: 'primary',
          title: i18n.t('general.actions.save'),
          type: 'submit',
          isLoading: props.isVehicleUpdating,
          onClick: props.formApi.handleSubmit(props.onVehicleUpdate),
          'data-testid': suffixTestId('main-save', props),
        })
        .when(props.mode === RentalVehicleFormMode.CLONE, {
          variant: 'primary',
          title: i18n.t('entity.vehicle.labels.clone'),
          type: 'submit',
          isLoading: props.isVehicleCreating,
          onClick: props.formApi.handleSubmit(props.onVehicleCreation),
          'data-testid': suffixTestId('main-clone', props),
        })
        .when(props.mode === RentalVehicleFormMode.NEW_FROM_SALE_OR_SERVICE, {
          variant: 'primary',
          title: i18n.t('general.actions.create'),
          type: 'submit',
          isLoading: props.isVehicleCreating || props.isVehicleUpdating,
          onClick: props.formApi.handleSubmit(props.onCreateRentalVehicleFromSaleOrService),
          'data-testid': suffixTestId('main-createFromSaleOrService', props),
        })}
    >
      <Box
        backgroundColor="palettes.white.10.100"
        borderColor="palettes.neutral.40.100"
        borderBottom="1px solid"
        paddingHorizontal={4}
      >
        <TabsHeader
          activeTabId={selectedTab}
          onChange={(newTab: string) => setSelectedTab(newTab as RentalVehicleFormTabs)}
          tabs={[
            {
              id: RentalVehicleFormTabs.BasicInformation,
              title: i18n.t('general.labels.basicInformation'),
              'data-testid': suffixTestId('basicInformation', props),
            },
            {
              id: RentalVehicleFormTabs.VehicleData,
              title: i18n.t('entity.vehicle.labels.vehicleData'),
              'data-testid': suffixTestId('vehicleData', props),
            },
            {
              id: RentalVehicleFormTabs.Features,
              title: i18n.t('entity.vehicle.labels.features'),
              'data-testid': suffixTestId('features', props),
            },

            {
              id: RentalVehicleFormTabs.ServiceIntervals,
              title: i18n.t('entity.vehicle.labels.serviceIntervals'),
              'data-testid': suffixTestId('serviceIntervals', props),
            },
            {
              id: RentalVehicleFormTabs.RentalInformation,
              title: i18n.t('entity.vehicle.labels.rentalInformation'),
              'data-testid': suffixTestId('RentalInformation', props),
            },
          ]}
        />
      </Box>
      <Show when={selectedTab === RentalVehicleFormTabs.BasicInformation}>
        <BasicInformation
          control={props.control}
          formApi={props.formApi}
          mode={props.mode}
          data-testid={suffixTestId('basicInformationPage', props)}
          isLoading={isPending}
        />
      </Show>
      <Show when={selectedTab === RentalVehicleFormTabs.VehicleData}>
        <VehicleData
          control={props.control}
          formApi={props.formApi}
          data-testid={suffixTestId('vehicleDataPage', props)}
          isLoading={isPending}
        />
      </Show>
      <Show when={selectedTab === RentalVehicleFormTabs.Features}>
        <Features
          formApi={props.formApi}
          data-testid={suffixTestId('featuresPage', props)}
          isLoading={isPending}
        />
      </Show>
      <Show when={selectedTab === RentalVehicleFormTabs.ServiceIntervals}>
        <ServiceIntervals
          control={props.control}
          formApi={props.formApi}
          data-testid={suffixTestId('serviceIntervalsPage', props)}
          isLoading={isPending}
        />
      </Show>
      <Show when={selectedTab === RentalVehicleFormTabs.RentalInformation}>
        <RentalInformation
          formApi={props.formApi}
          control={props.control}
          data-testid={suffixTestId('rentalInformation', props)}
          isLoading={isPending}
        />
      </Show>
    </FullScreenModal>
  );
}
