import {Action, ButtonVariant} from 'platform/components';
import {match} from 'ts-pattern';

import {isEmpty, isNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {
  HeaderActionType,
  HeaderDynamicActionsResponse,
} from '@dms/api/metadaWarehouseHeaderDynamicActions';

import {buildArray, Nullish} from 'shared';

type HeaderActionCallback = (key: string, type?: HeaderActionType) => void;

type CreateHeaderActionsConfig = {
  actions: HeaderDynamicActionsResponse['actions'] | Nullish;
  callback: HeaderActionCallback;
  isLoading?: boolean;
};

const DEFAULT_ACTION_TYPE: HeaderActionType = 'BE';

/**
 * Creates header actions based on the provided configuration.
 *
 * @param {CreateHeaderActionsConfig} props - The configuration object for creating header actions.
 * @param {Object} props.actions - Actions configuration.
 * @param {Array<Object>} props.actions.options - List of action options available for the header.
 * @param {string|string[]} [props.actions.optionClick] - Key or array of keys representing primary actions to display as buttons.
 * @param {boolean} [props.isLoading] - Indicates whether the actions are in a loading state.
 * @param {Function} props.callback - Callback function invoked when an action is clicked.
 *
 * @returns {Action[]} An array of header actions consisting of:
 *   - Primary action buttons (derived from optionClick)
 *   - A dropdown menu with remaining actions (excluding hidden ones)
 *
 * @example
 * Example usage of createHeaderActions:
 * const config = {
 *   actions: {
 *     options: [
 *       { key: 'edit', title: 'Edit', variant: 'primary', position: 1 },
 *       { key: 'delete', title: 'Delete', variant: 'secondary', position: 2 },
 *       { key: 'share', title: 'Share', style: 'hidden', position: 3 },
 *     ],
 *     optionClick: ['edit', 'delete'], // Can be a single string or array of strings
 *   },
 *   isLoading: false,
 *   callback: (key, type) => {
 *     console.log(`Action clicked: ${key}, Type: ${type}`);
 *   },
 * };
 *
 * const headerActions = createHeaderActions(config);
 */
export const createHeaderActions = (props: CreateHeaderActionsConfig): Action[] => {
  if (isNil(props.actions) || isEmpty(props.actions.options)) {
    return [];
  }

  const optionClickKeys = match(props.actions.optionClick)
    .when(Array.isArray, (keys) => keys)
    .when(String, (key) => [key])
    .otherwise(() => []);

  const primaryActions = optionClickKeys
    .map((key) => props.actions?.options.find((option) => option.key === key))
    .filter(Boolean);

  const buttonsWithTooltip = primaryActions.map((action) => ({
    type: 'buttonWithTooltip',
    title: action?.title ?? action?.key ?? '',
    variant: (action?.variant as ButtonVariant) ?? 'primary',
    isDisabled: action?.style === 'disabled',
    isLoading: props.isLoading ?? false,
    tooltip: {
      label: action?.tooltip?.label,
      description: action?.tooltip?.description,
    },
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      props.callback(action.key as string, action?.type ?? DEFAULT_ACTION_TYPE);
    },
  }));

  const contextOptions = props.actions.options
    .filter((option) => !optionClickKeys.includes(option.key))
    .filter((option) => option.style !== 'hidden')
    .sort((a, b) => a.position - b.position);

  return buildArray<Action>()
    .when(isNotNilOrEmpty(contextOptions), {
      type: 'dropdown-iconButton',
      icon: 'navigation/more_vert',
      priority: 'secondary',
      menuItems: contextOptions.map((contextOption) => ({
        variant: 'primary',
        isDisabled: contextOption.style === 'disabled',
        isLoading: props.isLoading ?? false,
        label: contextOption.title ?? contextOption.key,
        tooltip: {
          label: contextOption.tooltip?.label,
          description: contextOption.tooltip?.description,
        },
        onClick: (event) => {
          event.stopPropagation();
          props.callback(contextOption.key, contextOption.type ?? DEFAULT_ACTION_TYPE);
        },
      })),
    })
    .concat(buttonsWithTooltip as Action[]);
};
