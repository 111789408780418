import {
  DeleteDialog,
  DropdownItem,
  IconButton,
  openDropdown,
  showNotification,
  useDialog,
} from 'platform/components';

import {useNavigate} from 'react-router';
import {useParams} from 'react-router-dom';

import {useDeleteInspectionMutation} from '@dms/api/carAudit';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

export const DeleteInspection = () => {
  const navigate = useNavigate();

  const params = useParams();

  const [isOpen, open, close] = useDialog();
  const [deleteInspection] = useDeleteInspectionMutation({
    fixedCacheKey: 'deleteInspection_auditVideos', // Used to detect deletion of an inspection to not block user navigating when video is being loaded
  });

  const handleDeleteInspection = () => {
    if (params.inspectionId) {
      deleteInspection({inspectionId: params.inspectionId, force: true})
        .unwrap()
        .then(() => {
          showNotification.success(i18n.t('inspection.successfully.deleted'));
          navigate(-1);
        })
        .catch((error: Error) => {
          showNotification.error(i18n.t('inspection.notSuccessfully.deleted'));
          handleApiError(error);
        });
    }
  };

  return (
    <>
      <IconButton
        icon="navigation/more_vert"
        data-testid="deleteInspection-action"
        priority="tertiary"
        onClick={(event) =>
          openDropdown(
            event,
            <>
              <DropdownItem label={i18n.t`general.actions.deleteInspection`} onClick={open} />
            </>
          )
        }
      />
      <DeleteDialog isOpen={isOpen} onClose={close} onConfirm={handleDeleteInspection} />
    </>
  );
};
