import {useDndContext} from '@dnd-kit/core';
import {SortableContext} from '@dnd-kit/sortable';
import {isFeatureEnabled} from 'feature-flags';
import {
  Action,
  Alert,
  Card,
  Choice,
  closeDialog,
  closeDropdown,
  DataStatus,
  DropdownItem,
  DropdownItemProps,
  openDeleteDialog,
  openDialog,
  openDropdown,
  Switch,
  Upload,
} from 'platform/components';
import {
  Box,
  Center,
  Hide,
  HStack,
  Icon,
  Show,
  useWindowDimensions,
  Heading,
  VStack,
  Space,
} from 'platform/foundation';
import {Lightbox, useLightbox} from 'platform/lightbox';
import {css} from 'styled-components';

import {useEffect, useState} from 'react';

import {filter, isNil, not, reject} from 'ramda';
import {isFalse, isNilOrEmpty, isNotNil, isPositive} from 'ramda-adjunct';

import {useGetParticipationQuery} from '@dms/api/participation';
import {
  useGetSaleVehicleFromSaleVehicleIdQuery,
  usePatchSaleVehiclePhotosCategoryMutation,
  usePatchSaleVehicleWatermarkMutation,
} from '@dms/api/saleVehicle';
import {
  SaleVehiclePhotoPositionDto,
  UpdatePositionInSaleVehicleRequestBody,
  useGetPromoPhotosForSaleVehicleQuery,
  useGetSaleVehicleAlbumPhotoListQuery,
} from '@dms/api/saleVehiclePhoto';
import {EntityResourceIds, VehiclePhotoCategory} from '@dms/api/shared';
import {useGetWatermarkListQuery} from '@dms/api/vehicle';
import {featureFlags} from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {
  BackgroundRemovalDialog,
  useBatchDownload,
  usePermissions,
  handleApiError,
  useAdPlatformsVehicleUpdateDialog,
} from '@dms/shared';
import {DownloadDialog} from '@dms/teas';

import {buildArray, RequiredTestIdProps, suffixTestId, useBoolean, useRequiredParams} from 'shared';

import {IMAGE_SIZE} from '../../constants/imageSize';
import {useSaleVehicleAlbum} from '../../hooks/useSaleVehicleAlbum';
import {useVehicleAlbumImageSelection} from '../../hooks/useVehicleAlbumImageSelection';
import {AlbumImage} from './AlbumImage';
import {Droppable} from './dragndrop/Droppable';
import {useVehicleAlbumDndManager} from './dragndrop/useVehicleAlbumDndManager';
import {buildDroppableItemId} from './dragndrop/utils/buildDroppableItemId';
import {buildSortableItemId} from './dragndrop/utils/buildSortableItemId';
import {UnifiedAlbumImage} from './types/UnifiedAlbumImage';
import {isImageProcessing} from './utils/isImageProcessing';
import {mergeAlbumAndPromoImages} from './utils/mergeAlbumAndPromoImages';
import {prepareAlbumImageUrl} from './utils/prepareAlbumImageUrl';
import {sortImages} from './utils/sortImages';
import {toUnifiedAlbumImage} from './utils/toUnifiedAlbumImage';

interface AlbumProps extends RequiredTestIdProps {
  title: string;
  vehicleId: string;
  saleVehicleId: string;
  saleVehicleAlbumId: string;
  isActiveSaleVehicle: boolean;
  canUpload?: boolean;
  canDelete?: boolean;
  showPromotionalPhotos?: boolean;
  globallySelectedImages?: string[];
  onBackgroundRemoval?: (
    albumId: string,
    isInProgress: boolean,
    errorFileOperationIds?: string[]
  ) => void;
  buildAlbumActionsContextMenu?: (payload: {
    images: string[];
    isProcessing: boolean;
    onSuccess?: () => void;
    onEventFired?: () => void;
  }) => React.ReactNode;
  onUpdateGlobalImageSelection?: (imageIds: string[]) => void;
  canApplyWatermark?: boolean;
  inactiveAlbumIds?: string[];
}

const ALLOWED_MIME_TYPES_TO_UPLOAD = ['image/jpeg', 'image/png', 'image/jpg'].join(', ');

export function Album(props: AlbumProps) {
  const {data: vehicleParticipation} = useGetParticipationQuery({
    resourceId: EntityResourceIds.vehicle,
    recordId: props.vehicleId,
  });

  const {data: saleVehicle} = useGetSaleVehicleFromSaleVehicleIdQuery(
    {
      vehicleId: props.saleVehicleId,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    canRemoveVehiclePhotoBackground,
    canUploadVehicleAlbumPhotos,
    canDeleteVehicleAlbumPhoto,
    canRotateVehiclePhoto,
    canMoveVehiclePhoto,
  ] = usePermissions({
    permissionKeys: [
      'removeVehiclePhotoBackground',
      'createVehicleAlbumPhotos',
      'deleteVehicleAlbumPhoto',
      'rotateVehiclePhoto',
      'moveVehiclePhoto',
    ],
    scopes: {
      createVehicleAlbumPhotos: {
        participation: vehicleParticipation,
        branchId: saleVehicle?.branchId,
      },
      deleteVehicleAlbumPhoto: {
        participation: vehicleParticipation,
        branchId: saleVehicle?.branchId,
      },
      rotateVehiclePhoto: {
        participation: vehicleParticipation,
        branchId: saleVehicle?.branchId,
      },
      moveVehiclePhoto: {
        participation: vehicleParticipation,
        branchId: saleVehicle?.branchId,
      },
      removeVehiclePhotoBackground: {
        participation: vehicleParticipation,
        branchId: saleVehicle?.branchId,
      },
    },
  });

  const canRemoveBackground =
    canRemoveVehiclePhotoBackground && isFeatureEnabled(featureFlags.SALES_BACKGROUND_REMOVAL);

  const canEditPhotos = canRotateVehiclePhoto && canMoveVehiclePhoto;

  const isUploadAllowed = canUploadVehicleAlbumPhotos && props.canUpload;

  const windowDimensions = useWindowDimensions();

  const [updateVehicleAds] = useAdPlatformsVehicleUpdateDialog();
  const [patchSaleVehiclePhotosCategory] = usePatchSaleVehiclePhotosCategoryMutation();
  const {id: vehicleId} = useRequiredParams();

  const {
    handleSaleVehicleDownload,
    isLoading: isBatchDownloadLoading,
    isStatePending: isBatchStatePending,
  } = useBatchDownload();

  const {data: watermarks} = useGetWatermarkListQuery();
  const [patchWatermark, {isLoading: isPatchingWatermark}] = usePatchSaleVehicleWatermarkMutation();

  const [shouldApplyWatermark, setShouldApplyWatermark] = useState<boolean>(
    isNotNil(saleVehicle?.watermarkId)
  );
  const [watermarkOption, setWatermarkOption] = useState<string | null>(
    saleVehicle?.watermarkId ?? null
  );
  const [lastSelectedIndex, setLastSelectedIndex] = useState<number | null>(null);

  useEffect(() => {
    if (saleVehicle?.watermarkId) {
      if (saleVehicle.watermarkId !== watermarkOption) {
        setWatermarkOption(saleVehicle.watermarkId);
      }
      if (isNotNil(saleVehicle.watermarkId) !== shouldApplyWatermark) {
        setShouldApplyWatermark(isNotNil(saleVehicle.watermarkId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saleVehicle?.watermarkId]);

  useEffect(() => {
    if (!isBatchDownloadLoading && !isBatchStatePending) {
      closeDialog('download-dialog');
    }
  }, [isBatchStatePending, isBatchDownloadLoading]);

  const saleVehicleAlbum = useSaleVehicleAlbum({
    saleVehicleAlbumId: props.saleVehicleAlbumId,
    saleVehicleId: props.saleVehicleId,
  });

  const imageSelection = useVehicleAlbumImageSelection({
    onSelectionChange: props.onUpdateGlobalImageSelection,
  });

  const [lightboxControls, {onOpen}] = useLightbox(props.saleVehicleAlbumId);

  const [imagesToRender, setImagesToRender] = useState<UnifiedAlbumImage[]>([]);
  const [isUploadInProgress, setIsUploadInProgress] = useState(false);

  const {
    data: albumImages,
    isLoading: isLoadingAlbumImages,
    isFetching: isFetchingAlbumImages,
    isError: hasAlbumImagesError,
  } = useGetSaleVehicleAlbumPhotoListQuery(
    {
      saleVehicleId: props.saleVehicleId,
      saleVehicleAlbumId: props.saleVehicleAlbumId,
    },
    {
      skip: isNilOrEmpty(props.saleVehicleId) || isNilOrEmpty(props.saleVehicleAlbumId),
    }
  );

  const {
    data: promotionalPhotos,
    isLoading: isLoadingPromotionalPhotos,
    isFetching: isFetchingPromotionalPhotos,
    isError: hasPromotionalPhotosError,
  } = useGetPromoPhotosForSaleVehicleQuery(
    {
      saleVehicleId: props.saleVehicleId,
    },
    {
      skip: isNilOrEmpty(props.saleVehicleId) || !props.showPromotionalPhotos,
    }
  );

  const initDndManager = useVehicleAlbumDndManager({
    containerId: props.saleVehicleAlbumId,
    inactiveContainerIds: props.inactiveAlbumIds ?? [],
    selectedItemIds: props.globallySelectedImages ?? [],
    onUpdate: (images) => {
      setImagesToRender(images);
    },
    onEnd: (images, sourceAlbum, preferCopy) => {
      const positions = images.reduce<UpdatePositionInSaleVehicleRequestBody[]>(
        (acc, image, index) => {
          if (image.isPromoPhoto) {
            return [
              ...acc,
              {
                saleVehiclePromoPhotoId: image.id,
                position: index + 1,
              },
            ];
          }
          return acc;
        },
        []
      );
      if (isPositive(positions.length)) {
        saleVehicleAlbum.actions.movePromotionalImages(positions);
      }

      const vehicleImages = images
        .filter((image) => not(image.isPromoPhoto))
        .reduce<SaleVehiclePhotoPositionDto[]>((acc, image, index) => {
          if (image.isDirty) {
            return [
              ...acc,
              {
                fileId: image.id,
                position: index,
                isPromoPhoto: image.isPromoPhoto,
              },
            ];
          }
          return acc;
        }, []);

      if (preferCopy) {
        return saleVehicleAlbum.actions.copyImages(sourceAlbum, vehicleImages);
      }
      return saleVehicleAlbum.actions.moveImages(sourceAlbum, vehicleImages);
    },
    onSelectedItemsMove: () => {
      imageSelection.clearImageSelection();
      hideBulkActions();
    },
  });

  const {active} = useDndContext();
  const isDndSorting = isNotNil(active);

  useEffect(() => {
    if (saleVehicle?.watermarkId) {
      setShouldApplyWatermark(true);
      setWatermarkOption(saleVehicle?.watermarkId);
    } else {
      setShouldApplyWatermark(false);
      setWatermarkOption(null);
    }
  }, [saleVehicle?.watermarkId]);

  useEffect(() => {
    if (!isFetchingAlbumImages && !isFetchingPromotionalPhotos) {
      setTimeout(() => {
        const mergedImages = mergeAlbumAndPromoImages(
          sortImages(albumImages?.map(toUnifiedAlbumImage) ?? []),
          sortImages(promotionalPhotos?.map(toUnifiedAlbumImage) ?? [])
        );
        initDndManager(mergedImages);
        setImagesToRender(mergedImages);
      });
    }
  }, [
    albumImages,
    promotionalPhotos,
    initDndManager,
    isFetchingAlbumImages,
    isFetchingPromotionalPhotos,
  ]);

  const {onBackgroundRemoval, saleVehicleAlbumId} = props;

  useEffect(() => {
    if (isNotNil(albumImages)) {
      const isBackgroundRemovalInProgress = isPositive(
        filter(
          (image) =>
            image.latestFileOperation?.state === 'IN_PROGRESS' &&
            image.latestFileOperation?.type === 'REMOVE_BACKGROUND' &&
            isFalse(image.latestFileOperation?.acknowledged),
          albumImages ?? []
        ).length
      );

      const imagesWithFailedRemoveBackgroundOperation = filter(
        (image) =>
          image.latestFileOperation?.state === 'FAILED' &&
          image.latestFileOperation?.type === 'REMOVE_BACKGROUND' &&
          isFalse(image.latestFileOperation?.acknowledged),
        albumImages ?? []
      );

      if (isPositive(imagesWithFailedRemoveBackgroundOperation.length)) {
        onBackgroundRemoval?.(
          saleVehicleAlbumId,
          isBackgroundRemovalInProgress,
          reject(
            isNil,
            imagesWithFailedRemoveBackgroundOperation.map((photo) => photo.latestFileOperation?.id)
          )
        );
      } else {
        onBackgroundRemoval?.(saleVehicleAlbumId, isBackgroundRemovalInProgress);
      }
    }
  }, [albumImages, onBackgroundRemoval, saleVehicleAlbumId]);

  const [areBulkActionsVisible, showBulkActions, hideBulkActions] = useBoolean();
  const areSelectedInBulk = areBulkActionsVisible && imageSelection.getSelectedImages().length > 0;

  // Album Bulk Actions Handlers
  const handleBulkActionsToggle = () => {
    if (areBulkActionsVisible) {
      imageSelection.clearImageSelection();
      setLastSelectedIndex(null);
      hideBulkActions();
    } else {
      showBulkActions();
    }
  };

  const handleBulkDelete = () => {
    const selectedImages = imageSelection.getSelectedImages();
    if (!selectedImages.length) {
      return;
    }
    saleVehicleAlbum.actions.deleteImageFromAlbum(selectedImages);
    handleBulkActionsToggle();
  };

  const handleBackgroundRemoval = (imageId?: string) => {
    if (!canRemoveBackground) {
      return;
    }
    let imagesToProcess: string[];

    if (isNotNil(imageId) && !areSelectedInBulk) {
      imagesToProcess = [imageId];
    } else {
      const selectedImages = imageSelection.getSelectedImages();
      imagesToProcess = isPositive(selectedImages.length)
        ? selectedImages
        : (albumImages?.map((image) => image.id) ?? []);
    }

    openDialog(
      <BackgroundRemovalDialog
        saleVehicleId={props.saleVehicleId}
        saleVehicleAlbumId={props.saleVehicleAlbumId}
        vehiclePhotoIds={imagesToProcess}
        onConfirm={() => {
          if (areBulkActionsVisible) {
            imageSelection.clearImageSelection();
            hideBulkActions();
          }
        }}
      />,
      {
        'data-testid': suffixTestId('album-removeBackground', props),
        title: i18n.t('entity.photo.actions.removeBackground'),
      }
    );
  };

  const handleCategorySelect = (
    imageId: string | string[],
    category: VehiclePhotoCategory | null
  ) => {
    patchSaleVehiclePhotosCategory({
      saleVehicleId: props.saleVehicleId,
      saleVehicleAlbumId: props.saleVehicleAlbumId,
      patchSaleVehiclePhotosCategoryRequestBody: {
        saleVehiclePhotoIds: Array.isArray(imageId) ? imageId : [imageId],
        category,
      },
    });
  };

  const buildImageActionsContextMenu = (image: UnifiedAlbumImage) => {
    const isProcessing =
      saleVehicleAlbum.processingImageIds.includes(image.id) ||
      image.latestFileOperation?.state === 'IN_PROGRESS';

    const isExterior = image.category === 'EXTERIOR';
    const isInterior = image.category === 'INTERIOR';

    if (image.isPromoPhoto) {
      return buildArray<DropdownItemProps>([]).when(canDeleteVehicleAlbumPhoto, {
        label: i18n.t('general.actions.delete'),
        prefix: <Icon value="action/delete" />,
        isDisabled: isProcessing || saleVehicleAlbum.areActionsInProgress,
        onClick: () => {
          saleVehicleAlbum.actions.deletePromotionalPhotoFromAlbum(image.id);
        },
      });
    }

    return buildArray<DropdownItemProps>([])
      .when(
        canEditPhotos && props.isActiveSaleVehicle && !areBulkActionsVisible && !image.isCoverPhoto,
        () => ({
          label: i18n.t('general.actions.setAsCoverPhoto'),
          prefix: <Icon value="image/photo" />,
          onClick: () => {
            saleVehicleAlbum.actions.setImageAsCoverPhoto(image.id);
          },
        })
      )
      .when(canEditPhotos && isFeatureEnabled(featureFlags.SALES_PHOTO_ROTATION), () => ({
        label: i18n.t('general.actions.rotateRight'),
        prefix: <Icon value="action/rotateRight" />,
        isDisabled: isProcessing,
        onClick: () => {
          saleVehicleAlbum.actions.rotateImage({
            uuid: areSelectedInBulk ? imageSelection.getSelectedImages() : image.id,
            angle: 90,
          });
          if (areBulkActionsVisible) {
            handleBulkActionsToggle();
          }
        },
      }))
      .when(canEditPhotos && isFeatureEnabled(featureFlags.SALES_PHOTO_ROTATION), () => ({
        label: i18n.t('general.actions.rotateLeft'),
        prefix: <Icon value="action/rotateLeft" />,
        isDisabled: isProcessing,
        onClick: () => {
          saleVehicleAlbum.actions.rotateImage({
            uuid: areSelectedInBulk ? imageSelection.getSelectedImages() : image.id,
            angle: 270,
          });
          if (areBulkActionsVisible) {
            handleBulkActionsToggle();
          }
        },
      }))
      .add({
        label: isExterior
          ? i18n.t('entity.photo.actions.unmarkExterior')
          : i18n.t('entity.photo.actions.markExterior'),
        prefix: <Icon value={isExterior ? 'action/unmark_exterior' : 'action/mark_exterior'} />,
        onClick: () => handleCategorySelect(image.id, isExterior ? null : 'EXTERIOR'),
      })
      .add({
        label: isInterior
          ? i18n.t('entity.photo.actions.unmarkInterior')
          : i18n.t('entity.photo.actions.markInterior'),
        prefix: <Icon value={isInterior ? 'action/unmark_interior' : 'action/mark_interior'} />,
        onClick: () => handleCategorySelect(image.id, isInterior ? null : 'INTERIOR'),
      })
      .when(canRemoveBackground, {
        label: i18n.t('entity.photo.actions.removeBackground'),
        prefix: <Icon value="action/magic_wand" />,
        isDisabled: isProcessing,
        onClick: () => handleBackgroundRemoval(image.id),
      })
      .when(canDeleteVehicleAlbumPhoto && !areBulkActionsVisible, () => ({
        label: i18n.t('general.actions.delete'),
        prefix: <Icon value="action/delete" />,
        isDisabled: isProcessing,
        onClick: () => {
          saleVehicleAlbum.actions.deleteImageFromAlbum(image.id);
        },
      }));
  };

  const watermarkOptions =
    watermarks?.map((watermark) => ({
      label: watermark.name,
      value: watermark.id,
    })) ?? [];

  const selectedImageDetails = imageSelection
    .getSelectedImages()
    .map((id) => albumImages?.find((image) => image.id === id))
    .filter(Boolean);

  const hasSelectedImageWithCategory = selectedImageDetails.some(
    (image) => image?.category !== null
  );

  return (
    <SortableContext
      id={props.saleVehicleAlbumId}
      items={imagesToRender.map((image, index) =>
        buildSortableItemId(image.id, props.saleVehicleAlbumId, image.url, index)
      )}
    >
      <Card
        data-testid={suffixTestId('album', props)}
        title={props.title}
        actions={buildArray<Action>()
          .when(areBulkActionsVisible, {
            'data-testid': suffixTestId('album-selectImagesInBulk', props),
            type: 'button',
            title: i18n.t('general.actions.selectAll'),
            variant: 'ghostLink',
            leftIcon: 'action/done_all',
            onClick: () =>
              imageSelection.selectImagesInBulk(albumImages?.map((image) => image.id) ?? []),
          })
          .when(
            isPositive(albumImages?.length) &&
              isFeatureEnabled(featureFlags.SALES_BACKGROUND_REMOVAL),
            {
              'data-testid': suffixTestId('album-removeBackground', props),
              type: 'buttonWithTooltip',
              title: i18n.t('entity.photo.actions.removeBackground'),
              variant: 'ghostLink',
              leftIcon: 'action/magic_wand',
              isDisabled: !canRemoveVehiclePhotoBackground,
              onClick: () => handleBackgroundRemoval(),
              tooltip: {
                placement: 'top',
                label: i18n.t('general.labels.noPermission'),
                isDisabled: canRemoveVehiclePhotoBackground,
              },
            }
          )
          .when(areBulkActionsVisible, {
            title: i18n.t('entity.photo.actions.markInterior'),
            type: 'button',
            variant: 'ghostLink',
            leftIcon: 'action/mark_interior',
            onClick: () => handleCategorySelect(imageSelection.getSelectedImages(), 'INTERIOR'),
          })
          .when(areBulkActionsVisible, {
            title: i18n.t('entity.photo.actions.markExterior'),
            type: 'button',
            variant: 'ghostLink',
            leftIcon: 'action/mark_exterior',
            onClick: () => handleCategorySelect(imageSelection.getSelectedImages(), 'EXTERIOR'),
          })
          .when(
            areBulkActionsVisible &&
              isPositive(imageSelection.getSelectedImages().length) &&
              hasSelectedImageWithCategory,
            {
              title: i18n.t('entity.photo.actions.unmark'),
              type: 'button',
              variant: 'ghostLink',
              leftIcon: 'action/unmark_exterior',
              iconColor: 'general.accent',
              onClick: () => handleCategorySelect(imageSelection.getSelectedImages(), null),
            }
          )
          .when(!areBulkActionsVisible && props.showPromotionalPhotos, {
            'data-testid': suffixTestId('album-addPromotionalPhotos', props),
            type: 'button',
            variant: 'ghostLink',
            title: i18n.t('entity.photo.actions.addPromoPhotos'),
            leftIcon: 'image/add_photo_alternate',
            onClick: saleVehicleAlbum.actions.addPromotionalPhotosToAlbum,
            isDisabled: saleVehicleAlbum.areActionsInProgress,
          })
          .when(
            canDeleteVehicleAlbumPhoto &&
              areBulkActionsVisible &&
              isPositive(imageSelection.getSelectedImages().length),
            {
              'data-testid': suffixTestId('album-handleBulkDelete', props),
              type: 'button',
              title: i18n.t('general.actions.delete'),
              variant: 'dangerGhost',
              leftIcon: 'action/delete',
              onClick: handleBulkDelete,
            }
          )
          .when(props.canDelete && !areBulkActionsVisible, {
            'data-testid': suffixTestId('album-deleteAlbum', props),
            type: 'button',
            title: i18n.t('entity.vehicle.albums.delete'),
            variant: 'dangerGhost',
            leftIcon: 'action/delete',
            onClick: () => {
              openDeleteDialog({
                text: i18n.t('entity.vehicle.albums.deleteQuestion'),
                onConfirm: () => {
                  saleVehicleAlbum.actions.deleteAlbum();
                },
              });
            },
          })
          .add({
            'data-testid': suffixTestId('album-handleBulkActionsToggle', props),
            type: 'button',
            title: areBulkActionsVisible
              ? i18n.t('general.actions.cancel')
              : i18n.t('general.actions.bulkActions'),
            variant: 'ghostLink',
            leftIcon: 'image/filter_none',
            onClick: handleBulkActionsToggle,
          })
          .add({
            'data-testid': suffixTestId('album-downloadAll', props),
            type: 'button',
            title: i18n.t('general.actions.downloadAll'),
            variant: 'ghostLink',
            leftIcon: 'file/download',
            isLoading: isBatchDownloadLoading,
            isDisabled: albumImages?.length === 0,
            onClick: () => {
              handleSaleVehicleDownload({
                saleVehicleId: props.saleVehicleId,
                albumId: props.saleVehicleAlbumId,
              }).then((event) => {
                if (isNotNil(event?.data)) {
                  openDialog(
                    <DownloadDialog
                      fileName={event?.data?.bulkName}
                      fileCount={event?.data?.objects.length}
                    />,
                    {
                      id: 'download-dialog',
                      title: i18n.t('entity.vehicle.labels.downloadPhotos'),
                      size: 'small',
                    }
                  );
                }
              });
            },
          })}
      >
        <Show
          when={promotionalPhotos?.some((image) => image.position !== image.positionInSettings)}
        >
          <Box paddingBottom={4}>
            <Alert
              type="inline"
              title={i18n.t('entity.photo.labels.promoPhotoDifferentOrder')}
              data-testid={suffixTestId('promoPhotoAlert', props)}
              hyperlinks={[
                {
                  size: 'small',
                  title: i18n.t('entity.photo.actions.setDefaultOrder'),
                  onClick: saleVehicleAlbum.actions.resetPromotionalPhotos,
                  leftIcon: 'navigation/refresh',
                },
              ]}
            />
          </Box>
        </Show>
        <Show
          whenFeatureEnabled={featureFlags.SETTINGS_WATERMARKS}
          when={saleVehicle && props.canApplyWatermark && watermarks && watermarks?.length > 0}
        >
          <Box paddingBottom={4}>
            <HStack spacing={4} align="center">
              <Switch
                label={i18n.t('entity.watermark.labels.applyWatermark')}
                value={shouldApplyWatermark}
                onChange={(value) => {
                  setShouldApplyWatermark((prevState) => !prevState);
                  patchWatermark({
                    vehicleId: saleVehicle?.vehicleId ?? '',
                    patchSaleVehicleWatermarkRequestBody: {
                      watermarkId: value ? (watermarkOption ?? watermarkOptions?.[0]?.value) : null,
                    },
                  })
                    .unwrap()
                    .then(() => {
                      if (!watermarkOption) {
                        setWatermarkOption(watermarkOptions?.[0]?.value);
                      }
                    })
                    .then(() => updateVehicleAds(vehicleId))
                    .catch(handleApiError);
                  if (!value) {
                    setShouldApplyWatermark(false);
                    setWatermarkOption(null);
                  }
                }}
                data-testid={suffixTestId(`album-images-apply-watermark-switch`, props)}
              />
              <Show when={shouldApplyWatermark}>
                <Box minWidth={64}>
                  <Choice
                    options={watermarkOptions}
                    value={watermarkOption}
                    defaultValue={saleVehicle?.watermarkId ?? watermarkOptions?.[0]?.value ?? null}
                    onChange={(value) => {
                      setWatermarkOption(value ?? null);
                      patchWatermark({
                        vehicleId: saleVehicle?.vehicleId ?? '',
                        patchSaleVehicleWatermarkRequestBody: {watermarkId: value ?? null},
                      })
                        .unwrap()
                        .then(() => updateVehicleAds(vehicleId))
                        .catch(handleApiError);
                    }}
                    isLoading={isPatchingWatermark}
                    data-testid={suffixTestId(`album-images-apply-watermark-select`, props)}
                    isDisabled={watermarks?.length === 1}
                    isNotClearable
                  />
                </Box>
              </Show>
            </HStack>
          </Box>
        </Show>
        <Hide when={imagesToRender.length || props.isActiveSaleVehicle || props.canUpload}>
          <Center width="100%" height={IMAGE_SIZE.height}>
            <VStack align="center">
              <Icon value="image/image_alt" color="text.tertiary" size={8} />
              <Space vertical={2} />
              <Heading size={5} color="tertiary">
                {i18n.t('entity.vehicle.albums.empty')}
              </Heading>
            </VStack>
          </Center>
        </Hide>
        <Hide when={imagesToRender.length === 0 && !props.isActiveSaleVehicle && !props.canUpload}>
          <Droppable
            id={buildDroppableItemId(props.saleVehicleAlbumId)}
            disabled={!props.canUpload || !canEditPhotos}
          >
            <div
              css={css`
                display: grid;
                grid-template-columns: repeat(
                  auto-fill,
                  minmax(${({theme}) => theme.getSize(IMAGE_SIZE.width)}, 1fr)
                );
                width: 100%;
                min-height: ${({theme}) => theme.getSize(IMAGE_SIZE.height)};
                grid-gap: ${({theme}) => theme.getSize(3)};
              `}
            >
              <DataStatus
                isLoading={isLoadingAlbumImages || isLoadingPromotionalPhotos}
                isError={hasAlbumImagesError || hasPromotionalPhotosError}
              >
                {imagesToRender.map((image, index) => (
                  <AlbumImage
                    key={image.isDirty ? `dirty-${image.id}` : image.id}
                    id={buildSortableItemId(image.id, props.saleVehicleAlbumId, image.url, index)}
                    src={prepareAlbumImageUrl(image.url)}
                    isProcessing={isImageProcessing(
                      saleVehicleAlbum.processingImageIds.includes(image.id),
                      image.latestFileOperation
                    )}
                    isDisabledDndDrop={!props.canUpload || !canEditPhotos}
                    isGhost={image.isGhost}
                    isCoverPhoto={image.isCoverPhoto}
                    isPromoPhoto={image.isPromoPhoto}
                    isLoading={image.isLoading}
                    category={image.category}
                    isSelected={imageSelection.isImageSelected(image.id)}
                    isSelectable={areBulkActionsVisible && !image.isPromoPhoto}
                    data-testid={suffixTestId(`album-images-[${index}]`, props)}
                    onClick={(event) => {
                      if (areBulkActionsVisible && !image.isPromoPhoto) {
                        if (event.shiftKey && lastSelectedIndex !== null) {
                          const currentIndex = index;
                          const start = Math.min(lastSelectedIndex, currentIndex);
                          const end = Math.max(lastSelectedIndex, currentIndex);

                          const rangeToSelect = imagesToRender
                            .slice(start, end + 1)
                            .filter((img) => !img.isPromoPhoto)
                            .map((img) => img.id);

                          imageSelection.selectImagesInBulk(rangeToSelect);
                        } else {
                          imageSelection.toggleImageSelection(image.id);
                          setLastSelectedIndex(index);
                        }
                      } else if (!areBulkActionsVisible) {
                        onOpen(index);
                      }
                    }}
                    onContextMenu={(event) => {
                      openDropdown(
                        event,
                        <>
                          {image.isPromoPhoto || !canEditPhotos
                            ? null
                            : props.buildAlbumActionsContextMenu?.({
                                images: areSelectedInBulk
                                  ? imageSelection.getSelectedImages()
                                  : [image.id],
                                isProcessing:
                                  saleVehicleAlbum.processingImageIds.includes(image.id) ||
                                  image.latestFileOperation?.state === 'IN_PROGRESS',
                                onEventFired: () => {
                                  closeDropdown('album-image-context-menu');
                                },
                                onSuccess: () => {
                                  if (areBulkActionsVisible) {
                                    handleBulkActionsToggle();
                                  }
                                },
                              })}
                          {buildImageActionsContextMenu(image).map((props) => (
                            <DropdownItem key={props.label as string} {...props} />
                          ))}
                        </>,
                        {
                          id: 'album-image-context-menu',
                        }
                      );
                    }}
                  />
                ))}
                {saleVehicleAlbum.filePreview.list().map((preview, index) => (
                  <AlbumImage
                    id={`preview-${index}`}
                    data-testid={suffixTestId(`album-imagePreviews-[${index}]`, props)}
                    key={preview.id}
                    src={preview.uri}
                    isLoading
                    isDisabledDndDrop
                    isDisabledDndDrag
                  />
                ))}
                <Show when={isUploadAllowed && !isDndSorting}>
                  <Box width="100%" height="100%" ratio="4 / 3">
                    <Upload
                      isDisabled={isUploadInProgress}
                      data-testid={suffixTestId('album', props)}
                      type="card"
                      size="default"
                      accept={ALLOWED_MIME_TYPES_TO_UPLOAD}
                      isMultiple
                      shouldValidateAccept
                      uploadIcon="image/photo_camera"
                      onStart={(file) => {
                        setIsUploadInProgress(true);
                        saleVehicleAlbum.filePreview.show(file);
                      }}
                      onSuccess={() => {
                        setIsUploadInProgress(false);
                      }}
                      onError={(...args) => {
                        setIsUploadInProgress(false);
                        saleVehicleAlbum.handlers.handleUploadError(...args);
                      }}
                      customRequest={({file, onSuccess, onError}) => {
                        if (file instanceof File) {
                          saleVehicleAlbum.actions.addImageToAlbum({
                            file: file as File,
                            onSuccess,
                            onError,
                          });
                        }
                        // else should be string, which should not happen
                      }}
                    />
                  </Box>
                </Show>
                <Show when={isUploadAllowed && isDndSorting}>
                  <Box width="100%" height="100%" ratio="4 / 3" />
                </Show>
              </DataStatus>
            </div>
          </Droppable>
        </Hide>
        <Lightbox
          data-testid={suffixTestId('lightbox', props)}
          controls={lightboxControls}
          images={imagesToRender.map((image) => ({
            ...image,
            url: image.url + `&height=${windowDimensions.height}`,
            resizeUrl: image.url,
          }))}
        />
      </Card>
    </SortableContext>
  );
}
