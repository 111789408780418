import {isFeatureEnabled} from 'feature-flags';
import {
  Action,
  DataStatus,
  DropdownActionMenuItem,
  FlagProps,
  openDeleteDialog,
  openDialog,
  Parameter,
  showNotification,
} from 'platform/components';

import {Helmet} from 'react-helmet-async';

import {any, head, isNil} from 'ramda';
import {isNilOrEmpty, isNotNil, isTrue} from 'ramda-adjunct';

import {
  useCreateBusinessCaseMutation,
  useGetBusinessCaseQuery,
  useGetLastActiveBusinessCaseByCustomerQuery,
} from '@dms/api/businessCase';
import {BusinessCaseState} from '@dms/api/commission';
import {useDeleteCustomerMutation, useGetCustomerV2Query} from '@dms/api/customer';
import {useGetCustomerContractsByCustomerIdQuery} from '@dms/api/metadaCustomerContract';
import {useGetCustomerLastActiveServiceCaseQuery} from '@dms/api/metadaServiceVehicle';
import {useGetTireVehicleCountQuery} from '@dms/api/metadaTires';
import {useGetDirectSaleLastActiveByCustomerIdQuery} from '@dms/api/metadaWarehouseDirectSale';
import {useGetParticipationQuery} from '@dms/api/participation';
import {EntityResourceIds} from '@dms/api/shared';
import {useGetEmailConfigQuery, useGetKonzultaConfigQuery} from '@dms/api/tenant';
import {useGetVehicleV2Query} from '@dms/api/vehicle';
import {featureFlags} from '@dms/feature-flags';
import {TaskGridPage, useTasksCount} from '@dms/features/tasks';
import i18n from '@dms/i18n';
import {
  businessCaseRoutes,
  customerRoutes,
  testIds,
  warehouseRoutes,
  workshopRoutes,
} from '@dms/routes';
import {
  ActivityLogs,
  Checklists,
  DetailTemplateHeader,
  EMPTY_PLACEHOLDER,
  EntityHeader,
  GdprConsentsList,
  getBusinessCaseStateFlag,
  getBusinessCaseTypeFlag,
  getCustomerEmail,
  getCustomerName,
  getCustomerPhoneNumber,
  handleApiError,
  is404ApiError,
  isCustomerNaturalPerson,
  NavigationItem,
  Page,
  Section,
  SendEmail,
  SendSms,
  SnippetProps,
  useChecklist,
  useCustomerUrl,
  useDocumentContextCount,
  usePermissions,
  useStateFlag,
  useRecordCount,
} from '@dms/shared';

import {buildArray, buildObject, composePath, useNavigate} from 'shared';

import {customerSearchParams} from '../../customerSearchParams';
import {BusinessCases} from './(sections)/BusinessCases/BusinessCases';
import {CustomerContracts} from './(sections)/CustomerContracts/CustomerContracts';
import {CustomerInformation} from './(sections)/CustomerInformation/CustomerInformation';
import {Documents} from './(sections)/Documents/Documents';
import {Interests} from './(sections)/Interests/Interests';
import {Invoices} from './(sections)/Invoices/Invoices';
import {ServiceCases} from './(sections)/ServiceCases/ServiceCases';
import {Tires} from './(sections)/Tires/Tires';
import {Vehicles} from './(sections)/Vehicles/Vehicles';

export function CustomerDetail() {
  const navigate = useNavigate();
  const {customerId} = useCustomerUrl();
  const documentsCountBadge = useDocumentContextCount('customer', customerId);
  const {businessCaseCount, interestCount, toMenuBadge} = useRecordCount({
    customerId,
    vehicleId: undefined,
  });

  const {
    data,
    isLoading: isCustomerLoading,
    isError: isCustomerErrored,
  } = useGetCustomerV2Query({customerId});

  const {
    data: konzultaConfig,
    isLoading: isKonzultaConfigLoading,
    isError: isKonzultaConfigErrored,
  } = useGetKonzultaConfigQuery();

  const {
    data: emailConfig,
    isLoading: isEmailConfigLoading,
    isError: isEmailConfigErrored,
  } = useGetEmailConfigQuery();

  const {
    data: activeBusinessCase,
    isLoading: isActiveBCLoading,
    isError: isActiveBCErrored,
  } = useGetLastActiveBusinessCaseByCustomerQuery(
    {customerId},
    {
      selectFromResult: (res) => ({
        ...res,
        data: isNotNil(res?.data) ? head(res.data) : null,
      }),
    }
  );
  const {data: vehicleTires} = useGetTireVehicleCountQuery(
    {customerId},
    {
      skip:
        !isFeatureEnabled(featureFlags.AFTS_TIRE_ORDERS_CUSTOMER_CARD) ||
        !isFeatureEnabled(featureFlags.AFTS_LIST_TIRES_CUSTOMER_CARD) ||
        !isFeatureEnabled(featureFlags.PNEUHOTEL_BASIC_VIEW),
    }
  );

  const {data: customerParticipation} = useGetParticipationQuery({
    resourceId: EntityResourceIds.customer,
    recordId: customerId,
  });

  const {data: businessCaseParticipation} = useGetParticipationQuery(
    {
      resourceId: EntityResourceIds.businessCase,
      recordId: activeBusinessCase?.id ?? '',
    },
    {skip: isNil(activeBusinessCase?.id)}
  );

  const {data: businessCase} = useGetBusinessCaseQuery(
    {businessCaseId: activeBusinessCase?.id ?? ''},
    {skip: isNil(activeBusinessCase?.id)}
  );

  const {count: tasksTodoCount} = useTasksCount({
    entityId: customerId,
    relation: EntityResourceIds.customer,
    state: 'TODO',
  });
  const {count: tasksInProgressCount} = useTasksCount({
    entityId: customerId,
    relation: EntityResourceIds.customer,
    state: 'IN_PROGRESS',
  });
  const tasksCountBadge = tasksTodoCount + tasksInProgressCount;

  const {
    data: lastDirectSale,
    isLoading: isLastDirectSaleLoading,
    isError: isLastDirectSaleError,
    error: lastDirectSaleError,
  } = useGetDirectSaleLastActiveByCustomerIdQuery(
    {customerId},
    {
      skip: !isFeatureEnabled(featureFlags.ACL_WAREHOUSE),
    }
  );

  const {
    data: lastDirectSaleVehicle,
    isLoading: isLastDirectSaleVehicleLoading,
    isError: isLastDirectSaleVehicleError,
  } = useGetVehicleV2Query(
    {vehicleId: lastDirectSale?.vehicle_id as string},
    {skip: isNilOrEmpty(lastDirectSale?.vehicle_id)}
  );

  const lastDirectSaleStateFlag = useStateFlag(lastDirectSale?.state);

  const [
    hasDeleteCustomerPermission,
    canPurchaseVehicle,
    canSellVehicle,
    hasCreateBusinessCasePurchasePermission,
    hasCreateBusinessCaseSellingPermission,
    hasAddVehicleToBuyPermission,
    hasAddVehicleToSellPermission,
    canViewBusinessCaseSelling,
    canViewBusinessCasePurchase,
    canReadTag,
    hasAddCustomerTagPermission,
    hasUpdateCustomerTagPermission,
    hasAddCustomerTagToRecordPermission,
    hasRemoveCustomerTagFromRecordPermission,
    hasRemoveCustomerTagPermission,
    hasClearCustomerTagExpirationPermission,
    hasSetCustomerTagExpirationPermission,
    canReadBusinessCase,
    canReadServiceCase,
    canReadDocuments,
    canReadInterest,
  ] = usePermissions({
    permissionKeys: [
      'deleteCustomer',
      'viewBusinessCasePurchase',
      'addVehicleToSell',
      'createBusinessCasePurchase',
      'createBusinessCaseSelling',
      'addVehicleToBuy',
      'addVehicleToSell',
      'viewBusinessCaseSelling',
      'viewBusinessCasePurchase',
      'customerTagRead',
      'customerTagAdd',
      'customerTagUpdate',
      'customerTagAddToRecord',
      'customerTagRemoveFromRecord',
      'customerTagRemove',
      'customerTagClearExpiration',
      'customerTagSetExpiration',
      'businessCaseRead',
      'serviceCaseRead',
      'readDocuments',
      'interestRead',
    ],
    scopes: {
      customerTagAddToRecord: {
        participation: customerParticipation,
        // Customer has no branchId
        branchId: 'SKIP',
      },
      customerTagRead: {
        participation: customerParticipation,
        // Customer has no branchId
        branchId: 'SKIP',
      },
      customerTagRemoveFromRecord: {
        participation: customerParticipation,
        // Customer has no branchId
        branchId: 'SKIP',
      },
      viewBusinessCasePurchase: {
        // BC creation/BC tab should be allowed regardless of participation scope
        participation: 'SKIP',
        // BC creation/BC tab should be allowed regardless of participation scope
        branchId: 'SKIP',
      },
      viewBusinessCaseSelling: {
        // BC creation/BC tab should be allowed regardless of participation scope
        participation: 'SKIP',
        // BC creation/BC tab should be allowed regardless of participation scope
        branchId: 'SKIP',
      },
      deleteCustomer: {
        participation: customerParticipation,
      },
      interestRead: {
        // interest creation/interest tab should be allowed regardless of participation scope
        participation: 'SKIP',
        // interest creation/interest tab should be allowed regardless of participation scope
        branchId: 'SKIP',
      },
    },
  });

  const canCreateBusinessCase =
    hasCreateBusinessCasePurchasePermission && hasAddVehicleToBuyPermission;
  const canCreateSellBusinessCase =
    hasCreateBusinessCaseSellingPermission && hasAddVehicleToSellPermission;

  const canSeePurchase = canPurchaseVehicle && canCreateBusinessCase;
  const canSeeSell = canSellVehicle && canCreateSellBusinessCase;

  const canCreateTag = hasAddCustomerTagPermission && hasUpdateCustomerTagPermission;

  const canAssignOrUnassignTag =
    hasAddCustomerTagToRecordPermission && hasRemoveCustomerTagFromRecordPermission;

  const canDeleteTag =
    hasRemoveCustomerTagPermission &&
    hasClearCustomerTagExpirationPermission &&
    hasSetCustomerTagExpirationPermission;

  const hasSalesLicense = canReadBusinessCase && isFeatureEnabled(featureFlags.ACL_SALES);

  const isWorkShopEnabled = isFeatureEnabled(featureFlags.ACL_WORKSHOP) && canReadServiceCase;
  const {data: customerContracts} = useGetCustomerContractsByCustomerIdQuery(
    {customerId},
    {skip: !isWorkShopEnabled}
  );
  const {
    data: lastServiceCase,
    isLoading: isLastServiceCaseLoading,
    isError: isLastServiceCaseError,
    error: lastServiceCaseError,
  } = useGetCustomerLastActiveServiceCaseQuery(
    {customerId},
    {skip: !isFeatureEnabled(featureFlags.ACL_WORKSHOP) || !isWorkShopEnabled}
  );
  const {
    data: serviceCaseCustomer,
    isLoading: isServiceCaseCustomerLoading,
    isError: isServiceCaseCustomerError,
  } = useGetCustomerV2Query(
    {customerId: lastServiceCase?.customerId ?? ''},
    {skip: isNil(lastServiceCase?.customerId) || !isWorkShopEnabled}
  );
  const {
    data: serviceCaseVehicle,
    isLoading: isServiceCaseVehicleLoading,
    isError: isServiceCaseVehicleError,
  } = useGetVehicleV2Query(
    {vehicleId: lastServiceCase?.vehicleId ?? ''},
    {skip: !lastServiceCase?.vehicleId || !isWorkShopEnabled}
  );
  const {
    data: directSaleCustomer,
    isLoading: isDirectSaleCustomerLoading,
    isError: isDirectSaleCustomerError,
  } = useGetCustomerV2Query(
    {customerId: lastServiceCase?.customerId as string},
    {skip: isNilOrEmpty(lastServiceCase?.customerId) || !isWorkShopEnabled}
  );
  const lastServiceCaseStateFlag = useStateFlag(lastServiceCase?.state);
  const lastServiceCaseOrdersStateFlag = useStateFlag(lastServiceCase?.ordersState);

  const [createBusinessCaseMutation, {isLoading: isCreateBusinessCaseLoading}] =
    useCreateBusinessCaseMutation();
  const [deleteCustomer, {isLoading: isDeleteCustomerLoading}] = useDeleteCustomerMutation();

  const {badgeSettings, checklistSettings} = useChecklist({
    recordId: customerId,
    resourceId: 'CUSTOMER',
  });

  const isLoading = any(isTrue, [
    isCustomerLoading,
    isActiveBCLoading,
    isKonzultaConfigLoading,
    isEmailConfigLoading,
    isLastServiceCaseLoading,
    isLastDirectSaleLoading,
    isLastDirectSaleVehicleLoading,
    isDirectSaleCustomerLoading,
    isServiceCaseCustomerLoading,
    isServiceCaseVehicleLoading,
  ]);

  const isError = any(isTrue, [
    isCustomerErrored,
    isActiveBCErrored,
    isKonzultaConfigErrored,
    isEmailConfigErrored,
    isLastServiceCaseError && !is404ApiError(lastServiceCaseError),
    isLastDirectSaleError && !is404ApiError(lastDirectSaleError),
    isLastDirectSaleVehicleError,
    isDirectSaleCustomerError,
    isServiceCaseCustomerError,
    isServiceCaseVehicleError,
  ]);

  const handleDelete = () =>
    openDeleteDialog({
      'data-testid': testIds.customer.detail('delete'),
      onConfirm: () =>
        deleteCustomer({
          customerId,
        })
          .unwrap()
          .then(() => {
            showNotification.success(i18n.t('entity.customer.notifications.deleteSuccess'));
            navigate(customerRoutes.overview);
          })
          .catch(handleApiError),
    });

  const createBusinessCase = () =>
    createBusinessCaseMutation({
      createBusinessCaseRequestBody: {
        customerId,
      },
    })
      .unwrap()
      .then((res) => navigate(composePath(businessCaseRoutes.customer, {params: {id: res.id}})))
      .catch(handleApiError);

  const navigateDirectSalesCreate = () =>
    navigate(composePath(warehouseRoutes.directSalesCreate, {queryParams: {customerId}}));

  const navigationItems = buildArray<NavigationItem>()
    .add({
      id: customerSearchParams.customerDetail.customerInformation,
      href: composePath(customerRoutes.customerInformation, {
        params: {id: customerId},
      }),
      label: i18n.t('entity.customer.labels.customerInformation'),
      content: <CustomerInformation />,
      'data-testid': testIds.customer.detail('navigation-customerInformation'),
    })
    .when(data && isCustomerNaturalPerson(data), {
      id: customerSearchParams.customerDetail.GDPRConsents,
      href: composePath(customerRoutes.GDPRConsents, {
        params: {id: customerId},
      }),
      label: i18n.t('entity.customer.labels.GDPRConsents'),
      content: (
        <Section>
          <GdprConsentsList businessCaseId={activeBusinessCase?.id} customerId={customerId} />
        </Section>
      ),
      'data-testid': testIds.customer.detail('navigation-GDPRConsents'),
    })
    .when(hasSalesLicense && (canPurchaseVehicle || canSellVehicle), {
      id: customerSearchParams.customerDetail.businessCases,
      href: composePath(customerRoutes.businessCases, {
        params: {id: customerId},
      }),
      label: i18n.t('entity.customer.labels.businessCases'),
      content: <BusinessCases />,
      'data-testid': testIds.customer.detail('navigation-businessCases'),
      badge: toMenuBadge(businessCaseCount?.count ?? 0),
    })
    .when(hasSalesLicense && canReadInterest, {
      id: customerSearchParams.customerDetail.interests,
      href: composePath(customerRoutes.interests, {
        params: {id: customerId},
      }),
      label: i18n.t('entity.customer.labels.interests'),
      content: <Interests />,
      'data-testid': testIds.customer.detail('navigation-interests'),
      badge: toMenuBadge(interestCount?.count ?? 0),
    })
    .add({
      id: customerSearchParams.customerDetail.vehicles,
      href: composePath(customerRoutes.vehicles, {
        params: {id: customerId},
      }),
      label: i18n.t('entity.customer.labels.vehicles'),
      content: <Vehicles />,
      hasSeparator: true,
      'data-testid': testIds.customer.detail('navigation-vehicles'),
    })
    .when(canReadDocuments, {
      id: customerSearchParams.customerDetail.documents,
      href: composePath(customerRoutes.documents, {
        params: {id: customerId},
      }),
      label: i18n.t('entity.customer.labels.documents'),
      content: <Documents />,
      'data-testid': testIds.customer.detail('navigation-documents'),
      badge: documentsCountBadge,
    })
    .add({
      id: customerSearchParams.customerDetail.invoices,
      href: composePath(customerRoutes.invoices, {
        params: {id: customerId},
      }),
      label: i18n.t('entity.customer.labels.invoices'),
      content: <Invoices />,
      'data-testid': testIds.customer.detail('navigation-invoices'),
    })
    .whenFeatureEnabled(featureFlags.SALE_TASKS_CHECKLIST, {
      id: customerSearchParams.customerDetail.checklists,
      label: i18n.t('entity.checklist.labels.title'),
      'data-testid': testIds.customer.detail('navigation-checklists'),
      content: (
        <Section>
          <Checklists {...checklistSettings} />
        </Section>
      ),
      badge: badgeSettings,
      hasSeparator: true,
      href: composePath(customerRoutes.checklists, {
        params: {id: customerId},
      }),
    })
    .when(isWorkShopEnabled, {
      id: customerSearchParams.customerDetail.serviceCases,
      href: composePath(customerRoutes.serviceCases, {
        params: {id: customerId},
      }),
      label: i18n.t('page.workshop.labels.serviceCases'),
      content: <ServiceCases />,
      'data-testid': testIds.customer.detail('navigation-serviceCases'),
    })
    .when(isWorkShopEnabled, {
      id: customerSearchParams.customerDetail.customerContracts,
      href: composePath(customerRoutes.customerContracts, {
        params: {id: customerId},
      }),
      label: i18n.t('entity.customerContract.labels.customerContracts'),
      content: (
        <CustomerContracts data-testid={testIds.customer.detail('navigation-customerContracts')} />
      ),
      hasSeparator: true,
      badge: toMenuBadge(customerContracts?.length ?? 0),
      'data-testid': testIds.customer.detail('navigation-customerContracts'),
    })
    .whenFeatureEnabled(featureFlags.CORE_TASK_MANAGEMENT, {
      id: 'tasks',
      label: i18n.t('entity.task.labels.tasks'),
      href: composePath(customerRoutes.tasks, {params: {id: customerId}}),
      'data-testid': testIds.customer.detail('navigation-item-tasks'),
      badge: toMenuBadge(tasksCountBadge),
      content: (
        <TaskGridPage
          gridCode="customer-task"
          entityId={customerId}
          data-testid={testIds.customer.detail('tasks')}
        />
      ),
      hasSeparator: isFeatureEnabled(featureFlags.CORE_ACTIVITY_LOGS),
    })
    .whenFeatureEnabled(featureFlags.CORE_ACTIVITY_LOGS, {
      id: 'activityLogs',
      href: composePath(customerRoutes.activityLogs, {
        params: {id: customerId},
      }),
      label: i18n.t('general.labels.activityLogs'),
      content: <ActivityLogs code="customer-action-log" />,
      'data-testid': testIds.vehicles.detail('navigation-item-activityLogs'),
    })
    .when(
      isFeatureEnabled(featureFlags.AFTS_TIRE_ORDERS_CUSTOMER_CARD) ||
        isFeatureEnabled(featureFlags.AFTS_LIST_TIRES_CUSTOMER_CARD),
      {
        id: 'tires',
        href: composePath(customerRoutes.tires, {
          params: {id: customerId},
        }),
        label: i18n.t('page.tiresInventory.labels.tires'),
        content: <Tires />,
        isDisabled: !Boolean(vehicleTires?.count),
        'data-testid': testIds.vehicles.detail('navigation-item-tires'),
      }
    );

  const entityHeaderProps = buildObject<DetailTemplateHeader>()
    .title(getCustomerName(data) ?? EMPTY_PLACEHOLDER)
    .icon('social/person')
    .height(61)
    .parameters([data?.publicId])
    .recordId(data?.id)
    .resourceId(EntityResourceIds.customer)
    .controls(['ASSIGNEE'])
    .flags(
      buildArray<FlagProps>()
        .when(data && isCustomerNaturalPerson(data), {
          colorScheme: 'teal',
          label: i18n.t('general.labels.person'),
          'data-testid': testIds.customer.detail('person'),
        })
        .when(data && !isCustomerNaturalPerson(data), {
          colorScheme: 'purple',
          label: i18n.t('general.labels.company'),
          'data-testid': testIds.customer.detail('company'),
        })
    )
    .primaryParameter(
      getCustomerPhoneNumber(data) ?? EMPTY_PLACEHOLDER,
      data && isCustomerNaturalPerson(data)
    )
    .secondaryParameter(
      getCustomerEmail(data) ?? EMPTY_PLACEHOLDER,
      data && isCustomerNaturalPerson(data)
    )
    .snippets(
      buildArray<SnippetProps>()
        .when(
          hasSalesLicense &&
            (isNotNil(activeBusinessCase?.id) &&
            (activeBusinessCase?.vehicleDataPartialInfo?.purchaseVehiclesPartialInfo?.length ||
              activeBusinessCase?.vehicleDataPartialInfo?.purchaseBrokerageVehiclesPartialInfo
                ?.length)
              ? canPurchaseVehicle
              : canSellVehicle),
          buildObject<SnippetProps>()
            .icon('places/business_center')
            .label(i18n.t('page.businessCase.labels.activeBusinessCase'))
            .title(
              head(
                activeBusinessCase?.vehicleDataPartialInfo?.purchaseBrokerageVehiclesPartialInfo ??
                  []
              )?.title ||
                head(activeBusinessCase?.vehicleDataPartialInfo?.purchaseVehiclesPartialInfo ?? [])
                  ?.title ||
                head(activeBusinessCase?.vehicleDataPartialInfo?.saleVehiclesPartialInfo ?? [])
                  ?.title ||
                i18n.t(`page.workshop.placeholders.noVehicle`)
            )
            .flags(
              buildArray<FlagProps>()
                .when(
                  activeBusinessCase?.businessCaseState,
                  getBusinessCaseStateFlag(
                    activeBusinessCase?.businessCaseState as BusinessCaseState
                  ) as FlagProps
                )
                .when(
                  activeBusinessCase?.businessCaseType,
                  getBusinessCaseTypeFlag(activeBusinessCase?.businessCaseType) as FlagProps
                )
            )
            .parameters(
              buildArray<Parameter>()
                .when(activeBusinessCase?.code, activeBusinessCase?.code)
                .when(
                  activeBusinessCase?.ownerFirstName && activeBusinessCase?.ownerLastName,
                  `${activeBusinessCase?.ownerFirstName} ${activeBusinessCase?.ownerLastName}`
                )
            )
            .href(
              activeBusinessCase?.id
                ? composePath(businessCaseRoutes.overview, {
                    params: {id: activeBusinessCase.id},
                  })
                : undefined,
              isNotNil(activeBusinessCase?.id)
            )
            .selectedContactId(
              isNotNil(activeBusinessCase?.ownerId) ? activeBusinessCase?.ownerId : undefined
            )
            .build()
        )
        .when(
          hasSalesLicense &&
            isNil(activeBusinessCase?.id) &&
            (canCreateBusinessCase || canCreateSellBusinessCase),
          {
            icon: 'places/business_center',
            label: i18n.t('page.businessCase.labels.activeBusinessCase'),
            placeholder: {
              title: i18n.t('page.businessCase.actions.create'),
              onClick: createBusinessCase,
            },
          }
        )
        .when(
          isNotNil(lastServiceCase?.id) && canReadServiceCase && isWorkShopEnabled,
          buildObject<SnippetProps>()
            .icon('action/build')
            .label(i18n.t('page.vehicle.labels.lastActiveServiceCase'))
            .title(serviceCaseVehicle?.title ?? EMPTY_PLACEHOLDER)
            .flags(
              buildArray<FlagProps>()
                .when(lastServiceCaseStateFlag, lastServiceCaseStateFlag!)
                .when(lastServiceCaseOrdersStateFlag, lastServiceCaseOrdersStateFlag!)
            )
            .parameters(
              buildArray<Parameter>()
                .when(lastServiceCase?.number, lastServiceCase?.number)
                .when(serviceCaseCustomer, getCustomerName(serviceCaseCustomer))
            )
            .href(
              lastServiceCase?.id
                ? composePath(workshopRoutes.serviceCaseDetail, {
                    params: {id: lastServiceCase.id},
                  })
                : undefined,
              isNotNil(lastServiceCase?.id)
            )
            .build()
        )
        .when(isNil(lastServiceCase?.id) && isWorkShopEnabled, {
          icon: 'places/business_center',
          label: i18n.t('page.vehicle.labels.lastActiveServiceCase'),
          placeholder: {
            title: i18n.t('page.workshop.labels.newServiceCase'),
            onClick: () =>
              navigate(composePath(workshopRoutes.createServiceCase, {queryParams: {customerId}})),
          },
        })
        .when(
          isNotNil(lastDirectSale?.id) && isFeatureEnabled(featureFlags.ACL_WAREHOUSE),
          buildObject<SnippetProps>()
            .icon('action/shopping_cart')
            .label(i18n.t('page.vehicle.labels.lastDirectSale'))
            .title(lastDirectSaleVehicle?.title ?? EMPTY_PLACEHOLDER)
            .flags(buildArray<FlagProps>().when(lastDirectSaleStateFlag, lastDirectSaleStateFlag!))
            .parameters(
              buildArray<Parameter>()
                .when(lastDirectSale?.number, lastDirectSale?.number)
                .when(directSaleCustomer, getCustomerName(directSaleCustomer))
            )
            .href(
              lastDirectSale?.id
                ? composePath(warehouseRoutes.directSalesDetailOverview, {
                    params: {id: lastDirectSale.id},
                  })
                : undefined,
              isNotNil(lastDirectSale?.id)
            )
            .build()
        )
        .when(isNil(lastDirectSale?.id) && isFeatureEnabled(featureFlags.ACL_WAREHOUSE), {
          icon: 'places/business_center',
          label: i18n.t('page.vehicle.labels.lastDirectSale'),
          placeholder: {
            title: i18n.t('page.workshop.labels.createDirectSale'),
            onClick: navigateDirectSalesCreate,
          },
        })
    )
    .actions(
      buildArray<Action>()
        .when(hasDeleteCustomerPermission || isFeatureEnabled(featureFlags.ACL_WAREHOUSE), {
          type: 'dropdown-iconButton',
          priority: 'secondary',
          menuItems: buildArray<DropdownActionMenuItem>()
            .when(isFeatureEnabled(featureFlags.ACL_WAREHOUSE), {
              label: i18n.t('page.workshop.labels.createDirectSale'),
              onClick: navigateDirectSalesCreate,
              isLoading: isDeleteCustomerLoading,
              'data-testid': testIds.customer.detail('createDirectSale'),
            })
            .when(hasDeleteCustomerPermission, {
              label: i18n.t('entity.customer.actions.delete'),
              onClick: handleDelete,
              isLoading: isDeleteCustomerLoading,
              'data-testid': testIds.customer.detail('delete'),
            }),
          'data-testid': testIds.customer.detail('vehicleHeader-more'),
        })
        .add({
          type: 'dropdown-button',
          variant: 'secondary',
          rightIcon: 'hardware/keyboard_arrow_down',
          title: i18n.t('general.actions.sendMessage'),
          menuItems: [
            {
              label: i18n.t('general.labels.email'),
              leftIcon: 'communication/email',
              isDisabled: !emailConfig?.enabled,
              tooltip: !emailConfig?.enabled
                ? {
                    label: i18n.t('general.notifications.setupEmailProvider'),
                    placement: 'top-start',
                  }
                : undefined,
              onClick: () =>
                openDialog(
                  <SendEmail
                    data-testid={testIds.customer.detail('sendEmail')}
                    customerId={customerId}
                    shouldDisplayTemplateChoice={isFeatureEnabled(featureFlags.MESSAGE_TEMPLATES)}
                  />,
                  {
                    scrollBehavior: 'outside',
                    title: i18n.t('general.labels.email'),
                  }
                ),
              'data-testid': testIds.customer.detail('sendEmail'),
            },
            {
              label: i18n.t('general.labels.sms'),
              leftIcon: 'communication/textsms',
              isDisabled: !konzultaConfig?.enabled,
              tooltip: !konzultaConfig?.enabled
                ? {
                    label: i18n.t('general.notifications.setupSmsProvider'),
                    placement: 'bottom-start',
                  }
                : undefined,
              onClick: () =>
                openDialog(
                  <SendSms
                    data-testid={testIds.customer.detail('sendSms')}
                    customerId={customerId}
                    selectedContactId={data?.id}
                    shouldDisplayTemplateChoice={isFeatureEnabled(featureFlags.MESSAGE_TEMPLATES)}
                  />,
                  {
                    scrollBehavior: 'outside',
                    title: i18n.t('general.labels.smsMessage'),
                  }
                ),
              'data-testid': testIds.customer.detail('sendSms'),
            },
          ],
          'data-testid': testIds.customer.detail('sendMessage'),
        })
        .when(isFeatureEnabled(featureFlags.ACL_WORKSHOP), {
          type: 'button',
          variant:
            isFeatureEnabled(featureFlags.ACL_SALES) &&
            canReadBusinessCase &&
            ((canSeePurchase && canViewBusinessCasePurchase) ||
              (canSeeSell && canViewBusinessCaseSelling))
              ? 'secondary'
              : 'primary',
          title: i18n.t('page.workshop.labels.newServiceCase'),
          onClick: () =>
            navigate(composePath(workshopRoutes.createServiceCase, {queryParams: {customerId}})),
          'data-testid': testIds.customer.detail('createServiceCase'),
        })
        .when(
          isFeatureEnabled(featureFlags.ACL_SALES) &&
            canReadBusinessCase &&
            ((canSeePurchase && canViewBusinessCasePurchase) ||
              (canSeeSell && canViewBusinessCaseSelling)),
          {
            type: 'button',
            variant: isFeatureEnabled(featureFlags.ACL_WORKSHOP) ? 'secondary' : 'primary',
            title: i18n.t('page.businessCase.actions.create'),
            onClick: createBusinessCase,
            isLoading: isCreateBusinessCaseLoading,
            'data-testid': testIds.customer.detail('createBusinessCase'),
          }
        )
    )
    .build();

  return (
    <>
      {data && <Helmet title={i18n.t('page.customer.title', {name: getCustomerName(data)})} />}
      <DataStatus isLoading={isLoading} isError={isError} minHeight={100}>
        <Page
          key={customerId}
          navigation={navigationItems}
          header={
            <EntityHeader
              {...entityHeaderProps}
              permissions={{
                tag: {
                  isDeletable: canDeleteTag,
                  isUpdatable: canCreateTag,
                  isReadable: canReadTag,
                  isCreatable: canCreateTag,
                  isAssignable: canAssignOrUnassignTag,
                  isUnassignable: canAssignOrUnassignTag,
                },
              }}
              data-testid={testIds.customer.detail('customer-detail')}
            />
          }
          data-testid={testIds.customer.detail('customer-page')}
        />
      </DataStatus>
    </>
  );
}
