import {
  Alert,
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Grid, Show, Space, VStack} from 'platform/foundation';

import {useNavigate, useParams} from 'react-router-dom';

import {isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {useGetOperationItemsQuery} from '@dms/api/metadaAccounting';
import {
  useGetWarehouseAccountQuery,
  usePatchWarehouseAccountMutation,
  usePostWarehouseAccountMutation,
} from '@dms/api/metadaWarehouseAccount';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError, useDuplicateErrorHandler} from '@dms/shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';
import {getOptionsFromOperationalItems} from './utils/getOptionsFromOperationalItems';
import {WarehouseAccountFormSchema, WarehouseAccountFormType} from './WarehouseAccountFormSchema';

export function WarehouseAccountDetail() {
  const {id} = useParams();
  const navigate = useNavigate();
  const {duplicateError, duplicateErrorHandler} = useDuplicateErrorHandler();

  /**
   * https://carvago.atlassian.net/jira/software/c/projects/T20/boards/195?modal=detail&selectedIssue=T20-32370&assignee=712020%3Acb425570-e1e2-4ff2-ad26-935d89adf9f7
   * Not checking if the operationItems fails to load because warehouse should be independent on accounting/pre-accounting API
   */
  const {data: operationItems, isLoading: isOperationItemsLoading} = useGetOperationItemsQuery();
  const {
    data: warehouseAccount,
    isLoading: isWarehouseAccountLoading,
    isError: isWarehouseAccountError,
  } = useGetWarehouseAccountQuery({warehouseAccountId: id ?? ''}, {skip: isNil(id)});
  const [createWarehouseAccount] = usePostWarehouseAccountMutation();
  const [updateWarehouseAccount] = usePatchWarehouseAccountMutation();

  const isLoading = isNotNil(id)
    ? isOperationItemsLoading || isWarehouseAccountLoading
    : isOperationItemsLoading;
  const isError = isWarehouseAccountError;

  const handleSubmit: FormSubmitHandler<WarehouseAccountFormType> = async (data) => {
    if (isNotNil(id)) {
      return await updateWarehouseAccount({body: data, warehouseAccountId: id})
        .unwrap()
        .then(() => {
          showNotification.success(i18n.t('entity.warehouse.labels.discountGroupUpdated'));
          handleNavigateBack();
        })
        .catch(handleApiError);
    }
    await createWarehouseAccount(data)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('entity.warehouse.labels.discountGroupCreated'));
        handleNavigateBack();
      })
      .catch(duplicateErrorHandler);
  };

  const handleNavigateBack = () => {
    navigate(settingsRoutes.warehousesAccount);
  };

  const header: SettingsTemplateHeader = {
    title: isNil(id)
      ? i18n.t('entity.warehouse.labels.newWarehouseAccount')
      : warehouseAccount?.name,
  };

  return (
    <SettingsTemplate
      data-testid={testIds.settings.workshopPresetsCategory('template')}
      header={header}
    >
      <DataStatus isLoading={isLoading} isError={isError}>
        <Form<WarehouseAccountFormType>
          experimentalZodSchema={WarehouseAccountFormSchema}
          defaultValues={warehouseAccount}
          onSubmit={handleSubmit}
        >
          {(control) => (
            <VStack spacing={4} width="50%">
              <Show when={duplicateError}>
                <Alert
                  variant="error"
                  title={i18n.t('entity.warehouse.labels.warehouseAccountAlreadyExists')}
                  data-testid={testIds.settings.warehousesAccountDetail(
                    'warehouseAccountAlreadyExists'
                  )}
                />
              </Show>
              <Separator />
              <Grid columns={2}>
                <FormField
                  isRequired
                  control={control}
                  type="text"
                  name="name"
                  label={i18n.t('general.labels.name')}
                  data-testid={testIds.settings.warehousesAccountDetail('name')}
                />
                <FormField
                  control={control}
                  type="choice"
                  options={getOptionsFromOperationalItems(operationItems)}
                  name="operationId"
                  label={i18n.t('entity.warehouse.labels.operationItem')}
                  data-testid={testIds.settings.warehousesAccountDetail('operationItem')}
                />
              </Grid>
              <Grid columns={2}>
                <FormField
                  isRequired
                  control={control}
                  type="number"
                  name="syntheticNumber"
                  label={i18n.t('entity.warehouse.labels.syntheticNumber')}
                  data-testid={testIds.settings.warehousesAccountDetail('syntheticNumber')}
                />
                <FormField
                  isRequired
                  control={control}
                  type="number"
                  name="analyticalNumber"
                  label={i18n.t('entity.warehouse.labels.analyticalNumber')}
                  data-testid={testIds.settings.warehousesAccountDetail('analyticalNumber')}
                />
              </Grid>
              <Grid columns={2}>
                <FormField
                  isRequired
                  control={control}
                  type="number"
                  isStepperVisible
                  name="salesBasePriceMarkup"
                  label={`${i18n.t('entity.warehouse.labels.salesBasePriceMarkup')} (%)`}
                  data-testid={testIds.settings.warehousesAccountDetail('salesBasePriceMarkup')}
                />
                <FormField
                  isRequired
                  control={control}
                  type="number"
                  isStepperVisible
                  name="warrantyPriceMarkup"
                  label={`${i18n.t('entity.warehouse.labels.warrantyPriceMarkup')} (%)`}
                  data-testid={testIds.settings.warehousesAccountDetail('warrantyPriceMarkup')}
                />
              </Grid>
              <Space fillAvailable />
              <SettingsFooter
                actions={[
                  {
                    type: 'button',
                    variant: 'secondary',
                    title: isNil(id)
                      ? i18n.t('general.actions.discard')
                      : i18n.t('general.actions.discardChanges'),
                    onClick: handleNavigateBack,
                    'data-testid': testIds.settings.workshopPresetsCategoryDetail('discard'),
                  },
                  {
                    type: 'form-button',
                    control,
                    buttonType: 'submit',
                    variant: 'primary',
                    title: isNil(id)
                      ? i18n.t('general.actions.save')
                      : i18n.t('general.actions.saveChanges'),
                    'data-testid': testIds.settings.workshopPresetsCategoryDetail('save'),
                  },
                ]}
              />
            </VStack>
          )}
        </Form>
      </DataStatus>
    </SettingsTemplate>
  );
}
