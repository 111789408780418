import {Checkbox} from 'platform/components';

import {isTrue} from 'ramda-adjunct';

import {CustomFieldValue} from '@dms/api/shared';
import {useGetTenantQuery} from '@dms/api/tenant';
import i18n from '@dms/i18n';
import {getFormAdditionalField} from '@dms/shared';

import {Nullish} from 'shared';

interface AffiliatedEntityCheckboxProps {
  customerId: string | undefined;
  additionalFields: CustomFieldValue[] | Nullish;
}

export function AffiliatedEntityCheckboxDetail(props: AffiliatedEntityCheckboxProps) {
  const {data: tenant} = useGetTenantQuery();

  const isPolandTenant = tenant?.country === 'POL';
  const formAffiliatedEntity = getFormAdditionalField(props.additionalFields)?.affiliatedEntity;
  const value = isTrue(formAffiliatedEntity);

  if (!isPolandTenant) {
    return null;
  }

  return <Checkbox value={value} isDisabled label={i18n.t('general.labels.affiliatedEntity')} />;
}
