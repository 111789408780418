import {useState, useTransition} from 'react';
import {useSearchParams} from 'react-router-dom';

import {RentalVehicleFormTabs} from '../types/RentalVehicleFormTabs';

const QUERY_TAB_KEY = 'tab';

export function useTabs() {
  const [queryParams, setQueryParams] = useSearchParams();
  const query = new URLSearchParams(queryParams);
  const [isPending, startTransition] = useTransition();

  const [selectedInnerTab, setSelectedInnerTab] = useState<RentalVehicleFormTabs>(
    (query.get(QUERY_TAB_KEY) || RentalVehicleFormTabs.BasicInformation) as RentalVehicleFormTabs
  );

  const setSelectedTab = (newTab: RentalVehicleFormTabs) => {
    setSelectedInnerTab(newTab);
    startTransition(() => {
      setQueryParams({[QUERY_TAB_KEY]: newTab}, {replace: true});
    });
  };

  return {selectedTab: selectedInnerTab, isPending, setSelectedTab};
}
