import {Card, DataStatus} from 'platform/components';
import {Box} from 'platform/foundation';

import {useCallback} from 'react';

import {mergeAll} from 'ramda';

import i18n from '@dms/i18n';
import {Section} from '@dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {DataGrid, QueryFilterObject} from 'features/datagrid';

import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';

interface IssueNoteCorrectionsProps extends TestIdProps {
  isIssueNoteLoading: boolean;
}

export function IssueNoteCorrections(props: IssueNoteCorrectionsProps) {
  const {issueNoteId} = useWarehouseParams();

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {issueNoteId}]),
    [issueNoteId]
  );

  return (
    <Section isFullHeight data-testid={suffixTestId('wrapper', props)}>
      <DataStatus isLoading={props.isIssueNoteLoading}>
        <Card isFullHeight title={i18n.t('general.labels.issueNoteCorrections')}>
          <Box height="100%">
            <DataGrid
              gridCode="warehouse-issue-note-corrections-list"
              queryModifier={queryModifier}
              data-testid="issueNoteCorrectionsDatagrid"
            />
          </Box>
        </Card>
      </DataStatus>
    </Section>
  );
}
