import {
  usePostTireSetsCancelRequestMutation,
  usePostTireSetsRequestMutation,
} from '@dms/api/metadaTires';

import {TestIdProps} from 'shared';

import {handleApiError} from '../../utils/handleApiError';
import {RequestTireSetData, RequestTireSetForm} from '../RequestTireSetForm/RequestTireSetForm';

interface BulkRequestTireSetProps extends TestIdProps {
  tireSets: {
    setId: string;
    orderId: string;
  }[];
  onSave?: VoidFunction;
  onClose: VoidFunction;
}

export function BulkRequestTireSet(props: BulkRequestTireSetProps) {
  const [postTireSetRequest] = usePostTireSetsRequestMutation();
  const [postTireSetCancelRequest] = usePostTireSetsCancelRequestMutation();

  const handleSubmit = async (formData: RequestTireSetData) => {
    await postTireSetRequest({body: {tireSets: props.tireSets, ...formData}})
      .unwrap()
      .then(() => props.onClose())
      .then(() => props.onSave?.())
      .catch(handleApiError);
  };

  const handleCancelRequest = () =>
    postTireSetCancelRequest({body: {tireSets: props.tireSets}})
      .unwrap()
      .then(() => props.onClose())
      .then(() => props.onSave?.())
      .catch(handleApiError);

  return (
    <RequestTireSetForm
      onSubmit={handleSubmit}
      onClose={props.onClose}
      onCancelRequest={handleCancelRequest}
    />
  );
}
