import {DataStatus, getTimeFromDate} from 'platform/components';

import {
  useGetTireSetRequestQuery,
  usePatchTireSetRequestMutation,
  usePostTireSetCancelRequestMutation,
  usePostTireSetRequestMutation,
} from '@dms/api/metadaTires';

import {parseDate, TestIdProps} from 'shared';

import {handleApiError} from '../../utils/handleApiError';
import {RequestTireSetData, RequestTireSetForm} from '../RequestTireSetForm/RequestTireSetForm';

interface RequestTireSetProps extends TestIdProps {
  setId: string;
  orderId: string;
  onSave?: VoidFunction;
  onClose: VoidFunction;
}

export function RequestTireSet(props: RequestTireSetProps) {
  const {data, isLoading, isError} = useGetTireSetRequestQuery({
    orderId: props.orderId,
    setId: props.setId,
  });

  const [postTireSetRequest] = usePostTireSetRequestMutation();
  const [patchTireSetRequest] = usePatchTireSetRequestMutation();
  const [postTireSetCancelRequest] = usePostTireSetCancelRequestMutation();

  const handleSubmit = async (formData: RequestTireSetData) => {
    const requestData = {
      orderId: props.orderId,
      setId: props.setId,
      body: formData,
    };

    await (data ? patchTireSetRequest(requestData) : postTireSetRequest(requestData))
      .unwrap()
      .then(() => props.onClose())
      .then(() => props.onSave?.())
      .catch(handleApiError);
  };

  const handleCancelRequest = () =>
    postTireSetCancelRequest({orderId: props.orderId, setId: props.setId})
      .unwrap()
      .then(() => props.onClose())
      .then(() => props.onSave?.())
      .catch(handleApiError);

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <RequestTireSetForm
        defaultValues={{
          requestedBranchId: data?.requestBranchId,
          note: data?.note,
          neededAtTime: data?.neededAt && getTimeFromDate(parseDate(data.neededAt)),
          neededAtDate: parseDate(data?.neededAt) ?? undefined,
        }}
        onSubmit={handleSubmit}
        onClose={props.onClose}
        onCancelRequest={data ? handleCancelRequest : undefined}
      />
    </DataStatus>
  );
}
