import {DataStatus} from 'platform/components';

import {
  useCreateVehicleCostPresetMutation,
  useListVehicleCostsQuery,
} from '@dms/api/vehicleFinance';
import i18n from '@dms/i18n';

import {RequiredTestIdProps} from 'shared';

import {handleApiError} from '../../utils/handleApiError';
import {CreateTransactionPresetForm} from '../CreateTransactionPresetForm';

interface CreateVehicleCostTransactionPresetProps extends RequiredTestIdProps {
  vehicleId: string;
  onDiscard?: VoidFunction;
  onSuccess?: VoidFunction;
}

type SubmitHandler = Parameters<Parameters<typeof CreateTransactionPresetForm>[0]['onSubmit']>[0];

export function CreateVehicleCostTransactionPreset(props: CreateVehicleCostTransactionPresetProps) {
  const {
    data: costs,
    isLoading: isCostsLoading,
    isError: hasCostsError,
  } = useListVehicleCostsQuery({vehicleId: props.vehicleId, type: 'manual'});

  const [createVehicleCostPreset] = useCreateVehicleCostPresetMutation();

  const onSubmit = (data: SubmitHandler) =>
    createVehicleCostPreset({
      createVehicleCostPresetRequestBody: {
        vehicleId: props.vehicleId,
        costIds: data.transactionsIds,
        name: data.name,
      },
    })
      .unwrap()
      .then(() => {
        props.onSuccess?.();
      })
      .catch(handleApiError);

  return (
    <DataStatus isLoading={isCostsLoading} isError={hasCostsError}>
      <CreateTransactionPresetForm
        vehicleId={props.vehicleId}
        data-testid={props['data-testid']}
        onSubmit={onSubmit}
        onDiscard={props.onDiscard}
        label={i18n.t('entity.earningsCosts.labels.preset')}
        list={
          costs?.map((cost) => ({
            label: cost.name,
            value: cost.id,
          })) ?? []
        }
        helperText={i18n.t('entity.costs.labels.createPresetHelper')}
      />
    </DataStatus>
  );
}
