import {VerificationRegisterType} from '@dms/api/commission';
import {
  useGetDistraintRegisterQuery,
  useGetEUViesRegisterQuery,
  useGetInsolvencyRegisterQuery,
  useGetInvalidDocumentRegisterQuery,
  useGetUnreliableVatPayerRegisterQuery,
} from '@dms/api/tenant';

import {buildArray} from 'shared';

export const useAvailableVerificationRegisters = () => {
  const {
    data: distraintRegister,
    isLoading: isDistraintRegisterLoading,
    isFetching: isDistraintRegisterFetching,
    isError: isDistraintRegisterError,
  } = useGetDistraintRegisterQuery();
  const {
    data: unreliableVatPayerRegister,
    isLoading: isUnreliableVatPayerRegisterLoading,
    isFetching: isUnreliableVatPayerRegisterFetching,
    isError: isUnreliableVatPayerRegisterError,
  } = useGetUnreliableVatPayerRegisterQuery();
  const {
    data: insolvencyRegister,
    isLoading: isInsolvencyRegisterLoading,
    isFetching: isInsolvencyRegisterFetching,
    isError: isInsolvencyRegisterError,
  } = useGetInsolvencyRegisterQuery();
  const {
    data: invalidDocumentRegister,
    isLoading: isInvalidDocumentRegisterLoading,
    isFetching: isInvalidDocumentRegisterFetching,
    isError: isInvalidDocumentRegisterError,
  } = useGetInvalidDocumentRegisterQuery();
  const {
    data: euViesRegister,
    isLoading: isEuViesRegisterLoading,
    isFetching: isEuViesRegisterFetching,
    isError: isEuViesRegisterError,
  } = useGetEUViesRegisterQuery();

  const registers = buildArray<VerificationRegisterType>()
    .when(distraintRegister?.enabled, 'distraint')
    .when(unreliableVatPayerRegister?.enabled, 'unreliable_vat_payer')
    .when(insolvencyRegister?.enabled, 'insolvency')
    .when(invalidDocumentRegister?.enabled, 'invalid_document')
    .when(euViesRegister?.enabled, 'eu_vies');

  const isLoading =
    isDistraintRegisterLoading ||
    isUnreliableVatPayerRegisterLoading ||
    isInsolvencyRegisterLoading ||
    isInvalidDocumentRegisterLoading ||
    isEuViesRegisterLoading;

  const isFetching =
    isDistraintRegisterFetching ||
    isUnreliableVatPayerRegisterFetching ||
    isInsolvencyRegisterFetching ||
    isInvalidDocumentRegisterFetching ||
    isEuViesRegisterFetching;

  const isError =
    isDistraintRegisterError ||
    isUnreliableVatPayerRegisterError ||
    isInsolvencyRegisterError ||
    isInvalidDocumentRegisterError ||
    isEuViesRegisterError;

  return {registers, isLoading, isFetching, isError};
};
