import {ThunkDispatch, UnknownAction} from '@reduxjs/toolkit';
import {captureException} from '@sentry/browser';

import {ReactNode, useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';

import {isEnoughStorageAvailable} from 'shared';

import {createRehydrateThunk} from '../thunks/createRehydrateThunk';
import {GenericRTKQState} from '../types/GenericRTKQState';

interface PersistGateProps {
  children: ReactNode;
  reducers: Record<string, unknown>;
  loadingComponent?: ReactNode;
}

function initializeCache(
  dispatch: ThunkDispatch<GenericRTKQState, unknown, UnknownAction>,
  reducers: Record<string, unknown>
) {
  try {
    // Find reducers that have a '___persistConfig' property, which indicates they're persistable
    const persistableSlices = Object.entries(reducers).filter(([_, reducer]) =>
      // @ts-expect-error - filter ensures this property exists
      Object.hasOwn(reducer, '___persistConfig')
    );
    if (persistableSlices.length === 0) {
      return Promise.resolve();
    }

    return Promise.all(
      persistableSlices.map(([path, reducer]) => {
        // @ts-expect-error - filter ensures this property exists
        const persistConfig = reducer.___persistConfig;
        // Dispatch separate rehydration action for each reducer
        const thunk = createRehydrateThunk({
          reducerPaths: [path],
          transforms: persistConfig.transforms || [],
          onError: (error: unknown) => {
            console.error('PersistGate error:', error);
            captureException(error);
          },
        })();

        return dispatch(thunk);
      })
    );
  } catch (error) {
    console.error('PersistGate error:', error);
    captureException(error);
    return Promise.reject(error);
  }
}

const MIN_REQUIRED_BYTES = 15 * 1024 * 1024; // require at least 15MB free
const TIMEOUT_MS = 2500;

export function PersistGate(props: PersistGateProps) {
  const [isLoaded, setIsLoaded] = useState(false);
  const dispatch = useDispatch();
  const hasInitRun = useRef(false);

  const {reducers} = props;

  useEffect(() => {
    if (hasInitRun.current) {
      return;
    }
    const initCache = async () => {
      try {
        const isEnoughSpace = await isEnoughStorageAvailable(MIN_REQUIRED_BYTES);
        if (!isEnoughSpace) {
          console.warn('[PersistGate] Not enough storage available, skipping cache initialization');
          setIsLoaded(true);
          return;
        }
        hasInitRun.current = true;
        await initializeCache(dispatch, reducers);
        setIsLoaded(true);
      } catch (error) {
        console.error('PersistGate error:', error);
        captureException(error);
        setIsLoaded(true);
      }
    };

    initCache();
  }, [dispatch, reducers]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      console.warn('[PersistGate] Rehydration timed out after 2500ms, rendering app anyway');
      setIsLoaded(true);
    }, TIMEOUT_MS);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return isLoaded ? <>{props.children}</> : props.loadingComponent || null;
}
