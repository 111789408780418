import {Status} from 'platform/components';
import {VStack, Text, Show, Scroll} from 'platform/foundation';

import {isNilOrEmpty} from 'ramda-adjunct';

import {BusinessCaseActivityResponseBody} from '@dms/api/sales';
import i18n from '@dms/i18n';

import {ActivityItem} from './ActivityItem';

interface ActivityListProps {
  label: string;
  items: BusinessCaseActivityResponseBody[];
  showEmpty?: boolean;
  isFullHeight?: boolean;
}

export function ActivityList(props: ActivityListProps) {
  return (
    <VStack spacing={4} height="auto" maxHeight={props.isFullHeight ? '80%' : '50%'}>
      <Text size="xSmall" color="secondary">
        {props.label}
      </Text>

      <Show when={props.showEmpty && isNilOrEmpty(props.items)}>
        <Status headline={i18n.t('entity.activityPanel.activity.noNextSteps')} />
      </Show>

      <Scroll auto>
        <VStack spacing={0}>
          <Show when={props.items.length}>
            {props.items.map((item, index) => (
              <ActivityItem
                key={item.id}
                item={item}
                showLineToNextItem={index === props.items.length - 1}
              />
            ))}
          </Show>
        </VStack>
      </Scroll>
    </VStack>
  );
}
