import {Button, FormControl, FormField, UnsafeFieldAssertionPath} from 'platform/components';
import {Align, Box, HStack, VStack} from 'platform/foundation';

import {ArrayPath, FieldValues, Path, useFieldArray, UseFormReturn} from 'react-hook-form';

import {any} from 'ramda';
import {isNilOrEmpty, lengthLt} from 'ramda-adjunct';

import {IdentityCardRequestBody} from '@dms/api/customer';
import i18n from '@dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {useIdentityCard} from '../../hooks/useIdentityCard';
import {CountrySelect} from '../CountrySelect/CountrySelect';

type IdentityCardListProps<TFieldValues extends FieldValues = FieldValues> = {
  control: FormControl<TFieldValues>;
  formApi: UseFormReturn<TFieldValues>;
  name: ArrayPath<TFieldValues>;
  isDisabled?: boolean;
} & TestIdProps;

export function IdentityCardList<TFieldValues extends FieldValues = FieldValues>(
  props: IdentityCardListProps<TFieldValues>
) {
  const {fields, append, remove} = useFieldArray({
    control: props.control,
    name: props.name,
  });

  const {identityCardOptions} = useIdentityCard();

  const emptyIdentityCard: IdentityCardRequestBody = {
    id: null,
    cardData: {
      type: null,
      cardNumber: '',
      issuedOn: null,
      validUntil: null,
      issuer: null,
      issuedInCountryCode: null,
      note: null,
    },
  };

  const isAddAnotherDisabled = any((item: IdentityCardRequestBody) =>
    isNilOrEmpty(item.cardData.cardNumber)
  )(props.formApi.watch(props.name as Path<TFieldValues>));

  return (
    <>
      {fields.map((field, index) => (
        <VStack key={field.id} spacing={4}>
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={props.control}
                type="choice"
                options={identityCardOptions}
                name={`${props.name}.${index}.cardData.type` as Path<TFieldValues>}
                label={i18n.t('entity.identityCard.labels.type')}
                menuInPortal
                data-testid={suffixTestId(`identityCardType-[${index}]`, props)}
              />
            </Box>
            <Box flex={1}>
              <FormField
                control={props.control}
                type="text"
                name={`${props.name}.${index}.cardData.cardNumber` as Path<TFieldValues>}
                label={i18n.t('entity.identityCard.labels.cardNumber')}
                data-testid={suffixTestId(`identityCardNumber-[${index}]`, props)}
              />
            </Box>
          </HStack>
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={props.control}
                type="apiDate"
                isRelativeDatesHidden
                name={
                  `${props.name}.${index}.cardData.issuedOn` as UnsafeFieldAssertionPath<
                    TFieldValues,
                    string
                  >
                }
                label={i18n.t('general.labels.issuedOn')}
                data-testid={suffixTestId(`identityCardIssuedOn-[${index}]`, props)}
              />
            </Box>
            <Box flex={1}>
              <FormField
                control={props.control}
                type="apiDate"
                isRelativeDatesHidden
                name={
                  `${props.name}.${index}.cardData.validUntil` as UnsafeFieldAssertionPath<
                    TFieldValues,
                    string
                  >
                }
                label={i18n.t('entity.identityCard.labels.validUntil')}
                data-testid={suffixTestId(`identityCardValidUntil-[${index}]`, props)}
              />
            </Box>
          </HStack>
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={props.control}
                type="text"
                name={`${props.name}.${index}.cardData.issuer` as Path<TFieldValues>}
                label={i18n.t('entity.identityCard.labels.issuer')}
                data-testid={suffixTestId(`identityCardIssuedOn-[${index}]`, props)}
              />
            </Box>
            <Box flex={1}>
              <CountrySelect
                control={props.control}
                menuPlacement="top"
                name={`${props.name}.${index}.cardData.issuedInCountryCode` as Path<TFieldValues>}
                label={i18n.t('entity.identityCard.labels.issuedInCountryCode')}
                data-testid={suffixTestId(`identityCardIssuedInCountryCode-[${index}]`, props)}
              />
            </Box>
          </HStack>
          <FormField
            control={props.control}
            name={`${props.name}.${index}.cardData.note` as Path<TFieldValues>}
            type="textarea"
            label={i18n.t('entity.identityCard.labels.note')}
            minRows={3}
            data-testid={suffixTestId(`identityCardNote-[${index}]`, props)}
          />
          <Align left>
            <Button
              title={i18n.t('general.actions.delete')}
              size="small"
              leftIcon="action/delete"
              variant="dangerLink"
              isDisabled={lengthLt(2, fields)}
              onClick={() => remove(index)}
              data-testid={suffixTestId(`identityCardRemove-[${index}]`, props)}
            />
          </Align>
        </VStack>
      ))}
      <Align left>
        <Button
          leftIcon="content/add_circle"
          title={i18n.t('general.actions.addAnother')}
          size="small"
          variant="link"
          isDisabled={isAddAnotherDisabled}
          onClick={() => append(emptyIdentityCard as any)}
          data-testid={suffixTestId('identityCardAddAnother', props)}
        />
      </Align>
    </>
  );
}
