export function ClockImage() {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5434 7C19.0443 7.1865 18.5534 7.38995 18.0715 7.60961M48.3413 38.0359C48.5798 37.5198 48.7996 36.9931 49 36.4572M43.1639 45.1843C43.5645 44.8103 43.9518 44.4222 44.3247 44.0207M35.6274 49.8687C36.0803 49.6979 36.5262 49.5133 36.9649 49.3152M28.364 51.3191C27.8252 51.3378 27.2827 51.3378 26.7437 51.3191M18.1704 49.3276C18.5923 49.5173 19.0209 49.6949 19.4559 49.8594M10.9026 44.1485C11.2216 44.4866 11.5507 44.8149 11.8895 45.1332M6.14297 36.5505C6.31769 37.0118 6.5069 37.4661 6.71002 37.9127M4.67827 29.179C4.66313 28.6935 4.66317 28.2049 4.67827 27.7186M6.12606 20.3867C6.2977 19.9305 6.4835 19.4813 6.68291 19.0395M10.8641 12.7849C11.2016 12.4253 11.5506 12.0767 11.9105 11.7396"
        stroke="#BFE0FF"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.5066 28.001L37.3399 27.9955"
        stroke="#0065FF"
        strokeWidth="3"
        strokeLinecap="round"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="0 28 28; 360 28 28; 360 28 28"
          keyTimes="0; 0.25; 1"
          dur="8s"
          begin="2s"
          repeatCount="indefinite"
        />
      </path>
      <path
        d="M28.0033 24.5044L27.9934 14.0044"
        stroke="#0065FF"
        strokeWidth="3"
        strokeLinecap="round"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="0 28 28; 360 28 28; 720 28 28; 720 28 28"
          keyTimes="0; 0.125; 0.25; 1"
          dur="8s"
          begin="2s"
          repeatCount="indefinite"
        />
      </path>
      <path
        d="M51.3333 28.0001C51.3333 15.1134 40.8865 4.66675 28 4.66675"
        stroke="#0065FF"
        stroke-width="3"
        stroke-linecap="round"
      />
      <circle cx="28" cy="28" r="3" stroke="#0065FF" stroke-width="3" />
    </svg>
  );
}
