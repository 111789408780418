import {ThemeIconKey} from 'platform/foundation';
import {match} from 'ts-pattern';

import {TaskTypeEnumSchema} from '@dms/api/shared';
import {TaskTypeEnum} from '@dms/api/taskManagement';

export function getIconKeyByType(type: TaskTypeEnum) {
  return match(type)
    .returnType<ThemeIconKey>()
    .with(TaskTypeEnumSchema.enum.TASK, () => 'content/paste')
    .with(TaskTypeEnumSchema.enum.MEETING, () => 'action/calendar_today')
    .with(TaskTypeEnumSchema.enum.CALL, () => 'action/settings_phone')
    .exhaustive();
}
