import {metadaApi} from '@dms/api/core';

import {
  BaseServiceOrderVariant,
  GetServiceOrderVariantsRequest,
  GetServiceOrderVariantsResponse,
} from './types';

export const metadaWarehouseServiceOrderVariantsApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getServiceOrderVariants: build.query<BaseServiceOrderVariant[], GetServiceOrderVariantsRequest>(
      {
        query: () => ({
          url: `service/v1/settings/service-order-variants`,
          method: 'GET',
        }),
        providesTags: ['serviceOrderVariants'],
        transformResponse: (response: GetServiceOrderVariantsResponse) =>
          response.serviceOrderVariants.serviceOrderVariant,
      }
    ),
  }),
});

export const {useGetServiceOrderVariantsQuery} = metadaWarehouseServiceOrderVariantsApi;
