import {uniq, isNotNil} from 'ramda';

import {RTKQStateTransformerFactory} from '../types/RTKQStateTransformerFactory';

/**
 * Duplicate keys check transform
 *
 * Prevents errors from large objects with duplicate keys
 */
export const createDuplicateKeysCheckTransform: RTKQStateTransformerFactory<[() => string]> = (
  getCurrentLanguage
) => ({
  serialize: (state) =>
    // No transformation during serialization
    state,
  deserialize: (state) => {
    if (!isNotNil(state) || typeof state !== 'object') {
      return state;
    }

    const queries = state.queries;
    if (!queries) {
      return state;
    }

    // Check for duplicate keys in alpha catalogue
    const alphaCatalogueKey = `getAlphaCatalogue({"lang":"${getCurrentLanguage()}"})`;
    if (alphaCatalogueKey in queries) {
      const alphaCatalogueObject = queries[alphaCatalogueKey];
      if (alphaCatalogueObject?.data) {
        const alphaCatalogue = alphaCatalogueObject.data as {
          vehicle_type: string;
          keys: {const_key: string}[];
        }[];
        const semiTrailerFeatures =
          alphaCatalogue
            ?.find((item) => item.vehicle_type === 'VEHICLETYPE_SEMI_TRAILER')
            ?.keys.map((item) => item.const_key) ?? [];

        // Check for duplicates
        if (semiTrailerFeatures?.length - uniq(semiTrailerFeatures).length !== 0) {
          return undefined;
        }
      }
    }

    return state;
  },
});
