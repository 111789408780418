import {FetchBaseQueryError} from '@reduxjs/toolkit/query';
import {FormSubmitHandler} from 'platform/components';

import {omit} from 'ramda';

import {useCreateConsentTypeMutation} from '@dms/api/gdpr';
import {
  ConsentSubject,
  CreateTemplateRequestBody,
  CreateConsentTypeRequestBody,
} from '@dms/api/shared';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {ConsentTypeFormType} from '../../../components/GdprConsentTypeForm/components/ConsentTypeFormTemplate';
import {GdprConsentTypeForm} from '../../../components/GdprConsentTypeForm/GdprConsentTypeForm';

interface AddGdprConsentTypeFormProps {
  onSuccess: () => void;
  onCancel: () => void;
}

export function AddGdprConsentTypeForm(props: AddGdprConsentTypeFormProps) {
  const [create] = useCreateConsentTypeMutation();

  const onSubmit: FormSubmitHandler<ConsentTypeFormType> = async (values, setErrors) => {
    const branchId = ConsentSubject.BRANCH === values.subject ? values.branchId : null;

    const templates = values.templates.map(
      omit(['fileUri', 'fileName'])
    ) as CreateTemplateRequestBody[];

    const body: CreateConsentTypeRequestBody = {
      ...values,
      name: values.name.trim(),
      templates,
      branchId,
    };

    await create({createConsentTypeRequestBody: body})
      .unwrap()
      .then(props.onSuccess)
      .catch((error: FetchBaseQueryError) =>
        handleApiError(error, {
          silentOnValidationError: true,
          setErrors,
        })
      );
  };

  return (
    <GdprConsentTypeForm
      onSubmit={onSubmit}
      onCancel={props.onCancel}
      onSuccess={props.onSuccess}
      defaultValues={{branchId: null, templates: []}}
      submitTitle={i18n.t('general.actions.create')}
    />
  );
}
