import {metadaApi} from '@dms/api/core';

import {
  PostPartsRequestRequest,
  PostPartsRequestResponse,
  PostReservationCustomerRequest,
  PostReservationCustomerResponse,
  PostReservationDirectSalesRequest,
  PostReservationDirectSalesResponse,
  PostReservationOtherRequest,
  PostReservationOtherResponse,
  PostReservationServiceOrderRequest,
  PostReservationServiceOrderResponse,
  GetPartsRequestRequest,
  GetPartsRequestResponse,
  GetReservationCustomerRequest,
  GetReservationCustomerResponse,
  GetReservationDirectSalesRequest,
  GetReservationDirectSalesResponse,
  GetReservationOtherRequest,
  GetReservationOtherResponse,
  GetReservationServiceOrderRequest,
  GetReservationServiceOrderResponse,
  PatchPartsRequestCancelByOriginEntityRequest,
  PatchPartsRequestCancelByOriginEntityResponse,
  PatchPartsRequestItemCancelRequest,
  PatchPartsRequestItemCancelResponse,
  PatchPartsRequestItemDeleteRequest,
  PatchPartsRequestItemDeleteResponse,
  PatchReservationCustomerRequest,
  PatchReservationCustomerResponse,
  PatchReservationDirectSalesRequest,
  PatchReservationDirectSalesResponse,
  PatchReservationOtherRequest,
  PatchReservationOtherResponse,
  PatchReservationServiceOrderRequest,
  PatchReservationServiceOrderResponse,
  GetPartsRequestResponseSchema,
  PostPartsRequestRequestSchema,
  PostPartsRequestResponseSchema,
  PatchPartsRequestCancelByOriginEntityRequestSchema,
  PostReservationOtherRequestSchema,
  PatchReservationOtherRequestSchema,
  GetReservationCustomerResponseSchema,
  PostReservationCustomerRequestSchema,
  PatchReservationCustomerRequestSchema,
  GetReservationServiceOrderResponseSchema,
  PostReservationServiceOrderRequestSchema,
  PostReservationServiceOrderResponseSchema,
  PatchReservationServiceOrderRequestSchema,
  GetReservationDirectSalesResponseSchema,
  PostReservationDirectSalesRequestSchema,
  PostReservationDirectSalesResponseSchema,
  PatchReservationDirectSalesRequestSchema,
  GetPartsRequestRequestSchema,
  PatchPartsRequestCancelByOriginEntityResponseSchema,
  PatchPartsRequestItemCancelRequestSchema,
  PatchPartsRequestItemCancelResponseSchema,
  PatchPartsRequestItemDeleteRequestSchema,
  PatchPartsRequestItemDeleteResponseSchema,
  PostReservationOtherResponseSchema,
  PatchReservationOtherResponseSchema,
  PostReservationCustomerResponseSchema,
  PatchReservationCustomerResponseSchema,
  PatchReservationServiceOrderResponseSchema,
  PatchReservationDirectSalesResponseSchema,
  GetReservationOtherResponseSchema,
  GetReservationOtherRequestSchema,
  GetReservationCustomerRequestSchema,
  GetReservationServiceOrderRequestSchema,
  GetReservationDirectSalesRequestSchema,
} from './types';

export const metadaWarehousePartsRequestApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    // Parts Requests
    getPartsRequest: build.query<GetPartsRequestResponse, GetPartsRequestRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/parts-request/reserved/${queryArg.articleId}/overview`,
        method: 'GET',
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [{type: 'partsRequest', id: queryArg.articleId}],
      extraOptions: {
        requestSchema: GetPartsRequestRequestSchema,
        responseSchema: GetPartsRequestResponseSchema,
      },
    }),
    postPartsRequest: build.mutation<PostPartsRequestResponse, PostPartsRequestRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/parts-request`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'directSales', id: queryArg.directSaleId},
      ],
      extraOptions: {
        requestSchema: PostPartsRequestRequestSchema,
        responseSchema: PostPartsRequestResponseSchema,
      },
    }),
    patchPartsRequestCancelByOriginEntity: build.mutation<
      PatchPartsRequestCancelByOriginEntityResponse,
      PatchPartsRequestCancelByOriginEntityRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/parts-request/cancel-by-origin-entity`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'directSales', id: queryArg.directSaleId},
        'afsCheckout',
        'afsPaymentsList',
        'afsPaymentById',
      ],
      extraOptions: {
        requestSchema: PatchPartsRequestCancelByOriginEntityRequestSchema,
        responseSchema: PatchPartsRequestCancelByOriginEntityResponseSchema,
      },
    }),
    patchPartsRequestItemCancel: build.mutation<
      PatchPartsRequestItemCancelResponse,
      PatchPartsRequestItemCancelRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/parts-request-item/${queryArg.requestItemId}/cancel`,
        method: 'PATCH',
        queryArg,
      }),
      invalidatesTags: ['partsRequest'],
      extraOptions: {
        requestSchema: PatchPartsRequestItemCancelRequestSchema,
        responseSchema: PatchPartsRequestItemCancelResponseSchema,
      },
    }),
    patchPartsRequestItemDelete: build.mutation<
      PatchPartsRequestItemDeleteResponse,
      PatchPartsRequestItemDeleteRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/parts-request-item/${queryArg.requestItemId}/delete`,
        method: 'PATCH',
      }),
      invalidatesTags: ['partsRequest', 'afsCheckout', 'afsPaymentsList', 'afsPaymentById'],
      extraOptions: {
        requestSchema: PatchPartsRequestItemDeleteRequestSchema,
        responseSchema: PatchPartsRequestItemDeleteResponseSchema,
      },
    }),
    getReservationOther: build.query<GetReservationOtherResponse, GetReservationOtherRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/parts-request/reserved/${queryArg.articleId}/request/${queryArg.requestId}/other`,
        method: 'GET',
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [{type: 'reservations', id: queryArg.requestId}],
      extraOptions: {
        requestSchema: GetReservationOtherRequestSchema,
        responseSchema: GetReservationOtherResponseSchema,
      },
    }),
    postReservationOther: build.mutation<PostReservationOtherResponse, PostReservationOtherRequest>(
      {
        query: (queryArg) => ({
          url: `warehouse/v1/parts-request-reservation/other`,
          method: 'POST',
          body: queryArg,
          queryArg,
        }),
        invalidatesTags: (result, error, queryArg) => [
          {type: 'reservations'},
          {type: 'partsRequest', id: queryArg.articleId},
        ],
        extraOptions: {
          requestSchema: PostReservationOtherRequestSchema,
          responseSchema: PostReservationOtherResponseSchema,
        },
      }
    ),
    patchReservationOther: build.mutation<
      PatchReservationOtherResponse,
      PatchReservationOtherRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/parts-request/reserved/${queryArg.articleId}/request/${queryArg.requestId}/other`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'reservations', id: queryArg.requestId},
      ],
      extraOptions: {
        requestSchema: PatchReservationOtherRequestSchema,
        responseSchema: PatchReservationOtherResponseSchema,
      },
    }),
    getReservationCustomer: build.query<
      GetReservationCustomerResponse,
      GetReservationCustomerRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/parts-request/reserved/${queryArg.articleId}/request/${queryArg.requestId}/customer`,
        method: 'GET',
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [{type: 'reservations', id: queryArg.requestId}],
      extraOptions: {
        requestSchema: GetReservationCustomerRequestSchema,
        responseSchema: GetReservationCustomerResponseSchema,
      },
    }),
    postReservationCustomer: build.mutation<
      PostReservationCustomerResponse,
      PostReservationCustomerRequest
    >({
      query: (queryArg) => ({
        url: 'warehouse/v1/parts-request-reservation/customer',
        method: 'POST',
        body: queryArg,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'reservations'},
        {type: 'partsRequest', id: queryArg.articleId},
      ],
      extraOptions: {
        requestSchema: PostReservationCustomerRequestSchema,
        responseSchema: PostReservationCustomerResponseSchema,
      },
    }),
    patchReservationCustomer: build.mutation<
      PatchReservationCustomerResponse,
      PatchReservationCustomerRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/parts-request/reserved/${queryArg.articleId}/request/${queryArg.requestId}/customer`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'reservations', id: queryArg.requestId},
      ],
      extraOptions: {
        requestSchema: PatchReservationCustomerRequestSchema,
        responseSchema: PatchReservationCustomerResponseSchema,
      },
    }),
    getReservationServiceOrder: build.query<
      GetReservationServiceOrderResponse,
      GetReservationServiceOrderRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/parts-request/reserved/${queryArg.articleId}/request/${queryArg.requestId}/service-order`,
        method: 'GET',
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [{type: 'reservations', id: queryArg.requestId}],
      extraOptions: {
        requestSchema: GetReservationServiceOrderRequestSchema,
        responseSchema: GetReservationServiceOrderResponseSchema,
      },
    }),
    postReservationServiceOrder: build.mutation<
      PostReservationServiceOrderResponse,
      PostReservationServiceOrderRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/parts-request-reservation/service-orders`,
        method: 'POST',
        body: queryArg,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'reservations',
        {type: 'partsRequest', id: queryArg.articleId},
      ],
      extraOptions: {
        requestSchema: PostReservationServiceOrderRequestSchema,
        responseSchema: PostReservationServiceOrderResponseSchema,
      },
    }),
    patchReservationServiceOrder: build.mutation<
      PatchReservationServiceOrderResponse,
      PatchReservationServiceOrderRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/parts-request/reserved/${queryArg.articleId}/request/${queryArg.requestId}/service-order`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'reservations', id: queryArg.requestId},
      ],
      extraOptions: {
        requestSchema: PatchReservationServiceOrderRequestSchema,
        responseSchema: PatchReservationServiceOrderResponseSchema,
      },
    }),
    getReservationDirectSales: build.query<
      GetReservationDirectSalesResponse,
      GetReservationDirectSalesRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/parts-request/reserved/${queryArg.articleId}/request/${queryArg.requestId}/direct-sale`,
        method: 'GET',
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [{type: 'reservations', id: queryArg.requestId}],
      extraOptions: {
        requestSchema: GetReservationDirectSalesRequestSchema,
        responseSchema: GetReservationDirectSalesResponseSchema,
      },
    }),
    postReservationDirectSales: build.mutation<
      PostReservationDirectSalesResponse,
      PostReservationDirectSalesRequest
    >({
      query: (queryArg) => ({
        url: 'warehouse/v1/parts-request-reservation/direct-sales',
        method: 'POST',
        body: queryArg,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        'reservations',
        {type: 'partsRequest', id: queryArg.articleId},
      ],
      extraOptions: {
        requestSchema: PostReservationDirectSalesRequestSchema,
        responseSchema: PostReservationDirectSalesResponseSchema,
      },
    }),
    patchReservationDirectSales: build.mutation<
      PatchReservationDirectSalesResponse,
      PatchReservationDirectSalesRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/parts-request/reserved/${queryArg.articleId}/request/${queryArg.requestId}/direct-sale`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'reservations', id: queryArg.requestId},
      ],
      extraOptions: {
        requestSchema: PatchReservationDirectSalesRequestSchema,
        responseSchema: PatchReservationDirectSalesResponseSchema,
      },
    }),
  }),
});

export const {
  // Parts Request
  useGetPartsRequestQuery,
  usePostPartsRequestMutation,
  usePatchPartsRequestItemCancelMutation,
  usePatchPartsRequestItemDeleteMutation,
  usePatchPartsRequestCancelByOriginEntityMutation,
  // Reservations
  useGetReservationOtherQuery,
  usePostReservationOtherMutation,
  usePatchReservationOtherMutation,
  useGetReservationCustomerQuery,
  usePostReservationCustomerMutation,
  usePatchReservationCustomerMutation,
  useGetReservationServiceOrderQuery,
  usePostReservationServiceOrderMutation,
  usePatchReservationServiceOrderMutation,
  useGetReservationDirectSalesQuery,
  usePostReservationDirectSalesMutation,
  usePatchReservationDirectSalesMutation,
} = metadaWarehousePartsRequestApi;
