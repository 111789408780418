import {createApi} from '@reduxjs/toolkit/query/react';

import {UnknownAction} from 'redux';

import {teasBaseQuery} from '@dms/api/core';
import {
  GetCurrenciesApiArg,
  GetCurrenciesApiResponse,
  GetGendersApiArg,
  GetGendersApiResponse,
  GetPhoneNumbersApiArg,
  GetPhoneNumbersApiResponse,
  GetRoundTypeApiArg,
  GetRoundTypeApiResponse,
  GetIdentityCardTypesApiResponse,
  GetIdentityCardTypesArg,
  GetCountriesApiArg,
  GetCountriesApiResponse,
  GetPrioritizedCountriesApiArg,
  GetBankListApiArg,
  GetBankListApiResponse,
  GetAlphaCatalogueApiArg,
  GetAlphaCatalogueApiResponse,
} from '@dms/api/shared';

import {REHYDRATE_RTK_QUERY} from 'features/rtkq-persist';

import {
  GetVatRatesApiArg,
  GetVatRatesApiResponse,
  GetVatRatesForCountryApiArg,
  GetVatRatesForCountryApiResponse,
} from './types';

const ONE_DAY_IN_SECONDS = 24 * 60 * 60;

export const cachedApi = createApi({
  reducerPath: 'cachedApi',
  baseQuery: teasBaseQuery,
  keepUnusedDataFor: ONE_DAY_IN_SECONDS,

  /**
   * This is crucial for making the rehydrated state work correctly.
   * It tells RTK Query how to extract the rehydrated state from our
   * custom REHYDRATE_RTK_QUERY action.
   */
  extractRehydrationInfo(
    action: UnknownAction & {
      payload?: {
        reducerPath?: string;
        state?: any;
      };
    },
    {reducerPath}
  ) {
    if (action.type === REHYDRATE_RTK_QUERY && action.payload?.reducerPath === reducerPath) {
      return action.payload.state;
    }
  },

  endpoints: (build) => ({
    getAlphaCatalogue: build.query<GetAlphaCatalogueApiResponse, GetAlphaCatalogueApiArg>({
      query: (queryArg) => ({
        method: 'GET',
        url: `/vehicle-catalogue/api/enum`,
        params: {lang: queryArg.lang},
      }),
    }),
    getPhoneNumbers: build.query<GetPhoneNumbersApiResponse, GetPhoneNumbersApiArg>({
      query: () => ({
        url: `/common/v1/phone-numbers`,
      }),
    }),
    getCountries: build.query<GetCountriesApiResponse, GetCountriesApiArg>({
      query: () => ({
        url: `/common/v1/countries`,
      }),
    }),
    getPrioritizedCountries: build.query<GetCountriesApiResponse, GetPrioritizedCountriesApiArg>({
      query: (queryArgs: GetPrioritizedCountriesApiArg) => ({
        url: `/common/v1/countries`,
        params: {countryCode: queryArgs.countryCode},
      }),
    }),
    getCurrencies: build.query<GetCurrenciesApiResponse, GetCurrenciesApiArg>({
      query: () => ({
        url: `/common/v1/currencies`,
      }),
    }),
    getGenders: build.query<GetGendersApiResponse, GetGendersApiArg>({
      query: () => ({url: `/common/v1/genders`}),
    }),
    getBankList: build.query<GetBankListApiResponse, GetBankListApiArg>({
      query: (queryArg) => ({
        url: `/common/v2/bank-list`,
        params: {countryCode: queryArg.countryCode},
      }),
    }),
    getIdentityCardTypes: build.query<GetIdentityCardTypesApiResponse, GetIdentityCardTypesArg>({
      query: () => ({
        url: `/common/v1/identity-card-types`,
      }),
    }),
    getRoundType: build.query<GetRoundTypeApiResponse, GetRoundTypeApiArg>({
      query: () => ({
        url: `/common/v1/round-type`,
      }),
    }),
    getVatRates: build.query<GetVatRatesApiResponse, GetVatRatesApiArg>({
      query: () => ({
        url: `/common/v1/vat-rates`,
      }),
    }),
    getVatRatesForCountry: build.query<
      GetVatRatesForCountryApiResponse,
      GetVatRatesForCountryApiArg
    >({
      query: ({country, ...params}) => ({
        url: `/common/v1/vat-rates/${country}`,
        params,
      }),
    }),
  }),
});

export const {
  useLazyGetVatRatesForCountryQuery,
  useLazyGetAlphaCatalogueQuery,
  useLazyGetCountriesQuery,
  useLazyGetCurrenciesQuery,
  useLazyGetGendersQuery,
  useLazyGetBankListQuery,
  useLazyGetIdentityCardTypesQuery,
  useLazyGetPhoneNumbersQuery,
  useLazyGetRoundTypeQuery,
  useLazyGetVatRatesQuery,
  useGetAlphaCatalogueQuery,
  useGetBankListQuery,
  useGetCountriesQuery,
  useGetPrioritizedCountriesQuery,
  useGetCurrenciesQuery,
  useGetGendersQuery,
  useGetIdentityCardTypesQuery,
  useGetPhoneNumbersQuery,
  useGetRoundTypeQuery,
  useGetVatRatesQuery,
  useGetVatRatesForCountryQuery,
} = cachedApi;
