import {startOfDay, differenceInDays, isToday, isYesterday} from 'date-fns';
import {Tooltip} from 'platform/components';
import {VStack, Text, Show} from 'platform/foundation';
import {DayMonthFormat, useDateTimeFormatter} from 'platform/locale';

import i18n from '@dms/i18n';
import {formatDateToNaturalLanguage} from '@dms/shared';

import {parseDate} from 'shared';

interface ActivityDateProps {
  timestamp: string;
}

export function ActivityDate(props: ActivityDateProps) {
  const formatDateTime = useDateTimeFormatter();
  const parsedTimestamp = parseDate(props.timestamp);

  const normalizedDate = startOfDay(parsedTimestamp);
  const today = startOfDay(new Date());

  const differenceDays = Math.abs(differenceInDays(normalizedDate, today));

  const isActivityToday = isToday(parsedTimestamp);
  const isActivityYesterday = isYesterday(parsedTimestamp);

  return (
    <VStack>
      <Tooltip label={formatDateTime('dateTimeShort', parsedTimestamp)}>
        <Text size="xxSmall" color="tertiary" align="right" noWrap>
          <Show when={isActivityToday}>
            {i18n.t('general.labels.today')} {formatDateTime('timeShort', parsedTimestamp)}
          </Show>

          <Show when={isActivityYesterday}>{i18n.t('general.labels.yesterday')}</Show>

          <Show when={!isActivityToday && !isActivityYesterday}>
            <DayMonthFormat
              value={{
                day: parsedTimestamp.getDate(),
                month: parsedTimestamp.getMonth() + 1,
              }}
              format="dateShort"
            />
          </Show>
        </Text>
      </Tooltip>

      <Show when={differenceDays >= 2}>
        <Text size="xxSmall" color="tertiary" align="right" noWrap>
          {`(${formatDateToNaturalLanguage(parsedTimestamp)})`}
        </Text>
      </Show>
    </VStack>
  );
}
