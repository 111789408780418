import {Button} from 'platform/components';
import {Box, getSize, HStack, Icon} from 'platform/foundation';
import {css} from 'styled-components';

import {DragDropContext, DragDropContextProps, Draggable, Droppable} from 'react-beautiful-dnd';

import {isNil, move, sortBy} from 'ramda';

import {useSourcingGetPresetsListQuery, useSourcingReorderPresetsMutation} from '@dms/api/sourcing';
import {testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {SourcingFilterPresetsControl} from '../../hooks/useSourcingFilterPresets';

interface SourcingFilterPresetButtonsProps {
  filterPresetsControl: SourcingFilterPresetsControl;
  isSortingEnabled?: boolean;
}

export function FilterPresetButtons(props: SourcingFilterPresetButtonsProps) {
  const {data} = useSourcingGetPresetsListQuery();
  const [reorderPresets] = useSourcingReorderPresetsMutation();

  const orderedPresets = sortBy((a) => a.position, data ?? []);

  const onReorder: DragDropContextProps['onDragEnd'] = (draggedItem) => {
    if (isNil(draggedItem.destination)) {
      return;
    }

    const reorderedFilterPresets = move(
      draggedItem.source.index,
      draggedItem.destination.index,
      orderedPresets
    );

    return reorderPresets({
      reorderPresetsRequestBody: {
        items: reorderedFilterPresets.map((reorderedFilterPreset, index) => ({
          id: reorderedFilterPreset.id,
          position: index + 1,
        })),
      },
    })
      .unwrap()
      .catch(handleApiError);
  };

  if (props.isSortingEnabled) {
    return (
      <Box flexGrow={1} overflowX="auto">
        <DragDropContext onDragEnd={onReorder}>
          <Droppable droppableId="drop" direction="horizontal">
            {(providedDroppable) => (
              <div
                css={css`
                  display: flex;
                  flex-direction: row;
                  gap: ${getSize(2)};
                  flex-wrap: wrap;
                `}
                ref={providedDroppable.innerRef}
                {...providedDroppable.droppableProps}
              >
                {orderedPresets.map((filterPreset, index) => (
                  <Draggable key={filterPreset.id} draggableId={filterPreset.id} index={index}>
                    {(providedDraggable) => (
                      <div
                        ref={providedDraggable.innerRef}
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                      >
                        <HStack align="center">
                          <Icon
                            value="action/drag_indicator"
                            color="palettes.neutral.60.100"
                            data-testid={testIds.sourcing.classifieds('filterPresetButtons-drag')}
                          />
                          <Button
                            key={filterPreset.id}
                            title={filterPreset.name}
                            onClick={() =>
                              props.filterPresetsControl.onSelectFilterPresetId(filterPreset.id)
                            }
                            variant={
                              filterPreset.id === props.filterPresetsControl.selectedFilterPresetId
                                ? 'primary'
                                : 'outlined'
                            }
                            data-testid={testIds.sourcing.classifieds(
                              `filterPresetButtons-${filterPreset.name}`
                            )}
                          />
                        </HStack>
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    );
  }

  return (
    <HStack grow={1} spacing={2} wrap>
      {orderedPresets.map((filterPreset) => (
        <Button
          key={filterPreset.id}
          title={filterPreset.name}
          onClick={() => props.filterPresetsControl.onSelectFilterPresetId(filterPreset.id)}
          variant={
            filterPreset.id === props.filterPresetsControl.selectedFilterPresetId
              ? 'primary'
              : 'outlined'
          }
          data-testid={testIds.sourcing.classifieds(`filterPresetButtons-${filterPreset.name}`)}
        />
      ))}
    </HStack>
  );
}
