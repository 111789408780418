import {BigCalendarEvent} from 'platform/big-calendar';

import {GetDataByDataQueryApiResponse} from '@dms/api/datagrid';
import {TaskTypeEnum, TaskPriorityEnum, TaskStateEnum} from '@dms/api/taskManagement';
import {Task} from '@dms/features/tasks';

import {Nullish, getApiDateString} from 'shared';

interface GetTaskFromBigCalendarEventArgs {
  event: BigCalendarEvent;
  newStart: Date;
  newEnd: Date;
  dataGridData: GetDataByDataQueryApiResponse | Nullish;
}

export function getTaskFromBigCalendarEvent(args: GetTaskFromBigCalendarEventArgs): Task | null {
  const event = args.dataGridData?.rows.find((row) => row.id === args.event.id);

  if (!event) {
    return null;
  }

  const dueDate = getApiDateString(args.newEnd);
  const startTime = args.event.isAllDay ? null : args.newStart.toISOString();
  const endTime = args.event.isAllDay ? null : args.newEnd.toISOString();

  return {
    id: event.id,
    title: event.cells.title.value,
    state: event.cells.state.value?.key as TaskStateEnum,
    priority: event.cells?.priority?.value?.key as TaskPriorityEnum,
    type: event.cells?.type?.value?.key as TaskTypeEnum,
    dueDate,
    dueFrom: dueDate && startTime ? startTime : null,
    dueTo: dueDate && endTime ? endTime : null,
    createdAt: event.cells?.createdAt?.value,
    createdBy: event.cells?.createdBy?.value?.id,
    reportedBy: event.cells?.reportedBy?.value?.id,
    assignedTo: event.cells?.assignedTo?.value?.id,
    finishedAt: event.cells?.finishedAt?.value,
    relatedRecordId: event.cells?.relatedRecordId?.value?.id,
    description: event.cells?.description?.value,
  } as const;
}
