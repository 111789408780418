import {TableCellBaseProps} from 'platform/components';
import {Box, Hide, Show, Text} from 'platform/foundation';

import {EMPTY_PLACEHOLDER} from 'shared';

import {TableCellType} from '../types/TableCellType';

interface CommentCellProps extends TableCellBaseProps<TableCellType> {
  comment: string;
}

export function CommentCell(props: CommentCellProps) {
  return (
    <Box paddingHorizontal={2} paddingVertical={1}>
      <Text>
        <Show when={props.comment}>
          <Text size="xSmall" overflowWrap="anywhere">
            {props.comment}
          </Text>
        </Show>
        <Hide when={props.comment}>
          <Text>{EMPTY_PLACEHOLDER}</Text>
        </Hide>
      </Text>
    </Box>
  );
}
