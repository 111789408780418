import {TaskFormInput} from '@dms/features/tasks';

import {getApiDateString, parseDate} from 'shared';

export function getCreateTaskDefaultValues(date: Date): TaskFormInput {
  const dueFrom = parseDate(date);
  dueFrom.setHours(dueFrom.getHours());
  dueFrom.setMinutes(0);
  dueFrom.setSeconds(0);
  const dueTo = parseDate(date);
  dueTo.setHours(dueTo.getHours() + 1);
  dueTo.setMinutes(0);
  dueTo.setSeconds(0);

  return {
    dueDate: getApiDateString(date),
    dueFrom: dueFrom.toISOString(),
    dueTo: dueTo.toISOString(),
  };
}
