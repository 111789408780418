import {Card} from 'platform/components';
import {Icon, Heading, Space, VStack} from 'platform/foundation';

import {FC, useState, PropsWithChildren} from 'react';
import {useSelector} from 'react-redux';

import {selectTenant} from '@dms/api/tenant';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {
  useInlineEditing,
  DEFAULT_COUNTRY,
  ContactPersonRequestBody,
  PutTenantRequestBody,
} from '@dms/teas';

import {
  ContactPersonFormWrapper,
  RemoveContactPersonButton,
  AddContactPersonButton,
} from '../styles';
import {TenantCache} from '../types';
import {ContactPersonForm} from './ContactPersonForm';

export const ContactPersons: FC<PropsWithChildren<any>> = () => {
  const {data: {country: countryCode} = {country: DEFAULT_COUNTRY}} = useSelector(selectTenant);

  const [expandedIndex, handleExpandContactRow] = useState<null | number>();

  const inlineEditing = useInlineEditing<Partial<PutTenantRequestBody>, TenantCache>();
  const initialSelectedIndex = inlineEditing.editedValue?.data?.contactPersons?.findIndex(
    (c) => c.isPrimary
  );

  const [contacts, setContacts] = useState<ContactPersonRequestBody[]>(
    inlineEditing.editedValue?.data?.contactPersons || []
  );
  const [selectedIndex, setSelectedIndex] = useState<number | null>(initialSelectedIndex ?? null);

  const defaultContactPersonData: ContactPersonRequestBody = {
    isPrimary: false,
    firstName: null,
    lastName: null,
    role: null,
    phoneNumber: {
      countryCode,
      number: '',
      prefix: '',
    },
    emailAddress: null,
  };

  const handleContactPersonChange = (contact: ContactPersonRequestBody, index: number) => {
    const changedContacts = contacts.map((c, i) => (i === index ? contact : c));

    setContacts(changedContacts);
    submitInlineEditing(changedContacts);
  };

  const handleContactPersonAdd = () => {
    const changedContacts = contacts.concat([{...defaultContactPersonData}]);
    setContacts(changedContacts);
    submitInlineEditing(changedContacts);
  };

  const handleContactPersonRemove = (index: number) => {
    const changedContacts = contacts.filter((_c, i) => i !== index);
    setContacts(changedContacts);
    if (selectedIndex === null) {
      setSelectedIndex(index - 1);
    } else if (index === selectedIndex) {
      setSelectedIndex(null);
    } else if (index < selectedIndex) {
      setSelectedIndex(selectedIndex - 1);
    }
    submitInlineEditing(changedContacts);
  };

  const handleContactSelect = (index: number) => {
    const changedContacts = contacts.map((c, i) => ({
      ...c,
      isPrimary: i === index,
    }));
    submitInlineEditing(changedContacts);
    setSelectedIndex(index);
  };

  const submitInlineEditing = (contactPersons: ContactPersonRequestBody[]) => {
    inlineEditing.updateMethod({contactPersons}, {contactPersons});
  };

  return (
    <VStack>
      <Heading size={4}>{i18n.t('entity.customer.labels.contactPersons')}</Heading>
      <Space vertical={4} />
      <VStack spacing={4}>
        {contacts.map((person, index) => {
          const {firstName, lastName} = person;
          const showDetails = index === expandedIndex;
          return (
            <Card
              key={index}
              isExpandable
              variant="inlineGrey"
              isExpanded={showDetails}
              onExpandButtonClick={() => handleExpandContactRow(showDetails ? null : index)}
              title={
                firstName || lastName
                  ? `${firstName || ''} ${lastName || ''}`
                  : i18n.t('entity.customer.actions.newContactPerson')
              }
              control={{
                value: index === selectedIndex,
                type: 'radio',
                onChange: () => {
                  handleContactSelect(index);
                },
                tooltip: i18n.t('entity.customer.labels.contactPersonsHelperText'),
              }}
            >
              <ContactPersonFormWrapper>
                <ContactPersonForm
                  initialValues={person}
                  onChange={(contact) => handleContactPersonChange(contact, index)}
                />

                <RemoveContactPersonButton
                  data-testid={testIds.settings.tenant(`deletePerson-${index}`)}
                  onClick={() => handleContactPersonRemove(index)}
                >
                  {i18n.t('entity.customer.actions.removeContact')}
                </RemoveContactPersonButton>
              </ContactPersonFormWrapper>
            </Card>
          );
        })}
        <AddContactPersonButton
          data-testid={testIds.settings.tenant('addPersons')}
          onClick={handleContactPersonAdd}
        >
          <Icon value="content/add_circle" /> {i18n.t('entity.customer.actions.newContactPerson')}
        </AddContactPersonButton>
      </VStack>
    </VStack>
  );
};
