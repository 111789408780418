import {isNotNil} from 'ramda-adjunct';

import {AuditParamDefinitionResponseBody} from '@dms/api/carAudit';

export const getAllChildParams = (params: AuditParamDefinitionResponseBody[]) => {
  const allParams = params
    .flatMap((param) => param.values.map((value) => value.content))
    .filter(isNotNil);

  const relatedActions = allParams.flatMap((param) => param.relatedActions).filter(isNotNil);
  const additionalInformation = allParams
    .flatMap((param) => param.additionalInformation)
    .filter(isNotNil);

  return [...params, ...relatedActions, ...additionalInformation];
};
