import {useGetWatermarkQuery} from '@dms/api/watermark';
import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {WatermarkFormOld} from './components/WatermarkFormOld';

export function PhotosWatermarkingOld() {
  const {isLoading} = useGetWatermarkQuery();

  return (
    <SettingsTemplate
      isLoading={isLoading}
      header={{
        title: i18n.t('page.salesSettings.labels.watermark'),
        breadcrumbs: [
          {
            label: i18n.t('page.salesSettings.labels.advertising'),
            href: settingsRoutes.advertising,
          },
        ],
      }}
      data-testid="settings-advertising-watermarking"
    >
      <WatermarkFormOld />
    </SettingsTemplate>
  );
}
