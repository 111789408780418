import {BranchListItemResponseBody} from '@dms/api/shared';
import {useGetBranchListQuery} from '@dms/api/tenant';

export const useBranches = () => {
  const {data: branchList, ...args} = useGetBranchListQuery();

  return {
    ...args,
    data: branchList,
    branchOptions: branchList?.branchListItems?.map(branchToOption) ?? [],
  };
};

const branchToOption = (branch: BranchListItemResponseBody) => ({
  value: branch.id,
  label: branch.marketingName,
});
