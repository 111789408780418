import {ColorSchemeType} from 'platform/components';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import {ConsentStatus} from '@dms/api/shared';

export const getConsentStatusColor = (status: ConsentStatus): ColorSchemeType =>
  match<ConsentStatus, ColorSchemeType>(status)
    .with(ConsentStatus.AGREED, always('green'))
    .with(ConsentStatus.EXPIRED, always('orange'))
    .with(ConsentStatus.REJECTED, always('red'))
    .with(ConsentStatus.WITHDRAWN, always('yellow'))
    .exhaustive();
