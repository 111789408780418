import {
  Card,
  Form,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Grid, VStack} from 'platform/foundation';
import {boolean, object} from 'yup';

import {useCreatePreAccountingProcessingSettingsMutation} from '@dms/api/accounting';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError, useBranches} from '@dms/shared';

import {useNavigate, yupString} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

type FormValues = {
  branchId: string;
  notifyAccountingDocuments: boolean;
  notifyStockReceipts: boolean;
  notifyStockIssues: boolean;
  notifyStockTransfers: boolean;
};

const breadcrumbs = [
  {
    label: i18n.t('page.businessCases.title'),
    href: settingsRoutes.businessCase,
  },
  {
    label: i18n.t('page.accounting.labels.preProcessingCalculation'),
    href: settingsRoutes.preAccountingProcessingOverview,
  },
];

export function PreAccountingProcessingNew() {
  const navigate = useNavigate();

  const {branchOptions, isLoading, isError} = useBranches();
  const [createProcessingItem] = useCreatePreAccountingProcessingSettingsMutation();

  const handleSubmit: FormSubmitHandler<FormValues> = async (values) => {
    await createProcessingItem({
      createPreAccountingProcessingSettingsRequestBody: values,
    })
      .unwrap()
      .then(() =>
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'))
      )
      .then(() => navigate(settingsRoutes.preAccountingProcessingOverview))
      .catch(handleApiError);
  };

  return (
    <SettingsTemplate
      isError={isError}
      isLoading={isLoading}
      header={{
        title: i18n.t('page.accounting.labels.newDefinition'),
        breadcrumbs,
      }}
      data-testid={testIds.settings.preAccountingProcessingNew('settings-template')}
    >
      <Form<FormValues> onSubmit={handleSubmit} schema={schema}>
        {(control) => (
          <VStack spacing={4}>
            <SettingsSection>
              <Grid columns={2}>
                <FormField
                  control={control}
                  type="choice"
                  name="branchId"
                  isNotClearable
                  options={branchOptions}
                  label={i18n.t('entity.cashRegister.parameters.branch')}
                  isRequired
                  data-testid={testIds.settings.preAccountingProcessingNew('branch')}
                />
              </Grid>
              <Separator />

              <VStack spacing={4}>
                <Card variant="inlineGrey" title={i18n.t('entity.accounting.labels.accounting')}>
                  <FormField
                    control={control}
                    type="checkbox"
                    name="notifyAccountingDocuments"
                    label={i18n.t('entity.accounting.labels.notifyAccountingDocuments')}
                    data-testid={testIds.settings.preAccountingProcessingNew(
                      'notifyAccountingDocuments'
                    )}
                  />
                </Card>

                <Card
                  variant="inlineGrey"
                  title={i18n.t('page.accountingSettings.labels.warehouseMovements')}
                >
                  <VStack spacing={2}>
                    <FormField
                      control={control}
                      type="checkbox"
                      name="notifyStockReceipts"
                      label={i18n.t('page.accountingSettings.labels.notifyReceipt')}
                      data-testid={testIds.settings.preAccountingProcessingNew('notifyReceipt')}
                    />
                    <FormField
                      control={control}
                      type="checkbox"
                      name="notifyStockIssues"
                      label={i18n.t('page.accountingSettings.labels.notifyIssue')}
                      data-testid={testIds.settings.preAccountingProcessingNew('notifyIssue')}
                    />
                    <FormField
                      control={control}
                      type="checkbox"
                      name="notifyStockTransfers"
                      label={i18n.t('page.accountingSettings.labels.notifyTransfer')}
                      data-testid={testIds.settings.preAccountingProcessingNew('notifyTransfer')}
                    />
                  </VStack>
                </Card>
              </VStack>
            </SettingsSection>

            <SettingsFooter
              actions={[
                {
                  type: 'button',
                  title: i18n.t('general.actions.discardChanges'),
                  onClick: () => navigate(settingsRoutes.preAccountingProcessingOverview),
                  variant: 'secondary',
                },
                {
                  type: 'form-button',
                  control,
                  buttonType: 'submit',
                  title: i18n.t('general.actions.saveChanges'),
                },
              ]}
            />
          </VStack>
        )}
      </Form>
    </SettingsTemplate>
  );
}

const schema = object({
  branchId: yupString.required(),
  notifyAccountingDocuments: boolean().default(false).required(),
  notifyStockReceipts: boolean().default(false).required(),
  notifyStockIssues: boolean().default(false).required(),
  notifyStockTransfers: boolean().default(false).required(),
});
