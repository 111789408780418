import {useEffect, useRef, useState} from 'react';

interface Dimensions {
  width: number;
  height: number;
}

/**
 * A hook that measures and tracks the dimensions of a DOM element.
 *
 * @returns A tuple containing:
 * - A ref object to attach to the element being measured
 * - The current dimensions (width and height) of the element
 *
 * @example
 * ```tsx
 * function MyComponent() {
 *   const [ref, dimensions] = useDimensions();
 *
 *   return (
 *     <div ref={ref}>
 *       Width: {dimensions.width}px
 *       Height: {dimensions.height}px
 *     </div>
 *   );
 * }
 * ```
 */
export function useDimensions(): [React.RefObject<HTMLDivElement | null>, Dimensions] {
  const ref = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const observer = new ResizeObserver((entries) => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }

      const entry = entries[0];
      const {width, height} = entry.contentRect;

      setDimensions({width, height});
    });

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return [ref, dimensions];
}
