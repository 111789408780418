import {DataStatus} from 'platform/components';
import {Box, VStack} from 'platform/foundation';
import {css} from 'styled-components';

import {useMemo} from 'react';
import {Outlet, useLocation} from 'react-router-dom';

import {MenuItemProps} from '@dms/api/shared';
import {useGetCurrentUserInfoQuery, useGetUserMenuPinsQuery} from '@dms/api/user';
import {moduleKeys} from '@dms/config';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {AppContainer} from './components/AppContainer';
import {HideOnMobileNavigation} from './components/HideOnMobileNavigation';
import {MenuItemsStateProvider} from './components/MenuItemsStateProvider';
import {PageNavigationBar} from './components/PageNavigationBar';
import {ShowOnMobileNavigation} from './components/ShowOnMobileNavigation';
import {Sidebar} from './components/Sidebar';
import {SidebarMenu} from './components/SidebarMenu';
import {TopBar} from './components/TopBar';
import {useIsMobileNavigation} from './hooks/useIsMobileNavigation';
import {useIsPageNavigationVisible} from './hooks/useIsPageNavigationVisible';
import {addPinsToMenuItems} from './utils/addPinsToMenuItems';
import {isMenuItemActive} from './utils/isMenuItemActive';
import {transformPinnedMenuItems} from './utils/transformPinnedMenuItems';

interface LayoutProps extends RequiredTestIdProps {
  menuItems: MenuItemProps[];
  menuItemsById: Record<string, MenuItemProps>;
  createMenuItems: MenuItemProps[];
}

export function LayoutV2(props: LayoutProps) {
  const location = useLocation();

  const isMobileNavigation = useIsMobileNavigation();
  const isPageNavigationVisible = useIsPageNavigationVisible();

  const {
    data: userInfo,
    isLoading: isUserInfoLoading,
    isError: isUserInfoErrored,
  } = useGetCurrentUserInfoQuery();
  const {
    data: pinnedMenuItems = [],
    isLoading: isPinnedMenuItemsLoading,
    isError: isPinnedMenuItemsErrored,
  } = useGetUserMenuPinsQuery();

  const settingsModule = props.menuItems.find((item) => item.id === moduleKeys.settings);
  const activeModule = useMemo(
    () => props.menuItems.find((menuItem) => isMenuItemActive(location.pathname, menuItem.path)),
    [location.pathname, props.menuItems]
  );
  const filteredMenuItems = props.menuItems.filter(
    (menuItem) =>
      // Setting have been moved to the top bar.
      menuItem.id !== moduleKeys.settings
  );

  const menuItemsWithPins = addPinsToMenuItems(filteredMenuItems, pinnedMenuItems);
  const sidebarPinnedMenuItems = transformPinnedMenuItems(pinnedMenuItems, props.menuItemsById);

  return (
    <DataStatus
      minHeight="100%"
      isLoading={isPinnedMenuItemsLoading || isUserInfoLoading}
      isError={isPinnedMenuItemsErrored || isUserInfoErrored}
      data={userInfo}
    >
      {({data}) => (
        <div
          css={css`
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-columns: ${({theme}) => theme.getSize(12)} 1fr;
            grid-template-rows: ${({theme}) => theme.getSize(12)} 1fr;
          `}
        >
          <div
            css={css`
              grid-column-end: span 1;
              grid-row-end: span ${isMobileNavigation ? 1 : 2};
            `}
          >
            <Box
              height="100%"
              contain="layout"
              zIndex="MAIN_MENU"
              position="relative"
              backgroundColor="palettes.neutral.900.100"
              data-testid={suffixTestId('menuWrapper', props)}
            >
              <VStack height="100%">
                <MenuItemsStateProvider>
                  <SidebarMenu
                    iconSize={data.settings.sidebarIconSize}
                    menuItemsWithPins={menuItemsWithPins}
                    createMenuItems={props.createMenuItems}
                    menuItemsById={props.menuItemsById}
                    data-testid={props['data-testid']}
                  />
                </MenuItemsStateProvider>
                <HideOnMobileNavigation>
                  <Sidebar
                    iconSize={data.settings.sidebarIconSize}
                    menuItemsById={props.menuItemsById}
                    pinnedMenuItems={sidebarPinnedMenuItems}
                    createMenuItems={props.createMenuItems}
                    data-testid={props['data-testid']}
                  />
                </HideOnMobileNavigation>
              </VStack>
            </Box>
          </div>

          <TopBar
            iconSize={data.settings.sidebarIconSize}
            settingsModule={settingsModule}
            activeModule={activeModule}
            data-testid="header"
          />
          <ShowOnMobileNavigation when={isPageNavigationVisible}>
            <PageNavigationBar data-testid="sub-bar" />
          </ShowOnMobileNavigation>

          <AppContainer>
            <Outlet />
          </AppContainer>
        </div>
      )}
    </DataStatus>
  );
}
