import {Button, openDeleteDialog, showNotification} from 'platform/components';
import {Heading, HStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useCallback} from 'react';

import {head, isNil} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {useDeleteBackgroundRemovalMutation} from '@dms/api/vehicle';
import i18n from '@dms/i18n';
import {settingsRoutes} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid} from 'features/datagrid';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

export function BackgroundRemoval() {
  const navigate = useNavigate();
  const [deleteConfiguration] = useDeleteBackgroundRemovalMutation();

  const handleActionCallback: ActionCallback = useCallback(
    ({actionKey, rowId, refreshData}) => {
      const id = isArray(rowId) ? head(rowId) : rowId;

      if (isNil(id)) {
        showNotification.error();
        return;
      }

      match(actionKey)
        .with('edit', () =>
          navigate(composePath(settingsRoutes.backgroundRemovalDetail, {params: {id}}))
        )
        .with('delete', () =>
          openDeleteDialog({
            onConfirm: () =>
              deleteConfiguration({settingId: id})
                .unwrap()
                .then(refreshData)
                .then(() => showNotification.success())
                .catch(handleApiError),
            text: i18n.t('page.salesSettings.backgroundRemoval.deleteConfiguration'),
          })
        )
        .otherwise(() => showNotification.warning('unknown action key'));
    },
    [deleteConfiguration, navigate]
  );
  return (
    <SettingsTemplate
      header={{
        title: i18n.t('page.salesSettings.labels.backgroundRemovalName'),
        breadcrumbs: [
          {
            label: i18n.t('page.salesSettings.labels.advertising'),
            href: settingsRoutes.advertising,
          },
        ],
      }}
      data-testid="settings-backgroundRemoval"
      description={i18n.t('page.salesSettings.backgroundRemoval.common.description')}
    >
      <HStack align="center" justify="space-between">
        <Heading size={4}>
          {i18n.t('page.salesSettings.backgroundRemoval.configurationList')}
        </Heading>
        <Button
          leftIcon="content/add"
          title={i18n.t('page.salesSettings.backgroundRemoval.addConfiguration')}
          variant="link"
          onClick={() => navigate(settingsRoutes.backgroundRemovalCreate)}
          data-testid="settings-backgroundRemoval-addConfiguration-btn"
        />
      </HStack>
      <DataGrid
        gridCode="background-removal"
        actionCallback={handleActionCallback}
        data-testid="settings-backgroundRemoval-datagrid"
      />
    </SettingsTemplate>
  );
}
