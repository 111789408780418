import {isNil} from 'ramda';

import {ScopeValues} from '@dms/api/shared';

export const evaluateBranch = (
  scopes: string[],
  evaluatorParams: ScopeValues | undefined,
  userBranchesIds: string[]
) => {
  if (isNil(evaluatorParams) || !('branchId' in evaluatorParams)) {
    return false;
  }
  if (evaluatorParams.branchId === 'SKIP') {
    return true;
  }

  // scopes is array at maximal size of 2, so this check is fine
  if (scopes.includes('ALL_BRANCHES')) {
    return true;
  }
  if (scopes.includes('MY_BRANCHES')) {
    return Boolean(evaluatorParams.branchId && userBranchesIds.includes(evaluatorParams.branchId));
  }

  return false;
};
