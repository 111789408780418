import {
  Alert,
  Button,
  FieldValues,
  FormControl,
  FormField,
  showNotification,
} from 'platform/components';
import {Box, Show, Space, Stack, VStack} from 'platform/foundation';

import {Path, UseFormReturn} from 'react-hook-form';

import {isNil, isNotNil} from 'ramda';

import {SimilarVehicleResponseBody} from '@dms/api/shared';
import {useGetCebiaFeatureDecoderConfigQuery} from '@dms/api/tenant';
import {useLazyGetAutofillFeaturesQuery} from '@dms/api/vehicle';
import {useLazyVinDecoderDecodeQuery, VinDecoderResponseBody} from '@dms/api/vinDecoder';
import i18n from '@dms/i18n';

import {AllOrNone, suffixTestId, TestIdProps} from 'shared';

import {validateVin} from 'features/vin-validate';

import {VehicleRecommendationType} from '../../types/VehicleRecommendationType';
import {handleApiError} from '../../utils/handleApiError';
import {SimilarVehicles} from './components/SimilarVehicles';
import {VehicleSelectionType} from './components/VehiclePreview';
import {filterKeys} from './utils/filterKeys';
import {transformPaste} from './utils/transformPaste';

type SimilarVehiclesDisplayProps = AllOrNone<{
  shouldRecommendVehiclesFor?: VehicleRecommendationType;
  onVehicleSelect: (
    vehicle: SimilarVehicleResponseBody,
    selectionType: VehicleSelectionType
  ) => void;
}>;

export type VinDecoderFieldProps<TFieldValues extends FieldValues = FieldValues> = {
  control: FormControl<TFieldValues>;
  formApi: UseFormReturn<TFieldValues>;
  vinField: {
    name: Path<TFieldValues>;
    label?: string;
    isDisabled?: boolean;
  };
  registrationPlateField: {
    name: Path<TFieldValues>;
    label?: string;
    isDisabled?: boolean;
  };
  isStatic?: boolean;
  onDecodedData?: (decodedData?: VinDecoderResponseBody) => void;
  isCebiaFeatureDecoderEnabled?: boolean;
} & TestIdProps &
  SimilarVehiclesDisplayProps;

export function VinDecoderField<TFieldValues extends FieldValues = FieldValues>(
  props: VinDecoderFieldProps<TFieldValues>
) {
  const [decodeVin, {isLoading, data}] = useLazyVinDecoderDecodeQuery();
  const {data: {enabled: isCebiaFeatureDecoderEnabled} = {}} = useGetCebiaFeatureDecoderConfigQuery(
    undefined,
    {skip: !props.isCebiaFeatureDecoderEnabled}
  );
  const [getCebiaAutofillFeatures, {isLoading: isCebiaAutofillFeaturesLoading}] =
    useLazyGetAutofillFeaturesQuery();

  const handleDecodeVin = async () => {
    if (props.isStatic) {
      return;
    }

    const vin = props.formApi.getValues(props.vinField.name);

    if (isNil(vin)) {
      return props.formApi.setError(props.vinField.name, {
        message: i18n.t('entity.vehicle.notifications.vinIsRequired'),
      });
    }

    if (!validateVin(vin)) {
      return props.formApi.setError(props.vinField.name, {
        message: i18n.t('entity.vehicle.notifications.vinIsInvalid'),
      });
    }

    const decodedData = await decodeVin({vin}).unwrap().catch(handleApiError);

    if (isCebiaFeatureDecoderEnabled) {
      getCebiaAutofillFeatures({vin})
        .unwrap()
        .then((response) => {
          showNotification.success(i18n.t('entity.vehicle.notifications.featuresLoaded'));
          props.onDecodedData?.({...(decodedData ?? {}), features: response.features});
        })
        .catch(handleApiError);
    } else {
      props.onDecodedData?.(decodedData ?? undefined);
    }
  };

  return (
    <VStack spacing={4}>
      <Stack direction={['column', 'column', 'row', 'row']} spacing={[6, 6, 4, 4]}>
        <FormField
          control={props.control}
          type="text"
          name={props.registrationPlateField.name}
          label={props.registrationPlateField.label}
          data-testid={suffixTestId('registrationPlate', props)}
          isDisabled={props.registrationPlateField.isDisabled}
        />
        <Box flex={1}>
          <FormField
            control={props.control}
            type="text"
            maxLength={17}
            isCounterVisible
            name={props.vinField.name}
            label={props.vinField.label}
            onChange={() => props.formApi.clearErrors(props.vinField.name)}
            onKeyDown={filterKeys}
            onPaste={transformPaste}
            isDisabled={props.vinField.isDisabled}
            data-testid={suffixTestId('vin', props)}
          />
        </Box>
        <Show when={!props.isStatic}>
          <VStack>
            <Show onDesktop onNotebook>
              <Space vertical={5} />
            </Show>
            <Button
              title={i18n.t('general.labels.decode')}
              onClick={handleDecodeVin}
              isDisabled={props.vinField.isDisabled}
              isLoading={isLoading || isCebiaAutofillFeaturesLoading}
              data-testid={suffixTestId('decode', props)}
            />
          </VStack>
        </Show>
      </Stack>

      {isNotNil(props.shouldRecommendVehiclesFor) && isNotNil(props.onVehicleSelect) && (
        <SimilarVehicles
          control={props.control}
          registrationPlateField={props.registrationPlateField}
          shouldRecommendVehiclesFor={props.shouldRecommendVehiclesFor}
          vinField={props.vinField}
          onVehicleSelect={props.onVehicleSelect}
          data-testid={suffixTestId('SimilarVehicles', props)}
        />
      )}

      <Show when={data?.decodingStatus === 'fully'}>
        <Alert
          variant="success"
          title={i18n.t('entity.vehicle.notifications.vinDecodedSuccessfully')}
          message={i18n.t('entity.vehicle.notifications.vinDecodedSuccessfullyDescription')}
          data-testid={suffixTestId('vinDecodedSuccessfully', props)}
        />
      </Show>
      <Show when={data?.decodingStatus === 'partially'}>
        <Alert
          variant="warning"
          title={i18n.t('entity.vehicle.notifications.vinDecodedPartially')}
          message={i18n.t('entity.vehicle.notifications.vinDecodedPartiallyDescription')}
          data-testid={suffixTestId('vinDecodedPartially', props)}
        />
      </Show>
      <Show when={data?.decodingStatus === 'none'}>
        <Alert
          variant="error"
          title={i18n.t('entity.vehicle.notifications.vinDecodedError')}
          message={i18n.t('entity.vehicle.notifications.vinDecodedErrorDescriptions')}
          data-testid={suffixTestId('vinDecodedError', props)}
        />
      </Show>
    </VStack>
  );
}
