import {Action, Attributes, AttributesRow, Card, DataStatus, Flag} from 'platform/components';
import {Box, Link, VStack} from 'platform/foundation';
import {useDateTimeFormatter, useFormatCurrency} from 'platform/locale';

import {useState} from 'react';

import {defaultTo, equals, isNil, isNotNil, not} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {useGetPaymentTypeEnumQuery} from '@dms/api/accounting';
import {useGetCurrenciesQuery} from '@dms/api/cached';
import {useGetAuthorizationProfilesQuery} from '@dms/api/metadaAdminAuthorizationProfile';
import {useGetWarehouseQuery, useGetWarehousesQuery} from '@dms/api/metadaWarehouse';
import {useGetWarehouseAccountQuery} from '@dms/api/metadaWarehouseAccount';
import {useGetDeliveryNoteQuery} from '@dms/api/metadaWarehouseDeliveryNote';
import {GetReceiveNoteResponse} from '@dms/api/metadaWarehouseReceiveNote';
import {useGetReceiveNoteItemsTotalPriceQuery} from '@dms/api/metadaWarehouseReceiveNoteItem';
import {useGetSuppliersQuery} from '@dms/api/metadaWarehouseSupplier';
import {useGetTenantQuery} from '@dms/api/tenant';
import i18n from '@dms/i18n';
import {testIds, warehouseRoutes} from '@dms/routes';
import {DEFAULT_CURRENCY, Section} from '@dms/shared';

import {
  buildArray,
  composePath,
  EMPTY_PLACEHOLDER,
  parseDate,
  RequiredTestIdProps,
  suffixTestId,
} from 'shared';

import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';
import {getSupplierById} from '../../../../utils/getSupplierById';
import {BasicInformationForm} from './components/BasicInformationForm';
import {BasicInformationReadOnly} from './components/BasicInformationReadOnly';
import {ReceiveNoteItemList} from './components/ReceiveNoteItemList';

interface OverviewProps extends RequiredTestIdProps {
  receiveNote?: GetReceiveNoteResponse;
  isReceiveNoteLoading: boolean;
  hasReceiveNoteError: boolean;
}

export function Overview(props: OverviewProps) {
  const {receiveNoteId} = useWarehouseParams();

  const dateTimeFormatter = useDateTimeFormatter();

  const formatCurrency = useFormatCurrency();

  const [isReadOnly, setIsReadOnly] = useState(isNotNilOrEmpty(receiveNoteId));

  const isReceiveNoteCompleted = props.receiveNote?.state === 'COMPLETED';

  const {data: totalPrice} = useGetReceiveNoteItemsTotalPriceQuery(
    {receiveNoteId},
    {skip: isNilOrEmpty(receiveNoteId)}
  );

  const {data: warehouse} = useGetWarehouseQuery(
    {warehouseId: props.receiveNote?.warehouseId as string},
    {skip: isNilOrEmpty(props.receiveNote?.warehouseId)}
  );

  const {
    data: warehouseAccount,
    isLoading: isWarehouseAccountLoading,
    isError: isWarehouseAccountError,
  } = useGetWarehouseAccountQuery(
    {warehouseAccountId: warehouse?.defaultAccountId as string},
    {skip: isNilOrEmpty(warehouse)}
  );

  const {
    data: deliveryNote,
    isLoading: isDeliveryNoteLoading,
    isError: isDeliveryNoteError,
  } = useGetDeliveryNoteQuery(
    {deliveryNoteId: props.receiveNote?.deliveryNoteId as string},
    {skip: isNilOrEmpty(props.receiveNote)}
  );

  const {
    data: suppliers,
    isLoading: areSuppliersLoading,
    isError: hasSuppliersError,
  } = useGetSuppliersQuery();

  const {
    data: warehouses,
    isLoading: isWarehousesLoading,
    isError: isWarehousesError,
  } = useGetWarehousesQuery();

  const {
    data: paymentTypes,
    isLoading: isPaymentTypesLoading,
    isError: isPaymentTypesError,
  } = useGetPaymentTypeEnumQuery();

  const {
    data: currencies,
    isLoading: isCurrenciesLoading,
    isError: isCurrenciesError,
  } = useGetCurrenciesQuery();

  const {data: tenant, isLoading: isTenantLoading, isError: isTenantError} = useGetTenantQuery();

  const {
    data: authorizationProfiles,
    isLoading: areAuthorizationProfilesLoading,
    isError: hasAuthorizationProfilesError,
  } = useGetAuthorizationProfilesQuery({'x-tenant': tenant?.id ?? ''}, {skip: isNil(tenant)});

  const isDraftReceiveNote = isNilOrEmpty(receiveNoteId);

  const isNewReceiveNoteDetailDataLoaded = !warehouses || !paymentTypes || !currencies || !tenant;

  const isEditReceiveNoteDetailDataLoaded =
    isNewReceiveNoteDetailDataLoaded || !props.receiveNote || !warehouseAccount;

  const isNewReceiveNoteDetailDataLoading =
    isWarehousesLoading ||
    areSuppliersLoading ||
    isPaymentTypesLoading ||
    isCurrenciesLoading ||
    isTenantLoading ||
    props.isReceiveNoteLoading ||
    isDeliveryNoteLoading;

  const isEditReceiveNoteDetailDataLoading =
    isNewReceiveNoteDetailDataLoading ||
    props.isReceiveNoteLoading ||
    isWarehouseAccountLoading ||
    areAuthorizationProfilesLoading;

  const isNewReceiveNoteDetailDataError =
    isWarehousesError ||
    hasSuppliersError ||
    isPaymentTypesError ||
    isCurrenciesError ||
    isTenantError ||
    props.hasReceiveNoteError ||
    isDeliveryNoteError;

  const isEditReceiveNoteDetailDataError =
    isNewReceiveNoteDetailDataError ||
    props.hasReceiveNoteError ||
    isWarehouseAccountError ||
    hasAuthorizationProfilesError;

  const isFromReceiveNote = defaultTo(false, deliveryNote?.isFromReceiveNote);

  if (receiveNoteId ? isEditReceiveNoteDetailDataLoaded : isNewReceiveNoteDetailDataLoaded) {
    return (
      <DataStatus
        isLoading={
          receiveNoteId ? isEditReceiveNoteDetailDataLoading : isNewReceiveNoteDetailDataLoading
        }
        isError={receiveNoteId ? isEditReceiveNoteDetailDataError : isNewReceiveNoteDetailDataError}
        minHeight="100vh"
        data-testid={suffixTestId('status', props)}
      />
    );
  }

  const formatToShortDate = (date?: string) => {
    if (isNil(date)) {
      return EMPTY_PLACEHOLDER;
    }
    return dateTimeFormatter('dateShort', parseDate(date));
  };

  const isReceiveNotePending = props.receiveNote?.state === 'PENDING';

  const supplierByReceiveNote = getSupplierById(suppliers ?? [], props.receiveNote?.supplierId);

  const paymentTypeByReceiveNote = paymentTypes?.find((paymentType) =>
    equals(paymentType.key, props.receiveNote?.paymentType)
  );

  const currencyByWarehouse = currencies?.find((currency) =>
    equals(currency.code, warehouse?.currency)
  );

  const authorizationProfileByReceiveNote = authorizationProfiles?.find((authorizationProfile) =>
    equals(authorizationProfile.id, props.receiveNote?.authorizationProfileId)
  );

  const basicInformationCardActions = buildArray<Action>().when(
    isNotNilOrEmpty(receiveNoteId) && isReadOnly,
    {
      type: 'button',
      variant: 'link',
      leftIcon: 'image/edit',
      title: i18n.t('general.labels.edit'),
      isDisabled: not(isReceiveNotePending),
      onClick: () => setIsReadOnly(false),
    }
  );

  const deliveryNoteAttributes: AttributesRow[] = [
    {
      label: i18n.t('general.labels.number'),
      content: isNotNil(deliveryNote?.deliveryNoteNumber) ? (
        <Link
          title={deliveryNote?.deliveryNoteNumber}
          rightIcon="action/open_in_new"
          size="small"
          onClick={() =>
            window.open(
              composePath(warehouseRoutes.deliveryNoteDetailOverview, {
                params: {id: deliveryNote?.deliveryNoteId},
              }),
              '_blank'
            )
          }
          data-testid={suffixTestId('deliveryNoteLink', props)}
        />
      ) : (
        EMPTY_PLACEHOLDER
      ),
    },
    {
      label: i18n.t('entity.warehouse.labels.warehouse'),
      value: warehouse?.name,
    },
    {
      label: i18n.t('entity.warehouse.labels.supplier'),
      value: supplierByReceiveNote?.name,
    },
    {
      label: i18n.t('entity.warehouse.labels.issuedDate'),
      value: formatToShortDate(deliveryNote?.issuedAt),
    },
    {
      label: i18n.t('entity.warehouse.labels.createdDate'),
      value: formatToShortDate(deliveryNote?.created),
    },
    {
      label: i18n.t('entity.warehouse.labels.createdFromReceiveNote'),
      content: (
        <Flag
          isSubtle
          colorScheme="blue"
          label={isFromReceiveNote ? i18n.t('general.labels.yes') : i18n.t('general.labels.no')}
          size="small"
          data-testid={suffixTestId('isFromReceiveNoteFlag', props)}
        />
      ),
    },
  ];

  const totalPriceWithoutVat = formatCurrency(
    totalPrice?.totalPriceWithoutVat ?? 0,
    warehouse?.currency ?? DEFAULT_CURRENCY,
    2
  );

  const totalPriceWithVat = formatCurrency(
    totalPrice?.totalPriceWithVat ?? 0,
    warehouse?.currency ?? DEFAULT_CURRENCY,
    2
  );

  return (
    <Section data-testid={testIds.warehouse.receiveNoteCreate('wrapper')}>
      <Box>
        <VStack spacing={4}>
          <Card
            title={i18n.t('general.labels.basicInformation')}
            actions={basicInformationCardActions}
            data-testid={suffixTestId('cards.basicInformation', props)}
          >
            {isReadOnly ? (
              <BasicInformationReadOnly
                readOnlyFields={{
                  supplier: supplierByReceiveNote?.name,
                  warehouse: warehouse?.name,
                  authorizationProfile: authorizationProfileByReceiveNote?.name,
                  number: props.receiveNote?.receiveNoteNumber,
                  date: isReceiveNoteCompleted ? props.receiveNote?.updated : null,
                  invoiceNumber: props.receiveNote?.invoiceNumber,
                  invoiceIssueDate: props.receiveNote?.invoiceIssueDate,
                  paymentType: paymentTypeByReceiveNote?.value,
                  currency: currencyByWarehouse?.code,
                  exchangeRate: props.receiveNote?.exchangeRate,
                  ncConversion: props.receiveNote?.ncConversion,
                  note: props.receiveNote?.note,
                }}
              />
            ) : (
              <BasicInformationForm
                receiveNote={props.receiveNote}
                suppliers={suppliers}
                warehouses={warehouses}
                authorizationProfiles={authorizationProfiles}
                paymentTypes={paymentTypes}
                currencies={currencies}
                currencyCodeByWarehouse={currencyByWarehouse?.code}
                onClose={() => setIsReadOnly(true)}
                data-testid={suffixTestId('forms.basicInformation', props)}
              />
            )}
          </Card>

          <Card
            title={i18n.t('entity.warehouse.labels.deliveryNote')}
            data-testid={suffixTestId('cards.deliveryNote', props)}
          >
            <DataStatus isEmpty={isDraftReceiveNote} minHeight={60}>
              <Box height={60}>
                <Card variant="inlineGrey">
                  <Attributes
                    rows={deliveryNoteAttributes}
                    size="quarter"
                    data-testid={suffixTestId('deliveryNoteAttributes', props)}
                  />
                </Card>
              </Box>
            </DataStatus>
          </Card>

          <ReceiveNoteItemList
            receiveNoteId={receiveNoteId}
            receiveNote={props.receiveNote}
            warehouse={warehouse}
            warehouseAccount={warehouseAccount}
            tenant={tenant}
            isDraftReceiveNote={isDraftReceiveNote}
            isReceiveNotePending={isReceiveNotePending}
            isFromReceiveNote={isFromReceiveNote}
            totalPriceWithoutVat={totalPriceWithoutVat}
            totalPriceWithVat={totalPriceWithVat}
            data-testid={suffixTestId('cards.items', props)}
          />
        </VStack>
      </Box>
    </Section>
  );
}
