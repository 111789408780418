import {
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Option,
  Separator,
  showNotification,
} from 'platform/components';
import {Grid, HStack, VStack} from 'platform/foundation';
import {number, object} from 'yup';

import {useEffect, useState} from 'react';

import {isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  GetWarehouseResponse,
  GetWarehousesResponse,
  useLazyGetWarehouseQuery,
} from '@dms/api/metadaWarehouse';
import {BaseSupplier} from '@dms/api/metadaWarehouseSupplier';
import {
  BaseSupplierOrder,
  PatchSupplierOrderRequest,
  usePatchSupplierOrderMutation,
} from '@dms/api/metadaWarehouseSupplierOrder';
import {GetSupplierOrderVariantsResponse} from '@dms/api/metadaWarehouseSupplierOrderVariants';
import {CurrencyResponseBody} from '@dms/api/shared';
import i18n from '@dms/i18n';
import {
  getOptionsFromCurrencies,
  getOptionsFromSuppliers,
  getOptionsFromWarehouses,
  handleApiError,
} from '@dms/shared';

import {Nullish, TestIdProps, suffixTestId} from 'shared';

import {getOptionsFromSupplierOrderVariant} from '../../../../../utils/getOptionsFromSupplierOrderVariant';

export type SupplierOrderForm = {
  warehouseId: string;
  supplierId: string | null;
  orderVariantId: string;
  orderExternalNumber: string;
  referenceNumber: string;
  type: string;
  currency: string;
  exchangeRate: number;
  ncConversion: string;
};

interface BasicInformationFormProps extends TestIdProps {
  supplierOrder: BaseSupplierOrder | Nullish;
  warehouses: GetWarehousesResponse | Nullish;
  suppliers: BaseSupplier[] | Nullish;
  currencies: CurrencyResponseBody[] | Nullish;
  supplierOrderTypes: Option[] | Nullish;
  supplierOrderVariants: GetSupplierOrderVariantsResponse[] | Nullish;
  onSetReadonly: (isReadOnly: boolean) => void;
}

export function BasicInformationForm(props: BasicInformationFormProps) {
  const [selectedWarehouse, setSelectedWarehouse] = useState<GetWarehouseResponse | Nullish>(null);

  const [patchSupplierOrder] = usePatchSupplierOrderMutation();
  const [getWarehouse, {isFetching: isWarehouseFetching}] = useLazyGetWarehouseQuery();

  useEffect(() => {
    if (isNilOrEmpty(props.supplierOrder?.warehouseId)) {
      return;
    }

    getWarehouse({warehouseId: props.supplierOrder?.warehouseId as string})
      .unwrap()
      .then(setSelectedWarehouse)
      .catch(handleApiError);
  }, [props.supplierOrder?.warehouseId, getWarehouse]);

  const defaultValues: Partial<SupplierOrderForm> = {
    warehouseId: props.supplierOrder?.warehouseId,
    supplierId: props.supplierOrder?.supplierId,
    orderVariantId: props.supplierOrder?.orderVariantId,
    orderExternalNumber: props.supplierOrder?.orderExternalNumber,
    referenceNumber: props.supplierOrder?.referenceNumber,
    type: props.supplierOrder?.type,
    currency: props.supplierOrder?.currency,
    exchangeRate: props.supplierOrder?.exchangeRate,
    ncConversion: props.supplierOrder?.ncConversion,
  };

  const handleDiscard = () => {
    props.onSetReadonly(true);
  };

  const handleSubmit: FormSubmitHandler<SupplierOrderForm> = async (data) => {
    const body: PatchSupplierOrderRequest['body'] = {
      warehouseId: data.warehouseId,
      supplierId: data.supplierId,
      orderVariantId: data.orderVariantId,
      orderExternalNumber: data.orderExternalNumber,
      referenceNumber: data.referenceNumber,
      type: data.type,
      currency: data.currency,
      exchangeRate: data.exchangeRate,
      ncConversion: data.ncConversion,
    };

    return await patchSupplierOrder({
      orderId: props.supplierOrder?.orderId ?? '',
      body,
    })
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('page.warehouse.notification.supplierOrderUpdated'));
        props.onSetReadonly(true);
      })
      .catch(handleApiError);
  };

  const getOptionsFromSuppliersByWarehouse = (suppliers: BaseSupplier[] | Nullish) => {
    if (isNilOrEmpty(suppliers)) {
      return [];
    }

    const filteredSuppliers = suppliers?.filter((supplier) => {
      const warehouseSuppliers = selectedWarehouse?.suppliers?.map(
        (warehouseSupplier) => warehouseSupplier?.id
      );
      return warehouseSuppliers?.includes(supplier.id);
    });

    return getOptionsFromSuppliers(filteredSuppliers);
  };

  return (
    <Form<SupplierOrderForm>
      schema={formSchema}
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
    >
      {(control, formApi) => {
        const warehouseId = formApi.watch('warehouseId');

        const handleWarehouseChange = (newWarehouseId: string | string[] | number | null) => {
          if (isNilOrEmpty(newWarehouseId)) {
            setSelectedWarehouse(null);
            formApi.setValue('supplierId', null);
            formApi.trigger('supplierId');
            return;
          }

          getWarehouse({warehouseId: newWarehouseId as string})
            .unwrap()
            .then((warehouse) => {
              setSelectedWarehouse(warehouse);
              formApi.setValue('supplierId', warehouse.defaultSupplierId);
              formApi.trigger('supplierId');
            })
            .catch(handleApiError);
        };

        return (
          <>
            <VStack spacing={4}>
              <Grid columns={4}>
                <FormField
                  control={control}
                  onChange={handleWarehouseChange}
                  type="choice"
                  name="warehouseId"
                  label={i18n.t('entity.warehouse.labels.warehouse')}
                  options={getOptionsFromWarehouses(props.warehouses)}
                  data-testid={suffixTestId('inputs.warehouseId', props)}
                />
                <FormField
                  isDisabled
                  control={control}
                  type="choice"
                  name="orderVariantId"
                  label={i18n.t('entity.warehouse.labels.supplierOrderVariant')}
                  options={getOptionsFromSupplierOrderVariant(props.supplierOrderVariants)}
                  data-testid={suffixTestId('inputs.orderVariantId', props)}
                />
                <FormField
                  control={control}
                  type="choice"
                  name="supplierId"
                  label={i18n.t('entity.warehouse.labels.supplier')}
                  isDisabled={isNil(warehouseId) || isWarehouseFetching}
                  isLoading={isWarehouseFetching}
                  options={getOptionsFromSuppliersByWarehouse(props.suppliers)}
                  data-testid={suffixTestId('inputs.supplierId', props)}
                />
                <FormField
                  control={control}
                  type="choice"
                  name="type"
                  label={i18n.t('entity.warehouse.labels.supplierOrderType')}
                  options={props.supplierOrderTypes || []}
                  data-testid={suffixTestId('inputs.type', props)}
                />
              </Grid>
              <Grid columns={4}>
                <FormField
                  control={control}
                  type="choice"
                  name="currency"
                  label={i18n.t('entity.warehouse.labels.currency')}
                  options={getOptionsFromCurrencies(props.currencies)}
                  data-testid={suffixTestId('inputs.currency', props)}
                />
                <FormField
                  control={control}
                  type="number"
                  name="exchangeRate"
                  label={i18n.t('entity.warehouse.labels.exchangeRate')}
                  data-testid={suffixTestId('inputs.exchangeRate', props)}
                />
                <FormField
                  control={control}
                  type="choice"
                  name="ncConversion"
                  label={i18n.t('entity.warehouse.labels.ncConversion')}
                  options={[]}
                  isDisabled
                  data-testid={suffixTestId('inputs.ncConversion', props)}
                />
              </Grid>
            </VStack>
            <Separator />
            <HStack justify="flex-end">
              <ButtonGroup>
                <FormButton
                  control={control}
                  type="reset"
                  variant="secondary"
                  title={i18n.t('general.labels.discard')}
                  onClick={handleDiscard}
                  data-testid={suffixTestId('actions.discard', props)}
                />
                <FormButton
                  control={control}
                  type="submit"
                  variant="primary"
                  title={i18n.t('general.labels.saveChanges')}
                  data-testid={suffixTestId('actions.submit', props)}
                />
              </ButtonGroup>
            </HStack>
          </>
        );
      }}
    </Form>
  );
}

const formSchema = object({
  exchangeRate: number().min(0),
});
