import {Integer} from 'platform/foundation';

const MAX_DEPTH = 4;
const PADDING_PER_DEPTH = 4;

export function getMenuItemDepthPadding(depth: Integer): Integer {
  if (depth > MAX_DEPTH) {
    return 16;
  }
  return (depth * PADDING_PER_DEPTH) as Integer;
}
