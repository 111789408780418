import {
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Separator,
  showNotification,
} from 'platform/components';
import {Grid, GridItem, VStack} from 'platform/foundation';
import {z} from 'zod';

import {
  useGetRentalPriceGroupQuery,
  useUpdateRentalPriceGroupMutation,
} from '@dms/api/rentalVehicles';
import {VatType, VatTypeEnum} from '@dms/api/shared';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError, useVatRatesOptions} from '@dms/shared';

import {useNavigate, useRequiredParams} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {PriceList} from './components/PriceList';

export type FormValues = {
  name: string;
  vatType: VatType;
};

export function PriceGroupsDetail() {
  const navigate = useNavigate();
  const [vatTypeOptions] = useVatRatesOptions();

  const {id: rentalPriceGroupId} = useRequiredParams();

  const {data: priceGroup, isLoading, isError} = useGetRentalPriceGroupQuery({rentalPriceGroupId});
  const [updatePriceGroup] = useUpdateRentalPriceGroupMutation();

  const handleSubmit: FormSubmitHandler<FormValues> = async (values) =>
    await updatePriceGroup({
      rentalPriceGroupId,
      updateRentalPriceGroupRequestBody: values,
    })
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
      })
      .catch(handleApiError);

  return (
    <SettingsTemplate
      header={{
        title: priceGroup?.name,
        breadcrumbs: [
          {label: i18n.t('entity.rental.labels.priceGroups'), href: settingsRoutes.priceGroupList},
        ],
      }}
      data-testid={testIds.settings.priceGroupList('page')}
      isLoading={isLoading}
      isError={isError}
    >
      <Form<FormValues>
        onSubmit={handleSubmit}
        defaultValues={{name: priceGroup?.name, vatType: priceGroup?.vatType}}
        experimentalZodSchema={schema}
      >
        {(control) => (
          <VStack spacing={6}>
            <SettingsSection>
              <Grid columns={2} align="flex-end">
                <FormField
                  control={control}
                  name="name"
                  type="text"
                  isRequired
                  label={i18n.t('general.labels.name')}
                />

                <FormField
                  control={control}
                  name="vatType"
                  type="choice"
                  isRequired
                  label={i18n.t('general.labels.vat')}
                  options={vatTypeOptions}
                  isNotClearable
                />

                <GridItem span={2}>
                  <FormButton
                    control={control}
                    type="submit"
                    title={i18n.t('general.labels.edit')}
                  />
                </GridItem>
              </Grid>
            </SettingsSection>

            <Separator spacing={0} />

            <PriceList items={priceGroup?.items ?? []} rentalPriceGroupId={rentalPriceGroupId} />

            <SettingsFooter
              actions={[
                {
                  type: 'button',
                  title: i18n.t('general.actions.close'),
                  onClick: () => navigate(settingsRoutes.priceGroupList),
                  variant: 'secondary',
                  'data-testid': testIds.settings.priceGroupDetail('close'),
                },
              ]}
            />
          </VStack>
        )}
      </Form>
    </SettingsTemplate>
  );
}

const schema = z.object({
  name: z.string().nonempty(),
  vatType: z.enum([VatTypeEnum.S, VatTypeEnum.Z, VatTypeEnum.R, VatTypeEnum.MR, VatTypeEnum.E]),
});
