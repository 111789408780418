import {ColorSchemeType, Flag, TableRow} from 'platform/components';
import {Box, Text, ThemeColorPath, ThemeColorTextPath} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import {TemplateResponseBodyV2} from '@dms/api/document';
import i18n from '@dms/i18n';
import {EMPTY_PLACEHOLDER} from '@dms/shared';

import {parseDate} from 'shared';

import {useTemplateActions} from '../hooks/useTemplateActions';
import {formatUpdatedByName} from '../utils/formatUpdatedByName';

interface TemplateTableRowProps {
  template: TemplateResponseBodyV2;
}

export function TemplateTableRow(props: TemplateTableRowProps) {
  const formatDateTime = useDateTimeFormatter();

  const isPrimary = props.template.primary;
  const isHidden = props.template.hidden;
  const updatedAt = props.template.updatedAt
    ? formatDateTime('dateShort', parseDate(props.template.updatedAt))
    : EMPTY_PLACEHOLDER;

  const actions = useTemplateActions(props.template);

  const color = match<[boolean, boolean], ThemeColorPath>([isPrimary, isHidden])
    .with([true, false], always('palettes.green.10.100'))
    .with([false, true], always('palettes.neutral.20.100'))
    .otherwise(always('general.transparent'));

  const hoverColor = match<[boolean, boolean], ThemeColorPath>([isPrimary, isHidden])
    .with([true, false], always('palettes.green.20.100'))
    .with([false, true], always('palettes.neutral.40.100'))
    .otherwise(always('palettes.neutral.20.100'));

  const flagColorScheme = match<[boolean, boolean], ColorSchemeType>([isPrimary, isHidden])
    .with([true, false], always('green'))
    .otherwise(always('neutral'));

  const flagLabel = match<[boolean, boolean], string>([isPrimary, isHidden])
    .with([true, false], always(i18n.t('entity.document.labels.primary')))
    .with([false, true], always(i18n.t('entity.document.labels.hidden')))
    .otherwise(always(i18n.t('entity.document.labels.alternative')));

  const textColor = match<boolean, ThemeColorTextPath>(isHidden)
    .with(true, always('tertiary'))
    .otherwise(always('primary'));

  return (
    <TableRow
      key={props.template.id}
      color={color}
      hoverColor={hoverColor}
      actions={{secondary: actions}}
      actionsWidth="1%"
    >
      <Box paddingVertical={2}>
        <Flag colorScheme={flagColorScheme} isSubtle={!isPrimary && !isHidden} label={flagLabel} />
      </Box>

      <Text size="small" alternative color={textColor}>
        {props.template.fileName}
      </Text>
      <Text size="small" color={textColor}>
        {props.template.title}
      </Text>
      <Text size="small" color={textColor}>
        {updatedAt}
      </Text>
      <Text size="small" color={textColor}>
        {formatUpdatedByName(props.template.updatedBy)}
      </Text>
      <Text size="small" color={textColor}>
        {props.template.note}
      </Text>
    </TableRow>
  );
}
