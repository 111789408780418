import {z} from 'zod';

import {
  PriceWithAndWithoutVatSchema,
  BaseVoidResponseSchema,
  CreatedAtAndUpdatedBySchema,
} from '@dms/api/shared';

/**
 * GET
 */
export const GetServiceOrderReturnRequestSchema = z.object({
  serviceOrderReturnId: z.string(),
});
export type GetServiceOrderReturnRequest = z.infer<typeof GetServiceOrderReturnRequestSchema>;

export const GetServiceOrderReturnResponseSchema = z.object({
  serviceOrderReturn: z
    .object({
      serviceOrderReturnId: z.string(),
      state: z.enum(['PENDING', 'COMPLETED']),
      number: z.string(),
      serviceOrderId: z.string(),
      serviceCaseId: z.string(),
      returnedAt: z.string().nullish(),
      returnedBy: z.string().nullish(),
    })
    .merge(CreatedAtAndUpdatedBySchema),
  sparePartsTotalPrice: z.object({
    totalSellingPrice: PriceWithAndWithoutVatSchema,
    totalPurchasePrice: PriceWithAndWithoutVatSchema,
  }),
  serviceOrder: z.object({
    customerId: z.string(),
    vehicleId: z.string(),
    number: z.string(),
    type: z.string(),
    variant: z.string(),
    serviceOrderVariantId: z.string(),
    authorizationProfileId: z.string(),
  }),
});
export type GetServiceOrderReturnResponse = z.infer<typeof GetServiceOrderReturnResponseSchema>;

/**
 * POST
 */
export const PostServiceOrderReturnRequestSchema = z.object({
  body: z.object({
    serviceOrderId: z.string(),
  }),
});
export type PostServiceOrderReturnRequest = z.infer<typeof PostServiceOrderReturnRequestSchema>;

export const PostServiceOrderReturnResponseSchema = z.object({
  id: z.string(),
});
export type PostServiceOrderReturnResponse = z.infer<typeof PostServiceOrderReturnResponseSchema>;

/**
 * PATCH
 */

/**
 * DELETE
 */
export const DeleteServiceOrderReturnRequestSchema = z.object({
  body: z.object({
    serviceOrderReturnId: z.array(z.string()),
  }),
});
export type DeleteServiceOrderReturnRequest = z.infer<typeof DeleteServiceOrderReturnRequestSchema>;

export const DeleteServiceOrderReturnResponseSchema = BaseVoidResponseSchema;
export type DeleteServiceOrderReturnResponse = z.infer<
  typeof DeleteServiceOrderReturnResponseSchema
>;

/**
 * PUT
 */
