import {match} from 'ts-pattern';

import {always, isEmpty} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {EntityResourceIds} from '@dms/api/shared';
import {
  businessCaseRoutes,
  customerRoutes,
  interestRoutes,
  vehiclesRoutes,
  workshopRoutes,
} from '@dms/routes';

import {composePath} from 'shared';

export const composeEntityPath = (resourceId: string, recordId: string) => {
  if (isEmpty(resourceId) || isNilOrEmpty(recordId)) {
    return undefined;
  }

  const targetPath = match(resourceId)
    .with(
      EntityResourceIds.vehicle,
      always(
        composePath(vehiclesRoutes.detail, {params: {id: recordId}, isSearchParamsPreserved: false})
      )
    )
    .with(
      EntityResourceIds.customer,
      always(
        composePath(customerRoutes.detail, {params: {id: recordId}, isSearchParamsPreserved: false})
      )
    )
    .with(
      EntityResourceIds.businessCase,
      always(
        composePath(businessCaseRoutes.overview, {
          params: {id: recordId},
          isSearchParamsPreserved: false,
        })
      )
    )
    .with(
      EntityResourceIds.interest,
      always(
        composePath(interestRoutes.detail, {
          params: {id: recordId},
          isSearchParamsPreserved: false,
        })
      )
    )
    .with(
      EntityResourceIds.serviceCase,
      always(
        composePath(workshopRoutes.serviceCaseDetail, {
          params: {id: recordId},
          isSearchParamsPreserved: false,
        })
      )
    )
    .otherwise(always(undefined));

  return targetPath;
};
