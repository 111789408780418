import {PlatformCode} from '@dms/api/shared';

import type {Advertisement} from '../types/Advertisement';
import type {CancelablePromise} from '../types/CancelablePromise';
import type {Platform} from '../types/Platform';
import {request as __request} from '../utils/request';

export class PublishingService {
  /**
   * @returns Advertisement
   * @throws ApiError
   */
  public static getAdvertisementList({
    vehicleId,
    authorization,
  }: {
    vehicleId: string;
    authorization?: string;
  }): CancelablePromise<Array<Advertisement>> {
    return __request({
      method: 'GET',
      url: '/dms/v2/sales/publishing/vehicle/{vehicleId}/advertisements',
      path: {
        vehicleId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static repeatLastAction({
    vehicleId,
    platformCode,
    authorization,
  }: {
    vehicleId: string;
    platformCode: PlatformCode;
    authorization?: string;
  }): CancelablePromise<void> {
    return __request({
      method: 'PUT',
      url: '/dms/v2/sales/publishing/vehicle/{vehicleId}/platform/{platformCode}/repeat',
      path: {
        vehicleId,
        platformCode,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static disablePlatformForVehicle({
    vehicleId,
    platformCode,
    authorization,
  }: {
    vehicleId: string;
    platformCode: PlatformCode;
    authorization?: string;
  }): CancelablePromise<void> {
    return __request({
      method: 'PUT',
      url: '/dms/v2/sales/publishing/vehicle/{vehicleId}/platforms/{platformCode}/disable',
      path: {
        vehicleId,
        platformCode,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static enablePlatformForVehicle({
    vehicleId,
    platformCode,
    authorization,
  }: {
    vehicleId: string;
    platformCode: PlatformCode;
    authorization?: string;
  }): CancelablePromise<void> {
    return __request({
      method: 'PUT',
      url: '/dms/v2/sales/publishing/vehicle/{vehicleId}/platforms/{platformCode}/enable',
      path: {
        vehicleId,
        platformCode,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static updateVehicleOnAllPlatforms({
    vehicleId,
    authorization,
  }: {
    vehicleId: string;
    authorization?: string;
  }): CancelablePromise<void> {
    return __request({
      method: 'PUT',
      url: '/dms/v2/sales/publishing/vehicle/{vehicleId}/updateOnAllPlatforms',
      path: {
        vehicleId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static updateVehicleOnPlatform({
    vehicleId,
    platformCode,
    authorization,
  }: {
    vehicleId: string;
    platformCode: PlatformCode;
    authorization?: string;
  }): CancelablePromise<void> {
    return __request({
      method: 'PUT',
      url: '/dms/v2/sales/publishing/vehicle/{vehicleId}/platform/{platformCode}/update',
      path: {
        vehicleId,
        platformCode,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }

  /**
   * @returns Platform
   * @throws ApiError
   */
  public static getList({
    branchId,
    authorization,
  }: {
    branchId?: string;
    authorization?: string;
  }): CancelablePromise<Array<Platform>> {
    return __request({
      method: 'GET',
      url: '/dms/v1/sales/publishing/platform',
      query: {
        branchId,
      },
      headers: {
        Authorization: authorization,
      },
    });
  }
}
