import {AlertProps} from 'platform/components';

import {useMemo} from 'react';

import {isNil} from 'ramda';

import {
  useGetDocumentRestrictionQuery,
  useTaxDocumentCalculationV2Mutation,
} from '@dms/api/accounting';
import {CashRegisterDocumentTypeEnum} from '@dms/api/shared';
import i18n from '@dms/i18n';
import {useGetCurrentBranch} from '@dms/shared';

import {TAX_DOCUMENT_CALCULATION_FIXED_CACHE_KEY} from '../constants';

export const useDocumentAlertProps = (
  documentType: CashRegisterDocumentTypeEnum,
  currency?: string
) => {
  const [_, {data: summary}] = useTaxDocumentCalculationV2Mutation({
    fixedCacheKey: TAX_DOCUMENT_CALCULATION_FIXED_CACHE_KEY,
  });
  const {activeBranchId} = useGetCurrentBranch();

  const {data: limitDefinition} = useGetDocumentRestrictionQuery({
    branchId: activeBranchId,
    documentType,
    currency,
  });

  const alertProps: AlertProps | null = useMemo(() => {
    // return null if we don't have summary or limit definition
    if (isNil(limitDefinition) || isNil(summary?.calculation?.priceWithVat?.amount)) {
      return null;
    }

    // return null if price doesn't exceed limit
    if (
      parseFloat(limitDefinition.amount.amount) >
      parseFloat(summary?.calculation?.priceWithVat?.amount ?? '0')
    ) {
      return null;
    }

    // return null if type is set to off (in omnetic settings)
    if (limitDefinition.type === 'off') {
      return null;
    }

    const isNotice = limitDefinition.type === 'notice';

    return isNotice
      ? {
          title: i18n.t('entity.cashRegister.notifications.limitExceededWarningTitle'),
          variant: 'warning',
        }
      : {
          title: i18n.t('entity.cashRegister.notifications.limitExceededErrorTitle'),
          message: i18n.t('entity.cashRegister.notifications.limitExceededErrorSubtitle'),
          variant: 'error',
        };
  }, [limitDefinition, summary?.calculation?.priceWithVat?.amount]);

  return [alertProps] as const;
};
