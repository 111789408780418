import {omneticApi} from '@dms/api/core';

import {
  AssignPairedDeviceApiResponse,
  AssignPairedDeviceApiArg,
  GetConfigurationProfileApiResponse,
  GetConfigurationProfileApiArg,
  GetPairedDeviceQrCodeApiResponse,
  GetPairedDeviceQrCodeApiArg,
  GetPairedDevicesListApiResponse,
  GetPairedDevicesListApiArg,
  PairDeviceApiResponse,
  PairDeviceApiArg,
  GetSignableDocumentApiResponse,
  GetSignableDocumentApiArg,
  GetSignableDocumentTransactionApiResponse,
  GetSignableDocumentTransactionApiArg,
  GetTransactionApiResponse,
  GetTransactionApiArg,
  KillTransactionApiResponse,
  KillTransactionApiArg,
  GetTransactionQrCodeApiResponse,
  GetTransactionQrCodeApiArg,
  GetUserPairedDevicesListApiResponse,
  GetUserPairedDevicesListApiArg,
  RefreshEsignoTransactionApiResponse,
  RefreshEsignoTransactionApiArg,
  SignDocumentLocallyApiResponse,
  SignDocumentLocallyApiArg,
  SignDocumentRemotelyApiResponse,
  SignDocumentRemotelyApiArg,
  UnpairDeviceApiResponse,
  UnpairDeviceApiArg,
  UpdatePairedDeviceApiResponse,
  UpdatePairedDeviceApiArg,
  GetSignableDocumentListApiResponse,
  GetSignableDocumentListApiArg,
} from './types';

export const digitalSignatureApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    assignPairedDevice: build.mutation<AssignPairedDeviceApiResponse, AssignPairedDeviceApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/digital-signature/device/${queryArg.pairedDeviceId}/branch`,
        method: 'PUT',
        body: queryArg.assignPairedDeviceRequestBody,
      }),
      invalidatesTags: ['deviceList'],
    }),
    getConfigurationProfile: build.query<
      GetConfigurationProfileApiResponse,
      GetConfigurationProfileApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/digital-signature/configuration-profile/${queryArg.configurationProfileId}`,
      }),
    }),
    getPairedDeviceQrCode: build.query<
      GetPairedDeviceQrCodeApiResponse,
      GetPairedDeviceQrCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/digital-signature/device/${queryArg.pairedDeviceId}/qr-code`,
        responseHandler: async (response) => {
          const blob = await response.blob();
          return URL.createObjectURL(blob);
        },
      }),
    }),
    getPairedDevicesList: build.query<GetPairedDevicesListApiResponse, GetPairedDevicesListApiArg>({
      query: () => ({
        url: `/dms/v1/digital-signature/device`,
      }),
      providesTags: ['deviceList'],
    }),
    pairDevice: build.mutation<PairDeviceApiResponse, PairDeviceApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/digital-signature/device`,
        method: 'POST',
        body: queryArg.pairDeviceRequestBody,
      }),
      invalidatesTags: ['deviceList'],
    }),
    getSignableDocument: build.query<GetSignableDocumentApiResponse, GetSignableDocumentApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/digital-signature/signable-document/${queryArg.fileId}`,
      }),
      providesTags: ['signDocumentTransaction'],
    }),
    getSignableDocumentTransaction: build.query<
      GetSignableDocumentTransactionApiResponse,
      GetSignableDocumentTransactionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/digital-signature/signable-document/${queryArg.fileId}/transaction`,
      }),
      providesTags: ['signDocumentTransaction'],
    }),
    getTransaction: build.query<GetTransactionApiResponse, GetTransactionApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/digital-signature/transaction/${queryArg.transactionId}`,
      }),
      providesTags: ['transaction'],
    }),
    killTransaction: build.mutation<KillTransactionApiResponse, KillTransactionApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/digital-signature/transaction/${queryArg.transactionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['transaction'],
    }),
    getTransactionQrCode: build.query<GetTransactionQrCodeApiResponse, GetTransactionQrCodeApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/digital-signature/transaction/${queryArg.transactionId}/qr-code`,
        responseHandler: async (response) => {
          const blob = await response.blob();
          return URL.createObjectURL(blob);
        },
      }),
    }),
    getUserPairedDevicesList: build.query<
      GetUserPairedDevicesListApiResponse,
      GetUserPairedDevicesListApiArg
    >({
      query: () => ({
        url: `/dms/v1/digital-signature/user-device`,
      }),
      providesTags: ['deviceList'],
    }),
    refreshEsignoTransaction: build.query<
      RefreshEsignoTransactionApiResponse,
      RefreshEsignoTransactionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/digital-signature/transaction/${queryArg.transactionId}/refresh`,
      }),
    }),
    signDocumentLocally: build.mutation<SignDocumentLocallyApiResponse, SignDocumentLocallyApiArg>({
      query: (body) => ({
        url: `/dms/v1/digital-signature/sign-locally`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['signDocumentTransaction'],
    }),
    getSignableDocumentList: build.query<
      GetSignableDocumentListApiResponse,
      GetSignableDocumentListApiArg
    >({
      query: (body) => ({
        url: `/dms/v1/digital-signature/signable-document-list`,
        method: 'POST',
        body,
      }),
    }),
    signDocumentRemotely: build.mutation<
      SignDocumentRemotelyApiResponse,
      SignDocumentRemotelyApiArg
    >({
      query: (body) => ({
        url: `/dms/v1/digital-signature/sign-remotely`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['signDocumentTransaction'],
    }),
    unpairDevice: build.mutation<UnpairDeviceApiResponse, UnpairDeviceApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/digital-signature/device/${queryArg.pairedDeviceId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['deviceList'],
    }),
    updatePairedDevice: build.mutation<UpdatePairedDeviceApiResponse, UpdatePairedDeviceApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/digital-signature/device/${queryArg.pairedDeviceId}`,
        method: 'PUT',
        body: queryArg.updatePairedDeviceRequestBody,
      }),
      invalidatesTags: ['deviceList'],
    }),
  }),
});

export const {
  useGetSignableDocumentListQuery,
  useUpdatePairedDeviceMutation,
  useAssignPairedDeviceMutation,
  useGetConfigurationProfileQuery,
  useGetPairedDeviceQrCodeQuery,
  useGetPairedDevicesListQuery,
  usePairDeviceMutation,
  useGetSignableDocumentQuery,
  useGetSignableDocumentTransactionQuery,
  useGetTransactionQuery,
  useKillTransactionMutation,
  useGetTransactionQrCodeQuery,
  useGetUserPairedDevicesListQuery,
  useRefreshEsignoTransactionQuery,
  useSignDocumentLocallyMutation,
  useSignDocumentRemotelyMutation,
  useUnpairDeviceMutation,
} = digitalSignatureApi;
