import {match} from 'ts-pattern';

import {always} from 'ramda';

import {OpportunityType} from '../types/OpportunityType';

export const getOrder = (opportunityType: OpportunityType) => {
  const columnKey = match(opportunityType)
    .with('newInterests', 'activeInterests', always('createdAt'))
    .with('inBuying', 'inSale', 'warehouseTotal', always('createdAt'))
    .otherwise(always('businessCaseCreatedAt'));

  return {
    columnKey,
    order: 'DESC',
  } as const;
};
