export function SuccessImage() {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M73.3332 40C73.3332 21.5905 58.4092 6.66666 39.9998 6.66666C21.5903 6.66666 6.6665 21.5905 6.6665 40C6.6665 58.4093 21.5903 73.3333 39.9998 73.3333C58.4092 73.3333 73.3332 58.4093 73.3332 40Z"
        fill="#DEFBE6"
        stroke="#24A148"
        strokeWidth="2"
        style={{
          fillOpacity: 0,
          strokeDasharray: 210,
          strokeDashoffset: 210,
          animation: 'draw 1s ease forwards, fadeInFill 2s ease forwards',
        }}
      />
      <path
        d="M26.6665 41.6667C29.9209 44.921 34.9998 50 34.9998 50L53.3332 30"
        stroke="#24A148"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{
          strokeDasharray: 100,
          strokeDashoffset: 100,
          animation: 'draw 1s ease forwards 0.5s',
        }}
      />
      <style>
        {`
          @keyframes draw {
            to {
              stroke-dashoffset: 0;
            }
          }
          @keyframes fadeInFill {
            to {
              fill-opacity: 1;
            }
          }
        `}
      </style>
    </svg>
  );
}
