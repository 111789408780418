import styled from 'styled-components';

export const MenuAction = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 0;
  height: ${({theme}) => theme.getSize(9)};
  white-space: nowrap;
  padding: ${({theme}) => `0 ${theme.getSize(2)}`};
  border-radius: ${({theme}) => theme.getSize(1)};
  color: ${({theme}) => theme.colors.text.white};
  &:hover {
    background-color: ${(props) => props.theme.colors.palettes.neutral[500]['100']};
  }
`;
