import {Module} from '@dms/api/shared';
import {moduleKeys} from '@dms/config';
import featureFlags from '@dms/feature-flags';
import {importRoutes} from '@dms/routes';

import {Detail} from './pages/detail/Detail';
import {ImportsOverview} from './pages/overview/ImportsOverview';

export const ImportModule: Module = {
  id: moduleKeys.import,
  layoutProps: {
    title: 'module.import.title',
    icon: 'sidebar/import',
    iconV2: 'sidebar/import',
  },
  requiredFeatureFlag: featureFlags.IMPORT_MODULE,
  routerProps: {
    path: importRoutes.overview,
    element: <ImportsOverview />,
  },
  content: [
    {
      id: 'import-detail',
      routerProps: {
        path: importRoutes.detail,
        element: <Detail />,
      },
    },
  ],
};
