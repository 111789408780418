import {omneticApi} from '@dms/api/core';
import {
  GetParticipationApiResponse,
  GetParticipationApiArg,
  DefaultAssignmentApiArg,
  TransferOwnershipToUserV2ApiResponse,
  TransferOwnershipToUserV2ApiArg,
  AssignUserV2ApiArg,
  AssignUserV2ApiResponse,
  UnassignUserV2ApiArg,
  UnassignUserV2ApiResponse,
} from '@dms/api/shared';

export const participationApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getParticipation: build.query<GetParticipationApiResponse, GetParticipationApiArg>({
      query: ({recordId, resourceId}) => ({
        method: 'GET',
        url: `/dms/v1/participation/${resourceId}/${recordId}`,
      }),
      providesTags: ['participation'],
    }),
    transferOwnership: build.mutation<void, DefaultAssignmentApiArg>({
      query: ({participationId, userId}) => ({
        method: 'Post',
        url: `/dms/v1/participation/${participationId}/owner/user/${userId}`,
      }),
      invalidatesTags: ['participation'],
    }),
    assignUser: build.mutation<void, DefaultAssignmentApiArg>({
      query: ({participationId, userId}) => ({
        method: 'Post',
        url: `/dms/v1/participation/${participationId}/assignee/user/${userId}`,
      }),
      invalidatesTags: ['participation'],
    }),
    unassignUser: build.mutation<void, DefaultAssignmentApiArg>({
      query: ({participationId, userId}) => ({
        method: 'Delete',
        url: `/dms/v1/participation/${participationId}/assignee/user/${userId}`,
      }),
      invalidatesTags: ['participation'],
    }),
    transferOwnershipToUserV2: build.mutation<
      TransferOwnershipToUserV2ApiResponse,
      TransferOwnershipToUserV2ApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/participation/${queryArg.resourceId}/${queryArg.recordId}/owner/user/${queryArg.userIdToTransferOwnership}`,
        method: 'POST',
      }),
      invalidatesTags: ['participation'],
    }),
    assignUserV2: build.mutation<AssignUserV2ApiResponse, AssignUserV2ApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/participation/${queryArg.resourceId}/${queryArg.recordId}/assignee/user/${queryArg.userIdToAssign}`,
        method: 'POST',
      }),
      invalidatesTags: ['participation'],
    }),
    unassignUserV2: build.mutation<UnassignUserV2ApiResponse, UnassignUserV2ApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/participation/${queryArg.resourceId}/${queryArg.recordId}/assignee/user/${queryArg.userIdToUnassign}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['participation'],
    }),
  }),
});

export const {
  useGetParticipationQuery,
  useLazyGetParticipationQuery,
  useAssignUserMutation,
  useTransferOwnershipMutation,
  useUnassignUserMutation,
  useTransferOwnershipToUserV2Mutation,
  useAssignUserV2Mutation,
  useUnassignUserV2Mutation,
} = participationApi;
