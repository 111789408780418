import {
  Card,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Right, VStack} from 'platform/foundation';
import {boolean, object} from 'yup';

import {
  useGetSAutoDefaultSettingsQuery,
  useSetSAutoDefaultSettingsMutation,
} from '@dms/api/salesPublishing';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {useBranchId} from '../../../../hooks/useBranchId';

type PlatformSAutoManufacturedOnFormData = {
  sAutoManufacturedOnHidden: boolean;
};

export function PlatformSAutoManufacturedOn() {
  const branchId = useBranchId();
  const {
    data: defaultSettings,
    isLoading,
    isError,
  } = useGetSAutoDefaultSettingsQuery({
    branchId,
  });
  const [saveSettings] = useSetSAutoDefaultSettingsMutation();

  const onSubmit: FormSubmitHandler<PlatformSAutoManufacturedOnFormData> = (values) =>
    saveSettings({...values, branchId})
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

  return (
    <Card title={i18n.t('general.labels.defaultSettings')}>
      <DataStatus isLoading={isLoading} isError={isError}>
        <Form<PlatformSAutoManufacturedOnFormData>
          defaultValues={defaultSettings}
          schema={schema}
          onSubmit={onSubmit}
        >
          {(control) => (
            <VStack spacing={4}>
              <FormField
                control={control}
                name="sAutoManufacturedOnHidden"
                type="switch"
                label={i18n.t('page.settings.labels.sAutoManufacturedOnHidden')}
              />
              <Right>
                <FormButton
                  control={control}
                  type="submit"
                  title={i18n.t('general.actions.save')}
                />
              </Right>
            </VStack>
          )}
        </Form>
      </DataStatus>
    </Card>
  );
}

const schema = object().shape({
  sAutoManufacturedOnHidden: boolean().required(),
});
