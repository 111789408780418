import {FormControl, FormField} from 'platform/components';
import {Grid, GridItem, Heading, VStack} from 'platform/foundation';

import i18n from '@dms/i18n';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {SupplierOrderItemForm} from '../types/SupplierOrderItemForm';

interface RequestDetailFormProps extends RequiredTestIdProps {
  control: FormControl<SupplierOrderItemForm>;
  handlingUnit: string | Nullish;
  currency: string;
}

export function RequestDetailForm(props: RequestDetailFormProps) {
  return (
    <VStack spacing={4} data-testid={props['data-testid']}>
      <Heading size={5}>{i18n.t('entity.warehouse.labels.requestDetail')}</Heading>
      <Grid columns={4}>
        <GridItem span={1}>
          <FormField
            control={props.control}
            type="number"
            name="requestDetail.warehouseRequestedQuantity"
            label={i18n.t('entity.warehouse.labels.warehouseRequestedQuantity')}
            decimalPlaces={4}
            suffix={props.handlingUnit}
            data-testid={suffixTestId('inputs.warehouseRequestedQuantity', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            control={props.control}
            type="number"
            name="requestDetail.warehouseUnitPurchasePrice"
            label={i18n.t('entity.warehouse.labels.warehouseUnitPurchasePrice')}
            decimalPlaces={2}
            suffix={props.currency}
            isDisabled
            data-testid={suffixTestId('inputs.warehouseUnitPurchasePrice', props)}
          />
        </GridItem>
      </Grid>
    </VStack>
  );
}
