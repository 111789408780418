import {DataStatus} from 'platform/components';

import {useGetRentalVehicleQuery} from '@dms/api/rentalVehicles';
import {useGetAdditionalNoteQuery, useGetVehicleV2Query} from '@dms/api/vehicle';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {RentalVehicleFormLogic} from './components/RentalVehicleFormLogic';
import {RentalVehicleFormMode} from './types/RentalVehicleFormMode';
import {RentalVehicleFormType} from './types/RentalVehicleFormType';
import {convertVehicleToFormData} from './utils/convertVehicleToFormData';

interface RentalVehicleFormProps extends RequiredTestIdProps {
  mode: RentalVehicleFormMode;
  vehicleId?: string;
  extendedDefaultFormData?: Partial<RentalVehicleFormType>;
  onSuccess: (newVehicleId: string) => void;
  onDiscard: () => void;
}

export function RentalVehicleForm(props: RentalVehicleFormProps) {
  const skipFetchingVehicleDetail = props.mode === RentalVehicleFormMode.NEW;

  const vehicleDetailQuery = useGetVehicleV2Query(
    {vehicleId: props.vehicleId || ''},
    {skip: skipFetchingVehicleDetail, refetchOnMountOrArgChange: true}
  );

  const rentalVehicleDetailQuery = useGetRentalVehicleQuery(
    {vehicleId: props.vehicleId || ''},
    {skip: skipFetchingVehicleDetail, refetchOnMountOrArgChange: true}
  );

  const additionalNoteQuery = useGetAdditionalNoteQuery(
    {vehicleId: props.vehicleId || ''},
    {skip: skipFetchingVehicleDetail, refetchOnMountOrArgChange: true}
  );

  const isLoading =
    vehicleDetailQuery.isLoading ||
    vehicleDetailQuery.isFetching ||
    additionalNoteQuery.isLoading ||
    additionalNoteQuery.isFetching ||
    rentalVehicleDetailQuery.isLoading ||
    rentalVehicleDetailQuery.isFetching;

  const isError = vehicleDetailQuery.isError || rentalVehicleDetailQuery.isError; // we don't need note to success. If it's empty BE returns 404 anyway

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <RentalVehicleFormLogic
        mode={props.mode}
        vehicleData={vehicleDetailQuery.data!}
        defaultFormData={convertVehicleToFormData(
          vehicleDetailQuery.data,
          rentalVehicleDetailQuery?.data,
          additionalNoteQuery.data?.note
        )}
        extendedDefaultFormData={props.extendedDefaultFormData}
        onSuccess={props.onSuccess}
        onDiscard={props.onDiscard}
        data-testid={suffixTestId('seve', props)}
      />
    </DataStatus>
  );
}
