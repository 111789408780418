import {DataStatus, FormSubmitHandler, showNotification} from 'platform/components';
import {Icon, Box, Heading, HStack, Show, VStack, Hide, Spinner} from 'platform/foundation';

import {find, isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  useGetVehicleServiceCampaignQuery,
  usePostServiceCaseJobServiceCampaignMutation,
} from '@dms/api/metadaWorkshopServiceCampaign';
import {
  useDeleteServiceCaseJobMutation,
  useGetMetadaServiceCaseQuery,
  useGetPostponeJobsQuery,
  useGetServiceCaseJobsQuery,
  useGetServiceCaseOrderVariantsQuery,
  usePatchServiceCaseJobMutation,
  usePostServiceCaseJobFromPostponeJobMutation,
  usePostServiceCaseJobMutation,
} from '@dms/api/metadaWorkshopServiceCase';
import i18n from '@dms/i18n';
import {testIds, workshopRoutes} from '@dms/routes';
import {CampaignCard, handleApiError, JobFormProps, PostponedJob, queryParams} from '@dms/shared';

import {composePath, useNavigate} from 'shared';

import {Job} from '../../../../../components/Job/Job';
import {JobInput, JobInputForm} from '../../../../../components/JobInput/JobInput';
import {JobSegments} from '../../../../../types/JobSegments';

interface JobsProps {
  serviceCaseId: string;
}

export function Jobs(props: JobsProps) {
  const jobsQuery = useGetServiceCaseJobsQuery({serviceCaseId: props.serviceCaseId});

  const navigate = useNavigate();

  const {defaultVariant, isVariantError, isVariantLoading} = useGetServiceCaseOrderVariantsQuery(
    {serviceCaseId: props.serviceCaseId},
    {
      selectFromResult: ({data, isLoading, isError}) => ({
        isVariantLoading: isLoading,
        isVariantError: isError,
        defaultVariant: find((variant) => !!variant?.default, data ?? []),
      }),
    }
  );

  const postponedJobsQuery = useGetPostponeJobsQuery({serviceCaseId: props.serviceCaseId});
  const {data: serviceCase} = useGetMetadaServiceCaseQuery({serviceCaseId: props.serviceCaseId});

  const {data: campaign} = useGetVehicleServiceCampaignQuery(
    {vehicleId: serviceCase?.vehicleId ?? ''},
    {skip: isNil(serviceCase?.vehicleId) || jobsQuery.isLoading}
  );

  const [postServiceCaseJob, postServiceCaseJobStatus] = usePostServiceCaseJobMutation();
  const [patchServiceCaseJob] = usePatchServiceCaseJobMutation();
  const [deleteServiceCaseJob] = useDeleteServiceCaseJobMutation();
  const [postServiceCaseJobFromPostponeJob] = usePostServiceCaseJobFromPostponeJobMutation();
  const [postServiceCaseJobServiceCampaign] = usePostServiceCaseJobServiceCampaignMutation();

  const onSubmit: FormSubmitHandler<JobInputForm> = async ({job}) => {
    await postServiceCaseJob({
      serviceCaseId: props.serviceCaseId,
      body: {name: job, orderVariantId: defaultVariant?.id},
    })
      .unwrap()
      .then((data) => {
        navigate(
          composePath(workshopRoutes.serviceCaseDetail, {
            params: {id: props.serviceCaseId},
            queryParams: {
              [queryParams.SERVICE_CASE_JOB_SEGMENT]: JobSegments.DETAILS,
              [queryParams.SERVICE_CASE_OPEN_JOB_ID]: data?.id ?? '',
            },
          })
        );
      })
      .catch(handleApiError);
  };

  const handleJobUpadate: JobFormProps['onSubmit'] = async (data, reset) => {
    const jobId = data?.id;

    if (isNil(jobId)) {
      return;
    }

    await patchServiceCaseJob({
      serviceCaseId: props.serviceCaseId,
      serviceCaseJobId: jobId,
      body: data,
    })
      .unwrap()
      .then(() => {
        reset();
        showNotification.success();
      })
      .catch(handleApiError);
  };

  const handleRemoveJob = async (jobId: string) => {
    await deleteServiceCaseJob({
      serviceCaseId: props.serviceCaseId,
      serviceCaseJobId: jobId,
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleAddPostponedJob = (postponedJobId?: string | null) => {
    if (isNil(postponedJobId) || isNil(defaultVariant)) {
      return;
    }

    postServiceCaseJobFromPostponeJob({
      serviceCaseId: props.serviceCaseId,
      postponeJobId: postponedJobId,
      serviceOrderVariantId: defaultVariant.id ?? '',
    })
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);
  };

  const handleCampaignAdd = (serviceCampaignId: string) =>
    postServiceCaseJobServiceCampaign({
      serviceCaseId: props.serviceCaseId,
      body: {
        serviceCampaign: {
          platformCode: campaign?.platformCode ?? '',
          serviceCode: campaign?.serviceCode ?? '',
          serviceCampaignId,
        },
      },
    })
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

  const activeCampaigns =
    campaign?.serviceCampaign?.filter(
      (campaign) => campaign.kind === 'ACTIVE_CAMPAIGN' && isNil(campaign.serviceOrder)
    ) ?? [];

  return (
    <Box width="100%">
      <VStack spacing={6}>
        <Hide
          when={isNilOrEmpty(postponedJobsQuery.data?.postponeJob) && isNilOrEmpty(activeCampaigns)}
        >
          <HStack spacing={2} align="center">
            <Icon value="action/lightbulb_outline" size={4} />
            <Heading size={4}>{i18n.t('entity.orderRequest.labels.suggested')}</Heading>
            <Show when={postponedJobsQuery.isLoading || postponedJobsQuery.isFetching}>
              <Spinner size="small" />
            </Show>
          </HStack>
          <VStack spacing={3}>
            {postponedJobsQuery.data?.postponeJob?.map(
              (job, index) =>
                job && (
                  <PostponedJob
                    key={job.postponeJobId}
                    serviceCaseId={props.serviceCaseId}
                    postponeJobData={job}
                    onAddJob={() => handleAddPostponedJob(job.postponeJobId)}
                    data-testid={testIds.workshop.createServiceCase(`suggested-[${index}]`)}
                  />
                )
            )}
            {activeCampaigns?.map((campaign, index) => (
              <CampaignCard
                key={campaign.id}
                data={campaign}
                onAdd={() => handleCampaignAdd(campaign.id ?? '')}
                isSuggested
                data-testid={testIds.workshop.createServiceCase(`suggested-campaign-[${index}]`)}
              />
            ))}
          </VStack>
        </Hide>
        <HStack spacing={2} align="center">
          <Icon value="action/build" size={4} />
          <Heading size={4}>{i18n.t('entity.orderRequest.labels.listTitle')}</Heading>
          <Show
            when={
              postServiceCaseJobStatus.isLoading ||
              jobsQuery.isFetching ||
              jobsQuery.isLoading ||
              isVariantLoading
            }
          >
            <Spinner size="small" color="dark" />
          </Show>
        </HStack>
        <DataStatus
          isError={isVariantError}
          isLoading={isVariantLoading}
          data-testid={testIds.workshop.createServiceCase('variantStatus')}
          minHeight={48}
        >
          <DataStatus
            isError={jobsQuery.isError}
            isLoading={jobsQuery.isLoading}
            data-testid={testIds.workshop.createServiceCase('jobsStatus')}
            minHeight={30}
          >
            <Show when={jobsQuery.data?.length}>
              <VStack spacing={3}>
                {jobsQuery.data?.map(
                  (job, index) =>
                    !!job && (
                      <Job
                        serviceCaseId={props.serviceCaseId}
                        key={job.id}
                        jobData={job}
                        onSubmit={handleJobUpadate}
                        onRemove={handleRemoveJob}
                        data-testid={testIds.workshop.createServiceCase(`job-[${index}]`)}
                      />
                    )
                )}
              </VStack>
            </Show>
          </DataStatus>
          <JobInput
            onSubmit={onSubmit}
            data-testid={testIds.workshop.createServiceCase('jobInput')}
          />
        </DataStatus>
      </VStack>
    </Box>
  );
}
