import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  closeDialog,
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {VStack} from 'platform/foundation';

import {useGetEmployeeMechanicsQuery} from '@dms/api/metadaEmployee';
import {useBulkAssignMechanicMutation} from '@dms/api/metadaWarehouseDirectSale';
import i18n from '@dms/i18n';
import {getOptionsFromMechanics, handleApiError} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {WAREHOUSE_DIALOG_IDS} from '../../../../constants/warehouseDialogIds';
import {BulkAssignMechanicForm, BulkAssignMechanicFormSchema} from './types/BulkAssignMechanicForm';

interface BulkAssignMechanicProps extends RequiredTestIdProps {
  directSaleId: string;
  authorizationProfileId: string;
  rowIds: string[];
  onSuccess: VoidFunction;
}

export function BulkAssignMechanic(props: BulkAssignMechanicProps) {
  const {
    data: mechanics,
    isLoading: areMechanicsLoading,
    isError: hasMechanicsError,
  } = useGetEmployeeMechanicsQuery({authorizationProfileId: props.authorizationProfileId});

  const [bulkAssignMechanic, {isLoading: isAssigningMechanic}] = useBulkAssignMechanicMutation();

  const defaultValues: BulkAssignMechanicForm = {mechanicId: ''};

  const handleSubmit: FormSubmitHandler<BulkAssignMechanicForm> = async (formValues) => {
    await bulkAssignMechanic({
      directSaleId: props.directSaleId,
      body: {itemId: props.rowIds, mechanicId: formValues.mechanicId},
    })
      .unwrap()
      .then(() =>
        showNotification.success(
          i18n.t('entity.warehouse.notifications.mechanicSuccessfullyAssigned')
        )
      )
      .then(() => closeDialog(WAREHOUSE_DIALOG_IDS.bulkAssignMechanicDialog))
      .then(props.onSuccess)
      .catch(handleApiError);
  };

  return (
    <Form
      experimentalZodSchema={BulkAssignMechanicFormSchema}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
    >
      {(control) => (
        <VStack spacing={4} data-testid={props['data-testid']}>
          <DataStatus isError={hasMechanicsError}>
            <FormField
              isRequired
              isLoading={areMechanicsLoading}
              control={control}
              name="mechanicId"
              type="choice"
              options={getOptionsFromMechanics(mechanics)}
              data-testid={suffixTestId('mechanic', props)}
            />
            <ButtonGroup align="right">
              <Button
                isLoading={isAssigningMechanic}
                type="button"
                variant="secondary"
                title={i18n.t('general.actions.discard')}
                onClick={closeCurrentDialog}
                data-testid={suffixTestId('actions.discard', props)}
              />
              <Button
                isLoading={isAssigningMechanic}
                type="submit"
                variant="primary"
                title={i18n.t('general.actions.assign')}
                data-testid={suffixTestId('actions.submit', props)}
              />
            </ButtonGroup>
          </DataStatus>
        </VStack>
      )}
    </Form>
  );
}
