import {Box, VStack} from 'platform/foundation';

import {useRef} from 'react';

import {UserSidebarIconSize} from '@dms/api/shared';
import {TaskCenterContent} from '@dms/task-manager';

import {useIsMobileNavigation} from '../hooks/useIsMobileNavigation';
import {TaskCenterButton} from './TaskCenterButton';
import {TopBarItem, TopBarItemRef} from './TopBarItem';

interface TaskCenterProps {
  iconSize: UserSidebarIconSize;
}

export function TaskCenter(props: TaskCenterProps) {
  const isMobileNavigation = useIsMobileNavigation();
  const topBarItemRef = useRef<TopBarItemRef>(null);

  return (
    <TopBarItem
      gtmEventId="top_bar_task_center_open"
      ref={topBarItemRef}
      isFullScreen={isMobileNavigation}
      isClickPropagationDisabled
      trigger={(isOpen) => (
        <TaskCenterButton isOpen={isOpen} iconSize={props.iconSize} data-testid="layout-header" />
      )}
    >
      <Box
        backgroundColor="general.white"
        minWidth={['auto', 'auto', 120]}
        width="100%"
        height="100%"
        padding={4}
        paddingTop={0}
      >
        <VStack height="100%">
          <TaskCenterContent onClick={topBarItemRef.current?.close} data-testid="layout-header" />
        </VStack>
      </Box>
    </TopBarItem>
  );
}
