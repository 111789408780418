import {closeDialog} from 'platform/components';
import {match} from 'ts-pattern';

import {useState} from 'react';

import {refreshDatagrid} from 'features/datagrid';

import {ImportError} from './ImportError';
import {ImportForm} from './ImportForm';
import {ImportSuccess} from './ImportSuccess';

type ImportDialogState = 'form' | 'success' | 'error';

interface ImportDialogProps {
  dialogId: string;
}

export function ImportDialog(props: ImportDialogProps) {
  const [state, setState] = useState<ImportDialogState>('form');

  const handleCloseDialog = () => {
    closeDialog(props.dialogId);
  };

  return match(state)
    .with('form', () => (
      <ImportForm
        onClose={handleCloseDialog}
        onSuccess={() => {
          setState('success');
          refreshDatagrid('import');
        }}
        onError={() => setState('error')}
      />
    ))
    .with('success', () => <ImportSuccess onClose={handleCloseDialog} />)
    .with('error', () => <ImportError onClose={() => setState('form')} />)
    .exhaustive();
}
