import {Card} from 'platform/components';

import {equals} from 'ramda';

import {BaseAuthorizationProfile} from '@dms/api/metadaAdminAuthorizationProfile';
import {PaymentTypeResponseBody} from '@dms/api/metadaErrorLogs';
import {GetWarehousesResponse} from '@dms/api/metadaWarehouse';
import {GetReceiveNoteCorrectionResponse} from '@dms/api/metadaWarehouseReceiveNoteCorrection';
import {BaseSupplier} from '@dms/api/metadaWarehouseSupplier';
import {CurrencyResponseBody} from '@dms/api/shared';
import i18n from '@dms/i18n';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {ReceiveNoteBasicInformationReadOnly} from '../../../../../components/ReceiveNoteBasicInformationReadOnly';
import {getSupplierById} from '../../../../../utils/getSupplierById';
import {getWarehouseById} from '../../../../../utils/getWarehouseById';
import {BasicInformationForm} from './BasicInformationForm';

interface ReceiveNoteCorrectionCardProps extends RequiredTestIdProps {
  receiveNoteCorrection: GetReceiveNoteCorrectionResponse;
  suppliers: BaseSupplier[] | Nullish;
  warehouses: GetWarehousesResponse | Nullish;
  authorizationProfiles: BaseAuthorizationProfile[] | Nullish;
  paymentTypes: PaymentTypeResponseBody[] | Nullish;
  currencies: CurrencyResponseBody[] | Nullish;
  isReadOnly: boolean;
  onClose: VoidFunction;
}

export function ReceiveNoteCorrectionCard(props: ReceiveNoteCorrectionCardProps) {
  const isReceiveNoteCorrectionCompleted =
    props.receiveNoteCorrection?.creditNote?.state === 'COMPLETED';

  const correctionSupplier = getSupplierById(
    props.suppliers ?? [],
    props.receiveNoteCorrection?.creditNote?.supplierId
  )?.name;

  const correctionWarehouse = getWarehouseById(
    props.warehouses ?? [],
    props.receiveNoteCorrection?.creditNote?.warehouseId
  )?.name;

  const correctionAuthorizationProfile = props.authorizationProfiles?.find((authorizationProfile) =>
    equals(authorizationProfile.id, props.receiveNoteCorrection?.creditNote?.authorizationProfileId)
  )?.name;

  const correctionNumber = props.receiveNoteCorrection?.creditNote?.creditNoteNumber;

  const correctionDate = isReceiveNoteCorrectionCompleted
    ? props.receiveNoteCorrection?.creditNote?.updated
    : null;

  const correctionInvoiceNumber = props.receiveNoteCorrection?.creditNote?.correctionInvoiceNumber;

  const correctionInvoiceIssueDate = props.receiveNoteCorrection?.creditNote?.correctionInvoiceDate;

  const correctionPaymentType = props.paymentTypes?.find((paymentType) =>
    equals(paymentType.key, props.receiveNoteCorrection?.creditNote?.paymentType)
  )?.value;

  const correctionCurrency = props.currencies?.find((currency) =>
    equals(currency.code, props.receiveNoteCorrection?.creditNote?.currency)
  )?.code;

  const correctionExchangeRate = props.receiveNoteCorrection?.creditNote?.exchangeRate;

  const correctionNcConversion = props.receiveNoteCorrection?.creditNote?.ncConversion;

  const correctionNote = props.receiveNoteCorrection?.creditNote?.note;

  return (
    <Card
      variant="inlineWhite"
      flags={[{label: i18n.t('entity.warehouse.labels.correction'), colorScheme: 'red'}]}
    >
      {props.isReadOnly ? (
        <ReceiveNoteBasicInformationReadOnly
          readOnlyFields={{
            supplierName: correctionSupplier,
            warehouseName: correctionWarehouse,
            authorizationProfileName: correctionAuthorizationProfile,
            number: correctionNumber,
            date: correctionDate,
            invoiceNumber: correctionInvoiceNumber,
            invoiceIssueDate: correctionInvoiceIssueDate,
            paymentType: correctionPaymentType,
            currency: correctionCurrency,
            exchangeRate: correctionExchangeRate,
            ncConversion: correctionNcConversion,
            note: correctionNote,
          }}
          data-testid={suffixTestId('basicInformationReadOnly', props)}
        />
      ) : (
        <BasicInformationForm
          receiveNoteCorrection={props.receiveNoteCorrection}
          suppliers={props.suppliers}
          warehouses={props.warehouses}
          authorizationProfiles={props.authorizationProfiles}
          paymentTypes={props.paymentTypes}
          currencies={props.currencies}
          onClose={props.onClose}
          data-testid={suffixTestId('basicInformationForm', props)}
        />
      )}
    </Card>
  );
}
