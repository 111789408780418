import {FC} from 'react';
import {useSelector} from 'react-redux';

import {FeaturesPicker} from '@dms/shared';

import {Nullish, TestIdProps} from 'shared';

import {
  selectFeatureCategories,
  selectVehicleFeatures,
} from '../../../store/vehicleCatalogue/selectors';
import {CreateVehicleRequestBody} from '../../../types/CreateVehicleRequestBody';
import {useFormRenderer} from '../../FinalForm/hooks/useFormRenderer';

export const Features: FC<TestIdProps> = (props) => {
  const {Field, Subscribe} = useFormRenderer<CreateVehicleRequestBody>();

  return (
    <Subscribe
      name="type"
      component={({input: {value: vehicleType}}) => {
        /* eslint-disable react-hooks/rules-of-hooks */
        const featureCategories = useSelector(selectFeatureCategories(vehicleType));
        const features = useSelector(selectVehicleFeatures(vehicleType));

        return (
          <Field<Nullish | string[] | ''>
            name="features"
            component={({input}) => (
              <FeaturesPicker
                data-testid={props['data-testid']}
                categories={featureCategories}
                features={features}
                selected={input.value}
                onFeatureSelect={(selectedFeatures) => {
                  input.onChange(selectedFeatures);
                }}
              />
            )}
          />
        );
      }}
    />
  );
};
