import {isEqual} from 'date-fns';
import {isFeatureEnabled} from 'feature-flags';
import {
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Label,
  openDialog,
  TimeType,
} from 'platform/components';
import {Box, Hide, HStack, Right, Show, Space, Stack} from 'platform/foundation';

import {useSelector} from 'react-redux';

import {isNil, isNotNil, not} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {selectActiveBranchId} from '@dms/api/features';
import {useGetEmployeeWorkCompetencesListQuery} from '@dms/api/metadaEmployeeCompetence';
import {useGetServiceCaseOrderVariantsQuery} from '@dms/api/metadaWorkshopServiceCase';
import {useGetBranchQuery} from '@dms/api/tenant';
import {getOptionsFromServiceCaseOrderVariants} from '@dms/api/utils';
import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';

import {parseDate, suffixTestId, TestIdProps} from 'shared';

import {usePermissions} from '../../hooks/usePermissions/usePermissions';
import {getActiveOptions} from '../../utils/getActiveOptions';
import {numberToTimeString} from '../../utils/numberToTimeString';
import {timeStringToNumber} from '../../utils/timeStringToNumber';
import {NoPermissionTooltip} from '../NoPermissionTooltip/NoPermissionTooltip';
import {PredefinedNotes} from '../PredefinedNotes/PredefinedNotes';
import {NeededAtConfirmationDialog} from './components/NeededAtConfirmationDialog';
import {JobType} from './types/JobType';

type JobTypeForm = Omit<JobType, 'workTimeEstimated'> & {
  workTimeEstimated: TimeType | null;
  neededAt: Date | null;
};

export interface JobFormProps extends TestIdProps {
  serviceCaseId?: string;
  jobData?: JobType | null;
  orderId?: string;
  isReadOnly?: boolean;
  isLockedServicePackage?: boolean;
  onSubmit: (job: JobType, reset: () => void) => Promise<void>;
}

export function JobForm(props: JobFormProps) {
  const [canEditPartsListReady, canEditPartsListRequest, canEditSolution] = usePermissions({
    permissionKeys: ['editPartsListReady', 'editPartsListRequest', 'editSolution'],
  });

  const branchId = useSelector(selectActiveBranchId);
  const {data: branchDetail} = useGetBranchQuery({branchId});
  const {data: orderVariants, isLoading: isOrderVariantsLoading} =
    useGetServiceCaseOrderVariantsQuery(
      {serviceCaseId: props.serviceCaseId || ''},
      {skip: !isNil(props.orderId) || isNil(props.serviceCaseId)}
    );
  const {competenceOptions, isCompetenceOptionsLoading} = useGetEmployeeWorkCompetencesListQuery(
    undefined,
    {
      selectFromResult: ({data, isLoading}) => ({
        isCompetenceOptionsLoading: isLoading,
        competenceOptions: getActiveOptions(data?.competences),
      }),
      skip:
        isNilOrEmpty(branchDetail?.billingInformation?.id) ||
        isNilOrEmpty(branchDetail?.id) ||
        !isFeatureEnabled(featureFlags.ACL_EMPLOYEE_MANAGEMENT),
    }
  );

  const defaultValues = props.jobData
    ? {
        ...props.jobData,
        workTimeEstimated: isNotNil(props.jobData.workTimeEstimated)
          ? numberToTimeString(props.jobData.workTimeEstimated)
          : null,
        neededAt: isNotNil(props.jobData.neededAt)
          ? (parseDate(props.jobData.neededAt) as Date & string)
          : null,
      }
    : undefined;

  const onSubmit: FormSubmitHandler<JobTypeForm> = async (job, _, resetFunc) => {
    const workTimeEstimated = job.workTimeEstimated
      ? timeStringToNumber(job.workTimeEstimated)
      : null;

    const reset = () => resetFunc(undefined, {keepValues: true});

    const jobNeededAt = parseDate(props.jobData?.neededAt || 0);
    const formNeededAt = parseDate(job.neededAt || 0);

    const data = {...job, workTimeEstimated};

    if (
      isEqual(jobNeededAt, formNeededAt) ||
      (not(isEqual(jobNeededAt, formNeededAt)) && not(props.jobData?.hasItems))
    ) {
      await props.onSubmit(data, reset);
    } else {
      openIsItemNeededAtUpdateConfirmDialog(data, reset);
    }
  };

  const openIsItemNeededAtUpdateConfirmDialog = (data: JobType, reset: () => void) =>
    openDialog(
      <NeededAtConfirmationDialog
        onSubmit={props.onSubmit}
        data={data}
        reset={reset}
        data-testid={suffixTestId('neededAtConfirmationDialog', props)}
      />,
      {
        id: 'neededAtUpdateConfirmDialog',
        size: 'small',
        'data-testid': suffixTestId('neededAtUpdateConfirmDialog', props),
      }
    );

  return (
    <Form<JobTypeForm>
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      shouldWatchForUnsavedChanges
    >
      {(control, formApi) => (
        <>
          <HStack spacing={4}>
            <Box flex={1}>
              <FormField
                control={control}
                name="name"
                type="text"
                label={i18n.t('entity.orderRequest.labels.request')}
                isRequired
                isDisabled={props.isReadOnly || props.isLockedServicePackage}
                data-testid={suffixTestId('name', props)}
              />
            </Box>
            <Hide when={props.orderId || !props.serviceCaseId}>
              <Box flex={1}>
                <FormField
                  control={control}
                  label={i18n.t('entity.orderRequest.labels.orderVariant')}
                  name="orderVariantId"
                  type="choice"
                  isRequired
                  options={getOptionsFromServiceCaseOrderVariants(orderVariants)}
                  placeholder={i18n.t('general.labels.select')}
                  isDisabled={props.isReadOnly}
                  isLoading={isOrderVariantsLoading}
                  data-testid={suffixTestId('orderVariantId', props)}
                />
              </Box>
            </Hide>
            <Show when={props.orderId || !props.serviceCaseId}>
              <Box flex={1}>
                <FormField
                  control={control}
                  label={i18n.t('entity.warehouse.labels.neededAt')}
                  name="neededAt"
                  type="date"
                  isDisabled={props.isReadOnly}
                  data-testid={suffixTestId('neededAt', props)}
                />
              </Box>
            </Show>
          </HStack>
          <Space vertical={4} />
          <HStack spacing={4}>
            <Box flex={1}>
              <HStack justify="space-between" align="flex-end">
                <Box flex={1}>
                  <Label>{i18n.t('entity.orderRequest.labels.description')}</Label>
                </Box>
                <PredefinedNotes
                  note={formApi.watch('description') ?? null}
                  onPrefill={(note) => formApi.setValue('description', note)}
                  resource="SERVICE_CASE"
                  context="service_request_description"
                  isLinkVariant
                  data-testid={suffixTestId('descriptionPredefinedNotes', props)}
                />
              </HStack>
              <FormField
                control={control}
                name="description"
                type="textarea"
                isDisabled={props.isReadOnly}
                data-testid={suffixTestId('description', props)}
              />
            </Box>
            <Box flex={1}>
              <HStack justify="space-between" align="flex-end">
                <Box flex={1}>
                  <Label>{i18n.t('entity.orderRequest.labels.solution')}</Label>
                </Box>
                <Show when={canEditSolution}>
                  <PredefinedNotes
                    note={formApi.watch('solution') ?? null}
                    onPrefill={(note) => formApi.setValue('solution', note)}
                    resource="SERVICE_CASE"
                    context="service_request_solution"
                    isLinkVariant
                    data-testid={suffixTestId('solutionPredefinedNotes', props)}
                  />
                </Show>
              </HStack>
              <NoPermissionTooltip shouldShowTooltip={!canEditSolution}>
                <FormField
                  control={control}
                  name="solution"
                  type="textarea"
                  isDisabled={props.isReadOnly || !canEditSolution}
                  data-testid={suffixTestId('solution', props)}
                />
              </NoPermissionTooltip>
            </Box>
          </HStack>
          <Space vertical={4} />
          <Stack direction={['column', 'column', 'column', 'row']} spacing={4}>
            <Box flex={1}>
              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    control={control}
                    name="competencyId"
                    type="choice"
                    label={i18n.t('entity.orderRequest.labels.competence')}
                    isDisabled={props.isReadOnly || props.isLockedServicePackage}
                    options={competenceOptions}
                    isLoading={isCompetenceOptionsLoading}
                    data-testid={suffixTestId('competency', props)}
                  />
                </Box>
                <Box flex={1}>
                  <FormField
                    control={control}
                    name="workTimeEstimated"
                    type="time"
                    label={i18n.t('entity.orderRequest.labels.estimatedTime')}
                    isDisabled={props.isReadOnly || props.isLockedServicePackage}
                    data-testid={suffixTestId('workTimeEstimated', props)}
                  />
                </Box>
              </HStack>
            </Box>
            <Box flex={1}>
              <Label>{i18n.t('entity.workshop.labels.additionalOptions')}</Label>
              <HStack spacing={4} height={8} align="center">
                <FormField
                  control={control}
                  name="repeatedRepair"
                  type="checkbox"
                  isDisabled={props.isReadOnly}
                  label={i18n.t('entity.workshop.labels.repeatedRepair')}
                  data-testid={suffixTestId('repeatedRepair', props)}
                />
                <NoPermissionTooltip shouldShowTooltip={!canEditPartsListRequest}>
                  <FormField
                    control={control}
                    name="partsRequest"
                    type="checkbox"
                    isDisabled={
                      props.isReadOnly || !!formApi.watch('partsReady') || !canEditPartsListRequest
                    }
                    label={i18n.t('entity.workshop.labels.partsRequest')}
                    data-testid={suffixTestId('partsRequest', props)}
                  />
                </NoPermissionTooltip>
                <NoPermissionTooltip shouldShowTooltip={!canEditPartsListReady}>
                  <FormField
                    control={control}
                    name="partsReady"
                    type="checkbox"
                    isDisabled={
                      props.isReadOnly || !formApi.watch('partsRequest') || !canEditPartsListReady
                    }
                    label={i18n.t('entity.workshop.labels.partsReady')}
                    data-testid={suffixTestId('partsReady', props)}
                  />
                </NoPermissionTooltip>
              </HStack>
            </Box>
          </Stack>
          <Hide when={props.isReadOnly}>
            <Space vertical={4} />
            <Right>
              <FormButton
                control={control}
                type="submit"
                data-testid={suffixTestId('submit', props)}
                isLoading={formApi.formState.isSubmitting}
                title={i18n.t('general.actions.saveChanges')}
              />
            </Right>
          </Hide>
        </>
      )}
    </Form>
  );
}
