import {useCallback} from 'react';

import {GridApi} from '../types/AgGridTypes';

export type RefreshDatagridOptions = {
  deselectAll?: boolean;
};

export type RefreshDatagridCustomEvent = {
  gridCode: string;
  options: RefreshDatagridOptions;
};

export const REFETCH_DATAGRID_EVENT = 'refetch_data_grid_event';

export const useRefreshServerSideStore = (gridCode: string, gridApi: GridApi | undefined) => {
  const refetchManually = useCallback(
    (options?: RefreshDatagridOptions) => {
      if (gridApi) {
        if (options?.deselectAll) {
          gridApi.deselectAll();
        }

        gridApi.refreshServerSide({purge: true});
        gridApi.dispatchEvent({type: 'storeRefreshed'});
      }
    },
    [gridApi]
  );

  window.addEventListener(REFETCH_DATAGRID_EVENT, (event) => {
    const eventDetail = (event as CustomEvent<RefreshDatagridCustomEvent>).detail;

    if (eventDetail.gridCode === gridCode) {
      refetchManually(eventDetail.options);
    }
  });

  return refetchManually;
};
