import {isNotNil} from 'ramda';

import {omneticApi} from '@dms/api/core';

import {
  TenantFieldDefinitionResponseBody,
  TenantFieldDefinitionAddParams,
  TenantFieldDefinitionRemoveParams,
  TenantFieldDefinitionUpdateParams,
  MakeFieldDefinitionResponseBody,
  FieldDefinitionListParams,
  CustomFieldsSchemaApiResponse,
  CustomFieldsSchemaApiArg,
  CustomFieldsPayloadApiResponse,
  CustomFieldsPayloadApiArg,
  UpdateCustomFieldsPayloadApiResponse,
  UpdateCustomFieldsPayloadApiArg,
  ReorderFieldDefinitionsApiResponse,
  ReorderFieldDefinitionsApiArg,
} from './types';

export const customFieldsApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    addFieldDefinition: build.mutation<
      TenantFieldDefinitionResponseBody,
      TenantFieldDefinitionAddParams
    >({
      query: ({body}) => ({
        url: `/dms/v1/custom-fields/definition/tenant`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['customFieldList', 'entityCustomFields'],
    }),
    deleteFieldDefinition: build.mutation<void, TenantFieldDefinitionRemoveParams>({
      query: ({resourceId, definitionId}) => ({
        url: `/dms/v1/custom-fields/definition/tenant/${resourceId}/${definitionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['customFieldList', 'entityCustomFields'],
    }),
    updateFieldDefinition: build.mutation<
      TenantFieldDefinitionResponseBody,
      TenantFieldDefinitionUpdateParams
    >({
      query: ({resourceId, definitionId, body}) => ({
        url: `/dms/v1/custom-fields/definition/tenant/${resourceId}/${definitionId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['customFieldList', 'entityCustomFields'],
    }),
    getFieldDefinitionList: build.query<
      MakeFieldDefinitionResponseBody[],
      FieldDefinitionListParams
    >({
      query: ({type, resourceId}) => ({
        url: `/dms/v1/custom-fields/definition/all`,
        method: 'GET',
        params: {type, resourceId},
      }),
      providesTags: ['customFieldList'],
    }),
    reorderFieldDefinitions: build.mutation<
      ReorderFieldDefinitionsApiResponse,
      ReorderFieldDefinitionsApiArg
    >({
      query: ({resourceId, ...body}) => ({
        url: `/dms/v1/custom-fields/definition/resource/${resourceId}/order`,
        method: 'PUT',
        body,
      }),
      async onQueryStarted({items}, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          customFieldsApi.util.updateQueryData(
            'getFieldDefinitionList',
            {type: 'tenant'},
            (draft) => items.map((id) => draft.find((item) => item.id === id)).filter(isNotNil)
          )
        );

        await queryFulfilled.catch(patchResult.undo);
      },
    }),
    getCustomFieldsSchema: build.query<CustomFieldsSchemaApiResponse, CustomFieldsSchemaApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/model-schema/custom-fields`,
        params: {
          recordId: queryArg.recordId,
          resourceId: queryArg.resourceId,
        },
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'entityCustomFields', id: queryArg.recordId},
      ],
    }),
    getCustomFieldsPayload: build.query<CustomFieldsPayloadApiResponse, CustomFieldsPayloadApiArg>({
      query: ({recordId, resourceId}) => ({
        url: `/dms/v1/custom-fields/payload/${resourceId}/${recordId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'entityCustomFields', id: queryArg.recordId},
      ],
    }),
    updateCustomFieldsPayload: build.mutation<
      UpdateCustomFieldsPayloadApiResponse,
      UpdateCustomFieldsPayloadApiArg
    >({
      query: ({recordId, resourceId, fieldValues}) => ({
        url: `/dms/v1/custom-fields/payload/${resourceId}/${recordId}`,
        method: 'POST',
        body: fieldValues,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'entityCustomFields', id: queryArg.recordId},
      ],
    }),
  }),
});

export const {
  useReorderFieldDefinitionsMutation,
  useUpdateCustomFieldsPayloadMutation,
  useGetCustomFieldsPayloadQuery,
  useGetCustomFieldsSchemaQuery,
  useAddFieldDefinitionMutation,
  useDeleteFieldDefinitionMutation,
  useUpdateFieldDefinitionMutation,
  useGetFieldDefinitionListQuery,
} = customFieldsApi;
