import {startOfToday} from 'date-fns';
import {isFeatureEnabled} from 'feature-flags';
import {
  Button,
  ButtonGroup,
  Card,
  Choice,
  DataStatus,
  DialogFooter,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  isCurrency,
  Segment,
  Separator,
  showNotification,
  TextInput,
} from 'platform/components';
import {Grid, GridItem, Hide, HStack, Icon, Show, Text, VStack} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';
import {z} from 'zod';

import {Fragment, useState} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {defaultTo, isNil, not} from 'ramda';

import {useGetEmployeeMechanicsQuery} from '@dms/api/metadaEmployee';
import {useGetWarehousesQuery} from '@dms/api/metadaWarehouse';
import {
  DiscountSourceType,
  GetDirectSaleMaterialItemResponse,
  usePatchDirectSaleMaterialItemMutation,
} from '@dms/api/metadaWarehouseDirectSaleItemSparePart';
import {useGetDiscountGroupsQuery} from '@dms/api/metadaWarehouseDiscountGroup';
import featureFlags from '@dms/feature-flags';
import i18n from '@dms/i18n';
import {warehouseRoutes} from '@dms/routes';
import {
  DEFAULT_CURRENCY,
  getDecimalFromPercentage,
  getOptionsFromWarehouses,
  getPercentageFromDecimal,
  handleApiError,
  useGetVatRatesOptions,
} from '@dms/shared';

import {
  composePath,
  generateHashFromObjects,
  getApiDateString,
  Nullish,
  parseDate,
  precisionCalculation,
  RequiredTestIdProps,
  suffixTestId,
} from 'shared';

import {DEFAULT_VAT_RATE} from '../../../../constants/defaultVatRate';
import {
  EditMaterialItemForm,
  EditMaterialItemFormSchema,
} from '../../../../types/EditMaterialItemForm';
import {getOptionsFromMechanics} from '../../../../utils/getOptionsFromMechanics';
import {RequestTabMaterialAvailabilityTab} from './RequestTabMaterialAvailabilityTab';
import {
  MaterialItemCurrentPrices,
  RequestTabMaterialItemPrice,
} from './RequestTabMaterialItemPrice';
import {RequestTabMaterialRequestsTab} from './RequestTabMaterialRequestsTab';

enum ListSegment {
  availability = 'availability',
  requests = 'requests',
}

export interface RequestTabMaterialItemEditProps extends RequiredTestIdProps {
  directSaleId: string;
  item: GetDirectSaleMaterialItemResponse;
  isEditingDisabled?: boolean;
  authorizationProfileId?: string;
  onAfterSubmit?: () => void;
  onClose: () => void;
}

export function RequestTabMaterialItemEdit(props: RequestTabMaterialItemEditProps) {
  const materialItem = props.item;
  const formatCurrency = useFormatCurrency();
  const todayDate = startOfToday();

  const [listSegment, setListSegment] = useState<ListSegment>(ListSegment.availability);

  const {data: warehouses, isLoading: isWarehousesLoading} = useGetWarehousesQuery();

  const {data: discountGroups, isLoading: isDiscountGroupsLoading} = useGetDiscountGroupsQuery();

  const {data: mechanics, isLoading: isMechanicsLoading} = useGetEmployeeMechanicsQuery(
    {authorizationProfileId: defaultTo('', props.authorizationProfileId)},
    {
      skip:
        isNil(props.authorizationProfileId) ||
        !isFeatureEnabled(featureFlags.ACL_EMPLOYEE_MANAGEMENT),
    }
  );

  const [patchMaterialItem, {isLoading: isPatching}] = usePatchDirectSaleMaterialItemMutation();

  const [vatOptions] = useGetVatRatesOptions({fullLabel: true});

  const isLoading = isWarehousesLoading || isDiscountGroupsLoading;

  const isEditingDisabled = props.isEditingDisabled || not(materialItem.isAllowedItemEditing);

  const handleSubmit: FormSubmitHandler<EditMaterialItemForm> = async (formValues) => {
    await patchMaterialItem({
      directSaleId: props.directSaleId,
      itemId: props.item.id,
      body: {
        ...formValues,
        quantity: formValues.quantity,
        isUnitPriceWithVat: formValues.isUnitPriceWithVat,
        sellingPricePerUnit: formValues.sellingPricePerUnit,
        vatType: formValues.vatType,
        isCustomPrice: formValues.isCustomPrice,
        neededAt: formValues.neededAt ? getApiDateString(formValues.neededAt) : null,
        discount: {
          isDiscountEnable: formValues.discount.isDiscountEnable,
          discountSourceType: formValues.discount.isDiscountEnable
            ? formValues.discount.discountSourceType
            : null,
          discountRate: formValues.discount.isDiscountEnable
            ? (getDecimalFromPercentage(formValues.discount.discountRate) ?? 0)
            : 0,
          isDoNotApplyDiscount: formValues.discount.isDoNotApplyDiscount,
        },
      },
    })
      .unwrap()
      .then(() =>
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'))
      )
      .then(props.onAfterSubmit)
      .then(props.onClose)
      .catch(handleApiError);
  };

  const handleArticleDetail = () => {
    window.open(
      composePath(warehouseRoutes.articleDetailOverview, {
        params: {
          warehouseId: materialItem?.warehouse?.warehouseId,
          id: materialItem?.warehouse.warehouseArticleId,
        },
      }),
      '_blank'
    );
  };

  const handlePriceReset = (formApi: UseFormReturn<EditMaterialItemForm>) => {
    const isCustomPrice = formApi.watch('isCustomPrice');

    if (isCustomPrice) {
      return;
    }

    formApi.setValue(
      'sellingPricePerUnit',
      defaultTo(0, materialItem?.baseSellingPricePerUnit?.amount)
    );
  };

  const handleDiscountChange = (
    index: number,
    formApi: UseFormReturn<EditMaterialItemForm>,
    discountSourceType: DiscountSourceType,
    discountRate: number | Nullish
  ) => {
    formApi.clearErrors(`discount.availableDiscounts.${index}.discountRate`);

    const validationResult = discountRateSchema.safeParse(discountRate);

    if (validationResult.success) {
      formApi.setValue('discount.discountSourceType', discountSourceType);
      formApi.setValue('discount.discountRate', discountRate);
      return;
    }

    const errorMessages = validationResult.error?.errors.map((error) => error.message).join(' ');
    formApi.setError(`discount.availableDiscounts.${index}.discountRate`, {message: errorMessages});
  };

  const handleDoNotApplyDiscount = (formApi: UseFormReturn<EditMaterialItemForm>) => {
    const shouldNotApplyDiscount = formApi.watch('discount.isDoNotApplyDiscount');

    if (shouldNotApplyDiscount) {
      formApi.setValue('discount.isDiscountEnable', false);
      formApi.setValue('discount.discountRate', 0);

      // Get the current list of available discounts
      const availableDiscounts = formApi.getValues('discount.availableDiscounts') || [];

      availableDiscounts.forEach((_, index) => {
        formApi.setValue(`discount.availableDiscounts.${index}.discountRate`, 0);
      });
    }
  };

  const handlePriceChange = (
    currentPrices: MaterialItemCurrentPrices,
    formApi: UseFormReturn<EditMaterialItemForm>
  ) => {
    formApi.setValue('sellingPricePerUnit', currentPrices.sellingPricePerUnit);
    formApi.setValue('isCustomPrice', currentPrices.isCustomPrice);
    formApi.setValue('discount.isDiscountEnable', currentPrices.isDiscountEnabled);
    formApi.setValue('discount.isDoNotApplyDiscount', currentPrices.isDoNotApplyDiscount);
  };

  const validateQuantity = (value: number | null, formApi: UseFormReturn<EditMaterialItemForm>) => {
    const valueToValidate = defaultTo(0, value);

    formApi.clearErrors('quantity');

    const validationResult = quantitySchema(dispensingUnit).safeParse(valueToValidate);

    if (validationResult.success) {
      return;
    }

    const errorMessages = validationResult.error?.errors.map((error) => error.message).join(' ');
    formApi.setError('quantity', {message: errorMessages});
  };

  const dispensingUnit = defaultTo(1, materialItem?.dispensingUnit);

  const discountGroup = discountGroups?.find(
    (discountGroup) => discountGroup.id === materialItem?.warehouse?.discountGroup
  );

  const currency = materialItem?.sellingPricePerUnit?.currency;

  const baseVat = vatOptions.find((vat) => vat.value === materialItem?.baseSellingPriceVat?.type);

  const basePrice = `${i18n.t('general.labels.default')}: ${formatCurrency(
    defaultTo(0, materialItem?.baseSellingPricePerUnit?.amount),
    defaultTo(DEFAULT_CURRENCY, materialItem?.baseSellingPricePerUnit?.currency)
  )}, ${i18n.t('general.labels.vat')}: ${baseVat?.fieldLabel}`;

  const activeDiscount = materialItem?.itemsDiscounts.availableDiscounts?.find(
    (discount) => discount.isSet
  );

  const availableDiscounts =
    materialItem?.itemsDiscounts.availableDiscounts?.map((availableDiscount) => ({
      ...availableDiscount,
      discountRate: getPercentageFromDecimal(availableDiscount.discountRate) ?? 0,
    })) ?? [];

  const defaultValues: EditMaterialItemForm = {
    quantity: defaultTo(0, materialItem?.quantity.amount),
    sellingPricePerUnit: defaultTo(0, materialItem?.sellingPricePerUnit?.amount),
    mechanic: materialItem?.assignMechanic,
    vatType: defaultTo(DEFAULT_VAT_RATE, materialItem?.sellingPriceVat?.type),
    vatFreeCode: materialItem?.sellingPriceVat?.freeCode,
    isUnitPriceWithVat: defaultTo(false, materialItem?.isUnitPriceWithVat),
    isCustomPrice: defaultTo(false, materialItem?.isCustomPrice),
    neededAt: parseDate(materialItem?.neededAt) ?? new Date(),
    discount: {
      isDoNotApplyDiscount: defaultTo(false, materialItem?.itemsDiscounts.isDoNotApplyDiscount),
      isDiscountEnable: defaultTo(false, materialItem?.itemsDiscounts.isDiscountEnable),
      discountSourceType: activeDiscount?.discountSourceType,
      discountRate: getPercentageFromDecimal(activeDiscount?.discountRate) ?? 0,
      availableDiscounts,
    },
  };

  const segments = [
    {
      value: ListSegment.availability,
      label: i18n.t('entity.warehouse.labels.availability'),
      content: (
        <RequestTabMaterialAvailabilityTab
          manufacturerNumber={materialItem?.manufacturerNumber}
          manufacturerId={materialItem?.manufacturerId}
          data-testid={suffixTestId('availabilityTab', props)}
        />
      ),
    },
    {
      value: ListSegment.requests,
      label: i18n.t('entity.warehouse.labels.requests'),
      content: (
        <RequestTabMaterialRequestsTab
          directSaleItemId={materialItem?.id}
          data-testid={suffixTestId('requestsTab', props)}
        />
      ),
    },
  ];

  const segment = segments.find((item) => item.value === listSegment)?.content;

  return (
    <DataStatus isLoading={isLoading} minHeight={140} key={props.item.id}>
      <Form<EditMaterialItemForm>
        key={generateHashFromObjects(materialItem)}
        defaultValues={defaultValues}
        experimentalZodSchema={EditMaterialItemFormSchema}
        onSubmit={handleSubmit}
      >
        {(control, formApi) => {
          const isCustomPrice = formApi.watch('isCustomPrice');
          const discount = formApi.watch('discount');

          return (
            <VStack spacing={4}>
              <Card variant="inlineGrey">
                <VStack spacing={4}>
                  <Grid spacing={4} columns={2}>
                    <GridItem span={1}>
                      <Choice
                        value={defaultTo(null, materialItem?.warehouse?.warehouseId)}
                        label={i18n.t('entity.warehouse.labels.warehouse')}
                        options={getOptionsFromWarehouses(warehouses)}
                        isDisabled
                        data-testid={suffixTestId('inputs.warehouse', props)}
                      />
                    </GridItem>
                    <GridItem span={1}>
                      <TextInput
                        value={defaultTo(null, materialItem?.warehouse?.storageLocation)}
                        label={i18n.t('entity.warehouse.labels.storageLocation')}
                        isDisabled
                        data-testid={suffixTestId('inputs.storageLocation', props)}
                      />
                    </GridItem>
                    <GridItem span={1}>
                      <TextInput
                        value={defaultTo(null, discountGroup?.name)}
                        label={i18n.t('entity.warehouse.labels.rabatGroup')}
                        isDisabled
                        data-testid={suffixTestId('inputs.discountGroup', props)}
                      />
                    </GridItem>
                    <GridItem span={1}>
                      <FormField
                        name="neededAt"
                        type="date"
                        minDate={todayDate}
                        control={control}
                        label={i18n.t('entity.warehouse.labels.neededAt')}
                        isDisabled={isEditingDisabled}
                        data-testid={suffixTestId('inputs.neededAt', props)}
                      />
                    </GridItem>
                  </Grid>

                  <Separator spacing={0} />

                  <Grid spacing={4} columns={4}>
                    <GridItem span={1}>
                      <FormField
                        name="quantity"
                        type="number"
                        control={control}
                        label={i18n.t('entity.warehouse.labels.quantity')}
                        step={dispensingUnit}
                        isStepperVisible
                        shouldRoundStepsByDifference
                        onChange={(value) => validateQuantity(value, formApi)}
                        decimalPlaces={4}
                        isDisabled={
                          not(materialItem?.isAllowedQuantityEditing) || isEditingDisabled
                        }
                        data-testid={suffixTestId('inputs.quantity', props)}
                      />
                    </GridItem>
                    <GridItem span={1}>
                      <FormField
                        name="sellingPricePerUnit"
                        type="currency"
                        control={control}
                        label={i18n.t('entity.warehouse.labels.pricePerUnit')}
                        currency={isCurrency(currency) ? currency : undefined}
                        isDisabled={not(isCustomPrice) || isEditingDisabled}
                        data-testid={suffixTestId('inputs.sellingPricePerUnit', props)}
                      />
                    </GridItem>
                    <GridItem span={1}>
                      <FormField
                        name="vatType"
                        type="choice"
                        control={control}
                        label={i18n.t('entity.warehouse.labels.vat')}
                        options={vatOptions}
                        isDisabled={not(isCustomPrice) || isEditingDisabled}
                        isNotClearable
                        data-testid={suffixTestId('inputs.vat', props)}
                      />
                    </GridItem>
                    <GridItem span={1}>
                      <FormField
                        name="mechanic"
                        type="choice"
                        control={control}
                        label={i18n.t('entity.warehouse.labels.mechanic')}
                        options={getOptionsFromMechanics(mechanics)}
                        isLoading={isMechanicsLoading}
                        isDisabled={isEditingDisabled}
                        menuInPortal
                        data-testid={suffixTestId('inputs.mechanic', props)}
                      />
                    </GridItem>
                  </Grid>

                  <Show when={isCustomPrice}>
                    <HStack spacing={2}>
                      <Icon size={4} value="action/info" color="palettes.neutral.300.100" />
                      <Text
                        size="xSmall"
                        color="tertiary"
                        data-testid={suffixTestId('isCustomPriceHelper', props)}
                      >
                        {`${i18n.t(
                          'entity.warehouse.labels.customPriceDescription'
                        )} ${basePrice}.`}
                      </Text>
                    </HStack>
                  </Show>

                  <HStack>
                    <FormField
                      name="isCustomPrice"
                      type="switch"
                      control={control}
                      label={i18n.t('entity.warehouse.labels.customPrice')}
                      onChange={() => handlePriceReset(formApi)}
                      isDisabled={isEditingDisabled}
                      data-testid={suffixTestId('inputs.isCustomPrice', props)}
                    />
                  </HStack>

                  <HStack justify="space-between">
                    <FormField
                      name="discount.isDiscountEnable"
                      type="switch"
                      control={control}
                      label={i18n.t('general.labels.discount')}
                      isDisabled={isEditingDisabled || discount.isDoNotApplyDiscount}
                      data-testid={suffixTestId('inputs.isCustomDiscount', props)}
                    />
                    <FormField
                      control={control}
                      name="discount.isDoNotApplyDiscount"
                      label={i18n.t('entity.warehouse.labels.dontApplyDiscount')}
                      onChange={() => handleDoNotApplyDiscount(formApi)}
                      type="checkbox"
                      isDisabled={isEditingDisabled}
                      data-testid={suffixTestId('inputs.isDoNotApplyDiscount', props)}
                    />
                  </HStack>

                  <Show when={discount.isDiscountEnable}>
                    <Grid spacing={4} columns={4} align="center">
                      {discount.availableDiscounts?.map((discount, index) => (
                        <Fragment key={discount.discountSourceType}>
                          <GridItem span={1}>
                            <FormField
                              name="discount.discountSourceType"
                              type="radio"
                              control={control}
                              options={[
                                {
                                  label: discount.discountSourceTypeName,
                                  value: discount.discountSourceType,
                                  isDisabled: isEditingDisabled,
                                },
                              ]}
                              onChange={() =>
                                handleDiscountChange(
                                  index,
                                  formApi,
                                  discount.discountSourceType,
                                  discount.discountRate
                                )
                              }
                              data-testid={suffixTestId('discountRate', props)}
                            />
                          </GridItem>

                          <GridItem span={1}>
                            <FormField
                              name={`discount.availableDiscounts.${index}.discountRate`}
                              type="number"
                              control={control}
                              suffix="%"
                              minStepperValue={0}
                              maxStepperValue={100}
                              decimalPlaces={2}
                              isDisabled={not(discount.isChangeable) || isEditingDisabled}
                              onChange={() =>
                                handleDiscountChange(
                                  index,
                                  formApi,
                                  discount.discountSourceType,
                                  discount.discountRate
                                )
                              }
                              data-testid={suffixTestId('discountRate', props)}
                            />
                          </GridItem>
                        </Fragment>
                      ))}
                    </Grid>
                  </Show>

                  <Separator spacing={0} />

                  <RequestTabMaterialItemPrice
                    control={control}
                    isEditingDisabled={isEditingDisabled}
                    directSaleId={props.directSaleId}
                    itemId={props.item.id}
                    dispensingUnit={dispensingUnit}
                    onPriceChange={(currentPrices) => handlePriceChange(currentPrices, formApi)}
                    data-testid={suffixTestId('prices', props)}
                  />
                </VStack>
              </Card>

              <Grid columns={3}>
                <Segment<ListSegment>
                  value={listSegment}
                  onChange={setListSegment}
                  options={segments}
                />
              </Grid>
              {segment}

              <Hide when={isEditingDisabled}>
                <DialogFooter>
                  <ButtonGroup align="right">
                    <Button
                      variant="secondary"
                      title={i18n.t('general.actions.discard')}
                      onClick={props.onClose}
                      data-testid={suffixTestId('actions.discard', props)}
                    />
                    <Button
                      leftIcon="action/open_in_new"
                      variant="secondary"
                      title={i18n.t('entity.warehouse.labels.articleDetail')}
                      onClick={handleArticleDetail}
                      data-testid={suffixTestId('actions.articleDetail', props)}
                    />
                    <FormButton
                      type="submit"
                      control={control}
                      title={i18n.t('general.labels.saveChanges')}
                      isLoading={isPatching}
                      data-testid={suffixTestId('actions.submit', props)}
                    />
                  </ButtonGroup>
                </DialogFooter>
              </Hide>
            </VStack>
          );
        }}
      </Form>
    </DataStatus>
  );
}

const quantitySchema = (dispensingUnit: number) =>
  z
    .number()
    .min(0.0001)
    .refine((value) => precisionCalculation.modulo(defaultTo(0, value), dispensingUnit) === 0, {
      message: `${i18n.t(
        'entity.warehouse.labels.quantityMustBeMultipleOfDispensingUnit'
      )} (${dispensingUnit})`,
    });

const discountRateSchema = z.number().min(0).max(100).nullish();
