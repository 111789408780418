import {
  closeCurrentDialog,
  DataStatus,
  Form,
  FormSubmitHandler,
  Wizard,
  WizardStepConfig,
} from 'platform/components';
import {useDateTimeFormatter} from 'platform/locale';
import {array, object} from 'yup';

import {useState} from 'react';

import {head, isNil, isNotNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetBusinessCaseQuery} from '@dms/api/businessCase';
import {useGetParticipationQuery} from '@dms/api/participation';
import {useGetSaleVehicleQuery} from '@dms/api/saleVehicle';
import {EntityResourceIds} from '@dms/api/shared';
import {useGetTenantQuery} from '@dms/api/tenant';
import {useGetVehicleQuery} from '@dms/api/vehicle';
import {
  ComplaintResponseBody,
  PatchComplaintRequestBody,
  PatchComplaintResultRequestBody,
  useCreateComplaintMutation,
  useGetComplaintQuery,
  usePatchComplaintMutation,
  usePatchComplaintResultMutation,
} from '@dms/api/vehicleComplaint';
import i18n from '@dms/i18n';

import {
  buildArray,
  getApiDateString,
  Nullish,
  parseDate,
  suffixTestId,
  TestIdProps,
  yupNumber,
  yupString,
} from 'shared';

import {DEFAULT_CURRENCY} from '../../constants/currency';
import {usePermissions} from '../../hooks/usePermissions/usePermissions';
import {handleApiError} from '../../utils/handleApiError';
import {ComplaintReason} from './components/ComplaintReason';
import {ComplaintResult} from './components/ComplaintResult';
import {ComplaintForm} from './types/complaintForm';
import {getResolutionArrayFromValue} from './utils/getResolutionArrayFromValue';
import {getValueFromResolution} from './utils/getValueFromResolution';

interface VehicleComplaintWizardProps extends TestIdProps {
  vehicleId: string;
  complaintId?: string;
  onInspectionClick: (complaint: ComplaintResponseBody) => void;
  isInspectionButtonLoading: boolean;
  saleBusinessCaseId: string | Nullish;
}

export function VehicleComplaintWizard(props: VehicleComplaintWizardProps) {
  const {data: businessCaseParticipation} = useGetParticipationQuery(
    {
      resourceId: EntityResourceIds.businessCase,
      recordId: props.saleBusinessCaseId ?? '',
    },
    {skip: isNilOrEmpty(props.saleBusinessCaseId)}
  );

  const {data: businessCase} = useGetBusinessCaseQuery(
    {businessCaseId: props.saleBusinessCaseId ?? ''},
    {skip: isNilOrEmpty(props.saleBusinessCaseId)}
  );

  const [
    canUpdateComplaint,
    canSetComplaintInspection,
    canUpdateComplaintResult,
    canCreateComplaint,
  ] = usePermissions({
    permissionKeys: [
      'complaintUpdate',
      'complaintSetInspection',
      'complaintUpdateResult',
      'complaintCreate',
    ],
    scopes: {
      complaintUpdate: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      complaintSetInspection: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      complaintUpdateResult: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
      complaintCreate: {
        participation: businessCaseParticipation,
        branchId: businessCase?.branchId,
      },
    },
  });

  const canEditComplaint =
    canUpdateComplaint && canSetComplaintInspection && canUpdateComplaintResult;

  const formatDateTime = useDateTimeFormatter();
  const [complaintId, setComplaintId] = useState<string | null>(props.complaintId ?? null);

  const {data: tenant, isLoading: isTenantLoading, isError: isTenantError} = useGetTenantQuery();

  const {
    data: vehicle,
    isLoading: isVehicleLoading,
    isError: isVehicleError,
  } = useGetVehicleQuery({vehicleId: props.vehicleId});

  const {
    data: saleVehicle,
    isLoading: isSaleVehicleLoading,
    isError: isSaleVehicleError,
  } = useGetSaleVehicleQuery({vehicleId: props.vehicleId});

  const {
    data: complaint,
    isLoading: isComplaintLoading,
    isError: isComplaintError,
  } = useGetComplaintQuery(
    {
      complaintId: props.complaintId ?? '',
    },
    {
      skip: isNil(props.complaintId),
    }
  );

  const [createComplaint] = useCreateComplaintMutation();
  const [editComplaintReason] = usePatchComplaintMutation();
  const [editComplaintResult] = usePatchComplaintResultMutation();

  const isLoading =
    isTenantLoading || isVehicleLoading || isSaleVehicleLoading || isComplaintLoading;
  const isError = isTenantError || isVehicleError || isSaleVehicleError || isComplaintError;

  const currency = tenant?.currency ?? DEFAULT_CURRENCY;

  const canSave =
    (isNil(props.complaintId) && canCreateComplaint) ||
    (isNotNil(complaint?.id) && canEditComplaint);

  const getComplaintReasonBody = (values: ComplaintForm): PatchComplaintRequestBody => ({
    reason: values.reason,
    receiveAt: values.receiveAt ?? null,
    currentMileage: values.currentMileage,
    problem: values.problem ?? null,
    note: values.note ?? null,
    claimedSolution: head(values.claimedSolution) ?? 'REPAIR',
    claimedDiscount: isNotNil(values.claimedDiscount)
      ? {
          amount: String(values.claimedDiscount),
          currency,
        }
      : null,
  });

  const getComplaintResultBody = (values: ComplaintForm): PatchComplaintResultRequestBody => ({
    closeAt: values.closeAt ?? null,
    resultNote: values.resultNote ?? null,
    technicalReport: values.technicalReport ?? null,
    cost: isNotNil(values.cost)
      ? {
          amount: String(values.cost),
          currency,
        }
      : null,
    returnMileage: values.returnMileage ?? null,
    vehicleReturned: getValueFromResolution(head(values.vehicleReturned ?? [])),
    customerSatisfied: getValueFromResolution(head(values.customerSatisfied ?? [])),
  });

  const handleCreate = (values: ComplaintForm) =>
    createComplaint({
      body: {
        saleVehicleId: saleVehicle?.id ?? '',
        ...getComplaintReasonBody(values),
      },
    })
      .unwrap()
      .then(({id: complaintId}) => {
        setComplaintId(complaintId);

        if (canEditComplaint) {
          return editComplaintResult({
            complaintId,
            body: getComplaintResultBody(values),
          }).unwrap();
        }
      })
      .then(closeCurrentDialog)
      .catch(handleApiError);

  const handleEdit = (values: ComplaintForm) => {
    const editComplaintReasonPromise = editComplaintReason({
      complaintId: complaintId!,
      body: getComplaintReasonBody(values),
    }).unwrap();

    const editComplaintResultPromise = editComplaintResult({
      complaintId: complaintId!,
      body: getComplaintResultBody(values),
    }).unwrap();

    return Promise.all([editComplaintReasonPromise, editComplaintResultPromise])
      .then(closeCurrentDialog)
      .catch(handleApiError);
  };

  const handleSubmit: FormSubmitHandler<ComplaintForm> = (values) =>
    complaintId || complaint?.id ? handleEdit(values) : handleCreate(values);

  const defaultValues: Partial<ComplaintForm> = {
    reason: complaint?.reason,
    receiveAt: isNotNil(complaint?.receiveAt) ? complaint?.receiveAt : getApiDateString(new Date()),
    currentMileage: complaint?.currentMileage,
    problem: complaint?.problem,
    claimedSolution: complaint?.claimedSolution ? [complaint?.claimedSolution] : [],
    claimedDiscount: isNotNil(complaint?.claimedDiscount?.amount)
      ? Number(complaint?.claimedDiscount?.amount)
      : null,
    note: complaint?.note,
    closeAt: complaint?.closeAt,
    resultNote: complaint?.resultNote,
    technicalReport: complaint?.technicalReport,
    cost: isNotNil(complaint?.cost?.amount) ? Number(complaint?.cost?.amount) : null,
    returnMileage: complaint?.returnMileage,
    vehicleReturned: getResolutionArrayFromValue(complaint?.vehicleReturned),
    customerSatisfied: getResolutionArrayFromValue(complaint?.customerSatisfied),
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<ComplaintForm>
        defaultValues={defaultValues}
        schema={schema(vehicle?.vehicle.mileage, complaint?.currentMileage)}
        onSubmit={handleSubmit}
      >
        {(control, formApi) => (
          <Wizard
            steps={buildArray<WizardStepConfig>([
              {
                title: i18n.t('entity.vehicleComplaint.labels.complaintReason'),
                content: (
                  <ComplaintReason
                    control={control}
                    formApi={formApi}
                    vehicleId={props.vehicleId}
                    complaint={complaint}
                    onInspectionClick={props.onInspectionClick}
                    isInspectionButtonLoading={props.isInspectionButtonLoading}
                  />
                ),
                subtitle: formApi.watch('reason') ?? i18n.t('general.labels.notSet'),
                severity: isNotNil(complaint?.receiveAt) ? 'success' : undefined,
                actions: [
                  {
                    title: i18n.t('general.actions.save'),
                    type: 'form-button',
                    buttonType: 'submit',
                    variant: 'primary',
                    isDisabled: !canSave,
                    control,
                  },
                ],
              },
            ]).when(canEditComplaint, {
              title: i18n.t('entity.vehicleComplaint.labels.complaintResult'),
              content: <ComplaintResult control={control} formApi={formApi} />,
              subtitle: isNotNil(complaint?.closeAt)
                ? `${formatDateTime('dateShort', parseDate(complaint!.closeAt))}, ${i18n.t(
                    'entity.vehicleComplaint.labels.resultAs.' + complaint!.claimedSolution
                  )}`
                : i18n.t('general.labels.notSet'),
              severity: isNotNil(complaint?.closeAt) ? 'success' : undefined,
              actions: [
                {
                  title: i18n.t('general.actions.save'),
                  type: 'form-button',
                  buttonType: 'submit',
                  variant: 'primary',
                  isDisabled: !canSave,
                  control,
                },
              ],
            })}
            actions={[
              {
                title: i18n.t('general.actions.cancel'),
                onClick: closeCurrentDialog,
                type: 'button',
                variant: 'dangerGhost',
              },
            ]}
            minHeight="60vh"
            data-testid={suffixTestId('vehicleComplaintWizard', props)}
          />
        )}
      </Form>
    </DataStatus>
  );
}

const schema = (sellingMileage: number | Nullish, currentMileage: number | Nullish) =>
  object({
    reason: yupString.required(),
    receiveAt: yupString.required(),
    currentMileage: yupNumber.required().min(sellingMileage ?? 0),
    problem: yupString,
    claimedSolution: array()
      .of(yupString)
      .required()
      .min(1, i18n.t('general.errors.mixed.required')),
    claimedDiscount: yupNumber,
    note: yupString,
    closeAt: yupString,
    resultNote: yupString,
    technicalReport: yupString,
    cost: yupNumber,
    returnMileage: yupNumber.min(currentMileage ?? 0),
    vehicleReturned: array().of(yupString),
    customerSatisfied: array().of(yupString),
  });
