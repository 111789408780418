import {Action, openDialog} from 'platform/components';

import {isNil, isNotNil} from 'ramda';

import {TemplateResponseBodyV2} from '@dms/api/document';
import i18n from '@dms/i18n';
import {downloadFile} from '@dms/shared';

import {buildArray} from 'shared';

import {UploadDocumentTemplateForm} from '../components/UploadDocumentTemplateForm';

export const getDocumentKindActions = (
  templates: TemplateResponseBodyV2[],
  documentKindCode: string
): Action[] => {
  const primaryTemplate = templates.find((template) => template.primary);

  return buildArray<Action>()
    .when(isNotNil(primaryTemplate), {
      type: 'button',
      leftIcon: 'file/download',
      title: i18n.t('entity.document.actions.downloadPrimary'),
      variant: 'link',
      onClick: () => {
        if (isNil(primaryTemplate)) {
          throw new Error("Typecheck error: Primary template doesn't exist");
        }

        downloadFile(primaryTemplate.url, {fileName: primaryTemplate.fileName});
      },
    })
    .add({
      type: 'button',
      leftIcon: 'content/add_circle',
      title: i18n.t('general.actions.upload'),
      variant: 'link',
      onClick: () =>
        openDialog(<UploadDocumentTemplateForm documentKindCode={documentKindCode} />, {
          title: i18n.t('entity.documents.labels.uploadNewTemplateDialogTitle'),
        }),
    });
};
