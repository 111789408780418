import {z} from 'zod';

export type GetMyKanbanSelectedUsersApiResponse = /** status 200  */ SelectedUserBody[];
export type SetMyKanbanSelectedUsersApiResponse = /** status 204  */ void;
export type SetMyKanbanSelectedUsersApiArg = {
  setMyKanbanSelectedUsersRequestBody: SetMyKanbanSelectedUsersRequestBody;
};
export type SelectedUserBody = {
  userId: string;
  isHidden: boolean;
};
export type SetMyKanbanSelectedUsersRequestBody = {
  selectedUsers: SelectedUserBody[];
};

export const selectedUserBodySchema = z.object({
  userId: z.string(),
  isHidden: z.boolean(),
});

export const setMyKanbanSelectedUsersRequestBodySchema = z.object({
  selectedUsers: z.array(selectedUserBodySchema),
});

export const getMyKanbanSelectedUsersResponseSchema = z.array(selectedUserBodySchema);
