import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetTireOrderQuery} from '@dms/api/metadaTires';
import {useGetBranchQuery} from '@dms/api/tenant';

import {TestIdProps} from 'shared';

import {BranchChanger} from './BranchChanger';

export interface TireBranchProps extends TestIdProps {
  recordId: string;
}

export function TireBranch(props: TireBranchProps) {
  const {data: tireOrder, isLoading: isTireOrderLoading} = useGetTireOrderQuery({
    orderId: props.recordId,
  });

  const {data: branch, isLoading: isBranchLoading} = useGetBranchQuery(
    {branchId: tireOrder?.branchId as string},
    {skip: isNilOrEmpty(tireOrder?.branchId)}
  );

  return (
    <BranchChanger
      recordId={props.recordId}
      activeBranch={branch}
      isLoading={isTireOrderLoading || isBranchLoading}
      isEditDisabled
      hideTooltip
      data-testid={props['data-testid']}
    />
  );
}
