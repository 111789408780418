import {
  closeCurrentDialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Box, Center, Heading, Icon, Space, Text, VStack} from 'platform/foundation';
import {array, object} from 'yup';

import {
  useUpdatePairedDeviceMutation,
  useGetPairedDevicesListQuery,
} from '@dms/api/digitalSignature';
import i18n from '@dms/i18n';
import {handleApiError, useBranches} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId, yupString} from 'shared';

type FormValues = {
  name: string;
  branches: string[];
};

interface EditDeviceStepProps extends RequiredTestIdProps {
  pairedDeviceId: string | null;
}

export function EditDeviceStep(props: EditDeviceStepProps) {
  const {branchOptions} = useBranches();

  const [updatePairedDevice] = useUpdatePairedDeviceMutation();
  const {data: devices} = useGetPairedDevicesListQuery();
  const currentDevice = devices?.find((device) => device.pairedDeviceId === props.pairedDeviceId);

  const handleSubmit: FormSubmitHandler<FormValues> = async (values) => {
    if (!props.pairedDeviceId) {
      throw new Error('No paired device id');
    }

    await updatePairedDevice({
      pairedDeviceId: props.pairedDeviceId,
      updatePairedDeviceRequestBody: values,
    })
      .unwrap()
      .then(closeCurrentDialog)
      .then(() =>
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'))
      )
      .catch(handleApiError);
  };

  return (
    <>
      <VStack spacing={4} align="center" justify="center">
        <Icon size={20} value="content/computer_home_sync" color="text.white" />
        <Heading size={3}>{i18n.t('entity.settings.labels.editDevice')}</Heading>
        <Box width={140}>
          <Text size="small" align="center">
            {i18n.t('entity.settings.labels.editDeviceDescription')}
          </Text>
        </Box>
      </VStack>

      <Space vertical={10} />

      <Form<FormValues>
        schema={schema}
        onSubmit={handleSubmit}
        defaultValues={{name: currentDevice?.name, branches: currentDevice?.branches}}
      >
        {(control) => (
          <>
            <VStack spacing={4}>
              <FormField
                control={control}
                type="text"
                label={i18n.t('entity.settings.labels.deviceName')}
                name="name"
                isRequired
                data-testid={suffixTestId('deviceName', props)}
              />

              <FormField
                control={control}
                type="multiChoice"
                label={i18n.t('entity.branch.labels.branches')}
                name="branches"
                options={branchOptions}
                menuInPortal
                isRequired
                data-testid={suffixTestId('branches', props)}
              />
            </VStack>

            <Space vertical={10} />

            <Center>
              <FormButton
                control={control}
                type="submit"
                title={i18n.t('general.actions.saveChanges')}
                data-testid={suffixTestId('saveChanges', props)}
              />
            </Center>
          </>
        )}
      </Form>
    </>
  );
}

const schema = object({
  name: yupString.required(),
  branches: array().of(yupString).min(1, i18n.t('general.errors.mixed.required')).required(),
});
