import {
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormSubmitHandler,
  FormField,
} from 'platform/components';
import {VStack} from 'platform/foundation';
import {object} from 'yup';

import {usePutServiceItemSetDiscountMutation} from '@dms/api/metadaWorkshopServiceOrderJob';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId, yupNumber} from 'shared';

type BulkDiscountFormType = {
  discountRatio: number;
};

interface BulkDiscountFormProps extends RequiredTestIdProps {
  serviceCaseId: string;
  serviceOrderId: string;
  items: {serviceJobId: string; serviceItemId: string}[];
  onSubmit: VoidFunction;
  onClose: VoidFunction;
}

export function BulkDiscountForm(props: BulkDiscountFormProps) {
  const [putServiceOrderItemsAssignMechanic] = usePutServiceItemSetDiscountMutation();

  const handleSubmit: FormSubmitHandler<BulkDiscountFormType> = async (data) => {
    await putServiceOrderItemsAssignMechanic({
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.serviceOrderId,
      body: {
        items: props.items.map((item) => ({
          serviceItemId: item.serviceItemId,
          serviceJobId: item.serviceJobId,
        })),
        discountRatio: data.discountRatio / 100,
      },
    })
      .unwrap()
      .then(() => {
        props.onSubmit();
        props.onClose();
      })
      .catch(handleApiError);
  };

  return (
    <Form<BulkDiscountFormType> schema={formSchema} onSubmit={handleSubmit}>
      {(control) => (
        <VStack spacing={4}>
          <FormField
            control={control}
            label={i18n.t('general.labels.discount')}
            name="discountRatio"
            type="number"
            isRequired
            suffix="%"
            data-testid={suffixTestId('discount', props)}
          />
          <ButtonGroup align="right">
            <Button
              title={i18n.t('general.actions.discard')}
              variant="secondary"
              onClick={props.onClose}
              data-testid={suffixTestId('discardButton', props)}
            />
            <FormButton
              control={control}
              type="submit"
              title={i18n.t('general.actions.save')}
              data-testid={suffixTestId('saveButton', props)}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}

const formSchema = object({
  discountRatio: yupNumber.max(100).min(0).required(),
});
