import {
  BreadcrumbType,
  Form,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Heading, Space, Text, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {
  useGetBusinessCaseDocumentsSettingsQuery,
  usePutBusinessCaseDocumentsSettingsMutation,
} from '@dms/api/sales';
import i18n from '@dms/i18n';
import {settingsRoutes, testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {useNavigate} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

type FormValues = {
  selling: {
    vehicleCondition: boolean;
    powerOfAttorney: boolean;
    saleHandoverProtocol: boolean;
    saleContract: boolean;
    consent: boolean;
  };
  buying: {
    vehicleCondition: boolean;
    powerOfAttorney: boolean;
    handoverProtocol: boolean;
    purchaseContract: boolean;
    consent: boolean;
  };
  purchaseBrokerage: {
    vehicleCondition: boolean;
    powerOfAttorney: boolean;
    purchaseBrokerageHandoverProtocol: boolean;
    purchaseBrokerageContract: boolean;
    consent: boolean;
  };
  sellingBrokerage: {
    vehicleCondition: boolean;
    powerOfAttorney: boolean;
    saleHandoverProtocol: boolean;
    saleBrokerageContract: boolean;
    consent: boolean;
  };
};

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('page.businessCases.title'),
    href: settingsRoutes.businessCase,
  },
];

export function GeneratedDocuments() {
  const navigate = useNavigate();
  const {data, isLoading, isError} = useGetBusinessCaseDocumentsSettingsQuery();
  const [saveBusinessCaseDocumentsSettings] = usePutBusinessCaseDocumentsSettingsMutation();

  const handleSubmit: FormSubmitHandler<FormValues> = (data) =>
    saveBusinessCaseDocumentsSettings({businessCaseDocumentsSettingsRequestBody: {...data}})
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.success'));
      })
      .catch(handleApiError);

  const defaultValues: FormValues = {
    selling: {
      vehicleCondition: data?.selling.vehicleCondition ?? false,
      powerOfAttorney: data?.selling.powerOfAttorney ?? false,
      saleHandoverProtocol: data?.selling.saleHandoverProtocol ?? false,
      saleContract: data?.selling.saleContract ?? false,
      consent: data?.selling.consent ?? false,
    },
    buying: {
      vehicleCondition: data?.buying.vehicleCondition ?? false,
      powerOfAttorney: data?.buying.powerOfAttorney ?? false,
      handoverProtocol: data?.buying.handoverProtocol ?? false,
      purchaseContract: data?.buying.purchaseContract ?? false,
      consent: data?.buying.consent ?? false,
    },
    purchaseBrokerage: {
      vehicleCondition: data?.purchaseBrokerage.vehicleCondition ?? false,
      powerOfAttorney: data?.purchaseBrokerage.powerOfAttorney ?? false,
      purchaseBrokerageHandoverProtocol:
        data?.purchaseBrokerage.purchaseBrokerageHandoverProtocol ?? false,
      purchaseBrokerageContract: data?.purchaseBrokerage.purchaseBrokerageContract ?? false,
      consent: data?.purchaseBrokerage.consent ?? false,
    },
    sellingBrokerage: {
      vehicleCondition: data?.sellingBrokerage.vehicleCondition ?? false,
      powerOfAttorney: data?.sellingBrokerage.powerOfAttorney ?? false,
      saleHandoverProtocol: data?.sellingBrokerage.saleHandoverProtocol ?? false,
      saleBrokerageContract: data?.sellingBrokerage.saleBrokerageContract ?? false,
      consent: data?.sellingBrokerage.consent ?? false,
    },
  };

  return (
    <SettingsTemplate
      header={{
        title: i18n.t('page.bcDocumentsSettings.labels.title'),
        breadcrumbs,
      }}
      data-testid={testIds.settings.generatedDocuments('page')}
      isLoading={isLoading}
      isError={isError}
    >
      <Text size="small" color="secondary">
        {i18n.t('page.bcDocumentsSettings.labels.subtitle')}
      </Text>
      <Space vertical={6} />
      <Form<FormValues> onSubmit={handleSubmit} schema={schema} defaultValues={defaultValues}>
        {(control) => (
          <VStack spacing={4}>
            <SettingsSection>
              <VStack spacing={6}>
                <Heading size={3}>{i18n.t('page.bcDocumentsSettings.labels.buying.title')}</Heading>
                <FormField
                  control={control}
                  type="checkbox"
                  name="buying.vehicleCondition"
                  data-testid={testIds.settings.generatedDocuments('buyingVehicleCondition')}
                  label={i18n.t('page.bcDocumentsSettings.labels.buying.vehicleCondition')}
                  isDisabled={isLoading}
                />
                <FormField
                  control={control}
                  type="checkbox"
                  name="buying.powerOfAttorney"
                  data-testid={testIds.settings.generatedDocuments('buyingPowerOfAttorney')}
                  label={i18n.t('page.bcDocumentsSettings.labels.buying.powerOfAttorney')}
                  isDisabled={isLoading}
                />
                <FormField
                  control={control}
                  type="checkbox"
                  name="buying.handoverProtocol"
                  data-testid={testIds.settings.generatedDocuments('buyingHandoverProtocol')}
                  label={i18n.t('page.bcDocumentsSettings.labels.buying.handoverProtocol')}
                  isDisabled={isLoading}
                />
                <FormField
                  control={control}
                  type="checkbox"
                  name="buying.purchaseContract"
                  data-testid={testIds.settings.generatedDocuments('buyingPurchaseContract')}
                  label={i18n.t('page.bcDocumentsSettings.labels.buying.purchaseContract')}
                  isDisabled={isLoading}
                />
                <FormField
                  control={control}
                  type="checkbox"
                  name="buying.consent"
                  data-testid={testIds.settings.generatedDocuments('buyingConsent')}
                  label={i18n.t('page.bcDocumentsSettings.labels.buying.consent')}
                  isDisabled={isLoading}
                />
                <Heading size={3}>
                  {i18n.t('page.bcDocumentsSettings.labels.purchaseBrokerage.title')}
                </Heading>
                <FormField
                  control={control}
                  type="checkbox"
                  name="purchaseBrokerage.vehicleCondition"
                  data-testid={testIds.settings.generatedDocuments(
                    'purchaseBrokerageVehicleCondition'
                  )}
                  label={i18n.t(
                    'page.bcDocumentsSettings.labels.purchaseBrokerage.vehicleCondition'
                  )}
                  isDisabled={isLoading}
                />
                <FormField
                  control={control}
                  type="checkbox"
                  name="purchaseBrokerage.powerOfAttorney"
                  data-testid={testIds.settings.generatedDocuments(
                    'purchaseBrokeragePowerOfAttorney'
                  )}
                  label={i18n.t(
                    'page.bcDocumentsSettings.labels.purchaseBrokerage.powerOfAttorney'
                  )}
                  isDisabled={isLoading}
                />
                <FormField
                  control={control}
                  type="checkbox"
                  name="purchaseBrokerage.purchaseBrokerageHandoverProtocol"
                  data-testid={testIds.settings.generatedDocuments(
                    'purchaseBrokeragePurchaseBrokerageHandoverProtocol'
                  )}
                  label={i18n.t(
                    'page.bcDocumentsSettings.labels.purchaseBrokerage.purchaseBrokerageHandoverProtocol'
                  )}
                  isDisabled={isLoading}
                />
                <FormField
                  control={control}
                  type="checkbox"
                  name="purchaseBrokerage.purchaseBrokerageContract"
                  data-testid={testIds.settings.generatedDocuments(
                    'purchaseBrokeragePurchaseBrokerageContract'
                  )}
                  label={i18n.t(
                    'page.bcDocumentsSettings.labels.purchaseBrokerage.purchaseBrokerageContract'
                  )}
                  isDisabled={isLoading}
                />
                <FormField
                  control={control}
                  type="checkbox"
                  name="purchaseBrokerage.consent"
                  data-testid={testIds.settings.generatedDocuments('purchaseBrokerageConsent')}
                  label={i18n.t('page.bcDocumentsSettings.labels.purchaseBrokerage.consent')}
                  isDisabled={isLoading}
                />
                <Heading size={3}>
                  {i18n.t('page.bcDocumentsSettings.labels.selling.title')}
                </Heading>
                <FormField
                  control={control}
                  type="checkbox"
                  name="selling.vehicleCondition"
                  data-testid={testIds.settings.generatedDocuments('sellingVehicleCondition')}
                  label={i18n.t('page.bcDocumentsSettings.labels.selling.vehicleCondition')}
                  isDisabled={isLoading}
                />
                <FormField
                  control={control}
                  type="checkbox"
                  name="selling.powerOfAttorney"
                  data-testid={testIds.settings.generatedDocuments('sellingPowerOfAttorney')}
                  label={i18n.t('page.bcDocumentsSettings.labels.selling.powerOfAttorney')}
                  isDisabled={isLoading}
                />
                <FormField
                  control={control}
                  type="checkbox"
                  name="selling.saleHandoverProtocol"
                  data-testid={testIds.settings.generatedDocuments('sellingSaleHandoverProtocol')}
                  label={i18n.t('page.bcDocumentsSettings.labels.selling.saleHandoverProtocol')}
                  isDisabled={isLoading}
                />
                <FormField
                  control={control}
                  type="checkbox"
                  name="selling.saleContract"
                  data-testid={testIds.settings.generatedDocuments('sellingSaleContract')}
                  label={i18n.t('page.bcDocumentsSettings.labels.selling.saleContract')}
                  isDisabled={isLoading}
                />
                <FormField
                  control={control}
                  type="checkbox"
                  name="selling.consent"
                  data-testid={testIds.settings.generatedDocuments('sellingConsent')}
                  label={i18n.t('page.bcDocumentsSettings.labels.selling.consent')}
                  isDisabled={isLoading}
                />
                <Heading size={3}>
                  {i18n.t('page.bcDocumentsSettings.labels.sellingBrokerage.title')}
                </Heading>
                <FormField
                  control={control}
                  type="checkbox"
                  name="sellingBrokerage.vehicleCondition"
                  data-testid={testIds.settings.generatedDocuments(
                    'sellingBrokerageVehicleCondition'
                  )}
                  label={i18n.t(
                    'page.bcDocumentsSettings.labels.sellingBrokerage.vehicleCondition'
                  )}
                  isDisabled={isLoading}
                />
                <FormField
                  control={control}
                  type="checkbox"
                  name="sellingBrokerage.powerOfAttorney"
                  data-testid={testIds.settings.generatedDocuments(
                    'sellingBrokeragePowerOfAttorney'
                  )}
                  label={i18n.t('page.bcDocumentsSettings.labels.sellingBrokerage.powerOfAttorney')}
                  isDisabled={isLoading}
                />
                <FormField
                  control={control}
                  type="checkbox"
                  name="sellingBrokerage.saleHandoverProtocol"
                  data-testid={testIds.settings.generatedDocuments(
                    'sellingBrokerageSaleHandoverProtocol'
                  )}
                  label={i18n.t(
                    'page.bcDocumentsSettings.labels.sellingBrokerage.saleHandoverProtocol'
                  )}
                  isDisabled={isLoading}
                />
                <FormField
                  control={control}
                  type="checkbox"
                  name="sellingBrokerage.saleBrokerageContract"
                  data-testid={testIds.settings.generatedDocuments(
                    'sellingBrokerageSaleBrokerageContract'
                  )}
                  label={i18n.t(
                    'page.bcDocumentsSettings.labels.sellingBrokerage.saleBrokerageContract'
                  )}
                  isDisabled={isLoading}
                />
                <FormField
                  control={control}
                  type="checkbox"
                  name="sellingBrokerage.consent"
                  data-testid={testIds.settings.generatedDocuments('sellingBrokerageConsent')}
                  label={i18n.t('page.bcDocumentsSettings.labels.sellingBrokerage.consent')}
                  isDisabled={isLoading}
                />
              </VStack>
            </SettingsSection>
            <Space vertical={18} />
            <SettingsFooter
              actions={[
                {
                  type: 'button',
                  title: i18n.t('general.actions.discardChanges'),
                  onClick: () => navigate(settingsRoutes.businessCase),
                  variant: 'secondary',
                  isDisabled: isLoading,
                },
                {
                  type: 'form-button',
                  control,
                  isLoading,
                  buttonType: 'submit',
                  title: i18n.t('general.actions.saveChanges'),
                },
              ]}
            />
          </VStack>
        )}
      </Form>
    </SettingsTemplate>
  );
}

const schema = Yup.object({
  selling: Yup.object({
    vehicleCondition: Yup.boolean().required(),
    powerOfAttorney: Yup.boolean().required(),
    saleHandoverProtocol: Yup.boolean().required(),
    saleContract: Yup.boolean().required(),
    consent: Yup.boolean().required(),
  }),
  buying: Yup.object({
    vehicleCondition: Yup.boolean().required(),
    powerOfAttorney: Yup.boolean().required(),
    handoverProtocol: Yup.boolean().required(),
    purchaseContract: Yup.boolean().required(),
    consent: Yup.boolean().required(),
  }),
  purchaseBrokerage: Yup.object({
    vehicleCondition: Yup.boolean().required(),
    powerOfAttorney: Yup.boolean().required(),
    purchaseBrokerageHandoverProtocol: Yup.boolean().required(),
    purchaseBrokerageContract: Yup.boolean().required(),
    consent: Yup.boolean().required(),
  }),
  sellingBrokerage: Yup.object({
    vehicleCondition: Yup.boolean().required(),
    powerOfAttorney: Yup.boolean().required(),
    saleHandoverProtocol: Yup.boolean().required(),
    saleBrokerageContract: Yup.boolean().required(),
    consent: Yup.boolean().required(),
  }),
});
