import {closeCurrentDialog, openDialog} from 'platform/components';
import {ThemeColorPath} from 'platform/foundation';
import {match} from 'ts-pattern';

import {CodeResponseBody} from '@dms/api/codelist';
import {InterestResponseBody} from '@dms/api/interest';
import {BusinessCaseActivityResponseBody, InterestActivityResponseBody} from '@dms/api/sales';
import {EntityResourceIds} from '@dms/api/shared';
import i18n from '@dms/i18n';
import {businessCaseRoutes, interestRoutes} from '@dms/routes';

import {buildArray, composePath, isNotNilOrEmpty} from 'shared';

import {MessageDialog} from '../components/ActivityTab/MessageDialog';
import {LogContactDialog} from '../components/LogContactDialog/LogContactDialog';
import {IconColors} from '../constants/activityItem';
import {TransformedActivityItem, ActivityItemDataLink} from '../types/ActivitySidebarAction';

type ActivityItem = (InterestActivityResponseBody | BusinessCaseActivityResponseBody) & {
  color?: ThemeColorPath;
};

const handleOpenLogContactDialog = (item: ActivityItem) => {
  openDialog(<LogContactDialog salesActivity={item} />, {
    id: 'LOG_CONTACT_DIALOG',
    title: i18n.t('entity.activityPanel.logContact.editLog'),
    scrollBehavior: 'outside',
  });
};

const handleOpenMessageDialog = (body: string, title: string, id: string) => {
  openDialog(<MessageDialog message={body} />, {
    id,
    title,
    buttons: [
      {
        variant: 'secondary',
        title: i18n.t('general.actions.close'),
        onClick: closeCurrentDialog,
      },
    ],
  });
};

export const transformActivityItem = (
  item: ActivityItem,
  interest?: InterestResponseBody,
  codes?: {source?: CodeResponseBody; closeReason?: CodeResponseBody},
  parentResourceId?: EntityResourceIds,
  openEditTaskFormDialog?: (id: string) => void
): TransformedActivityItem =>
  match<string, TransformedActivityItem>(item.activityType)
    .with('INTEREST_CREATED', () => ({
      id: item.id,
      color: IconColors.GREEN,
      title: i18n.t('entity.activityPanel.activity.interestCreated'),
      interestType: codes?.source?.name,
      description: item.description,
      userName: item.userName,
      userId: item.userId,
      timestamp: item.timestamp,
      icon: 'content/next_week',
      links: buildArray<ActivityItemDataLink>().when(
        parentResourceId === EntityResourceIds.businessCase,
        {
          label: i18n.t('entity.activityPanel.activity.actions.openInterest'),
          url: composePath(interestRoutes.detail, {
            params: {id: 'interestId' in item ? (item.interestId ?? '') : ''},
          }),
        }
      ),
    }))
    .with('INTEREST_OPENED', () => ({
      id: item.id,
      title: i18n.t('entity.activityPanel.activity.interestOpened'),
      timestamp: item.timestamp,
      color: IconColors.GREEN,
      userName: item.userName,
      userId: item.userId,
      icon: 'content/next_week',
      links: buildArray<ActivityItemDataLink>().when(
        parentResourceId === EntityResourceIds.businessCase,
        {
          label: i18n.t('entity.activityPanel.activity.actions.openInterest'),
          url: composePath(interestRoutes.detail, {
            params: {id: 'interestId' in item ? (item.interestId ?? '') : ''},
          }),
        }
      ),
    }))
    .with('INTEREST_CLOSED', () => ({
      id: item.id,
      title: i18n.t('entity.activityPanel.activity.interestClosed'),
      timestamp: item.timestamp,
      color: IconColors.RED,
      userName: item.userName,
      userId: item.userId,
      description: interest?.reasonCloseNote ?? codes?.closeReason?.name ?? item.description,
      icon: 'content/next_week',
      links: buildArray<ActivityItemDataLink>().when(
        parentResourceId === EntityResourceIds.businessCase,
        {
          label: i18n.t('entity.activityPanel.activity.actions.openInterest'),
          url: composePath(interestRoutes.detail, {
            params: {id: 'interestId' in item ? (item.interestId ?? '') : ''},
          }),
        }
      ),
    }))
    .with('INTEREST_OPPORTUNITY_LOST', () => ({
      id: item.id,
      title: i18n.t('entity.activityPanel.activity.interestOpportunityLost'),
      timestamp: item.timestamp,
      color: IconColors.RED,
      userName: item.userName,
      userId: item.userId,
      description: item.reasonCloseNote ?? codes?.closeReason?.name ?? item.description,
      icon: 'custom/business_case_sad',
      links: buildArray<ActivityItemDataLink>().when(item.reasonCloseNote, {
        label: i18n.t('general.labels.note'),
        tooltip: [item.reasonCloseNote ?? '', codes?.closeReason?.name ?? ''],
      }),
    }))
    .with('BUSINESS_CASE_CREATED', () => ({
      id: item.id,
      title: i18n.t('entity.activityPanel.activity.businessCaseCreated'),
      timestamp: item.timestamp,
      color: IconColors.GREEN,
      userName: item.userName,
      userId: item.userId,
      description: item.description,
      icon: 'places/business_center',
      links: buildArray<ActivityItemDataLink>().when(
        parentResourceId === EntityResourceIds.interest,
        {
          label: i18n.t('entity.activityPanel.activity.actions.openBusinessCase'),
          url: composePath(businessCaseRoutes.overview, {
            params: {id: 'businessCaseId' in item ? (item.businessCaseId ?? '') : ''},
          }),
        }
      ),
    }))
    .with('BUSINESS_CASE_CLOSED', () => ({
      id: item.id,
      title: i18n.t('entity.activityPanel.activity.businessCaseClosed'),
      timestamp: item.timestamp,
      color: IconColors.RED,
      userName: item.userName,
      userId: item.userId,
      description: item.description,
      icon: 'places/business_center',
      links: buildArray<ActivityItemDataLink>().when(
        parentResourceId === EntityResourceIds.interest,
        {
          label: i18n.t('entity.activityPanel.activity.actions.openBusinessCase'),
          url: composePath(businessCaseRoutes.overview, {
            params: {id: 'businessCaseId' in item ? (item.businessCaseId ?? '') : ''},
          }),
        }
      ),
    }))
    .with('BUSINESS_CASE_OPPORTUNITY_LOST', () => ({
      id: item.id,
      title: i18n.t('entity.activityPanel.activity.businessCaseOpportunityLost'),
      timestamp: item.timestamp,
      color: IconColors.RED,
      userName: item.userName,
      userId: item.userId,
      description: item.reasonCloseNote ?? item.description,
      icon: 'custom/business_case_sad',
      links: buildArray<ActivityItemDataLink>().when(item.reasonCloseNote, {
        label: i18n.t('general.labels.note'),
        tooltip: [item?.reasonCloseNote ?? '', codes?.closeReason?.name ?? ''],
      }),
    }))
    .with('MESSAGE_TO_CUSTOMER', () => ({
      id: item.id,
      title: item.subject ?? i18n.t('entity.activityPanel.activity.messageToCustomer'),
      timestamp: item.timestamp,
      color: IconColors.GREEN,
      interestType: i18n.t('entity.activityPanel.activity.messageToCustomer'),
      userId: item.userId,
      userName: item.userName,
      description: item.description,
      icon: 'communication/comment',
      isEditable: item.isEditable,
      onClick: () => handleOpenLogContactDialog(item),
    }))
    .with('CALL_FROM_CUSTOMER', () => ({
      id: item.id,
      title: item.subject ?? i18n.t('entity.activityPanel.activity.callFromCustomer'),
      timestamp: item.timestamp,
      color: IconColors.GREEN,
      interestType: i18n.t('entity.activityPanel.activity.callFromCustomer'),
      userName: item.userName,
      userId: item.userId,
      description: item.description,
      isEditable: item.isEditable,
      icon: 'notification/phone_inbound',
      onClick: () => handleOpenLogContactDialog(item),
    }))
    .with('CALL_TO_CUSTOMER', () => ({
      id: item.id,
      title: item.subject ?? i18n.t('entity.activityPanel.activity.callToCustomer'),
      timestamp: item.timestamp,
      color: IconColors.GREEN,
      interestType: i18n.t('entity.activityPanel.activity.callToCustomer'),
      userName: item.userName,
      userId: item.userId,
      description: item.description,
      isEditable: item.isEditable,
      icon: 'notification/phone_outbound',
      onClick: () => handleOpenLogContactDialog(item),
    }))
    .with('MESSAGE_FROM_CUSTOMER', () => ({
      id: item.id,
      title: item.subject ?? i18n.t('entity.activityPanel.activity.messageFromCustomer'),
      timestamp: item.timestamp,
      color: IconColors.GREEN,
      interestType: i18n.t('entity.activityPanel.activity.messageFromCustomer'),
      userName: item.userName,
      userId: item.userId,
      description: item.description,
      icon: 'communication/comment',
      isEditable: item.isEditable,
      onClick: () => handleOpenLogContactDialog(item),
    }))
    .with('CUSTOMER_VISIT', () => ({
      id: item.id,
      title: item.subject ?? i18n.t('entity.activityPanel.activity.customerVisit'),
      timestamp: item.timestamp,
      color: IconColors.GREEN,
      userName: item.userName,
      userId: item.userId,
      interestType: i18n.t('entity.activityPanel.activity.customerVisit'),
      description: item.description,
      icon: 'communication/location_on',
      isEditable: item.isEditable,
      onClick: () => handleOpenLogContactDialog(item),
    }))
    .with('CONTRACT_CLOSED', () => ({
      id: item.id,
      title: i18n.t('entity.activityPanel.activity.contractClosed'),
      icon: 'action/contract_closed',
      timestamp: item.timestamp,
      color: IconColors.GREEN,
      userName: item.userName,
      userId: item.userId,
    }))
    .with('DEPOSIT_PAYMENT_ISSUED', () => ({
      id: item.id,
      title: i18n.t('entity.activityPanel.activity.depositPaymentIssued'),
      icon: 'action/deposit_payment',
      timestamp: item.timestamp,
      color: IconColors.GREEN,
      userName: item.userName,
      userId: item.userId,
      links: buildArray<ActivityItemDataLink>().when(
        parentResourceId === EntityResourceIds.interest,
        {
          label: i18n.t('general.labels.detail'),
          url: composePath(businessCaseRoutes.checkout, {
            params: {id: 'businessCaseId' in item ? (item.businessCaseId ?? '') : ''},
            queryParams: {'SALE-CheckoutOrder': 'payment'},
          }),
        }
      ),
    }))
    .with('BALANCE_PAYMENT_ISSUED', () => ({
      id: item.id,
      title: i18n.t('entity.activityPanel.activity.balancePaymentIssued'),
      icon: 'action/invoice_issued',
      timestamp: item.timestamp,
      color: IconColors.GREEN,
      userName: item.userName,
      userId: item.userId,
      links: buildArray<ActivityItemDataLink>().when(
        parentResourceId === EntityResourceIds.interest,
        {
          label: i18n.t('general.labels.detail'),
          url: composePath(businessCaseRoutes.checkout, {
            params: {id: 'businessCaseId' in item ? (item.businessCaseId ?? '') : ''},
            queryParams: {'SALE-CheckoutOrder': 'payment'},
          }),
        }
      ),
    }))
    .with('PURCHASE_PAYMENT_ISSUED', () => ({
      id: item.id,
      title: i18n.t('entity.activityPanel.activity.purchasePaymentIssued'),
      icon: 'action/invoice_issued',
      timestamp: item.timestamp,
      color: IconColors.GREEN,
      links: buildArray<ActivityItemDataLink>().when(
        parentResourceId === EntityResourceIds.interest,
        {
          label: i18n.t('general.labels.detail'),
          url: composePath(businessCaseRoutes.checkout, {
            params: {id: 'businessCaseId' in item ? (item.businessCaseId ?? '') : ''},
            queryParams: {'SALE-CheckoutOrder': 'payment'},
          }),
        }
      ),
    }))
    .with('PAYMENT_PAID', () => ({
      id: item.id,
      title: i18n.t('entity.activityPanel.activity.paymentPaid'),
      icon: 'action/payment_accepted',
      timestamp: item.timestamp,
      color: IconColors.GREEN,
      userName: item.userName,
      userId: item.userId,
      links: buildArray<ActivityItemDataLink>().when(
        parentResourceId === EntityResourceIds.interest,
        {
          label: i18n.t('general.labels.detail'),
          url: composePath(businessCaseRoutes.checkout, {
            params: {id: 'businessCaseId' in item ? (item.businessCaseId ?? '') : ''},
            queryParams: {'SALE-CheckoutOrder': 'payment'},
          }),
        }
      ),
    }))
    .with('TASK_CREATED', () => ({
      id: item.id,
      title: item.relatedTask?.title ?? item.description ?? '',
      timestamp: item.timestamp,
      icon: 'sidebar/task_management',
      color: item.color ?? IconColors.GREEN,
      userId: item.userId,
      userName: isNotNilOrEmpty(item.userName) ? item.userName : undefined,
      description: !item.relatedTask
        ? i18n.t('entity.activityPanel.activity.taskDeleted')
        : i18n.t('entity.activityPanel.activity.taskCreated'),
      links: buildArray<ActivityItemDataLink>().when(item.relatedTask, {
        label: i18n.t('general.labels.detail'),
        onClick: () => openEditTaskFormDialog && openEditTaskFormDialog(item.relatedTask!.id),
      }),
    }))
    .with('TASK_FINISHED', () => ({
      id: item.id,
      title: item.relatedTask?.title ?? item.description ?? '',
      timestamp: item.timestamp,
      icon: 'sidebar/task_management',
      color: IconColors.GREEN,
      userId: item.userId,
      userName: isNotNilOrEmpty(item.userName) ? item.userName : undefined,
      description: !item.relatedTask
        ? i18n.t('entity.activityPanel.activity.taskDeleted')
        : i18n.t('entity.activityPanel.activity.taskFinished'),
      links: buildArray<ActivityItemDataLink>().when(item.relatedTask, {
        label: i18n.t('general.labels.detail'),
        onClick: () => openEditTaskFormDialog && openEditTaskFormDialog(item.relatedTask!.id),
      }),
    }))
    .with('ONLINE_OFFER_SHARING_CANCELED', () => ({
      id: item.id,
      timestamp: item.timestamp,
      color: IconColors.RED,
      userName: item.userName,
      userId: item.userId,
      title: i18n.t('entity.activityPanel.activity.onlineOfferSharingCanceled'),
      icon: 'content/mail_opened',
    }))
    .with('ONLINE_OFFER_SENT', () => ({
      id: item.id,
      title: i18n.t('entity.activityPanel.activity.onlineOfferSent'),
      timestamp: item.timestamp,
      color: IconColors.GREEN,
      links: buildArray<ActivityItemDataLink>().add({
        label: i18n.t('general.labels.detail'),
        onClick: () =>
          handleOpenMessageDialog(
            item.relatedMessage?.body ?? '',
            i18n.t('entity.activityPanel.activity.actions.emailSent'),
            'MESSAGE_DIALOG_ONLINE_OFFER'
          ),
      }),
    }))
    .with('INBOUND_CALL', () => ({
      id: item.id,
      title: i18n.t('entity.activityPanel.activity.inboundCall'),
      timestamp: item.timestamp,
      color: IconColors.GREEN,
      icon: 'communication/call',
      userId: item.userId,
      userName: item.userName,
      description: item.description,
      links: buildArray<ActivityItemDataLink>().add({
        label: i18n.t('general.labels.detail'),
        onClick: () =>
          handleOpenMessageDialog(
            item.relatedCall?.id ?? '',
            i18n.t('entity.activityPanel.activity.actions.inboundCall'),
            'MESSAGE_DIALOG_CALL'
          ),
      }),
    }))
    .with('OUTBOUND_CALL', () => ({
      id: item.id,
      title: i18n.t('entity.activityPanel.activity.outboundCall'),
      timestamp: item.timestamp,
      color: IconColors.GREEN,
      icon: 'communication/call',
      userId: item.userId,
      userName: item.userName,
      description: item.description,
      links: buildArray<ActivityItemDataLink>().add({
        label: i18n.t('general.labels.detail'),
        onClick: () =>
          handleOpenMessageDialog(
            item.relatedCall?.id ?? '',
            i18n.t('entity.activityPanel.activity.actions.outboundCall'),
            'MESSAGE_DIALOG_CALL'
          ),
      }),
    }))
    .with('EMAIL_TO_CUSTOMER', () => ({
      id: item.id,
      title: i18n.t('entity.activityPanel.activity.emailToCustomer'),
      timestamp: item.timestamp,
      description: item.relatedMessage?.body,
      userName: item.relatedMessage?.sentTo ?? undefined,
      interestType: item.relatedMessage?.subject ?? undefined,
      color: IconColors.GREEN,
      icon: 'communication/email',
      links: buildArray<ActivityItemDataLink>().add({
        label: i18n.t('general.labels.detail'),
        onClick: () =>
          handleOpenMessageDialog(
            item.relatedMessage?.body ?? '',
            i18n.t('entity.activityPanel.activity.actions.emailSent'),
            'MESSAGE_DIALOG_EMAIL'
          ),
      }),
    }))
    .with('SMS_TO_CUSTOMER', () => ({
      id: item.id,
      title: i18n.t('entity.activityPanel.activity.smsToCustomer'),
      description: item.relatedMessage?.body,
      userName: item.relatedMessage?.sentTo ?? undefined,
      interestType: item.relatedMessage?.subject ?? undefined,
      timestamp: item.timestamp,
      color: IconColors.GREEN,
      icon: 'communication/textsms',
      links: buildArray<ActivityItemDataLink>().add({
        label: i18n.t('general.labels.detail'),
        onClick: () =>
          handleOpenMessageDialog(
            item.relatedMessage?.body ?? '',
            i18n.t('entity.activityPanel.activity.actions.smsSent'),
            'MESSAGE_DIALOG_SMS'
          ),
      }),
    }))
    .otherwise(() => null);
