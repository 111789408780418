import {Form, FormField, FormSubmitHandler, Separator, showNotification} from 'platform/components';
import {Grid, GridItem, Heading, Hide, Text, VStack} from 'platform/foundation';
import {boolean, object} from 'yup';

import {mergeAll, values} from 'ramda';

import {
  useAccGetConfigurationExportCarisQuery,
  useAccPatchConfigurationExportCarisMutation,
} from '@dms/api/metadaAccounting';
import i18n from '@dms/i18n';
import {testIds} from '@dms/routes';
import {handleApiError} from '@dms/shared';

import {yupNumber} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

const MIN_BACK_DAYS = 0;

type ExportParameters = {
  exportDateOffset: number;
  exportDateOffsetId: string;
  exportNewOnly: boolean;
  exportNewOnlyId: string;
};

type FormValues = {
  CarisInvoice: ExportParameters;
  CarisCashReceipt: ExportParameters;
  CarisStockMovement: ExportParameters;
};

export function ExportParametersDetail() {
  const {data: carisExport, isLoading, isError} = useAccGetConfigurationExportCarisQuery();
  const [updateCarisExport] = useAccPatchConfigurationExportCarisMutation();

  const exportData = carisExport?.exportDateOffset?.map((item) => {
    const exportNewOnly = carisExport?.exportNewOnly?.find(
      (exportNewOnly) => exportNewOnly?.filterValue === item?.filterValue
    );

    return {
      exportDateOffset: item,
      exportNewOnly,
    };
  });

  const defaultValues = exportData?.map((item) => ({
    [item.exportDateOffset?.filterValue as keyof FormValues]: {
      exportDateOffset: item.exportDateOffset?.parameterValue ?? 0,
      exportDateOffsetId: item.exportDateOffset?.id,
      exportNewOnly: item.exportNewOnly?.parameterValue ?? false,
      exportNewOnlyId: item.exportNewOnly?.id,
    },
  }));

  const handleSubmit: FormSubmitHandler<FormValues> = async (data) => {
    const exportDateOffset = values(data).map((item) => ({
      id: item.exportDateOffsetId,
      parameterValue: item.exportDateOffset,
    }));
    const exportNewOnly = values(data).map((item) => ({
      id: item.exportNewOnlyId,
      parameterValue: item.exportNewOnly,
    }));

    await updateCarisExport({exportDateOffset, exportNewOnly})
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);
  };

  return (
    <SettingsTemplate
      header={{title: i18n.t('page.accountingSettings.labels.exportParameters')}}
      isLoading={isLoading}
      isError={isError}
      data-testid={testIds.settings.exportParameters('page')}
    >
      <SettingsSection>
        <Form<FormValues>
          defaultValues={mergeAll(defaultValues ?? [])}
          onSubmit={handleSubmit}
          schema={schema}
        >
          {(control) => (
            <VStack>
              {exportData?.map((item, index) => {
                const filterValue = item.exportDateOffset?.filterValue as keyof FormValues;
                const isLastItem = index === exportData?.length - 1;

                return (
                  <VStack key={item.exportDateOffset?.id} spacing={2}>
                    <Heading size={4}>
                      {i18n.t(`page.accountingSettings.labels.${filterValue}`)}
                    </Heading>

                    <FormField
                      control={control}
                      type="checkbox"
                      name={`${filterValue}.exportNewOnly`}
                      label={i18n.t('page.accountingSettings.labels.newOnly')}
                      data-testid={testIds.settings.exportParameters(
                        `${filterValue}.exportNewOnly`
                      )}
                    />

                    <Grid columns={3} align="baseline">
                      <FormField
                        control={control}
                        type="number"
                        minStepperValue={MIN_BACK_DAYS}
                        name={`${filterValue}.exportDateOffset`}
                        isStepperVisible
                        data-testid={testIds.settings.exportParameters(
                          `${filterValue}.exportDateOffset`
                        )}
                      />
                      <GridItem span={2}>
                        <Text size="small">
                          {i18n.t('page.accountingSettings.labels.daysBack')}
                        </Text>
                      </GridItem>
                    </Grid>
                    <Hide when={isLastItem}>
                      <Separator />
                    </Hide>
                  </VStack>
                );
              })}

              <SettingsFooter
                actions={[
                  {
                    type: 'form-button',
                    control,
                    title: i18n.t('general.actions.saveChanges'),
                    buttonType: 'submit',
                    'data-testid': testIds.settings.exportParameters('saveChanges'),
                  },
                ]}
              />
            </VStack>
          )}
        </Form>
      </SettingsSection>
    </SettingsTemplate>
  );
}

const schema = object({
  CarisInvoice: object({
    exportNewOnly: boolean().default(false),
    exportDateOffset: yupNumber.required().min(MIN_BACK_DAYS),
  }),
  CarisCashReceipt: object({
    exportNewOnly: boolean().default(false),
    exportDateOffset: yupNumber.required().min(MIN_BACK_DAYS),
  }),
  CarisStockMovement: object({
    exportNewOnly: boolean().default(false),
    exportDateOffset: yupNumber.required().min(MIN_BACK_DAYS),
  }),
});
