import {
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Separator,
  closeCurrentDialog,
  showNotification,
} from 'platform/components';
import {Grid, GridItem, VStack} from 'platform/foundation';
import {array, object} from 'yup';

import {useGetRolesQuery} from '@dms/api/accessControl';
import {useGetEmployeePersonalDataQuery, usePutEmployeeLinkMutation} from '@dms/api/metadaEmployee';
import {RoleResponseBody, UserResponseBody} from '@dms/api/shared';
import {useCreateUserMutation} from '@dms/api/user';
import i18n from '@dms/i18n';
import {handleApiError, useBranches, yupPasswordValidation} from '@dms/shared';

import {RequiredTestIdProps, suffixTestId, yupString} from 'shared';

type FormValues = {
  email: string;
  password: string;
  confirmPassword: string;
  defaultBranchId: string;
  roles: string[];
  branchIds: string[];
  firstName: string;
  lastName: string;
};

interface CreateUserFormProps extends RequiredTestIdProps {
  employeeId: string;
}

export function CreateUserForm(props: CreateUserFormProps) {
  const {data: employee} = useGetEmployeePersonalDataQuery({employeeId: props.employeeId});
  const {branchOptions, isLoading: isLoadingBranches} = useBranches();
  const {data: roles, isLoading: isLoadingRoles} = useGetRolesQuery({});

  const [createUser] = useCreateUserMutation();
  const [linkToUser] = usePutEmployeeLinkMutation();

  const linkEmployeeToUser = async (user: UserResponseBody) => {
    await linkToUser({employeeId: props.employeeId, userId: user.id})
      .unwrap()
      .catch(handleApiError);
  };

  const handleSubmit: FormSubmitHandler<FormValues> = async (values) => {
    await createUser({
      createUserRequestBody: {
        ...values,
        pins: [],
      },
    })
      .unwrap()
      .then(linkEmployeeToUser)
      .then(() => showNotification.success())
      .then(closeCurrentDialog)
      .catch(handleApiError);
  };

  const roleOptions = roles?.map(rolesToOptions);

  const defaultValues: Partial<FormValues> = {
    firstName: employee?.personalData?.firstName,
    lastName: employee?.personalData?.lastName,
  };

  return (
    <Form<FormValues> schema={schema} onSubmit={handleSubmit} defaultValues={defaultValues}>
      {(control, formApi) => {
        const selectedBranches: string[] | null = formApi.watch('branchIds');
        const defaultBranchOptions = branchOptions?.filter(({value}) =>
          selectedBranches?.includes(value)
        );

        return (
          <VStack spacing={4}>
            <Grid columns={2}>
              <FormField
                control={control}
                name="firstName"
                type="text"
                isRequired
                label={i18n.t('general.labels.firstName')}
                data-testid={suffixTestId('userCreateForm-firstName', props)}
              />

              <FormField
                control={control}
                name="lastName"
                type="text"
                isRequired
                label={i18n.t('general.labels.lastName')}
                data-testid={suffixTestId('userCreateForm-lastName', props)}
              />

              <GridItem span={2}>
                <Separator />
              </GridItem>

              <FormField
                control={control}
                name="email"
                type="email"
                isRequired
                label={i18n.t('entity.user.labels.emailUsername')}
                data-testid={suffixTestId('userCreateForm-emailUsername', props)}
              />

              <FormField
                control={control}
                name="roles"
                type="multiChoice"
                isLoading={isLoadingRoles}
                isRequired
                menuInPortal
                options={roleOptions}
                label={i18n.t('general.labels.roles')}
                data-testid={suffixTestId('userCreateForm-roles', props)}
              />

              <FormField
                control={control}
                name="password"
                type="password"
                isRequired
                label={i18n.t('general.labels.password')}
                data-testid={suffixTestId('userCreateForm-password', props)}
              />
              <FormField
                control={control}
                name="confirmPassword"
                type="password"
                isRequired
                label={i18n.t('general.labels.confirmPassword')}
                data-testid={suffixTestId('userCreateForm-confirmPassword', props)}
              />

              <FormField
                control={control}
                name="branchIds"
                type="multiChoice"
                isLoading={isLoadingBranches}
                isRequired
                menuInPortal
                options={branchOptions}
                label={i18n.t('entity.branch.labels.branches')}
                data-testid={suffixTestId('userCreateForm-branches', props)}
              />
              <FormField
                control={control}
                name="defaultBranchId"
                isLoading={isLoadingBranches}
                menuInPortal
                isNotClearable
                type="choice"
                options={defaultBranchOptions}
                isRequired
                label={i18n.t('entity.branch.labels.defaultBranch')}
                data-testid={suffixTestId('userCreateForm-defaultBranch', props)}
              />
            </Grid>

            <ButtonGroup align="right">
              <Button
                variant="secondary"
                title={i18n.t('general.actions.discard')}
                data-testid={suffixTestId('userCreateForm-discard', props)}
                onClick={closeCurrentDialog}
              />
              <FormButton
                control={control}
                type="submit"
                title={i18n.t('general.actions.create')}
                data-testid={suffixTestId('userCreateForm-create', props)}
                variant="primary"
              />
            </ButtonGroup>
          </VStack>
        );
      }}
    </Form>
  );
}

const rolesToOptions = (role: RoleResponseBody) => ({
  value: role.id,
  label: role.title,
});

const schema = object({
  email: yupString.required(),
  password: yupPasswordValidation,
  confirmPassword: yupPasswordValidation.test(
    'passwords-match',
    i18n.t('general.notifications.passwordsDoesNotMatch'),
    (value, context) => context.parent.password === value
  ),
  defaultBranchId: yupString
    .required()
    .test('default-branch-match', i18n.t('general.validations.fieldIsRequired'), (value, context) =>
      context.parent.branchIds?.includes(value)
    ),
  roles: array()
    .of(yupString)
    .min(1, i18n.t('general.notifications.errorFieldRequired'))
    .required(i18n.t('general.notifications.errorFieldRequired')),
  branchIds: array()
    .of(yupString)
    .min(1, i18n.t('general.notifications.errorFieldRequired'))
    .required(i18n.t('general.notifications.errorFieldRequired')),
  firstName: yupString.required(),
  lastName: yupString.required(),
});
