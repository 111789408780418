/* eslint-disable eag/match-named-export */
import {
  AddressResponseBody,
  AddressResponseBodyV2,
  ContractInformationResponseBody,
  ContractInformationResponseBodyV2,
} from '@dms/api/customer';
import i18n from '@dms/i18n';
import {getNaturalPersonFullName} from '@dms/shared';

import {LegalFormEnum} from '../types/LegalFormEnum';

export const isNaturalPerson = (contractInformation?: ContractInformationResponseBodyV2) =>
  contractInformation?.legalForm === LegalFormEnum.NATURAL_PERSON;

export const isSelfEmployed = (contractInformation?: ContractInformationResponseBodyV2) =>
  contractInformation?.legalForm === LegalFormEnum.SELF_EMPLOYED;

export const getContractName = (contract?: ContractInformationResponseBodyV2) => {
  if (!contract) {
    return '';
  }

  if (isNaturalPerson(contract)) {
    return getNaturalPersonFullName(contract.person);
  }

  return contract.businessInfo?.businessInfo.tradeName || '';
};

export const getContractType = (contract?: ContractInformationResponseBodyV2) => {
  if (!contract) {
    return '';
  }

  if (isNaturalPerson(contract)) {
    return i18n.t('entity.person.labels.physicalPerson');
  } else if (isSelfEmployed(contract)) {
    return i18n.t('entity.customer.labels.isSelfEmployed');
  }

  return i18n.t('general.labels.company');
};

export const getContractAddress = (
  contract?: ContractInformationResponseBodyV2
): AddressResponseBodyV2 | null => {
  if (!contract) {
    return null;
  }

  if (isNaturalPerson(contract)) {
    return contract.person?.permanentAddress || null;
  }

  return contract.businessInfo?.address || null;
};

export const isSelfEmployedV1 = (contractInformation?: ContractInformationResponseBody) =>
  contractInformation?.legalForm === LegalFormEnum.SELF_EMPLOYED;

export const getContractNameV1 = (contract?: ContractInformationResponseBody) => {
  if (!contract) {
    return '';
  }

  if (contract.legalForm === LegalFormEnum.NATURAL_PERSON && contract.person?.personData) {
    return getNaturalPersonFullName({
      titleBefore: contract.person.personData.titleBefore,
      firstName: contract.person.personData.firstName,
      middleName: contract.person.personData.middleName,
      lastName: contract.person.personData.lastName,
      titleAfter: contract.person.personData.titleAfter,
    });
  }

  return contract.businessInfo?.businessInfoData.tradeName || '';
};

export const getContractTypeV1 = (contract?: ContractInformationResponseBody) => {
  if (!contract) {
    return '';
  }

  if (contract.legalForm === LegalFormEnum.NATURAL_PERSON) {
    return i18n.t('entity.person.labels.physicalPerson');
  } else if (isSelfEmployedV1(contract)) {
    return i18n.t('entity.customer.labels.isSelfEmployed');
  }

  return i18n.t('general.labels.company');
};

export const getContractAddressV1 = (
  contract?: ContractInformationResponseBody
): AddressResponseBody | null => {
  if (!contract) {
    return null;
  }

  if (contract.legalForm === LegalFormEnum.NATURAL_PERSON) {
    return contract.person?.permanentAddress || null;
  }

  return contract.businessInfo?.businessAddress || null;
};
