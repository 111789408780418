import {useSubscription} from '@apollo/client';
import {showNotification} from 'platform/components';

import {ReactNode} from 'react';

import {isNotNil} from 'ramda-adjunct';

import {SEND_NOTIFICATION_SUBSCRIPTION} from '@dms/api/graphQl';
import i18n from '@dms/i18n';

interface NotificationSubscriptionProviderProps {
  children: ReactNode;
}

export function NotificationSubscriptionProvider(props: NotificationSubscriptionProviderProps) {
  useSubscription(SEND_NOTIFICATION_SUBSCRIPTION, {
    onData: ({data}) => {
      const isToastNotification = isNotNil(data.data.onSendNotification.toastNotificationId);

      if (isToastNotification) {
        showNotification.info(i18n.t('general.labels.newNotificationReceived'));
      }
    },
    fetchPolicy: 'no-cache',
    shouldResubscribe: false,
  });

  return props.children;
}
