import {Form, showNotification} from 'platform/components';
import {array, object} from 'yup';

import {isNotEmpty, isNotNil, omit} from 'ramda';

import {usePutRentalVehicleMutation} from '@dms/api/rentalVehicles';
import {GetVehicleApiResponseV2, VehicleTypeEnumObject} from '@dms/api/shared';
import {useLazySourcingGetCarFeaturesQuery} from '@dms/api/sourcing';
import {
  useCreateVehicleV2Mutation,
  useDeleteVehicleMutation,
  useSetAdditionalNoteMutation,
  useUpdateVehicleV2Mutation,
} from '@dms/api/vehicle';
import i18n from '@dms/i18n';
import {handleApiError} from '@dms/shared';

import {ApiError, parseDate, RequiredTestIdProps, suffixTestId, yupString} from 'shared';

import {serviceIntervalsDefault} from '../constants/serviceIntervalsDefault';
import {RentalVehicleFormMode} from '../types/RentalVehicleFormMode';
import {RentalVehicleFormType} from '../types/RentalVehicleFormType';
import {convertFormVehicleToRentalVehicle} from '../utils/convertFormVehicleToRentalVehicle';
import {convertFormVehicleToVehicleV2} from '../utils/convertFormVehicleToVehicleV2';
import {FormStructure} from './FormStructure';

interface DefaultProps extends RequiredTestIdProps {
  extendedDefaultFormData?: Partial<RentalVehicleFormType>;
  onSuccess: (newVehicleId: string) => void;
  onDiscard: () => void;
}

type RentalVehicleFormLogicProps = DefaultProps &
  (
    | {
        mode: RentalVehicleFormMode.NEW;
      }
    | {
        mode:
          | RentalVehicleFormMode.NEW_FROM_SALE_OR_SERVICE
          | RentalVehicleFormMode.EDIT
          | RentalVehicleFormMode.CLONE;
        vehicleData: GetVehicleApiResponseV2;
        defaultFormData: Partial<RentalVehicleFormType>;
      }
  );

export function RentalVehicleFormLogic(props: RentalVehicleFormLogicProps) {
  const [createVehicle, createVehicleStatus] = useCreateVehicleV2Mutation();
  const [createRentalVehicle, createRentalVehicleStatus] = usePutRentalVehicleMutation();
  const [deleteVehicle] = useDeleteVehicleMutation();
  const [setAdditionalNote] = useSetAdditionalNoteMutation();

  const [updateVehicle, updateVehicleStatus] = useUpdateVehicleV2Mutation();

  const [getCarFeaturesQuery] = useLazySourcingGetCarFeaturesQuery();

  const isVehicleCreating = createVehicleStatus.isLoading || createRentalVehicleStatus.isLoading;
  const isVehicleUpdating = updateVehicleStatus.isLoading;

  const getFormDefaultValues = (): Partial<RentalVehicleFormType> => {
    if (
      props.mode === RentalVehicleFormMode.EDIT ||
      props.mode === RentalVehicleFormMode.NEW_FROM_SALE_OR_SERVICE
    ) {
      return props.defaultFormData;
    }

    if (props.mode === RentalVehicleFormMode.CLONE) {
      return omit(['registrationPlate', 'vin'], props.defaultFormData);
    }

    return {
      type: [VehicleTypeEnumObject.VEHICLETYPE_PASSENGER_CAR],
      features: [],
      serviceIntervals: serviceIntervalsDefault,
    };
  };

  const getCarFeatures = (formData: RentalVehicleFormType) => {
    if (!formData.make || !formData.modelFamily || !formData.firstRegistrationOn) {
      return Promise.reject(new Error('Missing required fields'));
    }

    return getCarFeaturesQuery(
      {
        body: {
          make: formData.make,
          model: formData.modelFamily,
          year: parseDate(formData.firstRegistrationOn).getFullYear(),
          features: formData.features || [],
        },
      },
      true
    ).unwrap();
  };

  const handleVehicleCreation = async (formData: RentalVehicleFormType) => {
    const staticCarFeatures = await getCarFeatures(formData).catch(() => undefined);

    const {rentalProperties, ...vehicleData} = formData;

    const rentalVehicleRequestBody = convertFormVehicleToRentalVehicle({rentalProperties});
    const saneVehicle = convertFormVehicleToVehicleV2({
      formVehicle: vehicleData,
      givenCarFeatures: [],
      staticCarFeatures,
    });

    await createVehicle(saneVehicle)
      .unwrap()
      .then(async ({id: vehicleId}) => {
        await createRentalVehicle({vehicleId, rentalVehicleRequestBody})
          .unwrap()
          .then(async () => {
            if (isNotNil(formData.note) && isNotEmpty(formData.note)) {
              await setAdditionalNote({
                vehicleId,
                additionalNoteRequestBody: {note: formData.note},
              });
            }

            showNotification.success();
            props.onSuccess?.(vehicleId);
          })
          .catch(async (error: ApiError) => {
            await deleteVehicle({vehicleId});
            handleApiError(error);
          });
      })
      .catch(handleApiError);
  };

  const handleVehicleUpdate = async (formData: RentalVehicleFormType) => {
    if (props.mode === RentalVehicleFormMode.NEW || props.mode === RentalVehicleFormMode.CLONE) {
      return;
    }

    const {rentalProperties, ...vehicleData} = formData;

    const staticCarFeatures = await getCarFeatures(formData).catch(() => undefined);
    const rentalVehicleRequestBody = convertFormVehicleToRentalVehicle({rentalProperties});

    const saneVehicle = convertFormVehicleToVehicleV2({
      formVehicle: vehicleData,
      givenCarFeatures: props.vehicleData.features,
      staticCarFeatures,
    });

    await createRentalVehicle({vehicleId: props.vehicleData.id, rentalVehicleRequestBody})
      .unwrap()
      .catch(handleApiError);

    await updateVehicle({id: props.vehicleData.id, ...saneVehicle})
      .unwrap()
      .then(async () => {
        if (isNotNil(formData.note) && isNotEmpty(formData.note)) {
          await setAdditionalNote({
            vehicleId: props.vehicleData.id,
            additionalNoteRequestBody: {note: formData.note},
          });
        }

        showNotification.success();
        props.onSuccess?.(props.vehicleData.id);
      })
      .catch(handleApiError);
  };

  return (
    <Form<RentalVehicleFormType>
      defaultValues={{...getFormDefaultValues(), ...props.extendedDefaultFormData}}
      schema={schema}
    >
      {(control, formApi) => (
        <FormStructure
          control={control}
          formApi={formApi}
          mode={props.mode}
          isVehicleCreating={isVehicleCreating}
          isVehicleUpdating={isVehicleUpdating}
          onVehicleCreation={handleVehicleCreation}
          onVehicleUpdate={handleVehicleUpdate}
          onCreateRentalVehicleFromSaleOrService={handleVehicleUpdate}
          onDiscard={props.onDiscard}
          data-testid={suffixTestId('extendedView', props)}
        />
      )}
    </Form>
  );
}

const schema = object({
  formMake: yupString.required(),
  formModelFamily: yupString.required(),
  type: array().of(yupString.required()).required().length(1, i18n.t('general.errors.required')),
});
