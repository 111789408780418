import {metadaApi} from '@dms/api/core';
import {BaseVoidResponseSchema} from '@dms/api/shared';

import {
  GetLabourRateApiArg,
  GetLabourRateApiResponse,
  DeleteLabourRateApiResponse,
  DeleteLabourRateApiArg,
  PostLabourRateSetAsActiveApiResponse,
  PostLabourRateSetAsActiveApiArg,
  PostLabourRateSetAsInActiveApiResponse,
  PostLabourRateSetAsInActiveApiArg,
  PostLabourRateApiResponse,
  PostLabourRateApiArg,
  PatchLabourRateApiResponse,
  PatchLabourRateApiArg,
  PostLabourRateApiArgSchema,
  LabourRateApiResponseSchema,
  GetLabourRateApiArgSchema,
  PatchLabourRateApiArgSchema,
  DeleteLabourRateApiArgSchema,
  PostLabourRateSetAsActiveApiArgSchema,
  PostLabourRateSetAsInActiveApiArgSchema,
} from './types';

export const metadaWorkshopLabourRateApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postLabourRate: build.mutation<PostLabourRateApiResponse, PostLabourRateApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/labour-rate`,
        method: 'POST',
        body: queryArg.body,
        queryArg,
      }),
      extraOptions: {
        responseSchema: LabourRateApiResponseSchema,
        requestSchema: PostLabourRateApiArgSchema,
      },
    }),
    getLabourRate: build.query<GetLabourRateApiResponse, GetLabourRateApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/labour-rate/${queryArg.labourRateId}`,
        queryArg,
      }),
      providesTags: (result, error, queryArg) => [{type: 'labourRate', id: queryArg.labourRateId}],
      extraOptions: {
        responseSchema: LabourRateApiResponseSchema,
        requestSchema: GetLabourRateApiArgSchema,
      },
    }),
    patchLabourRate: build.mutation<PatchLabourRateApiResponse, PatchLabourRateApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/labour-rate/${queryArg.labourRateId}`,
        method: 'PATCH',
        body: queryArg.body,
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'labourRate', id: queryArg.labourRateId},
      ],
      extraOptions: {
        responseSchema: LabourRateApiResponseSchema,
        requestSchema: PatchLabourRateApiArgSchema,
      },
    }),
    deleteLabourRate: build.mutation<DeleteLabourRateApiResponse, DeleteLabourRateApiArg>({
      query: (queryArg) => ({
        url: `/service/v1/labour-rate/${queryArg.labourRateId}`,
        method: 'DELETE',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'labourRate', id: queryArg.labourRateId},
      ],
      extraOptions: {
        responseSchema: BaseVoidResponseSchema,
        requestSchema: DeleteLabourRateApiArgSchema,
      },
    }),
    postLabourRateSetAsActive: build.mutation<
      PostLabourRateSetAsActiveApiResponse,
      PostLabourRateSetAsActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/labour-rate/${queryArg.labourRateId}/set-as-active`,
        method: 'POST',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'labourRate', id: queryArg.labourRateId},
      ],
      extraOptions: {
        responseSchema: BaseVoidResponseSchema,
        requestSchema: PostLabourRateSetAsActiveApiArgSchema,
      },
    }),
    postLabourRateSetAsInActive: build.mutation<
      PostLabourRateSetAsInActiveApiResponse,
      PostLabourRateSetAsInActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/labour-rate/${queryArg.labourRateId}/set-as-inactive`,
        method: 'POST',
        queryArg,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'labourRate', id: queryArg.labourRateId},
      ],
      extraOptions: {
        responseSchema: BaseVoidResponseSchema,
        requestSchema: PostLabourRateSetAsInActiveApiArgSchema,
      },
    }),
  }),
});

export const {
  useGetLabourRateQuery,
  usePostLabourRateMutation,
  usePatchLabourRateMutation,
  useDeleteLabourRateMutation,
  usePostLabourRateSetAsActiveMutation,
  usePostLabourRateSetAsInActiveMutation,
} = metadaWorkshopLabourRateApi;
