import {Action, Attributes, AttributesRow, Card, DataStatus, Separator} from 'platform/components';
import {VStack} from 'platform/foundation';

import {isEmpty, isNil} from 'ramda';

import {useGetManufacturerQuery} from '@dms/api/metadaAdminManufacturer';
import {useGetTreeFolderPathQuery} from '@dms/api/metadaTreeFolder';
import {useGetWarehouseQuery} from '@dms/api/metadaWarehouse';
import {useGetWarehouseAccountQuery} from '@dms/api/metadaWarehouseAccount';
import {useGetArticleQuery} from '@dms/api/metadaWarehouseArticle';
import i18n from '@dms/i18n';
import {warehouseRoutes} from '@dms/routes';

import {composePath, Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {ArticleDetailCardPrices} from '../../../../../components/ArticleDetailCardPrices';
import {getArticleDetailCardPrices} from '../../../../../utils/getArticleDetailCardPrices';

interface ArticleDetailCardProps extends RequiredTestIdProps {
  warehouseId: string | Nullish;
  articleId: string | Nullish;
}

export function ArticleDetailCard(props: ArticleDetailCardProps) {
  const doesNotHaveArticleDetailContext =
    isNil(props.warehouseId) ||
    isEmpty(props.warehouseId) ||
    isNil(props.articleId) ||
    isEmpty(props.articleId);

  const {
    data: articleDetail,
    isLoading: isArticleDetailLoading,
    isFetching: isArticleDetailFetching,
    isError: hasArticleDetailError,
    refetch: refreshArticleDetail,
  } = useGetArticleQuery(
    {
      warehouseId: props.warehouseId!,
      articleId: props.articleId!,
    },
    {skip: doesNotHaveArticleDetailContext}
  );

  const doesNotHaveWarehouseContext = isNil(props.warehouseId) || isEmpty(props.warehouseId);

  const {
    data: warehouseByArticle,
    isLoading: isWarehouseByArticleLoading,
    isError: hasWarehouseByArticleError,
  } = useGetWarehouseQuery({warehouseId: props.warehouseId!}, {skip: doesNotHaveWarehouseContext});

  const {
    data: manufacturerByArticle,
    isLoading: isManufacturerByArticleLoading,
    isError: hasManufacturerByArticleError,
  } = useGetManufacturerQuery(
    {manufacturerId: articleDetail?.manufacturerId},
    {skip: isNil(articleDetail?.manufacturerId)}
  );

  const {
    data: warehouseAccountByArticle,
    isLoading: isWarehouseAccountByArticleLoading,
    isError: hasWarehouseAccountByArticleError,
  } = useGetWarehouseAccountQuery(
    {warehouseAccountId: articleDetail?.warehouseAccount!},
    {skip: isNil(articleDetail?.warehouseAccount)}
  );

  const {
    data: treeFolderPathByArticle,
    isLoading: isTreeFolderPathByArticleLoading,
    isError: hasTreeFolderPathByArticleError,
  } = useGetTreeFolderPathQuery(
    {leafId: articleDetail?.treeFolder?.leafId!},
    {skip: isNil(articleDetail?.treeFolder?.leafId)}
  );

  const isArticleDetailCardLoading =
    isArticleDetailLoading ||
    isArticleDetailFetching ||
    isWarehouseByArticleLoading ||
    isManufacturerByArticleLoading ||
    isWarehouseAccountByArticleLoading ||
    isTreeFolderPathByArticleLoading;

  const hasArticleDetailCardError =
    hasArticleDetailError ||
    hasWarehouseByArticleError ||
    hasManufacturerByArticleError ||
    hasWarehouseAccountByArticleError ||
    hasTreeFolderPathByArticleError;

  const handleEditArticle = () => {
    window.open(
      composePath(warehouseRoutes.articleDetailOverview, {
        params: {warehouseId: props.warehouseId, id: props.articleId},
      }),
      '_blank'
    );
  };

  const prices = getArticleDetailCardPrices(articleDetail);

  const currency = warehouseByArticle?.currency;

  const actions: Action[] = [
    {
      type: 'button',
      variant: 'link',
      title: i18n.t('general.actions.refresh'),
      leftIcon: 'navigation/refresh',
      onClick: refreshArticleDetail,
    },
    {
      type: 'button',
      variant: 'link',
      title: i18n.t('entity.warehouse.actions.editArticle'),
      leftIcon: 'action/open_in_new',
      onClick: handleEditArticle,
    },
  ];

  const rows: AttributesRow[] = [
    {
      label: i18n.t('entity.warehouse.labels.name'),
      value: articleDetail?.name,
    },
    {
      label: i18n.t('entity.warehouse.labels.catalogueNumber'),
      value: articleDetail?.manufacturerNumber,
    },
    {
      label: i18n.t('entity.warehouse.labels.manufacturer'),
      value: manufacturerByArticle?.name,
    },
    {
      label: i18n.t('entity.warehouse.labels.warehouseAccount'),
      value: warehouseAccountByArticle?.name,
    },
    {
      label: i18n.t('entity.warehouse.labels.storageLocation'),
      value: articleDetail?.storageLocation,
    },
    {
      label: i18n.t('entity.warehouse.labels.category'),
      value: treeFolderPathByArticle?.treeFolder?.pathName,
    },
  ];
  return (
    <Card
      variant="inlineGrey"
      title={i18n.t('entity.warehouse.labels.articleDetail')}
      actions={actions}
      isExpandable
      data-testid={props['data-testid']}
    >
      <VStack>
        <DataStatus
          isLoading={isArticleDetailCardLoading}
          isError={hasArticleDetailCardError}
          minHeight={70}
        >
          <Attributes rows={rows} data-testid={suffixTestId('articleDetailAttributes', props)} />
          <Separator spacing={3} />
          <ArticleDetailCardPrices prices={prices} currency={currency} />
        </DataStatus>
      </VStack>
    </Card>
  );
}
