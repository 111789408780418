import {TabProps, Tabs} from 'platform/components';

import {GetIssueNoteCorrectionResponse} from '@dms/api/metadaWarehouseIssueNoteCorrection';
import i18n from '@dms/i18n';
import {queryParams, Section} from '@dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId, useQueryState} from 'shared';

import {GeneralInformationTab} from './components/GeneralInformationTab';
import {ListOfItemsTab} from './components/ListOfItemsTab';

interface OverviewProps extends RequiredTestIdProps {
  issueNoteCorrection: GetIssueNoteCorrectionResponse | Nullish;
  isIssueNoteCorrectionLoading: boolean;
}

export function Overview(props: OverviewProps) {
  const [activeTabId, setActiveTabId] = useQueryState(queryParams.COMPONENT_SECTIONS_TAB);

  const tabs: TabProps[] = [
    {
      id: 'general-information',
      title: i18n.t('general.labels.generalInformation'),
      content: (
        <GeneralInformationTab
          issueNoteCorrection={props.issueNoteCorrection}
          isIssueNoteCorrectionLoading={props.isIssueNoteCorrectionLoading}
          data-testid={suffixTestId('tabs.generalInformation', props)}
        />
      ),
    },
    {
      id: 'list-of-items',
      title: i18n.t('general.labels.listOfItems'),
      content: (
        <ListOfItemsTab
          issueNoteCorrection={props.issueNoteCorrection}
          data-testid={suffixTestId('tabs.listOfItems', props)}
        />
      ),
    },
  ];
  return (
    <Section isFullHeight data-testid={suffixTestId('wrapper', props)}>
      <Tabs
        isFullHeight
        isLazy
        variant="condensed"
        tabs={tabs}
        activeTabId={activeTabId}
        onChange={setActiveTabId}
      />
    </Section>
  );
}
