import {Box, Center, Text} from 'platform/foundation';
import styled from 'styled-components';

import {useIsMobileNavigation} from '../hooks/useIsMobileNavigation';
import {BadgeProps} from '../types/BadgeProps';

export function NavigationButtonBadge(props: BadgeProps) {
  const isMobileNavigation = useIsMobileNavigation();

  return (
    <Box
      position="absolute"
      top={isMobileNavigation ? 1 : 0}
      right={isMobileNavigation ? 1 : 0}
      height={4}
      width={4}
    >
      <Center width="100%" height="100%">
        <Box position="relative">
          <StyledCenter>
            <Text size="xxSmall" color="white" align="center">
              {props.value}
            </Text>
          </StyledCenter>
        </Box>
      </Center>
    </Box>
  );
}

const StyledCenter = styled.div`
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  padding: 0 ${({theme}) => theme.getSize(1)};
  border-radius: ${({theme}) => theme.radii.circular};
  background-color: ${({theme}) => theme.colors.palettes.red[50][100]};
`;
