import {toNumber} from 'ramda-adjunct';

import {BuyingOfferPurchaseVehicleResponseBody} from '@dms/api/commission';

import {Nullish} from 'shared';

export const getIsMaxBuyingPriceLowerThanBuyingPrice = (
  price: BuyingOfferPurchaseVehicleResponseBody | Nullish
) => {
  const maxBuyingPrice = toNumber(price?.maxBuyingPrice?.amount ?? 0);
  const priceNotDeductible = toNumber(price?.buyingPrice?.priceNotDeductible?.amount ?? 0);
  const priceWithVat = toNumber(price?.buyingPrice?.priceWithVat?.amount ?? 0);
  const isVatDeductible = price?.vatDeductible;
  return isVatDeductible ? maxBuyingPrice < priceWithVat : maxBuyingPrice < priceNotDeductible;
};
