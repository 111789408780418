import {getPixelsFromRem, getSize, Integer} from 'platform/foundation';

/** The base height of a time slot in rem units */
export const SLOT_HEIGHT: Integer = 13;

/** The CSS-friendly size value for the slot height (includes 'rem' unit) */
export const CSS_SLOT_HEIGHT = getSize(SLOT_HEIGHT);

/** The slot height converted to pixels for absolute positioning calculations */
export const SLOT_HEIGHT_IN_PIXELS = Number(getPixelsFromRem(SLOT_HEIGHT));
